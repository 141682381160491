import type { ID } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { segmentIdentify } from '@/utils/analytics';

import { vwoMiddleware } from '../vwo/middleware';

let segmentUserAuthenticatedAnalytics: AnalyticsBrowser | undefined;
let segmentUserUnauthenticatedAnalytics: AnalyticsBrowser | undefined;
let segmentAnonymousId!: ID;

export const initializeSegment = async (): Promise<ID> => {
  // console.debug(`# DEBUG: INITIALIZING_SEGMENT`);
  if (typeof window !== 'undefined') {
    segmentUserAuthenticatedAnalytics = new AnalyticsBrowser();
    segmentUserUnauthenticatedAnalytics = new AnalyticsBrowser();

    try {
      await Promise.all([
        segmentUserAuthenticatedAnalytics.load({
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY as string,
        }),
        segmentUserUnauthenticatedAnalytics.load({
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY as string,
        }),
      ]);

      segmentUserAuthenticatedAnalytics.addSourceMiddleware(vwoMiddleware());
      segmentUserUnauthenticatedAnalytics?.addSourceMiddleware(vwoMiddleware());

      segmentAnonymousId = (await segmentUserUnauthenticatedAnalytics?.user())?.anonymousId();

      // Hack because "@littledata/headless-shopify-sdk"; fetchClientIds() does not work (!window.analytics)
      window.localStorage.setItem(
        'littledataIDs',
        JSON.stringify({ '_segment-clientID': segmentAnonymousId })
      );

      if (segmentAnonymousId) {
        segmentIdentify({ id: segmentAnonymousId });
      }

      return segmentAnonymousId;
    } catch (error) {
      console.error('ERROR_LOADING_SEGMENT:', error);
    }
  }
};
export { segmentUserAuthenticatedAnalytics, segmentUserUnauthenticatedAnalytics };
