import { logEvent } from 'firebase/analytics';
import { v4 as uuidv4 } from 'uuid';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import { segmentUserUnauthenticatedAnalytics } from '@/lib/segment/segment';
import {
  getSegmentAnalytics,
  globalContextProperties,
  globalEventProperties,
} from '@/utils/analytics/analytics-core';
import { sendAxonEvent, sendTikTokPixelEvent } from '@/utils/analytics/pixel';

export const pageViewAnalytics = (pageLocation: string, pageName: string) => {
  const eventId = uuidv4();
  logEvent(firebaseAnalytics, 'page_view', {
    page_location: pageLocation,
  });
  getSegmentAnalytics()?.page(undefined, pageName, globalEventProperties(), {
    ...globalContextProperties(),
  });
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent('ViewContent', {
    event_id: eventId,
    user_agent,
    timestamp: new Date().toISOString(),
  });
  sendAxonEvent('page_view', {});
};

export const everflowPageViewAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track(
    'Page View',
    {
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};
