import { logEvent } from 'firebase/analytics';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import {
  segmentUserAuthenticatedAnalytics,
  segmentUserUnauthenticatedAnalytics,
} from '@/lib/segment/segment';
import type { FirebaseItem, ProductCartEventProperties } from '@/utils/analytics/analytics-core';
import {
  getCampaignData,
  globalContextProperties,
  globalEventProperties,
  isAuthenticated,
} from '@/utils/analytics/analytics-core';

export const removeFromCartAnalytics = (
  value: number,
  currency: string, //currency code
  items: FirebaseItem[],
  eventProperties: ProductCartEventProperties
) => {
  logEvent(firebaseAnalytics, 'remove_from_cart', {
    value,
    currency,
    items,
  });
  const campaignData = getCampaignData() ?? globalContextProperties();
  if (isAuthenticated()) {
    segmentUserAuthenticatedAnalytics?.track(
      'remove_from_cart',
      {
        value,
        currency,
        items,
      },
      {
        ...campaignData,
      }
    );
  } else {
    segmentUserUnauthenticatedAnalytics?.track(
      'Product Removed',
      {
        ...eventProperties,
        ...globalEventProperties(),
      },
      {
        ...campaignData,
      }
    );
  }
};
