export const fullName = (firstName?: string, lastName?: string): string => {
  if (firstName) {
    if (lastName) {
      return `${firstName} ${lastName}`;
    }
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return '';
};
