export const vwoMiddleware = () => {
  return ({ payload, next }: any) => {
    const getCookie = (name: string) => {
      if (typeof document === 'undefined') return null;
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(';').shift();
    };

    const event = payload.obj;
    const vwoUuid = getCookie('_vwo_uuid');

    switch (event.type) {
      case 'track':
      case 'page':
        payload.obj.properties = {
          ...payload.obj.properties,
          vwo_uuid: vwoUuid,
        };
        break;
      case 'identify':
        payload.obj.traits = {
          ...payload.obj.traits,
          vwo_uuid: vwoUuid,
        };
        break;
    }
    next();
  };
};
