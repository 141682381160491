import { AssessmentType } from '@backend-types/prisma';
import { CRUDaction } from '@backend-types/prisma';
import { CadenceType } from '@backend-types/prisma';
import { DMLType } from '@backend-types/prisma';
import { DNAKitRole } from '@backend-types/prisma';
import { DeliveryStatus } from '@backend-types/prisma';
import { DeviceType } from '@backend-types/prisma';
import { FeedbackType } from '@backend-types/prisma';
import { FileType } from '@backend-types/prisma';
import { FinanceStatus } from '@backend-types/prisma';
import { FirmwareDevice } from '@backend-types/prisma';
import { GeneralRank } from '@backend-types/prisma';
import { GeneralRankGroup } from '@backend-types/prisma';
import { HabitSubType } from '@backend-types/prisma';
import { HabitType } from '@backend-types/prisma';
import { HouseholdType } from '@backend-types/prisma';
import { OnboardingEntityType } from '@backend-types/prisma';
import { PlanType } from '@backend-types/prisma';
import { Role } from '@backend-types/prisma';
import { SetQuality } from '@backend-types/prisma';
import { StrapLocation } from '@backend-types/prisma';
import { SubWorkoutType } from '@backend-types/prisma';
import { SubscriptionType } from '@backend-types/prisma';
import { SurveyActivity } from '@backend-types/prisma';
import { SurveyAreasNeedingHelp } from '@backend-types/prisma';
import { SurveyEquipment } from '@backend-types/prisma';
import { SurveyFavoriteExercise } from '@backend-types/prisma';
import { SurveyFitnessAbility } from '@backend-types/prisma';
import { SurveyMotivation } from '@backend-types/prisma';
import { SurveyQuizAge } from '@backend-types/prisma';
import { SurveyQuizGender } from '@backend-types/prisma';
import { SurveyServicesTried } from '@backend-types/prisma';
import { SurveySleep } from '@backend-types/prisma';
import { SurveyWeightLossGoal } from '@backend-types/prisma';
import { SurveyWeightLossTimeline } from '@backend-types/prisma';
import { SurveyWorkouts } from '@backend-types/prisma';
import { ThemeColor } from '@backend-types/prisma';
import { TokenChangeSource } from '@backend-types/prisma';
import { TokenChangeType } from '@backend-types/prisma';
import { TutorialMode } from '@backend-types/prisma';
import { Unit } from '@backend-types/prisma';
import { VendorType } from '@backend-types/prisma';
import { WorkoutCategory } from '@backend-types/prisma';
import { WorkoutType } from '@backend-types/prisma';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AssessmentType: { input: AssessmentType; output: AssessmentType; }
  CRUDaction: { input: CRUDaction; output: CRUDaction; }
  CadenceType: { input: CadenceType; output: CadenceType; }
  DMLType: { input: DMLType; output: DMLType; }
  DNAKitRole: { input: DNAKitRole; output: DNAKitRole; }
  DeliveryStatus: { input: DeliveryStatus; output: DeliveryStatus; }
  DeviceType: { input: DeviceType; output: DeviceType; }
  FeedbackType: { input: FeedbackType; output: FeedbackType; }
  FileType: { input: FileType; output: FileType; }
  FinanceStatus: { input: FinanceStatus; output: FinanceStatus; }
  FirmwareDevice: { input: FirmwareDevice; output: FirmwareDevice; }
  GeneralRank: { input: GeneralRank; output: GeneralRank; }
  GeneralRankGroup: { input: GeneralRankGroup; output: GeneralRankGroup; }
  HabitSubType: { input: HabitSubType; output: HabitSubType; }
  HabitType: { input: HabitType; output: HabitType; }
  HouseholdType: { input: HouseholdType; output: HouseholdType; }
  OnboardingEntityType: { input: OnboardingEntityType; output: OnboardingEntityType; }
  PlanType: { input: PlanType; output: PlanType; }
  Role: { input: Role; output: Role; }
  SetQuality: { input: SetQuality; output: SetQuality; }
  StrapLocation: { input: StrapLocation; output: StrapLocation; }
  SubWorkoutType: { input: SubWorkoutType; output: SubWorkoutType; }
  SubscriptionType: { input: SubscriptionType; output: SubscriptionType; }
  SurveyActivity: { input: SurveyActivity; output: SurveyActivity; }
  SurveyAreasNeedingHelp: { input: SurveyAreasNeedingHelp; output: SurveyAreasNeedingHelp; }
  SurveyEquipment: { input: SurveyEquipment; output: SurveyEquipment; }
  SurveyFavoriteExercise: { input: SurveyFavoriteExercise; output: SurveyFavoriteExercise; }
  SurveyFitnessAbility: { input: SurveyFitnessAbility; output: SurveyFitnessAbility; }
  SurveyMotivation: { input: SurveyMotivation; output: SurveyMotivation; }
  SurveyQuizAge: { input: SurveyQuizAge; output: SurveyQuizAge; }
  SurveyQuizGender: { input: SurveyQuizGender; output: SurveyQuizGender; }
  SurveyServicesTried: { input: SurveyServicesTried; output: SurveyServicesTried; }
  SurveySleep: { input: SurveySleep; output: SurveySleep; }
  SurveyWeightLossGoal: { input: SurveyWeightLossGoal; output: SurveyWeightLossGoal; }
  SurveyWeightLossTimeline: { input: SurveyWeightLossTimeline; output: SurveyWeightLossTimeline; }
  SurveyWorkouts: { input: SurveyWorkouts; output: SurveyWorkouts; }
  ThemeColor: { input: ThemeColor; output: ThemeColor; }
  TokenChangeSource: { input: TokenChangeSource; output: TokenChangeSource; }
  TokenChangeType: { input: TokenChangeType; output: TokenChangeType; }
  TutorialMode: { input: TutorialMode; output: TutorialMode; }
  Unit: { input: Unit; output: Unit; }
  VendorType: { input: VendorType; output: VendorType; }
  WorkoutCategory: { input: WorkoutCategory; output: WorkoutCategory; }
  WorkoutType: { input: WorkoutType; output: WorkoutType; }
  bigint: { input: number; output: number; }
  date: { input: string; output: string; }
  float8: { input: number; output: number; }
  json: { input: Record<string, unknown>; output: Record<string, unknown>; }
  jsonb: { input: Record<string, unknown>; output: Record<string, unknown>; }
  time: { input: string; output: string; }
  timestamp: { input: string; output: string; }
};

export type ActionResultOutput = {
  __typename?: 'ActionResultOutput';
  action?: Maybe<CrudActionEnum>;
  result?: Maybe<Scalars['json']['output']>;
};

/** columns and relationships of "ActivationCode" */
export type ActivationCode = {
  __typename?: 'ActivationCode';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  kit: Scalars['String']['output'];
};

/** aggregated selection of "ActivationCode" */
export type ActivationCode_Aggregate = {
  __typename?: 'ActivationCode_aggregate';
  aggregate?: Maybe<ActivationCode_Aggregate_Fields>;
  nodes: Array<ActivationCode>;
};

/** aggregate fields of "ActivationCode" */
export type ActivationCode_Aggregate_Fields = {
  __typename?: 'ActivationCode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ActivationCode_Max_Fields>;
  min?: Maybe<ActivationCode_Min_Fields>;
};


/** aggregate fields of "ActivationCode" */
export type ActivationCode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivationCode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ActivationCode". All fields are combined with a logical 'AND'. */
export type ActivationCode_Bool_Exp = {
  _and?: InputMaybe<Array<ActivationCode_Bool_Exp>>;
  _not?: InputMaybe<ActivationCode_Bool_Exp>;
  _or?: InputMaybe<Array<ActivationCode_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  kit?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActivationCode" */
export type ActivationCode_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ActivationCode_pkey';

/** input type for inserting data into table "ActivationCode" */
export type ActivationCode_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kit?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ActivationCode_Max_Fields = {
  __typename?: 'ActivationCode_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kit?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ActivationCode_Min_Fields = {
  __typename?: 'ActivationCode_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kit?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ActivationCode" */
export type ActivationCode_Mutation_Response = {
  __typename?: 'ActivationCode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivationCode>;
};

/** on_conflict condition type for table "ActivationCode" */
export type ActivationCode_On_Conflict = {
  constraint: ActivationCode_Constraint;
  update_columns?: Array<ActivationCode_Update_Column>;
  where?: InputMaybe<ActivationCode_Bool_Exp>;
};

/** Ordering options when selecting data from "ActivationCode". */
export type ActivationCode_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActivationCode */
export type ActivationCode_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "ActivationCode" */
export type ActivationCode_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'id'
  /** column name */
  | 'kit';

/** input type for updating data in table "ActivationCode" */
export type ActivationCode_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kit?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ActivationCode" */
export type ActivationCode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActivationCode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivationCode_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kit?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ActivationCode" */
export type ActivationCode_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'id'
  /** column name */
  | 'kit';

export type ActivationCode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivationCode_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActivationCode_Bool_Exp;
};

export type ActivityInsightsDetailOutput = {
  __typename?: 'ActivityInsightsDetailOutput';
  active_time?: Maybe<ValueAndTime>;
  calories?: Maybe<CaloricOutput>;
  distance?: Maybe<ValueAndTime>;
  steps?: Maybe<ValueAndTime>;
  stepsV2?: Maybe<StepsV2Output>;
  time_moving?: Maybe<TimeMovingOutput>;
  time_working_out?: Maybe<TimeWorkingOutOutput>;
};

export type ActivityInsightsMetric =
  | 'ActiveTime'
  | 'Calories'
  | 'Distance'
  | 'Steps'
  | 'StepsV2'
  | 'TimeMoving'
  | 'TimeWorkingOut';

export type ActivityInsightsOutput = {
  __typename?: 'ActivityInsightsOutput';
  activeCalories?: Maybe<CurrentAverage>;
  activeMins?: Maybe<CurrentAverage>;
  bmr_calories?: Maybe<CurrentAverage>;
  distance?: Maybe<CurrentAverage>;
  netCalories?: Maybe<CurrentAverage>;
  steps?: Maybe<CurrentAverage>;
  zone2Mins?: Maybe<CurrentAverage>;
};

export type ActivityProgressOutput = {
  __typename?: 'ActivityProgressOutput';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AggregatesOutput = {
  __typename?: 'AggregatesOutput';
  User?: Maybe<AllUsersOutput>;
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  medianHR?: Maybe<Scalars['Int']['output']>;
  minutesAwake?: Maybe<Scalars['Int']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Int']['output']>;
  minutesLightSleep?: Maybe<Scalars['Int']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Int']['output']>;
  restlessnessModerate?: Maybe<Scalars['Int']['output']>;
  restlessnessNone?: Maybe<Scalars['Int']['output']>;
  restlessnessSevere?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Int']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Int']['output']>;
  totalTimeInBed?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AllUsersOutput = {
  __typename?: 'AllUsersOutput';
  DNAResults?: Maybe<DnaResultsOutput>;
  UserPreferences?: Maybe<UserPreferencesOutput>;
  activityLevel?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  biostrapId?: Maybe<Scalars['String']['output']>;
  biostrapName?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  ethnicity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  is_onboarded?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mostImportantFitnessGoal?: Maybe<Scalars['String']['output']>;
  pearId?: Maybe<Scalars['String']['output']>;
  pearRefreshToken?: Maybe<Scalars['String']['output']>;
  pearSessionToken?: Maybe<Scalars['String']['output']>;
  pearTokenExpiration?: Maybe<Scalars['String']['output']>;
  pearUserId?: Maybe<Scalars['String']['output']>;
  preferMetric?: Maybe<Scalars['Boolean']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  screen_id?: Maybe<Scalars['String']['output']>;
  screen_name?: Maybe<Scalars['String']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  strapColor?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "AlterTypeColor" */
export type AlterTypeColor = {
  __typename?: 'AlterTypeColor';
  color: Scalars['String']['output'];
  hex: Scalars['String']['output'];
  letter: Scalars['String']['output'];
};

/** aggregated selection of "AlterTypeColor" */
export type AlterTypeColor_Aggregate = {
  __typename?: 'AlterTypeColor_aggregate';
  aggregate?: Maybe<AlterTypeColor_Aggregate_Fields>;
  nodes: Array<AlterTypeColor>;
};

/** aggregate fields of "AlterTypeColor" */
export type AlterTypeColor_Aggregate_Fields = {
  __typename?: 'AlterTypeColor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AlterTypeColor_Max_Fields>;
  min?: Maybe<AlterTypeColor_Min_Fields>;
};


/** aggregate fields of "AlterTypeColor" */
export type AlterTypeColor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AlterTypeColor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "AlterTypeColor". All fields are combined with a logical 'AND'. */
export type AlterTypeColor_Bool_Exp = {
  _and?: InputMaybe<Array<AlterTypeColor_Bool_Exp>>;
  _not?: InputMaybe<AlterTypeColor_Bool_Exp>;
  _or?: InputMaybe<Array<AlterTypeColor_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  hex?: InputMaybe<String_Comparison_Exp>;
  letter?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AlterTypeColor" */
export type AlterTypeColor_Constraint =
  /** unique or primary key constraint on columns "color" */
  | 'AlterTypeColor_color_key'
  /** unique or primary key constraint on columns "hex" */
  | 'AlterTypeColor_hex_key'
  /** unique or primary key constraint on columns "letter" */
  | 'AlterTypeColor_pkey';

/** input type for inserting data into table "AlterTypeColor" */
export type AlterTypeColor_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  hex?: InputMaybe<Scalars['String']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AlterTypeColor_Max_Fields = {
  __typename?: 'AlterTypeColor_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  hex?: Maybe<Scalars['String']['output']>;
  letter?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AlterTypeColor_Min_Fields = {
  __typename?: 'AlterTypeColor_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  hex?: Maybe<Scalars['String']['output']>;
  letter?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "AlterTypeColor" */
export type AlterTypeColor_Mutation_Response = {
  __typename?: 'AlterTypeColor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AlterTypeColor>;
};

/** on_conflict condition type for table "AlterTypeColor" */
export type AlterTypeColor_On_Conflict = {
  constraint: AlterTypeColor_Constraint;
  update_columns?: Array<AlterTypeColor_Update_Column>;
  where?: InputMaybe<AlterTypeColor_Bool_Exp>;
};

/** Ordering options when selecting data from "AlterTypeColor". */
export type AlterTypeColor_Order_By = {
  color?: InputMaybe<Order_By>;
  hex?: InputMaybe<Order_By>;
  letter?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AlterTypeColor */
export type AlterTypeColor_Pk_Columns_Input = {
  letter: Scalars['String']['input'];
};

/** select columns of table "AlterTypeColor" */
export type AlterTypeColor_Select_Column =
  /** column name */
  | 'color'
  /** column name */
  | 'hex'
  /** column name */
  | 'letter';

/** input type for updating data in table "AlterTypeColor" */
export type AlterTypeColor_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  hex?: InputMaybe<Scalars['String']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "AlterTypeColor" */
export type AlterTypeColor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlterTypeColor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlterTypeColor_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  hex?: InputMaybe<Scalars['String']['input']>;
  letter?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "AlterTypeColor" */
export type AlterTypeColor_Update_Column =
  /** column name */
  | 'color'
  /** column name */
  | 'hex'
  /** column name */
  | 'letter';

export type AlterTypeColor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AlterTypeColor_Set_Input>;
  /** filter the rows which have to be updated */
  where: AlterTypeColor_Bool_Exp;
};

export type AssessmentTypeEnum =
  | 'HIGH_KNEE';

/** Boolean expression to compare columns of type "AssessmentType". All fields are combined with logical 'AND'. */
export type AssessmentType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['AssessmentType']['input']>;
  _gt?: InputMaybe<Scalars['AssessmentType']['input']>;
  _gte?: InputMaybe<Scalars['AssessmentType']['input']>;
  _in?: InputMaybe<Array<Scalars['AssessmentType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['AssessmentType']['input']>;
  _lte?: InputMaybe<Scalars['AssessmentType']['input']>;
  _neq?: InputMaybe<Scalars['AssessmentType']['input']>;
  _nin?: InputMaybe<Array<Scalars['AssessmentType']['input']>>;
};

export type AssessmentViewEnum =
  | 'Cardio';

export type BiometricEligibility =
  | 'challange'
  | 'go'
  | 'stop'
  | 'yield';

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "CRUDaction". All fields are combined with logical 'AND'. */
export type CruDaction_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['CRUDaction']['input']>;
  _gt?: InputMaybe<Scalars['CRUDaction']['input']>;
  _gte?: InputMaybe<Scalars['CRUDaction']['input']>;
  _in?: InputMaybe<Array<Scalars['CRUDaction']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['CRUDaction']['input']>;
  _lte?: InputMaybe<Scalars['CRUDaction']['input']>;
  _neq?: InputMaybe<Scalars['CRUDaction']['input']>;
  _nin?: InputMaybe<Array<Scalars['CRUDaction']['input']>>;
};

export type CadenceTypeEnum =
  | 'Exempt'
  | 'Monthly'
  | 'Yearly';

/** Boolean expression to compare columns of type "CadenceType". All fields are combined with logical 'AND'. */
export type CadenceType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['CadenceType']['input']>;
  _gt?: InputMaybe<Scalars['CadenceType']['input']>;
  _gte?: InputMaybe<Scalars['CadenceType']['input']>;
  _in?: InputMaybe<Array<Scalars['CadenceType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['CadenceType']['input']>;
  _lte?: InputMaybe<Scalars['CadenceType']['input']>;
  _neq?: InputMaybe<Scalars['CadenceType']['input']>;
  _nin?: InputMaybe<Array<Scalars['CadenceType']['input']>>;
};

export type CalculateMacroGramsOutput = {
  __typename?: 'CalculateMacroGramsOutput';
  carbs: Scalars['Int']['output'];
  fats: Scalars['Int']['output'];
  proteins: Scalars['Int']['output'];
};

export type CaloricOutput = {
  __typename?: 'CaloricOutput';
  _active_cal?: Maybe<Scalars['Float']['output']>;
  _overall_bmr?: Maybe<Scalars['Float']['output']>;
  _overall_cal?: Maybe<Scalars['Float']['output']>;
  _time?: Maybe<Scalars['String']['output']>;
  _value?: Maybe<Scalars['Float']['output']>;
};

export type ChangeCoachedPlanOutput = {
  __typename?: 'ChangeCoachedPlanOutput';
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  days?: Maybe<Array<Maybe<CustomPlanDay>>>;
  id?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  plan_type?: Maybe<PlanTypeEnum>;
  preset_plan_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type CheckDnaKitOutput = {
  __typename?: 'CheckDNAKitOutput';
  status?: Maybe<DnaKitResult>;
};

export type CheckNutritionReportReleasedOutput = {
  __typename?: 'CheckNutritionReportReleasedOutput';
  nutrition_report_released?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckPresetPlanStructureOutput = {
  __typename?: 'CheckPresetPlanStructureOutput';
  count?: Maybe<Scalars['Int']['output']>;
  current_plan_structure_id?: Maybe<Scalars['String']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  identities?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

/** columns and relationships of "Coach" */
export type Coach = {
  __typename?: 'Coach';
  /** An array relationship */
  CoachedNutritions: Array<CoachedNutrition>;
  /** An aggregate relationship */
  CoachedNutritions_aggregate: CoachedNutrition_Aggregate;
  /** An object relationship */
  User: User;
  /** An array relationship */
  Users: Array<User>;
  /** An aggregate relationship */
  Users_aggregate: User_Aggregate;
  calendly_link?: Maybe<Scalars['String']['output']>;
  calendly_link_thirty_min?: Maybe<Scalars['String']['output']>;
  coach_user_id: Scalars['String']['output'];
  contentful_id: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  sf_coach_id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "Coach" */
export type CoachCoachedNutritionsArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "Coach" */
export type CoachCoachedNutritions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "Coach" */
export type CoachUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Coach" */
export type CoachUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "Coach" */
export type Coach_Aggregate = {
  __typename?: 'Coach_aggregate';
  aggregate?: Maybe<Coach_Aggregate_Fields>;
  nodes: Array<Coach>;
};

export type Coach_Aggregate_Bool_Exp = {
  count?: InputMaybe<Coach_Aggregate_Bool_Exp_Count>;
};

export type Coach_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Coach_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Coach_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Coach" */
export type Coach_Aggregate_Fields = {
  __typename?: 'Coach_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Coach_Max_Fields>;
  min?: Maybe<Coach_Min_Fields>;
};


/** aggregate fields of "Coach" */
export type Coach_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coach_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Coach" */
export type Coach_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Coach_Max_Order_By>;
  min?: InputMaybe<Coach_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Coach" */
export type Coach_Arr_Rel_Insert_Input = {
  data: Array<Coach_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Coach_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Coach". All fields are combined with a logical 'AND'. */
export type Coach_Bool_Exp = {
  CoachedNutritions?: InputMaybe<CoachedNutrition_Bool_Exp>;
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  Users?: InputMaybe<User_Bool_Exp>;
  Users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Coach_Bool_Exp>>;
  _not?: InputMaybe<Coach_Bool_Exp>;
  _or?: InputMaybe<Array<Coach_Bool_Exp>>;
  calendly_link?: InputMaybe<String_Comparison_Exp>;
  calendly_link_thirty_min?: InputMaybe<String_Comparison_Exp>;
  coach_user_id?: InputMaybe<String_Comparison_Exp>;
  contentful_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sendbird_access_token?: InputMaybe<String_Comparison_Exp>;
  sf_coach_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Coach" */
export type Coach_Constraint =
  /** unique or primary key constraint on columns "coach_user_id" */
  | 'Coach_coach_user_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'Coach_pkey';

/** input type for inserting data into table "Coach" */
export type Coach_Insert_Input = {
  CoachedNutritions?: InputMaybe<CoachedNutrition_Arr_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  Users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  calendly_link?: InputMaybe<Scalars['String']['input']>;
  calendly_link_thirty_min?: InputMaybe<Scalars['String']['input']>;
  coach_user_id?: InputMaybe<Scalars['String']['input']>;
  contentful_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sendbird_access_token?: InputMaybe<Scalars['String']['input']>;
  sf_coach_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Coach_Max_Fields = {
  __typename?: 'Coach_max_fields';
  calendly_link?: Maybe<Scalars['String']['output']>;
  calendly_link_thirty_min?: Maybe<Scalars['String']['output']>;
  coach_user_id?: Maybe<Scalars['String']['output']>;
  contentful_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  sf_coach_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "Coach" */
export type Coach_Max_Order_By = {
  calendly_link?: InputMaybe<Order_By>;
  calendly_link_thirty_min?: InputMaybe<Order_By>;
  coach_user_id?: InputMaybe<Order_By>;
  contentful_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sendbird_access_token?: InputMaybe<Order_By>;
  sf_coach_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Coach_Min_Fields = {
  __typename?: 'Coach_min_fields';
  calendly_link?: Maybe<Scalars['String']['output']>;
  calendly_link_thirty_min?: Maybe<Scalars['String']['output']>;
  coach_user_id?: Maybe<Scalars['String']['output']>;
  contentful_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  sf_coach_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "Coach" */
export type Coach_Min_Order_By = {
  calendly_link?: InputMaybe<Order_By>;
  calendly_link_thirty_min?: InputMaybe<Order_By>;
  coach_user_id?: InputMaybe<Order_By>;
  contentful_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sendbird_access_token?: InputMaybe<Order_By>;
  sf_coach_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Coach" */
export type Coach_Mutation_Response = {
  __typename?: 'Coach_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Coach>;
};

/** input type for inserting object relation for remote table "Coach" */
export type Coach_Obj_Rel_Insert_Input = {
  data: Coach_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Coach_On_Conflict>;
};

/** on_conflict condition type for table "Coach" */
export type Coach_On_Conflict = {
  constraint: Coach_Constraint;
  update_columns?: Array<Coach_Update_Column>;
  where?: InputMaybe<Coach_Bool_Exp>;
};

/** Ordering options when selecting data from "Coach". */
export type Coach_Order_By = {
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Order_By>;
  User?: InputMaybe<User_Order_By>;
  Users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  calendly_link?: InputMaybe<Order_By>;
  calendly_link_thirty_min?: InputMaybe<Order_By>;
  coach_user_id?: InputMaybe<Order_By>;
  contentful_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sendbird_access_token?: InputMaybe<Order_By>;
  sf_coach_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Coach */
export type Coach_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Coach" */
export type Coach_Select_Column =
  /** column name */
  | 'calendly_link'
  /** column name */
  | 'calendly_link_thirty_min'
  /** column name */
  | 'coach_user_id'
  /** column name */
  | 'contentful_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'sendbird_access_token'
  /** column name */
  | 'sf_coach_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Coach" */
export type Coach_Set_Input = {
  calendly_link?: InputMaybe<Scalars['String']['input']>;
  calendly_link_thirty_min?: InputMaybe<Scalars['String']['input']>;
  coach_user_id?: InputMaybe<Scalars['String']['input']>;
  contentful_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sendbird_access_token?: InputMaybe<Scalars['String']['input']>;
  sf_coach_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "Coach" */
export type Coach_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coach_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coach_Stream_Cursor_Value_Input = {
  calendly_link?: InputMaybe<Scalars['String']['input']>;
  calendly_link_thirty_min?: InputMaybe<Scalars['String']['input']>;
  coach_user_id?: InputMaybe<Scalars['String']['input']>;
  contentful_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sendbird_access_token?: InputMaybe<Scalars['String']['input']>;
  sf_coach_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "Coach" */
export type Coach_Update_Column =
  /** column name */
  | 'calendly_link'
  /** column name */
  | 'calendly_link_thirty_min'
  /** column name */
  | 'coach_user_id'
  /** column name */
  | 'contentful_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'sendbird_access_token'
  /** column name */
  | 'sf_coach_id'
  /** column name */
  | 'updated_at';

export type Coach_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coach_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coach_Bool_Exp;
};

/** columns and relationships of "CoachedHabit" */
export type CoachedHabit = {
  __typename?: 'CoachedHabit';
  active: Scalars['Boolean']['output'];
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type: Scalars['HabitType']['output'];
  id: Scalars['String']['output'];
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type: Scalars['HabitSubType']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "CoachedHabit" */
export type CoachedHabit_Aggregate = {
  __typename?: 'CoachedHabit_aggregate';
  aggregate?: Maybe<CoachedHabit_Aggregate_Fields>;
  nodes: Array<CoachedHabit>;
};

/** aggregate fields of "CoachedHabit" */
export type CoachedHabit_Aggregate_Fields = {
  __typename?: 'CoachedHabit_aggregate_fields';
  avg?: Maybe<CoachedHabit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CoachedHabit_Max_Fields>;
  min?: Maybe<CoachedHabit_Min_Fields>;
  stddev?: Maybe<CoachedHabit_Stddev_Fields>;
  stddev_pop?: Maybe<CoachedHabit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoachedHabit_Stddev_Samp_Fields>;
  sum?: Maybe<CoachedHabit_Sum_Fields>;
  var_pop?: Maybe<CoachedHabit_Var_Pop_Fields>;
  var_samp?: Maybe<CoachedHabit_Var_Samp_Fields>;
  variance?: Maybe<CoachedHabit_Variance_Fields>;
};


/** aggregate fields of "CoachedHabit" */
export type CoachedHabit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CoachedHabit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CoachedHabit_Avg_Fields = {
  __typename?: 'CoachedHabit_avg_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "CoachedHabit". All fields are combined with a logical 'AND'. */
export type CoachedHabit_Bool_Exp = {
  _and?: InputMaybe<Array<CoachedHabit_Bool_Exp>>;
  _not?: InputMaybe<CoachedHabit_Bool_Exp>;
  _or?: InputMaybe<Array<CoachedHabit_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  coach_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  custom_congrats?: InputMaybe<String_Comparison_Exp>;
  custom_try_again?: InputMaybe<String_Comparison_Exp>;
  days_required?: InputMaybe<Int_Comparison_Exp>;
  goal_value?: InputMaybe<Int_Comparison_Exp>;
  habit_type?: InputMaybe<HabitType_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  long_goal?: InputMaybe<String_Comparison_Exp>;
  max_checkin_time?: InputMaybe<Int_Comparison_Exp>;
  medium_goal?: InputMaybe<String_Comparison_Exp>;
  min_checkin_time?: InputMaybe<Int_Comparison_Exp>;
  short_goal?: InputMaybe<String_Comparison_Exp>;
  sub_type?: InputMaybe<HabitSubType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CoachedHabit" */
export type CoachedHabit_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'CoachedHabit_pkey';

/** input type for incrementing numeric columns in table "CoachedHabit" */
export type CoachedHabit_Inc_Input = {
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "CoachedHabit" */
export type CoachedHabit_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CoachedHabit_Max_Fields = {
  __typename?: 'CoachedHabit_max_fields';
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<Scalars['HabitType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<Scalars['HabitSubType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CoachedHabit_Min_Fields = {
  __typename?: 'CoachedHabit_min_fields';
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<Scalars['HabitType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<Scalars['HabitSubType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "CoachedHabit" */
export type CoachedHabit_Mutation_Response = {
  __typename?: 'CoachedHabit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachedHabit>;
};

/** on_conflict condition type for table "CoachedHabit" */
export type CoachedHabit_On_Conflict = {
  constraint: CoachedHabit_Constraint;
  update_columns?: Array<CoachedHabit_Update_Column>;
  where?: InputMaybe<CoachedHabit_Bool_Exp>;
};

/** Ordering options when selecting data from "CoachedHabit". */
export type CoachedHabit_Order_By = {
  active?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_congrats?: InputMaybe<Order_By>;
  custom_try_again?: InputMaybe<Order_By>;
  days_required?: InputMaybe<Order_By>;
  goal_value?: InputMaybe<Order_By>;
  habit_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  long_goal?: InputMaybe<Order_By>;
  max_checkin_time?: InputMaybe<Order_By>;
  medium_goal?: InputMaybe<Order_By>;
  min_checkin_time?: InputMaybe<Order_By>;
  short_goal?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CoachedHabit */
export type CoachedHabit_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "CoachedHabit" */
export type CoachedHabit_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_congrats'
  /** column name */
  | 'custom_try_again'
  /** column name */
  | 'days_required'
  /** column name */
  | 'goal_value'
  /** column name */
  | 'habit_type'
  /** column name */
  | 'id'
  /** column name */
  | 'long_goal'
  /** column name */
  | 'max_checkin_time'
  /** column name */
  | 'medium_goal'
  /** column name */
  | 'min_checkin_time'
  /** column name */
  | 'short_goal'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "CoachedHabit" */
export type CoachedHabit_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type CoachedHabit_Stddev_Fields = {
  __typename?: 'CoachedHabit_stddev_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type CoachedHabit_Stddev_Pop_Fields = {
  __typename?: 'CoachedHabit_stddev_pop_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type CoachedHabit_Stddev_Samp_Fields = {
  __typename?: 'CoachedHabit_stddev_samp_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "CoachedHabit" */
export type CoachedHabit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CoachedHabit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CoachedHabit_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CoachedHabit_Sum_Fields = {
  __typename?: 'CoachedHabit_sum_fields';
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "CoachedHabit" */
export type CoachedHabit_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_congrats'
  /** column name */
  | 'custom_try_again'
  /** column name */
  | 'days_required'
  /** column name */
  | 'goal_value'
  /** column name */
  | 'habit_type'
  /** column name */
  | 'id'
  /** column name */
  | 'long_goal'
  /** column name */
  | 'max_checkin_time'
  /** column name */
  | 'medium_goal'
  /** column name */
  | 'min_checkin_time'
  /** column name */
  | 'short_goal'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type CoachedHabit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachedHabit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachedHabit_Set_Input>;
  /** filter the rows which have to be updated */
  where: CoachedHabit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CoachedHabit_Var_Pop_Fields = {
  __typename?: 'CoachedHabit_var_pop_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type CoachedHabit_Var_Samp_Fields = {
  __typename?: 'CoachedHabit_var_samp_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CoachedHabit_Variance_Fields = {
  __typename?: 'CoachedHabit_variance_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "CoachedNutrition" */
export type CoachedNutrition = {
  __typename?: 'CoachedNutrition';
  /** An object relationship */
  Coach?: Maybe<Coach>;
  /** An object relationship */
  Goal?: Maybe<Goal>;
  /** An object relationship */
  PresetDiet?: Maybe<PresetDiet>;
  /** An object relationship */
  User: User;
  active: Scalars['Boolean']['output'];
  calorie_deficit?: Maybe<Scalars['Int']['output']>;
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  custom_carbs?: Maybe<Array<Scalars['jsonb']['output']>>;
  custom_fats?: Maybe<Array<Scalars['jsonb']['output']>>;
  custom_proteins?: Maybe<Array<Scalars['jsonb']['output']>>;
  id: Scalars['String']['output'];
  nutrition_goal_id?: Maybe<Scalars['String']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Int']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Int']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Int']['output']>;
  preset_diet_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "CoachedNutrition" */
export type CoachedNutrition_Aggregate = {
  __typename?: 'CoachedNutrition_aggregate';
  aggregate?: Maybe<CoachedNutrition_Aggregate_Fields>;
  nodes: Array<CoachedNutrition>;
};

export type CoachedNutrition_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp_Count>;
};

export type CoachedNutrition_Aggregate_Bool_Exp_Bool_And = {
  arguments: CoachedNutrition_Select_Column_CoachedNutrition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CoachedNutrition_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CoachedNutrition_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CoachedNutrition_Select_Column_CoachedNutrition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CoachedNutrition_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CoachedNutrition_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CoachedNutrition_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CoachedNutrition" */
export type CoachedNutrition_Aggregate_Fields = {
  __typename?: 'CoachedNutrition_aggregate_fields';
  avg?: Maybe<CoachedNutrition_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CoachedNutrition_Max_Fields>;
  min?: Maybe<CoachedNutrition_Min_Fields>;
  stddev?: Maybe<CoachedNutrition_Stddev_Fields>;
  stddev_pop?: Maybe<CoachedNutrition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoachedNutrition_Stddev_Samp_Fields>;
  sum?: Maybe<CoachedNutrition_Sum_Fields>;
  var_pop?: Maybe<CoachedNutrition_Var_Pop_Fields>;
  var_samp?: Maybe<CoachedNutrition_Var_Samp_Fields>;
  variance?: Maybe<CoachedNutrition_Variance_Fields>;
};


/** aggregate fields of "CoachedNutrition" */
export type CoachedNutrition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "CoachedNutrition" */
export type CoachedNutrition_Aggregate_Order_By = {
  avg?: InputMaybe<CoachedNutrition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CoachedNutrition_Max_Order_By>;
  min?: InputMaybe<CoachedNutrition_Min_Order_By>;
  stddev?: InputMaybe<CoachedNutrition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CoachedNutrition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CoachedNutrition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CoachedNutrition_Sum_Order_By>;
  var_pop?: InputMaybe<CoachedNutrition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CoachedNutrition_Var_Samp_Order_By>;
  variance?: InputMaybe<CoachedNutrition_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CoachedNutrition" */
export type CoachedNutrition_Arr_Rel_Insert_Input = {
  data: Array<CoachedNutrition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CoachedNutrition_On_Conflict>;
};

/** aggregate avg on columns */
export type CoachedNutrition_Avg_Fields = {
  __typename?: 'CoachedNutrition_avg_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Avg_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoachedNutrition". All fields are combined with a logical 'AND'. */
export type CoachedNutrition_Bool_Exp = {
  Coach?: InputMaybe<Coach_Bool_Exp>;
  Goal?: InputMaybe<Goal_Bool_Exp>;
  PresetDiet?: InputMaybe<PresetDiet_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<CoachedNutrition_Bool_Exp>>;
  _not?: InputMaybe<CoachedNutrition_Bool_Exp>;
  _or?: InputMaybe<Array<CoachedNutrition_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  calorie_deficit?: InputMaybe<Int_Comparison_Exp>;
  coach_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  custom_carbs?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  custom_fats?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  custom_proteins?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  nutrition_goal_id?: InputMaybe<String_Comparison_Exp>;
  percent_recommended_carbs?: InputMaybe<Int_Comparison_Exp>;
  percent_recommended_fats?: InputMaybe<Int_Comparison_Exp>;
  percent_recommended_proteins?: InputMaybe<Int_Comparison_Exp>;
  preset_diet_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CoachedNutrition" */
export type CoachedNutrition_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'CoachedNutrition_pkey';

/** input type for incrementing numeric columns in table "CoachedNutrition" */
export type CoachedNutrition_Inc_Input = {
  calorie_deficit?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_carbs?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_fats?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_proteins?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "CoachedNutrition" */
export type CoachedNutrition_Insert_Input = {
  Coach?: InputMaybe<Coach_Obj_Rel_Insert_Input>;
  Goal?: InputMaybe<Goal_Obj_Rel_Insert_Input>;
  PresetDiet?: InputMaybe<PresetDiet_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  calorie_deficit?: InputMaybe<Scalars['Int']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_carbs?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  custom_fats?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  custom_proteins?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  nutrition_goal_id?: InputMaybe<Scalars['String']['input']>;
  percent_recommended_carbs?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_fats?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_proteins?: InputMaybe<Scalars['Int']['input']>;
  preset_diet_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CoachedNutrition_Max_Fields = {
  __typename?: 'CoachedNutrition_max_fields';
  calorie_deficit?: Maybe<Scalars['Int']['output']>;
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_carbs?: Maybe<Array<Scalars['jsonb']['output']>>;
  custom_fats?: Maybe<Array<Scalars['jsonb']['output']>>;
  custom_proteins?: Maybe<Array<Scalars['jsonb']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  nutrition_goal_id?: Maybe<Scalars['String']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Int']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Int']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Int']['output']>;
  preset_diet_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Max_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_carbs?: InputMaybe<Order_By>;
  custom_fats?: InputMaybe<Order_By>;
  custom_proteins?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nutrition_goal_id?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
  preset_diet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CoachedNutrition_Min_Fields = {
  __typename?: 'CoachedNutrition_min_fields';
  calorie_deficit?: Maybe<Scalars['Int']['output']>;
  coach_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_carbs?: Maybe<Array<Scalars['jsonb']['output']>>;
  custom_fats?: Maybe<Array<Scalars['jsonb']['output']>>;
  custom_proteins?: Maybe<Array<Scalars['jsonb']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  nutrition_goal_id?: Maybe<Scalars['String']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Int']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Int']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Int']['output']>;
  preset_diet_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Min_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_carbs?: InputMaybe<Order_By>;
  custom_fats?: InputMaybe<Order_By>;
  custom_proteins?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nutrition_goal_id?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
  preset_diet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CoachedNutrition" */
export type CoachedNutrition_Mutation_Response = {
  __typename?: 'CoachedNutrition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CoachedNutrition>;
};

/** on_conflict condition type for table "CoachedNutrition" */
export type CoachedNutrition_On_Conflict = {
  constraint: CoachedNutrition_Constraint;
  update_columns?: Array<CoachedNutrition_Update_Column>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};

/** Ordering options when selecting data from "CoachedNutrition". */
export type CoachedNutrition_Order_By = {
  Coach?: InputMaybe<Coach_Order_By>;
  Goal?: InputMaybe<Goal_Order_By>;
  PresetDiet?: InputMaybe<PresetDiet_Order_By>;
  User?: InputMaybe<User_Order_By>;
  active?: InputMaybe<Order_By>;
  calorie_deficit?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_carbs?: InputMaybe<Order_By>;
  custom_fats?: InputMaybe<Order_By>;
  custom_proteins?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nutrition_goal_id?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
  preset_diet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CoachedNutrition */
export type CoachedNutrition_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "CoachedNutrition" */
export type CoachedNutrition_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'calorie_deficit'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_carbs'
  /** column name */
  | 'custom_fats'
  /** column name */
  | 'custom_proteins'
  /** column name */
  | 'id'
  /** column name */
  | 'nutrition_goal_id'
  /** column name */
  | 'percent_recommended_carbs'
  /** column name */
  | 'percent_recommended_fats'
  /** column name */
  | 'percent_recommended_proteins'
  /** column name */
  | 'preset_diet_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** select "CoachedNutrition_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CoachedNutrition" */
export type CoachedNutrition_Select_Column_CoachedNutrition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'active';

/** select "CoachedNutrition_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CoachedNutrition" */
export type CoachedNutrition_Select_Column_CoachedNutrition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'active';

/** input type for updating data in table "CoachedNutrition" */
export type CoachedNutrition_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  calorie_deficit?: InputMaybe<Scalars['Int']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_carbs?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  custom_fats?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  custom_proteins?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  nutrition_goal_id?: InputMaybe<Scalars['String']['input']>;
  percent_recommended_carbs?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_fats?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_proteins?: InputMaybe<Scalars['Int']['input']>;
  preset_diet_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type CoachedNutrition_Stddev_Fields = {
  __typename?: 'CoachedNutrition_stddev_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Stddev_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoachedNutrition_Stddev_Pop_Fields = {
  __typename?: 'CoachedNutrition_stddev_pop_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Stddev_Pop_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoachedNutrition_Stddev_Samp_Fields = {
  __typename?: 'CoachedNutrition_stddev_samp_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Stddev_Samp_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CoachedNutrition" */
export type CoachedNutrition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CoachedNutrition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CoachedNutrition_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  calorie_deficit?: InputMaybe<Scalars['Int']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_carbs?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  custom_fats?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  custom_proteins?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  nutrition_goal_id?: InputMaybe<Scalars['String']['input']>;
  percent_recommended_carbs?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_fats?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_proteins?: InputMaybe<Scalars['Int']['input']>;
  preset_diet_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CoachedNutrition_Sum_Fields = {
  __typename?: 'CoachedNutrition_sum_fields';
  calorie_deficit?: Maybe<Scalars['Int']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Int']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Int']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Sum_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** update columns of table "CoachedNutrition" */
export type CoachedNutrition_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'calorie_deficit'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_carbs'
  /** column name */
  | 'custom_fats'
  /** column name */
  | 'custom_proteins'
  /** column name */
  | 'id'
  /** column name */
  | 'nutrition_goal_id'
  /** column name */
  | 'percent_recommended_carbs'
  /** column name */
  | 'percent_recommended_fats'
  /** column name */
  | 'percent_recommended_proteins'
  /** column name */
  | 'preset_diet_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type CoachedNutrition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoachedNutrition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachedNutrition_Set_Input>;
  /** filter the rows which have to be updated */
  where: CoachedNutrition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CoachedNutrition_Var_Pop_Fields = {
  __typename?: 'CoachedNutrition_var_pop_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Var_Pop_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoachedNutrition_Var_Samp_Fields = {
  __typename?: 'CoachedNutrition_var_samp_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Var_Samp_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CoachedNutrition_Variance_Fields = {
  __typename?: 'CoachedNutrition_variance_fields';
  calorie_deficit?: Maybe<Scalars['Float']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Float']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Float']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "CoachedNutrition" */
export type CoachedNutrition_Variance_Order_By = {
  calorie_deficit?: InputMaybe<Order_By>;
  percent_recommended_carbs?: InputMaybe<Order_By>;
  percent_recommended_fats?: InputMaybe<Order_By>;
  percent_recommended_proteins?: InputMaybe<Order_By>;
};

export type ConsultationItems =
  | 'DNABasedFitness'
  | 'DNABasedWeightLoss'
  | 'FitnessMyths'
  | 'PersonalizedNutrition'
  | 'StrengthBuilding';

export type CookBookItem = {
  __typename?: 'CookBookItem';
  calories?: Maybe<Scalars['Int']['output']>;
  carbs?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['String']['output']>;
  fats?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  is_fasting?: Maybe<Scalars['Boolean']['output']>;
  is_snack?: Maybe<Scalars['Boolean']['output']>;
  meal_id?: Maybe<Scalars['String']['output']>;
  meal_key: Scalars['String']['output'];
  proteins?: Maybe<Scalars['Int']['output']>;
  recipe?: Maybe<Scalars['json']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['String']['output'];
};

export type CreditHistoryOutput = {
  __typename?: 'CreditHistoryOutput';
  endingMonthBalance: Scalars['Int']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  weekFour: Scalars['Int']['output'];
  weekOne: Scalars['Int']['output'];
  weekThree: Scalars['Int']['output'];
  weekTwo: Scalars['Int']['output'];
};

export type CrudActionEnum =
  | 'create'
  | 'delete'
  | 'read'
  | 'update';

export type CurrentAverage = {
  __typename?: 'CurrentAverage';
  average?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
};

export type CustomPlanDay = {
  __typename?: 'CustomPlanDay';
  ordinal: Scalars['Int']['output'];
  sub_type: WorkoutSubTypeEnum;
  workout_type: WorkoutTypeEnum;
};

export type CustomPlanDayInput = {
  ordinal: Scalars['Int']['input'];
  sub_type: WorkoutSubTypeEnum;
  workout_type: WorkoutTypeEnum;
};

export type CustomPlanInput = {
  days?: InputMaybe<Array<InputMaybe<CustomPlanDayInput>>>;
  days_required: Scalars['Int']['input'];
  plan_type: PlanTypeEnum;
};

/** Boolean expression to compare columns of type "DMLType". All fields are combined with logical 'AND'. */
export type DmlType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DMLType']['input']>;
  _gt?: InputMaybe<Scalars['DMLType']['input']>;
  _gte?: InputMaybe<Scalars['DMLType']['input']>;
  _in?: InputMaybe<Array<Scalars['DMLType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DMLType']['input']>;
  _lte?: InputMaybe<Scalars['DMLType']['input']>;
  _neq?: InputMaybe<Scalars['DMLType']['input']>;
  _nin?: InputMaybe<Array<Scalars['DMLType']['input']>>;
};

/** columns and relationships of "DNAKit" */
export type DnaKit = {
  __typename?: 'DNAKit';
  /** An array relationship */
  DNAResults: Array<DnaResults>;
  /** An aggregate relationship */
  DNAResults_aggregate: DnaResults_Aggregate;
  /** An array relationship */
  GeneticDirectionWebhooks: Array<GeneticDirectionWebhook>;
  /** An aggregate relationship */
  GeneticDirectionWebhooks_aggregate: GeneticDirectionWebhook_Aggregate;
  /** An array relationship */
  QuizSurveys: Array<QuizSurvey>;
  /** An aggregate relationship */
  QuizSurveys_aggregate: QuizSurvey_Aggregate;
  accountId?: Maybe<Scalars['String']['output']>;
  activationCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dropShipOrderId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intended_for: Scalars['DNAKitRole']['output'];
  is_gift?: Maybe<Scalars['Boolean']['output']>;
  is_rekit?: Maybe<Scalars['Boolean']['output']>;
  is_returned?: Maybe<Scalars['Boolean']['output']>;
  kitActivated?: Maybe<Scalars['Boolean']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  latest_status_fedex?: Maybe<Scalars['String']['output']>;
  latest_status_ups?: Maybe<Scalars['String']['output']>;
  latest_status_usps?: Maybe<Scalars['String']['output']>;
  leadId?: Maybe<Scalars['String']['output']>;
  needs_rekit?: Maybe<Scalars['Boolean']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderItemIds?: Maybe<Array<Scalars['String']['output']>>;
  order_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registered_user_id?: Maybe<Scalars['String']['output']>;
  returnTrackingId?: Maybe<Scalars['String']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  verificationCode?: Maybe<Scalars['Int']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "DNAKit" */
export type DnaKitDnaResultsArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


/** columns and relationships of "DNAKit" */
export type DnaKitDnaResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


/** columns and relationships of "DNAKit" */
export type DnaKitGeneticDirectionWebhooksArgs = {
  distinct_on?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeneticDirectionWebhook_Order_By>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


/** columns and relationships of "DNAKit" */
export type DnaKitGeneticDirectionWebhooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeneticDirectionWebhook_Order_By>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


/** columns and relationships of "DNAKit" */
export type DnaKitQuizSurveysArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


/** columns and relationships of "DNAKit" */
export type DnaKitQuizSurveys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};

export type DnaKitResult =
  | 'KitAlreadyRegistered'
  | 'KitAvailable'
  | 'KitNotAvailable';

/** Boolean expression to compare columns of type "DNAKitRole". All fields are combined with logical 'AND'. */
export type DnaKitRole_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DNAKitRole']['input']>;
  _gt?: InputMaybe<Scalars['DNAKitRole']['input']>;
  _gte?: InputMaybe<Scalars['DNAKitRole']['input']>;
  _in?: InputMaybe<Array<Scalars['DNAKitRole']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DNAKitRole']['input']>;
  _lte?: InputMaybe<Scalars['DNAKitRole']['input']>;
  _neq?: InputMaybe<Scalars['DNAKitRole']['input']>;
  _nin?: InputMaybe<Array<Scalars['DNAKitRole']['input']>>;
};

/** aggregated selection of "DNAKit" */
export type DnaKit_Aggregate = {
  __typename?: 'DNAKit_aggregate';
  aggregate?: Maybe<DnaKit_Aggregate_Fields>;
  nodes: Array<DnaKit>;
};

/** aggregate fields of "DNAKit" */
export type DnaKit_Aggregate_Fields = {
  __typename?: 'DNAKit_aggregate_fields';
  avg?: Maybe<DnaKit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DnaKit_Max_Fields>;
  min?: Maybe<DnaKit_Min_Fields>;
  stddev?: Maybe<DnaKit_Stddev_Fields>;
  stddev_pop?: Maybe<DnaKit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DnaKit_Stddev_Samp_Fields>;
  sum?: Maybe<DnaKit_Sum_Fields>;
  var_pop?: Maybe<DnaKit_Var_Pop_Fields>;
  var_samp?: Maybe<DnaKit_Var_Samp_Fields>;
  variance?: Maybe<DnaKit_Variance_Fields>;
};


/** aggregate fields of "DNAKit" */
export type DnaKit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DnaKit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type DnaKit_Avg_Fields = {
  __typename?: 'DNAKit_avg_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "DNAKit". All fields are combined with a logical 'AND'. */
export type DnaKit_Bool_Exp = {
  DNAResults?: InputMaybe<DnaResults_Bool_Exp>;
  DNAResults_aggregate?: InputMaybe<DnaResults_Aggregate_Bool_Exp>;
  GeneticDirectionWebhooks?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
  GeneticDirectionWebhooks_aggregate?: InputMaybe<GeneticDirectionWebhook_Aggregate_Bool_Exp>;
  QuizSurveys?: InputMaybe<QuizSurvey_Bool_Exp>;
  QuizSurveys_aggregate?: InputMaybe<QuizSurvey_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<DnaKit_Bool_Exp>>;
  _not?: InputMaybe<DnaKit_Bool_Exp>;
  _or?: InputMaybe<Array<DnaKit_Bool_Exp>>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  activationCode?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dropShipOrderId?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  fulfilledBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  intended_for?: InputMaybe<DnaKitRole_Comparison_Exp>;
  is_gift?: InputMaybe<Boolean_Comparison_Exp>;
  is_rekit?: InputMaybe<Boolean_Comparison_Exp>;
  is_returned?: InputMaybe<Boolean_Comparison_Exp>;
  kitActivated?: InputMaybe<Boolean_Comparison_Exp>;
  kitId?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  latest_status_fedex?: InputMaybe<String_Comparison_Exp>;
  latest_status_ups?: InputMaybe<String_Comparison_Exp>;
  latest_status_usps?: InputMaybe<String_Comparison_Exp>;
  leadId?: InputMaybe<String_Comparison_Exp>;
  needs_rekit?: InputMaybe<Boolean_Comparison_Exp>;
  opportunityId?: InputMaybe<String_Comparison_Exp>;
  orderId?: InputMaybe<String_Comparison_Exp>;
  orderItemIds?: InputMaybe<String_Array_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  registered_user_id?: InputMaybe<String_Comparison_Exp>;
  returnTrackingId?: InputMaybe<String_Comparison_Exp>;
  shopifyOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  shopify_line_item_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  trackingId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  verificationCode?: InputMaybe<Int_Comparison_Exp>;
  webhookId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DNAKit" */
export type DnaKit_Constraint =
  /** unique or primary key constraint on columns "dropShipOrderId" */
  | 'DNAKit_dropShipOrderId_key'
  /** unique or primary key constraint on columns "kitId" */
  | 'DNAKit_kitId_key'
  /** unique or primary key constraint on columns "id" */
  | 'DNAKit_pkey'
  /** unique or primary key constraint on columns "registered_user_id" */
  | 'DNAKit_registered_user_id_key';

/** input type for incrementing numeric columns in table "DNAKit" */
export type DnaKit_Inc_Input = {
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  verificationCode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "DNAKit" */
export type DnaKit_Insert_Input = {
  DNAResults?: InputMaybe<DnaResults_Arr_Rel_Insert_Input>;
  GeneticDirectionWebhooks?: InputMaybe<GeneticDirectionWebhook_Arr_Rel_Insert_Input>;
  QuizSurveys?: InputMaybe<QuizSurvey_Arr_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intended_for?: InputMaybe<Scalars['DNAKitRole']['input']>;
  is_gift?: InputMaybe<Scalars['Boolean']['input']>;
  is_rekit?: InputMaybe<Scalars['Boolean']['input']>;
  is_returned?: InputMaybe<Scalars['Boolean']['input']>;
  kitActivated?: InputMaybe<Scalars['Boolean']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  latest_status_fedex?: InputMaybe<Scalars['String']['input']>;
  latest_status_ups?: InputMaybe<Scalars['String']['input']>;
  latest_status_usps?: InputMaybe<Scalars['String']['input']>;
  leadId?: InputMaybe<Scalars['String']['input']>;
  needs_rekit?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registered_user_id?: InputMaybe<Scalars['String']['input']>;
  returnTrackingId?: InputMaybe<Scalars['String']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  verificationCode?: InputMaybe<Scalars['Int']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DnaKit_Max_Fields = {
  __typename?: 'DNAKit_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  activationCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dropShipOrderId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intended_for?: Maybe<Scalars['DNAKitRole']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  latest_status_fedex?: Maybe<Scalars['String']['output']>;
  latest_status_ups?: Maybe<Scalars['String']['output']>;
  latest_status_usps?: Maybe<Scalars['String']['output']>;
  leadId?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderItemIds?: Maybe<Array<Scalars['String']['output']>>;
  order_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registered_user_id?: Maybe<Scalars['String']['output']>;
  returnTrackingId?: Maybe<Scalars['String']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  verificationCode?: Maybe<Scalars['Int']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DnaKit_Min_Fields = {
  __typename?: 'DNAKit_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  activationCode?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dropShipOrderId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intended_for?: Maybe<Scalars['DNAKitRole']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  latest_status_fedex?: Maybe<Scalars['String']['output']>;
  latest_status_ups?: Maybe<Scalars['String']['output']>;
  latest_status_usps?: Maybe<Scalars['String']['output']>;
  leadId?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderItemIds?: Maybe<Array<Scalars['String']['output']>>;
  order_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registered_user_id?: Maybe<Scalars['String']['output']>;
  returnTrackingId?: Maybe<Scalars['String']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  verificationCode?: Maybe<Scalars['Int']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "DNAKit" */
export type DnaKit_Mutation_Response = {
  __typename?: 'DNAKit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DnaKit>;
};

/** input type for inserting object relation for remote table "DNAKit" */
export type DnaKit_Obj_Rel_Insert_Input = {
  data: DnaKit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DnaKit_On_Conflict>;
};

/** on_conflict condition type for table "DNAKit" */
export type DnaKit_On_Conflict = {
  constraint: DnaKit_Constraint;
  update_columns?: Array<DnaKit_Update_Column>;
  where?: InputMaybe<DnaKit_Bool_Exp>;
};

/** Ordering options when selecting data from "DNAKit". */
export type DnaKit_Order_By = {
  DNAResults_aggregate?: InputMaybe<DnaResults_Aggregate_Order_By>;
  GeneticDirectionWebhooks_aggregate?: InputMaybe<GeneticDirectionWebhook_Aggregate_Order_By>;
  QuizSurveys_aggregate?: InputMaybe<QuizSurvey_Aggregate_Order_By>;
  accountId?: InputMaybe<Order_By>;
  activationCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dropShipOrderId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  fulfilledBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intended_for?: InputMaybe<Order_By>;
  is_gift?: InputMaybe<Order_By>;
  is_rekit?: InputMaybe<Order_By>;
  is_returned?: InputMaybe<Order_By>;
  kitActivated?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  latest_status_fedex?: InputMaybe<Order_By>;
  latest_status_ups?: InputMaybe<Order_By>;
  latest_status_usps?: InputMaybe<Order_By>;
  leadId?: InputMaybe<Order_By>;
  needs_rekit?: InputMaybe<Order_By>;
  opportunityId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  orderItemIds?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registered_user_id?: InputMaybe<Order_By>;
  returnTrackingId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trackingId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verificationCode?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DNAKit */
export type DnaKit_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "DNAKit" */
export type DnaKit_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'activationCode'
  /** column name */
  | 'created_at'
  /** column name */
  | 'dropShipOrderId'
  /** column name */
  | 'email'
  /** column name */
  | 'first_name'
  /** column name */
  | 'fulfilledBy'
  /** column name */
  | 'id'
  /** column name */
  | 'intended_for'
  /** column name */
  | 'is_gift'
  /** column name */
  | 'is_rekit'
  /** column name */
  | 'is_returned'
  /** column name */
  | 'kitActivated'
  /** column name */
  | 'kitId'
  /** column name */
  | 'last_name'
  /** column name */
  | 'latest_status_fedex'
  /** column name */
  | 'latest_status_ups'
  /** column name */
  | 'latest_status_usps'
  /** column name */
  | 'leadId'
  /** column name */
  | 'needs_rekit'
  /** column name */
  | 'opportunityId'
  /** column name */
  | 'orderId'
  /** column name */
  | 'orderItemIds'
  /** column name */
  | 'order_id'
  /** column name */
  | 'phone'
  /** column name */
  | 'registered_user_id'
  /** column name */
  | 'returnTrackingId'
  /** column name */
  | 'shopifyOrderId'
  /** column name */
  | 'shopify_line_item_id'
  /** column name */
  | 'status'
  /** column name */
  | 'trackingId'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'verificationCode'
  /** column name */
  | 'webhookId';

/** input type for updating data in table "DNAKit" */
export type DnaKit_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intended_for?: InputMaybe<Scalars['DNAKitRole']['input']>;
  is_gift?: InputMaybe<Scalars['Boolean']['input']>;
  is_rekit?: InputMaybe<Scalars['Boolean']['input']>;
  is_returned?: InputMaybe<Scalars['Boolean']['input']>;
  kitActivated?: InputMaybe<Scalars['Boolean']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  latest_status_fedex?: InputMaybe<Scalars['String']['input']>;
  latest_status_ups?: InputMaybe<Scalars['String']['input']>;
  latest_status_usps?: InputMaybe<Scalars['String']['input']>;
  leadId?: InputMaybe<Scalars['String']['input']>;
  needs_rekit?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registered_user_id?: InputMaybe<Scalars['String']['input']>;
  returnTrackingId?: InputMaybe<Scalars['String']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  verificationCode?: InputMaybe<Scalars['Int']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type DnaKit_Stddev_Fields = {
  __typename?: 'DNAKit_stddev_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DnaKit_Stddev_Pop_Fields = {
  __typename?: 'DNAKit_stddev_pop_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DnaKit_Stddev_Samp_Fields = {
  __typename?: 'DNAKit_stddev_samp_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "DNAKit" */
export type DnaKit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DnaKit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DnaKit_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intended_for?: InputMaybe<Scalars['DNAKitRole']['input']>;
  is_gift?: InputMaybe<Scalars['Boolean']['input']>;
  is_rekit?: InputMaybe<Scalars['Boolean']['input']>;
  is_returned?: InputMaybe<Scalars['Boolean']['input']>;
  kitActivated?: InputMaybe<Scalars['Boolean']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  latest_status_fedex?: InputMaybe<Scalars['String']['input']>;
  latest_status_ups?: InputMaybe<Scalars['String']['input']>;
  latest_status_usps?: InputMaybe<Scalars['String']['input']>;
  leadId?: InputMaybe<Scalars['String']['input']>;
  needs_rekit?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registered_user_id?: InputMaybe<Scalars['String']['input']>;
  returnTrackingId?: InputMaybe<Scalars['String']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  verificationCode?: InputMaybe<Scalars['Int']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type DnaKit_Sum_Fields = {
  __typename?: 'DNAKit_sum_fields';
  dropShipOrderId?: Maybe<Scalars['Int']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
  verificationCode?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "DNAKit" */
export type DnaKit_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'activationCode'
  /** column name */
  | 'created_at'
  /** column name */
  | 'dropShipOrderId'
  /** column name */
  | 'email'
  /** column name */
  | 'first_name'
  /** column name */
  | 'fulfilledBy'
  /** column name */
  | 'id'
  /** column name */
  | 'intended_for'
  /** column name */
  | 'is_gift'
  /** column name */
  | 'is_rekit'
  /** column name */
  | 'is_returned'
  /** column name */
  | 'kitActivated'
  /** column name */
  | 'kitId'
  /** column name */
  | 'last_name'
  /** column name */
  | 'latest_status_fedex'
  /** column name */
  | 'latest_status_ups'
  /** column name */
  | 'latest_status_usps'
  /** column name */
  | 'leadId'
  /** column name */
  | 'needs_rekit'
  /** column name */
  | 'opportunityId'
  /** column name */
  | 'orderId'
  /** column name */
  | 'orderItemIds'
  /** column name */
  | 'order_id'
  /** column name */
  | 'phone'
  /** column name */
  | 'registered_user_id'
  /** column name */
  | 'returnTrackingId'
  /** column name */
  | 'shopifyOrderId'
  /** column name */
  | 'shopify_line_item_id'
  /** column name */
  | 'status'
  /** column name */
  | 'trackingId'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'verificationCode'
  /** column name */
  | 'webhookId';

export type DnaKit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DnaKit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DnaKit_Set_Input>;
  /** filter the rows which have to be updated */
  where: DnaKit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DnaKit_Var_Pop_Fields = {
  __typename?: 'DNAKit_var_pop_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DnaKit_Var_Samp_Fields = {
  __typename?: 'DNAKit_var_samp_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DnaKit_Variance_Fields = {
  __typename?: 'DNAKit_variance_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
  verificationCode?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "DNAResults" */
export type DnaResults = {
  __typename?: 'DNAResults';
  /** An object relationship */
  DNAKit?: Maybe<DnaKit>;
  /** An object relationship */
  User?: Maybe<User>;
  alter_kit_id?: Maybe<Scalars['String']['output']>;
  boneMineralDensity?: Maybe<Scalars['String']['output']>;
  carbUtilization?: Maybe<Scalars['String']['output']>;
  chronotype?: Maybe<Scalars['String']['output']>;
  core_type?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  dna_results_received_time?: Maybe<Scalars['timestamp']['output']>;
  fatUtilization?: Maybe<Scalars['String']['output']>;
  first_viewed_time?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['String']['output'];
  injuryRisk?: Maybe<Scalars['String']['output']>;
  intrinsicMotivation?: Maybe<Scalars['String']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  last_viewed_time?: Maybe<Scalars['timestamp']['output']>;
  mentalAcuity?: Maybe<Scalars['String']['output']>;
  num_views?: Maybe<Scalars['Int']['output']>;
  primary_report_url?: Maybe<Scalars['String']['output']>;
  proteinUtilization?: Maybe<Scalars['String']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
  responseToCardio?: Maybe<Scalars['String']['output']>;
  responseToStrength?: Maybe<Scalars['String']['output']>;
  stressTolerance?: Maybe<Scalars['String']['output']>;
  swabId?: Maybe<Scalars['String']['output']>;
  systemicInflammation?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  weightLossTendency?: Maybe<Scalars['String']['output']>;
  weightRegain?: Maybe<Scalars['String']['output']>;
};

export type DnaResultsOutput = {
  __typename?: 'DNAResultsOutput';
  carbUtilization?: Maybe<Scalars['String']['output']>;
  chronotype?: Maybe<Scalars['String']['output']>;
  core_type?: Maybe<Scalars['String']['output']>;
  fatUtilization?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  injuryRisk?: Maybe<Scalars['String']['output']>;
  intrinsicMotivation?: Maybe<Scalars['String']['output']>;
  mentalAcuity?: Maybe<Scalars['String']['output']>;
  proteinUtilization?: Maybe<Scalars['String']['output']>;
  responseToCardio?: Maybe<Scalars['String']['output']>;
  responseToStrength?: Maybe<Scalars['String']['output']>;
  stressTolerance?: Maybe<Scalars['String']['output']>;
  systemicInflammation?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  weightLossTendency?: Maybe<Scalars['String']['output']>;
  weightRegain?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "DNAResults" */
export type DnaResults_Aggregate = {
  __typename?: 'DNAResults_aggregate';
  aggregate?: Maybe<DnaResults_Aggregate_Fields>;
  nodes: Array<DnaResults>;
};

export type DnaResults_Aggregate_Bool_Exp = {
  count?: InputMaybe<DnaResults_Aggregate_Bool_Exp_Count>;
};

export type DnaResults_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DnaResults_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DnaResults_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DNAResults" */
export type DnaResults_Aggregate_Fields = {
  __typename?: 'DNAResults_aggregate_fields';
  avg?: Maybe<DnaResults_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DnaResults_Max_Fields>;
  min?: Maybe<DnaResults_Min_Fields>;
  stddev?: Maybe<DnaResults_Stddev_Fields>;
  stddev_pop?: Maybe<DnaResults_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DnaResults_Stddev_Samp_Fields>;
  sum?: Maybe<DnaResults_Sum_Fields>;
  var_pop?: Maybe<DnaResults_Var_Pop_Fields>;
  var_samp?: Maybe<DnaResults_Var_Samp_Fields>;
  variance?: Maybe<DnaResults_Variance_Fields>;
};


/** aggregate fields of "DNAResults" */
export type DnaResults_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DnaResults_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "DNAResults" */
export type DnaResults_Aggregate_Order_By = {
  avg?: InputMaybe<DnaResults_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DnaResults_Max_Order_By>;
  min?: InputMaybe<DnaResults_Min_Order_By>;
  stddev?: InputMaybe<DnaResults_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DnaResults_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DnaResults_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DnaResults_Sum_Order_By>;
  var_pop?: InputMaybe<DnaResults_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DnaResults_Var_Samp_Order_By>;
  variance?: InputMaybe<DnaResults_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DNAResults" */
export type DnaResults_Arr_Rel_Insert_Input = {
  data: Array<DnaResults_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DnaResults_On_Conflict>;
};

/** aggregate avg on columns */
export type DnaResults_Avg_Fields = {
  __typename?: 'DNAResults_avg_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "DNAResults" */
export type DnaResults_Avg_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DNAResults". All fields are combined with a logical 'AND'. */
export type DnaResults_Bool_Exp = {
  DNAKit?: InputMaybe<DnaKit_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<DnaResults_Bool_Exp>>;
  _not?: InputMaybe<DnaResults_Bool_Exp>;
  _or?: InputMaybe<Array<DnaResults_Bool_Exp>>;
  alter_kit_id?: InputMaybe<String_Comparison_Exp>;
  boneMineralDensity?: InputMaybe<String_Comparison_Exp>;
  carbUtilization?: InputMaybe<String_Comparison_Exp>;
  chronotype?: InputMaybe<String_Comparison_Exp>;
  core_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dna_results_received_time?: InputMaybe<Timestamp_Comparison_Exp>;
  fatUtilization?: InputMaybe<String_Comparison_Exp>;
  first_viewed_time?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  injuryRisk?: InputMaybe<String_Comparison_Exp>;
  intrinsicMotivation?: InputMaybe<String_Comparison_Exp>;
  kitId?: InputMaybe<String_Comparison_Exp>;
  last_viewed_time?: InputMaybe<Timestamp_Comparison_Exp>;
  mentalAcuity?: InputMaybe<String_Comparison_Exp>;
  num_views?: InputMaybe<Int_Comparison_Exp>;
  primary_report_url?: InputMaybe<String_Comparison_Exp>;
  proteinUtilization?: InputMaybe<String_Comparison_Exp>;
  report_id?: InputMaybe<Int_Comparison_Exp>;
  responseToCardio?: InputMaybe<String_Comparison_Exp>;
  responseToStrength?: InputMaybe<String_Comparison_Exp>;
  stressTolerance?: InputMaybe<String_Comparison_Exp>;
  swabId?: InputMaybe<String_Comparison_Exp>;
  systemicInflammation?: InputMaybe<String_Comparison_Exp>;
  trainingPlan?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  weightLossTendency?: InputMaybe<String_Comparison_Exp>;
  weightRegain?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DNAResults" */
export type DnaResults_Constraint =
  /** unique or primary key constraint on columns "alter_kit_id" */
  | 'DNAResults_alter_kit_id_key'
  /** unique or primary key constraint on columns "kitId" */
  | 'DNAResults_kitId_key'
  /** unique or primary key constraint on columns "id" */
  | 'DNAResults_pkey'
  /** unique or primary key constraint on columns "report_id" */
  | 'DNAResults_report_id_key'
  /** unique or primary key constraint on columns "userId" */
  | 'DNAResults_userId_key';

/** input type for incrementing numeric columns in table "DNAResults" */
export type DnaResults_Inc_Input = {
  num_views?: InputMaybe<Scalars['Int']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "DNAResults" */
export type DnaResults_Insert_Input = {
  DNAKit?: InputMaybe<DnaKit_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  alter_kit_id?: InputMaybe<Scalars['String']['input']>;
  boneMineralDensity?: InputMaybe<Scalars['String']['input']>;
  carbUtilization?: InputMaybe<Scalars['String']['input']>;
  chronotype?: InputMaybe<Scalars['String']['input']>;
  core_type?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dna_results_received_time?: InputMaybe<Scalars['timestamp']['input']>;
  fatUtilization?: InputMaybe<Scalars['String']['input']>;
  first_viewed_time?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  injuryRisk?: InputMaybe<Scalars['String']['input']>;
  intrinsicMotivation?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  last_viewed_time?: InputMaybe<Scalars['timestamp']['input']>;
  mentalAcuity?: InputMaybe<Scalars['String']['input']>;
  num_views?: InputMaybe<Scalars['Int']['input']>;
  primary_report_url?: InputMaybe<Scalars['String']['input']>;
  proteinUtilization?: InputMaybe<Scalars['String']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  responseToCardio?: InputMaybe<Scalars['String']['input']>;
  responseToStrength?: InputMaybe<Scalars['String']['input']>;
  stressTolerance?: InputMaybe<Scalars['String']['input']>;
  swabId?: InputMaybe<Scalars['String']['input']>;
  systemicInflammation?: InputMaybe<Scalars['String']['input']>;
  trainingPlan?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  weightLossTendency?: InputMaybe<Scalars['String']['input']>;
  weightRegain?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DnaResults_Max_Fields = {
  __typename?: 'DNAResults_max_fields';
  alter_kit_id?: Maybe<Scalars['String']['output']>;
  boneMineralDensity?: Maybe<Scalars['String']['output']>;
  carbUtilization?: Maybe<Scalars['String']['output']>;
  chronotype?: Maybe<Scalars['String']['output']>;
  core_type?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dna_results_received_time?: Maybe<Scalars['timestamp']['output']>;
  fatUtilization?: Maybe<Scalars['String']['output']>;
  first_viewed_time?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  injuryRisk?: Maybe<Scalars['String']['output']>;
  intrinsicMotivation?: Maybe<Scalars['String']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  last_viewed_time?: Maybe<Scalars['timestamp']['output']>;
  mentalAcuity?: Maybe<Scalars['String']['output']>;
  num_views?: Maybe<Scalars['Int']['output']>;
  primary_report_url?: Maybe<Scalars['String']['output']>;
  proteinUtilization?: Maybe<Scalars['String']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
  responseToCardio?: Maybe<Scalars['String']['output']>;
  responseToStrength?: Maybe<Scalars['String']['output']>;
  stressTolerance?: Maybe<Scalars['String']['output']>;
  swabId?: Maybe<Scalars['String']['output']>;
  systemicInflammation?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  weightLossTendency?: Maybe<Scalars['String']['output']>;
  weightRegain?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "DNAResults" */
export type DnaResults_Max_Order_By = {
  alter_kit_id?: InputMaybe<Order_By>;
  boneMineralDensity?: InputMaybe<Order_By>;
  carbUtilization?: InputMaybe<Order_By>;
  chronotype?: InputMaybe<Order_By>;
  core_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dna_results_received_time?: InputMaybe<Order_By>;
  fatUtilization?: InputMaybe<Order_By>;
  first_viewed_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  injuryRisk?: InputMaybe<Order_By>;
  intrinsicMotivation?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  last_viewed_time?: InputMaybe<Order_By>;
  mentalAcuity?: InputMaybe<Order_By>;
  num_views?: InputMaybe<Order_By>;
  primary_report_url?: InputMaybe<Order_By>;
  proteinUtilization?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
  responseToCardio?: InputMaybe<Order_By>;
  responseToStrength?: InputMaybe<Order_By>;
  stressTolerance?: InputMaybe<Order_By>;
  swabId?: InputMaybe<Order_By>;
  systemicInflammation?: InputMaybe<Order_By>;
  trainingPlan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  weightLossTendency?: InputMaybe<Order_By>;
  weightRegain?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DnaResults_Min_Fields = {
  __typename?: 'DNAResults_min_fields';
  alter_kit_id?: Maybe<Scalars['String']['output']>;
  boneMineralDensity?: Maybe<Scalars['String']['output']>;
  carbUtilization?: Maybe<Scalars['String']['output']>;
  chronotype?: Maybe<Scalars['String']['output']>;
  core_type?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dna_results_received_time?: Maybe<Scalars['timestamp']['output']>;
  fatUtilization?: Maybe<Scalars['String']['output']>;
  first_viewed_time?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  injuryRisk?: Maybe<Scalars['String']['output']>;
  intrinsicMotivation?: Maybe<Scalars['String']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  last_viewed_time?: Maybe<Scalars['timestamp']['output']>;
  mentalAcuity?: Maybe<Scalars['String']['output']>;
  num_views?: Maybe<Scalars['Int']['output']>;
  primary_report_url?: Maybe<Scalars['String']['output']>;
  proteinUtilization?: Maybe<Scalars['String']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
  responseToCardio?: Maybe<Scalars['String']['output']>;
  responseToStrength?: Maybe<Scalars['String']['output']>;
  stressTolerance?: Maybe<Scalars['String']['output']>;
  swabId?: Maybe<Scalars['String']['output']>;
  systemicInflammation?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  weightLossTendency?: Maybe<Scalars['String']['output']>;
  weightRegain?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "DNAResults" */
export type DnaResults_Min_Order_By = {
  alter_kit_id?: InputMaybe<Order_By>;
  boneMineralDensity?: InputMaybe<Order_By>;
  carbUtilization?: InputMaybe<Order_By>;
  chronotype?: InputMaybe<Order_By>;
  core_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dna_results_received_time?: InputMaybe<Order_By>;
  fatUtilization?: InputMaybe<Order_By>;
  first_viewed_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  injuryRisk?: InputMaybe<Order_By>;
  intrinsicMotivation?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  last_viewed_time?: InputMaybe<Order_By>;
  mentalAcuity?: InputMaybe<Order_By>;
  num_views?: InputMaybe<Order_By>;
  primary_report_url?: InputMaybe<Order_By>;
  proteinUtilization?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
  responseToCardio?: InputMaybe<Order_By>;
  responseToStrength?: InputMaybe<Order_By>;
  stressTolerance?: InputMaybe<Order_By>;
  swabId?: InputMaybe<Order_By>;
  systemicInflammation?: InputMaybe<Order_By>;
  trainingPlan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  weightLossTendency?: InputMaybe<Order_By>;
  weightRegain?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DNAResults" */
export type DnaResults_Mutation_Response = {
  __typename?: 'DNAResults_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DnaResults>;
};

/** on_conflict condition type for table "DNAResults" */
export type DnaResults_On_Conflict = {
  constraint: DnaResults_Constraint;
  update_columns?: Array<DnaResults_Update_Column>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};

/** Ordering options when selecting data from "DNAResults". */
export type DnaResults_Order_By = {
  DNAKit?: InputMaybe<DnaKit_Order_By>;
  User?: InputMaybe<User_Order_By>;
  alter_kit_id?: InputMaybe<Order_By>;
  boneMineralDensity?: InputMaybe<Order_By>;
  carbUtilization?: InputMaybe<Order_By>;
  chronotype?: InputMaybe<Order_By>;
  core_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dna_results_received_time?: InputMaybe<Order_By>;
  fatUtilization?: InputMaybe<Order_By>;
  first_viewed_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  injuryRisk?: InputMaybe<Order_By>;
  intrinsicMotivation?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  last_viewed_time?: InputMaybe<Order_By>;
  mentalAcuity?: InputMaybe<Order_By>;
  num_views?: InputMaybe<Order_By>;
  primary_report_url?: InputMaybe<Order_By>;
  proteinUtilization?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
  responseToCardio?: InputMaybe<Order_By>;
  responseToStrength?: InputMaybe<Order_By>;
  stressTolerance?: InputMaybe<Order_By>;
  swabId?: InputMaybe<Order_By>;
  systemicInflammation?: InputMaybe<Order_By>;
  trainingPlan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  weightLossTendency?: InputMaybe<Order_By>;
  weightRegain?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DNAResults */
export type DnaResults_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "DNAResults" */
export type DnaResults_Select_Column =
  /** column name */
  | 'alter_kit_id'
  /** column name */
  | 'boneMineralDensity'
  /** column name */
  | 'carbUtilization'
  /** column name */
  | 'chronotype'
  /** column name */
  | 'core_type'
  /** column name */
  | 'created_at'
  /** column name */
  | 'dna_results_received_time'
  /** column name */
  | 'fatUtilization'
  /** column name */
  | 'first_viewed_time'
  /** column name */
  | 'id'
  /** column name */
  | 'injuryRisk'
  /** column name */
  | 'intrinsicMotivation'
  /** column name */
  | 'kitId'
  /** column name */
  | 'last_viewed_time'
  /** column name */
  | 'mentalAcuity'
  /** column name */
  | 'num_views'
  /** column name */
  | 'primary_report_url'
  /** column name */
  | 'proteinUtilization'
  /** column name */
  | 'report_id'
  /** column name */
  | 'responseToCardio'
  /** column name */
  | 'responseToStrength'
  /** column name */
  | 'stressTolerance'
  /** column name */
  | 'swabId'
  /** column name */
  | 'systemicInflammation'
  /** column name */
  | 'trainingPlan'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'weightLossTendency'
  /** column name */
  | 'weightRegain';

/** input type for updating data in table "DNAResults" */
export type DnaResults_Set_Input = {
  alter_kit_id?: InputMaybe<Scalars['String']['input']>;
  boneMineralDensity?: InputMaybe<Scalars['String']['input']>;
  carbUtilization?: InputMaybe<Scalars['String']['input']>;
  chronotype?: InputMaybe<Scalars['String']['input']>;
  core_type?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dna_results_received_time?: InputMaybe<Scalars['timestamp']['input']>;
  fatUtilization?: InputMaybe<Scalars['String']['input']>;
  first_viewed_time?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  injuryRisk?: InputMaybe<Scalars['String']['input']>;
  intrinsicMotivation?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  last_viewed_time?: InputMaybe<Scalars['timestamp']['input']>;
  mentalAcuity?: InputMaybe<Scalars['String']['input']>;
  num_views?: InputMaybe<Scalars['Int']['input']>;
  primary_report_url?: InputMaybe<Scalars['String']['input']>;
  proteinUtilization?: InputMaybe<Scalars['String']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  responseToCardio?: InputMaybe<Scalars['String']['input']>;
  responseToStrength?: InputMaybe<Scalars['String']['input']>;
  stressTolerance?: InputMaybe<Scalars['String']['input']>;
  swabId?: InputMaybe<Scalars['String']['input']>;
  systemicInflammation?: InputMaybe<Scalars['String']['input']>;
  trainingPlan?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  weightLossTendency?: InputMaybe<Scalars['String']['input']>;
  weightRegain?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type DnaResults_Stddev_Fields = {
  __typename?: 'DNAResults_stddev_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "DNAResults" */
export type DnaResults_Stddev_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DnaResults_Stddev_Pop_Fields = {
  __typename?: 'DNAResults_stddev_pop_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "DNAResults" */
export type DnaResults_Stddev_Pop_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DnaResults_Stddev_Samp_Fields = {
  __typename?: 'DNAResults_stddev_samp_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "DNAResults" */
export type DnaResults_Stddev_Samp_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DNAResults" */
export type DnaResults_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DnaResults_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DnaResults_Stream_Cursor_Value_Input = {
  alter_kit_id?: InputMaybe<Scalars['String']['input']>;
  boneMineralDensity?: InputMaybe<Scalars['String']['input']>;
  carbUtilization?: InputMaybe<Scalars['String']['input']>;
  chronotype?: InputMaybe<Scalars['String']['input']>;
  core_type?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dna_results_received_time?: InputMaybe<Scalars['timestamp']['input']>;
  fatUtilization?: InputMaybe<Scalars['String']['input']>;
  first_viewed_time?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  injuryRisk?: InputMaybe<Scalars['String']['input']>;
  intrinsicMotivation?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  last_viewed_time?: InputMaybe<Scalars['timestamp']['input']>;
  mentalAcuity?: InputMaybe<Scalars['String']['input']>;
  num_views?: InputMaybe<Scalars['Int']['input']>;
  primary_report_url?: InputMaybe<Scalars['String']['input']>;
  proteinUtilization?: InputMaybe<Scalars['String']['input']>;
  report_id?: InputMaybe<Scalars['Int']['input']>;
  responseToCardio?: InputMaybe<Scalars['String']['input']>;
  responseToStrength?: InputMaybe<Scalars['String']['input']>;
  stressTolerance?: InputMaybe<Scalars['String']['input']>;
  swabId?: InputMaybe<Scalars['String']['input']>;
  systemicInflammation?: InputMaybe<Scalars['String']['input']>;
  trainingPlan?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  weightLossTendency?: InputMaybe<Scalars['String']['input']>;
  weightRegain?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type DnaResults_Sum_Fields = {
  __typename?: 'DNAResults_sum_fields';
  num_views?: Maybe<Scalars['Int']['output']>;
  report_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "DNAResults" */
export type DnaResults_Sum_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** update columns of table "DNAResults" */
export type DnaResults_Update_Column =
  /** column name */
  | 'alter_kit_id'
  /** column name */
  | 'boneMineralDensity'
  /** column name */
  | 'carbUtilization'
  /** column name */
  | 'chronotype'
  /** column name */
  | 'core_type'
  /** column name */
  | 'created_at'
  /** column name */
  | 'dna_results_received_time'
  /** column name */
  | 'fatUtilization'
  /** column name */
  | 'first_viewed_time'
  /** column name */
  | 'id'
  /** column name */
  | 'injuryRisk'
  /** column name */
  | 'intrinsicMotivation'
  /** column name */
  | 'kitId'
  /** column name */
  | 'last_viewed_time'
  /** column name */
  | 'mentalAcuity'
  /** column name */
  | 'num_views'
  /** column name */
  | 'primary_report_url'
  /** column name */
  | 'proteinUtilization'
  /** column name */
  | 'report_id'
  /** column name */
  | 'responseToCardio'
  /** column name */
  | 'responseToStrength'
  /** column name */
  | 'stressTolerance'
  /** column name */
  | 'swabId'
  /** column name */
  | 'systemicInflammation'
  /** column name */
  | 'trainingPlan'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'weightLossTendency'
  /** column name */
  | 'weightRegain';

export type DnaResults_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DnaResults_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DnaResults_Set_Input>;
  /** filter the rows which have to be updated */
  where: DnaResults_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DnaResults_Var_Pop_Fields = {
  __typename?: 'DNAResults_var_pop_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "DNAResults" */
export type DnaResults_Var_Pop_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DnaResults_Var_Samp_Fields = {
  __typename?: 'DNAResults_var_samp_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "DNAResults" */
export type DnaResults_Var_Samp_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DnaResults_Variance_Fields = {
  __typename?: 'DNAResults_variance_fields';
  num_views?: Maybe<Scalars['Float']['output']>;
  report_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "DNAResults" */
export type DnaResults_Variance_Order_By = {
  num_views?: InputMaybe<Order_By>;
  report_id?: InputMaybe<Order_By>;
};

export type DailyCheckinOutput = {
  __typename?: 'DailyCheckinOutput';
  biometricEligibility?: Maybe<BiometricEligibility>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  chronotype?: Maybe<Scalars['String']['output']>;
  latest_history?: Maybe<Array<Maybe<WorkIoWithEpoch>>>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  sleep_history?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  sleep_is_override?: Maybe<Scalars['Boolean']['output']>;
  sleep_start?: Maybe<Scalars['Int']['output']>;
  sleep_stop?: Maybe<Scalars['Int']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  strength_workouts_done_at_level?: Maybe<Scalars['Int']['output']>;
  strength_workouts_to_advance?: Maybe<Scalars['Int']['output']>;
  stress?: Maybe<Scalars['String']['output']>;
  todays_recommendation?: Maybe<Scalars['json']['output']>;
};

export type DailyCheckinV2MasteryObject = {
  __typename?: 'DailyCheckinV2MasteryObject';
  cardio_level?: Maybe<Scalars['Int']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  strength_workouts_done_at_level?: Maybe<Scalars['Int']['output']>;
  strength_workouts_to_advance?: Maybe<Scalars['Int']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
};

export type DailyCheckinV2Output = {
  __typename?: 'DailyCheckinV2Output';
  cardio_group?: Maybe<RecommenderGeneralRankGroup>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  chronotype?: Maybe<Scalars['String']['output']>;
  mastery?: Maybe<DailyCheckinV2MasteryObject>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  sleep?: Maybe<DailyCheckinV2SleepObject>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stress?: Maybe<Scalars['String']['output']>;
  todays_recommendation?: Maybe<Scalars['json']['output']>;
  workin_group?: Maybe<RecommenderGeneralRankGroup>;
  workin_level?: Maybe<Scalars['Int']['output']>;
};

export type DailyCheckinV2SleepObject = {
  __typename?: 'DailyCheckinV2SleepObject';
  biometricEligibility?: Maybe<BiometricEligibility>;
  date?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  is_full_override?: Maybe<Scalars['Boolean']['output']>;
  is_partial_override?: Maybe<Scalars['Boolean']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Int']['output']>;
  stop?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "Delivery" */
export type Delivery = {
  __typename?: 'Delivery';
  /** An array relationship */
  DeliveryHistories: Array<DeliveryHistory>;
  /** An aggregate relationship */
  DeliveryHistories_aggregate: DeliveryHistory_Aggregate;
  /** An object relationship */
  UserProduct?: Maybe<UserProduct>;
  created_at: Scalars['timestamp']['output'];
  delivery_date?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['String']['output'];
  is_delivered: Scalars['Boolean']['output'];
  is_shipped: Scalars['Boolean']['output'];
  order_line_item_id: Scalars['String']['output'];
  purchase_date?: Maybe<Scalars['timestamp']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
  secondary_tracking_numbers?: Maybe<Array<Scalars['String']['output']>>;
  secondary_tracking_urls?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['DeliveryStatus']['output']>;
  tracking_info?: Maybe<Scalars['String']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
  tracking_url?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_product_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "Delivery" */
export type DeliveryDeliveryHistoriesArgs = {
  distinct_on?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryHistory_Order_By>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};


/** columns and relationships of "Delivery" */
export type DeliveryDeliveryHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryHistory_Order_By>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};

/** columns and relationships of "DeliveryHistory" */
export type DeliveryHistory = {
  __typename?: 'DeliveryHistory';
  /** An object relationship */
  Delivery: Delivery;
  /** An object relationship */
  UserProduct?: Maybe<UserProduct>;
  created_at: Scalars['timestamp']['output'];
  delivery_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  purchase_id: Scalars['String']['output'];
  status: Scalars['DeliveryStatus']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "DeliveryHistory" */
export type DeliveryHistory_Aggregate = {
  __typename?: 'DeliveryHistory_aggregate';
  aggregate?: Maybe<DeliveryHistory_Aggregate_Fields>;
  nodes: Array<DeliveryHistory>;
};

export type DeliveryHistory_Aggregate_Bool_Exp = {
  count?: InputMaybe<DeliveryHistory_Aggregate_Bool_Exp_Count>;
};

export type DeliveryHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DeliveryHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DeliveryHistory" */
export type DeliveryHistory_Aggregate_Fields = {
  __typename?: 'DeliveryHistory_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DeliveryHistory_Max_Fields>;
  min?: Maybe<DeliveryHistory_Min_Fields>;
};


/** aggregate fields of "DeliveryHistory" */
export type DeliveryHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "DeliveryHistory" */
export type DeliveryHistory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DeliveryHistory_Max_Order_By>;
  min?: InputMaybe<DeliveryHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "DeliveryHistory" */
export type DeliveryHistory_Arr_Rel_Insert_Input = {
  data: Array<DeliveryHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DeliveryHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "DeliveryHistory". All fields are combined with a logical 'AND'. */
export type DeliveryHistory_Bool_Exp = {
  Delivery?: InputMaybe<Delivery_Bool_Exp>;
  UserProduct?: InputMaybe<UserProduct_Bool_Exp>;
  _and?: InputMaybe<Array<DeliveryHistory_Bool_Exp>>;
  _not?: InputMaybe<DeliveryHistory_Bool_Exp>;
  _or?: InputMaybe<Array<DeliveryHistory_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivery_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  purchase_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<DeliveryStatus_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_product_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DeliveryHistory" */
export type DeliveryHistory_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'DeliveryHistory_pkey';

/** input type for inserting data into table "DeliveryHistory" */
export type DeliveryHistory_Insert_Input = {
  Delivery?: InputMaybe<Delivery_Obj_Rel_Insert_Input>;
  UserProduct?: InputMaybe<UserProduct_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DeliveryHistory_Max_Fields = {
  __typename?: 'DeliveryHistory_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  delivery_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['DeliveryStatus']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "DeliveryHistory" */
export type DeliveryHistory_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DeliveryHistory_Min_Fields = {
  __typename?: 'DeliveryHistory_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  delivery_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['DeliveryStatus']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "DeliveryHistory" */
export type DeliveryHistory_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DeliveryHistory" */
export type DeliveryHistory_Mutation_Response = {
  __typename?: 'DeliveryHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DeliveryHistory>;
};

/** on_conflict condition type for table "DeliveryHistory" */
export type DeliveryHistory_On_Conflict = {
  constraint: DeliveryHistory_Constraint;
  update_columns?: Array<DeliveryHistory_Update_Column>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "DeliveryHistory". */
export type DeliveryHistory_Order_By = {
  Delivery?: InputMaybe<Delivery_Order_By>;
  UserProduct?: InputMaybe<UserProduct_Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DeliveryHistory */
export type DeliveryHistory_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "DeliveryHistory" */
export type DeliveryHistory_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'delivery_id'
  /** column name */
  | 'id'
  /** column name */
  | 'purchase_id'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_product_id';

/** input type for updating data in table "DeliveryHistory" */
export type DeliveryHistory_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "DeliveryHistory" */
export type DeliveryHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DeliveryHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DeliveryHistory_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "DeliveryHistory" */
export type DeliveryHistory_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'delivery_id'
  /** column name */
  | 'id'
  /** column name */
  | 'purchase_id'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_product_id';

export type DeliveryHistory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeliveryHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: DeliveryHistory_Bool_Exp;
};

/** Boolean expression to compare columns of type "DeliveryStatus". All fields are combined with logical 'AND'. */
export type DeliveryStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  _gt?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  _gte?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['DeliveryStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  _lte?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  _neq?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['DeliveryStatus']['input']>>;
};

/** aggregated selection of "Delivery" */
export type Delivery_Aggregate = {
  __typename?: 'Delivery_aggregate';
  aggregate?: Maybe<Delivery_Aggregate_Fields>;
  nodes: Array<Delivery>;
};

export type Delivery_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Delivery_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Delivery_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Delivery_Aggregate_Bool_Exp_Count>;
};

export type Delivery_Aggregate_Bool_Exp_Bool_And = {
  arguments: Delivery_Select_Column_Delivery_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Delivery_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Delivery_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Delivery_Select_Column_Delivery_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Delivery_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Delivery_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Delivery_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Delivery_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Delivery" */
export type Delivery_Aggregate_Fields = {
  __typename?: 'Delivery_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Delivery_Max_Fields>;
  min?: Maybe<Delivery_Min_Fields>;
};


/** aggregate fields of "Delivery" */
export type Delivery_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delivery_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Delivery" */
export type Delivery_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Delivery_Max_Order_By>;
  min?: InputMaybe<Delivery_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Delivery" */
export type Delivery_Arr_Rel_Insert_Input = {
  data: Array<Delivery_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Delivery_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Delivery". All fields are combined with a logical 'AND'. */
export type Delivery_Bool_Exp = {
  DeliveryHistories?: InputMaybe<DeliveryHistory_Bool_Exp>;
  DeliveryHistories_aggregate?: InputMaybe<DeliveryHistory_Aggregate_Bool_Exp>;
  UserProduct?: InputMaybe<UserProduct_Bool_Exp>;
  _and?: InputMaybe<Array<Delivery_Bool_Exp>>;
  _not?: InputMaybe<Delivery_Bool_Exp>;
  _or?: InputMaybe<Array<Delivery_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivery_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_delivered?: InputMaybe<Boolean_Comparison_Exp>;
  is_shipped?: InputMaybe<Boolean_Comparison_Exp>;
  order_line_item_id?: InputMaybe<String_Comparison_Exp>;
  purchase_date?: InputMaybe<Timestamp_Comparison_Exp>;
  purchase_id?: InputMaybe<String_Comparison_Exp>;
  secondary_tracking_numbers?: InputMaybe<String_Array_Comparison_Exp>;
  secondary_tracking_urls?: InputMaybe<String_Array_Comparison_Exp>;
  status?: InputMaybe<DeliveryStatus_Comparison_Exp>;
  tracking_info?: InputMaybe<String_Comparison_Exp>;
  tracking_number?: InputMaybe<String_Comparison_Exp>;
  tracking_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_product_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Delivery" */
export type Delivery_Constraint =
  /** unique or primary key constraint on columns "order_line_item_id" */
  | 'Delivery_order_line_item_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'Delivery_pkey'
  /** unique or primary key constraint on columns "user_product_id" */
  | 'Delivery_user_product_id_key';

/** input type for inserting data into table "Delivery" */
export type Delivery_Insert_Input = {
  DeliveryHistories?: InputMaybe<DeliveryHistory_Arr_Rel_Insert_Input>;
  UserProduct?: InputMaybe<UserProduct_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_delivered?: InputMaybe<Scalars['Boolean']['input']>;
  is_shipped?: InputMaybe<Scalars['Boolean']['input']>;
  order_line_item_id?: InputMaybe<Scalars['String']['input']>;
  purchase_date?: InputMaybe<Scalars['timestamp']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  secondary_tracking_numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  secondary_tracking_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  tracking_info?: InputMaybe<Scalars['String']['input']>;
  tracking_number?: InputMaybe<Scalars['String']['input']>;
  tracking_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Delivery_Max_Fields = {
  __typename?: 'Delivery_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  delivery_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_line_item_id?: Maybe<Scalars['String']['output']>;
  purchase_date?: Maybe<Scalars['timestamp']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
  secondary_tracking_numbers?: Maybe<Array<Scalars['String']['output']>>;
  secondary_tracking_urls?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['DeliveryStatus']['output']>;
  tracking_info?: Maybe<Scalars['String']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
  tracking_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Delivery" */
export type Delivery_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  purchase_date?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  secondary_tracking_numbers?: InputMaybe<Order_By>;
  secondary_tracking_urls?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracking_info?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Delivery_Min_Fields = {
  __typename?: 'Delivery_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  delivery_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_line_item_id?: Maybe<Scalars['String']['output']>;
  purchase_date?: Maybe<Scalars['timestamp']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
  secondary_tracking_numbers?: Maybe<Array<Scalars['String']['output']>>;
  secondary_tracking_urls?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['DeliveryStatus']['output']>;
  tracking_info?: Maybe<Scalars['String']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
  tracking_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Delivery" */
export type Delivery_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  purchase_date?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  secondary_tracking_numbers?: InputMaybe<Order_By>;
  secondary_tracking_urls?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracking_info?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Delivery" */
export type Delivery_Mutation_Response = {
  __typename?: 'Delivery_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Delivery>;
};

/** input type for inserting object relation for remote table "Delivery" */
export type Delivery_Obj_Rel_Insert_Input = {
  data: Delivery_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Delivery_On_Conflict>;
};

/** on_conflict condition type for table "Delivery" */
export type Delivery_On_Conflict = {
  constraint: Delivery_Constraint;
  update_columns?: Array<Delivery_Update_Column>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};

/** Ordering options when selecting data from "Delivery". */
export type Delivery_Order_By = {
  DeliveryHistories_aggregate?: InputMaybe<DeliveryHistory_Aggregate_Order_By>;
  UserProduct?: InputMaybe<UserProduct_Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_delivered?: InputMaybe<Order_By>;
  is_shipped?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  purchase_date?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  secondary_tracking_numbers?: InputMaybe<Order_By>;
  secondary_tracking_urls?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracking_info?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Delivery */
export type Delivery_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Delivery" */
export type Delivery_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'delivery_date'
  /** column name */
  | 'id'
  /** column name */
  | 'is_delivered'
  /** column name */
  | 'is_shipped'
  /** column name */
  | 'order_line_item_id'
  /** column name */
  | 'purchase_date'
  /** column name */
  | 'purchase_id'
  /** column name */
  | 'secondary_tracking_numbers'
  /** column name */
  | 'secondary_tracking_urls'
  /** column name */
  | 'status'
  /** column name */
  | 'tracking_info'
  /** column name */
  | 'tracking_number'
  /** column name */
  | 'tracking_url'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_product_id';

/** select "Delivery_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Delivery" */
export type Delivery_Select_Column_Delivery_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'is_delivered'
  /** column name */
  | 'is_shipped';

/** select "Delivery_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Delivery" */
export type Delivery_Select_Column_Delivery_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'is_delivered'
  /** column name */
  | 'is_shipped';

/** input type for updating data in table "Delivery" */
export type Delivery_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_delivered?: InputMaybe<Scalars['Boolean']['input']>;
  is_shipped?: InputMaybe<Scalars['Boolean']['input']>;
  order_line_item_id?: InputMaybe<Scalars['String']['input']>;
  purchase_date?: InputMaybe<Scalars['timestamp']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  secondary_tracking_numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  secondary_tracking_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  tracking_info?: InputMaybe<Scalars['String']['input']>;
  tracking_number?: InputMaybe<Scalars['String']['input']>;
  tracking_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Delivery" */
export type Delivery_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delivery_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delivery_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_delivered?: InputMaybe<Scalars['Boolean']['input']>;
  is_shipped?: InputMaybe<Scalars['Boolean']['input']>;
  order_line_item_id?: InputMaybe<Scalars['String']['input']>;
  purchase_date?: InputMaybe<Scalars['timestamp']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  secondary_tracking_numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  secondary_tracking_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['DeliveryStatus']['input']>;
  tracking_info?: InputMaybe<Scalars['String']['input']>;
  tracking_number?: InputMaybe<Scalars['String']['input']>;
  tracking_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Delivery" */
export type Delivery_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'delivery_date'
  /** column name */
  | 'id'
  /** column name */
  | 'is_delivered'
  /** column name */
  | 'is_shipped'
  /** column name */
  | 'order_line_item_id'
  /** column name */
  | 'purchase_date'
  /** column name */
  | 'purchase_id'
  /** column name */
  | 'secondary_tracking_numbers'
  /** column name */
  | 'secondary_tracking_urls'
  /** column name */
  | 'status'
  /** column name */
  | 'tracking_info'
  /** column name */
  | 'tracking_number'
  /** column name */
  | 'tracking_url'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_product_id';

export type Delivery_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delivery_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delivery_Bool_Exp;
};

/** columns and relationships of "Device" */
export type Device = {
  __typename?: 'Device';
  /** An object relationship */
  User: User;
  createdAt: Scalars['timestamp']['output'];
  deviceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "DeviceType". All fields are combined with logical 'AND'. */
export type DeviceType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DeviceType']['input']>;
  _gt?: InputMaybe<Scalars['DeviceType']['input']>;
  _gte?: InputMaybe<Scalars['DeviceType']['input']>;
  _in?: InputMaybe<Array<Scalars['DeviceType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DeviceType']['input']>;
  _lte?: InputMaybe<Scalars['DeviceType']['input']>;
  _neq?: InputMaybe<Scalars['DeviceType']['input']>;
  _nin?: InputMaybe<Array<Scalars['DeviceType']['input']>>;
};

/** aggregated selection of "Device" */
export type Device_Aggregate = {
  __typename?: 'Device_aggregate';
  aggregate?: Maybe<Device_Aggregate_Fields>;
  nodes: Array<Device>;
};

export type Device_Aggregate_Bool_Exp = {
  count?: InputMaybe<Device_Aggregate_Bool_Exp_Count>;
};

export type Device_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Device_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Device_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Device" */
export type Device_Aggregate_Fields = {
  __typename?: 'Device_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Device_Max_Fields>;
  min?: Maybe<Device_Min_Fields>;
};


/** aggregate fields of "Device" */
export type Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Device" */
export type Device_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Device_Max_Order_By>;
  min?: InputMaybe<Device_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Device" */
export type Device_Arr_Rel_Insert_Input = {
  data: Array<Device_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Device". All fields are combined with a logical 'AND'. */
export type Device_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<Device_Bool_Exp>>;
  _not?: InputMaybe<Device_Bool_Exp>;
  _or?: InputMaybe<Array<Device_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  deviceId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Device" */
export type Device_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Device_pkey';

/** input type for inserting data into table "Device" */
export type Device_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Device_Max_Fields = {
  __typename?: 'Device_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Device" */
export type Device_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Device_Min_Fields = {
  __typename?: 'Device_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Device" */
export type Device_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Device" */
export type Device_Mutation_Response = {
  __typename?: 'Device_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Device>;
};

/** on_conflict condition type for table "Device" */
export type Device_On_Conflict = {
  constraint: Device_Constraint;
  update_columns?: Array<Device_Update_Column>;
  where?: InputMaybe<Device_Bool_Exp>;
};

/** Ordering options when selecting data from "Device". */
export type Device_Order_By = {
  User?: InputMaybe<User_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Device */
export type Device_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Device" */
export type Device_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'deviceId'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId'
  /** column name */
  | 'version';

/** input type for updating data in table "Device" */
export type Device_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Device" */
export type Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Device_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Device" */
export type Device_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'deviceId'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId'
  /** column name */
  | 'version';

export type Device_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Set_Input>;
  /** filter the rows which have to be updated */
  where: Device_Bool_Exp;
};

export type DevicesType =
  | 'biostrap'
  | 'mirror'
  | 'motherboard';

/** columns and relationships of "Diagnostics" */
export type Diagnostics = {
  __typename?: 'Diagnostics';
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserWorkout?: Maybe<UserWorkout>;
  batch_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  epoch_ms?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ordinal?: Maybe<Scalars['Int']['output']>;
  raw_data?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
  user_workout_id?: Maybe<Scalars['String']['output']>;
};

export type DiagnosticsDeviceType =
  | 'ANDROID'
  | 'IOS'
  | 'MIRROR';

/** aggregated selection of "Diagnostics" */
export type Diagnostics_Aggregate = {
  __typename?: 'Diagnostics_aggregate';
  aggregate?: Maybe<Diagnostics_Aggregate_Fields>;
  nodes: Array<Diagnostics>;
};

export type Diagnostics_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Diagnostics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Diagnostics" */
export type Diagnostics_Aggregate_Fields = {
  __typename?: 'Diagnostics_aggregate_fields';
  avg?: Maybe<Diagnostics_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Diagnostics_Max_Fields>;
  min?: Maybe<Diagnostics_Min_Fields>;
  stddev?: Maybe<Diagnostics_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Variance_Fields>;
};


/** aggregate fields of "Diagnostics" */
export type Diagnostics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Diagnostics" */
export type Diagnostics_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Diagnostics" */
export type Diagnostics_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Avg_Fields = {
  __typename?: 'Diagnostics_avg_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "Diagnostics" */
export type Diagnostics_Avg_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Diagnostics". All fields are combined with a logical 'AND'. */
export type Diagnostics_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserWorkout?: InputMaybe<UserWorkout_Bool_Exp>;
  _and?: InputMaybe<Array<Diagnostics_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Bool_Exp>>;
  batch_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  epoch_ms?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ordinal?: InputMaybe<Int_Comparison_Exp>;
  raw_data?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_workout_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Diagnostics" */
export type Diagnostics_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Diagnostics_pkey';

/** input type for incrementing numeric columns in table "Diagnostics" */
export type Diagnostics_Inc_Input = {
  ordinal?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Diagnostics" */
export type Diagnostics_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserWorkout?: InputMaybe<UserWorkout_Obj_Rel_Insert_Input>;
  batch_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  epoch_ms?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  raw_data?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Diagnostics_Max_Fields = {
  __typename?: 'Diagnostics_max_fields';
  batch_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  epoch_ms?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  raw_data?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Diagnostics" */
export type Diagnostics_Max_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  epoch_ms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Min_Fields = {
  __typename?: 'Diagnostics_min_fields';
  batch_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  epoch_ms?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  raw_data?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Diagnostics" */
export type Diagnostics_Min_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  epoch_ms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Diagnostics" */
export type Diagnostics_Mutation_Response = {
  __typename?: 'Diagnostics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics>;
};

/** on_conflict condition type for table "Diagnostics" */
export type Diagnostics_On_Conflict = {
  constraint: Diagnostics_Constraint;
  update_columns?: Array<Diagnostics_Update_Column>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};

/** Ordering options when selecting data from "Diagnostics". */
export type Diagnostics_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserWorkout?: InputMaybe<UserWorkout_Order_By>;
  batch_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  epoch_ms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Diagnostics */
export type Diagnostics_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Diagnostics" */
export type Diagnostics_Select_Column =
  /** column name */
  | 'batch_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'epoch_ms'
  /** column name */
  | 'id'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'raw_data'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id';

/** input type for updating data in table "Diagnostics" */
export type Diagnostics_Set_Input = {
  batch_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  epoch_ms?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  raw_data?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Stddev_Fields = {
  __typename?: 'Diagnostics_stddev_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "Diagnostics" */
export type Diagnostics_Stddev_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Stddev_Pop_Fields = {
  __typename?: 'Diagnostics_stddev_pop_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "Diagnostics" */
export type Diagnostics_Stddev_Pop_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Stddev_Samp_Fields = {
  __typename?: 'Diagnostics_stddev_samp_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "Diagnostics" */
export type Diagnostics_Stddev_Samp_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Diagnostics" */
export type Diagnostics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Stream_Cursor_Value_Input = {
  batch_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  epoch_ms?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  raw_data?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Diagnostics_Sum_Fields = {
  __typename?: 'Diagnostics_sum_fields';
  ordinal?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "Diagnostics" */
export type Diagnostics_Sum_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** update columns of table "Diagnostics" */
export type Diagnostics_Update_Column =
  /** column name */
  | 'batch_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'epoch_ms'
  /** column name */
  | 'id'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'raw_data'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id';

export type Diagnostics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Diagnostics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Diagnostics_Var_Pop_Fields = {
  __typename?: 'Diagnostics_var_pop_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "Diagnostics" */
export type Diagnostics_Var_Pop_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Var_Samp_Fields = {
  __typename?: 'Diagnostics_var_samp_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "Diagnostics" */
export type Diagnostics_Var_Samp_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Variance_Fields = {
  __typename?: 'Diagnostics_variance_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "Diagnostics" */
export type Diagnostics_Variance_Order_By = {
  ordinal?: InputMaybe<Order_By>;
};

export type DietContents = {
  __typename?: 'DietContents';
  carbs?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  fats?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  proteins?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type DifficultyEnum =
  | 'advanced'
  | 'average'
  | 'easy'
  | 'hard'
  | 'mixed';

export type EmailHasKitOutput = {
  __typename?: 'EmailHasKitOutput';
  email?: Maybe<Scalars['String']['output']>;
  hasKit?: Maybe<Scalars['Boolean']['output']>;
};

export type EndAssessmentOutput = {
  __typename?: 'EndAssessmentOutput';
  completed_epoch?: Maybe<Scalars['Int']['output']>;
  end_hr?: Maybe<Scalars['Int']['output']>;
  fitness_score?: Maybe<Scalars['Int']['output']>;
  high_knee_score?: Maybe<Scalars['Int']['output']>;
  max_hr?: Maybe<Scalars['Int']['output']>;
  recovery_score?: Maybe<Scalars['Int']['output']>;
  start_epoch?: Maybe<Scalars['Int']['output']>;
  total_high_knees?: Maybe<Scalars['Int']['output']>;
  v02_max?: Maybe<Scalars['Int']['output']>;
  v02_score?: Maybe<Scalars['Int']['output']>;
};

export type EquipmentPreferenceEnum =
  | 'Both'
  | 'Dumbbells'
  | 'NoDumbbells';

export type Ethnicity =
  | 'AmericanIndian'
  | 'Asian'
  | 'Black'
  | 'HispanicLatino'
  | 'Other'
  | 'PacificIslander'
  | 'White';

export type FeedbackTypeEnum =
  | 'Boolean'
  | 'MultiChoice'
  | 'OpenField'
  | 'Range'
  | 'SingleChoice';

/** Boolean expression to compare columns of type "FeedbackType". All fields are combined with logical 'AND'. */
export type FeedbackType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['FeedbackType']['input']>;
  _gt?: InputMaybe<Scalars['FeedbackType']['input']>;
  _gte?: InputMaybe<Scalars['FeedbackType']['input']>;
  _in?: InputMaybe<Array<Scalars['FeedbackType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['FeedbackType']['input']>;
  _lte?: InputMaybe<Scalars['FeedbackType']['input']>;
  _neq?: InputMaybe<Scalars['FeedbackType']['input']>;
  _nin?: InputMaybe<Array<Scalars['FeedbackType']['input']>>;
};

export type FetchCoachOutput = {
  __typename?: 'FetchCoachOutput';
  calendly_link?: Maybe<Scalars['String']['output']>;
  calendly_link_thirty_min?: Maybe<Scalars['String']['output']>;
  contentful_id: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sf_coach_id: Scalars['String']['output'];
};

export type FetchCoachedPlanOutput = {
  __typename?: 'FetchCoachedPlanOutput';
  current_plan?: Maybe<ChangeCoachedPlanOutput>;
  preset_plans?: Maybe<Array<Maybe<PresetPlans>>>;
};

export type FetchCookBookOutput = {
  __typename?: 'FetchCookBookOutput';
  items?: Maybe<Array<Maybe<CookBookItem>>>;
};

export type FetchWorkoutOptionsOutput = {
  __typename?: 'FetchWorkoutOptionsOutput';
  duration_minutes: Scalars['Int']['output'];
  trainer: Scalars['String']['output'];
};

export type FetchWorkoutOptionsOutputV2 = {
  __typename?: 'FetchWorkoutOptionsOutputV2';
  duration_minutes: Scalars['Int']['output'];
  equipments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trainer: Scalars['String']['output'];
};

export type FetchWorkoutOptionsOutputV3 = {
  __typename?: 'FetchWorkoutOptionsOutputV3';
  duration_minutes: Scalars['Int']['output'];
  equipments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isAdaptive?: Maybe<Scalars['Boolean']['output']>;
  themes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trainer: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "FileType". All fields are combined with logical 'AND'. */
export type FileType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['FileType']['input']>;
  _gt?: InputMaybe<Scalars['FileType']['input']>;
  _gte?: InputMaybe<Scalars['FileType']['input']>;
  _in?: InputMaybe<Array<Scalars['FileType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['FileType']['input']>;
  _lte?: InputMaybe<Scalars['FileType']['input']>;
  _neq?: InputMaybe<Scalars['FileType']['input']>;
  _nin?: InputMaybe<Array<Scalars['FileType']['input']>>;
};

/** columns and relationships of "FinancePlan" */
export type FinancePlan = {
  __typename?: 'FinancePlan';
  /** An object relationship */
  Product: Product;
  /** An array relationship */
  UserFinancePlans: Array<UserFinancePlan>;
  /** An aggregate relationship */
  UserFinancePlans_aggregate: UserFinancePlan_Aggregate;
  cadence: Scalars['CadenceType']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  interest_rate: Scalars['float8']['output'];
  num_months: Scalars['Int']['output'];
  product_id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  vendor_id: Scalars['String']['output'];
};


/** columns and relationships of "FinancePlan" */
export type FinancePlanUserFinancePlansArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


/** columns and relationships of "FinancePlan" */
export type FinancePlanUserFinancePlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};

/** aggregated selection of "FinancePlan" */
export type FinancePlan_Aggregate = {
  __typename?: 'FinancePlan_aggregate';
  aggregate?: Maybe<FinancePlan_Aggregate_Fields>;
  nodes: Array<FinancePlan>;
};

export type FinancePlan_Aggregate_Bool_Exp = {
  avg?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<FinancePlan_Aggregate_Bool_Exp_Var_Samp>;
};

export type FinancePlan_Aggregate_Bool_Exp_Avg = {
  arguments: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Corr = {
  arguments: FinancePlan_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Corr_Arguments = {
  X: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type FinancePlan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FinancePlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: FinancePlan_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type FinancePlan_Aggregate_Bool_Exp_Max = {
  arguments: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Min = {
  arguments: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Sum = {
  arguments: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FinancePlan_Aggregate_Bool_Exp_Var_Samp = {
  arguments: FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FinancePlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "FinancePlan" */
export type FinancePlan_Aggregate_Fields = {
  __typename?: 'FinancePlan_aggregate_fields';
  avg?: Maybe<FinancePlan_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FinancePlan_Max_Fields>;
  min?: Maybe<FinancePlan_Min_Fields>;
  stddev?: Maybe<FinancePlan_Stddev_Fields>;
  stddev_pop?: Maybe<FinancePlan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FinancePlan_Stddev_Samp_Fields>;
  sum?: Maybe<FinancePlan_Sum_Fields>;
  var_pop?: Maybe<FinancePlan_Var_Pop_Fields>;
  var_samp?: Maybe<FinancePlan_Var_Samp_Fields>;
  variance?: Maybe<FinancePlan_Variance_Fields>;
};


/** aggregate fields of "FinancePlan" */
export type FinancePlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FinancePlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "FinancePlan" */
export type FinancePlan_Aggregate_Order_By = {
  avg?: InputMaybe<FinancePlan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FinancePlan_Max_Order_By>;
  min?: InputMaybe<FinancePlan_Min_Order_By>;
  stddev?: InputMaybe<FinancePlan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FinancePlan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FinancePlan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FinancePlan_Sum_Order_By>;
  var_pop?: InputMaybe<FinancePlan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FinancePlan_Var_Samp_Order_By>;
  variance?: InputMaybe<FinancePlan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FinancePlan" */
export type FinancePlan_Arr_Rel_Insert_Input = {
  data: Array<FinancePlan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FinancePlan_On_Conflict>;
};

/** aggregate avg on columns */
export type FinancePlan_Avg_Fields = {
  __typename?: 'FinancePlan_avg_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "FinancePlan" */
export type FinancePlan_Avg_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FinancePlan". All fields are combined with a logical 'AND'. */
export type FinancePlan_Bool_Exp = {
  Product?: InputMaybe<Product_Bool_Exp>;
  UserFinancePlans?: InputMaybe<UserFinancePlan_Bool_Exp>;
  UserFinancePlans_aggregate?: InputMaybe<UserFinancePlan_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<FinancePlan_Bool_Exp>>;
  _not?: InputMaybe<FinancePlan_Bool_Exp>;
  _or?: InputMaybe<Array<FinancePlan_Bool_Exp>>;
  cadence?: InputMaybe<CadenceType_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  interest_rate?: InputMaybe<Float8_Comparison_Exp>;
  num_months?: InputMaybe<Int_Comparison_Exp>;
  product_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "FinancePlan" */
export type FinancePlan_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'FinancePlan_pkey';

/** input type for incrementing numeric columns in table "FinancePlan" */
export type FinancePlan_Inc_Input = {
  interest_rate?: InputMaybe<Scalars['float8']['input']>;
  num_months?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "FinancePlan" */
export type FinancePlan_Insert_Input = {
  Product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  UserFinancePlans?: InputMaybe<UserFinancePlan_Arr_Rel_Insert_Input>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interest_rate?: InputMaybe<Scalars['float8']['input']>;
  num_months?: InputMaybe<Scalars['Int']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type FinancePlan_Max_Fields = {
  __typename?: 'FinancePlan_max_fields';
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interest_rate?: Maybe<Scalars['float8']['output']>;
  num_months?: Maybe<Scalars['Int']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  vendor_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "FinancePlan" */
export type FinancePlan_Max_Order_By = {
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FinancePlan_Min_Fields = {
  __typename?: 'FinancePlan_min_fields';
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interest_rate?: Maybe<Scalars['float8']['output']>;
  num_months?: Maybe<Scalars['Int']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  vendor_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "FinancePlan" */
export type FinancePlan_Min_Order_By = {
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FinancePlan" */
export type FinancePlan_Mutation_Response = {
  __typename?: 'FinancePlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FinancePlan>;
};

/** input type for inserting object relation for remote table "FinancePlan" */
export type FinancePlan_Obj_Rel_Insert_Input = {
  data: FinancePlan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FinancePlan_On_Conflict>;
};

/** on_conflict condition type for table "FinancePlan" */
export type FinancePlan_On_Conflict = {
  constraint: FinancePlan_Constraint;
  update_columns?: Array<FinancePlan_Update_Column>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};

/** Ordering options when selecting data from "FinancePlan". */
export type FinancePlan_Order_By = {
  Product?: InputMaybe<Product_Order_By>;
  UserFinancePlans_aggregate?: InputMaybe<UserFinancePlan_Aggregate_Order_By>;
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FinancePlan */
export type FinancePlan_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "FinancePlan" */
export type FinancePlan_Select_Column =
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'interest_rate'
  /** column name */
  | 'num_months'
  /** column name */
  | 'product_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'vendor_id';

/** select "FinancePlan_aggregate_bool_exp_avg_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_corr_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_max_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_min_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_sum_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** select "FinancePlan_aggregate_bool_exp_var_samp_arguments_columns" columns of table "FinancePlan" */
export type FinancePlan_Select_Column_FinancePlan_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'interest_rate';

/** input type for updating data in table "FinancePlan" */
export type FinancePlan_Set_Input = {
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interest_rate?: InputMaybe<Scalars['float8']['input']>;
  num_months?: InputMaybe<Scalars['Int']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type FinancePlan_Stddev_Fields = {
  __typename?: 'FinancePlan_stddev_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "FinancePlan" */
export type FinancePlan_Stddev_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FinancePlan_Stddev_Pop_Fields = {
  __typename?: 'FinancePlan_stddev_pop_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "FinancePlan" */
export type FinancePlan_Stddev_Pop_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FinancePlan_Stddev_Samp_Fields = {
  __typename?: 'FinancePlan_stddev_samp_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "FinancePlan" */
export type FinancePlan_Stddev_Samp_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FinancePlan" */
export type FinancePlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FinancePlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FinancePlan_Stream_Cursor_Value_Input = {
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interest_rate?: InputMaybe<Scalars['float8']['input']>;
  num_months?: InputMaybe<Scalars['Int']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type FinancePlan_Sum_Fields = {
  __typename?: 'FinancePlan_sum_fields';
  interest_rate?: Maybe<Scalars['float8']['output']>;
  num_months?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "FinancePlan" */
export type FinancePlan_Sum_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** update columns of table "FinancePlan" */
export type FinancePlan_Update_Column =
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'interest_rate'
  /** column name */
  | 'num_months'
  /** column name */
  | 'product_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'vendor_id';

export type FinancePlan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FinancePlan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FinancePlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: FinancePlan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FinancePlan_Var_Pop_Fields = {
  __typename?: 'FinancePlan_var_pop_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "FinancePlan" */
export type FinancePlan_Var_Pop_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FinancePlan_Var_Samp_Fields = {
  __typename?: 'FinancePlan_var_samp_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "FinancePlan" */
export type FinancePlan_Var_Samp_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FinancePlan_Variance_Fields = {
  __typename?: 'FinancePlan_variance_fields';
  interest_rate?: Maybe<Scalars['Float']['output']>;
  num_months?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "FinancePlan" */
export type FinancePlan_Variance_Order_By = {
  interest_rate?: InputMaybe<Order_By>;
  num_months?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "FinanceStatus". All fields are combined with logical 'AND'. */
export type FinanceStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['FinanceStatus']['input']>;
  _gt?: InputMaybe<Scalars['FinanceStatus']['input']>;
  _gte?: InputMaybe<Scalars['FinanceStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['FinanceStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['FinanceStatus']['input']>;
  _lte?: InputMaybe<Scalars['FinanceStatus']['input']>;
  _neq?: InputMaybe<Scalars['FinanceStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['FinanceStatus']['input']>>;
};

/** columns and relationships of "FirmwareContent" */
export type FirmwareContent = {
  __typename?: 'FirmwareContent';
  createdAt: Scalars['timestamp']['output'];
  device: Scalars['FirmwareDevice']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  is_beta: Scalars['Boolean']['output'];
  type: Scalars['FileType']['output'];
  updatedAt: Scalars['timestamp']['output'];
  version: Scalars['String']['output'];
  version_number?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "FirmwareContent" */
export type FirmwareContent_Aggregate = {
  __typename?: 'FirmwareContent_aggregate';
  aggregate?: Maybe<FirmwareContent_Aggregate_Fields>;
  nodes: Array<FirmwareContent>;
};

/** aggregate fields of "FirmwareContent" */
export type FirmwareContent_Aggregate_Fields = {
  __typename?: 'FirmwareContent_aggregate_fields';
  avg?: Maybe<FirmwareContent_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FirmwareContent_Max_Fields>;
  min?: Maybe<FirmwareContent_Min_Fields>;
  stddev?: Maybe<FirmwareContent_Stddev_Fields>;
  stddev_pop?: Maybe<FirmwareContent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FirmwareContent_Stddev_Samp_Fields>;
  sum?: Maybe<FirmwareContent_Sum_Fields>;
  var_pop?: Maybe<FirmwareContent_Var_Pop_Fields>;
  var_samp?: Maybe<FirmwareContent_Var_Samp_Fields>;
  variance?: Maybe<FirmwareContent_Variance_Fields>;
};


/** aggregate fields of "FirmwareContent" */
export type FirmwareContent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FirmwareContent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type FirmwareContent_Avg_Fields = {
  __typename?: 'FirmwareContent_avg_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "FirmwareContent". All fields are combined with a logical 'AND'. */
export type FirmwareContent_Bool_Exp = {
  _and?: InputMaybe<Array<FirmwareContent_Bool_Exp>>;
  _not?: InputMaybe<FirmwareContent_Bool_Exp>;
  _or?: InputMaybe<Array<FirmwareContent_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  device?: InputMaybe<FirmwareDevice_Comparison_Exp>;
  fileUrl?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  is_beta?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<FileType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
  version_number?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "FirmwareContent" */
export type FirmwareContent_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'FirmwareContent_pkey';

/** input type for incrementing numeric columns in table "FirmwareContent" */
export type FirmwareContent_Inc_Input = {
  version_number?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "FirmwareContent" */
export type FirmwareContent_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  device?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['FileType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  version_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type FirmwareContent_Max_Fields = {
  __typename?: 'FirmwareContent_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  device?: Maybe<Scalars['FirmwareDevice']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['FileType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  version_number?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type FirmwareContent_Min_Fields = {
  __typename?: 'FirmwareContent_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  device?: Maybe<Scalars['FirmwareDevice']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['FileType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  version_number?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "FirmwareContent" */
export type FirmwareContent_Mutation_Response = {
  __typename?: 'FirmwareContent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FirmwareContent>;
};

/** on_conflict condition type for table "FirmwareContent" */
export type FirmwareContent_On_Conflict = {
  constraint: FirmwareContent_Constraint;
  update_columns?: Array<FirmwareContent_Update_Column>;
  where?: InputMaybe<FirmwareContent_Bool_Exp>;
};

/** Ordering options when selecting data from "FirmwareContent". */
export type FirmwareContent_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  device?: InputMaybe<Order_By>;
  fileUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  is_beta?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FirmwareContent */
export type FirmwareContent_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "FirmwareContent" */
export type FirmwareContent_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'device'
  /** column name */
  | 'fileUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'isActive'
  /** column name */
  | 'is_beta'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'version'
  /** column name */
  | 'version_number';

/** input type for updating data in table "FirmwareContent" */
export type FirmwareContent_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  device?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['FileType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  version_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type FirmwareContent_Stddev_Fields = {
  __typename?: 'FirmwareContent_stddev_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FirmwareContent_Stddev_Pop_Fields = {
  __typename?: 'FirmwareContent_stddev_pop_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FirmwareContent_Stddev_Samp_Fields = {
  __typename?: 'FirmwareContent_stddev_samp_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "FirmwareContent" */
export type FirmwareContent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FirmwareContent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FirmwareContent_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  device?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['FileType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  version_number?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type FirmwareContent_Sum_Fields = {
  __typename?: 'FirmwareContent_sum_fields';
  version_number?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "FirmwareContent" */
export type FirmwareContent_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'device'
  /** column name */
  | 'fileUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'isActive'
  /** column name */
  | 'is_beta'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'version'
  /** column name */
  | 'version_number';

export type FirmwareContent_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FirmwareContent_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FirmwareContent_Set_Input>;
  /** filter the rows which have to be updated */
  where: FirmwareContent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FirmwareContent_Var_Pop_Fields = {
  __typename?: 'FirmwareContent_var_pop_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FirmwareContent_Var_Samp_Fields = {
  __typename?: 'FirmwareContent_var_samp_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FirmwareContent_Variance_Fields = {
  __typename?: 'FirmwareContent_variance_fields';
  version_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "FirmwareDevice". All fields are combined with logical 'AND'. */
export type FirmwareDevice_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  _gt?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  _gte?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  _in?: InputMaybe<Array<Scalars['FirmwareDevice']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  _lte?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  _neq?: InputMaybe<Scalars['FirmwareDevice']['input']>;
  _nin?: InputMaybe<Array<Scalars['FirmwareDevice']['input']>>;
};

export type FitnessRecommenderOutput = {
  __typename?: 'FitnessRecommenderOutput';
  audioUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  extended_content?: Maybe<Scalars['json']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<WorkoutSubTypeEnum>;
  title?: Maybe<Scalars['String']['output']>;
  trainer?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  workout_id?: Maybe<Scalars['String']['output']>;
  workout_type?: Maybe<WorkoutTypeEnum>;
};

export type Gender =
  | 'Female'
  | 'Male'
  | 'Other'
  | 'female'
  | 'male'
  | 'other';

/** Boolean expression to compare columns of type "GeneralRankGroup". All fields are combined with logical 'AND'. */
export type GeneralRankGroup_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  _gt?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  _gte?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  _in?: InputMaybe<Array<Scalars['GeneralRankGroup']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  _lte?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  _neq?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  _nin?: InputMaybe<Array<Scalars['GeneralRankGroup']['input']>>;
};

/** Boolean expression to compare columns of type "GeneralRank". All fields are combined with logical 'AND'. */
export type GeneralRank_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['GeneralRank']['input']>;
  _gt?: InputMaybe<Scalars['GeneralRank']['input']>;
  _gte?: InputMaybe<Scalars['GeneralRank']['input']>;
  _in?: InputMaybe<Array<Scalars['GeneralRank']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['GeneralRank']['input']>;
  _lte?: InputMaybe<Scalars['GeneralRank']['input']>;
  _neq?: InputMaybe<Scalars['GeneralRank']['input']>;
  _nin?: InputMaybe<Array<Scalars['GeneralRank']['input']>>;
};

/** columns and relationships of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook = {
  __typename?: 'GeneticDirectionWebhook';
  /** An object relationship */
  DNAKit: DnaKit;
  created_at: Scalars['timestamp']['output'];
  dnaKitStatusNotification?: Maybe<Scalars['jsonb']['output']>;
  dnaKit_payload?: Maybe<Scalars['jsonb']['output']>;
  dropShipOrderId: Scalars['Int']['output'];
  geneticReport?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  kitId?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  webhookId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhookDnaKitStatusNotificationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhookDnaKit_PayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhookGeneticReportArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Aggregate = {
  __typename?: 'GeneticDirectionWebhook_aggregate';
  aggregate?: Maybe<GeneticDirectionWebhook_Aggregate_Fields>;
  nodes: Array<GeneticDirectionWebhook>;
};

export type GeneticDirectionWebhook_Aggregate_Bool_Exp = {
  count?: InputMaybe<GeneticDirectionWebhook_Aggregate_Bool_Exp_Count>;
};

export type GeneticDirectionWebhook_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Aggregate_Fields = {
  __typename?: 'GeneticDirectionWebhook_aggregate_fields';
  avg?: Maybe<GeneticDirectionWebhook_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GeneticDirectionWebhook_Max_Fields>;
  min?: Maybe<GeneticDirectionWebhook_Min_Fields>;
  stddev?: Maybe<GeneticDirectionWebhook_Stddev_Fields>;
  stddev_pop?: Maybe<GeneticDirectionWebhook_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GeneticDirectionWebhook_Stddev_Samp_Fields>;
  sum?: Maybe<GeneticDirectionWebhook_Sum_Fields>;
  var_pop?: Maybe<GeneticDirectionWebhook_Var_Pop_Fields>;
  var_samp?: Maybe<GeneticDirectionWebhook_Var_Samp_Fields>;
  variance?: Maybe<GeneticDirectionWebhook_Variance_Fields>;
};


/** aggregate fields of "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Aggregate_Order_By = {
  avg?: InputMaybe<GeneticDirectionWebhook_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GeneticDirectionWebhook_Max_Order_By>;
  min?: InputMaybe<GeneticDirectionWebhook_Min_Order_By>;
  stddev?: InputMaybe<GeneticDirectionWebhook_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GeneticDirectionWebhook_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GeneticDirectionWebhook_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GeneticDirectionWebhook_Sum_Order_By>;
  var_pop?: InputMaybe<GeneticDirectionWebhook_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GeneticDirectionWebhook_Var_Samp_Order_By>;
  variance?: InputMaybe<GeneticDirectionWebhook_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GeneticDirectionWebhook_Append_Input = {
  dnaKitStatusNotification?: InputMaybe<Scalars['jsonb']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['jsonb']['input']>;
  geneticReport?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Arr_Rel_Insert_Input = {
  data: Array<GeneticDirectionWebhook_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GeneticDirectionWebhook_On_Conflict>;
};

/** aggregate avg on columns */
export type GeneticDirectionWebhook_Avg_Fields = {
  __typename?: 'GeneticDirectionWebhook_avg_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Avg_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GeneticDirectionWebhook". All fields are combined with a logical 'AND'. */
export type GeneticDirectionWebhook_Bool_Exp = {
  DNAKit?: InputMaybe<DnaKit_Bool_Exp>;
  _and?: InputMaybe<Array<GeneticDirectionWebhook_Bool_Exp>>;
  _not?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
  _or?: InputMaybe<Array<GeneticDirectionWebhook_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dnaKitStatusNotification?: InputMaybe<Jsonb_Comparison_Exp>;
  dnaKit_payload?: InputMaybe<Jsonb_Comparison_Exp>;
  dropShipOrderId?: InputMaybe<Int_Comparison_Exp>;
  geneticReport?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  kitId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  webhookId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'GeneticDirectionWebhook_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GeneticDirectionWebhook_Delete_At_Path_Input = {
  dnaKitStatusNotification?: InputMaybe<Array<Scalars['String']['input']>>;
  dnaKit_payload?: InputMaybe<Array<Scalars['String']['input']>>;
  geneticReport?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GeneticDirectionWebhook_Delete_Elem_Input = {
  dnaKitStatusNotification?: InputMaybe<Scalars['Int']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['Int']['input']>;
  geneticReport?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GeneticDirectionWebhook_Delete_Key_Input = {
  dnaKitStatusNotification?: InputMaybe<Scalars['String']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['String']['input']>;
  geneticReport?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Inc_Input = {
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Insert_Input = {
  DNAKit?: InputMaybe<DnaKit_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dnaKitStatusNotification?: InputMaybe<Scalars['jsonb']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['jsonb']['input']>;
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  geneticReport?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type GeneticDirectionWebhook_Max_Fields = {
  __typename?: 'GeneticDirectionWebhook_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dropShipOrderId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dropShipOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GeneticDirectionWebhook_Min_Fields = {
  __typename?: 'GeneticDirectionWebhook_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  dropShipOrderId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dropShipOrderId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Mutation_Response = {
  __typename?: 'GeneticDirectionWebhook_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GeneticDirectionWebhook>;
};

/** on_conflict condition type for table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_On_Conflict = {
  constraint: GeneticDirectionWebhook_Constraint;
  update_columns?: Array<GeneticDirectionWebhook_Update_Column>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};

/** Ordering options when selecting data from "GeneticDirectionWebhook". */
export type GeneticDirectionWebhook_Order_By = {
  DNAKit?: InputMaybe<DnaKit_Order_By>;
  created_at?: InputMaybe<Order_By>;
  dnaKitStatusNotification?: InputMaybe<Order_By>;
  dnaKit_payload?: InputMaybe<Order_By>;
  dropShipOrderId?: InputMaybe<Order_By>;
  geneticReport?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kitId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GeneticDirectionWebhook */
export type GeneticDirectionWebhook_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GeneticDirectionWebhook_Prepend_Input = {
  dnaKitStatusNotification?: InputMaybe<Scalars['jsonb']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['jsonb']['input']>;
  geneticReport?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'dnaKitStatusNotification'
  /** column name */
  | 'dnaKit_payload'
  /** column name */
  | 'dropShipOrderId'
  /** column name */
  | 'geneticReport'
  /** column name */
  | 'id'
  /** column name */
  | 'kitId'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'webhookId';

/** input type for updating data in table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dnaKitStatusNotification?: InputMaybe<Scalars['jsonb']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['jsonb']['input']>;
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  geneticReport?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type GeneticDirectionWebhook_Stddev_Fields = {
  __typename?: 'GeneticDirectionWebhook_stddev_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Stddev_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GeneticDirectionWebhook_Stddev_Pop_Fields = {
  __typename?: 'GeneticDirectionWebhook_stddev_pop_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Stddev_Pop_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GeneticDirectionWebhook_Stddev_Samp_Fields = {
  __typename?: 'GeneticDirectionWebhook_stddev_samp_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Stddev_Samp_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GeneticDirectionWebhook_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GeneticDirectionWebhook_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  dnaKitStatusNotification?: InputMaybe<Scalars['jsonb']['input']>;
  dnaKit_payload?: InputMaybe<Scalars['jsonb']['input']>;
  dropShipOrderId?: InputMaybe<Scalars['Int']['input']>;
  geneticReport?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type GeneticDirectionWebhook_Sum_Fields = {
  __typename?: 'GeneticDirectionWebhook_sum_fields';
  dropShipOrderId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Sum_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** update columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'dnaKitStatusNotification'
  /** column name */
  | 'dnaKit_payload'
  /** column name */
  | 'dropShipOrderId'
  /** column name */
  | 'geneticReport'
  /** column name */
  | 'id'
  /** column name */
  | 'kitId'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'webhookId';

export type GeneticDirectionWebhook_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GeneticDirectionWebhook_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GeneticDirectionWebhook_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GeneticDirectionWebhook_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GeneticDirectionWebhook_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GeneticDirectionWebhook_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GeneticDirectionWebhook_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeneticDirectionWebhook_Set_Input>;
  /** filter the rows which have to be updated */
  where: GeneticDirectionWebhook_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GeneticDirectionWebhook_Var_Pop_Fields = {
  __typename?: 'GeneticDirectionWebhook_var_pop_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Var_Pop_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GeneticDirectionWebhook_Var_Samp_Fields = {
  __typename?: 'GeneticDirectionWebhook_var_samp_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Var_Samp_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GeneticDirectionWebhook_Variance_Fields = {
  __typename?: 'GeneticDirectionWebhook_variance_fields';
  dropShipOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "GeneticDirectionWebhook" */
export type GeneticDirectionWebhook_Variance_Order_By = {
  dropShipOrderId?: InputMaybe<Order_By>;
};

/** columns and relationships of "GlobalFeatureControl" */
export type GlobalFeatureControl = {
  __typename?: 'GlobalFeatureControl';
  created_at: Scalars['timestamp']['output'];
  enabled: Scalars['Boolean']['output'];
  feature_title: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_beta: Scalars['Boolean']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "GlobalFeatureControl" */
export type GlobalFeatureControl_Aggregate = {
  __typename?: 'GlobalFeatureControl_aggregate';
  aggregate?: Maybe<GlobalFeatureControl_Aggregate_Fields>;
  nodes: Array<GlobalFeatureControl>;
};

/** aggregate fields of "GlobalFeatureControl" */
export type GlobalFeatureControl_Aggregate_Fields = {
  __typename?: 'GlobalFeatureControl_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<GlobalFeatureControl_Max_Fields>;
  min?: Maybe<GlobalFeatureControl_Min_Fields>;
};


/** aggregate fields of "GlobalFeatureControl" */
export type GlobalFeatureControl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GlobalFeatureControl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "GlobalFeatureControl". All fields are combined with a logical 'AND'. */
export type GlobalFeatureControl_Bool_Exp = {
  _and?: InputMaybe<Array<GlobalFeatureControl_Bool_Exp>>;
  _not?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
  _or?: InputMaybe<Array<GlobalFeatureControl_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  feature_title?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_beta?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "GlobalFeatureControl" */
export type GlobalFeatureControl_Constraint =
  /** unique or primary key constraint on columns "enabled", "feature_title", "is_beta" */
  | 'GlobalFeatureControl_feature_title_is_beta_enabled_key'
  /** unique or primary key constraint on columns "id" */
  | 'GlobalFeatureControl_pkey';

/** input type for inserting data into table "GlobalFeatureControl" */
export type GlobalFeatureControl_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type GlobalFeatureControl_Max_Fields = {
  __typename?: 'GlobalFeatureControl_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  feature_title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type GlobalFeatureControl_Min_Fields = {
  __typename?: 'GlobalFeatureControl_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  feature_title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "GlobalFeatureControl" */
export type GlobalFeatureControl_Mutation_Response = {
  __typename?: 'GlobalFeatureControl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GlobalFeatureControl>;
};

/** on_conflict condition type for table "GlobalFeatureControl" */
export type GlobalFeatureControl_On_Conflict = {
  constraint: GlobalFeatureControl_Constraint;
  update_columns?: Array<GlobalFeatureControl_Update_Column>;
  where?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
};

/** Ordering options when selecting data from "GlobalFeatureControl". */
export type GlobalFeatureControl_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  feature_title?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_beta?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GlobalFeatureControl */
export type GlobalFeatureControl_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "GlobalFeatureControl" */
export type GlobalFeatureControl_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'enabled'
  /** column name */
  | 'feature_title'
  /** column name */
  | 'id'
  /** column name */
  | 'is_beta'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "GlobalFeatureControl" */
export type GlobalFeatureControl_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "GlobalFeatureControl" */
export type GlobalFeatureControl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GlobalFeatureControl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GlobalFeatureControl_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "GlobalFeatureControl" */
export type GlobalFeatureControl_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'enabled'
  /** column name */
  | 'feature_title'
  /** column name */
  | 'id'
  /** column name */
  | 'is_beta'
  /** column name */
  | 'updated_at';

export type GlobalFeatureControl_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GlobalFeatureControl_Set_Input>;
  /** filter the rows which have to be updated */
  where: GlobalFeatureControl_Bool_Exp;
};

/** columns and relationships of "Goal" */
export type Goal = {
  __typename?: 'Goal';
  /** An array relationship */
  CoachedNutritions: Array<CoachedNutrition>;
  /** An aggregate relationship */
  CoachedNutritions_aggregate: CoachedNutrition_Aggregate;
  /** An array relationship */
  Users: Array<User>;
  /** An aggregate relationship */
  Users_aggregate: User_Aggregate;
  /** An array relationship */
  _GoalToUserPreferences: Array<_GoalToUserPreferences>;
  /** An aggregate relationship */
  _GoalToUserPreferences_aggregate: _GoalToUserPreferences_Aggregate;
  created_at: Scalars['timestamp']['output'];
  goal_description?: Maybe<Scalars['String']['output']>;
  goal_title: Scalars['String']['output'];
  goal_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "Goal" */
export type GoalCoachedNutritionsArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "Goal" */
export type GoalCoachedNutritions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "Goal" */
export type GoalUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Goal" */
export type GoalUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Goal" */
export type Goal_GoalToUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};


/** columns and relationships of "Goal" */
export type Goal_GoalToUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};

/** aggregated selection of "Goal" */
export type Goal_Aggregate = {
  __typename?: 'Goal_aggregate';
  aggregate?: Maybe<Goal_Aggregate_Fields>;
  nodes: Array<Goal>;
};

/** aggregate fields of "Goal" */
export type Goal_Aggregate_Fields = {
  __typename?: 'Goal_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Goal_Max_Fields>;
  min?: Maybe<Goal_Min_Fields>;
};


/** aggregate fields of "Goal" */
export type Goal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Goal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "Goal". All fields are combined with a logical 'AND'. */
export type Goal_Bool_Exp = {
  CoachedNutritions?: InputMaybe<CoachedNutrition_Bool_Exp>;
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp>;
  Users?: InputMaybe<User_Bool_Exp>;
  Users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  _GoalToUserPreferences?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
  _GoalToUserPreferences_aggregate?: InputMaybe<_GoalToUserPreferences_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Goal_Bool_Exp>>;
  _not?: InputMaybe<Goal_Bool_Exp>;
  _or?: InputMaybe<Array<Goal_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  goal_description?: InputMaybe<String_Comparison_Exp>;
  goal_title?: InputMaybe<String_Comparison_Exp>;
  goal_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Goal" */
export type Goal_Constraint =
  /** unique or primary key constraint on columns "goal_title" */
  | 'Goal_goal_title_key'
  /** unique or primary key constraint on columns "id" */
  | 'Goal_pkey';

/** input type for inserting data into table "Goal" */
export type Goal_Insert_Input = {
  CoachedNutritions?: InputMaybe<CoachedNutrition_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  _GoalToUserPreferences?: InputMaybe<_GoalToUserPreferences_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  goal_description?: InputMaybe<Scalars['String']['input']>;
  goal_title?: InputMaybe<Scalars['String']['input']>;
  goal_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Goal_Max_Fields = {
  __typename?: 'Goal_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_description?: Maybe<Scalars['String']['output']>;
  goal_title?: Maybe<Scalars['String']['output']>;
  goal_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Goal_Min_Fields = {
  __typename?: 'Goal_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_description?: Maybe<Scalars['String']['output']>;
  goal_title?: Maybe<Scalars['String']['output']>;
  goal_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "Goal" */
export type Goal_Mutation_Response = {
  __typename?: 'Goal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Goal>;
};

/** input type for inserting object relation for remote table "Goal" */
export type Goal_Obj_Rel_Insert_Input = {
  data: Goal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Goal_On_Conflict>;
};

/** on_conflict condition type for table "Goal" */
export type Goal_On_Conflict = {
  constraint: Goal_Constraint;
  update_columns?: Array<Goal_Update_Column>;
  where?: InputMaybe<Goal_Bool_Exp>;
};

/** Ordering options when selecting data from "Goal". */
export type Goal_Order_By = {
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Order_By>;
  Users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  _GoalToUserPreferences_aggregate?: InputMaybe<_GoalToUserPreferences_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  goal_description?: InputMaybe<Order_By>;
  goal_title?: InputMaybe<Order_By>;
  goal_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Goal */
export type Goal_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Goal" */
export type Goal_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'goal_description'
  /** column name */
  | 'goal_title'
  /** column name */
  | 'goal_type'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Goal" */
export type Goal_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  goal_description?: InputMaybe<Scalars['String']['input']>;
  goal_title?: InputMaybe<Scalars['String']['input']>;
  goal_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "Goal" */
export type Goal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Goal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Goal_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  goal_description?: InputMaybe<Scalars['String']['input']>;
  goal_title?: InputMaybe<Scalars['String']['input']>;
  goal_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "Goal" */
export type Goal_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'goal_description'
  /** column name */
  | 'goal_title'
  /** column name */
  | 'goal_type'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Goal_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Goal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Goal_Bool_Exp;
};

export type HrDiagnosticData = {
  confidence?: InputMaybe<Scalars['Int']['input']>;
  hr_timestamp?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type HrDiagnosticType =
  | 'Continuous'
  | 'Resting';

export type HabitCompletedResult = {
  __typename?: 'HabitCompletedResult';
  habit_days_left?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  user_reimbursement_tokens?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "HabitDay" */
export type HabitDay = {
  __typename?: 'HabitDay';
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  date: Scalars['date']['output'];
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type: Scalars['HabitType']['output'];
  id: Scalars['String']['output'];
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  ordinal: Scalars['Int']['output'];
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type: Scalars['HabitSubType']['output'];
  submission_value_bool?: Maybe<Scalars['Boolean']['output']>;
  submission_value_int?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_habit_id: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type HabitDayOutput = {
  __typename?: 'HabitDayOutput';
  completed_at?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<HabitTypeEnum>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<HabitSubTypeEnum>;
  submission_value_bool?: Maybe<Scalars['Boolean']['output']>;
  submission_value_int?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_habit_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "HabitDay" */
export type HabitDay_Aggregate = {
  __typename?: 'HabitDay_aggregate';
  aggregate?: Maybe<HabitDay_Aggregate_Fields>;
  nodes: Array<HabitDay>;
};

/** aggregate fields of "HabitDay" */
export type HabitDay_Aggregate_Fields = {
  __typename?: 'HabitDay_aggregate_fields';
  avg?: Maybe<HabitDay_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<HabitDay_Max_Fields>;
  min?: Maybe<HabitDay_Min_Fields>;
  stddev?: Maybe<HabitDay_Stddev_Fields>;
  stddev_pop?: Maybe<HabitDay_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HabitDay_Stddev_Samp_Fields>;
  sum?: Maybe<HabitDay_Sum_Fields>;
  var_pop?: Maybe<HabitDay_Var_Pop_Fields>;
  var_samp?: Maybe<HabitDay_Var_Samp_Fields>;
  variance?: Maybe<HabitDay_Variance_Fields>;
};


/** aggregate fields of "HabitDay" */
export type HabitDay_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HabitDay_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type HabitDay_Avg_Fields = {
  __typename?: 'HabitDay_avg_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "HabitDay". All fields are combined with a logical 'AND'. */
export type HabitDay_Bool_Exp = {
  _and?: InputMaybe<Array<HabitDay_Bool_Exp>>;
  _not?: InputMaybe<HabitDay_Bool_Exp>;
  _or?: InputMaybe<Array<HabitDay_Bool_Exp>>;
  completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  custom_congrats?: InputMaybe<String_Comparison_Exp>;
  custom_try_again?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  goal_value?: InputMaybe<Int_Comparison_Exp>;
  habit_type?: InputMaybe<HabitType_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  long_goal?: InputMaybe<String_Comparison_Exp>;
  max_checkin_time?: InputMaybe<Int_Comparison_Exp>;
  medium_goal?: InputMaybe<String_Comparison_Exp>;
  min_checkin_time?: InputMaybe<Int_Comparison_Exp>;
  ordinal?: InputMaybe<Int_Comparison_Exp>;
  short_goal?: InputMaybe<String_Comparison_Exp>;
  sub_type?: InputMaybe<HabitSubType_Comparison_Exp>;
  submission_value_bool?: InputMaybe<Boolean_Comparison_Exp>;
  submission_value_int?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_habit_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "HabitDay" */
export type HabitDay_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'HabitDay_pkey'
  /** unique or primary key constraint on columns "user_id", "date" */
  | 'HabitDay_user_id_date_key';

/** input type for incrementing numeric columns in table "HabitDay" */
export type HabitDay_Inc_Input = {
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  submission_value_int?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "HabitDay" */
export type HabitDay_Insert_Input = {
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  submission_value_bool?: InputMaybe<Scalars['Boolean']['input']>;
  submission_value_int?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_habit_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type HabitDay_Max_Fields = {
  __typename?: 'HabitDay_max_fields';
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<Scalars['HabitType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<Scalars['HabitSubType']['output']>;
  submission_value_int?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_habit_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type HabitDay_Min_Fields = {
  __typename?: 'HabitDay_min_fields';
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<Scalars['HabitType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<Scalars['HabitSubType']['output']>;
  submission_value_int?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_habit_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "HabitDay" */
export type HabitDay_Mutation_Response = {
  __typename?: 'HabitDay_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<HabitDay>;
};

/** on_conflict condition type for table "HabitDay" */
export type HabitDay_On_Conflict = {
  constraint: HabitDay_Constraint;
  update_columns?: Array<HabitDay_Update_Column>;
  where?: InputMaybe<HabitDay_Bool_Exp>;
};

/** Ordering options when selecting data from "HabitDay". */
export type HabitDay_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_congrats?: InputMaybe<Order_By>;
  custom_try_again?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  goal_value?: InputMaybe<Order_By>;
  habit_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  long_goal?: InputMaybe<Order_By>;
  max_checkin_time?: InputMaybe<Order_By>;
  medium_goal?: InputMaybe<Order_By>;
  min_checkin_time?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  short_goal?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  submission_value_bool?: InputMaybe<Order_By>;
  submission_value_int?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_habit_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: HabitDay */
export type HabitDay_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "HabitDay" */
export type HabitDay_Select_Column =
  /** column name */
  | 'completed_at'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_congrats'
  /** column name */
  | 'custom_try_again'
  /** column name */
  | 'date'
  /** column name */
  | 'goal_value'
  /** column name */
  | 'habit_type'
  /** column name */
  | 'id'
  /** column name */
  | 'long_goal'
  /** column name */
  | 'max_checkin_time'
  /** column name */
  | 'medium_goal'
  /** column name */
  | 'min_checkin_time'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'short_goal'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'submission_value_bool'
  /** column name */
  | 'submission_value_int'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_habit_id'
  /** column name */
  | 'user_id';

/** input type for updating data in table "HabitDay" */
export type HabitDay_Set_Input = {
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  submission_value_bool?: InputMaybe<Scalars['Boolean']['input']>;
  submission_value_int?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_habit_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type HabitDay_Stddev_Fields = {
  __typename?: 'HabitDay_stddev_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type HabitDay_Stddev_Pop_Fields = {
  __typename?: 'HabitDay_stddev_pop_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type HabitDay_Stddev_Samp_Fields = {
  __typename?: 'HabitDay_stddev_samp_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "HabitDay" */
export type HabitDay_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HabitDay_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HabitDay_Stream_Cursor_Value_Input = {
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  submission_value_bool?: InputMaybe<Scalars['Boolean']['input']>;
  submission_value_int?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_habit_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type HabitDay_Sum_Fields = {
  __typename?: 'HabitDay_sum_fields';
  goal_value?: Maybe<Scalars['Int']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  submission_value_int?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "HabitDay" */
export type HabitDay_Update_Column =
  /** column name */
  | 'completed_at'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_congrats'
  /** column name */
  | 'custom_try_again'
  /** column name */
  | 'date'
  /** column name */
  | 'goal_value'
  /** column name */
  | 'habit_type'
  /** column name */
  | 'id'
  /** column name */
  | 'long_goal'
  /** column name */
  | 'max_checkin_time'
  /** column name */
  | 'medium_goal'
  /** column name */
  | 'min_checkin_time'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'short_goal'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'submission_value_bool'
  /** column name */
  | 'submission_value_int'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_habit_id'
  /** column name */
  | 'user_id';

export type HabitDay_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HabitDay_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HabitDay_Set_Input>;
  /** filter the rows which have to be updated */
  where: HabitDay_Bool_Exp;
};

/** aggregate var_pop on columns */
export type HabitDay_Var_Pop_Fields = {
  __typename?: 'HabitDay_var_pop_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type HabitDay_Var_Samp_Fields = {
  __typename?: 'HabitDay_var_samp_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type HabitDay_Variance_Fields = {
  __typename?: 'HabitDay_variance_fields';
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
  ordinal?: Maybe<Scalars['Float']['output']>;
  submission_value_int?: Maybe<Scalars['Float']['output']>;
};

export type HabitSubTypeEnum =
  | 'ACTIVE_CAL_GTE'
  | 'SEE_COACH'
  | 'START_SLEEP_GTE'
  | 'START_SLEEP_LTE'
  | 'STEPS_GTE'
  | 'TRUE_OR_FALSE'
  | 'WEAR_SIGNAL_TO_SLEEP';

/** Boolean expression to compare columns of type "HabitSubType". All fields are combined with logical 'AND'. */
export type HabitSubType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['HabitSubType']['input']>;
  _gt?: InputMaybe<Scalars['HabitSubType']['input']>;
  _gte?: InputMaybe<Scalars['HabitSubType']['input']>;
  _in?: InputMaybe<Array<Scalars['HabitSubType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['HabitSubType']['input']>;
  _lte?: InputMaybe<Scalars['HabitSubType']['input']>;
  _neq?: InputMaybe<Scalars['HabitSubType']['input']>;
  _nin?: InputMaybe<Array<Scalars['HabitSubType']['input']>>;
};

export type HabitTypeEnum =
  | 'ACTIVITY'
  | 'CUSTOM'
  | 'NUTRITION'
  | 'SLEEP';

/** Boolean expression to compare columns of type "HabitType". All fields are combined with logical 'AND'. */
export type HabitType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['HabitType']['input']>;
  _gt?: InputMaybe<Scalars['HabitType']['input']>;
  _gte?: InputMaybe<Scalars['HabitType']['input']>;
  _in?: InputMaybe<Array<Scalars['HabitType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['HabitType']['input']>;
  _lte?: InputMaybe<Scalars['HabitType']['input']>;
  _neq?: InputMaybe<Scalars['HabitType']['input']>;
  _nin?: InputMaybe<Array<Scalars['HabitType']['input']>>;
};

export type HardwareTypeEnum =
  | 'V1'
  | 'V2';

/** columns and relationships of "HeartRateDiagnostics" */
export type HeartRateDiagnostics = {
  __typename?: 'HeartRateDiagnostics';
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserWorkout?: Maybe<UserWorkout>;
  batch_id?: Maybe<Scalars['String']['output']>;
  confidence?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamp']['output'];
  device?: Maybe<Scalars['DeviceType']['output']>;
  hr_timestamp?: Maybe<Scalars['String']['output']>;
  hr_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
  user_workout_id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Aggregate = {
  __typename?: 'HeartRateDiagnostics_aggregate';
  aggregate?: Maybe<HeartRateDiagnostics_Aggregate_Fields>;
  nodes: Array<HeartRateDiagnostics>;
};

export type HeartRateDiagnostics_Aggregate_Bool_Exp = {
  count?: InputMaybe<HeartRateDiagnostics_Aggregate_Bool_Exp_Count>;
};

export type HeartRateDiagnostics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Aggregate_Fields = {
  __typename?: 'HeartRateDiagnostics_aggregate_fields';
  avg?: Maybe<HeartRateDiagnostics_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<HeartRateDiagnostics_Max_Fields>;
  min?: Maybe<HeartRateDiagnostics_Min_Fields>;
  stddev?: Maybe<HeartRateDiagnostics_Stddev_Fields>;
  stddev_pop?: Maybe<HeartRateDiagnostics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HeartRateDiagnostics_Stddev_Samp_Fields>;
  sum?: Maybe<HeartRateDiagnostics_Sum_Fields>;
  var_pop?: Maybe<HeartRateDiagnostics_Var_Pop_Fields>;
  var_samp?: Maybe<HeartRateDiagnostics_Var_Samp_Fields>;
  variance?: Maybe<HeartRateDiagnostics_Variance_Fields>;
};


/** aggregate fields of "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Aggregate_Order_By = {
  avg?: InputMaybe<HeartRateDiagnostics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<HeartRateDiagnostics_Max_Order_By>;
  min?: InputMaybe<HeartRateDiagnostics_Min_Order_By>;
  stddev?: InputMaybe<HeartRateDiagnostics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<HeartRateDiagnostics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<HeartRateDiagnostics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<HeartRateDiagnostics_Sum_Order_By>;
  var_pop?: InputMaybe<HeartRateDiagnostics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<HeartRateDiagnostics_Var_Samp_Order_By>;
  variance?: InputMaybe<HeartRateDiagnostics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Arr_Rel_Insert_Input = {
  data: Array<HeartRateDiagnostics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<HeartRateDiagnostics_On_Conflict>;
};

/** aggregate avg on columns */
export type HeartRateDiagnostics_Avg_Fields = {
  __typename?: 'HeartRateDiagnostics_avg_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Avg_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "HeartRateDiagnostics". All fields are combined with a logical 'AND'. */
export type HeartRateDiagnostics_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserWorkout?: InputMaybe<UserWorkout_Bool_Exp>;
  _and?: InputMaybe<Array<HeartRateDiagnostics_Bool_Exp>>;
  _not?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
  _or?: InputMaybe<Array<HeartRateDiagnostics_Bool_Exp>>;
  batch_id?: InputMaybe<String_Comparison_Exp>;
  confidence?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  device?: InputMaybe<DeviceType_Comparison_Exp>;
  hr_timestamp?: InputMaybe<String_Comparison_Exp>;
  hr_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_workout_id?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'HeartRateDiagnostics_pkey';

/** input type for incrementing numeric columns in table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Inc_Input = {
  confidence?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserWorkout?: InputMaybe<UserWorkout_Obj_Rel_Insert_Input>;
  batch_id?: InputMaybe<Scalars['String']['input']>;
  confidence?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  device?: InputMaybe<Scalars['DeviceType']['input']>;
  hr_timestamp?: InputMaybe<Scalars['String']['input']>;
  hr_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type HeartRateDiagnostics_Max_Fields = {
  __typename?: 'HeartRateDiagnostics_max_fields';
  batch_id?: Maybe<Scalars['String']['output']>;
  confidence?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  device?: Maybe<Scalars['DeviceType']['output']>;
  hr_timestamp?: Maybe<Scalars['String']['output']>;
  hr_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Max_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device?: InputMaybe<Order_By>;
  hr_timestamp?: InputMaybe<Order_By>;
  hr_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type HeartRateDiagnostics_Min_Fields = {
  __typename?: 'HeartRateDiagnostics_min_fields';
  batch_id?: Maybe<Scalars['String']['output']>;
  confidence?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  device?: Maybe<Scalars['DeviceType']['output']>;
  hr_timestamp?: Maybe<Scalars['String']['output']>;
  hr_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Min_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device?: InputMaybe<Order_By>;
  hr_timestamp?: InputMaybe<Order_By>;
  hr_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Mutation_Response = {
  __typename?: 'HeartRateDiagnostics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<HeartRateDiagnostics>;
};

/** on_conflict condition type for table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_On_Conflict = {
  constraint: HeartRateDiagnostics_Constraint;
  update_columns?: Array<HeartRateDiagnostics_Update_Column>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};

/** Ordering options when selecting data from "HeartRateDiagnostics". */
export type HeartRateDiagnostics_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserWorkout?: InputMaybe<UserWorkout_Order_By>;
  batch_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device?: InputMaybe<Order_By>;
  hr_timestamp?: InputMaybe<Order_By>;
  hr_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: HeartRateDiagnostics */
export type HeartRateDiagnostics_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Select_Column =
  /** column name */
  | 'batch_id'
  /** column name */
  | 'confidence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'device'
  /** column name */
  | 'hr_timestamp'
  /** column name */
  | 'hr_type'
  /** column name */
  | 'id'
  /** column name */
  | 'timestamp'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id'
  /** column name */
  | 'value';

/** input type for updating data in table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Set_Input = {
  batch_id?: InputMaybe<Scalars['String']['input']>;
  confidence?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  device?: InputMaybe<Scalars['DeviceType']['input']>;
  hr_timestamp?: InputMaybe<Scalars['String']['input']>;
  hr_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type HeartRateDiagnostics_Stddev_Fields = {
  __typename?: 'HeartRateDiagnostics_stddev_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Stddev_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type HeartRateDiagnostics_Stddev_Pop_Fields = {
  __typename?: 'HeartRateDiagnostics_stddev_pop_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Stddev_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type HeartRateDiagnostics_Stddev_Samp_Fields = {
  __typename?: 'HeartRateDiagnostics_stddev_samp_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Stddev_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HeartRateDiagnostics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HeartRateDiagnostics_Stream_Cursor_Value_Input = {
  batch_id?: InputMaybe<Scalars['String']['input']>;
  confidence?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  device?: InputMaybe<Scalars['DeviceType']['input']>;
  hr_timestamp?: InputMaybe<Scalars['String']['input']>;
  hr_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type HeartRateDiagnostics_Sum_Fields = {
  __typename?: 'HeartRateDiagnostics_sum_fields';
  confidence?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Sum_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Update_Column =
  /** column name */
  | 'batch_id'
  /** column name */
  | 'confidence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'device'
  /** column name */
  | 'hr_timestamp'
  /** column name */
  | 'hr_type'
  /** column name */
  | 'id'
  /** column name */
  | 'timestamp'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id'
  /** column name */
  | 'value';

export type HeartRateDiagnostics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HeartRateDiagnostics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HeartRateDiagnostics_Set_Input>;
  /** filter the rows which have to be updated */
  where: HeartRateDiagnostics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type HeartRateDiagnostics_Var_Pop_Fields = {
  __typename?: 'HeartRateDiagnostics_var_pop_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Var_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type HeartRateDiagnostics_Var_Samp_Fields = {
  __typename?: 'HeartRateDiagnostics_var_samp_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Var_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type HeartRateDiagnostics_Variance_Fields = {
  __typename?: 'HeartRateDiagnostics_variance_fields';
  confidence?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "HeartRateDiagnostics" */
export type HeartRateDiagnostics_Variance_Order_By = {
  confidence?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type HighKneeSetOutput = {
  __typename?: 'HighKneeSetOutput';
  high_knee_level?: Maybe<Scalars['Int']['output']>;
  max_hr?: Maybe<Scalars['Int']['output']>;
  total_high_knees?: Maybe<Scalars['Int']['output']>;
};

export type HistoryAndCounts = {
  __typename?: 'HistoryAndCounts';
  counts?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  history?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

/** columns and relationships of "Household" */
export type Household = {
  __typename?: 'Household';
  /** An object relationship */
  Subscription?: Maybe<Subscription>;
  allocated_num_users: Scalars['Int']['output'];
  cadence: Scalars['CadenceType']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An array relationship */
  members: Array<User>;
  /** An aggregate relationship */
  members_aggregate: User_Aggregate;
  /** An object relationship */
  owner?: Maybe<User>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  required_tokens: Scalars['Int']['output'];
  subscription_id?: Maybe<Scalars['String']['output']>;
  type: Scalars['HouseholdType']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "Household" */
export type HouseholdMembersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Household" */
export type HouseholdMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type HouseholdOutput = {
  __typename?: 'HouseholdOutput';
  cadence?: Maybe<CadenceTypeEnum>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  recurring_amount?: Maybe<Scalars['Int']['output']>;
  required_tokens_each?: Maybe<Scalars['Int']['output']>;
  required_tokens_total?: Maybe<Scalars['Int']['output']>;
  tokens?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  weekly_tokens?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to compare columns of type "HouseholdType". All fields are combined with logical 'AND'. */
export type HouseholdType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['HouseholdType']['input']>;
  _gt?: InputMaybe<Scalars['HouseholdType']['input']>;
  _gte?: InputMaybe<Scalars['HouseholdType']['input']>;
  _in?: InputMaybe<Array<Scalars['HouseholdType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['HouseholdType']['input']>;
  _lte?: InputMaybe<Scalars['HouseholdType']['input']>;
  _neq?: InputMaybe<Scalars['HouseholdType']['input']>;
  _nin?: InputMaybe<Array<Scalars['HouseholdType']['input']>>;
};

/** aggregated selection of "Household" */
export type Household_Aggregate = {
  __typename?: 'Household_aggregate';
  aggregate?: Maybe<Household_Aggregate_Fields>;
  nodes: Array<Household>;
};

export type Household_Aggregate_Bool_Exp = {
  count?: InputMaybe<Household_Aggregate_Bool_Exp_Count>;
};

export type Household_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Household_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Household_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Household" */
export type Household_Aggregate_Fields = {
  __typename?: 'Household_aggregate_fields';
  avg?: Maybe<Household_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Household_Max_Fields>;
  min?: Maybe<Household_Min_Fields>;
  stddev?: Maybe<Household_Stddev_Fields>;
  stddev_pop?: Maybe<Household_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Household_Stddev_Samp_Fields>;
  sum?: Maybe<Household_Sum_Fields>;
  var_pop?: Maybe<Household_Var_Pop_Fields>;
  var_samp?: Maybe<Household_Var_Samp_Fields>;
  variance?: Maybe<Household_Variance_Fields>;
};


/** aggregate fields of "Household" */
export type Household_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Household_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Household" */
export type Household_Aggregate_Order_By = {
  avg?: InputMaybe<Household_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Household_Max_Order_By>;
  min?: InputMaybe<Household_Min_Order_By>;
  stddev?: InputMaybe<Household_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Household_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Household_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Household_Sum_Order_By>;
  var_pop?: InputMaybe<Household_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Household_Var_Samp_Order_By>;
  variance?: InputMaybe<Household_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Household" */
export type Household_Arr_Rel_Insert_Input = {
  data: Array<Household_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Household_On_Conflict>;
};

/** aggregate avg on columns */
export type Household_Avg_Fields = {
  __typename?: 'Household_avg_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "Household" */
export type Household_Avg_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Household". All fields are combined with a logical 'AND'. */
export type Household_Bool_Exp = {
  Subscription?: InputMaybe<Subscription_Bool_Exp>;
  _and?: InputMaybe<Array<Household_Bool_Exp>>;
  _not?: InputMaybe<Household_Bool_Exp>;
  _or?: InputMaybe<Array<Household_Bool_Exp>>;
  allocated_num_users?: InputMaybe<Int_Comparison_Exp>;
  cadence?: InputMaybe<CadenceType_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  members?: InputMaybe<User_Bool_Exp>;
  members_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
  owner_user_id?: InputMaybe<String_Comparison_Exp>;
  required_tokens?: InputMaybe<Int_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<HouseholdType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Household" */
export type Household_Constraint =
  /** unique or primary key constraint on columns "owner_user_id" */
  | 'Household_owner_user_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'Household_pkey';

/** input type for incrementing numeric columns in table "Household" */
export type Household_Inc_Input = {
  allocated_num_users?: InputMaybe<Scalars['Int']['input']>;
  required_tokens?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Household" */
export type Household_Insert_Input = {
  Subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  allocated_num_users?: InputMaybe<Scalars['Int']['input']>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<User_Arr_Rel_Insert_Input>;
  owner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  required_tokens?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Household_Max_Fields = {
  __typename?: 'Household_max_fields';
  allocated_num_users?: Maybe<Scalars['Int']['output']>;
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  required_tokens?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['HouseholdType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "Household" */
export type Household_Max_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Household_Min_Fields = {
  __typename?: 'Household_min_fields';
  allocated_num_users?: Maybe<Scalars['Int']['output']>;
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  required_tokens?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['HouseholdType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "Household" */
export type Household_Min_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Household" */
export type Household_Mutation_Response = {
  __typename?: 'Household_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Household>;
};

/** input type for inserting object relation for remote table "Household" */
export type Household_Obj_Rel_Insert_Input = {
  data: Household_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Household_On_Conflict>;
};

/** on_conflict condition type for table "Household" */
export type Household_On_Conflict = {
  constraint: Household_Constraint;
  update_columns?: Array<Household_Update_Column>;
  where?: InputMaybe<Household_Bool_Exp>;
};

/** Ordering options when selecting data from "Household". */
export type Household_Order_By = {
  Subscription?: InputMaybe<Subscription_Order_By>;
  allocated_num_users?: InputMaybe<Order_By>;
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  owner?: InputMaybe<User_Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Household */
export type Household_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Household" */
export type Household_Select_Column =
  /** column name */
  | 'allocated_num_users'
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'owner_user_id'
  /** column name */
  | 'required_tokens'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Household" */
export type Household_Set_Input = {
  allocated_num_users?: InputMaybe<Scalars['Int']['input']>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  required_tokens?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Household_Stddev_Fields = {
  __typename?: 'Household_stddev_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "Household" */
export type Household_Stddev_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Household_Stddev_Pop_Fields = {
  __typename?: 'Household_stddev_pop_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "Household" */
export type Household_Stddev_Pop_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Household_Stddev_Samp_Fields = {
  __typename?: 'Household_stddev_samp_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "Household" */
export type Household_Stddev_Samp_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Household" */
export type Household_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Household_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Household_Stream_Cursor_Value_Input = {
  allocated_num_users?: InputMaybe<Scalars['Int']['input']>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  required_tokens?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Household_Sum_Fields = {
  __typename?: 'Household_sum_fields';
  allocated_num_users?: Maybe<Scalars['Int']['output']>;
  required_tokens?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "Household" */
export type Household_Sum_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** update columns of table "Household" */
export type Household_Update_Column =
  /** column name */
  | 'allocated_num_users'
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'owner_user_id'
  /** column name */
  | 'required_tokens'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

export type Household_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Household_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Household_Set_Input>;
  /** filter the rows which have to be updated */
  where: Household_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Household_Var_Pop_Fields = {
  __typename?: 'Household_var_pop_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "Household" */
export type Household_Var_Pop_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Household_Var_Samp_Fields = {
  __typename?: 'Household_var_samp_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "Household" */
export type Household_Var_Samp_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Household_Variance_Fields = {
  __typename?: 'Household_variance_fields';
  allocated_num_users?: Maybe<Scalars['Float']['output']>;
  required_tokens?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "Household" */
export type Household_Variance_Order_By = {
  allocated_num_users?: InputMaybe<Order_By>;
  required_tokens?: InputMaybe<Order_By>;
};

export type HrZone2Output = {
  __typename?: 'HrZone2Output';
  alter_zone_max?: Maybe<Scalars['Int']['output']>;
  alter_zone_min?: Maybe<Scalars['Int']['output']>;
  mhr?: Maybe<Scalars['Int']['output']>;
  rhr_month?: Maybe<Scalars['Int']['output']>;
};

export type InsightsDetailGroupBy =
  | 'Day'
  | 'Month'
  | 'Week'
  | 'Year';

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IsValidKitOutput = {
  __typename?: 'IsValidKitOutput';
  activationCode?: Maybe<Scalars['String']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  kitId?: Maybe<Scalars['String']['output']>;
};

export type LabelsOutput = {
  __typename?: 'LabelsOutput';
  activity?: Maybe<Array<Maybe<ValLabels>>>;
  equipments_owned?: Maybe<Array<Maybe<ValLabels>>>;
  favorite_workouts?: Maybe<Array<Maybe<ValLabels>>>;
  hows_your_sleep?: Maybe<Array<Maybe<ValLabels>>>;
  main_motivation?: Maybe<Array<Maybe<ValLabels>>>;
};

/** columns and relationships of "MarketingEmail" */
export type MarketingEmail = {
  __typename?: 'MarketingEmail';
  createdAt: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['timestamp']['output'];
};

/** aggregated selection of "MarketingEmail" */
export type MarketingEmail_Aggregate = {
  __typename?: 'MarketingEmail_aggregate';
  aggregate?: Maybe<MarketingEmail_Aggregate_Fields>;
  nodes: Array<MarketingEmail>;
};

/** aggregate fields of "MarketingEmail" */
export type MarketingEmail_Aggregate_Fields = {
  __typename?: 'MarketingEmail_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<MarketingEmail_Max_Fields>;
  min?: Maybe<MarketingEmail_Min_Fields>;
};


/** aggregate fields of "MarketingEmail" */
export type MarketingEmail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketingEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "MarketingEmail". All fields are combined with a logical 'AND'. */
export type MarketingEmail_Bool_Exp = {
  _and?: InputMaybe<Array<MarketingEmail_Bool_Exp>>;
  _not?: InputMaybe<MarketingEmail_Bool_Exp>;
  _or?: InputMaybe<Array<MarketingEmail_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  subscribed?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "MarketingEmail" */
export type MarketingEmail_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'MarketingEmail_pkey';

/** input type for inserting data into table "MarketingEmail" */
export type MarketingEmail_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type MarketingEmail_Max_Fields = {
  __typename?: 'MarketingEmail_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type MarketingEmail_Min_Fields = {
  __typename?: 'MarketingEmail_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "MarketingEmail" */
export type MarketingEmail_Mutation_Response = {
  __typename?: 'MarketingEmail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketingEmail>;
};

/** on_conflict condition type for table "MarketingEmail" */
export type MarketingEmail_On_Conflict = {
  constraint: MarketingEmail_Constraint;
  update_columns?: Array<MarketingEmail_Update_Column>;
  where?: InputMaybe<MarketingEmail_Bool_Exp>;
};

/** Ordering options when selecting data from "MarketingEmail". */
export type MarketingEmail_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  subscribed?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: MarketingEmail */
export type MarketingEmail_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "MarketingEmail" */
export type MarketingEmail_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'email'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'subscribed'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "MarketingEmail" */
export type MarketingEmail_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "MarketingEmail" */
export type MarketingEmail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketingEmail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketingEmail_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "MarketingEmail" */
export type MarketingEmail_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'email'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'subscribed'
  /** column name */
  | 'updatedAt';

export type MarketingEmail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketingEmail_Set_Input>;
  /** filter the rows which have to be updated */
  where: MarketingEmail_Bool_Exp;
};

/** columns and relationships of "Mastery" */
export type Mastery = {
  __typename?: 'Mastery';
  /** An array relationship */
  UserSets: Array<UserSet>;
  /** An aggregate relationship */
  UserSets_aggregate: UserSet_Aggregate;
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  min_percentage_reps: Scalars['float8']['output'];
  weighted_impact: Scalars['float8']['output'];
  workout_category: Scalars['WorkoutCategory']['output'];
  workouts_to_advance: Scalars['Int']['output'];
};


/** columns and relationships of "Mastery" */
export type MasteryUserSetsArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "Mastery" */
export type MasteryUserSets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};

/** aggregated selection of "Mastery" */
export type Mastery_Aggregate = {
  __typename?: 'Mastery_aggregate';
  aggregate?: Maybe<Mastery_Aggregate_Fields>;
  nodes: Array<Mastery>;
};

/** aggregate fields of "Mastery" */
export type Mastery_Aggregate_Fields = {
  __typename?: 'Mastery_aggregate_fields';
  avg?: Maybe<Mastery_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mastery_Max_Fields>;
  min?: Maybe<Mastery_Min_Fields>;
  stddev?: Maybe<Mastery_Stddev_Fields>;
  stddev_pop?: Maybe<Mastery_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mastery_Stddev_Samp_Fields>;
  sum?: Maybe<Mastery_Sum_Fields>;
  var_pop?: Maybe<Mastery_Var_Pop_Fields>;
  var_samp?: Maybe<Mastery_Var_Samp_Fields>;
  variance?: Maybe<Mastery_Variance_Fields>;
};


/** aggregate fields of "Mastery" */
export type Mastery_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mastery_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mastery_Avg_Fields = {
  __typename?: 'Mastery_avg_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Mastery". All fields are combined with a logical 'AND'. */
export type Mastery_Bool_Exp = {
  UserSets?: InputMaybe<UserSet_Bool_Exp>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Mastery_Bool_Exp>>;
  _not?: InputMaybe<Mastery_Bool_Exp>;
  _or?: InputMaybe<Array<Mastery_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  min_percentage_reps?: InputMaybe<Float8_Comparison_Exp>;
  weighted_impact?: InputMaybe<Float8_Comparison_Exp>;
  workout_category?: InputMaybe<WorkoutCategory_Comparison_Exp>;
  workouts_to_advance?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Mastery" */
export type Mastery_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Mastery_pkey'
  /** unique or primary key constraint on columns "level", "workout_category" */
  | 'Mastery_workout_category_level_key';

/** input type for incrementing numeric columns in table "Mastery" */
export type Mastery_Inc_Input = {
  level?: InputMaybe<Scalars['Int']['input']>;
  min_percentage_reps?: InputMaybe<Scalars['float8']['input']>;
  weighted_impact?: InputMaybe<Scalars['float8']['input']>;
  workouts_to_advance?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Mastery" */
export type Mastery_Insert_Input = {
  UserSets?: InputMaybe<UserSet_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  min_percentage_reps?: InputMaybe<Scalars['float8']['input']>;
  weighted_impact?: InputMaybe<Scalars['float8']['input']>;
  workout_category?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  workouts_to_advance?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mastery_Max_Fields = {
  __typename?: 'Mastery_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  min_percentage_reps?: Maybe<Scalars['float8']['output']>;
  weighted_impact?: Maybe<Scalars['float8']['output']>;
  workout_category?: Maybe<Scalars['WorkoutCategory']['output']>;
  workouts_to_advance?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mastery_Min_Fields = {
  __typename?: 'Mastery_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  min_percentage_reps?: Maybe<Scalars['float8']['output']>;
  weighted_impact?: Maybe<Scalars['float8']['output']>;
  workout_category?: Maybe<Scalars['WorkoutCategory']['output']>;
  workouts_to_advance?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "Mastery" */
export type Mastery_Mutation_Response = {
  __typename?: 'Mastery_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mastery>;
};

/** input type for inserting object relation for remote table "Mastery" */
export type Mastery_Obj_Rel_Insert_Input = {
  data: Mastery_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mastery_On_Conflict>;
};

/** on_conflict condition type for table "Mastery" */
export type Mastery_On_Conflict = {
  constraint: Mastery_Constraint;
  update_columns?: Array<Mastery_Update_Column>;
  where?: InputMaybe<Mastery_Bool_Exp>;
};

/** Ordering options when selecting data from "Mastery". */
export type Mastery_Order_By = {
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  min_percentage_reps?: InputMaybe<Order_By>;
  weighted_impact?: InputMaybe<Order_By>;
  workout_category?: InputMaybe<Order_By>;
  workouts_to_advance?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Mastery */
export type Mastery_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Mastery" */
export type Mastery_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'level'
  /** column name */
  | 'min_percentage_reps'
  /** column name */
  | 'weighted_impact'
  /** column name */
  | 'workout_category'
  /** column name */
  | 'workouts_to_advance';

/** input type for updating data in table "Mastery" */
export type Mastery_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  min_percentage_reps?: InputMaybe<Scalars['float8']['input']>;
  weighted_impact?: InputMaybe<Scalars['float8']['input']>;
  workout_category?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  workouts_to_advance?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mastery_Stddev_Fields = {
  __typename?: 'Mastery_stddev_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mastery_Stddev_Pop_Fields = {
  __typename?: 'Mastery_stddev_pop_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mastery_Stddev_Samp_Fields = {
  __typename?: 'Mastery_stddev_samp_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "Mastery" */
export type Mastery_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mastery_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mastery_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  min_percentage_reps?: InputMaybe<Scalars['float8']['input']>;
  weighted_impact?: InputMaybe<Scalars['float8']['input']>;
  workout_category?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  workouts_to_advance?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mastery_Sum_Fields = {
  __typename?: 'Mastery_sum_fields';
  level?: Maybe<Scalars['Int']['output']>;
  min_percentage_reps?: Maybe<Scalars['float8']['output']>;
  weighted_impact?: Maybe<Scalars['float8']['output']>;
  workouts_to_advance?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "Mastery" */
export type Mastery_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'level'
  /** column name */
  | 'min_percentage_reps'
  /** column name */
  | 'weighted_impact'
  /** column name */
  | 'workout_category'
  /** column name */
  | 'workouts_to_advance';

export type Mastery_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mastery_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mastery_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mastery_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mastery_Var_Pop_Fields = {
  __typename?: 'Mastery_var_pop_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mastery_Var_Samp_Fields = {
  __typename?: 'Mastery_var_samp_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mastery_Variance_Fields = {
  __typename?: 'Mastery_variance_fields';
  level?: Maybe<Scalars['Float']['output']>;
  min_percentage_reps?: Maybe<Scalars['Float']['output']>;
  weighted_impact?: Maybe<Scalars['Float']['output']>;
  workouts_to_advance?: Maybe<Scalars['Float']['output']>;
};

export type MealKeyEnum =
  | 'afternoon_snack'
  | 'breakfast'
  | 'dinner'
  | 'evening_snack'
  | 'lunch'
  | 'morning_snack'
  | 'snack_1'
  | 'snack_2';

export type MealPlan = {
  __typename?: 'MealPlan';
  created_at?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  generated_on_demand?: Maybe<Scalars['Boolean']['output']>;
  generative_inputs?: Maybe<Scalars['json']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['json']['output']>;
  regenerate_count?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  total_retry_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  usage?: Maybe<Scalars['json']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type MealPlanOutput = {
  __typename?: 'MealPlanOutput';
  plan?: Maybe<MealPlan>;
};

export type MealPlanPreferenceMapItem = {
  __typename?: 'MealPlanPreferenceMapItem';
  category?: Maybe<MealPlanPreferenceOptionCategoryEnum>;
  description?: Maybe<Scalars['String']['output']>;
  display_title?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MealPlanPreferenceOptionCategoryEnum =
  | 'allergies'
  | 'intolerances'
  | 'protein_preferences'
  | 'restrictions';

export type MealPlanPreferenceOutput = {
  __typename?: 'MealPlanPreferenceOutput';
  preferences?: Maybe<Array<Maybe<MealPlanPreferenceMapItem>>>;
};

export type MealPlanPreferences = {
  __typename?: 'MealPlanPreferences';
  preferences?: Maybe<Scalars['json']['output']>;
};

export type MealPlanSelectionTarget = {
  selection_status: Scalars['String']['input'];
  target_meal_key: Scalars['String']['input'];
  target_plan_index: Scalars['Int']['input'];
};

export type NotInPlanType = {
  __typename?: 'NotInPlanType';
  cardio?: Maybe<Scalars['Int']['output']>;
  strength?: Maybe<Scalars['Int']['output']>;
  workin?: Maybe<Scalars['Int']['output']>;
};

export type NutritionReportOutput = {
  __typename?: 'NutritionReportOutput';
  active_calories?: Maybe<Scalars['Int']['output']>;
  calorie_deficit?: Maybe<Scalars['Int']['output']>;
  default_nutrients_category?: Maybe<Scalars['Int']['output']>;
  diet_contents?: Maybe<DietContents>;
  diet_name?: Maybe<Scalars['String']['output']>;
  goalWeight?: Maybe<Scalars['Int']['output']>;
  grams_recommended_carbs?: Maybe<Scalars['Int']['output']>;
  grams_recommended_fats?: Maybe<Scalars['Int']['output']>;
  grams_recommended_proteins?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  inflammation_risk?: Maybe<Scalars['String']['output']>;
  nutrients_category?: Maybe<Scalars['Int']['output']>;
  nutrition_goal?: Maybe<Scalars['String']['output']>;
  nutrition_tip?: Maybe<Scalars['String']['output']>;
  percent_recommended_carbs?: Maybe<Scalars['Int']['output']>;
  percent_recommended_fats?: Maybe<Scalars['Int']['output']>;
  percent_recommended_proteins?: Maybe<Scalars['Int']['output']>;
  recommended_daily_calories?: Maybe<Scalars['Int']['output']>;
  resting_calories?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['String']['output'];
  weight_lbs?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to compare columns of type "OnboardingEntityType". All fields are combined with logical 'AND'. */
export type OnboardingEntityType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  _gt?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  _gte?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  _in?: InputMaybe<Array<Scalars['OnboardingEntityType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  _lte?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  _neq?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  _nin?: InputMaybe<Array<Scalars['OnboardingEntityType']['input']>>;
};

/** columns and relationships of "OnboardingInfo" */
export type OnboardingInfo = {
  __typename?: 'OnboardingInfo';
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['OnboardingEntityType']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "OnboardingInfo" */
export type OnboardingInfo_Aggregate = {
  __typename?: 'OnboardingInfo_aggregate';
  aggregate?: Maybe<OnboardingInfo_Aggregate_Fields>;
  nodes: Array<OnboardingInfo>;
};

/** aggregate fields of "OnboardingInfo" */
export type OnboardingInfo_Aggregate_Fields = {
  __typename?: 'OnboardingInfo_aggregate_fields';
  avg?: Maybe<OnboardingInfo_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OnboardingInfo_Max_Fields>;
  min?: Maybe<OnboardingInfo_Min_Fields>;
  stddev?: Maybe<OnboardingInfo_Stddev_Fields>;
  stddev_pop?: Maybe<OnboardingInfo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OnboardingInfo_Stddev_Samp_Fields>;
  sum?: Maybe<OnboardingInfo_Sum_Fields>;
  var_pop?: Maybe<OnboardingInfo_Var_Pop_Fields>;
  var_samp?: Maybe<OnboardingInfo_Var_Samp_Fields>;
  variance?: Maybe<OnboardingInfo_Variance_Fields>;
};


/** aggregate fields of "OnboardingInfo" */
export type OnboardingInfo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OnboardingInfo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OnboardingInfo_Avg_Fields = {
  __typename?: 'OnboardingInfo_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "OnboardingInfo". All fields are combined with a logical 'AND'. */
export type OnboardingInfo_Bool_Exp = {
  _and?: InputMaybe<Array<OnboardingInfo_Bool_Exp>>;
  _not?: InputMaybe<OnboardingInfo_Bool_Exp>;
  _or?: InputMaybe<Array<OnboardingInfo_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<OnboardingEntityType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "OnboardingInfo" */
export type OnboardingInfo_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'OnboardingInfo_pkey';

/** input type for incrementing numeric columns in table "OnboardingInfo" */
export type OnboardingInfo_Inc_Input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "OnboardingInfo" */
export type OnboardingInfo_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type OnboardingInfo_Max_Fields = {
  __typename?: 'OnboardingInfo_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['OnboardingEntityType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type OnboardingInfo_Min_Fields = {
  __typename?: 'OnboardingInfo_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['OnboardingEntityType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "OnboardingInfo" */
export type OnboardingInfo_Mutation_Response = {
  __typename?: 'OnboardingInfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OnboardingInfo>;
};

/** on_conflict condition type for table "OnboardingInfo" */
export type OnboardingInfo_On_Conflict = {
  constraint: OnboardingInfo_Constraint;
  update_columns?: Array<OnboardingInfo_Update_Column>;
  where?: InputMaybe<OnboardingInfo_Bool_Exp>;
};

/** Ordering options when selecting data from "OnboardingInfo". */
export type OnboardingInfo_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: OnboardingInfo */
export type OnboardingInfo_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "OnboardingInfo" */
export type OnboardingInfo_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'imageUrl'
  /** column name */
  | 'label'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'value';

/** input type for updating data in table "OnboardingInfo" */
export type OnboardingInfo_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type OnboardingInfo_Stddev_Fields = {
  __typename?: 'OnboardingInfo_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type OnboardingInfo_Stddev_Pop_Fields = {
  __typename?: 'OnboardingInfo_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type OnboardingInfo_Stddev_Samp_Fields = {
  __typename?: 'OnboardingInfo_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "OnboardingInfo" */
export type OnboardingInfo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OnboardingInfo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OnboardingInfo_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['OnboardingEntityType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type OnboardingInfo_Sum_Fields = {
  __typename?: 'OnboardingInfo_sum_fields';
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "OnboardingInfo" */
export type OnboardingInfo_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'imageUrl'
  /** column name */
  | 'label'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'value';

export type OnboardingInfo_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OnboardingInfo_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnboardingInfo_Set_Input>;
  /** filter the rows which have to be updated */
  where: OnboardingInfo_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OnboardingInfo_Var_Pop_Fields = {
  __typename?: 'OnboardingInfo_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type OnboardingInfo_Var_Samp_Fields = {
  __typename?: 'OnboardingInfo_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OnboardingInfo_Variance_Fields = {
  __typename?: 'OnboardingInfo_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "OnboardingVideos" */
export type OnboardingVideos = {
  __typename?: 'OnboardingVideos';
  created_at: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ordinal?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  url: Scalars['String']['output'];
};

/** aggregated selection of "OnboardingVideos" */
export type OnboardingVideos_Aggregate = {
  __typename?: 'OnboardingVideos_aggregate';
  aggregate?: Maybe<OnboardingVideos_Aggregate_Fields>;
  nodes: Array<OnboardingVideos>;
};

/** aggregate fields of "OnboardingVideos" */
export type OnboardingVideos_Aggregate_Fields = {
  __typename?: 'OnboardingVideos_aggregate_fields';
  avg?: Maybe<OnboardingVideos_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OnboardingVideos_Max_Fields>;
  min?: Maybe<OnboardingVideos_Min_Fields>;
  stddev?: Maybe<OnboardingVideos_Stddev_Fields>;
  stddev_pop?: Maybe<OnboardingVideos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OnboardingVideos_Stddev_Samp_Fields>;
  sum?: Maybe<OnboardingVideos_Sum_Fields>;
  var_pop?: Maybe<OnboardingVideos_Var_Pop_Fields>;
  var_samp?: Maybe<OnboardingVideos_Var_Samp_Fields>;
  variance?: Maybe<OnboardingVideos_Variance_Fields>;
};


/** aggregate fields of "OnboardingVideos" */
export type OnboardingVideos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OnboardingVideos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OnboardingVideos_Avg_Fields = {
  __typename?: 'OnboardingVideos_avg_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "OnboardingVideos". All fields are combined with a logical 'AND'. */
export type OnboardingVideos_Bool_Exp = {
  _and?: InputMaybe<Array<OnboardingVideos_Bool_Exp>>;
  _not?: InputMaybe<OnboardingVideos_Bool_Exp>;
  _or?: InputMaybe<Array<OnboardingVideos_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ordinal?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "OnboardingVideos" */
export type OnboardingVideos_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'OnboardingVideos_pkey';

/** input type for incrementing numeric columns in table "OnboardingVideos" */
export type OnboardingVideos_Inc_Input = {
  ordinal?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "OnboardingVideos" */
export type OnboardingVideos_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OnboardingVideos_Max_Fields = {
  __typename?: 'OnboardingVideos_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type OnboardingVideos_Min_Fields = {
  __typename?: 'OnboardingVideos_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "OnboardingVideos" */
export type OnboardingVideos_Mutation_Response = {
  __typename?: 'OnboardingVideos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OnboardingVideos>;
};

/** on_conflict condition type for table "OnboardingVideos" */
export type OnboardingVideos_On_Conflict = {
  constraint: OnboardingVideos_Constraint;
  update_columns?: Array<OnboardingVideos_Update_Column>;
  where?: InputMaybe<OnboardingVideos_Bool_Exp>;
};

/** Ordering options when selecting data from "OnboardingVideos". */
export type OnboardingVideos_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: OnboardingVideos */
export type OnboardingVideos_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "OnboardingVideos" */
export type OnboardingVideos_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'url';

/** input type for updating data in table "OnboardingVideos" */
export type OnboardingVideos_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type OnboardingVideos_Stddev_Fields = {
  __typename?: 'OnboardingVideos_stddev_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type OnboardingVideos_Stddev_Pop_Fields = {
  __typename?: 'OnboardingVideos_stddev_pop_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type OnboardingVideos_Stddev_Samp_Fields = {
  __typename?: 'OnboardingVideos_stddev_samp_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "OnboardingVideos" */
export type OnboardingVideos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OnboardingVideos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OnboardingVideos_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OnboardingVideos_Sum_Fields = {
  __typename?: 'OnboardingVideos_sum_fields';
  ordinal?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "OnboardingVideos" */
export type OnboardingVideos_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'url';

export type OnboardingVideos_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OnboardingVideos_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnboardingVideos_Set_Input>;
  /** filter the rows which have to be updated */
  where: OnboardingVideos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OnboardingVideos_Var_Pop_Fields = {
  __typename?: 'OnboardingVideos_var_pop_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type OnboardingVideos_Var_Samp_Fields = {
  __typename?: 'OnboardingVideos_var_samp_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OnboardingVideos_Variance_Fields = {
  __typename?: 'OnboardingVideos_variance_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "Order" */
export type Order = {
  __typename?: 'Order';
  /** An array relationship */
  OrderLineItems: Array<OrderLineItem>;
  /** An aggregate relationship */
  OrderLineItems_aggregate: OrderLineItem_Aggregate;
  created_at: Scalars['timestamp']['output'];
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_first_name?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_last_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  order_details?: Maybe<Scalars['String']['output']>;
  order_payload: Scalars['jsonb']['output'];
  sf_order_id?: Maybe<Scalars['String']['output']>;
  sf_order_item_ids?: Maybe<Array<Scalars['String']['output']>>;
  shopify_customer_id?: Maybe<Scalars['bigint']['output']>;
  shopify_order_id: Scalars['bigint']['output'];
  shopify_short_order_id?: Maybe<Scalars['Int']['output']>;
  shopify_webhook_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "Order" */
export type OrderOrderLineItemsArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "Order" */
export type OrderOrderLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "Order" */
export type OrderOrder_PayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "OrderLineItem" */
export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  /** An object relationship */
  Order: Order;
  /** An array relationship */
  PaymentHistories: Array<PaymentHistory>;
  /** An aggregate relationship */
  PaymentHistories_aggregate: PaymentHistory_Aggregate;
  /** An object relationship */
  Subscription?: Maybe<Subscription>;
  /** An object relationship */
  UserFinancePlan?: Maybe<UserFinancePlan>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  order_id: Scalars['String']['output'];
  product_id?: Maybe<Scalars['String']['output']>;
  product_sku: Scalars['String']['output'];
  product_title: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  sf_order_item_id?: Maybe<Scalars['String']['output']>;
  shopify_line_item_id: Scalars['bigint']['output'];
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_finance_plan_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "OrderLineItem" */
export type OrderLineItemPaymentHistoriesArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


/** columns and relationships of "OrderLineItem" */
export type OrderLineItemPaymentHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};

/** aggregated selection of "OrderLineItem" */
export type OrderLineItem_Aggregate = {
  __typename?: 'OrderLineItem_aggregate';
  aggregate?: Maybe<OrderLineItem_Aggregate_Fields>;
  nodes: Array<OrderLineItem>;
};

export type OrderLineItem_Aggregate_Bool_Exp = {
  count?: InputMaybe<OrderLineItem_Aggregate_Bool_Exp_Count>;
};

export type OrderLineItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OrderLineItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "OrderLineItem" */
export type OrderLineItem_Aggregate_Fields = {
  __typename?: 'OrderLineItem_aggregate_fields';
  avg?: Maybe<OrderLineItem_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OrderLineItem_Max_Fields>;
  min?: Maybe<OrderLineItem_Min_Fields>;
  stddev?: Maybe<OrderLineItem_Stddev_Fields>;
  stddev_pop?: Maybe<OrderLineItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OrderLineItem_Stddev_Samp_Fields>;
  sum?: Maybe<OrderLineItem_Sum_Fields>;
  var_pop?: Maybe<OrderLineItem_Var_Pop_Fields>;
  var_samp?: Maybe<OrderLineItem_Var_Samp_Fields>;
  variance?: Maybe<OrderLineItem_Variance_Fields>;
};


/** aggregate fields of "OrderLineItem" */
export type OrderLineItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "OrderLineItem" */
export type OrderLineItem_Aggregate_Order_By = {
  avg?: InputMaybe<OrderLineItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OrderLineItem_Max_Order_By>;
  min?: InputMaybe<OrderLineItem_Min_Order_By>;
  stddev?: InputMaybe<OrderLineItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderLineItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderLineItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderLineItem_Sum_Order_By>;
  var_pop?: InputMaybe<OrderLineItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderLineItem_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderLineItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "OrderLineItem" */
export type OrderLineItem_Arr_Rel_Insert_Input = {
  data: Array<OrderLineItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrderLineItem_On_Conflict>;
};

/** aggregate avg on columns */
export type OrderLineItem_Avg_Fields = {
  __typename?: 'OrderLineItem_avg_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "OrderLineItem" */
export type OrderLineItem_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "OrderLineItem". All fields are combined with a logical 'AND'. */
export type OrderLineItem_Bool_Exp = {
  Order?: InputMaybe<Order_Bool_Exp>;
  PaymentHistories?: InputMaybe<PaymentHistory_Bool_Exp>;
  PaymentHistories_aggregate?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp>;
  Subscription?: InputMaybe<Subscription_Bool_Exp>;
  UserFinancePlan?: InputMaybe<UserFinancePlan_Bool_Exp>;
  _and?: InputMaybe<Array<OrderLineItem_Bool_Exp>>;
  _not?: InputMaybe<OrderLineItem_Bool_Exp>;
  _or?: InputMaybe<Array<OrderLineItem_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<String_Comparison_Exp>;
  product_sku?: InputMaybe<String_Comparison_Exp>;
  product_title?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  sf_order_item_id?: InputMaybe<String_Comparison_Exp>;
  shopify_line_item_id?: InputMaybe<Bigint_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_finance_plan_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "OrderLineItem" */
export type OrderLineItem_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'OrderLineItem_pkey'
  /** unique or primary key constraint on columns "shopify_line_item_id" */
  | 'OrderLineItem_shopify_line_item_id_key';

/** input type for incrementing numeric columns in table "OrderLineItem" */
export type OrderLineItem_Inc_Input = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "OrderLineItem" */
export type OrderLineItem_Insert_Input = {
  Order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  PaymentHistories?: InputMaybe<PaymentHistory_Arr_Rel_Insert_Input>;
  Subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  UserFinancePlan?: InputMaybe<UserFinancePlan_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  product_sku?: InputMaybe<Scalars['String']['input']>;
  product_title?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sf_order_item_id?: InputMaybe<Scalars['String']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_finance_plan_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OrderLineItem_Max_Fields = {
  __typename?: 'OrderLineItem_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_sku?: Maybe<Scalars['String']['output']>;
  product_title?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sf_order_item_id?: Maybe<Scalars['String']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_finance_plan_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "OrderLineItem" */
export type OrderLineItem_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_sku?: InputMaybe<Order_By>;
  product_title?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sf_order_item_id?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_finance_plan_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OrderLineItem_Min_Fields = {
  __typename?: 'OrderLineItem_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_sku?: Maybe<Scalars['String']['output']>;
  product_title?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sf_order_item_id?: Maybe<Scalars['String']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_finance_plan_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "OrderLineItem" */
export type OrderLineItem_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_sku?: InputMaybe<Order_By>;
  product_title?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sf_order_item_id?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_finance_plan_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "OrderLineItem" */
export type OrderLineItem_Mutation_Response = {
  __typename?: 'OrderLineItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderLineItem>;
};

/** input type for inserting object relation for remote table "OrderLineItem" */
export type OrderLineItem_Obj_Rel_Insert_Input = {
  data: OrderLineItem_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<OrderLineItem_On_Conflict>;
};

/** on_conflict condition type for table "OrderLineItem" */
export type OrderLineItem_On_Conflict = {
  constraint: OrderLineItem_Constraint;
  update_columns?: Array<OrderLineItem_Update_Column>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};

/** Ordering options when selecting data from "OrderLineItem". */
export type OrderLineItem_Order_By = {
  Order?: InputMaybe<Order_Order_By>;
  PaymentHistories_aggregate?: InputMaybe<PaymentHistory_Aggregate_Order_By>;
  Subscription?: InputMaybe<Subscription_Order_By>;
  UserFinancePlan?: InputMaybe<UserFinancePlan_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_sku?: InputMaybe<Order_By>;
  product_title?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sf_order_item_id?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_finance_plan_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: OrderLineItem */
export type OrderLineItem_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "OrderLineItem" */
export type OrderLineItem_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order_id'
  /** column name */
  | 'product_id'
  /** column name */
  | 'product_sku'
  /** column name */
  | 'product_title'
  /** column name */
  | 'quantity'
  /** column name */
  | 'sf_order_item_id'
  /** column name */
  | 'shopify_line_item_id'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_finance_plan_id'
  /** column name */
  | 'user_id';

/** input type for updating data in table "OrderLineItem" */
export type OrderLineItem_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  product_sku?: InputMaybe<Scalars['String']['input']>;
  product_title?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sf_order_item_id?: InputMaybe<Scalars['String']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_finance_plan_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type OrderLineItem_Stddev_Fields = {
  __typename?: 'OrderLineItem_stddev_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "OrderLineItem" */
export type OrderLineItem_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type OrderLineItem_Stddev_Pop_Fields = {
  __typename?: 'OrderLineItem_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "OrderLineItem" */
export type OrderLineItem_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type OrderLineItem_Stddev_Samp_Fields = {
  __typename?: 'OrderLineItem_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "OrderLineItem" */
export type OrderLineItem_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "OrderLineItem" */
export type OrderLineItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OrderLineItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderLineItem_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  product_sku?: InputMaybe<Scalars['String']['input']>;
  product_title?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sf_order_item_id?: InputMaybe<Scalars['String']['input']>;
  shopify_line_item_id?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_finance_plan_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OrderLineItem_Sum_Fields = {
  __typename?: 'OrderLineItem_sum_fields';
  quantity?: Maybe<Scalars['Int']['output']>;
  shopify_line_item_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "OrderLineItem" */
export type OrderLineItem_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** update columns of table "OrderLineItem" */
export type OrderLineItem_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order_id'
  /** column name */
  | 'product_id'
  /** column name */
  | 'product_sku'
  /** column name */
  | 'product_title'
  /** column name */
  | 'quantity'
  /** column name */
  | 'sf_order_item_id'
  /** column name */
  | 'shopify_line_item_id'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_finance_plan_id'
  /** column name */
  | 'user_id';

export type OrderLineItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderLineItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderLineItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: OrderLineItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OrderLineItem_Var_Pop_Fields = {
  __typename?: 'OrderLineItem_var_pop_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "OrderLineItem" */
export type OrderLineItem_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type OrderLineItem_Var_Samp_Fields = {
  __typename?: 'OrderLineItem_var_samp_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "OrderLineItem" */
export type OrderLineItem_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type OrderLineItem_Variance_Fields = {
  __typename?: 'OrderLineItem_variance_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
  shopify_line_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "OrderLineItem" */
export type OrderLineItem_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
  shopify_line_item_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "Order" */
export type Order_Aggregate = {
  __typename?: 'Order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "Order" */
export type Order_Aggregate_Fields = {
  __typename?: 'Order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "Order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'Order_avg_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  OrderLineItems?: InputMaybe<OrderLineItem_Bool_Exp>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  customer_email?: InputMaybe<String_Comparison_Exp>;
  customer_first_name?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  customer_last_name?: InputMaybe<String_Comparison_Exp>;
  customer_phone?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  order_details?: InputMaybe<String_Comparison_Exp>;
  order_payload?: InputMaybe<Jsonb_Comparison_Exp>;
  sf_order_id?: InputMaybe<String_Comparison_Exp>;
  sf_order_item_ids?: InputMaybe<String_Array_Comparison_Exp>;
  shopify_customer_id?: InputMaybe<Bigint_Comparison_Exp>;
  shopify_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  shopify_short_order_id?: InputMaybe<Int_Comparison_Exp>;
  shopify_webhook_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Order" */
export type Order_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Order_pkey'
  /** unique or primary key constraint on columns "shopify_order_id" */
  | 'Order_shopify_order_id_key'
  /** unique or primary key constraint on columns "shopify_short_order_id" */
  | 'Order_shopify_short_order_id_key'
  /** unique or primary key constraint on columns "shopify_webhook_id" */
  | 'Order_shopify_webhook_id_key';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
  order_payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
  order_payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
  order_payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "Order" */
export type Order_Inc_Input = {
  shopify_customer_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_order_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_short_order_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Order" */
export type Order_Insert_Input = {
  OrderLineItems?: InputMaybe<OrderLineItem_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_first_name?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  customer_last_name?: InputMaybe<Scalars['String']['input']>;
  customer_phone?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_details?: InputMaybe<Scalars['String']['input']>;
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
  sf_order_id?: InputMaybe<Scalars['String']['input']>;
  sf_order_item_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  shopify_customer_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_order_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_short_order_id?: InputMaybe<Scalars['Int']['input']>;
  shopify_webhook_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'Order_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_first_name?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_last_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_details?: Maybe<Scalars['String']['output']>;
  sf_order_id?: Maybe<Scalars['String']['output']>;
  sf_order_item_ids?: Maybe<Array<Scalars['String']['output']>>;
  shopify_customer_id?: Maybe<Scalars['bigint']['output']>;
  shopify_order_id?: Maybe<Scalars['bigint']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Int']['output']>;
  shopify_webhook_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'Order_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_first_name?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_last_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_details?: Maybe<Scalars['String']['output']>;
  sf_order_id?: Maybe<Scalars['String']['output']>;
  sf_order_item_ids?: Maybe<Array<Scalars['String']['output']>>;
  shopify_customer_id?: Maybe<Scalars['bigint']['output']>;
  shopify_order_id?: Maybe<Scalars['bigint']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Int']['output']>;
  shopify_webhook_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "Order" */
export type Order_Mutation_Response = {
  __typename?: 'Order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "Order" */
export type Order_Obj_Rel_Insert_Input = {
  data: Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** on_conflict condition type for table "Order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns?: Array<Order_Update_Column>;
  where?: InputMaybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "Order". */
export type Order_Order_By = {
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_first_name?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  customer_last_name?: InputMaybe<Order_By>;
  customer_phone?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_details?: InputMaybe<Order_By>;
  order_payload?: InputMaybe<Order_By>;
  sf_order_id?: InputMaybe<Order_By>;
  sf_order_item_ids?: InputMaybe<Order_By>;
  shopify_customer_id?: InputMaybe<Order_By>;
  shopify_order_id?: InputMaybe<Order_By>;
  shopify_short_order_id?: InputMaybe<Order_By>;
  shopify_webhook_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Order */
export type Order_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "Order" */
export type Order_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'customer_email'
  /** column name */
  | 'customer_first_name'
  /** column name */
  | 'customer_id'
  /** column name */
  | 'customer_last_name'
  /** column name */
  | 'customer_phone'
  /** column name */
  | 'id'
  /** column name */
  | 'order_details'
  /** column name */
  | 'order_payload'
  /** column name */
  | 'sf_order_id'
  /** column name */
  | 'sf_order_item_ids'
  /** column name */
  | 'shopify_customer_id'
  /** column name */
  | 'shopify_order_id'
  /** column name */
  | 'shopify_short_order_id'
  /** column name */
  | 'shopify_webhook_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "Order" */
export type Order_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_first_name?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  customer_last_name?: InputMaybe<Scalars['String']['input']>;
  customer_phone?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_details?: InputMaybe<Scalars['String']['input']>;
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
  sf_order_id?: InputMaybe<Scalars['String']['input']>;
  sf_order_item_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  shopify_customer_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_order_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_short_order_id?: InputMaybe<Scalars['Int']['input']>;
  shopify_webhook_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'Order_stddev_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'Order_stddev_pop_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'Order_stddev_samp_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "Order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_first_name?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  customer_last_name?: InputMaybe<Scalars['String']['input']>;
  customer_phone?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_details?: InputMaybe<Scalars['String']['input']>;
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
  sf_order_id?: InputMaybe<Scalars['String']['input']>;
  sf_order_item_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  shopify_customer_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_order_id?: InputMaybe<Scalars['bigint']['input']>;
  shopify_short_order_id?: InputMaybe<Scalars['Int']['input']>;
  shopify_webhook_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'Order_sum_fields';
  shopify_customer_id?: Maybe<Scalars['bigint']['output']>;
  shopify_order_id?: Maybe<Scalars['bigint']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "Order" */
export type Order_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'customer_email'
  /** column name */
  | 'customer_first_name'
  /** column name */
  | 'customer_id'
  /** column name */
  | 'customer_last_name'
  /** column name */
  | 'customer_phone'
  /** column name */
  | 'id'
  /** column name */
  | 'order_details'
  /** column name */
  | 'order_payload'
  /** column name */
  | 'sf_order_id'
  /** column name */
  | 'sf_order_item_ids'
  /** column name */
  | 'shopify_customer_id'
  /** column name */
  | 'shopify_order_id'
  /** column name */
  | 'shopify_short_order_id'
  /** column name */
  | 'shopify_webhook_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type Order_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'Order_var_pop_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'Order_var_samp_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'Order_variance_fields';
  shopify_customer_id?: Maybe<Scalars['Float']['output']>;
  shopify_order_id?: Maybe<Scalars['Float']['output']>;
  shopify_short_order_id?: Maybe<Scalars['Float']['output']>;
};

export type PauseOrResumeEnum =
  | 'PAUSE'
  | 'RESUME';

export type PauseOrResumeOutput = {
  __typename?: 'PauseOrResumeOutput';
  duration_seen?: Maybe<Scalars['Int']['output']>;
  lastEpoch?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "PaymentHistory" */
export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  /** An object relationship */
  OrderLineItem: OrderLineItem;
  /** An object relationship */
  Subscription: Subscription;
  amount: Scalars['float8']['output'];
  created_at: Scalars['timestamp']['output'];
  details: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order_line_item_id: Scalars['String']['output'];
  stripe_item_id?: Maybe<Scalars['String']['output']>;
  subscription_id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "PaymentHistory" */
export type PaymentHistory_Aggregate = {
  __typename?: 'PaymentHistory_aggregate';
  aggregate?: Maybe<PaymentHistory_Aggregate_Fields>;
  nodes: Array<PaymentHistory>;
};

export type PaymentHistory_Aggregate_Bool_Exp = {
  avg?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp_Var_Samp>;
};

export type PaymentHistory_Aggregate_Bool_Exp_Avg = {
  arguments: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Corr = {
  arguments: PaymentHistory_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Corr_Arguments = {
  X: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type PaymentHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: PaymentHistory_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type PaymentHistory_Aggregate_Bool_Exp_Max = {
  arguments: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Min = {
  arguments: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Sum = {
  arguments: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PaymentHistory_Aggregate_Bool_Exp_Var_Samp = {
  arguments: PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentHistory_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "PaymentHistory" */
export type PaymentHistory_Aggregate_Fields = {
  __typename?: 'PaymentHistory_aggregate_fields';
  avg?: Maybe<PaymentHistory_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentHistory_Max_Fields>;
  min?: Maybe<PaymentHistory_Min_Fields>;
  stddev?: Maybe<PaymentHistory_Stddev_Fields>;
  stddev_pop?: Maybe<PaymentHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PaymentHistory_Stddev_Samp_Fields>;
  sum?: Maybe<PaymentHistory_Sum_Fields>;
  var_pop?: Maybe<PaymentHistory_Var_Pop_Fields>;
  var_samp?: Maybe<PaymentHistory_Var_Samp_Fields>;
  variance?: Maybe<PaymentHistory_Variance_Fields>;
};


/** aggregate fields of "PaymentHistory" */
export type PaymentHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "PaymentHistory" */
export type PaymentHistory_Aggregate_Order_By = {
  avg?: InputMaybe<PaymentHistory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PaymentHistory_Max_Order_By>;
  min?: InputMaybe<PaymentHistory_Min_Order_By>;
  stddev?: InputMaybe<PaymentHistory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PaymentHistory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PaymentHistory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PaymentHistory_Sum_Order_By>;
  var_pop?: InputMaybe<PaymentHistory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PaymentHistory_Var_Samp_Order_By>;
  variance?: InputMaybe<PaymentHistory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PaymentHistory" */
export type PaymentHistory_Arr_Rel_Insert_Input = {
  data: Array<PaymentHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PaymentHistory_On_Conflict>;
};

/** aggregate avg on columns */
export type PaymentHistory_Avg_Fields = {
  __typename?: 'PaymentHistory_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "PaymentHistory" */
export type PaymentHistory_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PaymentHistory". All fields are combined with a logical 'AND'. */
export type PaymentHistory_Bool_Exp = {
  OrderLineItem?: InputMaybe<OrderLineItem_Bool_Exp>;
  Subscription?: InputMaybe<Subscription_Bool_Exp>;
  _and?: InputMaybe<Array<PaymentHistory_Bool_Exp>>;
  _not?: InputMaybe<PaymentHistory_Bool_Exp>;
  _or?: InputMaybe<Array<PaymentHistory_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  details?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  order_line_item_id?: InputMaybe<String_Comparison_Exp>;
  stripe_item_id?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "PaymentHistory" */
export type PaymentHistory_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'PaymentHistory_pkey';

/** input type for incrementing numeric columns in table "PaymentHistory" */
export type PaymentHistory_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "PaymentHistory" */
export type PaymentHistory_Insert_Input = {
  OrderLineItem?: InputMaybe<OrderLineItem_Obj_Rel_Insert_Input>;
  Subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['float8']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_line_item_id?: InputMaybe<Scalars['String']['input']>;
  stripe_item_id?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type PaymentHistory_Max_Fields = {
  __typename?: 'PaymentHistory_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_line_item_id?: Maybe<Scalars['String']['output']>;
  stripe_item_id?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "PaymentHistory" */
export type PaymentHistory_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  stripe_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PaymentHistory_Min_Fields = {
  __typename?: 'PaymentHistory_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order_line_item_id?: Maybe<Scalars['String']['output']>;
  stripe_item_id?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "PaymentHistory" */
export type PaymentHistory_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  stripe_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PaymentHistory" */
export type PaymentHistory_Mutation_Response = {
  __typename?: 'PaymentHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentHistory>;
};

/** on_conflict condition type for table "PaymentHistory" */
export type PaymentHistory_On_Conflict = {
  constraint: PaymentHistory_Constraint;
  update_columns?: Array<PaymentHistory_Update_Column>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "PaymentHistory". */
export type PaymentHistory_Order_By = {
  OrderLineItem?: InputMaybe<OrderLineItem_Order_By>;
  Subscription?: InputMaybe<Subscription_Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  stripe_item_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PaymentHistory */
export type PaymentHistory_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'created_at'
  /** column name */
  | 'details'
  /** column name */
  | 'id'
  /** column name */
  | 'order_line_item_id'
  /** column name */
  | 'stripe_item_id'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'updated_at';

/** select "PaymentHistory_aggregate_bool_exp_avg_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_corr_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_max_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_min_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_sum_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'amount';

/** select "PaymentHistory_aggregate_bool_exp_var_samp_arguments_columns" columns of table "PaymentHistory" */
export type PaymentHistory_Select_Column_PaymentHistory_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'amount';

/** input type for updating data in table "PaymentHistory" */
export type PaymentHistory_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_line_item_id?: InputMaybe<Scalars['String']['input']>;
  stripe_item_id?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type PaymentHistory_Stddev_Fields = {
  __typename?: 'PaymentHistory_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "PaymentHistory" */
export type PaymentHistory_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PaymentHistory_Stddev_Pop_Fields = {
  __typename?: 'PaymentHistory_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "PaymentHistory" */
export type PaymentHistory_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PaymentHistory_Stddev_Samp_Fields = {
  __typename?: 'PaymentHistory_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "PaymentHistory" */
export type PaymentHistory_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PaymentHistory" */
export type PaymentHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PaymentHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentHistory_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order_line_item_id?: InputMaybe<Scalars['String']['input']>;
  stripe_item_id?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type PaymentHistory_Sum_Fields = {
  __typename?: 'PaymentHistory_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "PaymentHistory" */
export type PaymentHistory_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "PaymentHistory" */
export type PaymentHistory_Update_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'created_at'
  /** column name */
  | 'details'
  /** column name */
  | 'id'
  /** column name */
  | 'order_line_item_id'
  /** column name */
  | 'stripe_item_id'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'updated_at';

export type PaymentHistory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentHistory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: PaymentHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PaymentHistory_Var_Pop_Fields = {
  __typename?: 'PaymentHistory_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "PaymentHistory" */
export type PaymentHistory_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PaymentHistory_Var_Samp_Fields = {
  __typename?: 'PaymentHistory_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "PaymentHistory" */
export type PaymentHistory_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PaymentHistory_Variance_Fields = {
  __typename?: 'PaymentHistory_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "PaymentHistory" */
export type PaymentHistory_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

export type PearTokens = {
  __typename?: 'PearTokens';
  access_token?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "PlanDay" */
export type PlanDay = {
  __typename?: 'PlanDay';
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserWorkout?: Maybe<UserWorkout>;
  /** An object relationship */
  WeeklyPlan?: Maybe<WeeklyPlan>;
  challenge_workout_id?: Maybe<Scalars['String']['output']>;
  completed: Scalars['Boolean']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date: Scalars['date']['output'];
  id: Scalars['String']['output'];
  ordinal: Scalars['Int']['output'];
  pear_workout_id?: Maybe<Scalars['String']['output']>;
  pear_workout_sku?: Maybe<Scalars['String']['output']>;
  strength_duration?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id: Scalars['String']['output'];
  user_workout_id?: Maybe<Scalars['String']['output']>;
  week: Scalars['Int']['output'];
  weekly_plan_id?: Maybe<Scalars['String']['output']>;
  workout_type: Scalars['WorkoutType']['output'];
  yield_workout_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "PlanDay" */
export type PlanDay_Aggregate = {
  __typename?: 'PlanDay_aggregate';
  aggregate?: Maybe<PlanDay_Aggregate_Fields>;
  nodes: Array<PlanDay>;
};

export type PlanDay_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PlanDay_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PlanDay_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PlanDay_Aggregate_Bool_Exp_Count>;
};

export type PlanDay_Aggregate_Bool_Exp_Bool_And = {
  arguments: PlanDay_Select_Column_PlanDay_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlanDay_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PlanDay_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PlanDay_Select_Column_PlanDay_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlanDay_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PlanDay_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PlanDay_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlanDay_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PlanDay" */
export type PlanDay_Aggregate_Fields = {
  __typename?: 'PlanDay_aggregate_fields';
  avg?: Maybe<PlanDay_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PlanDay_Max_Fields>;
  min?: Maybe<PlanDay_Min_Fields>;
  stddev?: Maybe<PlanDay_Stddev_Fields>;
  stddev_pop?: Maybe<PlanDay_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PlanDay_Stddev_Samp_Fields>;
  sum?: Maybe<PlanDay_Sum_Fields>;
  var_pop?: Maybe<PlanDay_Var_Pop_Fields>;
  var_samp?: Maybe<PlanDay_Var_Samp_Fields>;
  variance?: Maybe<PlanDay_Variance_Fields>;
};


/** aggregate fields of "PlanDay" */
export type PlanDay_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PlanDay_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "PlanDay" */
export type PlanDay_Aggregate_Order_By = {
  avg?: InputMaybe<PlanDay_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PlanDay_Max_Order_By>;
  min?: InputMaybe<PlanDay_Min_Order_By>;
  stddev?: InputMaybe<PlanDay_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PlanDay_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PlanDay_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PlanDay_Sum_Order_By>;
  var_pop?: InputMaybe<PlanDay_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PlanDay_Var_Samp_Order_By>;
  variance?: InputMaybe<PlanDay_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PlanDay" */
export type PlanDay_Arr_Rel_Insert_Input = {
  data: Array<PlanDay_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanDay_On_Conflict>;
};

/** aggregate avg on columns */
export type PlanDay_Avg_Fields = {
  __typename?: 'PlanDay_avg_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "PlanDay" */
export type PlanDay_Avg_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PlanDay". All fields are combined with a logical 'AND'. */
export type PlanDay_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserWorkout?: InputMaybe<UserWorkout_Bool_Exp>;
  WeeklyPlan?: InputMaybe<WeeklyPlan_Bool_Exp>;
  _and?: InputMaybe<Array<PlanDay_Bool_Exp>>;
  _not?: InputMaybe<PlanDay_Bool_Exp>;
  _or?: InputMaybe<Array<PlanDay_Bool_Exp>>;
  challenge_workout_id?: InputMaybe<String_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ordinal?: InputMaybe<Int_Comparison_Exp>;
  pear_workout_id?: InputMaybe<String_Comparison_Exp>;
  pear_workout_sku?: InputMaybe<String_Comparison_Exp>;
  strength_duration?: InputMaybe<Int_Comparison_Exp>;
  sub_type?: InputMaybe<SubWorkoutType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_workout_id?: InputMaybe<String_Comparison_Exp>;
  week?: InputMaybe<Int_Comparison_Exp>;
  weekly_plan_id?: InputMaybe<String_Comparison_Exp>;
  workout_type?: InputMaybe<WorkoutType_Comparison_Exp>;
  yield_workout_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PlanDay" */
export type PlanDay_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'PlanDay_pkey'
  /** unique or primary key constraint on columns "user_id", "date" */
  | 'PlanDay_user_id_date_key';

/** input type for incrementing numeric columns in table "PlanDay" */
export type PlanDay_Inc_Input = {
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  strength_duration?: InputMaybe<Scalars['Int']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "PlanDay" */
export type PlanDay_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserWorkout?: InputMaybe<UserWorkout_Obj_Rel_Insert_Input>;
  WeeklyPlan?: InputMaybe<WeeklyPlan_Obj_Rel_Insert_Input>;
  challenge_workout_id?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  pear_workout_id?: InputMaybe<Scalars['String']['input']>;
  pear_workout_sku?: InputMaybe<Scalars['String']['input']>;
  strength_duration?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
  weekly_plan_id?: InputMaybe<Scalars['String']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
  yield_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type PlanDay_Max_Fields = {
  __typename?: 'PlanDay_max_fields';
  challenge_workout_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  pear_workout_id?: Maybe<Scalars['String']['output']>;
  pear_workout_sku?: Maybe<Scalars['String']['output']>;
  strength_duration?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
  weekly_plan_id?: Maybe<Scalars['String']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
  yield_workout_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "PlanDay" */
export type PlanDay_Max_Order_By = {
  challenge_workout_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  pear_workout_id?: InputMaybe<Order_By>;
  pear_workout_sku?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
  weekly_plan_id?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
  yield_workout_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PlanDay_Min_Fields = {
  __typename?: 'PlanDay_min_fields';
  challenge_workout_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  pear_workout_id?: Maybe<Scalars['String']['output']>;
  pear_workout_sku?: Maybe<Scalars['String']['output']>;
  strength_duration?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
  weekly_plan_id?: Maybe<Scalars['String']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
  yield_workout_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "PlanDay" */
export type PlanDay_Min_Order_By = {
  challenge_workout_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  pear_workout_id?: InputMaybe<Order_By>;
  pear_workout_sku?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
  weekly_plan_id?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
  yield_workout_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PlanDay" */
export type PlanDay_Mutation_Response = {
  __typename?: 'PlanDay_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanDay>;
};

/** on_conflict condition type for table "PlanDay" */
export type PlanDay_On_Conflict = {
  constraint: PlanDay_Constraint;
  update_columns?: Array<PlanDay_Update_Column>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};

/** Ordering options when selecting data from "PlanDay". */
export type PlanDay_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserWorkout?: InputMaybe<UserWorkout_Order_By>;
  WeeklyPlan?: InputMaybe<WeeklyPlan_Order_By>;
  challenge_workout_id?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordinal?: InputMaybe<Order_By>;
  pear_workout_id?: InputMaybe<Order_By>;
  pear_workout_sku?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
  weekly_plan_id?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
  yield_workout_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PlanDay */
export type PlanDay_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "PlanDay" */
export type PlanDay_Select_Column =
  /** column name */
  | 'challenge_workout_id'
  /** column name */
  | 'completed'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'id'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'pear_workout_id'
  /** column name */
  | 'pear_workout_sku'
  /** column name */
  | 'strength_duration'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id'
  /** column name */
  | 'week'
  /** column name */
  | 'weekly_plan_id'
  /** column name */
  | 'workout_type'
  /** column name */
  | 'yield_workout_id';

/** select "PlanDay_aggregate_bool_exp_bool_and_arguments_columns" columns of table "PlanDay" */
export type PlanDay_Select_Column_PlanDay_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'completed';

/** select "PlanDay_aggregate_bool_exp_bool_or_arguments_columns" columns of table "PlanDay" */
export type PlanDay_Select_Column_PlanDay_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'completed';

/** input type for updating data in table "PlanDay" */
export type PlanDay_Set_Input = {
  challenge_workout_id?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  pear_workout_id?: InputMaybe<Scalars['String']['input']>;
  pear_workout_sku?: InputMaybe<Scalars['String']['input']>;
  strength_duration?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
  weekly_plan_id?: InputMaybe<Scalars['String']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
  yield_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type PlanDay_Stddev_Fields = {
  __typename?: 'PlanDay_stddev_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "PlanDay" */
export type PlanDay_Stddev_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PlanDay_Stddev_Pop_Fields = {
  __typename?: 'PlanDay_stddev_pop_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "PlanDay" */
export type PlanDay_Stddev_Pop_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PlanDay_Stddev_Samp_Fields = {
  __typename?: 'PlanDay_stddev_samp_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "PlanDay" */
export type PlanDay_Stddev_Samp_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PlanDay" */
export type PlanDay_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PlanDay_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PlanDay_Stream_Cursor_Value_Input = {
  challenge_workout_id?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  pear_workout_id?: InputMaybe<Scalars['String']['input']>;
  pear_workout_sku?: InputMaybe<Scalars['String']['input']>;
  strength_duration?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
  weekly_plan_id?: InputMaybe<Scalars['String']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
  yield_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type PlanDay_Sum_Fields = {
  __typename?: 'PlanDay_sum_fields';
  ordinal?: Maybe<Scalars['Int']['output']>;
  strength_duration?: Maybe<Scalars['Int']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "PlanDay" */
export type PlanDay_Sum_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** update columns of table "PlanDay" */
export type PlanDay_Update_Column =
  /** column name */
  | 'challenge_workout_id'
  /** column name */
  | 'completed'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'id'
  /** column name */
  | 'ordinal'
  /** column name */
  | 'pear_workout_id'
  /** column name */
  | 'pear_workout_sku'
  /** column name */
  | 'strength_duration'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id'
  /** column name */
  | 'week'
  /** column name */
  | 'weekly_plan_id'
  /** column name */
  | 'workout_type'
  /** column name */
  | 'yield_workout_id';

export type PlanDay_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanDay_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanDay_Set_Input>;
  /** filter the rows which have to be updated */
  where: PlanDay_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PlanDay_Var_Pop_Fields = {
  __typename?: 'PlanDay_var_pop_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "PlanDay" */
export type PlanDay_Var_Pop_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PlanDay_Var_Samp_Fields = {
  __typename?: 'PlanDay_var_samp_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "PlanDay" */
export type PlanDay_Var_Samp_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PlanDay_Variance_Fields = {
  __typename?: 'PlanDay_variance_fields';
  ordinal?: Maybe<Scalars['Float']['output']>;
  strength_duration?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "PlanDay" */
export type PlanDay_Variance_Order_By = {
  ordinal?: InputMaybe<Order_By>;
  strength_duration?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

export type PlanProgressCounts = {
  __typename?: 'PlanProgressCounts';
  cardio: Scalars['Int']['output'];
  strength: Scalars['Int']['output'];
  workin: Scalars['Int']['output'];
};

export type PlanProgressOutput = {
  __typename?: 'PlanProgressOutput';
  activePlan: Scalars['Boolean']['output'];
  completed: PlanProgressCounts;
  goal: PlanProgressCounts;
  notInPlan?: Maybe<NotInPlanType>;
  planType?: Maybe<PlanTypeObject>;
  range: PlanProgressRange;
};

export type PlanProgressRange = {
  __typename?: 'PlanProgressRange';
  start: Scalars['String']['output'];
  stop: Scalars['String']['output'];
  week?: Maybe<Scalars['Int']['output']>;
};

export type PlanTypeEnum =
  | 'Cardio'
  | 'Mixed'
  | 'Strength';

export type PlanTypeObject = {
  __typename?: 'PlanTypeObject';
  fitnessGoal?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "PlanType". All fields are combined with logical 'AND'. */
export type PlanType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['PlanType']['input']>;
  _gt?: InputMaybe<Scalars['PlanType']['input']>;
  _gte?: InputMaybe<Scalars['PlanType']['input']>;
  _in?: InputMaybe<Array<Scalars['PlanType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['PlanType']['input']>;
  _lte?: InputMaybe<Scalars['PlanType']['input']>;
  _neq?: InputMaybe<Scalars['PlanType']['input']>;
  _nin?: InputMaybe<Array<Scalars['PlanType']['input']>>;
};

/** columns and relationships of "PresetDiet" */
export type PresetDiet = {
  __typename?: 'PresetDiet';
  /** An array relationship */
  CoachedNutritions: Array<CoachedNutrition>;
  /** An aggregate relationship */
  CoachedNutritions_aggregate: CoachedNutrition_Aggregate;
  carbs?: Maybe<Array<Scalars['jsonb']['output']>>;
  created_at: Scalars['timestamp']['output'];
  diet_name: Scalars['String']['output'];
  fats?: Maybe<Array<Scalars['jsonb']['output']>>;
  id: Scalars['String']['output'];
  proteins?: Maybe<Array<Scalars['jsonb']['output']>>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "PresetDiet" */
export type PresetDietCoachedNutritionsArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "PresetDiet" */
export type PresetDietCoachedNutritions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};

/** aggregated selection of "PresetDiet" */
export type PresetDiet_Aggregate = {
  __typename?: 'PresetDiet_aggregate';
  aggregate?: Maybe<PresetDiet_Aggregate_Fields>;
  nodes: Array<PresetDiet>;
};

/** aggregate fields of "PresetDiet" */
export type PresetDiet_Aggregate_Fields = {
  __typename?: 'PresetDiet_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PresetDiet_Max_Fields>;
  min?: Maybe<PresetDiet_Min_Fields>;
};


/** aggregate fields of "PresetDiet" */
export type PresetDiet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PresetDiet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "PresetDiet". All fields are combined with a logical 'AND'. */
export type PresetDiet_Bool_Exp = {
  CoachedNutritions?: InputMaybe<CoachedNutrition_Bool_Exp>;
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<PresetDiet_Bool_Exp>>;
  _not?: InputMaybe<PresetDiet_Bool_Exp>;
  _or?: InputMaybe<Array<PresetDiet_Bool_Exp>>;
  carbs?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  diet_name?: InputMaybe<String_Comparison_Exp>;
  fats?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  proteins?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "PresetDiet" */
export type PresetDiet_Constraint =
  /** unique or primary key constraint on columns "diet_name" */
  | 'PresetDiet_diet_name_key'
  /** unique or primary key constraint on columns "id" */
  | 'PresetDiet_pkey';

/** input type for inserting data into table "PresetDiet" */
export type PresetDiet_Insert_Input = {
  CoachedNutritions?: InputMaybe<CoachedNutrition_Arr_Rel_Insert_Input>;
  carbs?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  diet_name?: InputMaybe<Scalars['String']['input']>;
  fats?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  proteins?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type PresetDiet_Max_Fields = {
  __typename?: 'PresetDiet_max_fields';
  carbs?: Maybe<Array<Scalars['jsonb']['output']>>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  diet_name?: Maybe<Scalars['String']['output']>;
  fats?: Maybe<Array<Scalars['jsonb']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  proteins?: Maybe<Array<Scalars['jsonb']['output']>>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type PresetDiet_Min_Fields = {
  __typename?: 'PresetDiet_min_fields';
  carbs?: Maybe<Array<Scalars['jsonb']['output']>>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  diet_name?: Maybe<Scalars['String']['output']>;
  fats?: Maybe<Array<Scalars['jsonb']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  proteins?: Maybe<Array<Scalars['jsonb']['output']>>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "PresetDiet" */
export type PresetDiet_Mutation_Response = {
  __typename?: 'PresetDiet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PresetDiet>;
};

/** input type for inserting object relation for remote table "PresetDiet" */
export type PresetDiet_Obj_Rel_Insert_Input = {
  data: PresetDiet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PresetDiet_On_Conflict>;
};

/** on_conflict condition type for table "PresetDiet" */
export type PresetDiet_On_Conflict = {
  constraint: PresetDiet_Constraint;
  update_columns?: Array<PresetDiet_Update_Column>;
  where?: InputMaybe<PresetDiet_Bool_Exp>;
};

/** Ordering options when selecting data from "PresetDiet". */
export type PresetDiet_Order_By = {
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Order_By>;
  carbs?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  diet_name?: InputMaybe<Order_By>;
  fats?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  proteins?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PresetDiet */
export type PresetDiet_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "PresetDiet" */
export type PresetDiet_Select_Column =
  /** column name */
  | 'carbs'
  /** column name */
  | 'created_at'
  /** column name */
  | 'diet_name'
  /** column name */
  | 'fats'
  /** column name */
  | 'id'
  /** column name */
  | 'proteins'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "PresetDiet" */
export type PresetDiet_Set_Input = {
  carbs?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  diet_name?: InputMaybe<Scalars['String']['input']>;
  fats?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  proteins?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "PresetDiet" */
export type PresetDiet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PresetDiet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PresetDiet_Stream_Cursor_Value_Input = {
  carbs?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  diet_name?: InputMaybe<Scalars['String']['input']>;
  fats?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  proteins?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "PresetDiet" */
export type PresetDiet_Update_Column =
  /** column name */
  | 'carbs'
  /** column name */
  | 'created_at'
  /** column name */
  | 'diet_name'
  /** column name */
  | 'fats'
  /** column name */
  | 'id'
  /** column name */
  | 'proteins'
  /** column name */
  | 'updated_at';

export type PresetDiet_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PresetDiet_Set_Input>;
  /** filter the rows which have to be updated */
  where: PresetDiet_Bool_Exp;
};

export type PresetPlanDay = {
  ordinal: Scalars['Int']['input'];
  sub_type: WorkoutSubTypeEnum;
  workout_type: WorkoutTypeEnum;
};

export type PresetPlans = {
  __typename?: 'PresetPlans';
  created_at?: Maybe<Scalars['String']['output']>;
  days?: Maybe<Array<Maybe<CustomPlanDay>>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  plan_type?: Maybe<PlanTypeEnum>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "Pro" */
export type Pro = {
  __typename?: 'Pro';
  /** An array relationship */
  ProMusics: Array<ProMusic>;
  /** An aggregate relationship */
  ProMusics_aggregate: ProMusic_Aggregate;
  /** An object relationship */
  User?: Maybe<User>;
  /** An array relationship */
  Users: Array<User>;
  /** An aggregate relationship */
  Users_aggregate: User_Aggregate;
  /** An array relationship */
  Workouts: Array<Workout>;
  /** An aggregate relationship */
  Workouts_aggregate: Workout_Aggregate;
  /** An array relationship */
  _FollowingPros: Array<_FollowingPro>;
  /** An aggregate relationship */
  _FollowingPros_aggregate: _FollowingPro_Aggregate;
  aboutMe?: Maybe<Scalars['String']['output']>;
  bigImage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  introDescription?: Maybe<Scalars['String']['output']>;
  introTitle?: Maybe<Scalars['String']['output']>;
  introVideo?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  musicLinks?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<Scalars['String']['output']>;
  specialties?: Maybe<Array<Scalars['String']['output']>>;
  specialty?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "Pro" */
export type ProProMusicsArgs = {
  distinct_on?: InputMaybe<Array<ProMusic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProMusic_Order_By>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type ProProMusics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProMusic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProMusic_Order_By>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type ProUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type ProUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type ProWorkoutsArgs = {
  distinct_on?: InputMaybe<Array<Workout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workout_Order_By>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type ProWorkouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workout_Order_By>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type Pro_FollowingProsArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


/** columns and relationships of "Pro" */
export type Pro_FollowingPros_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};

/** columns and relationships of "ProMusic" */
export type ProMusic = {
  __typename?: 'ProMusic';
  /** An object relationship */
  Pro?: Maybe<Pro>;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  musicUrl: Scalars['String']['output'];
  photo: Scalars['String']['output'];
  proId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamp']['output'];
};

/** aggregated selection of "ProMusic" */
export type ProMusic_Aggregate = {
  __typename?: 'ProMusic_aggregate';
  aggregate?: Maybe<ProMusic_Aggregate_Fields>;
  nodes: Array<ProMusic>;
};

export type ProMusic_Aggregate_Bool_Exp = {
  count?: InputMaybe<ProMusic_Aggregate_Bool_Exp_Count>;
};

export type ProMusic_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProMusic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ProMusic_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ProMusic" */
export type ProMusic_Aggregate_Fields = {
  __typename?: 'ProMusic_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ProMusic_Max_Fields>;
  min?: Maybe<ProMusic_Min_Fields>;
};


/** aggregate fields of "ProMusic" */
export type ProMusic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProMusic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ProMusic" */
export type ProMusic_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProMusic_Max_Order_By>;
  min?: InputMaybe<ProMusic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ProMusic" */
export type ProMusic_Arr_Rel_Insert_Input = {
  data: Array<ProMusic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProMusic_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ProMusic". All fields are combined with a logical 'AND'. */
export type ProMusic_Bool_Exp = {
  Pro?: InputMaybe<Pro_Bool_Exp>;
  _and?: InputMaybe<Array<ProMusic_Bool_Exp>>;
  _not?: InputMaybe<ProMusic_Bool_Exp>;
  _or?: InputMaybe<Array<ProMusic_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  musicUrl?: InputMaybe<String_Comparison_Exp>;
  photo?: InputMaybe<String_Comparison_Exp>;
  proId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProMusic" */
export type ProMusic_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ProMusic_pkey';

/** input type for inserting data into table "ProMusic" */
export type ProMusic_Insert_Input = {
  Pro?: InputMaybe<Pro_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  musicUrl?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  proId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type ProMusic_Max_Fields = {
  __typename?: 'ProMusic_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  musicUrl?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  proId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "ProMusic" */
export type ProMusic_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  musicUrl?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  proId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProMusic_Min_Fields = {
  __typename?: 'ProMusic_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  musicUrl?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  proId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "ProMusic" */
export type ProMusic_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  musicUrl?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  proId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ProMusic" */
export type ProMusic_Mutation_Response = {
  __typename?: 'ProMusic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ProMusic>;
};

/** on_conflict condition type for table "ProMusic" */
export type ProMusic_On_Conflict = {
  constraint: ProMusic_Constraint;
  update_columns?: Array<ProMusic_Update_Column>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};

/** Ordering options when selecting data from "ProMusic". */
export type ProMusic_Order_By = {
  Pro?: InputMaybe<Pro_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  musicUrl?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  proId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProMusic */
export type ProMusic_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "ProMusic" */
export type ProMusic_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'musicUrl'
  /** column name */
  | 'photo'
  /** column name */
  | 'proId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "ProMusic" */
export type ProMusic_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  musicUrl?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  proId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "ProMusic" */
export type ProMusic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProMusic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProMusic_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  musicUrl?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  proId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "ProMusic" */
export type ProMusic_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'musicUrl'
  /** column name */
  | 'photo'
  /** column name */
  | 'proId'
  /** column name */
  | 'updatedAt';

export type ProMusic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProMusic_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProMusic_Bool_Exp;
};

/** aggregated selection of "Pro" */
export type Pro_Aggregate = {
  __typename?: 'Pro_aggregate';
  aggregate?: Maybe<Pro_Aggregate_Fields>;
  nodes: Array<Pro>;
};

export type Pro_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Pro_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Pro_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Pro_Aggregate_Bool_Exp_Count>;
};

export type Pro_Aggregate_Bool_Exp_Bool_And = {
  arguments: Pro_Select_Column_Pro_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Pro_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Pro_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Pro_Select_Column_Pro_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Pro_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Pro_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pro_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Pro_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Pro" */
export type Pro_Aggregate_Fields = {
  __typename?: 'Pro_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Pro_Max_Fields>;
  min?: Maybe<Pro_Min_Fields>;
};


/** aggregate fields of "Pro" */
export type Pro_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pro_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Pro" */
export type Pro_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pro_Max_Order_By>;
  min?: InputMaybe<Pro_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Pro" */
export type Pro_Arr_Rel_Insert_Input = {
  data: Array<Pro_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pro_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Pro". All fields are combined with a logical 'AND'. */
export type Pro_Bool_Exp = {
  ProMusics?: InputMaybe<ProMusic_Bool_Exp>;
  ProMusics_aggregate?: InputMaybe<ProMusic_Aggregate_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  Users?: InputMaybe<User_Bool_Exp>;
  Users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  Workouts?: InputMaybe<Workout_Bool_Exp>;
  Workouts_aggregate?: InputMaybe<Workout_Aggregate_Bool_Exp>;
  _FollowingPros?: InputMaybe<_FollowingPro_Bool_Exp>;
  _FollowingPros_aggregate?: InputMaybe<_FollowingPro_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Pro_Bool_Exp>>;
  _not?: InputMaybe<Pro_Bool_Exp>;
  _or?: InputMaybe<Array<Pro_Bool_Exp>>;
  aboutMe?: InputMaybe<String_Comparison_Exp>;
  bigImage?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  facebook?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  instagram?: InputMaybe<String_Comparison_Exp>;
  introDescription?: InputMaybe<String_Comparison_Exp>;
  introTitle?: InputMaybe<String_Comparison_Exp>;
  introVideo?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  musicLinks?: InputMaybe<String_Array_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profileImage?: InputMaybe<String_Comparison_Exp>;
  quote?: InputMaybe<String_Comparison_Exp>;
  specialties?: InputMaybe<String_Array_Comparison_Exp>;
  specialty?: InputMaybe<String_Comparison_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Pro" */
export type Pro_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Pro_pkey'
  /** unique or primary key constraint on columns "userId" */
  | 'Pro_userId_unique';

/** input type for inserting data into table "Pro" */
export type Pro_Insert_Input = {
  ProMusics?: InputMaybe<ProMusic_Arr_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  Users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  Workouts?: InputMaybe<Workout_Arr_Rel_Insert_Input>;
  _FollowingPros?: InputMaybe<_FollowingPro_Arr_Rel_Insert_Input>;
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  bigImage?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  introDescription?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  introVideo?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  musicLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  specialties?: InputMaybe<Array<Scalars['String']['input']>>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Pro_Max_Fields = {
  __typename?: 'Pro_max_fields';
  aboutMe?: Maybe<Scalars['String']['output']>;
  bigImage?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  introDescription?: Maybe<Scalars['String']['output']>;
  introTitle?: Maybe<Scalars['String']['output']>;
  introVideo?: Maybe<Scalars['String']['output']>;
  musicLinks?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<Scalars['String']['output']>;
  specialties?: Maybe<Array<Scalars['String']['output']>>;
  specialty?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Pro" */
export type Pro_Max_Order_By = {
  aboutMe?: InputMaybe<Order_By>;
  bigImage?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  introDescription?: InputMaybe<Order_By>;
  introTitle?: InputMaybe<Order_By>;
  introVideo?: InputMaybe<Order_By>;
  musicLinks?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profileImage?: InputMaybe<Order_By>;
  quote?: InputMaybe<Order_By>;
  specialties?: InputMaybe<Order_By>;
  specialty?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pro_Min_Fields = {
  __typename?: 'Pro_min_fields';
  aboutMe?: Maybe<Scalars['String']['output']>;
  bigImage?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  introDescription?: Maybe<Scalars['String']['output']>;
  introTitle?: Maybe<Scalars['String']['output']>;
  introVideo?: Maybe<Scalars['String']['output']>;
  musicLinks?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<Scalars['String']['output']>;
  specialties?: Maybe<Array<Scalars['String']['output']>>;
  specialty?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Pro" */
export type Pro_Min_Order_By = {
  aboutMe?: InputMaybe<Order_By>;
  bigImage?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  introDescription?: InputMaybe<Order_By>;
  introTitle?: InputMaybe<Order_By>;
  introVideo?: InputMaybe<Order_By>;
  musicLinks?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profileImage?: InputMaybe<Order_By>;
  quote?: InputMaybe<Order_By>;
  specialties?: InputMaybe<Order_By>;
  specialty?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Pro" */
export type Pro_Mutation_Response = {
  __typename?: 'Pro_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Pro>;
};

/** input type for inserting object relation for remote table "Pro" */
export type Pro_Obj_Rel_Insert_Input = {
  data: Pro_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Pro_On_Conflict>;
};

/** on_conflict condition type for table "Pro" */
export type Pro_On_Conflict = {
  constraint: Pro_Constraint;
  update_columns?: Array<Pro_Update_Column>;
  where?: InputMaybe<Pro_Bool_Exp>;
};

/** Ordering options when selecting data from "Pro". */
export type Pro_Order_By = {
  ProMusics_aggregate?: InputMaybe<ProMusic_Aggregate_Order_By>;
  User?: InputMaybe<User_Order_By>;
  Users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  Workouts_aggregate?: InputMaybe<Workout_Aggregate_Order_By>;
  _FollowingPros_aggregate?: InputMaybe<_FollowingPro_Aggregate_Order_By>;
  aboutMe?: InputMaybe<Order_By>;
  bigImage?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  introDescription?: InputMaybe<Order_By>;
  introTitle?: InputMaybe<Order_By>;
  introVideo?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  musicLinks?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profileImage?: InputMaybe<Order_By>;
  quote?: InputMaybe<Order_By>;
  specialties?: InputMaybe<Order_By>;
  specialty?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Pro */
export type Pro_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Pro" */
export type Pro_Select_Column =
  /** column name */
  | 'aboutMe'
  /** column name */
  | 'bigImage'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'email'
  /** column name */
  | 'facebook'
  /** column name */
  | 'id'
  /** column name */
  | 'instagram'
  /** column name */
  | 'introDescription'
  /** column name */
  | 'introTitle'
  /** column name */
  | 'introVideo'
  /** column name */
  | 'isActive'
  /** column name */
  | 'musicLinks'
  /** column name */
  | 'name'
  /** column name */
  | 'profileImage'
  /** column name */
  | 'quote'
  /** column name */
  | 'specialties'
  /** column name */
  | 'specialty'
  /** column name */
  | 'twitter'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId';

/** select "Pro_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Pro" */
export type Pro_Select_Column_Pro_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'isActive';

/** select "Pro_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Pro" */
export type Pro_Select_Column_Pro_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'isActive';

/** input type for updating data in table "Pro" */
export type Pro_Set_Input = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  bigImage?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  introDescription?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  introVideo?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  musicLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  specialties?: InputMaybe<Array<Scalars['String']['input']>>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Pro" */
export type Pro_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pro_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pro_Stream_Cursor_Value_Input = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  bigImage?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  introDescription?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  introVideo?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  musicLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  specialties?: InputMaybe<Array<Scalars['String']['input']>>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Pro" */
export type Pro_Update_Column =
  /** column name */
  | 'aboutMe'
  /** column name */
  | 'bigImage'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'email'
  /** column name */
  | 'facebook'
  /** column name */
  | 'id'
  /** column name */
  | 'instagram'
  /** column name */
  | 'introDescription'
  /** column name */
  | 'introTitle'
  /** column name */
  | 'introVideo'
  /** column name */
  | 'isActive'
  /** column name */
  | 'musicLinks'
  /** column name */
  | 'name'
  /** column name */
  | 'profileImage'
  /** column name */
  | 'quote'
  /** column name */
  | 'specialties'
  /** column name */
  | 'specialty'
  /** column name */
  | 'twitter'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId';

export type Pro_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pro_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pro_Bool_Exp;
};

/** columns and relationships of "Product" */
export type Product = {
  __typename?: 'Product';
  /** An array relationship */
  FinancePlans: Array<FinancePlan>;
  /** An aggregate relationship */
  FinancePlans_aggregate: FinancePlan_Aggregate;
  /** An array relationship */
  OrderLineItems: Array<OrderLineItem>;
  /** An aggregate relationship */
  OrderLineItems_aggregate: OrderLineItem_Aggregate;
  /** An array relationship */
  UserProductHistories: Array<UserProductHistory>;
  /** An aggregate relationship */
  UserProductHistories_aggregate: UserProductHistory_Aggregate;
  /** An array relationship */
  UserProducts: Array<UserProduct>;
  /** An aggregate relationship */
  UserProducts_aggregate: UserProduct_Aggregate;
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shopify_id?: Maybe<Scalars['String']['output']>;
  type: Scalars['SubscriptionType']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "Product" */
export type ProductFinancePlansArgs = {
  distinct_on?: InputMaybe<Array<FinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FinancePlan_Order_By>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductFinancePlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FinancePlan_Order_By>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductOrderLineItemsArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductOrderLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductUserProductHistoriesArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductUserProductHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductUserProductsArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductUserProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};

export type ProductDelivery = {
  __typename?: 'ProductDelivery';
  id: Scalars['String']['output'];
  secondary_tracking_numbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  secondary_tracking_urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tracking_info?: Maybe<Scalars['String']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
  tracking_url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "ProductLog" */
export type ProductLog = {
  __typename?: 'ProductLog';
  /** An object relationship */
  UserProduct: UserProduct;
  column: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  new_data: Scalars['String']['output'];
  old_data: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_product_id: Scalars['String']['output'];
};

/** aggregated selection of "ProductLog" */
export type ProductLog_Aggregate = {
  __typename?: 'ProductLog_aggregate';
  aggregate?: Maybe<ProductLog_Aggregate_Fields>;
  nodes: Array<ProductLog>;
};

export type ProductLog_Aggregate_Bool_Exp = {
  count?: InputMaybe<ProductLog_Aggregate_Bool_Exp_Count>;
};

export type ProductLog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProductLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ProductLog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ProductLog" */
export type ProductLog_Aggregate_Fields = {
  __typename?: 'ProductLog_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ProductLog_Max_Fields>;
  min?: Maybe<ProductLog_Min_Fields>;
};


/** aggregate fields of "ProductLog" */
export type ProductLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ProductLog" */
export type ProductLog_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProductLog_Max_Order_By>;
  min?: InputMaybe<ProductLog_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ProductLog" */
export type ProductLog_Arr_Rel_Insert_Input = {
  data: Array<ProductLog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductLog_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ProductLog". All fields are combined with a logical 'AND'. */
export type ProductLog_Bool_Exp = {
  UserProduct?: InputMaybe<UserProduct_Bool_Exp>;
  _and?: InputMaybe<Array<ProductLog_Bool_Exp>>;
  _not?: InputMaybe<ProductLog_Bool_Exp>;
  _or?: InputMaybe<Array<ProductLog_Bool_Exp>>;
  column?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  new_data?: InputMaybe<String_Comparison_Exp>;
  old_data?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_product_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProductLog" */
export type ProductLog_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ProductLog_pkey';

/** input type for inserting data into table "ProductLog" */
export type ProductLog_Insert_Input = {
  UserProduct?: InputMaybe<UserProduct_Obj_Rel_Insert_Input>;
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ProductLog_Max_Fields = {
  __typename?: 'ProductLog_max_fields';
  column?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  new_data?: Maybe<Scalars['String']['output']>;
  old_data?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ProductLog" */
export type ProductLog_Max_Order_By = {
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProductLog_Min_Fields = {
  __typename?: 'ProductLog_min_fields';
  column?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  new_data?: Maybe<Scalars['String']['output']>;
  old_data?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ProductLog" */
export type ProductLog_Min_Order_By = {
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ProductLog" */
export type ProductLog_Mutation_Response = {
  __typename?: 'ProductLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductLog>;
};

/** on_conflict condition type for table "ProductLog" */
export type ProductLog_On_Conflict = {
  constraint: ProductLog_Constraint;
  update_columns?: Array<ProductLog_Update_Column>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};

/** Ordering options when selecting data from "ProductLog". */
export type ProductLog_Order_By = {
  UserProduct?: InputMaybe<UserProduct_Order_By>;
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProductLog */
export type ProductLog_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "ProductLog" */
export type ProductLog_Select_Column =
  /** column name */
  | 'column'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'new_data'
  /** column name */
  | 'old_data'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_product_id';

/** input type for updating data in table "ProductLog" */
export type ProductLog_Set_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ProductLog" */
export type ProductLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductLog_Stream_Cursor_Value_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ProductLog" */
export type ProductLog_Update_Column =
  /** column name */
  | 'column'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'new_data'
  /** column name */
  | 'old_data'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_product_id';

export type ProductLog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductLog_Bool_Exp;
};

/** aggregated selection of "Product" */
export type Product_Aggregate = {
  __typename?: 'Product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "Product" */
export type Product_Aggregate_Fields = {
  __typename?: 'Product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "Product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'Product_avg_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  FinancePlans?: InputMaybe<FinancePlan_Bool_Exp>;
  FinancePlans_aggregate?: InputMaybe<FinancePlan_Aggregate_Bool_Exp>;
  OrderLineItems?: InputMaybe<OrderLineItem_Bool_Exp>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Bool_Exp>;
  UserProductHistories?: InputMaybe<UserProductHistory_Bool_Exp>;
  UserProductHistories_aggregate?: InputMaybe<UserProductHistory_Aggregate_Bool_Exp>;
  UserProducts?: InputMaybe<UserProduct_Bool_Exp>;
  UserProducts_aggregate?: InputMaybe<UserProduct_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  availableQuantity?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  shopify_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<SubscriptionType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Product" */
export type Product_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Product_pkey';

/** input type for incrementing numeric columns in table "Product" */
export type Product_Inc_Input = {
  availableQuantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Product" */
export type Product_Insert_Input = {
  FinancePlans?: InputMaybe<FinancePlan_Arr_Rel_Insert_Input>;
  OrderLineItems?: InputMaybe<OrderLineItem_Arr_Rel_Insert_Input>;
  UserProductHistories?: InputMaybe<UserProductHistory_Arr_Rel_Insert_Input>;
  UserProducts?: InputMaybe<UserProduct_Arr_Rel_Insert_Input>;
  availableQuantity?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shopify_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['SubscriptionType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'Product_max_fields';
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shopify_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['SubscriptionType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'Product_min_fields';
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shopify_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['SubscriptionType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "Product" */
export type Product_Mutation_Response = {
  __typename?: 'Product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "Product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "Product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "Product". */
export type Product_Order_By = {
  FinancePlans_aggregate?: InputMaybe<FinancePlan_Aggregate_Order_By>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Order_By>;
  UserProductHistories_aggregate?: InputMaybe<UserProductHistory_Aggregate_Order_By>;
  UserProducts_aggregate?: InputMaybe<UserProduct_Aggregate_Order_By>;
  availableQuantity?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shopify_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Product */
export type Product_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Product" */
export type Product_Select_Column =
  /** column name */
  | 'availableQuantity'
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'shopify_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Product" */
export type Product_Set_Input = {
  availableQuantity?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shopify_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['SubscriptionType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'Product_stddev_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'Product_stddev_pop_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'Product_stddev_samp_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "Product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  availableQuantity?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shopify_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['SubscriptionType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'Product_sum_fields';
  availableQuantity?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "Product" */
export type Product_Update_Column =
  /** column name */
  | 'availableQuantity'
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'shopify_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'Product_var_pop_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'Product_var_samp_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'Product_variance_fields';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
};

export type ProfileStatsOutput = {
  __typename?: 'ProfileStatsOutput';
  alter_type?: Maybe<Scalars['String']['output']>;
  alter_type_hex?: Maybe<Scalars['String']['output']>;
  alter_zone_max?: Maybe<Scalars['Int']['output']>;
  alter_zone_min?: Maybe<Scalars['Int']['output']>;
  cardio_level: Scalars['Int']['output'];
  current_num_wins: Scalars['Int']['output'];
  free_months: Scalars['Int']['output'];
  num_nights_slept: Scalars['Int']['output'];
  num_reps: Scalars['Int']['output'];
  num_workins: Scalars['Int']['output'];
  num_workouts: Scalars['Int']['output'];
  strength_level: Scalars['Int']['output'];
  user_joined_at?: Maybe<Scalars['String']['output']>;
  weeks_won: Scalars['Int']['output'];
};

/** columns and relationships of "PromotionalItem" */
export type PromotionalItem = {
  __typename?: 'PromotionalItem';
  created_at: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "PromotionalItem" */
export type PromotionalItem_Aggregate = {
  __typename?: 'PromotionalItem_aggregate';
  aggregate?: Maybe<PromotionalItem_Aggregate_Fields>;
  nodes: Array<PromotionalItem>;
};

/** aggregate fields of "PromotionalItem" */
export type PromotionalItem_Aggregate_Fields = {
  __typename?: 'PromotionalItem_aggregate_fields';
  avg?: Maybe<PromotionalItem_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PromotionalItem_Max_Fields>;
  min?: Maybe<PromotionalItem_Min_Fields>;
  stddev?: Maybe<PromotionalItem_Stddev_Fields>;
  stddev_pop?: Maybe<PromotionalItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PromotionalItem_Stddev_Samp_Fields>;
  sum?: Maybe<PromotionalItem_Sum_Fields>;
  var_pop?: Maybe<PromotionalItem_Var_Pop_Fields>;
  var_samp?: Maybe<PromotionalItem_Var_Samp_Fields>;
  variance?: Maybe<PromotionalItem_Variance_Fields>;
};


/** aggregate fields of "PromotionalItem" */
export type PromotionalItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PromotionalItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PromotionalItem_Avg_Fields = {
  __typename?: 'PromotionalItem_avg_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "PromotionalItem". All fields are combined with a logical 'AND'. */
export type PromotionalItem_Bool_Exp = {
  _and?: InputMaybe<Array<PromotionalItem_Bool_Exp>>;
  _not?: InputMaybe<PromotionalItem_Bool_Exp>;
  _or?: InputMaybe<Array<PromotionalItem_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  photo?: InputMaybe<String_Comparison_Exp>;
  sortOrder?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "PromotionalItem" */
export type PromotionalItem_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'PromotionalItem_pkey';

/** input type for incrementing numeric columns in table "PromotionalItem" */
export type PromotionalItem_Inc_Input = {
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "PromotionalItem" */
export type PromotionalItem_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type PromotionalItem_Max_Fields = {
  __typename?: 'PromotionalItem_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type PromotionalItem_Min_Fields = {
  __typename?: 'PromotionalItem_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "PromotionalItem" */
export type PromotionalItem_Mutation_Response = {
  __typename?: 'PromotionalItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PromotionalItem>;
};

/** on_conflict condition type for table "PromotionalItem" */
export type PromotionalItem_On_Conflict = {
  constraint: PromotionalItem_Constraint;
  update_columns?: Array<PromotionalItem_Update_Column>;
  where?: InputMaybe<PromotionalItem_Bool_Exp>;
};

/** Ordering options when selecting data from "PromotionalItem". */
export type PromotionalItem_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PromotionalItem */
export type PromotionalItem_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "PromotionalItem" */
export type PromotionalItem_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'isActive'
  /** column name */
  | 'link'
  /** column name */
  | 'photo'
  /** column name */
  | 'sortOrder'
  /** column name */
  | 'title'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "PromotionalItem" */
export type PromotionalItem_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type PromotionalItem_Stddev_Fields = {
  __typename?: 'PromotionalItem_stddev_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PromotionalItem_Stddev_Pop_Fields = {
  __typename?: 'PromotionalItem_stddev_pop_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PromotionalItem_Stddev_Samp_Fields = {
  __typename?: 'PromotionalItem_stddev_samp_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "PromotionalItem" */
export type PromotionalItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PromotionalItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PromotionalItem_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type PromotionalItem_Sum_Fields = {
  __typename?: 'PromotionalItem_sum_fields';
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "PromotionalItem" */
export type PromotionalItem_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'isActive'
  /** column name */
  | 'link'
  /** column name */
  | 'photo'
  /** column name */
  | 'sortOrder'
  /** column name */
  | 'title'
  /** column name */
  | 'updated_at';

export type PromotionalItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PromotionalItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PromotionalItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: PromotionalItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PromotionalItem_Var_Pop_Fields = {
  __typename?: 'PromotionalItem_var_pop_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PromotionalItem_Var_Samp_Fields = {
  __typename?: 'PromotionalItem_var_samp_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PromotionalItem_Variance_Fields = {
  __typename?: 'PromotionalItem_variance_fields';
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type Props = {
  __typename?: 'Props';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  province_code?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "QuizSurvey" */
export type QuizSurvey = {
  __typename?: 'QuizSurvey';
  /** An object relationship */
  DNAKit?: Maybe<DnaKit>;
  /** An object relationship */
  User?: Maybe<User>;
  activity?: Maybe<Scalars['SurveyActivity']['output']>;
  additional_notes?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['SurveyQuizAge']['output']>;
  age_num: Scalars['Int']['output'];
  areas_needing_help?: Maybe<Array<Scalars['SurveyAreasNeedingHelp']['output']>>;
  created_at: Scalars['timestamp']['output'];
  current_fitness_ability?: Maybe<Scalars['SurveyFitnessAbility']['output']>;
  current_weight_lbs?: Maybe<Scalars['String']['output']>;
  dNAKitId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  equipments_owned?: Maybe<Scalars['SurveyEquipment']['output']>;
  equipments_owned_list?: Maybe<Array<Scalars['SurveyEquipment']['output']>>;
  favorite_type_of_exercise?: Maybe<Scalars['SurveyFavoriteExercise']['output']>;
  favorite_workouts?: Maybe<Scalars['SurveyWorkouts']['output']>;
  favorite_workouts_list?: Maybe<Array<Scalars['SurveyWorkouts']['output']>>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['SurveyQuizGender']['output']>;
  glp_status?: Maybe<Scalars['String']['output']>;
  hows_your_sleep?: Maybe<Scalars['SurveySleep']['output']>;
  id: Scalars['String']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  main_motivation?: Maybe<Scalars['SurveyMotivation']['output']>;
  motivation_struggle?: Maybe<Scalars['Boolean']['output']>;
  services_tried?: Maybe<Array<Scalars['SurveyServicesTried']['output']>>;
  tried_at_home?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['String']['output']>;
  weight_loss_goal?: Maybe<Scalars['SurveyWeightLossGoal']['output']>;
  weight_loss_goal_custom?: Maybe<Scalars['String']['output']>;
  weight_loss_timeline?: Maybe<Scalars['SurveyWeightLossTimeline']['output']>;
};

/** aggregated selection of "QuizSurvey" */
export type QuizSurvey_Aggregate = {
  __typename?: 'QuizSurvey_aggregate';
  aggregate?: Maybe<QuizSurvey_Aggregate_Fields>;
  nodes: Array<QuizSurvey>;
};

export type QuizSurvey_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<QuizSurvey_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<QuizSurvey_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<QuizSurvey_Aggregate_Bool_Exp_Count>;
};

export type QuizSurvey_Aggregate_Bool_Exp_Bool_And = {
  arguments: QuizSurvey_Select_Column_QuizSurvey_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuizSurvey_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type QuizSurvey_Aggregate_Bool_Exp_Bool_Or = {
  arguments: QuizSurvey_Select_Column_QuizSurvey_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuizSurvey_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type QuizSurvey_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuizSurvey_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "QuizSurvey" */
export type QuizSurvey_Aggregate_Fields = {
  __typename?: 'QuizSurvey_aggregate_fields';
  avg?: Maybe<QuizSurvey_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<QuizSurvey_Max_Fields>;
  min?: Maybe<QuizSurvey_Min_Fields>;
  stddev?: Maybe<QuizSurvey_Stddev_Fields>;
  stddev_pop?: Maybe<QuizSurvey_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QuizSurvey_Stddev_Samp_Fields>;
  sum?: Maybe<QuizSurvey_Sum_Fields>;
  var_pop?: Maybe<QuizSurvey_Var_Pop_Fields>;
  var_samp?: Maybe<QuizSurvey_Var_Samp_Fields>;
  variance?: Maybe<QuizSurvey_Variance_Fields>;
};


/** aggregate fields of "QuizSurvey" */
export type QuizSurvey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "QuizSurvey" */
export type QuizSurvey_Aggregate_Order_By = {
  avg?: InputMaybe<QuizSurvey_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QuizSurvey_Max_Order_By>;
  min?: InputMaybe<QuizSurvey_Min_Order_By>;
  stddev?: InputMaybe<QuizSurvey_Stddev_Order_By>;
  stddev_pop?: InputMaybe<QuizSurvey_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<QuizSurvey_Stddev_Samp_Order_By>;
  sum?: InputMaybe<QuizSurvey_Sum_Order_By>;
  var_pop?: InputMaybe<QuizSurvey_Var_Pop_Order_By>;
  var_samp?: InputMaybe<QuizSurvey_Var_Samp_Order_By>;
  variance?: InputMaybe<QuizSurvey_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "QuizSurvey" */
export type QuizSurvey_Arr_Rel_Insert_Input = {
  data: Array<QuizSurvey_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuizSurvey_On_Conflict>;
};

/** aggregate avg on columns */
export type QuizSurvey_Avg_Fields = {
  __typename?: 'QuizSurvey_avg_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "QuizSurvey" */
export type QuizSurvey_Avg_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "QuizSurvey". All fields are combined with a logical 'AND'. */
export type QuizSurvey_Bool_Exp = {
  DNAKit?: InputMaybe<DnaKit_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<QuizSurvey_Bool_Exp>>;
  _not?: InputMaybe<QuizSurvey_Bool_Exp>;
  _or?: InputMaybe<Array<QuizSurvey_Bool_Exp>>;
  activity?: InputMaybe<SurveyActivity_Comparison_Exp>;
  additional_notes?: InputMaybe<String_Comparison_Exp>;
  age?: InputMaybe<SurveyQuizAge_Comparison_Exp>;
  age_num?: InputMaybe<Int_Comparison_Exp>;
  areas_needing_help?: InputMaybe<SurveyAreasNeedingHelp_Array_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_fitness_ability?: InputMaybe<SurveyFitnessAbility_Comparison_Exp>;
  current_weight_lbs?: InputMaybe<String_Comparison_Exp>;
  dNAKitId?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  equipments_owned?: InputMaybe<SurveyEquipment_Comparison_Exp>;
  equipments_owned_list?: InputMaybe<SurveyEquipment_Array_Comparison_Exp>;
  favorite_type_of_exercise?: InputMaybe<SurveyFavoriteExercise_Comparison_Exp>;
  favorite_workouts?: InputMaybe<SurveyWorkouts_Comparison_Exp>;
  favorite_workouts_list?: InputMaybe<SurveyWorkouts_Array_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<SurveyQuizGender_Comparison_Exp>;
  glp_status?: InputMaybe<String_Comparison_Exp>;
  hows_your_sleep?: InputMaybe<SurveySleep_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  main_motivation?: InputMaybe<SurveyMotivation_Comparison_Exp>;
  motivation_struggle?: InputMaybe<Boolean_Comparison_Exp>;
  services_tried?: InputMaybe<SurveyServicesTried_Array_Comparison_Exp>;
  tried_at_home?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  weight_loss_goal?: InputMaybe<SurveyWeightLossGoal_Comparison_Exp>;
  weight_loss_goal_custom?: InputMaybe<String_Comparison_Exp>;
  weight_loss_timeline?: InputMaybe<SurveyWeightLossTimeline_Comparison_Exp>;
};

/** unique or primary key constraints on table "QuizSurvey" */
export type QuizSurvey_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'QuizSurvey_pkey';

/** input type for incrementing numeric columns in table "QuizSurvey" */
export type QuizSurvey_Inc_Input = {
  age_num?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "QuizSurvey" */
export type QuizSurvey_Insert_Input = {
  DNAKit?: InputMaybe<DnaKit_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  activity?: InputMaybe<Scalars['SurveyActivity']['input']>;
  additional_notes?: InputMaybe<Scalars['String']['input']>;
  age?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  age_num?: InputMaybe<Scalars['Int']['input']>;
  areas_needing_help?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_fitness_ability?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  current_weight_lbs?: InputMaybe<Scalars['String']['input']>;
  dNAKitId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  equipments_owned?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  equipments_owned_list?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  favorite_type_of_exercise?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  favorite_workouts?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  favorite_workouts_list?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  glp_status?: InputMaybe<Scalars['String']['input']>;
  hows_your_sleep?: InputMaybe<Scalars['SurveySleep']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  main_motivation?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  motivation_struggle?: InputMaybe<Scalars['Boolean']['input']>;
  services_tried?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  tried_at_home?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  weight_loss_goal?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  weight_loss_goal_custom?: InputMaybe<Scalars['String']['input']>;
  weight_loss_timeline?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
};

/** aggregate max on columns */
export type QuizSurvey_Max_Fields = {
  __typename?: 'QuizSurvey_max_fields';
  activity?: Maybe<Scalars['SurveyActivity']['output']>;
  additional_notes?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['SurveyQuizAge']['output']>;
  age_num?: Maybe<Scalars['Int']['output']>;
  areas_needing_help?: Maybe<Array<Scalars['SurveyAreasNeedingHelp']['output']>>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  current_fitness_ability?: Maybe<Scalars['SurveyFitnessAbility']['output']>;
  current_weight_lbs?: Maybe<Scalars['String']['output']>;
  dNAKitId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equipments_owned?: Maybe<Scalars['SurveyEquipment']['output']>;
  equipments_owned_list?: Maybe<Array<Scalars['SurveyEquipment']['output']>>;
  favorite_type_of_exercise?: Maybe<Scalars['SurveyFavoriteExercise']['output']>;
  favorite_workouts?: Maybe<Scalars['SurveyWorkouts']['output']>;
  favorite_workouts_list?: Maybe<Array<Scalars['SurveyWorkouts']['output']>>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['SurveyQuizGender']['output']>;
  glp_status?: Maybe<Scalars['String']['output']>;
  hows_your_sleep?: Maybe<Scalars['SurveySleep']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  main_motivation?: Maybe<Scalars['SurveyMotivation']['output']>;
  services_tried?: Maybe<Array<Scalars['SurveyServicesTried']['output']>>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  weight_loss_goal?: Maybe<Scalars['SurveyWeightLossGoal']['output']>;
  weight_loss_goal_custom?: Maybe<Scalars['String']['output']>;
  weight_loss_timeline?: Maybe<Scalars['SurveyWeightLossTimeline']['output']>;
};

/** order by max() on columns of table "QuizSurvey" */
export type QuizSurvey_Max_Order_By = {
  activity?: InputMaybe<Order_By>;
  additional_notes?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  age_num?: InputMaybe<Order_By>;
  areas_needing_help?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_fitness_ability?: InputMaybe<Order_By>;
  current_weight_lbs?: InputMaybe<Order_By>;
  dNAKitId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  equipments_owned?: InputMaybe<Order_By>;
  equipments_owned_list?: InputMaybe<Order_By>;
  favorite_type_of_exercise?: InputMaybe<Order_By>;
  favorite_workouts?: InputMaybe<Order_By>;
  favorite_workouts_list?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  glp_status?: InputMaybe<Order_By>;
  hows_your_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  main_motivation?: InputMaybe<Order_By>;
  services_tried?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight_loss_goal?: InputMaybe<Order_By>;
  weight_loss_goal_custom?: InputMaybe<Order_By>;
  weight_loss_timeline?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QuizSurvey_Min_Fields = {
  __typename?: 'QuizSurvey_min_fields';
  activity?: Maybe<Scalars['SurveyActivity']['output']>;
  additional_notes?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['SurveyQuizAge']['output']>;
  age_num?: Maybe<Scalars['Int']['output']>;
  areas_needing_help?: Maybe<Array<Scalars['SurveyAreasNeedingHelp']['output']>>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  current_fitness_ability?: Maybe<Scalars['SurveyFitnessAbility']['output']>;
  current_weight_lbs?: Maybe<Scalars['String']['output']>;
  dNAKitId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equipments_owned?: Maybe<Scalars['SurveyEquipment']['output']>;
  equipments_owned_list?: Maybe<Array<Scalars['SurveyEquipment']['output']>>;
  favorite_type_of_exercise?: Maybe<Scalars['SurveyFavoriteExercise']['output']>;
  favorite_workouts?: Maybe<Scalars['SurveyWorkouts']['output']>;
  favorite_workouts_list?: Maybe<Array<Scalars['SurveyWorkouts']['output']>>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['SurveyQuizGender']['output']>;
  glp_status?: Maybe<Scalars['String']['output']>;
  hows_your_sleep?: Maybe<Scalars['SurveySleep']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  main_motivation?: Maybe<Scalars['SurveyMotivation']['output']>;
  services_tried?: Maybe<Array<Scalars['SurveyServicesTried']['output']>>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  weight_loss_goal?: Maybe<Scalars['SurveyWeightLossGoal']['output']>;
  weight_loss_goal_custom?: Maybe<Scalars['String']['output']>;
  weight_loss_timeline?: Maybe<Scalars['SurveyWeightLossTimeline']['output']>;
};

/** order by min() on columns of table "QuizSurvey" */
export type QuizSurvey_Min_Order_By = {
  activity?: InputMaybe<Order_By>;
  additional_notes?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  age_num?: InputMaybe<Order_By>;
  areas_needing_help?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_fitness_ability?: InputMaybe<Order_By>;
  current_weight_lbs?: InputMaybe<Order_By>;
  dNAKitId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  equipments_owned?: InputMaybe<Order_By>;
  equipments_owned_list?: InputMaybe<Order_By>;
  favorite_type_of_exercise?: InputMaybe<Order_By>;
  favorite_workouts?: InputMaybe<Order_By>;
  favorite_workouts_list?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  glp_status?: InputMaybe<Order_By>;
  hows_your_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  main_motivation?: InputMaybe<Order_By>;
  services_tried?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight_loss_goal?: InputMaybe<Order_By>;
  weight_loss_goal_custom?: InputMaybe<Order_By>;
  weight_loss_timeline?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "QuizSurvey" */
export type QuizSurvey_Mutation_Response = {
  __typename?: 'QuizSurvey_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuizSurvey>;
};

/** on_conflict condition type for table "QuizSurvey" */
export type QuizSurvey_On_Conflict = {
  constraint: QuizSurvey_Constraint;
  update_columns?: Array<QuizSurvey_Update_Column>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};

/** Ordering options when selecting data from "QuizSurvey". */
export type QuizSurvey_Order_By = {
  DNAKit?: InputMaybe<DnaKit_Order_By>;
  User?: InputMaybe<User_Order_By>;
  activity?: InputMaybe<Order_By>;
  additional_notes?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  age_num?: InputMaybe<Order_By>;
  areas_needing_help?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_fitness_ability?: InputMaybe<Order_By>;
  current_weight_lbs?: InputMaybe<Order_By>;
  dNAKitId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  equipments_owned?: InputMaybe<Order_By>;
  equipments_owned_list?: InputMaybe<Order_By>;
  favorite_type_of_exercise?: InputMaybe<Order_By>;
  favorite_workouts?: InputMaybe<Order_By>;
  favorite_workouts_list?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  glp_status?: InputMaybe<Order_By>;
  hows_your_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  main_motivation?: InputMaybe<Order_By>;
  motivation_struggle?: InputMaybe<Order_By>;
  services_tried?: InputMaybe<Order_By>;
  tried_at_home?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight_loss_goal?: InputMaybe<Order_By>;
  weight_loss_goal_custom?: InputMaybe<Order_By>;
  weight_loss_timeline?: InputMaybe<Order_By>;
};

/** primary key columns input for table: QuizSurvey */
export type QuizSurvey_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "QuizSurvey" */
export type QuizSurvey_Select_Column =
  /** column name */
  | 'activity'
  /** column name */
  | 'additional_notes'
  /** column name */
  | 'age'
  /** column name */
  | 'age_num'
  /** column name */
  | 'areas_needing_help'
  /** column name */
  | 'created_at'
  /** column name */
  | 'current_fitness_ability'
  /** column name */
  | 'current_weight_lbs'
  /** column name */
  | 'dNAKitId'
  /** column name */
  | 'email'
  /** column name */
  | 'equipments_owned'
  /** column name */
  | 'equipments_owned_list'
  /** column name */
  | 'favorite_type_of_exercise'
  /** column name */
  | 'favorite_workouts'
  /** column name */
  | 'favorite_workouts_list'
  /** column name */
  | 'first_name'
  /** column name */
  | 'gender'
  /** column name */
  | 'glp_status'
  /** column name */
  | 'hows_your_sleep'
  /** column name */
  | 'id'
  /** column name */
  | 'last_name'
  /** column name */
  | 'main_motivation'
  /** column name */
  | 'motivation_struggle'
  /** column name */
  | 'services_tried'
  /** column name */
  | 'tried_at_home'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'weight_loss_goal'
  /** column name */
  | 'weight_loss_goal_custom'
  /** column name */
  | 'weight_loss_timeline';

/** select "QuizSurvey_aggregate_bool_exp_bool_and_arguments_columns" columns of table "QuizSurvey" */
export type QuizSurvey_Select_Column_QuizSurvey_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'motivation_struggle'
  /** column name */
  | 'tried_at_home';

/** select "QuizSurvey_aggregate_bool_exp_bool_or_arguments_columns" columns of table "QuizSurvey" */
export type QuizSurvey_Select_Column_QuizSurvey_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'motivation_struggle'
  /** column name */
  | 'tried_at_home';

/** input type for updating data in table "QuizSurvey" */
export type QuizSurvey_Set_Input = {
  activity?: InputMaybe<Scalars['SurveyActivity']['input']>;
  additional_notes?: InputMaybe<Scalars['String']['input']>;
  age?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  age_num?: InputMaybe<Scalars['Int']['input']>;
  areas_needing_help?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_fitness_ability?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  current_weight_lbs?: InputMaybe<Scalars['String']['input']>;
  dNAKitId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  equipments_owned?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  equipments_owned_list?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  favorite_type_of_exercise?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  favorite_workouts?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  favorite_workouts_list?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  glp_status?: InputMaybe<Scalars['String']['input']>;
  hows_your_sleep?: InputMaybe<Scalars['SurveySleep']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  main_motivation?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  motivation_struggle?: InputMaybe<Scalars['Boolean']['input']>;
  services_tried?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  tried_at_home?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  weight_loss_goal?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  weight_loss_goal_custom?: InputMaybe<Scalars['String']['input']>;
  weight_loss_timeline?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
};

/** aggregate stddev on columns */
export type QuizSurvey_Stddev_Fields = {
  __typename?: 'QuizSurvey_stddev_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "QuizSurvey" */
export type QuizSurvey_Stddev_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QuizSurvey_Stddev_Pop_Fields = {
  __typename?: 'QuizSurvey_stddev_pop_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "QuizSurvey" */
export type QuizSurvey_Stddev_Pop_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QuizSurvey_Stddev_Samp_Fields = {
  __typename?: 'QuizSurvey_stddev_samp_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "QuizSurvey" */
export type QuizSurvey_Stddev_Samp_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "QuizSurvey" */
export type QuizSurvey_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuizSurvey_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuizSurvey_Stream_Cursor_Value_Input = {
  activity?: InputMaybe<Scalars['SurveyActivity']['input']>;
  additional_notes?: InputMaybe<Scalars['String']['input']>;
  age?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  age_num?: InputMaybe<Scalars['Int']['input']>;
  areas_needing_help?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_fitness_ability?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  current_weight_lbs?: InputMaybe<Scalars['String']['input']>;
  dNAKitId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  equipments_owned?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  equipments_owned_list?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  favorite_type_of_exercise?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  favorite_workouts?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  favorite_workouts_list?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  glp_status?: InputMaybe<Scalars['String']['input']>;
  hows_your_sleep?: InputMaybe<Scalars['SurveySleep']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  main_motivation?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  motivation_struggle?: InputMaybe<Scalars['Boolean']['input']>;
  services_tried?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  tried_at_home?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  weight_loss_goal?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  weight_loss_goal_custom?: InputMaybe<Scalars['String']['input']>;
  weight_loss_timeline?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
};

/** aggregate sum on columns */
export type QuizSurvey_Sum_Fields = {
  __typename?: 'QuizSurvey_sum_fields';
  age_num?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "QuizSurvey" */
export type QuizSurvey_Sum_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** update columns of table "QuizSurvey" */
export type QuizSurvey_Update_Column =
  /** column name */
  | 'activity'
  /** column name */
  | 'additional_notes'
  /** column name */
  | 'age'
  /** column name */
  | 'age_num'
  /** column name */
  | 'areas_needing_help'
  /** column name */
  | 'created_at'
  /** column name */
  | 'current_fitness_ability'
  /** column name */
  | 'current_weight_lbs'
  /** column name */
  | 'dNAKitId'
  /** column name */
  | 'email'
  /** column name */
  | 'equipments_owned'
  /** column name */
  | 'equipments_owned_list'
  /** column name */
  | 'favorite_type_of_exercise'
  /** column name */
  | 'favorite_workouts'
  /** column name */
  | 'favorite_workouts_list'
  /** column name */
  | 'first_name'
  /** column name */
  | 'gender'
  /** column name */
  | 'glp_status'
  /** column name */
  | 'hows_your_sleep'
  /** column name */
  | 'id'
  /** column name */
  | 'last_name'
  /** column name */
  | 'main_motivation'
  /** column name */
  | 'motivation_struggle'
  /** column name */
  | 'services_tried'
  /** column name */
  | 'tried_at_home'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'weight_loss_goal'
  /** column name */
  | 'weight_loss_goal_custom'
  /** column name */
  | 'weight_loss_timeline';

export type QuizSurvey_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QuizSurvey_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuizSurvey_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuizSurvey_Bool_Exp;
};

/** aggregate var_pop on columns */
export type QuizSurvey_Var_Pop_Fields = {
  __typename?: 'QuizSurvey_var_pop_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "QuizSurvey" */
export type QuizSurvey_Var_Pop_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QuizSurvey_Var_Samp_Fields = {
  __typename?: 'QuizSurvey_var_samp_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "QuizSurvey" */
export type QuizSurvey_Var_Samp_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type QuizSurvey_Variance_Fields = {
  __typename?: 'QuizSurvey_variance_fields';
  age_num?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "QuizSurvey" */
export type QuizSurvey_Variance_Order_By = {
  age_num?: InputMaybe<Order_By>;
};

/** columns and relationships of "Quote" */
export type Quote = {
  __typename?: 'Quote';
  context: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "Quote" */
export type Quote_Aggregate = {
  __typename?: 'Quote_aggregate';
  aggregate?: Maybe<Quote_Aggregate_Fields>;
  nodes: Array<Quote>;
};

/** aggregate fields of "Quote" */
export type Quote_Aggregate_Fields = {
  __typename?: 'Quote_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Quote_Max_Fields>;
  min?: Maybe<Quote_Min_Fields>;
};


/** aggregate fields of "Quote" */
export type Quote_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Quote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "Quote". All fields are combined with a logical 'AND'. */
export type Quote_Bool_Exp = {
  _and?: InputMaybe<Array<Quote_Bool_Exp>>;
  _not?: InputMaybe<Quote_Bool_Exp>;
  _or?: InputMaybe<Array<Quote_Bool_Exp>>;
  context?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Quote" */
export type Quote_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Quote_pkey';

/** input type for inserting data into table "Quote" */
export type Quote_Insert_Input = {
  context?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Quote_Max_Fields = {
  __typename?: 'Quote_max_fields';
  context?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Quote_Min_Fields = {
  __typename?: 'Quote_min_fields';
  context?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "Quote" */
export type Quote_Mutation_Response = {
  __typename?: 'Quote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Quote>;
};

/** on_conflict condition type for table "Quote" */
export type Quote_On_Conflict = {
  constraint: Quote_Constraint;
  update_columns?: Array<Quote_Update_Column>;
  where?: InputMaybe<Quote_Bool_Exp>;
};

/** Ordering options when selecting data from "Quote". */
export type Quote_Order_By = {
  context?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Quote */
export type Quote_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Quote" */
export type Quote_Select_Column =
  /** column name */
  | 'context'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Quote" */
export type Quote_Set_Input = {
  context?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "Quote" */
export type Quote_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Quote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Quote_Stream_Cursor_Value_Input = {
  context?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "Quote" */
export type Quote_Update_Column =
  /** column name */
  | 'context'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Quote_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Quote_Set_Input>;
  /** filter the rows which have to be updated */
  where: Quote_Bool_Exp;
};

export type RecommenderGeneralRank =
  | 'AboveAverage'
  | 'Average'
  | 'BelowAverage'
  | 'Excellent'
  | 'Good'
  | 'Low'
  | 'VeryLow';

export type RecommenderGeneralRankGroup =
  | 'Advanced'
  | 'Beginner'
  | 'Intermediate';

export type RecommenderOutput = {
  __typename?: 'RecommenderOutput';
  biometricEligibility?: Maybe<BiometricEligibility>;
  cardio_filters?: Maybe<HistoryAndCounts>;
  cardio_fitness?: Maybe<RecommenderGeneralRank>;
  cardio_group?: Maybe<RecommenderGeneralRankGroup>;
  chronotype?: Maybe<Scalars['String']['output']>;
  latest_history?: Maybe<Array<Maybe<WorkIoWithEpoch>>>;
  off_plan_recommendation?: Maybe<Scalars['json']['output']>;
  pear_outage?: Maybe<Scalars['json']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  sleep_history?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  sleep_start?: Maybe<Scalars['Int']['output']>;
  sleep_stop?: Maybe<Scalars['Int']['output']>;
  stress?: Maybe<Scalars['String']['output']>;
  todays_recommendation?: Maybe<Scalars['json']['output']>;
  workin_filters?: Maybe<HistoryAndCounts>;
  workin_recommendation: RecommenderWorkinObj;
};

export type RecommenderV2CardioAlternatives = {
  __typename?: 'RecommenderV2CardioAlternatives';
  bodyweight?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  combat?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  dance?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  mobility?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type RecommenderV2CardioObj = {
  __typename?: 'RecommenderV2CardioObj';
  alternatives?: Maybe<RecommenderV2CardioAlternatives>;
  bodyweight?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  combat?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  dance?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  mobility?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type RecommenderV2Output = {
  __typename?: 'RecommenderV2Output';
  biometricEligibility?: Maybe<BiometricEligibility>;
  cardio_fitness?: Maybe<RecommenderGeneralRank>;
  cardio_group?: Maybe<RecommenderGeneralRankGroup>;
  cardio_recommendation?: Maybe<RecommenderV2CardioObj>;
  chronotype?: Maybe<Scalars['String']['output']>;
  latest_history?: Maybe<Array<Maybe<WorkIoWithEpoch>>>;
  pear_outage?: Maybe<Scalars['json']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  sleep_history?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  sleep_is_override?: Maybe<Scalars['Boolean']['output']>;
  sleep_start?: Maybe<Scalars['Int']['output']>;
  sleep_stop?: Maybe<Scalars['Int']['output']>;
  strength_alternatives?: Maybe<Scalars['json']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  strength_workouts_done_at_level?: Maybe<Scalars['Int']['output']>;
  strength_workouts_to_advance?: Maybe<Scalars['Int']['output']>;
  stress?: Maybe<Scalars['String']['output']>;
  todays_recommendation?: Maybe<Scalars['json']['output']>;
  workin_recommendation?: Maybe<RecommenderV2WorkinObj>;
};

export type RecommenderV2WorkinAlternatives = {
  __typename?: 'RecommenderV2WorkinAlternatives';
  breathwork?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  meditation?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  yoga?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type RecommenderV2WorkinObj = {
  __typename?: 'RecommenderV2WorkinObj';
  alternatives?: Maybe<RecommenderV2WorkinAlternatives>;
  breathwork?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  meditation?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  yoga?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type RecommenderWorkinObj = {
  __typename?: 'RecommenderWorkinObj';
  alternative?: Maybe<RecommenderWorkinSubAlternativeObj>;
  breathwork?: Maybe<RecommenderWorkinSubObj>;
  meditation?: Maybe<RecommenderWorkinSubObj>;
};

export type RecommenderWorkinSubAlternativeObj = {
  __typename?: 'RecommenderWorkinSubAlternativeObj';
  breathwork?: Maybe<Array<Maybe<RecommenderWorkinSubObj>>>;
  meditation?: Maybe<Array<Maybe<RecommenderWorkinSubObj>>>;
};

export type RecommenderWorkinSubObj = {
  __typename?: 'RecommenderWorkinSubObj';
  audioUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  extended_content?: Maybe<Scalars['json']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  sub_type: WorkoutSubTypeEnum;
  times_seen: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  trainer?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  workout_id: Scalars['String']['output'];
  workout_type: WorkoutTypeEnum;
};

export type RegAreasNeedingHelp =
  | 'MotivationAndConsistency'
  | 'NextLevelFitness'
  | 'NotSeeingResults'
  | 'RightThingsToDo';

export type RegFavoriteExercise =
  | 'AtHome'
  | 'DislikeExercise'
  | 'GroupClass'
  | 'Gym'
  | 'Outside';

export type RegFitnessAbility =
  | 'Advanced'
  | 'Beginner'
  | 'Intermediate'
  | 'NotSure';

export type RegGender =
  | 'Female'
  | 'Male'
  | 'Other';

export type RegMotivation =
  | 'GetStronger'
  | 'Health'
  | 'LookGood'
  | 'LoseWeight';

export type RegServicesTried =
  | 'HealthCoach'
  | 'Medication'
  | 'None'
  | 'Nutritionist'
  | 'PersonalTrainer'
  | 'PhysicalTherapist'
  | 'SocialMediaChallenges'
  | 'Surgery';

export type RegWeightLossGoal =
  | 'FiftyPlus'
  | 'OneToTwenty'
  | 'Other'
  | 'TwentyOneToFifty';

export type RegWeightLossTimeline =
  | 'FourToSix'
  | 'OneToThree'
  | 'SevenPlus';

export type RegisterUser = {
  __typename?: 'RegisterUser';
  age?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  pearId?: Maybe<Scalars['String']['output']>;
  pearUserId?: Maybe<Scalars['String']['output']>;
  preferMetric?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  strapColor?: Maybe<Scalars['String']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "ReimbursementRequirement" */
export type ReimbursementRequirement = {
  __typename?: 'ReimbursementRequirement';
  cadence: Scalars['CadenceType']['output'];
  created_at: Scalars['timestamp']['output'];
  household_members: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  required_tokens_num: Scalars['Int']['output'];
  type: Scalars['HouseholdType']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "ReimbursementRequirement" */
export type ReimbursementRequirement_Aggregate = {
  __typename?: 'ReimbursementRequirement_aggregate';
  aggregate?: Maybe<ReimbursementRequirement_Aggregate_Fields>;
  nodes: Array<ReimbursementRequirement>;
};

/** aggregate fields of "ReimbursementRequirement" */
export type ReimbursementRequirement_Aggregate_Fields = {
  __typename?: 'ReimbursementRequirement_aggregate_fields';
  avg?: Maybe<ReimbursementRequirement_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ReimbursementRequirement_Max_Fields>;
  min?: Maybe<ReimbursementRequirement_Min_Fields>;
  stddev?: Maybe<ReimbursementRequirement_Stddev_Fields>;
  stddev_pop?: Maybe<ReimbursementRequirement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReimbursementRequirement_Stddev_Samp_Fields>;
  sum?: Maybe<ReimbursementRequirement_Sum_Fields>;
  var_pop?: Maybe<ReimbursementRequirement_Var_Pop_Fields>;
  var_samp?: Maybe<ReimbursementRequirement_Var_Samp_Fields>;
  variance?: Maybe<ReimbursementRequirement_Variance_Fields>;
};


/** aggregate fields of "ReimbursementRequirement" */
export type ReimbursementRequirement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReimbursementRequirement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ReimbursementRequirement_Avg_Fields = {
  __typename?: 'ReimbursementRequirement_avg_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ReimbursementRequirement". All fields are combined with a logical 'AND'. */
export type ReimbursementRequirement_Bool_Exp = {
  _and?: InputMaybe<Array<ReimbursementRequirement_Bool_Exp>>;
  _not?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
  _or?: InputMaybe<Array<ReimbursementRequirement_Bool_Exp>>;
  cadence?: InputMaybe<CadenceType_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  household_members?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  required_tokens_num?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<HouseholdType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReimbursementRequirement" */
export type ReimbursementRequirement_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ReimbursementRequirement_pkey'
  /** unique or primary key constraint on columns "type", "household_members", "cadence" */
  | 'ReimbursementRequirement_type_cadence_household_members_key';

/** input type for incrementing numeric columns in table "ReimbursementRequirement" */
export type ReimbursementRequirement_Inc_Input = {
  household_members?: InputMaybe<Scalars['Int']['input']>;
  required_tokens_num?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "ReimbursementRequirement" */
export type ReimbursementRequirement_Insert_Input = {
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  household_members?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  required_tokens_num?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type ReimbursementRequirement_Max_Fields = {
  __typename?: 'ReimbursementRequirement_max_fields';
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  household_members?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  required_tokens_num?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['HouseholdType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type ReimbursementRequirement_Min_Fields = {
  __typename?: 'ReimbursementRequirement_min_fields';
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  household_members?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  required_tokens_num?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['HouseholdType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "ReimbursementRequirement" */
export type ReimbursementRequirement_Mutation_Response = {
  __typename?: 'ReimbursementRequirement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReimbursementRequirement>;
};

/** on_conflict condition type for table "ReimbursementRequirement" */
export type ReimbursementRequirement_On_Conflict = {
  constraint: ReimbursementRequirement_Constraint;
  update_columns?: Array<ReimbursementRequirement_Update_Column>;
  where?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
};

/** Ordering options when selecting data from "ReimbursementRequirement". */
export type ReimbursementRequirement_Order_By = {
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  household_members?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  required_tokens_num?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReimbursementRequirement */
export type ReimbursementRequirement_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "ReimbursementRequirement" */
export type ReimbursementRequirement_Select_Column =
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'household_members'
  /** column name */
  | 'id'
  /** column name */
  | 'required_tokens_num'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "ReimbursementRequirement" */
export type ReimbursementRequirement_Set_Input = {
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  household_members?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  required_tokens_num?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type ReimbursementRequirement_Stddev_Fields = {
  __typename?: 'ReimbursementRequirement_stddev_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ReimbursementRequirement_Stddev_Pop_Fields = {
  __typename?: 'ReimbursementRequirement_stddev_pop_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ReimbursementRequirement_Stddev_Samp_Fields = {
  __typename?: 'ReimbursementRequirement_stddev_samp_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ReimbursementRequirement" */
export type ReimbursementRequirement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReimbursementRequirement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReimbursementRequirement_Stream_Cursor_Value_Input = {
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  household_members?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  required_tokens_num?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type ReimbursementRequirement_Sum_Fields = {
  __typename?: 'ReimbursementRequirement_sum_fields';
  household_members?: Maybe<Scalars['Int']['output']>;
  required_tokens_num?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "ReimbursementRequirement" */
export type ReimbursementRequirement_Update_Column =
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'household_members'
  /** column name */
  | 'id'
  /** column name */
  | 'required_tokens_num'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

export type ReimbursementRequirement_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReimbursementRequirement_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReimbursementRequirement_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReimbursementRequirement_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReimbursementRequirement_Var_Pop_Fields = {
  __typename?: 'ReimbursementRequirement_var_pop_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ReimbursementRequirement_Var_Samp_Fields = {
  __typename?: 'ReimbursementRequirement_var_samp_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ReimbursementRequirement_Variance_Fields = {
  __typename?: 'ReimbursementRequirement_variance_fields';
  household_members?: Maybe<Scalars['Float']['output']>;
  required_tokens_num?: Maybe<Scalars['Float']['output']>;
};

export type RepItemInput = {
  asensei_raw?: InputMaybe<Scalars['json']['input']>;
  negative_nuances?: InputMaybe<Scalars['Int']['input']>;
  outcome: Scalars['Int']['input'];
  positive_nuances?: InputMaybe<Scalars['Int']['input']>;
  rep_number: Scalars['Int']['input'];
};

export type RepMasteryResponse = {
  __typename?: 'RepMasteryResponse';
  BraceProne: Scalars['Int']['output'];
  BraceSide: Scalars['Int']['output'];
  BraceSupine: Scalars['Int']['output'];
  FoamRolling: Scalars['Int']['output'];
  Hinge: Scalars['Int']['output'];
  Lunge: Scalars['Int']['output'];
  Mobility: Scalars['Int']['output'];
  PullHorizontal: Scalars['Int']['output'];
  PullVertical: Scalars['Int']['output'];
  PushHorizontal: Scalars['Int']['output'];
  PushVertical: Scalars['Int']['output'];
  Squat: Scalars['Int']['output'];
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']['output']>;
};

export type ResultListOutput = {
  __typename?: 'ResultListOutput';
  result?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type ResultObjectOutput = {
  __typename?: 'ResultObjectOutput';
  result?: Maybe<Scalars['json']['output']>;
};

export type RetrieveFirmwareV2Output = {
  __typename?: 'RetrieveFirmwareV2Output';
  device?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  hardware_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_beta?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  version_number?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to compare columns of type "Role". All fields are combined with logical 'AND'. */
export type Role_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Role']['input']>;
  _gt?: InputMaybe<Scalars['Role']['input']>;
  _gte?: InputMaybe<Scalars['Role']['input']>;
  _in?: InputMaybe<Array<Scalars['Role']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Role']['input']>;
  _lte?: InputMaybe<Scalars['Role']['input']>;
  _neq?: InputMaybe<Scalars['Role']['input']>;
  _nin?: InputMaybe<Array<Scalars['Role']['input']>>;
};

export type SampleInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  accessToken: Scalars['String']['output'];
};

export type SampleQuizSurveyOutput = {
  __typename?: 'SampleQuizSurveyOutput';
  activity?: Maybe<SurveyQuizActivity>;
  age?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equipments_owned?: Maybe<Array<Maybe<SurveyEquipmentEnum>>>;
  favorite_workouts?: Maybe<Array<Maybe<SurveyWorkoutsEnum>>>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<SurveyQuizGenderEnum>;
  hows_your_sleep?: Maybe<SurveySleepEnum>;
  id: Scalars['String']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  main_motivation?: Maybe<SurveyMotivationEnum>;
  motivation_struggle?: Maybe<Scalars['Boolean']['output']>;
  tried_at_home?: Maybe<Scalars['Boolean']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type SampleScreenOutput = {
  __typename?: 'SampleScreenOutput';
  id?: Maybe<Scalars['String']['output']>;
  screen_id?: Maybe<Scalars['String']['output']>;
  screen_name?: Maybe<Scalars['String']['output']>;
};

export type SampleWorkoutZoneOutput = {
  __typename?: 'SampleWorkoutZoneOutput';
  id?: Maybe<Scalars['String']['output']>;
  zone2sec?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "ScreenOrder" */
export type ScreenOrder = {
  __typename?: 'ScreenOrder';
  /** An object relationship */
  User?: Maybe<User>;
  accountId?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email: Scalars['String']['output'];
  estimated_delivery_date?: Maybe<Scalars['timestamp']['output']>;
  extensivOrderId?: Maybe<Scalars['Int']['output']>;
  extensivRefNum?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  opportunityId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderItemIds?: Maybe<Array<Scalars['String']['output']>>;
  order_payload?: Maybe<Scalars['jsonb']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  returnTrackingId?: Maybe<Scalars['String']['output']>;
  serial_number?: Maybe<Scalars['String']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "ScreenOrder" */
export type ScreenOrderOrder_PayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ScreenOrder" */
export type ScreenOrder_Aggregate = {
  __typename?: 'ScreenOrder_aggregate';
  aggregate?: Maybe<ScreenOrder_Aggregate_Fields>;
  nodes: Array<ScreenOrder>;
};

export type ScreenOrder_Aggregate_Bool_Exp = {
  count?: InputMaybe<ScreenOrder_Aggregate_Bool_Exp_Count>;
};

export type ScreenOrder_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ScreenOrder_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ScreenOrder" */
export type ScreenOrder_Aggregate_Fields = {
  __typename?: 'ScreenOrder_aggregate_fields';
  avg?: Maybe<ScreenOrder_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ScreenOrder_Max_Fields>;
  min?: Maybe<ScreenOrder_Min_Fields>;
  stddev?: Maybe<ScreenOrder_Stddev_Fields>;
  stddev_pop?: Maybe<ScreenOrder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ScreenOrder_Stddev_Samp_Fields>;
  sum?: Maybe<ScreenOrder_Sum_Fields>;
  var_pop?: Maybe<ScreenOrder_Var_Pop_Fields>;
  var_samp?: Maybe<ScreenOrder_Var_Samp_Fields>;
  variance?: Maybe<ScreenOrder_Variance_Fields>;
};


/** aggregate fields of "ScreenOrder" */
export type ScreenOrder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ScreenOrder" */
export type ScreenOrder_Aggregate_Order_By = {
  avg?: InputMaybe<ScreenOrder_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ScreenOrder_Max_Order_By>;
  min?: InputMaybe<ScreenOrder_Min_Order_By>;
  stddev?: InputMaybe<ScreenOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ScreenOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ScreenOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ScreenOrder_Sum_Order_By>;
  var_pop?: InputMaybe<ScreenOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ScreenOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<ScreenOrder_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ScreenOrder_Append_Input = {
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "ScreenOrder" */
export type ScreenOrder_Arr_Rel_Insert_Input = {
  data: Array<ScreenOrder_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ScreenOrder_On_Conflict>;
};

/** aggregate avg on columns */
export type ScreenOrder_Avg_Fields = {
  __typename?: 'ScreenOrder_avg_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "ScreenOrder" */
export type ScreenOrder_Avg_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ScreenOrder". All fields are combined with a logical 'AND'. */
export type ScreenOrder_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<ScreenOrder_Bool_Exp>>;
  _not?: InputMaybe<ScreenOrder_Bool_Exp>;
  _or?: InputMaybe<Array<ScreenOrder_Bool_Exp>>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  estimated_delivery_date?: InputMaybe<Timestamp_Comparison_Exp>;
  extensivOrderId?: InputMaybe<Int_Comparison_Exp>;
  extensivRefNum?: InputMaybe<String_Comparison_Exp>;
  fulfilledBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  opportunityId?: InputMaybe<String_Comparison_Exp>;
  orderId?: InputMaybe<String_Comparison_Exp>;
  orderItemIds?: InputMaybe<String_Array_Comparison_Exp>;
  order_payload?: InputMaybe<Jsonb_Comparison_Exp>;
  owner_user_id?: InputMaybe<String_Comparison_Exp>;
  returnTrackingId?: InputMaybe<String_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  shopifyOrderId?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  trackingId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  webhookId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ScreenOrder" */
export type ScreenOrder_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ScreenOrder_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ScreenOrder_Delete_At_Path_Input = {
  order_payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ScreenOrder_Delete_Elem_Input = {
  order_payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ScreenOrder_Delete_Key_Input = {
  order_payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ScreenOrder" */
export type ScreenOrder_Inc_Input = {
  extensivOrderId?: InputMaybe<Scalars['Int']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "ScreenOrder" */
export type ScreenOrder_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimated_delivery_date?: InputMaybe<Scalars['timestamp']['input']>;
  extensivOrderId?: InputMaybe<Scalars['Int']['input']>;
  extensivRefNum?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  returnTrackingId?: InputMaybe<Scalars['String']['input']>;
  serial_number?: InputMaybe<Scalars['String']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ScreenOrder_Max_Fields = {
  __typename?: 'ScreenOrder_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  estimated_delivery_date?: Maybe<Scalars['timestamp']['output']>;
  extensivOrderId?: Maybe<Scalars['Int']['output']>;
  extensivRefNum?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderItemIds?: Maybe<Array<Scalars['String']['output']>>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  returnTrackingId?: Maybe<Scalars['String']['output']>;
  serial_number?: Maybe<Scalars['String']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ScreenOrder" */
export type ScreenOrder_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  estimated_delivery_date?: InputMaybe<Order_By>;
  extensivOrderId?: InputMaybe<Order_By>;
  extensivRefNum?: InputMaybe<Order_By>;
  fulfilledBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  opportunityId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  orderItemIds?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  returnTrackingId?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trackingId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ScreenOrder_Min_Fields = {
  __typename?: 'ScreenOrder_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  estimated_delivery_date?: Maybe<Scalars['timestamp']['output']>;
  extensivOrderId?: Maybe<Scalars['Int']['output']>;
  extensivRefNum?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderItemIds?: Maybe<Array<Scalars['String']['output']>>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  returnTrackingId?: Maybe<Scalars['String']['output']>;
  serial_number?: Maybe<Scalars['String']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  webhookId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ScreenOrder" */
export type ScreenOrder_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  estimated_delivery_date?: InputMaybe<Order_By>;
  extensivOrderId?: InputMaybe<Order_By>;
  extensivRefNum?: InputMaybe<Order_By>;
  fulfilledBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  opportunityId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  orderItemIds?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  returnTrackingId?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trackingId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ScreenOrder" */
export type ScreenOrder_Mutation_Response = {
  __typename?: 'ScreenOrder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ScreenOrder>;
};

/** on_conflict condition type for table "ScreenOrder" */
export type ScreenOrder_On_Conflict = {
  constraint: ScreenOrder_Constraint;
  update_columns?: Array<ScreenOrder_Update_Column>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};

/** Ordering options when selecting data from "ScreenOrder". */
export type ScreenOrder_Order_By = {
  User?: InputMaybe<User_Order_By>;
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  estimated_delivery_date?: InputMaybe<Order_By>;
  extensivOrderId?: InputMaybe<Order_By>;
  extensivRefNum?: InputMaybe<Order_By>;
  fulfilledBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  opportunityId?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  orderItemIds?: InputMaybe<Order_By>;
  order_payload?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  returnTrackingId?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trackingId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhookId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ScreenOrder */
export type ScreenOrder_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ScreenOrder_Prepend_Input = {
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "ScreenOrder" */
export type ScreenOrder_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'email'
  /** column name */
  | 'estimated_delivery_date'
  /** column name */
  | 'extensivOrderId'
  /** column name */
  | 'extensivRefNum'
  /** column name */
  | 'fulfilledBy'
  /** column name */
  | 'id'
  /** column name */
  | 'opportunityId'
  /** column name */
  | 'orderId'
  /** column name */
  | 'orderItemIds'
  /** column name */
  | 'order_payload'
  /** column name */
  | 'owner_user_id'
  /** column name */
  | 'returnTrackingId'
  /** column name */
  | 'serial_number'
  /** column name */
  | 'shopifyOrderId'
  /** column name */
  | 'status'
  /** column name */
  | 'trackingId'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'webhookId';

/** input type for updating data in table "ScreenOrder" */
export type ScreenOrder_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimated_delivery_date?: InputMaybe<Scalars['timestamp']['input']>;
  extensivOrderId?: InputMaybe<Scalars['Int']['input']>;
  extensivRefNum?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  returnTrackingId?: InputMaybe<Scalars['String']['input']>;
  serial_number?: InputMaybe<Scalars['String']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ScreenOrder_Stddev_Fields = {
  __typename?: 'ScreenOrder_stddev_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "ScreenOrder" */
export type ScreenOrder_Stddev_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ScreenOrder_Stddev_Pop_Fields = {
  __typename?: 'ScreenOrder_stddev_pop_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "ScreenOrder" */
export type ScreenOrder_Stddev_Pop_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ScreenOrder_Stddev_Samp_Fields = {
  __typename?: 'ScreenOrder_stddev_samp_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "ScreenOrder" */
export type ScreenOrder_Stddev_Samp_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ScreenOrder" */
export type ScreenOrder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ScreenOrder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ScreenOrder_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimated_delivery_date?: InputMaybe<Scalars['timestamp']['input']>;
  extensivOrderId?: InputMaybe<Scalars['Int']['input']>;
  extensivRefNum?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  order_payload?: InputMaybe<Scalars['jsonb']['input']>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  returnTrackingId?: InputMaybe<Scalars['String']['input']>;
  serial_number?: InputMaybe<Scalars['String']['input']>;
  shopifyOrderId?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  webhookId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ScreenOrder_Sum_Fields = {
  __typename?: 'ScreenOrder_sum_fields';
  extensivOrderId?: Maybe<Scalars['Int']['output']>;
  shopifyOrderId?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "ScreenOrder" */
export type ScreenOrder_Sum_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** update columns of table "ScreenOrder" */
export type ScreenOrder_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'email'
  /** column name */
  | 'estimated_delivery_date'
  /** column name */
  | 'extensivOrderId'
  /** column name */
  | 'extensivRefNum'
  /** column name */
  | 'fulfilledBy'
  /** column name */
  | 'id'
  /** column name */
  | 'opportunityId'
  /** column name */
  | 'orderId'
  /** column name */
  | 'orderItemIds'
  /** column name */
  | 'order_payload'
  /** column name */
  | 'owner_user_id'
  /** column name */
  | 'returnTrackingId'
  /** column name */
  | 'serial_number'
  /** column name */
  | 'shopifyOrderId'
  /** column name */
  | 'status'
  /** column name */
  | 'trackingId'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'webhookId';

export type ScreenOrder_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ScreenOrder_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ScreenOrder_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ScreenOrder_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ScreenOrder_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScreenOrder_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ScreenOrder_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScreenOrder_Set_Input>;
  /** filter the rows which have to be updated */
  where: ScreenOrder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ScreenOrder_Var_Pop_Fields = {
  __typename?: 'ScreenOrder_var_pop_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "ScreenOrder" */
export type ScreenOrder_Var_Pop_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ScreenOrder_Var_Samp_Fields = {
  __typename?: 'ScreenOrder_var_samp_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "ScreenOrder" */
export type ScreenOrder_Var_Samp_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ScreenOrder_Variance_Fields = {
  __typename?: 'ScreenOrder_variance_fields';
  extensivOrderId?: Maybe<Scalars['Float']['output']>;
  shopifyOrderId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "ScreenOrder" */
export type ScreenOrder_Variance_Order_By = {
  extensivOrderId?: InputMaybe<Order_By>;
  shopifyOrderId?: InputMaybe<Order_By>;
};

/** columns and relationships of "SearchResult" */
export type SearchResult = {
  __typename?: 'SearchResult';
  count: Scalars['Int']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};

/** aggregated selection of "SearchResult" */
export type SearchResult_Aggregate = {
  __typename?: 'SearchResult_aggregate';
  aggregate?: Maybe<SearchResult_Aggregate_Fields>;
  nodes: Array<SearchResult>;
};

/** aggregate fields of "SearchResult" */
export type SearchResult_Aggregate_Fields = {
  __typename?: 'SearchResult_aggregate_fields';
  avg?: Maybe<SearchResult_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SearchResult_Max_Fields>;
  min?: Maybe<SearchResult_Min_Fields>;
  stddev?: Maybe<SearchResult_Stddev_Fields>;
  stddev_pop?: Maybe<SearchResult_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SearchResult_Stddev_Samp_Fields>;
  sum?: Maybe<SearchResult_Sum_Fields>;
  var_pop?: Maybe<SearchResult_Var_Pop_Fields>;
  var_samp?: Maybe<SearchResult_Var_Samp_Fields>;
  variance?: Maybe<SearchResult_Variance_Fields>;
};


/** aggregate fields of "SearchResult" */
export type SearchResult_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SearchResult_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type SearchResult_Avg_Fields = {
  __typename?: 'SearchResult_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "SearchResult". All fields are combined with a logical 'AND'. */
export type SearchResult_Bool_Exp = {
  _and?: InputMaybe<Array<SearchResult_Bool_Exp>>;
  _not?: InputMaybe<SearchResult_Bool_Exp>;
  _or?: InputMaybe<Array<SearchResult_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "SearchResult" */
export type SearchResult_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'SearchResult_pkey'
  /** unique or primary key constraint on columns "text" */
  | 'SearchResult_text_unique';

/** input type for incrementing numeric columns in table "SearchResult" */
export type SearchResult_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "SearchResult" */
export type SearchResult_Insert_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type SearchResult_Max_Fields = {
  __typename?: 'SearchResult_max_fields';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type SearchResult_Min_Fields = {
  __typename?: 'SearchResult_min_fields';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "SearchResult" */
export type SearchResult_Mutation_Response = {
  __typename?: 'SearchResult_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SearchResult>;
};

/** on_conflict condition type for table "SearchResult" */
export type SearchResult_On_Conflict = {
  constraint: SearchResult_Constraint;
  update_columns?: Array<SearchResult_Update_Column>;
  where?: InputMaybe<SearchResult_Bool_Exp>;
};

/** Ordering options when selecting data from "SearchResult". */
export type SearchResult_Order_By = {
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SearchResult */
export type SearchResult_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "SearchResult" */
export type SearchResult_Select_Column =
  /** column name */
  | 'count'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'text'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "SearchResult" */
export type SearchResult_Set_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type SearchResult_Stddev_Fields = {
  __typename?: 'SearchResult_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type SearchResult_Stddev_Pop_Fields = {
  __typename?: 'SearchResult_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type SearchResult_Stddev_Samp_Fields = {
  __typename?: 'SearchResult_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "SearchResult" */
export type SearchResult_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SearchResult_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SearchResult_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type SearchResult_Sum_Fields = {
  __typename?: 'SearchResult_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "SearchResult" */
export type SearchResult_Update_Column =
  /** column name */
  | 'count'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'text'
  /** column name */
  | 'updatedAt';

export type SearchResult_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SearchResult_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SearchResult_Set_Input>;
  /** filter the rows which have to be updated */
  where: SearchResult_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SearchResult_Var_Pop_Fields = {
  __typename?: 'SearchResult_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type SearchResult_Var_Samp_Fields = {
  __typename?: 'SearchResult_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type SearchResult_Variance_Fields = {
  __typename?: 'SearchResult_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

export type SetQualityEnum =
  | 'Good'
  | 'NeedsWork'
  | 'Perfect';

/** Boolean expression to compare columns of type "SetQuality". All fields are combined with logical 'AND'. */
export type SetQuality_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SetQuality']['input']>;
  _gt?: InputMaybe<Scalars['SetQuality']['input']>;
  _gte?: InputMaybe<Scalars['SetQuality']['input']>;
  _in?: InputMaybe<Array<Scalars['SetQuality']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SetQuality']['input']>;
  _lte?: InputMaybe<Scalars['SetQuality']['input']>;
  _neq?: InputMaybe<Scalars['SetQuality']['input']>;
  _nin?: InputMaybe<Array<Scalars['SetQuality']['input']>>;
};

export type SetUserGoalsOutput = {
  __typename?: 'SetUserGoalsOutput';
  message: Scalars['String']['output'];
};

export type SfCoachCallOutput = {
  __typename?: 'SfCoachCallOutput';
  scheduled_call_status: Scalars['Boolean']['output'];
};

export type SfFitnessGoalOutput = {
  __typename?: 'SfFitnessGoalOutput';
  goal_id?: Maybe<Scalars['String']['output']>;
  goal_title?: Maybe<Scalars['String']['output']>;
};

export type ShipStatusMessage = {
  __typename?: 'ShipStatusMessage';
  message?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressOutput = {
  __typename?: 'ShippingAddressOutput';
  shipping_address?: Maybe<Props>;
};

export type SleepInsightsDetailOutput = {
  __typename?: 'SleepInsightsDetailOutput';
  averages?: Maybe<Array<Maybe<SleepAverage>>>;
  data?: Maybe<SleepInsightsDetailData>;
};

export type SleepInsightsMetric =
  | 'DeepSleep'
  | 'Efficiency'
  | 'HRVariability'
  | 'RestingHR'
  | 'Score'
  | 'Sleep'
  | 'SleepV2';

export type SleepInsightsOutput = {
  __typename?: 'SleepInsightsOutput';
  bedtimeConsistency?: Maybe<CurrentAverage>;
  circadianConsistency?: Maybe<CurrentAverage>;
  deepSleep?: Maybe<CurrentAverage>;
  efficiency?: Maybe<CurrentAverage>;
  hrv?: Maybe<CurrentAverage>;
  latencyMins?: Maybe<CurrentAverage>;
  morningConsistency?: Maybe<CurrentAverage>;
  remSleep?: Maybe<CurrentAverage>;
  rhr?: Maybe<CurrentAverage>;
  sleepDuration?: Maybe<CurrentAverage>;
};

/** columns and relationships of "SleepSurvey" */
export type SleepSurvey = {
  __typename?: 'SleepSurvey';
  /** An object relationship */
  User: User;
  createdAt: Scalars['timestamp']['output'];
  endTime: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  sleptWithStrap: Scalars['Boolean']['output'];
  startTime: Scalars['timestamp']['output'];
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "SleepSurvey" */
export type SleepSurvey_Aggregate = {
  __typename?: 'SleepSurvey_aggregate';
  aggregate?: Maybe<SleepSurvey_Aggregate_Fields>;
  nodes: Array<SleepSurvey>;
};

export type SleepSurvey_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<SleepSurvey_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<SleepSurvey_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<SleepSurvey_Aggregate_Bool_Exp_Count>;
};

export type SleepSurvey_Aggregate_Bool_Exp_Bool_And = {
  arguments: SleepSurvey_Select_Column_SleepSurvey_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SleepSurvey_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type SleepSurvey_Aggregate_Bool_Exp_Bool_Or = {
  arguments: SleepSurvey_Select_Column_SleepSurvey_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SleepSurvey_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type SleepSurvey_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SleepSurvey_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "SleepSurvey" */
export type SleepSurvey_Aggregate_Fields = {
  __typename?: 'SleepSurvey_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<SleepSurvey_Max_Fields>;
  min?: Maybe<SleepSurvey_Min_Fields>;
};


/** aggregate fields of "SleepSurvey" */
export type SleepSurvey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "SleepSurvey" */
export type SleepSurvey_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SleepSurvey_Max_Order_By>;
  min?: InputMaybe<SleepSurvey_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SleepSurvey" */
export type SleepSurvey_Arr_Rel_Insert_Input = {
  data: Array<SleepSurvey_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SleepSurvey_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SleepSurvey". All fields are combined with a logical 'AND'. */
export type SleepSurvey_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<SleepSurvey_Bool_Exp>>;
  _not?: InputMaybe<SleepSurvey_Bool_Exp>;
  _or?: InputMaybe<Array<SleepSurvey_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  endTime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  sleptWithStrap?: InputMaybe<Boolean_Comparison_Exp>;
  startTime?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SleepSurvey" */
export type SleepSurvey_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'SleepSurvey_pkey';

/** input type for inserting data into table "SleepSurvey" */
export type SleepSurvey_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  sleptWithStrap?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type SleepSurvey_Max_Fields = {
  __typename?: 'SleepSurvey_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "SleepSurvey" */
export type SleepSurvey_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SleepSurvey_Min_Fields = {
  __typename?: 'SleepSurvey_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  endTime?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "SleepSurvey" */
export type SleepSurvey_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "SleepSurvey" */
export type SleepSurvey_Mutation_Response = {
  __typename?: 'SleepSurvey_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SleepSurvey>;
};

/** on_conflict condition type for table "SleepSurvey" */
export type SleepSurvey_On_Conflict = {
  constraint: SleepSurvey_Constraint;
  update_columns?: Array<SleepSurvey_Update_Column>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};

/** Ordering options when selecting data from "SleepSurvey". */
export type SleepSurvey_Order_By = {
  User?: InputMaybe<User_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  sleptWithStrap?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SleepSurvey */
export type SleepSurvey_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "SleepSurvey" */
export type SleepSurvey_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'endTime'
  /** column name */
  | 'id'
  /** column name */
  | 'notes'
  /** column name */
  | 'sleptWithStrap'
  /** column name */
  | 'startTime'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** select "SleepSurvey_aggregate_bool_exp_bool_and_arguments_columns" columns of table "SleepSurvey" */
export type SleepSurvey_Select_Column_SleepSurvey_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'sleptWithStrap';

/** select "SleepSurvey_aggregate_bool_exp_bool_or_arguments_columns" columns of table "SleepSurvey" */
export type SleepSurvey_Select_Column_SleepSurvey_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'sleptWithStrap';

/** input type for updating data in table "SleepSurvey" */
export type SleepSurvey_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  sleptWithStrap?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "SleepSurvey" */
export type SleepSurvey_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SleepSurvey_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SleepSurvey_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  endTime?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  sleptWithStrap?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "SleepSurvey" */
export type SleepSurvey_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'endTime'
  /** column name */
  | 'id'
  /** column name */
  | 'notes'
  /** column name */
  | 'sleptWithStrap'
  /** column name */
  | 'startTime'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type SleepSurvey_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SleepSurvey_Set_Input>;
  /** filter the rows which have to be updated */
  where: SleepSurvey_Bool_Exp;
};

export type StartAssessmentOutput = {
  __typename?: 'StartAssessmentOutput';
  assessment_id?: Maybe<Scalars['String']['output']>;
};

export type StepsV2Output = {
  __typename?: 'StepsV2Output';
  _avg_distance?: Maybe<Scalars['Float']['output']>;
  _avg_steps?: Maybe<Scalars['Float']['output']>;
  _distance?: Maybe<Scalars['Float']['output']>;
  _time?: Maybe<Scalars['String']['output']>;
  _value?: Maybe<Scalars['Float']['output']>;
};

export type StrapLocationEnum =
  | 'bicep'
  | 'clip'
  | 'unknown'
  | 'wrist'
  | 'wrist_dominant'
  | 'wrist_non_dominant';

/** Boolean expression to compare columns of type "StrapLocation". All fields are combined with logical 'AND'. */
export type StrapLocation_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['StrapLocation']['input']>;
  _gt?: InputMaybe<Scalars['StrapLocation']['input']>;
  _gte?: InputMaybe<Scalars['StrapLocation']['input']>;
  _in?: InputMaybe<Array<Scalars['StrapLocation']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['StrapLocation']['input']>;
  _lte?: InputMaybe<Scalars['StrapLocation']['input']>;
  _neq?: InputMaybe<Scalars['StrapLocation']['input']>;
  _nin?: InputMaybe<Array<Scalars['StrapLocation']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "SubWorkoutType". All fields are combined with logical 'AND'. */
export type SubWorkoutType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  _gt?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  _gte?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  _in?: InputMaybe<Array<Scalars['SubWorkoutType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  _lte?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  _neq?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  _nin?: InputMaybe<Array<Scalars['SubWorkoutType']['input']>>;
};

/** columns and relationships of "Subscription" */
export type Subscription = {
  __typename?: 'Subscription';
  /** An array relationship */
  Households: Array<Household>;
  /** An aggregate relationship */
  Households_aggregate: Household_Aggregate;
  /** An array relationship */
  OrderLineItems: Array<OrderLineItem>;
  /** An aggregate relationship */
  OrderLineItems_aggregate: OrderLineItem_Aggregate;
  /** An array relationship */
  PaymentHistories: Array<PaymentHistory>;
  /** An aggregate relationship */
  PaymentHistories_aggregate: PaymentHistory_Aggregate;
  /** An array relationship */
  Users: Array<User>;
  /** An aggregate relationship */
  Users_aggregate: User_Aggregate;
  amount: Scalars['float8']['output'];
  cadence: Scalars['CadenceType']['output'];
  created_at: Scalars['timestamp']['output'];
  expiration: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stripe_item_id: Scalars['String']['output'];
  type: Scalars['HouseholdType']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "Subscription" */
export type SubscriptionHouseholdsArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionHouseholds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionOrderLineItemsArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionOrderLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionPaymentHistoriesArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionPaymentHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Subscription" */
export type SubscriptionUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "SubscriptionLog" */
export type SubscriptionLog = {
  __typename?: 'SubscriptionLog';
  column: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  new_data: Scalars['String']['output'];
  old_data: Scalars['String']['output'];
  subscription_id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "SubscriptionLog" */
export type SubscriptionLog_Aggregate = {
  __typename?: 'SubscriptionLog_aggregate';
  aggregate?: Maybe<SubscriptionLog_Aggregate_Fields>;
  nodes: Array<SubscriptionLog>;
};

/** aggregate fields of "SubscriptionLog" */
export type SubscriptionLog_Aggregate_Fields = {
  __typename?: 'SubscriptionLog_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<SubscriptionLog_Max_Fields>;
  min?: Maybe<SubscriptionLog_Min_Fields>;
};


/** aggregate fields of "SubscriptionLog" */
export type SubscriptionLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SubscriptionLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "SubscriptionLog". All fields are combined with a logical 'AND'. */
export type SubscriptionLog_Bool_Exp = {
  _and?: InputMaybe<Array<SubscriptionLog_Bool_Exp>>;
  _not?: InputMaybe<SubscriptionLog_Bool_Exp>;
  _or?: InputMaybe<Array<SubscriptionLog_Bool_Exp>>;
  column?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  new_data?: InputMaybe<String_Comparison_Exp>;
  old_data?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "SubscriptionLog" */
export type SubscriptionLog_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'SubscriptionLog_pkey';

/** input type for inserting data into table "SubscriptionLog" */
export type SubscriptionLog_Insert_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type SubscriptionLog_Max_Fields = {
  __typename?: 'SubscriptionLog_max_fields';
  column?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  new_data?: Maybe<Scalars['String']['output']>;
  old_data?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type SubscriptionLog_Min_Fields = {
  __typename?: 'SubscriptionLog_min_fields';
  column?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  new_data?: Maybe<Scalars['String']['output']>;
  old_data?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "SubscriptionLog" */
export type SubscriptionLog_Mutation_Response = {
  __typename?: 'SubscriptionLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SubscriptionLog>;
};

/** on_conflict condition type for table "SubscriptionLog" */
export type SubscriptionLog_On_Conflict = {
  constraint: SubscriptionLog_Constraint;
  update_columns?: Array<SubscriptionLog_Update_Column>;
  where?: InputMaybe<SubscriptionLog_Bool_Exp>;
};

/** Ordering options when selecting data from "SubscriptionLog". */
export type SubscriptionLog_Order_By = {
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SubscriptionLog */
export type SubscriptionLog_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "SubscriptionLog" */
export type SubscriptionLog_Select_Column =
  /** column name */
  | 'column'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'new_data'
  /** column name */
  | 'old_data'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "SubscriptionLog" */
export type SubscriptionLog_Set_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "SubscriptionLog" */
export type SubscriptionLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SubscriptionLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SubscriptionLog_Stream_Cursor_Value_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "SubscriptionLog" */
export type SubscriptionLog_Update_Column =
  /** column name */
  | 'column'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'new_data'
  /** column name */
  | 'old_data'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'updated_at';

export type SubscriptionLog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SubscriptionLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: SubscriptionLog_Bool_Exp;
};

/** Boolean expression to compare columns of type "SubscriptionType". All fields are combined with logical 'AND'. */
export type SubscriptionType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SubscriptionType']['input']>;
  _gt?: InputMaybe<Scalars['SubscriptionType']['input']>;
  _gte?: InputMaybe<Scalars['SubscriptionType']['input']>;
  _in?: InputMaybe<Array<Scalars['SubscriptionType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SubscriptionType']['input']>;
  _lte?: InputMaybe<Scalars['SubscriptionType']['input']>;
  _neq?: InputMaybe<Scalars['SubscriptionType']['input']>;
  _nin?: InputMaybe<Array<Scalars['SubscriptionType']['input']>>;
};

/** aggregated selection of "Subscription" */
export type Subscription_Aggregate = {
  __typename?: 'Subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

/** aggregate fields of "Subscription" */
export type Subscription_Aggregate_Fields = {
  __typename?: 'Subscription_aggregate_fields';
  avg?: Maybe<Subscription_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
  stddev?: Maybe<Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Sum_Fields>;
  var_pop?: Maybe<Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Variance_Fields>;
};


/** aggregate fields of "Subscription" */
export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Subscription_Avg_Fields = {
  __typename?: 'Subscription_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Subscription". All fields are combined with a logical 'AND'. */
export type Subscription_Bool_Exp = {
  Households?: InputMaybe<Household_Bool_Exp>;
  Households_aggregate?: InputMaybe<Household_Aggregate_Bool_Exp>;
  OrderLineItems?: InputMaybe<OrderLineItem_Bool_Exp>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Bool_Exp>;
  PaymentHistories?: InputMaybe<PaymentHistory_Bool_Exp>;
  PaymentHistories_aggregate?: InputMaybe<PaymentHistory_Aggregate_Bool_Exp>;
  Users?: InputMaybe<User_Bool_Exp>;
  Users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Subscription_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  cadence?: InputMaybe<CadenceType_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expiration?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  stripe_item_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<HouseholdType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Subscription" */
export type Subscription_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Subscription_pkey';

/** input type for incrementing numeric columns in table "Subscription" */
export type Subscription_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "Subscription" */
export type Subscription_Insert_Input = {
  Households?: InputMaybe<Household_Arr_Rel_Insert_Input>;
  OrderLineItems?: InputMaybe<OrderLineItem_Arr_Rel_Insert_Input>;
  PaymentHistories?: InputMaybe<PaymentHistory_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['float8']['input']>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripe_item_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Subscription_Max_Fields = {
  __typename?: 'Subscription_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripe_item_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['HouseholdType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Subscription_Min_Fields = {
  __typename?: 'Subscription_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  cadence?: Maybe<Scalars['CadenceType']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripe_item_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['HouseholdType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "Subscription" */
export type Subscription_Mutation_Response = {
  __typename?: 'Subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription>;
};

/** input type for inserting object relation for remote table "Subscription" */
export type Subscription_Obj_Rel_Insert_Input = {
  data: Subscription_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

/** on_conflict condition type for table "Subscription" */
export type Subscription_On_Conflict = {
  constraint: Subscription_Constraint;
  update_columns?: Array<Subscription_Update_Column>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "Subscription". */
export type Subscription_Order_By = {
  Households_aggregate?: InputMaybe<Household_Aggregate_Order_By>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Order_By>;
  PaymentHistories_aggregate?: InputMaybe<PaymentHistory_Aggregate_Order_By>;
  Users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  amount?: InputMaybe<Order_By>;
  cadence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stripe_item_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Subscription */
export type Subscription_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Subscription" */
export type Subscription_Select_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'expiration'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'stripe_item_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Subscription" */
export type Subscription_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripe_item_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Subscription_Stddev_Fields = {
  __typename?: 'Subscription_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Stddev_Pop_Fields = {
  __typename?: 'Subscription_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Stddev_Samp_Fields = {
  __typename?: 'Subscription_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "Subscription" */
export type Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  cadence?: InputMaybe<Scalars['CadenceType']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripe_item_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['HouseholdType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Subscription_Sum_Fields = {
  __typename?: 'Subscription_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "Subscription" */
export type Subscription_Update_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'cadence'
  /** column name */
  | 'created_at'
  /** column name */
  | 'expiration'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'stripe_item_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

export type Subscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Var_Pop_Fields = {
  __typename?: 'Subscription_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Subscription_Var_Samp_Fields = {
  __typename?: 'Subscription_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Subscription_Variance_Fields = {
  __typename?: 'Subscription_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "SurveyActivity". All fields are combined with logical 'AND'. */
export type SurveyActivity_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyActivity']['input']>;
  _gt?: InputMaybe<Scalars['SurveyActivity']['input']>;
  _gte?: InputMaybe<Scalars['SurveyActivity']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyActivity']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyActivity']['input']>;
  _lte?: InputMaybe<Scalars['SurveyActivity']['input']>;
  _neq?: InputMaybe<Scalars['SurveyActivity']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyActivity']['input']>>;
};

/** Boolean expression to compare columns of type "SurveyAreasNeedingHelp". All fields are combined with logical 'AND'. */
export type SurveyAreasNeedingHelp_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _eq?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _gt?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _gte?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['SurveyAreasNeedingHelp']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _lte?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _neq?: InputMaybe<Array<Scalars['SurveyAreasNeedingHelp']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['SurveyAreasNeedingHelp']['input']>>>;
};

export type SurveyEquipmentEnum =
  | 'FoamRoller'
  | 'None'
  | 'ResistanceBands'
  | 'Weights'
  | 'WorkoutMat'
  | 'YogaMat';

/** Boolean expression to compare columns of type "SurveyEquipment". All fields are combined with logical 'AND'. */
export type SurveyEquipment_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _eq?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _gt?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _gte?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['SurveyEquipment']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _lte?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _neq?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['SurveyEquipment']['input']>>>;
};

/** Boolean expression to compare columns of type "SurveyEquipment". All fields are combined with logical 'AND'. */
export type SurveyEquipment_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  _gt?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  _gte?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  _lte?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  _neq?: InputMaybe<Scalars['SurveyEquipment']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyEquipment']['input']>>;
};

/** Boolean expression to compare columns of type "SurveyFavoriteExercise". All fields are combined with logical 'AND'. */
export type SurveyFavoriteExercise_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  _gt?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  _gte?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyFavoriteExercise']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  _lte?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  _neq?: InputMaybe<Scalars['SurveyFavoriteExercise']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyFavoriteExercise']['input']>>;
};

/** Boolean expression to compare columns of type "SurveyFitnessAbility". All fields are combined with logical 'AND'. */
export type SurveyFitnessAbility_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  _gt?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  _gte?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyFitnessAbility']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  _lte?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  _neq?: InputMaybe<Scalars['SurveyFitnessAbility']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyFitnessAbility']['input']>>;
};

export type SurveyMotivationEnum =
  | 'GainMuscle'
  | 'Health'
  | 'IncreaseFocus'
  | 'LoseWeight'
  | 'ManageStress'
  | 'Mobility'
  | 'MoreEnergy';

/** Boolean expression to compare columns of type "SurveyMotivation". All fields are combined with logical 'AND'. */
export type SurveyMotivation_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  _gt?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  _gte?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyMotivation']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  _lte?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  _neq?: InputMaybe<Scalars['SurveyMotivation']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyMotivation']['input']>>;
};

export type SurveyQuizActivity =
  | 'AlmostEveryDay'
  | 'DontWorkoutRegularly'
  | 'FourFivePerWeek'
  | 'OnePerWeek'
  | 'TwoThreePerWeek';

/** Boolean expression to compare columns of type "SurveyQuizAge". All fields are combined with logical 'AND'. */
export type SurveyQuizAge_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  _gt?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  _gte?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyQuizAge']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  _lte?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  _neq?: InputMaybe<Scalars['SurveyQuizAge']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyQuizAge']['input']>>;
};

export type SurveyQuizGenderEnum =
  | 'Female'
  | 'Male'
  | 'Other'
  | 'female'
  | 'male';

/** Boolean expression to compare columns of type "SurveyQuizGender". All fields are combined with logical 'AND'. */
export type SurveyQuizGender_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  _gt?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  _gte?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyQuizGender']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  _lte?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  _neq?: InputMaybe<Scalars['SurveyQuizGender']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyQuizGender']['input']>>;
};

/** Boolean expression to compare columns of type "SurveyServicesTried". All fields are combined with logical 'AND'. */
export type SurveyServicesTried_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _eq?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _gt?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _gte?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['SurveyServicesTried']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _lte?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _neq?: InputMaybe<Array<Scalars['SurveyServicesTried']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['SurveyServicesTried']['input']>>>;
};

export type SurveySleepEnum =
  | 'OftenTired'
  | 'SleepWell'
  | 'TroubleSleeping';

/** Boolean expression to compare columns of type "SurveySleep". All fields are combined with logical 'AND'. */
export type SurveySleep_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveySleep']['input']>;
  _gt?: InputMaybe<Scalars['SurveySleep']['input']>;
  _gte?: InputMaybe<Scalars['SurveySleep']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveySleep']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveySleep']['input']>;
  _lte?: InputMaybe<Scalars['SurveySleep']['input']>;
  _neq?: InputMaybe<Scalars['SurveySleep']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveySleep']['input']>>;
};

/** Boolean expression to compare columns of type "SurveyWeightLossGoal". All fields are combined with logical 'AND'. */
export type SurveyWeightLossGoal_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  _gt?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  _gte?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyWeightLossGoal']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  _lte?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  _neq?: InputMaybe<Scalars['SurveyWeightLossGoal']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyWeightLossGoal']['input']>>;
};

/** Boolean expression to compare columns of type "SurveyWeightLossTimeline". All fields are combined with logical 'AND'. */
export type SurveyWeightLossTimeline_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
  _gt?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
  _gte?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyWeightLossTimeline']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
  _lte?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
  _neq?: InputMaybe<Scalars['SurveyWeightLossTimeline']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyWeightLossTimeline']['input']>>;
};

export type SurveyWorkoutsEnum =
  | 'Cycling'
  | 'Dancing'
  | 'HIITCardio'
  | 'Meditation'
  | 'Running'
  | 'StrengthTraining'
  | 'Stretching'
  | 'Walking'
  | 'Yoga';

/** Boolean expression to compare columns of type "SurveyWorkouts". All fields are combined with logical 'AND'. */
export type SurveyWorkouts_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _eq?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _gt?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _gte?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['SurveyWorkouts']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _lte?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _neq?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['SurveyWorkouts']['input']>>>;
};

/** Boolean expression to compare columns of type "SurveyWorkouts". All fields are combined with logical 'AND'. */
export type SurveyWorkouts_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  _gt?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  _gte?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  _in?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  _lte?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  _neq?: InputMaybe<Scalars['SurveyWorkouts']['input']>;
  _nin?: InputMaybe<Array<Scalars['SurveyWorkouts']['input']>>;
};

export type ThemeColorEnum =
  | 'Aqua'
  | 'Coral'
  | 'Saffron'
  | 'Sage'
  | 'Ultraviolet';

/** Boolean expression to compare columns of type "ThemeColor". All fields are combined with logical 'AND'. */
export type ThemeColor_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ThemeColor']['input']>;
  _gt?: InputMaybe<Scalars['ThemeColor']['input']>;
  _gte?: InputMaybe<Scalars['ThemeColor']['input']>;
  _in?: InputMaybe<Array<Scalars['ThemeColor']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['ThemeColor']['input']>;
  _lte?: InputMaybe<Scalars['ThemeColor']['input']>;
  _neq?: InputMaybe<Scalars['ThemeColor']['input']>;
  _nin?: InputMaybe<Array<Scalars['ThemeColor']['input']>>;
};

export type TimeMovingOutput = {
  __typename?: 'TimeMovingOutput';
  data?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  totals?: Maybe<Scalars['json']['output']>;
};

export type TimeWorkingOutOutput = {
  __typename?: 'TimeWorkingOutOutput';
  avg?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<WorkinWorkoutEnum>;
  datePart?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  ovarll_avg?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type TitleWithDescription = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "Token" */
export type Token = {
  __typename?: 'Token';
  expires_at: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** Boolean expression to compare columns of type "TokenChangeSource". All fields are combined with logical 'AND'. */
export type TokenChangeSource_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  _gt?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  _gte?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  _in?: InputMaybe<Array<Scalars['TokenChangeSource']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  _lte?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  _neq?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  _nin?: InputMaybe<Array<Scalars['TokenChangeSource']['input']>>;
};

/** Boolean expression to compare columns of type "TokenChangeType". All fields are combined with logical 'AND'. */
export type TokenChangeType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['TokenChangeType']['input']>;
  _gt?: InputMaybe<Scalars['TokenChangeType']['input']>;
  _gte?: InputMaybe<Scalars['TokenChangeType']['input']>;
  _in?: InputMaybe<Array<Scalars['TokenChangeType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['TokenChangeType']['input']>;
  _lte?: InputMaybe<Scalars['TokenChangeType']['input']>;
  _neq?: InputMaybe<Scalars['TokenChangeType']['input']>;
  _nin?: InputMaybe<Array<Scalars['TokenChangeType']['input']>>;
};

/** columns and relationships of "TokenHistory" */
export type TokenHistory = {
  __typename?: 'TokenHistory';
  /** An object relationship */
  User: User;
  /** An array relationship */
  UserWorkouts: Array<UserWorkout>;
  /** An aggregate relationship */
  UserWorkouts_aggregate: UserWorkout_Aggregate;
  change_source: Scalars['TokenChangeSource']['output'];
  change_type: Scalars['TokenChangeType']['output'];
  count: Scalars['Int']['output'];
  count_after: Scalars['Int']['output'];
  count_before: Scalars['Int']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "TokenHistory" */
export type TokenHistoryUserWorkoutsArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


/** columns and relationships of "TokenHistory" */
export type TokenHistoryUserWorkouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};

/** aggregated selection of "TokenHistory" */
export type TokenHistory_Aggregate = {
  __typename?: 'TokenHistory_aggregate';
  aggregate?: Maybe<TokenHistory_Aggregate_Fields>;
  nodes: Array<TokenHistory>;
};

export type TokenHistory_Aggregate_Bool_Exp = {
  count?: InputMaybe<TokenHistory_Aggregate_Bool_Exp_Count>;
};

export type TokenHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TokenHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TokenHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TokenHistory" */
export type TokenHistory_Aggregate_Fields = {
  __typename?: 'TokenHistory_aggregate_fields';
  avg?: Maybe<TokenHistory_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TokenHistory_Max_Fields>;
  min?: Maybe<TokenHistory_Min_Fields>;
  stddev?: Maybe<TokenHistory_Stddev_Fields>;
  stddev_pop?: Maybe<TokenHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TokenHistory_Stddev_Samp_Fields>;
  sum?: Maybe<TokenHistory_Sum_Fields>;
  var_pop?: Maybe<TokenHistory_Var_Pop_Fields>;
  var_samp?: Maybe<TokenHistory_Var_Samp_Fields>;
  variance?: Maybe<TokenHistory_Variance_Fields>;
};


/** aggregate fields of "TokenHistory" */
export type TokenHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TokenHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "TokenHistory" */
export type TokenHistory_Aggregate_Order_By = {
  avg?: InputMaybe<TokenHistory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TokenHistory_Max_Order_By>;
  min?: InputMaybe<TokenHistory_Min_Order_By>;
  stddev?: InputMaybe<TokenHistory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TokenHistory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TokenHistory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TokenHistory_Sum_Order_By>;
  var_pop?: InputMaybe<TokenHistory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TokenHistory_Var_Samp_Order_By>;
  variance?: InputMaybe<TokenHistory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TokenHistory" */
export type TokenHistory_Arr_Rel_Insert_Input = {
  data: Array<TokenHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TokenHistory_On_Conflict>;
};

/** aggregate avg on columns */
export type TokenHistory_Avg_Fields = {
  __typename?: 'TokenHistory_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "TokenHistory" */
export type TokenHistory_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TokenHistory". All fields are combined with a logical 'AND'. */
export type TokenHistory_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserWorkouts?: InputMaybe<UserWorkout_Bool_Exp>;
  UserWorkouts_aggregate?: InputMaybe<UserWorkout_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<TokenHistory_Bool_Exp>>;
  _not?: InputMaybe<TokenHistory_Bool_Exp>;
  _or?: InputMaybe<Array<TokenHistory_Bool_Exp>>;
  change_source?: InputMaybe<TokenChangeSource_Comparison_Exp>;
  change_type?: InputMaybe<TokenChangeType_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  count_after?: InputMaybe<Int_Comparison_Exp>;
  count_before?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TokenHistory" */
export type TokenHistory_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'TokenHistory_pkey';

/** input type for incrementing numeric columns in table "TokenHistory" */
export type TokenHistory_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  count_after?: InputMaybe<Scalars['Int']['input']>;
  count_before?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "TokenHistory" */
export type TokenHistory_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserWorkouts?: InputMaybe<UserWorkout_Arr_Rel_Insert_Input>;
  change_source?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  change_type?: InputMaybe<Scalars['TokenChangeType']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_after?: InputMaybe<Scalars['Int']['input']>;
  count_before?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TokenHistory_Max_Fields = {
  __typename?: 'TokenHistory_max_fields';
  change_source?: Maybe<Scalars['TokenChangeSource']['output']>;
  change_type?: Maybe<Scalars['TokenChangeType']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  count_after?: Maybe<Scalars['Int']['output']>;
  count_before?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "TokenHistory" */
export type TokenHistory_Max_Order_By = {
  change_source?: InputMaybe<Order_By>;
  change_type?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TokenHistory_Min_Fields = {
  __typename?: 'TokenHistory_min_fields';
  change_source?: Maybe<Scalars['TokenChangeSource']['output']>;
  change_type?: Maybe<Scalars['TokenChangeType']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  count_after?: Maybe<Scalars['Int']['output']>;
  count_before?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "TokenHistory" */
export type TokenHistory_Min_Order_By = {
  change_source?: InputMaybe<Order_By>;
  change_type?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TokenHistory" */
export type TokenHistory_Mutation_Response = {
  __typename?: 'TokenHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TokenHistory>;
};

/** input type for inserting object relation for remote table "TokenHistory" */
export type TokenHistory_Obj_Rel_Insert_Input = {
  data: TokenHistory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TokenHistory_On_Conflict>;
};

/** on_conflict condition type for table "TokenHistory" */
export type TokenHistory_On_Conflict = {
  constraint: TokenHistory_Constraint;
  update_columns?: Array<TokenHistory_Update_Column>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "TokenHistory". */
export type TokenHistory_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserWorkouts_aggregate?: InputMaybe<UserWorkout_Aggregate_Order_By>;
  change_source?: InputMaybe<Order_By>;
  change_type?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TokenHistory */
export type TokenHistory_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "TokenHistory" */
export type TokenHistory_Select_Column =
  /** column name */
  | 'change_source'
  /** column name */
  | 'change_type'
  /** column name */
  | 'count'
  /** column name */
  | 'count_after'
  /** column name */
  | 'count_before'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** input type for updating data in table "TokenHistory" */
export type TokenHistory_Set_Input = {
  change_source?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  change_type?: InputMaybe<Scalars['TokenChangeType']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_after?: InputMaybe<Scalars['Int']['input']>;
  count_before?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type TokenHistory_Stddev_Fields = {
  __typename?: 'TokenHistory_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "TokenHistory" */
export type TokenHistory_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TokenHistory_Stddev_Pop_Fields = {
  __typename?: 'TokenHistory_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "TokenHistory" */
export type TokenHistory_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TokenHistory_Stddev_Samp_Fields = {
  __typename?: 'TokenHistory_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "TokenHistory" */
export type TokenHistory_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TokenHistory" */
export type TokenHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TokenHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TokenHistory_Stream_Cursor_Value_Input = {
  change_source?: InputMaybe<Scalars['TokenChangeSource']['input']>;
  change_type?: InputMaybe<Scalars['TokenChangeType']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_after?: InputMaybe<Scalars['Int']['input']>;
  count_before?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type TokenHistory_Sum_Fields = {
  __typename?: 'TokenHistory_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
  count_after?: Maybe<Scalars['Int']['output']>;
  count_before?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "TokenHistory" */
export type TokenHistory_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** update columns of table "TokenHistory" */
export type TokenHistory_Update_Column =
  /** column name */
  | 'change_source'
  /** column name */
  | 'change_type'
  /** column name */
  | 'count'
  /** column name */
  | 'count_after'
  /** column name */
  | 'count_before'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type TokenHistory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TokenHistory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TokenHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: TokenHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TokenHistory_Var_Pop_Fields = {
  __typename?: 'TokenHistory_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "TokenHistory" */
export type TokenHistory_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TokenHistory_Var_Samp_Fields = {
  __typename?: 'TokenHistory_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "TokenHistory" */
export type TokenHistory_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TokenHistory_Variance_Fields = {
  __typename?: 'TokenHistory_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  count_after?: Maybe<Scalars['Float']['output']>;
  count_before?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "TokenHistory" */
export type TokenHistory_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
  count_after?: InputMaybe<Order_By>;
  count_before?: InputMaybe<Order_By>;
};

/** aggregated selection of "Token" */
export type Token_Aggregate = {
  __typename?: 'Token_aggregate';
  aggregate?: Maybe<Token_Aggregate_Fields>;
  nodes: Array<Token>;
};

/** aggregate fields of "Token" */
export type Token_Aggregate_Fields = {
  __typename?: 'Token_aggregate_fields';
  avg?: Maybe<Token_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Token_Max_Fields>;
  min?: Maybe<Token_Min_Fields>;
  stddev?: Maybe<Token_Stddev_Fields>;
  stddev_pop?: Maybe<Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Token_Stddev_Samp_Fields>;
  sum?: Maybe<Token_Sum_Fields>;
  var_pop?: Maybe<Token_Var_Pop_Fields>;
  var_samp?: Maybe<Token_Var_Samp_Fields>;
  variance?: Maybe<Token_Variance_Fields>;
};


/** aggregate fields of "Token" */
export type Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Token_Avg_Fields = {
  __typename?: 'Token_avg_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "Token". All fields are combined with a logical 'AND'. */
export type Token_Bool_Exp = {
  _and?: InputMaybe<Array<Token_Bool_Exp>>;
  _not?: InputMaybe<Token_Bool_Exp>;
  _or?: InputMaybe<Array<Token_Bool_Exp>>;
  expires_at?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Token" */
export type Token_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Token_pkey';

/** input type for incrementing numeric columns in table "Token" */
export type Token_Inc_Input = {
  expires_at?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Token" */
export type Token_Insert_Input = {
  expires_at?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Token_Max_Fields = {
  __typename?: 'Token_max_fields';
  expires_at?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Token_Min_Fields = {
  __typename?: 'Token_min_fields';
  expires_at?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "Token" */
export type Token_Mutation_Response = {
  __typename?: 'Token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Token>;
};

/** on_conflict condition type for table "Token" */
export type Token_On_Conflict = {
  constraint: Token_Constraint;
  update_columns?: Array<Token_Update_Column>;
  where?: InputMaybe<Token_Bool_Exp>;
};

/** Ordering options when selecting data from "Token". */
export type Token_Order_By = {
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Token */
export type Token_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "Token" */
export type Token_Select_Column =
  /** column name */
  | 'expires_at'
  /** column name */
  | 'id'
  /** column name */
  | 'token'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "Token" */
export type Token_Set_Input = {
  expires_at?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Token_Stddev_Fields = {
  __typename?: 'Token_stddev_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Token_Stddev_Pop_Fields = {
  __typename?: 'Token_stddev_pop_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Token_Stddev_Samp_Fields = {
  __typename?: 'Token_stddev_samp_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "Token" */
export type Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Token_Stream_Cursor_Value_Input = {
  expires_at?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Token_Sum_Fields = {
  __typename?: 'Token_sum_fields';
  expires_at?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "Token" */
export type Token_Update_Column =
  /** column name */
  | 'expires_at'
  /** column name */
  | 'id'
  /** column name */
  | 'token'
  /** column name */
  | 'updated_at';

export type Token_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Token_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Token_Set_Input>;
  /** filter the rows which have to be updated */
  where: Token_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Token_Var_Pop_Fields = {
  __typename?: 'Token_var_pop_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Token_Var_Samp_Fields = {
  __typename?: 'Token_var_samp_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Token_Variance_Fields = {
  __typename?: 'Token_variance_fields';
  expires_at?: Maybe<Scalars['Float']['output']>;
};

export type TutorialModeEnum =
  | 'EVERYSET'
  | 'FIRSTSETONLY'
  | 'NEVER';

/** Boolean expression to compare columns of type "TutorialMode". All fields are combined with logical 'AND'. */
export type TutorialMode_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['TutorialMode']['input']>;
  _gt?: InputMaybe<Scalars['TutorialMode']['input']>;
  _gte?: InputMaybe<Scalars['TutorialMode']['input']>;
  _in?: InputMaybe<Array<Scalars['TutorialMode']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['TutorialMode']['input']>;
  _lte?: InputMaybe<Scalars['TutorialMode']['input']>;
  _neq?: InputMaybe<Scalars['TutorialMode']['input']>;
  _nin?: InputMaybe<Array<Scalars['TutorialMode']['input']>>;
};

/** Boolean expression to compare columns of type "Unit". All fields are combined with logical 'AND'. */
export type Unit_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Unit']['input']>;
  _gt?: InputMaybe<Scalars['Unit']['input']>;
  _gte?: InputMaybe<Scalars['Unit']['input']>;
  _in?: InputMaybe<Array<Scalars['Unit']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Unit']['input']>;
  _lte?: InputMaybe<Scalars['Unit']['input']>;
  _neq?: InputMaybe<Scalars['Unit']['input']>;
  _nin?: InputMaybe<Array<Scalars['Unit']['input']>>;
};

export type UploadContents = {
  __typename?: 'UploadContents';
  createdAt?: Maybe<Scalars['String']['output']>;
  device?: Maybe<DevicesType>;
  fileType?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type UploadSetOutput = {
  __typename?: 'UploadSetOutput';
  did_progress: Scalars['Boolean']['output'];
  mastery: RepMasteryResponse;
  set_id: Scalars['String']['output'];
  set_quality?: Maybe<SetQualityEnum>;
};

export type UploadSetV2Output = {
  __typename?: 'UploadSetV2Output';
  set_id?: Maybe<Scalars['String']['output']>;
  set_quality?: Maybe<SetQualityEnum>;
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  /** An object relationship */
  Coach?: Maybe<Coach>;
  /** An array relationship */
  CoachedNutritions: Array<CoachedNutrition>;
  /** An aggregate relationship */
  CoachedNutritions_aggregate: CoachedNutrition_Aggregate;
  /** An array relationship */
  Coaches: Array<Coach>;
  /** An aggregate relationship */
  Coaches_aggregate: Coach_Aggregate;
  /** An array relationship */
  DNAResults: Array<DnaResults>;
  /** An aggregate relationship */
  DNAResults_aggregate: DnaResults_Aggregate;
  /** An array relationship */
  Devices: Array<Device>;
  /** An aggregate relationship */
  Devices_aggregate: Device_Aggregate;
  /** An array relationship */
  Diagnostics: Array<Diagnostics>;
  /** An aggregate relationship */
  Diagnostics_aggregate: Diagnostics_Aggregate;
  /** An object relationship */
  Goal?: Maybe<Goal>;
  /** An array relationship */
  HeartRateDiagnostics: Array<HeartRateDiagnostics>;
  /** An aggregate relationship */
  HeartRateDiagnostics_aggregate: HeartRateDiagnostics_Aggregate;
  /** An object relationship */
  Household?: Maybe<Household>;
  /** An array relationship */
  Households: Array<Household>;
  /** An aggregate relationship */
  Households_aggregate: Household_Aggregate;
  /** An array relationship */
  PlanDays: Array<PlanDay>;
  /** An aggregate relationship */
  PlanDays_aggregate: PlanDay_Aggregate;
  /** An object relationship */
  Pro?: Maybe<Pro>;
  /** An array relationship */
  Pros: Array<Pro>;
  /** An aggregate relationship */
  Pros_aggregate: Pro_Aggregate;
  /** An array relationship */
  QuizSurveys: Array<QuizSurvey>;
  /** An aggregate relationship */
  QuizSurveys_aggregate: QuizSurvey_Aggregate;
  /** An array relationship */
  ScreenOrders: Array<ScreenOrder>;
  /** An aggregate relationship */
  ScreenOrders_aggregate: ScreenOrder_Aggregate;
  /** An array relationship */
  SleepSurveys: Array<SleepSurvey>;
  /** An aggregate relationship */
  SleepSurveys_aggregate: SleepSurvey_Aggregate;
  /** An object relationship */
  Subscription?: Maybe<Subscription>;
  /** An array relationship */
  TokenHistories: Array<TokenHistory>;
  /** An aggregate relationship */
  TokenHistories_aggregate: TokenHistory_Aggregate;
  /** An array relationship */
  UserAggregates: Array<UserAggregates>;
  /** An aggregate relationship */
  UserAggregates_aggregate: UserAggregates_Aggregate;
  /** An array relationship */
  UserAssessments: Array<UserAssessment>;
  /** An aggregate relationship */
  UserAssessments_aggregate: UserAssessment_Aggregate;
  /** An array relationship */
  UserFeatureControls: Array<UserFeatureControl>;
  /** An aggregate relationship */
  UserFeatureControls_aggregate: UserFeatureControl_Aggregate;
  /** An array relationship */
  UserFeedbacks: Array<UserFeedback>;
  /** An aggregate relationship */
  UserFeedbacks_aggregate: UserFeedback_Aggregate;
  /** An array relationship */
  UserFinancePlans: Array<UserFinancePlan>;
  /** An aggregate relationship */
  UserFinancePlans_aggregate: UserFinancePlan_Aggregate;
  /** An array relationship */
  UserMovementDailies: Array<UserMovementDaily>;
  /** An aggregate relationship */
  UserMovementDailies_aggregate: UserMovementDaily_Aggregate;
  /** An array relationship */
  UserPreferences: Array<UserPreferences>;
  /** An aggregate relationship */
  UserPreferences_aggregate: UserPreferences_Aggregate;
  /** An array relationship */
  UserProductHistories: Array<UserProductHistory>;
  /** An aggregate relationship */
  UserProductHistories_aggregate: UserProductHistory_Aggregate;
  /** An array relationship */
  UserProducts: Array<UserProduct>;
  /** An aggregate relationship */
  UserProducts_aggregate: UserProduct_Aggregate;
  /** An array relationship */
  UserReadinessLogs: Array<UserReadinessLog>;
  /** An aggregate relationship */
  UserReadinessLogs_aggregate: UserReadinessLog_Aggregate;
  /** An array relationship */
  UserReadinesses: Array<UserReadiness>;
  /** An aggregate relationship */
  UserReadinesses_aggregate: UserReadiness_Aggregate;
  /** An array relationship */
  UserReimbursmentHistories: Array<UserReimbursmentHistory>;
  /** An aggregate relationship */
  UserReimbursmentHistories_aggregate: UserReimbursmentHistory_Aggregate;
  /** An array relationship */
  UserReps: Array<UserRep>;
  /** An aggregate relationship */
  UserReps_aggregate: UserRep_Aggregate;
  /** An array relationship */
  UserSets: Array<UserSet>;
  /** An aggregate relationship */
  UserSets_aggregate: UserSet_Aggregate;
  /** An array relationship */
  UserStates: Array<UserState>;
  /** An aggregate relationship */
  UserStates_aggregate: UserState_Aggregate;
  /** An array relationship */
  UserWorkouts: Array<UserWorkout>;
  /** An aggregate relationship */
  UserWorkouts_aggregate: UserWorkout_Aggregate;
  /** An array relationship */
  WeeklyPlans: Array<WeeklyPlan>;
  /** An aggregate relationship */
  WeeklyPlans_aggregate: WeeklyPlan_Aggregate;
  /** An array relationship */
  _FollowingPros: Array<_FollowingPro>;
  /** An aggregate relationship */
  _FollowingPros_aggregate: _FollowingPro_Aggregate;
  activityLevel?: Maybe<Scalars['Int']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  app_download_date?: Maybe<Scalars['timestamp']['output']>;
  auth0UserId?: Maybe<Scalars['String']['output']>;
  biostrapId?: Maybe<Scalars['String']['output']>;
  biostrapName?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  brace_prone_level: Scalars['Int']['output'];
  brace_side_level: Scalars['Int']['output'];
  brace_supine_level: Scalars['Int']['output'];
  cardio_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  cardio_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  cardio_level: Scalars['Int']['output'];
  coach_id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  days_signal_worn?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  email: Scalars['String']['output'];
  enable_asensei: Scalars['Boolean']['output'];
  enable_diagnostics: Scalars['Boolean']['output'];
  equipments?: Maybe<Array<Scalars['Int']['output']>>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitness_goal_id?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  goalWeight?: Maybe<Scalars['float8']['output']>;
  has_warming_state?: Maybe<Scalars['Boolean']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['float8']['output']>;
  hinge_level: Scalars['Int']['output'];
  household_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inRingMode?: Maybe<Scalars['Boolean']['output']>;
  is_onboarded?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  limitations?: Maybe<Array<Scalars['Int']['output']>>;
  lunge_level: Scalars['Int']['output'];
  mastery_prestige_id?: Maybe<Scalars['String']['output']>;
  maxHeartRate?: Maybe<Scalars['Int']['output']>;
  mostImportantFitnessGoal?: Maybe<Scalars['String']['output']>;
  nutrients_category?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  pearId?: Maybe<Scalars['String']['output']>;
  pearRefreshToken?: Maybe<Scalars['String']['output']>;
  pearSessionToken?: Maybe<Scalars['String']['output']>;
  pearTokenExpiration?: Maybe<Scalars['timestamp']['output']>;
  pearUserId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferMetric?: Maybe<Scalars['Boolean']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  preferredWorkouts?: Maybe<Array<Scalars['Int']['output']>>;
  profileImage?: Maybe<Scalars['String']['output']>;
  pull_horizontal_level: Scalars['Int']['output'];
  pull_vertical_level: Scalars['Int']['output'];
  push_horizontal_level: Scalars['Int']['output'];
  push_vertical_level: Scalars['Int']['output'];
  radioPreferences?: Maybe<Array<Scalars['String']['output']>>;
  referredId?: Maybe<Scalars['String']['output']>;
  reimbursementTokens: Scalars['Int']['output'];
  ringId?: Maybe<Scalars['String']['output']>;
  ringMacAddress?: Maybe<Scalars['String']['output']>;
  ringName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['Role']['output']>;
  screen_id?: Maybe<Scalars['String']['output']>;
  screen_name?: Maybe<Scalars['String']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  sf_account_id?: Maybe<Scalars['String']['output']>;
  shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  signal_color?: Maybe<Scalars['String']['output']>;
  squat_level: Scalars['Int']['output'];
  strapColor?: Maybe<Scalars['String']['output']>;
  strap_location: Scalars['StrapLocation']['output'];
  strength_level: Scalars['float8']['output'];
  subscription_id?: Maybe<Scalars['String']['output']>;
  swabId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  v02_max_raw: Scalars['float8']['output'];
  weight?: Maybe<Scalars['float8']['output']>;
  weightUnit?: Maybe<Scalars['Unit']['output']>;
  weight_kg?: Maybe<Scalars['float8']['output']>;
  weight_lbs?: Maybe<Scalars['float8']['output']>;
  workin_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  workin_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  workin_level: Scalars['Int']['output'];
  workoutFrequency?: Maybe<Array<Scalars['Int']['output']>>;
};


/** columns and relationships of "User" */
export type UserCoachedNutritionsArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserCoachedNutritions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserCoachesArgs = {
  distinct_on?: InputMaybe<Array<Coach_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coach_Order_By>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserCoaches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coach_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coach_Order_By>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserDnaResultsArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserDnaResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserHeartRateDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserHeartRateDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserHouseholdsArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserHouseholds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserPlanDaysArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserPlanDays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserProsArgs = {
  distinct_on?: InputMaybe<Array<Pro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pro_Order_By>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserPros_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pro_Order_By>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserQuizSurveysArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserQuizSurveys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserScreenOrdersArgs = {
  distinct_on?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ScreenOrder_Order_By>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserScreenOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ScreenOrder_Order_By>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserSleepSurveysArgs = {
  distinct_on?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SleepSurvey_Order_By>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserSleepSurveys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SleepSurvey_Order_By>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserTokenHistoriesArgs = {
  distinct_on?: InputMaybe<Array<TokenHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TokenHistory_Order_By>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserTokenHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TokenHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TokenHistory_Order_By>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserAggregatesArgs = {
  distinct_on?: InputMaybe<Array<UserAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAggregates_Order_By>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserAggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAggregates_Order_By>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserAssessmentsArgs = {
  distinct_on?: InputMaybe<Array<UserAssessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAssessment_Order_By>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserAssessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAssessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAssessment_Order_By>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserFeatureControlsArgs = {
  distinct_on?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeatureControl_Order_By>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserFeatureControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeatureControl_Order_By>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserFeedbacksArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserFeedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserFinancePlansArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserFinancePlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserMovementDailiesArgs = {
  distinct_on?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMovementDaily_Order_By>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserMovementDailies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMovementDaily_Order_By>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserProductHistoriesArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserProductHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserProductsArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReadinessLogsArgs = {
  distinct_on?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadinessLog_Order_By>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReadinessLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadinessLog_Order_By>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReadinessesArgs = {
  distinct_on?: InputMaybe<Array<UserReadiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadiness_Order_By>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReadinesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReadiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadiness_Order_By>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReimbursmentHistoriesArgs = {
  distinct_on?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReimbursmentHistory_Order_By>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReimbursmentHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReimbursmentHistory_Order_By>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserRepsArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserReps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserSetsArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserSets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserStatesArgs = {
  distinct_on?: InputMaybe<Array<UserState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserState_Order_By>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserState_Order_By>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserWorkoutsArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserUserWorkouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserWeeklyPlansArgs = {
  distinct_on?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WeeklyPlan_Order_By>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserWeeklyPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WeeklyPlan_Order_By>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


/** columns and relationships of "User" */
export type User_FollowingProsArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


/** columns and relationships of "User" */
export type User_FollowingPros_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};

/** columns and relationships of "UserAggregates" */
export type UserAggregates = {
  __typename?: 'UserAggregates';
  /** An object relationship */
  User: User;
  allowedAwakeTime?: Maybe<Scalars['float8']['output']>;
  awakeningPenalty?: Maybe<Scalars['float8']['output']>;
  awakenings?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamp']['output'];
  date: Scalars['String']['output'];
  deepSleepPenalty?: Maybe<Scalars['float8']['output']>;
  highHRPenalty?: Maybe<Scalars['float8']['output']>;
  id: Scalars['String']['output'];
  medianHR?: Maybe<Scalars['Int']['output']>;
  minutesAwake?: Maybe<Scalars['Int']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Int']['output']>;
  minutesLightSleep?: Maybe<Scalars['Int']['output']>;
  monthlyHR?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  restlessPenalty?: Maybe<Scalars['float8']['output']>;
  restlessnessMild?: Maybe<Scalars['Int']['output']>;
  restlessnessModerate?: Maybe<Scalars['Int']['output']>;
  restlessnessNone?: Maybe<Scalars['Int']['output']>;
  restlessnessSevere?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEfficiency?: Maybe<Scalars['float8']['output']>;
  sleepGoal?: Maybe<Scalars['Int']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['float8']['output']>;
  sleepQuality?: Maybe<Scalars['float8']['output']>;
  totalSleepPenalty?: Maybe<Scalars['float8']['output']>;
  totalSleepTime?: Maybe<Scalars['Int']['output']>;
  totalTimeInBed?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "UserAggregates" */
export type UserAggregates_Aggregate = {
  __typename?: 'UserAggregates_aggregate';
  aggregate?: Maybe<UserAggregates_Aggregate_Fields>;
  nodes: Array<UserAggregates>;
};

export type UserAggregates_Aggregate_Bool_Exp = {
  avg?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserAggregates_Aggregate_Bool_Exp_Var_Samp>;
};

export type UserAggregates_Aggregate_Bool_Exp_Avg = {
  arguments: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Corr = {
  arguments: UserAggregates_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Corr_Arguments = {
  X: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type UserAggregates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserAggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: UserAggregates_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type UserAggregates_Aggregate_Bool_Exp_Max = {
  arguments: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Min = {
  arguments: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Sum = {
  arguments: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAggregates_Aggregate_Bool_Exp_Var_Samp = {
  arguments: UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAggregates_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "UserAggregates" */
export type UserAggregates_Aggregate_Fields = {
  __typename?: 'UserAggregates_aggregate_fields';
  avg?: Maybe<UserAggregates_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserAggregates_Max_Fields>;
  min?: Maybe<UserAggregates_Min_Fields>;
  stddev?: Maybe<UserAggregates_Stddev_Fields>;
  stddev_pop?: Maybe<UserAggregates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserAggregates_Stddev_Samp_Fields>;
  sum?: Maybe<UserAggregates_Sum_Fields>;
  var_pop?: Maybe<UserAggregates_Var_Pop_Fields>;
  var_samp?: Maybe<UserAggregates_Var_Samp_Fields>;
  variance?: Maybe<UserAggregates_Variance_Fields>;
};


/** aggregate fields of "UserAggregates" */
export type UserAggregates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserAggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserAggregates" */
export type UserAggregates_Aggregate_Order_By = {
  avg?: InputMaybe<UserAggregates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserAggregates_Max_Order_By>;
  min?: InputMaybe<UserAggregates_Min_Order_By>;
  stddev?: InputMaybe<UserAggregates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserAggregates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserAggregates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserAggregates_Sum_Order_By>;
  var_pop?: InputMaybe<UserAggregates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserAggregates_Var_Samp_Order_By>;
  variance?: InputMaybe<UserAggregates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserAggregates" */
export type UserAggregates_Arr_Rel_Insert_Input = {
  data: Array<UserAggregates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAggregates_On_Conflict>;
};

/** aggregate avg on columns */
export type UserAggregates_Avg_Fields = {
  __typename?: 'UserAggregates_avg_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserAggregates" */
export type UserAggregates_Avg_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserAggregates". All fields are combined with a logical 'AND'. */
export type UserAggregates_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserAggregates_Bool_Exp>>;
  _not?: InputMaybe<UserAggregates_Bool_Exp>;
  _or?: InputMaybe<Array<UserAggregates_Bool_Exp>>;
  allowedAwakeTime?: InputMaybe<Float8_Comparison_Exp>;
  awakeningPenalty?: InputMaybe<Float8_Comparison_Exp>;
  awakenings?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  date?: InputMaybe<String_Comparison_Exp>;
  deepSleepPenalty?: InputMaybe<Float8_Comparison_Exp>;
  highHRPenalty?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  medianHR?: InputMaybe<Int_Comparison_Exp>;
  minutesAwake?: InputMaybe<Int_Comparison_Exp>;
  minutesDeepSleep?: InputMaybe<Int_Comparison_Exp>;
  minutesLightSleep?: InputMaybe<Int_Comparison_Exp>;
  monthlyHR?: InputMaybe<Float8_Comparison_Exp>;
  netSleepScore?: InputMaybe<Float8_Comparison_Exp>;
  restlessPenalty?: InputMaybe<Float8_Comparison_Exp>;
  restlessnessMild?: InputMaybe<Int_Comparison_Exp>;
  restlessnessModerate?: InputMaybe<Int_Comparison_Exp>;
  restlessnessNone?: InputMaybe<Int_Comparison_Exp>;
  restlessnessSevere?: InputMaybe<Int_Comparison_Exp>;
  sleepDuration?: InputMaybe<Float8_Comparison_Exp>;
  sleepEfficiency?: InputMaybe<Float8_Comparison_Exp>;
  sleepGoal?: InputMaybe<Int_Comparison_Exp>;
  sleepGoalAchieved?: InputMaybe<Float8_Comparison_Exp>;
  sleepQuality?: InputMaybe<Float8_Comparison_Exp>;
  totalSleepPenalty?: InputMaybe<Float8_Comparison_Exp>;
  totalSleepTime?: InputMaybe<Int_Comparison_Exp>;
  totalTimeInBed?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserAggregates" */
export type UserAggregates_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserAggregates_pkey';

/** input type for incrementing numeric columns in table "UserAggregates" */
export type UserAggregates_Inc_Input = {
  allowedAwakeTime?: InputMaybe<Scalars['float8']['input']>;
  awakeningPenalty?: InputMaybe<Scalars['float8']['input']>;
  awakenings?: InputMaybe<Scalars['Int']['input']>;
  deepSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  highHRPenalty?: InputMaybe<Scalars['float8']['input']>;
  medianHR?: InputMaybe<Scalars['Int']['input']>;
  minutesAwake?: InputMaybe<Scalars['Int']['input']>;
  minutesDeepSleep?: InputMaybe<Scalars['Int']['input']>;
  minutesLightSleep?: InputMaybe<Scalars['Int']['input']>;
  monthlyHR?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  restlessPenalty?: InputMaybe<Scalars['float8']['input']>;
  restlessnessMild?: InputMaybe<Scalars['Int']['input']>;
  restlessnessModerate?: InputMaybe<Scalars['Int']['input']>;
  restlessnessNone?: InputMaybe<Scalars['Int']['input']>;
  restlessnessSevere?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEfficiency?: InputMaybe<Scalars['float8']['input']>;
  sleepGoal?: InputMaybe<Scalars['Int']['input']>;
  sleepGoalAchieved?: InputMaybe<Scalars['float8']['input']>;
  sleepQuality?: InputMaybe<Scalars['float8']['input']>;
  totalSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  totalSleepTime?: InputMaybe<Scalars['Int']['input']>;
  totalTimeInBed?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserAggregates" */
export type UserAggregates_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  allowedAwakeTime?: InputMaybe<Scalars['float8']['input']>;
  awakeningPenalty?: InputMaybe<Scalars['float8']['input']>;
  awakenings?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deepSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  highHRPenalty?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  medianHR?: InputMaybe<Scalars['Int']['input']>;
  minutesAwake?: InputMaybe<Scalars['Int']['input']>;
  minutesDeepSleep?: InputMaybe<Scalars['Int']['input']>;
  minutesLightSleep?: InputMaybe<Scalars['Int']['input']>;
  monthlyHR?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  restlessPenalty?: InputMaybe<Scalars['float8']['input']>;
  restlessnessMild?: InputMaybe<Scalars['Int']['input']>;
  restlessnessModerate?: InputMaybe<Scalars['Int']['input']>;
  restlessnessNone?: InputMaybe<Scalars['Int']['input']>;
  restlessnessSevere?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEfficiency?: InputMaybe<Scalars['float8']['input']>;
  sleepGoal?: InputMaybe<Scalars['Int']['input']>;
  sleepGoalAchieved?: InputMaybe<Scalars['float8']['input']>;
  sleepQuality?: InputMaybe<Scalars['float8']['input']>;
  totalSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  totalSleepTime?: InputMaybe<Scalars['Int']['input']>;
  totalTimeInBed?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserAggregates_Max_Fields = {
  __typename?: 'UserAggregates_max_fields';
  allowedAwakeTime?: Maybe<Scalars['float8']['output']>;
  awakeningPenalty?: Maybe<Scalars['float8']['output']>;
  awakenings?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deepSleepPenalty?: Maybe<Scalars['float8']['output']>;
  highHRPenalty?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  medianHR?: Maybe<Scalars['Int']['output']>;
  minutesAwake?: Maybe<Scalars['Int']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Int']['output']>;
  minutesLightSleep?: Maybe<Scalars['Int']['output']>;
  monthlyHR?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  restlessPenalty?: Maybe<Scalars['float8']['output']>;
  restlessnessMild?: Maybe<Scalars['Int']['output']>;
  restlessnessModerate?: Maybe<Scalars['Int']['output']>;
  restlessnessNone?: Maybe<Scalars['Int']['output']>;
  restlessnessSevere?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEfficiency?: Maybe<Scalars['float8']['output']>;
  sleepGoal?: Maybe<Scalars['Int']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['float8']['output']>;
  sleepQuality?: Maybe<Scalars['float8']['output']>;
  totalSleepPenalty?: Maybe<Scalars['float8']['output']>;
  totalSleepTime?: Maybe<Scalars['Int']['output']>;
  totalTimeInBed?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserAggregates" */
export type UserAggregates_Max_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserAggregates_Min_Fields = {
  __typename?: 'UserAggregates_min_fields';
  allowedAwakeTime?: Maybe<Scalars['float8']['output']>;
  awakeningPenalty?: Maybe<Scalars['float8']['output']>;
  awakenings?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deepSleepPenalty?: Maybe<Scalars['float8']['output']>;
  highHRPenalty?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  medianHR?: Maybe<Scalars['Int']['output']>;
  minutesAwake?: Maybe<Scalars['Int']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Int']['output']>;
  minutesLightSleep?: Maybe<Scalars['Int']['output']>;
  monthlyHR?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  restlessPenalty?: Maybe<Scalars['float8']['output']>;
  restlessnessMild?: Maybe<Scalars['Int']['output']>;
  restlessnessModerate?: Maybe<Scalars['Int']['output']>;
  restlessnessNone?: Maybe<Scalars['Int']['output']>;
  restlessnessSevere?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEfficiency?: Maybe<Scalars['float8']['output']>;
  sleepGoal?: Maybe<Scalars['Int']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['float8']['output']>;
  sleepQuality?: Maybe<Scalars['float8']['output']>;
  totalSleepPenalty?: Maybe<Scalars['float8']['output']>;
  totalSleepTime?: Maybe<Scalars['Int']['output']>;
  totalTimeInBed?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserAggregates" */
export type UserAggregates_Min_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserAggregates" */
export type UserAggregates_Mutation_Response = {
  __typename?: 'UserAggregates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAggregates>;
};

/** on_conflict condition type for table "UserAggregates" */
export type UserAggregates_On_Conflict = {
  constraint: UserAggregates_Constraint;
  update_columns?: Array<UserAggregates_Update_Column>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};

/** Ordering options when selecting data from "UserAggregates". */
export type UserAggregates_Order_By = {
  User?: InputMaybe<User_Order_By>;
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserAggregates */
export type UserAggregates_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserAggregates" */
export type UserAggregates_Select_Column =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'awakenings'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'date'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'id'
  /** column name */
  | 'medianHR'
  /** column name */
  | 'minutesAwake'
  /** column name */
  | 'minutesDeepSleep'
  /** column name */
  | 'minutesLightSleep'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'restlessnessMild'
  /** column name */
  | 'restlessnessModerate'
  /** column name */
  | 'restlessnessNone'
  /** column name */
  | 'restlessnessSevere'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoal'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty'
  /** column name */
  | 'totalSleepTime'
  /** column name */
  | 'totalTimeInBed'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** select "UserAggregates_aggregate_bool_exp_avg_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_corr_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_max_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_min_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_sum_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** select "UserAggregates_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserAggregates" */
export type UserAggregates_Select_Column_UserAggregates_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty';

/** input type for updating data in table "UserAggregates" */
export type UserAggregates_Set_Input = {
  allowedAwakeTime?: InputMaybe<Scalars['float8']['input']>;
  awakeningPenalty?: InputMaybe<Scalars['float8']['input']>;
  awakenings?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deepSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  highHRPenalty?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  medianHR?: InputMaybe<Scalars['Int']['input']>;
  minutesAwake?: InputMaybe<Scalars['Int']['input']>;
  minutesDeepSleep?: InputMaybe<Scalars['Int']['input']>;
  minutesLightSleep?: InputMaybe<Scalars['Int']['input']>;
  monthlyHR?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  restlessPenalty?: InputMaybe<Scalars['float8']['input']>;
  restlessnessMild?: InputMaybe<Scalars['Int']['input']>;
  restlessnessModerate?: InputMaybe<Scalars['Int']['input']>;
  restlessnessNone?: InputMaybe<Scalars['Int']['input']>;
  restlessnessSevere?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEfficiency?: InputMaybe<Scalars['float8']['input']>;
  sleepGoal?: InputMaybe<Scalars['Int']['input']>;
  sleepGoalAchieved?: InputMaybe<Scalars['float8']['input']>;
  sleepQuality?: InputMaybe<Scalars['float8']['input']>;
  totalSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  totalSleepTime?: InputMaybe<Scalars['Int']['input']>;
  totalTimeInBed?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserAggregates_Stddev_Fields = {
  __typename?: 'UserAggregates_stddev_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserAggregates" */
export type UserAggregates_Stddev_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserAggregates_Stddev_Pop_Fields = {
  __typename?: 'UserAggregates_stddev_pop_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserAggregates" */
export type UserAggregates_Stddev_Pop_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserAggregates_Stddev_Samp_Fields = {
  __typename?: 'UserAggregates_stddev_samp_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserAggregates" */
export type UserAggregates_Stddev_Samp_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserAggregates" */
export type UserAggregates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserAggregates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAggregates_Stream_Cursor_Value_Input = {
  allowedAwakeTime?: InputMaybe<Scalars['float8']['input']>;
  awakeningPenalty?: InputMaybe<Scalars['float8']['input']>;
  awakenings?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deepSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  highHRPenalty?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  medianHR?: InputMaybe<Scalars['Int']['input']>;
  minutesAwake?: InputMaybe<Scalars['Int']['input']>;
  minutesDeepSleep?: InputMaybe<Scalars['Int']['input']>;
  minutesLightSleep?: InputMaybe<Scalars['Int']['input']>;
  monthlyHR?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  restlessPenalty?: InputMaybe<Scalars['float8']['input']>;
  restlessnessMild?: InputMaybe<Scalars['Int']['input']>;
  restlessnessModerate?: InputMaybe<Scalars['Int']['input']>;
  restlessnessNone?: InputMaybe<Scalars['Int']['input']>;
  restlessnessSevere?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEfficiency?: InputMaybe<Scalars['float8']['input']>;
  sleepGoal?: InputMaybe<Scalars['Int']['input']>;
  sleepGoalAchieved?: InputMaybe<Scalars['float8']['input']>;
  sleepQuality?: InputMaybe<Scalars['float8']['input']>;
  totalSleepPenalty?: InputMaybe<Scalars['float8']['input']>;
  totalSleepTime?: InputMaybe<Scalars['Int']['input']>;
  totalTimeInBed?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserAggregates_Sum_Fields = {
  __typename?: 'UserAggregates_sum_fields';
  allowedAwakeTime?: Maybe<Scalars['float8']['output']>;
  awakeningPenalty?: Maybe<Scalars['float8']['output']>;
  awakenings?: Maybe<Scalars['Int']['output']>;
  deepSleepPenalty?: Maybe<Scalars['float8']['output']>;
  highHRPenalty?: Maybe<Scalars['float8']['output']>;
  medianHR?: Maybe<Scalars['Int']['output']>;
  minutesAwake?: Maybe<Scalars['Int']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Int']['output']>;
  minutesLightSleep?: Maybe<Scalars['Int']['output']>;
  monthlyHR?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  restlessPenalty?: Maybe<Scalars['float8']['output']>;
  restlessnessMild?: Maybe<Scalars['Int']['output']>;
  restlessnessModerate?: Maybe<Scalars['Int']['output']>;
  restlessnessNone?: Maybe<Scalars['Int']['output']>;
  restlessnessSevere?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEfficiency?: Maybe<Scalars['float8']['output']>;
  sleepGoal?: Maybe<Scalars['Int']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['float8']['output']>;
  sleepQuality?: Maybe<Scalars['float8']['output']>;
  totalSleepPenalty?: Maybe<Scalars['float8']['output']>;
  totalSleepTime?: Maybe<Scalars['Int']['output']>;
  totalTimeInBed?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserAggregates" */
export type UserAggregates_Sum_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** update columns of table "UserAggregates" */
export type UserAggregates_Update_Column =
  /** column name */
  | 'allowedAwakeTime'
  /** column name */
  | 'awakeningPenalty'
  /** column name */
  | 'awakenings'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'date'
  /** column name */
  | 'deepSleepPenalty'
  /** column name */
  | 'highHRPenalty'
  /** column name */
  | 'id'
  /** column name */
  | 'medianHR'
  /** column name */
  | 'minutesAwake'
  /** column name */
  | 'minutesDeepSleep'
  /** column name */
  | 'minutesLightSleep'
  /** column name */
  | 'monthlyHR'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'restlessPenalty'
  /** column name */
  | 'restlessnessMild'
  /** column name */
  | 'restlessnessModerate'
  /** column name */
  | 'restlessnessNone'
  /** column name */
  | 'restlessnessSevere'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEfficiency'
  /** column name */
  | 'sleepGoal'
  /** column name */
  | 'sleepGoalAchieved'
  /** column name */
  | 'sleepQuality'
  /** column name */
  | 'totalSleepPenalty'
  /** column name */
  | 'totalSleepTime'
  /** column name */
  | 'totalTimeInBed'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type UserAggregates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserAggregates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAggregates_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserAggregates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserAggregates_Var_Pop_Fields = {
  __typename?: 'UserAggregates_var_pop_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserAggregates" */
export type UserAggregates_Var_Pop_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserAggregates_Var_Samp_Fields = {
  __typename?: 'UserAggregates_var_samp_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserAggregates" */
export type UserAggregates_Var_Samp_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserAggregates_Variance_Fields = {
  __typename?: 'UserAggregates_variance_fields';
  allowedAwakeTime?: Maybe<Scalars['Float']['output']>;
  awakeningPenalty?: Maybe<Scalars['Float']['output']>;
  awakenings?: Maybe<Scalars['Float']['output']>;
  deepSleepPenalty?: Maybe<Scalars['Float']['output']>;
  highHRPenalty?: Maybe<Scalars['Float']['output']>;
  medianHR?: Maybe<Scalars['Float']['output']>;
  minutesAwake?: Maybe<Scalars['Float']['output']>;
  minutesDeepSleep?: Maybe<Scalars['Float']['output']>;
  minutesLightSleep?: Maybe<Scalars['Float']['output']>;
  monthlyHR?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  restlessPenalty?: Maybe<Scalars['Float']['output']>;
  restlessnessMild?: Maybe<Scalars['Float']['output']>;
  restlessnessModerate?: Maybe<Scalars['Float']['output']>;
  restlessnessNone?: Maybe<Scalars['Float']['output']>;
  restlessnessSevere?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  sleepGoal?: Maybe<Scalars['Float']['output']>;
  sleepGoalAchieved?: Maybe<Scalars['Float']['output']>;
  sleepQuality?: Maybe<Scalars['Float']['output']>;
  totalSleepPenalty?: Maybe<Scalars['Float']['output']>;
  totalSleepTime?: Maybe<Scalars['Float']['output']>;
  totalTimeInBed?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserAggregates" */
export type UserAggregates_Variance_Order_By = {
  allowedAwakeTime?: InputMaybe<Order_By>;
  awakeningPenalty?: InputMaybe<Order_By>;
  awakenings?: InputMaybe<Order_By>;
  deepSleepPenalty?: InputMaybe<Order_By>;
  highHRPenalty?: InputMaybe<Order_By>;
  medianHR?: InputMaybe<Order_By>;
  minutesAwake?: InputMaybe<Order_By>;
  minutesDeepSleep?: InputMaybe<Order_By>;
  minutesLightSleep?: InputMaybe<Order_By>;
  monthlyHR?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  restlessPenalty?: InputMaybe<Order_By>;
  restlessnessMild?: InputMaybe<Order_By>;
  restlessnessModerate?: InputMaybe<Order_By>;
  restlessnessNone?: InputMaybe<Order_By>;
  restlessnessSevere?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEfficiency?: InputMaybe<Order_By>;
  sleepGoal?: InputMaybe<Order_By>;
  sleepGoalAchieved?: InputMaybe<Order_By>;
  sleepQuality?: InputMaybe<Order_By>;
  totalSleepPenalty?: InputMaybe<Order_By>;
  totalSleepTime?: InputMaybe<Order_By>;
  totalTimeInBed?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserAssessment" */
export type UserAssessment = {
  __typename?: 'UserAssessment';
  /** An object relationship */
  User: User;
  /** An array relationship */
  UserSets: Array<UserSet>;
  /** An aggregate relationship */
  UserSets_aggregate: UserSet_Aggregate;
  assessment_level?: Maybe<Scalars['Int']['output']>;
  assessment_type: Scalars['AssessmentType']['output'];
  completed_epoch?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamp']['output'];
  end_hr?: Maybe<Scalars['float8']['output']>;
  fitness_score?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  max_hr?: Maybe<Scalars['float8']['output']>;
  max_hr_epoch?: Maybe<Scalars['Int']['output']>;
  recovery_level?: Maybe<Scalars['Int']['output']>;
  screen_data?: Maybe<Scalars['jsonb']['output']>;
  start_epoch: Scalars['Int']['output'];
  total_reps?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
  v02_max: Scalars['float8']['output'];
  v02_score?: Maybe<Scalars['float8']['output']>;
};


/** columns and relationships of "UserAssessment" */
export type UserAssessmentUserSetsArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "UserAssessment" */
export type UserAssessmentUserSets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "UserAssessment" */
export type UserAssessmentScreen_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "UserAssessment" */
export type UserAssessment_Aggregate = {
  __typename?: 'UserAssessment_aggregate';
  aggregate?: Maybe<UserAssessment_Aggregate_Fields>;
  nodes: Array<UserAssessment>;
};

export type UserAssessment_Aggregate_Bool_Exp = {
  avg?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserAssessment_Aggregate_Bool_Exp_Var_Samp>;
};

export type UserAssessment_Aggregate_Bool_Exp_Avg = {
  arguments: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Corr = {
  arguments: UserAssessment_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Corr_Arguments = {
  X: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type UserAssessment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserAssessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: UserAssessment_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type UserAssessment_Aggregate_Bool_Exp_Max = {
  arguments: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Min = {
  arguments: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Sum = {
  arguments: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserAssessment_Aggregate_Bool_Exp_Var_Samp = {
  arguments: UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserAssessment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "UserAssessment" */
export type UserAssessment_Aggregate_Fields = {
  __typename?: 'UserAssessment_aggregate_fields';
  avg?: Maybe<UserAssessment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserAssessment_Max_Fields>;
  min?: Maybe<UserAssessment_Min_Fields>;
  stddev?: Maybe<UserAssessment_Stddev_Fields>;
  stddev_pop?: Maybe<UserAssessment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserAssessment_Stddev_Samp_Fields>;
  sum?: Maybe<UserAssessment_Sum_Fields>;
  var_pop?: Maybe<UserAssessment_Var_Pop_Fields>;
  var_samp?: Maybe<UserAssessment_Var_Samp_Fields>;
  variance?: Maybe<UserAssessment_Variance_Fields>;
};


/** aggregate fields of "UserAssessment" */
export type UserAssessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserAssessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserAssessment" */
export type UserAssessment_Aggregate_Order_By = {
  avg?: InputMaybe<UserAssessment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserAssessment_Max_Order_By>;
  min?: InputMaybe<UserAssessment_Min_Order_By>;
  stddev?: InputMaybe<UserAssessment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserAssessment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserAssessment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserAssessment_Sum_Order_By>;
  var_pop?: InputMaybe<UserAssessment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserAssessment_Var_Samp_Order_By>;
  variance?: InputMaybe<UserAssessment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserAssessment_Append_Input = {
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "UserAssessment" */
export type UserAssessment_Arr_Rel_Insert_Input = {
  data: Array<UserAssessment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAssessment_On_Conflict>;
};

/** aggregate avg on columns */
export type UserAssessment_Avg_Fields = {
  __typename?: 'UserAssessment_avg_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserAssessment" */
export type UserAssessment_Avg_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserAssessment". All fields are combined with a logical 'AND'. */
export type UserAssessment_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserSets?: InputMaybe<UserSet_Bool_Exp>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<UserAssessment_Bool_Exp>>;
  _not?: InputMaybe<UserAssessment_Bool_Exp>;
  _or?: InputMaybe<Array<UserAssessment_Bool_Exp>>;
  assessment_level?: InputMaybe<Int_Comparison_Exp>;
  assessment_type?: InputMaybe<AssessmentType_Comparison_Exp>;
  completed_epoch?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  end_hr?: InputMaybe<Float8_Comparison_Exp>;
  fitness_score?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  max_hr?: InputMaybe<Float8_Comparison_Exp>;
  max_hr_epoch?: InputMaybe<Int_Comparison_Exp>;
  recovery_level?: InputMaybe<Int_Comparison_Exp>;
  screen_data?: InputMaybe<Jsonb_Comparison_Exp>;
  start_epoch?: InputMaybe<Int_Comparison_Exp>;
  total_reps?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  v02_max?: InputMaybe<Float8_Comparison_Exp>;
  v02_score?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserAssessment" */
export type UserAssessment_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserAssessment_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserAssessment_Delete_At_Path_Input = {
  screen_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserAssessment_Delete_Elem_Input = {
  screen_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserAssessment_Delete_Key_Input = {
  screen_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "UserAssessment" */
export type UserAssessment_Inc_Input = {
  assessment_level?: InputMaybe<Scalars['Int']['input']>;
  completed_epoch?: InputMaybe<Scalars['Int']['input']>;
  end_hr?: InputMaybe<Scalars['float8']['input']>;
  fitness_score?: InputMaybe<Scalars['Int']['input']>;
  max_hr?: InputMaybe<Scalars['float8']['input']>;
  max_hr_epoch?: InputMaybe<Scalars['Int']['input']>;
  recovery_level?: InputMaybe<Scalars['Int']['input']>;
  start_epoch?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  v02_max?: InputMaybe<Scalars['float8']['input']>;
  v02_score?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "UserAssessment" */
export type UserAssessment_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserSets?: InputMaybe<UserSet_Arr_Rel_Insert_Input>;
  assessment_level?: InputMaybe<Scalars['Int']['input']>;
  assessment_type?: InputMaybe<Scalars['AssessmentType']['input']>;
  completed_epoch?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  end_hr?: InputMaybe<Scalars['float8']['input']>;
  fitness_score?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_hr?: InputMaybe<Scalars['float8']['input']>;
  max_hr_epoch?: InputMaybe<Scalars['Int']['input']>;
  recovery_level?: InputMaybe<Scalars['Int']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
  start_epoch?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  v02_max?: InputMaybe<Scalars['float8']['input']>;
  v02_score?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type UserAssessment_Max_Fields = {
  __typename?: 'UserAssessment_max_fields';
  assessment_level?: Maybe<Scalars['Int']['output']>;
  assessment_type?: Maybe<Scalars['AssessmentType']['output']>;
  completed_epoch?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  end_hr?: Maybe<Scalars['float8']['output']>;
  fitness_score?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_hr?: Maybe<Scalars['float8']['output']>;
  max_hr_epoch?: Maybe<Scalars['Int']['output']>;
  recovery_level?: Maybe<Scalars['Int']['output']>;
  start_epoch?: Maybe<Scalars['Int']['output']>;
  total_reps?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  v02_max?: Maybe<Scalars['float8']['output']>;
  v02_score?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "UserAssessment" */
export type UserAssessment_Max_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  assessment_type?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserAssessment_Min_Fields = {
  __typename?: 'UserAssessment_min_fields';
  assessment_level?: Maybe<Scalars['Int']['output']>;
  assessment_type?: Maybe<Scalars['AssessmentType']['output']>;
  completed_epoch?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  end_hr?: Maybe<Scalars['float8']['output']>;
  fitness_score?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_hr?: Maybe<Scalars['float8']['output']>;
  max_hr_epoch?: Maybe<Scalars['Int']['output']>;
  recovery_level?: Maybe<Scalars['Int']['output']>;
  start_epoch?: Maybe<Scalars['Int']['output']>;
  total_reps?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  v02_max?: Maybe<Scalars['float8']['output']>;
  v02_score?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "UserAssessment" */
export type UserAssessment_Min_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  assessment_type?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserAssessment" */
export type UserAssessment_Mutation_Response = {
  __typename?: 'UserAssessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAssessment>;
};

/** input type for inserting object relation for remote table "UserAssessment" */
export type UserAssessment_Obj_Rel_Insert_Input = {
  data: UserAssessment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAssessment_On_Conflict>;
};

/** on_conflict condition type for table "UserAssessment" */
export type UserAssessment_On_Conflict = {
  constraint: UserAssessment_Constraint;
  update_columns?: Array<UserAssessment_Update_Column>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};

/** Ordering options when selecting data from "UserAssessment". */
export type UserAssessment_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Order_By>;
  assessment_level?: InputMaybe<Order_By>;
  assessment_type?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  screen_data?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserAssessment */
export type UserAssessment_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserAssessment_Prepend_Input = {
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "UserAssessment" */
export type UserAssessment_Select_Column =
  /** column name */
  | 'assessment_level'
  /** column name */
  | 'assessment_type'
  /** column name */
  | 'completed_epoch'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_hr'
  /** column name */
  | 'fitness_score'
  /** column name */
  | 'id'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'max_hr_epoch'
  /** column name */
  | 'recovery_level'
  /** column name */
  | 'screen_data'
  /** column name */
  | 'start_epoch'
  /** column name */
  | 'total_reps'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_avg_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_corr_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_max_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_min_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_sum_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** select "UserAssessment_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserAssessment" */
export type UserAssessment_Select_Column_UserAssessment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'end_hr'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

/** input type for updating data in table "UserAssessment" */
export type UserAssessment_Set_Input = {
  assessment_level?: InputMaybe<Scalars['Int']['input']>;
  assessment_type?: InputMaybe<Scalars['AssessmentType']['input']>;
  completed_epoch?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  end_hr?: InputMaybe<Scalars['float8']['input']>;
  fitness_score?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_hr?: InputMaybe<Scalars['float8']['input']>;
  max_hr_epoch?: InputMaybe<Scalars['Int']['input']>;
  recovery_level?: InputMaybe<Scalars['Int']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
  start_epoch?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  v02_max?: InputMaybe<Scalars['float8']['input']>;
  v02_score?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type UserAssessment_Stddev_Fields = {
  __typename?: 'UserAssessment_stddev_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserAssessment" */
export type UserAssessment_Stddev_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserAssessment_Stddev_Pop_Fields = {
  __typename?: 'UserAssessment_stddev_pop_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserAssessment" */
export type UserAssessment_Stddev_Pop_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserAssessment_Stddev_Samp_Fields = {
  __typename?: 'UserAssessment_stddev_samp_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserAssessment" */
export type UserAssessment_Stddev_Samp_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserAssessment" */
export type UserAssessment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserAssessment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAssessment_Stream_Cursor_Value_Input = {
  assessment_level?: InputMaybe<Scalars['Int']['input']>;
  assessment_type?: InputMaybe<Scalars['AssessmentType']['input']>;
  completed_epoch?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  end_hr?: InputMaybe<Scalars['float8']['input']>;
  fitness_score?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_hr?: InputMaybe<Scalars['float8']['input']>;
  max_hr_epoch?: InputMaybe<Scalars['Int']['input']>;
  recovery_level?: InputMaybe<Scalars['Int']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
  start_epoch?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  v02_max?: InputMaybe<Scalars['float8']['input']>;
  v02_score?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type UserAssessment_Sum_Fields = {
  __typename?: 'UserAssessment_sum_fields';
  assessment_level?: Maybe<Scalars['Int']['output']>;
  completed_epoch?: Maybe<Scalars['Int']['output']>;
  end_hr?: Maybe<Scalars['float8']['output']>;
  fitness_score?: Maybe<Scalars['Int']['output']>;
  max_hr?: Maybe<Scalars['float8']['output']>;
  max_hr_epoch?: Maybe<Scalars['Int']['output']>;
  recovery_level?: Maybe<Scalars['Int']['output']>;
  start_epoch?: Maybe<Scalars['Int']['output']>;
  total_reps?: Maybe<Scalars['Int']['output']>;
  v02_max?: Maybe<Scalars['float8']['output']>;
  v02_score?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "UserAssessment" */
export type UserAssessment_Sum_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** update columns of table "UserAssessment" */
export type UserAssessment_Update_Column =
  /** column name */
  | 'assessment_level'
  /** column name */
  | 'assessment_type'
  /** column name */
  | 'completed_epoch'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_hr'
  /** column name */
  | 'fitness_score'
  /** column name */
  | 'id'
  /** column name */
  | 'max_hr'
  /** column name */
  | 'max_hr_epoch'
  /** column name */
  | 'recovery_level'
  /** column name */
  | 'screen_data'
  /** column name */
  | 'start_epoch'
  /** column name */
  | 'total_reps'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'v02_max'
  /** column name */
  | 'v02_score';

export type UserAssessment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserAssessment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserAssessment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserAssessment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserAssessment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserAssessment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserAssessment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAssessment_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserAssessment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserAssessment_Var_Pop_Fields = {
  __typename?: 'UserAssessment_var_pop_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserAssessment" */
export type UserAssessment_Var_Pop_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserAssessment_Var_Samp_Fields = {
  __typename?: 'UserAssessment_var_samp_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserAssessment" */
export type UserAssessment_Var_Samp_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserAssessment_Variance_Fields = {
  __typename?: 'UserAssessment_variance_fields';
  assessment_level?: Maybe<Scalars['Float']['output']>;
  completed_epoch?: Maybe<Scalars['Float']['output']>;
  end_hr?: Maybe<Scalars['Float']['output']>;
  fitness_score?: Maybe<Scalars['Float']['output']>;
  max_hr?: Maybe<Scalars['Float']['output']>;
  max_hr_epoch?: Maybe<Scalars['Float']['output']>;
  recovery_level?: Maybe<Scalars['Float']['output']>;
  start_epoch?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
  v02_max?: Maybe<Scalars['Float']['output']>;
  v02_score?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserAssessment" */
export type UserAssessment_Variance_Order_By = {
  assessment_level?: InputMaybe<Order_By>;
  completed_epoch?: InputMaybe<Order_By>;
  end_hr?: InputMaybe<Order_By>;
  fitness_score?: InputMaybe<Order_By>;
  max_hr?: InputMaybe<Order_By>;
  max_hr_epoch?: InputMaybe<Order_By>;
  recovery_level?: InputMaybe<Order_By>;
  start_epoch?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  v02_max?: InputMaybe<Order_By>;
  v02_score?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserFeatureControl" */
export type UserFeatureControl = {
  __typename?: 'UserFeatureControl';
  /** An object relationship */
  User: User;
  created_at: Scalars['timestamp']['output'];
  enabled: Scalars['Boolean']['output'];
  feature_title: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "UserFeatureControl" */
export type UserFeatureControl_Aggregate = {
  __typename?: 'UserFeatureControl_aggregate';
  aggregate?: Maybe<UserFeatureControl_Aggregate_Fields>;
  nodes: Array<UserFeatureControl>;
};

export type UserFeatureControl_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<UserFeatureControl_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserFeatureControl_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<UserFeatureControl_Aggregate_Bool_Exp_Count>;
};

export type UserFeatureControl_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserFeatureControl_Select_Column_UserFeatureControl_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFeatureControl_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserFeatureControl_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserFeatureControl_Select_Column_UserFeatureControl_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFeatureControl_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserFeatureControl_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFeatureControl_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserFeatureControl" */
export type UserFeatureControl_Aggregate_Fields = {
  __typename?: 'UserFeatureControl_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserFeatureControl_Max_Fields>;
  min?: Maybe<UserFeatureControl_Min_Fields>;
};


/** aggregate fields of "UserFeatureControl" */
export type UserFeatureControl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserFeatureControl" */
export type UserFeatureControl_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserFeatureControl_Max_Order_By>;
  min?: InputMaybe<UserFeatureControl_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserFeatureControl" */
export type UserFeatureControl_Arr_Rel_Insert_Input = {
  data: Array<UserFeatureControl_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserFeatureControl_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserFeatureControl". All fields are combined with a logical 'AND'. */
export type UserFeatureControl_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserFeatureControl_Bool_Exp>>;
  _not?: InputMaybe<UserFeatureControl_Bool_Exp>;
  _or?: InputMaybe<Array<UserFeatureControl_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  feature_title?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserFeatureControl" */
export type UserFeatureControl_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserFeatureControl_pkey'
  /** unique or primary key constraint on columns "user_id", "feature_title" */
  | 'UserFeatureControl_user_id_feature_title_key';

/** input type for inserting data into table "UserFeatureControl" */
export type UserFeatureControl_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserFeatureControl_Max_Fields = {
  __typename?: 'UserFeatureControl_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  feature_title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserFeatureControl" */
export type UserFeatureControl_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_title?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserFeatureControl_Min_Fields = {
  __typename?: 'UserFeatureControl_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  feature_title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserFeatureControl" */
export type UserFeatureControl_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_title?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserFeatureControl" */
export type UserFeatureControl_Mutation_Response = {
  __typename?: 'UserFeatureControl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserFeatureControl>;
};

/** on_conflict condition type for table "UserFeatureControl" */
export type UserFeatureControl_On_Conflict = {
  constraint: UserFeatureControl_Constraint;
  update_columns?: Array<UserFeatureControl_Update_Column>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};

/** Ordering options when selecting data from "UserFeatureControl". */
export type UserFeatureControl_Order_By = {
  User?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  feature_title?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserFeatureControl */
export type UserFeatureControl_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserFeatureControl" */
export type UserFeatureControl_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'enabled'
  /** column name */
  | 'feature_title'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** select "UserFeatureControl_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserFeatureControl" */
export type UserFeatureControl_Select_Column_UserFeatureControl_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'enabled';

/** select "UserFeatureControl_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserFeatureControl" */
export type UserFeatureControl_Select_Column_UserFeatureControl_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'enabled';

/** input type for updating data in table "UserFeatureControl" */
export type UserFeatureControl_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserFeatureControl" */
export type UserFeatureControl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserFeatureControl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserFeatureControl_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feature_title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserFeatureControl" */
export type UserFeatureControl_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'enabled'
  /** column name */
  | 'feature_title'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type UserFeatureControl_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserFeatureControl_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserFeatureControl_Bool_Exp;
};

/** columns and relationships of "UserFeedback" */
export type UserFeedback = {
  __typename?: 'UserFeedback';
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserWorkout?: Maybe<UserWorkout>;
  created_at: Scalars['timestamp']['output'];
  external_id?: Maybe<Scalars['String']['output']>;
  feedback_type: Scalars['FeedbackType']['output'];
  id: Scalars['String']['output'];
  max_rating?: Maybe<Scalars['Int']['output']>;
  min_rating?: Maybe<Scalars['Int']['output']>;
  multi_choice_text?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Int']['output'];
  raw_user_text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
  user_workout_id?: Maybe<Scalars['String']['output']>;
};

export type UserFeedbackInput = {
  external_id?: InputMaybe<Scalars['String']['input']>;
  feedback_type: FeedbackTypeEnum;
  max_rating?: InputMaybe<Scalars['Int']['input']>;
  min_rating?: InputMaybe<Scalars['Int']['input']>;
  multi_choice_text?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  raw_user_text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "UserFeedback" */
export type UserFeedback_Aggregate = {
  __typename?: 'UserFeedback_aggregate';
  aggregate?: Maybe<UserFeedback_Aggregate_Fields>;
  nodes: Array<UserFeedback>;
};

export type UserFeedback_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserFeedback_Aggregate_Bool_Exp_Count>;
};

export type UserFeedback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserFeedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFeedback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserFeedback" */
export type UserFeedback_Aggregate_Fields = {
  __typename?: 'UserFeedback_aggregate_fields';
  avg?: Maybe<UserFeedback_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserFeedback_Max_Fields>;
  min?: Maybe<UserFeedback_Min_Fields>;
  stddev?: Maybe<UserFeedback_Stddev_Fields>;
  stddev_pop?: Maybe<UserFeedback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserFeedback_Stddev_Samp_Fields>;
  sum?: Maybe<UserFeedback_Sum_Fields>;
  var_pop?: Maybe<UserFeedback_Var_Pop_Fields>;
  var_samp?: Maybe<UserFeedback_Var_Samp_Fields>;
  variance?: Maybe<UserFeedback_Variance_Fields>;
};


/** aggregate fields of "UserFeedback" */
export type UserFeedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserFeedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserFeedback" */
export type UserFeedback_Aggregate_Order_By = {
  avg?: InputMaybe<UserFeedback_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserFeedback_Max_Order_By>;
  min?: InputMaybe<UserFeedback_Min_Order_By>;
  stddev?: InputMaybe<UserFeedback_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserFeedback_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserFeedback_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserFeedback_Sum_Order_By>;
  var_pop?: InputMaybe<UserFeedback_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserFeedback_Var_Samp_Order_By>;
  variance?: InputMaybe<UserFeedback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserFeedback" */
export type UserFeedback_Arr_Rel_Insert_Input = {
  data: Array<UserFeedback_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserFeedback_On_Conflict>;
};

/** aggregate avg on columns */
export type UserFeedback_Avg_Fields = {
  __typename?: 'UserFeedback_avg_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserFeedback" */
export type UserFeedback_Avg_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserFeedback". All fields are combined with a logical 'AND'. */
export type UserFeedback_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserWorkout?: InputMaybe<UserWorkout_Bool_Exp>;
  _and?: InputMaybe<Array<UserFeedback_Bool_Exp>>;
  _not?: InputMaybe<UserFeedback_Bool_Exp>;
  _or?: InputMaybe<Array<UserFeedback_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  feedback_type?: InputMaybe<FeedbackType_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  max_rating?: InputMaybe<Int_Comparison_Exp>;
  min_rating?: InputMaybe<Int_Comparison_Exp>;
  multi_choice_text?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  raw_user_text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_workout_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserFeedback" */
export type UserFeedback_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserFeedback_pkey';

/** input type for incrementing numeric columns in table "UserFeedback" */
export type UserFeedback_Inc_Input = {
  max_rating?: InputMaybe<Scalars['Int']['input']>;
  min_rating?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserFeedback" */
export type UserFeedback_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserWorkout?: InputMaybe<UserWorkout_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  feedback_type?: InputMaybe<Scalars['FeedbackType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_rating?: InputMaybe<Scalars['Int']['input']>;
  min_rating?: InputMaybe<Scalars['Int']['input']>;
  multi_choice_text?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  raw_user_text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserFeedback_Max_Fields = {
  __typename?: 'UserFeedback_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  feedback_type?: Maybe<Scalars['FeedbackType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_rating?: Maybe<Scalars['Int']['output']>;
  min_rating?: Maybe<Scalars['Int']['output']>;
  multi_choice_text?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  raw_user_text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserFeedback" */
export type UserFeedback_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  feedback_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  multi_choice_text?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  raw_user_text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserFeedback_Min_Fields = {
  __typename?: 'UserFeedback_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  feedback_type?: Maybe<Scalars['FeedbackType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_rating?: Maybe<Scalars['Int']['output']>;
  min_rating?: Maybe<Scalars['Int']['output']>;
  multi_choice_text?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  raw_user_text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_workout_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserFeedback" */
export type UserFeedback_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  feedback_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  multi_choice_text?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  raw_user_text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserFeedback" */
export type UserFeedback_Mutation_Response = {
  __typename?: 'UserFeedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserFeedback>;
};

/** on_conflict condition type for table "UserFeedback" */
export type UserFeedback_On_Conflict = {
  constraint: UserFeedback_Constraint;
  update_columns?: Array<UserFeedback_Update_Column>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};

/** Ordering options when selecting data from "UserFeedback". */
export type UserFeedback_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserWorkout?: InputMaybe<UserWorkout_Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  feedback_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  multi_choice_text?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  raw_user_text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_workout_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserFeedback */
export type UserFeedback_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserFeedback" */
export type UserFeedback_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'external_id'
  /** column name */
  | 'feedback_type'
  /** column name */
  | 'id'
  /** column name */
  | 'max_rating'
  /** column name */
  | 'min_rating'
  /** column name */
  | 'multi_choice_text'
  /** column name */
  | 'rating'
  /** column name */
  | 'raw_user_text'
  /** column name */
  | 'title'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id';

/** input type for updating data in table "UserFeedback" */
export type UserFeedback_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  feedback_type?: InputMaybe<Scalars['FeedbackType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_rating?: InputMaybe<Scalars['Int']['input']>;
  min_rating?: InputMaybe<Scalars['Int']['input']>;
  multi_choice_text?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  raw_user_text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserFeedback_Stddev_Fields = {
  __typename?: 'UserFeedback_stddev_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserFeedback" */
export type UserFeedback_Stddev_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserFeedback_Stddev_Pop_Fields = {
  __typename?: 'UserFeedback_stddev_pop_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserFeedback" */
export type UserFeedback_Stddev_Pop_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserFeedback_Stddev_Samp_Fields = {
  __typename?: 'UserFeedback_stddev_samp_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserFeedback" */
export type UserFeedback_Stddev_Samp_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserFeedback" */
export type UserFeedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserFeedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserFeedback_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  feedback_type?: InputMaybe<Scalars['FeedbackType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_rating?: InputMaybe<Scalars['Int']['input']>;
  min_rating?: InputMaybe<Scalars['Int']['input']>;
  multi_choice_text?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  raw_user_text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserFeedback_Sum_Fields = {
  __typename?: 'UserFeedback_sum_fields';
  max_rating?: Maybe<Scalars['Int']['output']>;
  min_rating?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserFeedback" */
export type UserFeedback_Sum_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "UserFeedback" */
export type UserFeedback_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'external_id'
  /** column name */
  | 'feedback_type'
  /** column name */
  | 'id'
  /** column name */
  | 'max_rating'
  /** column name */
  | 'min_rating'
  /** column name */
  | 'multi_choice_text'
  /** column name */
  | 'rating'
  /** column name */
  | 'raw_user_text'
  /** column name */
  | 'title'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_workout_id';

export type UserFeedback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserFeedback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserFeedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserFeedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserFeedback_Var_Pop_Fields = {
  __typename?: 'UserFeedback_var_pop_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserFeedback" */
export type UserFeedback_Var_Pop_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserFeedback_Var_Samp_Fields = {
  __typename?: 'UserFeedback_var_samp_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserFeedback" */
export type UserFeedback_Var_Samp_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserFeedback_Variance_Fields = {
  __typename?: 'UserFeedback_variance_fields';
  max_rating?: Maybe<Scalars['Float']['output']>;
  min_rating?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserFeedback" */
export type UserFeedback_Variance_Order_By = {
  max_rating?: InputMaybe<Order_By>;
  min_rating?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserFinancePlan" */
export type UserFinancePlan = {
  __typename?: 'UserFinancePlan';
  /** An object relationship */
  FinancePlan: FinancePlan;
  /** An array relationship */
  OrderLineItems: Array<OrderLineItem>;
  /** An aggregate relationship */
  OrderLineItems_aggregate: OrderLineItem_Aggregate;
  /** An object relationship */
  User: User;
  created_at: Scalars['timestamp']['output'];
  finance_plan_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['FinanceStatus']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
  vendor_item_id: Scalars['String']['output'];
};


/** columns and relationships of "UserFinancePlan" */
export type UserFinancePlanOrderLineItemsArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


/** columns and relationships of "UserFinancePlan" */
export type UserFinancePlanOrderLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};

/** aggregated selection of "UserFinancePlan" */
export type UserFinancePlan_Aggregate = {
  __typename?: 'UserFinancePlan_aggregate';
  aggregate?: Maybe<UserFinancePlan_Aggregate_Fields>;
  nodes: Array<UserFinancePlan>;
};

export type UserFinancePlan_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserFinancePlan_Aggregate_Bool_Exp_Count>;
};

export type UserFinancePlan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFinancePlan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserFinancePlan" */
export type UserFinancePlan_Aggregate_Fields = {
  __typename?: 'UserFinancePlan_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserFinancePlan_Max_Fields>;
  min?: Maybe<UserFinancePlan_Min_Fields>;
};


/** aggregate fields of "UserFinancePlan" */
export type UserFinancePlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserFinancePlan" */
export type UserFinancePlan_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserFinancePlan_Max_Order_By>;
  min?: InputMaybe<UserFinancePlan_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserFinancePlan" */
export type UserFinancePlan_Arr_Rel_Insert_Input = {
  data: Array<UserFinancePlan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserFinancePlan_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserFinancePlan". All fields are combined with a logical 'AND'. */
export type UserFinancePlan_Bool_Exp = {
  FinancePlan?: InputMaybe<FinancePlan_Bool_Exp>;
  OrderLineItems?: InputMaybe<OrderLineItem_Bool_Exp>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserFinancePlan_Bool_Exp>>;
  _not?: InputMaybe<UserFinancePlan_Bool_Exp>;
  _or?: InputMaybe<Array<UserFinancePlan_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  finance_plan_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<FinanceStatus_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  vendor_item_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserFinancePlan" */
export type UserFinancePlan_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserFinancePlan_pkey';

/** input type for inserting data into table "UserFinancePlan" */
export type UserFinancePlan_Insert_Input = {
  FinancePlan?: InputMaybe<FinancePlan_Obj_Rel_Insert_Input>;
  OrderLineItems?: InputMaybe<OrderLineItem_Arr_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  finance_plan_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['FinanceStatus']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor_item_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserFinancePlan_Max_Fields = {
  __typename?: 'UserFinancePlan_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  finance_plan_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['FinanceStatus']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  vendor_item_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserFinancePlan" */
export type UserFinancePlan_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  finance_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_item_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserFinancePlan_Min_Fields = {
  __typename?: 'UserFinancePlan_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  finance_plan_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['FinanceStatus']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  vendor_item_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserFinancePlan" */
export type UserFinancePlan_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  finance_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_item_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserFinancePlan" */
export type UserFinancePlan_Mutation_Response = {
  __typename?: 'UserFinancePlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserFinancePlan>;
};

/** input type for inserting object relation for remote table "UserFinancePlan" */
export type UserFinancePlan_Obj_Rel_Insert_Input = {
  data: UserFinancePlan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserFinancePlan_On_Conflict>;
};

/** on_conflict condition type for table "UserFinancePlan" */
export type UserFinancePlan_On_Conflict = {
  constraint: UserFinancePlan_Constraint;
  update_columns?: Array<UserFinancePlan_Update_Column>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};

/** Ordering options when selecting data from "UserFinancePlan". */
export type UserFinancePlan_Order_By = {
  FinancePlan?: InputMaybe<FinancePlan_Order_By>;
  OrderLineItems_aggregate?: InputMaybe<OrderLineItem_Aggregate_Order_By>;
  User?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  finance_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserFinancePlan */
export type UserFinancePlan_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserFinancePlan" */
export type UserFinancePlan_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'finance_plan_id'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'vendor_item_id';

/** input type for updating data in table "UserFinancePlan" */
export type UserFinancePlan_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  finance_plan_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['FinanceStatus']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor_item_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserFinancePlan" */
export type UserFinancePlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserFinancePlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserFinancePlan_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  finance_plan_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['FinanceStatus']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor_item_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserFinancePlan" */
export type UserFinancePlan_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'finance_plan_id'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'vendor_item_id';

export type UserFinancePlan_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserFinancePlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserFinancePlan_Bool_Exp;
};

/** columns and relationships of "UserHabit" */
export type UserHabit = {
  __typename?: 'UserHabit';
  active: Scalars['Boolean']['output'];
  coached_habit_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type: Scalars['HabitType']['output'];
  id: Scalars['String']['output'];
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type: Scalars['HabitSubType']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "UserHabit" */
export type UserHabit_Aggregate = {
  __typename?: 'UserHabit_aggregate';
  aggregate?: Maybe<UserHabit_Aggregate_Fields>;
  nodes: Array<UserHabit>;
};

/** aggregate fields of "UserHabit" */
export type UserHabit_Aggregate_Fields = {
  __typename?: 'UserHabit_aggregate_fields';
  avg?: Maybe<UserHabit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserHabit_Max_Fields>;
  min?: Maybe<UserHabit_Min_Fields>;
  stddev?: Maybe<UserHabit_Stddev_Fields>;
  stddev_pop?: Maybe<UserHabit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserHabit_Stddev_Samp_Fields>;
  sum?: Maybe<UserHabit_Sum_Fields>;
  var_pop?: Maybe<UserHabit_Var_Pop_Fields>;
  var_samp?: Maybe<UserHabit_Var_Samp_Fields>;
  variance?: Maybe<UserHabit_Variance_Fields>;
};


/** aggregate fields of "UserHabit" */
export type UserHabit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserHabit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type UserHabit_Avg_Fields = {
  __typename?: 'UserHabit_avg_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "UserHabit". All fields are combined with a logical 'AND'. */
export type UserHabit_Bool_Exp = {
  _and?: InputMaybe<Array<UserHabit_Bool_Exp>>;
  _not?: InputMaybe<UserHabit_Bool_Exp>;
  _or?: InputMaybe<Array<UserHabit_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  coached_habit_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  custom_congrats?: InputMaybe<String_Comparison_Exp>;
  custom_try_again?: InputMaybe<String_Comparison_Exp>;
  days_required?: InputMaybe<Int_Comparison_Exp>;
  goal_value?: InputMaybe<Int_Comparison_Exp>;
  habit_type?: InputMaybe<HabitType_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  long_goal?: InputMaybe<String_Comparison_Exp>;
  max_checkin_time?: InputMaybe<Int_Comparison_Exp>;
  medium_goal?: InputMaybe<String_Comparison_Exp>;
  min_checkin_time?: InputMaybe<Int_Comparison_Exp>;
  short_goal?: InputMaybe<String_Comparison_Exp>;
  sub_type?: InputMaybe<HabitSubType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserHabit" */
export type UserHabit_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserHabit_pkey';

/** input type for incrementing numeric columns in table "UserHabit" */
export type UserHabit_Inc_Input = {
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserHabit" */
export type UserHabit_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  coached_habit_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserHabit_Max_Fields = {
  __typename?: 'UserHabit_max_fields';
  coached_habit_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<Scalars['HabitType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<Scalars['HabitSubType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserHabit_Min_Fields = {
  __typename?: 'UserHabit_min_fields';
  coached_habit_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  custom_congrats?: Maybe<Scalars['String']['output']>;
  custom_try_again?: Maybe<Scalars['String']['output']>;
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  habit_type?: Maybe<Scalars['HabitType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  long_goal?: Maybe<Scalars['String']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  medium_goal?: Maybe<Scalars['String']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
  short_goal?: Maybe<Scalars['String']['output']>;
  sub_type?: Maybe<Scalars['HabitSubType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "UserHabit" */
export type UserHabit_Mutation_Response = {
  __typename?: 'UserHabit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserHabit>;
};

/** on_conflict condition type for table "UserHabit" */
export type UserHabit_On_Conflict = {
  constraint: UserHabit_Constraint;
  update_columns?: Array<UserHabit_Update_Column>;
  where?: InputMaybe<UserHabit_Bool_Exp>;
};

/** Ordering options when selecting data from "UserHabit". */
export type UserHabit_Order_By = {
  active?: InputMaybe<Order_By>;
  coached_habit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_congrats?: InputMaybe<Order_By>;
  custom_try_again?: InputMaybe<Order_By>;
  days_required?: InputMaybe<Order_By>;
  goal_value?: InputMaybe<Order_By>;
  habit_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  long_goal?: InputMaybe<Order_By>;
  max_checkin_time?: InputMaybe<Order_By>;
  medium_goal?: InputMaybe<Order_By>;
  min_checkin_time?: InputMaybe<Order_By>;
  short_goal?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserHabit */
export type UserHabit_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserHabit" */
export type UserHabit_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'coached_habit_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_congrats'
  /** column name */
  | 'custom_try_again'
  /** column name */
  | 'days_required'
  /** column name */
  | 'goal_value'
  /** column name */
  | 'habit_type'
  /** column name */
  | 'id'
  /** column name */
  | 'long_goal'
  /** column name */
  | 'max_checkin_time'
  /** column name */
  | 'medium_goal'
  /** column name */
  | 'min_checkin_time'
  /** column name */
  | 'short_goal'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "UserHabit" */
export type UserHabit_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  coached_habit_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserHabit_Stddev_Fields = {
  __typename?: 'UserHabit_stddev_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type UserHabit_Stddev_Pop_Fields = {
  __typename?: 'UserHabit_stddev_pop_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type UserHabit_Stddev_Samp_Fields = {
  __typename?: 'UserHabit_stddev_samp_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "UserHabit" */
export type UserHabit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserHabit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserHabit_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  coached_habit_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type?: InputMaybe<Scalars['HabitType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type?: InputMaybe<Scalars['HabitSubType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserHabit_Sum_Fields = {
  __typename?: 'UserHabit_sum_fields';
  days_required?: Maybe<Scalars['Int']['output']>;
  goal_value?: Maybe<Scalars['Int']['output']>;
  max_checkin_time?: Maybe<Scalars['Int']['output']>;
  min_checkin_time?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "UserHabit" */
export type UserHabit_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'coached_habit_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'custom_congrats'
  /** column name */
  | 'custom_try_again'
  /** column name */
  | 'days_required'
  /** column name */
  | 'goal_value'
  /** column name */
  | 'habit_type'
  /** column name */
  | 'id'
  /** column name */
  | 'long_goal'
  /** column name */
  | 'max_checkin_time'
  /** column name */
  | 'medium_goal'
  /** column name */
  | 'min_checkin_time'
  /** column name */
  | 'short_goal'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type UserHabit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserHabit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserHabit_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserHabit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserHabit_Var_Pop_Fields = {
  __typename?: 'UserHabit_var_pop_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type UserHabit_Var_Samp_Fields = {
  __typename?: 'UserHabit_var_samp_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type UserHabit_Variance_Fields = {
  __typename?: 'UserHabit_variance_fields';
  days_required?: Maybe<Scalars['Float']['output']>;
  goal_value?: Maybe<Scalars['Float']['output']>;
  max_checkin_time?: Maybe<Scalars['Float']['output']>;
  min_checkin_time?: Maybe<Scalars['Float']['output']>;
};

export type UserMonthsActiveOutput = {
  __typename?: 'UserMonthsActiveOutput';
  monthsActive?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** columns and relationships of "UserMovementDaily" */
export type UserMovementDaily = {
  __typename?: 'UserMovementDaily';
  /** An object relationship */
  User: User;
  activeCalories?: Maybe<Scalars['float8']['output']>;
  activeMins?: Maybe<Scalars['Int']['output']>;
  bmr_calories?: Maybe<Scalars['float8']['output']>;
  caloric_data?: Maybe<Array<Scalars['jsonb']['output']>>;
  created_at: Scalars['timestamp']['output'];
  date: Scalars['date']['output'];
  distance?: Maybe<Scalars['float8']['output']>;
  duration_high_activity?: Maybe<Scalars['float8']['output']>;
  duration_inactive_activity?: Maybe<Scalars['float8']['output']>;
  duration_low_activity?: Maybe<Scalars['float8']['output']>;
  duration_medium_activity?: Maybe<Scalars['float8']['output']>;
  duration_workin?: Maybe<Scalars['float8']['output']>;
  duration_workout?: Maybe<Scalars['float8']['output']>;
  id: Scalars['String']['output'];
  netCalories?: Maybe<Scalars['float8']['output']>;
  sedentaryHours?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['time']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  workouts_accounted?: Maybe<Scalars['Int']['output']>;
  zone2Mins?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "UserMovementDaily" */
export type UserMovementDaily_Aggregate = {
  __typename?: 'UserMovementDaily_aggregate';
  aggregate?: Maybe<UserMovementDaily_Aggregate_Fields>;
  nodes: Array<UserMovementDaily>;
};

export type UserMovementDaily_Aggregate_Bool_Exp = {
  avg?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp_Var_Samp>;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Avg = {
  arguments: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Corr = {
  arguments: UserMovementDaily_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Corr_Arguments = {
  X: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Max = {
  arguments: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Min = {
  arguments: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Sum = {
  arguments: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserMovementDaily_Aggregate_Bool_Exp_Var_Samp = {
  arguments: UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMovementDaily_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "UserMovementDaily" */
export type UserMovementDaily_Aggregate_Fields = {
  __typename?: 'UserMovementDaily_aggregate_fields';
  avg?: Maybe<UserMovementDaily_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserMovementDaily_Max_Fields>;
  min?: Maybe<UserMovementDaily_Min_Fields>;
  stddev?: Maybe<UserMovementDaily_Stddev_Fields>;
  stddev_pop?: Maybe<UserMovementDaily_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserMovementDaily_Stddev_Samp_Fields>;
  sum?: Maybe<UserMovementDaily_Sum_Fields>;
  var_pop?: Maybe<UserMovementDaily_Var_Pop_Fields>;
  var_samp?: Maybe<UserMovementDaily_Var_Samp_Fields>;
  variance?: Maybe<UserMovementDaily_Variance_Fields>;
};


/** aggregate fields of "UserMovementDaily" */
export type UserMovementDaily_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserMovementDaily" */
export type UserMovementDaily_Aggregate_Order_By = {
  avg?: InputMaybe<UserMovementDaily_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserMovementDaily_Max_Order_By>;
  min?: InputMaybe<UserMovementDaily_Min_Order_By>;
  stddev?: InputMaybe<UserMovementDaily_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserMovementDaily_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserMovementDaily_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserMovementDaily_Sum_Order_By>;
  var_pop?: InputMaybe<UserMovementDaily_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserMovementDaily_Var_Samp_Order_By>;
  variance?: InputMaybe<UserMovementDaily_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserMovementDaily" */
export type UserMovementDaily_Arr_Rel_Insert_Input = {
  data: Array<UserMovementDaily_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserMovementDaily_On_Conflict>;
};

/** aggregate avg on columns */
export type UserMovementDaily_Avg_Fields = {
  __typename?: 'UserMovementDaily_avg_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Avg_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserMovementDaily". All fields are combined with a logical 'AND'. */
export type UserMovementDaily_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserMovementDaily_Bool_Exp>>;
  _not?: InputMaybe<UserMovementDaily_Bool_Exp>;
  _or?: InputMaybe<Array<UserMovementDaily_Bool_Exp>>;
  activeCalories?: InputMaybe<Float8_Comparison_Exp>;
  activeMins?: InputMaybe<Int_Comparison_Exp>;
  bmr_calories?: InputMaybe<Float8_Comparison_Exp>;
  caloric_data?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  distance?: InputMaybe<Float8_Comparison_Exp>;
  duration_high_activity?: InputMaybe<Float8_Comparison_Exp>;
  duration_inactive_activity?: InputMaybe<Float8_Comparison_Exp>;
  duration_low_activity?: InputMaybe<Float8_Comparison_Exp>;
  duration_medium_activity?: InputMaybe<Float8_Comparison_Exp>;
  duration_workin?: InputMaybe<Float8_Comparison_Exp>;
  duration_workout?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  netCalories?: InputMaybe<Float8_Comparison_Exp>;
  sedentaryHours?: InputMaybe<Int_Comparison_Exp>;
  steps?: InputMaybe<Int_Comparison_Exp>;
  time?: InputMaybe<Time_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  workouts_accounted?: InputMaybe<Int_Comparison_Exp>;
  zone2Mins?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserMovementDaily" */
export type UserMovementDaily_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserMovementDaily_pkey'
  /** unique or primary key constraint on columns "userId", "date" */
  | 'UserMovementDaily_userId_date_key';

/** input type for incrementing numeric columns in table "UserMovementDaily" */
export type UserMovementDaily_Inc_Input = {
  activeCalories?: InputMaybe<Scalars['float8']['input']>;
  activeMins?: InputMaybe<Scalars['Int']['input']>;
  bmr_calories?: InputMaybe<Scalars['float8']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  duration_high_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_inactive_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_low_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_medium_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_workin?: InputMaybe<Scalars['float8']['input']>;
  duration_workout?: InputMaybe<Scalars['float8']['input']>;
  netCalories?: InputMaybe<Scalars['float8']['input']>;
  sedentaryHours?: InputMaybe<Scalars['Int']['input']>;
  steps?: InputMaybe<Scalars['Int']['input']>;
  workouts_accounted?: InputMaybe<Scalars['Int']['input']>;
  zone2Mins?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserMovementDaily" */
export type UserMovementDaily_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  activeCalories?: InputMaybe<Scalars['float8']['input']>;
  activeMins?: InputMaybe<Scalars['Int']['input']>;
  bmr_calories?: InputMaybe<Scalars['float8']['input']>;
  caloric_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  duration_high_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_inactive_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_low_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_medium_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_workin?: InputMaybe<Scalars['float8']['input']>;
  duration_workout?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  netCalories?: InputMaybe<Scalars['float8']['input']>;
  sedentaryHours?: InputMaybe<Scalars['Int']['input']>;
  steps?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['time']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workouts_accounted?: InputMaybe<Scalars['Int']['input']>;
  zone2Mins?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UserMovementDaily_Max_Fields = {
  __typename?: 'UserMovementDaily_max_fields';
  activeCalories?: Maybe<Scalars['float8']['output']>;
  activeMins?: Maybe<Scalars['Int']['output']>;
  bmr_calories?: Maybe<Scalars['float8']['output']>;
  caloric_data?: Maybe<Array<Scalars['jsonb']['output']>>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  duration_high_activity?: Maybe<Scalars['float8']['output']>;
  duration_inactive_activity?: Maybe<Scalars['float8']['output']>;
  duration_low_activity?: Maybe<Scalars['float8']['output']>;
  duration_medium_activity?: Maybe<Scalars['float8']['output']>;
  duration_workin?: Maybe<Scalars['float8']['output']>;
  duration_workout?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  netCalories?: Maybe<Scalars['float8']['output']>;
  sedentaryHours?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workouts_accounted?: Maybe<Scalars['Int']['output']>;
  zone2Mins?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Max_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  caloric_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserMovementDaily_Min_Fields = {
  __typename?: 'UserMovementDaily_min_fields';
  activeCalories?: Maybe<Scalars['float8']['output']>;
  activeMins?: Maybe<Scalars['Int']['output']>;
  bmr_calories?: Maybe<Scalars['float8']['output']>;
  caloric_data?: Maybe<Array<Scalars['jsonb']['output']>>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  duration_high_activity?: Maybe<Scalars['float8']['output']>;
  duration_inactive_activity?: Maybe<Scalars['float8']['output']>;
  duration_low_activity?: Maybe<Scalars['float8']['output']>;
  duration_medium_activity?: Maybe<Scalars['float8']['output']>;
  duration_workin?: Maybe<Scalars['float8']['output']>;
  duration_workout?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  netCalories?: Maybe<Scalars['float8']['output']>;
  sedentaryHours?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workouts_accounted?: Maybe<Scalars['Int']['output']>;
  zone2Mins?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Min_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  caloric_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserMovementDaily" */
export type UserMovementDaily_Mutation_Response = {
  __typename?: 'UserMovementDaily_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserMovementDaily>;
};

/** on_conflict condition type for table "UserMovementDaily" */
export type UserMovementDaily_On_Conflict = {
  constraint: UserMovementDaily_Constraint;
  update_columns?: Array<UserMovementDaily_Update_Column>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};

/** Ordering options when selecting data from "UserMovementDaily". */
export type UserMovementDaily_Order_By = {
  User?: InputMaybe<User_Order_By>;
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  caloric_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserMovementDaily */
export type UserMovementDaily_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'activeMins'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'caloric_data'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'id'
  /** column name */
  | 'netCalories'
  /** column name */
  | 'sedentaryHours'
  /** column name */
  | 'steps'
  /** column name */
  | 'time'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'workouts_accounted'
  /** column name */
  | 'zone2Mins';

/** select "UserMovementDaily_aggregate_bool_exp_avg_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_corr_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_max_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_min_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_sum_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** select "UserMovementDaily_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserMovementDaily" */
export type UserMovementDaily_Select_Column_UserMovementDaily_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'netCalories';

/** input type for updating data in table "UserMovementDaily" */
export type UserMovementDaily_Set_Input = {
  activeCalories?: InputMaybe<Scalars['float8']['input']>;
  activeMins?: InputMaybe<Scalars['Int']['input']>;
  bmr_calories?: InputMaybe<Scalars['float8']['input']>;
  caloric_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  duration_high_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_inactive_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_low_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_medium_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_workin?: InputMaybe<Scalars['float8']['input']>;
  duration_workout?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  netCalories?: InputMaybe<Scalars['float8']['input']>;
  sedentaryHours?: InputMaybe<Scalars['Int']['input']>;
  steps?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['time']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workouts_accounted?: InputMaybe<Scalars['Int']['input']>;
  zone2Mins?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type UserMovementDaily_Stddev_Fields = {
  __typename?: 'UserMovementDaily_stddev_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Stddev_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserMovementDaily_Stddev_Pop_Fields = {
  __typename?: 'UserMovementDaily_stddev_pop_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Stddev_Pop_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserMovementDaily_Stddev_Samp_Fields = {
  __typename?: 'UserMovementDaily_stddev_samp_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Stddev_Samp_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserMovementDaily" */
export type UserMovementDaily_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserMovementDaily_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserMovementDaily_Stream_Cursor_Value_Input = {
  activeCalories?: InputMaybe<Scalars['float8']['input']>;
  activeMins?: InputMaybe<Scalars['Int']['input']>;
  bmr_calories?: InputMaybe<Scalars['float8']['input']>;
  caloric_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  duration_high_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_inactive_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_low_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_medium_activity?: InputMaybe<Scalars['float8']['input']>;
  duration_workin?: InputMaybe<Scalars['float8']['input']>;
  duration_workout?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  netCalories?: InputMaybe<Scalars['float8']['input']>;
  sedentaryHours?: InputMaybe<Scalars['Int']['input']>;
  steps?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['time']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workouts_accounted?: InputMaybe<Scalars['Int']['input']>;
  zone2Mins?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UserMovementDaily_Sum_Fields = {
  __typename?: 'UserMovementDaily_sum_fields';
  activeCalories?: Maybe<Scalars['float8']['output']>;
  activeMins?: Maybe<Scalars['Int']['output']>;
  bmr_calories?: Maybe<Scalars['float8']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  duration_high_activity?: Maybe<Scalars['float8']['output']>;
  duration_inactive_activity?: Maybe<Scalars['float8']['output']>;
  duration_low_activity?: Maybe<Scalars['float8']['output']>;
  duration_medium_activity?: Maybe<Scalars['float8']['output']>;
  duration_workin?: Maybe<Scalars['float8']['output']>;
  duration_workout?: Maybe<Scalars['float8']['output']>;
  netCalories?: Maybe<Scalars['float8']['output']>;
  sedentaryHours?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  workouts_accounted?: Maybe<Scalars['Int']['output']>;
  zone2Mins?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Sum_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** update columns of table "UserMovementDaily" */
export type UserMovementDaily_Update_Column =
  /** column name */
  | 'activeCalories'
  /** column name */
  | 'activeMins'
  /** column name */
  | 'bmr_calories'
  /** column name */
  | 'caloric_data'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'distance'
  /** column name */
  | 'duration_high_activity'
  /** column name */
  | 'duration_inactive_activity'
  /** column name */
  | 'duration_low_activity'
  /** column name */
  | 'duration_medium_activity'
  /** column name */
  | 'duration_workin'
  /** column name */
  | 'duration_workout'
  /** column name */
  | 'id'
  /** column name */
  | 'netCalories'
  /** column name */
  | 'sedentaryHours'
  /** column name */
  | 'steps'
  /** column name */
  | 'time'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'workouts_accounted'
  /** column name */
  | 'zone2Mins';

export type UserMovementDaily_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserMovementDaily_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserMovementDaily_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserMovementDaily_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserMovementDaily_Var_Pop_Fields = {
  __typename?: 'UserMovementDaily_var_pop_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Var_Pop_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserMovementDaily_Var_Samp_Fields = {
  __typename?: 'UserMovementDaily_var_samp_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Var_Samp_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserMovementDaily_Variance_Fields = {
  __typename?: 'UserMovementDaily_variance_fields';
  activeCalories?: Maybe<Scalars['Float']['output']>;
  activeMins?: Maybe<Scalars['Float']['output']>;
  bmr_calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration_high_activity?: Maybe<Scalars['Float']['output']>;
  duration_inactive_activity?: Maybe<Scalars['Float']['output']>;
  duration_low_activity?: Maybe<Scalars['Float']['output']>;
  duration_medium_activity?: Maybe<Scalars['Float']['output']>;
  duration_workin?: Maybe<Scalars['Float']['output']>;
  duration_workout?: Maybe<Scalars['Float']['output']>;
  netCalories?: Maybe<Scalars['Float']['output']>;
  sedentaryHours?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
  workouts_accounted?: Maybe<Scalars['Float']['output']>;
  zone2Mins?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserMovementDaily" */
export type UserMovementDaily_Variance_Order_By = {
  activeCalories?: InputMaybe<Order_By>;
  activeMins?: InputMaybe<Order_By>;
  bmr_calories?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  duration_high_activity?: InputMaybe<Order_By>;
  duration_inactive_activity?: InputMaybe<Order_By>;
  duration_low_activity?: InputMaybe<Order_By>;
  duration_medium_activity?: InputMaybe<Order_By>;
  duration_workin?: InputMaybe<Order_By>;
  duration_workout?: InputMaybe<Order_By>;
  netCalories?: InputMaybe<Order_By>;
  sedentaryHours?: InputMaybe<Order_By>;
  steps?: InputMaybe<Order_By>;
  workouts_accounted?: InputMaybe<Order_By>;
  zone2Mins?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserPreferences" */
export type UserPreferences = {
  __typename?: 'UserPreferences';
  /** An object relationship */
  User: User;
  /** An array relationship */
  WorkinPrefrences: Array<WorkinPrefrence>;
  /** An aggregate relationship */
  WorkinPrefrences_aggregate: WorkinPrefrence_Aggregate;
  /** An array relationship */
  WorkoutPrefrences: Array<WorkoutPrefrence>;
  /** An aggregate relationship */
  WorkoutPrefrences_aggregate: WorkoutPrefrence_Aggregate;
  /** An array relationship */
  _GoalToUserPreferences: Array<_GoalToUserPreferences>;
  /** An aggregate relationship */
  _GoalToUserPreferences_aggregate: _GoalToUserPreferences_Aggregate;
  cardioPreference?: Maybe<Scalars['String']['output']>;
  coaching_cues: Scalars['Boolean']['output'];
  created_at: Scalars['timestamp']['output'];
  enabled_coach_chat: Scalars['Boolean']['output'];
  enabled_coach_chat_notification: Scalars['Boolean']['output'];
  enabled_low_signal_battery_notification: Scalars['Boolean']['output'];
  goal_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['String']['output'];
  ownsBand?: Maybe<Scalars['String']['output']>;
  ownsDumbells?: Maybe<Scalars['String']['output']>;
  receive_personalized_content: Scalars['Boolean']['output'];
  receive_promotional_content: Scalars['Boolean']['output'];
  theme_color?: Maybe<Scalars['ThemeColor']['output']>;
  trainerPreference?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  workout_tutorial_mode: Scalars['TutorialMode']['output'];
};


/** columns and relationships of "UserPreferences" */
export type UserPreferencesWorkinPrefrencesArgs = {
  distinct_on?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkinPrefrence_Order_By>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


/** columns and relationships of "UserPreferences" */
export type UserPreferencesWorkinPrefrences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkinPrefrence_Order_By>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


/** columns and relationships of "UserPreferences" */
export type UserPreferencesWorkoutPrefrencesArgs = {
  distinct_on?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkoutPrefrence_Order_By>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


/** columns and relationships of "UserPreferences" */
export type UserPreferencesWorkoutPrefrences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkoutPrefrence_Order_By>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


/** columns and relationships of "UserPreferences" */
export type UserPreferences_GoalToUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};


/** columns and relationships of "UserPreferences" */
export type UserPreferences_GoalToUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};

export type UserPreferencesOutput = {
  __typename?: 'UserPreferencesOutput';
  cardioPreference?: Maybe<Scalars['String']['output']>;
  coaching_cues?: Maybe<Scalars['Boolean']['output']>;
  enabled_coach_chat?: Maybe<Scalars['Boolean']['output']>;
  enabled_coach_chat_notification?: Maybe<Scalars['Boolean']['output']>;
  enabled_low_signal_battery_notification?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ownsBand?: Maybe<Scalars['String']['output']>;
  ownsDumbells?: Maybe<Scalars['String']['output']>;
  receive_personalized_content?: Maybe<Scalars['Boolean']['output']>;
  receive_promotional_content?: Maybe<Scalars['Boolean']['output']>;
  theme_color?: Maybe<ThemeColorEnum>;
  trainerPreference?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workin_prefrence?: Maybe<WorkinPrefrenceOutput>;
  workout_prefrence?: Maybe<WorkoutPrefrenceOutput>;
  workout_tutorial_mode?: Maybe<TutorialModeEnum>;
};

/** aggregated selection of "UserPreferences" */
export type UserPreferences_Aggregate = {
  __typename?: 'UserPreferences_aggregate';
  aggregate?: Maybe<UserPreferences_Aggregate_Fields>;
  nodes: Array<UserPreferences>;
};

export type UserPreferences_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<UserPreferences_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserPreferences_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<UserPreferences_Aggregate_Bool_Exp_Count>;
};

export type UserPreferences_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserPreferences_Select_Column_UserPreferences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserPreferences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserPreferences_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserPreferences_Select_Column_UserPreferences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserPreferences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserPreferences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserPreferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserPreferences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserPreferences" */
export type UserPreferences_Aggregate_Fields = {
  __typename?: 'UserPreferences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserPreferences_Max_Fields>;
  min?: Maybe<UserPreferences_Min_Fields>;
};


/** aggregate fields of "UserPreferences" */
export type UserPreferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserPreferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserPreferences" */
export type UserPreferences_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserPreferences_Max_Order_By>;
  min?: InputMaybe<UserPreferences_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserPreferences" */
export type UserPreferences_Arr_Rel_Insert_Input = {
  data: Array<UserPreferences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserPreferences". All fields are combined with a logical 'AND'. */
export type UserPreferences_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  WorkinPrefrences?: InputMaybe<WorkinPrefrence_Bool_Exp>;
  WorkinPrefrences_aggregate?: InputMaybe<WorkinPrefrence_Aggregate_Bool_Exp>;
  WorkoutPrefrences?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
  WorkoutPrefrences_aggregate?: InputMaybe<WorkoutPrefrence_Aggregate_Bool_Exp>;
  _GoalToUserPreferences?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
  _GoalToUserPreferences_aggregate?: InputMaybe<_GoalToUserPreferences_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<UserPreferences_Bool_Exp>>;
  _not?: InputMaybe<UserPreferences_Bool_Exp>;
  _or?: InputMaybe<Array<UserPreferences_Bool_Exp>>;
  cardioPreference?: InputMaybe<String_Comparison_Exp>;
  coaching_cues?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  enabled_coach_chat?: InputMaybe<Boolean_Comparison_Exp>;
  enabled_coach_chat_notification?: InputMaybe<Boolean_Comparison_Exp>;
  enabled_low_signal_battery_notification?: InputMaybe<Boolean_Comparison_Exp>;
  goal_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ownsBand?: InputMaybe<String_Comparison_Exp>;
  ownsDumbells?: InputMaybe<String_Comparison_Exp>;
  receive_personalized_content?: InputMaybe<Boolean_Comparison_Exp>;
  receive_promotional_content?: InputMaybe<Boolean_Comparison_Exp>;
  theme_color?: InputMaybe<ThemeColor_Comparison_Exp>;
  trainerPreference?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  workout_tutorial_mode?: InputMaybe<TutorialMode_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserPreferences" */
export type UserPreferences_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserPreferences_pkey'
  /** unique or primary key constraint on columns "userId" */
  | 'UserPreferences_userId_key';

/** input type for inserting data into table "UserPreferences" */
export type UserPreferences_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  WorkinPrefrences?: InputMaybe<WorkinPrefrence_Arr_Rel_Insert_Input>;
  WorkoutPrefrences?: InputMaybe<WorkoutPrefrence_Arr_Rel_Insert_Input>;
  _GoalToUserPreferences?: InputMaybe<_GoalToUserPreferences_Arr_Rel_Insert_Input>;
  cardioPreference?: InputMaybe<Scalars['String']['input']>;
  coaching_cues?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled_coach_chat?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_coach_chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_low_signal_battery_notification?: InputMaybe<Scalars['Boolean']['input']>;
  goal_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ownsBand?: InputMaybe<Scalars['String']['input']>;
  ownsDumbells?: InputMaybe<Scalars['String']['input']>;
  receive_personalized_content?: InputMaybe<Scalars['Boolean']['input']>;
  receive_promotional_content?: InputMaybe<Scalars['Boolean']['input']>;
  theme_color?: InputMaybe<Scalars['ThemeColor']['input']>;
  trainerPreference?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workout_tutorial_mode?: InputMaybe<Scalars['TutorialMode']['input']>;
};

/** aggregate max on columns */
export type UserPreferences_Max_Fields = {
  __typename?: 'UserPreferences_max_fields';
  cardioPreference?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ownsBand?: Maybe<Scalars['String']['output']>;
  ownsDumbells?: Maybe<Scalars['String']['output']>;
  theme_color?: Maybe<Scalars['ThemeColor']['output']>;
  trainerPreference?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workout_tutorial_mode?: Maybe<Scalars['TutorialMode']['output']>;
};

/** order by max() on columns of table "UserPreferences" */
export type UserPreferences_Max_Order_By = {
  cardioPreference?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  goal_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ownsBand?: InputMaybe<Order_By>;
  ownsDumbells?: InputMaybe<Order_By>;
  theme_color?: InputMaybe<Order_By>;
  trainerPreference?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workout_tutorial_mode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserPreferences_Min_Fields = {
  __typename?: 'UserPreferences_min_fields';
  cardioPreference?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ownsBand?: Maybe<Scalars['String']['output']>;
  ownsDumbells?: Maybe<Scalars['String']['output']>;
  theme_color?: Maybe<Scalars['ThemeColor']['output']>;
  trainerPreference?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workout_tutorial_mode?: Maybe<Scalars['TutorialMode']['output']>;
};

/** order by min() on columns of table "UserPreferences" */
export type UserPreferences_Min_Order_By = {
  cardioPreference?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  goal_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ownsBand?: InputMaybe<Order_By>;
  ownsDumbells?: InputMaybe<Order_By>;
  theme_color?: InputMaybe<Order_By>;
  trainerPreference?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workout_tutorial_mode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserPreferences" */
export type UserPreferences_Mutation_Response = {
  __typename?: 'UserPreferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserPreferences>;
};

/** input type for inserting object relation for remote table "UserPreferences" */
export type UserPreferences_Obj_Rel_Insert_Input = {
  data: UserPreferences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};

/** on_conflict condition type for table "UserPreferences" */
export type UserPreferences_On_Conflict = {
  constraint: UserPreferences_Constraint;
  update_columns?: Array<UserPreferences_Update_Column>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};

/** Ordering options when selecting data from "UserPreferences". */
export type UserPreferences_Order_By = {
  User?: InputMaybe<User_Order_By>;
  WorkinPrefrences_aggregate?: InputMaybe<WorkinPrefrence_Aggregate_Order_By>;
  WorkoutPrefrences_aggregate?: InputMaybe<WorkoutPrefrence_Aggregate_Order_By>;
  _GoalToUserPreferences_aggregate?: InputMaybe<_GoalToUserPreferences_Aggregate_Order_By>;
  cardioPreference?: InputMaybe<Order_By>;
  coaching_cues?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enabled_coach_chat?: InputMaybe<Order_By>;
  enabled_coach_chat_notification?: InputMaybe<Order_By>;
  enabled_low_signal_battery_notification?: InputMaybe<Order_By>;
  goal_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ownsBand?: InputMaybe<Order_By>;
  ownsDumbells?: InputMaybe<Order_By>;
  receive_personalized_content?: InputMaybe<Order_By>;
  receive_promotional_content?: InputMaybe<Order_By>;
  theme_color?: InputMaybe<Order_By>;
  trainerPreference?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workout_tutorial_mode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserPreferences */
export type UserPreferences_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserPreferences" */
export type UserPreferences_Select_Column =
  /** column name */
  | 'cardioPreference'
  /** column name */
  | 'coaching_cues'
  /** column name */
  | 'created_at'
  /** column name */
  | 'enabled_coach_chat'
  /** column name */
  | 'enabled_coach_chat_notification'
  /** column name */
  | 'enabled_low_signal_battery_notification'
  /** column name */
  | 'goal_updated_at'
  /** column name */
  | 'id'
  /** column name */
  | 'ownsBand'
  /** column name */
  | 'ownsDumbells'
  /** column name */
  | 'receive_personalized_content'
  /** column name */
  | 'receive_promotional_content'
  /** column name */
  | 'theme_color'
  /** column name */
  | 'trainerPreference'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'workout_tutorial_mode';

/** select "UserPreferences_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserPreferences" */
export type UserPreferences_Select_Column_UserPreferences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'coaching_cues'
  /** column name */
  | 'enabled_coach_chat'
  /** column name */
  | 'enabled_coach_chat_notification'
  /** column name */
  | 'enabled_low_signal_battery_notification'
  /** column name */
  | 'receive_personalized_content'
  /** column name */
  | 'receive_promotional_content';

/** select "UserPreferences_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserPreferences" */
export type UserPreferences_Select_Column_UserPreferences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'coaching_cues'
  /** column name */
  | 'enabled_coach_chat'
  /** column name */
  | 'enabled_coach_chat_notification'
  /** column name */
  | 'enabled_low_signal_battery_notification'
  /** column name */
  | 'receive_personalized_content'
  /** column name */
  | 'receive_promotional_content';

/** input type for updating data in table "UserPreferences" */
export type UserPreferences_Set_Input = {
  cardioPreference?: InputMaybe<Scalars['String']['input']>;
  coaching_cues?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled_coach_chat?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_coach_chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_low_signal_battery_notification?: InputMaybe<Scalars['Boolean']['input']>;
  goal_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ownsBand?: InputMaybe<Scalars['String']['input']>;
  ownsDumbells?: InputMaybe<Scalars['String']['input']>;
  receive_personalized_content?: InputMaybe<Scalars['Boolean']['input']>;
  receive_promotional_content?: InputMaybe<Scalars['Boolean']['input']>;
  theme_color?: InputMaybe<Scalars['ThemeColor']['input']>;
  trainerPreference?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workout_tutorial_mode?: InputMaybe<Scalars['TutorialMode']['input']>;
};

/** Streaming cursor of the table "UserPreferences" */
export type UserPreferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserPreferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserPreferences_Stream_Cursor_Value_Input = {
  cardioPreference?: InputMaybe<Scalars['String']['input']>;
  coaching_cues?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  enabled_coach_chat?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_coach_chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_low_signal_battery_notification?: InputMaybe<Scalars['Boolean']['input']>;
  goal_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ownsBand?: InputMaybe<Scalars['String']['input']>;
  ownsDumbells?: InputMaybe<Scalars['String']['input']>;
  receive_personalized_content?: InputMaybe<Scalars['Boolean']['input']>;
  receive_promotional_content?: InputMaybe<Scalars['Boolean']['input']>;
  theme_color?: InputMaybe<Scalars['ThemeColor']['input']>;
  trainerPreference?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workout_tutorial_mode?: InputMaybe<Scalars['TutorialMode']['input']>;
};

/** update columns of table "UserPreferences" */
export type UserPreferences_Update_Column =
  /** column name */
  | 'cardioPreference'
  /** column name */
  | 'coaching_cues'
  /** column name */
  | 'created_at'
  /** column name */
  | 'enabled_coach_chat'
  /** column name */
  | 'enabled_coach_chat_notification'
  /** column name */
  | 'enabled_low_signal_battery_notification'
  /** column name */
  | 'goal_updated_at'
  /** column name */
  | 'id'
  /** column name */
  | 'ownsBand'
  /** column name */
  | 'ownsDumbells'
  /** column name */
  | 'receive_personalized_content'
  /** column name */
  | 'receive_promotional_content'
  /** column name */
  | 'theme_color'
  /** column name */
  | 'trainerPreference'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'workout_tutorial_mode';

export type UserPreferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserPreferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserPreferences_Bool_Exp;
};

/** columns and relationships of "UserProduct" */
export type UserProduct = {
  __typename?: 'UserProduct';
  /** An array relationship */
  Deliveries: Array<Delivery>;
  /** An aggregate relationship */
  Deliveries_aggregate: Delivery_Aggregate;
  /** An array relationship */
  ProductLogs: Array<ProductLog>;
  /** An aggregate relationship */
  ProductLogs_aggregate: ProductLog_Aggregate;
  /** An object relationship */
  User: User;
  created_at: Scalars['timestamp']['output'];
  delivery_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  product_id?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  stripe_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "UserProduct" */
export type UserProductDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Order_By>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


/** columns and relationships of "UserProduct" */
export type UserProductDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Order_By>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


/** columns and relationships of "UserProduct" */
export type UserProductProductLogsArgs = {
  distinct_on?: InputMaybe<Array<ProductLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductLog_Order_By>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};


/** columns and relationships of "UserProduct" */
export type UserProductProductLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductLog_Order_By>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};

/** columns and relationships of "UserProductHistory" */
export type UserProductHistory = {
  __typename?: 'UserProductHistory';
  /** An object relationship */
  Product: Product;
  /** An object relationship */
  User: User;
  column: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  date: Scalars['timestamp']['output'];
  dml_type: Scalars['DMLType']['output'];
  id: Scalars['String']['output'];
  new_data: Scalars['String']['output'];
  old_data: Scalars['String']['output'];
  product_id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "UserProductHistory" */
export type UserProductHistory_Aggregate = {
  __typename?: 'UserProductHistory_aggregate';
  aggregate?: Maybe<UserProductHistory_Aggregate_Fields>;
  nodes: Array<UserProductHistory>;
};

export type UserProductHistory_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserProductHistory_Aggregate_Bool_Exp_Count>;
};

export type UserProductHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserProductHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserProductHistory" */
export type UserProductHistory_Aggregate_Fields = {
  __typename?: 'UserProductHistory_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserProductHistory_Max_Fields>;
  min?: Maybe<UserProductHistory_Min_Fields>;
};


/** aggregate fields of "UserProductHistory" */
export type UserProductHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserProductHistory" */
export type UserProductHistory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserProductHistory_Max_Order_By>;
  min?: InputMaybe<UserProductHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserProductHistory" */
export type UserProductHistory_Arr_Rel_Insert_Input = {
  data: Array<UserProductHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserProductHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserProductHistory". All fields are combined with a logical 'AND'. */
export type UserProductHistory_Bool_Exp = {
  Product?: InputMaybe<Product_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserProductHistory_Bool_Exp>>;
  _not?: InputMaybe<UserProductHistory_Bool_Exp>;
  _or?: InputMaybe<Array<UserProductHistory_Bool_Exp>>;
  column?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  dml_type?: InputMaybe<DmlType_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  new_data?: InputMaybe<String_Comparison_Exp>;
  old_data?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserProductHistory" */
export type UserProductHistory_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserProductHistory_pkey';

/** input type for inserting data into table "UserProductHistory" */
export type UserProductHistory_Insert_Input = {
  Product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  dml_type?: InputMaybe<Scalars['DMLType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserProductHistory_Max_Fields = {
  __typename?: 'UserProductHistory_max_fields';
  column?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  dml_type?: Maybe<Scalars['DMLType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  new_data?: Maybe<Scalars['String']['output']>;
  old_data?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserProductHistory" */
export type UserProductHistory_Max_Order_By = {
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dml_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserProductHistory_Min_Fields = {
  __typename?: 'UserProductHistory_min_fields';
  column?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  dml_type?: Maybe<Scalars['DMLType']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  new_data?: Maybe<Scalars['String']['output']>;
  old_data?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserProductHistory" */
export type UserProductHistory_Min_Order_By = {
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dml_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserProductHistory" */
export type UserProductHistory_Mutation_Response = {
  __typename?: 'UserProductHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserProductHistory>;
};

/** on_conflict condition type for table "UserProductHistory" */
export type UserProductHistory_On_Conflict = {
  constraint: UserProductHistory_Constraint;
  update_columns?: Array<UserProductHistory_Update_Column>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "UserProductHistory". */
export type UserProductHistory_Order_By = {
  Product?: InputMaybe<Product_Order_By>;
  User?: InputMaybe<User_Order_By>;
  column?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dml_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_data?: InputMaybe<Order_By>;
  old_data?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserProductHistory */
export type UserProductHistory_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserProductHistory" */
export type UserProductHistory_Select_Column =
  /** column name */
  | 'column'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'dml_type'
  /** column name */
  | 'id'
  /** column name */
  | 'new_data'
  /** column name */
  | 'old_data'
  /** column name */
  | 'product_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** input type for updating data in table "UserProductHistory" */
export type UserProductHistory_Set_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  dml_type?: InputMaybe<Scalars['DMLType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserProductHistory" */
export type UserProductHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserProductHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserProductHistory_Stream_Cursor_Value_Input = {
  column?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  dml_type?: InputMaybe<Scalars['DMLType']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  new_data?: InputMaybe<Scalars['String']['input']>;
  old_data?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserProductHistory" */
export type UserProductHistory_Update_Column =
  /** column name */
  | 'column'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'dml_type'
  /** column name */
  | 'id'
  /** column name */
  | 'new_data'
  /** column name */
  | 'old_data'
  /** column name */
  | 'product_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type UserProductHistory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserProductHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserProductHistory_Bool_Exp;
};

/** aggregated selection of "UserProduct" */
export type UserProduct_Aggregate = {
  __typename?: 'UserProduct_aggregate';
  aggregate?: Maybe<UserProduct_Aggregate_Fields>;
  nodes: Array<UserProduct>;
};

export type UserProduct_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserProduct_Aggregate_Bool_Exp_Count>;
};

export type UserProduct_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserProduct_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserProduct_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserProduct" */
export type UserProduct_Aggregate_Fields = {
  __typename?: 'UserProduct_aggregate_fields';
  avg?: Maybe<UserProduct_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserProduct_Max_Fields>;
  min?: Maybe<UserProduct_Min_Fields>;
  stddev?: Maybe<UserProduct_Stddev_Fields>;
  stddev_pop?: Maybe<UserProduct_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserProduct_Stddev_Samp_Fields>;
  sum?: Maybe<UserProduct_Sum_Fields>;
  var_pop?: Maybe<UserProduct_Var_Pop_Fields>;
  var_samp?: Maybe<UserProduct_Var_Samp_Fields>;
  variance?: Maybe<UserProduct_Variance_Fields>;
};


/** aggregate fields of "UserProduct" */
export type UserProduct_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserProduct_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserProduct" */
export type UserProduct_Aggregate_Order_By = {
  avg?: InputMaybe<UserProduct_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserProduct_Max_Order_By>;
  min?: InputMaybe<UserProduct_Min_Order_By>;
  stddev?: InputMaybe<UserProduct_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserProduct_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserProduct_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserProduct_Sum_Order_By>;
  var_pop?: InputMaybe<UserProduct_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserProduct_Var_Samp_Order_By>;
  variance?: InputMaybe<UserProduct_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserProduct" */
export type UserProduct_Arr_Rel_Insert_Input = {
  data: Array<UserProduct_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserProduct_On_Conflict>;
};

/** aggregate avg on columns */
export type UserProduct_Avg_Fields = {
  __typename?: 'UserProduct_avg_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserProduct" */
export type UserProduct_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserProduct". All fields are combined with a logical 'AND'. */
export type UserProduct_Bool_Exp = {
  Deliveries?: InputMaybe<Delivery_Bool_Exp>;
  Deliveries_aggregate?: InputMaybe<Delivery_Aggregate_Bool_Exp>;
  ProductLogs?: InputMaybe<ProductLog_Bool_Exp>;
  ProductLogs_aggregate?: InputMaybe<ProductLog_Aggregate_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserProduct_Bool_Exp>>;
  _not?: InputMaybe<UserProduct_Bool_Exp>;
  _or?: InputMaybe<Array<UserProduct_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivery_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  stripe_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserProduct" */
export type UserProduct_Constraint =
  /** unique or primary key constraint on columns "delivery_id" */
  | 'UserProduct_delivery_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'UserProduct_pkey';

/** input type for incrementing numeric columns in table "UserProduct" */
export type UserProduct_Inc_Input = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserProduct" */
export type UserProduct_Insert_Input = {
  Deliveries?: InputMaybe<Delivery_Arr_Rel_Insert_Input>;
  ProductLogs?: InputMaybe<ProductLog_Arr_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserProduct_Max_Fields = {
  __typename?: 'UserProduct_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  delivery_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserProduct" */
export type UserProduct_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserProduct_Min_Fields = {
  __typename?: 'UserProduct_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  delivery_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserProduct" */
export type UserProduct_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserProduct" */
export type UserProduct_Mutation_Response = {
  __typename?: 'UserProduct_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserProduct>;
};

/** input type for inserting object relation for remote table "UserProduct" */
export type UserProduct_Obj_Rel_Insert_Input = {
  data: UserProduct_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserProduct_On_Conflict>;
};

/** on_conflict condition type for table "UserProduct" */
export type UserProduct_On_Conflict = {
  constraint: UserProduct_Constraint;
  update_columns?: Array<UserProduct_Update_Column>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};

/** Ordering options when selecting data from "UserProduct". */
export type UserProduct_Order_By = {
  Deliveries_aggregate?: InputMaybe<Delivery_Aggregate_Order_By>;
  ProductLogs_aggregate?: InputMaybe<ProductLog_Aggregate_Order_By>;
  User?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserProduct */
export type UserProduct_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserProduct" */
export type UserProduct_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'delivery_id'
  /** column name */
  | 'id'
  /** column name */
  | 'product_id'
  /** column name */
  | 'quantity'
  /** column name */
  | 'stripe_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** input type for updating data in table "UserProduct" */
export type UserProduct_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserProduct_Stddev_Fields = {
  __typename?: 'UserProduct_stddev_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserProduct" */
export type UserProduct_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserProduct_Stddev_Pop_Fields = {
  __typename?: 'UserProduct_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserProduct" */
export type UserProduct_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserProduct_Stddev_Samp_Fields = {
  __typename?: 'UserProduct_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserProduct" */
export type UserProduct_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserProduct" */
export type UserProduct_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserProduct_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserProduct_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  delivery_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserProduct_Sum_Fields = {
  __typename?: 'UserProduct_sum_fields';
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserProduct" */
export type UserProduct_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "UserProduct" */
export type UserProduct_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'delivery_id'
  /** column name */
  | 'id'
  /** column name */
  | 'product_id'
  /** column name */
  | 'quantity'
  /** column name */
  | 'stripe_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type UserProduct_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserProduct_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserProduct_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserProduct_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserProduct_Var_Pop_Fields = {
  __typename?: 'UserProduct_var_pop_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserProduct" */
export type UserProduct_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserProduct_Var_Samp_Fields = {
  __typename?: 'UserProduct_var_samp_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserProduct" */
export type UserProduct_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserProduct_Variance_Fields = {
  __typename?: 'UserProduct_variance_fields';
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserProduct" */
export type UserProduct_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

export type UserProductsOutput = {
  __typename?: 'UserProductsOutput';
  delivery?: Maybe<ProductDelivery>;
  id: Scalars['String']['output'];
  product_sku: Scalars['String']['output'];
};

/** columns and relationships of "UserReadiness" */
export type UserReadiness = {
  __typename?: 'UserReadiness';
  /** An object relationship */
  User: User;
  alter_zone_max?: Maybe<Scalars['Int']['output']>;
  alter_zone_min?: Maybe<Scalars['Int']['output']>;
  avg_rhr?: Maybe<Scalars['float8']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['float8']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['float8']['output']>;
  awakeTime?: Maybe<Scalars['float8']['output']>;
  circadianConsistency?: Maybe<Scalars['Int']['output']>;
  combinedSleeps?: Maybe<Scalars['Int']['output']>;
  consistency_onset?: Maybe<Scalars['Int']['output']>;
  consistency_wakeup?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamp']['output'];
  date: Scalars['timestamp']['output'];
  dayString?: Maybe<Scalars['String']['output']>;
  deepSleep: Scalars['float8']['output'];
  efficiency?: Maybe<Scalars['float8']['output']>;
  hrv: Scalars['float8']['output'];
  id: Scalars['String']['output'];
  interruptionMins?: Maybe<Scalars['Int']['output']>;
  is_full_override: Scalars['Boolean']['output'];
  is_philips: Scalars['Boolean']['output'];
  latencyMins?: Maybe<Scalars['Int']['output']>;
  lightSleep?: Maybe<Scalars['float8']['output']>;
  mhr?: Maybe<Scalars['Int']['output']>;
  min_rhr?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  overrideScore?: Maybe<Scalars['float8']['output']>;
  override_id?: Maybe<Scalars['String']['output']>;
  readinessScore?: Maybe<Scalars['float8']['output']>;
  remSleep?: Maybe<Scalars['float8']['output']>;
  rhr: Scalars['float8']['output'];
  rhr_month?: Maybe<Scalars['Int']['output']>;
  sleepDuration: Scalars['float8']['output'];
  sleepEndEpoch?: Maybe<Scalars['Int']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Int']['output']>;
  sleep_stages?: Maybe<Array<Scalars['jsonb']['output']>>;
  sleep_timezone?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  wakingHrv?: Maybe<Scalars['float8']['output']>;
  zScoreDeepSleep: Scalars['float8']['output'];
  zScoreHRV: Scalars['float8']['output'];
  zScoreRHR: Scalars['float8']['output'];
  zScoreSleepDuration: Scalars['float8']['output'];
};

/** columns and relationships of "UserReadinessLog" */
export type UserReadinessLog = {
  __typename?: 'UserReadinessLog';
  /** An object relationship */
  User: User;
  action: Scalars['CRUDaction']['output'];
  cause_for_action?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['jsonb']['output']>;
  created_at: Scalars['timestamp']['output'];
  divided_sleeps?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  single_results?: Maybe<Scalars['jsonb']['output']>;
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "UserReadinessLog" */
export type UserReadinessLogContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "UserReadinessLog" */
export type UserReadinessLogDivided_SleepsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "UserReadinessLog" */
export type UserReadinessLogSingle_ResultsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "UserReadinessLog" */
export type UserReadinessLog_Aggregate = {
  __typename?: 'UserReadinessLog_aggregate';
  aggregate?: Maybe<UserReadinessLog_Aggregate_Fields>;
  nodes: Array<UserReadinessLog>;
};

export type UserReadinessLog_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserReadinessLog_Aggregate_Bool_Exp_Count>;
};

export type UserReadinessLog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadinessLog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserReadinessLog" */
export type UserReadinessLog_Aggregate_Fields = {
  __typename?: 'UserReadinessLog_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserReadinessLog_Max_Fields>;
  min?: Maybe<UserReadinessLog_Min_Fields>;
};


/** aggregate fields of "UserReadinessLog" */
export type UserReadinessLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserReadinessLog" */
export type UserReadinessLog_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserReadinessLog_Max_Order_By>;
  min?: InputMaybe<UserReadinessLog_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserReadinessLog_Append_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  divided_sleeps?: InputMaybe<Scalars['jsonb']['input']>;
  single_results?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "UserReadinessLog" */
export type UserReadinessLog_Arr_Rel_Insert_Input = {
  data: Array<UserReadinessLog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserReadinessLog_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserReadinessLog". All fields are combined with a logical 'AND'. */
export type UserReadinessLog_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserReadinessLog_Bool_Exp>>;
  _not?: InputMaybe<UserReadinessLog_Bool_Exp>;
  _or?: InputMaybe<Array<UserReadinessLog_Bool_Exp>>;
  action?: InputMaybe<CruDaction_Comparison_Exp>;
  cause_for_action?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  divided_sleeps?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  single_results?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserReadinessLog" */
export type UserReadinessLog_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserReadinessLog_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserReadinessLog_Delete_At_Path_Input = {
  content?: InputMaybe<Array<Scalars['String']['input']>>;
  divided_sleeps?: InputMaybe<Array<Scalars['String']['input']>>;
  single_results?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserReadinessLog_Delete_Elem_Input = {
  content?: InputMaybe<Scalars['Int']['input']>;
  divided_sleeps?: InputMaybe<Scalars['Int']['input']>;
  single_results?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserReadinessLog_Delete_Key_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  divided_sleeps?: InputMaybe<Scalars['String']['input']>;
  single_results?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "UserReadinessLog" */
export type UserReadinessLog_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  action?: InputMaybe<Scalars['CRUDaction']['input']>;
  cause_for_action?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  divided_sleeps?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  single_results?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserReadinessLog_Max_Fields = {
  __typename?: 'UserReadinessLog_max_fields';
  action?: Maybe<Scalars['CRUDaction']['output']>;
  cause_for_action?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserReadinessLog" */
export type UserReadinessLog_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  cause_for_action?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserReadinessLog_Min_Fields = {
  __typename?: 'UserReadinessLog_min_fields';
  action?: Maybe<Scalars['CRUDaction']['output']>;
  cause_for_action?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserReadinessLog" */
export type UserReadinessLog_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  cause_for_action?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserReadinessLog" */
export type UserReadinessLog_Mutation_Response = {
  __typename?: 'UserReadinessLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserReadinessLog>;
};

/** on_conflict condition type for table "UserReadinessLog" */
export type UserReadinessLog_On_Conflict = {
  constraint: UserReadinessLog_Constraint;
  update_columns?: Array<UserReadinessLog_Update_Column>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};

/** Ordering options when selecting data from "UserReadinessLog". */
export type UserReadinessLog_Order_By = {
  User?: InputMaybe<User_Order_By>;
  action?: InputMaybe<Order_By>;
  cause_for_action?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  divided_sleeps?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  single_results?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserReadinessLog */
export type UserReadinessLog_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserReadinessLog_Prepend_Input = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
  divided_sleeps?: InputMaybe<Scalars['jsonb']['input']>;
  single_results?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "UserReadinessLog" */
export type UserReadinessLog_Select_Column =
  /** column name */
  | 'action'
  /** column name */
  | 'cause_for_action'
  /** column name */
  | 'content'
  /** column name */
  | 'created_at'
  /** column name */
  | 'divided_sleeps'
  /** column name */
  | 'id'
  /** column name */
  | 'single_results'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** input type for updating data in table "UserReadinessLog" */
export type UserReadinessLog_Set_Input = {
  action?: InputMaybe<Scalars['CRUDaction']['input']>;
  cause_for_action?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  divided_sleeps?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  single_results?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserReadinessLog" */
export type UserReadinessLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserReadinessLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserReadinessLog_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['CRUDaction']['input']>;
  cause_for_action?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  divided_sleeps?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  single_results?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserReadinessLog" */
export type UserReadinessLog_Update_Column =
  /** column name */
  | 'action'
  /** column name */
  | 'cause_for_action'
  /** column name */
  | 'content'
  /** column name */
  | 'created_at'
  /** column name */
  | 'divided_sleeps'
  /** column name */
  | 'id'
  /** column name */
  | 'single_results'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type UserReadinessLog_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserReadinessLog_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserReadinessLog_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserReadinessLog_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserReadinessLog_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserReadinessLog_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserReadinessLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserReadinessLog_Bool_Exp;
};

/** aggregated selection of "UserReadiness" */
export type UserReadiness_Aggregate = {
  __typename?: 'UserReadiness_aggregate';
  aggregate?: Maybe<UserReadiness_Aggregate_Fields>;
  nodes: Array<UserReadiness>;
};

export type UserReadiness_Aggregate_Bool_Exp = {
  avg?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserReadiness_Aggregate_Bool_Exp_Var_Samp>;
};

export type UserReadiness_Aggregate_Bool_Exp_Avg = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Corr = {
  arguments: UserReadiness_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Corr_Arguments = {
  X: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type UserReadiness_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserReadiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: UserReadiness_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type UserReadiness_Aggregate_Bool_Exp_Max = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Min = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Sum = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserReadiness_Aggregate_Bool_Exp_Var_Samp = {
  arguments: UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReadiness_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "UserReadiness" */
export type UserReadiness_Aggregate_Fields = {
  __typename?: 'UserReadiness_aggregate_fields';
  avg?: Maybe<UserReadiness_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserReadiness_Max_Fields>;
  min?: Maybe<UserReadiness_Min_Fields>;
  stddev?: Maybe<UserReadiness_Stddev_Fields>;
  stddev_pop?: Maybe<UserReadiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserReadiness_Stddev_Samp_Fields>;
  sum?: Maybe<UserReadiness_Sum_Fields>;
  var_pop?: Maybe<UserReadiness_Var_Pop_Fields>;
  var_samp?: Maybe<UserReadiness_Var_Samp_Fields>;
  variance?: Maybe<UserReadiness_Variance_Fields>;
};


/** aggregate fields of "UserReadiness" */
export type UserReadiness_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserReadiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserReadiness" */
export type UserReadiness_Aggregate_Order_By = {
  avg?: InputMaybe<UserReadiness_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserReadiness_Max_Order_By>;
  min?: InputMaybe<UserReadiness_Min_Order_By>;
  stddev?: InputMaybe<UserReadiness_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserReadiness_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserReadiness_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserReadiness_Sum_Order_By>;
  var_pop?: InputMaybe<UserReadiness_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserReadiness_Var_Samp_Order_By>;
  variance?: InputMaybe<UserReadiness_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserReadiness" */
export type UserReadiness_Arr_Rel_Insert_Input = {
  data: Array<UserReadiness_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserReadiness_On_Conflict>;
};

/** aggregate avg on columns */
export type UserReadiness_Avg_Fields = {
  __typename?: 'UserReadiness_avg_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserReadiness" */
export type UserReadiness_Avg_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserReadiness". All fields are combined with a logical 'AND'. */
export type UserReadiness_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserReadiness_Bool_Exp>>;
  _not?: InputMaybe<UserReadiness_Bool_Exp>;
  _or?: InputMaybe<Array<UserReadiness_Bool_Exp>>;
  alter_zone_max?: InputMaybe<Int_Comparison_Exp>;
  alter_zone_min?: InputMaybe<Int_Comparison_Exp>;
  avg_rhr?: InputMaybe<Float8_Comparison_Exp>;
  avg_sleep_start_30_day?: InputMaybe<Float8_Comparison_Exp>;
  avg_sleep_stop_30_day?: InputMaybe<Float8_Comparison_Exp>;
  awakeTime?: InputMaybe<Float8_Comparison_Exp>;
  circadianConsistency?: InputMaybe<Int_Comparison_Exp>;
  combinedSleeps?: InputMaybe<Int_Comparison_Exp>;
  consistency_onset?: InputMaybe<Int_Comparison_Exp>;
  consistency_wakeup?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  dayString?: InputMaybe<String_Comparison_Exp>;
  deepSleep?: InputMaybe<Float8_Comparison_Exp>;
  efficiency?: InputMaybe<Float8_Comparison_Exp>;
  hrv?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  interruptionMins?: InputMaybe<Int_Comparison_Exp>;
  is_full_override?: InputMaybe<Boolean_Comparison_Exp>;
  is_philips?: InputMaybe<Boolean_Comparison_Exp>;
  latencyMins?: InputMaybe<Int_Comparison_Exp>;
  lightSleep?: InputMaybe<Float8_Comparison_Exp>;
  mhr?: InputMaybe<Int_Comparison_Exp>;
  min_rhr?: InputMaybe<Float8_Comparison_Exp>;
  netSleepScore?: InputMaybe<Float8_Comparison_Exp>;
  overrideScore?: InputMaybe<Float8_Comparison_Exp>;
  override_id?: InputMaybe<String_Comparison_Exp>;
  readinessScore?: InputMaybe<Float8_Comparison_Exp>;
  remSleep?: InputMaybe<Float8_Comparison_Exp>;
  rhr?: InputMaybe<Float8_Comparison_Exp>;
  rhr_month?: InputMaybe<Int_Comparison_Exp>;
  sleepDuration?: InputMaybe<Float8_Comparison_Exp>;
  sleepEndEpoch?: InputMaybe<Int_Comparison_Exp>;
  sleepStartEpoch?: InputMaybe<Int_Comparison_Exp>;
  sleep_stages?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  sleep_timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  wakingHrv?: InputMaybe<Float8_Comparison_Exp>;
  zScoreDeepSleep?: InputMaybe<Float8_Comparison_Exp>;
  zScoreHRV?: InputMaybe<Float8_Comparison_Exp>;
  zScoreRHR?: InputMaybe<Float8_Comparison_Exp>;
  zScoreSleepDuration?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserReadiness" */
export type UserReadiness_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserReadiness_pkey'
  /** unique or primary key constraint on columns "userId", "dayString", "is_philips" */
  | 'UserReadiness_userId_dayString_is_philips_key';

/** input type for incrementing numeric columns in table "UserReadiness" */
export type UserReadiness_Inc_Input = {
  alter_zone_max?: InputMaybe<Scalars['Int']['input']>;
  alter_zone_min?: InputMaybe<Scalars['Int']['input']>;
  avg_rhr?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_start_30_day?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_stop_30_day?: InputMaybe<Scalars['float8']['input']>;
  awakeTime?: InputMaybe<Scalars['float8']['input']>;
  circadianConsistency?: InputMaybe<Scalars['Int']['input']>;
  combinedSleeps?: InputMaybe<Scalars['Int']['input']>;
  consistency_onset?: InputMaybe<Scalars['Int']['input']>;
  consistency_wakeup?: InputMaybe<Scalars['Int']['input']>;
  deepSleep?: InputMaybe<Scalars['float8']['input']>;
  efficiency?: InputMaybe<Scalars['float8']['input']>;
  hrv?: InputMaybe<Scalars['float8']['input']>;
  interruptionMins?: InputMaybe<Scalars['Int']['input']>;
  latencyMins?: InputMaybe<Scalars['Int']['input']>;
  lightSleep?: InputMaybe<Scalars['float8']['input']>;
  mhr?: InputMaybe<Scalars['Int']['input']>;
  min_rhr?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  overrideScore?: InputMaybe<Scalars['float8']['input']>;
  readinessScore?: InputMaybe<Scalars['float8']['input']>;
  remSleep?: InputMaybe<Scalars['float8']['input']>;
  rhr?: InputMaybe<Scalars['float8']['input']>;
  rhr_month?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEndEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleepStartEpoch?: InputMaybe<Scalars['Int']['input']>;
  wakingHrv?: InputMaybe<Scalars['float8']['input']>;
  zScoreDeepSleep?: InputMaybe<Scalars['float8']['input']>;
  zScoreHRV?: InputMaybe<Scalars['float8']['input']>;
  zScoreRHR?: InputMaybe<Scalars['float8']['input']>;
  zScoreSleepDuration?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "UserReadiness" */
export type UserReadiness_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  alter_zone_max?: InputMaybe<Scalars['Int']['input']>;
  alter_zone_min?: InputMaybe<Scalars['Int']['input']>;
  avg_rhr?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_start_30_day?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_stop_30_day?: InputMaybe<Scalars['float8']['input']>;
  awakeTime?: InputMaybe<Scalars['float8']['input']>;
  circadianConsistency?: InputMaybe<Scalars['Int']['input']>;
  combinedSleeps?: InputMaybe<Scalars['Int']['input']>;
  consistency_onset?: InputMaybe<Scalars['Int']['input']>;
  consistency_wakeup?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  dayString?: InputMaybe<Scalars['String']['input']>;
  deepSleep?: InputMaybe<Scalars['float8']['input']>;
  efficiency?: InputMaybe<Scalars['float8']['input']>;
  hrv?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interruptionMins?: InputMaybe<Scalars['Int']['input']>;
  is_full_override?: InputMaybe<Scalars['Boolean']['input']>;
  is_philips?: InputMaybe<Scalars['Boolean']['input']>;
  latencyMins?: InputMaybe<Scalars['Int']['input']>;
  lightSleep?: InputMaybe<Scalars['float8']['input']>;
  mhr?: InputMaybe<Scalars['Int']['input']>;
  min_rhr?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  overrideScore?: InputMaybe<Scalars['float8']['input']>;
  override_id?: InputMaybe<Scalars['String']['input']>;
  readinessScore?: InputMaybe<Scalars['float8']['input']>;
  remSleep?: InputMaybe<Scalars['float8']['input']>;
  rhr?: InputMaybe<Scalars['float8']['input']>;
  rhr_month?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEndEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleepStartEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleep_stages?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  sleep_timezone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  wakingHrv?: InputMaybe<Scalars['float8']['input']>;
  zScoreDeepSleep?: InputMaybe<Scalars['float8']['input']>;
  zScoreHRV?: InputMaybe<Scalars['float8']['input']>;
  zScoreRHR?: InputMaybe<Scalars['float8']['input']>;
  zScoreSleepDuration?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type UserReadiness_Max_Fields = {
  __typename?: 'UserReadiness_max_fields';
  alter_zone_max?: Maybe<Scalars['Int']['output']>;
  alter_zone_min?: Maybe<Scalars['Int']['output']>;
  avg_rhr?: Maybe<Scalars['float8']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['float8']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['float8']['output']>;
  awakeTime?: Maybe<Scalars['float8']['output']>;
  circadianConsistency?: Maybe<Scalars['Int']['output']>;
  combinedSleeps?: Maybe<Scalars['Int']['output']>;
  consistency_onset?: Maybe<Scalars['Int']['output']>;
  consistency_wakeup?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  dayString?: Maybe<Scalars['String']['output']>;
  deepSleep?: Maybe<Scalars['float8']['output']>;
  efficiency?: Maybe<Scalars['float8']['output']>;
  hrv?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interruptionMins?: Maybe<Scalars['Int']['output']>;
  latencyMins?: Maybe<Scalars['Int']['output']>;
  lightSleep?: Maybe<Scalars['float8']['output']>;
  mhr?: Maybe<Scalars['Int']['output']>;
  min_rhr?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  overrideScore?: Maybe<Scalars['float8']['output']>;
  override_id?: Maybe<Scalars['String']['output']>;
  readinessScore?: Maybe<Scalars['float8']['output']>;
  remSleep?: Maybe<Scalars['float8']['output']>;
  rhr?: Maybe<Scalars['float8']['output']>;
  rhr_month?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Int']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Int']['output']>;
  sleep_stages?: Maybe<Array<Scalars['jsonb']['output']>>;
  sleep_timezone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wakingHrv?: Maybe<Scalars['float8']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['float8']['output']>;
  zScoreHRV?: Maybe<Scalars['float8']['output']>;
  zScoreRHR?: Maybe<Scalars['float8']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "UserReadiness" */
export type UserReadiness_Max_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dayString?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  override_id?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  sleep_stages?: InputMaybe<Order_By>;
  sleep_timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserReadiness_Min_Fields = {
  __typename?: 'UserReadiness_min_fields';
  alter_zone_max?: Maybe<Scalars['Int']['output']>;
  alter_zone_min?: Maybe<Scalars['Int']['output']>;
  avg_rhr?: Maybe<Scalars['float8']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['float8']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['float8']['output']>;
  awakeTime?: Maybe<Scalars['float8']['output']>;
  circadianConsistency?: Maybe<Scalars['Int']['output']>;
  combinedSleeps?: Maybe<Scalars['Int']['output']>;
  consistency_onset?: Maybe<Scalars['Int']['output']>;
  consistency_wakeup?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  dayString?: Maybe<Scalars['String']['output']>;
  deepSleep?: Maybe<Scalars['float8']['output']>;
  efficiency?: Maybe<Scalars['float8']['output']>;
  hrv?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interruptionMins?: Maybe<Scalars['Int']['output']>;
  latencyMins?: Maybe<Scalars['Int']['output']>;
  lightSleep?: Maybe<Scalars['float8']['output']>;
  mhr?: Maybe<Scalars['Int']['output']>;
  min_rhr?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  overrideScore?: Maybe<Scalars['float8']['output']>;
  override_id?: Maybe<Scalars['String']['output']>;
  readinessScore?: Maybe<Scalars['float8']['output']>;
  remSleep?: Maybe<Scalars['float8']['output']>;
  rhr?: Maybe<Scalars['float8']['output']>;
  rhr_month?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Int']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Int']['output']>;
  sleep_stages?: Maybe<Array<Scalars['jsonb']['output']>>;
  sleep_timezone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wakingHrv?: Maybe<Scalars['float8']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['float8']['output']>;
  zScoreHRV?: Maybe<Scalars['float8']['output']>;
  zScoreRHR?: Maybe<Scalars['float8']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "UserReadiness" */
export type UserReadiness_Min_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dayString?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  override_id?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  sleep_stages?: InputMaybe<Order_By>;
  sleep_timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserReadiness" */
export type UserReadiness_Mutation_Response = {
  __typename?: 'UserReadiness_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserReadiness>;
};

/** on_conflict condition type for table "UserReadiness" */
export type UserReadiness_On_Conflict = {
  constraint: UserReadiness_Constraint;
  update_columns?: Array<UserReadiness_Update_Column>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};

/** Ordering options when selecting data from "UserReadiness". */
export type UserReadiness_Order_By = {
  User?: InputMaybe<User_Order_By>;
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dayString?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  is_full_override?: InputMaybe<Order_By>;
  is_philips?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  override_id?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  sleep_stages?: InputMaybe<Order_By>;
  sleep_timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserReadiness */
export type UserReadiness_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserReadiness" */
export type UserReadiness_Select_Column =
  /** column name */
  | 'alter_zone_max'
  /** column name */
  | 'alter_zone_min'
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'circadianConsistency'
  /** column name */
  | 'combinedSleeps'
  /** column name */
  | 'consistency_onset'
  /** column name */
  | 'consistency_wakeup'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'dayString'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'id'
  /** column name */
  | 'interruptionMins'
  /** column name */
  | 'is_full_override'
  /** column name */
  | 'is_philips'
  /** column name */
  | 'latencyMins'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'mhr'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'override_id'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'rhr_month'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEndEpoch'
  /** column name */
  | 'sleepStartEpoch'
  /** column name */
  | 'sleep_stages'
  /** column name */
  | 'sleep_timezone'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_avg_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'is_full_override'
  /** column name */
  | 'is_philips';

/** select "UserReadiness_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'is_full_override'
  /** column name */
  | 'is_philips';

/** select "UserReadiness_aggregate_bool_exp_corr_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_max_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_min_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_sum_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** select "UserReadiness_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserReadiness" */
export type UserReadiness_Select_Column_UserReadiness_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

/** input type for updating data in table "UserReadiness" */
export type UserReadiness_Set_Input = {
  alter_zone_max?: InputMaybe<Scalars['Int']['input']>;
  alter_zone_min?: InputMaybe<Scalars['Int']['input']>;
  avg_rhr?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_start_30_day?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_stop_30_day?: InputMaybe<Scalars['float8']['input']>;
  awakeTime?: InputMaybe<Scalars['float8']['input']>;
  circadianConsistency?: InputMaybe<Scalars['Int']['input']>;
  combinedSleeps?: InputMaybe<Scalars['Int']['input']>;
  consistency_onset?: InputMaybe<Scalars['Int']['input']>;
  consistency_wakeup?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  dayString?: InputMaybe<Scalars['String']['input']>;
  deepSleep?: InputMaybe<Scalars['float8']['input']>;
  efficiency?: InputMaybe<Scalars['float8']['input']>;
  hrv?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interruptionMins?: InputMaybe<Scalars['Int']['input']>;
  is_full_override?: InputMaybe<Scalars['Boolean']['input']>;
  is_philips?: InputMaybe<Scalars['Boolean']['input']>;
  latencyMins?: InputMaybe<Scalars['Int']['input']>;
  lightSleep?: InputMaybe<Scalars['float8']['input']>;
  mhr?: InputMaybe<Scalars['Int']['input']>;
  min_rhr?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  overrideScore?: InputMaybe<Scalars['float8']['input']>;
  override_id?: InputMaybe<Scalars['String']['input']>;
  readinessScore?: InputMaybe<Scalars['float8']['input']>;
  remSleep?: InputMaybe<Scalars['float8']['input']>;
  rhr?: InputMaybe<Scalars['float8']['input']>;
  rhr_month?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEndEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleepStartEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleep_stages?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  sleep_timezone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  wakingHrv?: InputMaybe<Scalars['float8']['input']>;
  zScoreDeepSleep?: InputMaybe<Scalars['float8']['input']>;
  zScoreHRV?: InputMaybe<Scalars['float8']['input']>;
  zScoreRHR?: InputMaybe<Scalars['float8']['input']>;
  zScoreSleepDuration?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type UserReadiness_Stddev_Fields = {
  __typename?: 'UserReadiness_stddev_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserReadiness" */
export type UserReadiness_Stddev_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserReadiness_Stddev_Pop_Fields = {
  __typename?: 'UserReadiness_stddev_pop_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserReadiness" */
export type UserReadiness_Stddev_Pop_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserReadiness_Stddev_Samp_Fields = {
  __typename?: 'UserReadiness_stddev_samp_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserReadiness" */
export type UserReadiness_Stddev_Samp_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserReadiness" */
export type UserReadiness_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserReadiness_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserReadiness_Stream_Cursor_Value_Input = {
  alter_zone_max?: InputMaybe<Scalars['Int']['input']>;
  alter_zone_min?: InputMaybe<Scalars['Int']['input']>;
  avg_rhr?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_start_30_day?: InputMaybe<Scalars['float8']['input']>;
  avg_sleep_stop_30_day?: InputMaybe<Scalars['float8']['input']>;
  awakeTime?: InputMaybe<Scalars['float8']['input']>;
  circadianConsistency?: InputMaybe<Scalars['Int']['input']>;
  combinedSleeps?: InputMaybe<Scalars['Int']['input']>;
  consistency_onset?: InputMaybe<Scalars['Int']['input']>;
  consistency_wakeup?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  dayString?: InputMaybe<Scalars['String']['input']>;
  deepSleep?: InputMaybe<Scalars['float8']['input']>;
  efficiency?: InputMaybe<Scalars['float8']['input']>;
  hrv?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interruptionMins?: InputMaybe<Scalars['Int']['input']>;
  is_full_override?: InputMaybe<Scalars['Boolean']['input']>;
  is_philips?: InputMaybe<Scalars['Boolean']['input']>;
  latencyMins?: InputMaybe<Scalars['Int']['input']>;
  lightSleep?: InputMaybe<Scalars['float8']['input']>;
  mhr?: InputMaybe<Scalars['Int']['input']>;
  min_rhr?: InputMaybe<Scalars['float8']['input']>;
  netSleepScore?: InputMaybe<Scalars['float8']['input']>;
  overrideScore?: InputMaybe<Scalars['float8']['input']>;
  override_id?: InputMaybe<Scalars['String']['input']>;
  readinessScore?: InputMaybe<Scalars['float8']['input']>;
  remSleep?: InputMaybe<Scalars['float8']['input']>;
  rhr?: InputMaybe<Scalars['float8']['input']>;
  rhr_month?: InputMaybe<Scalars['Int']['input']>;
  sleepDuration?: InputMaybe<Scalars['float8']['input']>;
  sleepEndEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleepStartEpoch?: InputMaybe<Scalars['Int']['input']>;
  sleep_stages?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  sleep_timezone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  wakingHrv?: InputMaybe<Scalars['float8']['input']>;
  zScoreDeepSleep?: InputMaybe<Scalars['float8']['input']>;
  zScoreHRV?: InputMaybe<Scalars['float8']['input']>;
  zScoreRHR?: InputMaybe<Scalars['float8']['input']>;
  zScoreSleepDuration?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type UserReadiness_Sum_Fields = {
  __typename?: 'UserReadiness_sum_fields';
  alter_zone_max?: Maybe<Scalars['Int']['output']>;
  alter_zone_min?: Maybe<Scalars['Int']['output']>;
  avg_rhr?: Maybe<Scalars['float8']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['float8']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['float8']['output']>;
  awakeTime?: Maybe<Scalars['float8']['output']>;
  circadianConsistency?: Maybe<Scalars['Int']['output']>;
  combinedSleeps?: Maybe<Scalars['Int']['output']>;
  consistency_onset?: Maybe<Scalars['Int']['output']>;
  consistency_wakeup?: Maybe<Scalars['Int']['output']>;
  deepSleep?: Maybe<Scalars['float8']['output']>;
  efficiency?: Maybe<Scalars['float8']['output']>;
  hrv?: Maybe<Scalars['float8']['output']>;
  interruptionMins?: Maybe<Scalars['Int']['output']>;
  latencyMins?: Maybe<Scalars['Int']['output']>;
  lightSleep?: Maybe<Scalars['float8']['output']>;
  mhr?: Maybe<Scalars['Int']['output']>;
  min_rhr?: Maybe<Scalars['float8']['output']>;
  netSleepScore?: Maybe<Scalars['float8']['output']>;
  overrideScore?: Maybe<Scalars['float8']['output']>;
  readinessScore?: Maybe<Scalars['float8']['output']>;
  remSleep?: Maybe<Scalars['float8']['output']>;
  rhr?: Maybe<Scalars['float8']['output']>;
  rhr_month?: Maybe<Scalars['Int']['output']>;
  sleepDuration?: Maybe<Scalars['float8']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Int']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Int']['output']>;
  wakingHrv?: Maybe<Scalars['float8']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['float8']['output']>;
  zScoreHRV?: Maybe<Scalars['float8']['output']>;
  zScoreRHR?: Maybe<Scalars['float8']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "UserReadiness" */
export type UserReadiness_Sum_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** update columns of table "UserReadiness" */
export type UserReadiness_Update_Column =
  /** column name */
  | 'alter_zone_max'
  /** column name */
  | 'alter_zone_min'
  /** column name */
  | 'avg_rhr'
  /** column name */
  | 'avg_sleep_start_30_day'
  /** column name */
  | 'avg_sleep_stop_30_day'
  /** column name */
  | 'awakeTime'
  /** column name */
  | 'circadianConsistency'
  /** column name */
  | 'combinedSleeps'
  /** column name */
  | 'consistency_onset'
  /** column name */
  | 'consistency_wakeup'
  /** column name */
  | 'created_at'
  /** column name */
  | 'date'
  /** column name */
  | 'dayString'
  /** column name */
  | 'deepSleep'
  /** column name */
  | 'efficiency'
  /** column name */
  | 'hrv'
  /** column name */
  | 'id'
  /** column name */
  | 'interruptionMins'
  /** column name */
  | 'is_full_override'
  /** column name */
  | 'is_philips'
  /** column name */
  | 'latencyMins'
  /** column name */
  | 'lightSleep'
  /** column name */
  | 'mhr'
  /** column name */
  | 'min_rhr'
  /** column name */
  | 'netSleepScore'
  /** column name */
  | 'overrideScore'
  /** column name */
  | 'override_id'
  /** column name */
  | 'readinessScore'
  /** column name */
  | 'remSleep'
  /** column name */
  | 'rhr'
  /** column name */
  | 'rhr_month'
  /** column name */
  | 'sleepDuration'
  /** column name */
  | 'sleepEndEpoch'
  /** column name */
  | 'sleepStartEpoch'
  /** column name */
  | 'sleep_stages'
  /** column name */
  | 'sleep_timezone'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'wakingHrv'
  /** column name */
  | 'zScoreDeepSleep'
  /** column name */
  | 'zScoreHRV'
  /** column name */
  | 'zScoreRHR'
  /** column name */
  | 'zScoreSleepDuration';

export type UserReadiness_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserReadiness_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserReadiness_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserReadiness_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserReadiness_Var_Pop_Fields = {
  __typename?: 'UserReadiness_var_pop_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserReadiness" */
export type UserReadiness_Var_Pop_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserReadiness_Var_Samp_Fields = {
  __typename?: 'UserReadiness_var_samp_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserReadiness" */
export type UserReadiness_Var_Samp_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserReadiness_Variance_Fields = {
  __typename?: 'UserReadiness_variance_fields';
  alter_zone_max?: Maybe<Scalars['Float']['output']>;
  alter_zone_min?: Maybe<Scalars['Float']['output']>;
  avg_rhr?: Maybe<Scalars['Float']['output']>;
  avg_sleep_start_30_day?: Maybe<Scalars['Float']['output']>;
  avg_sleep_stop_30_day?: Maybe<Scalars['Float']['output']>;
  awakeTime?: Maybe<Scalars['Float']['output']>;
  circadianConsistency?: Maybe<Scalars['Float']['output']>;
  combinedSleeps?: Maybe<Scalars['Float']['output']>;
  consistency_onset?: Maybe<Scalars['Float']['output']>;
  consistency_wakeup?: Maybe<Scalars['Float']['output']>;
  deepSleep?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  hrv?: Maybe<Scalars['Float']['output']>;
  interruptionMins?: Maybe<Scalars['Float']['output']>;
  latencyMins?: Maybe<Scalars['Float']['output']>;
  lightSleep?: Maybe<Scalars['Float']['output']>;
  mhr?: Maybe<Scalars['Float']['output']>;
  min_rhr?: Maybe<Scalars['Float']['output']>;
  netSleepScore?: Maybe<Scalars['Float']['output']>;
  overrideScore?: Maybe<Scalars['Float']['output']>;
  readinessScore?: Maybe<Scalars['Float']['output']>;
  remSleep?: Maybe<Scalars['Float']['output']>;
  rhr?: Maybe<Scalars['Float']['output']>;
  rhr_month?: Maybe<Scalars['Float']['output']>;
  sleepDuration?: Maybe<Scalars['Float']['output']>;
  sleepEndEpoch?: Maybe<Scalars['Float']['output']>;
  sleepStartEpoch?: Maybe<Scalars['Float']['output']>;
  wakingHrv?: Maybe<Scalars['Float']['output']>;
  zScoreDeepSleep?: Maybe<Scalars['Float']['output']>;
  zScoreHRV?: Maybe<Scalars['Float']['output']>;
  zScoreRHR?: Maybe<Scalars['Float']['output']>;
  zScoreSleepDuration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserReadiness" */
export type UserReadiness_Variance_Order_By = {
  alter_zone_max?: InputMaybe<Order_By>;
  alter_zone_min?: InputMaybe<Order_By>;
  avg_rhr?: InputMaybe<Order_By>;
  avg_sleep_start_30_day?: InputMaybe<Order_By>;
  avg_sleep_stop_30_day?: InputMaybe<Order_By>;
  awakeTime?: InputMaybe<Order_By>;
  circadianConsistency?: InputMaybe<Order_By>;
  combinedSleeps?: InputMaybe<Order_By>;
  consistency_onset?: InputMaybe<Order_By>;
  consistency_wakeup?: InputMaybe<Order_By>;
  deepSleep?: InputMaybe<Order_By>;
  efficiency?: InputMaybe<Order_By>;
  hrv?: InputMaybe<Order_By>;
  interruptionMins?: InputMaybe<Order_By>;
  latencyMins?: InputMaybe<Order_By>;
  lightSleep?: InputMaybe<Order_By>;
  mhr?: InputMaybe<Order_By>;
  min_rhr?: InputMaybe<Order_By>;
  netSleepScore?: InputMaybe<Order_By>;
  overrideScore?: InputMaybe<Order_By>;
  readinessScore?: InputMaybe<Order_By>;
  remSleep?: InputMaybe<Order_By>;
  rhr?: InputMaybe<Order_By>;
  rhr_month?: InputMaybe<Order_By>;
  sleepDuration?: InputMaybe<Order_By>;
  sleepEndEpoch?: InputMaybe<Order_By>;
  sleepStartEpoch?: InputMaybe<Order_By>;
  wakingHrv?: InputMaybe<Order_By>;
  zScoreDeepSleep?: InputMaybe<Order_By>;
  zScoreHRV?: InputMaybe<Order_By>;
  zScoreRHR?: InputMaybe<Order_By>;
  zScoreSleepDuration?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserReimbursmentHistory" */
export type UserReimbursmentHistory = {
  __typename?: 'UserReimbursmentHistory';
  /** An object relationship */
  User: User;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  planEndDate?: Maybe<Scalars['timestamp']['output']>;
  planStartDate: Scalars['timestamp']['output'];
  reimbursed: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Aggregate = {
  __typename?: 'UserReimbursmentHistory_aggregate';
  aggregate?: Maybe<UserReimbursmentHistory_Aggregate_Fields>;
  nodes: Array<UserReimbursmentHistory>;
};

export type UserReimbursmentHistory_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<UserReimbursmentHistory_Aggregate_Bool_Exp_Count>;
};

export type UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserReimbursmentHistory_Select_Column_UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserReimbursmentHistory_Select_Column_UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserReimbursmentHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Aggregate_Fields = {
  __typename?: 'UserReimbursmentHistory_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserReimbursmentHistory_Max_Fields>;
  min?: Maybe<UserReimbursmentHistory_Min_Fields>;
};


/** aggregate fields of "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserReimbursmentHistory_Max_Order_By>;
  min?: InputMaybe<UserReimbursmentHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Arr_Rel_Insert_Input = {
  data: Array<UserReimbursmentHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserReimbursmentHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserReimbursmentHistory". All fields are combined with a logical 'AND'. */
export type UserReimbursmentHistory_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserReimbursmentHistory_Bool_Exp>>;
  _not?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
  _or?: InputMaybe<Array<UserReimbursmentHistory_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  planEndDate?: InputMaybe<Timestamp_Comparison_Exp>;
  planStartDate?: InputMaybe<Timestamp_Comparison_Exp>;
  reimbursed?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserReimbursmentHistory_pkey';

/** input type for inserting data into table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  planEndDate?: InputMaybe<Scalars['timestamp']['input']>;
  planStartDate?: InputMaybe<Scalars['timestamp']['input']>;
  reimbursed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserReimbursmentHistory_Max_Fields = {
  __typename?: 'UserReimbursmentHistory_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  planEndDate?: Maybe<Scalars['timestamp']['output']>;
  planStartDate?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  planEndDate?: InputMaybe<Order_By>;
  planStartDate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserReimbursmentHistory_Min_Fields = {
  __typename?: 'UserReimbursmentHistory_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  planEndDate?: Maybe<Scalars['timestamp']['output']>;
  planStartDate?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  planEndDate?: InputMaybe<Order_By>;
  planStartDate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Mutation_Response = {
  __typename?: 'UserReimbursmentHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserReimbursmentHistory>;
};

/** on_conflict condition type for table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_On_Conflict = {
  constraint: UserReimbursmentHistory_Constraint;
  update_columns?: Array<UserReimbursmentHistory_Update_Column>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "UserReimbursmentHistory". */
export type UserReimbursmentHistory_Order_By = {
  User?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  planEndDate?: InputMaybe<Order_By>;
  planStartDate?: InputMaybe<Order_By>;
  reimbursed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserReimbursmentHistory */
export type UserReimbursmentHistory_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'planEndDate'
  /** column name */
  | 'planStartDate'
  /** column name */
  | 'reimbursed'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** select "UserReimbursmentHistory_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Select_Column_UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'reimbursed';

/** select "UserReimbursmentHistory_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Select_Column_UserReimbursmentHistory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'reimbursed';

/** input type for updating data in table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  planEndDate?: InputMaybe<Scalars['timestamp']['input']>;
  planStartDate?: InputMaybe<Scalars['timestamp']['input']>;
  reimbursed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserReimbursmentHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserReimbursmentHistory_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  planEndDate?: InputMaybe<Scalars['timestamp']['input']>;
  planStartDate?: InputMaybe<Scalars['timestamp']['input']>;
  reimbursed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserReimbursmentHistory" */
export type UserReimbursmentHistory_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'planEndDate'
  /** column name */
  | 'planStartDate'
  /** column name */
  | 'reimbursed'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type UserReimbursmentHistory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserReimbursmentHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserReimbursmentHistory_Bool_Exp;
};

/** columns and relationships of "UserRep" */
export type UserRep = {
  __typename?: 'UserRep';
  /** An object relationship */
  User?: Maybe<User>;
  /** An object relationship */
  UserWorkout?: Maybe<UserWorkout>;
  asensei_raw?: Maybe<Scalars['jsonb']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  negative_nuances: Scalars['Int']['output'];
  outcome?: Maybe<Scalars['Int']['output']>;
  positive_nuances: Scalars['Int']['output'];
  rep: Scalars['Int']['output'];
  updated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  userSetByUserSetId: UserSet;
  userWorkoutId?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_set_id: Scalars['String']['output'];
};


/** columns and relationships of "UserRep" */
export type UserRepAsensei_RawArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "UserRep" */
export type UserRep_Aggregate = {
  __typename?: 'UserRep_aggregate';
  aggregate?: Maybe<UserRep_Aggregate_Fields>;
  nodes: Array<UserRep>;
};

export type UserRep_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserRep_Aggregate_Bool_Exp_Count>;
};

export type UserRep_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserRep_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserRep_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserRep" */
export type UserRep_Aggregate_Fields = {
  __typename?: 'UserRep_aggregate_fields';
  avg?: Maybe<UserRep_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserRep_Max_Fields>;
  min?: Maybe<UserRep_Min_Fields>;
  stddev?: Maybe<UserRep_Stddev_Fields>;
  stddev_pop?: Maybe<UserRep_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserRep_Stddev_Samp_Fields>;
  sum?: Maybe<UserRep_Sum_Fields>;
  var_pop?: Maybe<UserRep_Var_Pop_Fields>;
  var_samp?: Maybe<UserRep_Var_Samp_Fields>;
  variance?: Maybe<UserRep_Variance_Fields>;
};


/** aggregate fields of "UserRep" */
export type UserRep_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserRep_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserRep" */
export type UserRep_Aggregate_Order_By = {
  avg?: InputMaybe<UserRep_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserRep_Max_Order_By>;
  min?: InputMaybe<UserRep_Min_Order_By>;
  stddev?: InputMaybe<UserRep_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserRep_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserRep_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserRep_Sum_Order_By>;
  var_pop?: InputMaybe<UserRep_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserRep_Var_Samp_Order_By>;
  variance?: InputMaybe<UserRep_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserRep_Append_Input = {
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "UserRep" */
export type UserRep_Arr_Rel_Insert_Input = {
  data: Array<UserRep_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserRep_On_Conflict>;
};

/** aggregate avg on columns */
export type UserRep_Avg_Fields = {
  __typename?: 'UserRep_avg_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserRep" */
export type UserRep_Avg_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserRep". All fields are combined with a logical 'AND'. */
export type UserRep_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  UserWorkout?: InputMaybe<UserWorkout_Bool_Exp>;
  _and?: InputMaybe<Array<UserRep_Bool_Exp>>;
  _not?: InputMaybe<UserRep_Bool_Exp>;
  _or?: InputMaybe<Array<UserRep_Bool_Exp>>;
  asensei_raw?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  negative_nuances?: InputMaybe<Int_Comparison_Exp>;
  outcome?: InputMaybe<Int_Comparison_Exp>;
  positive_nuances?: InputMaybe<Int_Comparison_Exp>;
  rep?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userSetByUserSetId?: InputMaybe<UserSet_Bool_Exp>;
  userWorkoutId?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_set_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserRep" */
export type UserRep_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserRep_pkey'
  /** unique or primary key constraint on columns "rep", "user_set_id" */
  | 'UserRep_rep_user_set_id_key';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserRep_Delete_At_Path_Input = {
  asensei_raw?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserRep_Delete_Elem_Input = {
  asensei_raw?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserRep_Delete_Key_Input = {
  asensei_raw?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "UserRep" */
export type UserRep_Inc_Input = {
  negative_nuances?: InputMaybe<Scalars['Int']['input']>;
  outcome?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances?: InputMaybe<Scalars['Int']['input']>;
  rep?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserRep" */
export type UserRep_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserWorkout?: InputMaybe<UserWorkout_Obj_Rel_Insert_Input>;
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  negative_nuances?: InputMaybe<Scalars['Int']['input']>;
  outcome?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances?: InputMaybe<Scalars['Int']['input']>;
  rep?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userSetByUserSetId?: InputMaybe<UserSet_Obj_Rel_Insert_Input>;
  userWorkoutId?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_set_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserRep_Max_Fields = {
  __typename?: 'UserRep_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  negative_nuances?: Maybe<Scalars['Int']['output']>;
  outcome?: Maybe<Scalars['Int']['output']>;
  positive_nuances?: Maybe<Scalars['Int']['output']>;
  rep?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userWorkoutId?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_set_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserRep" */
export type UserRep_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userWorkoutId?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_set_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserRep_Min_Fields = {
  __typename?: 'UserRep_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  negative_nuances?: Maybe<Scalars['Int']['output']>;
  outcome?: Maybe<Scalars['Int']['output']>;
  positive_nuances?: Maybe<Scalars['Int']['output']>;
  rep?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userWorkoutId?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_set_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserRep" */
export type UserRep_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userWorkoutId?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_set_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserRep" */
export type UserRep_Mutation_Response = {
  __typename?: 'UserRep_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserRep>;
};

/** on_conflict condition type for table "UserRep" */
export type UserRep_On_Conflict = {
  constraint: UserRep_Constraint;
  update_columns?: Array<UserRep_Update_Column>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};

/** Ordering options when selecting data from "UserRep". */
export type UserRep_Order_By = {
  User?: InputMaybe<User_Order_By>;
  UserWorkout?: InputMaybe<UserWorkout_Order_By>;
  asensei_raw?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userSetByUserSetId?: InputMaybe<UserSet_Order_By>;
  userWorkoutId?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_set_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserRep */
export type UserRep_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserRep_Prepend_Input = {
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "UserRep" */
export type UserRep_Select_Column =
  /** column name */
  | 'asensei_raw'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'negative_nuances'
  /** column name */
  | 'outcome'
  /** column name */
  | 'positive_nuances'
  /** column name */
  | 'rep'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userWorkoutId'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_set_id';

/** input type for updating data in table "UserRep" */
export type UserRep_Set_Input = {
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  negative_nuances?: InputMaybe<Scalars['Int']['input']>;
  outcome?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances?: InputMaybe<Scalars['Int']['input']>;
  rep?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userWorkoutId?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_set_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserRep_Stddev_Fields = {
  __typename?: 'UserRep_stddev_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserRep" */
export type UserRep_Stddev_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserRep_Stddev_Pop_Fields = {
  __typename?: 'UserRep_stddev_pop_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserRep" */
export type UserRep_Stddev_Pop_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserRep_Stddev_Samp_Fields = {
  __typename?: 'UserRep_stddev_samp_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserRep" */
export type UserRep_Stddev_Samp_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserRep" */
export type UserRep_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserRep_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserRep_Stream_Cursor_Value_Input = {
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  negative_nuances?: InputMaybe<Scalars['Int']['input']>;
  outcome?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances?: InputMaybe<Scalars['Int']['input']>;
  rep?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userWorkoutId?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_set_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserRep_Sum_Fields = {
  __typename?: 'UserRep_sum_fields';
  negative_nuances?: Maybe<Scalars['Int']['output']>;
  outcome?: Maybe<Scalars['Int']['output']>;
  positive_nuances?: Maybe<Scalars['Int']['output']>;
  rep?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserRep" */
export type UserRep_Sum_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** update columns of table "UserRep" */
export type UserRep_Update_Column =
  /** column name */
  | 'asensei_raw'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'negative_nuances'
  /** column name */
  | 'outcome'
  /** column name */
  | 'positive_nuances'
  /** column name */
  | 'rep'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userWorkoutId'
  /** column name */
  | 'user_id'
  /** column name */
  | 'user_set_id';

export type UserRep_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserRep_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserRep_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserRep_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserRep_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserRep_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserRep_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserRep_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserRep_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserRep_Var_Pop_Fields = {
  __typename?: 'UserRep_var_pop_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserRep" */
export type UserRep_Var_Pop_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserRep_Var_Samp_Fields = {
  __typename?: 'UserRep_var_samp_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserRep" */
export type UserRep_Var_Samp_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserRep_Variance_Fields = {
  __typename?: 'UserRep_variance_fields';
  negative_nuances?: Maybe<Scalars['Float']['output']>;
  outcome?: Maybe<Scalars['Float']['output']>;
  positive_nuances?: Maybe<Scalars['Float']['output']>;
  rep?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserRep" */
export type UserRep_Variance_Order_By = {
  negative_nuances?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  positive_nuances?: InputMaybe<Order_By>;
  rep?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserSet" */
export type UserSet = {
  __typename?: 'UserSet';
  /** An object relationship */
  Mastery?: Maybe<Mastery>;
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserAssessment?: Maybe<UserAssessment>;
  /** An object relationship */
  UserWorkout?: Maybe<UserWorkout>;
  asenseiFeedback?: Maybe<Scalars['String']['output']>;
  asensei_exercise_id?: Maybe<Scalars['String']['output']>;
  asensei_exercise_version?: Maybe<Scalars['String']['output']>;
  asensei_raw?: Maybe<Scalars['jsonb']['output']>;
  category?: Maybe<Scalars['WorkoutCategory']['output']>;
  completedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt: Scalars['timestamp']['output'];
  exercise_name?: Maybe<Scalars['String']['output']>;
  goal_reps: Scalars['Int']['output'];
  hold_goal_duration_sec: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  json_asensei_summary?: Maybe<Scalars['jsonb']['output']>;
  mastery_level?: Maybe<Scalars['Int']['output']>;
  negative_nuances_possible: Scalars['Int']['output'];
  num_good_reps: Scalars['Int']['output'];
  num_perfect_reps: Scalars['Int']['output'];
  positive_nuances_possible: Scalars['Int']['output'];
  reps_completed: Scalars['Int']['output'];
  section?: Maybe<Scalars['Int']['output']>;
  set?: Maybe<Scalars['Int']['output']>;
  set_quality?: Maybe<Scalars['SetQuality']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Int']['output']>;
  total_reps: Scalars['Int']['output'];
  updatedAt: Scalars['timestamp']['output'];
  userAssessmentId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  /** An array relationship */
  userRepsByUserSetId: Array<UserRep>;
  /** An aggregate relationship */
  userRepsByUserSetId_aggregate: UserRep_Aggregate;
  userWorkoutId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "UserSet" */
export type UserSetAsensei_RawArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "UserSet" */
export type UserSetJson_Asensei_SummaryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "UserSet" */
export type UserSetUserRepsByUserSetIdArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


/** columns and relationships of "UserSet" */
export type UserSetUserRepsByUserSetId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};

/** aggregated selection of "UserSet" */
export type UserSet_Aggregate = {
  __typename?: 'UserSet_aggregate';
  aggregate?: Maybe<UserSet_Aggregate_Fields>;
  nodes: Array<UserSet>;
};

export type UserSet_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserSet_Aggregate_Bool_Exp_Count>;
};

export type UserSet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserSet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserSet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserSet" */
export type UserSet_Aggregate_Fields = {
  __typename?: 'UserSet_aggregate_fields';
  avg?: Maybe<UserSet_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserSet_Max_Fields>;
  min?: Maybe<UserSet_Min_Fields>;
  stddev?: Maybe<UserSet_Stddev_Fields>;
  stddev_pop?: Maybe<UserSet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserSet_Stddev_Samp_Fields>;
  sum?: Maybe<UserSet_Sum_Fields>;
  var_pop?: Maybe<UserSet_Var_Pop_Fields>;
  var_samp?: Maybe<UserSet_Var_Samp_Fields>;
  variance?: Maybe<UserSet_Variance_Fields>;
};


/** aggregate fields of "UserSet" */
export type UserSet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserSet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserSet" */
export type UserSet_Aggregate_Order_By = {
  avg?: InputMaybe<UserSet_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserSet_Max_Order_By>;
  min?: InputMaybe<UserSet_Min_Order_By>;
  stddev?: InputMaybe<UserSet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserSet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserSet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserSet_Sum_Order_By>;
  var_pop?: InputMaybe<UserSet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserSet_Var_Samp_Order_By>;
  variance?: InputMaybe<UserSet_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserSet_Append_Input = {
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "UserSet" */
export type UserSet_Arr_Rel_Insert_Input = {
  data: Array<UserSet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserSet_On_Conflict>;
};

/** aggregate avg on columns */
export type UserSet_Avg_Fields = {
  __typename?: 'UserSet_avg_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserSet" */
export type UserSet_Avg_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserSet". All fields are combined with a logical 'AND'. */
export type UserSet_Bool_Exp = {
  Mastery?: InputMaybe<Mastery_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  UserAssessment?: InputMaybe<UserAssessment_Bool_Exp>;
  UserWorkout?: InputMaybe<UserWorkout_Bool_Exp>;
  _and?: InputMaybe<Array<UserSet_Bool_Exp>>;
  _not?: InputMaybe<UserSet_Bool_Exp>;
  _or?: InputMaybe<Array<UserSet_Bool_Exp>>;
  asenseiFeedback?: InputMaybe<String_Comparison_Exp>;
  asensei_exercise_id?: InputMaybe<String_Comparison_Exp>;
  asensei_exercise_version?: InputMaybe<String_Comparison_Exp>;
  asensei_raw?: InputMaybe<Jsonb_Comparison_Exp>;
  category?: InputMaybe<WorkoutCategory_Comparison_Exp>;
  completedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  exercise_name?: InputMaybe<String_Comparison_Exp>;
  goal_reps?: InputMaybe<Int_Comparison_Exp>;
  hold_goal_duration_sec?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  json_asensei_summary?: InputMaybe<Jsonb_Comparison_Exp>;
  mastery_level?: InputMaybe<Int_Comparison_Exp>;
  negative_nuances_possible?: InputMaybe<Int_Comparison_Exp>;
  num_good_reps?: InputMaybe<Int_Comparison_Exp>;
  num_perfect_reps?: InputMaybe<Int_Comparison_Exp>;
  positive_nuances_possible?: InputMaybe<Int_Comparison_Exp>;
  reps_completed?: InputMaybe<Int_Comparison_Exp>;
  section?: InputMaybe<Int_Comparison_Exp>;
  set?: InputMaybe<Int_Comparison_Exp>;
  set_quality?: InputMaybe<SetQuality_Comparison_Exp>;
  time_in_hold_sec?: InputMaybe<Int_Comparison_Exp>;
  total_reps?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userAssessmentId?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  userRepsByUserSetId?: InputMaybe<UserRep_Bool_Exp>;
  userRepsByUserSetId_aggregate?: InputMaybe<UserRep_Aggregate_Bool_Exp>;
  userWorkoutId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserSet" */
export type UserSet_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserSet_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserSet_Delete_At_Path_Input = {
  asensei_raw?: InputMaybe<Array<Scalars['String']['input']>>;
  json_asensei_summary?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserSet_Delete_Elem_Input = {
  asensei_raw?: InputMaybe<Scalars['Int']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserSet_Delete_Key_Input = {
  asensei_raw?: InputMaybe<Scalars['String']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "UserSet" */
export type UserSet_Inc_Input = {
  goal_reps?: InputMaybe<Scalars['Int']['input']>;
  hold_goal_duration_sec?: InputMaybe<Scalars['Int']['input']>;
  mastery_level?: InputMaybe<Scalars['Int']['input']>;
  negative_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  num_good_reps?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_reps?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  reps_completed?: InputMaybe<Scalars['Int']['input']>;
  section?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  time_in_hold_sec?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserSet" */
export type UserSet_Insert_Input = {
  Mastery?: InputMaybe<Mastery_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserAssessment?: InputMaybe<UserAssessment_Obj_Rel_Insert_Input>;
  UserWorkout?: InputMaybe<UserWorkout_Obj_Rel_Insert_Input>;
  asenseiFeedback?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_id?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_version?: InputMaybe<Scalars['String']['input']>;
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  category?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  completedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  exercise_name?: InputMaybe<Scalars['String']['input']>;
  goal_reps?: InputMaybe<Scalars['Int']['input']>;
  hold_goal_duration_sec?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['jsonb']['input']>;
  mastery_level?: InputMaybe<Scalars['Int']['input']>;
  negative_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  num_good_reps?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_reps?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  reps_completed?: InputMaybe<Scalars['Int']['input']>;
  section?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_quality?: InputMaybe<Scalars['SetQuality']['input']>;
  time_in_hold_sec?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userAssessmentId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userRepsByUserSetId?: InputMaybe<UserRep_Arr_Rel_Insert_Input>;
  userWorkoutId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserSet_Max_Fields = {
  __typename?: 'UserSet_max_fields';
  asenseiFeedback?: Maybe<Scalars['String']['output']>;
  asensei_exercise_id?: Maybe<Scalars['String']['output']>;
  asensei_exercise_version?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['WorkoutCategory']['output']>;
  completedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  exercise_name?: Maybe<Scalars['String']['output']>;
  goal_reps?: Maybe<Scalars['Int']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mastery_level?: Maybe<Scalars['Int']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Int']['output']>;
  num_good_reps?: Maybe<Scalars['Int']['output']>;
  num_perfect_reps?: Maybe<Scalars['Int']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Int']['output']>;
  reps_completed?: Maybe<Scalars['Int']['output']>;
  section?: Maybe<Scalars['Int']['output']>;
  set?: Maybe<Scalars['Int']['output']>;
  set_quality?: Maybe<Scalars['SetQuality']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Int']['output']>;
  total_reps?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userAssessmentId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userWorkoutId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserSet" */
export type UserSet_Max_Order_By = {
  asenseiFeedback?: InputMaybe<Order_By>;
  asensei_exercise_id?: InputMaybe<Order_By>;
  asensei_exercise_version?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  exercise_name?: InputMaybe<Order_By>;
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  set_quality?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAssessmentId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userWorkoutId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserSet_Min_Fields = {
  __typename?: 'UserSet_min_fields';
  asenseiFeedback?: Maybe<Scalars['String']['output']>;
  asensei_exercise_id?: Maybe<Scalars['String']['output']>;
  asensei_exercise_version?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['WorkoutCategory']['output']>;
  completedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  exercise_name?: Maybe<Scalars['String']['output']>;
  goal_reps?: Maybe<Scalars['Int']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mastery_level?: Maybe<Scalars['Int']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Int']['output']>;
  num_good_reps?: Maybe<Scalars['Int']['output']>;
  num_perfect_reps?: Maybe<Scalars['Int']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Int']['output']>;
  reps_completed?: Maybe<Scalars['Int']['output']>;
  section?: Maybe<Scalars['Int']['output']>;
  set?: Maybe<Scalars['Int']['output']>;
  set_quality?: Maybe<Scalars['SetQuality']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Int']['output']>;
  total_reps?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userAssessmentId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userWorkoutId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserSet" */
export type UserSet_Min_Order_By = {
  asenseiFeedback?: InputMaybe<Order_By>;
  asensei_exercise_id?: InputMaybe<Order_By>;
  asensei_exercise_version?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  exercise_name?: InputMaybe<Order_By>;
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  set_quality?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAssessmentId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userWorkoutId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserSet" */
export type UserSet_Mutation_Response = {
  __typename?: 'UserSet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSet>;
};

/** input type for inserting object relation for remote table "UserSet" */
export type UserSet_Obj_Rel_Insert_Input = {
  data: UserSet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserSet_On_Conflict>;
};

/** on_conflict condition type for table "UserSet" */
export type UserSet_On_Conflict = {
  constraint: UserSet_Constraint;
  update_columns?: Array<UserSet_Update_Column>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};

/** Ordering options when selecting data from "UserSet". */
export type UserSet_Order_By = {
  Mastery?: InputMaybe<Mastery_Order_By>;
  User?: InputMaybe<User_Order_By>;
  UserAssessment?: InputMaybe<UserAssessment_Order_By>;
  UserWorkout?: InputMaybe<UserWorkout_Order_By>;
  asenseiFeedback?: InputMaybe<Order_By>;
  asensei_exercise_id?: InputMaybe<Order_By>;
  asensei_exercise_version?: InputMaybe<Order_By>;
  asensei_raw?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  exercise_name?: InputMaybe<Order_By>;
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json_asensei_summary?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  set_quality?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAssessmentId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userRepsByUserSetId_aggregate?: InputMaybe<UserRep_Aggregate_Order_By>;
  userWorkoutId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserSet */
export type UserSet_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserSet_Prepend_Input = {
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "UserSet" */
export type UserSet_Select_Column =
  /** column name */
  | 'asenseiFeedback'
  /** column name */
  | 'asensei_exercise_id'
  /** column name */
  | 'asensei_exercise_version'
  /** column name */
  | 'asensei_raw'
  /** column name */
  | 'category'
  /** column name */
  | 'completedAt'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'exercise_name'
  /** column name */
  | 'goal_reps'
  /** column name */
  | 'hold_goal_duration_sec'
  /** column name */
  | 'id'
  /** column name */
  | 'json_asensei_summary'
  /** column name */
  | 'mastery_level'
  /** column name */
  | 'negative_nuances_possible'
  /** column name */
  | 'num_good_reps'
  /** column name */
  | 'num_perfect_reps'
  /** column name */
  | 'positive_nuances_possible'
  /** column name */
  | 'reps_completed'
  /** column name */
  | 'section'
  /** column name */
  | 'set'
  /** column name */
  | 'set_quality'
  /** column name */
  | 'time_in_hold_sec'
  /** column name */
  | 'total_reps'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userAssessmentId'
  /** column name */
  | 'userId'
  /** column name */
  | 'userWorkoutId';

/** input type for updating data in table "UserSet" */
export type UserSet_Set_Input = {
  asenseiFeedback?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_id?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_version?: InputMaybe<Scalars['String']['input']>;
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  category?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  completedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  exercise_name?: InputMaybe<Scalars['String']['input']>;
  goal_reps?: InputMaybe<Scalars['Int']['input']>;
  hold_goal_duration_sec?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['jsonb']['input']>;
  mastery_level?: InputMaybe<Scalars['Int']['input']>;
  negative_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  num_good_reps?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_reps?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  reps_completed?: InputMaybe<Scalars['Int']['input']>;
  section?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_quality?: InputMaybe<Scalars['SetQuality']['input']>;
  time_in_hold_sec?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userAssessmentId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userWorkoutId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserSet_Stddev_Fields = {
  __typename?: 'UserSet_stddev_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserSet" */
export type UserSet_Stddev_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserSet_Stddev_Pop_Fields = {
  __typename?: 'UserSet_stddev_pop_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserSet" */
export type UserSet_Stddev_Pop_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserSet_Stddev_Samp_Fields = {
  __typename?: 'UserSet_stddev_samp_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserSet" */
export type UserSet_Stddev_Samp_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserSet" */
export type UserSet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserSet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserSet_Stream_Cursor_Value_Input = {
  asenseiFeedback?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_id?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_version?: InputMaybe<Scalars['String']['input']>;
  asensei_raw?: InputMaybe<Scalars['jsonb']['input']>;
  category?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  completedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  exercise_name?: InputMaybe<Scalars['String']['input']>;
  goal_reps?: InputMaybe<Scalars['Int']['input']>;
  hold_goal_duration_sec?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['jsonb']['input']>;
  mastery_level?: InputMaybe<Scalars['Int']['input']>;
  negative_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  num_good_reps?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_reps?: InputMaybe<Scalars['Int']['input']>;
  positive_nuances_possible?: InputMaybe<Scalars['Int']['input']>;
  reps_completed?: InputMaybe<Scalars['Int']['input']>;
  section?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_quality?: InputMaybe<Scalars['SetQuality']['input']>;
  time_in_hold_sec?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userAssessmentId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userWorkoutId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserSet_Sum_Fields = {
  __typename?: 'UserSet_sum_fields';
  goal_reps?: Maybe<Scalars['Int']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Int']['output']>;
  mastery_level?: Maybe<Scalars['Int']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Int']['output']>;
  num_good_reps?: Maybe<Scalars['Int']['output']>;
  num_perfect_reps?: Maybe<Scalars['Int']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Int']['output']>;
  reps_completed?: Maybe<Scalars['Int']['output']>;
  section?: Maybe<Scalars['Int']['output']>;
  set?: Maybe<Scalars['Int']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Int']['output']>;
  total_reps?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserSet" */
export type UserSet_Sum_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** update columns of table "UserSet" */
export type UserSet_Update_Column =
  /** column name */
  | 'asenseiFeedback'
  /** column name */
  | 'asensei_exercise_id'
  /** column name */
  | 'asensei_exercise_version'
  /** column name */
  | 'asensei_raw'
  /** column name */
  | 'category'
  /** column name */
  | 'completedAt'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'exercise_name'
  /** column name */
  | 'goal_reps'
  /** column name */
  | 'hold_goal_duration_sec'
  /** column name */
  | 'id'
  /** column name */
  | 'json_asensei_summary'
  /** column name */
  | 'mastery_level'
  /** column name */
  | 'negative_nuances_possible'
  /** column name */
  | 'num_good_reps'
  /** column name */
  | 'num_perfect_reps'
  /** column name */
  | 'positive_nuances_possible'
  /** column name */
  | 'reps_completed'
  /** column name */
  | 'section'
  /** column name */
  | 'set'
  /** column name */
  | 'set_quality'
  /** column name */
  | 'time_in_hold_sec'
  /** column name */
  | 'total_reps'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userAssessmentId'
  /** column name */
  | 'userId'
  /** column name */
  | 'userWorkoutId';

export type UserSet_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserSet_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserSet_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserSet_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserSet_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserSet_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserSet_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSet_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserSet_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserSet_Var_Pop_Fields = {
  __typename?: 'UserSet_var_pop_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserSet" */
export type UserSet_Var_Pop_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserSet_Var_Samp_Fields = {
  __typename?: 'UserSet_var_samp_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserSet" */
export type UserSet_Var_Samp_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserSet_Variance_Fields = {
  __typename?: 'UserSet_variance_fields';
  goal_reps?: Maybe<Scalars['Float']['output']>;
  hold_goal_duration_sec?: Maybe<Scalars['Float']['output']>;
  mastery_level?: Maybe<Scalars['Float']['output']>;
  negative_nuances_possible?: Maybe<Scalars['Float']['output']>;
  num_good_reps?: Maybe<Scalars['Float']['output']>;
  num_perfect_reps?: Maybe<Scalars['Float']['output']>;
  positive_nuances_possible?: Maybe<Scalars['Float']['output']>;
  reps_completed?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['Float']['output']>;
  set?: Maybe<Scalars['Float']['output']>;
  time_in_hold_sec?: Maybe<Scalars['Float']['output']>;
  total_reps?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserSet" */
export type UserSet_Variance_Order_By = {
  goal_reps?: InputMaybe<Order_By>;
  hold_goal_duration_sec?: InputMaybe<Order_By>;
  mastery_level?: InputMaybe<Order_By>;
  negative_nuances_possible?: InputMaybe<Order_By>;
  num_good_reps?: InputMaybe<Order_By>;
  num_perfect_reps?: InputMaybe<Order_By>;
  positive_nuances_possible?: InputMaybe<Order_By>;
  reps_completed?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  set?: InputMaybe<Order_By>;
  time_in_hold_sec?: InputMaybe<Order_By>;
  total_reps?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserState" */
export type UserState = {
  __typename?: 'UserState';
  /** An object relationship */
  User?: Maybe<User>;
  chat_tab_complete: Scalars['Boolean']['output'];
  created_at: Scalars['timestamp']['output'];
  data_tab_complete: Scalars['Boolean']['output'];
  data_tip_complete: Scalars['Boolean']['output'];
  home_tab_complete: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  legal_coach_access_acknowledgment: Scalars['Boolean']['output'];
  legal_fitness_and_nutrition_acknowledgment: Scalars['Boolean']['output'];
  legal_screen_installation_acknowledgment: Scalars['Boolean']['output'];
  plan_tab_complete: Scalars['Boolean']['output'];
  plan_tab_locked: Scalars['Boolean']['output'];
  plan_tab_seen: Scalars['Boolean']['output'];
  profile_tab_complete: Scalars['Boolean']['output'];
  profile_tip_complete: Scalars['Boolean']['output'];
  ring_setup_complete: Scalars['Boolean']['output'];
  ring_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  ring_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  screen_setup_complete: Scalars['Boolean']['output'];
  screen_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  screen_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  signal_setup_complete: Scalars['Boolean']['output'];
  signal_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  signal_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type UserStateOutput = {
  __typename?: 'UserStateOutput';
  chat_tab_complete?: Maybe<Scalars['Boolean']['output']>;
  data_tab_complete?: Maybe<Scalars['Boolean']['output']>;
  data_tip_complete?: Maybe<Scalars['Boolean']['output']>;
  home_tab_complete?: Maybe<Scalars['Boolean']['output']>;
  plan_tab_complete?: Maybe<Scalars['Boolean']['output']>;
  plan_tab_locked?: Maybe<Scalars['Boolean']['output']>;
  plan_tab_seen?: Maybe<Scalars['Boolean']['output']>;
  profile_tab_complete?: Maybe<Scalars['Boolean']['output']>;
  profile_tip_complete?: Maybe<Scalars['Boolean']['output']>;
  ring_setup_complete?: Maybe<Scalars['Boolean']['output']>;
  ring_setup_completed_at?: Maybe<Scalars['String']['output']>;
  ring_setup_started_at?: Maybe<Scalars['String']['output']>;
  screen_setup_complete?: Maybe<Scalars['Boolean']['output']>;
  screen_setup_completed_at?: Maybe<Scalars['String']['output']>;
  screen_setup_started_at?: Maybe<Scalars['String']['output']>;
  signal_setup_complete?: Maybe<Scalars['Boolean']['output']>;
  signal_setup_completed_at?: Maybe<Scalars['String']['output']>;
  signal_setup_started_at?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "UserState" */
export type UserState_Aggregate = {
  __typename?: 'UserState_aggregate';
  aggregate?: Maybe<UserState_Aggregate_Fields>;
  nodes: Array<UserState>;
};

export type UserState_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<UserState_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserState_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<UserState_Aggregate_Bool_Exp_Count>;
};

export type UserState_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserState_Select_Column_UserState_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserState_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserState_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserState_Select_Column_UserState_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserState_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserState_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserState_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "UserState" */
export type UserState_Aggregate_Fields = {
  __typename?: 'UserState_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserState_Max_Fields>;
  min?: Maybe<UserState_Min_Fields>;
};


/** aggregate fields of "UserState" */
export type UserState_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserState" */
export type UserState_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserState_Max_Order_By>;
  min?: InputMaybe<UserState_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserState" */
export type UserState_Arr_Rel_Insert_Input = {
  data: Array<UserState_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserState_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserState". All fields are combined with a logical 'AND'. */
export type UserState_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<UserState_Bool_Exp>>;
  _not?: InputMaybe<UserState_Bool_Exp>;
  _or?: InputMaybe<Array<UserState_Bool_Exp>>;
  chat_tab_complete?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data_tab_complete?: InputMaybe<Boolean_Comparison_Exp>;
  data_tip_complete?: InputMaybe<Boolean_Comparison_Exp>;
  home_tab_complete?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  legal_coach_access_acknowledgment?: InputMaybe<Boolean_Comparison_Exp>;
  legal_fitness_and_nutrition_acknowledgment?: InputMaybe<Boolean_Comparison_Exp>;
  legal_screen_installation_acknowledgment?: InputMaybe<Boolean_Comparison_Exp>;
  plan_tab_complete?: InputMaybe<Boolean_Comparison_Exp>;
  plan_tab_locked?: InputMaybe<Boolean_Comparison_Exp>;
  plan_tab_seen?: InputMaybe<Boolean_Comparison_Exp>;
  profile_tab_complete?: InputMaybe<Boolean_Comparison_Exp>;
  profile_tip_complete?: InputMaybe<Boolean_Comparison_Exp>;
  ring_setup_complete?: InputMaybe<Boolean_Comparison_Exp>;
  ring_setup_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  ring_setup_started_at?: InputMaybe<Timestamp_Comparison_Exp>;
  screen_setup_complete?: InputMaybe<Boolean_Comparison_Exp>;
  screen_setup_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  screen_setup_started_at?: InputMaybe<Timestamp_Comparison_Exp>;
  signal_setup_complete?: InputMaybe<Boolean_Comparison_Exp>;
  signal_setup_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  signal_setup_started_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserState" */
export type UserState_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserState_pkey'
  /** unique or primary key constraint on columns "user_id" */
  | 'UserState_user_id_key';

/** input type for inserting data into table "UserState" */
export type UserState_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  chat_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  data_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  home_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  legal_coach_access_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  legal_fitness_and_nutrition_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  legal_screen_installation_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_locked?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_seen?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  ring_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  screen_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  screen_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  screen_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  signal_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  signal_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  signal_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserState_Max_Fields = {
  __typename?: 'UserState_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ring_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  ring_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  screen_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  screen_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  signal_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  signal_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "UserState" */
export type UserState_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ring_setup_completed_at?: InputMaybe<Order_By>;
  ring_setup_started_at?: InputMaybe<Order_By>;
  screen_setup_completed_at?: InputMaybe<Order_By>;
  screen_setup_started_at?: InputMaybe<Order_By>;
  signal_setup_completed_at?: InputMaybe<Order_By>;
  signal_setup_started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserState_Min_Fields = {
  __typename?: 'UserState_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ring_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  ring_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  screen_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  screen_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  signal_setup_completed_at?: Maybe<Scalars['timestamp']['output']>;
  signal_setup_started_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "UserState" */
export type UserState_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ring_setup_completed_at?: InputMaybe<Order_By>;
  ring_setup_started_at?: InputMaybe<Order_By>;
  screen_setup_completed_at?: InputMaybe<Order_By>;
  screen_setup_started_at?: InputMaybe<Order_By>;
  signal_setup_completed_at?: InputMaybe<Order_By>;
  signal_setup_started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserState" */
export type UserState_Mutation_Response = {
  __typename?: 'UserState_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserState>;
};

/** on_conflict condition type for table "UserState" */
export type UserState_On_Conflict = {
  constraint: UserState_Constraint;
  update_columns?: Array<UserState_Update_Column>;
  where?: InputMaybe<UserState_Bool_Exp>;
};

/** Ordering options when selecting data from "UserState". */
export type UserState_Order_By = {
  User?: InputMaybe<User_Order_By>;
  chat_tab_complete?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_tab_complete?: InputMaybe<Order_By>;
  data_tip_complete?: InputMaybe<Order_By>;
  home_tab_complete?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_coach_access_acknowledgment?: InputMaybe<Order_By>;
  legal_fitness_and_nutrition_acknowledgment?: InputMaybe<Order_By>;
  legal_screen_installation_acknowledgment?: InputMaybe<Order_By>;
  plan_tab_complete?: InputMaybe<Order_By>;
  plan_tab_locked?: InputMaybe<Order_By>;
  plan_tab_seen?: InputMaybe<Order_By>;
  profile_tab_complete?: InputMaybe<Order_By>;
  profile_tip_complete?: InputMaybe<Order_By>;
  ring_setup_complete?: InputMaybe<Order_By>;
  ring_setup_completed_at?: InputMaybe<Order_By>;
  ring_setup_started_at?: InputMaybe<Order_By>;
  screen_setup_complete?: InputMaybe<Order_By>;
  screen_setup_completed_at?: InputMaybe<Order_By>;
  screen_setup_started_at?: InputMaybe<Order_By>;
  signal_setup_complete?: InputMaybe<Order_By>;
  signal_setup_completed_at?: InputMaybe<Order_By>;
  signal_setup_started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserState */
export type UserState_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserState" */
export type UserState_Select_Column =
  /** column name */
  | 'chat_tab_complete'
  /** column name */
  | 'created_at'
  /** column name */
  | 'data_tab_complete'
  /** column name */
  | 'data_tip_complete'
  /** column name */
  | 'home_tab_complete'
  /** column name */
  | 'id'
  /** column name */
  | 'legal_coach_access_acknowledgment'
  /** column name */
  | 'legal_fitness_and_nutrition_acknowledgment'
  /** column name */
  | 'legal_screen_installation_acknowledgment'
  /** column name */
  | 'plan_tab_complete'
  /** column name */
  | 'plan_tab_locked'
  /** column name */
  | 'plan_tab_seen'
  /** column name */
  | 'profile_tab_complete'
  /** column name */
  | 'profile_tip_complete'
  /** column name */
  | 'ring_setup_complete'
  /** column name */
  | 'ring_setup_completed_at'
  /** column name */
  | 'ring_setup_started_at'
  /** column name */
  | 'screen_setup_complete'
  /** column name */
  | 'screen_setup_completed_at'
  /** column name */
  | 'screen_setup_started_at'
  /** column name */
  | 'signal_setup_complete'
  /** column name */
  | 'signal_setup_completed_at'
  /** column name */
  | 'signal_setup_started_at'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** select "UserState_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserState" */
export type UserState_Select_Column_UserState_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'chat_tab_complete'
  /** column name */
  | 'data_tab_complete'
  /** column name */
  | 'data_tip_complete'
  /** column name */
  | 'home_tab_complete'
  /** column name */
  | 'legal_coach_access_acknowledgment'
  /** column name */
  | 'legal_fitness_and_nutrition_acknowledgment'
  /** column name */
  | 'legal_screen_installation_acknowledgment'
  /** column name */
  | 'plan_tab_complete'
  /** column name */
  | 'plan_tab_locked'
  /** column name */
  | 'plan_tab_seen'
  /** column name */
  | 'profile_tab_complete'
  /** column name */
  | 'profile_tip_complete'
  /** column name */
  | 'ring_setup_complete'
  /** column name */
  | 'screen_setup_complete'
  /** column name */
  | 'signal_setup_complete';

/** select "UserState_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserState" */
export type UserState_Select_Column_UserState_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'chat_tab_complete'
  /** column name */
  | 'data_tab_complete'
  /** column name */
  | 'data_tip_complete'
  /** column name */
  | 'home_tab_complete'
  /** column name */
  | 'legal_coach_access_acknowledgment'
  /** column name */
  | 'legal_fitness_and_nutrition_acknowledgment'
  /** column name */
  | 'legal_screen_installation_acknowledgment'
  /** column name */
  | 'plan_tab_complete'
  /** column name */
  | 'plan_tab_locked'
  /** column name */
  | 'plan_tab_seen'
  /** column name */
  | 'profile_tab_complete'
  /** column name */
  | 'profile_tip_complete'
  /** column name */
  | 'ring_setup_complete'
  /** column name */
  | 'screen_setup_complete'
  /** column name */
  | 'signal_setup_complete';

/** input type for updating data in table "UserState" */
export type UserState_Set_Input = {
  chat_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  data_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  home_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  legal_coach_access_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  legal_fitness_and_nutrition_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  legal_screen_installation_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_locked?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_seen?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  ring_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  screen_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  screen_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  screen_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  signal_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  signal_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  signal_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserState" */
export type UserState_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserState_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserState_Stream_Cursor_Value_Input = {
  chat_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  data_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  home_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  legal_coach_access_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  legal_fitness_and_nutrition_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  legal_screen_installation_acknowledgment?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_locked?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_seen?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  ring_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  screen_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  screen_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  screen_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  signal_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  signal_setup_completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  signal_setup_started_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserState" */
export type UserState_Update_Column =
  /** column name */
  | 'chat_tab_complete'
  /** column name */
  | 'created_at'
  /** column name */
  | 'data_tab_complete'
  /** column name */
  | 'data_tip_complete'
  /** column name */
  | 'home_tab_complete'
  /** column name */
  | 'id'
  /** column name */
  | 'legal_coach_access_acknowledgment'
  /** column name */
  | 'legal_fitness_and_nutrition_acknowledgment'
  /** column name */
  | 'legal_screen_installation_acknowledgment'
  /** column name */
  | 'plan_tab_complete'
  /** column name */
  | 'plan_tab_locked'
  /** column name */
  | 'plan_tab_seen'
  /** column name */
  | 'profile_tab_complete'
  /** column name */
  | 'profile_tip_complete'
  /** column name */
  | 'ring_setup_complete'
  /** column name */
  | 'ring_setup_completed_at'
  /** column name */
  | 'ring_setup_started_at'
  /** column name */
  | 'screen_setup_complete'
  /** column name */
  | 'screen_setup_completed_at'
  /** column name */
  | 'screen_setup_started_at'
  /** column name */
  | 'signal_setup_complete'
  /** column name */
  | 'signal_setup_completed_at'
  /** column name */
  | 'signal_setup_started_at'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type UserState_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserState_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserState_Bool_Exp;
};

/** columns and relationships of "UserTrainingPlan" */
export type UserTrainingPlan = {
  __typename?: 'UserTrainingPlan';
  cardios_per_week: Scalars['Int']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  strengths_per_week: Scalars['Int']['output'];
  type: Scalars['PlanType']['output'];
  updated_at: Scalars['timestamp']['output'];
  workins_per_week: Scalars['Int']['output'];
};

/** aggregated selection of "UserTrainingPlan" */
export type UserTrainingPlan_Aggregate = {
  __typename?: 'UserTrainingPlan_aggregate';
  aggregate?: Maybe<UserTrainingPlan_Aggregate_Fields>;
  nodes: Array<UserTrainingPlan>;
};

/** aggregate fields of "UserTrainingPlan" */
export type UserTrainingPlan_Aggregate_Fields = {
  __typename?: 'UserTrainingPlan_aggregate_fields';
  avg?: Maybe<UserTrainingPlan_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserTrainingPlan_Max_Fields>;
  min?: Maybe<UserTrainingPlan_Min_Fields>;
  stddev?: Maybe<UserTrainingPlan_Stddev_Fields>;
  stddev_pop?: Maybe<UserTrainingPlan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserTrainingPlan_Stddev_Samp_Fields>;
  sum?: Maybe<UserTrainingPlan_Sum_Fields>;
  var_pop?: Maybe<UserTrainingPlan_Var_Pop_Fields>;
  var_samp?: Maybe<UserTrainingPlan_Var_Samp_Fields>;
  variance?: Maybe<UserTrainingPlan_Variance_Fields>;
};


/** aggregate fields of "UserTrainingPlan" */
export type UserTrainingPlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserTrainingPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type UserTrainingPlan_Avg_Fields = {
  __typename?: 'UserTrainingPlan_avg_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "UserTrainingPlan". All fields are combined with a logical 'AND'. */
export type UserTrainingPlan_Bool_Exp = {
  _and?: InputMaybe<Array<UserTrainingPlan_Bool_Exp>>;
  _not?: InputMaybe<UserTrainingPlan_Bool_Exp>;
  _or?: InputMaybe<Array<UserTrainingPlan_Bool_Exp>>;
  cardios_per_week?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  strengths_per_week?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<PlanType_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  workins_per_week?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserTrainingPlan" */
export type UserTrainingPlan_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserTrainingPlan_pkey'
  /** unique or primary key constraint on columns "type" */
  | 'UserTrainingPlan_type_key';

/** input type for incrementing numeric columns in table "UserTrainingPlan" */
export type UserTrainingPlan_Inc_Input = {
  cardios_per_week?: InputMaybe<Scalars['Int']['input']>;
  strengths_per_week?: InputMaybe<Scalars['Int']['input']>;
  workins_per_week?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserTrainingPlan" */
export type UserTrainingPlan_Insert_Input = {
  cardios_per_week?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  strengths_per_week?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['PlanType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  workins_per_week?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UserTrainingPlan_Max_Fields = {
  __typename?: 'UserTrainingPlan_max_fields';
  cardios_per_week?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  strengths_per_week?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['PlanType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  workins_per_week?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type UserTrainingPlan_Min_Fields = {
  __typename?: 'UserTrainingPlan_min_fields';
  cardios_per_week?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  strengths_per_week?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['PlanType']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  workins_per_week?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "UserTrainingPlan" */
export type UserTrainingPlan_Mutation_Response = {
  __typename?: 'UserTrainingPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserTrainingPlan>;
};

/** on_conflict condition type for table "UserTrainingPlan" */
export type UserTrainingPlan_On_Conflict = {
  constraint: UserTrainingPlan_Constraint;
  update_columns?: Array<UserTrainingPlan_Update_Column>;
  where?: InputMaybe<UserTrainingPlan_Bool_Exp>;
};

/** Ordering options when selecting data from "UserTrainingPlan". */
export type UserTrainingPlan_Order_By = {
  cardios_per_week?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  strengths_per_week?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workins_per_week?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserTrainingPlan */
export type UserTrainingPlan_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "UserTrainingPlan" */
export type UserTrainingPlan_Select_Column =
  /** column name */
  | 'cardios_per_week'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'strengths_per_week'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'workins_per_week';

/** input type for updating data in table "UserTrainingPlan" */
export type UserTrainingPlan_Set_Input = {
  cardios_per_week?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  strengths_per_week?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['PlanType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  workins_per_week?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type UserTrainingPlan_Stddev_Fields = {
  __typename?: 'UserTrainingPlan_stddev_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type UserTrainingPlan_Stddev_Pop_Fields = {
  __typename?: 'UserTrainingPlan_stddev_pop_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type UserTrainingPlan_Stddev_Samp_Fields = {
  __typename?: 'UserTrainingPlan_stddev_samp_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "UserTrainingPlan" */
export type UserTrainingPlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserTrainingPlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserTrainingPlan_Stream_Cursor_Value_Input = {
  cardios_per_week?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  strengths_per_week?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['PlanType']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  workins_per_week?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UserTrainingPlan_Sum_Fields = {
  __typename?: 'UserTrainingPlan_sum_fields';
  cardios_per_week?: Maybe<Scalars['Int']['output']>;
  strengths_per_week?: Maybe<Scalars['Int']['output']>;
  workins_per_week?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "UserTrainingPlan" */
export type UserTrainingPlan_Update_Column =
  /** column name */
  | 'cardios_per_week'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'strengths_per_week'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'workins_per_week';

export type UserTrainingPlan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserTrainingPlan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserTrainingPlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserTrainingPlan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserTrainingPlan_Var_Pop_Fields = {
  __typename?: 'UserTrainingPlan_var_pop_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type UserTrainingPlan_Var_Samp_Fields = {
  __typename?: 'UserTrainingPlan_var_samp_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type UserTrainingPlan_Variance_Fields = {
  __typename?: 'UserTrainingPlan_variance_fields';
  cardios_per_week?: Maybe<Scalars['Float']['output']>;
  strengths_per_week?: Maybe<Scalars['Float']['output']>;
  workins_per_week?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "UserWorkout" */
export type UserWorkout = {
  __typename?: 'UserWorkout';
  /** An array relationship */
  Diagnostics: Array<Diagnostics>;
  /** An aggregate relationship */
  Diagnostics_aggregate: Diagnostics_Aggregate;
  /** An array relationship */
  HeartRateDiagnostics: Array<HeartRateDiagnostics>;
  /** An aggregate relationship */
  HeartRateDiagnostics_aggregate: HeartRateDiagnostics_Aggregate;
  /** An array relationship */
  PlanDays: Array<PlanDay>;
  /** An aggregate relationship */
  PlanDays_aggregate: PlanDay_Aggregate;
  /** An object relationship */
  TokenHistory?: Maybe<TokenHistory>;
  /** An object relationship */
  User: User;
  /** An array relationship */
  UserFeedbacks: Array<UserFeedback>;
  /** An aggregate relationship */
  UserFeedbacks_aggregate: UserFeedback_Aggregate;
  /** An array relationship */
  UserReps: Array<UserRep>;
  /** An aggregate relationship */
  UserReps_aggregate: UserRep_Aggregate;
  /** An array relationship */
  UserSets: Array<UserSet>;
  /** An aggregate relationship */
  UserSets_aggregate: UserSet_Aggregate;
  caloric_data?: Maybe<Array<Scalars['jsonb']['output']>>;
  calories?: Maybe<Scalars['Int']['output']>;
  calories_by_lookup?: Maybe<Scalars['Int']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  completedAmount: Scalars['Int']['output'];
  completedEpoch?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  difficulty?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  duration_seen?: Maybe<Scalars['Int']['output']>;
  heartRate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  inPlan?: Maybe<Scalars['Boolean']['output']>;
  isChallenge?: Maybe<Scalars['Boolean']['output']>;
  isCounted?: Maybe<Scalars['Boolean']['output']>;
  is_demo: Scalars['Boolean']['output'];
  is_qualified?: Maybe<Scalars['Boolean']['output']>;
  lookup_reference_code?: Maybe<Scalars['String']['output']>;
  num_good_to_advance?: Maybe<Scalars['Int']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Int']['output']>;
  off_screen_type?: Maybe<Scalars['String']['output']>;
  pauses?: Maybe<Array<Scalars['jsonb']['output']>>;
  pear_workout_structure?: Maybe<Scalars['jsonb']['output']>;
  proRating?: Maybe<Scalars['Int']['output']>;
  recommendation_log_id?: Maybe<Scalars['String']['output']>;
  rpe?: Maybe<Scalars['float8']['output']>;
  screen_data?: Maybe<Scalars['jsonb']['output']>;
  startEpoch: Scalars['Int']['output'];
  strength_level?: Maybe<Scalars['float8']['output']>;
  stressRating?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token_history_id?: Maybe<Scalars['String']['output']>;
  trainerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId: Scalars['String']['output'];
  workin_level?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  workoutByWorkoutid?: Maybe<Workout>;
  workoutId?: Maybe<Scalars['String']['output']>;
  workoutRating?: Maybe<Scalars['Int']['output']>;
  workout_pear_id?: Maybe<Scalars['String']['output']>;
  workout_pear_sku?: Maybe<Scalars['String']['output']>;
  workout_streak?: Maybe<Scalars['Int']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
  zone1sec?: Maybe<Scalars['Int']['output']>;
  zone2sec?: Maybe<Scalars['Int']['output']>;
  zone3sec?: Maybe<Scalars['Int']['output']>;
  zone_data?: Maybe<Array<Scalars['jsonb']['output']>>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutHeartRateDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutHeartRateDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutPlanDaysArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutPlanDays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutUserFeedbacksArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutUserFeedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutUserRepsArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutUserReps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutUserSetsArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutUserSets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutPear_Workout_StructureArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "UserWorkout" */
export type UserWorkoutScreen_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "UserWorkout" */
export type UserWorkout_Aggregate = {
  __typename?: 'UserWorkout_aggregate';
  aggregate?: Maybe<UserWorkout_Aggregate_Fields>;
  nodes: Array<UserWorkout>;
};

export type UserWorkout_Aggregate_Bool_Exp = {
  avg?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserWorkout_Aggregate_Bool_Exp_Var_Samp>;
};

export type UserWorkout_Aggregate_Bool_Exp_Avg = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Corr = {
  arguments: UserWorkout_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Corr_Arguments = {
  X: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type UserWorkout_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserWorkout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: UserWorkout_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type UserWorkout_Aggregate_Bool_Exp_Max = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Min = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Sum = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type UserWorkout_Aggregate_Bool_Exp_Var_Samp = {
  arguments: UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserWorkout_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "UserWorkout" */
export type UserWorkout_Aggregate_Fields = {
  __typename?: 'UserWorkout_aggregate_fields';
  avg?: Maybe<UserWorkout_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserWorkout_Max_Fields>;
  min?: Maybe<UserWorkout_Min_Fields>;
  stddev?: Maybe<UserWorkout_Stddev_Fields>;
  stddev_pop?: Maybe<UserWorkout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserWorkout_Stddev_Samp_Fields>;
  sum?: Maybe<UserWorkout_Sum_Fields>;
  var_pop?: Maybe<UserWorkout_Var_Pop_Fields>;
  var_samp?: Maybe<UserWorkout_Var_Samp_Fields>;
  variance?: Maybe<UserWorkout_Variance_Fields>;
};


/** aggregate fields of "UserWorkout" */
export type UserWorkout_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserWorkout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "UserWorkout" */
export type UserWorkout_Aggregate_Order_By = {
  avg?: InputMaybe<UserWorkout_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserWorkout_Max_Order_By>;
  min?: InputMaybe<UserWorkout_Min_Order_By>;
  stddev?: InputMaybe<UserWorkout_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserWorkout_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserWorkout_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserWorkout_Sum_Order_By>;
  var_pop?: InputMaybe<UserWorkout_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserWorkout_Var_Samp_Order_By>;
  variance?: InputMaybe<UserWorkout_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserWorkout_Append_Input = {
  pear_workout_structure?: InputMaybe<Scalars['jsonb']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "UserWorkout" */
export type UserWorkout_Arr_Rel_Insert_Input = {
  data: Array<UserWorkout_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserWorkout_On_Conflict>;
};

/** aggregate avg on columns */
export type UserWorkout_Avg_Fields = {
  __typename?: 'UserWorkout_avg_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "UserWorkout" */
export type UserWorkout_Avg_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserWorkout". All fields are combined with a logical 'AND'. */
export type UserWorkout_Bool_Exp = {
  Diagnostics?: InputMaybe<Diagnostics_Bool_Exp>;
  Diagnostics_aggregate?: InputMaybe<Diagnostics_Aggregate_Bool_Exp>;
  HeartRateDiagnostics?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
  HeartRateDiagnostics_aggregate?: InputMaybe<HeartRateDiagnostics_Aggregate_Bool_Exp>;
  PlanDays?: InputMaybe<PlanDay_Bool_Exp>;
  PlanDays_aggregate?: InputMaybe<PlanDay_Aggregate_Bool_Exp>;
  TokenHistory?: InputMaybe<TokenHistory_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  UserFeedbacks?: InputMaybe<UserFeedback_Bool_Exp>;
  UserFeedbacks_aggregate?: InputMaybe<UserFeedback_Aggregate_Bool_Exp>;
  UserReps?: InputMaybe<UserRep_Bool_Exp>;
  UserReps_aggregate?: InputMaybe<UserRep_Aggregate_Bool_Exp>;
  UserSets?: InputMaybe<UserSet_Bool_Exp>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<UserWorkout_Bool_Exp>>;
  _not?: InputMaybe<UserWorkout_Bool_Exp>;
  _or?: InputMaybe<Array<UserWorkout_Bool_Exp>>;
  caloric_data?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  calories?: InputMaybe<Int_Comparison_Exp>;
  calories_by_lookup?: InputMaybe<Int_Comparison_Exp>;
  cardio_level?: InputMaybe<Int_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  completedAmount?: InputMaybe<Int_Comparison_Exp>;
  completedEpoch?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  difficulty?: InputMaybe<Int_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  duration_seen?: InputMaybe<Int_Comparison_Exp>;
  heartRate?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  inPlan?: InputMaybe<Boolean_Comparison_Exp>;
  isChallenge?: InputMaybe<Boolean_Comparison_Exp>;
  isCounted?: InputMaybe<Boolean_Comparison_Exp>;
  is_demo?: InputMaybe<Boolean_Comparison_Exp>;
  is_qualified?: InputMaybe<Boolean_Comparison_Exp>;
  lookup_reference_code?: InputMaybe<String_Comparison_Exp>;
  num_good_to_advance?: InputMaybe<Int_Comparison_Exp>;
  num_perfect_to_advance?: InputMaybe<Int_Comparison_Exp>;
  off_screen_type?: InputMaybe<String_Comparison_Exp>;
  pauses?: InputMaybe<Jsonb_Array_Comparison_Exp>;
  pear_workout_structure?: InputMaybe<Jsonb_Comparison_Exp>;
  proRating?: InputMaybe<Int_Comparison_Exp>;
  recommendation_log_id?: InputMaybe<String_Comparison_Exp>;
  rpe?: InputMaybe<Float8_Comparison_Exp>;
  screen_data?: InputMaybe<Jsonb_Comparison_Exp>;
  startEpoch?: InputMaybe<Int_Comparison_Exp>;
  strength_level?: InputMaybe<Float8_Comparison_Exp>;
  stressRating?: InputMaybe<Int_Comparison_Exp>;
  sub_type?: InputMaybe<SubWorkoutType_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  token_history_id?: InputMaybe<String_Comparison_Exp>;
  trainerId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  workin_level?: InputMaybe<Int_Comparison_Exp>;
  workoutByWorkoutid?: InputMaybe<Workout_Bool_Exp>;
  workoutId?: InputMaybe<String_Comparison_Exp>;
  workoutRating?: InputMaybe<Int_Comparison_Exp>;
  workout_pear_id?: InputMaybe<String_Comparison_Exp>;
  workout_pear_sku?: InputMaybe<String_Comparison_Exp>;
  workout_streak?: InputMaybe<Int_Comparison_Exp>;
  workout_type?: InputMaybe<WorkoutType_Comparison_Exp>;
  zone1sec?: InputMaybe<Int_Comparison_Exp>;
  zone2sec?: InputMaybe<Int_Comparison_Exp>;
  zone3sec?: InputMaybe<Int_Comparison_Exp>;
  zone_data?: InputMaybe<Jsonb_Array_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserWorkout" */
export type UserWorkout_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserWorkout_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserWorkout_Delete_At_Path_Input = {
  pear_workout_structure?: InputMaybe<Array<Scalars['String']['input']>>;
  screen_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserWorkout_Delete_Elem_Input = {
  pear_workout_structure?: InputMaybe<Scalars['Int']['input']>;
  screen_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserWorkout_Delete_Key_Input = {
  pear_workout_structure?: InputMaybe<Scalars['String']['input']>;
  screen_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "UserWorkout" */
export type UserWorkout_Inc_Input = {
  calories?: InputMaybe<Scalars['Int']['input']>;
  calories_by_lookup?: InputMaybe<Scalars['Int']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  completedAmount?: InputMaybe<Scalars['Int']['input']>;
  completedEpoch?: InputMaybe<Scalars['Int']['input']>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  heartRate?: InputMaybe<Scalars['Int']['input']>;
  num_good_to_advance?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_to_advance?: InputMaybe<Scalars['Int']['input']>;
  proRating?: InputMaybe<Scalars['Int']['input']>;
  rpe?: InputMaybe<Scalars['float8']['input']>;
  startEpoch?: InputMaybe<Scalars['Int']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  stressRating?: InputMaybe<Scalars['Int']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutRating?: InputMaybe<Scalars['Int']['input']>;
  workout_streak?: InputMaybe<Scalars['Int']['input']>;
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "UserWorkout" */
export type UserWorkout_Insert_Input = {
  Diagnostics?: InputMaybe<Diagnostics_Arr_Rel_Insert_Input>;
  HeartRateDiagnostics?: InputMaybe<HeartRateDiagnostics_Arr_Rel_Insert_Input>;
  PlanDays?: InputMaybe<PlanDay_Arr_Rel_Insert_Input>;
  TokenHistory?: InputMaybe<TokenHistory_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserFeedbacks?: InputMaybe<UserFeedback_Arr_Rel_Insert_Input>;
  UserReps?: InputMaybe<UserRep_Arr_Rel_Insert_Input>;
  UserSets?: InputMaybe<UserSet_Arr_Rel_Insert_Input>;
  caloric_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  calories?: InputMaybe<Scalars['Int']['input']>;
  calories_by_lookup?: InputMaybe<Scalars['Int']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  completedAmount?: InputMaybe<Scalars['Int']['input']>;
  completedEpoch?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  heartRate?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inPlan?: InputMaybe<Scalars['Boolean']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCounted?: InputMaybe<Scalars['Boolean']['input']>;
  is_demo?: InputMaybe<Scalars['Boolean']['input']>;
  is_qualified?: InputMaybe<Scalars['Boolean']['input']>;
  lookup_reference_code?: InputMaybe<Scalars['String']['input']>;
  num_good_to_advance?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_to_advance?: InputMaybe<Scalars['Int']['input']>;
  off_screen_type?: InputMaybe<Scalars['String']['input']>;
  pauses?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  pear_workout_structure?: InputMaybe<Scalars['jsonb']['input']>;
  proRating?: InputMaybe<Scalars['Int']['input']>;
  recommendation_log_id?: InputMaybe<Scalars['String']['input']>;
  rpe?: InputMaybe<Scalars['float8']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
  startEpoch?: InputMaybe<Scalars['Int']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  stressRating?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token_history_id?: InputMaybe<Scalars['String']['input']>;
  trainerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutByWorkoutid?: InputMaybe<Workout_Obj_Rel_Insert_Input>;
  workoutId?: InputMaybe<Scalars['String']['input']>;
  workoutRating?: InputMaybe<Scalars['Int']['input']>;
  workout_pear_id?: InputMaybe<Scalars['String']['input']>;
  workout_pear_sku?: InputMaybe<Scalars['String']['input']>;
  workout_streak?: InputMaybe<Scalars['Int']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
  zone_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** aggregate max on columns */
export type UserWorkout_Max_Fields = {
  __typename?: 'UserWorkout_max_fields';
  caloric_data?: Maybe<Array<Scalars['jsonb']['output']>>;
  calories?: Maybe<Scalars['Int']['output']>;
  calories_by_lookup?: Maybe<Scalars['Int']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  completedAmount?: Maybe<Scalars['Int']['output']>;
  completedEpoch?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  difficulty?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  duration_seen?: Maybe<Scalars['Int']['output']>;
  heartRate?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lookup_reference_code?: Maybe<Scalars['String']['output']>;
  num_good_to_advance?: Maybe<Scalars['Int']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Int']['output']>;
  off_screen_type?: Maybe<Scalars['String']['output']>;
  pauses?: Maybe<Array<Scalars['jsonb']['output']>>;
  proRating?: Maybe<Scalars['Int']['output']>;
  recommendation_log_id?: Maybe<Scalars['String']['output']>;
  rpe?: Maybe<Scalars['float8']['output']>;
  startEpoch?: Maybe<Scalars['Int']['output']>;
  strength_level?: Maybe<Scalars['float8']['output']>;
  stressRating?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token_history_id?: Maybe<Scalars['String']['output']>;
  trainerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
  workoutId?: Maybe<Scalars['String']['output']>;
  workoutRating?: Maybe<Scalars['Int']['output']>;
  workout_pear_id?: Maybe<Scalars['String']['output']>;
  workout_pear_sku?: Maybe<Scalars['String']['output']>;
  workout_streak?: Maybe<Scalars['Int']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
  zone1sec?: Maybe<Scalars['Int']['output']>;
  zone2sec?: Maybe<Scalars['Int']['output']>;
  zone3sec?: Maybe<Scalars['Int']['output']>;
  zone_data?: Maybe<Array<Scalars['jsonb']['output']>>;
};

/** order by max() on columns of table "UserWorkout" */
export type UserWorkout_Max_Order_By = {
  caloric_data?: InputMaybe<Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lookup_reference_code?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  off_screen_type?: InputMaybe<Order_By>;
  pauses?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  recommendation_log_id?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  token_history_id?: InputMaybe<Order_By>;
  trainerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutId?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_pear_id?: InputMaybe<Order_By>;
  workout_pear_sku?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
  zone_data?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserWorkout_Min_Fields = {
  __typename?: 'UserWorkout_min_fields';
  caloric_data?: Maybe<Array<Scalars['jsonb']['output']>>;
  calories?: Maybe<Scalars['Int']['output']>;
  calories_by_lookup?: Maybe<Scalars['Int']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  completedAmount?: Maybe<Scalars['Int']['output']>;
  completedEpoch?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  difficulty?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  duration_seen?: Maybe<Scalars['Int']['output']>;
  heartRate?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lookup_reference_code?: Maybe<Scalars['String']['output']>;
  num_good_to_advance?: Maybe<Scalars['Int']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Int']['output']>;
  off_screen_type?: Maybe<Scalars['String']['output']>;
  pauses?: Maybe<Array<Scalars['jsonb']['output']>>;
  proRating?: Maybe<Scalars['Int']['output']>;
  recommendation_log_id?: Maybe<Scalars['String']['output']>;
  rpe?: Maybe<Scalars['float8']['output']>;
  startEpoch?: Maybe<Scalars['Int']['output']>;
  strength_level?: Maybe<Scalars['float8']['output']>;
  stressRating?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token_history_id?: Maybe<Scalars['String']['output']>;
  trainerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
  workoutId?: Maybe<Scalars['String']['output']>;
  workoutRating?: Maybe<Scalars['Int']['output']>;
  workout_pear_id?: Maybe<Scalars['String']['output']>;
  workout_pear_sku?: Maybe<Scalars['String']['output']>;
  workout_streak?: Maybe<Scalars['Int']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
  zone1sec?: Maybe<Scalars['Int']['output']>;
  zone2sec?: Maybe<Scalars['Int']['output']>;
  zone3sec?: Maybe<Scalars['Int']['output']>;
  zone_data?: Maybe<Array<Scalars['jsonb']['output']>>;
};

/** order by min() on columns of table "UserWorkout" */
export type UserWorkout_Min_Order_By = {
  caloric_data?: InputMaybe<Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lookup_reference_code?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  off_screen_type?: InputMaybe<Order_By>;
  pauses?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  recommendation_log_id?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  token_history_id?: InputMaybe<Order_By>;
  trainerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutId?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_pear_id?: InputMaybe<Order_By>;
  workout_pear_sku?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
  zone_data?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UserWorkout" */
export type UserWorkout_Mutation_Response = {
  __typename?: 'UserWorkout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserWorkout>;
};

/** input type for inserting object relation for remote table "UserWorkout" */
export type UserWorkout_Obj_Rel_Insert_Input = {
  data: UserWorkout_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserWorkout_On_Conflict>;
};

/** on_conflict condition type for table "UserWorkout" */
export type UserWorkout_On_Conflict = {
  constraint: UserWorkout_Constraint;
  update_columns?: Array<UserWorkout_Update_Column>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};

/** Ordering options when selecting data from "UserWorkout". */
export type UserWorkout_Order_By = {
  Diagnostics_aggregate?: InputMaybe<Diagnostics_Aggregate_Order_By>;
  HeartRateDiagnostics_aggregate?: InputMaybe<HeartRateDiagnostics_Aggregate_Order_By>;
  PlanDays_aggregate?: InputMaybe<PlanDay_Aggregate_Order_By>;
  TokenHistory?: InputMaybe<TokenHistory_Order_By>;
  User?: InputMaybe<User_Order_By>;
  UserFeedbacks_aggregate?: InputMaybe<UserFeedback_Aggregate_Order_By>;
  UserReps_aggregate?: InputMaybe<UserRep_Aggregate_Order_By>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Order_By>;
  caloric_data?: InputMaybe<Order_By>;
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inPlan?: InputMaybe<Order_By>;
  isChallenge?: InputMaybe<Order_By>;
  isCounted?: InputMaybe<Order_By>;
  is_demo?: InputMaybe<Order_By>;
  is_qualified?: InputMaybe<Order_By>;
  lookup_reference_code?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  off_screen_type?: InputMaybe<Order_By>;
  pauses?: InputMaybe<Order_By>;
  pear_workout_structure?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  recommendation_log_id?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  screen_data?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  token_history_id?: InputMaybe<Order_By>;
  trainerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutByWorkoutid?: InputMaybe<Workout_Order_By>;
  workoutId?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_pear_id?: InputMaybe<Order_By>;
  workout_pear_sku?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
  zone_data?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserWorkout */
export type UserWorkout_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserWorkout_Prepend_Input = {
  pear_workout_structure?: InputMaybe<Scalars['jsonb']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "UserWorkout" */
export type UserWorkout_Select_Column =
  /** column name */
  | 'caloric_data'
  /** column name */
  | 'calories'
  /** column name */
  | 'calories_by_lookup'
  /** column name */
  | 'cardio_level'
  /** column name */
  | 'comments'
  /** column name */
  | 'completedAmount'
  /** column name */
  | 'completedEpoch'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'difficulty'
  /** column name */
  | 'duration'
  /** column name */
  | 'duration_seen'
  /** column name */
  | 'heartRate'
  /** column name */
  | 'id'
  /** column name */
  | 'inPlan'
  /** column name */
  | 'isChallenge'
  /** column name */
  | 'isCounted'
  /** column name */
  | 'is_demo'
  /** column name */
  | 'is_qualified'
  /** column name */
  | 'lookup_reference_code'
  /** column name */
  | 'num_good_to_advance'
  /** column name */
  | 'num_perfect_to_advance'
  /** column name */
  | 'off_screen_type'
  /** column name */
  | 'pauses'
  /** column name */
  | 'pear_workout_structure'
  /** column name */
  | 'proRating'
  /** column name */
  | 'recommendation_log_id'
  /** column name */
  | 'rpe'
  /** column name */
  | 'screen_data'
  /** column name */
  | 'startEpoch'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'stressRating'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'thumbnail_url'
  /** column name */
  | 'title'
  /** column name */
  | 'token_history_id'
  /** column name */
  | 'trainerId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId'
  /** column name */
  | 'workin_level'
  /** column name */
  | 'workoutId'
  /** column name */
  | 'workoutRating'
  /** column name */
  | 'workout_pear_id'
  /** column name */
  | 'workout_pear_sku'
  /** column name */
  | 'workout_streak'
  /** column name */
  | 'workout_type'
  /** column name */
  | 'zone1sec'
  /** column name */
  | 'zone2sec'
  /** column name */
  | 'zone3sec'
  /** column name */
  | 'zone_data';

/** select "UserWorkout_aggregate_bool_exp_avg_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'inPlan'
  /** column name */
  | 'isChallenge'
  /** column name */
  | 'isCounted'
  /** column name */
  | 'is_demo'
  /** column name */
  | 'is_qualified';

/** select "UserWorkout_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'inPlan'
  /** column name */
  | 'isChallenge'
  /** column name */
  | 'isCounted'
  /** column name */
  | 'is_demo'
  /** column name */
  | 'is_qualified';

/** select "UserWorkout_aggregate_bool_exp_corr_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_max_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_min_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_sum_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** select "UserWorkout_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserWorkout" */
export type UserWorkout_Select_Column_UserWorkout_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'rpe'
  /** column name */
  | 'strength_level';

/** input type for updating data in table "UserWorkout" */
export type UserWorkout_Set_Input = {
  caloric_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  calories?: InputMaybe<Scalars['Int']['input']>;
  calories_by_lookup?: InputMaybe<Scalars['Int']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  completedAmount?: InputMaybe<Scalars['Int']['input']>;
  completedEpoch?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  heartRate?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inPlan?: InputMaybe<Scalars['Boolean']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCounted?: InputMaybe<Scalars['Boolean']['input']>;
  is_demo?: InputMaybe<Scalars['Boolean']['input']>;
  is_qualified?: InputMaybe<Scalars['Boolean']['input']>;
  lookup_reference_code?: InputMaybe<Scalars['String']['input']>;
  num_good_to_advance?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_to_advance?: InputMaybe<Scalars['Int']['input']>;
  off_screen_type?: InputMaybe<Scalars['String']['input']>;
  pauses?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  pear_workout_structure?: InputMaybe<Scalars['jsonb']['input']>;
  proRating?: InputMaybe<Scalars['Int']['input']>;
  recommendation_log_id?: InputMaybe<Scalars['String']['input']>;
  rpe?: InputMaybe<Scalars['float8']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
  startEpoch?: InputMaybe<Scalars['Int']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  stressRating?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token_history_id?: InputMaybe<Scalars['String']['input']>;
  trainerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutId?: InputMaybe<Scalars['String']['input']>;
  workoutRating?: InputMaybe<Scalars['Int']['input']>;
  workout_pear_id?: InputMaybe<Scalars['String']['input']>;
  workout_pear_sku?: InputMaybe<Scalars['String']['input']>;
  workout_streak?: InputMaybe<Scalars['Int']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
  zone_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** aggregate stddev on columns */
export type UserWorkout_Stddev_Fields = {
  __typename?: 'UserWorkout_stddev_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "UserWorkout" */
export type UserWorkout_Stddev_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserWorkout_Stddev_Pop_Fields = {
  __typename?: 'UserWorkout_stddev_pop_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "UserWorkout" */
export type UserWorkout_Stddev_Pop_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserWorkout_Stddev_Samp_Fields = {
  __typename?: 'UserWorkout_stddev_samp_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "UserWorkout" */
export type UserWorkout_Stddev_Samp_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "UserWorkout" */
export type UserWorkout_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserWorkout_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserWorkout_Stream_Cursor_Value_Input = {
  caloric_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  calories?: InputMaybe<Scalars['Int']['input']>;
  calories_by_lookup?: InputMaybe<Scalars['Int']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  completedAmount?: InputMaybe<Scalars['Int']['input']>;
  completedEpoch?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  heartRate?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inPlan?: InputMaybe<Scalars['Boolean']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCounted?: InputMaybe<Scalars['Boolean']['input']>;
  is_demo?: InputMaybe<Scalars['Boolean']['input']>;
  is_qualified?: InputMaybe<Scalars['Boolean']['input']>;
  lookup_reference_code?: InputMaybe<Scalars['String']['input']>;
  num_good_to_advance?: InputMaybe<Scalars['Int']['input']>;
  num_perfect_to_advance?: InputMaybe<Scalars['Int']['input']>;
  off_screen_type?: InputMaybe<Scalars['String']['input']>;
  pauses?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  pear_workout_structure?: InputMaybe<Scalars['jsonb']['input']>;
  proRating?: InputMaybe<Scalars['Int']['input']>;
  recommendation_log_id?: InputMaybe<Scalars['String']['input']>;
  rpe?: InputMaybe<Scalars['float8']['input']>;
  screen_data?: InputMaybe<Scalars['jsonb']['input']>;
  startEpoch?: InputMaybe<Scalars['Int']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  stressRating?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token_history_id?: InputMaybe<Scalars['String']['input']>;
  trainerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutId?: InputMaybe<Scalars['String']['input']>;
  workoutRating?: InputMaybe<Scalars['Int']['input']>;
  workout_pear_id?: InputMaybe<Scalars['String']['input']>;
  workout_pear_sku?: InputMaybe<Scalars['String']['input']>;
  workout_streak?: InputMaybe<Scalars['Int']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
  zone_data?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** aggregate sum on columns */
export type UserWorkout_Sum_Fields = {
  __typename?: 'UserWorkout_sum_fields';
  calories?: Maybe<Scalars['Int']['output']>;
  calories_by_lookup?: Maybe<Scalars['Int']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  completedAmount?: Maybe<Scalars['Int']['output']>;
  completedEpoch?: Maybe<Scalars['Int']['output']>;
  difficulty?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  duration_seen?: Maybe<Scalars['Int']['output']>;
  heartRate?: Maybe<Scalars['Int']['output']>;
  num_good_to_advance?: Maybe<Scalars['Int']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Int']['output']>;
  proRating?: Maybe<Scalars['Int']['output']>;
  rpe?: Maybe<Scalars['float8']['output']>;
  startEpoch?: Maybe<Scalars['Int']['output']>;
  strength_level?: Maybe<Scalars['float8']['output']>;
  stressRating?: Maybe<Scalars['Int']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
  workoutRating?: Maybe<Scalars['Int']['output']>;
  workout_streak?: Maybe<Scalars['Int']['output']>;
  zone1sec?: Maybe<Scalars['Int']['output']>;
  zone2sec?: Maybe<Scalars['Int']['output']>;
  zone3sec?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "UserWorkout" */
export type UserWorkout_Sum_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** update columns of table "UserWorkout" */
export type UserWorkout_Update_Column =
  /** column name */
  | 'caloric_data'
  /** column name */
  | 'calories'
  /** column name */
  | 'calories_by_lookup'
  /** column name */
  | 'cardio_level'
  /** column name */
  | 'comments'
  /** column name */
  | 'completedAmount'
  /** column name */
  | 'completedEpoch'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'difficulty'
  /** column name */
  | 'duration'
  /** column name */
  | 'duration_seen'
  /** column name */
  | 'heartRate'
  /** column name */
  | 'id'
  /** column name */
  | 'inPlan'
  /** column name */
  | 'isChallenge'
  /** column name */
  | 'isCounted'
  /** column name */
  | 'is_demo'
  /** column name */
  | 'is_qualified'
  /** column name */
  | 'lookup_reference_code'
  /** column name */
  | 'num_good_to_advance'
  /** column name */
  | 'num_perfect_to_advance'
  /** column name */
  | 'off_screen_type'
  /** column name */
  | 'pauses'
  /** column name */
  | 'pear_workout_structure'
  /** column name */
  | 'proRating'
  /** column name */
  | 'recommendation_log_id'
  /** column name */
  | 'rpe'
  /** column name */
  | 'screen_data'
  /** column name */
  | 'startEpoch'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'stressRating'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'thumbnail_url'
  /** column name */
  | 'title'
  /** column name */
  | 'token_history_id'
  /** column name */
  | 'trainerId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId'
  /** column name */
  | 'workin_level'
  /** column name */
  | 'workoutId'
  /** column name */
  | 'workoutRating'
  /** column name */
  | 'workout_pear_id'
  /** column name */
  | 'workout_pear_sku'
  /** column name */
  | 'workout_streak'
  /** column name */
  | 'workout_type'
  /** column name */
  | 'zone1sec'
  /** column name */
  | 'zone2sec'
  /** column name */
  | 'zone3sec'
  /** column name */
  | 'zone_data';

export type UserWorkout_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserWorkout_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserWorkout_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserWorkout_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserWorkout_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserWorkout_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserWorkout_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserWorkout_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserWorkout_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserWorkout_Var_Pop_Fields = {
  __typename?: 'UserWorkout_var_pop_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "UserWorkout" */
export type UserWorkout_Var_Pop_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserWorkout_Var_Samp_Fields = {
  __typename?: 'UserWorkout_var_samp_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "UserWorkout" */
export type UserWorkout_Var_Samp_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UserWorkout_Variance_Fields = {
  __typename?: 'UserWorkout_variance_fields';
  calories?: Maybe<Scalars['Float']['output']>;
  calories_by_lookup?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  completedAmount?: Maybe<Scalars['Float']['output']>;
  completedEpoch?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  duration_seen?: Maybe<Scalars['Float']['output']>;
  heartRate?: Maybe<Scalars['Float']['output']>;
  num_good_to_advance?: Maybe<Scalars['Float']['output']>;
  num_perfect_to_advance?: Maybe<Scalars['Float']['output']>;
  proRating?: Maybe<Scalars['Float']['output']>;
  rpe?: Maybe<Scalars['Float']['output']>;
  startEpoch?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  stressRating?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
  workoutRating?: Maybe<Scalars['Float']['output']>;
  workout_streak?: Maybe<Scalars['Float']['output']>;
  zone1sec?: Maybe<Scalars['Float']['output']>;
  zone2sec?: Maybe<Scalars['Float']['output']>;
  zone3sec?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "UserWorkout" */
export type UserWorkout_Variance_Order_By = {
  calories?: InputMaybe<Order_By>;
  calories_by_lookup?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  completedAmount?: InputMaybe<Order_By>;
  completedEpoch?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_seen?: InputMaybe<Order_By>;
  heartRate?: InputMaybe<Order_By>;
  num_good_to_advance?: InputMaybe<Order_By>;
  num_perfect_to_advance?: InputMaybe<Order_By>;
  proRating?: InputMaybe<Order_By>;
  rpe?: InputMaybe<Order_By>;
  startEpoch?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  stressRating?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutRating?: InputMaybe<Order_By>;
  workout_streak?: InputMaybe<Order_By>;
  zone1sec?: InputMaybe<Order_By>;
  zone2sec?: InputMaybe<Order_By>;
  zone3sec?: InputMaybe<Order_By>;
};

/** aggregated selection of "User" */
export type User_Aggregate = {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  avg?: InputMaybe<User_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<User_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<User_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<User_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<User_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<User_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<User_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<User_Aggregate_Bool_Exp_Var_Samp>;
};

export type User_Aggregate_Bool_Exp_Avg = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Corr = {
  arguments: User_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Corr_Arguments = {
  X: User_Select_Column_User_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: User_Select_Column_User_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: User_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: User_Select_Column_User_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: User_Select_Column_User_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type User_Aggregate_Bool_Exp_Max = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Min = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Sum = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Var_Samp = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "User" */
export type User_Aggregate_Fields = {
  __typename?: 'User_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "User" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "User" */
export type User_Aggregate_Order_By = {
  avg?: InputMaybe<User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
  stddev?: InputMaybe<User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Sum_Order_By>;
  var_pop?: InputMaybe<User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "User" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'User_avg_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "User" */
export type User_Avg_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  Coach?: InputMaybe<Coach_Bool_Exp>;
  CoachedNutritions?: InputMaybe<CoachedNutrition_Bool_Exp>;
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Bool_Exp>;
  Coaches?: InputMaybe<Coach_Bool_Exp>;
  Coaches_aggregate?: InputMaybe<Coach_Aggregate_Bool_Exp>;
  DNAResults?: InputMaybe<DnaResults_Bool_Exp>;
  DNAResults_aggregate?: InputMaybe<DnaResults_Aggregate_Bool_Exp>;
  Devices?: InputMaybe<Device_Bool_Exp>;
  Devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>;
  Diagnostics?: InputMaybe<Diagnostics_Bool_Exp>;
  Diagnostics_aggregate?: InputMaybe<Diagnostics_Aggregate_Bool_Exp>;
  Goal?: InputMaybe<Goal_Bool_Exp>;
  HeartRateDiagnostics?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
  HeartRateDiagnostics_aggregate?: InputMaybe<HeartRateDiagnostics_Aggregate_Bool_Exp>;
  Household?: InputMaybe<Household_Bool_Exp>;
  Households?: InputMaybe<Household_Bool_Exp>;
  Households_aggregate?: InputMaybe<Household_Aggregate_Bool_Exp>;
  PlanDays?: InputMaybe<PlanDay_Bool_Exp>;
  PlanDays_aggregate?: InputMaybe<PlanDay_Aggregate_Bool_Exp>;
  Pro?: InputMaybe<Pro_Bool_Exp>;
  Pros?: InputMaybe<Pro_Bool_Exp>;
  Pros_aggregate?: InputMaybe<Pro_Aggregate_Bool_Exp>;
  QuizSurveys?: InputMaybe<QuizSurvey_Bool_Exp>;
  QuizSurveys_aggregate?: InputMaybe<QuizSurvey_Aggregate_Bool_Exp>;
  ScreenOrders?: InputMaybe<ScreenOrder_Bool_Exp>;
  ScreenOrders_aggregate?: InputMaybe<ScreenOrder_Aggregate_Bool_Exp>;
  SleepSurveys?: InputMaybe<SleepSurvey_Bool_Exp>;
  SleepSurveys_aggregate?: InputMaybe<SleepSurvey_Aggregate_Bool_Exp>;
  Subscription?: InputMaybe<Subscription_Bool_Exp>;
  TokenHistories?: InputMaybe<TokenHistory_Bool_Exp>;
  TokenHistories_aggregate?: InputMaybe<TokenHistory_Aggregate_Bool_Exp>;
  UserAggregates?: InputMaybe<UserAggregates_Bool_Exp>;
  UserAggregates_aggregate?: InputMaybe<UserAggregates_Aggregate_Bool_Exp>;
  UserAssessments?: InputMaybe<UserAssessment_Bool_Exp>;
  UserAssessments_aggregate?: InputMaybe<UserAssessment_Aggregate_Bool_Exp>;
  UserFeatureControls?: InputMaybe<UserFeatureControl_Bool_Exp>;
  UserFeatureControls_aggregate?: InputMaybe<UserFeatureControl_Aggregate_Bool_Exp>;
  UserFeedbacks?: InputMaybe<UserFeedback_Bool_Exp>;
  UserFeedbacks_aggregate?: InputMaybe<UserFeedback_Aggregate_Bool_Exp>;
  UserFinancePlans?: InputMaybe<UserFinancePlan_Bool_Exp>;
  UserFinancePlans_aggregate?: InputMaybe<UserFinancePlan_Aggregate_Bool_Exp>;
  UserMovementDailies?: InputMaybe<UserMovementDaily_Bool_Exp>;
  UserMovementDailies_aggregate?: InputMaybe<UserMovementDaily_Aggregate_Bool_Exp>;
  UserPreferences?: InputMaybe<UserPreferences_Bool_Exp>;
  UserPreferences_aggregate?: InputMaybe<UserPreferences_Aggregate_Bool_Exp>;
  UserProductHistories?: InputMaybe<UserProductHistory_Bool_Exp>;
  UserProductHistories_aggregate?: InputMaybe<UserProductHistory_Aggregate_Bool_Exp>;
  UserProducts?: InputMaybe<UserProduct_Bool_Exp>;
  UserProducts_aggregate?: InputMaybe<UserProduct_Aggregate_Bool_Exp>;
  UserReadinessLogs?: InputMaybe<UserReadinessLog_Bool_Exp>;
  UserReadinessLogs_aggregate?: InputMaybe<UserReadinessLog_Aggregate_Bool_Exp>;
  UserReadinesses?: InputMaybe<UserReadiness_Bool_Exp>;
  UserReadinesses_aggregate?: InputMaybe<UserReadiness_Aggregate_Bool_Exp>;
  UserReimbursmentHistories?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
  UserReimbursmentHistories_aggregate?: InputMaybe<UserReimbursmentHistory_Aggregate_Bool_Exp>;
  UserReps?: InputMaybe<UserRep_Bool_Exp>;
  UserReps_aggregate?: InputMaybe<UserRep_Aggregate_Bool_Exp>;
  UserSets?: InputMaybe<UserSet_Bool_Exp>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Bool_Exp>;
  UserStates?: InputMaybe<UserState_Bool_Exp>;
  UserStates_aggregate?: InputMaybe<UserState_Aggregate_Bool_Exp>;
  UserWorkouts?: InputMaybe<UserWorkout_Bool_Exp>;
  UserWorkouts_aggregate?: InputMaybe<UserWorkout_Aggregate_Bool_Exp>;
  WeeklyPlans?: InputMaybe<WeeklyPlan_Bool_Exp>;
  WeeklyPlans_aggregate?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp>;
  _FollowingPros?: InputMaybe<_FollowingPro_Bool_Exp>;
  _FollowingPros_aggregate?: InputMaybe<_FollowingPro_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  activityLevel?: InputMaybe<Int_Comparison_Exp>;
  age?: InputMaybe<Int_Comparison_Exp>;
  app_download_date?: InputMaybe<Timestamp_Comparison_Exp>;
  auth0UserId?: InputMaybe<String_Comparison_Exp>;
  biostrapId?: InputMaybe<String_Comparison_Exp>;
  biostrapName?: InputMaybe<String_Comparison_Exp>;
  birthday?: InputMaybe<String_Comparison_Exp>;
  brace_prone_level?: InputMaybe<Int_Comparison_Exp>;
  brace_side_level?: InputMaybe<Int_Comparison_Exp>;
  brace_supine_level?: InputMaybe<Int_Comparison_Exp>;
  cardio_fitness?: InputMaybe<GeneralRank_Comparison_Exp>;
  cardio_fitness_group?: InputMaybe<GeneralRankGroup_Comparison_Exp>;
  cardio_level?: InputMaybe<Int_Comparison_Exp>;
  coach_id?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  cuid?: InputMaybe<String_Comparison_Exp>;
  days_signal_worn?: InputMaybe<Int_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  enable_asensei?: InputMaybe<Boolean_Comparison_Exp>;
  enable_diagnostics?: InputMaybe<Boolean_Comparison_Exp>;
  equipments?: InputMaybe<Int_Array_Comparison_Exp>;
  ethnicity?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  fitness_goal_id?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  goalWeight?: InputMaybe<Float8_Comparison_Exp>;
  has_warming_state?: InputMaybe<Boolean_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  height_feet?: InputMaybe<Int_Comparison_Exp>;
  height_inches?: InputMaybe<Int_Comparison_Exp>;
  height_meters?: InputMaybe<Float8_Comparison_Exp>;
  hinge_level?: InputMaybe<Int_Comparison_Exp>;
  household_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  inRingMode?: InputMaybe<Boolean_Comparison_Exp>;
  is_onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  limitations?: InputMaybe<Int_Array_Comparison_Exp>;
  lunge_level?: InputMaybe<Int_Comparison_Exp>;
  mastery_prestige_id?: InputMaybe<String_Comparison_Exp>;
  maxHeartRate?: InputMaybe<Int_Comparison_Exp>;
  mostImportantFitnessGoal?: InputMaybe<String_Comparison_Exp>;
  nutrients_category?: InputMaybe<Int_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  pearId?: InputMaybe<String_Comparison_Exp>;
  pearRefreshToken?: InputMaybe<String_Comparison_Exp>;
  pearSessionToken?: InputMaybe<String_Comparison_Exp>;
  pearTokenExpiration?: InputMaybe<Timestamp_Comparison_Exp>;
  pearUserId?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  preferMetric?: InputMaybe<Boolean_Comparison_Exp>;
  preferredWorkoutLength?: InputMaybe<Int_Comparison_Exp>;
  preferredWorkouts?: InputMaybe<Int_Array_Comparison_Exp>;
  profileImage?: InputMaybe<String_Comparison_Exp>;
  pull_horizontal_level?: InputMaybe<Int_Comparison_Exp>;
  pull_vertical_level?: InputMaybe<Int_Comparison_Exp>;
  push_horizontal_level?: InputMaybe<Int_Comparison_Exp>;
  push_vertical_level?: InputMaybe<Int_Comparison_Exp>;
  radioPreferences?: InputMaybe<String_Array_Comparison_Exp>;
  referredId?: InputMaybe<String_Comparison_Exp>;
  reimbursementTokens?: InputMaybe<Int_Comparison_Exp>;
  ringId?: InputMaybe<String_Comparison_Exp>;
  ringMacAddress?: InputMaybe<String_Comparison_Exp>;
  ringName?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Role_Comparison_Exp>;
  screen_id?: InputMaybe<String_Comparison_Exp>;
  screen_name?: InputMaybe<String_Comparison_Exp>;
  sessionToken?: InputMaybe<String_Comparison_Exp>;
  sf_account_id?: InputMaybe<String_Comparison_Exp>;
  shopifyCustomerId?: InputMaybe<String_Comparison_Exp>;
  signal_color?: InputMaybe<String_Comparison_Exp>;
  squat_level?: InputMaybe<Int_Comparison_Exp>;
  strapColor?: InputMaybe<String_Comparison_Exp>;
  strap_location?: InputMaybe<StrapLocation_Comparison_Exp>;
  strength_level?: InputMaybe<Float8_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  swabId?: InputMaybe<String_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  trainingPlan?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  v02_max_raw?: InputMaybe<Float8_Comparison_Exp>;
  weight?: InputMaybe<Float8_Comparison_Exp>;
  weightUnit?: InputMaybe<Unit_Comparison_Exp>;
  weight_kg?: InputMaybe<Float8_Comparison_Exp>;
  weight_lbs?: InputMaybe<Float8_Comparison_Exp>;
  workin_fitness?: InputMaybe<GeneralRank_Comparison_Exp>;
  workin_fitness_group?: InputMaybe<GeneralRankGroup_Comparison_Exp>;
  workin_level?: InputMaybe<Int_Comparison_Exp>;
  workoutFrequency?: InputMaybe<Int_Array_Comparison_Exp>;
};

/** unique or primary key constraints on table "User" */
export type User_Constraint =
  /** unique or primary key constraint on columns "auth0UserId" */
  | 'User_auth0UserId_key'
  /** unique or primary key constraint on columns "email" */
  | 'User_email_unique'
  /** unique or primary key constraint on columns "mastery_prestige_id" */
  | 'User_mastery_prestige_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'User_pkey'
  /** unique or primary key constraint on columns "sf_account_id" */
  | 'User_sf_account_id_key';

/** input type for incrementing numeric columns in table "User" */
export type User_Inc_Input = {
  activityLevel?: InputMaybe<Scalars['Int']['input']>;
  age?: InputMaybe<Scalars['Int']['input']>;
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  days_signal_worn?: InputMaybe<Scalars['Int']['input']>;
  goalWeight?: InputMaybe<Scalars['float8']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_feet?: InputMaybe<Scalars['Int']['input']>;
  height_inches?: InputMaybe<Scalars['Int']['input']>;
  height_meters?: InputMaybe<Scalars['float8']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  maxHeartRate?: InputMaybe<Scalars['Int']['input']>;
  nutrients_category?: InputMaybe<Scalars['Int']['input']>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  reimbursementTokens?: InputMaybe<Scalars['Int']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  weight?: InputMaybe<Scalars['float8']['input']>;
  weight_kg?: InputMaybe<Scalars['float8']['input']>;
  weight_lbs?: InputMaybe<Scalars['float8']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "User" */
export type User_Insert_Input = {
  Coach?: InputMaybe<Coach_Obj_Rel_Insert_Input>;
  CoachedNutritions?: InputMaybe<CoachedNutrition_Arr_Rel_Insert_Input>;
  Coaches?: InputMaybe<Coach_Arr_Rel_Insert_Input>;
  DNAResults?: InputMaybe<DnaResults_Arr_Rel_Insert_Input>;
  Devices?: InputMaybe<Device_Arr_Rel_Insert_Input>;
  Diagnostics?: InputMaybe<Diagnostics_Arr_Rel_Insert_Input>;
  Goal?: InputMaybe<Goal_Obj_Rel_Insert_Input>;
  HeartRateDiagnostics?: InputMaybe<HeartRateDiagnostics_Arr_Rel_Insert_Input>;
  Household?: InputMaybe<Household_Obj_Rel_Insert_Input>;
  Households?: InputMaybe<Household_Arr_Rel_Insert_Input>;
  PlanDays?: InputMaybe<PlanDay_Arr_Rel_Insert_Input>;
  Pro?: InputMaybe<Pro_Obj_Rel_Insert_Input>;
  Pros?: InputMaybe<Pro_Arr_Rel_Insert_Input>;
  QuizSurveys?: InputMaybe<QuizSurvey_Arr_Rel_Insert_Input>;
  ScreenOrders?: InputMaybe<ScreenOrder_Arr_Rel_Insert_Input>;
  SleepSurveys?: InputMaybe<SleepSurvey_Arr_Rel_Insert_Input>;
  Subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  TokenHistories?: InputMaybe<TokenHistory_Arr_Rel_Insert_Input>;
  UserAggregates?: InputMaybe<UserAggregates_Arr_Rel_Insert_Input>;
  UserAssessments?: InputMaybe<UserAssessment_Arr_Rel_Insert_Input>;
  UserFeatureControls?: InputMaybe<UserFeatureControl_Arr_Rel_Insert_Input>;
  UserFeedbacks?: InputMaybe<UserFeedback_Arr_Rel_Insert_Input>;
  UserFinancePlans?: InputMaybe<UserFinancePlan_Arr_Rel_Insert_Input>;
  UserMovementDailies?: InputMaybe<UserMovementDaily_Arr_Rel_Insert_Input>;
  UserPreferences?: InputMaybe<UserPreferences_Arr_Rel_Insert_Input>;
  UserProductHistories?: InputMaybe<UserProductHistory_Arr_Rel_Insert_Input>;
  UserProducts?: InputMaybe<UserProduct_Arr_Rel_Insert_Input>;
  UserReadinessLogs?: InputMaybe<UserReadinessLog_Arr_Rel_Insert_Input>;
  UserReadinesses?: InputMaybe<UserReadiness_Arr_Rel_Insert_Input>;
  UserReimbursmentHistories?: InputMaybe<UserReimbursmentHistory_Arr_Rel_Insert_Input>;
  UserReps?: InputMaybe<UserRep_Arr_Rel_Insert_Input>;
  UserSets?: InputMaybe<UserSet_Arr_Rel_Insert_Input>;
  UserStates?: InputMaybe<UserState_Arr_Rel_Insert_Input>;
  UserWorkouts?: InputMaybe<UserWorkout_Arr_Rel_Insert_Input>;
  WeeklyPlans?: InputMaybe<WeeklyPlan_Arr_Rel_Insert_Input>;
  _FollowingPros?: InputMaybe<_FollowingPro_Arr_Rel_Insert_Input>;
  activityLevel?: InputMaybe<Scalars['Int']['input']>;
  age?: InputMaybe<Scalars['Int']['input']>;
  app_download_date?: InputMaybe<Scalars['timestamp']['input']>;
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  biostrapId?: InputMaybe<Scalars['String']['input']>;
  biostrapName?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  cardio_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  cuid?: InputMaybe<Scalars['String']['input']>;
  days_signal_worn?: InputMaybe<Scalars['Int']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enable_asensei?: InputMaybe<Scalars['Boolean']['input']>;
  enable_diagnostics?: InputMaybe<Scalars['Boolean']['input']>;
  equipments?: InputMaybe<Array<Scalars['Int']['input']>>;
  ethnicity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitness_goal_id?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  goalWeight?: InputMaybe<Scalars['float8']['input']>;
  has_warming_state?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_feet?: InputMaybe<Scalars['Int']['input']>;
  height_inches?: InputMaybe<Scalars['Int']['input']>;
  height_meters?: InputMaybe<Scalars['float8']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  household_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inRingMode?: InputMaybe<Scalars['Boolean']['input']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  limitations?: InputMaybe<Array<Scalars['Int']['input']>>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  mastery_prestige_id?: InputMaybe<Scalars['String']['input']>;
  maxHeartRate?: InputMaybe<Scalars['Int']['input']>;
  mostImportantFitnessGoal?: InputMaybe<Scalars['String']['input']>;
  nutrients_category?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pearId?: InputMaybe<Scalars['String']['input']>;
  pearRefreshToken?: InputMaybe<Scalars['String']['input']>;
  pearSessionToken?: InputMaybe<Scalars['String']['input']>;
  pearTokenExpiration?: InputMaybe<Scalars['timestamp']['input']>;
  pearUserId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferMetric?: InputMaybe<Scalars['Boolean']['input']>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  preferredWorkouts?: InputMaybe<Array<Scalars['Int']['input']>>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  radioPreferences?: InputMaybe<Array<Scalars['String']['input']>>;
  referredId?: InputMaybe<Scalars['String']['input']>;
  reimbursementTokens?: InputMaybe<Scalars['Int']['input']>;
  ringId?: InputMaybe<Scalars['String']['input']>;
  ringMacAddress?: InputMaybe<Scalars['String']['input']>;
  ringName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['Role']['input']>;
  screen_id?: InputMaybe<Scalars['String']['input']>;
  screen_name?: InputMaybe<Scalars['String']['input']>;
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  shopifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  signal_color?: InputMaybe<Scalars['String']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  strapColor?: InputMaybe<Scalars['String']['input']>;
  strap_location?: InputMaybe<Scalars['StrapLocation']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  swabId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  trainingPlan?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  weight?: InputMaybe<Scalars['float8']['input']>;
  weightUnit?: InputMaybe<Scalars['Unit']['input']>;
  weight_kg?: InputMaybe<Scalars['float8']['input']>;
  weight_lbs?: InputMaybe<Scalars['float8']['input']>;
  workin_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  workin_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutFrequency?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'User_max_fields';
  activityLevel?: Maybe<Scalars['Int']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  app_download_date?: Maybe<Scalars['timestamp']['output']>;
  auth0UserId?: Maybe<Scalars['String']['output']>;
  biostrapId?: Maybe<Scalars['String']['output']>;
  biostrapName?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  cardio_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  coach_id?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  cuid?: Maybe<Scalars['String']['output']>;
  days_signal_worn?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equipments?: Maybe<Array<Scalars['Int']['output']>>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitness_goal_id?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  goalWeight?: Maybe<Scalars['float8']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['float8']['output']>;
  hinge_level?: Maybe<Scalars['Int']['output']>;
  household_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  limitations?: Maybe<Array<Scalars['Int']['output']>>;
  lunge_level?: Maybe<Scalars['Int']['output']>;
  mastery_prestige_id?: Maybe<Scalars['String']['output']>;
  maxHeartRate?: Maybe<Scalars['Int']['output']>;
  mostImportantFitnessGoal?: Maybe<Scalars['String']['output']>;
  nutrients_category?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  pearId?: Maybe<Scalars['String']['output']>;
  pearRefreshToken?: Maybe<Scalars['String']['output']>;
  pearSessionToken?: Maybe<Scalars['String']['output']>;
  pearTokenExpiration?: Maybe<Scalars['timestamp']['output']>;
  pearUserId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  preferredWorkouts?: Maybe<Array<Scalars['Int']['output']>>;
  profileImage?: Maybe<Scalars['String']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  radioPreferences?: Maybe<Array<Scalars['String']['output']>>;
  referredId?: Maybe<Scalars['String']['output']>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  ringId?: Maybe<Scalars['String']['output']>;
  ringMacAddress?: Maybe<Scalars['String']['output']>;
  ringName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['Role']['output']>;
  screen_id?: Maybe<Scalars['String']['output']>;
  screen_name?: Maybe<Scalars['String']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  sf_account_id?: Maybe<Scalars['String']['output']>;
  shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  signal_color?: Maybe<Scalars['String']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  strapColor?: Maybe<Scalars['String']['output']>;
  strap_location?: Maybe<Scalars['StrapLocation']['output']>;
  strength_level?: Maybe<Scalars['float8']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  swabId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  weight?: Maybe<Scalars['float8']['output']>;
  weightUnit?: Maybe<Scalars['Unit']['output']>;
  weight_kg?: Maybe<Scalars['float8']['output']>;
  weight_lbs?: Maybe<Scalars['float8']['output']>;
  workin_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  workin_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
  workoutFrequency?: Maybe<Array<Scalars['Int']['output']>>;
};

/** order by max() on columns of table "User" */
export type User_Max_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  app_download_date?: InputMaybe<Order_By>;
  auth0UserId?: InputMaybe<Order_By>;
  biostrapId?: InputMaybe<Order_By>;
  biostrapName?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_fitness?: InputMaybe<Order_By>;
  cardio_fitness_group?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuid?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  equipments?: InputMaybe<Order_By>;
  ethnicity?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fitness_goal_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  household_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  limitations?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  mastery_prestige_id?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  mostImportantFitnessGoal?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  pearId?: InputMaybe<Order_By>;
  pearRefreshToken?: InputMaybe<Order_By>;
  pearSessionToken?: InputMaybe<Order_By>;
  pearTokenExpiration?: InputMaybe<Order_By>;
  pearUserId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  preferredWorkouts?: InputMaybe<Order_By>;
  profileImage?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  radioPreferences?: InputMaybe<Order_By>;
  referredId?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  ringId?: InputMaybe<Order_By>;
  ringMacAddress?: InputMaybe<Order_By>;
  ringName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  screen_id?: InputMaybe<Order_By>;
  screen_name?: InputMaybe<Order_By>;
  sessionToken?: InputMaybe<Order_By>;
  sf_account_id?: InputMaybe<Order_By>;
  shopifyCustomerId?: InputMaybe<Order_By>;
  signal_color?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strapColor?: InputMaybe<Order_By>;
  strap_location?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  swabId?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  trainingPlan?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_fitness?: InputMaybe<Order_By>;
  workin_fitness_group?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutFrequency?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'User_min_fields';
  activityLevel?: Maybe<Scalars['Int']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  app_download_date?: Maybe<Scalars['timestamp']['output']>;
  auth0UserId?: Maybe<Scalars['String']['output']>;
  biostrapId?: Maybe<Scalars['String']['output']>;
  biostrapName?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  cardio_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  coach_id?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  cuid?: Maybe<Scalars['String']['output']>;
  days_signal_worn?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  equipments?: Maybe<Array<Scalars['Int']['output']>>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitness_goal_id?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  goalWeight?: Maybe<Scalars['float8']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['float8']['output']>;
  hinge_level?: Maybe<Scalars['Int']['output']>;
  household_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  limitations?: Maybe<Array<Scalars['Int']['output']>>;
  lunge_level?: Maybe<Scalars['Int']['output']>;
  mastery_prestige_id?: Maybe<Scalars['String']['output']>;
  maxHeartRate?: Maybe<Scalars['Int']['output']>;
  mostImportantFitnessGoal?: Maybe<Scalars['String']['output']>;
  nutrients_category?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  pearId?: Maybe<Scalars['String']['output']>;
  pearRefreshToken?: Maybe<Scalars['String']['output']>;
  pearSessionToken?: Maybe<Scalars['String']['output']>;
  pearTokenExpiration?: Maybe<Scalars['timestamp']['output']>;
  pearUserId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  preferredWorkouts?: Maybe<Array<Scalars['Int']['output']>>;
  profileImage?: Maybe<Scalars['String']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  radioPreferences?: Maybe<Array<Scalars['String']['output']>>;
  referredId?: Maybe<Scalars['String']['output']>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  ringId?: Maybe<Scalars['String']['output']>;
  ringMacAddress?: Maybe<Scalars['String']['output']>;
  ringName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['Role']['output']>;
  screen_id?: Maybe<Scalars['String']['output']>;
  screen_name?: Maybe<Scalars['String']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  sf_account_id?: Maybe<Scalars['String']['output']>;
  shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  signal_color?: Maybe<Scalars['String']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  strapColor?: Maybe<Scalars['String']['output']>;
  strap_location?: Maybe<Scalars['StrapLocation']['output']>;
  strength_level?: Maybe<Scalars['float8']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  swabId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  trainingPlan?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  weight?: Maybe<Scalars['float8']['output']>;
  weightUnit?: Maybe<Scalars['Unit']['output']>;
  weight_kg?: Maybe<Scalars['float8']['output']>;
  weight_lbs?: Maybe<Scalars['float8']['output']>;
  workin_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  workin_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
  workoutFrequency?: Maybe<Array<Scalars['Int']['output']>>;
};

/** order by min() on columns of table "User" */
export type User_Min_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  app_download_date?: InputMaybe<Order_By>;
  auth0UserId?: InputMaybe<Order_By>;
  biostrapId?: InputMaybe<Order_By>;
  biostrapName?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_fitness?: InputMaybe<Order_By>;
  cardio_fitness_group?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuid?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  equipments?: InputMaybe<Order_By>;
  ethnicity?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fitness_goal_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  household_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  limitations?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  mastery_prestige_id?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  mostImportantFitnessGoal?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  pearId?: InputMaybe<Order_By>;
  pearRefreshToken?: InputMaybe<Order_By>;
  pearSessionToken?: InputMaybe<Order_By>;
  pearTokenExpiration?: InputMaybe<Order_By>;
  pearUserId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  preferredWorkouts?: InputMaybe<Order_By>;
  profileImage?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  radioPreferences?: InputMaybe<Order_By>;
  referredId?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  ringId?: InputMaybe<Order_By>;
  ringMacAddress?: InputMaybe<Order_By>;
  ringName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  screen_id?: InputMaybe<Order_By>;
  screen_name?: InputMaybe<Order_By>;
  sessionToken?: InputMaybe<Order_By>;
  sf_account_id?: InputMaybe<Order_By>;
  shopifyCustomerId?: InputMaybe<Order_By>;
  signal_color?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strapColor?: InputMaybe<Order_By>;
  strap_location?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  swabId?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  trainingPlan?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_fitness?: InputMaybe<Order_By>;
  workin_fitness_group?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutFrequency?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "User" */
export type User_Mutation_Response = {
  __typename?: 'User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "User" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "User". */
export type User_Order_By = {
  Coach?: InputMaybe<Coach_Order_By>;
  CoachedNutritions_aggregate?: InputMaybe<CoachedNutrition_Aggregate_Order_By>;
  Coaches_aggregate?: InputMaybe<Coach_Aggregate_Order_By>;
  DNAResults_aggregate?: InputMaybe<DnaResults_Aggregate_Order_By>;
  Devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>;
  Diagnostics_aggregate?: InputMaybe<Diagnostics_Aggregate_Order_By>;
  Goal?: InputMaybe<Goal_Order_By>;
  HeartRateDiagnostics_aggregate?: InputMaybe<HeartRateDiagnostics_Aggregate_Order_By>;
  Household?: InputMaybe<Household_Order_By>;
  Households_aggregate?: InputMaybe<Household_Aggregate_Order_By>;
  PlanDays_aggregate?: InputMaybe<PlanDay_Aggregate_Order_By>;
  Pro?: InputMaybe<Pro_Order_By>;
  Pros_aggregate?: InputMaybe<Pro_Aggregate_Order_By>;
  QuizSurveys_aggregate?: InputMaybe<QuizSurvey_Aggregate_Order_By>;
  ScreenOrders_aggregate?: InputMaybe<ScreenOrder_Aggregate_Order_By>;
  SleepSurveys_aggregate?: InputMaybe<SleepSurvey_Aggregate_Order_By>;
  Subscription?: InputMaybe<Subscription_Order_By>;
  TokenHistories_aggregate?: InputMaybe<TokenHistory_Aggregate_Order_By>;
  UserAggregates_aggregate?: InputMaybe<UserAggregates_Aggregate_Order_By>;
  UserAssessments_aggregate?: InputMaybe<UserAssessment_Aggregate_Order_By>;
  UserFeatureControls_aggregate?: InputMaybe<UserFeatureControl_Aggregate_Order_By>;
  UserFeedbacks_aggregate?: InputMaybe<UserFeedback_Aggregate_Order_By>;
  UserFinancePlans_aggregate?: InputMaybe<UserFinancePlan_Aggregate_Order_By>;
  UserMovementDailies_aggregate?: InputMaybe<UserMovementDaily_Aggregate_Order_By>;
  UserPreferences_aggregate?: InputMaybe<UserPreferences_Aggregate_Order_By>;
  UserProductHistories_aggregate?: InputMaybe<UserProductHistory_Aggregate_Order_By>;
  UserProducts_aggregate?: InputMaybe<UserProduct_Aggregate_Order_By>;
  UserReadinessLogs_aggregate?: InputMaybe<UserReadinessLog_Aggregate_Order_By>;
  UserReadinesses_aggregate?: InputMaybe<UserReadiness_Aggregate_Order_By>;
  UserReimbursmentHistories_aggregate?: InputMaybe<UserReimbursmentHistory_Aggregate_Order_By>;
  UserReps_aggregate?: InputMaybe<UserRep_Aggregate_Order_By>;
  UserSets_aggregate?: InputMaybe<UserSet_Aggregate_Order_By>;
  UserStates_aggregate?: InputMaybe<UserState_Aggregate_Order_By>;
  UserWorkouts_aggregate?: InputMaybe<UserWorkout_Aggregate_Order_By>;
  WeeklyPlans_aggregate?: InputMaybe<WeeklyPlan_Aggregate_Order_By>;
  _FollowingPros_aggregate?: InputMaybe<_FollowingPro_Aggregate_Order_By>;
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  app_download_date?: InputMaybe<Order_By>;
  auth0UserId?: InputMaybe<Order_By>;
  biostrapId?: InputMaybe<Order_By>;
  biostrapName?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_fitness?: InputMaybe<Order_By>;
  cardio_fitness_group?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cuid?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  enable_asensei?: InputMaybe<Order_By>;
  enable_diagnostics?: InputMaybe<Order_By>;
  equipments?: InputMaybe<Order_By>;
  ethnicity?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fitness_goal_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  has_warming_state?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  household_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inRingMode?: InputMaybe<Order_By>;
  is_onboarded?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  limitations?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  mastery_prestige_id?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  mostImportantFitnessGoal?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  pearId?: InputMaybe<Order_By>;
  pearRefreshToken?: InputMaybe<Order_By>;
  pearSessionToken?: InputMaybe<Order_By>;
  pearTokenExpiration?: InputMaybe<Order_By>;
  pearUserId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  preferMetric?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  preferredWorkouts?: InputMaybe<Order_By>;
  profileImage?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  radioPreferences?: InputMaybe<Order_By>;
  referredId?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  ringId?: InputMaybe<Order_By>;
  ringMacAddress?: InputMaybe<Order_By>;
  ringName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  screen_id?: InputMaybe<Order_By>;
  screen_name?: InputMaybe<Order_By>;
  sessionToken?: InputMaybe<Order_By>;
  sf_account_id?: InputMaybe<Order_By>;
  shopifyCustomerId?: InputMaybe<Order_By>;
  signal_color?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strapColor?: InputMaybe<Order_By>;
  strap_location?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  swabId?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  trainingPlan?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_fitness?: InputMaybe<Order_By>;
  workin_fitness_group?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
  workoutFrequency?: InputMaybe<Order_By>;
};

/** primary key columns input for table: User */
export type User_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "User" */
export type User_Select_Column =
  /** column name */
  | 'activityLevel'
  /** column name */
  | 'age'
  /** column name */
  | 'app_download_date'
  /** column name */
  | 'auth0UserId'
  /** column name */
  | 'biostrapId'
  /** column name */
  | 'biostrapName'
  /** column name */
  | 'birthday'
  /** column name */
  | 'brace_prone_level'
  /** column name */
  | 'brace_side_level'
  /** column name */
  | 'brace_supine_level'
  /** column name */
  | 'cardio_fitness'
  /** column name */
  | 'cardio_fitness_group'
  /** column name */
  | 'cardio_level'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'cuid'
  /** column name */
  | 'days_signal_worn'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'email'
  /** column name */
  | 'enable_asensei'
  /** column name */
  | 'enable_diagnostics'
  /** column name */
  | 'equipments'
  /** column name */
  | 'ethnicity'
  /** column name */
  | 'firstName'
  /** column name */
  | 'fitness_goal_id'
  /** column name */
  | 'gender'
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'has_warming_state'
  /** column name */
  | 'height'
  /** column name */
  | 'height_feet'
  /** column name */
  | 'height_inches'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'hinge_level'
  /** column name */
  | 'household_id'
  /** column name */
  | 'id'
  /** column name */
  | 'inRingMode'
  /** column name */
  | 'is_onboarded'
  /** column name */
  | 'lastName'
  /** column name */
  | 'limitations'
  /** column name */
  | 'lunge_level'
  /** column name */
  | 'mastery_prestige_id'
  /** column name */
  | 'maxHeartRate'
  /** column name */
  | 'mostImportantFitnessGoal'
  /** column name */
  | 'nutrients_category'
  /** column name */
  | 'password'
  /** column name */
  | 'pearId'
  /** column name */
  | 'pearRefreshToken'
  /** column name */
  | 'pearSessionToken'
  /** column name */
  | 'pearTokenExpiration'
  /** column name */
  | 'pearUserId'
  /** column name */
  | 'phone'
  /** column name */
  | 'preferMetric'
  /** column name */
  | 'preferredWorkoutLength'
  /** column name */
  | 'preferredWorkouts'
  /** column name */
  | 'profileImage'
  /** column name */
  | 'pull_horizontal_level'
  /** column name */
  | 'pull_vertical_level'
  /** column name */
  | 'push_horizontal_level'
  /** column name */
  | 'push_vertical_level'
  /** column name */
  | 'radioPreferences'
  /** column name */
  | 'referredId'
  /** column name */
  | 'reimbursementTokens'
  /** column name */
  | 'ringId'
  /** column name */
  | 'ringMacAddress'
  /** column name */
  | 'ringName'
  /** column name */
  | 'role'
  /** column name */
  | 'screen_id'
  /** column name */
  | 'screen_name'
  /** column name */
  | 'sessionToken'
  /** column name */
  | 'sf_account_id'
  /** column name */
  | 'shopifyCustomerId'
  /** column name */
  | 'signal_color'
  /** column name */
  | 'squat_level'
  /** column name */
  | 'strapColor'
  /** column name */
  | 'strap_location'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'swabId'
  /** column name */
  | 'timezone'
  /** column name */
  | 'trainingPlan'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weightUnit'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs'
  /** column name */
  | 'workin_fitness'
  /** column name */
  | 'workin_fitness_group'
  /** column name */
  | 'workin_level'
  /** column name */
  | 'workoutFrequency';

/** select "User_aggregate_bool_exp_avg_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_bool_and_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'enable_asensei'
  /** column name */
  | 'enable_diagnostics'
  /** column name */
  | 'has_warming_state'
  /** column name */
  | 'inRingMode'
  /** column name */
  | 'is_onboarded'
  /** column name */
  | 'preferMetric';

/** select "User_aggregate_bool_exp_bool_or_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'enable_asensei'
  /** column name */
  | 'enable_diagnostics'
  /** column name */
  | 'has_warming_state'
  /** column name */
  | 'inRingMode'
  /** column name */
  | 'is_onboarded'
  /** column name */
  | 'preferMetric';

/** select "User_aggregate_bool_exp_corr_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_max_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_min_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_sum_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** select "User_aggregate_bool_exp_var_samp_arguments_columns" columns of table "User" */
export type User_Select_Column_User_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs';

/** input type for updating data in table "User" */
export type User_Set_Input = {
  activityLevel?: InputMaybe<Scalars['Int']['input']>;
  age?: InputMaybe<Scalars['Int']['input']>;
  app_download_date?: InputMaybe<Scalars['timestamp']['input']>;
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  biostrapId?: InputMaybe<Scalars['String']['input']>;
  biostrapName?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  cardio_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  cuid?: InputMaybe<Scalars['String']['input']>;
  days_signal_worn?: InputMaybe<Scalars['Int']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enable_asensei?: InputMaybe<Scalars['Boolean']['input']>;
  enable_diagnostics?: InputMaybe<Scalars['Boolean']['input']>;
  equipments?: InputMaybe<Array<Scalars['Int']['input']>>;
  ethnicity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitness_goal_id?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  goalWeight?: InputMaybe<Scalars['float8']['input']>;
  has_warming_state?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_feet?: InputMaybe<Scalars['Int']['input']>;
  height_inches?: InputMaybe<Scalars['Int']['input']>;
  height_meters?: InputMaybe<Scalars['float8']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  household_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inRingMode?: InputMaybe<Scalars['Boolean']['input']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  limitations?: InputMaybe<Array<Scalars['Int']['input']>>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  mastery_prestige_id?: InputMaybe<Scalars['String']['input']>;
  maxHeartRate?: InputMaybe<Scalars['Int']['input']>;
  mostImportantFitnessGoal?: InputMaybe<Scalars['String']['input']>;
  nutrients_category?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pearId?: InputMaybe<Scalars['String']['input']>;
  pearRefreshToken?: InputMaybe<Scalars['String']['input']>;
  pearSessionToken?: InputMaybe<Scalars['String']['input']>;
  pearTokenExpiration?: InputMaybe<Scalars['timestamp']['input']>;
  pearUserId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferMetric?: InputMaybe<Scalars['Boolean']['input']>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  preferredWorkouts?: InputMaybe<Array<Scalars['Int']['input']>>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  radioPreferences?: InputMaybe<Array<Scalars['String']['input']>>;
  referredId?: InputMaybe<Scalars['String']['input']>;
  reimbursementTokens?: InputMaybe<Scalars['Int']['input']>;
  ringId?: InputMaybe<Scalars['String']['input']>;
  ringMacAddress?: InputMaybe<Scalars['String']['input']>;
  ringName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['Role']['input']>;
  screen_id?: InputMaybe<Scalars['String']['input']>;
  screen_name?: InputMaybe<Scalars['String']['input']>;
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  shopifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  signal_color?: InputMaybe<Scalars['String']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  strapColor?: InputMaybe<Scalars['String']['input']>;
  strap_location?: InputMaybe<Scalars['StrapLocation']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  swabId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  trainingPlan?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  weight?: InputMaybe<Scalars['float8']['input']>;
  weightUnit?: InputMaybe<Scalars['Unit']['input']>;
  weight_kg?: InputMaybe<Scalars['float8']['input']>;
  weight_lbs?: InputMaybe<Scalars['float8']['input']>;
  workin_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  workin_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutFrequency?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'User_stddev_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "User" */
export type User_Stddev_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'User_stddev_pop_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "User" */
export type User_Stddev_Pop_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'User_stddev_samp_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "User" */
export type User_Stddev_Samp_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "User" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  activityLevel?: InputMaybe<Scalars['Int']['input']>;
  age?: InputMaybe<Scalars['Int']['input']>;
  app_download_date?: InputMaybe<Scalars['timestamp']['input']>;
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  biostrapId?: InputMaybe<Scalars['String']['input']>;
  biostrapName?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  cardio_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  coach_id?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  cuid?: InputMaybe<Scalars['String']['input']>;
  days_signal_worn?: InputMaybe<Scalars['Int']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enable_asensei?: InputMaybe<Scalars['Boolean']['input']>;
  enable_diagnostics?: InputMaybe<Scalars['Boolean']['input']>;
  equipments?: InputMaybe<Array<Scalars['Int']['input']>>;
  ethnicity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitness_goal_id?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  goalWeight?: InputMaybe<Scalars['float8']['input']>;
  has_warming_state?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_feet?: InputMaybe<Scalars['Int']['input']>;
  height_inches?: InputMaybe<Scalars['Int']['input']>;
  height_meters?: InputMaybe<Scalars['float8']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  household_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inRingMode?: InputMaybe<Scalars['Boolean']['input']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  limitations?: InputMaybe<Array<Scalars['Int']['input']>>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  mastery_prestige_id?: InputMaybe<Scalars['String']['input']>;
  maxHeartRate?: InputMaybe<Scalars['Int']['input']>;
  mostImportantFitnessGoal?: InputMaybe<Scalars['String']['input']>;
  nutrients_category?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pearId?: InputMaybe<Scalars['String']['input']>;
  pearRefreshToken?: InputMaybe<Scalars['String']['input']>;
  pearSessionToken?: InputMaybe<Scalars['String']['input']>;
  pearTokenExpiration?: InputMaybe<Scalars['timestamp']['input']>;
  pearUserId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferMetric?: InputMaybe<Scalars['Boolean']['input']>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  preferredWorkouts?: InputMaybe<Array<Scalars['Int']['input']>>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  radioPreferences?: InputMaybe<Array<Scalars['String']['input']>>;
  referredId?: InputMaybe<Scalars['String']['input']>;
  reimbursementTokens?: InputMaybe<Scalars['Int']['input']>;
  ringId?: InputMaybe<Scalars['String']['input']>;
  ringMacAddress?: InputMaybe<Scalars['String']['input']>;
  ringName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['Role']['input']>;
  screen_id?: InputMaybe<Scalars['String']['input']>;
  screen_name?: InputMaybe<Scalars['String']['input']>;
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  shopifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  signal_color?: InputMaybe<Scalars['String']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  strapColor?: InputMaybe<Scalars['String']['input']>;
  strap_location?: InputMaybe<Scalars['StrapLocation']['input']>;
  strength_level?: InputMaybe<Scalars['float8']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  swabId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  trainingPlan?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  weight?: InputMaybe<Scalars['float8']['input']>;
  weightUnit?: InputMaybe<Scalars['Unit']['input']>;
  weight_kg?: InputMaybe<Scalars['float8']['input']>;
  weight_lbs?: InputMaybe<Scalars['float8']['input']>;
  workin_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  workin_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  workin_level?: InputMaybe<Scalars['Int']['input']>;
  workoutFrequency?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'User_sum_fields';
  activityLevel?: Maybe<Scalars['Int']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  days_signal_worn?: Maybe<Scalars['Int']['output']>;
  goalWeight?: Maybe<Scalars['float8']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['float8']['output']>;
  hinge_level?: Maybe<Scalars['Int']['output']>;
  lunge_level?: Maybe<Scalars['Int']['output']>;
  maxHeartRate?: Maybe<Scalars['Int']['output']>;
  nutrients_category?: Maybe<Scalars['Int']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  reimbursementTokens?: Maybe<Scalars['Int']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  strength_level?: Maybe<Scalars['float8']['output']>;
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  weight?: Maybe<Scalars['float8']['output']>;
  weight_kg?: Maybe<Scalars['float8']['output']>;
  weight_lbs?: Maybe<Scalars['float8']['output']>;
  workin_level?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "User" */
export type User_Sum_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** update columns of table "User" */
export type User_Update_Column =
  /** column name */
  | 'activityLevel'
  /** column name */
  | 'age'
  /** column name */
  | 'app_download_date'
  /** column name */
  | 'auth0UserId'
  /** column name */
  | 'biostrapId'
  /** column name */
  | 'biostrapName'
  /** column name */
  | 'birthday'
  /** column name */
  | 'brace_prone_level'
  /** column name */
  | 'brace_side_level'
  /** column name */
  | 'brace_supine_level'
  /** column name */
  | 'cardio_fitness'
  /** column name */
  | 'cardio_fitness_group'
  /** column name */
  | 'cardio_level'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'cuid'
  /** column name */
  | 'days_signal_worn'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'email'
  /** column name */
  | 'enable_asensei'
  /** column name */
  | 'enable_diagnostics'
  /** column name */
  | 'equipments'
  /** column name */
  | 'ethnicity'
  /** column name */
  | 'firstName'
  /** column name */
  | 'fitness_goal_id'
  /** column name */
  | 'gender'
  /** column name */
  | 'goalWeight'
  /** column name */
  | 'has_warming_state'
  /** column name */
  | 'height'
  /** column name */
  | 'height_feet'
  /** column name */
  | 'height_inches'
  /** column name */
  | 'height_meters'
  /** column name */
  | 'hinge_level'
  /** column name */
  | 'household_id'
  /** column name */
  | 'id'
  /** column name */
  | 'inRingMode'
  /** column name */
  | 'is_onboarded'
  /** column name */
  | 'lastName'
  /** column name */
  | 'limitations'
  /** column name */
  | 'lunge_level'
  /** column name */
  | 'mastery_prestige_id'
  /** column name */
  | 'maxHeartRate'
  /** column name */
  | 'mostImportantFitnessGoal'
  /** column name */
  | 'nutrients_category'
  /** column name */
  | 'password'
  /** column name */
  | 'pearId'
  /** column name */
  | 'pearRefreshToken'
  /** column name */
  | 'pearSessionToken'
  /** column name */
  | 'pearTokenExpiration'
  /** column name */
  | 'pearUserId'
  /** column name */
  | 'phone'
  /** column name */
  | 'preferMetric'
  /** column name */
  | 'preferredWorkoutLength'
  /** column name */
  | 'preferredWorkouts'
  /** column name */
  | 'profileImage'
  /** column name */
  | 'pull_horizontal_level'
  /** column name */
  | 'pull_vertical_level'
  /** column name */
  | 'push_horizontal_level'
  /** column name */
  | 'push_vertical_level'
  /** column name */
  | 'radioPreferences'
  /** column name */
  | 'referredId'
  /** column name */
  | 'reimbursementTokens'
  /** column name */
  | 'ringId'
  /** column name */
  | 'ringMacAddress'
  /** column name */
  | 'ringName'
  /** column name */
  | 'role'
  /** column name */
  | 'screen_id'
  /** column name */
  | 'screen_name'
  /** column name */
  | 'sessionToken'
  /** column name */
  | 'sf_account_id'
  /** column name */
  | 'shopifyCustomerId'
  /** column name */
  | 'signal_color'
  /** column name */
  | 'squat_level'
  /** column name */
  | 'strapColor'
  /** column name */
  | 'strap_location'
  /** column name */
  | 'strength_level'
  /** column name */
  | 'subscription_id'
  /** column name */
  | 'swabId'
  /** column name */
  | 'timezone'
  /** column name */
  | 'trainingPlan'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'weight'
  /** column name */
  | 'weightUnit'
  /** column name */
  | 'weight_kg'
  /** column name */
  | 'weight_lbs'
  /** column name */
  | 'workin_fitness'
  /** column name */
  | 'workin_fitness_group'
  /** column name */
  | 'workin_level'
  /** column name */
  | 'workoutFrequency';

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'User_var_pop_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "User" */
export type User_Var_Pop_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'User_var_samp_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "User" */
export type User_Var_Samp_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'User_variance_fields';
  activityLevel?: Maybe<Scalars['Float']['output']>;
  age?: Maybe<Scalars['Float']['output']>;
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  days_signal_worn?: Maybe<Scalars['Float']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_feet?: Maybe<Scalars['Float']['output']>;
  height_inches?: Maybe<Scalars['Float']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  maxHeartRate?: Maybe<Scalars['Float']['output']>;
  nutrients_category?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  reimbursementTokens?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  strength_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
  workin_level?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "User" */
export type User_Variance_Order_By = {
  activityLevel?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  days_signal_worn?: InputMaybe<Order_By>;
  goalWeight?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_feet?: InputMaybe<Order_By>;
  height_inches?: InputMaybe<Order_By>;
  height_meters?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  maxHeartRate?: InputMaybe<Order_By>;
  nutrients_category?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  reimbursementTokens?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  strength_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_kg?: InputMaybe<Order_By>;
  weight_lbs?: InputMaybe<Order_By>;
  workin_level?: InputMaybe<Order_By>;
};

export type ValLabels = {
  __typename?: 'ValLabels';
  label?: Maybe<Scalars['String']['output']>;
  val?: Maybe<Scalars['String']['output']>;
};

export type ValueAndTime = {
  __typename?: 'ValueAndTime';
  _time?: Maybe<Scalars['String']['output']>;
  _value?: Maybe<Scalars['Float']['output']>;
};

export type ValueTimeAndAverage = {
  __typename?: 'ValueTimeAndAverage';
  _avg?: Maybe<Scalars['Float']['output']>;
  _time?: Maybe<Scalars['String']['output']>;
  _value?: Maybe<Scalars['Float']['output']>;
};

export type ValueTimeAndMinimum = {
  __typename?: 'ValueTimeAndMinimum';
  _min?: Maybe<Scalars['Float']['output']>;
  _time?: Maybe<Scalars['String']['output']>;
  _value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "VendorErrorLog" */
export type VendorErrorLog = {
  __typename?: 'VendorErrorLog';
  createdAt: Scalars['timestamp']['output'];
  errorDetails?: Maybe<Scalars['jsonb']['output']>;
  errorMessage: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  userId: Scalars['String']['output'];
  vendorType?: Maybe<Scalars['VendorType']['output']>;
};


/** columns and relationships of "VendorErrorLog" */
export type VendorErrorLogErrorDetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "VendorErrorLog" */
export type VendorErrorLog_Aggregate = {
  __typename?: 'VendorErrorLog_aggregate';
  aggregate?: Maybe<VendorErrorLog_Aggregate_Fields>;
  nodes: Array<VendorErrorLog>;
};

/** aggregate fields of "VendorErrorLog" */
export type VendorErrorLog_Aggregate_Fields = {
  __typename?: 'VendorErrorLog_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<VendorErrorLog_Max_Fields>;
  min?: Maybe<VendorErrorLog_Min_Fields>;
};


/** aggregate fields of "VendorErrorLog" */
export type VendorErrorLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VendorErrorLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type VendorErrorLog_Append_Input = {
  errorDetails?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "VendorErrorLog". All fields are combined with a logical 'AND'. */
export type VendorErrorLog_Bool_Exp = {
  _and?: InputMaybe<Array<VendorErrorLog_Bool_Exp>>;
  _not?: InputMaybe<VendorErrorLog_Bool_Exp>;
  _or?: InputMaybe<Array<VendorErrorLog_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  errorDetails?: InputMaybe<Jsonb_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  vendorType?: InputMaybe<VendorType_Comparison_Exp>;
};

/** unique or primary key constraints on table "VendorErrorLog" */
export type VendorErrorLog_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'VendorErrorLog_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type VendorErrorLog_Delete_At_Path_Input = {
  errorDetails?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type VendorErrorLog_Delete_Elem_Input = {
  errorDetails?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type VendorErrorLog_Delete_Key_Input = {
  errorDetails?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "VendorErrorLog" */
export type VendorErrorLog_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  errorDetails?: InputMaybe<Scalars['jsonb']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  vendorType?: InputMaybe<Scalars['VendorType']['input']>;
};

/** aggregate max on columns */
export type VendorErrorLog_Max_Fields = {
  __typename?: 'VendorErrorLog_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  vendorType?: Maybe<Scalars['VendorType']['output']>;
};

/** aggregate min on columns */
export type VendorErrorLog_Min_Fields = {
  __typename?: 'VendorErrorLog_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  vendorType?: Maybe<Scalars['VendorType']['output']>;
};

/** response of any mutation on the table "VendorErrorLog" */
export type VendorErrorLog_Mutation_Response = {
  __typename?: 'VendorErrorLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VendorErrorLog>;
};

/** on_conflict condition type for table "VendorErrorLog" */
export type VendorErrorLog_On_Conflict = {
  constraint: VendorErrorLog_Constraint;
  update_columns?: Array<VendorErrorLog_Update_Column>;
  where?: InputMaybe<VendorErrorLog_Bool_Exp>;
};

/** Ordering options when selecting data from "VendorErrorLog". */
export type VendorErrorLog_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorDetails?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendorType?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VendorErrorLog */
export type VendorErrorLog_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type VendorErrorLog_Prepend_Input = {
  errorDetails?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "VendorErrorLog" */
export type VendorErrorLog_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'errorDetails'
  /** column name */
  | 'errorMessage'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'vendorType';

/** input type for updating data in table "VendorErrorLog" */
export type VendorErrorLog_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  errorDetails?: InputMaybe<Scalars['jsonb']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  vendorType?: InputMaybe<Scalars['VendorType']['input']>;
};

/** Streaming cursor of the table "VendorErrorLog" */
export type VendorErrorLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VendorErrorLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VendorErrorLog_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  errorDetails?: InputMaybe<Scalars['jsonb']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  vendorType?: InputMaybe<Scalars['VendorType']['input']>;
};

/** update columns of table "VendorErrorLog" */
export type VendorErrorLog_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'errorDetails'
  /** column name */
  | 'errorMessage'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId'
  /** column name */
  | 'vendorType';

export type VendorErrorLog_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<VendorErrorLog_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<VendorErrorLog_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<VendorErrorLog_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<VendorErrorLog_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<VendorErrorLog_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VendorErrorLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: VendorErrorLog_Bool_Exp;
};

/** Boolean expression to compare columns of type "VendorType". All fields are combined with logical 'AND'. */
export type VendorType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['VendorType']['input']>;
  _gt?: InputMaybe<Scalars['VendorType']['input']>;
  _gte?: InputMaybe<Scalars['VendorType']['input']>;
  _in?: InputMaybe<Array<Scalars['VendorType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['VendorType']['input']>;
  _lte?: InputMaybe<Scalars['VendorType']['input']>;
  _neq?: InputMaybe<Scalars['VendorType']['input']>;
  _nin?: InputMaybe<Array<Scalars['VendorType']['input']>>;
};

/** columns and relationships of "VersionControl" */
export type VersionControl = {
  __typename?: 'VersionControl';
  id: Scalars['String']['output'];
  mobile_os: Scalars['DeviceType']['output'];
  mobile_version: Scalars['Int']['output'];
  screen_version: Scalars['Int']['output'];
};

/** aggregated selection of "VersionControl" */
export type VersionControl_Aggregate = {
  __typename?: 'VersionControl_aggregate';
  aggregate?: Maybe<VersionControl_Aggregate_Fields>;
  nodes: Array<VersionControl>;
};

/** aggregate fields of "VersionControl" */
export type VersionControl_Aggregate_Fields = {
  __typename?: 'VersionControl_aggregate_fields';
  avg?: Maybe<VersionControl_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VersionControl_Max_Fields>;
  min?: Maybe<VersionControl_Min_Fields>;
  stddev?: Maybe<VersionControl_Stddev_Fields>;
  stddev_pop?: Maybe<VersionControl_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VersionControl_Stddev_Samp_Fields>;
  sum?: Maybe<VersionControl_Sum_Fields>;
  var_pop?: Maybe<VersionControl_Var_Pop_Fields>;
  var_samp?: Maybe<VersionControl_Var_Samp_Fields>;
  variance?: Maybe<VersionControl_Variance_Fields>;
};


/** aggregate fields of "VersionControl" */
export type VersionControl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VersionControl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type VersionControl_Avg_Fields = {
  __typename?: 'VersionControl_avg_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "VersionControl". All fields are combined with a logical 'AND'. */
export type VersionControl_Bool_Exp = {
  _and?: InputMaybe<Array<VersionControl_Bool_Exp>>;
  _not?: InputMaybe<VersionControl_Bool_Exp>;
  _or?: InputMaybe<Array<VersionControl_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  mobile_os?: InputMaybe<DeviceType_Comparison_Exp>;
  mobile_version?: InputMaybe<Int_Comparison_Exp>;
  screen_version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "VersionControl" */
export type VersionControl_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'VersionControl_pkey'
  /** unique or primary key constraint on columns "screen_version", "mobile_os", "mobile_version" */
  | 'VersionControl_screen_version_mobile_version_mobile_os_key';

/** input type for incrementing numeric columns in table "VersionControl" */
export type VersionControl_Inc_Input = {
  mobile_version?: InputMaybe<Scalars['Int']['input']>;
  screen_version?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "VersionControl" */
export type VersionControl_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  mobile_os?: InputMaybe<Scalars['DeviceType']['input']>;
  mobile_version?: InputMaybe<Scalars['Int']['input']>;
  screen_version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type VersionControl_Max_Fields = {
  __typename?: 'VersionControl_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  mobile_os?: Maybe<Scalars['DeviceType']['output']>;
  mobile_version?: Maybe<Scalars['Int']['output']>;
  screen_version?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type VersionControl_Min_Fields = {
  __typename?: 'VersionControl_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  mobile_os?: Maybe<Scalars['DeviceType']['output']>;
  mobile_version?: Maybe<Scalars['Int']['output']>;
  screen_version?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "VersionControl" */
export type VersionControl_Mutation_Response = {
  __typename?: 'VersionControl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VersionControl>;
};

/** on_conflict condition type for table "VersionControl" */
export type VersionControl_On_Conflict = {
  constraint: VersionControl_Constraint;
  update_columns?: Array<VersionControl_Update_Column>;
  where?: InputMaybe<VersionControl_Bool_Exp>;
};

/** Ordering options when selecting data from "VersionControl". */
export type VersionControl_Order_By = {
  id?: InputMaybe<Order_By>;
  mobile_os?: InputMaybe<Order_By>;
  mobile_version?: InputMaybe<Order_By>;
  screen_version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VersionControl */
export type VersionControl_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "VersionControl" */
export type VersionControl_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'mobile_os'
  /** column name */
  | 'mobile_version'
  /** column name */
  | 'screen_version';

/** input type for updating data in table "VersionControl" */
export type VersionControl_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  mobile_os?: InputMaybe<Scalars['DeviceType']['input']>;
  mobile_version?: InputMaybe<Scalars['Int']['input']>;
  screen_version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type VersionControl_Stddev_Fields = {
  __typename?: 'VersionControl_stddev_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type VersionControl_Stddev_Pop_Fields = {
  __typename?: 'VersionControl_stddev_pop_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type VersionControl_Stddev_Samp_Fields = {
  __typename?: 'VersionControl_stddev_samp_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "VersionControl" */
export type VersionControl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VersionControl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VersionControl_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  mobile_os?: InputMaybe<Scalars['DeviceType']['input']>;
  mobile_version?: InputMaybe<Scalars['Int']['input']>;
  screen_version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type VersionControl_Sum_Fields = {
  __typename?: 'VersionControl_sum_fields';
  mobile_version?: Maybe<Scalars['Int']['output']>;
  screen_version?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "VersionControl" */
export type VersionControl_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'mobile_os'
  /** column name */
  | 'mobile_version'
  /** column name */
  | 'screen_version';

export type VersionControl_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VersionControl_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VersionControl_Set_Input>;
  /** filter the rows which have to be updated */
  where: VersionControl_Bool_Exp;
};

/** aggregate var_pop on columns */
export type VersionControl_Var_Pop_Fields = {
  __typename?: 'VersionControl_var_pop_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type VersionControl_Var_Samp_Fields = {
  __typename?: 'VersionControl_var_samp_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VersionControl_Variance_Fields = {
  __typename?: 'VersionControl_variance_fields';
  mobile_version?: Maybe<Scalars['Float']['output']>;
  screen_version?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "WeeklyPlan" */
export type WeeklyPlan = {
  __typename?: 'WeeklyPlan';
  /** An array relationship */
  PlanDays: Array<PlanDay>;
  /** An aggregate relationship */
  PlanDays_aggregate: PlanDay_Aggregate;
  /** An object relationship */
  User: User;
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  cardio_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  coached_plan_id?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at: Scalars['timestamp']['output'];
  end_date: Scalars['date']['output'];
  hinge_level?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  lunge_level?: Maybe<Scalars['Int']['output']>;
  pear_plan?: Maybe<Scalars['jsonb']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  start_date: Scalars['date']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['String']['output'];
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  week: Scalars['Int']['output'];
};


/** columns and relationships of "WeeklyPlan" */
export type WeeklyPlanPlanDaysArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


/** columns and relationships of "WeeklyPlan" */
export type WeeklyPlanPlanDays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


/** columns and relationships of "WeeklyPlan" */
export type WeeklyPlanPear_PlanArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "WeeklyPlan" */
export type WeeklyPlan_Aggregate = {
  __typename?: 'WeeklyPlan_aggregate';
  aggregate?: Maybe<WeeklyPlan_Aggregate_Fields>;
  nodes: Array<WeeklyPlan>;
};

export type WeeklyPlan_Aggregate_Bool_Exp = {
  avg?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<WeeklyPlan_Aggregate_Bool_Exp_Var_Samp>;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Avg = {
  arguments: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Corr = {
  arguments: WeeklyPlan_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Corr_Arguments = {
  X: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Max = {
  arguments: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Min = {
  arguments: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Sum = {
  arguments: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type WeeklyPlan_Aggregate_Bool_Exp_Var_Samp = {
  arguments: WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WeeklyPlan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "WeeklyPlan" */
export type WeeklyPlan_Aggregate_Fields = {
  __typename?: 'WeeklyPlan_aggregate_fields';
  avg?: Maybe<WeeklyPlan_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<WeeklyPlan_Max_Fields>;
  min?: Maybe<WeeklyPlan_Min_Fields>;
  stddev?: Maybe<WeeklyPlan_Stddev_Fields>;
  stddev_pop?: Maybe<WeeklyPlan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeeklyPlan_Stddev_Samp_Fields>;
  sum?: Maybe<WeeklyPlan_Sum_Fields>;
  var_pop?: Maybe<WeeklyPlan_Var_Pop_Fields>;
  var_samp?: Maybe<WeeklyPlan_Var_Samp_Fields>;
  variance?: Maybe<WeeklyPlan_Variance_Fields>;
};


/** aggregate fields of "WeeklyPlan" */
export type WeeklyPlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "WeeklyPlan" */
export type WeeklyPlan_Aggregate_Order_By = {
  avg?: InputMaybe<WeeklyPlan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<WeeklyPlan_Max_Order_By>;
  min?: InputMaybe<WeeklyPlan_Min_Order_By>;
  stddev?: InputMaybe<WeeklyPlan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<WeeklyPlan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<WeeklyPlan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<WeeklyPlan_Sum_Order_By>;
  var_pop?: InputMaybe<WeeklyPlan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<WeeklyPlan_Var_Samp_Order_By>;
  variance?: InputMaybe<WeeklyPlan_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WeeklyPlan_Append_Input = {
  pear_plan?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "WeeklyPlan" */
export type WeeklyPlan_Arr_Rel_Insert_Input = {
  data: Array<WeeklyPlan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<WeeklyPlan_On_Conflict>;
};

/** aggregate avg on columns */
export type WeeklyPlan_Avg_Fields = {
  __typename?: 'WeeklyPlan_avg_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Avg_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WeeklyPlan". All fields are combined with a logical 'AND'. */
export type WeeklyPlan_Bool_Exp = {
  PlanDays?: InputMaybe<PlanDay_Bool_Exp>;
  PlanDays_aggregate?: InputMaybe<PlanDay_Aggregate_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<WeeklyPlan_Bool_Exp>>;
  _not?: InputMaybe<WeeklyPlan_Bool_Exp>;
  _or?: InputMaybe<Array<WeeklyPlan_Bool_Exp>>;
  brace_prone_level?: InputMaybe<Int_Comparison_Exp>;
  brace_side_level?: InputMaybe<Int_Comparison_Exp>;
  brace_supine_level?: InputMaybe<Int_Comparison_Exp>;
  cardio_fitness?: InputMaybe<GeneralRank_Comparison_Exp>;
  cardio_fitness_group?: InputMaybe<GeneralRankGroup_Comparison_Exp>;
  cardio_level?: InputMaybe<Int_Comparison_Exp>;
  coached_plan_id?: InputMaybe<String_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  hinge_level?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lunge_level?: InputMaybe<Int_Comparison_Exp>;
  pear_plan?: InputMaybe<Jsonb_Comparison_Exp>;
  pull_horizontal_level?: InputMaybe<Int_Comparison_Exp>;
  pull_vertical_level?: InputMaybe<Int_Comparison_Exp>;
  push_horizontal_level?: InputMaybe<Int_Comparison_Exp>;
  push_vertical_level?: InputMaybe<Int_Comparison_Exp>;
  squat_level?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  v02_max_raw?: InputMaybe<Float8_Comparison_Exp>;
  week?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "WeeklyPlan" */
export type WeeklyPlan_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'WeeklyPlan_pkey'
  /** unique or primary key constraint on columns "user_id", "start_date" */
  | 'WeeklyPlan_user_id_start_date_key';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WeeklyPlan_Delete_At_Path_Input = {
  pear_plan?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WeeklyPlan_Delete_Elem_Input = {
  pear_plan?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WeeklyPlan_Delete_Key_Input = {
  pear_plan?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "WeeklyPlan" */
export type WeeklyPlan_Inc_Input = {
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "WeeklyPlan" */
export type WeeklyPlan_Insert_Input = {
  PlanDays?: InputMaybe<PlanDay_Arr_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  cardio_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  coached_plan_id?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  pear_plan?: InputMaybe<Scalars['jsonb']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type WeeklyPlan_Max_Fields = {
  __typename?: 'WeeklyPlan_max_fields';
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  cardio_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  coached_plan_id?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  hinge_level?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lunge_level?: Maybe<Scalars['Int']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Max_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_fitness?: InputMaybe<Order_By>;
  cardio_fitness_group?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  coached_plan_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type WeeklyPlan_Min_Fields = {
  __typename?: 'WeeklyPlan_min_fields';
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_fitness?: Maybe<Scalars['GeneralRank']['output']>;
  cardio_fitness_group?: Maybe<Scalars['GeneralRankGroup']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  coached_plan_id?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  hinge_level?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lunge_level?: Maybe<Scalars['Int']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Min_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_fitness?: InputMaybe<Order_By>;
  cardio_fitness_group?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  coached_plan_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WeeklyPlan" */
export type WeeklyPlan_Mutation_Response = {
  __typename?: 'WeeklyPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<WeeklyPlan>;
};

/** input type for inserting object relation for remote table "WeeklyPlan" */
export type WeeklyPlan_Obj_Rel_Insert_Input = {
  data: WeeklyPlan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<WeeklyPlan_On_Conflict>;
};

/** on_conflict condition type for table "WeeklyPlan" */
export type WeeklyPlan_On_Conflict = {
  constraint: WeeklyPlan_Constraint;
  update_columns?: Array<WeeklyPlan_Update_Column>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};

/** Ordering options when selecting data from "WeeklyPlan". */
export type WeeklyPlan_Order_By = {
  PlanDays_aggregate?: InputMaybe<PlanDay_Aggregate_Order_By>;
  User?: InputMaybe<User_Order_By>;
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_fitness?: InputMaybe<Order_By>;
  cardio_fitness_group?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  coached_plan_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pear_plan?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WeeklyPlan */
export type WeeklyPlan_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WeeklyPlan_Prepend_Input = {
  pear_plan?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column =
  /** column name */
  | 'brace_prone_level'
  /** column name */
  | 'brace_side_level'
  /** column name */
  | 'brace_supine_level'
  /** column name */
  | 'cardio_fitness'
  /** column name */
  | 'cardio_fitness_group'
  /** column name */
  | 'cardio_level'
  /** column name */
  | 'coached_plan_id'
  /** column name */
  | 'completed_at'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_date'
  /** column name */
  | 'hinge_level'
  /** column name */
  | 'id'
  /** column name */
  | 'lunge_level'
  /** column name */
  | 'pear_plan'
  /** column name */
  | 'pull_horizontal_level'
  /** column name */
  | 'pull_vertical_level'
  /** column name */
  | 'push_horizontal_level'
  /** column name */
  | 'push_vertical_level'
  /** column name */
  | 'squat_level'
  /** column name */
  | 'start_date'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'week';

/** select "WeeklyPlan_aggregate_bool_exp_avg_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_corr_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_max_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_min_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_sum_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** select "WeeklyPlan_aggregate_bool_exp_var_samp_arguments_columns" columns of table "WeeklyPlan" */
export type WeeklyPlan_Select_Column_WeeklyPlan_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'v02_max_raw';

/** input type for updating data in table "WeeklyPlan" */
export type WeeklyPlan_Set_Input = {
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  cardio_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  coached_plan_id?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  pear_plan?: InputMaybe<Scalars['jsonb']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type WeeklyPlan_Stddev_Fields = {
  __typename?: 'WeeklyPlan_stddev_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Stddev_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type WeeklyPlan_Stddev_Pop_Fields = {
  __typename?: 'WeeklyPlan_stddev_pop_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Stddev_Pop_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type WeeklyPlan_Stddev_Samp_Fields = {
  __typename?: 'WeeklyPlan_stddev_samp_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Stddev_Samp_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "WeeklyPlan" */
export type WeeklyPlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WeeklyPlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WeeklyPlan_Stream_Cursor_Value_Input = {
  brace_prone_level?: InputMaybe<Scalars['Int']['input']>;
  brace_side_level?: InputMaybe<Scalars['Int']['input']>;
  brace_supine_level?: InputMaybe<Scalars['Int']['input']>;
  cardio_fitness?: InputMaybe<Scalars['GeneralRank']['input']>;
  cardio_fitness_group?: InputMaybe<Scalars['GeneralRankGroup']['input']>;
  cardio_level?: InputMaybe<Scalars['Int']['input']>;
  coached_plan_id?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  hinge_level?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lunge_level?: InputMaybe<Scalars['Int']['input']>;
  pear_plan?: InputMaybe<Scalars['jsonb']['input']>;
  pull_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  pull_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  push_horizontal_level?: InputMaybe<Scalars['Int']['input']>;
  push_vertical_level?: InputMaybe<Scalars['Int']['input']>;
  squat_level?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  v02_max_raw?: InputMaybe<Scalars['float8']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type WeeklyPlan_Sum_Fields = {
  __typename?: 'WeeklyPlan_sum_fields';
  brace_prone_level?: Maybe<Scalars['Int']['output']>;
  brace_side_level?: Maybe<Scalars['Int']['output']>;
  brace_supine_level?: Maybe<Scalars['Int']['output']>;
  cardio_level?: Maybe<Scalars['Int']['output']>;
  hinge_level?: Maybe<Scalars['Int']['output']>;
  lunge_level?: Maybe<Scalars['Int']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Int']['output']>;
  pull_vertical_level?: Maybe<Scalars['Int']['output']>;
  push_horizontal_level?: Maybe<Scalars['Int']['output']>;
  push_vertical_level?: Maybe<Scalars['Int']['output']>;
  squat_level?: Maybe<Scalars['Int']['output']>;
  v02_max_raw?: Maybe<Scalars['float8']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Sum_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** update columns of table "WeeklyPlan" */
export type WeeklyPlan_Update_Column =
  /** column name */
  | 'brace_prone_level'
  /** column name */
  | 'brace_side_level'
  /** column name */
  | 'brace_supine_level'
  /** column name */
  | 'cardio_fitness'
  /** column name */
  | 'cardio_fitness_group'
  /** column name */
  | 'cardio_level'
  /** column name */
  | 'coached_plan_id'
  /** column name */
  | 'completed_at'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_date'
  /** column name */
  | 'hinge_level'
  /** column name */
  | 'id'
  /** column name */
  | 'lunge_level'
  /** column name */
  | 'pear_plan'
  /** column name */
  | 'pull_horizontal_level'
  /** column name */
  | 'pull_vertical_level'
  /** column name */
  | 'push_horizontal_level'
  /** column name */
  | 'push_vertical_level'
  /** column name */
  | 'squat_level'
  /** column name */
  | 'start_date'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id'
  /** column name */
  | 'v02_max_raw'
  /** column name */
  | 'week';

export type WeeklyPlan_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WeeklyPlan_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WeeklyPlan_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WeeklyPlan_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WeeklyPlan_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WeeklyPlan_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WeeklyPlan_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WeeklyPlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: WeeklyPlan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type WeeklyPlan_Var_Pop_Fields = {
  __typename?: 'WeeklyPlan_var_pop_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Var_Pop_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type WeeklyPlan_Var_Samp_Fields = {
  __typename?: 'WeeklyPlan_var_samp_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Var_Samp_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type WeeklyPlan_Variance_Fields = {
  __typename?: 'WeeklyPlan_variance_fields';
  brace_prone_level?: Maybe<Scalars['Float']['output']>;
  brace_side_level?: Maybe<Scalars['Float']['output']>;
  brace_supine_level?: Maybe<Scalars['Float']['output']>;
  cardio_level?: Maybe<Scalars['Float']['output']>;
  hinge_level?: Maybe<Scalars['Float']['output']>;
  lunge_level?: Maybe<Scalars['Float']['output']>;
  pull_horizontal_level?: Maybe<Scalars['Float']['output']>;
  pull_vertical_level?: Maybe<Scalars['Float']['output']>;
  push_horizontal_level?: Maybe<Scalars['Float']['output']>;
  push_vertical_level?: Maybe<Scalars['Float']['output']>;
  squat_level?: Maybe<Scalars['Float']['output']>;
  v02_max_raw?: Maybe<Scalars['Float']['output']>;
  week?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "WeeklyPlan" */
export type WeeklyPlan_Variance_Order_By = {
  brace_prone_level?: InputMaybe<Order_By>;
  brace_side_level?: InputMaybe<Order_By>;
  brace_supine_level?: InputMaybe<Order_By>;
  cardio_level?: InputMaybe<Order_By>;
  hinge_level?: InputMaybe<Order_By>;
  lunge_level?: InputMaybe<Order_By>;
  pull_horizontal_level?: InputMaybe<Order_By>;
  pull_vertical_level?: InputMaybe<Order_By>;
  push_horizontal_level?: InputMaybe<Order_By>;
  push_vertical_level?: InputMaybe<Order_By>;
  squat_level?: InputMaybe<Order_By>;
  v02_max_raw?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

export type WorkIoWithEpoch = {
  __typename?: 'WorkIOWithEpoch';
  completedEpoch?: Maybe<Scalars['Int']['output']>;
  workout_type?: Maybe<WorkoutTypeEnum>;
};

/** columns and relationships of "WorkinPrefrence" */
export type WorkinPrefrence = {
  __typename?: 'WorkinPrefrence';
  /** An object relationship */
  UserPreference: UserPreferences;
  breath_work?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  meditation?: Maybe<Scalars['Boolean']['output']>;
  sound_bath?: Maybe<Scalars['Boolean']['output']>;
  user_prefrence_id: Scalars['String']['output'];
  yoga?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkinPrefrenceInput = {
  breath_work?: InputMaybe<Scalars['Boolean']['input']>;
  meditation?: InputMaybe<Scalars['Boolean']['input']>;
  sound_bath?: InputMaybe<Scalars['Boolean']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkinPrefrenceOutput = {
  __typename?: 'WorkinPrefrenceOutput';
  breath_work?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meditation?: Maybe<Scalars['Boolean']['output']>;
  sound_bath?: Maybe<Scalars['Boolean']['output']>;
  user_prefrence_id?: Maybe<Scalars['String']['output']>;
  yoga?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "WorkinPrefrence" */
export type WorkinPrefrence_Aggregate = {
  __typename?: 'WorkinPrefrence_aggregate';
  aggregate?: Maybe<WorkinPrefrence_Aggregate_Fields>;
  nodes: Array<WorkinPrefrence>;
};

export type WorkinPrefrence_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<WorkinPrefrence_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<WorkinPrefrence_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<WorkinPrefrence_Aggregate_Bool_Exp_Count>;
};

export type WorkinPrefrence_Aggregate_Bool_Exp_Bool_And = {
  arguments: WorkinPrefrence_Select_Column_WorkinPrefrence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WorkinPrefrence_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type WorkinPrefrence_Aggregate_Bool_Exp_Bool_Or = {
  arguments: WorkinPrefrence_Select_Column_WorkinPrefrence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WorkinPrefrence_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type WorkinPrefrence_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WorkinPrefrence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WorkinPrefrence" */
export type WorkinPrefrence_Aggregate_Fields = {
  __typename?: 'WorkinPrefrence_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<WorkinPrefrence_Max_Fields>;
  min?: Maybe<WorkinPrefrence_Min_Fields>;
};


/** aggregate fields of "WorkinPrefrence" */
export type WorkinPrefrence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "WorkinPrefrence" */
export type WorkinPrefrence_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<WorkinPrefrence_Max_Order_By>;
  min?: InputMaybe<WorkinPrefrence_Min_Order_By>;
};

/** input type for inserting array relation for remote table "WorkinPrefrence" */
export type WorkinPrefrence_Arr_Rel_Insert_Input = {
  data: Array<WorkinPrefrence_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<WorkinPrefrence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "WorkinPrefrence". All fields are combined with a logical 'AND'. */
export type WorkinPrefrence_Bool_Exp = {
  UserPreference?: InputMaybe<UserPreferences_Bool_Exp>;
  _and?: InputMaybe<Array<WorkinPrefrence_Bool_Exp>>;
  _not?: InputMaybe<WorkinPrefrence_Bool_Exp>;
  _or?: InputMaybe<Array<WorkinPrefrence_Bool_Exp>>;
  breath_work?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  meditation?: InputMaybe<Boolean_Comparison_Exp>;
  sound_bath?: InputMaybe<Boolean_Comparison_Exp>;
  user_prefrence_id?: InputMaybe<String_Comparison_Exp>;
  yoga?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "WorkinPrefrence" */
export type WorkinPrefrence_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'WorkinPrefrence_pkey'
  /** unique or primary key constraint on columns "user_prefrence_id" */
  | 'WorkinPrefrence_user_prefrence_id_key';

/** input type for inserting data into table "WorkinPrefrence" */
export type WorkinPrefrence_Insert_Input = {
  UserPreference?: InputMaybe<UserPreferences_Obj_Rel_Insert_Input>;
  breath_work?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meditation?: InputMaybe<Scalars['Boolean']['input']>;
  sound_bath?: InputMaybe<Scalars['Boolean']['input']>;
  user_prefrence_id?: InputMaybe<Scalars['String']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type WorkinPrefrence_Max_Fields = {
  __typename?: 'WorkinPrefrence_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  user_prefrence_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "WorkinPrefrence" */
export type WorkinPrefrence_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  user_prefrence_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type WorkinPrefrence_Min_Fields = {
  __typename?: 'WorkinPrefrence_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  user_prefrence_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "WorkinPrefrence" */
export type WorkinPrefrence_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  user_prefrence_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WorkinPrefrence" */
export type WorkinPrefrence_Mutation_Response = {
  __typename?: 'WorkinPrefrence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkinPrefrence>;
};

/** on_conflict condition type for table "WorkinPrefrence" */
export type WorkinPrefrence_On_Conflict = {
  constraint: WorkinPrefrence_Constraint;
  update_columns?: Array<WorkinPrefrence_Update_Column>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};

/** Ordering options when selecting data from "WorkinPrefrence". */
export type WorkinPrefrence_Order_By = {
  UserPreference?: InputMaybe<UserPreferences_Order_By>;
  breath_work?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meditation?: InputMaybe<Order_By>;
  sound_bath?: InputMaybe<Order_By>;
  user_prefrence_id?: InputMaybe<Order_By>;
  yoga?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WorkinPrefrence */
export type WorkinPrefrence_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "WorkinPrefrence" */
export type WorkinPrefrence_Select_Column =
  /** column name */
  | 'breath_work'
  /** column name */
  | 'id'
  /** column name */
  | 'meditation'
  /** column name */
  | 'sound_bath'
  /** column name */
  | 'user_prefrence_id'
  /** column name */
  | 'yoga';

/** select "WorkinPrefrence_aggregate_bool_exp_bool_and_arguments_columns" columns of table "WorkinPrefrence" */
export type WorkinPrefrence_Select_Column_WorkinPrefrence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'breath_work'
  /** column name */
  | 'meditation'
  /** column name */
  | 'sound_bath'
  /** column name */
  | 'yoga';

/** select "WorkinPrefrence_aggregate_bool_exp_bool_or_arguments_columns" columns of table "WorkinPrefrence" */
export type WorkinPrefrence_Select_Column_WorkinPrefrence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'breath_work'
  /** column name */
  | 'meditation'
  /** column name */
  | 'sound_bath'
  /** column name */
  | 'yoga';

/** input type for updating data in table "WorkinPrefrence" */
export type WorkinPrefrence_Set_Input = {
  breath_work?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meditation?: InputMaybe<Scalars['Boolean']['input']>;
  sound_bath?: InputMaybe<Scalars['Boolean']['input']>;
  user_prefrence_id?: InputMaybe<Scalars['String']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "WorkinPrefrence" */
export type WorkinPrefrence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkinPrefrence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkinPrefrence_Stream_Cursor_Value_Input = {
  breath_work?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meditation?: InputMaybe<Scalars['Boolean']['input']>;
  sound_bath?: InputMaybe<Scalars['Boolean']['input']>;
  user_prefrence_id?: InputMaybe<Scalars['String']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "WorkinPrefrence" */
export type WorkinPrefrence_Update_Column =
  /** column name */
  | 'breath_work'
  /** column name */
  | 'id'
  /** column name */
  | 'meditation'
  /** column name */
  | 'sound_bath'
  /** column name */
  | 'user_prefrence_id'
  /** column name */
  | 'yoga';

export type WorkinPrefrence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkinPrefrence_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkinPrefrence_Bool_Exp;
};

export type WorkinWorkoutEnum =
  | 'workin'
  | 'workout';

/** columns and relationships of "Workout" */
export type Workout = {
  __typename?: 'Workout';
  /** An object relationship */
  Pro?: Maybe<Pro>;
  ProId?: Maybe<Scalars['String']['output']>;
  applicable_levels?: Maybe<Array<Scalars['String']['output']>>;
  audioUrl?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  equipments?: Maybe<Array<Scalars['String']['output']>>;
  extended_content?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isAdaptive: Scalars['Boolean']['output'];
  isDemo: Scalars['Boolean']['output'];
  limitations?: Maybe<Array<Scalars['String']['output']>>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  skillLevel?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  sub_type: Scalars['SubWorkoutType']['output'];
  tag?: Maybe<Array<Scalars['String']['output']>>;
  themes?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  trainer?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  /** An array relationship */
  userWorkoutsByWorkoutid: Array<UserWorkout>;
  /** An aggregate relationship */
  userWorkoutsByWorkoutid_aggregate: UserWorkout_Aggregate;
  videoUrl?: Maybe<Scalars['String']['output']>;
  video_duration_seconds?: Maybe<Scalars['Int']['output']>;
  workout_type: Scalars['WorkoutType']['output'];
};


/** columns and relationships of "Workout" */
export type WorkoutExtended_ContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "Workout" */
export type WorkoutUserWorkoutsByWorkoutidArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


/** columns and relationships of "Workout" */
export type WorkoutUserWorkoutsByWorkoutid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};

export type WorkoutCategoryEnum =
  | 'BraceProne'
  | 'BraceSide'
  | 'BraceSupine'
  | 'Breathwork'
  | 'Combat'
  | 'Dance'
  | 'FoamRolling'
  | 'Hinge'
  | 'Interval'
  | 'Lunge'
  | 'Meditation'
  | 'Mobility'
  | 'PullHorizontal'
  | 'PullVertical'
  | 'PushHorizontal'
  | 'PushVertical'
  | 'RestorativeYoga'
  | 'Squat'
  | 'Yoga';

/** Boolean expression to compare columns of type "WorkoutCategory". All fields are combined with logical 'AND'. */
export type WorkoutCategory_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  _gt?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  _gte?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  _in?: InputMaybe<Array<Scalars['WorkoutCategory']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  _lte?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  _neq?: InputMaybe<Scalars['WorkoutCategory']['input']>;
  _nin?: InputMaybe<Array<Scalars['WorkoutCategory']['input']>>;
};

/** columns and relationships of "WorkoutPrefrence" */
export type WorkoutPrefrence = {
  __typename?: 'WorkoutPrefrence';
  /** An object relationship */
  UserPreference: UserPreferences;
  combat?: Maybe<Scalars['Boolean']['output']>;
  dance?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  interval?: Maybe<Scalars['Boolean']['output']>;
  user_prefrence_id: Scalars['String']['output'];
  yoga?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkoutPrefrenceInput = {
  combat?: InputMaybe<Scalars['Boolean']['input']>;
  dance?: InputMaybe<Scalars['Boolean']['input']>;
  interval?: InputMaybe<Scalars['Boolean']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkoutPrefrenceOutput = {
  __typename?: 'WorkoutPrefrenceOutput';
  combat?: Maybe<Scalars['Boolean']['output']>;
  dance?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['Boolean']['output']>;
  user_prefrence_id?: Maybe<Scalars['String']['output']>;
  yoga?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "WorkoutPrefrence" */
export type WorkoutPrefrence_Aggregate = {
  __typename?: 'WorkoutPrefrence_aggregate';
  aggregate?: Maybe<WorkoutPrefrence_Aggregate_Fields>;
  nodes: Array<WorkoutPrefrence>;
};

export type WorkoutPrefrence_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<WorkoutPrefrence_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<WorkoutPrefrence_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<WorkoutPrefrence_Aggregate_Bool_Exp_Count>;
};

export type WorkoutPrefrence_Aggregate_Bool_Exp_Bool_And = {
  arguments: WorkoutPrefrence_Select_Column_WorkoutPrefrence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type WorkoutPrefrence_Aggregate_Bool_Exp_Bool_Or = {
  arguments: WorkoutPrefrence_Select_Column_WorkoutPrefrence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type WorkoutPrefrence_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "WorkoutPrefrence" */
export type WorkoutPrefrence_Aggregate_Fields = {
  __typename?: 'WorkoutPrefrence_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<WorkoutPrefrence_Max_Fields>;
  min?: Maybe<WorkoutPrefrence_Min_Fields>;
};


/** aggregate fields of "WorkoutPrefrence" */
export type WorkoutPrefrence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<WorkoutPrefrence_Max_Order_By>;
  min?: InputMaybe<WorkoutPrefrence_Min_Order_By>;
};

/** input type for inserting array relation for remote table "WorkoutPrefrence" */
export type WorkoutPrefrence_Arr_Rel_Insert_Input = {
  data: Array<WorkoutPrefrence_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<WorkoutPrefrence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "WorkoutPrefrence". All fields are combined with a logical 'AND'. */
export type WorkoutPrefrence_Bool_Exp = {
  UserPreference?: InputMaybe<UserPreferences_Bool_Exp>;
  _and?: InputMaybe<Array<WorkoutPrefrence_Bool_Exp>>;
  _not?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
  _or?: InputMaybe<Array<WorkoutPrefrence_Bool_Exp>>;
  combat?: InputMaybe<Boolean_Comparison_Exp>;
  dance?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  interval?: InputMaybe<Boolean_Comparison_Exp>;
  user_prefrence_id?: InputMaybe<String_Comparison_Exp>;
  yoga?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "WorkoutPrefrence" */
export type WorkoutPrefrence_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'WorkoutPrefrence_pkey'
  /** unique or primary key constraint on columns "user_prefrence_id" */
  | 'WorkoutPrefrence_user_prefrence_id_key';

/** input type for inserting data into table "WorkoutPrefrence" */
export type WorkoutPrefrence_Insert_Input = {
  UserPreference?: InputMaybe<UserPreferences_Obj_Rel_Insert_Input>;
  combat?: InputMaybe<Scalars['Boolean']['input']>;
  dance?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['Boolean']['input']>;
  user_prefrence_id?: InputMaybe<Scalars['String']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type WorkoutPrefrence_Max_Fields = {
  __typename?: 'WorkoutPrefrence_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  user_prefrence_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  user_prefrence_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type WorkoutPrefrence_Min_Fields = {
  __typename?: 'WorkoutPrefrence_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  user_prefrence_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  user_prefrence_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "WorkoutPrefrence" */
export type WorkoutPrefrence_Mutation_Response = {
  __typename?: 'WorkoutPrefrence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkoutPrefrence>;
};

/** on_conflict condition type for table "WorkoutPrefrence" */
export type WorkoutPrefrence_On_Conflict = {
  constraint: WorkoutPrefrence_Constraint;
  update_columns?: Array<WorkoutPrefrence_Update_Column>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};

/** Ordering options when selecting data from "WorkoutPrefrence". */
export type WorkoutPrefrence_Order_By = {
  UserPreference?: InputMaybe<UserPreferences_Order_By>;
  combat?: InputMaybe<Order_By>;
  dance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  user_prefrence_id?: InputMaybe<Order_By>;
  yoga?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WorkoutPrefrence */
export type WorkoutPrefrence_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Select_Column =
  /** column name */
  | 'combat'
  /** column name */
  | 'dance'
  /** column name */
  | 'id'
  /** column name */
  | 'interval'
  /** column name */
  | 'user_prefrence_id'
  /** column name */
  | 'yoga';

/** select "WorkoutPrefrence_aggregate_bool_exp_bool_and_arguments_columns" columns of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Select_Column_WorkoutPrefrence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'combat'
  /** column name */
  | 'dance'
  /** column name */
  | 'interval'
  /** column name */
  | 'yoga';

/** select "WorkoutPrefrence_aggregate_bool_exp_bool_or_arguments_columns" columns of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Select_Column_WorkoutPrefrence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'combat'
  /** column name */
  | 'dance'
  /** column name */
  | 'interval'
  /** column name */
  | 'yoga';

/** input type for updating data in table "WorkoutPrefrence" */
export type WorkoutPrefrence_Set_Input = {
  combat?: InputMaybe<Scalars['Boolean']['input']>;
  dance?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['Boolean']['input']>;
  user_prefrence_id?: InputMaybe<Scalars['String']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "WorkoutPrefrence" */
export type WorkoutPrefrence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkoutPrefrence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkoutPrefrence_Stream_Cursor_Value_Input = {
  combat?: InputMaybe<Scalars['Boolean']['input']>;
  dance?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['Boolean']['input']>;
  user_prefrence_id?: InputMaybe<Scalars['String']['input']>;
  yoga?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "WorkoutPrefrence" */
export type WorkoutPrefrence_Update_Column =
  /** column name */
  | 'combat'
  /** column name */
  | 'dance'
  /** column name */
  | 'id'
  /** column name */
  | 'interval'
  /** column name */
  | 'user_prefrence_id'
  /** column name */
  | 'yoga';

export type WorkoutPrefrence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkoutPrefrence_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkoutPrefrence_Bool_Exp;
};

export type WorkoutSubTypeEnum =
  | 'balanced'
  | 'bodyweight'
  | 'breathwork'
  | 'combat'
  | 'core'
  | 'custom'
  | 'dance'
  | 'interval'
  | 'lower'
  | 'meditation'
  | 'mixed'
  | 'mobility'
  | 'restorative_yoga'
  | 'stretch'
  | 'upper'
  | 'yoga';

export type WorkoutTypeEnum =
  | 'cardio'
  | 'strength'
  | 'workin';

/** Boolean expression to compare columns of type "WorkoutType". All fields are combined with logical 'AND'. */
export type WorkoutType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['WorkoutType']['input']>;
  _gt?: InputMaybe<Scalars['WorkoutType']['input']>;
  _gte?: InputMaybe<Scalars['WorkoutType']['input']>;
  _in?: InputMaybe<Array<Scalars['WorkoutType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['WorkoutType']['input']>;
  _lte?: InputMaybe<Scalars['WorkoutType']['input']>;
  _neq?: InputMaybe<Scalars['WorkoutType']['input']>;
  _nin?: InputMaybe<Array<Scalars['WorkoutType']['input']>>;
};

/** aggregated selection of "Workout" */
export type Workout_Aggregate = {
  __typename?: 'Workout_aggregate';
  aggregate?: Maybe<Workout_Aggregate_Fields>;
  nodes: Array<Workout>;
};

export type Workout_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Workout_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Workout_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Workout_Aggregate_Bool_Exp_Count>;
};

export type Workout_Aggregate_Bool_Exp_Bool_And = {
  arguments: Workout_Select_Column_Workout_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Workout_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Workout_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Workout_Select_Column_Workout_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Workout_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Workout_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Workout_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Workout" */
export type Workout_Aggregate_Fields = {
  __typename?: 'Workout_aggregate_fields';
  avg?: Maybe<Workout_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Workout_Max_Fields>;
  min?: Maybe<Workout_Min_Fields>;
  stddev?: Maybe<Workout_Stddev_Fields>;
  stddev_pop?: Maybe<Workout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workout_Stddev_Samp_Fields>;
  sum?: Maybe<Workout_Sum_Fields>;
  var_pop?: Maybe<Workout_Var_Pop_Fields>;
  var_samp?: Maybe<Workout_Var_Samp_Fields>;
  variance?: Maybe<Workout_Variance_Fields>;
};


/** aggregate fields of "Workout" */
export type Workout_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Workout" */
export type Workout_Aggregate_Order_By = {
  avg?: InputMaybe<Workout_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workout_Max_Order_By>;
  min?: InputMaybe<Workout_Min_Order_By>;
  stddev?: InputMaybe<Workout_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workout_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workout_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workout_Sum_Order_By>;
  var_pop?: InputMaybe<Workout_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workout_Var_Samp_Order_By>;
  variance?: InputMaybe<Workout_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workout_Append_Input = {
  extended_content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "Workout" */
export type Workout_Arr_Rel_Insert_Input = {
  data: Array<Workout_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workout_On_Conflict>;
};

/** aggregate avg on columns */
export type Workout_Avg_Fields = {
  __typename?: 'Workout_avg_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "Workout" */
export type Workout_Avg_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Workout". All fields are combined with a logical 'AND'. */
export type Workout_Bool_Exp = {
  Pro?: InputMaybe<Pro_Bool_Exp>;
  ProId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Workout_Bool_Exp>>;
  _not?: InputMaybe<Workout_Bool_Exp>;
  _or?: InputMaybe<Array<Workout_Bool_Exp>>;
  applicable_levels?: InputMaybe<String_Array_Comparison_Exp>;
  audioUrl?: InputMaybe<String_Comparison_Exp>;
  categoryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<String_Comparison_Exp>;
  duration_minutes?: InputMaybe<Int_Comparison_Exp>;
  equipments?: InputMaybe<String_Array_Comparison_Exp>;
  extended_content?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isAdaptive?: InputMaybe<Boolean_Comparison_Exp>;
  isDemo?: InputMaybe<Boolean_Comparison_Exp>;
  limitations?: InputMaybe<String_Array_Comparison_Exp>;
  preferredWorkoutLength?: InputMaybe<Int_Comparison_Exp>;
  previewUrl?: InputMaybe<String_Comparison_Exp>;
  skillLevel?: InputMaybe<String_Comparison_Exp>;
  sortOrder?: InputMaybe<Int_Comparison_Exp>;
  sub_type?: InputMaybe<SubWorkoutType_Comparison_Exp>;
  tag?: InputMaybe<String_Array_Comparison_Exp>;
  themes?: InputMaybe<String_Array_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  trainer?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userWorkoutsByWorkoutid?: InputMaybe<UserWorkout_Bool_Exp>;
  userWorkoutsByWorkoutid_aggregate?: InputMaybe<UserWorkout_Aggregate_Bool_Exp>;
  videoUrl?: InputMaybe<String_Comparison_Exp>;
  video_duration_seconds?: InputMaybe<Int_Comparison_Exp>;
  workout_type?: InputMaybe<WorkoutType_Comparison_Exp>;
};

/** unique or primary key constraints on table "Workout" */
export type Workout_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Workout_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workout_Delete_At_Path_Input = {
  extended_content?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workout_Delete_Elem_Input = {
  extended_content?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workout_Delete_Key_Input = {
  extended_content?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "Workout" */
export type Workout_Inc_Input = {
  duration_minutes?: InputMaybe<Scalars['Int']['input']>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  video_duration_seconds?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "Workout" */
export type Workout_Insert_Input = {
  Pro?: InputMaybe<Pro_Obj_Rel_Insert_Input>;
  ProId?: InputMaybe<Scalars['String']['input']>;
  applicable_levels?: InputMaybe<Array<Scalars['String']['input']>>;
  audioUrl?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  duration_minutes?: InputMaybe<Scalars['Int']['input']>;
  equipments?: InputMaybe<Array<Scalars['String']['input']>>;
  extended_content?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdaptive?: InputMaybe<Scalars['Boolean']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  limitations?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  tag?: InputMaybe<Array<Scalars['String']['input']>>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainer?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userWorkoutsByWorkoutid?: InputMaybe<UserWorkout_Arr_Rel_Insert_Input>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  video_duration_seconds?: InputMaybe<Scalars['Int']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
};

/** aggregate max on columns */
export type Workout_Max_Fields = {
  __typename?: 'Workout_max_fields';
  ProId?: Maybe<Scalars['String']['output']>;
  applicable_levels?: Maybe<Array<Scalars['String']['output']>>;
  audioUrl?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  equipments?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  limitations?: Maybe<Array<Scalars['String']['output']>>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  skillLevel?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  tag?: Maybe<Array<Scalars['String']['output']>>;
  themes?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  trainer?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  video_duration_seconds?: Maybe<Scalars['Int']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
};

/** order by max() on columns of table "Workout" */
export type Workout_Max_Order_By = {
  ProId?: InputMaybe<Order_By>;
  applicable_levels?: InputMaybe<Order_By>;
  audioUrl?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_minutes?: InputMaybe<Order_By>;
  equipments?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  limitations?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  previewUrl?: InputMaybe<Order_By>;
  skillLevel?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  themes?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  trainer?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videoUrl?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workout_Min_Fields = {
  __typename?: 'Workout_min_fields';
  ProId?: Maybe<Scalars['String']['output']>;
  applicable_levels?: Maybe<Array<Scalars['String']['output']>>;
  audioUrl?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  equipments?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  limitations?: Maybe<Array<Scalars['String']['output']>>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  skillLevel?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  sub_type?: Maybe<Scalars['SubWorkoutType']['output']>;
  tag?: Maybe<Array<Scalars['String']['output']>>;
  themes?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  trainer?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  video_duration_seconds?: Maybe<Scalars['Int']['output']>;
  workout_type?: Maybe<Scalars['WorkoutType']['output']>;
};

/** order by min() on columns of table "Workout" */
export type Workout_Min_Order_By = {
  ProId?: InputMaybe<Order_By>;
  applicable_levels?: InputMaybe<Order_By>;
  audioUrl?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_minutes?: InputMaybe<Order_By>;
  equipments?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  limitations?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  previewUrl?: InputMaybe<Order_By>;
  skillLevel?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  themes?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  trainer?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  videoUrl?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Workout" */
export type Workout_Mutation_Response = {
  __typename?: 'Workout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Workout>;
};

/** input type for inserting object relation for remote table "Workout" */
export type Workout_Obj_Rel_Insert_Input = {
  data: Workout_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workout_On_Conflict>;
};

/** on_conflict condition type for table "Workout" */
export type Workout_On_Conflict = {
  constraint: Workout_Constraint;
  update_columns?: Array<Workout_Update_Column>;
  where?: InputMaybe<Workout_Bool_Exp>;
};

/** Ordering options when selecting data from "Workout". */
export type Workout_Order_By = {
  Pro?: InputMaybe<Pro_Order_By>;
  ProId?: InputMaybe<Order_By>;
  applicable_levels?: InputMaybe<Order_By>;
  audioUrl?: InputMaybe<Order_By>;
  categoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_minutes?: InputMaybe<Order_By>;
  equipments?: InputMaybe<Order_By>;
  extended_content?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isAdaptive?: InputMaybe<Order_By>;
  isDemo?: InputMaybe<Order_By>;
  limitations?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  previewUrl?: InputMaybe<Order_By>;
  skillLevel?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  themes?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  trainer?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userWorkoutsByWorkoutid_aggregate?: InputMaybe<UserWorkout_Aggregate_Order_By>;
  videoUrl?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
  workout_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Workout */
export type Workout_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workout_Prepend_Input = {
  extended_content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "Workout" */
export type Workout_Select_Column =
  /** column name */
  | 'ProId'
  /** column name */
  | 'applicable_levels'
  /** column name */
  | 'audioUrl'
  /** column name */
  | 'categoryId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'duration'
  /** column name */
  | 'duration_minutes'
  /** column name */
  | 'equipments'
  /** column name */
  | 'extended_content'
  /** column name */
  | 'id'
  /** column name */
  | 'imageUrl'
  /** column name */
  | 'isActive'
  /** column name */
  | 'isAdaptive'
  /** column name */
  | 'isDemo'
  /** column name */
  | 'limitations'
  /** column name */
  | 'preferredWorkoutLength'
  /** column name */
  | 'previewUrl'
  /** column name */
  | 'skillLevel'
  /** column name */
  | 'sortOrder'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'tag'
  /** column name */
  | 'themes'
  /** column name */
  | 'title'
  /** column name */
  | 'trainer'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'videoUrl'
  /** column name */
  | 'video_duration_seconds'
  /** column name */
  | 'workout_type';

/** select "Workout_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Workout" */
export type Workout_Select_Column_Workout_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'isActive'
  /** column name */
  | 'isAdaptive'
  /** column name */
  | 'isDemo';

/** select "Workout_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Workout" */
export type Workout_Select_Column_Workout_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'isActive'
  /** column name */
  | 'isAdaptive'
  /** column name */
  | 'isDemo';

/** input type for updating data in table "Workout" */
export type Workout_Set_Input = {
  ProId?: InputMaybe<Scalars['String']['input']>;
  applicable_levels?: InputMaybe<Array<Scalars['String']['input']>>;
  audioUrl?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  duration_minutes?: InputMaybe<Scalars['Int']['input']>;
  equipments?: InputMaybe<Array<Scalars['String']['input']>>;
  extended_content?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdaptive?: InputMaybe<Scalars['Boolean']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  limitations?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  tag?: InputMaybe<Array<Scalars['String']['input']>>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainer?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  video_duration_seconds?: InputMaybe<Scalars['Int']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
};

/** aggregate stddev on columns */
export type Workout_Stddev_Fields = {
  __typename?: 'Workout_stddev_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "Workout" */
export type Workout_Stddev_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workout_Stddev_Pop_Fields = {
  __typename?: 'Workout_stddev_pop_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "Workout" */
export type Workout_Stddev_Pop_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workout_Stddev_Samp_Fields = {
  __typename?: 'Workout_stddev_samp_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "Workout" */
export type Workout_Stddev_Samp_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Workout" */
export type Workout_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workout_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workout_Stream_Cursor_Value_Input = {
  ProId?: InputMaybe<Scalars['String']['input']>;
  applicable_levels?: InputMaybe<Array<Scalars['String']['input']>>;
  audioUrl?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  duration_minutes?: InputMaybe<Scalars['Int']['input']>;
  equipments?: InputMaybe<Array<Scalars['String']['input']>>;
  extended_content?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdaptive?: InputMaybe<Scalars['Boolean']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  limitations?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredWorkoutLength?: InputMaybe<Scalars['Int']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  sub_type?: InputMaybe<Scalars['SubWorkoutType']['input']>;
  tag?: InputMaybe<Array<Scalars['String']['input']>>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainer?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  video_duration_seconds?: InputMaybe<Scalars['Int']['input']>;
  workout_type?: InputMaybe<Scalars['WorkoutType']['input']>;
};

/** aggregate sum on columns */
export type Workout_Sum_Fields = {
  __typename?: 'Workout_sum_fields';
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Int']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  video_duration_seconds?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "Workout" */
export type Workout_Sum_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** update columns of table "Workout" */
export type Workout_Update_Column =
  /** column name */
  | 'ProId'
  /** column name */
  | 'applicable_levels'
  /** column name */
  | 'audioUrl'
  /** column name */
  | 'categoryId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'duration'
  /** column name */
  | 'duration_minutes'
  /** column name */
  | 'equipments'
  /** column name */
  | 'extended_content'
  /** column name */
  | 'id'
  /** column name */
  | 'imageUrl'
  /** column name */
  | 'isActive'
  /** column name */
  | 'isAdaptive'
  /** column name */
  | 'isDemo'
  /** column name */
  | 'limitations'
  /** column name */
  | 'preferredWorkoutLength'
  /** column name */
  | 'previewUrl'
  /** column name */
  | 'skillLevel'
  /** column name */
  | 'sortOrder'
  /** column name */
  | 'sub_type'
  /** column name */
  | 'tag'
  /** column name */
  | 'themes'
  /** column name */
  | 'title'
  /** column name */
  | 'trainer'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'videoUrl'
  /** column name */
  | 'video_duration_seconds'
  /** column name */
  | 'workout_type';

export type Workout_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Workout_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Workout_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Workout_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Workout_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workout_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Workout_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workout_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workout_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workout_Var_Pop_Fields = {
  __typename?: 'Workout_var_pop_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "Workout" */
export type Workout_Var_Pop_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workout_Var_Samp_Fields = {
  __typename?: 'Workout_var_samp_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "Workout" */
export type Workout_Var_Samp_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workout_Variance_Fields = {
  __typename?: 'Workout_variance_fields';
  duration_minutes?: Maybe<Scalars['Float']['output']>;
  preferredWorkoutLength?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  video_duration_seconds?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "Workout" */
export type Workout_Variance_Order_By = {
  duration_minutes?: InputMaybe<Order_By>;
  preferredWorkoutLength?: InputMaybe<Order_By>;
  sortOrder?: InputMaybe<Order_By>;
  video_duration_seconds?: InputMaybe<Order_By>;
};

/** columns and relationships of "_FollowingPro" */
export type _FollowingPro = {
  __typename?: '_FollowingPro';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  Pro: Pro;
  /** An object relationship */
  User: User;
};

/** aggregated selection of "_FollowingPro" */
export type _FollowingPro_Aggregate = {
  __typename?: '_FollowingPro_aggregate';
  aggregate?: Maybe<_FollowingPro_Aggregate_Fields>;
  nodes: Array<_FollowingPro>;
};

export type _FollowingPro_Aggregate_Bool_Exp = {
  count?: InputMaybe<_FollowingPro_Aggregate_Bool_Exp_Count>;
};

export type _FollowingPro_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<_FollowingPro_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "_FollowingPro" */
export type _FollowingPro_Aggregate_Fields = {
  __typename?: '_FollowingPro_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_FollowingPro_Max_Fields>;
  min?: Maybe<_FollowingPro_Min_Fields>;
};


/** aggregate fields of "_FollowingPro" */
export type _FollowingPro_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "_FollowingPro" */
export type _FollowingPro_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<_FollowingPro_Max_Order_By>;
  min?: InputMaybe<_FollowingPro_Min_Order_By>;
};

/** input type for inserting array relation for remote table "_FollowingPro" */
export type _FollowingPro_Arr_Rel_Insert_Input = {
  data: Array<_FollowingPro_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<_FollowingPro_On_Conflict>;
};

/** Boolean expression to filter rows from the table "_FollowingPro". All fields are combined with a logical 'AND'. */
export type _FollowingPro_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  Pro?: InputMaybe<Pro_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<_FollowingPro_Bool_Exp>>;
  _not?: InputMaybe<_FollowingPro_Bool_Exp>;
  _or?: InputMaybe<Array<_FollowingPro_Bool_Exp>>;
};

/** unique or primary key constraints on table "_FollowingPro" */
export type _FollowingPro_Constraint =
  /** unique or primary key constraint on columns "A", "B" */
  | '_FollowingPro_AB_unique';

/** input type for inserting data into table "_FollowingPro" */
export type _FollowingPro_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  Pro?: InputMaybe<Pro_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type _FollowingPro_Max_Fields = {
  __typename?: '_FollowingPro_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "_FollowingPro" */
export type _FollowingPro_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type _FollowingPro_Min_Fields = {
  __typename?: '_FollowingPro_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "_FollowingPro" */
export type _FollowingPro_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "_FollowingPro" */
export type _FollowingPro_Mutation_Response = {
  __typename?: '_FollowingPro_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_FollowingPro>;
};

/** on_conflict condition type for table "_FollowingPro" */
export type _FollowingPro_On_Conflict = {
  constraint: _FollowingPro_Constraint;
  update_columns?: Array<_FollowingPro_Update_Column>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};

/** Ordering options when selecting data from "_FollowingPro". */
export type _FollowingPro_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  Pro?: InputMaybe<Pro_Order_By>;
  User?: InputMaybe<User_Order_By>;
};

/** select columns of table "_FollowingPro" */
export type _FollowingPro_Select_Column =
  /** column name */
  | 'A'
  /** column name */
  | 'B';

/** input type for updating data in table "_FollowingPro" */
export type _FollowingPro_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_FollowingPro" */
export type _FollowingPro_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _FollowingPro_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _FollowingPro_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_FollowingPro" */
export type _FollowingPro_Update_Column =
  /** column name */
  | 'A'
  /** column name */
  | 'B';

export type _FollowingPro_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_FollowingPro_Set_Input>;
  /** filter the rows which have to be updated */
  where: _FollowingPro_Bool_Exp;
};

/** columns and relationships of "_GoalToUserPreferences" */
export type _GoalToUserPreferences = {
  __typename?: '_GoalToUserPreferences';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  Goal: Goal;
  /** An object relationship */
  UserPreference: UserPreferences;
};

/** aggregated selection of "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Aggregate = {
  __typename?: '_GoalToUserPreferences_aggregate';
  aggregate?: Maybe<_GoalToUserPreferences_Aggregate_Fields>;
  nodes: Array<_GoalToUserPreferences>;
};

export type _GoalToUserPreferences_Aggregate_Bool_Exp = {
  count?: InputMaybe<_GoalToUserPreferences_Aggregate_Bool_Exp_Count>;
};

export type _GoalToUserPreferences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Aggregate_Fields = {
  __typename?: '_GoalToUserPreferences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<_GoalToUserPreferences_Max_Fields>;
  min?: Maybe<_GoalToUserPreferences_Min_Fields>;
};


/** aggregate fields of "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<_GoalToUserPreferences_Max_Order_By>;
  min?: InputMaybe<_GoalToUserPreferences_Min_Order_By>;
};

/** input type for inserting array relation for remote table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Arr_Rel_Insert_Input = {
  data: Array<_GoalToUserPreferences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<_GoalToUserPreferences_On_Conflict>;
};

/** Boolean expression to filter rows from the table "_GoalToUserPreferences". All fields are combined with a logical 'AND'. */
export type _GoalToUserPreferences_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  Goal?: InputMaybe<Goal_Bool_Exp>;
  UserPreference?: InputMaybe<UserPreferences_Bool_Exp>;
  _and?: InputMaybe<Array<_GoalToUserPreferences_Bool_Exp>>;
  _not?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
  _or?: InputMaybe<Array<_GoalToUserPreferences_Bool_Exp>>;
};

/** unique or primary key constraints on table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Constraint =
  /** unique or primary key constraint on columns "A", "B" */
  | '_GoalToUserPreferences_AB_unique';

/** input type for inserting data into table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  Goal?: InputMaybe<Goal_Obj_Rel_Insert_Input>;
  UserPreference?: InputMaybe<UserPreferences_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type _GoalToUserPreferences_Max_Fields = {
  __typename?: '_GoalToUserPreferences_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type _GoalToUserPreferences_Min_Fields = {
  __typename?: '_GoalToUserPreferences_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Mutation_Response = {
  __typename?: '_GoalToUserPreferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<_GoalToUserPreferences>;
};

/** on_conflict condition type for table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_On_Conflict = {
  constraint: _GoalToUserPreferences_Constraint;
  update_columns?: Array<_GoalToUserPreferences_Update_Column>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};

/** Ordering options when selecting data from "_GoalToUserPreferences". */
export type _GoalToUserPreferences_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  Goal?: InputMaybe<Goal_Order_By>;
  UserPreference?: InputMaybe<UserPreferences_Order_By>;
};

/** select columns of table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Select_Column =
  /** column name */
  | 'A'
  /** column name */
  | 'B';

/** input type for updating data in table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _GoalToUserPreferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _GoalToUserPreferences_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_GoalToUserPreferences" */
export type _GoalToUserPreferences_Update_Column =
  /** column name */
  | 'A'
  /** column name */
  | 'B';

export type _GoalToUserPreferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_GoalToUserPreferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: _GoalToUserPreferences_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

export type BioStrapAssignment = {
  __typename?: 'bioStrapAssignment';
  biostrapId?: Maybe<Scalars['String']['output']>;
};

export type CreateManyOutput = {
  __typename?: 'createManyOutput';
  count: Scalars['Int']['output'];
};

/** ordering argument of a cursor */
export type Cursor_Ordering =
  /** ascending ordering of the cursor */
  | 'ASC'
  /** descending ordering of the cursor */
  | 'DESC';

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

export type DiagnosticData = {
  epoch_ms?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  raw_data?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  user_workout_id?: InputMaybe<Scalars['String']['input']>;
};

export type FetchActivityAverages = {
  __typename?: 'fetchActivityAverages';
  active_cal?: Maybe<Scalars['Float']['output']>;
  bmr_cal?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  net_cal?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Float']['output']>;
};

export type FetchActivityResult = {
  __typename?: 'fetchActivityResult';
  averages?: Maybe<FetchActivityAverages>;
  data?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  totals?: Maybe<FetchActivityTotals>;
};

export type FetchActivityTotals = {
  __typename?: 'fetchActivityTotals';
  active_cal?: Maybe<Scalars['Float']['output']>;
  bmr_cal?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  net_cal?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
};

export type FetchFeatureFlagsOutput = {
  __typename?: 'fetchFeatureFlagsOutput';
  flags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FetchHabitsOutput = {
  __typename?: 'fetchHabitsOutput';
  days?: Maybe<Array<Maybe<HabitDayOutput>>>;
  days_required?: Maybe<Scalars['Int']['output']>;
  days_required_last_week?: Maybe<Scalars['Int']['output']>;
};

export type FetchPlansOutput = {
  __typename?: 'fetchPlansOutput';
  completed_at?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  plan_days?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  week?: Maybe<Scalars['Int']['output']>;
};

export type FileInput = {
  content: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type GenerateSignedUrlResponse = {
  __typename?: 'generateSignedUrlResponse';
  url: Scalars['String']['output'];
};

export type GenericListOutput = {
  __typename?: 'genericListOutput';
  data?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
  pageInfo?: Maybe<Scalars['json']['output']>;
};

export type GetSfCoachIdOutput = {
  __typename?: 'getSFCoachIdOutput';
  sendbird_access_token?: Maybe<Scalars['String']['output']>;
  sf_coach_id: Scalars['String']['output'];
};

export type GetUserByAuth0IdOutput = {
  __typename?: 'getUserByAuth0IdOutput';
  userId: Scalars['String']['output'];
};

export type IdObj = {
  __typename?: 'idObj';
  id?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _eq?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _gt?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _gte?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['jsonb']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _lte?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _neq?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['jsonb']['input']>>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

export type MessageOutput = {
  __typename?: 'messageOutput';
  message: Scalars['String']['output'];
};

export type MobileVersionsOutput = {
  __typename?: 'mobileVersionsOutput';
  androidBuild?: Maybe<Scalars['Int']['output']>;
  androidVersion?: Maybe<Scalars['String']['output']>;
  iOSBuild?: Maybe<Scalars['Int']['output']>;
  iOSVersion?: Maybe<Scalars['String']['output']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Coach Access Legal Acknowledgment */
  acknowledgeCoachAccess: Scalars['json']['output'];
  /** Fitness and Nutrition Legal Acknowledgment */
  acknowledgeFitnessAndNutrition: Scalars['json']['output'];
  /** Screen Installation Legal Acknowledgment */
  acknowledgeScreenInstallation: Scalars['json']['output'];
  /** activateSubscription */
  activateSubscription: Scalars['json']['output'];
  addFirmware?: Maybe<UploadContents>;
  addOrRemoveFavoriteWorkout?: Maybe<MessageOutput>;
  /** addUserToHousehold */
  addUserToHousehold: Scalars['json']['output'];
  assignBiostrap?: Maybe<BioStrapAssignment>;
  assignScreen?: Maybe<SampleScreenOutput>;
  calculateActivityMetrics: Scalars['String']['output'];
  calculateMacroGrams?: Maybe<CalculateMacroGramsOutput>;
  calculateWorkoutZones: Scalars['json']['output'];
  /** cancelSubscription */
  cancelSubscription: Scalars['json']['output'];
  changeCoachedPlan?: Maybe<ChangeCoachedPlanOutput>;
  changeGoal?: Maybe<MessageOutput>;
  /** changeProductVariant */
  changeProductVariant: Scalars['json']['output'];
  confirmAndCreatePresetPlan?: Maybe<ResultObjectOutput>;
  confirmAndUpdatePresetPlan?: Maybe<ResultObjectOutput>;
  createCoachedHabit: Scalars['Boolean']['output'];
  createCoachedNutrition: Scalars['String']['output'];
  createFirmwareContentV2?: Maybe<MessageOutput>;
  createNutritionReport?: Maybe<NutritionReportOutput>;
  dailyCheckin?: Maybe<DailyCheckinOutput>;
  dailyCheckinV2?: Maybe<DailyCheckinV2Output>;
  /** deleteAccount */
  deleteAccount: Scalars['json']['output'];
  deleteFirmwareContentV2?: Maybe<MessageOutput>;
  deleteOneUser?: Maybe<AllUsersOutput>;
  /** delete data from the table: "ActivationCode" */
  delete_ActivationCode?: Maybe<ActivationCode_Mutation_Response>;
  /** delete single row from the table: "ActivationCode" */
  delete_ActivationCode_by_pk?: Maybe<ActivationCode>;
  /** delete data from the table: "AlterTypeColor" */
  delete_AlterTypeColor?: Maybe<AlterTypeColor_Mutation_Response>;
  /** delete single row from the table: "AlterTypeColor" */
  delete_AlterTypeColor_by_pk?: Maybe<AlterTypeColor>;
  /** delete data from the table: "Coach" */
  delete_Coach?: Maybe<Coach_Mutation_Response>;
  /** delete single row from the table: "Coach" */
  delete_Coach_by_pk?: Maybe<Coach>;
  /** delete data from the table: "CoachedHabit" */
  delete_CoachedHabit?: Maybe<CoachedHabit_Mutation_Response>;
  /** delete single row from the table: "CoachedHabit" */
  delete_CoachedHabit_by_pk?: Maybe<CoachedHabit>;
  /** delete data from the table: "CoachedNutrition" */
  delete_CoachedNutrition?: Maybe<CoachedNutrition_Mutation_Response>;
  /** delete single row from the table: "CoachedNutrition" */
  delete_CoachedNutrition_by_pk?: Maybe<CoachedNutrition>;
  /** delete data from the table: "DNAKit" */
  delete_DNAKit?: Maybe<DnaKit_Mutation_Response>;
  /** delete single row from the table: "DNAKit" */
  delete_DNAKit_by_pk?: Maybe<DnaKit>;
  /** delete data from the table: "DNAResults" */
  delete_DNAResults?: Maybe<DnaResults_Mutation_Response>;
  /** delete single row from the table: "DNAResults" */
  delete_DNAResults_by_pk?: Maybe<DnaResults>;
  /** delete data from the table: "Delivery" */
  delete_Delivery?: Maybe<Delivery_Mutation_Response>;
  /** delete data from the table: "DeliveryHistory" */
  delete_DeliveryHistory?: Maybe<DeliveryHistory_Mutation_Response>;
  /** delete single row from the table: "DeliveryHistory" */
  delete_DeliveryHistory_by_pk?: Maybe<DeliveryHistory>;
  /** delete single row from the table: "Delivery" */
  delete_Delivery_by_pk?: Maybe<Delivery>;
  /** delete data from the table: "Device" */
  delete_Device?: Maybe<Device_Mutation_Response>;
  /** delete single row from the table: "Device" */
  delete_Device_by_pk?: Maybe<Device>;
  /** delete data from the table: "Diagnostics" */
  delete_Diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** delete single row from the table: "Diagnostics" */
  delete_Diagnostics_by_pk?: Maybe<Diagnostics>;
  /** delete data from the table: "FinancePlan" */
  delete_FinancePlan?: Maybe<FinancePlan_Mutation_Response>;
  /** delete single row from the table: "FinancePlan" */
  delete_FinancePlan_by_pk?: Maybe<FinancePlan>;
  /** delete data from the table: "FirmwareContent" */
  delete_FirmwareContent?: Maybe<FirmwareContent_Mutation_Response>;
  /** delete single row from the table: "FirmwareContent" */
  delete_FirmwareContent_by_pk?: Maybe<FirmwareContent>;
  /** delete data from the table: "GeneticDirectionWebhook" */
  delete_GeneticDirectionWebhook?: Maybe<GeneticDirectionWebhook_Mutation_Response>;
  /** delete single row from the table: "GeneticDirectionWebhook" */
  delete_GeneticDirectionWebhook_by_pk?: Maybe<GeneticDirectionWebhook>;
  /** delete data from the table: "GlobalFeatureControl" */
  delete_GlobalFeatureControl?: Maybe<GlobalFeatureControl_Mutation_Response>;
  /** delete single row from the table: "GlobalFeatureControl" */
  delete_GlobalFeatureControl_by_pk?: Maybe<GlobalFeatureControl>;
  /** delete data from the table: "Goal" */
  delete_Goal?: Maybe<Goal_Mutation_Response>;
  /** delete single row from the table: "Goal" */
  delete_Goal_by_pk?: Maybe<Goal>;
  /** delete data from the table: "HabitDay" */
  delete_HabitDay?: Maybe<HabitDay_Mutation_Response>;
  /** delete single row from the table: "HabitDay" */
  delete_HabitDay_by_pk?: Maybe<HabitDay>;
  /** delete data from the table: "HeartRateDiagnostics" */
  delete_HeartRateDiagnostics?: Maybe<HeartRateDiagnostics_Mutation_Response>;
  /** delete single row from the table: "HeartRateDiagnostics" */
  delete_HeartRateDiagnostics_by_pk?: Maybe<HeartRateDiagnostics>;
  /** delete data from the table: "Household" */
  delete_Household?: Maybe<Household_Mutation_Response>;
  /** delete single row from the table: "Household" */
  delete_Household_by_pk?: Maybe<Household>;
  /** delete data from the table: "MarketingEmail" */
  delete_MarketingEmail?: Maybe<MarketingEmail_Mutation_Response>;
  /** delete single row from the table: "MarketingEmail" */
  delete_MarketingEmail_by_pk?: Maybe<MarketingEmail>;
  /** delete data from the table: "Mastery" */
  delete_Mastery?: Maybe<Mastery_Mutation_Response>;
  /** delete single row from the table: "Mastery" */
  delete_Mastery_by_pk?: Maybe<Mastery>;
  /** delete data from the table: "OnboardingInfo" */
  delete_OnboardingInfo?: Maybe<OnboardingInfo_Mutation_Response>;
  /** delete single row from the table: "OnboardingInfo" */
  delete_OnboardingInfo_by_pk?: Maybe<OnboardingInfo>;
  /** delete data from the table: "OnboardingVideos" */
  delete_OnboardingVideos?: Maybe<OnboardingVideos_Mutation_Response>;
  /** delete single row from the table: "OnboardingVideos" */
  delete_OnboardingVideos_by_pk?: Maybe<OnboardingVideos>;
  /** delete data from the table: "Order" */
  delete_Order?: Maybe<Order_Mutation_Response>;
  /** delete data from the table: "OrderLineItem" */
  delete_OrderLineItem?: Maybe<OrderLineItem_Mutation_Response>;
  /** delete single row from the table: "OrderLineItem" */
  delete_OrderLineItem_by_pk?: Maybe<OrderLineItem>;
  /** delete single row from the table: "Order" */
  delete_Order_by_pk?: Maybe<Order>;
  /** delete data from the table: "PaymentHistory" */
  delete_PaymentHistory?: Maybe<PaymentHistory_Mutation_Response>;
  /** delete single row from the table: "PaymentHistory" */
  delete_PaymentHistory_by_pk?: Maybe<PaymentHistory>;
  /** delete data from the table: "PlanDay" */
  delete_PlanDay?: Maybe<PlanDay_Mutation_Response>;
  /** delete single row from the table: "PlanDay" */
  delete_PlanDay_by_pk?: Maybe<PlanDay>;
  /** delete data from the table: "PresetDiet" */
  delete_PresetDiet?: Maybe<PresetDiet_Mutation_Response>;
  /** delete single row from the table: "PresetDiet" */
  delete_PresetDiet_by_pk?: Maybe<PresetDiet>;
  /** delete data from the table: "Pro" */
  delete_Pro?: Maybe<Pro_Mutation_Response>;
  /** delete data from the table: "ProMusic" */
  delete_ProMusic?: Maybe<ProMusic_Mutation_Response>;
  /** delete single row from the table: "ProMusic" */
  delete_ProMusic_by_pk?: Maybe<ProMusic>;
  /** delete single row from the table: "Pro" */
  delete_Pro_by_pk?: Maybe<Pro>;
  /** delete data from the table: "Product" */
  delete_Product?: Maybe<Product_Mutation_Response>;
  /** delete data from the table: "ProductLog" */
  delete_ProductLog?: Maybe<ProductLog_Mutation_Response>;
  /** delete single row from the table: "ProductLog" */
  delete_ProductLog_by_pk?: Maybe<ProductLog>;
  /** delete single row from the table: "Product" */
  delete_Product_by_pk?: Maybe<Product>;
  /** delete data from the table: "PromotionalItem" */
  delete_PromotionalItem?: Maybe<PromotionalItem_Mutation_Response>;
  /** delete single row from the table: "PromotionalItem" */
  delete_PromotionalItem_by_pk?: Maybe<PromotionalItem>;
  /** delete data from the table: "QuizSurvey" */
  delete_QuizSurvey?: Maybe<QuizSurvey_Mutation_Response>;
  /** delete single row from the table: "QuizSurvey" */
  delete_QuizSurvey_by_pk?: Maybe<QuizSurvey>;
  /** delete data from the table: "Quote" */
  delete_Quote?: Maybe<Quote_Mutation_Response>;
  /** delete single row from the table: "Quote" */
  delete_Quote_by_pk?: Maybe<Quote>;
  /** delete data from the table: "ReimbursementRequirement" */
  delete_ReimbursementRequirement?: Maybe<ReimbursementRequirement_Mutation_Response>;
  /** delete single row from the table: "ReimbursementRequirement" */
  delete_ReimbursementRequirement_by_pk?: Maybe<ReimbursementRequirement>;
  /** delete data from the table: "ScreenOrder" */
  delete_ScreenOrder?: Maybe<ScreenOrder_Mutation_Response>;
  /** delete single row from the table: "ScreenOrder" */
  delete_ScreenOrder_by_pk?: Maybe<ScreenOrder>;
  /** delete data from the table: "SearchResult" */
  delete_SearchResult?: Maybe<SearchResult_Mutation_Response>;
  /** delete single row from the table: "SearchResult" */
  delete_SearchResult_by_pk?: Maybe<SearchResult>;
  /** delete data from the table: "SleepSurvey" */
  delete_SleepSurvey?: Maybe<SleepSurvey_Mutation_Response>;
  /** delete single row from the table: "SleepSurvey" */
  delete_SleepSurvey_by_pk?: Maybe<SleepSurvey>;
  /** delete data from the table: "Subscription" */
  delete_Subscription?: Maybe<Subscription_Mutation_Response>;
  /** delete data from the table: "SubscriptionLog" */
  delete_SubscriptionLog?: Maybe<SubscriptionLog_Mutation_Response>;
  /** delete single row from the table: "SubscriptionLog" */
  delete_SubscriptionLog_by_pk?: Maybe<SubscriptionLog>;
  /** delete single row from the table: "Subscription" */
  delete_Subscription_by_pk?: Maybe<Subscription>;
  /** delete data from the table: "Token" */
  delete_Token?: Maybe<Token_Mutation_Response>;
  /** delete data from the table: "TokenHistory" */
  delete_TokenHistory?: Maybe<TokenHistory_Mutation_Response>;
  /** delete single row from the table: "TokenHistory" */
  delete_TokenHistory_by_pk?: Maybe<TokenHistory>;
  /** delete single row from the table: "Token" */
  delete_Token_by_pk?: Maybe<Token>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "UserAggregates" */
  delete_UserAggregates?: Maybe<UserAggregates_Mutation_Response>;
  /** delete single row from the table: "UserAggregates" */
  delete_UserAggregates_by_pk?: Maybe<UserAggregates>;
  /** delete data from the table: "UserAssessment" */
  delete_UserAssessment?: Maybe<UserAssessment_Mutation_Response>;
  /** delete single row from the table: "UserAssessment" */
  delete_UserAssessment_by_pk?: Maybe<UserAssessment>;
  /** delete data from the table: "UserFeatureControl" */
  delete_UserFeatureControl?: Maybe<UserFeatureControl_Mutation_Response>;
  /** delete single row from the table: "UserFeatureControl" */
  delete_UserFeatureControl_by_pk?: Maybe<UserFeatureControl>;
  /** delete data from the table: "UserFeedback" */
  delete_UserFeedback?: Maybe<UserFeedback_Mutation_Response>;
  /** delete single row from the table: "UserFeedback" */
  delete_UserFeedback_by_pk?: Maybe<UserFeedback>;
  /** delete data from the table: "UserFinancePlan" */
  delete_UserFinancePlan?: Maybe<UserFinancePlan_Mutation_Response>;
  /** delete single row from the table: "UserFinancePlan" */
  delete_UserFinancePlan_by_pk?: Maybe<UserFinancePlan>;
  /** delete data from the table: "UserHabit" */
  delete_UserHabit?: Maybe<UserHabit_Mutation_Response>;
  /** delete single row from the table: "UserHabit" */
  delete_UserHabit_by_pk?: Maybe<UserHabit>;
  /** delete data from the table: "UserMovementDaily" */
  delete_UserMovementDaily?: Maybe<UserMovementDaily_Mutation_Response>;
  /** delete single row from the table: "UserMovementDaily" */
  delete_UserMovementDaily_by_pk?: Maybe<UserMovementDaily>;
  /** delete data from the table: "UserPreferences" */
  delete_UserPreferences?: Maybe<UserPreferences_Mutation_Response>;
  /** delete single row from the table: "UserPreferences" */
  delete_UserPreferences_by_pk?: Maybe<UserPreferences>;
  /** delete data from the table: "UserProduct" */
  delete_UserProduct?: Maybe<UserProduct_Mutation_Response>;
  /** delete data from the table: "UserProductHistory" */
  delete_UserProductHistory?: Maybe<UserProductHistory_Mutation_Response>;
  /** delete single row from the table: "UserProductHistory" */
  delete_UserProductHistory_by_pk?: Maybe<UserProductHistory>;
  /** delete single row from the table: "UserProduct" */
  delete_UserProduct_by_pk?: Maybe<UserProduct>;
  /** delete data from the table: "UserReadiness" */
  delete_UserReadiness?: Maybe<UserReadiness_Mutation_Response>;
  /** delete data from the table: "UserReadinessLog" */
  delete_UserReadinessLog?: Maybe<UserReadinessLog_Mutation_Response>;
  /** delete single row from the table: "UserReadinessLog" */
  delete_UserReadinessLog_by_pk?: Maybe<UserReadinessLog>;
  /** delete single row from the table: "UserReadiness" */
  delete_UserReadiness_by_pk?: Maybe<UserReadiness>;
  /** delete data from the table: "UserReimbursmentHistory" */
  delete_UserReimbursmentHistory?: Maybe<UserReimbursmentHistory_Mutation_Response>;
  /** delete single row from the table: "UserReimbursmentHistory" */
  delete_UserReimbursmentHistory_by_pk?: Maybe<UserReimbursmentHistory>;
  /** delete data from the table: "UserRep" */
  delete_UserRep?: Maybe<UserRep_Mutation_Response>;
  /** delete single row from the table: "UserRep" */
  delete_UserRep_by_pk?: Maybe<UserRep>;
  /** delete data from the table: "UserSet" */
  delete_UserSet?: Maybe<UserSet_Mutation_Response>;
  /** delete single row from the table: "UserSet" */
  delete_UserSet_by_pk?: Maybe<UserSet>;
  /** delete data from the table: "UserState" */
  delete_UserState?: Maybe<UserState_Mutation_Response>;
  /** delete single row from the table: "UserState" */
  delete_UserState_by_pk?: Maybe<UserState>;
  /** delete data from the table: "UserTrainingPlan" */
  delete_UserTrainingPlan?: Maybe<UserTrainingPlan_Mutation_Response>;
  /** delete single row from the table: "UserTrainingPlan" */
  delete_UserTrainingPlan_by_pk?: Maybe<UserTrainingPlan>;
  /** delete data from the table: "UserWorkout" */
  delete_UserWorkout?: Maybe<UserWorkout_Mutation_Response>;
  /** delete single row from the table: "UserWorkout" */
  delete_UserWorkout_by_pk?: Maybe<UserWorkout>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** delete data from the table: "VendorErrorLog" */
  delete_VendorErrorLog?: Maybe<VendorErrorLog_Mutation_Response>;
  /** delete single row from the table: "VendorErrorLog" */
  delete_VendorErrorLog_by_pk?: Maybe<VendorErrorLog>;
  /** delete data from the table: "VersionControl" */
  delete_VersionControl?: Maybe<VersionControl_Mutation_Response>;
  /** delete single row from the table: "VersionControl" */
  delete_VersionControl_by_pk?: Maybe<VersionControl>;
  /** delete data from the table: "WeeklyPlan" */
  delete_WeeklyPlan?: Maybe<WeeklyPlan_Mutation_Response>;
  /** delete single row from the table: "WeeklyPlan" */
  delete_WeeklyPlan_by_pk?: Maybe<WeeklyPlan>;
  /** delete data from the table: "WorkinPrefrence" */
  delete_WorkinPrefrence?: Maybe<WorkinPrefrence_Mutation_Response>;
  /** delete single row from the table: "WorkinPrefrence" */
  delete_WorkinPrefrence_by_pk?: Maybe<WorkinPrefrence>;
  /** delete data from the table: "Workout" */
  delete_Workout?: Maybe<Workout_Mutation_Response>;
  /** delete data from the table: "WorkoutPrefrence" */
  delete_WorkoutPrefrence?: Maybe<WorkoutPrefrence_Mutation_Response>;
  /** delete single row from the table: "WorkoutPrefrence" */
  delete_WorkoutPrefrence_by_pk?: Maybe<WorkoutPrefrence>;
  /** delete single row from the table: "Workout" */
  delete_Workout_by_pk?: Maybe<Workout>;
  /** delete data from the table: "_FollowingPro" */
  delete__FollowingPro?: Maybe<_FollowingPro_Mutation_Response>;
  /** delete data from the table: "_GoalToUserPreferences" */
  delete__GoalToUserPreferences?: Maybe<_GoalToUserPreferences_Mutation_Response>;
  endAssessment?: Maybe<EndAssessmentOutput>;
  endWorkout: StartWorkoutOutput;
  fetchHabits?: Maybe<FetchHabitsOutput>;
  fetchMealPlanPreferences?: Maybe<MealPlanPreferences>;
  findOrCreateMealPlan?: Maybe<MealPlanOutput>;
  generateSignedUrl?: Maybe<GenerateSignedUrlResponse>;
  hasuraSync?: Maybe<Response>;
  helloLocalHasura?: Maybe<SampleOutput>;
  /** insert data into the table: "ActivationCode" */
  insert_ActivationCode?: Maybe<ActivationCode_Mutation_Response>;
  /** insert a single row into the table: "ActivationCode" */
  insert_ActivationCode_one?: Maybe<ActivationCode>;
  /** insert data into the table: "AlterTypeColor" */
  insert_AlterTypeColor?: Maybe<AlterTypeColor_Mutation_Response>;
  /** insert a single row into the table: "AlterTypeColor" */
  insert_AlterTypeColor_one?: Maybe<AlterTypeColor>;
  /** insert data into the table: "Coach" */
  insert_Coach?: Maybe<Coach_Mutation_Response>;
  /** insert a single row into the table: "Coach" */
  insert_Coach_one?: Maybe<Coach>;
  /** insert data into the table: "CoachedHabit" */
  insert_CoachedHabit?: Maybe<CoachedHabit_Mutation_Response>;
  /** insert a single row into the table: "CoachedHabit" */
  insert_CoachedHabit_one?: Maybe<CoachedHabit>;
  /** insert data into the table: "CoachedNutrition" */
  insert_CoachedNutrition?: Maybe<CoachedNutrition_Mutation_Response>;
  /** insert a single row into the table: "CoachedNutrition" */
  insert_CoachedNutrition_one?: Maybe<CoachedNutrition>;
  /** insert data into the table: "DNAKit" */
  insert_DNAKit?: Maybe<DnaKit_Mutation_Response>;
  /** insert a single row into the table: "DNAKit" */
  insert_DNAKit_one?: Maybe<DnaKit>;
  /** insert data into the table: "DNAResults" */
  insert_DNAResults?: Maybe<DnaResults_Mutation_Response>;
  /** insert a single row into the table: "DNAResults" */
  insert_DNAResults_one?: Maybe<DnaResults>;
  /** insert data into the table: "Delivery" */
  insert_Delivery?: Maybe<Delivery_Mutation_Response>;
  /** insert data into the table: "DeliveryHistory" */
  insert_DeliveryHistory?: Maybe<DeliveryHistory_Mutation_Response>;
  /** insert a single row into the table: "DeliveryHistory" */
  insert_DeliveryHistory_one?: Maybe<DeliveryHistory>;
  /** insert a single row into the table: "Delivery" */
  insert_Delivery_one?: Maybe<Delivery>;
  /** insert data into the table: "Device" */
  insert_Device?: Maybe<Device_Mutation_Response>;
  /** insert a single row into the table: "Device" */
  insert_Device_one?: Maybe<Device>;
  /** insert data into the table: "Diagnostics" */
  insert_Diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** insert a single row into the table: "Diagnostics" */
  insert_Diagnostics_one?: Maybe<Diagnostics>;
  /** insert data into the table: "FinancePlan" */
  insert_FinancePlan?: Maybe<FinancePlan_Mutation_Response>;
  /** insert a single row into the table: "FinancePlan" */
  insert_FinancePlan_one?: Maybe<FinancePlan>;
  /** insert data into the table: "FirmwareContent" */
  insert_FirmwareContent?: Maybe<FirmwareContent_Mutation_Response>;
  /** insert a single row into the table: "FirmwareContent" */
  insert_FirmwareContent_one?: Maybe<FirmwareContent>;
  /** insert data into the table: "GeneticDirectionWebhook" */
  insert_GeneticDirectionWebhook?: Maybe<GeneticDirectionWebhook_Mutation_Response>;
  /** insert a single row into the table: "GeneticDirectionWebhook" */
  insert_GeneticDirectionWebhook_one?: Maybe<GeneticDirectionWebhook>;
  /** insert data into the table: "GlobalFeatureControl" */
  insert_GlobalFeatureControl?: Maybe<GlobalFeatureControl_Mutation_Response>;
  /** insert a single row into the table: "GlobalFeatureControl" */
  insert_GlobalFeatureControl_one?: Maybe<GlobalFeatureControl>;
  /** insert data into the table: "Goal" */
  insert_Goal?: Maybe<Goal_Mutation_Response>;
  /** insert a single row into the table: "Goal" */
  insert_Goal_one?: Maybe<Goal>;
  /** insert data into the table: "HabitDay" */
  insert_HabitDay?: Maybe<HabitDay_Mutation_Response>;
  /** insert a single row into the table: "HabitDay" */
  insert_HabitDay_one?: Maybe<HabitDay>;
  /** insert data into the table: "HeartRateDiagnostics" */
  insert_HeartRateDiagnostics?: Maybe<HeartRateDiagnostics_Mutation_Response>;
  /** insert a single row into the table: "HeartRateDiagnostics" */
  insert_HeartRateDiagnostics_one?: Maybe<HeartRateDiagnostics>;
  /** insert data into the table: "Household" */
  insert_Household?: Maybe<Household_Mutation_Response>;
  /** insert a single row into the table: "Household" */
  insert_Household_one?: Maybe<Household>;
  /** insert data into the table: "MarketingEmail" */
  insert_MarketingEmail?: Maybe<MarketingEmail_Mutation_Response>;
  /** insert a single row into the table: "MarketingEmail" */
  insert_MarketingEmail_one?: Maybe<MarketingEmail>;
  /** insert data into the table: "Mastery" */
  insert_Mastery?: Maybe<Mastery_Mutation_Response>;
  /** insert a single row into the table: "Mastery" */
  insert_Mastery_one?: Maybe<Mastery>;
  /** insert data into the table: "OnboardingInfo" */
  insert_OnboardingInfo?: Maybe<OnboardingInfo_Mutation_Response>;
  /** insert a single row into the table: "OnboardingInfo" */
  insert_OnboardingInfo_one?: Maybe<OnboardingInfo>;
  /** insert data into the table: "OnboardingVideos" */
  insert_OnboardingVideos?: Maybe<OnboardingVideos_Mutation_Response>;
  /** insert a single row into the table: "OnboardingVideos" */
  insert_OnboardingVideos_one?: Maybe<OnboardingVideos>;
  /** insert data into the table: "Order" */
  insert_Order?: Maybe<Order_Mutation_Response>;
  /** insert data into the table: "OrderLineItem" */
  insert_OrderLineItem?: Maybe<OrderLineItem_Mutation_Response>;
  /** insert a single row into the table: "OrderLineItem" */
  insert_OrderLineItem_one?: Maybe<OrderLineItem>;
  /** insert a single row into the table: "Order" */
  insert_Order_one?: Maybe<Order>;
  /** insert data into the table: "PaymentHistory" */
  insert_PaymentHistory?: Maybe<PaymentHistory_Mutation_Response>;
  /** insert a single row into the table: "PaymentHistory" */
  insert_PaymentHistory_one?: Maybe<PaymentHistory>;
  /** insert data into the table: "PlanDay" */
  insert_PlanDay?: Maybe<PlanDay_Mutation_Response>;
  /** insert a single row into the table: "PlanDay" */
  insert_PlanDay_one?: Maybe<PlanDay>;
  /** insert data into the table: "PresetDiet" */
  insert_PresetDiet?: Maybe<PresetDiet_Mutation_Response>;
  /** insert a single row into the table: "PresetDiet" */
  insert_PresetDiet_one?: Maybe<PresetDiet>;
  /** insert data into the table: "Pro" */
  insert_Pro?: Maybe<Pro_Mutation_Response>;
  /** insert data into the table: "ProMusic" */
  insert_ProMusic?: Maybe<ProMusic_Mutation_Response>;
  /** insert a single row into the table: "ProMusic" */
  insert_ProMusic_one?: Maybe<ProMusic>;
  /** insert a single row into the table: "Pro" */
  insert_Pro_one?: Maybe<Pro>;
  /** insert data into the table: "Product" */
  insert_Product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "ProductLog" */
  insert_ProductLog?: Maybe<ProductLog_Mutation_Response>;
  /** insert a single row into the table: "ProductLog" */
  insert_ProductLog_one?: Maybe<ProductLog>;
  /** insert a single row into the table: "Product" */
  insert_Product_one?: Maybe<Product>;
  /** insert data into the table: "PromotionalItem" */
  insert_PromotionalItem?: Maybe<PromotionalItem_Mutation_Response>;
  /** insert a single row into the table: "PromotionalItem" */
  insert_PromotionalItem_one?: Maybe<PromotionalItem>;
  /** insert data into the table: "QuizSurvey" */
  insert_QuizSurvey?: Maybe<QuizSurvey_Mutation_Response>;
  /** insert a single row into the table: "QuizSurvey" */
  insert_QuizSurvey_one?: Maybe<QuizSurvey>;
  /** insert data into the table: "Quote" */
  insert_Quote?: Maybe<Quote_Mutation_Response>;
  /** insert a single row into the table: "Quote" */
  insert_Quote_one?: Maybe<Quote>;
  /** insert data into the table: "ReimbursementRequirement" */
  insert_ReimbursementRequirement?: Maybe<ReimbursementRequirement_Mutation_Response>;
  /** insert a single row into the table: "ReimbursementRequirement" */
  insert_ReimbursementRequirement_one?: Maybe<ReimbursementRequirement>;
  /** insert data into the table: "ScreenOrder" */
  insert_ScreenOrder?: Maybe<ScreenOrder_Mutation_Response>;
  /** insert a single row into the table: "ScreenOrder" */
  insert_ScreenOrder_one?: Maybe<ScreenOrder>;
  /** insert data into the table: "SearchResult" */
  insert_SearchResult?: Maybe<SearchResult_Mutation_Response>;
  /** insert a single row into the table: "SearchResult" */
  insert_SearchResult_one?: Maybe<SearchResult>;
  /** insert data into the table: "SleepSurvey" */
  insert_SleepSurvey?: Maybe<SleepSurvey_Mutation_Response>;
  /** insert a single row into the table: "SleepSurvey" */
  insert_SleepSurvey_one?: Maybe<SleepSurvey>;
  /** insert data into the table: "Subscription" */
  insert_Subscription?: Maybe<Subscription_Mutation_Response>;
  /** insert data into the table: "SubscriptionLog" */
  insert_SubscriptionLog?: Maybe<SubscriptionLog_Mutation_Response>;
  /** insert a single row into the table: "SubscriptionLog" */
  insert_SubscriptionLog_one?: Maybe<SubscriptionLog>;
  /** insert a single row into the table: "Subscription" */
  insert_Subscription_one?: Maybe<Subscription>;
  /** insert data into the table: "Token" */
  insert_Token?: Maybe<Token_Mutation_Response>;
  /** insert data into the table: "TokenHistory" */
  insert_TokenHistory?: Maybe<TokenHistory_Mutation_Response>;
  /** insert a single row into the table: "TokenHistory" */
  insert_TokenHistory_one?: Maybe<TokenHistory>;
  /** insert a single row into the table: "Token" */
  insert_Token_one?: Maybe<Token>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "UserAggregates" */
  insert_UserAggregates?: Maybe<UserAggregates_Mutation_Response>;
  /** insert a single row into the table: "UserAggregates" */
  insert_UserAggregates_one?: Maybe<UserAggregates>;
  /** insert data into the table: "UserAssessment" */
  insert_UserAssessment?: Maybe<UserAssessment_Mutation_Response>;
  /** insert a single row into the table: "UserAssessment" */
  insert_UserAssessment_one?: Maybe<UserAssessment>;
  /** insert data into the table: "UserFeatureControl" */
  insert_UserFeatureControl?: Maybe<UserFeatureControl_Mutation_Response>;
  /** insert a single row into the table: "UserFeatureControl" */
  insert_UserFeatureControl_one?: Maybe<UserFeatureControl>;
  /** insert data into the table: "UserFeedback" */
  insert_UserFeedback?: Maybe<UserFeedback_Mutation_Response>;
  /** insert a single row into the table: "UserFeedback" */
  insert_UserFeedback_one?: Maybe<UserFeedback>;
  /** insert data into the table: "UserFinancePlan" */
  insert_UserFinancePlan?: Maybe<UserFinancePlan_Mutation_Response>;
  /** insert a single row into the table: "UserFinancePlan" */
  insert_UserFinancePlan_one?: Maybe<UserFinancePlan>;
  /** insert data into the table: "UserHabit" */
  insert_UserHabit?: Maybe<UserHabit_Mutation_Response>;
  /** insert a single row into the table: "UserHabit" */
  insert_UserHabit_one?: Maybe<UserHabit>;
  /** insert data into the table: "UserMovementDaily" */
  insert_UserMovementDaily?: Maybe<UserMovementDaily_Mutation_Response>;
  /** insert a single row into the table: "UserMovementDaily" */
  insert_UserMovementDaily_one?: Maybe<UserMovementDaily>;
  /** insert data into the table: "UserPreferences" */
  insert_UserPreferences?: Maybe<UserPreferences_Mutation_Response>;
  /** insert a single row into the table: "UserPreferences" */
  insert_UserPreferences_one?: Maybe<UserPreferences>;
  /** insert data into the table: "UserProduct" */
  insert_UserProduct?: Maybe<UserProduct_Mutation_Response>;
  /** insert data into the table: "UserProductHistory" */
  insert_UserProductHistory?: Maybe<UserProductHistory_Mutation_Response>;
  /** insert a single row into the table: "UserProductHistory" */
  insert_UserProductHistory_one?: Maybe<UserProductHistory>;
  /** insert a single row into the table: "UserProduct" */
  insert_UserProduct_one?: Maybe<UserProduct>;
  /** insert data into the table: "UserReadiness" */
  insert_UserReadiness?: Maybe<UserReadiness_Mutation_Response>;
  /** insert data into the table: "UserReadinessLog" */
  insert_UserReadinessLog?: Maybe<UserReadinessLog_Mutation_Response>;
  /** insert a single row into the table: "UserReadinessLog" */
  insert_UserReadinessLog_one?: Maybe<UserReadinessLog>;
  /** insert a single row into the table: "UserReadiness" */
  insert_UserReadiness_one?: Maybe<UserReadiness>;
  /** insert data into the table: "UserReimbursmentHistory" */
  insert_UserReimbursmentHistory?: Maybe<UserReimbursmentHistory_Mutation_Response>;
  /** insert a single row into the table: "UserReimbursmentHistory" */
  insert_UserReimbursmentHistory_one?: Maybe<UserReimbursmentHistory>;
  /** insert data into the table: "UserRep" */
  insert_UserRep?: Maybe<UserRep_Mutation_Response>;
  /** insert a single row into the table: "UserRep" */
  insert_UserRep_one?: Maybe<UserRep>;
  /** insert data into the table: "UserSet" */
  insert_UserSet?: Maybe<UserSet_Mutation_Response>;
  /** insert a single row into the table: "UserSet" */
  insert_UserSet_one?: Maybe<UserSet>;
  /** insert data into the table: "UserState" */
  insert_UserState?: Maybe<UserState_Mutation_Response>;
  /** insert a single row into the table: "UserState" */
  insert_UserState_one?: Maybe<UserState>;
  /** insert data into the table: "UserTrainingPlan" */
  insert_UserTrainingPlan?: Maybe<UserTrainingPlan_Mutation_Response>;
  /** insert a single row into the table: "UserTrainingPlan" */
  insert_UserTrainingPlan_one?: Maybe<UserTrainingPlan>;
  /** insert data into the table: "UserWorkout" */
  insert_UserWorkout?: Maybe<UserWorkout_Mutation_Response>;
  /** insert a single row into the table: "UserWorkout" */
  insert_UserWorkout_one?: Maybe<UserWorkout>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** insert data into the table: "VendorErrorLog" */
  insert_VendorErrorLog?: Maybe<VendorErrorLog_Mutation_Response>;
  /** insert a single row into the table: "VendorErrorLog" */
  insert_VendorErrorLog_one?: Maybe<VendorErrorLog>;
  /** insert data into the table: "VersionControl" */
  insert_VersionControl?: Maybe<VersionControl_Mutation_Response>;
  /** insert a single row into the table: "VersionControl" */
  insert_VersionControl_one?: Maybe<VersionControl>;
  /** insert data into the table: "WeeklyPlan" */
  insert_WeeklyPlan?: Maybe<WeeklyPlan_Mutation_Response>;
  /** insert a single row into the table: "WeeklyPlan" */
  insert_WeeklyPlan_one?: Maybe<WeeklyPlan>;
  /** insert data into the table: "WorkinPrefrence" */
  insert_WorkinPrefrence?: Maybe<WorkinPrefrence_Mutation_Response>;
  /** insert a single row into the table: "WorkinPrefrence" */
  insert_WorkinPrefrence_one?: Maybe<WorkinPrefrence>;
  /** insert data into the table: "Workout" */
  insert_Workout?: Maybe<Workout_Mutation_Response>;
  /** insert data into the table: "WorkoutPrefrence" */
  insert_WorkoutPrefrence?: Maybe<WorkoutPrefrence_Mutation_Response>;
  /** insert a single row into the table: "WorkoutPrefrence" */
  insert_WorkoutPrefrence_one?: Maybe<WorkoutPrefrence>;
  /** insert a single row into the table: "Workout" */
  insert_Workout_one?: Maybe<Workout>;
  /** insert data into the table: "_FollowingPro" */
  insert__FollowingPro?: Maybe<_FollowingPro_Mutation_Response>;
  /** insert a single row into the table: "_FollowingPro" */
  insert__FollowingPro_one?: Maybe<_FollowingPro>;
  /** insert data into the table: "_GoalToUserPreferences" */
  insert__GoalToUserPreferences?: Maybe<_GoalToUserPreferences_Mutation_Response>;
  /** insert a single row into the table: "_GoalToUserPreferences" */
  insert__GoalToUserPreferences_one?: Maybe<_GoalToUserPreferences>;
  logRecommendation: Scalars['String']['output'];
  login: AllUsersOutput;
  lookupUspsStatus?: Maybe<ShipStatusMessage>;
  pauseOrResumeWorkout?: Maybe<PauseOrResumeOutput>;
  performReadinessCalculations: Scalars['String']['output'];
  performWorkoutCalculations?: Maybe<Array<Maybe<SampleWorkoutZoneOutput>>>;
  prestigeUser: Scalars['json']['output'];
  promoteToCoach: Scalars['json']['output'];
  qualifyWorkout: QualifyWorkoutOutput;
  qualifyWorkoutV2: QualifyWorkoutOutput;
  qualifyWorkoutV3: QualifyWorkoutOutput;
  readinessOverride: Scalars['String']['output'];
  recommender?: Maybe<RecommenderOutput>;
  recommenderV2?: Maybe<RecommenderV2Output>;
  refreshPearToken: PearTokens;
  refreshPlan?: Maybe<MessageOutput>;
  refreshToken: Scalars['String']['output'];
  register: RegisterUser;
  registerDNAKit: Scalars['String']['output'];
  /** registerDNAKitStaging */
  registerDNAKitStaging: Scalars['String']['output'];
  /** removeUserFromHousehold */
  removeUserFromHousehold: Scalars['json']['output'];
  resetNutritionReport?: Maybe<MessageOutput>;
  resetOnboarding?: Maybe<MessageOutput>;
  resetPlan: MessageOutput;
  runInfluxCalculations?: Maybe<AggregatesOutput>;
  saveFileBase64: Scalars['String']['output'];
  setUserGoals: SetUserGoalsOutput;
  softDeleteUser: MessageOutput;
  startAssessment?: Maybe<StartAssessmentOutput>;
  startWorkout: StartWorkoutOutput;
  submitDiagnostics: CreateManyOutput;
  submitHRDiagnostics: CreateManyOutput;
  submitHabitCompleted?: Maybe<HabitCompletedResult>;
  submitHardwareDiagnostics?: Maybe<MessageOutput>;
  submitQuizSurvey: SampleQuizSurveyOutput;
  /** submitRegistrationSurvey */
  submitRegistrationSurvey: Scalars['json']['output'];
  submitReportViewed: MessageOutput;
  /** submitSurveyToSf */
  submitSurveyToSf: Scalars['json']['output'];
  /** submitSurveyToSfV2 */
  submitSurveyToSfV2: Scalars['json']['output'];
  submitUserFeedback?: Maybe<MessageOutput>;
  swapMeal?: Maybe<MealPlanOutput>;
  unassignBiostrap?: Maybe<MessageOutput>;
  updateConsentDetails: Scalars['String']['output'];
  updateDNAResults?: Maybe<DnaResultsOutput>;
  updateEmail: MessageOutput;
  updateFirmwareContentV2?: Maybe<MessageOutput>;
  updateFirmwareV1ActiveStatus?: Maybe<MessageOutput>;
  updateFirmwareV1BetaStatus?: Maybe<MessageOutput>;
  updateMealSelectionStatus?: Maybe<MealPlanOutput>;
  updateMobileVersions?: Maybe<MobileVersionsOutput>;
  updatePassword?: Maybe<MessageOutput>;
  updateReimbursementTokens?: Maybe<UpdateReimbursementTokensResponse>;
  updateUser?: Maybe<UpdateUser>;
  updateUserPreferences?: Maybe<UserPreferencesOutput>;
  /** update data of the table: "ActivationCode" */
  update_ActivationCode?: Maybe<ActivationCode_Mutation_Response>;
  /** update single row of the table: "ActivationCode" */
  update_ActivationCode_by_pk?: Maybe<ActivationCode>;
  /** update multiples rows of table: "ActivationCode" */
  update_ActivationCode_many?: Maybe<Array<Maybe<ActivationCode_Mutation_Response>>>;
  /** update data of the table: "AlterTypeColor" */
  update_AlterTypeColor?: Maybe<AlterTypeColor_Mutation_Response>;
  /** update single row of the table: "AlterTypeColor" */
  update_AlterTypeColor_by_pk?: Maybe<AlterTypeColor>;
  /** update multiples rows of table: "AlterTypeColor" */
  update_AlterTypeColor_many?: Maybe<Array<Maybe<AlterTypeColor_Mutation_Response>>>;
  /** update data of the table: "Coach" */
  update_Coach?: Maybe<Coach_Mutation_Response>;
  /** update single row of the table: "Coach" */
  update_Coach_by_pk?: Maybe<Coach>;
  /** update multiples rows of table: "Coach" */
  update_Coach_many?: Maybe<Array<Maybe<Coach_Mutation_Response>>>;
  /** update data of the table: "CoachedHabit" */
  update_CoachedHabit?: Maybe<CoachedHabit_Mutation_Response>;
  /** update single row of the table: "CoachedHabit" */
  update_CoachedHabit_by_pk?: Maybe<CoachedHabit>;
  /** update multiples rows of table: "CoachedHabit" */
  update_CoachedHabit_many?: Maybe<Array<Maybe<CoachedHabit_Mutation_Response>>>;
  /** update data of the table: "CoachedNutrition" */
  update_CoachedNutrition?: Maybe<CoachedNutrition_Mutation_Response>;
  /** update single row of the table: "CoachedNutrition" */
  update_CoachedNutrition_by_pk?: Maybe<CoachedNutrition>;
  /** update multiples rows of table: "CoachedNutrition" */
  update_CoachedNutrition_many?: Maybe<Array<Maybe<CoachedNutrition_Mutation_Response>>>;
  /** update data of the table: "DNAKit" */
  update_DNAKit?: Maybe<DnaKit_Mutation_Response>;
  /** update single row of the table: "DNAKit" */
  update_DNAKit_by_pk?: Maybe<DnaKit>;
  /** update multiples rows of table: "DNAKit" */
  update_DNAKit_many?: Maybe<Array<Maybe<DnaKit_Mutation_Response>>>;
  /** update data of the table: "DNAResults" */
  update_DNAResults?: Maybe<DnaResults_Mutation_Response>;
  /** update single row of the table: "DNAResults" */
  update_DNAResults_by_pk?: Maybe<DnaResults>;
  /** update multiples rows of table: "DNAResults" */
  update_DNAResults_many?: Maybe<Array<Maybe<DnaResults_Mutation_Response>>>;
  /** update data of the table: "Delivery" */
  update_Delivery?: Maybe<Delivery_Mutation_Response>;
  /** update data of the table: "DeliveryHistory" */
  update_DeliveryHistory?: Maybe<DeliveryHistory_Mutation_Response>;
  /** update single row of the table: "DeliveryHistory" */
  update_DeliveryHistory_by_pk?: Maybe<DeliveryHistory>;
  /** update multiples rows of table: "DeliveryHistory" */
  update_DeliveryHistory_many?: Maybe<Array<Maybe<DeliveryHistory_Mutation_Response>>>;
  /** update single row of the table: "Delivery" */
  update_Delivery_by_pk?: Maybe<Delivery>;
  /** update multiples rows of table: "Delivery" */
  update_Delivery_many?: Maybe<Array<Maybe<Delivery_Mutation_Response>>>;
  /** update data of the table: "Device" */
  update_Device?: Maybe<Device_Mutation_Response>;
  /** update single row of the table: "Device" */
  update_Device_by_pk?: Maybe<Device>;
  /** update multiples rows of table: "Device" */
  update_Device_many?: Maybe<Array<Maybe<Device_Mutation_Response>>>;
  /** update data of the table: "Diagnostics" */
  update_Diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** update single row of the table: "Diagnostics" */
  update_Diagnostics_by_pk?: Maybe<Diagnostics>;
  /** update multiples rows of table: "Diagnostics" */
  update_Diagnostics_many?: Maybe<Array<Maybe<Diagnostics_Mutation_Response>>>;
  /** update data of the table: "FinancePlan" */
  update_FinancePlan?: Maybe<FinancePlan_Mutation_Response>;
  /** update single row of the table: "FinancePlan" */
  update_FinancePlan_by_pk?: Maybe<FinancePlan>;
  /** update multiples rows of table: "FinancePlan" */
  update_FinancePlan_many?: Maybe<Array<Maybe<FinancePlan_Mutation_Response>>>;
  /** update data of the table: "FirmwareContent" */
  update_FirmwareContent?: Maybe<FirmwareContent_Mutation_Response>;
  /** update single row of the table: "FirmwareContent" */
  update_FirmwareContent_by_pk?: Maybe<FirmwareContent>;
  /** update multiples rows of table: "FirmwareContent" */
  update_FirmwareContent_many?: Maybe<Array<Maybe<FirmwareContent_Mutation_Response>>>;
  /** update data of the table: "GeneticDirectionWebhook" */
  update_GeneticDirectionWebhook?: Maybe<GeneticDirectionWebhook_Mutation_Response>;
  /** update single row of the table: "GeneticDirectionWebhook" */
  update_GeneticDirectionWebhook_by_pk?: Maybe<GeneticDirectionWebhook>;
  /** update multiples rows of table: "GeneticDirectionWebhook" */
  update_GeneticDirectionWebhook_many?: Maybe<Array<Maybe<GeneticDirectionWebhook_Mutation_Response>>>;
  /** update data of the table: "GlobalFeatureControl" */
  update_GlobalFeatureControl?: Maybe<GlobalFeatureControl_Mutation_Response>;
  /** update single row of the table: "GlobalFeatureControl" */
  update_GlobalFeatureControl_by_pk?: Maybe<GlobalFeatureControl>;
  /** update multiples rows of table: "GlobalFeatureControl" */
  update_GlobalFeatureControl_many?: Maybe<Array<Maybe<GlobalFeatureControl_Mutation_Response>>>;
  /** update data of the table: "Goal" */
  update_Goal?: Maybe<Goal_Mutation_Response>;
  /** update single row of the table: "Goal" */
  update_Goal_by_pk?: Maybe<Goal>;
  /** update multiples rows of table: "Goal" */
  update_Goal_many?: Maybe<Array<Maybe<Goal_Mutation_Response>>>;
  /** update data of the table: "HabitDay" */
  update_HabitDay?: Maybe<HabitDay_Mutation_Response>;
  /** update single row of the table: "HabitDay" */
  update_HabitDay_by_pk?: Maybe<HabitDay>;
  /** update multiples rows of table: "HabitDay" */
  update_HabitDay_many?: Maybe<Array<Maybe<HabitDay_Mutation_Response>>>;
  /** update data of the table: "HeartRateDiagnostics" */
  update_HeartRateDiagnostics?: Maybe<HeartRateDiagnostics_Mutation_Response>;
  /** update single row of the table: "HeartRateDiagnostics" */
  update_HeartRateDiagnostics_by_pk?: Maybe<HeartRateDiagnostics>;
  /** update multiples rows of table: "HeartRateDiagnostics" */
  update_HeartRateDiagnostics_many?: Maybe<Array<Maybe<HeartRateDiagnostics_Mutation_Response>>>;
  /** update data of the table: "Household" */
  update_Household?: Maybe<Household_Mutation_Response>;
  /** update single row of the table: "Household" */
  update_Household_by_pk?: Maybe<Household>;
  /** update multiples rows of table: "Household" */
  update_Household_many?: Maybe<Array<Maybe<Household_Mutation_Response>>>;
  /** update data of the table: "MarketingEmail" */
  update_MarketingEmail?: Maybe<MarketingEmail_Mutation_Response>;
  /** update single row of the table: "MarketingEmail" */
  update_MarketingEmail_by_pk?: Maybe<MarketingEmail>;
  /** update multiples rows of table: "MarketingEmail" */
  update_MarketingEmail_many?: Maybe<Array<Maybe<MarketingEmail_Mutation_Response>>>;
  /** update data of the table: "Mastery" */
  update_Mastery?: Maybe<Mastery_Mutation_Response>;
  /** update single row of the table: "Mastery" */
  update_Mastery_by_pk?: Maybe<Mastery>;
  /** update multiples rows of table: "Mastery" */
  update_Mastery_many?: Maybe<Array<Maybe<Mastery_Mutation_Response>>>;
  /** update data of the table: "OnboardingInfo" */
  update_OnboardingInfo?: Maybe<OnboardingInfo_Mutation_Response>;
  /** update single row of the table: "OnboardingInfo" */
  update_OnboardingInfo_by_pk?: Maybe<OnboardingInfo>;
  /** update multiples rows of table: "OnboardingInfo" */
  update_OnboardingInfo_many?: Maybe<Array<Maybe<OnboardingInfo_Mutation_Response>>>;
  /** update data of the table: "OnboardingVideos" */
  update_OnboardingVideos?: Maybe<OnboardingVideos_Mutation_Response>;
  /** update single row of the table: "OnboardingVideos" */
  update_OnboardingVideos_by_pk?: Maybe<OnboardingVideos>;
  /** update multiples rows of table: "OnboardingVideos" */
  update_OnboardingVideos_many?: Maybe<Array<Maybe<OnboardingVideos_Mutation_Response>>>;
  /** update data of the table: "Order" */
  update_Order?: Maybe<Order_Mutation_Response>;
  /** update data of the table: "OrderLineItem" */
  update_OrderLineItem?: Maybe<OrderLineItem_Mutation_Response>;
  /** update single row of the table: "OrderLineItem" */
  update_OrderLineItem_by_pk?: Maybe<OrderLineItem>;
  /** update multiples rows of table: "OrderLineItem" */
  update_OrderLineItem_many?: Maybe<Array<Maybe<OrderLineItem_Mutation_Response>>>;
  /** update single row of the table: "Order" */
  update_Order_by_pk?: Maybe<Order>;
  /** update multiples rows of table: "Order" */
  update_Order_many?: Maybe<Array<Maybe<Order_Mutation_Response>>>;
  /** update data of the table: "PaymentHistory" */
  update_PaymentHistory?: Maybe<PaymentHistory_Mutation_Response>;
  /** update single row of the table: "PaymentHistory" */
  update_PaymentHistory_by_pk?: Maybe<PaymentHistory>;
  /** update multiples rows of table: "PaymentHistory" */
  update_PaymentHistory_many?: Maybe<Array<Maybe<PaymentHistory_Mutation_Response>>>;
  /** update data of the table: "PlanDay" */
  update_PlanDay?: Maybe<PlanDay_Mutation_Response>;
  /** update single row of the table: "PlanDay" */
  update_PlanDay_by_pk?: Maybe<PlanDay>;
  /** update multiples rows of table: "PlanDay" */
  update_PlanDay_many?: Maybe<Array<Maybe<PlanDay_Mutation_Response>>>;
  /** update data of the table: "PresetDiet" */
  update_PresetDiet?: Maybe<PresetDiet_Mutation_Response>;
  /** update single row of the table: "PresetDiet" */
  update_PresetDiet_by_pk?: Maybe<PresetDiet>;
  /** update multiples rows of table: "PresetDiet" */
  update_PresetDiet_many?: Maybe<Array<Maybe<PresetDiet_Mutation_Response>>>;
  /** update data of the table: "Pro" */
  update_Pro?: Maybe<Pro_Mutation_Response>;
  /** update data of the table: "ProMusic" */
  update_ProMusic?: Maybe<ProMusic_Mutation_Response>;
  /** update single row of the table: "ProMusic" */
  update_ProMusic_by_pk?: Maybe<ProMusic>;
  /** update multiples rows of table: "ProMusic" */
  update_ProMusic_many?: Maybe<Array<Maybe<ProMusic_Mutation_Response>>>;
  /** update single row of the table: "Pro" */
  update_Pro_by_pk?: Maybe<Pro>;
  /** update multiples rows of table: "Pro" */
  update_Pro_many?: Maybe<Array<Maybe<Pro_Mutation_Response>>>;
  /** update data of the table: "Product" */
  update_Product?: Maybe<Product_Mutation_Response>;
  /** update data of the table: "ProductLog" */
  update_ProductLog?: Maybe<ProductLog_Mutation_Response>;
  /** update single row of the table: "ProductLog" */
  update_ProductLog_by_pk?: Maybe<ProductLog>;
  /** update multiples rows of table: "ProductLog" */
  update_ProductLog_many?: Maybe<Array<Maybe<ProductLog_Mutation_Response>>>;
  /** update single row of the table: "Product" */
  update_Product_by_pk?: Maybe<Product>;
  /** update multiples rows of table: "Product" */
  update_Product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "PromotionalItem" */
  update_PromotionalItem?: Maybe<PromotionalItem_Mutation_Response>;
  /** update single row of the table: "PromotionalItem" */
  update_PromotionalItem_by_pk?: Maybe<PromotionalItem>;
  /** update multiples rows of table: "PromotionalItem" */
  update_PromotionalItem_many?: Maybe<Array<Maybe<PromotionalItem_Mutation_Response>>>;
  /** update data of the table: "QuizSurvey" */
  update_QuizSurvey?: Maybe<QuizSurvey_Mutation_Response>;
  /** update single row of the table: "QuizSurvey" */
  update_QuizSurvey_by_pk?: Maybe<QuizSurvey>;
  /** update multiples rows of table: "QuizSurvey" */
  update_QuizSurvey_many?: Maybe<Array<Maybe<QuizSurvey_Mutation_Response>>>;
  /** update data of the table: "Quote" */
  update_Quote?: Maybe<Quote_Mutation_Response>;
  /** update single row of the table: "Quote" */
  update_Quote_by_pk?: Maybe<Quote>;
  /** update multiples rows of table: "Quote" */
  update_Quote_many?: Maybe<Array<Maybe<Quote_Mutation_Response>>>;
  /** update data of the table: "ReimbursementRequirement" */
  update_ReimbursementRequirement?: Maybe<ReimbursementRequirement_Mutation_Response>;
  /** update single row of the table: "ReimbursementRequirement" */
  update_ReimbursementRequirement_by_pk?: Maybe<ReimbursementRequirement>;
  /** update multiples rows of table: "ReimbursementRequirement" */
  update_ReimbursementRequirement_many?: Maybe<Array<Maybe<ReimbursementRequirement_Mutation_Response>>>;
  /** update data of the table: "ScreenOrder" */
  update_ScreenOrder?: Maybe<ScreenOrder_Mutation_Response>;
  /** update single row of the table: "ScreenOrder" */
  update_ScreenOrder_by_pk?: Maybe<ScreenOrder>;
  /** update multiples rows of table: "ScreenOrder" */
  update_ScreenOrder_many?: Maybe<Array<Maybe<ScreenOrder_Mutation_Response>>>;
  /** update data of the table: "SearchResult" */
  update_SearchResult?: Maybe<SearchResult_Mutation_Response>;
  /** update single row of the table: "SearchResult" */
  update_SearchResult_by_pk?: Maybe<SearchResult>;
  /** update multiples rows of table: "SearchResult" */
  update_SearchResult_many?: Maybe<Array<Maybe<SearchResult_Mutation_Response>>>;
  /** update data of the table: "SleepSurvey" */
  update_SleepSurvey?: Maybe<SleepSurvey_Mutation_Response>;
  /** update single row of the table: "SleepSurvey" */
  update_SleepSurvey_by_pk?: Maybe<SleepSurvey>;
  /** update multiples rows of table: "SleepSurvey" */
  update_SleepSurvey_many?: Maybe<Array<Maybe<SleepSurvey_Mutation_Response>>>;
  /** update data of the table: "Subscription" */
  update_Subscription?: Maybe<Subscription_Mutation_Response>;
  /** update data of the table: "SubscriptionLog" */
  update_SubscriptionLog?: Maybe<SubscriptionLog_Mutation_Response>;
  /** update single row of the table: "SubscriptionLog" */
  update_SubscriptionLog_by_pk?: Maybe<SubscriptionLog>;
  /** update multiples rows of table: "SubscriptionLog" */
  update_SubscriptionLog_many?: Maybe<Array<Maybe<SubscriptionLog_Mutation_Response>>>;
  /** update single row of the table: "Subscription" */
  update_Subscription_by_pk?: Maybe<Subscription>;
  /** update multiples rows of table: "Subscription" */
  update_Subscription_many?: Maybe<Array<Maybe<Subscription_Mutation_Response>>>;
  /** update data of the table: "Token" */
  update_Token?: Maybe<Token_Mutation_Response>;
  /** update data of the table: "TokenHistory" */
  update_TokenHistory?: Maybe<TokenHistory_Mutation_Response>;
  /** update single row of the table: "TokenHistory" */
  update_TokenHistory_by_pk?: Maybe<TokenHistory>;
  /** update multiples rows of table: "TokenHistory" */
  update_TokenHistory_many?: Maybe<Array<Maybe<TokenHistory_Mutation_Response>>>;
  /** update single row of the table: "Token" */
  update_Token_by_pk?: Maybe<Token>;
  /** update multiples rows of table: "Token" */
  update_Token_many?: Maybe<Array<Maybe<Token_Mutation_Response>>>;
  /** update data of the table: "User" */
  update_User?: Maybe<User_Mutation_Response>;
  /** update data of the table: "UserAggregates" */
  update_UserAggregates?: Maybe<UserAggregates_Mutation_Response>;
  /** update single row of the table: "UserAggregates" */
  update_UserAggregates_by_pk?: Maybe<UserAggregates>;
  /** update multiples rows of table: "UserAggregates" */
  update_UserAggregates_many?: Maybe<Array<Maybe<UserAggregates_Mutation_Response>>>;
  /** update data of the table: "UserAssessment" */
  update_UserAssessment?: Maybe<UserAssessment_Mutation_Response>;
  /** update single row of the table: "UserAssessment" */
  update_UserAssessment_by_pk?: Maybe<UserAssessment>;
  /** update multiples rows of table: "UserAssessment" */
  update_UserAssessment_many?: Maybe<Array<Maybe<UserAssessment_Mutation_Response>>>;
  /** update data of the table: "UserFeatureControl" */
  update_UserFeatureControl?: Maybe<UserFeatureControl_Mutation_Response>;
  /** update single row of the table: "UserFeatureControl" */
  update_UserFeatureControl_by_pk?: Maybe<UserFeatureControl>;
  /** update multiples rows of table: "UserFeatureControl" */
  update_UserFeatureControl_many?: Maybe<Array<Maybe<UserFeatureControl_Mutation_Response>>>;
  /** update data of the table: "UserFeedback" */
  update_UserFeedback?: Maybe<UserFeedback_Mutation_Response>;
  /** update single row of the table: "UserFeedback" */
  update_UserFeedback_by_pk?: Maybe<UserFeedback>;
  /** update multiples rows of table: "UserFeedback" */
  update_UserFeedback_many?: Maybe<Array<Maybe<UserFeedback_Mutation_Response>>>;
  /** update data of the table: "UserFinancePlan" */
  update_UserFinancePlan?: Maybe<UserFinancePlan_Mutation_Response>;
  /** update single row of the table: "UserFinancePlan" */
  update_UserFinancePlan_by_pk?: Maybe<UserFinancePlan>;
  /** update multiples rows of table: "UserFinancePlan" */
  update_UserFinancePlan_many?: Maybe<Array<Maybe<UserFinancePlan_Mutation_Response>>>;
  /** update data of the table: "UserHabit" */
  update_UserHabit?: Maybe<UserHabit_Mutation_Response>;
  /** update single row of the table: "UserHabit" */
  update_UserHabit_by_pk?: Maybe<UserHabit>;
  /** update multiples rows of table: "UserHabit" */
  update_UserHabit_many?: Maybe<Array<Maybe<UserHabit_Mutation_Response>>>;
  /** update data of the table: "UserMovementDaily" */
  update_UserMovementDaily?: Maybe<UserMovementDaily_Mutation_Response>;
  /** update single row of the table: "UserMovementDaily" */
  update_UserMovementDaily_by_pk?: Maybe<UserMovementDaily>;
  /** update multiples rows of table: "UserMovementDaily" */
  update_UserMovementDaily_many?: Maybe<Array<Maybe<UserMovementDaily_Mutation_Response>>>;
  /** update data of the table: "UserPreferences" */
  update_UserPreferences?: Maybe<UserPreferences_Mutation_Response>;
  /** update single row of the table: "UserPreferences" */
  update_UserPreferences_by_pk?: Maybe<UserPreferences>;
  /** update multiples rows of table: "UserPreferences" */
  update_UserPreferences_many?: Maybe<Array<Maybe<UserPreferences_Mutation_Response>>>;
  /** update data of the table: "UserProduct" */
  update_UserProduct?: Maybe<UserProduct_Mutation_Response>;
  /** update data of the table: "UserProductHistory" */
  update_UserProductHistory?: Maybe<UserProductHistory_Mutation_Response>;
  /** update single row of the table: "UserProductHistory" */
  update_UserProductHistory_by_pk?: Maybe<UserProductHistory>;
  /** update multiples rows of table: "UserProductHistory" */
  update_UserProductHistory_many?: Maybe<Array<Maybe<UserProductHistory_Mutation_Response>>>;
  /** update single row of the table: "UserProduct" */
  update_UserProduct_by_pk?: Maybe<UserProduct>;
  /** update multiples rows of table: "UserProduct" */
  update_UserProduct_many?: Maybe<Array<Maybe<UserProduct_Mutation_Response>>>;
  /** update data of the table: "UserReadiness" */
  update_UserReadiness?: Maybe<UserReadiness_Mutation_Response>;
  /** update data of the table: "UserReadinessLog" */
  update_UserReadinessLog?: Maybe<UserReadinessLog_Mutation_Response>;
  /** update single row of the table: "UserReadinessLog" */
  update_UserReadinessLog_by_pk?: Maybe<UserReadinessLog>;
  /** update multiples rows of table: "UserReadinessLog" */
  update_UserReadinessLog_many?: Maybe<Array<Maybe<UserReadinessLog_Mutation_Response>>>;
  /** update single row of the table: "UserReadiness" */
  update_UserReadiness_by_pk?: Maybe<UserReadiness>;
  /** update multiples rows of table: "UserReadiness" */
  update_UserReadiness_many?: Maybe<Array<Maybe<UserReadiness_Mutation_Response>>>;
  /** update data of the table: "UserReimbursmentHistory" */
  update_UserReimbursmentHistory?: Maybe<UserReimbursmentHistory_Mutation_Response>;
  /** update single row of the table: "UserReimbursmentHistory" */
  update_UserReimbursmentHistory_by_pk?: Maybe<UserReimbursmentHistory>;
  /** update multiples rows of table: "UserReimbursmentHistory" */
  update_UserReimbursmentHistory_many?: Maybe<Array<Maybe<UserReimbursmentHistory_Mutation_Response>>>;
  /** update data of the table: "UserRep" */
  update_UserRep?: Maybe<UserRep_Mutation_Response>;
  /** update single row of the table: "UserRep" */
  update_UserRep_by_pk?: Maybe<UserRep>;
  /** update multiples rows of table: "UserRep" */
  update_UserRep_many?: Maybe<Array<Maybe<UserRep_Mutation_Response>>>;
  /** update data of the table: "UserSet" */
  update_UserSet?: Maybe<UserSet_Mutation_Response>;
  /** update single row of the table: "UserSet" */
  update_UserSet_by_pk?: Maybe<UserSet>;
  /** update multiples rows of table: "UserSet" */
  update_UserSet_many?: Maybe<Array<Maybe<UserSet_Mutation_Response>>>;
  /** update data of the table: "UserState" */
  update_UserState?: Maybe<UserState_Mutation_Response>;
  /** update single row of the table: "UserState" */
  update_UserState_by_pk?: Maybe<UserState>;
  /** update multiples rows of table: "UserState" */
  update_UserState_many?: Maybe<Array<Maybe<UserState_Mutation_Response>>>;
  /** update data of the table: "UserTrainingPlan" */
  update_UserTrainingPlan?: Maybe<UserTrainingPlan_Mutation_Response>;
  /** update single row of the table: "UserTrainingPlan" */
  update_UserTrainingPlan_by_pk?: Maybe<UserTrainingPlan>;
  /** update multiples rows of table: "UserTrainingPlan" */
  update_UserTrainingPlan_many?: Maybe<Array<Maybe<UserTrainingPlan_Mutation_Response>>>;
  /** update data of the table: "UserWorkout" */
  update_UserWorkout?: Maybe<UserWorkout_Mutation_Response>;
  /** update single row of the table: "UserWorkout" */
  update_UserWorkout_by_pk?: Maybe<UserWorkout>;
  /** update multiples rows of table: "UserWorkout" */
  update_UserWorkout_many?: Maybe<Array<Maybe<UserWorkout_Mutation_Response>>>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
  /** update multiples rows of table: "User" */
  update_User_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "VendorErrorLog" */
  update_VendorErrorLog?: Maybe<VendorErrorLog_Mutation_Response>;
  /** update single row of the table: "VendorErrorLog" */
  update_VendorErrorLog_by_pk?: Maybe<VendorErrorLog>;
  /** update multiples rows of table: "VendorErrorLog" */
  update_VendorErrorLog_many?: Maybe<Array<Maybe<VendorErrorLog_Mutation_Response>>>;
  /** update data of the table: "VersionControl" */
  update_VersionControl?: Maybe<VersionControl_Mutation_Response>;
  /** update single row of the table: "VersionControl" */
  update_VersionControl_by_pk?: Maybe<VersionControl>;
  /** update multiples rows of table: "VersionControl" */
  update_VersionControl_many?: Maybe<Array<Maybe<VersionControl_Mutation_Response>>>;
  /** update data of the table: "WeeklyPlan" */
  update_WeeklyPlan?: Maybe<WeeklyPlan_Mutation_Response>;
  /** update single row of the table: "WeeklyPlan" */
  update_WeeklyPlan_by_pk?: Maybe<WeeklyPlan>;
  /** update multiples rows of table: "WeeklyPlan" */
  update_WeeklyPlan_many?: Maybe<Array<Maybe<WeeklyPlan_Mutation_Response>>>;
  /** update data of the table: "WorkinPrefrence" */
  update_WorkinPrefrence?: Maybe<WorkinPrefrence_Mutation_Response>;
  /** update single row of the table: "WorkinPrefrence" */
  update_WorkinPrefrence_by_pk?: Maybe<WorkinPrefrence>;
  /** update multiples rows of table: "WorkinPrefrence" */
  update_WorkinPrefrence_many?: Maybe<Array<Maybe<WorkinPrefrence_Mutation_Response>>>;
  /** update data of the table: "Workout" */
  update_Workout?: Maybe<Workout_Mutation_Response>;
  /** update data of the table: "WorkoutPrefrence" */
  update_WorkoutPrefrence?: Maybe<WorkoutPrefrence_Mutation_Response>;
  /** update single row of the table: "WorkoutPrefrence" */
  update_WorkoutPrefrence_by_pk?: Maybe<WorkoutPrefrence>;
  /** update multiples rows of table: "WorkoutPrefrence" */
  update_WorkoutPrefrence_many?: Maybe<Array<Maybe<WorkoutPrefrence_Mutation_Response>>>;
  /** update single row of the table: "Workout" */
  update_Workout_by_pk?: Maybe<Workout>;
  /** update multiples rows of table: "Workout" */
  update_Workout_many?: Maybe<Array<Maybe<Workout_Mutation_Response>>>;
  /** update data of the table: "_FollowingPro" */
  update__FollowingPro?: Maybe<_FollowingPro_Mutation_Response>;
  /** update multiples rows of table: "_FollowingPro" */
  update__FollowingPro_many?: Maybe<Array<Maybe<_FollowingPro_Mutation_Response>>>;
  /** update data of the table: "_GoalToUserPreferences" */
  update__GoalToUserPreferences?: Maybe<_GoalToUserPreferences_Mutation_Response>;
  /** update multiples rows of table: "_GoalToUserPreferences" */
  update__GoalToUserPreferences_many?: Maybe<Array<Maybe<_GoalToUserPreferences_Mutation_Response>>>;
  uploadHighKneeSet?: Maybe<HighKneeSetOutput>;
  uploadSet: UploadSetOutput;
  uploadSetV2: UploadSetV2Output;
  upsertCookBookItem?: Maybe<ActionResultOutput>;
  upsertMealPlanPreferences?: Maybe<MealPlanPreferences>;
  webRegister?: Maybe<RegisterUser>;
};


/** mutation root */
export type Mutation_RootAcknowledgeCoachAccessArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootAcknowledgeFitnessAndNutritionArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootAcknowledgeScreenInstallationArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootActivateSubscriptionArgs = {
  shopify_variant_id?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootAddFirmwareArgs = {
  activate: Scalars['Boolean']['input'];
  device: DevicesType;
  fileType: Scalars['String']['input'];
  location: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootAddOrRemoveFavoriteWorkoutArgs = {
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
  workout_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootAddUserToHouseholdArgs = {
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  signal_color?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootAssignBiostrapArgs = {
  biostrapId: Scalars['String']['input'];
  biostrapName?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootAssignScreenArgs = {
  screen_id?: InputMaybe<Scalars['String']['input']>;
  screen_name?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootCalculateActivityMetricsArgs = {
  midnight: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCalculateMacroGramsArgs = {
  goalWeight: Scalars['Int']['input'];
  nutrientsCategory: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootCalculateWorkoutZonesArgs = {
  workoutId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootChangeCoachedPlanArgs = {
  custom_plan?: InputMaybe<CustomPlanInput>;
  lock_coached_plan?: InputMaybe<Scalars['Boolean']['input']>;
  preset_plan_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootChangeGoalArgs = {
  fitness_goal_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootChangeProductVariantArgs = {
  shopify_variant_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootConfirmAndCreatePresetPlanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  fitness_goal_id?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Array<InputMaybe<Array<InputMaybe<PresetPlanDay>>>>>;
  plan_structure_id: Scalars['String']['input'];
  plan_type: PlanTypeEnum;
  title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootConfirmAndUpdatePresetPlanArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  fitness_goal_id?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  plan?: InputMaybe<Array<InputMaybe<Array<InputMaybe<PresetPlanDay>>>>>;
  plan_structure_id: Scalars['String']['input'];
  plan_type?: InputMaybe<PlanTypeEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootCreateCoachedHabitArgs = {
  coach_id: Scalars['String']['input'];
  custom_congrats?: InputMaybe<Scalars['String']['input']>;
  custom_try_again?: InputMaybe<Scalars['String']['input']>;
  days_required?: InputMaybe<Scalars['Int']['input']>;
  goal_value?: InputMaybe<Scalars['Int']['input']>;
  habit_type: HabitTypeEnum;
  long_goal?: InputMaybe<Scalars['String']['input']>;
  max_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  medium_goal?: InputMaybe<Scalars['String']['input']>;
  min_checkin_time?: InputMaybe<Scalars['Int']['input']>;
  short_goal?: InputMaybe<Scalars['String']['input']>;
  sub_type: HabitSubTypeEnum;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateCoachedNutritionArgs = {
  calorie_deficit?: InputMaybe<Scalars['Int']['input']>;
  coach_id: Scalars['String']['input'];
  current_weight?: InputMaybe<Scalars['Float']['input']>;
  custom_carbs?: InputMaybe<Array<InputMaybe<TitleWithDescription>>>;
  custom_fats?: InputMaybe<Array<InputMaybe<TitleWithDescription>>>;
  custom_proteins?: InputMaybe<Array<InputMaybe<TitleWithDescription>>>;
  diet_name?: InputMaybe<Scalars['String']['input']>;
  goal_weight?: InputMaybe<Scalars['Float']['input']>;
  grams_recommended_carbs?: InputMaybe<Scalars['Int']['input']>;
  grams_recommended_fats?: InputMaybe<Scalars['Int']['input']>;
  grams_recommended_proteins?: InputMaybe<Scalars['Int']['input']>;
  nutrients_category?: InputMaybe<Scalars['Int']['input']>;
  nutrition_goal_id?: InputMaybe<Scalars['String']['input']>;
  percent_recommended_carbs?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_fats?: InputMaybe<Scalars['Int']['input']>;
  percent_recommended_proteins?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateFirmwareContentV2Args = {
  device?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  hardware_type?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  version_number?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootCreateNutritionReportArgs = {
  nutritionGoal?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDailyCheckinArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDailyCheckinV2Args = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteFirmwareContentV2Args = {
  firmware_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteOneUserArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ActivationCodeArgs = {
  where: ActivationCode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivationCode_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AlterTypeColorArgs = {
  where: AlterTypeColor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AlterTypeColor_By_PkArgs = {
  letter: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CoachArgs = {
  where: Coach_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coach_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CoachedHabitArgs = {
  where: CoachedHabit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoachedHabit_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CoachedNutritionArgs = {
  where: CoachedNutrition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoachedNutrition_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DnaKitArgs = {
  where: DnaKit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DnaKit_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DnaResultsArgs = {
  where: DnaResults_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DnaResults_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DeliveryArgs = {
  where: Delivery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeliveryHistoryArgs = {
  where: DeliveryHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeliveryHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Delivery_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DeviceArgs = {
  where: Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Device_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DiagnosticsArgs = {
  where: Diagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FinancePlanArgs = {
  where: FinancePlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FinancePlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FirmwareContentArgs = {
  where: FirmwareContent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FirmwareContent_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GeneticDirectionWebhookArgs = {
  where: GeneticDirectionWebhook_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GeneticDirectionWebhook_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GlobalFeatureControlArgs = {
  where: GlobalFeatureControl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GlobalFeatureControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GoalArgs = {
  where: Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Goal_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HabitDayArgs = {
  where: HabitDay_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HabitDay_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HeartRateDiagnosticsArgs = {
  where: HeartRateDiagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HeartRateDiagnostics_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HouseholdArgs = {
  where: Household_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Household_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MarketingEmailArgs = {
  where: MarketingEmail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MarketingEmail_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MasteryArgs = {
  where: Mastery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mastery_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OnboardingInfoArgs = {
  where: OnboardingInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OnboardingInfo_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OnboardingVideosArgs = {
  where: OnboardingVideos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OnboardingVideos_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OrderLineItemArgs = {
  where: OrderLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OrderLineItem_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentHistoryArgs = {
  where: PaymentHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PaymentHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PlanDayArgs = {
  where: PlanDay_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PlanDay_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PresetDietArgs = {
  where: PresetDiet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PresetDiet_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProArgs = {
  where: Pro_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProMusicArgs = {
  where: ProMusic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProMusic_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Pro_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductLogArgs = {
  where: ProductLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PromotionalItemArgs = {
  where: PromotionalItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PromotionalItem_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuizSurveyArgs = {
  where: QuizSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuizSurvey_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuoteArgs = {
  where: Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quote_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReimbursementRequirementArgs = {
  where: ReimbursementRequirement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReimbursementRequirement_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ScreenOrderArgs = {
  where: ScreenOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ScreenOrder_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SearchResultArgs = {
  where: SearchResult_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SearchResult_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SleepSurveyArgs = {
  where: SleepSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SleepSurvey_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionArgs = {
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionLogArgs = {
  where: SubscriptionLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TokenArgs = {
  where: Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TokenHistoryArgs = {
  where: TokenHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TokenHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Token_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserAggregatesArgs = {
  where: UserAggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserAggregates_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserAssessmentArgs = {
  where: UserAssessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserAssessment_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserFeatureControlArgs = {
  where: UserFeatureControl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserFeatureControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserFeedbackArgs = {
  where: UserFeedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserFeedback_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserFinancePlanArgs = {
  where: UserFinancePlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserFinancePlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserHabitArgs = {
  where: UserHabit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserHabit_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserMovementDailyArgs = {
  where: UserMovementDaily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserMovementDaily_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserPreferencesArgs = {
  where: UserPreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserPreferences_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserProductArgs = {
  where: UserProduct_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserProductHistoryArgs = {
  where: UserProductHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserProductHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserProduct_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserReadinessArgs = {
  where: UserReadiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserReadinessLogArgs = {
  where: UserReadinessLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserReadinessLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserReadiness_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserReimbursmentHistoryArgs = {
  where: UserReimbursmentHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserReimbursmentHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserRepArgs = {
  where: UserRep_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserRep_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserSetArgs = {
  where: UserSet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserSet_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserStateArgs = {
  where: UserState_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserState_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserTrainingPlanArgs = {
  where: UserTrainingPlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserTrainingPlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserWorkoutArgs = {
  where: UserWorkout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserWorkout_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VendorErrorLogArgs = {
  where: VendorErrorLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VendorErrorLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VersionControlArgs = {
  where: VersionControl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VersionControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_WeeklyPlanArgs = {
  where: WeeklyPlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeeklyPlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_WorkinPrefrenceArgs = {
  where: WorkinPrefrence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkinPrefrence_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_WorkoutArgs = {
  where: Workout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkoutPrefrenceArgs = {
  where: WorkoutPrefrence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkoutPrefrence_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Workout_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete__FollowingProArgs = {
  where: _FollowingPro_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__GoalToUserPreferencesArgs = {
  where: _GoalToUserPreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootEndAssessmentArgs = {
  assessment_id: Scalars['String']['input'];
  completed_epoch: Scalars['Int']['input'];
  hr?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootEndWorkoutArgs = {
  calories?: InputMaybe<Scalars['Int']['input']>;
  completedAmount: Scalars['Int']['input'];
  completedEpoch: Scalars['Int']['input'];
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  heartRate?: InputMaybe<Scalars['Int']['input']>;
  rpe?: InputMaybe<Scalars['Float']['input']>;
  workout_id: Scalars['String']['input'];
  zone_data?: InputMaybe<Array<InputMaybe<Scalars['json']['input']>>>;
};


/** mutation root */
export type Mutation_RootFetchHabitsArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootFetchMealPlanPreferencesArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootFindOrCreateMealPlanArgs = {
  regen?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootGenerateSignedUrlArgs = {
  key: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootHasuraSyncArgs = {
  email: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootHelloLocalHasuraArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootInsert_ActivationCodeArgs = {
  objects: Array<ActivationCode_Insert_Input>;
  on_conflict?: InputMaybe<ActivationCode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivationCode_OneArgs = {
  object: ActivationCode_Insert_Input;
  on_conflict?: InputMaybe<ActivationCode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AlterTypeColorArgs = {
  objects: Array<AlterTypeColor_Insert_Input>;
  on_conflict?: InputMaybe<AlterTypeColor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AlterTypeColor_OneArgs = {
  object: AlterTypeColor_Insert_Input;
  on_conflict?: InputMaybe<AlterTypeColor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoachArgs = {
  objects: Array<Coach_Insert_Input>;
  on_conflict?: InputMaybe<Coach_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coach_OneArgs = {
  object: Coach_Insert_Input;
  on_conflict?: InputMaybe<Coach_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoachedHabitArgs = {
  objects: Array<CoachedHabit_Insert_Input>;
  on_conflict?: InputMaybe<CoachedHabit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoachedHabit_OneArgs = {
  object: CoachedHabit_Insert_Input;
  on_conflict?: InputMaybe<CoachedHabit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoachedNutritionArgs = {
  objects: Array<CoachedNutrition_Insert_Input>;
  on_conflict?: InputMaybe<CoachedNutrition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoachedNutrition_OneArgs = {
  object: CoachedNutrition_Insert_Input;
  on_conflict?: InputMaybe<CoachedNutrition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DnaKitArgs = {
  objects: Array<DnaKit_Insert_Input>;
  on_conflict?: InputMaybe<DnaKit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DnaKit_OneArgs = {
  object: DnaKit_Insert_Input;
  on_conflict?: InputMaybe<DnaKit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DnaResultsArgs = {
  objects: Array<DnaResults_Insert_Input>;
  on_conflict?: InputMaybe<DnaResults_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DnaResults_OneArgs = {
  object: DnaResults_Insert_Input;
  on_conflict?: InputMaybe<DnaResults_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryArgs = {
  objects: Array<Delivery_Insert_Input>;
  on_conflict?: InputMaybe<Delivery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryHistoryArgs = {
  objects: Array<DeliveryHistory_Insert_Input>;
  on_conflict?: InputMaybe<DeliveryHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryHistory_OneArgs = {
  object: DeliveryHistory_Insert_Input;
  on_conflict?: InputMaybe<DeliveryHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Delivery_OneArgs = {
  object: Delivery_Insert_Input;
  on_conflict?: InputMaybe<Delivery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeviceArgs = {
  objects: Array<Device_Insert_Input>;
  on_conflict?: InputMaybe<Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_OneArgs = {
  object: Device_Insert_Input;
  on_conflict?: InputMaybe<Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiagnosticsArgs = {
  objects: Array<Diagnostics_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_OneArgs = {
  object: Diagnostics_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FinancePlanArgs = {
  objects: Array<FinancePlan_Insert_Input>;
  on_conflict?: InputMaybe<FinancePlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FinancePlan_OneArgs = {
  object: FinancePlan_Insert_Input;
  on_conflict?: InputMaybe<FinancePlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FirmwareContentArgs = {
  objects: Array<FirmwareContent_Insert_Input>;
  on_conflict?: InputMaybe<FirmwareContent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FirmwareContent_OneArgs = {
  object: FirmwareContent_Insert_Input;
  on_conflict?: InputMaybe<FirmwareContent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GeneticDirectionWebhookArgs = {
  objects: Array<GeneticDirectionWebhook_Insert_Input>;
  on_conflict?: InputMaybe<GeneticDirectionWebhook_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GeneticDirectionWebhook_OneArgs = {
  object: GeneticDirectionWebhook_Insert_Input;
  on_conflict?: InputMaybe<GeneticDirectionWebhook_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GlobalFeatureControlArgs = {
  objects: Array<GlobalFeatureControl_Insert_Input>;
  on_conflict?: InputMaybe<GlobalFeatureControl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GlobalFeatureControl_OneArgs = {
  object: GlobalFeatureControl_Insert_Input;
  on_conflict?: InputMaybe<GlobalFeatureControl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoalArgs = {
  objects: Array<Goal_Insert_Input>;
  on_conflict?: InputMaybe<Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goal_OneArgs = {
  object: Goal_Insert_Input;
  on_conflict?: InputMaybe<Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HabitDayArgs = {
  objects: Array<HabitDay_Insert_Input>;
  on_conflict?: InputMaybe<HabitDay_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HabitDay_OneArgs = {
  object: HabitDay_Insert_Input;
  on_conflict?: InputMaybe<HabitDay_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeartRateDiagnosticsArgs = {
  objects: Array<HeartRateDiagnostics_Insert_Input>;
  on_conflict?: InputMaybe<HeartRateDiagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeartRateDiagnostics_OneArgs = {
  object: HeartRateDiagnostics_Insert_Input;
  on_conflict?: InputMaybe<HeartRateDiagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HouseholdArgs = {
  objects: Array<Household_Insert_Input>;
  on_conflict?: InputMaybe<Household_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Household_OneArgs = {
  object: Household_Insert_Input;
  on_conflict?: InputMaybe<Household_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingEmailArgs = {
  objects: Array<MarketingEmail_Insert_Input>;
  on_conflict?: InputMaybe<MarketingEmail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingEmail_OneArgs = {
  object: MarketingEmail_Insert_Input;
  on_conflict?: InputMaybe<MarketingEmail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MasteryArgs = {
  objects: Array<Mastery_Insert_Input>;
  on_conflict?: InputMaybe<Mastery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mastery_OneArgs = {
  object: Mastery_Insert_Input;
  on_conflict?: InputMaybe<Mastery_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OnboardingInfoArgs = {
  objects: Array<OnboardingInfo_Insert_Input>;
  on_conflict?: InputMaybe<OnboardingInfo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OnboardingInfo_OneArgs = {
  object: OnboardingInfo_Insert_Input;
  on_conflict?: InputMaybe<OnboardingInfo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OnboardingVideosArgs = {
  objects: Array<OnboardingVideos_Insert_Input>;
  on_conflict?: InputMaybe<OnboardingVideos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OnboardingVideos_OneArgs = {
  object: OnboardingVideos_Insert_Input;
  on_conflict?: InputMaybe<OnboardingVideos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>;
  on_conflict?: InputMaybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderLineItemArgs = {
  objects: Array<OrderLineItem_Insert_Input>;
  on_conflict?: InputMaybe<OrderLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderLineItem_OneArgs = {
  object: OrderLineItem_Insert_Input;
  on_conflict?: InputMaybe<OrderLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input;
  on_conflict?: InputMaybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentHistoryArgs = {
  objects: Array<PaymentHistory_Insert_Input>;
  on_conflict?: InputMaybe<PaymentHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentHistory_OneArgs = {
  object: PaymentHistory_Insert_Input;
  on_conflict?: InputMaybe<PaymentHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlanDayArgs = {
  objects: Array<PlanDay_Insert_Input>;
  on_conflict?: InputMaybe<PlanDay_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlanDay_OneArgs = {
  object: PlanDay_Insert_Input;
  on_conflict?: InputMaybe<PlanDay_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PresetDietArgs = {
  objects: Array<PresetDiet_Insert_Input>;
  on_conflict?: InputMaybe<PresetDiet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PresetDiet_OneArgs = {
  object: PresetDiet_Insert_Input;
  on_conflict?: InputMaybe<PresetDiet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProArgs = {
  objects: Array<Pro_Insert_Input>;
  on_conflict?: InputMaybe<Pro_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProMusicArgs = {
  objects: Array<ProMusic_Insert_Input>;
  on_conflict?: InputMaybe<ProMusic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProMusic_OneArgs = {
  object: ProMusic_Insert_Input;
  on_conflict?: InputMaybe<ProMusic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pro_OneArgs = {
  object: Pro_Insert_Input;
  on_conflict?: InputMaybe<Pro_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductLogArgs = {
  objects: Array<ProductLog_Insert_Input>;
  on_conflict?: InputMaybe<ProductLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductLog_OneArgs = {
  object: ProductLog_Insert_Input;
  on_conflict?: InputMaybe<ProductLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionalItemArgs = {
  objects: Array<PromotionalItem_Insert_Input>;
  on_conflict?: InputMaybe<PromotionalItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionalItem_OneArgs = {
  object: PromotionalItem_Insert_Input;
  on_conflict?: InputMaybe<PromotionalItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuizSurveyArgs = {
  objects: Array<QuizSurvey_Insert_Input>;
  on_conflict?: InputMaybe<QuizSurvey_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuizSurvey_OneArgs = {
  object: QuizSurvey_Insert_Input;
  on_conflict?: InputMaybe<QuizSurvey_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuoteArgs = {
  objects: Array<Quote_Insert_Input>;
  on_conflict?: InputMaybe<Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_OneArgs = {
  object: Quote_Insert_Input;
  on_conflict?: InputMaybe<Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReimbursementRequirementArgs = {
  objects: Array<ReimbursementRequirement_Insert_Input>;
  on_conflict?: InputMaybe<ReimbursementRequirement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReimbursementRequirement_OneArgs = {
  object: ReimbursementRequirement_Insert_Input;
  on_conflict?: InputMaybe<ReimbursementRequirement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScreenOrderArgs = {
  objects: Array<ScreenOrder_Insert_Input>;
  on_conflict?: InputMaybe<ScreenOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScreenOrder_OneArgs = {
  object: ScreenOrder_Insert_Input;
  on_conflict?: InputMaybe<ScreenOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SearchResultArgs = {
  objects: Array<SearchResult_Insert_Input>;
  on_conflict?: InputMaybe<SearchResult_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SearchResult_OneArgs = {
  object: SearchResult_Insert_Input;
  on_conflict?: InputMaybe<SearchResult_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SleepSurveyArgs = {
  objects: Array<SleepSurvey_Insert_Input>;
  on_conflict?: InputMaybe<SleepSurvey_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SleepSurvey_OneArgs = {
  object: SleepSurvey_Insert_Input;
  on_conflict?: InputMaybe<SleepSurvey_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionArgs = {
  objects: Array<Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionLogArgs = {
  objects: Array<SubscriptionLog_Insert_Input>;
  on_conflict?: InputMaybe<SubscriptionLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionLog_OneArgs = {
  object: SubscriptionLog_Insert_Input;
  on_conflict?: InputMaybe<SubscriptionLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_OneArgs = {
  object: Subscription_Insert_Input;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TokenArgs = {
  objects: Array<Token_Insert_Input>;
  on_conflict?: InputMaybe<Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TokenHistoryArgs = {
  objects: Array<TokenHistory_Insert_Input>;
  on_conflict?: InputMaybe<TokenHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TokenHistory_OneArgs = {
  object: TokenHistory_Insert_Input;
  on_conflict?: InputMaybe<TokenHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Token_OneArgs = {
  object: Token_Insert_Input;
  on_conflict?: InputMaybe<Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAggregatesArgs = {
  objects: Array<UserAggregates_Insert_Input>;
  on_conflict?: InputMaybe<UserAggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAggregates_OneArgs = {
  object: UserAggregates_Insert_Input;
  on_conflict?: InputMaybe<UserAggregates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAssessmentArgs = {
  objects: Array<UserAssessment_Insert_Input>;
  on_conflict?: InputMaybe<UserAssessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAssessment_OneArgs = {
  object: UserAssessment_Insert_Input;
  on_conflict?: InputMaybe<UserAssessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserFeatureControlArgs = {
  objects: Array<UserFeatureControl_Insert_Input>;
  on_conflict?: InputMaybe<UserFeatureControl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserFeatureControl_OneArgs = {
  object: UserFeatureControl_Insert_Input;
  on_conflict?: InputMaybe<UserFeatureControl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserFeedbackArgs = {
  objects: Array<UserFeedback_Insert_Input>;
  on_conflict?: InputMaybe<UserFeedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserFeedback_OneArgs = {
  object: UserFeedback_Insert_Input;
  on_conflict?: InputMaybe<UserFeedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserFinancePlanArgs = {
  objects: Array<UserFinancePlan_Insert_Input>;
  on_conflict?: InputMaybe<UserFinancePlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserFinancePlan_OneArgs = {
  object: UserFinancePlan_Insert_Input;
  on_conflict?: InputMaybe<UserFinancePlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserHabitArgs = {
  objects: Array<UserHabit_Insert_Input>;
  on_conflict?: InputMaybe<UserHabit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserHabit_OneArgs = {
  object: UserHabit_Insert_Input;
  on_conflict?: InputMaybe<UserHabit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserMovementDailyArgs = {
  objects: Array<UserMovementDaily_Insert_Input>;
  on_conflict?: InputMaybe<UserMovementDaily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserMovementDaily_OneArgs = {
  object: UserMovementDaily_Insert_Input;
  on_conflict?: InputMaybe<UserMovementDaily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserPreferencesArgs = {
  objects: Array<UserPreferences_Insert_Input>;
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserPreferences_OneArgs = {
  object: UserPreferences_Insert_Input;
  on_conflict?: InputMaybe<UserPreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserProductArgs = {
  objects: Array<UserProduct_Insert_Input>;
  on_conflict?: InputMaybe<UserProduct_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserProductHistoryArgs = {
  objects: Array<UserProductHistory_Insert_Input>;
  on_conflict?: InputMaybe<UserProductHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserProductHistory_OneArgs = {
  object: UserProductHistory_Insert_Input;
  on_conflict?: InputMaybe<UserProductHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserProduct_OneArgs = {
  object: UserProduct_Insert_Input;
  on_conflict?: InputMaybe<UserProduct_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserReadinessArgs = {
  objects: Array<UserReadiness_Insert_Input>;
  on_conflict?: InputMaybe<UserReadiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserReadinessLogArgs = {
  objects: Array<UserReadinessLog_Insert_Input>;
  on_conflict?: InputMaybe<UserReadinessLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserReadinessLog_OneArgs = {
  object: UserReadinessLog_Insert_Input;
  on_conflict?: InputMaybe<UserReadinessLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserReadiness_OneArgs = {
  object: UserReadiness_Insert_Input;
  on_conflict?: InputMaybe<UserReadiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserReimbursmentHistoryArgs = {
  objects: Array<UserReimbursmentHistory_Insert_Input>;
  on_conflict?: InputMaybe<UserReimbursmentHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserReimbursmentHistory_OneArgs = {
  object: UserReimbursmentHistory_Insert_Input;
  on_conflict?: InputMaybe<UserReimbursmentHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserRepArgs = {
  objects: Array<UserRep_Insert_Input>;
  on_conflict?: InputMaybe<UserRep_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserRep_OneArgs = {
  object: UserRep_Insert_Input;
  on_conflict?: InputMaybe<UserRep_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserSetArgs = {
  objects: Array<UserSet_Insert_Input>;
  on_conflict?: InputMaybe<UserSet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserSet_OneArgs = {
  object: UserSet_Insert_Input;
  on_conflict?: InputMaybe<UserSet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserStateArgs = {
  objects: Array<UserState_Insert_Input>;
  on_conflict?: InputMaybe<UserState_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserState_OneArgs = {
  object: UserState_Insert_Input;
  on_conflict?: InputMaybe<UserState_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserTrainingPlanArgs = {
  objects: Array<UserTrainingPlan_Insert_Input>;
  on_conflict?: InputMaybe<UserTrainingPlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserTrainingPlan_OneArgs = {
  object: UserTrainingPlan_Insert_Input;
  on_conflict?: InputMaybe<UserTrainingPlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserWorkoutArgs = {
  objects: Array<UserWorkout_Insert_Input>;
  on_conflict?: InputMaybe<UserWorkout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserWorkout_OneArgs = {
  object: UserWorkout_Insert_Input;
  on_conflict?: InputMaybe<UserWorkout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VendorErrorLogArgs = {
  objects: Array<VendorErrorLog_Insert_Input>;
  on_conflict?: InputMaybe<VendorErrorLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VendorErrorLog_OneArgs = {
  object: VendorErrorLog_Insert_Input;
  on_conflict?: InputMaybe<VendorErrorLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VersionControlArgs = {
  objects: Array<VersionControl_Insert_Input>;
  on_conflict?: InputMaybe<VersionControl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VersionControl_OneArgs = {
  object: VersionControl_Insert_Input;
  on_conflict?: InputMaybe<VersionControl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WeeklyPlanArgs = {
  objects: Array<WeeklyPlan_Insert_Input>;
  on_conflict?: InputMaybe<WeeklyPlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WeeklyPlan_OneArgs = {
  object: WeeklyPlan_Insert_Input;
  on_conflict?: InputMaybe<WeeklyPlan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkinPrefrenceArgs = {
  objects: Array<WorkinPrefrence_Insert_Input>;
  on_conflict?: InputMaybe<WorkinPrefrence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkinPrefrence_OneArgs = {
  object: WorkinPrefrence_Insert_Input;
  on_conflict?: InputMaybe<WorkinPrefrence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkoutArgs = {
  objects: Array<Workout_Insert_Input>;
  on_conflict?: InputMaybe<Workout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkoutPrefrenceArgs = {
  objects: Array<WorkoutPrefrence_Insert_Input>;
  on_conflict?: InputMaybe<WorkoutPrefrence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkoutPrefrence_OneArgs = {
  object: WorkoutPrefrence_Insert_Input;
  on_conflict?: InputMaybe<WorkoutPrefrence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workout_OneArgs = {
  object: Workout_Insert_Input;
  on_conflict?: InputMaybe<Workout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__FollowingProArgs = {
  objects: Array<_FollowingPro_Insert_Input>;
  on_conflict?: InputMaybe<_FollowingPro_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__FollowingPro_OneArgs = {
  object: _FollowingPro_Insert_Input;
  on_conflict?: InputMaybe<_FollowingPro_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__GoalToUserPreferencesArgs = {
  objects: Array<_GoalToUserPreferences_Insert_Input>;
  on_conflict?: InputMaybe<_GoalToUserPreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__GoalToUserPreferences_OneArgs = {
  object: _GoalToUserPreferences_Insert_Input;
  on_conflict?: InputMaybe<_GoalToUserPreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLogRecommendationArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  full_readiness_override: Scalars['Boolean']['input'];
  partial_readiness_override: Scalars['Boolean']['input'];
  readiness_score?: InputMaybe<Scalars['Float']['input']>;
  user_id: Scalars['String']['input'];
  workout_type?: InputMaybe<WorkoutTypeEnum>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPauseOrResumeWorkoutArgs = {
  epoch: Scalars['Int']['input'];
  pause_or_resume?: InputMaybe<PauseOrResumeEnum>;
  workout_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPerformReadinessCalculationsArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPerformWorkoutCalculationsArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPrestigeUserArgs = {
  force_prestige_id_abandonment?: InputMaybe<Scalars['Boolean']['input']>;
  requested_cardio_group?: InputMaybe<RecommenderGeneralRankGroup>;
  requested_cardio_level?: InputMaybe<Scalars['Int']['input']>;
  requested_strength_level?: InputMaybe<Scalars['Int']['input']>;
  requested_workin_group?: InputMaybe<RecommenderGeneralRankGroup>;
  requested_workin_level?: InputMaybe<Scalars['Int']['input']>;
  target_user_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPromoteToCoachArgs = {
  contentful_id: Scalars['String']['input'];
  sf_coach_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootQualifyWorkoutArgs = {
  completedAmount: Scalars['Int']['input'];
  workout_id: Scalars['String']['input'];
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootQualifyWorkoutV2Args = {
  completedAmount: Scalars['Int']['input'];
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  workout_id: Scalars['String']['input'];
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootQualifyWorkoutV3Args = {
  completedAmount: Scalars['Int']['input'];
  duration_seen?: InputMaybe<Scalars['Int']['input']>;
  workout_id: Scalars['String']['input'];
  zone1sec?: InputMaybe<Scalars['Int']['input']>;
  zone2sec?: InputMaybe<Scalars['Int']['input']>;
  zone3sec?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootReadinessOverrideArgs = {
  date: Scalars['String']['input'];
  score: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRecommenderArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRecommenderV2Args = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRefreshPearTokenArgs = {
  alterUserId?: InputMaybe<Scalars['String']['input']>;
  pearRefreshToken: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRefreshPlanArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  ethnicity?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  lastName: Scalars['String']['input'];
  preferMetric?: InputMaybe<Scalars['Boolean']['input']>;
  strapColor?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};


/** mutation root */
export type Mutation_RootRegisterDnaKitArgs = {
  activationCode: Scalars['String']['input'];
  alterUserId: Scalars['String']['input'];
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  ethnicity?: InputMaybe<Ethnicity>;
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  heightFeet: Scalars['Int']['input'];
  heightInches: Scalars['Int']['input'];
  kitId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  marketingOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  overEighteen?: InputMaybe<Scalars['Boolean']['input']>;
  smsOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  weightInLbs?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootRegisterDnaKitStagingArgs = {
  activationCode: Scalars['String']['input'];
  alterUserId: Scalars['String']['input'];
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  ethnicity?: InputMaybe<Ethnicity>;
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  heightFeet: Scalars['Int']['input'];
  heightInches: Scalars['Int']['input'];
  kitId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  marketingOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  overEighteen?: InputMaybe<Scalars['Boolean']['input']>;
  smsOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  weightInLbs?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootRemoveUserFromHouseholdArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootResetNutritionReportArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootResetOnboardingArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootResetPlanArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootRunInfluxCalculationsArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSaveFileBase64Args = {
  file: FileInput;
};


/** mutation root */
export type Mutation_RootSetUserGoalsArgs = {
  goals_id: Array<InputMaybe<Scalars['String']['input']>>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootSoftDeleteUserArgs = {
  auth0UserId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootStartAssessmentArgs = {
  assessment_type: AssessmentTypeEnum;
  screen_data?: InputMaybe<Scalars['json']['input']>;
  start_epoch: Scalars['Int']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootStartWorkoutArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  is_demo?: InputMaybe<Scalars['Boolean']['input']>;
  off_screen_type?: InputMaybe<Scalars['String']['input']>;
  pear_workout_structure?: InputMaybe<Scalars['json']['input']>;
  recommendation_log_id?: InputMaybe<Scalars['String']['input']>;
  screen_data?: InputMaybe<Scalars['json']['input']>;
  startEpoch: Scalars['Int']['input'];
  sub_type?: InputMaybe<WorkoutSubTypeEnum>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainerId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  workoutId?: InputMaybe<Scalars['String']['input']>;
  workout_pear_id?: InputMaybe<Scalars['String']['input']>;
  workout_pear_sku?: InputMaybe<Scalars['String']['input']>;
  workout_type: WorkoutTypeEnum;
};


/** mutation root */
export type Mutation_RootSubmitDiagnosticsArgs = {
  data: Array<InputMaybe<DiagnosticData>>;
};


/** mutation root */
export type Mutation_RootSubmitHrDiagnosticsArgs = {
  data: Array<InputMaybe<HrDiagnosticData>>;
  device?: InputMaybe<DiagnosticsDeviceType>;
  hr_type?: InputMaybe<HrDiagnosticType>;
};


/** mutation root */
export type Mutation_RootSubmitHabitCompletedArgs = {
  coach_id?: InputMaybe<Scalars['String']['input']>;
  submission_value?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitHardwareDiagnosticsArgs = {
  device_info?: InputMaybe<Scalars['json']['input']>;
  device_type: DiagnosticsDeviceType;
  diagnostics?: InputMaybe<Array<InputMaybe<Scalars['json']['input']>>>;
  pass: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitQuizSurveyArgs = {
  activity?: InputMaybe<SurveyQuizActivity>;
  age?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  equipments_owned?: InputMaybe<Array<InputMaybe<SurveyEquipmentEnum>>>;
  favorite_workouts?: InputMaybe<Array<InputMaybe<SurveyWorkoutsEnum>>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<SurveyQuizGenderEnum>;
  hows_your_sleep?: InputMaybe<SurveySleepEnum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  main_motivation?: InputMaybe<SurveyMotivationEnum>;
  motivation_struggle?: InputMaybe<Scalars['Boolean']['input']>;
  tried_at_home?: InputMaybe<Scalars['Boolean']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootSubmitRegistrationSurveyArgs = {
  additional_notes?: InputMaybe<Scalars['String']['input']>;
  age?: InputMaybe<Scalars['Int']['input']>;
  areas_needing_help?: InputMaybe<Array<InputMaybe<RegAreasNeedingHelp>>>;
  current_fitness_ability?: InputMaybe<RegFitnessAbility>;
  current_weight_lbs?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  favorite_type_of_exercise?: InputMaybe<RegFavoriteExercise>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<RegGender>;
  glp_status?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  main_motivation?: InputMaybe<RegMotivation>;
  services_tried?: InputMaybe<Array<InputMaybe<RegServicesTried>>>;
  user_id: Scalars['String']['input'];
  weight_loss_goal?: InputMaybe<RegWeightLossGoal>;
  weight_loss_goal_custom?: InputMaybe<Scalars['String']['input']>;
  weight_loss_timeline?: InputMaybe<RegWeightLossTimeline>;
};


/** mutation root */
export type Mutation_RootSubmitReportViewedArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitSurveyToSfArgs = {
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitSurveyToSfV2Args = {
  consultationItems?: InputMaybe<Array<InputMaybe<ConsultationItems>>>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitUserFeedbackArgs = {
  feedback?: InputMaybe<Array<InputMaybe<UserFeedbackInput>>>;
};


/** mutation root */
export type Mutation_RootSwapMealArgs = {
  meal_plan_id: Scalars['String']['input'];
  target_meal_key: MealKeyEnum;
  target_plan_index: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootUnassignBiostrapArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateConsentDetailsArgs = {
  auth0UserId: Scalars['String']['input'];
  consent_location?: InputMaybe<Scalars['String']['input']>;
  is_consented: Scalars['Boolean']['input'];
  terms_version?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateDnaResultsArgs = {
  alterUserId: Scalars['String']['input'];
  carbUtilization?: InputMaybe<Scalars['String']['input']>;
  chronotype?: InputMaybe<Scalars['String']['input']>;
  core_type?: InputMaybe<Scalars['String']['input']>;
  fatUtilization?: InputMaybe<Scalars['String']['input']>;
  injuryRisk?: InputMaybe<Scalars['String']['input']>;
  intrinsicMotivation?: InputMaybe<Scalars['String']['input']>;
  mentalAcuity?: InputMaybe<Scalars['String']['input']>;
  proteinUtilization?: InputMaybe<Scalars['String']['input']>;
  responseToCardio?: InputMaybe<Scalars['String']['input']>;
  responseToStrength?: InputMaybe<Scalars['String']['input']>;
  stressTolerance?: InputMaybe<Scalars['String']['input']>;
  systemicInflammation?: InputMaybe<Scalars['String']['input']>;
  weightLossTendency?: InputMaybe<Scalars['String']['input']>;
  weightRegain?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateEmailArgs = {
  auth0UserId: Scalars['String']['input'];
  newEmail: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateFirmwareContentV2Args = {
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  firmware_id?: InputMaybe<Scalars['String']['input']>;
  hardware_type?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  version_number?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateFirmwareV1ActiveStatusArgs = {
  device?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateFirmwareV1BetaStatusArgs = {
  device: Scalars['String']['input'];
  isBeta?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateMealSelectionStatusArgs = {
  meal_plan_id: Scalars['String']['input'];
  targets: Array<InputMaybe<MealPlanSelectionTarget>>;
};


/** mutation root */
export type Mutation_RootUpdateMobileVersionsArgs = {
  androidBuild?: InputMaybe<Scalars['Int']['input']>;
  androidVersion?: InputMaybe<Scalars['String']['input']>;
  iOSBuild?: InputMaybe<Scalars['Int']['input']>;
  iOSVersion?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdatePasswordArgs = {
  auth0UserId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateReimbursementTokensArgs = {
  credits: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  chat_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  cuid?: InputMaybe<Scalars['String']['input']>;
  data_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  data_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  ethnicity?: InputMaybe<Ethnicity>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  goalWeight?: InputMaybe<Scalars['Float']['input']>;
  has_warming_state?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  home_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  inRingMode?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  plan_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_locked?: InputMaybe<Scalars['Boolean']['input']>;
  plan_tab_seen?: InputMaybe<Scalars['Boolean']['input']>;
  preferMetric?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tab_complete?: InputMaybe<Scalars['Boolean']['input']>;
  profile_tip_complete?: InputMaybe<Scalars['Boolean']['input']>;
  radioPreferences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ringId?: InputMaybe<Scalars['String']['input']>;
  ringMacAddress?: InputMaybe<Scalars['String']['input']>;
  ringName?: InputMaybe<Scalars['String']['input']>;
  ring_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  ring_setup_completed_at?: InputMaybe<Scalars['String']['input']>;
  ring_setup_started_at?: InputMaybe<Scalars['String']['input']>;
  screen_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  screen_setup_completed_at?: InputMaybe<Scalars['String']['input']>;
  screen_setup_started_at?: InputMaybe<Scalars['String']['input']>;
  shopifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  signal_color?: InputMaybe<Scalars['String']['input']>;
  signal_setup_complete?: InputMaybe<Scalars['Boolean']['input']>;
  signal_setup_completed_at?: InputMaybe<Scalars['String']['input']>;
  signal_setup_started_at?: InputMaybe<Scalars['String']['input']>;
  strapColor?: InputMaybe<Scalars['String']['input']>;
  strapLocation?: InputMaybe<StrapLocationEnum>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateUserPreferencesArgs = {
  cardioPreference?: InputMaybe<Scalars['String']['input']>;
  coaching_cues?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_coach_chat?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_coach_chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_low_signal_battery_notification?: InputMaybe<Scalars['Boolean']['input']>;
  ownsBand?: InputMaybe<Scalars['String']['input']>;
  ownsDumbells?: InputMaybe<Scalars['String']['input']>;
  receive_personalized_content?: InputMaybe<Scalars['Boolean']['input']>;
  receive_promotional_content?: InputMaybe<Scalars['Boolean']['input']>;
  theme_color?: InputMaybe<ThemeColorEnum>;
  trainerPreference?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  workin_prefrence?: InputMaybe<WorkinPrefrenceInput>;
  workout_prefrence?: InputMaybe<WorkoutPrefrenceInput>;
  workout_tutorial_mode?: InputMaybe<TutorialModeEnum>;
};


/** mutation root */
export type Mutation_RootUpdate_ActivationCodeArgs = {
  _set?: InputMaybe<ActivationCode_Set_Input>;
  where: ActivationCode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActivationCode_By_PkArgs = {
  _set?: InputMaybe<ActivationCode_Set_Input>;
  pk_columns: ActivationCode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivationCode_ManyArgs = {
  updates: Array<ActivationCode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AlterTypeColorArgs = {
  _set?: InputMaybe<AlterTypeColor_Set_Input>;
  where: AlterTypeColor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AlterTypeColor_By_PkArgs = {
  _set?: InputMaybe<AlterTypeColor_Set_Input>;
  pk_columns: AlterTypeColor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AlterTypeColor_ManyArgs = {
  updates: Array<AlterTypeColor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CoachArgs = {
  _set?: InputMaybe<Coach_Set_Input>;
  where: Coach_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coach_By_PkArgs = {
  _set?: InputMaybe<Coach_Set_Input>;
  pk_columns: Coach_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coach_ManyArgs = {
  updates: Array<Coach_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CoachedHabitArgs = {
  _inc?: InputMaybe<CoachedHabit_Inc_Input>;
  _set?: InputMaybe<CoachedHabit_Set_Input>;
  where: CoachedHabit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoachedHabit_By_PkArgs = {
  _inc?: InputMaybe<CoachedHabit_Inc_Input>;
  _set?: InputMaybe<CoachedHabit_Set_Input>;
  pk_columns: CoachedHabit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoachedHabit_ManyArgs = {
  updates: Array<CoachedHabit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CoachedNutritionArgs = {
  _inc?: InputMaybe<CoachedNutrition_Inc_Input>;
  _set?: InputMaybe<CoachedNutrition_Set_Input>;
  where: CoachedNutrition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoachedNutrition_By_PkArgs = {
  _inc?: InputMaybe<CoachedNutrition_Inc_Input>;
  _set?: InputMaybe<CoachedNutrition_Set_Input>;
  pk_columns: CoachedNutrition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoachedNutrition_ManyArgs = {
  updates: Array<CoachedNutrition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DnaKitArgs = {
  _inc?: InputMaybe<DnaKit_Inc_Input>;
  _set?: InputMaybe<DnaKit_Set_Input>;
  where: DnaKit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DnaKit_By_PkArgs = {
  _inc?: InputMaybe<DnaKit_Inc_Input>;
  _set?: InputMaybe<DnaKit_Set_Input>;
  pk_columns: DnaKit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DnaKit_ManyArgs = {
  updates: Array<DnaKit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DnaResultsArgs = {
  _inc?: InputMaybe<DnaResults_Inc_Input>;
  _set?: InputMaybe<DnaResults_Set_Input>;
  where: DnaResults_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DnaResults_By_PkArgs = {
  _inc?: InputMaybe<DnaResults_Inc_Input>;
  _set?: InputMaybe<DnaResults_Set_Input>;
  pk_columns: DnaResults_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DnaResults_ManyArgs = {
  updates: Array<DnaResults_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryArgs = {
  _set?: InputMaybe<Delivery_Set_Input>;
  where: Delivery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryHistoryArgs = {
  _set?: InputMaybe<DeliveryHistory_Set_Input>;
  where: DeliveryHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryHistory_By_PkArgs = {
  _set?: InputMaybe<DeliveryHistory_Set_Input>;
  pk_columns: DeliveryHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryHistory_ManyArgs = {
  updates: Array<DeliveryHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_By_PkArgs = {
  _set?: InputMaybe<Delivery_Set_Input>;
  pk_columns: Delivery_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Delivery_ManyArgs = {
  updates: Array<Delivery_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeviceArgs = {
  _set?: InputMaybe<Device_Set_Input>;
  where: Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Device_By_PkArgs = {
  _set?: InputMaybe<Device_Set_Input>;
  pk_columns: Device_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Device_ManyArgs = {
  updates: Array<Device_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DiagnosticsArgs = {
  _inc?: InputMaybe<Diagnostics_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Set_Input>;
  where: Diagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Set_Input>;
  pk_columns: Diagnostics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_ManyArgs = {
  updates: Array<Diagnostics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FinancePlanArgs = {
  _inc?: InputMaybe<FinancePlan_Inc_Input>;
  _set?: InputMaybe<FinancePlan_Set_Input>;
  where: FinancePlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FinancePlan_By_PkArgs = {
  _inc?: InputMaybe<FinancePlan_Inc_Input>;
  _set?: InputMaybe<FinancePlan_Set_Input>;
  pk_columns: FinancePlan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FinancePlan_ManyArgs = {
  updates: Array<FinancePlan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FirmwareContentArgs = {
  _inc?: InputMaybe<FirmwareContent_Inc_Input>;
  _set?: InputMaybe<FirmwareContent_Set_Input>;
  where: FirmwareContent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FirmwareContent_By_PkArgs = {
  _inc?: InputMaybe<FirmwareContent_Inc_Input>;
  _set?: InputMaybe<FirmwareContent_Set_Input>;
  pk_columns: FirmwareContent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FirmwareContent_ManyArgs = {
  updates: Array<FirmwareContent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GeneticDirectionWebhookArgs = {
  _append?: InputMaybe<GeneticDirectionWebhook_Append_Input>;
  _delete_at_path?: InputMaybe<GeneticDirectionWebhook_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GeneticDirectionWebhook_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GeneticDirectionWebhook_Delete_Key_Input>;
  _inc?: InputMaybe<GeneticDirectionWebhook_Inc_Input>;
  _prepend?: InputMaybe<GeneticDirectionWebhook_Prepend_Input>;
  _set?: InputMaybe<GeneticDirectionWebhook_Set_Input>;
  where: GeneticDirectionWebhook_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GeneticDirectionWebhook_By_PkArgs = {
  _append?: InputMaybe<GeneticDirectionWebhook_Append_Input>;
  _delete_at_path?: InputMaybe<GeneticDirectionWebhook_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GeneticDirectionWebhook_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GeneticDirectionWebhook_Delete_Key_Input>;
  _inc?: InputMaybe<GeneticDirectionWebhook_Inc_Input>;
  _prepend?: InputMaybe<GeneticDirectionWebhook_Prepend_Input>;
  _set?: InputMaybe<GeneticDirectionWebhook_Set_Input>;
  pk_columns: GeneticDirectionWebhook_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GeneticDirectionWebhook_ManyArgs = {
  updates: Array<GeneticDirectionWebhook_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GlobalFeatureControlArgs = {
  _set?: InputMaybe<GlobalFeatureControl_Set_Input>;
  where: GlobalFeatureControl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GlobalFeatureControl_By_PkArgs = {
  _set?: InputMaybe<GlobalFeatureControl_Set_Input>;
  pk_columns: GlobalFeatureControl_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GlobalFeatureControl_ManyArgs = {
  updates: Array<GlobalFeatureControl_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoalArgs = {
  _set?: InputMaybe<Goal_Set_Input>;
  where: Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_By_PkArgs = {
  _set?: InputMaybe<Goal_Set_Input>;
  pk_columns: Goal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_ManyArgs = {
  updates: Array<Goal_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HabitDayArgs = {
  _inc?: InputMaybe<HabitDay_Inc_Input>;
  _set?: InputMaybe<HabitDay_Set_Input>;
  where: HabitDay_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HabitDay_By_PkArgs = {
  _inc?: InputMaybe<HabitDay_Inc_Input>;
  _set?: InputMaybe<HabitDay_Set_Input>;
  pk_columns: HabitDay_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HabitDay_ManyArgs = {
  updates: Array<HabitDay_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HeartRateDiagnosticsArgs = {
  _inc?: InputMaybe<HeartRateDiagnostics_Inc_Input>;
  _set?: InputMaybe<HeartRateDiagnostics_Set_Input>;
  where: HeartRateDiagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HeartRateDiagnostics_By_PkArgs = {
  _inc?: InputMaybe<HeartRateDiagnostics_Inc_Input>;
  _set?: InputMaybe<HeartRateDiagnostics_Set_Input>;
  pk_columns: HeartRateDiagnostics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HeartRateDiagnostics_ManyArgs = {
  updates: Array<HeartRateDiagnostics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HouseholdArgs = {
  _inc?: InputMaybe<Household_Inc_Input>;
  _set?: InputMaybe<Household_Set_Input>;
  where: Household_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Household_By_PkArgs = {
  _inc?: InputMaybe<Household_Inc_Input>;
  _set?: InputMaybe<Household_Set_Input>;
  pk_columns: Household_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Household_ManyArgs = {
  updates: Array<Household_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingEmailArgs = {
  _set?: InputMaybe<MarketingEmail_Set_Input>;
  where: MarketingEmail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingEmail_By_PkArgs = {
  _set?: InputMaybe<MarketingEmail_Set_Input>;
  pk_columns: MarketingEmail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingEmail_ManyArgs = {
  updates: Array<MarketingEmail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MasteryArgs = {
  _inc?: InputMaybe<Mastery_Inc_Input>;
  _set?: InputMaybe<Mastery_Set_Input>;
  where: Mastery_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mastery_By_PkArgs = {
  _inc?: InputMaybe<Mastery_Inc_Input>;
  _set?: InputMaybe<Mastery_Set_Input>;
  pk_columns: Mastery_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mastery_ManyArgs = {
  updates: Array<Mastery_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OnboardingInfoArgs = {
  _inc?: InputMaybe<OnboardingInfo_Inc_Input>;
  _set?: InputMaybe<OnboardingInfo_Set_Input>;
  where: OnboardingInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OnboardingInfo_By_PkArgs = {
  _inc?: InputMaybe<OnboardingInfo_Inc_Input>;
  _set?: InputMaybe<OnboardingInfo_Set_Input>;
  pk_columns: OnboardingInfo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OnboardingInfo_ManyArgs = {
  updates: Array<OnboardingInfo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OnboardingVideosArgs = {
  _inc?: InputMaybe<OnboardingVideos_Inc_Input>;
  _set?: InputMaybe<OnboardingVideos_Set_Input>;
  where: OnboardingVideos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OnboardingVideos_By_PkArgs = {
  _inc?: InputMaybe<OnboardingVideos_Inc_Input>;
  _set?: InputMaybe<OnboardingVideos_Set_Input>;
  pk_columns: OnboardingVideos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OnboardingVideos_ManyArgs = {
  updates: Array<OnboardingVideos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _append?: InputMaybe<Order_Append_Input>;
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Order_Delete_Key_Input>;
  _inc?: InputMaybe<Order_Inc_Input>;
  _prepend?: InputMaybe<Order_Prepend_Input>;
  _set?: InputMaybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OrderLineItemArgs = {
  _inc?: InputMaybe<OrderLineItem_Inc_Input>;
  _set?: InputMaybe<OrderLineItem_Set_Input>;
  where: OrderLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OrderLineItem_By_PkArgs = {
  _inc?: InputMaybe<OrderLineItem_Inc_Input>;
  _set?: InputMaybe<OrderLineItem_Set_Input>;
  pk_columns: OrderLineItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrderLineItem_ManyArgs = {
  updates: Array<OrderLineItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _append?: InputMaybe<Order_Append_Input>;
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Order_Delete_Key_Input>;
  _inc?: InputMaybe<Order_Inc_Input>;
  _prepend?: InputMaybe<Order_Prepend_Input>;
  _set?: InputMaybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ManyArgs = {
  updates: Array<Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentHistoryArgs = {
  _inc?: InputMaybe<PaymentHistory_Inc_Input>;
  _set?: InputMaybe<PaymentHistory_Set_Input>;
  where: PaymentHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentHistory_By_PkArgs = {
  _inc?: InputMaybe<PaymentHistory_Inc_Input>;
  _set?: InputMaybe<PaymentHistory_Set_Input>;
  pk_columns: PaymentHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentHistory_ManyArgs = {
  updates: Array<PaymentHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PlanDayArgs = {
  _inc?: InputMaybe<PlanDay_Inc_Input>;
  _set?: InputMaybe<PlanDay_Set_Input>;
  where: PlanDay_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PlanDay_By_PkArgs = {
  _inc?: InputMaybe<PlanDay_Inc_Input>;
  _set?: InputMaybe<PlanDay_Set_Input>;
  pk_columns: PlanDay_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PlanDay_ManyArgs = {
  updates: Array<PlanDay_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PresetDietArgs = {
  _set?: InputMaybe<PresetDiet_Set_Input>;
  where: PresetDiet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PresetDiet_By_PkArgs = {
  _set?: InputMaybe<PresetDiet_Set_Input>;
  pk_columns: PresetDiet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PresetDiet_ManyArgs = {
  updates: Array<PresetDiet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProArgs = {
  _set?: InputMaybe<Pro_Set_Input>;
  where: Pro_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProMusicArgs = {
  _set?: InputMaybe<ProMusic_Set_Input>;
  where: ProMusic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProMusic_By_PkArgs = {
  _set?: InputMaybe<ProMusic_Set_Input>;
  pk_columns: ProMusic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProMusic_ManyArgs = {
  updates: Array<ProMusic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pro_By_PkArgs = {
  _set?: InputMaybe<Pro_Set_Input>;
  pk_columns: Pro_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pro_ManyArgs = {
  updates: Array<Pro_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductLogArgs = {
  _set?: InputMaybe<ProductLog_Set_Input>;
  where: ProductLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductLog_By_PkArgs = {
  _set?: InputMaybe<ProductLog_Set_Input>;
  pk_columns: ProductLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductLog_ManyArgs = {
  updates: Array<ProductLog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionalItemArgs = {
  _inc?: InputMaybe<PromotionalItem_Inc_Input>;
  _set?: InputMaybe<PromotionalItem_Set_Input>;
  where: PromotionalItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionalItem_By_PkArgs = {
  _inc?: InputMaybe<PromotionalItem_Inc_Input>;
  _set?: InputMaybe<PromotionalItem_Set_Input>;
  pk_columns: PromotionalItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionalItem_ManyArgs = {
  updates: Array<PromotionalItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuizSurveyArgs = {
  _inc?: InputMaybe<QuizSurvey_Inc_Input>;
  _set?: InputMaybe<QuizSurvey_Set_Input>;
  where: QuizSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuizSurvey_By_PkArgs = {
  _inc?: InputMaybe<QuizSurvey_Inc_Input>;
  _set?: InputMaybe<QuizSurvey_Set_Input>;
  pk_columns: QuizSurvey_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuizSurvey_ManyArgs = {
  updates: Array<QuizSurvey_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuoteArgs = {
  _set?: InputMaybe<Quote_Set_Input>;
  where: Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_By_PkArgs = {
  _set?: InputMaybe<Quote_Set_Input>;
  pk_columns: Quote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_ManyArgs = {
  updates: Array<Quote_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReimbursementRequirementArgs = {
  _inc?: InputMaybe<ReimbursementRequirement_Inc_Input>;
  _set?: InputMaybe<ReimbursementRequirement_Set_Input>;
  where: ReimbursementRequirement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReimbursementRequirement_By_PkArgs = {
  _inc?: InputMaybe<ReimbursementRequirement_Inc_Input>;
  _set?: InputMaybe<ReimbursementRequirement_Set_Input>;
  pk_columns: ReimbursementRequirement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReimbursementRequirement_ManyArgs = {
  updates: Array<ReimbursementRequirement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ScreenOrderArgs = {
  _append?: InputMaybe<ScreenOrder_Append_Input>;
  _delete_at_path?: InputMaybe<ScreenOrder_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ScreenOrder_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ScreenOrder_Delete_Key_Input>;
  _inc?: InputMaybe<ScreenOrder_Inc_Input>;
  _prepend?: InputMaybe<ScreenOrder_Prepend_Input>;
  _set?: InputMaybe<ScreenOrder_Set_Input>;
  where: ScreenOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ScreenOrder_By_PkArgs = {
  _append?: InputMaybe<ScreenOrder_Append_Input>;
  _delete_at_path?: InputMaybe<ScreenOrder_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ScreenOrder_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ScreenOrder_Delete_Key_Input>;
  _inc?: InputMaybe<ScreenOrder_Inc_Input>;
  _prepend?: InputMaybe<ScreenOrder_Prepend_Input>;
  _set?: InputMaybe<ScreenOrder_Set_Input>;
  pk_columns: ScreenOrder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ScreenOrder_ManyArgs = {
  updates: Array<ScreenOrder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SearchResultArgs = {
  _inc?: InputMaybe<SearchResult_Inc_Input>;
  _set?: InputMaybe<SearchResult_Set_Input>;
  where: SearchResult_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SearchResult_By_PkArgs = {
  _inc?: InputMaybe<SearchResult_Inc_Input>;
  _set?: InputMaybe<SearchResult_Set_Input>;
  pk_columns: SearchResult_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SearchResult_ManyArgs = {
  updates: Array<SearchResult_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SleepSurveyArgs = {
  _set?: InputMaybe<SleepSurvey_Set_Input>;
  where: SleepSurvey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SleepSurvey_By_PkArgs = {
  _set?: InputMaybe<SleepSurvey_Set_Input>;
  pk_columns: SleepSurvey_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SleepSurvey_ManyArgs = {
  updates: Array<SleepSurvey_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionLogArgs = {
  _set?: InputMaybe<SubscriptionLog_Set_Input>;
  where: SubscriptionLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionLog_By_PkArgs = {
  _set?: InputMaybe<SubscriptionLog_Set_Input>;
  pk_columns: SubscriptionLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionLog_ManyArgs = {
  updates: Array<SubscriptionLog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_ManyArgs = {
  updates: Array<Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TokenArgs = {
  _inc?: InputMaybe<Token_Inc_Input>;
  _set?: InputMaybe<Token_Set_Input>;
  where: Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TokenHistoryArgs = {
  _inc?: InputMaybe<TokenHistory_Inc_Input>;
  _set?: InputMaybe<TokenHistory_Set_Input>;
  where: TokenHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TokenHistory_By_PkArgs = {
  _inc?: InputMaybe<TokenHistory_Inc_Input>;
  _set?: InputMaybe<TokenHistory_Set_Input>;
  pk_columns: TokenHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TokenHistory_ManyArgs = {
  updates: Array<TokenHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Token_By_PkArgs = {
  _inc?: InputMaybe<Token_Inc_Input>;
  _set?: InputMaybe<Token_Set_Input>;
  pk_columns: Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Token_ManyArgs = {
  updates: Array<Token_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserAggregatesArgs = {
  _inc?: InputMaybe<UserAggregates_Inc_Input>;
  _set?: InputMaybe<UserAggregates_Set_Input>;
  where: UserAggregates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserAggregates_By_PkArgs = {
  _inc?: InputMaybe<UserAggregates_Inc_Input>;
  _set?: InputMaybe<UserAggregates_Set_Input>;
  pk_columns: UserAggregates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserAggregates_ManyArgs = {
  updates: Array<UserAggregates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserAssessmentArgs = {
  _append?: InputMaybe<UserAssessment_Append_Input>;
  _delete_at_path?: InputMaybe<UserAssessment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserAssessment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserAssessment_Delete_Key_Input>;
  _inc?: InputMaybe<UserAssessment_Inc_Input>;
  _prepend?: InputMaybe<UserAssessment_Prepend_Input>;
  _set?: InputMaybe<UserAssessment_Set_Input>;
  where: UserAssessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserAssessment_By_PkArgs = {
  _append?: InputMaybe<UserAssessment_Append_Input>;
  _delete_at_path?: InputMaybe<UserAssessment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserAssessment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserAssessment_Delete_Key_Input>;
  _inc?: InputMaybe<UserAssessment_Inc_Input>;
  _prepend?: InputMaybe<UserAssessment_Prepend_Input>;
  _set?: InputMaybe<UserAssessment_Set_Input>;
  pk_columns: UserAssessment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserAssessment_ManyArgs = {
  updates: Array<UserAssessment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserFeatureControlArgs = {
  _set?: InputMaybe<UserFeatureControl_Set_Input>;
  where: UserFeatureControl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserFeatureControl_By_PkArgs = {
  _set?: InputMaybe<UserFeatureControl_Set_Input>;
  pk_columns: UserFeatureControl_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserFeatureControl_ManyArgs = {
  updates: Array<UserFeatureControl_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserFeedbackArgs = {
  _inc?: InputMaybe<UserFeedback_Inc_Input>;
  _set?: InputMaybe<UserFeedback_Set_Input>;
  where: UserFeedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserFeedback_By_PkArgs = {
  _inc?: InputMaybe<UserFeedback_Inc_Input>;
  _set?: InputMaybe<UserFeedback_Set_Input>;
  pk_columns: UserFeedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserFeedback_ManyArgs = {
  updates: Array<UserFeedback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserFinancePlanArgs = {
  _set?: InputMaybe<UserFinancePlan_Set_Input>;
  where: UserFinancePlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserFinancePlan_By_PkArgs = {
  _set?: InputMaybe<UserFinancePlan_Set_Input>;
  pk_columns: UserFinancePlan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserFinancePlan_ManyArgs = {
  updates: Array<UserFinancePlan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserHabitArgs = {
  _inc?: InputMaybe<UserHabit_Inc_Input>;
  _set?: InputMaybe<UserHabit_Set_Input>;
  where: UserHabit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserHabit_By_PkArgs = {
  _inc?: InputMaybe<UserHabit_Inc_Input>;
  _set?: InputMaybe<UserHabit_Set_Input>;
  pk_columns: UserHabit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserHabit_ManyArgs = {
  updates: Array<UserHabit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserMovementDailyArgs = {
  _inc?: InputMaybe<UserMovementDaily_Inc_Input>;
  _set?: InputMaybe<UserMovementDaily_Set_Input>;
  where: UserMovementDaily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserMovementDaily_By_PkArgs = {
  _inc?: InputMaybe<UserMovementDaily_Inc_Input>;
  _set?: InputMaybe<UserMovementDaily_Set_Input>;
  pk_columns: UserMovementDaily_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserMovementDaily_ManyArgs = {
  updates: Array<UserMovementDaily_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserPreferencesArgs = {
  _set?: InputMaybe<UserPreferences_Set_Input>;
  where: UserPreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserPreferences_By_PkArgs = {
  _set?: InputMaybe<UserPreferences_Set_Input>;
  pk_columns: UserPreferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserPreferences_ManyArgs = {
  updates: Array<UserPreferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserProductArgs = {
  _inc?: InputMaybe<UserProduct_Inc_Input>;
  _set?: InputMaybe<UserProduct_Set_Input>;
  where: UserProduct_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserProductHistoryArgs = {
  _set?: InputMaybe<UserProductHistory_Set_Input>;
  where: UserProductHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserProductHistory_By_PkArgs = {
  _set?: InputMaybe<UserProductHistory_Set_Input>;
  pk_columns: UserProductHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserProductHistory_ManyArgs = {
  updates: Array<UserProductHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserProduct_By_PkArgs = {
  _inc?: InputMaybe<UserProduct_Inc_Input>;
  _set?: InputMaybe<UserProduct_Set_Input>;
  pk_columns: UserProduct_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserProduct_ManyArgs = {
  updates: Array<UserProduct_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserReadinessArgs = {
  _inc?: InputMaybe<UserReadiness_Inc_Input>;
  _set?: InputMaybe<UserReadiness_Set_Input>;
  where: UserReadiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserReadinessLogArgs = {
  _append?: InputMaybe<UserReadinessLog_Append_Input>;
  _delete_at_path?: InputMaybe<UserReadinessLog_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserReadinessLog_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserReadinessLog_Delete_Key_Input>;
  _prepend?: InputMaybe<UserReadinessLog_Prepend_Input>;
  _set?: InputMaybe<UserReadinessLog_Set_Input>;
  where: UserReadinessLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserReadinessLog_By_PkArgs = {
  _append?: InputMaybe<UserReadinessLog_Append_Input>;
  _delete_at_path?: InputMaybe<UserReadinessLog_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserReadinessLog_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserReadinessLog_Delete_Key_Input>;
  _prepend?: InputMaybe<UserReadinessLog_Prepend_Input>;
  _set?: InputMaybe<UserReadinessLog_Set_Input>;
  pk_columns: UserReadinessLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserReadinessLog_ManyArgs = {
  updates: Array<UserReadinessLog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserReadiness_By_PkArgs = {
  _inc?: InputMaybe<UserReadiness_Inc_Input>;
  _set?: InputMaybe<UserReadiness_Set_Input>;
  pk_columns: UserReadiness_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserReadiness_ManyArgs = {
  updates: Array<UserReadiness_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserReimbursmentHistoryArgs = {
  _set?: InputMaybe<UserReimbursmentHistory_Set_Input>;
  where: UserReimbursmentHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserReimbursmentHistory_By_PkArgs = {
  _set?: InputMaybe<UserReimbursmentHistory_Set_Input>;
  pk_columns: UserReimbursmentHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserReimbursmentHistory_ManyArgs = {
  updates: Array<UserReimbursmentHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserRepArgs = {
  _append?: InputMaybe<UserRep_Append_Input>;
  _delete_at_path?: InputMaybe<UserRep_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserRep_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserRep_Delete_Key_Input>;
  _inc?: InputMaybe<UserRep_Inc_Input>;
  _prepend?: InputMaybe<UserRep_Prepend_Input>;
  _set?: InputMaybe<UserRep_Set_Input>;
  where: UserRep_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserRep_By_PkArgs = {
  _append?: InputMaybe<UserRep_Append_Input>;
  _delete_at_path?: InputMaybe<UserRep_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserRep_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserRep_Delete_Key_Input>;
  _inc?: InputMaybe<UserRep_Inc_Input>;
  _prepend?: InputMaybe<UserRep_Prepend_Input>;
  _set?: InputMaybe<UserRep_Set_Input>;
  pk_columns: UserRep_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserRep_ManyArgs = {
  updates: Array<UserRep_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserSetArgs = {
  _append?: InputMaybe<UserSet_Append_Input>;
  _delete_at_path?: InputMaybe<UserSet_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserSet_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserSet_Delete_Key_Input>;
  _inc?: InputMaybe<UserSet_Inc_Input>;
  _prepend?: InputMaybe<UserSet_Prepend_Input>;
  _set?: InputMaybe<UserSet_Set_Input>;
  where: UserSet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserSet_By_PkArgs = {
  _append?: InputMaybe<UserSet_Append_Input>;
  _delete_at_path?: InputMaybe<UserSet_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserSet_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserSet_Delete_Key_Input>;
  _inc?: InputMaybe<UserSet_Inc_Input>;
  _prepend?: InputMaybe<UserSet_Prepend_Input>;
  _set?: InputMaybe<UserSet_Set_Input>;
  pk_columns: UserSet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserSet_ManyArgs = {
  updates: Array<UserSet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserStateArgs = {
  _set?: InputMaybe<UserState_Set_Input>;
  where: UserState_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserState_By_PkArgs = {
  _set?: InputMaybe<UserState_Set_Input>;
  pk_columns: UserState_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserState_ManyArgs = {
  updates: Array<UserState_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserTrainingPlanArgs = {
  _inc?: InputMaybe<UserTrainingPlan_Inc_Input>;
  _set?: InputMaybe<UserTrainingPlan_Set_Input>;
  where: UserTrainingPlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserTrainingPlan_By_PkArgs = {
  _inc?: InputMaybe<UserTrainingPlan_Inc_Input>;
  _set?: InputMaybe<UserTrainingPlan_Set_Input>;
  pk_columns: UserTrainingPlan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserTrainingPlan_ManyArgs = {
  updates: Array<UserTrainingPlan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserWorkoutArgs = {
  _append?: InputMaybe<UserWorkout_Append_Input>;
  _delete_at_path?: InputMaybe<UserWorkout_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserWorkout_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserWorkout_Delete_Key_Input>;
  _inc?: InputMaybe<UserWorkout_Inc_Input>;
  _prepend?: InputMaybe<UserWorkout_Prepend_Input>;
  _set?: InputMaybe<UserWorkout_Set_Input>;
  where: UserWorkout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserWorkout_By_PkArgs = {
  _append?: InputMaybe<UserWorkout_Append_Input>;
  _delete_at_path?: InputMaybe<UserWorkout_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserWorkout_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserWorkout_Delete_Key_Input>;
  _inc?: InputMaybe<UserWorkout_Inc_Input>;
  _prepend?: InputMaybe<UserWorkout_Prepend_Input>;
  _set?: InputMaybe<UserWorkout_Set_Input>;
  pk_columns: UserWorkout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserWorkout_ManyArgs = {
  updates: Array<UserWorkout_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VendorErrorLogArgs = {
  _append?: InputMaybe<VendorErrorLog_Append_Input>;
  _delete_at_path?: InputMaybe<VendorErrorLog_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<VendorErrorLog_Delete_Elem_Input>;
  _delete_key?: InputMaybe<VendorErrorLog_Delete_Key_Input>;
  _prepend?: InputMaybe<VendorErrorLog_Prepend_Input>;
  _set?: InputMaybe<VendorErrorLog_Set_Input>;
  where: VendorErrorLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VendorErrorLog_By_PkArgs = {
  _append?: InputMaybe<VendorErrorLog_Append_Input>;
  _delete_at_path?: InputMaybe<VendorErrorLog_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<VendorErrorLog_Delete_Elem_Input>;
  _delete_key?: InputMaybe<VendorErrorLog_Delete_Key_Input>;
  _prepend?: InputMaybe<VendorErrorLog_Prepend_Input>;
  _set?: InputMaybe<VendorErrorLog_Set_Input>;
  pk_columns: VendorErrorLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VendorErrorLog_ManyArgs = {
  updates: Array<VendorErrorLog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VersionControlArgs = {
  _inc?: InputMaybe<VersionControl_Inc_Input>;
  _set?: InputMaybe<VersionControl_Set_Input>;
  where: VersionControl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VersionControl_By_PkArgs = {
  _inc?: InputMaybe<VersionControl_Inc_Input>;
  _set?: InputMaybe<VersionControl_Set_Input>;
  pk_columns: VersionControl_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VersionControl_ManyArgs = {
  updates: Array<VersionControl_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WeeklyPlanArgs = {
  _append?: InputMaybe<WeeklyPlan_Append_Input>;
  _delete_at_path?: InputMaybe<WeeklyPlan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WeeklyPlan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WeeklyPlan_Delete_Key_Input>;
  _inc?: InputMaybe<WeeklyPlan_Inc_Input>;
  _prepend?: InputMaybe<WeeklyPlan_Prepend_Input>;
  _set?: InputMaybe<WeeklyPlan_Set_Input>;
  where: WeeklyPlan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeeklyPlan_By_PkArgs = {
  _append?: InputMaybe<WeeklyPlan_Append_Input>;
  _delete_at_path?: InputMaybe<WeeklyPlan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WeeklyPlan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WeeklyPlan_Delete_Key_Input>;
  _inc?: InputMaybe<WeeklyPlan_Inc_Input>;
  _prepend?: InputMaybe<WeeklyPlan_Prepend_Input>;
  _set?: InputMaybe<WeeklyPlan_Set_Input>;
  pk_columns: WeeklyPlan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeeklyPlan_ManyArgs = {
  updates: Array<WeeklyPlan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorkinPrefrenceArgs = {
  _set?: InputMaybe<WorkinPrefrence_Set_Input>;
  where: WorkinPrefrence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkinPrefrence_By_PkArgs = {
  _set?: InputMaybe<WorkinPrefrence_Set_Input>;
  pk_columns: WorkinPrefrence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WorkinPrefrence_ManyArgs = {
  updates: Array<WorkinPrefrence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorkoutArgs = {
  _append?: InputMaybe<Workout_Append_Input>;
  _delete_at_path?: InputMaybe<Workout_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workout_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workout_Delete_Key_Input>;
  _inc?: InputMaybe<Workout_Inc_Input>;
  _prepend?: InputMaybe<Workout_Prepend_Input>;
  _set?: InputMaybe<Workout_Set_Input>;
  where: Workout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkoutPrefrenceArgs = {
  _set?: InputMaybe<WorkoutPrefrence_Set_Input>;
  where: WorkoutPrefrence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkoutPrefrence_By_PkArgs = {
  _set?: InputMaybe<WorkoutPrefrence_Set_Input>;
  pk_columns: WorkoutPrefrence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WorkoutPrefrence_ManyArgs = {
  updates: Array<WorkoutPrefrence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workout_By_PkArgs = {
  _append?: InputMaybe<Workout_Append_Input>;
  _delete_at_path?: InputMaybe<Workout_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workout_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workout_Delete_Key_Input>;
  _inc?: InputMaybe<Workout_Inc_Input>;
  _prepend?: InputMaybe<Workout_Prepend_Input>;
  _set?: InputMaybe<Workout_Set_Input>;
  pk_columns: Workout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workout_ManyArgs = {
  updates: Array<Workout_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__FollowingProArgs = {
  _set?: InputMaybe<_FollowingPro_Set_Input>;
  where: _FollowingPro_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__FollowingPro_ManyArgs = {
  updates: Array<_FollowingPro_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__GoalToUserPreferencesArgs = {
  _set?: InputMaybe<_GoalToUserPreferences_Set_Input>;
  where: _GoalToUserPreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__GoalToUserPreferences_ManyArgs = {
  updates: Array<_GoalToUserPreferences_Updates>;
};


/** mutation root */
export type Mutation_RootUploadHighKneeSetArgs = {
  asensei_raw?: InputMaybe<Scalars['json']['input']>;
  assessment_id: Scalars['String']['input'];
  completedAt: Scalars['Int']['input'];
  exercise_name?: InputMaybe<Scalars['String']['input']>;
  hr?: InputMaybe<Scalars['Int']['input']>;
  hr_epoch?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUploadSetArgs = {
  asenseiFeedback?: InputMaybe<Scalars['json']['input']>;
  asensei_exercise_id?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_version?: InputMaybe<Scalars['String']['input']>;
  asensei_raw?: InputMaybe<Scalars['json']['input']>;
  category: WorkoutCategoryEnum;
  completedAt: Scalars['Int']['input'];
  exercise_name?: InputMaybe<Scalars['String']['input']>;
  goal_reps?: InputMaybe<Scalars['Int']['input']>;
  hold_goal_duration_sec?: InputMaybe<Scalars['Int']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['json']['input']>;
  negative_nuances_possible: Scalars['Int']['input'];
  positive_nuances_possible: Scalars['Int']['input'];
  rep_items?: InputMaybe<Array<InputMaybe<RepItemInput>>>;
  section: Scalars['Int']['input'];
  set: Scalars['Int']['input'];
  set_level?: InputMaybe<Scalars['Int']['input']>;
  time_in_hold_sec?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
  userWorkoutId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUploadSetV2Args = {
  asenseiFeedback?: InputMaybe<Scalars['json']['input']>;
  asensei_exercise_id?: InputMaybe<Scalars['String']['input']>;
  asensei_exercise_version?: InputMaybe<Scalars['String']['input']>;
  asensei_raw?: InputMaybe<Scalars['json']['input']>;
  category: WorkoutCategoryEnum;
  completedAt: Scalars['Int']['input'];
  exercise_name?: InputMaybe<Scalars['String']['input']>;
  goal_reps?: InputMaybe<Scalars['Int']['input']>;
  hold_goal_duration_sec?: InputMaybe<Scalars['Int']['input']>;
  json_asensei_summary?: InputMaybe<Scalars['json']['input']>;
  negative_nuances_possible: Scalars['Int']['input'];
  positive_nuances_possible: Scalars['Int']['input'];
  rep_items?: InputMaybe<Array<InputMaybe<RepItemInput>>>;
  section: Scalars['Int']['input'];
  set: Scalars['Int']['input'];
  time_in_hold_sec?: InputMaybe<Scalars['Int']['input']>;
  total_reps?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
  userWorkoutId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpsertCookBookItemArgs = {
  calories?: InputMaybe<Scalars['Int']['input']>;
  carbs?: InputMaybe<Scalars['Int']['input']>;
  delete_record?: InputMaybe<Scalars['Boolean']['input']>;
  fats?: InputMaybe<Scalars['Int']['input']>;
  is_fasting?: InputMaybe<Scalars['Boolean']['input']>;
  is_snack?: InputMaybe<Scalars['Boolean']['input']>;
  meal_key: MealKeyEnum;
  proteins?: InputMaybe<Scalars['Int']['input']>;
  recipe?: InputMaybe<Scalars['json']['input']>;
  title: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpsertMealPlanPreferencesArgs = {
  allergies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fasting_plan?: InputMaybe<Scalars['Boolean']['input']>;
  intolerances?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  protein_preferences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipe_difficulty?: InputMaybe<DifficultyEnum>;
  restrictions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootWebRegisterArgs = {
  auth0UserId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

/** column ordering options */
export type Order_By =
  /** in ascending order, nulls last */
  | 'asc'
  /** in ascending order, nulls first */
  | 'asc_nulls_first'
  /** in ascending order, nulls last */
  | 'asc_nulls_last'
  /** in descending order, nulls first */
  | 'desc'
  /** in descending order, nulls first */
  | 'desc_nulls_first'
  /** in descending order, nulls last */
  | 'desc_nulls_last';

export type PlanHistoryOutput = {
  __typename?: 'planHistoryOutput';
  historyByWeek?: Maybe<Array<Maybe<PlanHistoryWeek>>>;
  totals?: Maybe<PlanHistoryTotals>;
};

export type PlanHistoryTotals = {
  __typename?: 'planHistoryTotals';
  free_months: Scalars['Int']['output'];
  weeks_won: Scalars['Int']['output'];
};

export type PlanHistoryWeek = {
  __typename?: 'planHistoryWeek';
  completed: Scalars['Int']['output'];
  start_date: Scalars['String']['output'];
};

export type QualifyWorkoutOutput = {
  __typename?: 'qualifyWorkoutOutput';
  is_qualified?: Maybe<Scalars['Boolean']['output']>;
  plan_day_id?: Maybe<Scalars['String']['output']>;
  workout_id?: Maybe<Scalars['String']['output']>;
  zone1sec?: Maybe<Scalars['Int']['output']>;
  zone2sec?: Maybe<Scalars['Int']['output']>;
  zone3sec?: Maybe<Scalars['Int']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "ActivationCode" */
  ActivationCode: Array<ActivationCode>;
  /** fetch aggregated fields from the table: "ActivationCode" */
  ActivationCode_aggregate: ActivationCode_Aggregate;
  /** fetch data from the table: "ActivationCode" using primary key columns */
  ActivationCode_by_pk?: Maybe<ActivationCode>;
  /** fetch data from the table: "AlterTypeColor" */
  AlterTypeColor: Array<AlterTypeColor>;
  /** fetch aggregated fields from the table: "AlterTypeColor" */
  AlterTypeColor_aggregate: AlterTypeColor_Aggregate;
  /** fetch data from the table: "AlterTypeColor" using primary key columns */
  AlterTypeColor_by_pk?: Maybe<AlterTypeColor>;
  /** fetch data from the table: "Coach" */
  Coach: Array<Coach>;
  /** fetch aggregated fields from the table: "Coach" */
  Coach_aggregate: Coach_Aggregate;
  /** fetch data from the table: "Coach" using primary key columns */
  Coach_by_pk?: Maybe<Coach>;
  /** fetch data from the table: "CoachedHabit" */
  CoachedHabit: Array<CoachedHabit>;
  /** fetch aggregated fields from the table: "CoachedHabit" */
  CoachedHabit_aggregate: CoachedHabit_Aggregate;
  /** fetch data from the table: "CoachedHabit" using primary key columns */
  CoachedHabit_by_pk?: Maybe<CoachedHabit>;
  /** fetch data from the table: "CoachedNutrition" */
  CoachedNutrition: Array<CoachedNutrition>;
  /** fetch aggregated fields from the table: "CoachedNutrition" */
  CoachedNutrition_aggregate: CoachedNutrition_Aggregate;
  /** fetch data from the table: "CoachedNutrition" using primary key columns */
  CoachedNutrition_by_pk?: Maybe<CoachedNutrition>;
  /** fetch data from the table: "DNAKit" */
  DNAKit: Array<DnaKit>;
  /** fetch aggregated fields from the table: "DNAKit" */
  DNAKit_aggregate: DnaKit_Aggregate;
  /** fetch data from the table: "DNAKit" using primary key columns */
  DNAKit_by_pk?: Maybe<DnaKit>;
  /** An array relationship */
  DNAResults: Array<DnaResults>;
  /** An aggregate relationship */
  DNAResults_aggregate: DnaResults_Aggregate;
  /** fetch data from the table: "DNAResults" using primary key columns */
  DNAResults_by_pk?: Maybe<DnaResults>;
  /** fetch data from the table: "Delivery" */
  Delivery: Array<Delivery>;
  /** fetch data from the table: "DeliveryHistory" */
  DeliveryHistory: Array<DeliveryHistory>;
  /** fetch aggregated fields from the table: "DeliveryHistory" */
  DeliveryHistory_aggregate: DeliveryHistory_Aggregate;
  /** fetch data from the table: "DeliveryHistory" using primary key columns */
  DeliveryHistory_by_pk?: Maybe<DeliveryHistory>;
  /** fetch aggregated fields from the table: "Delivery" */
  Delivery_aggregate: Delivery_Aggregate;
  /** fetch data from the table: "Delivery" using primary key columns */
  Delivery_by_pk?: Maybe<Delivery>;
  /** fetch data from the table: "Device" */
  Device: Array<Device>;
  /** fetch aggregated fields from the table: "Device" */
  Device_aggregate: Device_Aggregate;
  /** fetch data from the table: "Device" using primary key columns */
  Device_by_pk?: Maybe<Device>;
  /** An array relationship */
  Diagnostics: Array<Diagnostics>;
  /** An aggregate relationship */
  Diagnostics_aggregate: Diagnostics_Aggregate;
  /** fetch data from the table: "Diagnostics" using primary key columns */
  Diagnostics_by_pk?: Maybe<Diagnostics>;
  /** fetch data from the table: "FinancePlan" */
  FinancePlan: Array<FinancePlan>;
  /** fetch aggregated fields from the table: "FinancePlan" */
  FinancePlan_aggregate: FinancePlan_Aggregate;
  /** fetch data from the table: "FinancePlan" using primary key columns */
  FinancePlan_by_pk?: Maybe<FinancePlan>;
  /** fetch data from the table: "FirmwareContent" */
  FirmwareContent: Array<FirmwareContent>;
  /** fetch aggregated fields from the table: "FirmwareContent" */
  FirmwareContent_aggregate: FirmwareContent_Aggregate;
  /** fetch data from the table: "FirmwareContent" using primary key columns */
  FirmwareContent_by_pk?: Maybe<FirmwareContent>;
  /** fetch data from the table: "GeneticDirectionWebhook" */
  GeneticDirectionWebhook: Array<GeneticDirectionWebhook>;
  /** fetch aggregated fields from the table: "GeneticDirectionWebhook" */
  GeneticDirectionWebhook_aggregate: GeneticDirectionWebhook_Aggregate;
  /** fetch data from the table: "GeneticDirectionWebhook" using primary key columns */
  GeneticDirectionWebhook_by_pk?: Maybe<GeneticDirectionWebhook>;
  /** fetch data from the table: "GlobalFeatureControl" */
  GlobalFeatureControl: Array<GlobalFeatureControl>;
  /** fetch aggregated fields from the table: "GlobalFeatureControl" */
  GlobalFeatureControl_aggregate: GlobalFeatureControl_Aggregate;
  /** fetch data from the table: "GlobalFeatureControl" using primary key columns */
  GlobalFeatureControl_by_pk?: Maybe<GlobalFeatureControl>;
  /** fetch data from the table: "Goal" */
  Goal: Array<Goal>;
  /** fetch aggregated fields from the table: "Goal" */
  Goal_aggregate: Goal_Aggregate;
  /** fetch data from the table: "Goal" using primary key columns */
  Goal_by_pk?: Maybe<Goal>;
  /** fetch data from the table: "HabitDay" */
  HabitDay: Array<HabitDay>;
  /** fetch aggregated fields from the table: "HabitDay" */
  HabitDay_aggregate: HabitDay_Aggregate;
  /** fetch data from the table: "HabitDay" using primary key columns */
  HabitDay_by_pk?: Maybe<HabitDay>;
  /** An array relationship */
  HeartRateDiagnostics: Array<HeartRateDiagnostics>;
  /** An aggregate relationship */
  HeartRateDiagnostics_aggregate: HeartRateDiagnostics_Aggregate;
  /** fetch data from the table: "HeartRateDiagnostics" using primary key columns */
  HeartRateDiagnostics_by_pk?: Maybe<HeartRateDiagnostics>;
  /** fetch data from the table: "Household" */
  Household: Array<Household>;
  /** fetch aggregated fields from the table: "Household" */
  Household_aggregate: Household_Aggregate;
  /** fetch data from the table: "Household" using primary key columns */
  Household_by_pk?: Maybe<Household>;
  /** fetch data from the table: "MarketingEmail" */
  MarketingEmail: Array<MarketingEmail>;
  /** fetch aggregated fields from the table: "MarketingEmail" */
  MarketingEmail_aggregate: MarketingEmail_Aggregate;
  /** fetch data from the table: "MarketingEmail" using primary key columns */
  MarketingEmail_by_pk?: Maybe<MarketingEmail>;
  /** fetch data from the table: "Mastery" */
  Mastery: Array<Mastery>;
  /** fetch aggregated fields from the table: "Mastery" */
  Mastery_aggregate: Mastery_Aggregate;
  /** fetch data from the table: "Mastery" using primary key columns */
  Mastery_by_pk?: Maybe<Mastery>;
  /** fetch data from the table: "OnboardingInfo" */
  OnboardingInfo: Array<OnboardingInfo>;
  /** fetch aggregated fields from the table: "OnboardingInfo" */
  OnboardingInfo_aggregate: OnboardingInfo_Aggregate;
  /** fetch data from the table: "OnboardingInfo" using primary key columns */
  OnboardingInfo_by_pk?: Maybe<OnboardingInfo>;
  /** fetch data from the table: "OnboardingVideos" */
  OnboardingVideos: Array<OnboardingVideos>;
  /** fetch aggregated fields from the table: "OnboardingVideos" */
  OnboardingVideos_aggregate: OnboardingVideos_Aggregate;
  /** fetch data from the table: "OnboardingVideos" using primary key columns */
  OnboardingVideos_by_pk?: Maybe<OnboardingVideos>;
  /** fetch data from the table: "Order" */
  Order: Array<Order>;
  /** fetch data from the table: "OrderLineItem" */
  OrderLineItem: Array<OrderLineItem>;
  /** fetch aggregated fields from the table: "OrderLineItem" */
  OrderLineItem_aggregate: OrderLineItem_Aggregate;
  /** fetch data from the table: "OrderLineItem" using primary key columns */
  OrderLineItem_by_pk?: Maybe<OrderLineItem>;
  /** fetch aggregated fields from the table: "Order" */
  Order_aggregate: Order_Aggregate;
  /** fetch data from the table: "Order" using primary key columns */
  Order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "PaymentHistory" */
  PaymentHistory: Array<PaymentHistory>;
  /** fetch aggregated fields from the table: "PaymentHistory" */
  PaymentHistory_aggregate: PaymentHistory_Aggregate;
  /** fetch data from the table: "PaymentHistory" using primary key columns */
  PaymentHistory_by_pk?: Maybe<PaymentHistory>;
  /** fetch data from the table: "PlanDay" */
  PlanDay: Array<PlanDay>;
  /** fetch aggregated fields from the table: "PlanDay" */
  PlanDay_aggregate: PlanDay_Aggregate;
  /** fetch data from the table: "PlanDay" using primary key columns */
  PlanDay_by_pk?: Maybe<PlanDay>;
  /** fetch data from the table: "PresetDiet" */
  PresetDiet: Array<PresetDiet>;
  /** fetch aggregated fields from the table: "PresetDiet" */
  PresetDiet_aggregate: PresetDiet_Aggregate;
  /** fetch data from the table: "PresetDiet" using primary key columns */
  PresetDiet_by_pk?: Maybe<PresetDiet>;
  /** fetch data from the table: "Pro" */
  Pro: Array<Pro>;
  /** fetch data from the table: "ProMusic" */
  ProMusic: Array<ProMusic>;
  /** fetch aggregated fields from the table: "ProMusic" */
  ProMusic_aggregate: ProMusic_Aggregate;
  /** fetch data from the table: "ProMusic" using primary key columns */
  ProMusic_by_pk?: Maybe<ProMusic>;
  /** fetch aggregated fields from the table: "Pro" */
  Pro_aggregate: Pro_Aggregate;
  /** fetch data from the table: "Pro" using primary key columns */
  Pro_by_pk?: Maybe<Pro>;
  /** fetch data from the table: "Product" */
  Product: Array<Product>;
  /** fetch data from the table: "ProductLog" */
  ProductLog: Array<ProductLog>;
  /** fetch aggregated fields from the table: "ProductLog" */
  ProductLog_aggregate: ProductLog_Aggregate;
  /** fetch data from the table: "ProductLog" using primary key columns */
  ProductLog_by_pk?: Maybe<ProductLog>;
  /** fetch aggregated fields from the table: "Product" */
  Product_aggregate: Product_Aggregate;
  /** fetch data from the table: "Product" using primary key columns */
  Product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "PromotionalItem" */
  PromotionalItem: Array<PromotionalItem>;
  /** fetch aggregated fields from the table: "PromotionalItem" */
  PromotionalItem_aggregate: PromotionalItem_Aggregate;
  /** fetch data from the table: "PromotionalItem" using primary key columns */
  PromotionalItem_by_pk?: Maybe<PromotionalItem>;
  /** fetch data from the table: "QuizSurvey" */
  QuizSurvey: Array<QuizSurvey>;
  /** fetch aggregated fields from the table: "QuizSurvey" */
  QuizSurvey_aggregate: QuizSurvey_Aggregate;
  /** fetch data from the table: "QuizSurvey" using primary key columns */
  QuizSurvey_by_pk?: Maybe<QuizSurvey>;
  /** fetch data from the table: "Quote" */
  Quote: Array<Quote>;
  /** fetch aggregated fields from the table: "Quote" */
  Quote_aggregate: Quote_Aggregate;
  /** fetch data from the table: "Quote" using primary key columns */
  Quote_by_pk?: Maybe<Quote>;
  /** fetch data from the table: "ReimbursementRequirement" */
  ReimbursementRequirement: Array<ReimbursementRequirement>;
  /** fetch aggregated fields from the table: "ReimbursementRequirement" */
  ReimbursementRequirement_aggregate: ReimbursementRequirement_Aggregate;
  /** fetch data from the table: "ReimbursementRequirement" using primary key columns */
  ReimbursementRequirement_by_pk?: Maybe<ReimbursementRequirement>;
  /** fetch data from the table: "ScreenOrder" */
  ScreenOrder: Array<ScreenOrder>;
  /** fetch aggregated fields from the table: "ScreenOrder" */
  ScreenOrder_aggregate: ScreenOrder_Aggregate;
  /** fetch data from the table: "ScreenOrder" using primary key columns */
  ScreenOrder_by_pk?: Maybe<ScreenOrder>;
  /** fetch data from the table: "SearchResult" */
  SearchResult: Array<SearchResult>;
  /** fetch aggregated fields from the table: "SearchResult" */
  SearchResult_aggregate: SearchResult_Aggregate;
  /** fetch data from the table: "SearchResult" using primary key columns */
  SearchResult_by_pk?: Maybe<SearchResult>;
  /** fetch data from the table: "SleepSurvey" */
  SleepSurvey: Array<SleepSurvey>;
  /** fetch aggregated fields from the table: "SleepSurvey" */
  SleepSurvey_aggregate: SleepSurvey_Aggregate;
  /** fetch data from the table: "SleepSurvey" using primary key columns */
  SleepSurvey_by_pk?: Maybe<SleepSurvey>;
  /** fetch data from the table: "Subscription" */
  Subscription: Array<Subscription>;
  /** fetch data from the table: "SubscriptionLog" */
  SubscriptionLog: Array<SubscriptionLog>;
  /** fetch aggregated fields from the table: "SubscriptionLog" */
  SubscriptionLog_aggregate: SubscriptionLog_Aggregate;
  /** fetch data from the table: "SubscriptionLog" using primary key columns */
  SubscriptionLog_by_pk?: Maybe<SubscriptionLog>;
  /** fetch aggregated fields from the table: "Subscription" */
  Subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "Subscription" using primary key columns */
  Subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "Token" */
  Token: Array<Token>;
  /** fetch data from the table: "TokenHistory" */
  TokenHistory: Array<TokenHistory>;
  /** fetch aggregated fields from the table: "TokenHistory" */
  TokenHistory_aggregate: TokenHistory_Aggregate;
  /** fetch data from the table: "TokenHistory" using primary key columns */
  TokenHistory_by_pk?: Maybe<TokenHistory>;
  /** fetch aggregated fields from the table: "Token" */
  Token_aggregate: Token_Aggregate;
  /** fetch data from the table: "Token" using primary key columns */
  Token_by_pk?: Maybe<Token>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** An array relationship */
  UserAggregates: Array<UserAggregates>;
  /** An aggregate relationship */
  UserAggregates_aggregate: UserAggregates_Aggregate;
  /** fetch data from the table: "UserAggregates" using primary key columns */
  UserAggregates_by_pk?: Maybe<UserAggregates>;
  /** fetch data from the table: "UserAssessment" */
  UserAssessment: Array<UserAssessment>;
  /** fetch aggregated fields from the table: "UserAssessment" */
  UserAssessment_aggregate: UserAssessment_Aggregate;
  /** fetch data from the table: "UserAssessment" using primary key columns */
  UserAssessment_by_pk?: Maybe<UserAssessment>;
  /** fetch data from the table: "UserFeatureControl" */
  UserFeatureControl: Array<UserFeatureControl>;
  /** fetch aggregated fields from the table: "UserFeatureControl" */
  UserFeatureControl_aggregate: UserFeatureControl_Aggregate;
  /** fetch data from the table: "UserFeatureControl" using primary key columns */
  UserFeatureControl_by_pk?: Maybe<UserFeatureControl>;
  /** fetch data from the table: "UserFeedback" */
  UserFeedback: Array<UserFeedback>;
  /** fetch aggregated fields from the table: "UserFeedback" */
  UserFeedback_aggregate: UserFeedback_Aggregate;
  /** fetch data from the table: "UserFeedback" using primary key columns */
  UserFeedback_by_pk?: Maybe<UserFeedback>;
  /** fetch data from the table: "UserFinancePlan" */
  UserFinancePlan: Array<UserFinancePlan>;
  /** fetch aggregated fields from the table: "UserFinancePlan" */
  UserFinancePlan_aggregate: UserFinancePlan_Aggregate;
  /** fetch data from the table: "UserFinancePlan" using primary key columns */
  UserFinancePlan_by_pk?: Maybe<UserFinancePlan>;
  /** fetch data from the table: "UserHabit" */
  UserHabit: Array<UserHabit>;
  /** fetch aggregated fields from the table: "UserHabit" */
  UserHabit_aggregate: UserHabit_Aggregate;
  /** fetch data from the table: "UserHabit" using primary key columns */
  UserHabit_by_pk?: Maybe<UserHabit>;
  /** fetch data from the table: "UserMovementDaily" */
  UserMovementDaily: Array<UserMovementDaily>;
  /** fetch aggregated fields from the table: "UserMovementDaily" */
  UserMovementDaily_aggregate: UserMovementDaily_Aggregate;
  /** fetch data from the table: "UserMovementDaily" using primary key columns */
  UserMovementDaily_by_pk?: Maybe<UserMovementDaily>;
  /** An array relationship */
  UserPreferences: Array<UserPreferences>;
  /** An aggregate relationship */
  UserPreferences_aggregate: UserPreferences_Aggregate;
  /** fetch data from the table: "UserPreferences" using primary key columns */
  UserPreferences_by_pk?: Maybe<UserPreferences>;
  /** fetch data from the table: "UserProduct" */
  UserProduct: Array<UserProduct>;
  /** fetch data from the table: "UserProductHistory" */
  UserProductHistory: Array<UserProductHistory>;
  /** fetch aggregated fields from the table: "UserProductHistory" */
  UserProductHistory_aggregate: UserProductHistory_Aggregate;
  /** fetch data from the table: "UserProductHistory" using primary key columns */
  UserProductHistory_by_pk?: Maybe<UserProductHistory>;
  /** fetch aggregated fields from the table: "UserProduct" */
  UserProduct_aggregate: UserProduct_Aggregate;
  /** fetch data from the table: "UserProduct" using primary key columns */
  UserProduct_by_pk?: Maybe<UserProduct>;
  /** fetch data from the table: "UserReadiness" */
  UserReadiness: Array<UserReadiness>;
  /** fetch data from the table: "UserReadinessLog" */
  UserReadinessLog: Array<UserReadinessLog>;
  /** fetch aggregated fields from the table: "UserReadinessLog" */
  UserReadinessLog_aggregate: UserReadinessLog_Aggregate;
  /** fetch data from the table: "UserReadinessLog" using primary key columns */
  UserReadinessLog_by_pk?: Maybe<UserReadinessLog>;
  /** fetch aggregated fields from the table: "UserReadiness" */
  UserReadiness_aggregate: UserReadiness_Aggregate;
  /** fetch data from the table: "UserReadiness" using primary key columns */
  UserReadiness_by_pk?: Maybe<UserReadiness>;
  /** fetch data from the table: "UserReimbursmentHistory" */
  UserReimbursmentHistory: Array<UserReimbursmentHistory>;
  /** fetch aggregated fields from the table: "UserReimbursmentHistory" */
  UserReimbursmentHistory_aggregate: UserReimbursmentHistory_Aggregate;
  /** fetch data from the table: "UserReimbursmentHistory" using primary key columns */
  UserReimbursmentHistory_by_pk?: Maybe<UserReimbursmentHistory>;
  /** fetch data from the table: "UserRep" */
  UserRep: Array<UserRep>;
  /** fetch aggregated fields from the table: "UserRep" */
  UserRep_aggregate: UserRep_Aggregate;
  /** fetch data from the table: "UserRep" using primary key columns */
  UserRep_by_pk?: Maybe<UserRep>;
  /** fetch data from the table: "UserSet" */
  UserSet: Array<UserSet>;
  /** fetch aggregated fields from the table: "UserSet" */
  UserSet_aggregate: UserSet_Aggregate;
  /** fetch data from the table: "UserSet" using primary key columns */
  UserSet_by_pk?: Maybe<UserSet>;
  /** fetch data from the table: "UserState" */
  UserState: Array<UserState>;
  /** fetch aggregated fields from the table: "UserState" */
  UserState_aggregate: UserState_Aggregate;
  /** fetch data from the table: "UserState" using primary key columns */
  UserState_by_pk?: Maybe<UserState>;
  /** fetch data from the table: "UserTrainingPlan" */
  UserTrainingPlan: Array<UserTrainingPlan>;
  /** fetch aggregated fields from the table: "UserTrainingPlan" */
  UserTrainingPlan_aggregate: UserTrainingPlan_Aggregate;
  /** fetch data from the table: "UserTrainingPlan" using primary key columns */
  UserTrainingPlan_by_pk?: Maybe<UserTrainingPlan>;
  /** fetch data from the table: "UserWorkout" */
  UserWorkout: Array<UserWorkout>;
  /** fetch aggregated fields from the table: "UserWorkout" */
  UserWorkout_aggregate: UserWorkout_Aggregate;
  /** fetch data from the table: "UserWorkout" using primary key columns */
  UserWorkout_by_pk?: Maybe<UserWorkout>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** fetch data from the table: "VendorErrorLog" */
  VendorErrorLog: Array<VendorErrorLog>;
  /** fetch aggregated fields from the table: "VendorErrorLog" */
  VendorErrorLog_aggregate: VendorErrorLog_Aggregate;
  /** fetch data from the table: "VendorErrorLog" using primary key columns */
  VendorErrorLog_by_pk?: Maybe<VendorErrorLog>;
  /** fetch data from the table: "VersionControl" */
  VersionControl: Array<VersionControl>;
  /** fetch aggregated fields from the table: "VersionControl" */
  VersionControl_aggregate: VersionControl_Aggregate;
  /** fetch data from the table: "VersionControl" using primary key columns */
  VersionControl_by_pk?: Maybe<VersionControl>;
  /** fetch data from the table: "WeeklyPlan" */
  WeeklyPlan: Array<WeeklyPlan>;
  /** fetch aggregated fields from the table: "WeeklyPlan" */
  WeeklyPlan_aggregate: WeeklyPlan_Aggregate;
  /** fetch data from the table: "WeeklyPlan" using primary key columns */
  WeeklyPlan_by_pk?: Maybe<WeeklyPlan>;
  /** fetch data from the table: "WorkinPrefrence" */
  WorkinPrefrence: Array<WorkinPrefrence>;
  /** fetch aggregated fields from the table: "WorkinPrefrence" */
  WorkinPrefrence_aggregate: WorkinPrefrence_Aggregate;
  /** fetch data from the table: "WorkinPrefrence" using primary key columns */
  WorkinPrefrence_by_pk?: Maybe<WorkinPrefrence>;
  /** fetch data from the table: "Workout" */
  Workout: Array<Workout>;
  /** fetch data from the table: "WorkoutPrefrence" */
  WorkoutPrefrence: Array<WorkoutPrefrence>;
  /** fetch aggregated fields from the table: "WorkoutPrefrence" */
  WorkoutPrefrence_aggregate: WorkoutPrefrence_Aggregate;
  /** fetch data from the table: "WorkoutPrefrence" using primary key columns */
  WorkoutPrefrence_by_pk?: Maybe<WorkoutPrefrence>;
  /** fetch aggregated fields from the table: "Workout" */
  Workout_aggregate: Workout_Aggregate;
  /** fetch data from the table: "Workout" using primary key columns */
  Workout_by_pk?: Maybe<Workout>;
  /** fetch data from the table: "_FollowingPro" */
  _FollowingPro: Array<_FollowingPro>;
  /** fetch aggregated fields from the table: "_FollowingPro" */
  _FollowingPro_aggregate: _FollowingPro_Aggregate;
  /** An array relationship */
  _GoalToUserPreferences: Array<_GoalToUserPreferences>;
  /** An aggregate relationship */
  _GoalToUserPreferences_aggregate: _GoalToUserPreferences_Aggregate;
  activityInsights?: Maybe<ActivityInsightsOutput>;
  activityInsightsDetail?: Maybe<Array<Maybe<ActivityInsightsDetailOutput>>>;
  assessmentHistory: Scalars['json']['output'];
  assessmentHistoryDetail: Scalars['json']['output'];
  /** checkCompleteDNAResults */
  checkCompleteDNAResults: Scalars['json']['output'];
  /** checkDnaKit */
  checkDnaKit?: Maybe<CheckDnaKitOutput>;
  checkDnaKitEmail: Scalars['Boolean']['output'];
  checkNutritionReportReleased?: Maybe<CheckNutritionReportReleasedOutput>;
  checkPresetPlanStructure?: Maybe<CheckPresetPlanStructureOutput>;
  currentActivityProgress?: Maybe<ActivityProgressOutput>;
  /** discountCodeDetails */
  discountCodeDetails: Scalars['json']['output'];
  emailHasDNAKit?: Maybe<EmailHasKitOutput>;
  fetchActivity?: Maybe<FetchActivityResult>;
  fetchBioStrapAssignment?: Maybe<Array<Maybe<IdObj>>>;
  fetchCalendlyLink: Scalars['json']['output'];
  /** fetchCoachBySfId */
  fetchCoachBySfId?: Maybe<FetchCoachOutput>;
  fetchCoachedPlan?: Maybe<FetchCoachedPlanOutput>;
  /** fetchCoaches */
  fetchCoaches?: Maybe<Array<Maybe<FetchCoachOutput>>>;
  fetchCookBook?: Maybe<FetchCookBookOutput>;
  fetchFeatureFlags?: Maybe<FetchFeatureFlagsOutput>;
  fetchFirmwareContentV2?: Maybe<GenericListOutput>;
  fetchHousehold: Array<Maybe<HouseholdOutput>>;
  /** fetchHouseholdCreditHistory */
  fetchHouseholdCreditHistory?: Maybe<Array<Maybe<CreditHistoryOutput>>>;
  fetchMealPlan?: Maybe<MealPlanOutput>;
  fetchMealPlanPreferenceMap?: Maybe<MealPlanPreferenceOutput>;
  fetchMobileVersions?: Maybe<MobileVersionsOutput>;
  fetchNutritionReport?: Maybe<NutritionReportOutput>;
  fetchPearWorkout: Scalars['json']['output'];
  fetchPlanProgress: PlanProgressOutput;
  fetchPlans?: Maybe<Array<Maybe<FetchPlansOutput>>>;
  fetchRingAssignment?: Maybe<Array<Maybe<IdObj>>>;
  fetchUserMonthsActive?: Maybe<UserMonthsActiveOutput>;
  fetchUserShippingAddress?: Maybe<ShippingAddressOutput>;
  fetchWorkoutOptions?: Maybe<Array<Maybe<FetchWorkoutOptionsOutput>>>;
  fetchWorkoutOptionsV2?: Maybe<Array<Maybe<FetchWorkoutOptionsOutputV2>>>;
  fetchWorkoutOptionsV3?: Maybe<Array<Maybe<FetchWorkoutOptionsOutputV3>>>;
  findManyPresetPlans?: Maybe<ResultListOutput>;
  fitnessRecommender?: Maybe<Array<Maybe<FitnessRecommenderOutput>>>;
  getAllUsers?: Maybe<Array<Maybe<AllUsersOutput>>>;
  getHRZoneBounds: Scalars['json']['output'];
  /** getHouseholdMembers */
  getHouseholdMembers: Scalars['json']['output'];
  getHrZone2?: Maybe<HrZone2Output>;
  getSFCoachId?: Maybe<GetSfCoachIdOutput>;
  /** getSfCoachCall */
  getSfCoachCall?: Maybe<SfCoachCallOutput>;
  /** getSfFitnessGoal */
  getSfFitnessGoal?: Maybe<SfFitnessGoalOutput>;
  /** getSubscription */
  getSubscription: Scalars['json']['output'];
  getUserByAuth0Id?: Maybe<GetUserByAuth0IdOutput>;
  getUserMetadata: Scalars['json']['output'];
  /** getUserProducts */
  getUserProducts?: Maybe<Array<Maybe<UserProductsOutput>>>;
  hi: Scalars['String']['output'];
  isValidKit?: Maybe<IsValidKitOutput>;
  /** myAlterProducts */
  myAlterProducts: Scalars['json']['output'];
  planHistory: PlanHistoryOutput;
  profileStats: ProfileStatsOutput;
  profileStatsDetail: Scalars['json']['output'];
  quizSurveyLabels?: Maybe<LabelsOutput>;
  recommendWorkoutByOption?: Maybe<RecommendWorkoutByOptionOutput>;
  retrieveFirmwareV2?: Maybe<RetrieveFirmwareV2Output>;
  sleepInsights?: Maybe<SleepInsightsOutput>;
  sleepInsightsDetail?: Maybe<SleepInsightsDetailOutput>;
  testAdmin: Scalars['String']['output'];
  /** testCheckSleep */
  testCheckSleep: Scalars['json']['output'];
  testFree: Scalars['String']['output'];
  testPremium: Scalars['String']['output'];
  testPro: Scalars['String']['output'];
  userNutritionReport?: Maybe<UserNutritionReportOutput>;
  workoutDetails: Scalars['json']['output'];
  workoutDetailsV2: Scalars['json']['output'];
};


export type Query_RootActivationCodeArgs = {
  distinct_on?: InputMaybe<Array<ActivationCode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActivationCode_Order_By>>;
  where?: InputMaybe<ActivationCode_Bool_Exp>;
};


export type Query_RootActivationCode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivationCode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActivationCode_Order_By>>;
  where?: InputMaybe<ActivationCode_Bool_Exp>;
};


export type Query_RootActivationCode_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAlterTypeColorArgs = {
  distinct_on?: InputMaybe<Array<AlterTypeColor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlterTypeColor_Order_By>>;
  where?: InputMaybe<AlterTypeColor_Bool_Exp>;
};


export type Query_RootAlterTypeColor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlterTypeColor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlterTypeColor_Order_By>>;
  where?: InputMaybe<AlterTypeColor_Bool_Exp>;
};


export type Query_RootAlterTypeColor_By_PkArgs = {
  letter: Scalars['String']['input'];
};


export type Query_RootCoachArgs = {
  distinct_on?: InputMaybe<Array<Coach_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coach_Order_By>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


export type Query_RootCoach_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coach_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coach_Order_By>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


export type Query_RootCoach_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCoachedHabitArgs = {
  distinct_on?: InputMaybe<Array<CoachedHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedHabit_Order_By>>;
  where?: InputMaybe<CoachedHabit_Bool_Exp>;
};


export type Query_RootCoachedHabit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedHabit_Order_By>>;
  where?: InputMaybe<CoachedHabit_Bool_Exp>;
};


export type Query_RootCoachedHabit_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCoachedNutritionArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


export type Query_RootCoachedNutrition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


export type Query_RootCoachedNutrition_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootDnaKitArgs = {
  distinct_on?: InputMaybe<Array<DnaKit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaKit_Order_By>>;
  where?: InputMaybe<DnaKit_Bool_Exp>;
};


export type Query_RootDnaKit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DnaKit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaKit_Order_By>>;
  where?: InputMaybe<DnaKit_Bool_Exp>;
};


export type Query_RootDnaKit_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootDnaResultsArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


export type Query_RootDnaResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


export type Query_RootDnaResults_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootDeliveryArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Order_By>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


export type Query_RootDeliveryHistoryArgs = {
  distinct_on?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryHistory_Order_By>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};


export type Query_RootDeliveryHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryHistory_Order_By>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};


export type Query_RootDeliveryHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootDelivery_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Order_By>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


export type Query_RootDelivery_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Query_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Query_RootDevice_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Query_RootDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Query_RootDiagnostics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootFinancePlanArgs = {
  distinct_on?: InputMaybe<Array<FinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FinancePlan_Order_By>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


export type Query_RootFinancePlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FinancePlan_Order_By>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


export type Query_RootFinancePlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootFirmwareContentArgs = {
  distinct_on?: InputMaybe<Array<FirmwareContent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FirmwareContent_Order_By>>;
  where?: InputMaybe<FirmwareContent_Bool_Exp>;
};


export type Query_RootFirmwareContent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FirmwareContent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FirmwareContent_Order_By>>;
  where?: InputMaybe<FirmwareContent_Bool_Exp>;
};


export type Query_RootFirmwareContent_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootGeneticDirectionWebhookArgs = {
  distinct_on?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeneticDirectionWebhook_Order_By>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


export type Query_RootGeneticDirectionWebhook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeneticDirectionWebhook_Order_By>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


export type Query_RootGeneticDirectionWebhook_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootGlobalFeatureControlArgs = {
  distinct_on?: InputMaybe<Array<GlobalFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GlobalFeatureControl_Order_By>>;
  where?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
};


export type Query_RootGlobalFeatureControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GlobalFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GlobalFeatureControl_Order_By>>;
  where?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
};


export type Query_RootGlobalFeatureControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootGoalArgs = {
  distinct_on?: InputMaybe<Array<Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goal_Order_By>>;
  where?: InputMaybe<Goal_Bool_Exp>;
};


export type Query_RootGoal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goal_Order_By>>;
  where?: InputMaybe<Goal_Bool_Exp>;
};


export type Query_RootGoal_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootHabitDayArgs = {
  distinct_on?: InputMaybe<Array<HabitDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HabitDay_Order_By>>;
  where?: InputMaybe<HabitDay_Bool_Exp>;
};


export type Query_RootHabitDay_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HabitDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HabitDay_Order_By>>;
  where?: InputMaybe<HabitDay_Bool_Exp>;
};


export type Query_RootHabitDay_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootHeartRateDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


export type Query_RootHeartRateDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


export type Query_RootHeartRateDiagnostics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootHouseholdArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


export type Query_RootHousehold_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


export type Query_RootHousehold_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootMarketingEmailArgs = {
  distinct_on?: InputMaybe<Array<MarketingEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MarketingEmail_Order_By>>;
  where?: InputMaybe<MarketingEmail_Bool_Exp>;
};


export type Query_RootMarketingEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MarketingEmail_Order_By>>;
  where?: InputMaybe<MarketingEmail_Bool_Exp>;
};


export type Query_RootMarketingEmail_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootMasteryArgs = {
  distinct_on?: InputMaybe<Array<Mastery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mastery_Order_By>>;
  where?: InputMaybe<Mastery_Bool_Exp>;
};


export type Query_RootMastery_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mastery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mastery_Order_By>>;
  where?: InputMaybe<Mastery_Bool_Exp>;
};


export type Query_RootMastery_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootOnboardingInfoArgs = {
  distinct_on?: InputMaybe<Array<OnboardingInfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingInfo_Order_By>>;
  where?: InputMaybe<OnboardingInfo_Bool_Exp>;
};


export type Query_RootOnboardingInfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OnboardingInfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingInfo_Order_By>>;
  where?: InputMaybe<OnboardingInfo_Bool_Exp>;
};


export type Query_RootOnboardingInfo_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootOnboardingVideosArgs = {
  distinct_on?: InputMaybe<Array<OnboardingVideos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingVideos_Order_By>>;
  where?: InputMaybe<OnboardingVideos_Bool_Exp>;
};


export type Query_RootOnboardingVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OnboardingVideos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingVideos_Order_By>>;
  where?: InputMaybe<OnboardingVideos_Bool_Exp>;
};


export type Query_RootOnboardingVideos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrderLineItemArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


export type Query_RootOrderLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


export type Query_RootOrderLineItem_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPaymentHistoryArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


export type Query_RootPaymentHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


export type Query_RootPaymentHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPlanDayArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


export type Query_RootPlanDay_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


export type Query_RootPlanDay_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPresetDietArgs = {
  distinct_on?: InputMaybe<Array<PresetDiet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PresetDiet_Order_By>>;
  where?: InputMaybe<PresetDiet_Bool_Exp>;
};


export type Query_RootPresetDiet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PresetDiet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PresetDiet_Order_By>>;
  where?: InputMaybe<PresetDiet_Bool_Exp>;
};


export type Query_RootPresetDiet_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProArgs = {
  distinct_on?: InputMaybe<Array<Pro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pro_Order_By>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


export type Query_RootProMusicArgs = {
  distinct_on?: InputMaybe<Array<ProMusic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProMusic_Order_By>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


export type Query_RootProMusic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProMusic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProMusic_Order_By>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


export type Query_RootProMusic_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPro_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pro_Order_By>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


export type Query_RootPro_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProductLogArgs = {
  distinct_on?: InputMaybe<Array<ProductLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductLog_Order_By>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};


export type Query_RootProductLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductLog_Order_By>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};


export type Query_RootProductLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPromotionalItemArgs = {
  distinct_on?: InputMaybe<Array<PromotionalItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PromotionalItem_Order_By>>;
  where?: InputMaybe<PromotionalItem_Bool_Exp>;
};


export type Query_RootPromotionalItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionalItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PromotionalItem_Order_By>>;
  where?: InputMaybe<PromotionalItem_Bool_Exp>;
};


export type Query_RootPromotionalItem_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootQuizSurveyArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


export type Query_RootQuizSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


export type Query_RootQuizSurvey_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootQuoteArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Query_RootQuote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Query_RootQuote_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootReimbursementRequirementArgs = {
  distinct_on?: InputMaybe<Array<ReimbursementRequirement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReimbursementRequirement_Order_By>>;
  where?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
};


export type Query_RootReimbursementRequirement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReimbursementRequirement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReimbursementRequirement_Order_By>>;
  where?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
};


export type Query_RootReimbursementRequirement_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootScreenOrderArgs = {
  distinct_on?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ScreenOrder_Order_By>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


export type Query_RootScreenOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ScreenOrder_Order_By>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


export type Query_RootScreenOrder_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSearchResultArgs = {
  distinct_on?: InputMaybe<Array<SearchResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SearchResult_Order_By>>;
  where?: InputMaybe<SearchResult_Bool_Exp>;
};


export type Query_RootSearchResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SearchResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SearchResult_Order_By>>;
  where?: InputMaybe<SearchResult_Bool_Exp>;
};


export type Query_RootSearchResult_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSleepSurveyArgs = {
  distinct_on?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SleepSurvey_Order_By>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


export type Query_RootSleepSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SleepSurvey_Order_By>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


export type Query_RootSleepSurvey_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscriptionLogArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubscriptionLog_Order_By>>;
  where?: InputMaybe<SubscriptionLog_Bool_Exp>;
};


export type Query_RootSubscriptionLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubscriptionLog_Order_By>>;
  where?: InputMaybe<SubscriptionLog_Bool_Exp>;
};


export type Query_RootSubscriptionLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTokenArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Query_RootTokenHistoryArgs = {
  distinct_on?: InputMaybe<Array<TokenHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TokenHistory_Order_By>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


export type Query_RootTokenHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TokenHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TokenHistory_Order_By>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


export type Query_RootTokenHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootToken_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Query_RootToken_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUserAggregatesArgs = {
  distinct_on?: InputMaybe<Array<UserAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAggregates_Order_By>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


export type Query_RootUserAggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAggregates_Order_By>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


export type Query_RootUserAggregates_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserAssessmentArgs = {
  distinct_on?: InputMaybe<Array<UserAssessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAssessment_Order_By>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


export type Query_RootUserAssessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAssessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAssessment_Order_By>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


export type Query_RootUserAssessment_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserFeatureControlArgs = {
  distinct_on?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeatureControl_Order_By>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


export type Query_RootUserFeatureControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeatureControl_Order_By>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


export type Query_RootUserFeatureControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserFeedbackArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


export type Query_RootUserFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


export type Query_RootUserFeedback_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserFinancePlanArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


export type Query_RootUserFinancePlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


export type Query_RootUserFinancePlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserHabitArgs = {
  distinct_on?: InputMaybe<Array<UserHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserHabit_Order_By>>;
  where?: InputMaybe<UserHabit_Bool_Exp>;
};


export type Query_RootUserHabit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserHabit_Order_By>>;
  where?: InputMaybe<UserHabit_Bool_Exp>;
};


export type Query_RootUserHabit_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserMovementDailyArgs = {
  distinct_on?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMovementDaily_Order_By>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


export type Query_RootUserMovementDaily_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMovementDaily_Order_By>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


export type Query_RootUserMovementDaily_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Query_RootUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Query_RootUserPreferences_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserProductArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


export type Query_RootUserProductHistoryArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


export type Query_RootUserProductHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


export type Query_RootUserProductHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


export type Query_RootUserProduct_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserReadinessArgs = {
  distinct_on?: InputMaybe<Array<UserReadiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadiness_Order_By>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


export type Query_RootUserReadinessLogArgs = {
  distinct_on?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadinessLog_Order_By>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


export type Query_RootUserReadinessLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadinessLog_Order_By>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


export type Query_RootUserReadinessLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserReadiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReadiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadiness_Order_By>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


export type Query_RootUserReadiness_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserReimbursmentHistoryArgs = {
  distinct_on?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReimbursmentHistory_Order_By>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


export type Query_RootUserReimbursmentHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReimbursmentHistory_Order_By>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


export type Query_RootUserReimbursmentHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserRepArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


export type Query_RootUserRep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


export type Query_RootUserRep_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserSetArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


export type Query_RootUserSet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


export type Query_RootUserSet_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserStateArgs = {
  distinct_on?: InputMaybe<Array<UserState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserState_Order_By>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


export type Query_RootUserState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserState_Order_By>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


export type Query_RootUserState_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserTrainingPlanArgs = {
  distinct_on?: InputMaybe<Array<UserTrainingPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserTrainingPlan_Order_By>>;
  where?: InputMaybe<UserTrainingPlan_Bool_Exp>;
};


export type Query_RootUserTrainingPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserTrainingPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserTrainingPlan_Order_By>>;
  where?: InputMaybe<UserTrainingPlan_Bool_Exp>;
};


export type Query_RootUserTrainingPlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUserWorkoutArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


export type Query_RootUserWorkout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


export type Query_RootUserWorkout_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVendorErrorLogArgs = {
  distinct_on?: InputMaybe<Array<VendorErrorLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VendorErrorLog_Order_By>>;
  where?: InputMaybe<VendorErrorLog_Bool_Exp>;
};


export type Query_RootVendorErrorLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VendorErrorLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VendorErrorLog_Order_By>>;
  where?: InputMaybe<VendorErrorLog_Bool_Exp>;
};


export type Query_RootVendorErrorLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVersionControlArgs = {
  distinct_on?: InputMaybe<Array<VersionControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionControl_Order_By>>;
  where?: InputMaybe<VersionControl_Bool_Exp>;
};


export type Query_RootVersionControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VersionControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionControl_Order_By>>;
  where?: InputMaybe<VersionControl_Bool_Exp>;
};


export type Query_RootVersionControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootWeeklyPlanArgs = {
  distinct_on?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WeeklyPlan_Order_By>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


export type Query_RootWeeklyPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WeeklyPlan_Order_By>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


export type Query_RootWeeklyPlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootWorkinPrefrenceArgs = {
  distinct_on?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkinPrefrence_Order_By>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


export type Query_RootWorkinPrefrence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkinPrefrence_Order_By>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


export type Query_RootWorkinPrefrence_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootWorkoutArgs = {
  distinct_on?: InputMaybe<Array<Workout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workout_Order_By>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


export type Query_RootWorkoutPrefrenceArgs = {
  distinct_on?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkoutPrefrence_Order_By>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


export type Query_RootWorkoutPrefrence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkoutPrefrence_Order_By>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


export type Query_RootWorkoutPrefrence_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootWorkout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workout_Order_By>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


export type Query_RootWorkout_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_Root_FollowingProArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


export type Query_Root_FollowingPro_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


export type Query_Root_GoalToUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};


export type Query_Root_GoalToUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};


export type Query_RootActivityInsightsArgs = {
  startDate: Scalars['String']['input'];
  stopDate: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootActivityInsightsDetailArgs = {
  groupBy?: InputMaybe<InsightsDetailGroupBy>;
  metric: ActivityInsightsMetric;
  startDate: Scalars['String']['input'];
  stopDate: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootAssessmentHistoryArgs = {
  start_epoch: Scalars['Int']['input'];
  stop_epoch?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['String']['input'];
  view: AssessmentViewEnum;
};


export type Query_RootAssessmentHistoryDetailArgs = {
  assessment_type: AssessmentTypeEnum;
  start_epoch: Scalars['Int']['input'];
  stop_epoch?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['String']['input'];
};


export type Query_RootCheckDnaKitArgs = {
  activationCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
};


export type Query_RootCheckDnaKitEmailArgs = {
  email: Scalars['String']['input'];
};


export type Query_RootCheckPresetPlanStructureArgs = {
  plan?: InputMaybe<Array<InputMaybe<Array<InputMaybe<PresetPlanDay>>>>>;
};


export type Query_RootCurrentActivityProgressArgs = {
  date: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootDiscountCodeDetailsArgs = {
  discountCode: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};


export type Query_RootEmailHasDnaKitArgs = {
  email: Scalars['String']['input'];
};


export type Query_RootFetchActivityArgs = {
  startDate: Scalars['String']['input'];
  stopDate: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  view?: InputMaybe<InsightsDetailGroupBy>;
};


export type Query_RootFetchBioStrapAssignmentArgs = {
  biostrapName: Scalars['String']['input'];
};


export type Query_RootFetchCoachBySfIdArgs = {
  sf_coach_id: Scalars['String']['input'];
};


export type Query_RootFetchCoachedPlanArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootFetchCookBookArgs = {
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_snack?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['String']['input'];
};


export type Query_RootFetchFeatureFlagsArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootFetchFirmwareContentV2Args = {
  device?: InputMaybe<Scalars['String']['input']>;
  hardware_type?: InputMaybe<HardwareTypeEnum>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_beta?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type Query_RootFetchHouseholdArgs = {
  household_id: Scalars['String']['input'];
};


export type Query_RootFetchHouseholdCreditHistoryArgs = {
  householdId: Scalars['String']['input'];
  monthTimestamp: Scalars['String']['input'];
};


export type Query_RootFetchMealPlanArgs = {
  meal_plan_id: Scalars['String']['input'];
};


export type Query_RootFetchMealPlanPreferenceMapArgs = {
  category?: InputMaybe<MealPlanPreferenceOptionCategoryEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type Query_RootFetchMobileVersionsArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootFetchNutritionReportArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootFetchPearWorkoutArgs = {
  sku: Scalars['String']['input'];
};


export type Query_RootFetchPlanProgressArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootFetchPlansArgs = {
  start_date: Scalars['String']['input'];
  stop_date?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
};


export type Query_RootFetchRingAssignmentArgs = {
  ringName: Scalars['String']['input'];
};


export type Query_RootFetchUserMonthsActiveArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootFetchUserShippingAddressArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootFetchWorkoutOptionsArgs = {
  sub_type: WorkoutSubTypeEnum;
  user_id: Scalars['String']['input'];
  workout_type: WorkoutTypeEnum;
};


export type Query_RootFetchWorkoutOptionsV2Args = {
  sub_type: WorkoutSubTypeEnum;
  user_id: Scalars['String']['input'];
  workout_type: WorkoutTypeEnum;
};


export type Query_RootFetchWorkoutOptionsV3Args = {
  level_override?: InputMaybe<Scalars['Int']['input']>;
  sub_type: WorkoutSubTypeEnum;
  user_id: Scalars['String']['input'];
  workout_type: WorkoutTypeEnum;
};


export type Query_RootFindManyPresetPlansArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type Query_RootFitnessRecommenderArgs = {
  sub_type: WorkoutSubTypeEnum;
  user_id: Scalars['String']['input'];
  workout_type: WorkoutTypeEnum;
};


export type Query_RootGetHrZoneBoundsArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootGetHrZone2Args = {
  userId: Scalars['String']['input'];
};


export type Query_RootGetSfCoachIdArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootGetSfCoachCallArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootGetSfFitnessGoalArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootGetUserByAuth0IdArgs = {
  auth0_id: Scalars['String']['input'];
};


export type Query_RootGetUserMetadataArgs = {
  auth0UserId: Scalars['String']['input'];
};


export type Query_RootGetUserProductsArgs = {
  systemAndSignalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
};


export type Query_RootIsValidKitArgs = {
  activationCode: Scalars['String']['input'];
  kitId: Scalars['String']['input'];
};


export type Query_RootPlanHistoryArgs = {
  stopDate?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  weekOffset: Scalars['Int']['input'];
};


export type Query_RootProfileStatsArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootProfileStatsDetailArgs = {
  user_id: Scalars['String']['input'];
  workout_type?: InputMaybe<WorkoutTypeEnum>;
};


export type Query_RootRecommendWorkoutByOptionArgs = {
  duration: Scalars['Int']['input'];
  equipment_preference?: InputMaybe<EquipmentPreferenceEnum>;
  isAdaptive?: InputMaybe<Scalars['Boolean']['input']>;
  level_override?: InputMaybe<Scalars['Int']['input']>;
  sub_type: WorkoutSubTypeEnum;
  theme?: InputMaybe<Scalars['String']['input']>;
  trainer: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
  workout_type: WorkoutTypeEnum;
};


export type Query_RootRetrieveFirmwareV2Args = {
  id: Scalars['String']['input'];
};


export type Query_RootSleepInsightsArgs = {
  enablePhilips?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['String']['input'];
  stopDate: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootSleepInsightsDetailArgs = {
  enablePhilips?: InputMaybe<Scalars['Boolean']['input']>;
  groupBy?: InputMaybe<InsightsDetailGroupBy>;
  metric: SleepInsightsMetric;
  startDate: Scalars['String']['input'];
  stopDate: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Query_RootTestCheckSleepArgs = {
  date: Scalars['String']['input'];
  start_modifier: Scalars['Int']['input'];
  stop_modifier: Scalars['Int']['input'];
  use_existing_start_stop: Scalars['Boolean']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUserNutritionReportArgs = {
  userId: Scalars['String']['input'];
};


export type Query_RootWorkoutDetailsArgs = {
  workout_id: Scalars['String']['input'];
  workout_type: WorkoutTypeEnum;
};


export type Query_RootWorkoutDetailsV2Args = {
  workout_id: Scalars['String']['input'];
};

export type RecommendWorkoutByOptionOutput = {
  __typename?: 'recommendWorkoutByOptionOutput';
  audioUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration_minutes?: Maybe<Scalars['Int']['output']>;
  extended_content?: Maybe<Scalars['json']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isAdaptive?: Maybe<Scalars['Boolean']['output']>;
  sub_type?: Maybe<WorkoutSubTypeEnum>;
  themes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  trainer?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  workout_id?: Maybe<Scalars['String']['output']>;
  workout_type?: Maybe<WorkoutTypeEnum>;
};

export type SleepAverage = {
  __typename?: 'sleepAverage';
  datePart?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['Float']['output']>;
  min_start?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

export type SleepInsightsDetailData = {
  __typename?: 'sleepInsightsDetailData';
  awakeTime?: Maybe<Array<Maybe<ValueAndTime>>>;
  hrVariability?: Maybe<Array<Maybe<ValueTimeAndAverage>>>;
  restingHR?: Maybe<Array<Maybe<ValueTimeAndMinimum>>>;
  score?: Maybe<Array<Maybe<ValueAndTime>>>;
  sleep?: Maybe<SleepV1>;
  sleepV2?: Maybe<Array<Maybe<Scalars['json']['output']>>>;
};

export type SleepV1 = {
  __typename?: 'sleepV1';
  awakeTime?: Maybe<Array<Maybe<ValueAndTime>>>;
  deepSleep?: Maybe<Array<Maybe<ValueAndTime>>>;
  lightSleep?: Maybe<Array<Maybe<ValueAndTime>>>;
  sleepDuration?: Maybe<Array<Maybe<ValueAndTime>>>;
};

export type StartWorkoutOutput = {
  __typename?: 'startWorkoutOutput';
  workout_id: Scalars['String']['output'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "ActivationCode" */
  ActivationCode: Array<ActivationCode>;
  /** fetch aggregated fields from the table: "ActivationCode" */
  ActivationCode_aggregate: ActivationCode_Aggregate;
  /** fetch data from the table: "ActivationCode" using primary key columns */
  ActivationCode_by_pk?: Maybe<ActivationCode>;
  /** fetch data from the table in a streaming manner: "ActivationCode" */
  ActivationCode_stream: Array<ActivationCode>;
  /** fetch data from the table: "AlterTypeColor" */
  AlterTypeColor: Array<AlterTypeColor>;
  /** fetch aggregated fields from the table: "AlterTypeColor" */
  AlterTypeColor_aggregate: AlterTypeColor_Aggregate;
  /** fetch data from the table: "AlterTypeColor" using primary key columns */
  AlterTypeColor_by_pk?: Maybe<AlterTypeColor>;
  /** fetch data from the table in a streaming manner: "AlterTypeColor" */
  AlterTypeColor_stream: Array<AlterTypeColor>;
  /** fetch data from the table: "Coach" */
  Coach: Array<Coach>;
  /** fetch aggregated fields from the table: "Coach" */
  Coach_aggregate: Coach_Aggregate;
  /** fetch data from the table: "Coach" using primary key columns */
  Coach_by_pk?: Maybe<Coach>;
  /** fetch data from the table in a streaming manner: "Coach" */
  Coach_stream: Array<Coach>;
  /** fetch data from the table: "CoachedHabit" */
  CoachedHabit: Array<CoachedHabit>;
  /** fetch aggregated fields from the table: "CoachedHabit" */
  CoachedHabit_aggregate: CoachedHabit_Aggregate;
  /** fetch data from the table: "CoachedHabit" using primary key columns */
  CoachedHabit_by_pk?: Maybe<CoachedHabit>;
  /** fetch data from the table in a streaming manner: "CoachedHabit" */
  CoachedHabit_stream: Array<CoachedHabit>;
  /** fetch data from the table: "CoachedNutrition" */
  CoachedNutrition: Array<CoachedNutrition>;
  /** fetch aggregated fields from the table: "CoachedNutrition" */
  CoachedNutrition_aggregate: CoachedNutrition_Aggregate;
  /** fetch data from the table: "CoachedNutrition" using primary key columns */
  CoachedNutrition_by_pk?: Maybe<CoachedNutrition>;
  /** fetch data from the table in a streaming manner: "CoachedNutrition" */
  CoachedNutrition_stream: Array<CoachedNutrition>;
  /** fetch data from the table: "DNAKit" */
  DNAKit: Array<DnaKit>;
  /** fetch aggregated fields from the table: "DNAKit" */
  DNAKit_aggregate: DnaKit_Aggregate;
  /** fetch data from the table: "DNAKit" using primary key columns */
  DNAKit_by_pk?: Maybe<DnaKit>;
  /** fetch data from the table in a streaming manner: "DNAKit" */
  DNAKit_stream: Array<DnaKit>;
  /** An array relationship */
  DNAResults: Array<DnaResults>;
  /** An aggregate relationship */
  DNAResults_aggregate: DnaResults_Aggregate;
  /** fetch data from the table: "DNAResults" using primary key columns */
  DNAResults_by_pk?: Maybe<DnaResults>;
  /** fetch data from the table in a streaming manner: "DNAResults" */
  DNAResults_stream: Array<DnaResults>;
  /** fetch data from the table: "Delivery" */
  Delivery: Array<Delivery>;
  /** fetch data from the table: "DeliveryHistory" */
  DeliveryHistory: Array<DeliveryHistory>;
  /** fetch aggregated fields from the table: "DeliveryHistory" */
  DeliveryHistory_aggregate: DeliveryHistory_Aggregate;
  /** fetch data from the table: "DeliveryHistory" using primary key columns */
  DeliveryHistory_by_pk?: Maybe<DeliveryHistory>;
  /** fetch data from the table in a streaming manner: "DeliveryHistory" */
  DeliveryHistory_stream: Array<DeliveryHistory>;
  /** fetch aggregated fields from the table: "Delivery" */
  Delivery_aggregate: Delivery_Aggregate;
  /** fetch data from the table: "Delivery" using primary key columns */
  Delivery_by_pk?: Maybe<Delivery>;
  /** fetch data from the table in a streaming manner: "Delivery" */
  Delivery_stream: Array<Delivery>;
  /** fetch data from the table: "Device" */
  Device: Array<Device>;
  /** fetch aggregated fields from the table: "Device" */
  Device_aggregate: Device_Aggregate;
  /** fetch data from the table: "Device" using primary key columns */
  Device_by_pk?: Maybe<Device>;
  /** fetch data from the table in a streaming manner: "Device" */
  Device_stream: Array<Device>;
  /** An array relationship */
  Diagnostics: Array<Diagnostics>;
  /** An aggregate relationship */
  Diagnostics_aggregate: Diagnostics_Aggregate;
  /** fetch data from the table: "Diagnostics" using primary key columns */
  Diagnostics_by_pk?: Maybe<Diagnostics>;
  /** fetch data from the table in a streaming manner: "Diagnostics" */
  Diagnostics_stream: Array<Diagnostics>;
  /** fetch data from the table: "FinancePlan" */
  FinancePlan: Array<FinancePlan>;
  /** fetch aggregated fields from the table: "FinancePlan" */
  FinancePlan_aggregate: FinancePlan_Aggregate;
  /** fetch data from the table: "FinancePlan" using primary key columns */
  FinancePlan_by_pk?: Maybe<FinancePlan>;
  /** fetch data from the table in a streaming manner: "FinancePlan" */
  FinancePlan_stream: Array<FinancePlan>;
  /** fetch data from the table: "FirmwareContent" */
  FirmwareContent: Array<FirmwareContent>;
  /** fetch aggregated fields from the table: "FirmwareContent" */
  FirmwareContent_aggregate: FirmwareContent_Aggregate;
  /** fetch data from the table: "FirmwareContent" using primary key columns */
  FirmwareContent_by_pk?: Maybe<FirmwareContent>;
  /** fetch data from the table in a streaming manner: "FirmwareContent" */
  FirmwareContent_stream: Array<FirmwareContent>;
  /** fetch data from the table: "GeneticDirectionWebhook" */
  GeneticDirectionWebhook: Array<GeneticDirectionWebhook>;
  /** fetch aggregated fields from the table: "GeneticDirectionWebhook" */
  GeneticDirectionWebhook_aggregate: GeneticDirectionWebhook_Aggregate;
  /** fetch data from the table: "GeneticDirectionWebhook" using primary key columns */
  GeneticDirectionWebhook_by_pk?: Maybe<GeneticDirectionWebhook>;
  /** fetch data from the table in a streaming manner: "GeneticDirectionWebhook" */
  GeneticDirectionWebhook_stream: Array<GeneticDirectionWebhook>;
  /** fetch data from the table: "GlobalFeatureControl" */
  GlobalFeatureControl: Array<GlobalFeatureControl>;
  /** fetch aggregated fields from the table: "GlobalFeatureControl" */
  GlobalFeatureControl_aggregate: GlobalFeatureControl_Aggregate;
  /** fetch data from the table: "GlobalFeatureControl" using primary key columns */
  GlobalFeatureControl_by_pk?: Maybe<GlobalFeatureControl>;
  /** fetch data from the table in a streaming manner: "GlobalFeatureControl" */
  GlobalFeatureControl_stream: Array<GlobalFeatureControl>;
  /** fetch data from the table: "Goal" */
  Goal: Array<Goal>;
  /** fetch aggregated fields from the table: "Goal" */
  Goal_aggregate: Goal_Aggregate;
  /** fetch data from the table: "Goal" using primary key columns */
  Goal_by_pk?: Maybe<Goal>;
  /** fetch data from the table in a streaming manner: "Goal" */
  Goal_stream: Array<Goal>;
  /** fetch data from the table: "HabitDay" */
  HabitDay: Array<HabitDay>;
  /** fetch aggregated fields from the table: "HabitDay" */
  HabitDay_aggregate: HabitDay_Aggregate;
  /** fetch data from the table: "HabitDay" using primary key columns */
  HabitDay_by_pk?: Maybe<HabitDay>;
  /** fetch data from the table in a streaming manner: "HabitDay" */
  HabitDay_stream: Array<HabitDay>;
  /** An array relationship */
  HeartRateDiagnostics: Array<HeartRateDiagnostics>;
  /** An aggregate relationship */
  HeartRateDiagnostics_aggregate: HeartRateDiagnostics_Aggregate;
  /** fetch data from the table: "HeartRateDiagnostics" using primary key columns */
  HeartRateDiagnostics_by_pk?: Maybe<HeartRateDiagnostics>;
  /** fetch data from the table in a streaming manner: "HeartRateDiagnostics" */
  HeartRateDiagnostics_stream: Array<HeartRateDiagnostics>;
  /** fetch data from the table: "Household" */
  Household: Array<Household>;
  /** fetch aggregated fields from the table: "Household" */
  Household_aggregate: Household_Aggregate;
  /** fetch data from the table: "Household" using primary key columns */
  Household_by_pk?: Maybe<Household>;
  /** fetch data from the table in a streaming manner: "Household" */
  Household_stream: Array<Household>;
  /** fetch data from the table: "MarketingEmail" */
  MarketingEmail: Array<MarketingEmail>;
  /** fetch aggregated fields from the table: "MarketingEmail" */
  MarketingEmail_aggregate: MarketingEmail_Aggregate;
  /** fetch data from the table: "MarketingEmail" using primary key columns */
  MarketingEmail_by_pk?: Maybe<MarketingEmail>;
  /** fetch data from the table in a streaming manner: "MarketingEmail" */
  MarketingEmail_stream: Array<MarketingEmail>;
  /** fetch data from the table: "Mastery" */
  Mastery: Array<Mastery>;
  /** fetch aggregated fields from the table: "Mastery" */
  Mastery_aggregate: Mastery_Aggregate;
  /** fetch data from the table: "Mastery" using primary key columns */
  Mastery_by_pk?: Maybe<Mastery>;
  /** fetch data from the table in a streaming manner: "Mastery" */
  Mastery_stream: Array<Mastery>;
  /** fetch data from the table: "OnboardingInfo" */
  OnboardingInfo: Array<OnboardingInfo>;
  /** fetch aggregated fields from the table: "OnboardingInfo" */
  OnboardingInfo_aggregate: OnboardingInfo_Aggregate;
  /** fetch data from the table: "OnboardingInfo" using primary key columns */
  OnboardingInfo_by_pk?: Maybe<OnboardingInfo>;
  /** fetch data from the table in a streaming manner: "OnboardingInfo" */
  OnboardingInfo_stream: Array<OnboardingInfo>;
  /** fetch data from the table: "OnboardingVideos" */
  OnboardingVideos: Array<OnboardingVideos>;
  /** fetch aggregated fields from the table: "OnboardingVideos" */
  OnboardingVideos_aggregate: OnboardingVideos_Aggregate;
  /** fetch data from the table: "OnboardingVideos" using primary key columns */
  OnboardingVideos_by_pk?: Maybe<OnboardingVideos>;
  /** fetch data from the table in a streaming manner: "OnboardingVideos" */
  OnboardingVideos_stream: Array<OnboardingVideos>;
  /** fetch data from the table: "Order" */
  Order: Array<Order>;
  /** fetch data from the table: "OrderLineItem" */
  OrderLineItem: Array<OrderLineItem>;
  /** fetch aggregated fields from the table: "OrderLineItem" */
  OrderLineItem_aggregate: OrderLineItem_Aggregate;
  /** fetch data from the table: "OrderLineItem" using primary key columns */
  OrderLineItem_by_pk?: Maybe<OrderLineItem>;
  /** fetch data from the table in a streaming manner: "OrderLineItem" */
  OrderLineItem_stream: Array<OrderLineItem>;
  /** fetch aggregated fields from the table: "Order" */
  Order_aggregate: Order_Aggregate;
  /** fetch data from the table: "Order" using primary key columns */
  Order_by_pk?: Maybe<Order>;
  /** fetch data from the table in a streaming manner: "Order" */
  Order_stream: Array<Order>;
  /** fetch data from the table: "PaymentHistory" */
  PaymentHistory: Array<PaymentHistory>;
  /** fetch aggregated fields from the table: "PaymentHistory" */
  PaymentHistory_aggregate: PaymentHistory_Aggregate;
  /** fetch data from the table: "PaymentHistory" using primary key columns */
  PaymentHistory_by_pk?: Maybe<PaymentHistory>;
  /** fetch data from the table in a streaming manner: "PaymentHistory" */
  PaymentHistory_stream: Array<PaymentHistory>;
  /** fetch data from the table: "PlanDay" */
  PlanDay: Array<PlanDay>;
  /** fetch aggregated fields from the table: "PlanDay" */
  PlanDay_aggregate: PlanDay_Aggregate;
  /** fetch data from the table: "PlanDay" using primary key columns */
  PlanDay_by_pk?: Maybe<PlanDay>;
  /** fetch data from the table in a streaming manner: "PlanDay" */
  PlanDay_stream: Array<PlanDay>;
  /** fetch data from the table: "PresetDiet" */
  PresetDiet: Array<PresetDiet>;
  /** fetch aggregated fields from the table: "PresetDiet" */
  PresetDiet_aggregate: PresetDiet_Aggregate;
  /** fetch data from the table: "PresetDiet" using primary key columns */
  PresetDiet_by_pk?: Maybe<PresetDiet>;
  /** fetch data from the table in a streaming manner: "PresetDiet" */
  PresetDiet_stream: Array<PresetDiet>;
  /** fetch data from the table: "Pro" */
  Pro: Array<Pro>;
  /** fetch data from the table: "ProMusic" */
  ProMusic: Array<ProMusic>;
  /** fetch aggregated fields from the table: "ProMusic" */
  ProMusic_aggregate: ProMusic_Aggregate;
  /** fetch data from the table: "ProMusic" using primary key columns */
  ProMusic_by_pk?: Maybe<ProMusic>;
  /** fetch data from the table in a streaming manner: "ProMusic" */
  ProMusic_stream: Array<ProMusic>;
  /** fetch aggregated fields from the table: "Pro" */
  Pro_aggregate: Pro_Aggregate;
  /** fetch data from the table: "Pro" using primary key columns */
  Pro_by_pk?: Maybe<Pro>;
  /** fetch data from the table in a streaming manner: "Pro" */
  Pro_stream: Array<Pro>;
  /** fetch data from the table: "Product" */
  Product: Array<Product>;
  /** fetch data from the table: "ProductLog" */
  ProductLog: Array<ProductLog>;
  /** fetch aggregated fields from the table: "ProductLog" */
  ProductLog_aggregate: ProductLog_Aggregate;
  /** fetch data from the table: "ProductLog" using primary key columns */
  ProductLog_by_pk?: Maybe<ProductLog>;
  /** fetch data from the table in a streaming manner: "ProductLog" */
  ProductLog_stream: Array<ProductLog>;
  /** fetch aggregated fields from the table: "Product" */
  Product_aggregate: Product_Aggregate;
  /** fetch data from the table: "Product" using primary key columns */
  Product_by_pk?: Maybe<Product>;
  /** fetch data from the table in a streaming manner: "Product" */
  Product_stream: Array<Product>;
  /** fetch data from the table: "PromotionalItem" */
  PromotionalItem: Array<PromotionalItem>;
  /** fetch aggregated fields from the table: "PromotionalItem" */
  PromotionalItem_aggregate: PromotionalItem_Aggregate;
  /** fetch data from the table: "PromotionalItem" using primary key columns */
  PromotionalItem_by_pk?: Maybe<PromotionalItem>;
  /** fetch data from the table in a streaming manner: "PromotionalItem" */
  PromotionalItem_stream: Array<PromotionalItem>;
  /** fetch data from the table: "QuizSurvey" */
  QuizSurvey: Array<QuizSurvey>;
  /** fetch aggregated fields from the table: "QuizSurvey" */
  QuizSurvey_aggregate: QuizSurvey_Aggregate;
  /** fetch data from the table: "QuizSurvey" using primary key columns */
  QuizSurvey_by_pk?: Maybe<QuizSurvey>;
  /** fetch data from the table in a streaming manner: "QuizSurvey" */
  QuizSurvey_stream: Array<QuizSurvey>;
  /** fetch data from the table: "Quote" */
  Quote: Array<Quote>;
  /** fetch aggregated fields from the table: "Quote" */
  Quote_aggregate: Quote_Aggregate;
  /** fetch data from the table: "Quote" using primary key columns */
  Quote_by_pk?: Maybe<Quote>;
  /** fetch data from the table in a streaming manner: "Quote" */
  Quote_stream: Array<Quote>;
  /** fetch data from the table: "ReimbursementRequirement" */
  ReimbursementRequirement: Array<ReimbursementRequirement>;
  /** fetch aggregated fields from the table: "ReimbursementRequirement" */
  ReimbursementRequirement_aggregate: ReimbursementRequirement_Aggregate;
  /** fetch data from the table: "ReimbursementRequirement" using primary key columns */
  ReimbursementRequirement_by_pk?: Maybe<ReimbursementRequirement>;
  /** fetch data from the table in a streaming manner: "ReimbursementRequirement" */
  ReimbursementRequirement_stream: Array<ReimbursementRequirement>;
  /** fetch data from the table: "ScreenOrder" */
  ScreenOrder: Array<ScreenOrder>;
  /** fetch aggregated fields from the table: "ScreenOrder" */
  ScreenOrder_aggregate: ScreenOrder_Aggregate;
  /** fetch data from the table: "ScreenOrder" using primary key columns */
  ScreenOrder_by_pk?: Maybe<ScreenOrder>;
  /** fetch data from the table in a streaming manner: "ScreenOrder" */
  ScreenOrder_stream: Array<ScreenOrder>;
  /** fetch data from the table: "SearchResult" */
  SearchResult: Array<SearchResult>;
  /** fetch aggregated fields from the table: "SearchResult" */
  SearchResult_aggregate: SearchResult_Aggregate;
  /** fetch data from the table: "SearchResult" using primary key columns */
  SearchResult_by_pk?: Maybe<SearchResult>;
  /** fetch data from the table in a streaming manner: "SearchResult" */
  SearchResult_stream: Array<SearchResult>;
  /** fetch data from the table: "SleepSurvey" */
  SleepSurvey: Array<SleepSurvey>;
  /** fetch aggregated fields from the table: "SleepSurvey" */
  SleepSurvey_aggregate: SleepSurvey_Aggregate;
  /** fetch data from the table: "SleepSurvey" using primary key columns */
  SleepSurvey_by_pk?: Maybe<SleepSurvey>;
  /** fetch data from the table in a streaming manner: "SleepSurvey" */
  SleepSurvey_stream: Array<SleepSurvey>;
  /** fetch data from the table: "Subscription" */
  Subscription: Array<Subscription>;
  /** fetch data from the table: "SubscriptionLog" */
  SubscriptionLog: Array<SubscriptionLog>;
  /** fetch aggregated fields from the table: "SubscriptionLog" */
  SubscriptionLog_aggregate: SubscriptionLog_Aggregate;
  /** fetch data from the table: "SubscriptionLog" using primary key columns */
  SubscriptionLog_by_pk?: Maybe<SubscriptionLog>;
  /** fetch data from the table in a streaming manner: "SubscriptionLog" */
  SubscriptionLog_stream: Array<SubscriptionLog>;
  /** fetch aggregated fields from the table: "Subscription" */
  Subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "Subscription" using primary key columns */
  Subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table in a streaming manner: "Subscription" */
  Subscription_stream: Array<Subscription>;
  /** fetch data from the table: "Token" */
  Token: Array<Token>;
  /** fetch data from the table: "TokenHistory" */
  TokenHistory: Array<TokenHistory>;
  /** fetch aggregated fields from the table: "TokenHistory" */
  TokenHistory_aggregate: TokenHistory_Aggregate;
  /** fetch data from the table: "TokenHistory" using primary key columns */
  TokenHistory_by_pk?: Maybe<TokenHistory>;
  /** fetch data from the table in a streaming manner: "TokenHistory" */
  TokenHistory_stream: Array<TokenHistory>;
  /** fetch aggregated fields from the table: "Token" */
  Token_aggregate: Token_Aggregate;
  /** fetch data from the table: "Token" using primary key columns */
  Token_by_pk?: Maybe<Token>;
  /** fetch data from the table in a streaming manner: "Token" */
  Token_stream: Array<Token>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** An array relationship */
  UserAggregates: Array<UserAggregates>;
  /** An aggregate relationship */
  UserAggregates_aggregate: UserAggregates_Aggregate;
  /** fetch data from the table: "UserAggregates" using primary key columns */
  UserAggregates_by_pk?: Maybe<UserAggregates>;
  /** fetch data from the table in a streaming manner: "UserAggregates" */
  UserAggregates_stream: Array<UserAggregates>;
  /** fetch data from the table: "UserAssessment" */
  UserAssessment: Array<UserAssessment>;
  /** fetch aggregated fields from the table: "UserAssessment" */
  UserAssessment_aggregate: UserAssessment_Aggregate;
  /** fetch data from the table: "UserAssessment" using primary key columns */
  UserAssessment_by_pk?: Maybe<UserAssessment>;
  /** fetch data from the table in a streaming manner: "UserAssessment" */
  UserAssessment_stream: Array<UserAssessment>;
  /** fetch data from the table: "UserFeatureControl" */
  UserFeatureControl: Array<UserFeatureControl>;
  /** fetch aggregated fields from the table: "UserFeatureControl" */
  UserFeatureControl_aggregate: UserFeatureControl_Aggregate;
  /** fetch data from the table: "UserFeatureControl" using primary key columns */
  UserFeatureControl_by_pk?: Maybe<UserFeatureControl>;
  /** fetch data from the table in a streaming manner: "UserFeatureControl" */
  UserFeatureControl_stream: Array<UserFeatureControl>;
  /** fetch data from the table: "UserFeedback" */
  UserFeedback: Array<UserFeedback>;
  /** fetch aggregated fields from the table: "UserFeedback" */
  UserFeedback_aggregate: UserFeedback_Aggregate;
  /** fetch data from the table: "UserFeedback" using primary key columns */
  UserFeedback_by_pk?: Maybe<UserFeedback>;
  /** fetch data from the table in a streaming manner: "UserFeedback" */
  UserFeedback_stream: Array<UserFeedback>;
  /** fetch data from the table: "UserFinancePlan" */
  UserFinancePlan: Array<UserFinancePlan>;
  /** fetch aggregated fields from the table: "UserFinancePlan" */
  UserFinancePlan_aggregate: UserFinancePlan_Aggregate;
  /** fetch data from the table: "UserFinancePlan" using primary key columns */
  UserFinancePlan_by_pk?: Maybe<UserFinancePlan>;
  /** fetch data from the table in a streaming manner: "UserFinancePlan" */
  UserFinancePlan_stream: Array<UserFinancePlan>;
  /** fetch data from the table: "UserHabit" */
  UserHabit: Array<UserHabit>;
  /** fetch aggregated fields from the table: "UserHabit" */
  UserHabit_aggregate: UserHabit_Aggregate;
  /** fetch data from the table: "UserHabit" using primary key columns */
  UserHabit_by_pk?: Maybe<UserHabit>;
  /** fetch data from the table in a streaming manner: "UserHabit" */
  UserHabit_stream: Array<UserHabit>;
  /** fetch data from the table: "UserMovementDaily" */
  UserMovementDaily: Array<UserMovementDaily>;
  /** fetch aggregated fields from the table: "UserMovementDaily" */
  UserMovementDaily_aggregate: UserMovementDaily_Aggregate;
  /** fetch data from the table: "UserMovementDaily" using primary key columns */
  UserMovementDaily_by_pk?: Maybe<UserMovementDaily>;
  /** fetch data from the table in a streaming manner: "UserMovementDaily" */
  UserMovementDaily_stream: Array<UserMovementDaily>;
  /** An array relationship */
  UserPreferences: Array<UserPreferences>;
  /** An aggregate relationship */
  UserPreferences_aggregate: UserPreferences_Aggregate;
  /** fetch data from the table: "UserPreferences" using primary key columns */
  UserPreferences_by_pk?: Maybe<UserPreferences>;
  /** fetch data from the table in a streaming manner: "UserPreferences" */
  UserPreferences_stream: Array<UserPreferences>;
  /** fetch data from the table: "UserProduct" */
  UserProduct: Array<UserProduct>;
  /** fetch data from the table: "UserProductHistory" */
  UserProductHistory: Array<UserProductHistory>;
  /** fetch aggregated fields from the table: "UserProductHistory" */
  UserProductHistory_aggregate: UserProductHistory_Aggregate;
  /** fetch data from the table: "UserProductHistory" using primary key columns */
  UserProductHistory_by_pk?: Maybe<UserProductHistory>;
  /** fetch data from the table in a streaming manner: "UserProductHistory" */
  UserProductHistory_stream: Array<UserProductHistory>;
  /** fetch aggregated fields from the table: "UserProduct" */
  UserProduct_aggregate: UserProduct_Aggregate;
  /** fetch data from the table: "UserProduct" using primary key columns */
  UserProduct_by_pk?: Maybe<UserProduct>;
  /** fetch data from the table in a streaming manner: "UserProduct" */
  UserProduct_stream: Array<UserProduct>;
  /** fetch data from the table: "UserReadiness" */
  UserReadiness: Array<UserReadiness>;
  /** fetch data from the table: "UserReadinessLog" */
  UserReadinessLog: Array<UserReadinessLog>;
  /** fetch aggregated fields from the table: "UserReadinessLog" */
  UserReadinessLog_aggregate: UserReadinessLog_Aggregate;
  /** fetch data from the table: "UserReadinessLog" using primary key columns */
  UserReadinessLog_by_pk?: Maybe<UserReadinessLog>;
  /** fetch data from the table in a streaming manner: "UserReadinessLog" */
  UserReadinessLog_stream: Array<UserReadinessLog>;
  /** fetch aggregated fields from the table: "UserReadiness" */
  UserReadiness_aggregate: UserReadiness_Aggregate;
  /** fetch data from the table: "UserReadiness" using primary key columns */
  UserReadiness_by_pk?: Maybe<UserReadiness>;
  /** fetch data from the table in a streaming manner: "UserReadiness" */
  UserReadiness_stream: Array<UserReadiness>;
  /** fetch data from the table: "UserReimbursmentHistory" */
  UserReimbursmentHistory: Array<UserReimbursmentHistory>;
  /** fetch aggregated fields from the table: "UserReimbursmentHistory" */
  UserReimbursmentHistory_aggregate: UserReimbursmentHistory_Aggregate;
  /** fetch data from the table: "UserReimbursmentHistory" using primary key columns */
  UserReimbursmentHistory_by_pk?: Maybe<UserReimbursmentHistory>;
  /** fetch data from the table in a streaming manner: "UserReimbursmentHistory" */
  UserReimbursmentHistory_stream: Array<UserReimbursmentHistory>;
  /** fetch data from the table: "UserRep" */
  UserRep: Array<UserRep>;
  /** fetch aggregated fields from the table: "UserRep" */
  UserRep_aggregate: UserRep_Aggregate;
  /** fetch data from the table: "UserRep" using primary key columns */
  UserRep_by_pk?: Maybe<UserRep>;
  /** fetch data from the table in a streaming manner: "UserRep" */
  UserRep_stream: Array<UserRep>;
  /** fetch data from the table: "UserSet" */
  UserSet: Array<UserSet>;
  /** fetch aggregated fields from the table: "UserSet" */
  UserSet_aggregate: UserSet_Aggregate;
  /** fetch data from the table: "UserSet" using primary key columns */
  UserSet_by_pk?: Maybe<UserSet>;
  /** fetch data from the table in a streaming manner: "UserSet" */
  UserSet_stream: Array<UserSet>;
  /** fetch data from the table: "UserState" */
  UserState: Array<UserState>;
  /** fetch aggregated fields from the table: "UserState" */
  UserState_aggregate: UserState_Aggregate;
  /** fetch data from the table: "UserState" using primary key columns */
  UserState_by_pk?: Maybe<UserState>;
  /** fetch data from the table in a streaming manner: "UserState" */
  UserState_stream: Array<UserState>;
  /** fetch data from the table: "UserTrainingPlan" */
  UserTrainingPlan: Array<UserTrainingPlan>;
  /** fetch aggregated fields from the table: "UserTrainingPlan" */
  UserTrainingPlan_aggregate: UserTrainingPlan_Aggregate;
  /** fetch data from the table: "UserTrainingPlan" using primary key columns */
  UserTrainingPlan_by_pk?: Maybe<UserTrainingPlan>;
  /** fetch data from the table in a streaming manner: "UserTrainingPlan" */
  UserTrainingPlan_stream: Array<UserTrainingPlan>;
  /** fetch data from the table: "UserWorkout" */
  UserWorkout: Array<UserWorkout>;
  /** fetch aggregated fields from the table: "UserWorkout" */
  UserWorkout_aggregate: UserWorkout_Aggregate;
  /** fetch data from the table: "UserWorkout" using primary key columns */
  UserWorkout_by_pk?: Maybe<UserWorkout>;
  /** fetch data from the table in a streaming manner: "UserWorkout" */
  UserWorkout_stream: Array<UserWorkout>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "User" */
  User_stream: Array<User>;
  /** fetch data from the table: "VendorErrorLog" */
  VendorErrorLog: Array<VendorErrorLog>;
  /** fetch aggregated fields from the table: "VendorErrorLog" */
  VendorErrorLog_aggregate: VendorErrorLog_Aggregate;
  /** fetch data from the table: "VendorErrorLog" using primary key columns */
  VendorErrorLog_by_pk?: Maybe<VendorErrorLog>;
  /** fetch data from the table in a streaming manner: "VendorErrorLog" */
  VendorErrorLog_stream: Array<VendorErrorLog>;
  /** fetch data from the table: "VersionControl" */
  VersionControl: Array<VersionControl>;
  /** fetch aggregated fields from the table: "VersionControl" */
  VersionControl_aggregate: VersionControl_Aggregate;
  /** fetch data from the table: "VersionControl" using primary key columns */
  VersionControl_by_pk?: Maybe<VersionControl>;
  /** fetch data from the table in a streaming manner: "VersionControl" */
  VersionControl_stream: Array<VersionControl>;
  /** fetch data from the table: "WeeklyPlan" */
  WeeklyPlan: Array<WeeklyPlan>;
  /** fetch aggregated fields from the table: "WeeklyPlan" */
  WeeklyPlan_aggregate: WeeklyPlan_Aggregate;
  /** fetch data from the table: "WeeklyPlan" using primary key columns */
  WeeklyPlan_by_pk?: Maybe<WeeklyPlan>;
  /** fetch data from the table in a streaming manner: "WeeklyPlan" */
  WeeklyPlan_stream: Array<WeeklyPlan>;
  /** fetch data from the table: "WorkinPrefrence" */
  WorkinPrefrence: Array<WorkinPrefrence>;
  /** fetch aggregated fields from the table: "WorkinPrefrence" */
  WorkinPrefrence_aggregate: WorkinPrefrence_Aggregate;
  /** fetch data from the table: "WorkinPrefrence" using primary key columns */
  WorkinPrefrence_by_pk?: Maybe<WorkinPrefrence>;
  /** fetch data from the table in a streaming manner: "WorkinPrefrence" */
  WorkinPrefrence_stream: Array<WorkinPrefrence>;
  /** fetch data from the table: "Workout" */
  Workout: Array<Workout>;
  /** fetch data from the table: "WorkoutPrefrence" */
  WorkoutPrefrence: Array<WorkoutPrefrence>;
  /** fetch aggregated fields from the table: "WorkoutPrefrence" */
  WorkoutPrefrence_aggregate: WorkoutPrefrence_Aggregate;
  /** fetch data from the table: "WorkoutPrefrence" using primary key columns */
  WorkoutPrefrence_by_pk?: Maybe<WorkoutPrefrence>;
  /** fetch data from the table in a streaming manner: "WorkoutPrefrence" */
  WorkoutPrefrence_stream: Array<WorkoutPrefrence>;
  /** fetch aggregated fields from the table: "Workout" */
  Workout_aggregate: Workout_Aggregate;
  /** fetch data from the table: "Workout" using primary key columns */
  Workout_by_pk?: Maybe<Workout>;
  /** fetch data from the table in a streaming manner: "Workout" */
  Workout_stream: Array<Workout>;
  /** fetch data from the table: "_FollowingPro" */
  _FollowingPro: Array<_FollowingPro>;
  /** fetch aggregated fields from the table: "_FollowingPro" */
  _FollowingPro_aggregate: _FollowingPro_Aggregate;
  /** fetch data from the table in a streaming manner: "_FollowingPro" */
  _FollowingPro_stream: Array<_FollowingPro>;
  /** An array relationship */
  _GoalToUserPreferences: Array<_GoalToUserPreferences>;
  /** An aggregate relationship */
  _GoalToUserPreferences_aggregate: _GoalToUserPreferences_Aggregate;
  /** fetch data from the table in a streaming manner: "_GoalToUserPreferences" */
  _GoalToUserPreferences_stream: Array<_GoalToUserPreferences>;
};


export type Subscription_RootActivationCodeArgs = {
  distinct_on?: InputMaybe<Array<ActivationCode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActivationCode_Order_By>>;
  where?: InputMaybe<ActivationCode_Bool_Exp>;
};


export type Subscription_RootActivationCode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivationCode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActivationCode_Order_By>>;
  where?: InputMaybe<ActivationCode_Bool_Exp>;
};


export type Subscription_RootActivationCode_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootActivationCode_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActivationCode_Stream_Cursor_Input>>;
  where?: InputMaybe<ActivationCode_Bool_Exp>;
};


export type Subscription_RootAlterTypeColorArgs = {
  distinct_on?: InputMaybe<Array<AlterTypeColor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlterTypeColor_Order_By>>;
  where?: InputMaybe<AlterTypeColor_Bool_Exp>;
};


export type Subscription_RootAlterTypeColor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlterTypeColor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlterTypeColor_Order_By>>;
  where?: InputMaybe<AlterTypeColor_Bool_Exp>;
};


export type Subscription_RootAlterTypeColor_By_PkArgs = {
  letter: Scalars['String']['input'];
};


export type Subscription_RootAlterTypeColor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AlterTypeColor_Stream_Cursor_Input>>;
  where?: InputMaybe<AlterTypeColor_Bool_Exp>;
};


export type Subscription_RootCoachArgs = {
  distinct_on?: InputMaybe<Array<Coach_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coach_Order_By>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


export type Subscription_RootCoach_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coach_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Coach_Order_By>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


export type Subscription_RootCoach_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootCoach_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Coach_Stream_Cursor_Input>>;
  where?: InputMaybe<Coach_Bool_Exp>;
};


export type Subscription_RootCoachedHabitArgs = {
  distinct_on?: InputMaybe<Array<CoachedHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedHabit_Order_By>>;
  where?: InputMaybe<CoachedHabit_Bool_Exp>;
};


export type Subscription_RootCoachedHabit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedHabit_Order_By>>;
  where?: InputMaybe<CoachedHabit_Bool_Exp>;
};


export type Subscription_RootCoachedHabit_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootCoachedHabit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CoachedHabit_Stream_Cursor_Input>>;
  where?: InputMaybe<CoachedHabit_Bool_Exp>;
};


export type Subscription_RootCoachedNutritionArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


export type Subscription_RootCoachedNutrition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CoachedNutrition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CoachedNutrition_Order_By>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


export type Subscription_RootCoachedNutrition_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootCoachedNutrition_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CoachedNutrition_Stream_Cursor_Input>>;
  where?: InputMaybe<CoachedNutrition_Bool_Exp>;
};


export type Subscription_RootDnaKitArgs = {
  distinct_on?: InputMaybe<Array<DnaKit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaKit_Order_By>>;
  where?: InputMaybe<DnaKit_Bool_Exp>;
};


export type Subscription_RootDnaKit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DnaKit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaKit_Order_By>>;
  where?: InputMaybe<DnaKit_Bool_Exp>;
};


export type Subscription_RootDnaKit_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootDnaKit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DnaKit_Stream_Cursor_Input>>;
  where?: InputMaybe<DnaKit_Bool_Exp>;
};


export type Subscription_RootDnaResultsArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


export type Subscription_RootDnaResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DnaResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DnaResults_Order_By>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


export type Subscription_RootDnaResults_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootDnaResults_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DnaResults_Stream_Cursor_Input>>;
  where?: InputMaybe<DnaResults_Bool_Exp>;
};


export type Subscription_RootDeliveryArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Order_By>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


export type Subscription_RootDeliveryHistoryArgs = {
  distinct_on?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryHistory_Order_By>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};


export type Subscription_RootDeliveryHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryHistory_Order_By>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};


export type Subscription_RootDeliveryHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootDeliveryHistory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DeliveryHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<DeliveryHistory_Bool_Exp>;
};


export type Subscription_RootDelivery_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delivery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delivery_Order_By>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


export type Subscription_RootDelivery_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootDelivery_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Delivery_Stream_Cursor_Input>>;
  where?: InputMaybe<Delivery_Bool_Exp>;
};


export type Subscription_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Subscription_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Subscription_RootDevice_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootDevice_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Device_Stream_Cursor_Input>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Subscription_RootDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootDiagnostics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Diagnostics_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootFinancePlanArgs = {
  distinct_on?: InputMaybe<Array<FinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FinancePlan_Order_By>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


export type Subscription_RootFinancePlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FinancePlan_Order_By>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


export type Subscription_RootFinancePlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootFinancePlan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FinancePlan_Stream_Cursor_Input>>;
  where?: InputMaybe<FinancePlan_Bool_Exp>;
};


export type Subscription_RootFirmwareContentArgs = {
  distinct_on?: InputMaybe<Array<FirmwareContent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FirmwareContent_Order_By>>;
  where?: InputMaybe<FirmwareContent_Bool_Exp>;
};


export type Subscription_RootFirmwareContent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FirmwareContent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FirmwareContent_Order_By>>;
  where?: InputMaybe<FirmwareContent_Bool_Exp>;
};


export type Subscription_RootFirmwareContent_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootFirmwareContent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FirmwareContent_Stream_Cursor_Input>>;
  where?: InputMaybe<FirmwareContent_Bool_Exp>;
};


export type Subscription_RootGeneticDirectionWebhookArgs = {
  distinct_on?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeneticDirectionWebhook_Order_By>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


export type Subscription_RootGeneticDirectionWebhook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneticDirectionWebhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeneticDirectionWebhook_Order_By>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


export type Subscription_RootGeneticDirectionWebhook_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootGeneticDirectionWebhook_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GeneticDirectionWebhook_Stream_Cursor_Input>>;
  where?: InputMaybe<GeneticDirectionWebhook_Bool_Exp>;
};


export type Subscription_RootGlobalFeatureControlArgs = {
  distinct_on?: InputMaybe<Array<GlobalFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GlobalFeatureControl_Order_By>>;
  where?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
};


export type Subscription_RootGlobalFeatureControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GlobalFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GlobalFeatureControl_Order_By>>;
  where?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
};


export type Subscription_RootGlobalFeatureControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootGlobalFeatureControl_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GlobalFeatureControl_Stream_Cursor_Input>>;
  where?: InputMaybe<GlobalFeatureControl_Bool_Exp>;
};


export type Subscription_RootGoalArgs = {
  distinct_on?: InputMaybe<Array<Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goal_Order_By>>;
  where?: InputMaybe<Goal_Bool_Exp>;
};


export type Subscription_RootGoal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goal_Order_By>>;
  where?: InputMaybe<Goal_Bool_Exp>;
};


export type Subscription_RootGoal_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootGoal_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Goal_Stream_Cursor_Input>>;
  where?: InputMaybe<Goal_Bool_Exp>;
};


export type Subscription_RootHabitDayArgs = {
  distinct_on?: InputMaybe<Array<HabitDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HabitDay_Order_By>>;
  where?: InputMaybe<HabitDay_Bool_Exp>;
};


export type Subscription_RootHabitDay_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HabitDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HabitDay_Order_By>>;
  where?: InputMaybe<HabitDay_Bool_Exp>;
};


export type Subscription_RootHabitDay_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootHabitDay_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<HabitDay_Stream_Cursor_Input>>;
  where?: InputMaybe<HabitDay_Bool_Exp>;
};


export type Subscription_RootHeartRateDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


export type Subscription_RootHeartRateDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeartRateDiagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeartRateDiagnostics_Order_By>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


export type Subscription_RootHeartRateDiagnostics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootHeartRateDiagnostics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<HeartRateDiagnostics_Stream_Cursor_Input>>;
  where?: InputMaybe<HeartRateDiagnostics_Bool_Exp>;
};


export type Subscription_RootHouseholdArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


export type Subscription_RootHousehold_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Household_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Household_Order_By>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


export type Subscription_RootHousehold_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootHousehold_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Household_Stream_Cursor_Input>>;
  where?: InputMaybe<Household_Bool_Exp>;
};


export type Subscription_RootMarketingEmailArgs = {
  distinct_on?: InputMaybe<Array<MarketingEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MarketingEmail_Order_By>>;
  where?: InputMaybe<MarketingEmail_Bool_Exp>;
};


export type Subscription_RootMarketingEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MarketingEmail_Order_By>>;
  where?: InputMaybe<MarketingEmail_Bool_Exp>;
};


export type Subscription_RootMarketingEmail_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMarketingEmail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MarketingEmail_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketingEmail_Bool_Exp>;
};


export type Subscription_RootMasteryArgs = {
  distinct_on?: InputMaybe<Array<Mastery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mastery_Order_By>>;
  where?: InputMaybe<Mastery_Bool_Exp>;
};


export type Subscription_RootMastery_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mastery_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mastery_Order_By>>;
  where?: InputMaybe<Mastery_Bool_Exp>;
};


export type Subscription_RootMastery_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMastery_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mastery_Stream_Cursor_Input>>;
  where?: InputMaybe<Mastery_Bool_Exp>;
};


export type Subscription_RootOnboardingInfoArgs = {
  distinct_on?: InputMaybe<Array<OnboardingInfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingInfo_Order_By>>;
  where?: InputMaybe<OnboardingInfo_Bool_Exp>;
};


export type Subscription_RootOnboardingInfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OnboardingInfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingInfo_Order_By>>;
  where?: InputMaybe<OnboardingInfo_Bool_Exp>;
};


export type Subscription_RootOnboardingInfo_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOnboardingInfo_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OnboardingInfo_Stream_Cursor_Input>>;
  where?: InputMaybe<OnboardingInfo_Bool_Exp>;
};


export type Subscription_RootOnboardingVideosArgs = {
  distinct_on?: InputMaybe<Array<OnboardingVideos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingVideos_Order_By>>;
  where?: InputMaybe<OnboardingVideos_Bool_Exp>;
};


export type Subscription_RootOnboardingVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OnboardingVideos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OnboardingVideos_Order_By>>;
  where?: InputMaybe<OnboardingVideos_Bool_Exp>;
};


export type Subscription_RootOnboardingVideos_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOnboardingVideos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OnboardingVideos_Stream_Cursor_Input>>;
  where?: InputMaybe<OnboardingVideos_Bool_Exp>;
};


export type Subscription_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrderLineItemArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


export type Subscription_RootOrderLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrderLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrderLineItem_Order_By>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


export type Subscription_RootOrderLineItem_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOrderLineItem_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrderLineItem_Stream_Cursor_Input>>;
  where?: InputMaybe<OrderLineItem_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOrder_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootPaymentHistoryArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


export type Subscription_RootPaymentHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PaymentHistory_Order_By>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


export type Subscription_RootPaymentHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPaymentHistory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<PaymentHistory_Bool_Exp>;
};


export type Subscription_RootPlanDayArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


export type Subscription_RootPlanDay_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanDay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PlanDay_Order_By>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


export type Subscription_RootPlanDay_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPlanDay_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PlanDay_Stream_Cursor_Input>>;
  where?: InputMaybe<PlanDay_Bool_Exp>;
};


export type Subscription_RootPresetDietArgs = {
  distinct_on?: InputMaybe<Array<PresetDiet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PresetDiet_Order_By>>;
  where?: InputMaybe<PresetDiet_Bool_Exp>;
};


export type Subscription_RootPresetDiet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PresetDiet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PresetDiet_Order_By>>;
  where?: InputMaybe<PresetDiet_Bool_Exp>;
};


export type Subscription_RootPresetDiet_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPresetDiet_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PresetDiet_Stream_Cursor_Input>>;
  where?: InputMaybe<PresetDiet_Bool_Exp>;
};


export type Subscription_RootProArgs = {
  distinct_on?: InputMaybe<Array<Pro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pro_Order_By>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


export type Subscription_RootProMusicArgs = {
  distinct_on?: InputMaybe<Array<ProMusic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProMusic_Order_By>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


export type Subscription_RootProMusic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProMusic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProMusic_Order_By>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


export type Subscription_RootProMusic_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootProMusic_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ProMusic_Stream_Cursor_Input>>;
  where?: InputMaybe<ProMusic_Bool_Exp>;
};


export type Subscription_RootPro_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pro_Order_By>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


export type Subscription_RootPro_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPro_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Pro_Stream_Cursor_Input>>;
  where?: InputMaybe<Pro_Bool_Exp>;
};


export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProductLogArgs = {
  distinct_on?: InputMaybe<Array<ProductLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductLog_Order_By>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};


export type Subscription_RootProductLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductLog_Order_By>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};


export type Subscription_RootProductLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootProductLog_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ProductLog_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductLog_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootPromotionalItemArgs = {
  distinct_on?: InputMaybe<Array<PromotionalItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PromotionalItem_Order_By>>;
  where?: InputMaybe<PromotionalItem_Bool_Exp>;
};


export type Subscription_RootPromotionalItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionalItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PromotionalItem_Order_By>>;
  where?: InputMaybe<PromotionalItem_Bool_Exp>;
};


export type Subscription_RootPromotionalItem_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPromotionalItem_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PromotionalItem_Stream_Cursor_Input>>;
  where?: InputMaybe<PromotionalItem_Bool_Exp>;
};


export type Subscription_RootQuizSurveyArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


export type Subscription_RootQuizSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuizSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuizSurvey_Order_By>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


export type Subscription_RootQuizSurvey_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootQuizSurvey_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuizSurvey_Stream_Cursor_Input>>;
  where?: InputMaybe<QuizSurvey_Bool_Exp>;
};


export type Subscription_RootQuoteArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Subscription_RootQuote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Subscription_RootQuote_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootQuote_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Quote_Stream_Cursor_Input>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Subscription_RootReimbursementRequirementArgs = {
  distinct_on?: InputMaybe<Array<ReimbursementRequirement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReimbursementRequirement_Order_By>>;
  where?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
};


export type Subscription_RootReimbursementRequirement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReimbursementRequirement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReimbursementRequirement_Order_By>>;
  where?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
};


export type Subscription_RootReimbursementRequirement_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootReimbursementRequirement_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReimbursementRequirement_Stream_Cursor_Input>>;
  where?: InputMaybe<ReimbursementRequirement_Bool_Exp>;
};


export type Subscription_RootScreenOrderArgs = {
  distinct_on?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ScreenOrder_Order_By>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


export type Subscription_RootScreenOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ScreenOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ScreenOrder_Order_By>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


export type Subscription_RootScreenOrder_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootScreenOrder_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ScreenOrder_Stream_Cursor_Input>>;
  where?: InputMaybe<ScreenOrder_Bool_Exp>;
};


export type Subscription_RootSearchResultArgs = {
  distinct_on?: InputMaybe<Array<SearchResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SearchResult_Order_By>>;
  where?: InputMaybe<SearchResult_Bool_Exp>;
};


export type Subscription_RootSearchResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SearchResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SearchResult_Order_By>>;
  where?: InputMaybe<SearchResult_Bool_Exp>;
};


export type Subscription_RootSearchResult_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSearchResult_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SearchResult_Stream_Cursor_Input>>;
  where?: InputMaybe<SearchResult_Bool_Exp>;
};


export type Subscription_RootSleepSurveyArgs = {
  distinct_on?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SleepSurvey_Order_By>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


export type Subscription_RootSleepSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SleepSurvey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SleepSurvey_Order_By>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


export type Subscription_RootSleepSurvey_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSleepSurvey_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SleepSurvey_Stream_Cursor_Input>>;
  where?: InputMaybe<SleepSurvey_Bool_Exp>;
};


export type Subscription_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscriptionLogArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubscriptionLog_Order_By>>;
  where?: InputMaybe<SubscriptionLog_Bool_Exp>;
};


export type Subscription_RootSubscriptionLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubscriptionLog_Order_By>>;
  where?: InputMaybe<SubscriptionLog_Bool_Exp>;
};


export type Subscription_RootSubscriptionLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSubscriptionLog_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SubscriptionLog_Stream_Cursor_Input>>;
  where?: InputMaybe<SubscriptionLog_Bool_Exp>;
};


export type Subscription_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSubscription_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootTokenArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Subscription_RootTokenHistoryArgs = {
  distinct_on?: InputMaybe<Array<TokenHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TokenHistory_Order_By>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


export type Subscription_RootTokenHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TokenHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TokenHistory_Order_By>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


export type Subscription_RootTokenHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTokenHistory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TokenHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<TokenHistory_Bool_Exp>;
};


export type Subscription_RootToken_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Token_Order_By>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Subscription_RootToken_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootToken_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Token_Stream_Cursor_Input>>;
  where?: InputMaybe<Token_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUserAggregatesArgs = {
  distinct_on?: InputMaybe<Array<UserAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAggregates_Order_By>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


export type Subscription_RootUserAggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAggregates_Order_By>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


export type Subscription_RootUserAggregates_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserAggregates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserAggregates_Stream_Cursor_Input>>;
  where?: InputMaybe<UserAggregates_Bool_Exp>;
};


export type Subscription_RootUserAssessmentArgs = {
  distinct_on?: InputMaybe<Array<UserAssessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAssessment_Order_By>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


export type Subscription_RootUserAssessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAssessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserAssessment_Order_By>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


export type Subscription_RootUserAssessment_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserAssessment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserAssessment_Stream_Cursor_Input>>;
  where?: InputMaybe<UserAssessment_Bool_Exp>;
};


export type Subscription_RootUserFeatureControlArgs = {
  distinct_on?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeatureControl_Order_By>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


export type Subscription_RootUserFeatureControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeatureControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeatureControl_Order_By>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


export type Subscription_RootUserFeatureControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserFeatureControl_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserFeatureControl_Stream_Cursor_Input>>;
  where?: InputMaybe<UserFeatureControl_Bool_Exp>;
};


export type Subscription_RootUserFeedbackArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


export type Subscription_RootUserFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFeedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFeedback_Order_By>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


export type Subscription_RootUserFeedback_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserFeedback_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserFeedback_Stream_Cursor_Input>>;
  where?: InputMaybe<UserFeedback_Bool_Exp>;
};


export type Subscription_RootUserFinancePlanArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


export type Subscription_RootUserFinancePlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFinancePlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFinancePlan_Order_By>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


export type Subscription_RootUserFinancePlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserFinancePlan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserFinancePlan_Stream_Cursor_Input>>;
  where?: InputMaybe<UserFinancePlan_Bool_Exp>;
};


export type Subscription_RootUserHabitArgs = {
  distinct_on?: InputMaybe<Array<UserHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserHabit_Order_By>>;
  where?: InputMaybe<UserHabit_Bool_Exp>;
};


export type Subscription_RootUserHabit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserHabit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserHabit_Order_By>>;
  where?: InputMaybe<UserHabit_Bool_Exp>;
};


export type Subscription_RootUserHabit_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserHabit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserHabit_Stream_Cursor_Input>>;
  where?: InputMaybe<UserHabit_Bool_Exp>;
};


export type Subscription_RootUserMovementDailyArgs = {
  distinct_on?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMovementDaily_Order_By>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


export type Subscription_RootUserMovementDaily_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserMovementDaily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMovementDaily_Order_By>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


export type Subscription_RootUserMovementDaily_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserMovementDaily_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserMovementDaily_Stream_Cursor_Input>>;
  where?: InputMaybe<UserMovementDaily_Bool_Exp>;
};


export type Subscription_RootUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Subscription_RootUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserPreferences_Order_By>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Subscription_RootUserPreferences_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserPreferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserPreferences_Stream_Cursor_Input>>;
  where?: InputMaybe<UserPreferences_Bool_Exp>;
};


export type Subscription_RootUserProductArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


export type Subscription_RootUserProductHistoryArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


export type Subscription_RootUserProductHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProductHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProductHistory_Order_By>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


export type Subscription_RootUserProductHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserProductHistory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserProductHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<UserProductHistory_Bool_Exp>;
};


export type Subscription_RootUserProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserProduct_Order_By>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


export type Subscription_RootUserProduct_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserProduct_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserProduct_Stream_Cursor_Input>>;
  where?: InputMaybe<UserProduct_Bool_Exp>;
};


export type Subscription_RootUserReadinessArgs = {
  distinct_on?: InputMaybe<Array<UserReadiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadiness_Order_By>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


export type Subscription_RootUserReadinessLogArgs = {
  distinct_on?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadinessLog_Order_By>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


export type Subscription_RootUserReadinessLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReadinessLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadinessLog_Order_By>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


export type Subscription_RootUserReadinessLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserReadinessLog_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserReadinessLog_Stream_Cursor_Input>>;
  where?: InputMaybe<UserReadinessLog_Bool_Exp>;
};


export type Subscription_RootUserReadiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReadiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReadiness_Order_By>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


export type Subscription_RootUserReadiness_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserReadiness_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserReadiness_Stream_Cursor_Input>>;
  where?: InputMaybe<UserReadiness_Bool_Exp>;
};


export type Subscription_RootUserReimbursmentHistoryArgs = {
  distinct_on?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReimbursmentHistory_Order_By>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


export type Subscription_RootUserReimbursmentHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserReimbursmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserReimbursmentHistory_Order_By>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


export type Subscription_RootUserReimbursmentHistory_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserReimbursmentHistory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserReimbursmentHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<UserReimbursmentHistory_Bool_Exp>;
};


export type Subscription_RootUserRepArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


export type Subscription_RootUserRep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRep_Order_By>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


export type Subscription_RootUserRep_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserRep_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserRep_Stream_Cursor_Input>>;
  where?: InputMaybe<UserRep_Bool_Exp>;
};


export type Subscription_RootUserSetArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


export type Subscription_RootUserSet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSet_Order_By>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


export type Subscription_RootUserSet_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserSet_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserSet_Stream_Cursor_Input>>;
  where?: InputMaybe<UserSet_Bool_Exp>;
};


export type Subscription_RootUserStateArgs = {
  distinct_on?: InputMaybe<Array<UserState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserState_Order_By>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


export type Subscription_RootUserState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserState_Order_By>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


export type Subscription_RootUserState_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserState_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserState_Stream_Cursor_Input>>;
  where?: InputMaybe<UserState_Bool_Exp>;
};


export type Subscription_RootUserTrainingPlanArgs = {
  distinct_on?: InputMaybe<Array<UserTrainingPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserTrainingPlan_Order_By>>;
  where?: InputMaybe<UserTrainingPlan_Bool_Exp>;
};


export type Subscription_RootUserTrainingPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserTrainingPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserTrainingPlan_Order_By>>;
  where?: InputMaybe<UserTrainingPlan_Bool_Exp>;
};


export type Subscription_RootUserTrainingPlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserTrainingPlan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserTrainingPlan_Stream_Cursor_Input>>;
  where?: InputMaybe<UserTrainingPlan_Bool_Exp>;
};


export type Subscription_RootUserWorkoutArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


export type Subscription_RootUserWorkout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserWorkout_Order_By>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


export type Subscription_RootUserWorkout_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUserWorkout_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserWorkout_Stream_Cursor_Input>>;
  where?: InputMaybe<UserWorkout_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootVendorErrorLogArgs = {
  distinct_on?: InputMaybe<Array<VendorErrorLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VendorErrorLog_Order_By>>;
  where?: InputMaybe<VendorErrorLog_Bool_Exp>;
};


export type Subscription_RootVendorErrorLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VendorErrorLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VendorErrorLog_Order_By>>;
  where?: InputMaybe<VendorErrorLog_Bool_Exp>;
};


export type Subscription_RootVendorErrorLog_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootVendorErrorLog_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VendorErrorLog_Stream_Cursor_Input>>;
  where?: InputMaybe<VendorErrorLog_Bool_Exp>;
};


export type Subscription_RootVersionControlArgs = {
  distinct_on?: InputMaybe<Array<VersionControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionControl_Order_By>>;
  where?: InputMaybe<VersionControl_Bool_Exp>;
};


export type Subscription_RootVersionControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VersionControl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionControl_Order_By>>;
  where?: InputMaybe<VersionControl_Bool_Exp>;
};


export type Subscription_RootVersionControl_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootVersionControl_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VersionControl_Stream_Cursor_Input>>;
  where?: InputMaybe<VersionControl_Bool_Exp>;
};


export type Subscription_RootWeeklyPlanArgs = {
  distinct_on?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WeeklyPlan_Order_By>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


export type Subscription_RootWeeklyPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WeeklyPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WeeklyPlan_Order_By>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


export type Subscription_RootWeeklyPlan_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootWeeklyPlan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<WeeklyPlan_Stream_Cursor_Input>>;
  where?: InputMaybe<WeeklyPlan_Bool_Exp>;
};


export type Subscription_RootWorkinPrefrenceArgs = {
  distinct_on?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkinPrefrence_Order_By>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


export type Subscription_RootWorkinPrefrence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkinPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkinPrefrence_Order_By>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


export type Subscription_RootWorkinPrefrence_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootWorkinPrefrence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<WorkinPrefrence_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkinPrefrence_Bool_Exp>;
};


export type Subscription_RootWorkoutArgs = {
  distinct_on?: InputMaybe<Array<Workout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workout_Order_By>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


export type Subscription_RootWorkoutPrefrenceArgs = {
  distinct_on?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkoutPrefrence_Order_By>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


export type Subscription_RootWorkoutPrefrence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkoutPrefrence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<WorkoutPrefrence_Order_By>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


export type Subscription_RootWorkoutPrefrence_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootWorkoutPrefrence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<WorkoutPrefrence_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkoutPrefrence_Bool_Exp>;
};


export type Subscription_RootWorkout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workout_Order_By>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


export type Subscription_RootWorkout_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootWorkout_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Workout_Stream_Cursor_Input>>;
  where?: InputMaybe<Workout_Bool_Exp>;
};


export type Subscription_Root_FollowingProArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


export type Subscription_Root_FollowingPro_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_FollowingPro_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_FollowingPro_Order_By>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


export type Subscription_Root_FollowingPro_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_FollowingPro_Stream_Cursor_Input>>;
  where?: InputMaybe<_FollowingPro_Bool_Exp>;
};


export type Subscription_Root_GoalToUserPreferencesArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};


export type Subscription_Root_GoalToUserPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_GoalToUserPreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<_GoalToUserPreferences_Order_By>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};


export type Subscription_Root_GoalToUserPreferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<_GoalToUserPreferences_Stream_Cursor_Input>>;
  where?: InputMaybe<_GoalToUserPreferences_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

export type UpdateReimbursementTokensResponse = {
  __typename?: 'updateReimbursementTokensResponse';
  message: Scalars['String']['output'];
};

export type UpdateUser = {
  __typename?: 'updateUser';
  age?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  cuid?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  ethnicity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  goalWeight?: Maybe<Scalars['Float']['output']>;
  has_warming_state?: Maybe<Scalars['Boolean']['output']>;
  height_feet?: Maybe<Scalars['Int']['output']>;
  height_inches?: Maybe<Scalars['Int']['output']>;
  height_meters?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  inRingMode?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferMetric?: Maybe<Scalars['Boolean']['output']>;
  radioPreferences?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ringId?: Maybe<Scalars['String']['output']>;
  ringMacAddress?: Maybe<Scalars['String']['output']>;
  ringName?: Maybe<Scalars['String']['output']>;
  shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  strapColor?: Maybe<Scalars['String']['output']>;
  strap_location?: Maybe<StrapLocationEnum>;
  user_state?: Maybe<UserStateOutput>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  weight_lbs?: Maybe<Scalars['Float']['output']>;
};

export type UserNutritionReportOutput = {
  __typename?: 'userNutritionReportOutput';
  age: Scalars['Int']['output'];
  alterType?: Maybe<Scalars['String']['output']>;
  bestExercise?: Maybe<Scalars['String']['output']>;
  currentWeight?: Maybe<Scalars['Float']['output']>;
  dailyCaloricLimit?: Maybe<Scalars['Float']['output']>;
  dailyCaloricMin?: Maybe<Scalars['Float']['output']>;
  dailyCarbsMax?: Maybe<Scalars['Float']['output']>;
  dailyFatAllowance?: Maybe<Scalars['Float']['output']>;
  dailyProteinMin?: Maybe<Scalars['Float']['output']>;
  easeWeightLoss?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  goalWeight?: Maybe<Scalars['Float']['output']>;
  gramsRecommendedCarbs?: Maybe<Scalars['Int']['output']>;
  gramsRecommendedFats?: Maybe<Scalars['Int']['output']>;
  gramsRecommendedProteins?: Maybe<Scalars['Int']['output']>;
  impactEatingCarbs?: Maybe<Scalars['String']['output']>;
  impactEatingFat?: Maybe<Scalars['String']['output']>;
  impactEatingProtein?: Maybe<Scalars['String']['output']>;
  keepingWeightOff?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  nutrients_category?: Maybe<Scalars['Int']['output']>;
  nutritionGoal?: Maybe<Scalars['String']['output']>;
  percentRecommendedCarbs?: Maybe<Scalars['Float']['output']>;
  percentRecommendedFats?: Maybe<Scalars['Float']['output']>;
  percentRecommendedProteins?: Maybe<Scalars['Float']['output']>;
  sex: Scalars['String']['output'];
  systemicInflammation?: Maybe<Scalars['String']['output']>;
};

export type SubmitReportViewedMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type SubmitReportViewedMutation = { __typename?: 'mutation_root', submitReportViewed: { __typename?: 'messageOutput', message: string } };

export type RegisterDnaKitMutationVariables = Exact<{
  auth0UserId: Scalars['String']['input'];
  alterUserId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  heightFeet: Scalars['Int']['input'];
  heightInches: Scalars['Int']['input'];
  activationCode: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  weightInLbs?: InputMaybe<Scalars['Int']['input']>;
  kitId?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Ethnicity>;
  smsOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  overEighteen?: InputMaybe<Scalars['Boolean']['input']>;
  marketingOptOut?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RegisterDnaKitMutation = { __typename?: 'mutation_root', registerDNAKit: string };

export type GetDnaResultsQueryVariables = Exact<{
  alter_kit_id: Scalars['String']['input'];
}>;


export type GetDnaResultsQuery = { __typename?: 'query_root', DNAResults: Array<{ __typename?: 'DNAResults', carbUtilization?: string | null, chronotype?: string | null, fatUtilization?: string | null, intrinsicMotivation?: string | null, mentalAcuity?: string | null, proteinUtilization?: string | null, responseToCardio?: string | null, responseToStrength?: string | null, stressTolerance?: string | null, systemicInflammation?: string | null, weightLossTendency?: string | null, weightRegain?: string | null, injuryRisk?: string | null, trainingPlan?: string | null, core_type?: string | null, created_at: string, User?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, birthday?: string | null } | null }> };

export type CheckDnaKitQueryVariables = Exact<{
  kitId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CheckDnaKitQuery = { __typename?: 'query_root', checkDnaKit?: { __typename?: 'CheckDNAKitOutput', status?: DnaKitResult | null } | null };

export type CheckDnaKitWithEmailQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type CheckDnaKitWithEmailQuery = { __typename?: 'query_root', checkDnaKit?: { __typename?: 'CheckDNAKitOutput', status?: DnaKitResult | null } | null };

export type CheckDnaKitWithEmailOnlyQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CheckDnaKitWithEmailOnlyQuery = { __typename?: 'query_root', checkDnaKitEmail: boolean };

export type CheckDnaKitWithCodeQueryVariables = Exact<{
  kitId?: InputMaybe<Scalars['String']['input']>;
  activationCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type CheckDnaKitWithCodeQuery = { __typename?: 'query_root', checkDnaKit?: { __typename?: 'CheckDNAKitOutput', status?: DnaKitResult | null } | null };

export type CheckDnaKitWithUserIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type CheckDnaKitWithUserIdQuery = { __typename?: 'query_root', DNAKit: Array<{ __typename?: 'DNAKit', status?: string | null }> };

export type CheckCompleteDnaResultsQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckCompleteDnaResultsQuery = { __typename?: 'query_root', checkCompleteDNAResults: Record<string, unknown> };

export type MyAlterProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAlterProductsQuery = { __typename?: 'query_root', myAlterProducts: Record<string, unknown> };

export type DnaKitQueryVariables = Exact<{
  registered_user_id: Scalars['String']['input'];
}>;


export type DnaKitQuery = { __typename?: 'query_root', DNAKit: Array<{ __typename?: 'DNAKit', id: string, email: string, status?: string | null, kitActivated?: boolean | null, needs_rekit?: boolean | null, DNAResults: Array<{ __typename?: 'DNAResults', id: string, dna_results_received_time?: string | null, num_views?: number | null, primary_report_url?: string | null }> }> };

export type FetchCalendlyLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCalendlyLinkQuery = { __typename?: 'query_root', fetchCalendlyLink: Record<string, unknown> };

export type CheckNutritionReportReleasedQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckNutritionReportReleasedQuery = { __typename?: 'query_root', checkNutritionReportReleased?: { __typename?: 'CheckNutritionReportReleasedOutput', nutrition_report_released?: boolean | null } | null };

export type UserNutritionReportQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type UserNutritionReportQuery = { __typename?: 'query_root', userNutritionReport?: { __typename?: 'userNutritionReportOutput', firstName: string, lastName: string, age: number, sex: string, alterType?: string | null, percentRecommendedCarbs?: number | null, percentRecommendedFats?: number | null, percentRecommendedProteins?: number | null, gramsRecommendedCarbs?: number | null, gramsRecommendedFats?: number | null, gramsRecommendedProteins?: number | null, dailyCaloricLimit?: number | null, dailyProteinMin?: number | null, dailyCarbsMax?: number | null, dailyFatAllowance?: number | null, dailyCaloricMin?: number | null, easeWeightLoss?: string | null, keepingWeightOff?: string | null, impactEatingProtein?: string | null, impactEatingFat?: string | null, impactEatingCarbs?: string | null, systemicInflammation?: string | null, bestExercise?: string | null, currentWeight?: number | null, goalWeight?: number | null, nutritionGoal?: string | null } | null };

export type SubmitSurveyToSfV2MutationVariables = Exact<{
  userId: Scalars['String']['input'];
  consultationItems?: InputMaybe<Array<InputMaybe<ConsultationItems>> | InputMaybe<ConsultationItems>>;
}>;


export type SubmitSurveyToSfV2Mutation = { __typename?: 'mutation_root', submitSurveyToSfV2: Record<string, unknown> };

export type SubmitRegistrationSurveyMutationVariables = Exact<{
  user_id: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  age?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<RegGender>;
  main_motivation?: InputMaybe<RegMotivation>;
  weight_loss_goal?: InputMaybe<RegWeightLossGoal>;
  weight_loss_goal_custom?: InputMaybe<Scalars['String']['input']>;
  weight_loss_timeline?: InputMaybe<RegWeightLossTimeline>;
  current_weight_lbs?: InputMaybe<Scalars['String']['input']>;
  areas_needing_help?: InputMaybe<Array<InputMaybe<RegAreasNeedingHelp>> | InputMaybe<RegAreasNeedingHelp>>;
  services_tried?: InputMaybe<Array<InputMaybe<RegServicesTried>> | InputMaybe<RegServicesTried>>;
  current_fitness_ability?: InputMaybe<RegFitnessAbility>;
  favorite_type_of_exercise?: InputMaybe<RegFavoriteExercise>;
  additional_notes?: InputMaybe<Scalars['String']['input']>;
  glp_status?: InputMaybe<Scalars['String']['input']>;
}>;


export type SubmitRegistrationSurveyMutation = { __typename?: 'mutation_root', submitRegistrationSurvey: Record<string, unknown> };

export type AddUserToHouseholdMutationVariables = Exact<{
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  signal_color?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddUserToHouseholdMutation = { __typename?: 'mutation_root', addUserToHousehold: Record<string, unknown> };

export type RemoveUserFromHouseholdMutationVariables = Exact<{
  user_id: Scalars['String']['input'];
}>;


export type RemoveUserFromHouseholdMutation = { __typename?: 'mutation_root', removeUserFromHousehold: Record<string, unknown> };

export type GetHouseholdMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHouseholdMembersQuery = { __typename?: 'query_root', getHouseholdMembers: Record<string, unknown> };

export type ChangeSubscriptionMutationVariables = Exact<{
  shopify_variant_id: Scalars['String']['input'];
}>;


export type ChangeSubscriptionMutation = { __typename?: 'mutation_root', changeProductVariant: Record<string, unknown> };

export type ActivateSubscriptionMutationVariables = Exact<{
  shopify_variant_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type ActivateSubscriptionMutation = { __typename?: 'mutation_root', activateSubscription: Record<string, unknown> };

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = { __typename?: 'mutation_root', cancelSubscription: Record<string, unknown> };

export type GetSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionQuery = { __typename?: 'query_root', getSubscription: Record<string, unknown> };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { __typename?: 'mutation_root', deleteAccount: Record<string, unknown> };

export type UpdateUserPhoneEmailMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}>;


export type UpdateUserPhoneEmailMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'updateUser', id: string, email: string, phone?: string | null } | null };

export type UpdateUserEmailMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type UpdateUserEmailMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'updateUser', id: string, email: string } | null };

export type UpdateUserCuidMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  afWebUserId: Scalars['String']['input'];
}>;


export type UpdateUserCuidMutation = { __typename?: 'mutation_root', update_User?: { __typename?: 'User_mutation_response', returning: Array<{ __typename?: 'User', id: string, birthday?: string | null, gender?: string | null, email: string, firstName?: string | null, lastName?: string | null, auth0UserId?: string | null, cuid?: string | null }> } | null };

export type UpdateUserBioMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  birthday: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  height?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
}>;


export type UpdateUserBioMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'updateUser', id: string, email: string, birthday?: string | null, firstName?: string | null, lastName?: string | null, gender?: string | null, height_feet?: number | null, height_inches?: number | null, weight_lbs?: number | null } | null };

export type UpdateUserOnboardMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  birthday: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
}>;


export type UpdateUserOnboardMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'updateUser', id: string, email: string, birthday?: string | null, firstName?: string | null, lastName?: string | null, gender?: string | null } | null };

export type UpdateUserSystemMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  signal_color?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserSystemMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'updateUser', id: string, email: string, firstName?: string | null, lastName?: string | null, strapColor?: string | null } | null };

export type GetUserQueryVariables = Exact<{
  auth0UserId: Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'query_root', User: Array<{ __typename?: 'User', id: string, birthday?: string | null, gender?: string | null, email: string, firstName?: string | null, lastName?: string | null, pearUserId?: string | null, cuid?: string | null, shopifyCustomerId?: string | null, height_feet?: number | null, height_inches?: number | null, weight_lbs?: number | null, ethnicity?: string | null, Household?: { __typename?: 'Household', id: string } | null }> };

export type FetchUserShippingAddressQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type FetchUserShippingAddressQuery = { __typename?: 'query_root', fetchUserShippingAddress?: { __typename?: 'ShippingAddressOutput', shipping_address?: { __typename?: 'Props', address1?: string | null, address2?: string | null, city?: string | null, country?: string | null, country_code?: string | null, province?: string | null, province_code?: string | null, zip?: string | null } | null } | null };

export type FetchHouseholdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FetchHouseholdQuery = { __typename?: 'query_root', fetchHousehold: Array<{ __typename?: 'HouseholdOutput', firstName?: string | null, lastName?: string | null, owner_user_id?: string | null, user_id?: string | null } | null> };

export type GetUserProductsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  systemAndSignalOnly: Scalars['Boolean']['input'];
}>;


export type GetUserProductsQuery = { __typename?: 'query_root', getUserProducts?: Array<{ __typename?: 'UserProductsOutput', id: string, product_sku: string } | null> | null };


export const SubmitReportViewedDocument = gql`
    mutation submitReportViewed($userId: String!) {
  submitReportViewed(userId: $userId) {
    message
  }
}
    `;
export type SubmitReportViewedMutationFn = Apollo.MutationFunction<SubmitReportViewedMutation, SubmitReportViewedMutationVariables>;

/**
 * __useSubmitReportViewedMutation__
 *
 * To run a mutation, you first call `useSubmitReportViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReportViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReportViewedMutation, { data, loading, error }] = useSubmitReportViewedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubmitReportViewedMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReportViewedMutation, SubmitReportViewedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitReportViewedMutation, SubmitReportViewedMutationVariables>(SubmitReportViewedDocument, options);
      }
export type SubmitReportViewedMutationHookResult = ReturnType<typeof useSubmitReportViewedMutation>;
export type SubmitReportViewedMutationResult = Apollo.MutationResult<SubmitReportViewedMutation>;
export type SubmitReportViewedMutationOptions = Apollo.BaseMutationOptions<SubmitReportViewedMutation, SubmitReportViewedMutationVariables>;
export const RegisterDnaKitDocument = gql`
    mutation registerDNAKit($auth0UserId: String!, $alterUserId: String!, $dateOfBirth: String!, $email: String!, $firstName: String!, $gender: String!, $heightFeet: Int!, $heightInches: Int!, $activationCode: String!, $lastName: String!, $weightInLbs: Int, $kitId: String, $ethnicity: Ethnicity, $smsOptOut: Boolean, $overEighteen: Boolean, $marketingOptOut: Boolean) {
  registerDNAKit(
    auth0UserId: $auth0UserId
    alterUserId: $alterUserId
    dateOfBirth: $dateOfBirth
    email: $email
    firstName: $firstName
    gender: $gender
    heightFeet: $heightFeet
    heightInches: $heightInches
    activationCode: $activationCode
    lastName: $lastName
    weightInLbs: $weightInLbs
    kitId: $kitId
    ethnicity: $ethnicity
    smsOptOut: $smsOptOut
    overEighteen: $overEighteen
    marketingOptOut: $marketingOptOut
  )
}
    `;
export type RegisterDnaKitMutationFn = Apollo.MutationFunction<RegisterDnaKitMutation, RegisterDnaKitMutationVariables>;

/**
 * __useRegisterDnaKitMutation__
 *
 * To run a mutation, you first call `useRegisterDnaKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDnaKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDnaKitMutation, { data, loading, error }] = useRegisterDnaKitMutation({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *      alterUserId: // value for 'alterUserId'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      gender: // value for 'gender'
 *      heightFeet: // value for 'heightFeet'
 *      heightInches: // value for 'heightInches'
 *      activationCode: // value for 'activationCode'
 *      lastName: // value for 'lastName'
 *      weightInLbs: // value for 'weightInLbs'
 *      kitId: // value for 'kitId'
 *      ethnicity: // value for 'ethnicity'
 *      smsOptOut: // value for 'smsOptOut'
 *      overEighteen: // value for 'overEighteen'
 *      marketingOptOut: // value for 'marketingOptOut'
 *   },
 * });
 */
export function useRegisterDnaKitMutation(baseOptions?: Apollo.MutationHookOptions<RegisterDnaKitMutation, RegisterDnaKitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterDnaKitMutation, RegisterDnaKitMutationVariables>(RegisterDnaKitDocument, options);
      }
export type RegisterDnaKitMutationHookResult = ReturnType<typeof useRegisterDnaKitMutation>;
export type RegisterDnaKitMutationResult = Apollo.MutationResult<RegisterDnaKitMutation>;
export type RegisterDnaKitMutationOptions = Apollo.BaseMutationOptions<RegisterDnaKitMutation, RegisterDnaKitMutationVariables>;
export const GetDnaResultsDocument = gql`
    query getDnaResults($alter_kit_id: String!) {
  DNAResults(
    where: {_or: [{alter_kit_id: {_eq: $alter_kit_id}}, {id: {_eq: $alter_kit_id}}]}
  ) {
    carbUtilization
    chronotype
    fatUtilization
    intrinsicMotivation
    mentalAcuity
    proteinUtilization
    responseToCardio
    responseToStrength
    stressTolerance
    systemicInflammation
    weightLossTendency
    weightRegain
    injuryRisk
    trainingPlan
    core_type
    created_at
    User {
      id
      firstName
      lastName
      email
      birthday
    }
  }
}
    `;

/**
 * __useGetDnaResultsQuery__
 *
 * To run a query within a React component, call `useGetDnaResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDnaResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDnaResultsQuery({
 *   variables: {
 *      alter_kit_id: // value for 'alter_kit_id'
 *   },
 * });
 */
export function useGetDnaResultsQuery(baseOptions: Apollo.QueryHookOptions<GetDnaResultsQuery, GetDnaResultsQueryVariables> & ({ variables: GetDnaResultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDnaResultsQuery, GetDnaResultsQueryVariables>(GetDnaResultsDocument, options);
      }
export function useGetDnaResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDnaResultsQuery, GetDnaResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDnaResultsQuery, GetDnaResultsQueryVariables>(GetDnaResultsDocument, options);
        }
export function useGetDnaResultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDnaResultsQuery, GetDnaResultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDnaResultsQuery, GetDnaResultsQueryVariables>(GetDnaResultsDocument, options);
        }
export type GetDnaResultsQueryHookResult = ReturnType<typeof useGetDnaResultsQuery>;
export type GetDnaResultsLazyQueryHookResult = ReturnType<typeof useGetDnaResultsLazyQuery>;
export type GetDnaResultsSuspenseQueryHookResult = ReturnType<typeof useGetDnaResultsSuspenseQuery>;
export type GetDnaResultsQueryResult = Apollo.QueryResult<GetDnaResultsQuery, GetDnaResultsQueryVariables>;
export function refetchGetDnaResultsQuery(variables: GetDnaResultsQueryVariables) {
      return { query: GetDnaResultsDocument, variables: variables }
    }
export const CheckDnaKitDocument = gql`
    query checkDnaKit($kitId: String) {
  checkDnaKit(kitId: $kitId) {
    status
  }
}
    `;

/**
 * __useCheckDnaKitQuery__
 *
 * To run a query within a React component, call `useCheckDnaKitQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDnaKitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDnaKitQuery({
 *   variables: {
 *      kitId: // value for 'kitId'
 *   },
 * });
 */
export function useCheckDnaKitQuery(baseOptions?: Apollo.QueryHookOptions<CheckDnaKitQuery, CheckDnaKitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDnaKitQuery, CheckDnaKitQueryVariables>(CheckDnaKitDocument, options);
      }
export function useCheckDnaKitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDnaKitQuery, CheckDnaKitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDnaKitQuery, CheckDnaKitQueryVariables>(CheckDnaKitDocument, options);
        }
export function useCheckDnaKitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckDnaKitQuery, CheckDnaKitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckDnaKitQuery, CheckDnaKitQueryVariables>(CheckDnaKitDocument, options);
        }
export type CheckDnaKitQueryHookResult = ReturnType<typeof useCheckDnaKitQuery>;
export type CheckDnaKitLazyQueryHookResult = ReturnType<typeof useCheckDnaKitLazyQuery>;
export type CheckDnaKitSuspenseQueryHookResult = ReturnType<typeof useCheckDnaKitSuspenseQuery>;
export type CheckDnaKitQueryResult = Apollo.QueryResult<CheckDnaKitQuery, CheckDnaKitQueryVariables>;
export function refetchCheckDnaKitQuery(variables?: CheckDnaKitQueryVariables) {
      return { query: CheckDnaKitDocument, variables: variables }
    }
export const CheckDnaKitWithEmailDocument = gql`
    query checkDnaKitWithEmail($email: String, $activationCode: String) {
  checkDnaKit(email: $email, activationCode: $activationCode) {
    status
  }
}
    `;

/**
 * __useCheckDnaKitWithEmailQuery__
 *
 * To run a query within a React component, call `useCheckDnaKitWithEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDnaKitWithEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDnaKitWithEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useCheckDnaKitWithEmailQuery(baseOptions?: Apollo.QueryHookOptions<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>(CheckDnaKitWithEmailDocument, options);
      }
export function useCheckDnaKitWithEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>(CheckDnaKitWithEmailDocument, options);
        }
export function useCheckDnaKitWithEmailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>(CheckDnaKitWithEmailDocument, options);
        }
export type CheckDnaKitWithEmailQueryHookResult = ReturnType<typeof useCheckDnaKitWithEmailQuery>;
export type CheckDnaKitWithEmailLazyQueryHookResult = ReturnType<typeof useCheckDnaKitWithEmailLazyQuery>;
export type CheckDnaKitWithEmailSuspenseQueryHookResult = ReturnType<typeof useCheckDnaKitWithEmailSuspenseQuery>;
export type CheckDnaKitWithEmailQueryResult = Apollo.QueryResult<CheckDnaKitWithEmailQuery, CheckDnaKitWithEmailQueryVariables>;
export function refetchCheckDnaKitWithEmailQuery(variables?: CheckDnaKitWithEmailQueryVariables) {
      return { query: CheckDnaKitWithEmailDocument, variables: variables }
    }
export const CheckDnaKitWithEmailOnlyDocument = gql`
    query checkDnaKitWithEmailOnly($email: String!) {
  checkDnaKitEmail(email: $email)
}
    `;

/**
 * __useCheckDnaKitWithEmailOnlyQuery__
 *
 * To run a query within a React component, call `useCheckDnaKitWithEmailOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDnaKitWithEmailOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDnaKitWithEmailOnlyQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckDnaKitWithEmailOnlyQuery(baseOptions: Apollo.QueryHookOptions<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables> & ({ variables: CheckDnaKitWithEmailOnlyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables>(CheckDnaKitWithEmailOnlyDocument, options);
      }
export function useCheckDnaKitWithEmailOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables>(CheckDnaKitWithEmailOnlyDocument, options);
        }
export function useCheckDnaKitWithEmailOnlySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables>(CheckDnaKitWithEmailOnlyDocument, options);
        }
export type CheckDnaKitWithEmailOnlyQueryHookResult = ReturnType<typeof useCheckDnaKitWithEmailOnlyQuery>;
export type CheckDnaKitWithEmailOnlyLazyQueryHookResult = ReturnType<typeof useCheckDnaKitWithEmailOnlyLazyQuery>;
export type CheckDnaKitWithEmailOnlySuspenseQueryHookResult = ReturnType<typeof useCheckDnaKitWithEmailOnlySuspenseQuery>;
export type CheckDnaKitWithEmailOnlyQueryResult = Apollo.QueryResult<CheckDnaKitWithEmailOnlyQuery, CheckDnaKitWithEmailOnlyQueryVariables>;
export function refetchCheckDnaKitWithEmailOnlyQuery(variables: CheckDnaKitWithEmailOnlyQueryVariables) {
      return { query: CheckDnaKitWithEmailOnlyDocument, variables: variables }
    }
export const CheckDnaKitWithCodeDocument = gql`
    query checkDnaKitWithCode($kitId: String, $activationCode: String) {
  checkDnaKit(kitId: $kitId, activationCode: $activationCode) {
    status
  }
}
    `;

/**
 * __useCheckDnaKitWithCodeQuery__
 *
 * To run a query within a React component, call `useCheckDnaKitWithCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDnaKitWithCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDnaKitWithCodeQuery({
 *   variables: {
 *      kitId: // value for 'kitId'
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useCheckDnaKitWithCodeQuery(baseOptions?: Apollo.QueryHookOptions<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>(CheckDnaKitWithCodeDocument, options);
      }
export function useCheckDnaKitWithCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>(CheckDnaKitWithCodeDocument, options);
        }
export function useCheckDnaKitWithCodeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>(CheckDnaKitWithCodeDocument, options);
        }
export type CheckDnaKitWithCodeQueryHookResult = ReturnType<typeof useCheckDnaKitWithCodeQuery>;
export type CheckDnaKitWithCodeLazyQueryHookResult = ReturnType<typeof useCheckDnaKitWithCodeLazyQuery>;
export type CheckDnaKitWithCodeSuspenseQueryHookResult = ReturnType<typeof useCheckDnaKitWithCodeSuspenseQuery>;
export type CheckDnaKitWithCodeQueryResult = Apollo.QueryResult<CheckDnaKitWithCodeQuery, CheckDnaKitWithCodeQueryVariables>;
export function refetchCheckDnaKitWithCodeQuery(variables?: CheckDnaKitWithCodeQueryVariables) {
      return { query: CheckDnaKitWithCodeDocument, variables: variables }
    }
export const CheckDnaKitWithUserIdDocument = gql`
    query checkDnaKitWithUserId($userId: String!) {
  DNAKit(where: {registered_user_id: {_eq: $userId}}) {
    status
  }
}
    `;

/**
 * __useCheckDnaKitWithUserIdQuery__
 *
 * To run a query within a React component, call `useCheckDnaKitWithUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDnaKitWithUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDnaKitWithUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckDnaKitWithUserIdQuery(baseOptions: Apollo.QueryHookOptions<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables> & ({ variables: CheckDnaKitWithUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables>(CheckDnaKitWithUserIdDocument, options);
      }
export function useCheckDnaKitWithUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables>(CheckDnaKitWithUserIdDocument, options);
        }
export function useCheckDnaKitWithUserIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables>(CheckDnaKitWithUserIdDocument, options);
        }
export type CheckDnaKitWithUserIdQueryHookResult = ReturnType<typeof useCheckDnaKitWithUserIdQuery>;
export type CheckDnaKitWithUserIdLazyQueryHookResult = ReturnType<typeof useCheckDnaKitWithUserIdLazyQuery>;
export type CheckDnaKitWithUserIdSuspenseQueryHookResult = ReturnType<typeof useCheckDnaKitWithUserIdSuspenseQuery>;
export type CheckDnaKitWithUserIdQueryResult = Apollo.QueryResult<CheckDnaKitWithUserIdQuery, CheckDnaKitWithUserIdQueryVariables>;
export function refetchCheckDnaKitWithUserIdQuery(variables: CheckDnaKitWithUserIdQueryVariables) {
      return { query: CheckDnaKitWithUserIdDocument, variables: variables }
    }
export const CheckCompleteDnaResultsDocument = gql`
    query checkCompleteDNAResults {
  checkCompleteDNAResults
}
    `;

/**
 * __useCheckCompleteDnaResultsQuery__
 *
 * To run a query within a React component, call `useCheckCompleteDnaResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCompleteDnaResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCompleteDnaResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckCompleteDnaResultsQuery(baseOptions?: Apollo.QueryHookOptions<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>(CheckCompleteDnaResultsDocument, options);
      }
export function useCheckCompleteDnaResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>(CheckCompleteDnaResultsDocument, options);
        }
export function useCheckCompleteDnaResultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>(CheckCompleteDnaResultsDocument, options);
        }
export type CheckCompleteDnaResultsQueryHookResult = ReturnType<typeof useCheckCompleteDnaResultsQuery>;
export type CheckCompleteDnaResultsLazyQueryHookResult = ReturnType<typeof useCheckCompleteDnaResultsLazyQuery>;
export type CheckCompleteDnaResultsSuspenseQueryHookResult = ReturnType<typeof useCheckCompleteDnaResultsSuspenseQuery>;
export type CheckCompleteDnaResultsQueryResult = Apollo.QueryResult<CheckCompleteDnaResultsQuery, CheckCompleteDnaResultsQueryVariables>;
export function refetchCheckCompleteDnaResultsQuery(variables?: CheckCompleteDnaResultsQueryVariables) {
      return { query: CheckCompleteDnaResultsDocument, variables: variables }
    }
export const MyAlterProductsDocument = gql`
    query myAlterProducts {
  myAlterProducts
}
    `;

/**
 * __useMyAlterProductsQuery__
 *
 * To run a query within a React component, call `useMyAlterProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAlterProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAlterProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAlterProductsQuery(baseOptions?: Apollo.QueryHookOptions<MyAlterProductsQuery, MyAlterProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAlterProductsQuery, MyAlterProductsQueryVariables>(MyAlterProductsDocument, options);
      }
export function useMyAlterProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAlterProductsQuery, MyAlterProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAlterProductsQuery, MyAlterProductsQueryVariables>(MyAlterProductsDocument, options);
        }
export function useMyAlterProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyAlterProductsQuery, MyAlterProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyAlterProductsQuery, MyAlterProductsQueryVariables>(MyAlterProductsDocument, options);
        }
export type MyAlterProductsQueryHookResult = ReturnType<typeof useMyAlterProductsQuery>;
export type MyAlterProductsLazyQueryHookResult = ReturnType<typeof useMyAlterProductsLazyQuery>;
export type MyAlterProductsSuspenseQueryHookResult = ReturnType<typeof useMyAlterProductsSuspenseQuery>;
export type MyAlterProductsQueryResult = Apollo.QueryResult<MyAlterProductsQuery, MyAlterProductsQueryVariables>;
export function refetchMyAlterProductsQuery(variables?: MyAlterProductsQueryVariables) {
      return { query: MyAlterProductsDocument, variables: variables }
    }
export const DnaKitDocument = gql`
    query dnaKit($registered_user_id: String!) {
  DNAKit(
    where: {registered_user_id: {_eq: $registered_user_id}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    id
    email
    status
    kitActivated
    needs_rekit
    DNAResults {
      id
      dna_results_received_time
      num_views
      primary_report_url
    }
  }
}
    `;

/**
 * __useDnaKitQuery__
 *
 * To run a query within a React component, call `useDnaKitQuery` and pass it any options that fit your needs.
 * When your component renders, `useDnaKitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDnaKitQuery({
 *   variables: {
 *      registered_user_id: // value for 'registered_user_id'
 *   },
 * });
 */
export function useDnaKitQuery(baseOptions: Apollo.QueryHookOptions<DnaKitQuery, DnaKitQueryVariables> & ({ variables: DnaKitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DnaKitQuery, DnaKitQueryVariables>(DnaKitDocument, options);
      }
export function useDnaKitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DnaKitQuery, DnaKitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DnaKitQuery, DnaKitQueryVariables>(DnaKitDocument, options);
        }
export function useDnaKitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DnaKitQuery, DnaKitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DnaKitQuery, DnaKitQueryVariables>(DnaKitDocument, options);
        }
export type DnaKitQueryHookResult = ReturnType<typeof useDnaKitQuery>;
export type DnaKitLazyQueryHookResult = ReturnType<typeof useDnaKitLazyQuery>;
export type DnaKitSuspenseQueryHookResult = ReturnType<typeof useDnaKitSuspenseQuery>;
export type DnaKitQueryResult = Apollo.QueryResult<DnaKitQuery, DnaKitQueryVariables>;
export function refetchDnaKitQuery(variables: DnaKitQueryVariables) {
      return { query: DnaKitDocument, variables: variables }
    }
export const FetchCalendlyLinkDocument = gql`
    query fetchCalendlyLink {
  fetchCalendlyLink
}
    `;

/**
 * __useFetchCalendlyLinkQuery__
 *
 * To run a query within a React component, call `useFetchCalendlyLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCalendlyLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCalendlyLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCalendlyLinkQuery(baseOptions?: Apollo.QueryHookOptions<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>(FetchCalendlyLinkDocument, options);
      }
export function useFetchCalendlyLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>(FetchCalendlyLinkDocument, options);
        }
export function useFetchCalendlyLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>(FetchCalendlyLinkDocument, options);
        }
export type FetchCalendlyLinkQueryHookResult = ReturnType<typeof useFetchCalendlyLinkQuery>;
export type FetchCalendlyLinkLazyQueryHookResult = ReturnType<typeof useFetchCalendlyLinkLazyQuery>;
export type FetchCalendlyLinkSuspenseQueryHookResult = ReturnType<typeof useFetchCalendlyLinkSuspenseQuery>;
export type FetchCalendlyLinkQueryResult = Apollo.QueryResult<FetchCalendlyLinkQuery, FetchCalendlyLinkQueryVariables>;
export function refetchFetchCalendlyLinkQuery(variables?: FetchCalendlyLinkQueryVariables) {
      return { query: FetchCalendlyLinkDocument, variables: variables }
    }
export const CheckNutritionReportReleasedDocument = gql`
    query checkNutritionReportReleased {
  checkNutritionReportReleased {
    nutrition_report_released
  }
}
    `;

/**
 * __useCheckNutritionReportReleasedQuery__
 *
 * To run a query within a React component, call `useCheckNutritionReportReleasedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckNutritionReportReleasedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckNutritionReportReleasedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckNutritionReportReleasedQuery(baseOptions?: Apollo.QueryHookOptions<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>(CheckNutritionReportReleasedDocument, options);
      }
export function useCheckNutritionReportReleasedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>(CheckNutritionReportReleasedDocument, options);
        }
export function useCheckNutritionReportReleasedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>(CheckNutritionReportReleasedDocument, options);
        }
export type CheckNutritionReportReleasedQueryHookResult = ReturnType<typeof useCheckNutritionReportReleasedQuery>;
export type CheckNutritionReportReleasedLazyQueryHookResult = ReturnType<typeof useCheckNutritionReportReleasedLazyQuery>;
export type CheckNutritionReportReleasedSuspenseQueryHookResult = ReturnType<typeof useCheckNutritionReportReleasedSuspenseQuery>;
export type CheckNutritionReportReleasedQueryResult = Apollo.QueryResult<CheckNutritionReportReleasedQuery, CheckNutritionReportReleasedQueryVariables>;
export function refetchCheckNutritionReportReleasedQuery(variables?: CheckNutritionReportReleasedQueryVariables) {
      return { query: CheckNutritionReportReleasedDocument, variables: variables }
    }
export const UserNutritionReportDocument = gql`
    query userNutritionReport($userId: String!) {
  userNutritionReport(userId: $userId) {
    firstName
    lastName
    age
    sex
    alterType
    percentRecommendedCarbs
    percentRecommendedFats
    percentRecommendedProteins
    gramsRecommendedCarbs
    gramsRecommendedFats
    gramsRecommendedProteins
    dailyCaloricLimit
    dailyProteinMin
    dailyCarbsMax
    dailyFatAllowance
    dailyCaloricMin
    easeWeightLoss
    keepingWeightOff
    impactEatingProtein
    impactEatingFat
    impactEatingCarbs
    systemicInflammation
    bestExercise
    currentWeight
    goalWeight
    nutritionGoal
  }
}
    `;

/**
 * __useUserNutritionReportQuery__
 *
 * To run a query within a React component, call `useUserNutritionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNutritionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNutritionReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserNutritionReportQuery(baseOptions: Apollo.QueryHookOptions<UserNutritionReportQuery, UserNutritionReportQueryVariables> & ({ variables: UserNutritionReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNutritionReportQuery, UserNutritionReportQueryVariables>(UserNutritionReportDocument, options);
      }
export function useUserNutritionReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNutritionReportQuery, UserNutritionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNutritionReportQuery, UserNutritionReportQueryVariables>(UserNutritionReportDocument, options);
        }
export function useUserNutritionReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserNutritionReportQuery, UserNutritionReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserNutritionReportQuery, UserNutritionReportQueryVariables>(UserNutritionReportDocument, options);
        }
export type UserNutritionReportQueryHookResult = ReturnType<typeof useUserNutritionReportQuery>;
export type UserNutritionReportLazyQueryHookResult = ReturnType<typeof useUserNutritionReportLazyQuery>;
export type UserNutritionReportSuspenseQueryHookResult = ReturnType<typeof useUserNutritionReportSuspenseQuery>;
export type UserNutritionReportQueryResult = Apollo.QueryResult<UserNutritionReportQuery, UserNutritionReportQueryVariables>;
export function refetchUserNutritionReportQuery(variables: UserNutritionReportQueryVariables) {
      return { query: UserNutritionReportDocument, variables: variables }
    }
export const SubmitSurveyToSfV2Document = gql`
    mutation submitSurveyToSfV2($userId: String!, $consultationItems: [ConsultationItems]) {
  submitSurveyToSfV2(userId: $userId, consultationItems: $consultationItems)
}
    `;
export type SubmitSurveyToSfV2MutationFn = Apollo.MutationFunction<SubmitSurveyToSfV2Mutation, SubmitSurveyToSfV2MutationVariables>;

/**
 * __useSubmitSurveyToSfV2Mutation__
 *
 * To run a mutation, you first call `useSubmitSurveyToSfV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSurveyToSfV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSurveyToSfV2Mutation, { data, loading, error }] = useSubmitSurveyToSfV2Mutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      consultationItems: // value for 'consultationItems'
 *   },
 * });
 */
export function useSubmitSurveyToSfV2Mutation(baseOptions?: Apollo.MutationHookOptions<SubmitSurveyToSfV2Mutation, SubmitSurveyToSfV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitSurveyToSfV2Mutation, SubmitSurveyToSfV2MutationVariables>(SubmitSurveyToSfV2Document, options);
      }
export type SubmitSurveyToSfV2MutationHookResult = ReturnType<typeof useSubmitSurveyToSfV2Mutation>;
export type SubmitSurveyToSfV2MutationResult = Apollo.MutationResult<SubmitSurveyToSfV2Mutation>;
export type SubmitSurveyToSfV2MutationOptions = Apollo.BaseMutationOptions<SubmitSurveyToSfV2Mutation, SubmitSurveyToSfV2MutationVariables>;
export const SubmitRegistrationSurveyDocument = gql`
    mutation submitRegistrationSurvey($user_id: String!, $first_name: String, $last_name: String, $email: String!, $age: Int, $gender: RegGender, $main_motivation: RegMotivation, $weight_loss_goal: RegWeightLossGoal, $weight_loss_goal_custom: String, $weight_loss_timeline: RegWeightLossTimeline, $current_weight_lbs: String, $areas_needing_help: [RegAreasNeedingHelp], $services_tried: [RegServicesTried], $current_fitness_ability: RegFitnessAbility, $favorite_type_of_exercise: RegFavoriteExercise, $additional_notes: String, $glp_status: String) {
  submitRegistrationSurvey(
    user_id: $user_id
    first_name: $first_name
    last_name: $last_name
    email: $email
    age: $age
    gender: $gender
    main_motivation: $main_motivation
    weight_loss_goal: $weight_loss_goal
    weight_loss_goal_custom: $weight_loss_goal_custom
    weight_loss_timeline: $weight_loss_timeline
    current_weight_lbs: $current_weight_lbs
    areas_needing_help: $areas_needing_help
    services_tried: $services_tried
    current_fitness_ability: $current_fitness_ability
    favorite_type_of_exercise: $favorite_type_of_exercise
    additional_notes: $additional_notes
    glp_status: $glp_status
  )
}
    `;
export type SubmitRegistrationSurveyMutationFn = Apollo.MutationFunction<SubmitRegistrationSurveyMutation, SubmitRegistrationSurveyMutationVariables>;

/**
 * __useSubmitRegistrationSurveyMutation__
 *
 * To run a mutation, you first call `useSubmitRegistrationSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRegistrationSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRegistrationSurveyMutation, { data, loading, error }] = useSubmitRegistrationSurveyMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      age: // value for 'age'
 *      gender: // value for 'gender'
 *      main_motivation: // value for 'main_motivation'
 *      weight_loss_goal: // value for 'weight_loss_goal'
 *      weight_loss_goal_custom: // value for 'weight_loss_goal_custom'
 *      weight_loss_timeline: // value for 'weight_loss_timeline'
 *      current_weight_lbs: // value for 'current_weight_lbs'
 *      areas_needing_help: // value for 'areas_needing_help'
 *      services_tried: // value for 'services_tried'
 *      current_fitness_ability: // value for 'current_fitness_ability'
 *      favorite_type_of_exercise: // value for 'favorite_type_of_exercise'
 *      additional_notes: // value for 'additional_notes'
 *      glp_status: // value for 'glp_status'
 *   },
 * });
 */
export function useSubmitRegistrationSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SubmitRegistrationSurveyMutation, SubmitRegistrationSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitRegistrationSurveyMutation, SubmitRegistrationSurveyMutationVariables>(SubmitRegistrationSurveyDocument, options);
      }
export type SubmitRegistrationSurveyMutationHookResult = ReturnType<typeof useSubmitRegistrationSurveyMutation>;
export type SubmitRegistrationSurveyMutationResult = Apollo.MutationResult<SubmitRegistrationSurveyMutation>;
export type SubmitRegistrationSurveyMutationOptions = Apollo.BaseMutationOptions<SubmitRegistrationSurveyMutation, SubmitRegistrationSurveyMutationVariables>;
export const AddUserToHouseholdDocument = gql`
    mutation addUserToHousehold($first_name: String, $last_name: String, $email: String!, $signal_color: String, $phone_number: String) {
  addUserToHousehold(
    first_name: $first_name
    last_name: $last_name
    email: $email
    signal_color: $signal_color
    phone_number: $phone_number
  )
}
    `;
export type AddUserToHouseholdMutationFn = Apollo.MutationFunction<AddUserToHouseholdMutation, AddUserToHouseholdMutationVariables>;

/**
 * __useAddUserToHouseholdMutation__
 *
 * To run a mutation, you first call `useAddUserToHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToHouseholdMutation, { data, loading, error }] = useAddUserToHouseholdMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      signal_color: // value for 'signal_color'
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useAddUserToHouseholdMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToHouseholdMutation, AddUserToHouseholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToHouseholdMutation, AddUserToHouseholdMutationVariables>(AddUserToHouseholdDocument, options);
      }
export type AddUserToHouseholdMutationHookResult = ReturnType<typeof useAddUserToHouseholdMutation>;
export type AddUserToHouseholdMutationResult = Apollo.MutationResult<AddUserToHouseholdMutation>;
export type AddUserToHouseholdMutationOptions = Apollo.BaseMutationOptions<AddUserToHouseholdMutation, AddUserToHouseholdMutationVariables>;
export const RemoveUserFromHouseholdDocument = gql`
    mutation removeUserFromHousehold($user_id: String!) {
  removeUserFromHousehold(user_id: $user_id)
}
    `;
export type RemoveUserFromHouseholdMutationFn = Apollo.MutationFunction<RemoveUserFromHouseholdMutation, RemoveUserFromHouseholdMutationVariables>;

/**
 * __useRemoveUserFromHouseholdMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromHouseholdMutation, { data, loading, error }] = useRemoveUserFromHouseholdMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useRemoveUserFromHouseholdMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromHouseholdMutation, RemoveUserFromHouseholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromHouseholdMutation, RemoveUserFromHouseholdMutationVariables>(RemoveUserFromHouseholdDocument, options);
      }
export type RemoveUserFromHouseholdMutationHookResult = ReturnType<typeof useRemoveUserFromHouseholdMutation>;
export type RemoveUserFromHouseholdMutationResult = Apollo.MutationResult<RemoveUserFromHouseholdMutation>;
export type RemoveUserFromHouseholdMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromHouseholdMutation, RemoveUserFromHouseholdMutationVariables>;
export const GetHouseholdMembersDocument = gql`
    query getHouseholdMembers {
  getHouseholdMembers
}
    `;

/**
 * __useGetHouseholdMembersQuery__
 *
 * To run a query within a React component, call `useGetHouseholdMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHouseholdMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>(GetHouseholdMembersDocument, options);
      }
export function useGetHouseholdMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>(GetHouseholdMembersDocument, options);
        }
export function useGetHouseholdMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>(GetHouseholdMembersDocument, options);
        }
export type GetHouseholdMembersQueryHookResult = ReturnType<typeof useGetHouseholdMembersQuery>;
export type GetHouseholdMembersLazyQueryHookResult = ReturnType<typeof useGetHouseholdMembersLazyQuery>;
export type GetHouseholdMembersSuspenseQueryHookResult = ReturnType<typeof useGetHouseholdMembersSuspenseQuery>;
export type GetHouseholdMembersQueryResult = Apollo.QueryResult<GetHouseholdMembersQuery, GetHouseholdMembersQueryVariables>;
export function refetchGetHouseholdMembersQuery(variables?: GetHouseholdMembersQueryVariables) {
      return { query: GetHouseholdMembersDocument, variables: variables }
    }
export const ChangeSubscriptionDocument = gql`
    mutation changeSubscription($shopify_variant_id: String!) {
  changeProductVariant(shopify_variant_id: $shopify_variant_id)
}
    `;
export type ChangeSubscriptionMutationFn = Apollo.MutationFunction<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>;

/**
 * __useChangeSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionMutation, { data, loading, error }] = useChangeSubscriptionMutation({
 *   variables: {
 *      shopify_variant_id: // value for 'shopify_variant_id'
 *   },
 * });
 */
export function useChangeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>(ChangeSubscriptionDocument, options);
      }
export type ChangeSubscriptionMutationHookResult = ReturnType<typeof useChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationResult = Apollo.MutationResult<ChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>;
export const ActivateSubscriptionDocument = gql`
    mutation activateSubscription($shopify_variant_id: String) {
  activateSubscription(shopify_variant_id: $shopify_variant_id)
}
    `;
export type ActivateSubscriptionMutationFn = Apollo.MutationFunction<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;

/**
 * __useActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubscriptionMutation, { data, loading, error }] = useActivateSubscriptionMutation({
 *   variables: {
 *      shopify_variant_id: // value for 'shopify_variant_id'
 *   },
 * });
 */
export function useActivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>(ActivateSubscriptionDocument, options);
      }
export type ActivateSubscriptionMutationHookResult = ReturnType<typeof useActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationResult = Apollo.MutationResult<ActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription {
  cancelSubscription
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const GetSubscriptionDocument = gql`
    query getSubscription {
  getSubscription
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export function useGetSubscriptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionSuspenseQueryHookResult = ReturnType<typeof useGetSubscriptionSuspenseQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export function refetchGetSubscriptionQuery(variables?: GetSubscriptionQueryVariables) {
      return { query: GetSubscriptionDocument, variables: variables }
    }
export const DeleteAccountDocument = gql`
    mutation deleteAccount {
  deleteAccount
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const UpdateUserPhoneEmailDocument = gql`
    mutation updateUserPhoneEmail($id: String!, $email: String!, $phone: String!) {
  updateUser(id: $id, email: $email, phone: $phone) {
    id
    email
    phone
  }
}
    `;
export type UpdateUserPhoneEmailMutationFn = Apollo.MutationFunction<UpdateUserPhoneEmailMutation, UpdateUserPhoneEmailMutationVariables>;

/**
 * __useUpdateUserPhoneEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneEmailMutation, { data, loading, error }] = useUpdateUserPhoneEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserPhoneEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPhoneEmailMutation, UpdateUserPhoneEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPhoneEmailMutation, UpdateUserPhoneEmailMutationVariables>(UpdateUserPhoneEmailDocument, options);
      }
export type UpdateUserPhoneEmailMutationHookResult = ReturnType<typeof useUpdateUserPhoneEmailMutation>;
export type UpdateUserPhoneEmailMutationResult = Apollo.MutationResult<UpdateUserPhoneEmailMutation>;
export type UpdateUserPhoneEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserPhoneEmailMutation, UpdateUserPhoneEmailMutationVariables>;
export const UpdateUserEmailDocument = gql`
    mutation updateUserEmail($id: String!, $email: String!) {
  updateUser(id: $id, email: $email) {
    id
    email
  }
}
    `;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(UpdateUserEmailDocument, options);
      }
export type UpdateUserEmailMutationHookResult = ReturnType<typeof useUpdateUserEmailMutation>;
export type UpdateUserEmailMutationResult = Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export const UpdateUserCuidDocument = gql`
    mutation updateUserCuid($userId: String!, $afWebUserId: String!) {
  update_User(where: {id: {_eq: $userId}}, _set: {cuid: $afWebUserId}) {
    returning {
      id
      birthday
      gender
      email
      firstName
      lastName
      auth0UserId
      cuid
    }
  }
}
    `;
export type UpdateUserCuidMutationFn = Apollo.MutationFunction<UpdateUserCuidMutation, UpdateUserCuidMutationVariables>;

/**
 * __useUpdateUserCuidMutation__
 *
 * To run a mutation, you first call `useUpdateUserCuidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCuidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCuidMutation, { data, loading, error }] = useUpdateUserCuidMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      afWebUserId: // value for 'afWebUserId'
 *   },
 * });
 */
export function useUpdateUserCuidMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCuidMutation, UpdateUserCuidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserCuidMutation, UpdateUserCuidMutationVariables>(UpdateUserCuidDocument, options);
      }
export type UpdateUserCuidMutationHookResult = ReturnType<typeof useUpdateUserCuidMutation>;
export type UpdateUserCuidMutationResult = Apollo.MutationResult<UpdateUserCuidMutation>;
export type UpdateUserCuidMutationOptions = Apollo.BaseMutationOptions<UpdateUserCuidMutation, UpdateUserCuidMutationVariables>;
export const UpdateUserBioDocument = gql`
    mutation updateUserBio($id: String!, $email: String!, $birthday: String!, $firstName: String, $lastName: String, $gender: Gender, $height: Float, $weight: Float) {
  updateUser(
    id: $id
    email: $email
    birthday: $birthday
    firstName: $firstName
    lastName: $lastName
    gender: $gender
    height: $height
    weight: $weight
  ) {
    id
    email
    birthday
    firstName
    lastName
    gender
    height_feet
    height_inches
    weight_lbs
  }
}
    `;
export type UpdateUserBioMutationFn = Apollo.MutationFunction<UpdateUserBioMutation, UpdateUserBioMutationVariables>;

/**
 * __useUpdateUserBioMutation__
 *
 * To run a mutation, you first call `useUpdateUserBioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBioMutation, { data, loading, error }] = useUpdateUserBioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      birthday: // value for 'birthday'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      height: // value for 'height'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateUserBioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBioMutation, UpdateUserBioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserBioMutation, UpdateUserBioMutationVariables>(UpdateUserBioDocument, options);
      }
export type UpdateUserBioMutationHookResult = ReturnType<typeof useUpdateUserBioMutation>;
export type UpdateUserBioMutationResult = Apollo.MutationResult<UpdateUserBioMutation>;
export type UpdateUserBioMutationOptions = Apollo.BaseMutationOptions<UpdateUserBioMutation, UpdateUserBioMutationVariables>;
export const UpdateUserOnboardDocument = gql`
    mutation updateUserOnboard($id: String!, $email: String!, $birthday: String!, $firstName: String!, $lastName: String!, $gender: Gender) {
  updateUser(
    id: $id
    email: $email
    birthday: $birthday
    firstName: $firstName
    lastName: $lastName
    gender: $gender
  ) {
    id
    email
    birthday
    firstName
    lastName
    gender
  }
}
    `;
export type UpdateUserOnboardMutationFn = Apollo.MutationFunction<UpdateUserOnboardMutation, UpdateUserOnboardMutationVariables>;

/**
 * __useUpdateUserOnboardMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnboardMutation, { data, loading, error }] = useUpdateUserOnboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      birthday: // value for 'birthday'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdateUserOnboardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOnboardMutation, UpdateUserOnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOnboardMutation, UpdateUserOnboardMutationVariables>(UpdateUserOnboardDocument, options);
      }
export type UpdateUserOnboardMutationHookResult = ReturnType<typeof useUpdateUserOnboardMutation>;
export type UpdateUserOnboardMutationResult = Apollo.MutationResult<UpdateUserOnboardMutation>;
export type UpdateUserOnboardMutationOptions = Apollo.BaseMutationOptions<UpdateUserOnboardMutation, UpdateUserOnboardMutationVariables>;
export const UpdateUserSystemDocument = gql`
    mutation updateUserSystem($id: String!, $email: String!, $firstName: String, $lastName: String, $signal_color: String, $phone: String) {
  updateUser(
    id: $id
    email: $email
    firstName: $firstName
    lastName: $lastName
    signal_color: $signal_color
    phone: $phone
  ) {
    id
    email
    firstName
    lastName
    strapColor
  }
}
    `;
export type UpdateUserSystemMutationFn = Apollo.MutationFunction<UpdateUserSystemMutation, UpdateUserSystemMutationVariables>;

/**
 * __useUpdateUserSystemMutation__
 *
 * To run a mutation, you first call `useUpdateUserSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSystemMutation, { data, loading, error }] = useUpdateUserSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      signal_color: // value for 'signal_color'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSystemMutation, UpdateUserSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSystemMutation, UpdateUserSystemMutationVariables>(UpdateUserSystemDocument, options);
      }
export type UpdateUserSystemMutationHookResult = ReturnType<typeof useUpdateUserSystemMutation>;
export type UpdateUserSystemMutationResult = Apollo.MutationResult<UpdateUserSystemMutation>;
export type UpdateUserSystemMutationOptions = Apollo.BaseMutationOptions<UpdateUserSystemMutation, UpdateUserSystemMutationVariables>;
export const GetUserDocument = gql`
    query getUser($auth0UserId: String!) {
  User(where: {auth0UserId: {_eq: $auth0UserId}}) {
    id
    birthday
    gender
    email
    firstName
    lastName
    pearUserId
    cuid
    shopifyCustomerId
    height_feet
    height_inches
    weight_lbs
    ethnicity
    Household {
      id
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const FetchUserShippingAddressDocument = gql`
    query fetchUserShippingAddress($userId: String!) {
  fetchUserShippingAddress(userId: $userId) {
    shipping_address {
      address1
      address2
      city
      country
      country_code
      province
      province_code
      zip
    }
  }
}
    `;

/**
 * __useFetchUserShippingAddressQuery__
 *
 * To run a query within a React component, call `useFetchUserShippingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserShippingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserShippingAddressQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchUserShippingAddressQuery(baseOptions: Apollo.QueryHookOptions<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables> & ({ variables: FetchUserShippingAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables>(FetchUserShippingAddressDocument, options);
      }
export function useFetchUserShippingAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables>(FetchUserShippingAddressDocument, options);
        }
export function useFetchUserShippingAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables>(FetchUserShippingAddressDocument, options);
        }
export type FetchUserShippingAddressQueryHookResult = ReturnType<typeof useFetchUserShippingAddressQuery>;
export type FetchUserShippingAddressLazyQueryHookResult = ReturnType<typeof useFetchUserShippingAddressLazyQuery>;
export type FetchUserShippingAddressSuspenseQueryHookResult = ReturnType<typeof useFetchUserShippingAddressSuspenseQuery>;
export type FetchUserShippingAddressQueryResult = Apollo.QueryResult<FetchUserShippingAddressQuery, FetchUserShippingAddressQueryVariables>;
export function refetchFetchUserShippingAddressQuery(variables: FetchUserShippingAddressQueryVariables) {
      return { query: FetchUserShippingAddressDocument, variables: variables }
    }
export const FetchHouseholdDocument = gql`
    query fetchHousehold($id: String!) {
  fetchHousehold(household_id: $id) {
    firstName
    lastName
    owner_user_id
    user_id
  }
}
    `;

/**
 * __useFetchHouseholdQuery__
 *
 * To run a query within a React component, call `useFetchHouseholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHouseholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHouseholdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchHouseholdQuery(baseOptions: Apollo.QueryHookOptions<FetchHouseholdQuery, FetchHouseholdQueryVariables> & ({ variables: FetchHouseholdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchHouseholdQuery, FetchHouseholdQueryVariables>(FetchHouseholdDocument, options);
      }
export function useFetchHouseholdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchHouseholdQuery, FetchHouseholdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchHouseholdQuery, FetchHouseholdQueryVariables>(FetchHouseholdDocument, options);
        }
export function useFetchHouseholdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchHouseholdQuery, FetchHouseholdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchHouseholdQuery, FetchHouseholdQueryVariables>(FetchHouseholdDocument, options);
        }
export type FetchHouseholdQueryHookResult = ReturnType<typeof useFetchHouseholdQuery>;
export type FetchHouseholdLazyQueryHookResult = ReturnType<typeof useFetchHouseholdLazyQuery>;
export type FetchHouseholdSuspenseQueryHookResult = ReturnType<typeof useFetchHouseholdSuspenseQuery>;
export type FetchHouseholdQueryResult = Apollo.QueryResult<FetchHouseholdQuery, FetchHouseholdQueryVariables>;
export function refetchFetchHouseholdQuery(variables: FetchHouseholdQueryVariables) {
      return { query: FetchHouseholdDocument, variables: variables }
    }
export const GetUserProductsDocument = gql`
    query getUserProducts($userId: String!, $systemAndSignalOnly: Boolean!) {
  getUserProducts(userId: $userId, systemAndSignalOnly: $systemAndSignalOnly) {
    id
    product_sku
  }
}
    `;

/**
 * __useGetUserProductsQuery__
 *
 * To run a query within a React component, call `useGetUserProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      systemAndSignalOnly: // value for 'systemAndSignalOnly'
 *   },
 * });
 */
export function useGetUserProductsQuery(baseOptions: Apollo.QueryHookOptions<GetUserProductsQuery, GetUserProductsQueryVariables> & ({ variables: GetUserProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProductsQuery, GetUserProductsQueryVariables>(GetUserProductsDocument, options);
      }
export function useGetUserProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProductsQuery, GetUserProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProductsQuery, GetUserProductsQueryVariables>(GetUserProductsDocument, options);
        }
export function useGetUserProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserProductsQuery, GetUserProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserProductsQuery, GetUserProductsQueryVariables>(GetUserProductsDocument, options);
        }
export type GetUserProductsQueryHookResult = ReturnType<typeof useGetUserProductsQuery>;
export type GetUserProductsLazyQueryHookResult = ReturnType<typeof useGetUserProductsLazyQuery>;
export type GetUserProductsSuspenseQueryHookResult = ReturnType<typeof useGetUserProductsSuspenseQuery>;
export type GetUserProductsQueryResult = Apollo.QueryResult<GetUserProductsQuery, GetUserProductsQueryVariables>;
export function refetchGetUserProductsQuery(variables: GetUserProductsQueryVariables) {
      return { query: GetUserProductsDocument, variables: variables }
    }
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  ActionResultOutput: ResolverTypeWrapper<unknown>;
  ActivationCode: ResolverTypeWrapper<unknown>;
  ActivationCode_aggregate: ResolverTypeWrapper<unknown>;
  ActivationCode_aggregate_fields: ResolverTypeWrapper<unknown>;
  ActivationCode_bool_exp: ResolverTypeWrapper<unknown>;
  ActivationCode_constraint: ResolverTypeWrapper<unknown>;
  ActivationCode_insert_input: ResolverTypeWrapper<unknown>;
  ActivationCode_max_fields: ResolverTypeWrapper<unknown>;
  ActivationCode_min_fields: ResolverTypeWrapper<unknown>;
  ActivationCode_mutation_response: ResolverTypeWrapper<unknown>;
  ActivationCode_on_conflict: ResolverTypeWrapper<unknown>;
  ActivationCode_order_by: ResolverTypeWrapper<unknown>;
  ActivationCode_pk_columns_input: ResolverTypeWrapper<unknown>;
  ActivationCode_select_column: ResolverTypeWrapper<unknown>;
  ActivationCode_set_input: ResolverTypeWrapper<unknown>;
  ActivationCode_stream_cursor_input: ResolverTypeWrapper<unknown>;
  ActivationCode_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  ActivationCode_update_column: ResolverTypeWrapper<unknown>;
  ActivationCode_updates: ResolverTypeWrapper<unknown>;
  ActivityInsightsDetailOutput: ResolverTypeWrapper<unknown>;
  ActivityInsightsMetric: ResolverTypeWrapper<unknown>;
  ActivityInsightsOutput: ResolverTypeWrapper<unknown>;
  ActivityProgressOutput: ResolverTypeWrapper<unknown>;
  AggregatesOutput: ResolverTypeWrapper<unknown>;
  AllUsersOutput: ResolverTypeWrapper<unknown>;
  AlterTypeColor: ResolverTypeWrapper<unknown>;
  AlterTypeColor_aggregate: ResolverTypeWrapper<unknown>;
  AlterTypeColor_aggregate_fields: ResolverTypeWrapper<unknown>;
  AlterTypeColor_bool_exp: ResolverTypeWrapper<unknown>;
  AlterTypeColor_constraint: ResolverTypeWrapper<unknown>;
  AlterTypeColor_insert_input: ResolverTypeWrapper<unknown>;
  AlterTypeColor_max_fields: ResolverTypeWrapper<unknown>;
  AlterTypeColor_min_fields: ResolverTypeWrapper<unknown>;
  AlterTypeColor_mutation_response: ResolverTypeWrapper<unknown>;
  AlterTypeColor_on_conflict: ResolverTypeWrapper<unknown>;
  AlterTypeColor_order_by: ResolverTypeWrapper<unknown>;
  AlterTypeColor_pk_columns_input: ResolverTypeWrapper<unknown>;
  AlterTypeColor_select_column: ResolverTypeWrapper<unknown>;
  AlterTypeColor_set_input: ResolverTypeWrapper<unknown>;
  AlterTypeColor_stream_cursor_input: ResolverTypeWrapper<unknown>;
  AlterTypeColor_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  AlterTypeColor_update_column: ResolverTypeWrapper<unknown>;
  AlterTypeColor_updates: ResolverTypeWrapper<unknown>;
  AssessmentType: ResolverTypeWrapper<unknown>;
  AssessmentTypeEnum: ResolverTypeWrapper<unknown>;
  AssessmentType_comparison_exp: ResolverTypeWrapper<unknown>;
  AssessmentViewEnum: ResolverTypeWrapper<unknown>;
  BiometricEligibility: ResolverTypeWrapper<unknown>;
  Boolean: ResolverTypeWrapper<unknown>;
  Boolean_comparison_exp: ResolverTypeWrapper<unknown>;
  CRUDaction: ResolverTypeWrapper<unknown>;
  CRUDaction_comparison_exp: ResolverTypeWrapper<unknown>;
  CadenceType: ResolverTypeWrapper<unknown>;
  CadenceTypeEnum: ResolverTypeWrapper<unknown>;
  CadenceType_comparison_exp: ResolverTypeWrapper<unknown>;
  CalculateMacroGramsOutput: ResolverTypeWrapper<unknown>;
  CaloricOutput: ResolverTypeWrapper<unknown>;
  ChangeCoachedPlanOutput: ResolverTypeWrapper<unknown>;
  CheckDNAKitOutput: ResolverTypeWrapper<unknown>;
  CheckNutritionReportReleasedOutput: ResolverTypeWrapper<unknown>;
  CheckPresetPlanStructureOutput: ResolverTypeWrapper<unknown>;
  Coach: ResolverTypeWrapper<unknown>;
  Coach_aggregate: ResolverTypeWrapper<unknown>;
  Coach_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Coach_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Coach_aggregate_fields: ResolverTypeWrapper<unknown>;
  Coach_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Coach_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Coach_bool_exp: ResolverTypeWrapper<unknown>;
  Coach_constraint: ResolverTypeWrapper<unknown>;
  Coach_insert_input: ResolverTypeWrapper<unknown>;
  Coach_max_fields: ResolverTypeWrapper<unknown>;
  Coach_max_order_by: ResolverTypeWrapper<unknown>;
  Coach_min_fields: ResolverTypeWrapper<unknown>;
  Coach_min_order_by: ResolverTypeWrapper<unknown>;
  Coach_mutation_response: ResolverTypeWrapper<unknown>;
  Coach_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Coach_on_conflict: ResolverTypeWrapper<unknown>;
  Coach_order_by: ResolverTypeWrapper<unknown>;
  Coach_pk_columns_input: ResolverTypeWrapper<unknown>;
  Coach_select_column: ResolverTypeWrapper<unknown>;
  Coach_set_input: ResolverTypeWrapper<unknown>;
  Coach_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Coach_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Coach_update_column: ResolverTypeWrapper<unknown>;
  Coach_updates: ResolverTypeWrapper<unknown>;
  CoachedHabit: ResolverTypeWrapper<unknown>;
  CoachedHabit_aggregate: ResolverTypeWrapper<unknown>;
  CoachedHabit_aggregate_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_avg_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_bool_exp: ResolverTypeWrapper<unknown>;
  CoachedHabit_constraint: ResolverTypeWrapper<unknown>;
  CoachedHabit_inc_input: ResolverTypeWrapper<unknown>;
  CoachedHabit_insert_input: ResolverTypeWrapper<unknown>;
  CoachedHabit_max_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_min_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_mutation_response: ResolverTypeWrapper<unknown>;
  CoachedHabit_on_conflict: ResolverTypeWrapper<unknown>;
  CoachedHabit_order_by: ResolverTypeWrapper<unknown>;
  CoachedHabit_pk_columns_input: ResolverTypeWrapper<unknown>;
  CoachedHabit_select_column: ResolverTypeWrapper<unknown>;
  CoachedHabit_set_input: ResolverTypeWrapper<unknown>;
  CoachedHabit_stddev_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_stream_cursor_input: ResolverTypeWrapper<unknown>;
  CoachedHabit_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  CoachedHabit_sum_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_update_column: ResolverTypeWrapper<unknown>;
  CoachedHabit_updates: ResolverTypeWrapper<unknown>;
  CoachedHabit_var_pop_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_var_samp_fields: ResolverTypeWrapper<unknown>;
  CoachedHabit_variance_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_aggregate_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_avg_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_avg_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_bool_exp: ResolverTypeWrapper<unknown>;
  CoachedNutrition_constraint: ResolverTypeWrapper<unknown>;
  CoachedNutrition_inc_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_insert_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_max_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_max_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_min_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_min_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_mutation_response: ResolverTypeWrapper<unknown>;
  CoachedNutrition_on_conflict: ResolverTypeWrapper<unknown>;
  CoachedNutrition_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_pk_columns_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_select_column: ResolverTypeWrapper<unknown>;
  CoachedNutrition_select_column_CoachedNutrition_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  CoachedNutrition_select_column_CoachedNutrition_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  CoachedNutrition_set_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stddev_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stddev_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stream_cursor_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  CoachedNutrition_sum_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_sum_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_update_column: ResolverTypeWrapper<unknown>;
  CoachedNutrition_updates: ResolverTypeWrapper<unknown>;
  CoachedNutrition_var_pop_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_var_pop_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_var_samp_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_var_samp_order_by: ResolverTypeWrapper<unknown>;
  CoachedNutrition_variance_fields: ResolverTypeWrapper<unknown>;
  CoachedNutrition_variance_order_by: ResolverTypeWrapper<unknown>;
  ConsultationItems: ResolverTypeWrapper<unknown>;
  CookBookItem: ResolverTypeWrapper<unknown>;
  CreditHistoryOutput: ResolverTypeWrapper<unknown>;
  CrudActionEnum: ResolverTypeWrapper<unknown>;
  CurrentAverage: ResolverTypeWrapper<unknown>;
  CustomPlanDay: ResolverTypeWrapper<unknown>;
  CustomPlanDayInput: ResolverTypeWrapper<unknown>;
  CustomPlanInput: ResolverTypeWrapper<unknown>;
  DMLType: ResolverTypeWrapper<unknown>;
  DMLType_comparison_exp: ResolverTypeWrapper<unknown>;
  DNAKit: ResolverTypeWrapper<unknown>;
  DNAKitResult: ResolverTypeWrapper<unknown>;
  DNAKitRole: ResolverTypeWrapper<unknown>;
  DNAKitRole_comparison_exp: ResolverTypeWrapper<unknown>;
  DNAKit_aggregate: ResolverTypeWrapper<unknown>;
  DNAKit_aggregate_fields: ResolverTypeWrapper<unknown>;
  DNAKit_avg_fields: ResolverTypeWrapper<unknown>;
  DNAKit_bool_exp: ResolverTypeWrapper<unknown>;
  DNAKit_constraint: ResolverTypeWrapper<unknown>;
  DNAKit_inc_input: ResolverTypeWrapper<unknown>;
  DNAKit_insert_input: ResolverTypeWrapper<unknown>;
  DNAKit_max_fields: ResolverTypeWrapper<unknown>;
  DNAKit_min_fields: ResolverTypeWrapper<unknown>;
  DNAKit_mutation_response: ResolverTypeWrapper<unknown>;
  DNAKit_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  DNAKit_on_conflict: ResolverTypeWrapper<unknown>;
  DNAKit_order_by: ResolverTypeWrapper<unknown>;
  DNAKit_pk_columns_input: ResolverTypeWrapper<unknown>;
  DNAKit_select_column: ResolverTypeWrapper<unknown>;
  DNAKit_set_input: ResolverTypeWrapper<unknown>;
  DNAKit_stddev_fields: ResolverTypeWrapper<unknown>;
  DNAKit_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  DNAKit_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  DNAKit_stream_cursor_input: ResolverTypeWrapper<unknown>;
  DNAKit_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  DNAKit_sum_fields: ResolverTypeWrapper<unknown>;
  DNAKit_update_column: ResolverTypeWrapper<unknown>;
  DNAKit_updates: ResolverTypeWrapper<unknown>;
  DNAKit_var_pop_fields: ResolverTypeWrapper<unknown>;
  DNAKit_var_samp_fields: ResolverTypeWrapper<unknown>;
  DNAKit_variance_fields: ResolverTypeWrapper<unknown>;
  DNAResults: ResolverTypeWrapper<unknown>;
  DNAResultsOutput: ResolverTypeWrapper<unknown>;
  DNAResults_aggregate: ResolverTypeWrapper<unknown>;
  DNAResults_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  DNAResults_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  DNAResults_aggregate_fields: ResolverTypeWrapper<unknown>;
  DNAResults_aggregate_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  DNAResults_avg_fields: ResolverTypeWrapper<unknown>;
  DNAResults_avg_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_bool_exp: ResolverTypeWrapper<unknown>;
  DNAResults_constraint: ResolverTypeWrapper<unknown>;
  DNAResults_inc_input: ResolverTypeWrapper<unknown>;
  DNAResults_insert_input: ResolverTypeWrapper<unknown>;
  DNAResults_max_fields: ResolverTypeWrapper<unknown>;
  DNAResults_max_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_min_fields: ResolverTypeWrapper<unknown>;
  DNAResults_min_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_mutation_response: ResolverTypeWrapper<unknown>;
  DNAResults_on_conflict: ResolverTypeWrapper<unknown>;
  DNAResults_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_pk_columns_input: ResolverTypeWrapper<unknown>;
  DNAResults_select_column: ResolverTypeWrapper<unknown>;
  DNAResults_set_input: ResolverTypeWrapper<unknown>;
  DNAResults_stddev_fields: ResolverTypeWrapper<unknown>;
  DNAResults_stddev_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  DNAResults_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  DNAResults_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_stream_cursor_input: ResolverTypeWrapper<unknown>;
  DNAResults_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  DNAResults_sum_fields: ResolverTypeWrapper<unknown>;
  DNAResults_sum_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_update_column: ResolverTypeWrapper<unknown>;
  DNAResults_updates: ResolverTypeWrapper<unknown>;
  DNAResults_var_pop_fields: ResolverTypeWrapper<unknown>;
  DNAResults_var_pop_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_var_samp_fields: ResolverTypeWrapper<unknown>;
  DNAResults_var_samp_order_by: ResolverTypeWrapper<unknown>;
  DNAResults_variance_fields: ResolverTypeWrapper<unknown>;
  DNAResults_variance_order_by: ResolverTypeWrapper<unknown>;
  DailyCheckinOutput: ResolverTypeWrapper<unknown>;
  DailyCheckinV2MasteryObject: ResolverTypeWrapper<unknown>;
  DailyCheckinV2Output: ResolverTypeWrapper<unknown>;
  DailyCheckinV2SleepObject: ResolverTypeWrapper<unknown>;
  Delivery: ResolverTypeWrapper<unknown>;
  DeliveryHistory: ResolverTypeWrapper<unknown>;
  DeliveryHistory_aggregate: ResolverTypeWrapper<unknown>;
  DeliveryHistory_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  DeliveryHistory_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  DeliveryHistory_aggregate_fields: ResolverTypeWrapper<unknown>;
  DeliveryHistory_aggregate_order_by: ResolverTypeWrapper<unknown>;
  DeliveryHistory_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  DeliveryHistory_bool_exp: ResolverTypeWrapper<unknown>;
  DeliveryHistory_constraint: ResolverTypeWrapper<unknown>;
  DeliveryHistory_insert_input: ResolverTypeWrapper<unknown>;
  DeliveryHistory_max_fields: ResolverTypeWrapper<unknown>;
  DeliveryHistory_max_order_by: ResolverTypeWrapper<unknown>;
  DeliveryHistory_min_fields: ResolverTypeWrapper<unknown>;
  DeliveryHistory_min_order_by: ResolverTypeWrapper<unknown>;
  DeliveryHistory_mutation_response: ResolverTypeWrapper<unknown>;
  DeliveryHistory_on_conflict: ResolverTypeWrapper<unknown>;
  DeliveryHistory_order_by: ResolverTypeWrapper<unknown>;
  DeliveryHistory_pk_columns_input: ResolverTypeWrapper<unknown>;
  DeliveryHistory_select_column: ResolverTypeWrapper<unknown>;
  DeliveryHistory_set_input: ResolverTypeWrapper<unknown>;
  DeliveryHistory_stream_cursor_input: ResolverTypeWrapper<unknown>;
  DeliveryHistory_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  DeliveryHistory_update_column: ResolverTypeWrapper<unknown>;
  DeliveryHistory_updates: ResolverTypeWrapper<unknown>;
  DeliveryStatus: ResolverTypeWrapper<unknown>;
  DeliveryStatus_comparison_exp: ResolverTypeWrapper<unknown>;
  Delivery_aggregate: ResolverTypeWrapper<unknown>;
  Delivery_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Delivery_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  Delivery_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  Delivery_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Delivery_aggregate_fields: ResolverTypeWrapper<unknown>;
  Delivery_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Delivery_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Delivery_bool_exp: ResolverTypeWrapper<unknown>;
  Delivery_constraint: ResolverTypeWrapper<unknown>;
  Delivery_insert_input: ResolverTypeWrapper<unknown>;
  Delivery_max_fields: ResolverTypeWrapper<unknown>;
  Delivery_max_order_by: ResolverTypeWrapper<unknown>;
  Delivery_min_fields: ResolverTypeWrapper<unknown>;
  Delivery_min_order_by: ResolverTypeWrapper<unknown>;
  Delivery_mutation_response: ResolverTypeWrapper<unknown>;
  Delivery_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Delivery_on_conflict: ResolverTypeWrapper<unknown>;
  Delivery_order_by: ResolverTypeWrapper<unknown>;
  Delivery_pk_columns_input: ResolverTypeWrapper<unknown>;
  Delivery_select_column: ResolverTypeWrapper<unknown>;
  Delivery_select_column_Delivery_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  Delivery_select_column_Delivery_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  Delivery_set_input: ResolverTypeWrapper<unknown>;
  Delivery_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Delivery_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Delivery_update_column: ResolverTypeWrapper<unknown>;
  Delivery_updates: ResolverTypeWrapper<unknown>;
  Device: ResolverTypeWrapper<unknown>;
  DeviceType: ResolverTypeWrapper<unknown>;
  DeviceType_comparison_exp: ResolverTypeWrapper<unknown>;
  Device_aggregate: ResolverTypeWrapper<unknown>;
  Device_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Device_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Device_aggregate_fields: ResolverTypeWrapper<unknown>;
  Device_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Device_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Device_bool_exp: ResolverTypeWrapper<unknown>;
  Device_constraint: ResolverTypeWrapper<unknown>;
  Device_insert_input: ResolverTypeWrapper<unknown>;
  Device_max_fields: ResolverTypeWrapper<unknown>;
  Device_max_order_by: ResolverTypeWrapper<unknown>;
  Device_min_fields: ResolverTypeWrapper<unknown>;
  Device_min_order_by: ResolverTypeWrapper<unknown>;
  Device_mutation_response: ResolverTypeWrapper<unknown>;
  Device_on_conflict: ResolverTypeWrapper<unknown>;
  Device_order_by: ResolverTypeWrapper<unknown>;
  Device_pk_columns_input: ResolverTypeWrapper<unknown>;
  Device_select_column: ResolverTypeWrapper<unknown>;
  Device_set_input: ResolverTypeWrapper<unknown>;
  Device_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Device_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Device_update_column: ResolverTypeWrapper<unknown>;
  Device_updates: ResolverTypeWrapper<unknown>;
  DevicesType: ResolverTypeWrapper<unknown>;
  Diagnostics: ResolverTypeWrapper<unknown>;
  DiagnosticsDeviceType: ResolverTypeWrapper<unknown>;
  Diagnostics_aggregate: ResolverTypeWrapper<unknown>;
  Diagnostics_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Diagnostics_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Diagnostics_aggregate_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Diagnostics_avg_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_avg_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_bool_exp: ResolverTypeWrapper<unknown>;
  Diagnostics_constraint: ResolverTypeWrapper<unknown>;
  Diagnostics_inc_input: ResolverTypeWrapper<unknown>;
  Diagnostics_insert_input: ResolverTypeWrapper<unknown>;
  Diagnostics_max_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_max_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_min_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_min_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_mutation_response: ResolverTypeWrapper<unknown>;
  Diagnostics_on_conflict: ResolverTypeWrapper<unknown>;
  Diagnostics_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_pk_columns_input: ResolverTypeWrapper<unknown>;
  Diagnostics_select_column: ResolverTypeWrapper<unknown>;
  Diagnostics_set_input: ResolverTypeWrapper<unknown>;
  Diagnostics_stddev_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_stddev_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Diagnostics_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Diagnostics_sum_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_sum_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_update_column: ResolverTypeWrapper<unknown>;
  Diagnostics_updates: ResolverTypeWrapper<unknown>;
  Diagnostics_var_pop_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_var_pop_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_var_samp_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_var_samp_order_by: ResolverTypeWrapper<unknown>;
  Diagnostics_variance_fields: ResolverTypeWrapper<unknown>;
  Diagnostics_variance_order_by: ResolverTypeWrapper<unknown>;
  DietContents: ResolverTypeWrapper<unknown>;
  DifficultyEnum: ResolverTypeWrapper<unknown>;
  EmailHasKitOutput: ResolverTypeWrapper<unknown>;
  EndAssessmentOutput: ResolverTypeWrapper<unknown>;
  EquipmentPreferenceEnum: ResolverTypeWrapper<unknown>;
  Ethnicity: ResolverTypeWrapper<unknown>;
  FeedbackType: ResolverTypeWrapper<unknown>;
  FeedbackTypeEnum: ResolverTypeWrapper<unknown>;
  FeedbackType_comparison_exp: ResolverTypeWrapper<unknown>;
  FetchCoachOutput: ResolverTypeWrapper<unknown>;
  FetchCoachedPlanOutput: ResolverTypeWrapper<unknown>;
  FetchCookBookOutput: ResolverTypeWrapper<unknown>;
  FetchWorkoutOptionsOutput: ResolverTypeWrapper<unknown>;
  FetchWorkoutOptionsOutputV2: ResolverTypeWrapper<unknown>;
  FetchWorkoutOptionsOutputV3: ResolverTypeWrapper<unknown>;
  FileType: ResolverTypeWrapper<unknown>;
  FileType_comparison_exp: ResolverTypeWrapper<unknown>;
  FinancePlan: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_aggregate_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  FinancePlan_avg_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_avg_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_bool_exp: ResolverTypeWrapper<unknown>;
  FinancePlan_constraint: ResolverTypeWrapper<unknown>;
  FinancePlan_inc_input: ResolverTypeWrapper<unknown>;
  FinancePlan_insert_input: ResolverTypeWrapper<unknown>;
  FinancePlan_max_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_max_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_min_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_min_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_mutation_response: ResolverTypeWrapper<unknown>;
  FinancePlan_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  FinancePlan_on_conflict: ResolverTypeWrapper<unknown>;
  FinancePlan_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_pk_columns_input: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_select_column_FinancePlan_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  FinancePlan_set_input: ResolverTypeWrapper<unknown>;
  FinancePlan_stddev_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_stddev_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_stream_cursor_input: ResolverTypeWrapper<unknown>;
  FinancePlan_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  FinancePlan_sum_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_sum_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_update_column: ResolverTypeWrapper<unknown>;
  FinancePlan_updates: ResolverTypeWrapper<unknown>;
  FinancePlan_var_pop_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_var_pop_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_var_samp_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_var_samp_order_by: ResolverTypeWrapper<unknown>;
  FinancePlan_variance_fields: ResolverTypeWrapper<unknown>;
  FinancePlan_variance_order_by: ResolverTypeWrapper<unknown>;
  FinanceStatus: ResolverTypeWrapper<unknown>;
  FinanceStatus_comparison_exp: ResolverTypeWrapper<unknown>;
  FirmwareContent: ResolverTypeWrapper<unknown>;
  FirmwareContent_aggregate: ResolverTypeWrapper<unknown>;
  FirmwareContent_aggregate_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_avg_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_bool_exp: ResolverTypeWrapper<unknown>;
  FirmwareContent_constraint: ResolverTypeWrapper<unknown>;
  FirmwareContent_inc_input: ResolverTypeWrapper<unknown>;
  FirmwareContent_insert_input: ResolverTypeWrapper<unknown>;
  FirmwareContent_max_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_min_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_mutation_response: ResolverTypeWrapper<unknown>;
  FirmwareContent_on_conflict: ResolverTypeWrapper<unknown>;
  FirmwareContent_order_by: ResolverTypeWrapper<unknown>;
  FirmwareContent_pk_columns_input: ResolverTypeWrapper<unknown>;
  FirmwareContent_select_column: ResolverTypeWrapper<unknown>;
  FirmwareContent_set_input: ResolverTypeWrapper<unknown>;
  FirmwareContent_stddev_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_stream_cursor_input: ResolverTypeWrapper<unknown>;
  FirmwareContent_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  FirmwareContent_sum_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_update_column: ResolverTypeWrapper<unknown>;
  FirmwareContent_updates: ResolverTypeWrapper<unknown>;
  FirmwareContent_var_pop_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_var_samp_fields: ResolverTypeWrapper<unknown>;
  FirmwareContent_variance_fields: ResolverTypeWrapper<unknown>;
  FirmwareDevice: ResolverTypeWrapper<unknown>;
  FirmwareDevice_comparison_exp: ResolverTypeWrapper<unknown>;
  FitnessRecommenderOutput: ResolverTypeWrapper<unknown>;
  Float: ResolverTypeWrapper<unknown>;
  Gender: ResolverTypeWrapper<unknown>;
  GeneralRank: ResolverTypeWrapper<unknown>;
  GeneralRankGroup: ResolverTypeWrapper<unknown>;
  GeneralRankGroup_comparison_exp: ResolverTypeWrapper<unknown>;
  GeneralRank_comparison_exp: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_aggregate: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_aggregate_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_aggregate_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_append_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_avg_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_avg_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_bool_exp: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_constraint: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_delete_at_path_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_delete_elem_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_delete_key_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_inc_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_insert_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_max_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_max_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_min_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_min_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_mutation_response: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_on_conflict: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_pk_columns_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_prepend_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_select_column: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_set_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stddev_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stddev_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stream_cursor_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_sum_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_sum_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_update_column: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_updates: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_var_pop_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_var_pop_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_var_samp_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_var_samp_order_by: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_variance_fields: ResolverTypeWrapper<unknown>;
  GeneticDirectionWebhook_variance_order_by: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_aggregate: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_aggregate_fields: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_bool_exp: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_constraint: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_insert_input: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_max_fields: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_min_fields: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_mutation_response: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_on_conflict: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_order_by: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_pk_columns_input: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_select_column: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_set_input: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_stream_cursor_input: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_update_column: ResolverTypeWrapper<unknown>;
  GlobalFeatureControl_updates: ResolverTypeWrapper<unknown>;
  Goal: ResolverTypeWrapper<unknown>;
  Goal_aggregate: ResolverTypeWrapper<unknown>;
  Goal_aggregate_fields: ResolverTypeWrapper<unknown>;
  Goal_bool_exp: ResolverTypeWrapper<unknown>;
  Goal_constraint: ResolverTypeWrapper<unknown>;
  Goal_insert_input: ResolverTypeWrapper<unknown>;
  Goal_max_fields: ResolverTypeWrapper<unknown>;
  Goal_min_fields: ResolverTypeWrapper<unknown>;
  Goal_mutation_response: ResolverTypeWrapper<unknown>;
  Goal_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Goal_on_conflict: ResolverTypeWrapper<unknown>;
  Goal_order_by: ResolverTypeWrapper<unknown>;
  Goal_pk_columns_input: ResolverTypeWrapper<unknown>;
  Goal_select_column: ResolverTypeWrapper<unknown>;
  Goal_set_input: ResolverTypeWrapper<unknown>;
  Goal_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Goal_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Goal_update_column: ResolverTypeWrapper<unknown>;
  Goal_updates: ResolverTypeWrapper<unknown>;
  HRDiagnosticData: ResolverTypeWrapper<unknown>;
  HRDiagnosticType: ResolverTypeWrapper<unknown>;
  HabitCompletedResult: ResolverTypeWrapper<unknown>;
  HabitDay: ResolverTypeWrapper<unknown>;
  HabitDayOutput: ResolverTypeWrapper<unknown>;
  HabitDay_aggregate: ResolverTypeWrapper<unknown>;
  HabitDay_aggregate_fields: ResolverTypeWrapper<unknown>;
  HabitDay_avg_fields: ResolverTypeWrapper<unknown>;
  HabitDay_bool_exp: ResolverTypeWrapper<unknown>;
  HabitDay_constraint: ResolverTypeWrapper<unknown>;
  HabitDay_inc_input: ResolverTypeWrapper<unknown>;
  HabitDay_insert_input: ResolverTypeWrapper<unknown>;
  HabitDay_max_fields: ResolverTypeWrapper<unknown>;
  HabitDay_min_fields: ResolverTypeWrapper<unknown>;
  HabitDay_mutation_response: ResolverTypeWrapper<unknown>;
  HabitDay_on_conflict: ResolverTypeWrapper<unknown>;
  HabitDay_order_by: ResolverTypeWrapper<unknown>;
  HabitDay_pk_columns_input: ResolverTypeWrapper<unknown>;
  HabitDay_select_column: ResolverTypeWrapper<unknown>;
  HabitDay_set_input: ResolverTypeWrapper<unknown>;
  HabitDay_stddev_fields: ResolverTypeWrapper<unknown>;
  HabitDay_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  HabitDay_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  HabitDay_stream_cursor_input: ResolverTypeWrapper<unknown>;
  HabitDay_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  HabitDay_sum_fields: ResolverTypeWrapper<unknown>;
  HabitDay_update_column: ResolverTypeWrapper<unknown>;
  HabitDay_updates: ResolverTypeWrapper<unknown>;
  HabitDay_var_pop_fields: ResolverTypeWrapper<unknown>;
  HabitDay_var_samp_fields: ResolverTypeWrapper<unknown>;
  HabitDay_variance_fields: ResolverTypeWrapper<unknown>;
  HabitSubType: ResolverTypeWrapper<unknown>;
  HabitSubTypeEnum: ResolverTypeWrapper<unknown>;
  HabitSubType_comparison_exp: ResolverTypeWrapper<unknown>;
  HabitType: ResolverTypeWrapper<unknown>;
  HabitTypeEnum: ResolverTypeWrapper<unknown>;
  HabitType_comparison_exp: ResolverTypeWrapper<unknown>;
  HardwareTypeEnum: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_aggregate: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_aggregate_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_aggregate_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_avg_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_avg_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_bool_exp: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_constraint: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_inc_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_insert_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_max_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_max_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_min_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_min_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_mutation_response: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_on_conflict: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_pk_columns_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_select_column: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_set_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stddev_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stddev_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stream_cursor_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_sum_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_sum_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_update_column: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_updates: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_var_pop_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_var_pop_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_var_samp_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_var_samp_order_by: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_variance_fields: ResolverTypeWrapper<unknown>;
  HeartRateDiagnostics_variance_order_by: ResolverTypeWrapper<unknown>;
  HighKneeSetOutput: ResolverTypeWrapper<unknown>;
  HistoryAndCounts: ResolverTypeWrapper<unknown>;
  Household: ResolverTypeWrapper<unknown>;
  HouseholdOutput: ResolverTypeWrapper<unknown>;
  HouseholdType: ResolverTypeWrapper<unknown>;
  HouseholdType_comparison_exp: ResolverTypeWrapper<unknown>;
  Household_aggregate: ResolverTypeWrapper<unknown>;
  Household_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Household_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Household_aggregate_fields: ResolverTypeWrapper<unknown>;
  Household_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Household_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Household_avg_fields: ResolverTypeWrapper<unknown>;
  Household_avg_order_by: ResolverTypeWrapper<unknown>;
  Household_bool_exp: ResolverTypeWrapper<unknown>;
  Household_constraint: ResolverTypeWrapper<unknown>;
  Household_inc_input: ResolverTypeWrapper<unknown>;
  Household_insert_input: ResolverTypeWrapper<unknown>;
  Household_max_fields: ResolverTypeWrapper<unknown>;
  Household_max_order_by: ResolverTypeWrapper<unknown>;
  Household_min_fields: ResolverTypeWrapper<unknown>;
  Household_min_order_by: ResolverTypeWrapper<unknown>;
  Household_mutation_response: ResolverTypeWrapper<unknown>;
  Household_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Household_on_conflict: ResolverTypeWrapper<unknown>;
  Household_order_by: ResolverTypeWrapper<unknown>;
  Household_pk_columns_input: ResolverTypeWrapper<unknown>;
  Household_select_column: ResolverTypeWrapper<unknown>;
  Household_set_input: ResolverTypeWrapper<unknown>;
  Household_stddev_fields: ResolverTypeWrapper<unknown>;
  Household_stddev_order_by: ResolverTypeWrapper<unknown>;
  Household_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Household_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  Household_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Household_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  Household_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Household_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Household_sum_fields: ResolverTypeWrapper<unknown>;
  Household_sum_order_by: ResolverTypeWrapper<unknown>;
  Household_update_column: ResolverTypeWrapper<unknown>;
  Household_updates: ResolverTypeWrapper<unknown>;
  Household_var_pop_fields: ResolverTypeWrapper<unknown>;
  Household_var_pop_order_by: ResolverTypeWrapper<unknown>;
  Household_var_samp_fields: ResolverTypeWrapper<unknown>;
  Household_var_samp_order_by: ResolverTypeWrapper<unknown>;
  Household_variance_fields: ResolverTypeWrapper<unknown>;
  Household_variance_order_by: ResolverTypeWrapper<unknown>;
  HrZone2Output: ResolverTypeWrapper<unknown>;
  InsightsDetailGroupBy: ResolverTypeWrapper<unknown>;
  Int: ResolverTypeWrapper<unknown>;
  Int_array_comparison_exp: ResolverTypeWrapper<unknown>;
  Int_comparison_exp: ResolverTypeWrapper<unknown>;
  IsValidKitOutput: ResolverTypeWrapper<unknown>;
  LabelsOutput: ResolverTypeWrapper<unknown>;
  MarketingEmail: ResolverTypeWrapper<unknown>;
  MarketingEmail_aggregate: ResolverTypeWrapper<unknown>;
  MarketingEmail_aggregate_fields: ResolverTypeWrapper<unknown>;
  MarketingEmail_bool_exp: ResolverTypeWrapper<unknown>;
  MarketingEmail_constraint: ResolverTypeWrapper<unknown>;
  MarketingEmail_insert_input: ResolverTypeWrapper<unknown>;
  MarketingEmail_max_fields: ResolverTypeWrapper<unknown>;
  MarketingEmail_min_fields: ResolverTypeWrapper<unknown>;
  MarketingEmail_mutation_response: ResolverTypeWrapper<unknown>;
  MarketingEmail_on_conflict: ResolverTypeWrapper<unknown>;
  MarketingEmail_order_by: ResolverTypeWrapper<unknown>;
  MarketingEmail_pk_columns_input: ResolverTypeWrapper<unknown>;
  MarketingEmail_select_column: ResolverTypeWrapper<unknown>;
  MarketingEmail_set_input: ResolverTypeWrapper<unknown>;
  MarketingEmail_stream_cursor_input: ResolverTypeWrapper<unknown>;
  MarketingEmail_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  MarketingEmail_update_column: ResolverTypeWrapper<unknown>;
  MarketingEmail_updates: ResolverTypeWrapper<unknown>;
  Mastery: ResolverTypeWrapper<unknown>;
  Mastery_aggregate: ResolverTypeWrapper<unknown>;
  Mastery_aggregate_fields: ResolverTypeWrapper<unknown>;
  Mastery_avg_fields: ResolverTypeWrapper<unknown>;
  Mastery_bool_exp: ResolverTypeWrapper<unknown>;
  Mastery_constraint: ResolverTypeWrapper<unknown>;
  Mastery_inc_input: ResolverTypeWrapper<unknown>;
  Mastery_insert_input: ResolverTypeWrapper<unknown>;
  Mastery_max_fields: ResolverTypeWrapper<unknown>;
  Mastery_min_fields: ResolverTypeWrapper<unknown>;
  Mastery_mutation_response: ResolverTypeWrapper<unknown>;
  Mastery_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Mastery_on_conflict: ResolverTypeWrapper<unknown>;
  Mastery_order_by: ResolverTypeWrapper<unknown>;
  Mastery_pk_columns_input: ResolverTypeWrapper<unknown>;
  Mastery_select_column: ResolverTypeWrapper<unknown>;
  Mastery_set_input: ResolverTypeWrapper<unknown>;
  Mastery_stddev_fields: ResolverTypeWrapper<unknown>;
  Mastery_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Mastery_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Mastery_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Mastery_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Mastery_sum_fields: ResolverTypeWrapper<unknown>;
  Mastery_update_column: ResolverTypeWrapper<unknown>;
  Mastery_updates: ResolverTypeWrapper<unknown>;
  Mastery_var_pop_fields: ResolverTypeWrapper<unknown>;
  Mastery_var_samp_fields: ResolverTypeWrapper<unknown>;
  Mastery_variance_fields: ResolverTypeWrapper<unknown>;
  MealKeyEnum: ResolverTypeWrapper<unknown>;
  MealPlan: ResolverTypeWrapper<unknown>;
  MealPlanOutput: ResolverTypeWrapper<unknown>;
  MealPlanPreferenceMapItem: ResolverTypeWrapper<unknown>;
  MealPlanPreferenceOptionCategoryEnum: ResolverTypeWrapper<unknown>;
  MealPlanPreferenceOutput: ResolverTypeWrapper<unknown>;
  MealPlanPreferences: ResolverTypeWrapper<unknown>;
  MealPlanSelectionTarget: ResolverTypeWrapper<unknown>;
  NotInPlanType: ResolverTypeWrapper<unknown>;
  NutritionReportOutput: ResolverTypeWrapper<unknown>;
  OnboardingEntityType: ResolverTypeWrapper<unknown>;
  OnboardingEntityType_comparison_exp: ResolverTypeWrapper<unknown>;
  OnboardingInfo: ResolverTypeWrapper<unknown>;
  OnboardingInfo_aggregate: ResolverTypeWrapper<unknown>;
  OnboardingInfo_aggregate_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_avg_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_bool_exp: ResolverTypeWrapper<unknown>;
  OnboardingInfo_constraint: ResolverTypeWrapper<unknown>;
  OnboardingInfo_inc_input: ResolverTypeWrapper<unknown>;
  OnboardingInfo_insert_input: ResolverTypeWrapper<unknown>;
  OnboardingInfo_max_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_min_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_mutation_response: ResolverTypeWrapper<unknown>;
  OnboardingInfo_on_conflict: ResolverTypeWrapper<unknown>;
  OnboardingInfo_order_by: ResolverTypeWrapper<unknown>;
  OnboardingInfo_pk_columns_input: ResolverTypeWrapper<unknown>;
  OnboardingInfo_select_column: ResolverTypeWrapper<unknown>;
  OnboardingInfo_set_input: ResolverTypeWrapper<unknown>;
  OnboardingInfo_stddev_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_stream_cursor_input: ResolverTypeWrapper<unknown>;
  OnboardingInfo_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  OnboardingInfo_sum_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_update_column: ResolverTypeWrapper<unknown>;
  OnboardingInfo_updates: ResolverTypeWrapper<unknown>;
  OnboardingInfo_var_pop_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_var_samp_fields: ResolverTypeWrapper<unknown>;
  OnboardingInfo_variance_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos: ResolverTypeWrapper<unknown>;
  OnboardingVideos_aggregate: ResolverTypeWrapper<unknown>;
  OnboardingVideos_aggregate_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_avg_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_bool_exp: ResolverTypeWrapper<unknown>;
  OnboardingVideos_constraint: ResolverTypeWrapper<unknown>;
  OnboardingVideos_inc_input: ResolverTypeWrapper<unknown>;
  OnboardingVideos_insert_input: ResolverTypeWrapper<unknown>;
  OnboardingVideos_max_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_min_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_mutation_response: ResolverTypeWrapper<unknown>;
  OnboardingVideos_on_conflict: ResolverTypeWrapper<unknown>;
  OnboardingVideos_order_by: ResolverTypeWrapper<unknown>;
  OnboardingVideos_pk_columns_input: ResolverTypeWrapper<unknown>;
  OnboardingVideos_select_column: ResolverTypeWrapper<unknown>;
  OnboardingVideos_set_input: ResolverTypeWrapper<unknown>;
  OnboardingVideos_stddev_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_stream_cursor_input: ResolverTypeWrapper<unknown>;
  OnboardingVideos_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  OnboardingVideos_sum_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_update_column: ResolverTypeWrapper<unknown>;
  OnboardingVideos_updates: ResolverTypeWrapper<unknown>;
  OnboardingVideos_var_pop_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_var_samp_fields: ResolverTypeWrapper<unknown>;
  OnboardingVideos_variance_fields: ResolverTypeWrapper<unknown>;
  Order: ResolverTypeWrapper<unknown>;
  OrderLineItem: ResolverTypeWrapper<unknown>;
  OrderLineItem_aggregate: ResolverTypeWrapper<unknown>;
  OrderLineItem_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  OrderLineItem_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  OrderLineItem_aggregate_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_aggregate_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_avg_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_avg_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_bool_exp: ResolverTypeWrapper<unknown>;
  OrderLineItem_constraint: ResolverTypeWrapper<unknown>;
  OrderLineItem_inc_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_insert_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_max_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_max_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_min_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_min_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_mutation_response: ResolverTypeWrapper<unknown>;
  OrderLineItem_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_on_conflict: ResolverTypeWrapper<unknown>;
  OrderLineItem_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_pk_columns_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_select_column: ResolverTypeWrapper<unknown>;
  OrderLineItem_set_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_stddev_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_stddev_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_stream_cursor_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  OrderLineItem_sum_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_sum_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_update_column: ResolverTypeWrapper<unknown>;
  OrderLineItem_updates: ResolverTypeWrapper<unknown>;
  OrderLineItem_var_pop_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_var_pop_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_var_samp_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_var_samp_order_by: ResolverTypeWrapper<unknown>;
  OrderLineItem_variance_fields: ResolverTypeWrapper<unknown>;
  OrderLineItem_variance_order_by: ResolverTypeWrapper<unknown>;
  Order_aggregate: ResolverTypeWrapper<unknown>;
  Order_aggregate_fields: ResolverTypeWrapper<unknown>;
  Order_append_input: ResolverTypeWrapper<unknown>;
  Order_avg_fields: ResolverTypeWrapper<unknown>;
  Order_bool_exp: ResolverTypeWrapper<unknown>;
  Order_constraint: ResolverTypeWrapper<unknown>;
  Order_delete_at_path_input: ResolverTypeWrapper<unknown>;
  Order_delete_elem_input: ResolverTypeWrapper<unknown>;
  Order_delete_key_input: ResolverTypeWrapper<unknown>;
  Order_inc_input: ResolverTypeWrapper<unknown>;
  Order_insert_input: ResolverTypeWrapper<unknown>;
  Order_max_fields: ResolverTypeWrapper<unknown>;
  Order_min_fields: ResolverTypeWrapper<unknown>;
  Order_mutation_response: ResolverTypeWrapper<unknown>;
  Order_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Order_on_conflict: ResolverTypeWrapper<unknown>;
  Order_order_by: ResolverTypeWrapper<unknown>;
  Order_pk_columns_input: ResolverTypeWrapper<unknown>;
  Order_prepend_input: ResolverTypeWrapper<unknown>;
  Order_select_column: ResolverTypeWrapper<unknown>;
  Order_set_input: ResolverTypeWrapper<unknown>;
  Order_stddev_fields: ResolverTypeWrapper<unknown>;
  Order_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Order_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Order_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Order_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Order_sum_fields: ResolverTypeWrapper<unknown>;
  Order_update_column: ResolverTypeWrapper<unknown>;
  Order_updates: ResolverTypeWrapper<unknown>;
  Order_var_pop_fields: ResolverTypeWrapper<unknown>;
  Order_var_samp_fields: ResolverTypeWrapper<unknown>;
  Order_variance_fields: ResolverTypeWrapper<unknown>;
  PauseOrResumeEnum: ResolverTypeWrapper<unknown>;
  PauseOrResumeOutput: ResolverTypeWrapper<unknown>;
  PaymentHistory: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_aggregate_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_avg_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_avg_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_bool_exp: ResolverTypeWrapper<unknown>;
  PaymentHistory_constraint: ResolverTypeWrapper<unknown>;
  PaymentHistory_inc_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_insert_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_max_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_max_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_min_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_min_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_mutation_response: ResolverTypeWrapper<unknown>;
  PaymentHistory_on_conflict: ResolverTypeWrapper<unknown>;
  PaymentHistory_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_pk_columns_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_select_column_PaymentHistory_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  PaymentHistory_set_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_stddev_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_stddev_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_stream_cursor_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  PaymentHistory_sum_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_sum_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_update_column: ResolverTypeWrapper<unknown>;
  PaymentHistory_updates: ResolverTypeWrapper<unknown>;
  PaymentHistory_var_pop_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_var_pop_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_var_samp_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_var_samp_order_by: ResolverTypeWrapper<unknown>;
  PaymentHistory_variance_fields: ResolverTypeWrapper<unknown>;
  PaymentHistory_variance_order_by: ResolverTypeWrapper<unknown>;
  PearTokens: ResolverTypeWrapper<unknown>;
  PlanDay: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate_fields: ResolverTypeWrapper<unknown>;
  PlanDay_aggregate_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  PlanDay_avg_fields: ResolverTypeWrapper<unknown>;
  PlanDay_avg_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_bool_exp: ResolverTypeWrapper<unknown>;
  PlanDay_constraint: ResolverTypeWrapper<unknown>;
  PlanDay_inc_input: ResolverTypeWrapper<unknown>;
  PlanDay_insert_input: ResolverTypeWrapper<unknown>;
  PlanDay_max_fields: ResolverTypeWrapper<unknown>;
  PlanDay_max_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_min_fields: ResolverTypeWrapper<unknown>;
  PlanDay_min_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_mutation_response: ResolverTypeWrapper<unknown>;
  PlanDay_on_conflict: ResolverTypeWrapper<unknown>;
  PlanDay_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_pk_columns_input: ResolverTypeWrapper<unknown>;
  PlanDay_select_column: ResolverTypeWrapper<unknown>;
  PlanDay_select_column_PlanDay_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  PlanDay_select_column_PlanDay_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  PlanDay_set_input: ResolverTypeWrapper<unknown>;
  PlanDay_stddev_fields: ResolverTypeWrapper<unknown>;
  PlanDay_stddev_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  PlanDay_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  PlanDay_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_stream_cursor_input: ResolverTypeWrapper<unknown>;
  PlanDay_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  PlanDay_sum_fields: ResolverTypeWrapper<unknown>;
  PlanDay_sum_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_update_column: ResolverTypeWrapper<unknown>;
  PlanDay_updates: ResolverTypeWrapper<unknown>;
  PlanDay_var_pop_fields: ResolverTypeWrapper<unknown>;
  PlanDay_var_pop_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_var_samp_fields: ResolverTypeWrapper<unknown>;
  PlanDay_var_samp_order_by: ResolverTypeWrapper<unknown>;
  PlanDay_variance_fields: ResolverTypeWrapper<unknown>;
  PlanDay_variance_order_by: ResolverTypeWrapper<unknown>;
  PlanProgressCounts: ResolverTypeWrapper<unknown>;
  PlanProgressOutput: ResolverTypeWrapper<unknown>;
  PlanProgressRange: ResolverTypeWrapper<unknown>;
  PlanType: ResolverTypeWrapper<unknown>;
  PlanTypeEnum: ResolverTypeWrapper<unknown>;
  PlanTypeObject: ResolverTypeWrapper<unknown>;
  PlanType_comparison_exp: ResolverTypeWrapper<unknown>;
  PresetDiet: ResolverTypeWrapper<unknown>;
  PresetDiet_aggregate: ResolverTypeWrapper<unknown>;
  PresetDiet_aggregate_fields: ResolverTypeWrapper<unknown>;
  PresetDiet_bool_exp: ResolverTypeWrapper<unknown>;
  PresetDiet_constraint: ResolverTypeWrapper<unknown>;
  PresetDiet_insert_input: ResolverTypeWrapper<unknown>;
  PresetDiet_max_fields: ResolverTypeWrapper<unknown>;
  PresetDiet_min_fields: ResolverTypeWrapper<unknown>;
  PresetDiet_mutation_response: ResolverTypeWrapper<unknown>;
  PresetDiet_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  PresetDiet_on_conflict: ResolverTypeWrapper<unknown>;
  PresetDiet_order_by: ResolverTypeWrapper<unknown>;
  PresetDiet_pk_columns_input: ResolverTypeWrapper<unknown>;
  PresetDiet_select_column: ResolverTypeWrapper<unknown>;
  PresetDiet_set_input: ResolverTypeWrapper<unknown>;
  PresetDiet_stream_cursor_input: ResolverTypeWrapper<unknown>;
  PresetDiet_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  PresetDiet_update_column: ResolverTypeWrapper<unknown>;
  PresetDiet_updates: ResolverTypeWrapper<unknown>;
  PresetPlanDay: ResolverTypeWrapper<unknown>;
  PresetPlans: ResolverTypeWrapper<unknown>;
  Pro: ResolverTypeWrapper<unknown>;
  ProMusic: ResolverTypeWrapper<unknown>;
  ProMusic_aggregate: ResolverTypeWrapper<unknown>;
  ProMusic_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  ProMusic_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  ProMusic_aggregate_fields: ResolverTypeWrapper<unknown>;
  ProMusic_aggregate_order_by: ResolverTypeWrapper<unknown>;
  ProMusic_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  ProMusic_bool_exp: ResolverTypeWrapper<unknown>;
  ProMusic_constraint: ResolverTypeWrapper<unknown>;
  ProMusic_insert_input: ResolverTypeWrapper<unknown>;
  ProMusic_max_fields: ResolverTypeWrapper<unknown>;
  ProMusic_max_order_by: ResolverTypeWrapper<unknown>;
  ProMusic_min_fields: ResolverTypeWrapper<unknown>;
  ProMusic_min_order_by: ResolverTypeWrapper<unknown>;
  ProMusic_mutation_response: ResolverTypeWrapper<unknown>;
  ProMusic_on_conflict: ResolverTypeWrapper<unknown>;
  ProMusic_order_by: ResolverTypeWrapper<unknown>;
  ProMusic_pk_columns_input: ResolverTypeWrapper<unknown>;
  ProMusic_select_column: ResolverTypeWrapper<unknown>;
  ProMusic_set_input: ResolverTypeWrapper<unknown>;
  ProMusic_stream_cursor_input: ResolverTypeWrapper<unknown>;
  ProMusic_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  ProMusic_update_column: ResolverTypeWrapper<unknown>;
  ProMusic_updates: ResolverTypeWrapper<unknown>;
  Pro_aggregate: ResolverTypeWrapper<unknown>;
  Pro_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Pro_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  Pro_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  Pro_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Pro_aggregate_fields: ResolverTypeWrapper<unknown>;
  Pro_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Pro_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Pro_bool_exp: ResolverTypeWrapper<unknown>;
  Pro_constraint: ResolverTypeWrapper<unknown>;
  Pro_insert_input: ResolverTypeWrapper<unknown>;
  Pro_max_fields: ResolverTypeWrapper<unknown>;
  Pro_max_order_by: ResolverTypeWrapper<unknown>;
  Pro_min_fields: ResolverTypeWrapper<unknown>;
  Pro_min_order_by: ResolverTypeWrapper<unknown>;
  Pro_mutation_response: ResolverTypeWrapper<unknown>;
  Pro_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Pro_on_conflict: ResolverTypeWrapper<unknown>;
  Pro_order_by: ResolverTypeWrapper<unknown>;
  Pro_pk_columns_input: ResolverTypeWrapper<unknown>;
  Pro_select_column: ResolverTypeWrapper<unknown>;
  Pro_select_column_Pro_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  Pro_select_column_Pro_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  Pro_set_input: ResolverTypeWrapper<unknown>;
  Pro_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Pro_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Pro_update_column: ResolverTypeWrapper<unknown>;
  Pro_updates: ResolverTypeWrapper<unknown>;
  Product: ResolverTypeWrapper<unknown>;
  ProductDelivery: ResolverTypeWrapper<unknown>;
  ProductLog: ResolverTypeWrapper<unknown>;
  ProductLog_aggregate: ResolverTypeWrapper<unknown>;
  ProductLog_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  ProductLog_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  ProductLog_aggregate_fields: ResolverTypeWrapper<unknown>;
  ProductLog_aggregate_order_by: ResolverTypeWrapper<unknown>;
  ProductLog_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  ProductLog_bool_exp: ResolverTypeWrapper<unknown>;
  ProductLog_constraint: ResolverTypeWrapper<unknown>;
  ProductLog_insert_input: ResolverTypeWrapper<unknown>;
  ProductLog_max_fields: ResolverTypeWrapper<unknown>;
  ProductLog_max_order_by: ResolverTypeWrapper<unknown>;
  ProductLog_min_fields: ResolverTypeWrapper<unknown>;
  ProductLog_min_order_by: ResolverTypeWrapper<unknown>;
  ProductLog_mutation_response: ResolverTypeWrapper<unknown>;
  ProductLog_on_conflict: ResolverTypeWrapper<unknown>;
  ProductLog_order_by: ResolverTypeWrapper<unknown>;
  ProductLog_pk_columns_input: ResolverTypeWrapper<unknown>;
  ProductLog_select_column: ResolverTypeWrapper<unknown>;
  ProductLog_set_input: ResolverTypeWrapper<unknown>;
  ProductLog_stream_cursor_input: ResolverTypeWrapper<unknown>;
  ProductLog_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  ProductLog_update_column: ResolverTypeWrapper<unknown>;
  ProductLog_updates: ResolverTypeWrapper<unknown>;
  Product_aggregate: ResolverTypeWrapper<unknown>;
  Product_aggregate_fields: ResolverTypeWrapper<unknown>;
  Product_avg_fields: ResolverTypeWrapper<unknown>;
  Product_bool_exp: ResolverTypeWrapper<unknown>;
  Product_constraint: ResolverTypeWrapper<unknown>;
  Product_inc_input: ResolverTypeWrapper<unknown>;
  Product_insert_input: ResolverTypeWrapper<unknown>;
  Product_max_fields: ResolverTypeWrapper<unknown>;
  Product_min_fields: ResolverTypeWrapper<unknown>;
  Product_mutation_response: ResolverTypeWrapper<unknown>;
  Product_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Product_on_conflict: ResolverTypeWrapper<unknown>;
  Product_order_by: ResolverTypeWrapper<unknown>;
  Product_pk_columns_input: ResolverTypeWrapper<unknown>;
  Product_select_column: ResolverTypeWrapper<unknown>;
  Product_set_input: ResolverTypeWrapper<unknown>;
  Product_stddev_fields: ResolverTypeWrapper<unknown>;
  Product_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Product_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Product_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Product_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Product_sum_fields: ResolverTypeWrapper<unknown>;
  Product_update_column: ResolverTypeWrapper<unknown>;
  Product_updates: ResolverTypeWrapper<unknown>;
  Product_var_pop_fields: ResolverTypeWrapper<unknown>;
  Product_var_samp_fields: ResolverTypeWrapper<unknown>;
  Product_variance_fields: ResolverTypeWrapper<unknown>;
  ProfileStatsOutput: ResolverTypeWrapper<unknown>;
  PromotionalItem: ResolverTypeWrapper<unknown>;
  PromotionalItem_aggregate: ResolverTypeWrapper<unknown>;
  PromotionalItem_aggregate_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_avg_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_bool_exp: ResolverTypeWrapper<unknown>;
  PromotionalItem_constraint: ResolverTypeWrapper<unknown>;
  PromotionalItem_inc_input: ResolverTypeWrapper<unknown>;
  PromotionalItem_insert_input: ResolverTypeWrapper<unknown>;
  PromotionalItem_max_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_min_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_mutation_response: ResolverTypeWrapper<unknown>;
  PromotionalItem_on_conflict: ResolverTypeWrapper<unknown>;
  PromotionalItem_order_by: ResolverTypeWrapper<unknown>;
  PromotionalItem_pk_columns_input: ResolverTypeWrapper<unknown>;
  PromotionalItem_select_column: ResolverTypeWrapper<unknown>;
  PromotionalItem_set_input: ResolverTypeWrapper<unknown>;
  PromotionalItem_stddev_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_stream_cursor_input: ResolverTypeWrapper<unknown>;
  PromotionalItem_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  PromotionalItem_sum_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_update_column: ResolverTypeWrapper<unknown>;
  PromotionalItem_updates: ResolverTypeWrapper<unknown>;
  PromotionalItem_var_pop_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_var_samp_fields: ResolverTypeWrapper<unknown>;
  PromotionalItem_variance_fields: ResolverTypeWrapper<unknown>;
  Props: ResolverTypeWrapper<unknown>;
  QuizSurvey: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_aggregate_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_avg_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_avg_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_bool_exp: ResolverTypeWrapper<unknown>;
  QuizSurvey_constraint: ResolverTypeWrapper<unknown>;
  QuizSurvey_inc_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_insert_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_max_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_max_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_min_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_min_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_mutation_response: ResolverTypeWrapper<unknown>;
  QuizSurvey_on_conflict: ResolverTypeWrapper<unknown>;
  QuizSurvey_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_pk_columns_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_select_column: ResolverTypeWrapper<unknown>;
  QuizSurvey_select_column_QuizSurvey_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  QuizSurvey_select_column_QuizSurvey_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  QuizSurvey_set_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_stddev_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_stddev_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_stream_cursor_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  QuizSurvey_sum_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_sum_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_update_column: ResolverTypeWrapper<unknown>;
  QuizSurvey_updates: ResolverTypeWrapper<unknown>;
  QuizSurvey_var_pop_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_var_pop_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_var_samp_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_var_samp_order_by: ResolverTypeWrapper<unknown>;
  QuizSurvey_variance_fields: ResolverTypeWrapper<unknown>;
  QuizSurvey_variance_order_by: ResolverTypeWrapper<unknown>;
  Quote: ResolverTypeWrapper<unknown>;
  Quote_aggregate: ResolverTypeWrapper<unknown>;
  Quote_aggregate_fields: ResolverTypeWrapper<unknown>;
  Quote_bool_exp: ResolverTypeWrapper<unknown>;
  Quote_constraint: ResolverTypeWrapper<unknown>;
  Quote_insert_input: ResolverTypeWrapper<unknown>;
  Quote_max_fields: ResolverTypeWrapper<unknown>;
  Quote_min_fields: ResolverTypeWrapper<unknown>;
  Quote_mutation_response: ResolverTypeWrapper<unknown>;
  Quote_on_conflict: ResolverTypeWrapper<unknown>;
  Quote_order_by: ResolverTypeWrapper<unknown>;
  Quote_pk_columns_input: ResolverTypeWrapper<unknown>;
  Quote_select_column: ResolverTypeWrapper<unknown>;
  Quote_set_input: ResolverTypeWrapper<unknown>;
  Quote_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Quote_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Quote_update_column: ResolverTypeWrapper<unknown>;
  Quote_updates: ResolverTypeWrapper<unknown>;
  RecommenderGeneralRank: ResolverTypeWrapper<unknown>;
  RecommenderGeneralRankGroup: ResolverTypeWrapper<unknown>;
  RecommenderOutput: ResolverTypeWrapper<unknown>;
  RecommenderV2CardioAlternatives: ResolverTypeWrapper<unknown>;
  RecommenderV2CardioObj: ResolverTypeWrapper<unknown>;
  RecommenderV2Output: ResolverTypeWrapper<unknown>;
  RecommenderV2WorkinAlternatives: ResolverTypeWrapper<unknown>;
  RecommenderV2WorkinObj: ResolverTypeWrapper<unknown>;
  RecommenderWorkinObj: ResolverTypeWrapper<unknown>;
  RecommenderWorkinSubAlternativeObj: ResolverTypeWrapper<unknown>;
  RecommenderWorkinSubObj: ResolverTypeWrapper<unknown>;
  RegAreasNeedingHelp: ResolverTypeWrapper<unknown>;
  RegFavoriteExercise: ResolverTypeWrapper<unknown>;
  RegFitnessAbility: ResolverTypeWrapper<unknown>;
  RegGender: ResolverTypeWrapper<unknown>;
  RegMotivation: ResolverTypeWrapper<unknown>;
  RegServicesTried: ResolverTypeWrapper<unknown>;
  RegWeightLossGoal: ResolverTypeWrapper<unknown>;
  RegWeightLossTimeline: ResolverTypeWrapper<unknown>;
  RegisterUser: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_aggregate: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_aggregate_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_avg_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_bool_exp: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_constraint: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_inc_input: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_insert_input: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_max_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_min_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_mutation_response: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_on_conflict: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_order_by: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_pk_columns_input: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_select_column: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_set_input: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_stddev_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_stream_cursor_input: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_sum_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_update_column: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_updates: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_var_pop_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_var_samp_fields: ResolverTypeWrapper<unknown>;
  ReimbursementRequirement_variance_fields: ResolverTypeWrapper<unknown>;
  RepItemInput: ResolverTypeWrapper<unknown>;
  RepMasteryResponse: ResolverTypeWrapper<unknown>;
  Response: ResolverTypeWrapper<unknown>;
  ResultListOutput: ResolverTypeWrapper<unknown>;
  ResultObjectOutput: ResolverTypeWrapper<unknown>;
  RetrieveFirmwareV2Output: ResolverTypeWrapper<unknown>;
  Role: ResolverTypeWrapper<unknown>;
  Role_comparison_exp: ResolverTypeWrapper<unknown>;
  SampleInput: ResolverTypeWrapper<unknown>;
  SampleOutput: ResolverTypeWrapper<unknown>;
  SampleQuizSurveyOutput: ResolverTypeWrapper<unknown>;
  SampleScreenOutput: ResolverTypeWrapper<unknown>;
  SampleWorkoutZoneOutput: ResolverTypeWrapper<unknown>;
  ScreenOrder: ResolverTypeWrapper<unknown>;
  ScreenOrder_aggregate: ResolverTypeWrapper<unknown>;
  ScreenOrder_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  ScreenOrder_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  ScreenOrder_aggregate_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_aggregate_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_append_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_avg_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_avg_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_bool_exp: ResolverTypeWrapper<unknown>;
  ScreenOrder_constraint: ResolverTypeWrapper<unknown>;
  ScreenOrder_delete_at_path_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_delete_elem_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_delete_key_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_inc_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_insert_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_max_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_max_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_min_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_min_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_mutation_response: ResolverTypeWrapper<unknown>;
  ScreenOrder_on_conflict: ResolverTypeWrapper<unknown>;
  ScreenOrder_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_pk_columns_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_prepend_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_select_column: ResolverTypeWrapper<unknown>;
  ScreenOrder_set_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_stddev_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_stddev_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_stream_cursor_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  ScreenOrder_sum_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_sum_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_update_column: ResolverTypeWrapper<unknown>;
  ScreenOrder_updates: ResolverTypeWrapper<unknown>;
  ScreenOrder_var_pop_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_var_pop_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_var_samp_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_var_samp_order_by: ResolverTypeWrapper<unknown>;
  ScreenOrder_variance_fields: ResolverTypeWrapper<unknown>;
  ScreenOrder_variance_order_by: ResolverTypeWrapper<unknown>;
  SearchResult: ResolverTypeWrapper<unknown>;
  SearchResult_aggregate: ResolverTypeWrapper<unknown>;
  SearchResult_aggregate_fields: ResolverTypeWrapper<unknown>;
  SearchResult_avg_fields: ResolverTypeWrapper<unknown>;
  SearchResult_bool_exp: ResolverTypeWrapper<unknown>;
  SearchResult_constraint: ResolverTypeWrapper<unknown>;
  SearchResult_inc_input: ResolverTypeWrapper<unknown>;
  SearchResult_insert_input: ResolverTypeWrapper<unknown>;
  SearchResult_max_fields: ResolverTypeWrapper<unknown>;
  SearchResult_min_fields: ResolverTypeWrapper<unknown>;
  SearchResult_mutation_response: ResolverTypeWrapper<unknown>;
  SearchResult_on_conflict: ResolverTypeWrapper<unknown>;
  SearchResult_order_by: ResolverTypeWrapper<unknown>;
  SearchResult_pk_columns_input: ResolverTypeWrapper<unknown>;
  SearchResult_select_column: ResolverTypeWrapper<unknown>;
  SearchResult_set_input: ResolverTypeWrapper<unknown>;
  SearchResult_stddev_fields: ResolverTypeWrapper<unknown>;
  SearchResult_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  SearchResult_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  SearchResult_stream_cursor_input: ResolverTypeWrapper<unknown>;
  SearchResult_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  SearchResult_sum_fields: ResolverTypeWrapper<unknown>;
  SearchResult_update_column: ResolverTypeWrapper<unknown>;
  SearchResult_updates: ResolverTypeWrapper<unknown>;
  SearchResult_var_pop_fields: ResolverTypeWrapper<unknown>;
  SearchResult_var_samp_fields: ResolverTypeWrapper<unknown>;
  SearchResult_variance_fields: ResolverTypeWrapper<unknown>;
  SetQuality: ResolverTypeWrapper<unknown>;
  SetQualityEnum: ResolverTypeWrapper<unknown>;
  SetQuality_comparison_exp: ResolverTypeWrapper<unknown>;
  SetUserGoalsOutput: ResolverTypeWrapper<unknown>;
  SfCoachCallOutput: ResolverTypeWrapper<unknown>;
  SfFitnessGoalOutput: ResolverTypeWrapper<unknown>;
  ShipStatusMessage: ResolverTypeWrapper<unknown>;
  ShippingAddressOutput: ResolverTypeWrapper<unknown>;
  SleepInsightsDetailOutput: ResolverTypeWrapper<unknown>;
  SleepInsightsMetric: ResolverTypeWrapper<unknown>;
  SleepInsightsOutput: ResolverTypeWrapper<unknown>;
  SleepSurvey: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate_fields: ResolverTypeWrapper<unknown>;
  SleepSurvey_aggregate_order_by: ResolverTypeWrapper<unknown>;
  SleepSurvey_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  SleepSurvey_bool_exp: ResolverTypeWrapper<unknown>;
  SleepSurvey_constraint: ResolverTypeWrapper<unknown>;
  SleepSurvey_insert_input: ResolverTypeWrapper<unknown>;
  SleepSurvey_max_fields: ResolverTypeWrapper<unknown>;
  SleepSurvey_max_order_by: ResolverTypeWrapper<unknown>;
  SleepSurvey_min_fields: ResolverTypeWrapper<unknown>;
  SleepSurvey_min_order_by: ResolverTypeWrapper<unknown>;
  SleepSurvey_mutation_response: ResolverTypeWrapper<unknown>;
  SleepSurvey_on_conflict: ResolverTypeWrapper<unknown>;
  SleepSurvey_order_by: ResolverTypeWrapper<unknown>;
  SleepSurvey_pk_columns_input: ResolverTypeWrapper<unknown>;
  SleepSurvey_select_column: ResolverTypeWrapper<unknown>;
  SleepSurvey_select_column_SleepSurvey_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  SleepSurvey_select_column_SleepSurvey_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  SleepSurvey_set_input: ResolverTypeWrapper<unknown>;
  SleepSurvey_stream_cursor_input: ResolverTypeWrapper<unknown>;
  SleepSurvey_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  SleepSurvey_update_column: ResolverTypeWrapper<unknown>;
  SleepSurvey_updates: ResolverTypeWrapper<unknown>;
  StartAssessmentOutput: ResolverTypeWrapper<unknown>;
  StepsV2Output: ResolverTypeWrapper<unknown>;
  StrapLocation: ResolverTypeWrapper<unknown>;
  StrapLocationEnum: ResolverTypeWrapper<unknown>;
  StrapLocation_comparison_exp: ResolverTypeWrapper<unknown>;
  String: ResolverTypeWrapper<unknown>;
  String_array_comparison_exp: ResolverTypeWrapper<unknown>;
  String_comparison_exp: ResolverTypeWrapper<unknown>;
  SubWorkoutType: ResolverTypeWrapper<unknown>;
  SubWorkoutType_comparison_exp: ResolverTypeWrapper<unknown>;
  Subscription: ResolverTypeWrapper<unknown>;
  SubscriptionLog: ResolverTypeWrapper<unknown>;
  SubscriptionLog_aggregate: ResolverTypeWrapper<unknown>;
  SubscriptionLog_aggregate_fields: ResolverTypeWrapper<unknown>;
  SubscriptionLog_bool_exp: ResolverTypeWrapper<unknown>;
  SubscriptionLog_constraint: ResolverTypeWrapper<unknown>;
  SubscriptionLog_insert_input: ResolverTypeWrapper<unknown>;
  SubscriptionLog_max_fields: ResolverTypeWrapper<unknown>;
  SubscriptionLog_min_fields: ResolverTypeWrapper<unknown>;
  SubscriptionLog_mutation_response: ResolverTypeWrapper<unknown>;
  SubscriptionLog_on_conflict: ResolverTypeWrapper<unknown>;
  SubscriptionLog_order_by: ResolverTypeWrapper<unknown>;
  SubscriptionLog_pk_columns_input: ResolverTypeWrapper<unknown>;
  SubscriptionLog_select_column: ResolverTypeWrapper<unknown>;
  SubscriptionLog_set_input: ResolverTypeWrapper<unknown>;
  SubscriptionLog_stream_cursor_input: ResolverTypeWrapper<unknown>;
  SubscriptionLog_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  SubscriptionLog_update_column: ResolverTypeWrapper<unknown>;
  SubscriptionLog_updates: ResolverTypeWrapper<unknown>;
  SubscriptionType: ResolverTypeWrapper<unknown>;
  SubscriptionType_comparison_exp: ResolverTypeWrapper<unknown>;
  Subscription_aggregate: ResolverTypeWrapper<unknown>;
  Subscription_aggregate_fields: ResolverTypeWrapper<unknown>;
  Subscription_avg_fields: ResolverTypeWrapper<unknown>;
  Subscription_bool_exp: ResolverTypeWrapper<unknown>;
  Subscription_constraint: ResolverTypeWrapper<unknown>;
  Subscription_inc_input: ResolverTypeWrapper<unknown>;
  Subscription_insert_input: ResolverTypeWrapper<unknown>;
  Subscription_max_fields: ResolverTypeWrapper<unknown>;
  Subscription_min_fields: ResolverTypeWrapper<unknown>;
  Subscription_mutation_response: ResolverTypeWrapper<unknown>;
  Subscription_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Subscription_on_conflict: ResolverTypeWrapper<unknown>;
  Subscription_order_by: ResolverTypeWrapper<unknown>;
  Subscription_pk_columns_input: ResolverTypeWrapper<unknown>;
  Subscription_select_column: ResolverTypeWrapper<unknown>;
  Subscription_set_input: ResolverTypeWrapper<unknown>;
  Subscription_stddev_fields: ResolverTypeWrapper<unknown>;
  Subscription_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Subscription_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Subscription_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Subscription_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Subscription_sum_fields: ResolverTypeWrapper<unknown>;
  Subscription_update_column: ResolverTypeWrapper<unknown>;
  Subscription_updates: ResolverTypeWrapper<unknown>;
  Subscription_var_pop_fields: ResolverTypeWrapper<unknown>;
  Subscription_var_samp_fields: ResolverTypeWrapper<unknown>;
  Subscription_variance_fields: ResolverTypeWrapper<unknown>;
  SurveyActivity: ResolverTypeWrapper<unknown>;
  SurveyActivity_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyAreasNeedingHelp: ResolverTypeWrapper<unknown>;
  SurveyAreasNeedingHelp_array_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyEquipment: ResolverTypeWrapper<unknown>;
  SurveyEquipmentEnum: ResolverTypeWrapper<unknown>;
  SurveyEquipment_array_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyEquipment_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyFavoriteExercise: ResolverTypeWrapper<unknown>;
  SurveyFavoriteExercise_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyFitnessAbility: ResolverTypeWrapper<unknown>;
  SurveyFitnessAbility_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyMotivation: ResolverTypeWrapper<unknown>;
  SurveyMotivationEnum: ResolverTypeWrapper<unknown>;
  SurveyMotivation_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyQuizActivity: ResolverTypeWrapper<unknown>;
  SurveyQuizAge: ResolverTypeWrapper<unknown>;
  SurveyQuizAge_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyQuizGender: ResolverTypeWrapper<unknown>;
  SurveyQuizGenderEnum: ResolverTypeWrapper<unknown>;
  SurveyQuizGender_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyServicesTried: ResolverTypeWrapper<unknown>;
  SurveyServicesTried_array_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveySleep: ResolverTypeWrapper<unknown>;
  SurveySleepEnum: ResolverTypeWrapper<unknown>;
  SurveySleep_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyWeightLossGoal: ResolverTypeWrapper<unknown>;
  SurveyWeightLossGoal_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyWeightLossTimeline: ResolverTypeWrapper<unknown>;
  SurveyWeightLossTimeline_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyWorkouts: ResolverTypeWrapper<unknown>;
  SurveyWorkoutsEnum: ResolverTypeWrapper<unknown>;
  SurveyWorkouts_array_comparison_exp: ResolverTypeWrapper<unknown>;
  SurveyWorkouts_comparison_exp: ResolverTypeWrapper<unknown>;
  ThemeColor: ResolverTypeWrapper<unknown>;
  ThemeColorEnum: ResolverTypeWrapper<unknown>;
  ThemeColor_comparison_exp: ResolverTypeWrapper<unknown>;
  TimeMovingOutput: ResolverTypeWrapper<unknown>;
  TimeWorkingOutOutput: ResolverTypeWrapper<unknown>;
  TitleWithDescription: ResolverTypeWrapper<unknown>;
  Token: ResolverTypeWrapper<unknown>;
  TokenChangeSource: ResolverTypeWrapper<unknown>;
  TokenChangeSource_comparison_exp: ResolverTypeWrapper<unknown>;
  TokenChangeType: ResolverTypeWrapper<unknown>;
  TokenChangeType_comparison_exp: ResolverTypeWrapper<unknown>;
  TokenHistory: ResolverTypeWrapper<unknown>;
  TokenHistory_aggregate: ResolverTypeWrapper<unknown>;
  TokenHistory_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  TokenHistory_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  TokenHistory_aggregate_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_aggregate_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  TokenHistory_avg_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_avg_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_bool_exp: ResolverTypeWrapper<unknown>;
  TokenHistory_constraint: ResolverTypeWrapper<unknown>;
  TokenHistory_inc_input: ResolverTypeWrapper<unknown>;
  TokenHistory_insert_input: ResolverTypeWrapper<unknown>;
  TokenHistory_max_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_max_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_min_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_min_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_mutation_response: ResolverTypeWrapper<unknown>;
  TokenHistory_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  TokenHistory_on_conflict: ResolverTypeWrapper<unknown>;
  TokenHistory_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_pk_columns_input: ResolverTypeWrapper<unknown>;
  TokenHistory_select_column: ResolverTypeWrapper<unknown>;
  TokenHistory_set_input: ResolverTypeWrapper<unknown>;
  TokenHistory_stddev_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_stddev_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_stream_cursor_input: ResolverTypeWrapper<unknown>;
  TokenHistory_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  TokenHistory_sum_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_sum_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_update_column: ResolverTypeWrapper<unknown>;
  TokenHistory_updates: ResolverTypeWrapper<unknown>;
  TokenHistory_var_pop_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_var_pop_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_var_samp_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_var_samp_order_by: ResolverTypeWrapper<unknown>;
  TokenHistory_variance_fields: ResolverTypeWrapper<unknown>;
  TokenHistory_variance_order_by: ResolverTypeWrapper<unknown>;
  Token_aggregate: ResolverTypeWrapper<unknown>;
  Token_aggregate_fields: ResolverTypeWrapper<unknown>;
  Token_avg_fields: ResolverTypeWrapper<unknown>;
  Token_bool_exp: ResolverTypeWrapper<unknown>;
  Token_constraint: ResolverTypeWrapper<unknown>;
  Token_inc_input: ResolverTypeWrapper<unknown>;
  Token_insert_input: ResolverTypeWrapper<unknown>;
  Token_max_fields: ResolverTypeWrapper<unknown>;
  Token_min_fields: ResolverTypeWrapper<unknown>;
  Token_mutation_response: ResolverTypeWrapper<unknown>;
  Token_on_conflict: ResolverTypeWrapper<unknown>;
  Token_order_by: ResolverTypeWrapper<unknown>;
  Token_pk_columns_input: ResolverTypeWrapper<unknown>;
  Token_select_column: ResolverTypeWrapper<unknown>;
  Token_set_input: ResolverTypeWrapper<unknown>;
  Token_stddev_fields: ResolverTypeWrapper<unknown>;
  Token_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Token_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Token_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Token_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Token_sum_fields: ResolverTypeWrapper<unknown>;
  Token_update_column: ResolverTypeWrapper<unknown>;
  Token_updates: ResolverTypeWrapper<unknown>;
  Token_var_pop_fields: ResolverTypeWrapper<unknown>;
  Token_var_samp_fields: ResolverTypeWrapper<unknown>;
  Token_variance_fields: ResolverTypeWrapper<unknown>;
  TutorialMode: ResolverTypeWrapper<unknown>;
  TutorialModeEnum: ResolverTypeWrapper<unknown>;
  TutorialMode_comparison_exp: ResolverTypeWrapper<unknown>;
  Unit: ResolverTypeWrapper<unknown>;
  Unit_comparison_exp: ResolverTypeWrapper<unknown>;
  UploadContents: ResolverTypeWrapper<unknown>;
  UploadSetOutput: ResolverTypeWrapper<unknown>;
  UploadSetV2Output: ResolverTypeWrapper<unknown>;
  User: ResolverTypeWrapper<unknown>;
  UserAggregates: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserAggregates_avg_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_avg_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_bool_exp: ResolverTypeWrapper<unknown>;
  UserAggregates_constraint: ResolverTypeWrapper<unknown>;
  UserAggregates_inc_input: ResolverTypeWrapper<unknown>;
  UserAggregates_insert_input: ResolverTypeWrapper<unknown>;
  UserAggregates_max_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_max_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_min_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_min_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_mutation_response: ResolverTypeWrapper<unknown>;
  UserAggregates_on_conflict: ResolverTypeWrapper<unknown>;
  UserAggregates_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_select_column_UserAggregates_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAggregates_set_input: ResolverTypeWrapper<unknown>;
  UserAggregates_stddev_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserAggregates_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserAggregates_sum_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_sum_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_update_column: ResolverTypeWrapper<unknown>;
  UserAggregates_updates: ResolverTypeWrapper<unknown>;
  UserAggregates_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserAggregates_variance_fields: ResolverTypeWrapper<unknown>;
  UserAggregates_variance_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_append_input: ResolverTypeWrapper<unknown>;
  UserAssessment_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserAssessment_avg_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_avg_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_bool_exp: ResolverTypeWrapper<unknown>;
  UserAssessment_constraint: ResolverTypeWrapper<unknown>;
  UserAssessment_delete_at_path_input: ResolverTypeWrapper<unknown>;
  UserAssessment_delete_elem_input: ResolverTypeWrapper<unknown>;
  UserAssessment_delete_key_input: ResolverTypeWrapper<unknown>;
  UserAssessment_inc_input: ResolverTypeWrapper<unknown>;
  UserAssessment_insert_input: ResolverTypeWrapper<unknown>;
  UserAssessment_max_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_max_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_min_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_min_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_mutation_response: ResolverTypeWrapper<unknown>;
  UserAssessment_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserAssessment_on_conflict: ResolverTypeWrapper<unknown>;
  UserAssessment_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserAssessment_prepend_input: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_select_column_UserAssessment_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserAssessment_set_input: ResolverTypeWrapper<unknown>;
  UserAssessment_stddev_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserAssessment_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserAssessment_sum_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_sum_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_update_column: ResolverTypeWrapper<unknown>;
  UserAssessment_updates: ResolverTypeWrapper<unknown>;
  UserAssessment_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserAssessment_variance_fields: ResolverTypeWrapper<unknown>;
  UserAssessment_variance_order_by: ResolverTypeWrapper<unknown>;
  UserFeatureControl: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserFeatureControl_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserFeatureControl_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserFeatureControl_bool_exp: ResolverTypeWrapper<unknown>;
  UserFeatureControl_constraint: ResolverTypeWrapper<unknown>;
  UserFeatureControl_insert_input: ResolverTypeWrapper<unknown>;
  UserFeatureControl_max_fields: ResolverTypeWrapper<unknown>;
  UserFeatureControl_max_order_by: ResolverTypeWrapper<unknown>;
  UserFeatureControl_min_fields: ResolverTypeWrapper<unknown>;
  UserFeatureControl_min_order_by: ResolverTypeWrapper<unknown>;
  UserFeatureControl_mutation_response: ResolverTypeWrapper<unknown>;
  UserFeatureControl_on_conflict: ResolverTypeWrapper<unknown>;
  UserFeatureControl_order_by: ResolverTypeWrapper<unknown>;
  UserFeatureControl_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserFeatureControl_select_column: ResolverTypeWrapper<unknown>;
  UserFeatureControl_select_column_UserFeatureControl_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  UserFeatureControl_select_column_UserFeatureControl_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  UserFeatureControl_set_input: ResolverTypeWrapper<unknown>;
  UserFeatureControl_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserFeatureControl_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserFeatureControl_update_column: ResolverTypeWrapper<unknown>;
  UserFeatureControl_updates: ResolverTypeWrapper<unknown>;
  UserFeedback: ResolverTypeWrapper<unknown>;
  UserFeedbackInput: ResolverTypeWrapper<unknown>;
  UserFeedback_aggregate: ResolverTypeWrapper<unknown>;
  UserFeedback_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserFeedback_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserFeedback_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserFeedback_avg_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_avg_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_bool_exp: ResolverTypeWrapper<unknown>;
  UserFeedback_constraint: ResolverTypeWrapper<unknown>;
  UserFeedback_inc_input: ResolverTypeWrapper<unknown>;
  UserFeedback_insert_input: ResolverTypeWrapper<unknown>;
  UserFeedback_max_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_max_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_min_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_min_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_mutation_response: ResolverTypeWrapper<unknown>;
  UserFeedback_on_conflict: ResolverTypeWrapper<unknown>;
  UserFeedback_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserFeedback_select_column: ResolverTypeWrapper<unknown>;
  UserFeedback_set_input: ResolverTypeWrapper<unknown>;
  UserFeedback_stddev_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserFeedback_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserFeedback_sum_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_sum_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_update_column: ResolverTypeWrapper<unknown>;
  UserFeedback_updates: ResolverTypeWrapper<unknown>;
  UserFeedback_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserFeedback_variance_fields: ResolverTypeWrapper<unknown>;
  UserFeedback_variance_order_by: ResolverTypeWrapper<unknown>;
  UserFinancePlan: ResolverTypeWrapper<unknown>;
  UserFinancePlan_aggregate: ResolverTypeWrapper<unknown>;
  UserFinancePlan_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserFinancePlan_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserFinancePlan_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserFinancePlan_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserFinancePlan_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_bool_exp: ResolverTypeWrapper<unknown>;
  UserFinancePlan_constraint: ResolverTypeWrapper<unknown>;
  UserFinancePlan_insert_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_max_fields: ResolverTypeWrapper<unknown>;
  UserFinancePlan_max_order_by: ResolverTypeWrapper<unknown>;
  UserFinancePlan_min_fields: ResolverTypeWrapper<unknown>;
  UserFinancePlan_min_order_by: ResolverTypeWrapper<unknown>;
  UserFinancePlan_mutation_response: ResolverTypeWrapper<unknown>;
  UserFinancePlan_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_on_conflict: ResolverTypeWrapper<unknown>;
  UserFinancePlan_order_by: ResolverTypeWrapper<unknown>;
  UserFinancePlan_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_select_column: ResolverTypeWrapper<unknown>;
  UserFinancePlan_set_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserFinancePlan_update_column: ResolverTypeWrapper<unknown>;
  UserFinancePlan_updates: ResolverTypeWrapper<unknown>;
  UserHabit: ResolverTypeWrapper<unknown>;
  UserHabit_aggregate: ResolverTypeWrapper<unknown>;
  UserHabit_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserHabit_avg_fields: ResolverTypeWrapper<unknown>;
  UserHabit_bool_exp: ResolverTypeWrapper<unknown>;
  UserHabit_constraint: ResolverTypeWrapper<unknown>;
  UserHabit_inc_input: ResolverTypeWrapper<unknown>;
  UserHabit_insert_input: ResolverTypeWrapper<unknown>;
  UserHabit_max_fields: ResolverTypeWrapper<unknown>;
  UserHabit_min_fields: ResolverTypeWrapper<unknown>;
  UserHabit_mutation_response: ResolverTypeWrapper<unknown>;
  UserHabit_on_conflict: ResolverTypeWrapper<unknown>;
  UserHabit_order_by: ResolverTypeWrapper<unknown>;
  UserHabit_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserHabit_select_column: ResolverTypeWrapper<unknown>;
  UserHabit_set_input: ResolverTypeWrapper<unknown>;
  UserHabit_stddev_fields: ResolverTypeWrapper<unknown>;
  UserHabit_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserHabit_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserHabit_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserHabit_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserHabit_sum_fields: ResolverTypeWrapper<unknown>;
  UserHabit_update_column: ResolverTypeWrapper<unknown>;
  UserHabit_updates: ResolverTypeWrapper<unknown>;
  UserHabit_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserHabit_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserHabit_variance_fields: ResolverTypeWrapper<unknown>;
  UserMonthsActiveOutput: ResolverTypeWrapper<unknown>;
  UserMovementDaily: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_avg_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_avg_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_bool_exp: ResolverTypeWrapper<unknown>;
  UserMovementDaily_constraint: ResolverTypeWrapper<unknown>;
  UserMovementDaily_inc_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_insert_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_max_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_max_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_min_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_min_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_mutation_response: ResolverTypeWrapper<unknown>;
  UserMovementDaily_on_conflict: ResolverTypeWrapper<unknown>;
  UserMovementDaily_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_select_column_UserMovementDaily_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserMovementDaily_set_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stddev_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserMovementDaily_sum_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_sum_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_update_column: ResolverTypeWrapper<unknown>;
  UserMovementDaily_updates: ResolverTypeWrapper<unknown>;
  UserMovementDaily_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserMovementDaily_variance_fields: ResolverTypeWrapper<unknown>;
  UserMovementDaily_variance_order_by: ResolverTypeWrapper<unknown>;
  UserPreferences: ResolverTypeWrapper<unknown>;
  UserPreferencesOutput: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserPreferences_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserPreferences_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserPreferences_bool_exp: ResolverTypeWrapper<unknown>;
  UserPreferences_constraint: ResolverTypeWrapper<unknown>;
  UserPreferences_insert_input: ResolverTypeWrapper<unknown>;
  UserPreferences_max_fields: ResolverTypeWrapper<unknown>;
  UserPreferences_max_order_by: ResolverTypeWrapper<unknown>;
  UserPreferences_min_fields: ResolverTypeWrapper<unknown>;
  UserPreferences_min_order_by: ResolverTypeWrapper<unknown>;
  UserPreferences_mutation_response: ResolverTypeWrapper<unknown>;
  UserPreferences_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserPreferences_on_conflict: ResolverTypeWrapper<unknown>;
  UserPreferences_order_by: ResolverTypeWrapper<unknown>;
  UserPreferences_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserPreferences_select_column: ResolverTypeWrapper<unknown>;
  UserPreferences_select_column_UserPreferences_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  UserPreferences_select_column_UserPreferences_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  UserPreferences_set_input: ResolverTypeWrapper<unknown>;
  UserPreferences_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserPreferences_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserPreferences_update_column: ResolverTypeWrapper<unknown>;
  UserPreferences_updates: ResolverTypeWrapper<unknown>;
  UserProduct: ResolverTypeWrapper<unknown>;
  UserProductHistory: ResolverTypeWrapper<unknown>;
  UserProductHistory_aggregate: ResolverTypeWrapper<unknown>;
  UserProductHistory_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserProductHistory_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserProductHistory_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserProductHistory_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserProductHistory_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserProductHistory_bool_exp: ResolverTypeWrapper<unknown>;
  UserProductHistory_constraint: ResolverTypeWrapper<unknown>;
  UserProductHistory_insert_input: ResolverTypeWrapper<unknown>;
  UserProductHistory_max_fields: ResolverTypeWrapper<unknown>;
  UserProductHistory_max_order_by: ResolverTypeWrapper<unknown>;
  UserProductHistory_min_fields: ResolverTypeWrapper<unknown>;
  UserProductHistory_min_order_by: ResolverTypeWrapper<unknown>;
  UserProductHistory_mutation_response: ResolverTypeWrapper<unknown>;
  UserProductHistory_on_conflict: ResolverTypeWrapper<unknown>;
  UserProductHistory_order_by: ResolverTypeWrapper<unknown>;
  UserProductHistory_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserProductHistory_select_column: ResolverTypeWrapper<unknown>;
  UserProductHistory_set_input: ResolverTypeWrapper<unknown>;
  UserProductHistory_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserProductHistory_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserProductHistory_update_column: ResolverTypeWrapper<unknown>;
  UserProductHistory_updates: ResolverTypeWrapper<unknown>;
  UserProduct_aggregate: ResolverTypeWrapper<unknown>;
  UserProduct_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserProduct_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserProduct_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserProduct_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserProduct_avg_fields: ResolverTypeWrapper<unknown>;
  UserProduct_avg_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_bool_exp: ResolverTypeWrapper<unknown>;
  UserProduct_constraint: ResolverTypeWrapper<unknown>;
  UserProduct_inc_input: ResolverTypeWrapper<unknown>;
  UserProduct_insert_input: ResolverTypeWrapper<unknown>;
  UserProduct_max_fields: ResolverTypeWrapper<unknown>;
  UserProduct_max_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_min_fields: ResolverTypeWrapper<unknown>;
  UserProduct_min_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_mutation_response: ResolverTypeWrapper<unknown>;
  UserProduct_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserProduct_on_conflict: ResolverTypeWrapper<unknown>;
  UserProduct_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserProduct_select_column: ResolverTypeWrapper<unknown>;
  UserProduct_set_input: ResolverTypeWrapper<unknown>;
  UserProduct_stddev_fields: ResolverTypeWrapper<unknown>;
  UserProduct_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserProduct_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserProduct_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserProduct_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserProduct_sum_fields: ResolverTypeWrapper<unknown>;
  UserProduct_sum_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_update_column: ResolverTypeWrapper<unknown>;
  UserProduct_updates: ResolverTypeWrapper<unknown>;
  UserProduct_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserProduct_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserProduct_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserProduct_variance_fields: ResolverTypeWrapper<unknown>;
  UserProduct_variance_order_by: ResolverTypeWrapper<unknown>;
  UserProductsOutput: ResolverTypeWrapper<unknown>;
  UserReadiness: ResolverTypeWrapper<unknown>;
  UserReadinessLog: ResolverTypeWrapper<unknown>;
  UserReadinessLog_aggregate: ResolverTypeWrapper<unknown>;
  UserReadinessLog_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserReadinessLog_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserReadinessLog_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserReadinessLog_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserReadinessLog_append_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_bool_exp: ResolverTypeWrapper<unknown>;
  UserReadinessLog_constraint: ResolverTypeWrapper<unknown>;
  UserReadinessLog_delete_at_path_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_delete_elem_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_delete_key_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_insert_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_max_fields: ResolverTypeWrapper<unknown>;
  UserReadinessLog_max_order_by: ResolverTypeWrapper<unknown>;
  UserReadinessLog_min_fields: ResolverTypeWrapper<unknown>;
  UserReadinessLog_min_order_by: ResolverTypeWrapper<unknown>;
  UserReadinessLog_mutation_response: ResolverTypeWrapper<unknown>;
  UserReadinessLog_on_conflict: ResolverTypeWrapper<unknown>;
  UserReadinessLog_order_by: ResolverTypeWrapper<unknown>;
  UserReadinessLog_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_prepend_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_select_column: ResolverTypeWrapper<unknown>;
  UserReadinessLog_set_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserReadinessLog_update_column: ResolverTypeWrapper<unknown>;
  UserReadinessLog_updates: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserReadiness_avg_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_avg_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_bool_exp: ResolverTypeWrapper<unknown>;
  UserReadiness_constraint: ResolverTypeWrapper<unknown>;
  UserReadiness_inc_input: ResolverTypeWrapper<unknown>;
  UserReadiness_insert_input: ResolverTypeWrapper<unknown>;
  UserReadiness_max_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_max_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_min_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_min_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_mutation_response: ResolverTypeWrapper<unknown>;
  UserReadiness_on_conflict: ResolverTypeWrapper<unknown>;
  UserReadiness_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_select_column_UserReadiness_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReadiness_set_input: ResolverTypeWrapper<unknown>;
  UserReadiness_stddev_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserReadiness_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserReadiness_sum_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_sum_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_update_column: ResolverTypeWrapper<unknown>;
  UserReadiness_updates: ResolverTypeWrapper<unknown>;
  UserReadiness_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserReadiness_variance_fields: ResolverTypeWrapper<unknown>;
  UserReadiness_variance_order_by: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_bool_exp: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_constraint: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_insert_input: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_max_fields: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_max_order_by: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_min_fields: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_min_order_by: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_mutation_response: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_on_conflict: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_order_by: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_select_column: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_select_column_UserReimbursmentHistory_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_select_column_UserReimbursmentHistory_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_set_input: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_update_column: ResolverTypeWrapper<unknown>;
  UserReimbursmentHistory_updates: ResolverTypeWrapper<unknown>;
  UserRep: ResolverTypeWrapper<unknown>;
  UserRep_aggregate: ResolverTypeWrapper<unknown>;
  UserRep_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserRep_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserRep_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserRep_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserRep_append_input: ResolverTypeWrapper<unknown>;
  UserRep_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserRep_avg_fields: ResolverTypeWrapper<unknown>;
  UserRep_avg_order_by: ResolverTypeWrapper<unknown>;
  UserRep_bool_exp: ResolverTypeWrapper<unknown>;
  UserRep_constraint: ResolverTypeWrapper<unknown>;
  UserRep_delete_at_path_input: ResolverTypeWrapper<unknown>;
  UserRep_delete_elem_input: ResolverTypeWrapper<unknown>;
  UserRep_delete_key_input: ResolverTypeWrapper<unknown>;
  UserRep_inc_input: ResolverTypeWrapper<unknown>;
  UserRep_insert_input: ResolverTypeWrapper<unknown>;
  UserRep_max_fields: ResolverTypeWrapper<unknown>;
  UserRep_max_order_by: ResolverTypeWrapper<unknown>;
  UserRep_min_fields: ResolverTypeWrapper<unknown>;
  UserRep_min_order_by: ResolverTypeWrapper<unknown>;
  UserRep_mutation_response: ResolverTypeWrapper<unknown>;
  UserRep_on_conflict: ResolverTypeWrapper<unknown>;
  UserRep_order_by: ResolverTypeWrapper<unknown>;
  UserRep_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserRep_prepend_input: ResolverTypeWrapper<unknown>;
  UserRep_select_column: ResolverTypeWrapper<unknown>;
  UserRep_set_input: ResolverTypeWrapper<unknown>;
  UserRep_stddev_fields: ResolverTypeWrapper<unknown>;
  UserRep_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserRep_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserRep_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserRep_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserRep_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserRep_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserRep_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserRep_sum_fields: ResolverTypeWrapper<unknown>;
  UserRep_sum_order_by: ResolverTypeWrapper<unknown>;
  UserRep_update_column: ResolverTypeWrapper<unknown>;
  UserRep_updates: ResolverTypeWrapper<unknown>;
  UserRep_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserRep_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserRep_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserRep_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserRep_variance_fields: ResolverTypeWrapper<unknown>;
  UserRep_variance_order_by: ResolverTypeWrapper<unknown>;
  UserSet: ResolverTypeWrapper<unknown>;
  UserSet_aggregate: ResolverTypeWrapper<unknown>;
  UserSet_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserSet_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserSet_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserSet_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserSet_append_input: ResolverTypeWrapper<unknown>;
  UserSet_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserSet_avg_fields: ResolverTypeWrapper<unknown>;
  UserSet_avg_order_by: ResolverTypeWrapper<unknown>;
  UserSet_bool_exp: ResolverTypeWrapper<unknown>;
  UserSet_constraint: ResolverTypeWrapper<unknown>;
  UserSet_delete_at_path_input: ResolverTypeWrapper<unknown>;
  UserSet_delete_elem_input: ResolverTypeWrapper<unknown>;
  UserSet_delete_key_input: ResolverTypeWrapper<unknown>;
  UserSet_inc_input: ResolverTypeWrapper<unknown>;
  UserSet_insert_input: ResolverTypeWrapper<unknown>;
  UserSet_max_fields: ResolverTypeWrapper<unknown>;
  UserSet_max_order_by: ResolverTypeWrapper<unknown>;
  UserSet_min_fields: ResolverTypeWrapper<unknown>;
  UserSet_min_order_by: ResolverTypeWrapper<unknown>;
  UserSet_mutation_response: ResolverTypeWrapper<unknown>;
  UserSet_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserSet_on_conflict: ResolverTypeWrapper<unknown>;
  UserSet_order_by: ResolverTypeWrapper<unknown>;
  UserSet_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserSet_prepend_input: ResolverTypeWrapper<unknown>;
  UserSet_select_column: ResolverTypeWrapper<unknown>;
  UserSet_set_input: ResolverTypeWrapper<unknown>;
  UserSet_stddev_fields: ResolverTypeWrapper<unknown>;
  UserSet_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserSet_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserSet_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserSet_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserSet_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserSet_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserSet_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserSet_sum_fields: ResolverTypeWrapper<unknown>;
  UserSet_sum_order_by: ResolverTypeWrapper<unknown>;
  UserSet_update_column: ResolverTypeWrapper<unknown>;
  UserSet_updates: ResolverTypeWrapper<unknown>;
  UserSet_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserSet_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserSet_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserSet_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserSet_variance_fields: ResolverTypeWrapper<unknown>;
  UserSet_variance_order_by: ResolverTypeWrapper<unknown>;
  UserState: ResolverTypeWrapper<unknown>;
  UserStateOutput: ResolverTypeWrapper<unknown>;
  UserState_aggregate: ResolverTypeWrapper<unknown>;
  UserState_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserState_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  UserState_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  UserState_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserState_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserState_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserState_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserState_bool_exp: ResolverTypeWrapper<unknown>;
  UserState_constraint: ResolverTypeWrapper<unknown>;
  UserState_insert_input: ResolverTypeWrapper<unknown>;
  UserState_max_fields: ResolverTypeWrapper<unknown>;
  UserState_max_order_by: ResolverTypeWrapper<unknown>;
  UserState_min_fields: ResolverTypeWrapper<unknown>;
  UserState_min_order_by: ResolverTypeWrapper<unknown>;
  UserState_mutation_response: ResolverTypeWrapper<unknown>;
  UserState_on_conflict: ResolverTypeWrapper<unknown>;
  UserState_order_by: ResolverTypeWrapper<unknown>;
  UserState_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserState_select_column: ResolverTypeWrapper<unknown>;
  UserState_select_column_UserState_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  UserState_select_column_UserState_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  UserState_set_input: ResolverTypeWrapper<unknown>;
  UserState_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserState_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserState_update_column: ResolverTypeWrapper<unknown>;
  UserState_updates: ResolverTypeWrapper<unknown>;
  UserTrainingPlan: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_aggregate: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_avg_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_bool_exp: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_constraint: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_inc_input: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_insert_input: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_max_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_min_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_mutation_response: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_on_conflict: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_order_by: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_select_column: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_set_input: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_stddev_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_sum_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_update_column: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_updates: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserTrainingPlan_variance_fields: ResolverTypeWrapper<unknown>;
  UserWorkout: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_aggregate_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_append_input: ResolverTypeWrapper<unknown>;
  UserWorkout_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserWorkout_avg_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_avg_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_bool_exp: ResolverTypeWrapper<unknown>;
  UserWorkout_constraint: ResolverTypeWrapper<unknown>;
  UserWorkout_delete_at_path_input: ResolverTypeWrapper<unknown>;
  UserWorkout_delete_elem_input: ResolverTypeWrapper<unknown>;
  UserWorkout_delete_key_input: ResolverTypeWrapper<unknown>;
  UserWorkout_inc_input: ResolverTypeWrapper<unknown>;
  UserWorkout_insert_input: ResolverTypeWrapper<unknown>;
  UserWorkout_max_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_max_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_min_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_min_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_mutation_response: ResolverTypeWrapper<unknown>;
  UserWorkout_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  UserWorkout_on_conflict: ResolverTypeWrapper<unknown>;
  UserWorkout_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_pk_columns_input: ResolverTypeWrapper<unknown>;
  UserWorkout_prepend_input: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_select_column_UserWorkout_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  UserWorkout_set_input: ResolverTypeWrapper<unknown>;
  UserWorkout_stddev_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_stddev_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_stream_cursor_input: ResolverTypeWrapper<unknown>;
  UserWorkout_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  UserWorkout_sum_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_sum_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_update_column: ResolverTypeWrapper<unknown>;
  UserWorkout_updates: ResolverTypeWrapper<unknown>;
  UserWorkout_var_pop_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_var_pop_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_var_samp_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_var_samp_order_by: ResolverTypeWrapper<unknown>;
  UserWorkout_variance_fields: ResolverTypeWrapper<unknown>;
  UserWorkout_variance_order_by: ResolverTypeWrapper<unknown>;
  User_aggregate: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  User_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  User_aggregate_fields: ResolverTypeWrapper<unknown>;
  User_aggregate_order_by: ResolverTypeWrapper<unknown>;
  User_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  User_avg_fields: ResolverTypeWrapper<unknown>;
  User_avg_order_by: ResolverTypeWrapper<unknown>;
  User_bool_exp: ResolverTypeWrapper<unknown>;
  User_constraint: ResolverTypeWrapper<unknown>;
  User_inc_input: ResolverTypeWrapper<unknown>;
  User_insert_input: ResolverTypeWrapper<unknown>;
  User_max_fields: ResolverTypeWrapper<unknown>;
  User_max_order_by: ResolverTypeWrapper<unknown>;
  User_min_fields: ResolverTypeWrapper<unknown>;
  User_min_order_by: ResolverTypeWrapper<unknown>;
  User_mutation_response: ResolverTypeWrapper<unknown>;
  User_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  User_on_conflict: ResolverTypeWrapper<unknown>;
  User_order_by: ResolverTypeWrapper<unknown>;
  User_pk_columns_input: ResolverTypeWrapper<unknown>;
  User_select_column: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  User_select_column_User_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  User_set_input: ResolverTypeWrapper<unknown>;
  User_stddev_fields: ResolverTypeWrapper<unknown>;
  User_stddev_order_by: ResolverTypeWrapper<unknown>;
  User_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  User_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  User_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  User_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  User_stream_cursor_input: ResolverTypeWrapper<unknown>;
  User_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  User_sum_fields: ResolverTypeWrapper<unknown>;
  User_sum_order_by: ResolverTypeWrapper<unknown>;
  User_update_column: ResolverTypeWrapper<unknown>;
  User_updates: ResolverTypeWrapper<unknown>;
  User_var_pop_fields: ResolverTypeWrapper<unknown>;
  User_var_pop_order_by: ResolverTypeWrapper<unknown>;
  User_var_samp_fields: ResolverTypeWrapper<unknown>;
  User_var_samp_order_by: ResolverTypeWrapper<unknown>;
  User_variance_fields: ResolverTypeWrapper<unknown>;
  User_variance_order_by: ResolverTypeWrapper<unknown>;
  ValLabels: ResolverTypeWrapper<unknown>;
  ValueAndTime: ResolverTypeWrapper<unknown>;
  ValueTimeAndAverage: ResolverTypeWrapper<unknown>;
  ValueTimeAndMinimum: ResolverTypeWrapper<unknown>;
  VendorErrorLog: ResolverTypeWrapper<unknown>;
  VendorErrorLog_aggregate: ResolverTypeWrapper<unknown>;
  VendorErrorLog_aggregate_fields: ResolverTypeWrapper<unknown>;
  VendorErrorLog_append_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_bool_exp: ResolverTypeWrapper<unknown>;
  VendorErrorLog_constraint: ResolverTypeWrapper<unknown>;
  VendorErrorLog_delete_at_path_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_delete_elem_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_delete_key_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_insert_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_max_fields: ResolverTypeWrapper<unknown>;
  VendorErrorLog_min_fields: ResolverTypeWrapper<unknown>;
  VendorErrorLog_mutation_response: ResolverTypeWrapper<unknown>;
  VendorErrorLog_on_conflict: ResolverTypeWrapper<unknown>;
  VendorErrorLog_order_by: ResolverTypeWrapper<unknown>;
  VendorErrorLog_pk_columns_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_prepend_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_select_column: ResolverTypeWrapper<unknown>;
  VendorErrorLog_set_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_stream_cursor_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  VendorErrorLog_update_column: ResolverTypeWrapper<unknown>;
  VendorErrorLog_updates: ResolverTypeWrapper<unknown>;
  VendorType: ResolverTypeWrapper<unknown>;
  VendorType_comparison_exp: ResolverTypeWrapper<unknown>;
  VersionControl: ResolverTypeWrapper<unknown>;
  VersionControl_aggregate: ResolverTypeWrapper<unknown>;
  VersionControl_aggregate_fields: ResolverTypeWrapper<unknown>;
  VersionControl_avg_fields: ResolverTypeWrapper<unknown>;
  VersionControl_bool_exp: ResolverTypeWrapper<unknown>;
  VersionControl_constraint: ResolverTypeWrapper<unknown>;
  VersionControl_inc_input: ResolverTypeWrapper<unknown>;
  VersionControl_insert_input: ResolverTypeWrapper<unknown>;
  VersionControl_max_fields: ResolverTypeWrapper<unknown>;
  VersionControl_min_fields: ResolverTypeWrapper<unknown>;
  VersionControl_mutation_response: ResolverTypeWrapper<unknown>;
  VersionControl_on_conflict: ResolverTypeWrapper<unknown>;
  VersionControl_order_by: ResolverTypeWrapper<unknown>;
  VersionControl_pk_columns_input: ResolverTypeWrapper<unknown>;
  VersionControl_select_column: ResolverTypeWrapper<unknown>;
  VersionControl_set_input: ResolverTypeWrapper<unknown>;
  VersionControl_stddev_fields: ResolverTypeWrapper<unknown>;
  VersionControl_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  VersionControl_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  VersionControl_stream_cursor_input: ResolverTypeWrapper<unknown>;
  VersionControl_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  VersionControl_sum_fields: ResolverTypeWrapper<unknown>;
  VersionControl_update_column: ResolverTypeWrapper<unknown>;
  VersionControl_updates: ResolverTypeWrapper<unknown>;
  VersionControl_var_pop_fields: ResolverTypeWrapper<unknown>;
  VersionControl_var_samp_fields: ResolverTypeWrapper<unknown>;
  VersionControl_variance_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_avg: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_corr: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_corr_arguments: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_covar_samp: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_covar_samp_arguments: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_max: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_min: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_stddev_samp: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_sum: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_bool_exp_var_samp: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_aggregate_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_append_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_avg_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_avg_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_bool_exp: ResolverTypeWrapper<unknown>;
  WeeklyPlan_constraint: ResolverTypeWrapper<unknown>;
  WeeklyPlan_delete_at_path_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_delete_elem_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_delete_key_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_inc_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_insert_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_max_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_max_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_min_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_min_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_mutation_response: ResolverTypeWrapper<unknown>;
  WeeklyPlan_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_on_conflict: ResolverTypeWrapper<unknown>;
  WeeklyPlan_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_pk_columns_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_prepend_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_avg_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_corr_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_covar_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_max_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_min_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_stddev_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_sum_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_select_column_WeeklyPlan_aggregate_bool_exp_var_samp_arguments_columns: ResolverTypeWrapper<unknown>;
  WeeklyPlan_set_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stddev_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stddev_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stream_cursor_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  WeeklyPlan_sum_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_sum_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_update_column: ResolverTypeWrapper<unknown>;
  WeeklyPlan_updates: ResolverTypeWrapper<unknown>;
  WeeklyPlan_var_pop_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_var_pop_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_var_samp_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_var_samp_order_by: ResolverTypeWrapper<unknown>;
  WeeklyPlan_variance_fields: ResolverTypeWrapper<unknown>;
  WeeklyPlan_variance_order_by: ResolverTypeWrapper<unknown>;
  WorkIOWithEpoch: ResolverTypeWrapper<unknown>;
  WorkinPrefrence: ResolverTypeWrapper<unknown>;
  WorkinPrefrenceInput: ResolverTypeWrapper<unknown>;
  WorkinPrefrenceOutput: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate_fields: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_aggregate_order_by: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_bool_exp: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_constraint: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_insert_input: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_max_fields: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_max_order_by: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_min_fields: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_min_order_by: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_mutation_response: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_on_conflict: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_order_by: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_pk_columns_input: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_select_column: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_select_column_WorkinPrefrence_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_select_column_WorkinPrefrence_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_set_input: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_stream_cursor_input: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_update_column: ResolverTypeWrapper<unknown>;
  WorkinPrefrence_updates: ResolverTypeWrapper<unknown>;
  WorkinWorkoutEnum: ResolverTypeWrapper<unknown>;
  Workout: ResolverTypeWrapper<unknown>;
  WorkoutCategory: ResolverTypeWrapper<unknown>;
  WorkoutCategoryEnum: ResolverTypeWrapper<unknown>;
  WorkoutCategory_comparison_exp: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence: ResolverTypeWrapper<unknown>;
  WorkoutPrefrenceInput: ResolverTypeWrapper<unknown>;
  WorkoutPrefrenceOutput: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate_fields: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_aggregate_order_by: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_bool_exp: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_constraint: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_insert_input: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_max_fields: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_max_order_by: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_min_fields: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_min_order_by: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_mutation_response: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_on_conflict: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_order_by: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_pk_columns_input: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_select_column: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_select_column_WorkoutPrefrence_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_select_column_WorkoutPrefrence_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_set_input: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_stream_cursor_input: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_update_column: ResolverTypeWrapper<unknown>;
  WorkoutPrefrence_updates: ResolverTypeWrapper<unknown>;
  WorkoutSubTypeEnum: ResolverTypeWrapper<unknown>;
  WorkoutType: ResolverTypeWrapper<unknown>;
  WorkoutTypeEnum: ResolverTypeWrapper<unknown>;
  WorkoutType_comparison_exp: ResolverTypeWrapper<unknown>;
  Workout_aggregate: ResolverTypeWrapper<unknown>;
  Workout_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  Workout_aggregate_bool_exp_bool_and: ResolverTypeWrapper<unknown>;
  Workout_aggregate_bool_exp_bool_or: ResolverTypeWrapper<unknown>;
  Workout_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  Workout_aggregate_fields: ResolverTypeWrapper<unknown>;
  Workout_aggregate_order_by: ResolverTypeWrapper<unknown>;
  Workout_append_input: ResolverTypeWrapper<unknown>;
  Workout_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  Workout_avg_fields: ResolverTypeWrapper<unknown>;
  Workout_avg_order_by: ResolverTypeWrapper<unknown>;
  Workout_bool_exp: ResolverTypeWrapper<unknown>;
  Workout_constraint: ResolverTypeWrapper<unknown>;
  Workout_delete_at_path_input: ResolverTypeWrapper<unknown>;
  Workout_delete_elem_input: ResolverTypeWrapper<unknown>;
  Workout_delete_key_input: ResolverTypeWrapper<unknown>;
  Workout_inc_input: ResolverTypeWrapper<unknown>;
  Workout_insert_input: ResolverTypeWrapper<unknown>;
  Workout_max_fields: ResolverTypeWrapper<unknown>;
  Workout_max_order_by: ResolverTypeWrapper<unknown>;
  Workout_min_fields: ResolverTypeWrapper<unknown>;
  Workout_min_order_by: ResolverTypeWrapper<unknown>;
  Workout_mutation_response: ResolverTypeWrapper<unknown>;
  Workout_obj_rel_insert_input: ResolverTypeWrapper<unknown>;
  Workout_on_conflict: ResolverTypeWrapper<unknown>;
  Workout_order_by: ResolverTypeWrapper<unknown>;
  Workout_pk_columns_input: ResolverTypeWrapper<unknown>;
  Workout_prepend_input: ResolverTypeWrapper<unknown>;
  Workout_select_column: ResolverTypeWrapper<unknown>;
  Workout_select_column_Workout_aggregate_bool_exp_bool_and_arguments_columns: ResolverTypeWrapper<unknown>;
  Workout_select_column_Workout_aggregate_bool_exp_bool_or_arguments_columns: ResolverTypeWrapper<unknown>;
  Workout_set_input: ResolverTypeWrapper<unknown>;
  Workout_stddev_fields: ResolverTypeWrapper<unknown>;
  Workout_stddev_order_by: ResolverTypeWrapper<unknown>;
  Workout_stddev_pop_fields: ResolverTypeWrapper<unknown>;
  Workout_stddev_pop_order_by: ResolverTypeWrapper<unknown>;
  Workout_stddev_samp_fields: ResolverTypeWrapper<unknown>;
  Workout_stddev_samp_order_by: ResolverTypeWrapper<unknown>;
  Workout_stream_cursor_input: ResolverTypeWrapper<unknown>;
  Workout_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  Workout_sum_fields: ResolverTypeWrapper<unknown>;
  Workout_sum_order_by: ResolverTypeWrapper<unknown>;
  Workout_update_column: ResolverTypeWrapper<unknown>;
  Workout_updates: ResolverTypeWrapper<unknown>;
  Workout_var_pop_fields: ResolverTypeWrapper<unknown>;
  Workout_var_pop_order_by: ResolverTypeWrapper<unknown>;
  Workout_var_samp_fields: ResolverTypeWrapper<unknown>;
  Workout_var_samp_order_by: ResolverTypeWrapper<unknown>;
  Workout_variance_fields: ResolverTypeWrapper<unknown>;
  Workout_variance_order_by: ResolverTypeWrapper<unknown>;
  _FollowingPro: ResolverTypeWrapper<unknown>;
  _FollowingPro_aggregate: ResolverTypeWrapper<unknown>;
  _FollowingPro_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  _FollowingPro_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  _FollowingPro_aggregate_fields: ResolverTypeWrapper<unknown>;
  _FollowingPro_aggregate_order_by: ResolverTypeWrapper<unknown>;
  _FollowingPro_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  _FollowingPro_bool_exp: ResolverTypeWrapper<unknown>;
  _FollowingPro_constraint: ResolverTypeWrapper<unknown>;
  _FollowingPro_insert_input: ResolverTypeWrapper<unknown>;
  _FollowingPro_max_fields: ResolverTypeWrapper<unknown>;
  _FollowingPro_max_order_by: ResolverTypeWrapper<unknown>;
  _FollowingPro_min_fields: ResolverTypeWrapper<unknown>;
  _FollowingPro_min_order_by: ResolverTypeWrapper<unknown>;
  _FollowingPro_mutation_response: ResolverTypeWrapper<unknown>;
  _FollowingPro_on_conflict: ResolverTypeWrapper<unknown>;
  _FollowingPro_order_by: ResolverTypeWrapper<unknown>;
  _FollowingPro_select_column: ResolverTypeWrapper<unknown>;
  _FollowingPro_set_input: ResolverTypeWrapper<unknown>;
  _FollowingPro_stream_cursor_input: ResolverTypeWrapper<unknown>;
  _FollowingPro_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  _FollowingPro_update_column: ResolverTypeWrapper<unknown>;
  _FollowingPro_updates: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_aggregate: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_aggregate_bool_exp: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_aggregate_bool_exp_count: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_aggregate_fields: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_aggregate_order_by: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_arr_rel_insert_input: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_bool_exp: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_constraint: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_insert_input: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_max_fields: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_max_order_by: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_min_fields: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_min_order_by: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_mutation_response: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_on_conflict: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_order_by: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_select_column: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_set_input: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_stream_cursor_input: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_stream_cursor_value_input: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_update_column: ResolverTypeWrapper<unknown>;
  _GoalToUserPreferences_updates: ResolverTypeWrapper<unknown>;
  bigint: ResolverTypeWrapper<unknown>;
  bigint_comparison_exp: ResolverTypeWrapper<unknown>;
  bioStrapAssignment: ResolverTypeWrapper<unknown>;
  createManyOutput: ResolverTypeWrapper<unknown>;
  cursor_ordering: ResolverTypeWrapper<unknown>;
  date: ResolverTypeWrapper<unknown>;
  date_comparison_exp: ResolverTypeWrapper<unknown>;
  diagnosticData: ResolverTypeWrapper<unknown>;
  fetchActivityAverages: ResolverTypeWrapper<unknown>;
  fetchActivityResult: ResolverTypeWrapper<unknown>;
  fetchActivityTotals: ResolverTypeWrapper<unknown>;
  fetchFeatureFlagsOutput: ResolverTypeWrapper<unknown>;
  fetchHabitsOutput: ResolverTypeWrapper<unknown>;
  fetchPlansOutput: ResolverTypeWrapper<unknown>;
  fileInput: ResolverTypeWrapper<unknown>;
  float8: ResolverTypeWrapper<unknown>;
  float8_comparison_exp: ResolverTypeWrapper<unknown>;
  generateSignedUrlResponse: ResolverTypeWrapper<unknown>;
  genericListOutput: ResolverTypeWrapper<unknown>;
  getSFCoachIdOutput: ResolverTypeWrapper<unknown>;
  getUserByAuth0IdOutput: ResolverTypeWrapper<unknown>;
  idObj: ResolverTypeWrapper<unknown>;
  json: ResolverTypeWrapper<unknown>;
  jsonb: ResolverTypeWrapper<unknown>;
  jsonb_array_comparison_exp: ResolverTypeWrapper<unknown>;
  jsonb_cast_exp: ResolverTypeWrapper<unknown>;
  jsonb_comparison_exp: ResolverTypeWrapper<unknown>;
  messageOutput: ResolverTypeWrapper<unknown>;
  mobileVersionsOutput: ResolverTypeWrapper<unknown>;
  mutation_root: ResolverTypeWrapper<{}>;
  order_by: ResolverTypeWrapper<unknown>;
  planHistoryOutput: ResolverTypeWrapper<unknown>;
  planHistoryTotals: ResolverTypeWrapper<unknown>;
  planHistoryWeek: ResolverTypeWrapper<unknown>;
  qualifyWorkoutOutput: ResolverTypeWrapper<unknown>;
  query_root: ResolverTypeWrapper<{}>;
  recommendWorkoutByOptionOutput: ResolverTypeWrapper<unknown>;
  sleepAverage: ResolverTypeWrapper<unknown>;
  sleepInsightsDetailData: ResolverTypeWrapper<unknown>;
  sleepV1: ResolverTypeWrapper<unknown>;
  startWorkoutOutput: ResolverTypeWrapper<unknown>;
  subscription_root: ResolverTypeWrapper<{}>;
  time: ResolverTypeWrapper<unknown>;
  time_comparison_exp: ResolverTypeWrapper<unknown>;
  timestamp: ResolverTypeWrapper<unknown>;
  timestamp_comparison_exp: ResolverTypeWrapper<unknown>;
  updateReimbursementTokensResponse: ResolverTypeWrapper<unknown>;
  updateUser: ResolverTypeWrapper<unknown>;
  userNutritionReportOutput: ResolverTypeWrapper<unknown>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  ActionResultOutput: unknown;
  ActivationCode: unknown;
  ActivationCode_aggregate: unknown;
  ActivationCode_aggregate_fields: unknown;
  ActivationCode_bool_exp: unknown;
  ActivationCode_insert_input: unknown;
  ActivationCode_max_fields: unknown;
  ActivationCode_min_fields: unknown;
  ActivationCode_mutation_response: unknown;
  ActivationCode_on_conflict: unknown;
  ActivationCode_order_by: unknown;
  ActivationCode_pk_columns_input: unknown;
  ActivationCode_set_input: unknown;
  ActivationCode_stream_cursor_input: unknown;
  ActivationCode_stream_cursor_value_input: unknown;
  ActivationCode_updates: unknown;
  ActivityInsightsDetailOutput: unknown;
  ActivityInsightsOutput: unknown;
  ActivityProgressOutput: unknown;
  AggregatesOutput: unknown;
  AllUsersOutput: unknown;
  AlterTypeColor: unknown;
  AlterTypeColor_aggregate: unknown;
  AlterTypeColor_aggregate_fields: unknown;
  AlterTypeColor_bool_exp: unknown;
  AlterTypeColor_insert_input: unknown;
  AlterTypeColor_max_fields: unknown;
  AlterTypeColor_min_fields: unknown;
  AlterTypeColor_mutation_response: unknown;
  AlterTypeColor_on_conflict: unknown;
  AlterTypeColor_order_by: unknown;
  AlterTypeColor_pk_columns_input: unknown;
  AlterTypeColor_set_input: unknown;
  AlterTypeColor_stream_cursor_input: unknown;
  AlterTypeColor_stream_cursor_value_input: unknown;
  AlterTypeColor_updates: unknown;
  AssessmentType: unknown;
  AssessmentType_comparison_exp: unknown;
  Boolean: unknown;
  Boolean_comparison_exp: unknown;
  CRUDaction: unknown;
  CRUDaction_comparison_exp: unknown;
  CadenceType: unknown;
  CadenceType_comparison_exp: unknown;
  CalculateMacroGramsOutput: unknown;
  CaloricOutput: unknown;
  ChangeCoachedPlanOutput: unknown;
  CheckDNAKitOutput: unknown;
  CheckNutritionReportReleasedOutput: unknown;
  CheckPresetPlanStructureOutput: unknown;
  Coach: unknown;
  Coach_aggregate: unknown;
  Coach_aggregate_bool_exp: unknown;
  Coach_aggregate_bool_exp_count: unknown;
  Coach_aggregate_fields: unknown;
  Coach_aggregate_order_by: unknown;
  Coach_arr_rel_insert_input: unknown;
  Coach_bool_exp: unknown;
  Coach_insert_input: unknown;
  Coach_max_fields: unknown;
  Coach_max_order_by: unknown;
  Coach_min_fields: unknown;
  Coach_min_order_by: unknown;
  Coach_mutation_response: unknown;
  Coach_obj_rel_insert_input: unknown;
  Coach_on_conflict: unknown;
  Coach_order_by: unknown;
  Coach_pk_columns_input: unknown;
  Coach_set_input: unknown;
  Coach_stream_cursor_input: unknown;
  Coach_stream_cursor_value_input: unknown;
  Coach_updates: unknown;
  CoachedHabit: unknown;
  CoachedHabit_aggregate: unknown;
  CoachedHabit_aggregate_fields: unknown;
  CoachedHabit_avg_fields: unknown;
  CoachedHabit_bool_exp: unknown;
  CoachedHabit_inc_input: unknown;
  CoachedHabit_insert_input: unknown;
  CoachedHabit_max_fields: unknown;
  CoachedHabit_min_fields: unknown;
  CoachedHabit_mutation_response: unknown;
  CoachedHabit_on_conflict: unknown;
  CoachedHabit_order_by: unknown;
  CoachedHabit_pk_columns_input: unknown;
  CoachedHabit_set_input: unknown;
  CoachedHabit_stddev_fields: unknown;
  CoachedHabit_stddev_pop_fields: unknown;
  CoachedHabit_stddev_samp_fields: unknown;
  CoachedHabit_stream_cursor_input: unknown;
  CoachedHabit_stream_cursor_value_input: unknown;
  CoachedHabit_sum_fields: unknown;
  CoachedHabit_updates: unknown;
  CoachedHabit_var_pop_fields: unknown;
  CoachedHabit_var_samp_fields: unknown;
  CoachedHabit_variance_fields: unknown;
  CoachedNutrition: unknown;
  CoachedNutrition_aggregate: unknown;
  CoachedNutrition_aggregate_bool_exp: unknown;
  CoachedNutrition_aggregate_bool_exp_bool_and: unknown;
  CoachedNutrition_aggregate_bool_exp_bool_or: unknown;
  CoachedNutrition_aggregate_bool_exp_count: unknown;
  CoachedNutrition_aggregate_fields: unknown;
  CoachedNutrition_aggregate_order_by: unknown;
  CoachedNutrition_arr_rel_insert_input: unknown;
  CoachedNutrition_avg_fields: unknown;
  CoachedNutrition_avg_order_by: unknown;
  CoachedNutrition_bool_exp: unknown;
  CoachedNutrition_inc_input: unknown;
  CoachedNutrition_insert_input: unknown;
  CoachedNutrition_max_fields: unknown;
  CoachedNutrition_max_order_by: unknown;
  CoachedNutrition_min_fields: unknown;
  CoachedNutrition_min_order_by: unknown;
  CoachedNutrition_mutation_response: unknown;
  CoachedNutrition_on_conflict: unknown;
  CoachedNutrition_order_by: unknown;
  CoachedNutrition_pk_columns_input: unknown;
  CoachedNutrition_set_input: unknown;
  CoachedNutrition_stddev_fields: unknown;
  CoachedNutrition_stddev_order_by: unknown;
  CoachedNutrition_stddev_pop_fields: unknown;
  CoachedNutrition_stddev_pop_order_by: unknown;
  CoachedNutrition_stddev_samp_fields: unknown;
  CoachedNutrition_stddev_samp_order_by: unknown;
  CoachedNutrition_stream_cursor_input: unknown;
  CoachedNutrition_stream_cursor_value_input: unknown;
  CoachedNutrition_sum_fields: unknown;
  CoachedNutrition_sum_order_by: unknown;
  CoachedNutrition_updates: unknown;
  CoachedNutrition_var_pop_fields: unknown;
  CoachedNutrition_var_pop_order_by: unknown;
  CoachedNutrition_var_samp_fields: unknown;
  CoachedNutrition_var_samp_order_by: unknown;
  CoachedNutrition_variance_fields: unknown;
  CoachedNutrition_variance_order_by: unknown;
  CookBookItem: unknown;
  CreditHistoryOutput: unknown;
  CurrentAverage: unknown;
  CustomPlanDay: unknown;
  CustomPlanDayInput: unknown;
  CustomPlanInput: unknown;
  DMLType: unknown;
  DMLType_comparison_exp: unknown;
  DNAKit: unknown;
  DNAKitRole: unknown;
  DNAKitRole_comparison_exp: unknown;
  DNAKit_aggregate: unknown;
  DNAKit_aggregate_fields: unknown;
  DNAKit_avg_fields: unknown;
  DNAKit_bool_exp: unknown;
  DNAKit_inc_input: unknown;
  DNAKit_insert_input: unknown;
  DNAKit_max_fields: unknown;
  DNAKit_min_fields: unknown;
  DNAKit_mutation_response: unknown;
  DNAKit_obj_rel_insert_input: unknown;
  DNAKit_on_conflict: unknown;
  DNAKit_order_by: unknown;
  DNAKit_pk_columns_input: unknown;
  DNAKit_set_input: unknown;
  DNAKit_stddev_fields: unknown;
  DNAKit_stddev_pop_fields: unknown;
  DNAKit_stddev_samp_fields: unknown;
  DNAKit_stream_cursor_input: unknown;
  DNAKit_stream_cursor_value_input: unknown;
  DNAKit_sum_fields: unknown;
  DNAKit_updates: unknown;
  DNAKit_var_pop_fields: unknown;
  DNAKit_var_samp_fields: unknown;
  DNAKit_variance_fields: unknown;
  DNAResults: unknown;
  DNAResultsOutput: unknown;
  DNAResults_aggregate: unknown;
  DNAResults_aggregate_bool_exp: unknown;
  DNAResults_aggregate_bool_exp_count: unknown;
  DNAResults_aggregate_fields: unknown;
  DNAResults_aggregate_order_by: unknown;
  DNAResults_arr_rel_insert_input: unknown;
  DNAResults_avg_fields: unknown;
  DNAResults_avg_order_by: unknown;
  DNAResults_bool_exp: unknown;
  DNAResults_inc_input: unknown;
  DNAResults_insert_input: unknown;
  DNAResults_max_fields: unknown;
  DNAResults_max_order_by: unknown;
  DNAResults_min_fields: unknown;
  DNAResults_min_order_by: unknown;
  DNAResults_mutation_response: unknown;
  DNAResults_on_conflict: unknown;
  DNAResults_order_by: unknown;
  DNAResults_pk_columns_input: unknown;
  DNAResults_set_input: unknown;
  DNAResults_stddev_fields: unknown;
  DNAResults_stddev_order_by: unknown;
  DNAResults_stddev_pop_fields: unknown;
  DNAResults_stddev_pop_order_by: unknown;
  DNAResults_stddev_samp_fields: unknown;
  DNAResults_stddev_samp_order_by: unknown;
  DNAResults_stream_cursor_input: unknown;
  DNAResults_stream_cursor_value_input: unknown;
  DNAResults_sum_fields: unknown;
  DNAResults_sum_order_by: unknown;
  DNAResults_updates: unknown;
  DNAResults_var_pop_fields: unknown;
  DNAResults_var_pop_order_by: unknown;
  DNAResults_var_samp_fields: unknown;
  DNAResults_var_samp_order_by: unknown;
  DNAResults_variance_fields: unknown;
  DNAResults_variance_order_by: unknown;
  DailyCheckinOutput: unknown;
  DailyCheckinV2MasteryObject: unknown;
  DailyCheckinV2Output: unknown;
  DailyCheckinV2SleepObject: unknown;
  Delivery: unknown;
  DeliveryHistory: unknown;
  DeliveryHistory_aggregate: unknown;
  DeliveryHistory_aggregate_bool_exp: unknown;
  DeliveryHistory_aggregate_bool_exp_count: unknown;
  DeliveryHistory_aggregate_fields: unknown;
  DeliveryHistory_aggregate_order_by: unknown;
  DeliveryHistory_arr_rel_insert_input: unknown;
  DeliveryHistory_bool_exp: unknown;
  DeliveryHistory_insert_input: unknown;
  DeliveryHistory_max_fields: unknown;
  DeliveryHistory_max_order_by: unknown;
  DeliveryHistory_min_fields: unknown;
  DeliveryHistory_min_order_by: unknown;
  DeliveryHistory_mutation_response: unknown;
  DeliveryHistory_on_conflict: unknown;
  DeliveryHistory_order_by: unknown;
  DeliveryHistory_pk_columns_input: unknown;
  DeliveryHistory_set_input: unknown;
  DeliveryHistory_stream_cursor_input: unknown;
  DeliveryHistory_stream_cursor_value_input: unknown;
  DeliveryHistory_updates: unknown;
  DeliveryStatus: unknown;
  DeliveryStatus_comparison_exp: unknown;
  Delivery_aggregate: unknown;
  Delivery_aggregate_bool_exp: unknown;
  Delivery_aggregate_bool_exp_bool_and: unknown;
  Delivery_aggregate_bool_exp_bool_or: unknown;
  Delivery_aggregate_bool_exp_count: unknown;
  Delivery_aggregate_fields: unknown;
  Delivery_aggregate_order_by: unknown;
  Delivery_arr_rel_insert_input: unknown;
  Delivery_bool_exp: unknown;
  Delivery_insert_input: unknown;
  Delivery_max_fields: unknown;
  Delivery_max_order_by: unknown;
  Delivery_min_fields: unknown;
  Delivery_min_order_by: unknown;
  Delivery_mutation_response: unknown;
  Delivery_obj_rel_insert_input: unknown;
  Delivery_on_conflict: unknown;
  Delivery_order_by: unknown;
  Delivery_pk_columns_input: unknown;
  Delivery_set_input: unknown;
  Delivery_stream_cursor_input: unknown;
  Delivery_stream_cursor_value_input: unknown;
  Delivery_updates: unknown;
  Device: unknown;
  DeviceType: unknown;
  DeviceType_comparison_exp: unknown;
  Device_aggregate: unknown;
  Device_aggregate_bool_exp: unknown;
  Device_aggregate_bool_exp_count: unknown;
  Device_aggregate_fields: unknown;
  Device_aggregate_order_by: unknown;
  Device_arr_rel_insert_input: unknown;
  Device_bool_exp: unknown;
  Device_insert_input: unknown;
  Device_max_fields: unknown;
  Device_max_order_by: unknown;
  Device_min_fields: unknown;
  Device_min_order_by: unknown;
  Device_mutation_response: unknown;
  Device_on_conflict: unknown;
  Device_order_by: unknown;
  Device_pk_columns_input: unknown;
  Device_set_input: unknown;
  Device_stream_cursor_input: unknown;
  Device_stream_cursor_value_input: unknown;
  Device_updates: unknown;
  Diagnostics: unknown;
  Diagnostics_aggregate: unknown;
  Diagnostics_aggregate_bool_exp: unknown;
  Diagnostics_aggregate_bool_exp_count: unknown;
  Diagnostics_aggregate_fields: unknown;
  Diagnostics_aggregate_order_by: unknown;
  Diagnostics_arr_rel_insert_input: unknown;
  Diagnostics_avg_fields: unknown;
  Diagnostics_avg_order_by: unknown;
  Diagnostics_bool_exp: unknown;
  Diagnostics_inc_input: unknown;
  Diagnostics_insert_input: unknown;
  Diagnostics_max_fields: unknown;
  Diagnostics_max_order_by: unknown;
  Diagnostics_min_fields: unknown;
  Diagnostics_min_order_by: unknown;
  Diagnostics_mutation_response: unknown;
  Diagnostics_on_conflict: unknown;
  Diagnostics_order_by: unknown;
  Diagnostics_pk_columns_input: unknown;
  Diagnostics_set_input: unknown;
  Diagnostics_stddev_fields: unknown;
  Diagnostics_stddev_order_by: unknown;
  Diagnostics_stddev_pop_fields: unknown;
  Diagnostics_stddev_pop_order_by: unknown;
  Diagnostics_stddev_samp_fields: unknown;
  Diagnostics_stddev_samp_order_by: unknown;
  Diagnostics_stream_cursor_input: unknown;
  Diagnostics_stream_cursor_value_input: unknown;
  Diagnostics_sum_fields: unknown;
  Diagnostics_sum_order_by: unknown;
  Diagnostics_updates: unknown;
  Diagnostics_var_pop_fields: unknown;
  Diagnostics_var_pop_order_by: unknown;
  Diagnostics_var_samp_fields: unknown;
  Diagnostics_var_samp_order_by: unknown;
  Diagnostics_variance_fields: unknown;
  Diagnostics_variance_order_by: unknown;
  DietContents: unknown;
  EmailHasKitOutput: unknown;
  EndAssessmentOutput: unknown;
  FeedbackType: unknown;
  FeedbackType_comparison_exp: unknown;
  FetchCoachOutput: unknown;
  FetchCoachedPlanOutput: unknown;
  FetchCookBookOutput: unknown;
  FetchWorkoutOptionsOutput: unknown;
  FetchWorkoutOptionsOutputV2: unknown;
  FetchWorkoutOptionsOutputV3: unknown;
  FileType: unknown;
  FileType_comparison_exp: unknown;
  FinancePlan: unknown;
  FinancePlan_aggregate: unknown;
  FinancePlan_aggregate_bool_exp: unknown;
  FinancePlan_aggregate_bool_exp_avg: unknown;
  FinancePlan_aggregate_bool_exp_corr: unknown;
  FinancePlan_aggregate_bool_exp_corr_arguments: unknown;
  FinancePlan_aggregate_bool_exp_count: unknown;
  FinancePlan_aggregate_bool_exp_covar_samp: unknown;
  FinancePlan_aggregate_bool_exp_covar_samp_arguments: unknown;
  FinancePlan_aggregate_bool_exp_max: unknown;
  FinancePlan_aggregate_bool_exp_min: unknown;
  FinancePlan_aggregate_bool_exp_stddev_samp: unknown;
  FinancePlan_aggregate_bool_exp_sum: unknown;
  FinancePlan_aggregate_bool_exp_var_samp: unknown;
  FinancePlan_aggregate_fields: unknown;
  FinancePlan_aggregate_order_by: unknown;
  FinancePlan_arr_rel_insert_input: unknown;
  FinancePlan_avg_fields: unknown;
  FinancePlan_avg_order_by: unknown;
  FinancePlan_bool_exp: unknown;
  FinancePlan_inc_input: unknown;
  FinancePlan_insert_input: unknown;
  FinancePlan_max_fields: unknown;
  FinancePlan_max_order_by: unknown;
  FinancePlan_min_fields: unknown;
  FinancePlan_min_order_by: unknown;
  FinancePlan_mutation_response: unknown;
  FinancePlan_obj_rel_insert_input: unknown;
  FinancePlan_on_conflict: unknown;
  FinancePlan_order_by: unknown;
  FinancePlan_pk_columns_input: unknown;
  FinancePlan_set_input: unknown;
  FinancePlan_stddev_fields: unknown;
  FinancePlan_stddev_order_by: unknown;
  FinancePlan_stddev_pop_fields: unknown;
  FinancePlan_stddev_pop_order_by: unknown;
  FinancePlan_stddev_samp_fields: unknown;
  FinancePlan_stddev_samp_order_by: unknown;
  FinancePlan_stream_cursor_input: unknown;
  FinancePlan_stream_cursor_value_input: unknown;
  FinancePlan_sum_fields: unknown;
  FinancePlan_sum_order_by: unknown;
  FinancePlan_updates: unknown;
  FinancePlan_var_pop_fields: unknown;
  FinancePlan_var_pop_order_by: unknown;
  FinancePlan_var_samp_fields: unknown;
  FinancePlan_var_samp_order_by: unknown;
  FinancePlan_variance_fields: unknown;
  FinancePlan_variance_order_by: unknown;
  FinanceStatus: unknown;
  FinanceStatus_comparison_exp: unknown;
  FirmwareContent: unknown;
  FirmwareContent_aggregate: unknown;
  FirmwareContent_aggregate_fields: unknown;
  FirmwareContent_avg_fields: unknown;
  FirmwareContent_bool_exp: unknown;
  FirmwareContent_inc_input: unknown;
  FirmwareContent_insert_input: unknown;
  FirmwareContent_max_fields: unknown;
  FirmwareContent_min_fields: unknown;
  FirmwareContent_mutation_response: unknown;
  FirmwareContent_on_conflict: unknown;
  FirmwareContent_order_by: unknown;
  FirmwareContent_pk_columns_input: unknown;
  FirmwareContent_set_input: unknown;
  FirmwareContent_stddev_fields: unknown;
  FirmwareContent_stddev_pop_fields: unknown;
  FirmwareContent_stddev_samp_fields: unknown;
  FirmwareContent_stream_cursor_input: unknown;
  FirmwareContent_stream_cursor_value_input: unknown;
  FirmwareContent_sum_fields: unknown;
  FirmwareContent_updates: unknown;
  FirmwareContent_var_pop_fields: unknown;
  FirmwareContent_var_samp_fields: unknown;
  FirmwareContent_variance_fields: unknown;
  FirmwareDevice: unknown;
  FirmwareDevice_comparison_exp: unknown;
  FitnessRecommenderOutput: unknown;
  Float: unknown;
  GeneralRank: unknown;
  GeneralRankGroup: unknown;
  GeneralRankGroup_comparison_exp: unknown;
  GeneralRank_comparison_exp: unknown;
  GeneticDirectionWebhook: unknown;
  GeneticDirectionWebhook_aggregate: unknown;
  GeneticDirectionWebhook_aggregate_bool_exp: unknown;
  GeneticDirectionWebhook_aggregate_bool_exp_count: unknown;
  GeneticDirectionWebhook_aggregate_fields: unknown;
  GeneticDirectionWebhook_aggregate_order_by: unknown;
  GeneticDirectionWebhook_append_input: unknown;
  GeneticDirectionWebhook_arr_rel_insert_input: unknown;
  GeneticDirectionWebhook_avg_fields: unknown;
  GeneticDirectionWebhook_avg_order_by: unknown;
  GeneticDirectionWebhook_bool_exp: unknown;
  GeneticDirectionWebhook_delete_at_path_input: unknown;
  GeneticDirectionWebhook_delete_elem_input: unknown;
  GeneticDirectionWebhook_delete_key_input: unknown;
  GeneticDirectionWebhook_inc_input: unknown;
  GeneticDirectionWebhook_insert_input: unknown;
  GeneticDirectionWebhook_max_fields: unknown;
  GeneticDirectionWebhook_max_order_by: unknown;
  GeneticDirectionWebhook_min_fields: unknown;
  GeneticDirectionWebhook_min_order_by: unknown;
  GeneticDirectionWebhook_mutation_response: unknown;
  GeneticDirectionWebhook_on_conflict: unknown;
  GeneticDirectionWebhook_order_by: unknown;
  GeneticDirectionWebhook_pk_columns_input: unknown;
  GeneticDirectionWebhook_prepend_input: unknown;
  GeneticDirectionWebhook_set_input: unknown;
  GeneticDirectionWebhook_stddev_fields: unknown;
  GeneticDirectionWebhook_stddev_order_by: unknown;
  GeneticDirectionWebhook_stddev_pop_fields: unknown;
  GeneticDirectionWebhook_stddev_pop_order_by: unknown;
  GeneticDirectionWebhook_stddev_samp_fields: unknown;
  GeneticDirectionWebhook_stddev_samp_order_by: unknown;
  GeneticDirectionWebhook_stream_cursor_input: unknown;
  GeneticDirectionWebhook_stream_cursor_value_input: unknown;
  GeneticDirectionWebhook_sum_fields: unknown;
  GeneticDirectionWebhook_sum_order_by: unknown;
  GeneticDirectionWebhook_updates: unknown;
  GeneticDirectionWebhook_var_pop_fields: unknown;
  GeneticDirectionWebhook_var_pop_order_by: unknown;
  GeneticDirectionWebhook_var_samp_fields: unknown;
  GeneticDirectionWebhook_var_samp_order_by: unknown;
  GeneticDirectionWebhook_variance_fields: unknown;
  GeneticDirectionWebhook_variance_order_by: unknown;
  GlobalFeatureControl: unknown;
  GlobalFeatureControl_aggregate: unknown;
  GlobalFeatureControl_aggregate_fields: unknown;
  GlobalFeatureControl_bool_exp: unknown;
  GlobalFeatureControl_insert_input: unknown;
  GlobalFeatureControl_max_fields: unknown;
  GlobalFeatureControl_min_fields: unknown;
  GlobalFeatureControl_mutation_response: unknown;
  GlobalFeatureControl_on_conflict: unknown;
  GlobalFeatureControl_order_by: unknown;
  GlobalFeatureControl_pk_columns_input: unknown;
  GlobalFeatureControl_set_input: unknown;
  GlobalFeatureControl_stream_cursor_input: unknown;
  GlobalFeatureControl_stream_cursor_value_input: unknown;
  GlobalFeatureControl_updates: unknown;
  Goal: unknown;
  Goal_aggregate: unknown;
  Goal_aggregate_fields: unknown;
  Goal_bool_exp: unknown;
  Goal_insert_input: unknown;
  Goal_max_fields: unknown;
  Goal_min_fields: unknown;
  Goal_mutation_response: unknown;
  Goal_obj_rel_insert_input: unknown;
  Goal_on_conflict: unknown;
  Goal_order_by: unknown;
  Goal_pk_columns_input: unknown;
  Goal_set_input: unknown;
  Goal_stream_cursor_input: unknown;
  Goal_stream_cursor_value_input: unknown;
  Goal_updates: unknown;
  HRDiagnosticData: unknown;
  HabitCompletedResult: unknown;
  HabitDay: unknown;
  HabitDayOutput: unknown;
  HabitDay_aggregate: unknown;
  HabitDay_aggregate_fields: unknown;
  HabitDay_avg_fields: unknown;
  HabitDay_bool_exp: unknown;
  HabitDay_inc_input: unknown;
  HabitDay_insert_input: unknown;
  HabitDay_max_fields: unknown;
  HabitDay_min_fields: unknown;
  HabitDay_mutation_response: unknown;
  HabitDay_on_conflict: unknown;
  HabitDay_order_by: unknown;
  HabitDay_pk_columns_input: unknown;
  HabitDay_set_input: unknown;
  HabitDay_stddev_fields: unknown;
  HabitDay_stddev_pop_fields: unknown;
  HabitDay_stddev_samp_fields: unknown;
  HabitDay_stream_cursor_input: unknown;
  HabitDay_stream_cursor_value_input: unknown;
  HabitDay_sum_fields: unknown;
  HabitDay_updates: unknown;
  HabitDay_var_pop_fields: unknown;
  HabitDay_var_samp_fields: unknown;
  HabitDay_variance_fields: unknown;
  HabitSubType: unknown;
  HabitSubType_comparison_exp: unknown;
  HabitType: unknown;
  HabitType_comparison_exp: unknown;
  HeartRateDiagnostics: unknown;
  HeartRateDiagnostics_aggregate: unknown;
  HeartRateDiagnostics_aggregate_bool_exp: unknown;
  HeartRateDiagnostics_aggregate_bool_exp_count: unknown;
  HeartRateDiagnostics_aggregate_fields: unknown;
  HeartRateDiagnostics_aggregate_order_by: unknown;
  HeartRateDiagnostics_arr_rel_insert_input: unknown;
  HeartRateDiagnostics_avg_fields: unknown;
  HeartRateDiagnostics_avg_order_by: unknown;
  HeartRateDiagnostics_bool_exp: unknown;
  HeartRateDiagnostics_inc_input: unknown;
  HeartRateDiagnostics_insert_input: unknown;
  HeartRateDiagnostics_max_fields: unknown;
  HeartRateDiagnostics_max_order_by: unknown;
  HeartRateDiagnostics_min_fields: unknown;
  HeartRateDiagnostics_min_order_by: unknown;
  HeartRateDiagnostics_mutation_response: unknown;
  HeartRateDiagnostics_on_conflict: unknown;
  HeartRateDiagnostics_order_by: unknown;
  HeartRateDiagnostics_pk_columns_input: unknown;
  HeartRateDiagnostics_set_input: unknown;
  HeartRateDiagnostics_stddev_fields: unknown;
  HeartRateDiagnostics_stddev_order_by: unknown;
  HeartRateDiagnostics_stddev_pop_fields: unknown;
  HeartRateDiagnostics_stddev_pop_order_by: unknown;
  HeartRateDiagnostics_stddev_samp_fields: unknown;
  HeartRateDiagnostics_stddev_samp_order_by: unknown;
  HeartRateDiagnostics_stream_cursor_input: unknown;
  HeartRateDiagnostics_stream_cursor_value_input: unknown;
  HeartRateDiagnostics_sum_fields: unknown;
  HeartRateDiagnostics_sum_order_by: unknown;
  HeartRateDiagnostics_updates: unknown;
  HeartRateDiagnostics_var_pop_fields: unknown;
  HeartRateDiagnostics_var_pop_order_by: unknown;
  HeartRateDiagnostics_var_samp_fields: unknown;
  HeartRateDiagnostics_var_samp_order_by: unknown;
  HeartRateDiagnostics_variance_fields: unknown;
  HeartRateDiagnostics_variance_order_by: unknown;
  HighKneeSetOutput: unknown;
  HistoryAndCounts: unknown;
  Household: unknown;
  HouseholdOutput: unknown;
  HouseholdType: unknown;
  HouseholdType_comparison_exp: unknown;
  Household_aggregate: unknown;
  Household_aggregate_bool_exp: unknown;
  Household_aggregate_bool_exp_count: unknown;
  Household_aggregate_fields: unknown;
  Household_aggregate_order_by: unknown;
  Household_arr_rel_insert_input: unknown;
  Household_avg_fields: unknown;
  Household_avg_order_by: unknown;
  Household_bool_exp: unknown;
  Household_inc_input: unknown;
  Household_insert_input: unknown;
  Household_max_fields: unknown;
  Household_max_order_by: unknown;
  Household_min_fields: unknown;
  Household_min_order_by: unknown;
  Household_mutation_response: unknown;
  Household_obj_rel_insert_input: unknown;
  Household_on_conflict: unknown;
  Household_order_by: unknown;
  Household_pk_columns_input: unknown;
  Household_set_input: unknown;
  Household_stddev_fields: unknown;
  Household_stddev_order_by: unknown;
  Household_stddev_pop_fields: unknown;
  Household_stddev_pop_order_by: unknown;
  Household_stddev_samp_fields: unknown;
  Household_stddev_samp_order_by: unknown;
  Household_stream_cursor_input: unknown;
  Household_stream_cursor_value_input: unknown;
  Household_sum_fields: unknown;
  Household_sum_order_by: unknown;
  Household_updates: unknown;
  Household_var_pop_fields: unknown;
  Household_var_pop_order_by: unknown;
  Household_var_samp_fields: unknown;
  Household_var_samp_order_by: unknown;
  Household_variance_fields: unknown;
  Household_variance_order_by: unknown;
  HrZone2Output: unknown;
  Int: unknown;
  Int_array_comparison_exp: unknown;
  Int_comparison_exp: unknown;
  IsValidKitOutput: unknown;
  LabelsOutput: unknown;
  MarketingEmail: unknown;
  MarketingEmail_aggregate: unknown;
  MarketingEmail_aggregate_fields: unknown;
  MarketingEmail_bool_exp: unknown;
  MarketingEmail_insert_input: unknown;
  MarketingEmail_max_fields: unknown;
  MarketingEmail_min_fields: unknown;
  MarketingEmail_mutation_response: unknown;
  MarketingEmail_on_conflict: unknown;
  MarketingEmail_order_by: unknown;
  MarketingEmail_pk_columns_input: unknown;
  MarketingEmail_set_input: unknown;
  MarketingEmail_stream_cursor_input: unknown;
  MarketingEmail_stream_cursor_value_input: unknown;
  MarketingEmail_updates: unknown;
  Mastery: unknown;
  Mastery_aggregate: unknown;
  Mastery_aggregate_fields: unknown;
  Mastery_avg_fields: unknown;
  Mastery_bool_exp: unknown;
  Mastery_inc_input: unknown;
  Mastery_insert_input: unknown;
  Mastery_max_fields: unknown;
  Mastery_min_fields: unknown;
  Mastery_mutation_response: unknown;
  Mastery_obj_rel_insert_input: unknown;
  Mastery_on_conflict: unknown;
  Mastery_order_by: unknown;
  Mastery_pk_columns_input: unknown;
  Mastery_set_input: unknown;
  Mastery_stddev_fields: unknown;
  Mastery_stddev_pop_fields: unknown;
  Mastery_stddev_samp_fields: unknown;
  Mastery_stream_cursor_input: unknown;
  Mastery_stream_cursor_value_input: unknown;
  Mastery_sum_fields: unknown;
  Mastery_updates: unknown;
  Mastery_var_pop_fields: unknown;
  Mastery_var_samp_fields: unknown;
  Mastery_variance_fields: unknown;
  MealPlan: unknown;
  MealPlanOutput: unknown;
  MealPlanPreferenceMapItem: unknown;
  MealPlanPreferenceOutput: unknown;
  MealPlanPreferences: unknown;
  MealPlanSelectionTarget: unknown;
  NotInPlanType: unknown;
  NutritionReportOutput: unknown;
  OnboardingEntityType: unknown;
  OnboardingEntityType_comparison_exp: unknown;
  OnboardingInfo: unknown;
  OnboardingInfo_aggregate: unknown;
  OnboardingInfo_aggregate_fields: unknown;
  OnboardingInfo_avg_fields: unknown;
  OnboardingInfo_bool_exp: unknown;
  OnboardingInfo_inc_input: unknown;
  OnboardingInfo_insert_input: unknown;
  OnboardingInfo_max_fields: unknown;
  OnboardingInfo_min_fields: unknown;
  OnboardingInfo_mutation_response: unknown;
  OnboardingInfo_on_conflict: unknown;
  OnboardingInfo_order_by: unknown;
  OnboardingInfo_pk_columns_input: unknown;
  OnboardingInfo_set_input: unknown;
  OnboardingInfo_stddev_fields: unknown;
  OnboardingInfo_stddev_pop_fields: unknown;
  OnboardingInfo_stddev_samp_fields: unknown;
  OnboardingInfo_stream_cursor_input: unknown;
  OnboardingInfo_stream_cursor_value_input: unknown;
  OnboardingInfo_sum_fields: unknown;
  OnboardingInfo_updates: unknown;
  OnboardingInfo_var_pop_fields: unknown;
  OnboardingInfo_var_samp_fields: unknown;
  OnboardingInfo_variance_fields: unknown;
  OnboardingVideos: unknown;
  OnboardingVideos_aggregate: unknown;
  OnboardingVideos_aggregate_fields: unknown;
  OnboardingVideos_avg_fields: unknown;
  OnboardingVideos_bool_exp: unknown;
  OnboardingVideos_inc_input: unknown;
  OnboardingVideos_insert_input: unknown;
  OnboardingVideos_max_fields: unknown;
  OnboardingVideos_min_fields: unknown;
  OnboardingVideos_mutation_response: unknown;
  OnboardingVideos_on_conflict: unknown;
  OnboardingVideos_order_by: unknown;
  OnboardingVideos_pk_columns_input: unknown;
  OnboardingVideos_set_input: unknown;
  OnboardingVideos_stddev_fields: unknown;
  OnboardingVideos_stddev_pop_fields: unknown;
  OnboardingVideos_stddev_samp_fields: unknown;
  OnboardingVideos_stream_cursor_input: unknown;
  OnboardingVideos_stream_cursor_value_input: unknown;
  OnboardingVideos_sum_fields: unknown;
  OnboardingVideos_updates: unknown;
  OnboardingVideos_var_pop_fields: unknown;
  OnboardingVideos_var_samp_fields: unknown;
  OnboardingVideos_variance_fields: unknown;
  Order: unknown;
  OrderLineItem: unknown;
  OrderLineItem_aggregate: unknown;
  OrderLineItem_aggregate_bool_exp: unknown;
  OrderLineItem_aggregate_bool_exp_count: unknown;
  OrderLineItem_aggregate_fields: unknown;
  OrderLineItem_aggregate_order_by: unknown;
  OrderLineItem_arr_rel_insert_input: unknown;
  OrderLineItem_avg_fields: unknown;
  OrderLineItem_avg_order_by: unknown;
  OrderLineItem_bool_exp: unknown;
  OrderLineItem_inc_input: unknown;
  OrderLineItem_insert_input: unknown;
  OrderLineItem_max_fields: unknown;
  OrderLineItem_max_order_by: unknown;
  OrderLineItem_min_fields: unknown;
  OrderLineItem_min_order_by: unknown;
  OrderLineItem_mutation_response: unknown;
  OrderLineItem_obj_rel_insert_input: unknown;
  OrderLineItem_on_conflict: unknown;
  OrderLineItem_order_by: unknown;
  OrderLineItem_pk_columns_input: unknown;
  OrderLineItem_set_input: unknown;
  OrderLineItem_stddev_fields: unknown;
  OrderLineItem_stddev_order_by: unknown;
  OrderLineItem_stddev_pop_fields: unknown;
  OrderLineItem_stddev_pop_order_by: unknown;
  OrderLineItem_stddev_samp_fields: unknown;
  OrderLineItem_stddev_samp_order_by: unknown;
  OrderLineItem_stream_cursor_input: unknown;
  OrderLineItem_stream_cursor_value_input: unknown;
  OrderLineItem_sum_fields: unknown;
  OrderLineItem_sum_order_by: unknown;
  OrderLineItem_updates: unknown;
  OrderLineItem_var_pop_fields: unknown;
  OrderLineItem_var_pop_order_by: unknown;
  OrderLineItem_var_samp_fields: unknown;
  OrderLineItem_var_samp_order_by: unknown;
  OrderLineItem_variance_fields: unknown;
  OrderLineItem_variance_order_by: unknown;
  Order_aggregate: unknown;
  Order_aggregate_fields: unknown;
  Order_append_input: unknown;
  Order_avg_fields: unknown;
  Order_bool_exp: unknown;
  Order_delete_at_path_input: unknown;
  Order_delete_elem_input: unknown;
  Order_delete_key_input: unknown;
  Order_inc_input: unknown;
  Order_insert_input: unknown;
  Order_max_fields: unknown;
  Order_min_fields: unknown;
  Order_mutation_response: unknown;
  Order_obj_rel_insert_input: unknown;
  Order_on_conflict: unknown;
  Order_order_by: unknown;
  Order_pk_columns_input: unknown;
  Order_prepend_input: unknown;
  Order_set_input: unknown;
  Order_stddev_fields: unknown;
  Order_stddev_pop_fields: unknown;
  Order_stddev_samp_fields: unknown;
  Order_stream_cursor_input: unknown;
  Order_stream_cursor_value_input: unknown;
  Order_sum_fields: unknown;
  Order_updates: unknown;
  Order_var_pop_fields: unknown;
  Order_var_samp_fields: unknown;
  Order_variance_fields: unknown;
  PauseOrResumeOutput: unknown;
  PaymentHistory: unknown;
  PaymentHistory_aggregate: unknown;
  PaymentHistory_aggregate_bool_exp: unknown;
  PaymentHistory_aggregate_bool_exp_avg: unknown;
  PaymentHistory_aggregate_bool_exp_corr: unknown;
  PaymentHistory_aggregate_bool_exp_corr_arguments: unknown;
  PaymentHistory_aggregate_bool_exp_count: unknown;
  PaymentHistory_aggregate_bool_exp_covar_samp: unknown;
  PaymentHistory_aggregate_bool_exp_covar_samp_arguments: unknown;
  PaymentHistory_aggregate_bool_exp_max: unknown;
  PaymentHistory_aggregate_bool_exp_min: unknown;
  PaymentHistory_aggregate_bool_exp_stddev_samp: unknown;
  PaymentHistory_aggregate_bool_exp_sum: unknown;
  PaymentHistory_aggregate_bool_exp_var_samp: unknown;
  PaymentHistory_aggregate_fields: unknown;
  PaymentHistory_aggregate_order_by: unknown;
  PaymentHistory_arr_rel_insert_input: unknown;
  PaymentHistory_avg_fields: unknown;
  PaymentHistory_avg_order_by: unknown;
  PaymentHistory_bool_exp: unknown;
  PaymentHistory_inc_input: unknown;
  PaymentHistory_insert_input: unknown;
  PaymentHistory_max_fields: unknown;
  PaymentHistory_max_order_by: unknown;
  PaymentHistory_min_fields: unknown;
  PaymentHistory_min_order_by: unknown;
  PaymentHistory_mutation_response: unknown;
  PaymentHistory_on_conflict: unknown;
  PaymentHistory_order_by: unknown;
  PaymentHistory_pk_columns_input: unknown;
  PaymentHistory_set_input: unknown;
  PaymentHistory_stddev_fields: unknown;
  PaymentHistory_stddev_order_by: unknown;
  PaymentHistory_stddev_pop_fields: unknown;
  PaymentHistory_stddev_pop_order_by: unknown;
  PaymentHistory_stddev_samp_fields: unknown;
  PaymentHistory_stddev_samp_order_by: unknown;
  PaymentHistory_stream_cursor_input: unknown;
  PaymentHistory_stream_cursor_value_input: unknown;
  PaymentHistory_sum_fields: unknown;
  PaymentHistory_sum_order_by: unknown;
  PaymentHistory_updates: unknown;
  PaymentHistory_var_pop_fields: unknown;
  PaymentHistory_var_pop_order_by: unknown;
  PaymentHistory_var_samp_fields: unknown;
  PaymentHistory_var_samp_order_by: unknown;
  PaymentHistory_variance_fields: unknown;
  PaymentHistory_variance_order_by: unknown;
  PearTokens: unknown;
  PlanDay: unknown;
  PlanDay_aggregate: unknown;
  PlanDay_aggregate_bool_exp: unknown;
  PlanDay_aggregate_bool_exp_bool_and: unknown;
  PlanDay_aggregate_bool_exp_bool_or: unknown;
  PlanDay_aggregate_bool_exp_count: unknown;
  PlanDay_aggregate_fields: unknown;
  PlanDay_aggregate_order_by: unknown;
  PlanDay_arr_rel_insert_input: unknown;
  PlanDay_avg_fields: unknown;
  PlanDay_avg_order_by: unknown;
  PlanDay_bool_exp: unknown;
  PlanDay_inc_input: unknown;
  PlanDay_insert_input: unknown;
  PlanDay_max_fields: unknown;
  PlanDay_max_order_by: unknown;
  PlanDay_min_fields: unknown;
  PlanDay_min_order_by: unknown;
  PlanDay_mutation_response: unknown;
  PlanDay_on_conflict: unknown;
  PlanDay_order_by: unknown;
  PlanDay_pk_columns_input: unknown;
  PlanDay_set_input: unknown;
  PlanDay_stddev_fields: unknown;
  PlanDay_stddev_order_by: unknown;
  PlanDay_stddev_pop_fields: unknown;
  PlanDay_stddev_pop_order_by: unknown;
  PlanDay_stddev_samp_fields: unknown;
  PlanDay_stddev_samp_order_by: unknown;
  PlanDay_stream_cursor_input: unknown;
  PlanDay_stream_cursor_value_input: unknown;
  PlanDay_sum_fields: unknown;
  PlanDay_sum_order_by: unknown;
  PlanDay_updates: unknown;
  PlanDay_var_pop_fields: unknown;
  PlanDay_var_pop_order_by: unknown;
  PlanDay_var_samp_fields: unknown;
  PlanDay_var_samp_order_by: unknown;
  PlanDay_variance_fields: unknown;
  PlanDay_variance_order_by: unknown;
  PlanProgressCounts: unknown;
  PlanProgressOutput: unknown;
  PlanProgressRange: unknown;
  PlanType: unknown;
  PlanTypeObject: unknown;
  PlanType_comparison_exp: unknown;
  PresetDiet: unknown;
  PresetDiet_aggregate: unknown;
  PresetDiet_aggregate_fields: unknown;
  PresetDiet_bool_exp: unknown;
  PresetDiet_insert_input: unknown;
  PresetDiet_max_fields: unknown;
  PresetDiet_min_fields: unknown;
  PresetDiet_mutation_response: unknown;
  PresetDiet_obj_rel_insert_input: unknown;
  PresetDiet_on_conflict: unknown;
  PresetDiet_order_by: unknown;
  PresetDiet_pk_columns_input: unknown;
  PresetDiet_set_input: unknown;
  PresetDiet_stream_cursor_input: unknown;
  PresetDiet_stream_cursor_value_input: unknown;
  PresetDiet_updates: unknown;
  PresetPlanDay: unknown;
  PresetPlans: unknown;
  Pro: unknown;
  ProMusic: unknown;
  ProMusic_aggregate: unknown;
  ProMusic_aggregate_bool_exp: unknown;
  ProMusic_aggregate_bool_exp_count: unknown;
  ProMusic_aggregate_fields: unknown;
  ProMusic_aggregate_order_by: unknown;
  ProMusic_arr_rel_insert_input: unknown;
  ProMusic_bool_exp: unknown;
  ProMusic_insert_input: unknown;
  ProMusic_max_fields: unknown;
  ProMusic_max_order_by: unknown;
  ProMusic_min_fields: unknown;
  ProMusic_min_order_by: unknown;
  ProMusic_mutation_response: unknown;
  ProMusic_on_conflict: unknown;
  ProMusic_order_by: unknown;
  ProMusic_pk_columns_input: unknown;
  ProMusic_set_input: unknown;
  ProMusic_stream_cursor_input: unknown;
  ProMusic_stream_cursor_value_input: unknown;
  ProMusic_updates: unknown;
  Pro_aggregate: unknown;
  Pro_aggregate_bool_exp: unknown;
  Pro_aggregate_bool_exp_bool_and: unknown;
  Pro_aggregate_bool_exp_bool_or: unknown;
  Pro_aggregate_bool_exp_count: unknown;
  Pro_aggregate_fields: unknown;
  Pro_aggregate_order_by: unknown;
  Pro_arr_rel_insert_input: unknown;
  Pro_bool_exp: unknown;
  Pro_insert_input: unknown;
  Pro_max_fields: unknown;
  Pro_max_order_by: unknown;
  Pro_min_fields: unknown;
  Pro_min_order_by: unknown;
  Pro_mutation_response: unknown;
  Pro_obj_rel_insert_input: unknown;
  Pro_on_conflict: unknown;
  Pro_order_by: unknown;
  Pro_pk_columns_input: unknown;
  Pro_set_input: unknown;
  Pro_stream_cursor_input: unknown;
  Pro_stream_cursor_value_input: unknown;
  Pro_updates: unknown;
  Product: unknown;
  ProductDelivery: unknown;
  ProductLog: unknown;
  ProductLog_aggregate: unknown;
  ProductLog_aggregate_bool_exp: unknown;
  ProductLog_aggregate_bool_exp_count: unknown;
  ProductLog_aggregate_fields: unknown;
  ProductLog_aggregate_order_by: unknown;
  ProductLog_arr_rel_insert_input: unknown;
  ProductLog_bool_exp: unknown;
  ProductLog_insert_input: unknown;
  ProductLog_max_fields: unknown;
  ProductLog_max_order_by: unknown;
  ProductLog_min_fields: unknown;
  ProductLog_min_order_by: unknown;
  ProductLog_mutation_response: unknown;
  ProductLog_on_conflict: unknown;
  ProductLog_order_by: unknown;
  ProductLog_pk_columns_input: unknown;
  ProductLog_set_input: unknown;
  ProductLog_stream_cursor_input: unknown;
  ProductLog_stream_cursor_value_input: unknown;
  ProductLog_updates: unknown;
  Product_aggregate: unknown;
  Product_aggregate_fields: unknown;
  Product_avg_fields: unknown;
  Product_bool_exp: unknown;
  Product_inc_input: unknown;
  Product_insert_input: unknown;
  Product_max_fields: unknown;
  Product_min_fields: unknown;
  Product_mutation_response: unknown;
  Product_obj_rel_insert_input: unknown;
  Product_on_conflict: unknown;
  Product_order_by: unknown;
  Product_pk_columns_input: unknown;
  Product_set_input: unknown;
  Product_stddev_fields: unknown;
  Product_stddev_pop_fields: unknown;
  Product_stddev_samp_fields: unknown;
  Product_stream_cursor_input: unknown;
  Product_stream_cursor_value_input: unknown;
  Product_sum_fields: unknown;
  Product_updates: unknown;
  Product_var_pop_fields: unknown;
  Product_var_samp_fields: unknown;
  Product_variance_fields: unknown;
  ProfileStatsOutput: unknown;
  PromotionalItem: unknown;
  PromotionalItem_aggregate: unknown;
  PromotionalItem_aggregate_fields: unknown;
  PromotionalItem_avg_fields: unknown;
  PromotionalItem_bool_exp: unknown;
  PromotionalItem_inc_input: unknown;
  PromotionalItem_insert_input: unknown;
  PromotionalItem_max_fields: unknown;
  PromotionalItem_min_fields: unknown;
  PromotionalItem_mutation_response: unknown;
  PromotionalItem_on_conflict: unknown;
  PromotionalItem_order_by: unknown;
  PromotionalItem_pk_columns_input: unknown;
  PromotionalItem_set_input: unknown;
  PromotionalItem_stddev_fields: unknown;
  PromotionalItem_stddev_pop_fields: unknown;
  PromotionalItem_stddev_samp_fields: unknown;
  PromotionalItem_stream_cursor_input: unknown;
  PromotionalItem_stream_cursor_value_input: unknown;
  PromotionalItem_sum_fields: unknown;
  PromotionalItem_updates: unknown;
  PromotionalItem_var_pop_fields: unknown;
  PromotionalItem_var_samp_fields: unknown;
  PromotionalItem_variance_fields: unknown;
  Props: unknown;
  QuizSurvey: unknown;
  QuizSurvey_aggregate: unknown;
  QuizSurvey_aggregate_bool_exp: unknown;
  QuizSurvey_aggregate_bool_exp_bool_and: unknown;
  QuizSurvey_aggregate_bool_exp_bool_or: unknown;
  QuizSurvey_aggregate_bool_exp_count: unknown;
  QuizSurvey_aggregate_fields: unknown;
  QuizSurvey_aggregate_order_by: unknown;
  QuizSurvey_arr_rel_insert_input: unknown;
  QuizSurvey_avg_fields: unknown;
  QuizSurvey_avg_order_by: unknown;
  QuizSurvey_bool_exp: unknown;
  QuizSurvey_inc_input: unknown;
  QuizSurvey_insert_input: unknown;
  QuizSurvey_max_fields: unknown;
  QuizSurvey_max_order_by: unknown;
  QuizSurvey_min_fields: unknown;
  QuizSurvey_min_order_by: unknown;
  QuizSurvey_mutation_response: unknown;
  QuizSurvey_on_conflict: unknown;
  QuizSurvey_order_by: unknown;
  QuizSurvey_pk_columns_input: unknown;
  QuizSurvey_set_input: unknown;
  QuizSurvey_stddev_fields: unknown;
  QuizSurvey_stddev_order_by: unknown;
  QuizSurvey_stddev_pop_fields: unknown;
  QuizSurvey_stddev_pop_order_by: unknown;
  QuizSurvey_stddev_samp_fields: unknown;
  QuizSurvey_stddev_samp_order_by: unknown;
  QuizSurvey_stream_cursor_input: unknown;
  QuizSurvey_stream_cursor_value_input: unknown;
  QuizSurvey_sum_fields: unknown;
  QuizSurvey_sum_order_by: unknown;
  QuizSurvey_updates: unknown;
  QuizSurvey_var_pop_fields: unknown;
  QuizSurvey_var_pop_order_by: unknown;
  QuizSurvey_var_samp_fields: unknown;
  QuizSurvey_var_samp_order_by: unknown;
  QuizSurvey_variance_fields: unknown;
  QuizSurvey_variance_order_by: unknown;
  Quote: unknown;
  Quote_aggregate: unknown;
  Quote_aggregate_fields: unknown;
  Quote_bool_exp: unknown;
  Quote_insert_input: unknown;
  Quote_max_fields: unknown;
  Quote_min_fields: unknown;
  Quote_mutation_response: unknown;
  Quote_on_conflict: unknown;
  Quote_order_by: unknown;
  Quote_pk_columns_input: unknown;
  Quote_set_input: unknown;
  Quote_stream_cursor_input: unknown;
  Quote_stream_cursor_value_input: unknown;
  Quote_updates: unknown;
  RecommenderOutput: unknown;
  RecommenderV2CardioAlternatives: unknown;
  RecommenderV2CardioObj: unknown;
  RecommenderV2Output: unknown;
  RecommenderV2WorkinAlternatives: unknown;
  RecommenderV2WorkinObj: unknown;
  RecommenderWorkinObj: unknown;
  RecommenderWorkinSubAlternativeObj: unknown;
  RecommenderWorkinSubObj: unknown;
  RegisterUser: unknown;
  ReimbursementRequirement: unknown;
  ReimbursementRequirement_aggregate: unknown;
  ReimbursementRequirement_aggregate_fields: unknown;
  ReimbursementRequirement_avg_fields: unknown;
  ReimbursementRequirement_bool_exp: unknown;
  ReimbursementRequirement_inc_input: unknown;
  ReimbursementRequirement_insert_input: unknown;
  ReimbursementRequirement_max_fields: unknown;
  ReimbursementRequirement_min_fields: unknown;
  ReimbursementRequirement_mutation_response: unknown;
  ReimbursementRequirement_on_conflict: unknown;
  ReimbursementRequirement_order_by: unknown;
  ReimbursementRequirement_pk_columns_input: unknown;
  ReimbursementRequirement_set_input: unknown;
  ReimbursementRequirement_stddev_fields: unknown;
  ReimbursementRequirement_stddev_pop_fields: unknown;
  ReimbursementRequirement_stddev_samp_fields: unknown;
  ReimbursementRequirement_stream_cursor_input: unknown;
  ReimbursementRequirement_stream_cursor_value_input: unknown;
  ReimbursementRequirement_sum_fields: unknown;
  ReimbursementRequirement_updates: unknown;
  ReimbursementRequirement_var_pop_fields: unknown;
  ReimbursementRequirement_var_samp_fields: unknown;
  ReimbursementRequirement_variance_fields: unknown;
  RepItemInput: unknown;
  RepMasteryResponse: unknown;
  Response: unknown;
  ResultListOutput: unknown;
  ResultObjectOutput: unknown;
  RetrieveFirmwareV2Output: unknown;
  Role: unknown;
  Role_comparison_exp: unknown;
  SampleInput: unknown;
  SampleOutput: unknown;
  SampleQuizSurveyOutput: unknown;
  SampleScreenOutput: unknown;
  SampleWorkoutZoneOutput: unknown;
  ScreenOrder: unknown;
  ScreenOrder_aggregate: unknown;
  ScreenOrder_aggregate_bool_exp: unknown;
  ScreenOrder_aggregate_bool_exp_count: unknown;
  ScreenOrder_aggregate_fields: unknown;
  ScreenOrder_aggregate_order_by: unknown;
  ScreenOrder_append_input: unknown;
  ScreenOrder_arr_rel_insert_input: unknown;
  ScreenOrder_avg_fields: unknown;
  ScreenOrder_avg_order_by: unknown;
  ScreenOrder_bool_exp: unknown;
  ScreenOrder_delete_at_path_input: unknown;
  ScreenOrder_delete_elem_input: unknown;
  ScreenOrder_delete_key_input: unknown;
  ScreenOrder_inc_input: unknown;
  ScreenOrder_insert_input: unknown;
  ScreenOrder_max_fields: unknown;
  ScreenOrder_max_order_by: unknown;
  ScreenOrder_min_fields: unknown;
  ScreenOrder_min_order_by: unknown;
  ScreenOrder_mutation_response: unknown;
  ScreenOrder_on_conflict: unknown;
  ScreenOrder_order_by: unknown;
  ScreenOrder_pk_columns_input: unknown;
  ScreenOrder_prepend_input: unknown;
  ScreenOrder_set_input: unknown;
  ScreenOrder_stddev_fields: unknown;
  ScreenOrder_stddev_order_by: unknown;
  ScreenOrder_stddev_pop_fields: unknown;
  ScreenOrder_stddev_pop_order_by: unknown;
  ScreenOrder_stddev_samp_fields: unknown;
  ScreenOrder_stddev_samp_order_by: unknown;
  ScreenOrder_stream_cursor_input: unknown;
  ScreenOrder_stream_cursor_value_input: unknown;
  ScreenOrder_sum_fields: unknown;
  ScreenOrder_sum_order_by: unknown;
  ScreenOrder_updates: unknown;
  ScreenOrder_var_pop_fields: unknown;
  ScreenOrder_var_pop_order_by: unknown;
  ScreenOrder_var_samp_fields: unknown;
  ScreenOrder_var_samp_order_by: unknown;
  ScreenOrder_variance_fields: unknown;
  ScreenOrder_variance_order_by: unknown;
  SearchResult: unknown;
  SearchResult_aggregate: unknown;
  SearchResult_aggregate_fields: unknown;
  SearchResult_avg_fields: unknown;
  SearchResult_bool_exp: unknown;
  SearchResult_inc_input: unknown;
  SearchResult_insert_input: unknown;
  SearchResult_max_fields: unknown;
  SearchResult_min_fields: unknown;
  SearchResult_mutation_response: unknown;
  SearchResult_on_conflict: unknown;
  SearchResult_order_by: unknown;
  SearchResult_pk_columns_input: unknown;
  SearchResult_set_input: unknown;
  SearchResult_stddev_fields: unknown;
  SearchResult_stddev_pop_fields: unknown;
  SearchResult_stddev_samp_fields: unknown;
  SearchResult_stream_cursor_input: unknown;
  SearchResult_stream_cursor_value_input: unknown;
  SearchResult_sum_fields: unknown;
  SearchResult_updates: unknown;
  SearchResult_var_pop_fields: unknown;
  SearchResult_var_samp_fields: unknown;
  SearchResult_variance_fields: unknown;
  SetQuality: unknown;
  SetQuality_comparison_exp: unknown;
  SetUserGoalsOutput: unknown;
  SfCoachCallOutput: unknown;
  SfFitnessGoalOutput: unknown;
  ShipStatusMessage: unknown;
  ShippingAddressOutput: unknown;
  SleepInsightsDetailOutput: unknown;
  SleepInsightsOutput: unknown;
  SleepSurvey: unknown;
  SleepSurvey_aggregate: unknown;
  SleepSurvey_aggregate_bool_exp: unknown;
  SleepSurvey_aggregate_bool_exp_bool_and: unknown;
  SleepSurvey_aggregate_bool_exp_bool_or: unknown;
  SleepSurvey_aggregate_bool_exp_count: unknown;
  SleepSurvey_aggregate_fields: unknown;
  SleepSurvey_aggregate_order_by: unknown;
  SleepSurvey_arr_rel_insert_input: unknown;
  SleepSurvey_bool_exp: unknown;
  SleepSurvey_insert_input: unknown;
  SleepSurvey_max_fields: unknown;
  SleepSurvey_max_order_by: unknown;
  SleepSurvey_min_fields: unknown;
  SleepSurvey_min_order_by: unknown;
  SleepSurvey_mutation_response: unknown;
  SleepSurvey_on_conflict: unknown;
  SleepSurvey_order_by: unknown;
  SleepSurvey_pk_columns_input: unknown;
  SleepSurvey_set_input: unknown;
  SleepSurvey_stream_cursor_input: unknown;
  SleepSurvey_stream_cursor_value_input: unknown;
  SleepSurvey_updates: unknown;
  StartAssessmentOutput: unknown;
  StepsV2Output: unknown;
  StrapLocation: unknown;
  StrapLocation_comparison_exp: unknown;
  String: unknown;
  String_array_comparison_exp: unknown;
  String_comparison_exp: unknown;
  SubWorkoutType: unknown;
  SubWorkoutType_comparison_exp: unknown;
  Subscription: unknown;
  SubscriptionLog: unknown;
  SubscriptionLog_aggregate: unknown;
  SubscriptionLog_aggregate_fields: unknown;
  SubscriptionLog_bool_exp: unknown;
  SubscriptionLog_insert_input: unknown;
  SubscriptionLog_max_fields: unknown;
  SubscriptionLog_min_fields: unknown;
  SubscriptionLog_mutation_response: unknown;
  SubscriptionLog_on_conflict: unknown;
  SubscriptionLog_order_by: unknown;
  SubscriptionLog_pk_columns_input: unknown;
  SubscriptionLog_set_input: unknown;
  SubscriptionLog_stream_cursor_input: unknown;
  SubscriptionLog_stream_cursor_value_input: unknown;
  SubscriptionLog_updates: unknown;
  SubscriptionType: unknown;
  SubscriptionType_comparison_exp: unknown;
  Subscription_aggregate: unknown;
  Subscription_aggregate_fields: unknown;
  Subscription_avg_fields: unknown;
  Subscription_bool_exp: unknown;
  Subscription_inc_input: unknown;
  Subscription_insert_input: unknown;
  Subscription_max_fields: unknown;
  Subscription_min_fields: unknown;
  Subscription_mutation_response: unknown;
  Subscription_obj_rel_insert_input: unknown;
  Subscription_on_conflict: unknown;
  Subscription_order_by: unknown;
  Subscription_pk_columns_input: unknown;
  Subscription_set_input: unknown;
  Subscription_stddev_fields: unknown;
  Subscription_stddev_pop_fields: unknown;
  Subscription_stddev_samp_fields: unknown;
  Subscription_stream_cursor_input: unknown;
  Subscription_stream_cursor_value_input: unknown;
  Subscription_sum_fields: unknown;
  Subscription_updates: unknown;
  Subscription_var_pop_fields: unknown;
  Subscription_var_samp_fields: unknown;
  Subscription_variance_fields: unknown;
  SurveyActivity: unknown;
  SurveyActivity_comparison_exp: unknown;
  SurveyAreasNeedingHelp: unknown;
  SurveyAreasNeedingHelp_array_comparison_exp: unknown;
  SurveyEquipment: unknown;
  SurveyEquipment_array_comparison_exp: unknown;
  SurveyEquipment_comparison_exp: unknown;
  SurveyFavoriteExercise: unknown;
  SurveyFavoriteExercise_comparison_exp: unknown;
  SurveyFitnessAbility: unknown;
  SurveyFitnessAbility_comparison_exp: unknown;
  SurveyMotivation: unknown;
  SurveyMotivation_comparison_exp: unknown;
  SurveyQuizAge: unknown;
  SurveyQuizAge_comparison_exp: unknown;
  SurveyQuizGender: unknown;
  SurveyQuizGender_comparison_exp: unknown;
  SurveyServicesTried: unknown;
  SurveyServicesTried_array_comparison_exp: unknown;
  SurveySleep: unknown;
  SurveySleep_comparison_exp: unknown;
  SurveyWeightLossGoal: unknown;
  SurveyWeightLossGoal_comparison_exp: unknown;
  SurveyWeightLossTimeline: unknown;
  SurveyWeightLossTimeline_comparison_exp: unknown;
  SurveyWorkouts: unknown;
  SurveyWorkouts_array_comparison_exp: unknown;
  SurveyWorkouts_comparison_exp: unknown;
  ThemeColor: unknown;
  ThemeColor_comparison_exp: unknown;
  TimeMovingOutput: unknown;
  TimeWorkingOutOutput: unknown;
  TitleWithDescription: unknown;
  Token: unknown;
  TokenChangeSource: unknown;
  TokenChangeSource_comparison_exp: unknown;
  TokenChangeType: unknown;
  TokenChangeType_comparison_exp: unknown;
  TokenHistory: unknown;
  TokenHistory_aggregate: unknown;
  TokenHistory_aggregate_bool_exp: unknown;
  TokenHistory_aggregate_bool_exp_count: unknown;
  TokenHistory_aggregate_fields: unknown;
  TokenHistory_aggregate_order_by: unknown;
  TokenHistory_arr_rel_insert_input: unknown;
  TokenHistory_avg_fields: unknown;
  TokenHistory_avg_order_by: unknown;
  TokenHistory_bool_exp: unknown;
  TokenHistory_inc_input: unknown;
  TokenHistory_insert_input: unknown;
  TokenHistory_max_fields: unknown;
  TokenHistory_max_order_by: unknown;
  TokenHistory_min_fields: unknown;
  TokenHistory_min_order_by: unknown;
  TokenHistory_mutation_response: unknown;
  TokenHistory_obj_rel_insert_input: unknown;
  TokenHistory_on_conflict: unknown;
  TokenHistory_order_by: unknown;
  TokenHistory_pk_columns_input: unknown;
  TokenHistory_set_input: unknown;
  TokenHistory_stddev_fields: unknown;
  TokenHistory_stddev_order_by: unknown;
  TokenHistory_stddev_pop_fields: unknown;
  TokenHistory_stddev_pop_order_by: unknown;
  TokenHistory_stddev_samp_fields: unknown;
  TokenHistory_stddev_samp_order_by: unknown;
  TokenHistory_stream_cursor_input: unknown;
  TokenHistory_stream_cursor_value_input: unknown;
  TokenHistory_sum_fields: unknown;
  TokenHistory_sum_order_by: unknown;
  TokenHistory_updates: unknown;
  TokenHistory_var_pop_fields: unknown;
  TokenHistory_var_pop_order_by: unknown;
  TokenHistory_var_samp_fields: unknown;
  TokenHistory_var_samp_order_by: unknown;
  TokenHistory_variance_fields: unknown;
  TokenHistory_variance_order_by: unknown;
  Token_aggregate: unknown;
  Token_aggregate_fields: unknown;
  Token_avg_fields: unknown;
  Token_bool_exp: unknown;
  Token_inc_input: unknown;
  Token_insert_input: unknown;
  Token_max_fields: unknown;
  Token_min_fields: unknown;
  Token_mutation_response: unknown;
  Token_on_conflict: unknown;
  Token_order_by: unknown;
  Token_pk_columns_input: unknown;
  Token_set_input: unknown;
  Token_stddev_fields: unknown;
  Token_stddev_pop_fields: unknown;
  Token_stddev_samp_fields: unknown;
  Token_stream_cursor_input: unknown;
  Token_stream_cursor_value_input: unknown;
  Token_sum_fields: unknown;
  Token_updates: unknown;
  Token_var_pop_fields: unknown;
  Token_var_samp_fields: unknown;
  Token_variance_fields: unknown;
  TutorialMode: unknown;
  TutorialMode_comparison_exp: unknown;
  Unit: unknown;
  Unit_comparison_exp: unknown;
  UploadContents: unknown;
  UploadSetOutput: unknown;
  UploadSetV2Output: unknown;
  User: unknown;
  UserAggregates: unknown;
  UserAggregates_aggregate: unknown;
  UserAggregates_aggregate_bool_exp: unknown;
  UserAggregates_aggregate_bool_exp_avg: unknown;
  UserAggregates_aggregate_bool_exp_corr: unknown;
  UserAggregates_aggregate_bool_exp_corr_arguments: unknown;
  UserAggregates_aggregate_bool_exp_count: unknown;
  UserAggregates_aggregate_bool_exp_covar_samp: unknown;
  UserAggregates_aggregate_bool_exp_covar_samp_arguments: unknown;
  UserAggregates_aggregate_bool_exp_max: unknown;
  UserAggregates_aggregate_bool_exp_min: unknown;
  UserAggregates_aggregate_bool_exp_stddev_samp: unknown;
  UserAggregates_aggregate_bool_exp_sum: unknown;
  UserAggregates_aggregate_bool_exp_var_samp: unknown;
  UserAggregates_aggregate_fields: unknown;
  UserAggregates_aggregate_order_by: unknown;
  UserAggregates_arr_rel_insert_input: unknown;
  UserAggregates_avg_fields: unknown;
  UserAggregates_avg_order_by: unknown;
  UserAggregates_bool_exp: unknown;
  UserAggregates_inc_input: unknown;
  UserAggregates_insert_input: unknown;
  UserAggregates_max_fields: unknown;
  UserAggregates_max_order_by: unknown;
  UserAggregates_min_fields: unknown;
  UserAggregates_min_order_by: unknown;
  UserAggregates_mutation_response: unknown;
  UserAggregates_on_conflict: unknown;
  UserAggregates_order_by: unknown;
  UserAggregates_pk_columns_input: unknown;
  UserAggregates_set_input: unknown;
  UserAggregates_stddev_fields: unknown;
  UserAggregates_stddev_order_by: unknown;
  UserAggregates_stddev_pop_fields: unknown;
  UserAggregates_stddev_pop_order_by: unknown;
  UserAggregates_stddev_samp_fields: unknown;
  UserAggregates_stddev_samp_order_by: unknown;
  UserAggregates_stream_cursor_input: unknown;
  UserAggregates_stream_cursor_value_input: unknown;
  UserAggregates_sum_fields: unknown;
  UserAggregates_sum_order_by: unknown;
  UserAggregates_updates: unknown;
  UserAggregates_var_pop_fields: unknown;
  UserAggregates_var_pop_order_by: unknown;
  UserAggregates_var_samp_fields: unknown;
  UserAggregates_var_samp_order_by: unknown;
  UserAggregates_variance_fields: unknown;
  UserAggregates_variance_order_by: unknown;
  UserAssessment: unknown;
  UserAssessment_aggregate: unknown;
  UserAssessment_aggregate_bool_exp: unknown;
  UserAssessment_aggregate_bool_exp_avg: unknown;
  UserAssessment_aggregate_bool_exp_corr: unknown;
  UserAssessment_aggregate_bool_exp_corr_arguments: unknown;
  UserAssessment_aggregate_bool_exp_count: unknown;
  UserAssessment_aggregate_bool_exp_covar_samp: unknown;
  UserAssessment_aggregate_bool_exp_covar_samp_arguments: unknown;
  UserAssessment_aggregate_bool_exp_max: unknown;
  UserAssessment_aggregate_bool_exp_min: unknown;
  UserAssessment_aggregate_bool_exp_stddev_samp: unknown;
  UserAssessment_aggregate_bool_exp_sum: unknown;
  UserAssessment_aggregate_bool_exp_var_samp: unknown;
  UserAssessment_aggregate_fields: unknown;
  UserAssessment_aggregate_order_by: unknown;
  UserAssessment_append_input: unknown;
  UserAssessment_arr_rel_insert_input: unknown;
  UserAssessment_avg_fields: unknown;
  UserAssessment_avg_order_by: unknown;
  UserAssessment_bool_exp: unknown;
  UserAssessment_delete_at_path_input: unknown;
  UserAssessment_delete_elem_input: unknown;
  UserAssessment_delete_key_input: unknown;
  UserAssessment_inc_input: unknown;
  UserAssessment_insert_input: unknown;
  UserAssessment_max_fields: unknown;
  UserAssessment_max_order_by: unknown;
  UserAssessment_min_fields: unknown;
  UserAssessment_min_order_by: unknown;
  UserAssessment_mutation_response: unknown;
  UserAssessment_obj_rel_insert_input: unknown;
  UserAssessment_on_conflict: unknown;
  UserAssessment_order_by: unknown;
  UserAssessment_pk_columns_input: unknown;
  UserAssessment_prepend_input: unknown;
  UserAssessment_set_input: unknown;
  UserAssessment_stddev_fields: unknown;
  UserAssessment_stddev_order_by: unknown;
  UserAssessment_stddev_pop_fields: unknown;
  UserAssessment_stddev_pop_order_by: unknown;
  UserAssessment_stddev_samp_fields: unknown;
  UserAssessment_stddev_samp_order_by: unknown;
  UserAssessment_stream_cursor_input: unknown;
  UserAssessment_stream_cursor_value_input: unknown;
  UserAssessment_sum_fields: unknown;
  UserAssessment_sum_order_by: unknown;
  UserAssessment_updates: unknown;
  UserAssessment_var_pop_fields: unknown;
  UserAssessment_var_pop_order_by: unknown;
  UserAssessment_var_samp_fields: unknown;
  UserAssessment_var_samp_order_by: unknown;
  UserAssessment_variance_fields: unknown;
  UserAssessment_variance_order_by: unknown;
  UserFeatureControl: unknown;
  UserFeatureControl_aggregate: unknown;
  UserFeatureControl_aggregate_bool_exp: unknown;
  UserFeatureControl_aggregate_bool_exp_bool_and: unknown;
  UserFeatureControl_aggregate_bool_exp_bool_or: unknown;
  UserFeatureControl_aggregate_bool_exp_count: unknown;
  UserFeatureControl_aggregate_fields: unknown;
  UserFeatureControl_aggregate_order_by: unknown;
  UserFeatureControl_arr_rel_insert_input: unknown;
  UserFeatureControl_bool_exp: unknown;
  UserFeatureControl_insert_input: unknown;
  UserFeatureControl_max_fields: unknown;
  UserFeatureControl_max_order_by: unknown;
  UserFeatureControl_min_fields: unknown;
  UserFeatureControl_min_order_by: unknown;
  UserFeatureControl_mutation_response: unknown;
  UserFeatureControl_on_conflict: unknown;
  UserFeatureControl_order_by: unknown;
  UserFeatureControl_pk_columns_input: unknown;
  UserFeatureControl_set_input: unknown;
  UserFeatureControl_stream_cursor_input: unknown;
  UserFeatureControl_stream_cursor_value_input: unknown;
  UserFeatureControl_updates: unknown;
  UserFeedback: unknown;
  UserFeedbackInput: unknown;
  UserFeedback_aggregate: unknown;
  UserFeedback_aggregate_bool_exp: unknown;
  UserFeedback_aggregate_bool_exp_count: unknown;
  UserFeedback_aggregate_fields: unknown;
  UserFeedback_aggregate_order_by: unknown;
  UserFeedback_arr_rel_insert_input: unknown;
  UserFeedback_avg_fields: unknown;
  UserFeedback_avg_order_by: unknown;
  UserFeedback_bool_exp: unknown;
  UserFeedback_inc_input: unknown;
  UserFeedback_insert_input: unknown;
  UserFeedback_max_fields: unknown;
  UserFeedback_max_order_by: unknown;
  UserFeedback_min_fields: unknown;
  UserFeedback_min_order_by: unknown;
  UserFeedback_mutation_response: unknown;
  UserFeedback_on_conflict: unknown;
  UserFeedback_order_by: unknown;
  UserFeedback_pk_columns_input: unknown;
  UserFeedback_set_input: unknown;
  UserFeedback_stddev_fields: unknown;
  UserFeedback_stddev_order_by: unknown;
  UserFeedback_stddev_pop_fields: unknown;
  UserFeedback_stddev_pop_order_by: unknown;
  UserFeedback_stddev_samp_fields: unknown;
  UserFeedback_stddev_samp_order_by: unknown;
  UserFeedback_stream_cursor_input: unknown;
  UserFeedback_stream_cursor_value_input: unknown;
  UserFeedback_sum_fields: unknown;
  UserFeedback_sum_order_by: unknown;
  UserFeedback_updates: unknown;
  UserFeedback_var_pop_fields: unknown;
  UserFeedback_var_pop_order_by: unknown;
  UserFeedback_var_samp_fields: unknown;
  UserFeedback_var_samp_order_by: unknown;
  UserFeedback_variance_fields: unknown;
  UserFeedback_variance_order_by: unknown;
  UserFinancePlan: unknown;
  UserFinancePlan_aggregate: unknown;
  UserFinancePlan_aggregate_bool_exp: unknown;
  UserFinancePlan_aggregate_bool_exp_count: unknown;
  UserFinancePlan_aggregate_fields: unknown;
  UserFinancePlan_aggregate_order_by: unknown;
  UserFinancePlan_arr_rel_insert_input: unknown;
  UserFinancePlan_bool_exp: unknown;
  UserFinancePlan_insert_input: unknown;
  UserFinancePlan_max_fields: unknown;
  UserFinancePlan_max_order_by: unknown;
  UserFinancePlan_min_fields: unknown;
  UserFinancePlan_min_order_by: unknown;
  UserFinancePlan_mutation_response: unknown;
  UserFinancePlan_obj_rel_insert_input: unknown;
  UserFinancePlan_on_conflict: unknown;
  UserFinancePlan_order_by: unknown;
  UserFinancePlan_pk_columns_input: unknown;
  UserFinancePlan_set_input: unknown;
  UserFinancePlan_stream_cursor_input: unknown;
  UserFinancePlan_stream_cursor_value_input: unknown;
  UserFinancePlan_updates: unknown;
  UserHabit: unknown;
  UserHabit_aggregate: unknown;
  UserHabit_aggregate_fields: unknown;
  UserHabit_avg_fields: unknown;
  UserHabit_bool_exp: unknown;
  UserHabit_inc_input: unknown;
  UserHabit_insert_input: unknown;
  UserHabit_max_fields: unknown;
  UserHabit_min_fields: unknown;
  UserHabit_mutation_response: unknown;
  UserHabit_on_conflict: unknown;
  UserHabit_order_by: unknown;
  UserHabit_pk_columns_input: unknown;
  UserHabit_set_input: unknown;
  UserHabit_stddev_fields: unknown;
  UserHabit_stddev_pop_fields: unknown;
  UserHabit_stddev_samp_fields: unknown;
  UserHabit_stream_cursor_input: unknown;
  UserHabit_stream_cursor_value_input: unknown;
  UserHabit_sum_fields: unknown;
  UserHabit_updates: unknown;
  UserHabit_var_pop_fields: unknown;
  UserHabit_var_samp_fields: unknown;
  UserHabit_variance_fields: unknown;
  UserMonthsActiveOutput: unknown;
  UserMovementDaily: unknown;
  UserMovementDaily_aggregate: unknown;
  UserMovementDaily_aggregate_bool_exp: unknown;
  UserMovementDaily_aggregate_bool_exp_avg: unknown;
  UserMovementDaily_aggregate_bool_exp_corr: unknown;
  UserMovementDaily_aggregate_bool_exp_corr_arguments: unknown;
  UserMovementDaily_aggregate_bool_exp_count: unknown;
  UserMovementDaily_aggregate_bool_exp_covar_samp: unknown;
  UserMovementDaily_aggregate_bool_exp_covar_samp_arguments: unknown;
  UserMovementDaily_aggregate_bool_exp_max: unknown;
  UserMovementDaily_aggregate_bool_exp_min: unknown;
  UserMovementDaily_aggregate_bool_exp_stddev_samp: unknown;
  UserMovementDaily_aggregate_bool_exp_sum: unknown;
  UserMovementDaily_aggregate_bool_exp_var_samp: unknown;
  UserMovementDaily_aggregate_fields: unknown;
  UserMovementDaily_aggregate_order_by: unknown;
  UserMovementDaily_arr_rel_insert_input: unknown;
  UserMovementDaily_avg_fields: unknown;
  UserMovementDaily_avg_order_by: unknown;
  UserMovementDaily_bool_exp: unknown;
  UserMovementDaily_inc_input: unknown;
  UserMovementDaily_insert_input: unknown;
  UserMovementDaily_max_fields: unknown;
  UserMovementDaily_max_order_by: unknown;
  UserMovementDaily_min_fields: unknown;
  UserMovementDaily_min_order_by: unknown;
  UserMovementDaily_mutation_response: unknown;
  UserMovementDaily_on_conflict: unknown;
  UserMovementDaily_order_by: unknown;
  UserMovementDaily_pk_columns_input: unknown;
  UserMovementDaily_set_input: unknown;
  UserMovementDaily_stddev_fields: unknown;
  UserMovementDaily_stddev_order_by: unknown;
  UserMovementDaily_stddev_pop_fields: unknown;
  UserMovementDaily_stddev_pop_order_by: unknown;
  UserMovementDaily_stddev_samp_fields: unknown;
  UserMovementDaily_stddev_samp_order_by: unknown;
  UserMovementDaily_stream_cursor_input: unknown;
  UserMovementDaily_stream_cursor_value_input: unknown;
  UserMovementDaily_sum_fields: unknown;
  UserMovementDaily_sum_order_by: unknown;
  UserMovementDaily_updates: unknown;
  UserMovementDaily_var_pop_fields: unknown;
  UserMovementDaily_var_pop_order_by: unknown;
  UserMovementDaily_var_samp_fields: unknown;
  UserMovementDaily_var_samp_order_by: unknown;
  UserMovementDaily_variance_fields: unknown;
  UserMovementDaily_variance_order_by: unknown;
  UserPreferences: unknown;
  UserPreferencesOutput: unknown;
  UserPreferences_aggregate: unknown;
  UserPreferences_aggregate_bool_exp: unknown;
  UserPreferences_aggregate_bool_exp_bool_and: unknown;
  UserPreferences_aggregate_bool_exp_bool_or: unknown;
  UserPreferences_aggregate_bool_exp_count: unknown;
  UserPreferences_aggregate_fields: unknown;
  UserPreferences_aggregate_order_by: unknown;
  UserPreferences_arr_rel_insert_input: unknown;
  UserPreferences_bool_exp: unknown;
  UserPreferences_insert_input: unknown;
  UserPreferences_max_fields: unknown;
  UserPreferences_max_order_by: unknown;
  UserPreferences_min_fields: unknown;
  UserPreferences_min_order_by: unknown;
  UserPreferences_mutation_response: unknown;
  UserPreferences_obj_rel_insert_input: unknown;
  UserPreferences_on_conflict: unknown;
  UserPreferences_order_by: unknown;
  UserPreferences_pk_columns_input: unknown;
  UserPreferences_set_input: unknown;
  UserPreferences_stream_cursor_input: unknown;
  UserPreferences_stream_cursor_value_input: unknown;
  UserPreferences_updates: unknown;
  UserProduct: unknown;
  UserProductHistory: unknown;
  UserProductHistory_aggregate: unknown;
  UserProductHistory_aggregate_bool_exp: unknown;
  UserProductHistory_aggregate_bool_exp_count: unknown;
  UserProductHistory_aggregate_fields: unknown;
  UserProductHistory_aggregate_order_by: unknown;
  UserProductHistory_arr_rel_insert_input: unknown;
  UserProductHistory_bool_exp: unknown;
  UserProductHistory_insert_input: unknown;
  UserProductHistory_max_fields: unknown;
  UserProductHistory_max_order_by: unknown;
  UserProductHistory_min_fields: unknown;
  UserProductHistory_min_order_by: unknown;
  UserProductHistory_mutation_response: unknown;
  UserProductHistory_on_conflict: unknown;
  UserProductHistory_order_by: unknown;
  UserProductHistory_pk_columns_input: unknown;
  UserProductHistory_set_input: unknown;
  UserProductHistory_stream_cursor_input: unknown;
  UserProductHistory_stream_cursor_value_input: unknown;
  UserProductHistory_updates: unknown;
  UserProduct_aggregate: unknown;
  UserProduct_aggregate_bool_exp: unknown;
  UserProduct_aggregate_bool_exp_count: unknown;
  UserProduct_aggregate_fields: unknown;
  UserProduct_aggregate_order_by: unknown;
  UserProduct_arr_rel_insert_input: unknown;
  UserProduct_avg_fields: unknown;
  UserProduct_avg_order_by: unknown;
  UserProduct_bool_exp: unknown;
  UserProduct_inc_input: unknown;
  UserProduct_insert_input: unknown;
  UserProduct_max_fields: unknown;
  UserProduct_max_order_by: unknown;
  UserProduct_min_fields: unknown;
  UserProduct_min_order_by: unknown;
  UserProduct_mutation_response: unknown;
  UserProduct_obj_rel_insert_input: unknown;
  UserProduct_on_conflict: unknown;
  UserProduct_order_by: unknown;
  UserProduct_pk_columns_input: unknown;
  UserProduct_set_input: unknown;
  UserProduct_stddev_fields: unknown;
  UserProduct_stddev_order_by: unknown;
  UserProduct_stddev_pop_fields: unknown;
  UserProduct_stddev_pop_order_by: unknown;
  UserProduct_stddev_samp_fields: unknown;
  UserProduct_stddev_samp_order_by: unknown;
  UserProduct_stream_cursor_input: unknown;
  UserProduct_stream_cursor_value_input: unknown;
  UserProduct_sum_fields: unknown;
  UserProduct_sum_order_by: unknown;
  UserProduct_updates: unknown;
  UserProduct_var_pop_fields: unknown;
  UserProduct_var_pop_order_by: unknown;
  UserProduct_var_samp_fields: unknown;
  UserProduct_var_samp_order_by: unknown;
  UserProduct_variance_fields: unknown;
  UserProduct_variance_order_by: unknown;
  UserProductsOutput: unknown;
  UserReadiness: unknown;
  UserReadinessLog: unknown;
  UserReadinessLog_aggregate: unknown;
  UserReadinessLog_aggregate_bool_exp: unknown;
  UserReadinessLog_aggregate_bool_exp_count: unknown;
  UserReadinessLog_aggregate_fields: unknown;
  UserReadinessLog_aggregate_order_by: unknown;
  UserReadinessLog_append_input: unknown;
  UserReadinessLog_arr_rel_insert_input: unknown;
  UserReadinessLog_bool_exp: unknown;
  UserReadinessLog_delete_at_path_input: unknown;
  UserReadinessLog_delete_elem_input: unknown;
  UserReadinessLog_delete_key_input: unknown;
  UserReadinessLog_insert_input: unknown;
  UserReadinessLog_max_fields: unknown;
  UserReadinessLog_max_order_by: unknown;
  UserReadinessLog_min_fields: unknown;
  UserReadinessLog_min_order_by: unknown;
  UserReadinessLog_mutation_response: unknown;
  UserReadinessLog_on_conflict: unknown;
  UserReadinessLog_order_by: unknown;
  UserReadinessLog_pk_columns_input: unknown;
  UserReadinessLog_prepend_input: unknown;
  UserReadinessLog_set_input: unknown;
  UserReadinessLog_stream_cursor_input: unknown;
  UserReadinessLog_stream_cursor_value_input: unknown;
  UserReadinessLog_updates: unknown;
  UserReadiness_aggregate: unknown;
  UserReadiness_aggregate_bool_exp: unknown;
  UserReadiness_aggregate_bool_exp_avg: unknown;
  UserReadiness_aggregate_bool_exp_bool_and: unknown;
  UserReadiness_aggregate_bool_exp_bool_or: unknown;
  UserReadiness_aggregate_bool_exp_corr: unknown;
  UserReadiness_aggregate_bool_exp_corr_arguments: unknown;
  UserReadiness_aggregate_bool_exp_count: unknown;
  UserReadiness_aggregate_bool_exp_covar_samp: unknown;
  UserReadiness_aggregate_bool_exp_covar_samp_arguments: unknown;
  UserReadiness_aggregate_bool_exp_max: unknown;
  UserReadiness_aggregate_bool_exp_min: unknown;
  UserReadiness_aggregate_bool_exp_stddev_samp: unknown;
  UserReadiness_aggregate_bool_exp_sum: unknown;
  UserReadiness_aggregate_bool_exp_var_samp: unknown;
  UserReadiness_aggregate_fields: unknown;
  UserReadiness_aggregate_order_by: unknown;
  UserReadiness_arr_rel_insert_input: unknown;
  UserReadiness_avg_fields: unknown;
  UserReadiness_avg_order_by: unknown;
  UserReadiness_bool_exp: unknown;
  UserReadiness_inc_input: unknown;
  UserReadiness_insert_input: unknown;
  UserReadiness_max_fields: unknown;
  UserReadiness_max_order_by: unknown;
  UserReadiness_min_fields: unknown;
  UserReadiness_min_order_by: unknown;
  UserReadiness_mutation_response: unknown;
  UserReadiness_on_conflict: unknown;
  UserReadiness_order_by: unknown;
  UserReadiness_pk_columns_input: unknown;
  UserReadiness_set_input: unknown;
  UserReadiness_stddev_fields: unknown;
  UserReadiness_stddev_order_by: unknown;
  UserReadiness_stddev_pop_fields: unknown;
  UserReadiness_stddev_pop_order_by: unknown;
  UserReadiness_stddev_samp_fields: unknown;
  UserReadiness_stddev_samp_order_by: unknown;
  UserReadiness_stream_cursor_input: unknown;
  UserReadiness_stream_cursor_value_input: unknown;
  UserReadiness_sum_fields: unknown;
  UserReadiness_sum_order_by: unknown;
  UserReadiness_updates: unknown;
  UserReadiness_var_pop_fields: unknown;
  UserReadiness_var_pop_order_by: unknown;
  UserReadiness_var_samp_fields: unknown;
  UserReadiness_var_samp_order_by: unknown;
  UserReadiness_variance_fields: unknown;
  UserReadiness_variance_order_by: unknown;
  UserReimbursmentHistory: unknown;
  UserReimbursmentHistory_aggregate: unknown;
  UserReimbursmentHistory_aggregate_bool_exp: unknown;
  UserReimbursmentHistory_aggregate_bool_exp_bool_and: unknown;
  UserReimbursmentHistory_aggregate_bool_exp_bool_or: unknown;
  UserReimbursmentHistory_aggregate_bool_exp_count: unknown;
  UserReimbursmentHistory_aggregate_fields: unknown;
  UserReimbursmentHistory_aggregate_order_by: unknown;
  UserReimbursmentHistory_arr_rel_insert_input: unknown;
  UserReimbursmentHistory_bool_exp: unknown;
  UserReimbursmentHistory_insert_input: unknown;
  UserReimbursmentHistory_max_fields: unknown;
  UserReimbursmentHistory_max_order_by: unknown;
  UserReimbursmentHistory_min_fields: unknown;
  UserReimbursmentHistory_min_order_by: unknown;
  UserReimbursmentHistory_mutation_response: unknown;
  UserReimbursmentHistory_on_conflict: unknown;
  UserReimbursmentHistory_order_by: unknown;
  UserReimbursmentHistory_pk_columns_input: unknown;
  UserReimbursmentHistory_set_input: unknown;
  UserReimbursmentHistory_stream_cursor_input: unknown;
  UserReimbursmentHistory_stream_cursor_value_input: unknown;
  UserReimbursmentHistory_updates: unknown;
  UserRep: unknown;
  UserRep_aggregate: unknown;
  UserRep_aggregate_bool_exp: unknown;
  UserRep_aggregate_bool_exp_count: unknown;
  UserRep_aggregate_fields: unknown;
  UserRep_aggregate_order_by: unknown;
  UserRep_append_input: unknown;
  UserRep_arr_rel_insert_input: unknown;
  UserRep_avg_fields: unknown;
  UserRep_avg_order_by: unknown;
  UserRep_bool_exp: unknown;
  UserRep_delete_at_path_input: unknown;
  UserRep_delete_elem_input: unknown;
  UserRep_delete_key_input: unknown;
  UserRep_inc_input: unknown;
  UserRep_insert_input: unknown;
  UserRep_max_fields: unknown;
  UserRep_max_order_by: unknown;
  UserRep_min_fields: unknown;
  UserRep_min_order_by: unknown;
  UserRep_mutation_response: unknown;
  UserRep_on_conflict: unknown;
  UserRep_order_by: unknown;
  UserRep_pk_columns_input: unknown;
  UserRep_prepend_input: unknown;
  UserRep_set_input: unknown;
  UserRep_stddev_fields: unknown;
  UserRep_stddev_order_by: unknown;
  UserRep_stddev_pop_fields: unknown;
  UserRep_stddev_pop_order_by: unknown;
  UserRep_stddev_samp_fields: unknown;
  UserRep_stddev_samp_order_by: unknown;
  UserRep_stream_cursor_input: unknown;
  UserRep_stream_cursor_value_input: unknown;
  UserRep_sum_fields: unknown;
  UserRep_sum_order_by: unknown;
  UserRep_updates: unknown;
  UserRep_var_pop_fields: unknown;
  UserRep_var_pop_order_by: unknown;
  UserRep_var_samp_fields: unknown;
  UserRep_var_samp_order_by: unknown;
  UserRep_variance_fields: unknown;
  UserRep_variance_order_by: unknown;
  UserSet: unknown;
  UserSet_aggregate: unknown;
  UserSet_aggregate_bool_exp: unknown;
  UserSet_aggregate_bool_exp_count: unknown;
  UserSet_aggregate_fields: unknown;
  UserSet_aggregate_order_by: unknown;
  UserSet_append_input: unknown;
  UserSet_arr_rel_insert_input: unknown;
  UserSet_avg_fields: unknown;
  UserSet_avg_order_by: unknown;
  UserSet_bool_exp: unknown;
  UserSet_delete_at_path_input: unknown;
  UserSet_delete_elem_input: unknown;
  UserSet_delete_key_input: unknown;
  UserSet_inc_input: unknown;
  UserSet_insert_input: unknown;
  UserSet_max_fields: unknown;
  UserSet_max_order_by: unknown;
  UserSet_min_fields: unknown;
  UserSet_min_order_by: unknown;
  UserSet_mutation_response: unknown;
  UserSet_obj_rel_insert_input: unknown;
  UserSet_on_conflict: unknown;
  UserSet_order_by: unknown;
  UserSet_pk_columns_input: unknown;
  UserSet_prepend_input: unknown;
  UserSet_set_input: unknown;
  UserSet_stddev_fields: unknown;
  UserSet_stddev_order_by: unknown;
  UserSet_stddev_pop_fields: unknown;
  UserSet_stddev_pop_order_by: unknown;
  UserSet_stddev_samp_fields: unknown;
  UserSet_stddev_samp_order_by: unknown;
  UserSet_stream_cursor_input: unknown;
  UserSet_stream_cursor_value_input: unknown;
  UserSet_sum_fields: unknown;
  UserSet_sum_order_by: unknown;
  UserSet_updates: unknown;
  UserSet_var_pop_fields: unknown;
  UserSet_var_pop_order_by: unknown;
  UserSet_var_samp_fields: unknown;
  UserSet_var_samp_order_by: unknown;
  UserSet_variance_fields: unknown;
  UserSet_variance_order_by: unknown;
  UserState: unknown;
  UserStateOutput: unknown;
  UserState_aggregate: unknown;
  UserState_aggregate_bool_exp: unknown;
  UserState_aggregate_bool_exp_bool_and: unknown;
  UserState_aggregate_bool_exp_bool_or: unknown;
  UserState_aggregate_bool_exp_count: unknown;
  UserState_aggregate_fields: unknown;
  UserState_aggregate_order_by: unknown;
  UserState_arr_rel_insert_input: unknown;
  UserState_bool_exp: unknown;
  UserState_insert_input: unknown;
  UserState_max_fields: unknown;
  UserState_max_order_by: unknown;
  UserState_min_fields: unknown;
  UserState_min_order_by: unknown;
  UserState_mutation_response: unknown;
  UserState_on_conflict: unknown;
  UserState_order_by: unknown;
  UserState_pk_columns_input: unknown;
  UserState_set_input: unknown;
  UserState_stream_cursor_input: unknown;
  UserState_stream_cursor_value_input: unknown;
  UserState_updates: unknown;
  UserTrainingPlan: unknown;
  UserTrainingPlan_aggregate: unknown;
  UserTrainingPlan_aggregate_fields: unknown;
  UserTrainingPlan_avg_fields: unknown;
  UserTrainingPlan_bool_exp: unknown;
  UserTrainingPlan_inc_input: unknown;
  UserTrainingPlan_insert_input: unknown;
  UserTrainingPlan_max_fields: unknown;
  UserTrainingPlan_min_fields: unknown;
  UserTrainingPlan_mutation_response: unknown;
  UserTrainingPlan_on_conflict: unknown;
  UserTrainingPlan_order_by: unknown;
  UserTrainingPlan_pk_columns_input: unknown;
  UserTrainingPlan_set_input: unknown;
  UserTrainingPlan_stddev_fields: unknown;
  UserTrainingPlan_stddev_pop_fields: unknown;
  UserTrainingPlan_stddev_samp_fields: unknown;
  UserTrainingPlan_stream_cursor_input: unknown;
  UserTrainingPlan_stream_cursor_value_input: unknown;
  UserTrainingPlan_sum_fields: unknown;
  UserTrainingPlan_updates: unknown;
  UserTrainingPlan_var_pop_fields: unknown;
  UserTrainingPlan_var_samp_fields: unknown;
  UserTrainingPlan_variance_fields: unknown;
  UserWorkout: unknown;
  UserWorkout_aggregate: unknown;
  UserWorkout_aggregate_bool_exp: unknown;
  UserWorkout_aggregate_bool_exp_avg: unknown;
  UserWorkout_aggregate_bool_exp_bool_and: unknown;
  UserWorkout_aggregate_bool_exp_bool_or: unknown;
  UserWorkout_aggregate_bool_exp_corr: unknown;
  UserWorkout_aggregate_bool_exp_corr_arguments: unknown;
  UserWorkout_aggregate_bool_exp_count: unknown;
  UserWorkout_aggregate_bool_exp_covar_samp: unknown;
  UserWorkout_aggregate_bool_exp_covar_samp_arguments: unknown;
  UserWorkout_aggregate_bool_exp_max: unknown;
  UserWorkout_aggregate_bool_exp_min: unknown;
  UserWorkout_aggregate_bool_exp_stddev_samp: unknown;
  UserWorkout_aggregate_bool_exp_sum: unknown;
  UserWorkout_aggregate_bool_exp_var_samp: unknown;
  UserWorkout_aggregate_fields: unknown;
  UserWorkout_aggregate_order_by: unknown;
  UserWorkout_append_input: unknown;
  UserWorkout_arr_rel_insert_input: unknown;
  UserWorkout_avg_fields: unknown;
  UserWorkout_avg_order_by: unknown;
  UserWorkout_bool_exp: unknown;
  UserWorkout_delete_at_path_input: unknown;
  UserWorkout_delete_elem_input: unknown;
  UserWorkout_delete_key_input: unknown;
  UserWorkout_inc_input: unknown;
  UserWorkout_insert_input: unknown;
  UserWorkout_max_fields: unknown;
  UserWorkout_max_order_by: unknown;
  UserWorkout_min_fields: unknown;
  UserWorkout_min_order_by: unknown;
  UserWorkout_mutation_response: unknown;
  UserWorkout_obj_rel_insert_input: unknown;
  UserWorkout_on_conflict: unknown;
  UserWorkout_order_by: unknown;
  UserWorkout_pk_columns_input: unknown;
  UserWorkout_prepend_input: unknown;
  UserWorkout_set_input: unknown;
  UserWorkout_stddev_fields: unknown;
  UserWorkout_stddev_order_by: unknown;
  UserWorkout_stddev_pop_fields: unknown;
  UserWorkout_stddev_pop_order_by: unknown;
  UserWorkout_stddev_samp_fields: unknown;
  UserWorkout_stddev_samp_order_by: unknown;
  UserWorkout_stream_cursor_input: unknown;
  UserWorkout_stream_cursor_value_input: unknown;
  UserWorkout_sum_fields: unknown;
  UserWorkout_sum_order_by: unknown;
  UserWorkout_updates: unknown;
  UserWorkout_var_pop_fields: unknown;
  UserWorkout_var_pop_order_by: unknown;
  UserWorkout_var_samp_fields: unknown;
  UserWorkout_var_samp_order_by: unknown;
  UserWorkout_variance_fields: unknown;
  UserWorkout_variance_order_by: unknown;
  User_aggregate: unknown;
  User_aggregate_bool_exp: unknown;
  User_aggregate_bool_exp_avg: unknown;
  User_aggregate_bool_exp_bool_and: unknown;
  User_aggregate_bool_exp_bool_or: unknown;
  User_aggregate_bool_exp_corr: unknown;
  User_aggregate_bool_exp_corr_arguments: unknown;
  User_aggregate_bool_exp_count: unknown;
  User_aggregate_bool_exp_covar_samp: unknown;
  User_aggregate_bool_exp_covar_samp_arguments: unknown;
  User_aggregate_bool_exp_max: unknown;
  User_aggregate_bool_exp_min: unknown;
  User_aggregate_bool_exp_stddev_samp: unknown;
  User_aggregate_bool_exp_sum: unknown;
  User_aggregate_bool_exp_var_samp: unknown;
  User_aggregate_fields: unknown;
  User_aggregate_order_by: unknown;
  User_arr_rel_insert_input: unknown;
  User_avg_fields: unknown;
  User_avg_order_by: unknown;
  User_bool_exp: unknown;
  User_inc_input: unknown;
  User_insert_input: unknown;
  User_max_fields: unknown;
  User_max_order_by: unknown;
  User_min_fields: unknown;
  User_min_order_by: unknown;
  User_mutation_response: unknown;
  User_obj_rel_insert_input: unknown;
  User_on_conflict: unknown;
  User_order_by: unknown;
  User_pk_columns_input: unknown;
  User_set_input: unknown;
  User_stddev_fields: unknown;
  User_stddev_order_by: unknown;
  User_stddev_pop_fields: unknown;
  User_stddev_pop_order_by: unknown;
  User_stddev_samp_fields: unknown;
  User_stddev_samp_order_by: unknown;
  User_stream_cursor_input: unknown;
  User_stream_cursor_value_input: unknown;
  User_sum_fields: unknown;
  User_sum_order_by: unknown;
  User_updates: unknown;
  User_var_pop_fields: unknown;
  User_var_pop_order_by: unknown;
  User_var_samp_fields: unknown;
  User_var_samp_order_by: unknown;
  User_variance_fields: unknown;
  User_variance_order_by: unknown;
  ValLabels: unknown;
  ValueAndTime: unknown;
  ValueTimeAndAverage: unknown;
  ValueTimeAndMinimum: unknown;
  VendorErrorLog: unknown;
  VendorErrorLog_aggregate: unknown;
  VendorErrorLog_aggregate_fields: unknown;
  VendorErrorLog_append_input: unknown;
  VendorErrorLog_bool_exp: unknown;
  VendorErrorLog_delete_at_path_input: unknown;
  VendorErrorLog_delete_elem_input: unknown;
  VendorErrorLog_delete_key_input: unknown;
  VendorErrorLog_insert_input: unknown;
  VendorErrorLog_max_fields: unknown;
  VendorErrorLog_min_fields: unknown;
  VendorErrorLog_mutation_response: unknown;
  VendorErrorLog_on_conflict: unknown;
  VendorErrorLog_order_by: unknown;
  VendorErrorLog_pk_columns_input: unknown;
  VendorErrorLog_prepend_input: unknown;
  VendorErrorLog_set_input: unknown;
  VendorErrorLog_stream_cursor_input: unknown;
  VendorErrorLog_stream_cursor_value_input: unknown;
  VendorErrorLog_updates: unknown;
  VendorType: unknown;
  VendorType_comparison_exp: unknown;
  VersionControl: unknown;
  VersionControl_aggregate: unknown;
  VersionControl_aggregate_fields: unknown;
  VersionControl_avg_fields: unknown;
  VersionControl_bool_exp: unknown;
  VersionControl_inc_input: unknown;
  VersionControl_insert_input: unknown;
  VersionControl_max_fields: unknown;
  VersionControl_min_fields: unknown;
  VersionControl_mutation_response: unknown;
  VersionControl_on_conflict: unknown;
  VersionControl_order_by: unknown;
  VersionControl_pk_columns_input: unknown;
  VersionControl_set_input: unknown;
  VersionControl_stddev_fields: unknown;
  VersionControl_stddev_pop_fields: unknown;
  VersionControl_stddev_samp_fields: unknown;
  VersionControl_stream_cursor_input: unknown;
  VersionControl_stream_cursor_value_input: unknown;
  VersionControl_sum_fields: unknown;
  VersionControl_updates: unknown;
  VersionControl_var_pop_fields: unknown;
  VersionControl_var_samp_fields: unknown;
  VersionControl_variance_fields: unknown;
  WeeklyPlan: unknown;
  WeeklyPlan_aggregate: unknown;
  WeeklyPlan_aggregate_bool_exp: unknown;
  WeeklyPlan_aggregate_bool_exp_avg: unknown;
  WeeklyPlan_aggregate_bool_exp_corr: unknown;
  WeeklyPlan_aggregate_bool_exp_corr_arguments: unknown;
  WeeklyPlan_aggregate_bool_exp_count: unknown;
  WeeklyPlan_aggregate_bool_exp_covar_samp: unknown;
  WeeklyPlan_aggregate_bool_exp_covar_samp_arguments: unknown;
  WeeklyPlan_aggregate_bool_exp_max: unknown;
  WeeklyPlan_aggregate_bool_exp_min: unknown;
  WeeklyPlan_aggregate_bool_exp_stddev_samp: unknown;
  WeeklyPlan_aggregate_bool_exp_sum: unknown;
  WeeklyPlan_aggregate_bool_exp_var_samp: unknown;
  WeeklyPlan_aggregate_fields: unknown;
  WeeklyPlan_aggregate_order_by: unknown;
  WeeklyPlan_append_input: unknown;
  WeeklyPlan_arr_rel_insert_input: unknown;
  WeeklyPlan_avg_fields: unknown;
  WeeklyPlan_avg_order_by: unknown;
  WeeklyPlan_bool_exp: unknown;
  WeeklyPlan_delete_at_path_input: unknown;
  WeeklyPlan_delete_elem_input: unknown;
  WeeklyPlan_delete_key_input: unknown;
  WeeklyPlan_inc_input: unknown;
  WeeklyPlan_insert_input: unknown;
  WeeklyPlan_max_fields: unknown;
  WeeklyPlan_max_order_by: unknown;
  WeeklyPlan_min_fields: unknown;
  WeeklyPlan_min_order_by: unknown;
  WeeklyPlan_mutation_response: unknown;
  WeeklyPlan_obj_rel_insert_input: unknown;
  WeeklyPlan_on_conflict: unknown;
  WeeklyPlan_order_by: unknown;
  WeeklyPlan_pk_columns_input: unknown;
  WeeklyPlan_prepend_input: unknown;
  WeeklyPlan_set_input: unknown;
  WeeklyPlan_stddev_fields: unknown;
  WeeklyPlan_stddev_order_by: unknown;
  WeeklyPlan_stddev_pop_fields: unknown;
  WeeklyPlan_stddev_pop_order_by: unknown;
  WeeklyPlan_stddev_samp_fields: unknown;
  WeeklyPlan_stddev_samp_order_by: unknown;
  WeeklyPlan_stream_cursor_input: unknown;
  WeeklyPlan_stream_cursor_value_input: unknown;
  WeeklyPlan_sum_fields: unknown;
  WeeklyPlan_sum_order_by: unknown;
  WeeklyPlan_updates: unknown;
  WeeklyPlan_var_pop_fields: unknown;
  WeeklyPlan_var_pop_order_by: unknown;
  WeeklyPlan_var_samp_fields: unknown;
  WeeklyPlan_var_samp_order_by: unknown;
  WeeklyPlan_variance_fields: unknown;
  WeeklyPlan_variance_order_by: unknown;
  WorkIOWithEpoch: unknown;
  WorkinPrefrence: unknown;
  WorkinPrefrenceInput: unknown;
  WorkinPrefrenceOutput: unknown;
  WorkinPrefrence_aggregate: unknown;
  WorkinPrefrence_aggregate_bool_exp: unknown;
  WorkinPrefrence_aggregate_bool_exp_bool_and: unknown;
  WorkinPrefrence_aggregate_bool_exp_bool_or: unknown;
  WorkinPrefrence_aggregate_bool_exp_count: unknown;
  WorkinPrefrence_aggregate_fields: unknown;
  WorkinPrefrence_aggregate_order_by: unknown;
  WorkinPrefrence_arr_rel_insert_input: unknown;
  WorkinPrefrence_bool_exp: unknown;
  WorkinPrefrence_insert_input: unknown;
  WorkinPrefrence_max_fields: unknown;
  WorkinPrefrence_max_order_by: unknown;
  WorkinPrefrence_min_fields: unknown;
  WorkinPrefrence_min_order_by: unknown;
  WorkinPrefrence_mutation_response: unknown;
  WorkinPrefrence_on_conflict: unknown;
  WorkinPrefrence_order_by: unknown;
  WorkinPrefrence_pk_columns_input: unknown;
  WorkinPrefrence_set_input: unknown;
  WorkinPrefrence_stream_cursor_input: unknown;
  WorkinPrefrence_stream_cursor_value_input: unknown;
  WorkinPrefrence_updates: unknown;
  Workout: unknown;
  WorkoutCategory: unknown;
  WorkoutCategory_comparison_exp: unknown;
  WorkoutPrefrence: unknown;
  WorkoutPrefrenceInput: unknown;
  WorkoutPrefrenceOutput: unknown;
  WorkoutPrefrence_aggregate: unknown;
  WorkoutPrefrence_aggregate_bool_exp: unknown;
  WorkoutPrefrence_aggregate_bool_exp_bool_and: unknown;
  WorkoutPrefrence_aggregate_bool_exp_bool_or: unknown;
  WorkoutPrefrence_aggregate_bool_exp_count: unknown;
  WorkoutPrefrence_aggregate_fields: unknown;
  WorkoutPrefrence_aggregate_order_by: unknown;
  WorkoutPrefrence_arr_rel_insert_input: unknown;
  WorkoutPrefrence_bool_exp: unknown;
  WorkoutPrefrence_insert_input: unknown;
  WorkoutPrefrence_max_fields: unknown;
  WorkoutPrefrence_max_order_by: unknown;
  WorkoutPrefrence_min_fields: unknown;
  WorkoutPrefrence_min_order_by: unknown;
  WorkoutPrefrence_mutation_response: unknown;
  WorkoutPrefrence_on_conflict: unknown;
  WorkoutPrefrence_order_by: unknown;
  WorkoutPrefrence_pk_columns_input: unknown;
  WorkoutPrefrence_set_input: unknown;
  WorkoutPrefrence_stream_cursor_input: unknown;
  WorkoutPrefrence_stream_cursor_value_input: unknown;
  WorkoutPrefrence_updates: unknown;
  WorkoutType: unknown;
  WorkoutType_comparison_exp: unknown;
  Workout_aggregate: unknown;
  Workout_aggregate_bool_exp: unknown;
  Workout_aggregate_bool_exp_bool_and: unknown;
  Workout_aggregate_bool_exp_bool_or: unknown;
  Workout_aggregate_bool_exp_count: unknown;
  Workout_aggregate_fields: unknown;
  Workout_aggregate_order_by: unknown;
  Workout_append_input: unknown;
  Workout_arr_rel_insert_input: unknown;
  Workout_avg_fields: unknown;
  Workout_avg_order_by: unknown;
  Workout_bool_exp: unknown;
  Workout_delete_at_path_input: unknown;
  Workout_delete_elem_input: unknown;
  Workout_delete_key_input: unknown;
  Workout_inc_input: unknown;
  Workout_insert_input: unknown;
  Workout_max_fields: unknown;
  Workout_max_order_by: unknown;
  Workout_min_fields: unknown;
  Workout_min_order_by: unknown;
  Workout_mutation_response: unknown;
  Workout_obj_rel_insert_input: unknown;
  Workout_on_conflict: unknown;
  Workout_order_by: unknown;
  Workout_pk_columns_input: unknown;
  Workout_prepend_input: unknown;
  Workout_set_input: unknown;
  Workout_stddev_fields: unknown;
  Workout_stddev_order_by: unknown;
  Workout_stddev_pop_fields: unknown;
  Workout_stddev_pop_order_by: unknown;
  Workout_stddev_samp_fields: unknown;
  Workout_stddev_samp_order_by: unknown;
  Workout_stream_cursor_input: unknown;
  Workout_stream_cursor_value_input: unknown;
  Workout_sum_fields: unknown;
  Workout_sum_order_by: unknown;
  Workout_updates: unknown;
  Workout_var_pop_fields: unknown;
  Workout_var_pop_order_by: unknown;
  Workout_var_samp_fields: unknown;
  Workout_var_samp_order_by: unknown;
  Workout_variance_fields: unknown;
  Workout_variance_order_by: unknown;
  _FollowingPro: unknown;
  _FollowingPro_aggregate: unknown;
  _FollowingPro_aggregate_bool_exp: unknown;
  _FollowingPro_aggregate_bool_exp_count: unknown;
  _FollowingPro_aggregate_fields: unknown;
  _FollowingPro_aggregate_order_by: unknown;
  _FollowingPro_arr_rel_insert_input: unknown;
  _FollowingPro_bool_exp: unknown;
  _FollowingPro_insert_input: unknown;
  _FollowingPro_max_fields: unknown;
  _FollowingPro_max_order_by: unknown;
  _FollowingPro_min_fields: unknown;
  _FollowingPro_min_order_by: unknown;
  _FollowingPro_mutation_response: unknown;
  _FollowingPro_on_conflict: unknown;
  _FollowingPro_order_by: unknown;
  _FollowingPro_set_input: unknown;
  _FollowingPro_stream_cursor_input: unknown;
  _FollowingPro_stream_cursor_value_input: unknown;
  _FollowingPro_updates: unknown;
  _GoalToUserPreferences: unknown;
  _GoalToUserPreferences_aggregate: unknown;
  _GoalToUserPreferences_aggregate_bool_exp: unknown;
  _GoalToUserPreferences_aggregate_bool_exp_count: unknown;
  _GoalToUserPreferences_aggregate_fields: unknown;
  _GoalToUserPreferences_aggregate_order_by: unknown;
  _GoalToUserPreferences_arr_rel_insert_input: unknown;
  _GoalToUserPreferences_bool_exp: unknown;
  _GoalToUserPreferences_insert_input: unknown;
  _GoalToUserPreferences_max_fields: unknown;
  _GoalToUserPreferences_max_order_by: unknown;
  _GoalToUserPreferences_min_fields: unknown;
  _GoalToUserPreferences_min_order_by: unknown;
  _GoalToUserPreferences_mutation_response: unknown;
  _GoalToUserPreferences_on_conflict: unknown;
  _GoalToUserPreferences_order_by: unknown;
  _GoalToUserPreferences_set_input: unknown;
  _GoalToUserPreferences_stream_cursor_input: unknown;
  _GoalToUserPreferences_stream_cursor_value_input: unknown;
  _GoalToUserPreferences_updates: unknown;
  bigint: unknown;
  bigint_comparison_exp: unknown;
  bioStrapAssignment: unknown;
  createManyOutput: unknown;
  date: unknown;
  date_comparison_exp: unknown;
  diagnosticData: unknown;
  fetchActivityAverages: unknown;
  fetchActivityResult: unknown;
  fetchActivityTotals: unknown;
  fetchFeatureFlagsOutput: unknown;
  fetchHabitsOutput: unknown;
  fetchPlansOutput: unknown;
  fileInput: unknown;
  float8: unknown;
  float8_comparison_exp: unknown;
  generateSignedUrlResponse: unknown;
  genericListOutput: unknown;
  getSFCoachIdOutput: unknown;
  getUserByAuth0IdOutput: unknown;
  idObj: unknown;
  json: unknown;
  jsonb: unknown;
  jsonb_array_comparison_exp: unknown;
  jsonb_cast_exp: unknown;
  jsonb_comparison_exp: unknown;
  messageOutput: unknown;
  mobileVersionsOutput: unknown;
  mutation_root: {};
  planHistoryOutput: unknown;
  planHistoryTotals: unknown;
  planHistoryWeek: unknown;
  qualifyWorkoutOutput: unknown;
  query_root: {};
  recommendWorkoutByOptionOutput: unknown;
  sleepAverage: unknown;
  sleepInsightsDetailData: unknown;
  sleepV1: unknown;
  startWorkoutOutput: unknown;
  subscription_root: {};
  time: unknown;
  time_comparison_exp: unknown;
  timestamp: unknown;
  timestamp_comparison_exp: unknown;
  updateReimbursementTokensResponse: unknown;
  updateUser: unknown;
  userNutritionReportOutput: unknown;
}>;

export type CachedDirectiveArgs = {
  refresh?: Scalars['Boolean']['input'];
  ttl?: Scalars['Int']['input'];
};

export type CachedDirectiveResolver<Result, Parent, ContextType = any, Args = CachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActionResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionResultOutput'] = ResolversParentTypes['ActionResultOutput']> = ResolversObject<{
  action?: Resolver<Maybe<ResolversTypes['CrudActionEnum']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivationCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivationCode'] = ResolversParentTypes['ActivationCode']> = ResolversObject<{
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivationCode_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivationCode_aggregate'] = ResolversParentTypes['ActivationCode_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['ActivationCode_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ActivationCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivationCode_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivationCode_aggregate_fields'] = ResolversParentTypes['ActivationCode_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ActivationCode_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ActivationCode_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ActivationCode_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivationCode_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivationCode_max_fields'] = ResolversParentTypes['ActivationCode_max_fields']> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivationCode_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivationCode_min_fields'] = ResolversParentTypes['ActivationCode_min_fields']> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivationCode_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivationCode_mutation_response'] = ResolversParentTypes['ActivationCode_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ActivationCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivityInsightsDetailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityInsightsDetailOutput'] = ResolversParentTypes['ActivityInsightsDetailOutput']> = ResolversObject<{
  active_time?: Resolver<Maybe<ResolversTypes['ValueAndTime']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['CaloricOutput']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['ValueAndTime']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['ValueAndTime']>, ParentType, ContextType>;
  stepsV2?: Resolver<Maybe<ResolversTypes['StepsV2Output']>, ParentType, ContextType>;
  time_moving?: Resolver<Maybe<ResolversTypes['TimeMovingOutput']>, ParentType, ContextType>;
  time_working_out?: Resolver<Maybe<ResolversTypes['TimeWorkingOutOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivityInsightsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityInsightsOutput'] = ResolversParentTypes['ActivityInsightsOutput']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivityProgressOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityProgressOutput'] = ResolversParentTypes['ActivityProgressOutput']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AggregatesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatesOutput'] = ResolversParentTypes['AggregatesOutput']> = ResolversObject<{
  User?: Resolver<Maybe<ResolversTypes['AllUsersOutput']>, ParentType, ContextType>;
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllUsersOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllUsersOutput'] = ResolversParentTypes['AllUsersOutput']> = ResolversObject<{
  DNAResults?: Resolver<Maybe<ResolversTypes['DNAResultsOutput']>, ParentType, ContextType>;
  UserPreferences?: Resolver<Maybe<ResolversTypes['UserPreferencesOutput']>, ParentType, ContextType>;
  activityLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  biostrapId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_onboarded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mostImportantFitnessGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearSessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearTokenExpiration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferMetric?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strapColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlterTypeColorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlterTypeColor'] = ResolversParentTypes['AlterTypeColor']> = ResolversObject<{
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hex?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  letter?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlterTypeColor_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlterTypeColor_aggregate'] = ResolversParentTypes['AlterTypeColor_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['AlterTypeColor_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['AlterTypeColor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlterTypeColor_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlterTypeColor_aggregate_fields'] = ResolversParentTypes['AlterTypeColor_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AlterTypeColor_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['AlterTypeColor_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['AlterTypeColor_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlterTypeColor_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlterTypeColor_max_fields'] = ResolversParentTypes['AlterTypeColor_max_fields']> = ResolversObject<{
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  letter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlterTypeColor_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlterTypeColor_min_fields'] = ResolversParentTypes['AlterTypeColor_min_fields']> = ResolversObject<{
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  letter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlterTypeColor_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlterTypeColor_mutation_response'] = ResolversParentTypes['AlterTypeColor_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['AlterTypeColor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface AssessmentTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AssessmentType'], any> {
  name: 'AssessmentType';
}

export interface CruDactionScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CRUDaction'], any> {
  name: 'CRUDaction';
}

export interface CadenceTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CadenceType'], any> {
  name: 'CadenceType';
}

export type CalculateMacroGramsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalculateMacroGramsOutput'] = ResolversParentTypes['CalculateMacroGramsOutput']> = ResolversObject<{
  carbs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fats?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  proteins?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CaloricOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaloricOutput'] = ResolversParentTypes['CaloricOutput']> = ResolversObject<{
  _active_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _overall_bmr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _overall_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChangeCoachedPlanOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeCoachedPlanOutput'] = ResolversParentTypes['ChangeCoachedPlanOutput']> = ResolversObject<{
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomPlanDay']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  plan_type?: Resolver<Maybe<ResolversTypes['PlanTypeEnum']>, ParentType, ContextType>;
  preset_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheckDnaKitOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckDNAKitOutput'] = ResolversParentTypes['CheckDNAKitOutput']> = ResolversObject<{
  status?: Resolver<Maybe<ResolversTypes['DNAKitResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheckNutritionReportReleasedOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckNutritionReportReleasedOutput'] = ResolversParentTypes['CheckNutritionReportReleasedOutput']> = ResolversObject<{
  nutrition_report_released?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheckPresetPlanStructureOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckPresetPlanStructureOutput'] = ResolversParentTypes['CheckPresetPlanStructureOutput']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_plan_structure_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  identities?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coach'] = ResolversParentTypes['Coach']> = ResolversObject<{
  CoachedNutritions?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, Partial<CoachCoachedNutritionsArgs>>;
  CoachedNutritions_aggregate?: Resolver<ResolversTypes['CoachedNutrition_aggregate'], ParentType, ContextType, Partial<CoachCoachedNutritions_AggregateArgs>>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  Users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<CoachUsersArgs>>;
  Users_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<CoachUsers_AggregateArgs>>;
  calendly_link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendly_link_thirty_min?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coach_user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contentful_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendbird_access_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_coach_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Coach_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coach_aggregate'] = ResolversParentTypes['Coach_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Coach_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Coach']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Coach_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coach_aggregate_fields'] = ResolversParentTypes['Coach_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Coach_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Coach_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Coach_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Coach_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coach_max_fields'] = ResolversParentTypes['Coach_max_fields']> = ResolversObject<{
  calendly_link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendly_link_thirty_min?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coach_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentful_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendbird_access_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Coach_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coach_min_fields'] = ResolversParentTypes['Coach_min_fields']> = ResolversObject<{
  calendly_link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendly_link_thirty_min?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coach_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentful_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendbird_access_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Coach_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coach_mutation_response'] = ResolversParentTypes['Coach_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Coach']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit'] = ResolversParentTypes['CoachedHabit']> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<ResolversTypes['HabitType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<ResolversTypes['HabitSubType'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_aggregate'] = ResolversParentTypes['CoachedHabit_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['CoachedHabit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CoachedHabit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_aggregate_fields'] = ResolversParentTypes['CoachedHabit_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['CoachedHabit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CoachedHabit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CoachedHabit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoachedHabit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CoachedHabit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CoachedHabit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CoachedHabit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CoachedHabit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CoachedHabit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CoachedHabit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CoachedHabit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_avg_fields'] = ResolversParentTypes['CoachedHabit_avg_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_max_fields'] = ResolversParentTypes['CoachedHabit_max_fields']> = ResolversObject<{
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_min_fields'] = ResolversParentTypes['CoachedHabit_min_fields']> = ResolversObject<{
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_mutation_response'] = ResolversParentTypes['CoachedHabit_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CoachedHabit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_stddev_fields'] = ResolversParentTypes['CoachedHabit_stddev_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_stddev_pop_fields'] = ResolversParentTypes['CoachedHabit_stddev_pop_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_stddev_samp_fields'] = ResolversParentTypes['CoachedHabit_stddev_samp_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_sum_fields'] = ResolversParentTypes['CoachedHabit_sum_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_var_pop_fields'] = ResolversParentTypes['CoachedHabit_var_pop_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_var_samp_fields'] = ResolversParentTypes['CoachedHabit_var_samp_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedHabit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedHabit_variance_fields'] = ResolversParentTypes['CoachedHabit_variance_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutritionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition'] = ResolversParentTypes['CoachedNutrition']> = ResolversObject<{
  Coach?: Resolver<Maybe<ResolversTypes['Coach']>, ParentType, ContextType>;
  Goal?: Resolver<Maybe<ResolversTypes['Goal']>, ParentType, ContextType>;
  PresetDiet?: Resolver<Maybe<ResolversTypes['PresetDiet']>, ParentType, ContextType>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  custom_carbs?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  custom_fats?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  custom_proteins?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nutrition_goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preset_diet_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_aggregate'] = ResolversParentTypes['CoachedNutrition_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['CoachedNutrition_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_aggregate_fields'] = ResolversParentTypes['CoachedNutrition_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['CoachedNutrition_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CoachedNutrition_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CoachedNutrition_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoachedNutrition_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CoachedNutrition_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CoachedNutrition_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CoachedNutrition_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CoachedNutrition_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CoachedNutrition_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CoachedNutrition_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CoachedNutrition_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_avg_fields'] = ResolversParentTypes['CoachedNutrition_avg_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_max_fields'] = ResolversParentTypes['CoachedNutrition_max_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_carbs?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  custom_fats?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  custom_proteins?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrition_goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preset_diet_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_min_fields'] = ResolversParentTypes['CoachedNutrition_min_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_carbs?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  custom_fats?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  custom_proteins?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrition_goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preset_diet_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_mutation_response'] = ResolversParentTypes['CoachedNutrition_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_stddev_fields'] = ResolversParentTypes['CoachedNutrition_stddev_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_stddev_pop_fields'] = ResolversParentTypes['CoachedNutrition_stddev_pop_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_stddev_samp_fields'] = ResolversParentTypes['CoachedNutrition_stddev_samp_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_sum_fields'] = ResolversParentTypes['CoachedNutrition_sum_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_var_pop_fields'] = ResolversParentTypes['CoachedNutrition_var_pop_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_var_samp_fields'] = ResolversParentTypes['CoachedNutrition_var_samp_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoachedNutrition_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoachedNutrition_variance_fields'] = ResolversParentTypes['CoachedNutrition_variance_fields']> = ResolversObject<{
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CookBookItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CookBookItem'] = ResolversParentTypes['CookBookItem']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_fasting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_snack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meal_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recipe?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreditHistoryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditHistoryOutput'] = ResolversParentTypes['CreditHistoryOutput']> = ResolversObject<{
  endingMonthBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekFour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekOne?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekThree?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekTwo?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CurrentAverageResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentAverage'] = ResolversParentTypes['CurrentAverage']> = ResolversObject<{
  average?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomPlanDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPlanDay'] = ResolversParentTypes['CustomPlanDay']> = ResolversObject<{
  ordinal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sub_type?: Resolver<ResolversTypes['WorkoutSubTypeEnum'], ParentType, ContextType>;
  workout_type?: Resolver<ResolversTypes['WorkoutTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DmlTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DMLType'], any> {
  name: 'DMLType';
}

export type DnaKitResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit'] = ResolversParentTypes['DNAKit']> = ResolversObject<{
  DNAResults?: Resolver<Array<ResolversTypes['DNAResults']>, ParentType, ContextType, Partial<DnaKitDnaResultsArgs>>;
  DNAResults_aggregate?: Resolver<ResolversTypes['DNAResults_aggregate'], ParentType, ContextType, Partial<DnaKitDnaResults_AggregateArgs>>;
  GeneticDirectionWebhooks?: Resolver<Array<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType, Partial<DnaKitGeneticDirectionWebhooksArgs>>;
  GeneticDirectionWebhooks_aggregate?: Resolver<ResolversTypes['GeneticDirectionWebhook_aggregate'], ParentType, ContextType, Partial<DnaKitGeneticDirectionWebhooks_AggregateArgs>>;
  QuizSurveys?: Resolver<Array<ResolversTypes['QuizSurvey']>, ParentType, ContextType, Partial<DnaKitQuizSurveysArgs>>;
  QuizSurveys_aggregate?: Resolver<ResolversTypes['QuizSurvey_aggregate'], ParentType, ContextType, Partial<DnaKitQuizSurveys_AggregateArgs>>;
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dropShipOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfilledBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  intended_for?: Resolver<ResolversTypes['DNAKitRole'], ParentType, ContextType>;
  is_gift?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_rekit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_returned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  kitActivated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_fedex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_ups?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_usps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  needs_rekit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderItemIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registered_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTrackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DnaKitRoleScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DNAKitRole'], any> {
  name: 'DNAKitRole';
}

export type DnaKit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_aggregate'] = ResolversParentTypes['DNAKit_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['DNAKit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['DNAKit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_aggregate_fields'] = ResolversParentTypes['DNAKit_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['DNAKit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<DnaKit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['DNAKit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['DNAKit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['DNAKit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['DNAKit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['DNAKit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['DNAKit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['DNAKit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['DNAKit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['DNAKit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_avg_fields'] = ResolversParentTypes['DNAKit_avg_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_max_fields'] = ResolversParentTypes['DNAKit_max_fields']> = ResolversObject<{
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfilledBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intended_for?: Resolver<Maybe<ResolversTypes['DNAKitRole']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_fedex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_ups?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_usps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderItemIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registered_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTrackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_min_fields'] = ResolversParentTypes['DNAKit_min_fields']> = ResolversObject<{
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfilledBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intended_for?: Resolver<Maybe<ResolversTypes['DNAKitRole']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_fedex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_ups?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_status_usps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderItemIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registered_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTrackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_mutation_response'] = ResolversParentTypes['DNAKit_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['DNAKit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_stddev_fields'] = ResolversParentTypes['DNAKit_stddev_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_stddev_pop_fields'] = ResolversParentTypes['DNAKit_stddev_pop_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_stddev_samp_fields'] = ResolversParentTypes['DNAKit_stddev_samp_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_sum_fields'] = ResolversParentTypes['DNAKit_sum_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_var_pop_fields'] = ResolversParentTypes['DNAKit_var_pop_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_var_samp_fields'] = ResolversParentTypes['DNAKit_var_samp_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaKit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAKit_variance_fields'] = ResolversParentTypes['DNAKit_variance_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  verificationCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults'] = ResolversParentTypes['DNAResults']> = ResolversObject<{
  DNAKit?: Resolver<Maybe<ResolversTypes['DNAKit']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  alter_kit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  boneMineralDensity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carbUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  core_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  dna_results_received_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  fatUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_viewed_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  injuryRisk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intrinsicMotivation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_viewed_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  mentalAcuity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  primary_report_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proteinUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  responseToCardio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responseToStrength?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stressTolerance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swabId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemicInflammation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightLossTendency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightRegain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResultsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResultsOutput'] = ResolversParentTypes['DNAResultsOutput']> = ResolversObject<{
  carbUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  core_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fatUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  injuryRisk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intrinsicMotivation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentalAcuity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proteinUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responseToCardio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responseToStrength?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stressTolerance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemicInflammation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightLossTendency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightRegain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_aggregate'] = ResolversParentTypes['DNAResults_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['DNAResults_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['DNAResults']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_aggregate_fields'] = ResolversParentTypes['DNAResults_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['DNAResults_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<DnaResults_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['DNAResults_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['DNAResults_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['DNAResults_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['DNAResults_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['DNAResults_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['DNAResults_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['DNAResults_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['DNAResults_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['DNAResults_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_avg_fields'] = ResolversParentTypes['DNAResults_avg_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_max_fields'] = ResolversParentTypes['DNAResults_max_fields']> = ResolversObject<{
  alter_kit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  boneMineralDensity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carbUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  core_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dna_results_received_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  fatUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_viewed_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  injuryRisk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intrinsicMotivation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_viewed_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  mentalAcuity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  primary_report_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proteinUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  responseToCardio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responseToStrength?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stressTolerance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swabId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemicInflammation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightLossTendency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightRegain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_min_fields'] = ResolversParentTypes['DNAResults_min_fields']> = ResolversObject<{
  alter_kit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  boneMineralDensity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carbUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  core_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dna_results_received_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  fatUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_viewed_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  injuryRisk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intrinsicMotivation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_viewed_time?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  mentalAcuity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  primary_report_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proteinUtilization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  responseToCardio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responseToStrength?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stressTolerance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swabId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemicInflammation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightLossTendency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightRegain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_mutation_response'] = ResolversParentTypes['DNAResults_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['DNAResults']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_stddev_fields'] = ResolversParentTypes['DNAResults_stddev_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_stddev_pop_fields'] = ResolversParentTypes['DNAResults_stddev_pop_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_stddev_samp_fields'] = ResolversParentTypes['DNAResults_stddev_samp_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_sum_fields'] = ResolversParentTypes['DNAResults_sum_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_var_pop_fields'] = ResolversParentTypes['DNAResults_var_pop_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_var_samp_fields'] = ResolversParentTypes['DNAResults_var_samp_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DnaResults_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DNAResults_variance_fields'] = ResolversParentTypes['DNAResults_variance_fields']> = ResolversObject<{
  num_views?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  report_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailyCheckinOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyCheckinOutput'] = ResolversParentTypes['DailyCheckinOutput']> = ResolversObject<{
  biometricEligibility?: Resolver<Maybe<ResolversTypes['BiometricEligibility']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_history?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkIOWithEpoch']>>>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_history?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  sleep_is_override?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sleep_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_stop?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_workouts_done_at_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_workouts_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  todays_recommendation?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailyCheckinV2MasteryObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyCheckinV2MasteryObject'] = ResolversParentTypes['DailyCheckinV2MasteryObject']> = ResolversObject<{
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_workouts_done_at_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_workouts_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailyCheckinV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyCheckinV2Output'] = ResolversParentTypes['DailyCheckinV2Output']> = ResolversObject<{
  cardio_group?: Resolver<Maybe<ResolversTypes['RecommenderGeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mastery?: Resolver<Maybe<ResolversTypes['DailyCheckinV2MasteryObject']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep?: Resolver<Maybe<ResolversTypes['DailyCheckinV2SleepObject']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  todays_recommendation?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  workin_group?: Resolver<Maybe<ResolversTypes['RecommenderGeneralRankGroup']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailyCheckinV2SleepObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyCheckinV2SleepObject'] = ResolversParentTypes['DailyCheckinV2SleepObject']> = ResolversObject<{
  biometricEligibility?: Resolver<Maybe<ResolversTypes['BiometricEligibility']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  history?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  is_full_override?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_partial_override?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stop?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery'] = ResolversParentTypes['Delivery']> = ResolversObject<{
  DeliveryHistories?: Resolver<Array<ResolversTypes['DeliveryHistory']>, ParentType, ContextType, Partial<DeliveryDeliveryHistoriesArgs>>;
  DeliveryHistories_aggregate?: Resolver<ResolversTypes['DeliveryHistory_aggregate'], ParentType, ContextType, Partial<DeliveryDeliveryHistories_AggregateArgs>>;
  UserProduct?: Resolver<Maybe<ResolversTypes['UserProduct']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  delivery_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_delivered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_shipped?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  order_line_item_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchase_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  purchase_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondary_tracking_numbers?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  secondary_tracking_urls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DeliveryStatus']>, ParentType, ContextType>;
  tracking_info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryHistory'] = ResolversParentTypes['DeliveryHistory']> = ResolversObject<{
  Delivery?: Resolver<ResolversTypes['Delivery'], ParentType, ContextType>;
  UserProduct?: Resolver<Maybe<ResolversTypes['UserProduct']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  delivery_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchase_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DeliveryStatus'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryHistory_aggregate'] = ResolversParentTypes['DeliveryHistory_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['DeliveryHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['DeliveryHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryHistory_aggregate_fields'] = ResolversParentTypes['DeliveryHistory_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<DeliveryHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['DeliveryHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['DeliveryHistory_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryHistory_max_fields'] = ResolversParentTypes['DeliveryHistory_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  delivery_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DeliveryStatus']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryHistory_min_fields'] = ResolversParentTypes['DeliveryHistory_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  delivery_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DeliveryStatus']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeliveryHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryHistory_mutation_response'] = ResolversParentTypes['DeliveryHistory_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['DeliveryHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DeliveryStatusScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DeliveryStatus'], any> {
  name: 'DeliveryStatus';
}

export type Delivery_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery_aggregate'] = ResolversParentTypes['Delivery_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Delivery_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Delivery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Delivery_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery_aggregate_fields'] = ResolversParentTypes['Delivery_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Delivery_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Delivery_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Delivery_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Delivery_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery_max_fields'] = ResolversParentTypes['Delivery_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  delivery_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_line_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  purchase_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondary_tracking_numbers?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  secondary_tracking_urls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DeliveryStatus']>, ParentType, ContextType>;
  tracking_info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Delivery_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery_min_fields'] = ResolversParentTypes['Delivery_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  delivery_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_line_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  purchase_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondary_tracking_numbers?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  secondary_tracking_urls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DeliveryStatus']>, ParentType, ContextType>;
  tracking_info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Delivery_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery_mutation_response'] = ResolversParentTypes['Delivery_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Delivery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DeviceTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DeviceType'], any> {
  name: 'DeviceType';
}

export type Device_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device_aggregate'] = ResolversParentTypes['Device_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Device_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Device_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device_aggregate_fields'] = ResolversParentTypes['Device_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Device_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Device_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Device_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Device_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device_max_fields'] = ResolversParentTypes['Device_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Device_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device_min_fields'] = ResolversParentTypes['Device_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Device_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device_mutation_response'] = ResolversParentTypes['Device_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DiagnosticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics'] = ResolversParentTypes['Diagnostics']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  epoch_ms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  raw_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_aggregate'] = ResolversParentTypes['Diagnostics_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Diagnostics_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Diagnostics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_aggregate_fields'] = ResolversParentTypes['Diagnostics_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Diagnostics_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Diagnostics_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Diagnostics_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Diagnostics_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Diagnostics_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Diagnostics_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Diagnostics_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Diagnostics_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Diagnostics_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Diagnostics_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Diagnostics_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_avg_fields'] = ResolversParentTypes['Diagnostics_avg_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_max_fields'] = ResolversParentTypes['Diagnostics_max_fields']> = ResolversObject<{
  batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  epoch_ms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  raw_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_min_fields'] = ResolversParentTypes['Diagnostics_min_fields']> = ResolversObject<{
  batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  epoch_ms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  raw_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_mutation_response'] = ResolversParentTypes['Diagnostics_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Diagnostics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_stddev_fields'] = ResolversParentTypes['Diagnostics_stddev_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_stddev_pop_fields'] = ResolversParentTypes['Diagnostics_stddev_pop_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_stddev_samp_fields'] = ResolversParentTypes['Diagnostics_stddev_samp_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_sum_fields'] = ResolversParentTypes['Diagnostics_sum_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_var_pop_fields'] = ResolversParentTypes['Diagnostics_var_pop_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_var_samp_fields'] = ResolversParentTypes['Diagnostics_var_samp_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Diagnostics_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Diagnostics_variance_fields'] = ResolversParentTypes['Diagnostics_variance_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DietContentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DietContents'] = ResolversParentTypes['DietContents']> = ResolversObject<{
  carbs?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  fats?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  proteins?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailHasKitOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailHasKitOutput'] = ResolversParentTypes['EmailHasKitOutput']> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasKit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EndAssessmentOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EndAssessmentOutput'] = ResolversParentTypes['EndAssessmentOutput']> = ResolversObject<{
  completed_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  high_knee_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recovery_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_high_knees?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface FeedbackTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FeedbackType'], any> {
  name: 'FeedbackType';
}

export type FetchCoachOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchCoachOutput'] = ResolversParentTypes['FetchCoachOutput']> = ResolversObject<{
  calendly_link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendly_link_thirty_min?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentful_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sf_coach_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchCoachedPlanOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchCoachedPlanOutput'] = ResolversParentTypes['FetchCoachedPlanOutput']> = ResolversObject<{
  current_plan?: Resolver<Maybe<ResolversTypes['ChangeCoachedPlanOutput']>, ParentType, ContextType>;
  preset_plans?: Resolver<Maybe<Array<Maybe<ResolversTypes['PresetPlans']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchCookBookOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchCookBookOutput'] = ResolversParentTypes['FetchCookBookOutput']> = ResolversObject<{
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['CookBookItem']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchWorkoutOptionsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchWorkoutOptionsOutput'] = ResolversParentTypes['FetchWorkoutOptionsOutput']> = ResolversObject<{
  duration_minutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trainer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchWorkoutOptionsOutputV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchWorkoutOptionsOutputV2'] = ResolversParentTypes['FetchWorkoutOptionsOutputV2']> = ResolversObject<{
  duration_minutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trainer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchWorkoutOptionsOutputV3Resolvers<ContextType = any, ParentType extends ResolversParentTypes['FetchWorkoutOptionsOutputV3'] = ResolversParentTypes['FetchWorkoutOptionsOutputV3']> = ResolversObject<{
  duration_minutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  isAdaptive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  themes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trainer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface FileTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FileType'], any> {
  name: 'FileType';
}

export type FinancePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan'] = ResolversParentTypes['FinancePlan']> = ResolversObject<{
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  UserFinancePlans?: Resolver<Array<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, Partial<FinancePlanUserFinancePlansArgs>>;
  UserFinancePlans_aggregate?: Resolver<ResolversTypes['UserFinancePlan_aggregate'], ParentType, ContextType, Partial<FinancePlanUserFinancePlans_AggregateArgs>>;
  cadence?: Resolver<ResolversTypes['CadenceType'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interest_rate?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  num_months?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  vendor_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_aggregate'] = ResolversParentTypes['FinancePlan_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['FinancePlan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['FinancePlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_aggregate_fields'] = ResolversParentTypes['FinancePlan_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['FinancePlan_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<FinancePlan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['FinancePlan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['FinancePlan_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['FinancePlan_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['FinancePlan_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['FinancePlan_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['FinancePlan_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['FinancePlan_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['FinancePlan_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['FinancePlan_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_avg_fields'] = ResolversParentTypes['FinancePlan_avg_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_max_fields'] = ResolversParentTypes['FinancePlan_max_fields']> = ResolversObject<{
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interest_rate?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  vendor_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_min_fields'] = ResolversParentTypes['FinancePlan_min_fields']> = ResolversObject<{
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interest_rate?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  vendor_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_mutation_response'] = ResolversParentTypes['FinancePlan_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['FinancePlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_stddev_fields'] = ResolversParentTypes['FinancePlan_stddev_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_stddev_pop_fields'] = ResolversParentTypes['FinancePlan_stddev_pop_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_stddev_samp_fields'] = ResolversParentTypes['FinancePlan_stddev_samp_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_sum_fields'] = ResolversParentTypes['FinancePlan_sum_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_var_pop_fields'] = ResolversParentTypes['FinancePlan_var_pop_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_var_samp_fields'] = ResolversParentTypes['FinancePlan_var_samp_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinancePlan_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancePlan_variance_fields'] = ResolversParentTypes['FinancePlan_variance_fields']> = ResolversObject<{
  interest_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface FinanceStatusScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FinanceStatus'], any> {
  name: 'FinanceStatus';
}

export type FirmwareContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent'] = ResolversParentTypes['FirmwareContent']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['FirmwareDevice'], ParentType, ContextType>;
  fileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_beta?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FileType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_aggregate'] = ResolversParentTypes['FirmwareContent_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['FirmwareContent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['FirmwareContent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_aggregate_fields'] = ResolversParentTypes['FirmwareContent_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['FirmwareContent_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<FirmwareContent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['FirmwareContent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['FirmwareContent_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['FirmwareContent_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['FirmwareContent_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['FirmwareContent_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['FirmwareContent_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['FirmwareContent_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['FirmwareContent_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['FirmwareContent_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_avg_fields'] = ResolversParentTypes['FirmwareContent_avg_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_max_fields'] = ResolversParentTypes['FirmwareContent_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['FirmwareDevice']>, ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FileType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_min_fields'] = ResolversParentTypes['FirmwareContent_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['FirmwareDevice']>, ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FileType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_mutation_response'] = ResolversParentTypes['FirmwareContent_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['FirmwareContent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_stddev_fields'] = ResolversParentTypes['FirmwareContent_stddev_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_stddev_pop_fields'] = ResolversParentTypes['FirmwareContent_stddev_pop_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_stddev_samp_fields'] = ResolversParentTypes['FirmwareContent_stddev_samp_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_sum_fields'] = ResolversParentTypes['FirmwareContent_sum_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_var_pop_fields'] = ResolversParentTypes['FirmwareContent_var_pop_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_var_samp_fields'] = ResolversParentTypes['FirmwareContent_var_samp_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirmwareContent_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirmwareContent_variance_fields'] = ResolversParentTypes['FirmwareContent_variance_fields']> = ResolversObject<{
  version_number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface FirmwareDeviceScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FirmwareDevice'], any> {
  name: 'FirmwareDevice';
}

export type FitnessRecommenderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FitnessRecommenderOutput'] = ResolversParentTypes['FitnessRecommenderOutput']> = ResolversObject<{
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extended_content?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previewUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['WorkoutSubTypeEnum']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface GeneralRankScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GeneralRank'], any> {
  name: 'GeneralRank';
}

export interface GeneralRankGroupScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GeneralRankGroup'], any> {
  name: 'GeneralRankGroup';
}

export type GeneticDirectionWebhookResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook'] = ResolversParentTypes['GeneticDirectionWebhook']> = ResolversObject<{
  DNAKit?: Resolver<ResolversTypes['DNAKit'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  dnaKitStatusNotification?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<GeneticDirectionWebhookDnaKitStatusNotificationArgs>>;
  dnaKit_payload?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<GeneticDirectionWebhookDnaKit_PayloadArgs>>;
  dropShipOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  geneticReport?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<GeneticDirectionWebhookGeneticReportArgs>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_aggregate'] = ResolversParentTypes['GeneticDirectionWebhook_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_aggregate_fields'] = ResolversParentTypes['GeneticDirectionWebhook_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<GeneticDirectionWebhook_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_avg_fields'] = ResolversParentTypes['GeneticDirectionWebhook_avg_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_max_fields'] = ResolversParentTypes['GeneticDirectionWebhook_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_min_fields'] = ResolversParentTypes['GeneticDirectionWebhook_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_mutation_response'] = ResolversParentTypes['GeneticDirectionWebhook_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_stddev_fields'] = ResolversParentTypes['GeneticDirectionWebhook_stddev_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_stddev_pop_fields'] = ResolversParentTypes['GeneticDirectionWebhook_stddev_pop_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_stddev_samp_fields'] = ResolversParentTypes['GeneticDirectionWebhook_stddev_samp_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_sum_fields'] = ResolversParentTypes['GeneticDirectionWebhook_sum_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_var_pop_fields'] = ResolversParentTypes['GeneticDirectionWebhook_var_pop_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_var_samp_fields'] = ResolversParentTypes['GeneticDirectionWebhook_var_samp_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GeneticDirectionWebhook_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneticDirectionWebhook_variance_fields'] = ResolversParentTypes['GeneticDirectionWebhook_variance_fields']> = ResolversObject<{
  dropShipOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GlobalFeatureControlResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalFeatureControl'] = ResolversParentTypes['GlobalFeatureControl']> = ResolversObject<{
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  feature_title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_beta?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GlobalFeatureControl_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalFeatureControl_aggregate'] = ResolversParentTypes['GlobalFeatureControl_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GlobalFeatureControl_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalFeatureControl_aggregate_fields'] = ResolversParentTypes['GlobalFeatureControl_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<GlobalFeatureControl_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GlobalFeatureControl_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalFeatureControl_max_fields'] = ResolversParentTypes['GlobalFeatureControl_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  feature_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GlobalFeatureControl_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalFeatureControl_min_fields'] = ResolversParentTypes['GlobalFeatureControl_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  feature_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GlobalFeatureControl_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlobalFeatureControl_mutation_response'] = ResolversParentTypes['GlobalFeatureControl_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GoalResolvers<ContextType = any, ParentType extends ResolversParentTypes['Goal'] = ResolversParentTypes['Goal']> = ResolversObject<{
  CoachedNutritions?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, Partial<GoalCoachedNutritionsArgs>>;
  CoachedNutritions_aggregate?: Resolver<ResolversTypes['CoachedNutrition_aggregate'], ParentType, ContextType, Partial<GoalCoachedNutritions_AggregateArgs>>;
  Users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<GoalUsersArgs>>;
  Users_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<GoalUsers_AggregateArgs>>;
  _GoalToUserPreferences?: Resolver<Array<ResolversTypes['_GoalToUserPreferences']>, ParentType, ContextType, Partial<Goal_GoalToUserPreferencesArgs>>;
  _GoalToUserPreferences_aggregate?: Resolver<ResolversTypes['_GoalToUserPreferences_aggregate'], ParentType, ContextType, Partial<Goal_GoalToUserPreferences_AggregateArgs>>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  goal_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  goal_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Goal_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Goal_aggregate'] = ResolversParentTypes['Goal_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Goal_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Goal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Goal_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Goal_aggregate_fields'] = ResolversParentTypes['Goal_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Goal_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Goal_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Goal_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Goal_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Goal_max_fields'] = ResolversParentTypes['Goal_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  goal_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Goal_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Goal_min_fields'] = ResolversParentTypes['Goal_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  goal_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Goal_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Goal_mutation_response'] = ResolversParentTypes['Goal_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Goal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitCompletedResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitCompletedResult'] = ResolversParentTypes['HabitCompletedResult']> = ResolversObject<{
  habit_days_left?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_reimbursement_tokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay'] = ResolversParentTypes['HabitDay']> = ResolversObject<{
  completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<ResolversTypes['HabitType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordinal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<ResolversTypes['HabitSubType'], ParentType, ContextType>;
  submission_value_bool?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_habit_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDayOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDayOutput'] = ResolversParentTypes['HabitDayOutput']> = ResolversObject<{
  completed_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitTypeEnum']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubTypeEnum']>, ParentType, ContextType>;
  submission_value_bool?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_habit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_aggregate'] = ResolversParentTypes['HabitDay_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['HabitDay_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['HabitDay']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_aggregate_fields'] = ResolversParentTypes['HabitDay_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['HabitDay_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<HabitDay_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['HabitDay_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['HabitDay_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['HabitDay_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['HabitDay_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['HabitDay_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['HabitDay_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['HabitDay_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['HabitDay_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['HabitDay_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_avg_fields'] = ResolversParentTypes['HabitDay_avg_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_max_fields'] = ResolversParentTypes['HabitDay_max_fields']> = ResolversObject<{
  completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubType']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_habit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_min_fields'] = ResolversParentTypes['HabitDay_min_fields']> = ResolversObject<{
  completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubType']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_habit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_mutation_response'] = ResolversParentTypes['HabitDay_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['HabitDay']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_stddev_fields'] = ResolversParentTypes['HabitDay_stddev_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_stddev_pop_fields'] = ResolversParentTypes['HabitDay_stddev_pop_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_stddev_samp_fields'] = ResolversParentTypes['HabitDay_stddev_samp_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_sum_fields'] = ResolversParentTypes['HabitDay_sum_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_var_pop_fields'] = ResolversParentTypes['HabitDay_var_pop_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_var_samp_fields'] = ResolversParentTypes['HabitDay_var_samp_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HabitDay_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HabitDay_variance_fields'] = ResolversParentTypes['HabitDay_variance_fields']> = ResolversObject<{
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submission_value_int?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface HabitSubTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HabitSubType'], any> {
  name: 'HabitSubType';
}

export interface HabitTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HabitType'], any> {
  name: 'HabitType';
}

export type HeartRateDiagnosticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics'] = ResolversParentTypes['HeartRateDiagnostics']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confidence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['DeviceType']>, ParentType, ContextType>;
  hr_timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hr_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_aggregate'] = ResolversParentTypes['HeartRateDiagnostics_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_aggregate_fields'] = ResolversParentTypes['HeartRateDiagnostics_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<HeartRateDiagnostics_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_avg_fields'] = ResolversParentTypes['HeartRateDiagnostics_avg_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_max_fields'] = ResolversParentTypes['HeartRateDiagnostics_max_fields']> = ResolversObject<{
  batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confidence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['DeviceType']>, ParentType, ContextType>;
  hr_timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hr_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_min_fields'] = ResolversParentTypes['HeartRateDiagnostics_min_fields']> = ResolversObject<{
  batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confidence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['DeviceType']>, ParentType, ContextType>;
  hr_timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hr_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_mutation_response'] = ResolversParentTypes['HeartRateDiagnostics_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_stddev_fields'] = ResolversParentTypes['HeartRateDiagnostics_stddev_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_stddev_pop_fields'] = ResolversParentTypes['HeartRateDiagnostics_stddev_pop_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_stddev_samp_fields'] = ResolversParentTypes['HeartRateDiagnostics_stddev_samp_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_sum_fields'] = ResolversParentTypes['HeartRateDiagnostics_sum_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_var_pop_fields'] = ResolversParentTypes['HeartRateDiagnostics_var_pop_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_var_samp_fields'] = ResolversParentTypes['HeartRateDiagnostics_var_samp_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HeartRateDiagnostics_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HeartRateDiagnostics_variance_fields'] = ResolversParentTypes['HeartRateDiagnostics_variance_fields']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HighKneeSetOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HighKneeSetOutput'] = ResolversParentTypes['HighKneeSetOutput']> = ResolversObject<{
  high_knee_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_high_knees?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HistoryAndCountsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryAndCounts'] = ResolversParentTypes['HistoryAndCounts']> = ResolversObject<{
  counts?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  history?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HouseholdResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household'] = ResolversParentTypes['Household']> = ResolversObject<{
  Subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  allocated_num_users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cadence?: Resolver<ResolversTypes['CadenceType'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<HouseholdMembersArgs>>;
  members_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<HouseholdMembers_AggregateArgs>>;
  owner?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required_tokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['HouseholdType'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HouseholdOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HouseholdOutput'] = ResolversParentTypes['HouseholdOutput']> = ResolversObject<{
  cadence?: Resolver<Maybe<ResolversTypes['CadenceTypeEnum']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurring_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  required_tokens_each?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  required_tokens_total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekly_tokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface HouseholdTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HouseholdType'], any> {
  name: 'HouseholdType';
}

export type Household_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_aggregate'] = ResolversParentTypes['Household_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Household_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Household']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_aggregate_fields'] = ResolversParentTypes['Household_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Household_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Household_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Household_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Household_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Household_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Household_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Household_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Household_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Household_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Household_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Household_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_avg_fields'] = ResolversParentTypes['Household_avg_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_max_fields'] = ResolversParentTypes['Household_max_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['HouseholdType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_min_fields'] = ResolversParentTypes['Household_min_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['HouseholdType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_mutation_response'] = ResolversParentTypes['Household_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Household']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_stddev_fields'] = ResolversParentTypes['Household_stddev_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_stddev_pop_fields'] = ResolversParentTypes['Household_stddev_pop_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_stddev_samp_fields'] = ResolversParentTypes['Household_stddev_samp_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_sum_fields'] = ResolversParentTypes['Household_sum_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_var_pop_fields'] = ResolversParentTypes['Household_var_pop_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_var_samp_fields'] = ResolversParentTypes['Household_var_samp_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Household_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Household_variance_fields'] = ResolversParentTypes['Household_variance_fields']> = ResolversObject<{
  allocated_num_users?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HrZone2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HrZone2Output'] = ResolversParentTypes['HrZone2Output']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IsValidKitOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsValidKitOutput'] = ResolversParentTypes['IsValidKitOutput']> = ResolversObject<{
  activationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  kitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LabelsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['LabelsOutput'] = ResolversParentTypes['LabelsOutput']> = ResolversObject<{
  activity?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValLabels']>>>, ParentType, ContextType>;
  equipments_owned?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValLabels']>>>, ParentType, ContextType>;
  favorite_workouts?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValLabels']>>>, ParentType, ContextType>;
  hows_your_sleep?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValLabels']>>>, ParentType, ContextType>;
  main_motivation?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValLabels']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketingEmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingEmail'] = ResolversParentTypes['MarketingEmail']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscribed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketingEmail_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingEmail_aggregate'] = ResolversParentTypes['MarketingEmail_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['MarketingEmail_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['MarketingEmail']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketingEmail_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingEmail_aggregate_fields'] = ResolversParentTypes['MarketingEmail_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<MarketingEmail_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['MarketingEmail_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['MarketingEmail_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketingEmail_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingEmail_max_fields'] = ResolversParentTypes['MarketingEmail_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketingEmail_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingEmail_min_fields'] = ResolversParentTypes['MarketingEmail_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketingEmail_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingEmail_mutation_response'] = ResolversParentTypes['MarketingEmail_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['MarketingEmail']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MasteryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery'] = ResolversParentTypes['Mastery']> = ResolversObject<{
  UserSets?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType, Partial<MasteryUserSetsArgs>>;
  UserSets_aggregate?: Resolver<ResolversTypes['UserSet_aggregate'], ParentType, ContextType, Partial<MasteryUserSets_AggregateArgs>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  min_percentage_reps?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  weighted_impact?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  workout_category?: Resolver<ResolversTypes['WorkoutCategory'], ParentType, ContextType>;
  workouts_to_advance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_aggregate'] = ResolversParentTypes['Mastery_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Mastery_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Mastery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_aggregate_fields'] = ResolversParentTypes['Mastery_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Mastery_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Mastery_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Mastery_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Mastery_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Mastery_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Mastery_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Mastery_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Mastery_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Mastery_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Mastery_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Mastery_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_avg_fields'] = ResolversParentTypes['Mastery_avg_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_max_fields'] = ResolversParentTypes['Mastery_max_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workout_category?: Resolver<Maybe<ResolversTypes['WorkoutCategory']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_min_fields'] = ResolversParentTypes['Mastery_min_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workout_category?: Resolver<Maybe<ResolversTypes['WorkoutCategory']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_mutation_response'] = ResolversParentTypes['Mastery_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Mastery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_stddev_fields'] = ResolversParentTypes['Mastery_stddev_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_stddev_pop_fields'] = ResolversParentTypes['Mastery_stddev_pop_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_stddev_samp_fields'] = ResolversParentTypes['Mastery_stddev_samp_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_sum_fields'] = ResolversParentTypes['Mastery_sum_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_var_pop_fields'] = ResolversParentTypes['Mastery_var_pop_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_var_samp_fields'] = ResolversParentTypes['Mastery_var_samp_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mastery_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mastery_variance_fields'] = ResolversParentTypes['Mastery_variance_fields']> = ResolversObject<{
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_percentage_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weighted_impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MealPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['MealPlan'] = ResolversParentTypes['MealPlan']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  generated_on_demand?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  generative_inputs?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  regenerate_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_retry_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usage?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MealPlanOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['MealPlanOutput'] = ResolversParentTypes['MealPlanOutput']> = ResolversObject<{
  plan?: Resolver<Maybe<ResolversTypes['MealPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MealPlanPreferenceMapItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MealPlanPreferenceMapItem'] = ResolversParentTypes['MealPlanPreferenceMapItem']> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['MealPlanPreferenceOptionCategoryEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  display_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MealPlanPreferenceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['MealPlanPreferenceOutput'] = ResolversParentTypes['MealPlanPreferenceOutput']> = ResolversObject<{
  preferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['MealPlanPreferenceMapItem']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MealPlanPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['MealPlanPreferences'] = ResolversParentTypes['MealPlanPreferences']> = ResolversObject<{
  preferences?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotInPlanTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotInPlanType'] = ResolversParentTypes['NotInPlanType']> = ResolversObject<{
  cardio?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NutritionReportOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['NutritionReportOutput'] = ResolversParentTypes['NutritionReportOutput']> = ResolversObject<{
  active_calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calorie_deficit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  default_nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  diet_contents?: Resolver<Maybe<ResolversTypes['DietContents']>, ParentType, ContextType>;
  diet_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  grams_recommended_carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  grams_recommended_fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  grams_recommended_proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inflammation_risk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nutrition_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrition_tip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percent_recommended_carbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_fats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percent_recommended_proteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recommended_daily_calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  resting_calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface OnboardingEntityTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['OnboardingEntityType'], any> {
  name: 'OnboardingEntityType';
}

export type OnboardingInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo'] = ResolversParentTypes['OnboardingInfo']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OnboardingEntityType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_aggregate'] = ResolversParentTypes['OnboardingInfo_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['OnboardingInfo_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['OnboardingInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_aggregate_fields'] = ResolversParentTypes['OnboardingInfo_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['OnboardingInfo_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OnboardingInfo_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['OnboardingInfo_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['OnboardingInfo_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['OnboardingInfo_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['OnboardingInfo_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['OnboardingInfo_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['OnboardingInfo_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['OnboardingInfo_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['OnboardingInfo_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['OnboardingInfo_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_avg_fields'] = ResolversParentTypes['OnboardingInfo_avg_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_max_fields'] = ResolversParentTypes['OnboardingInfo_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OnboardingEntityType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_min_fields'] = ResolversParentTypes['OnboardingInfo_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OnboardingEntityType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_mutation_response'] = ResolversParentTypes['OnboardingInfo_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['OnboardingInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_stddev_fields'] = ResolversParentTypes['OnboardingInfo_stddev_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_stddev_pop_fields'] = ResolversParentTypes['OnboardingInfo_stddev_pop_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_stddev_samp_fields'] = ResolversParentTypes['OnboardingInfo_stddev_samp_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_sum_fields'] = ResolversParentTypes['OnboardingInfo_sum_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_var_pop_fields'] = ResolversParentTypes['OnboardingInfo_var_pop_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_var_samp_fields'] = ResolversParentTypes['OnboardingInfo_var_samp_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingInfo_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingInfo_variance_fields'] = ResolversParentTypes['OnboardingInfo_variance_fields']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideosResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos'] = ResolversParentTypes['OnboardingVideos']> = ResolversObject<{
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_aggregate'] = ResolversParentTypes['OnboardingVideos_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['OnboardingVideos_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['OnboardingVideos']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_aggregate_fields'] = ResolversParentTypes['OnboardingVideos_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['OnboardingVideos_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OnboardingVideos_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['OnboardingVideos_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['OnboardingVideos_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['OnboardingVideos_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['OnboardingVideos_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['OnboardingVideos_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['OnboardingVideos_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['OnboardingVideos_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['OnboardingVideos_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['OnboardingVideos_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_avg_fields'] = ResolversParentTypes['OnboardingVideos_avg_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_max_fields'] = ResolversParentTypes['OnboardingVideos_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_min_fields'] = ResolversParentTypes['OnboardingVideos_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_mutation_response'] = ResolversParentTypes['OnboardingVideos_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['OnboardingVideos']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_stddev_fields'] = ResolversParentTypes['OnboardingVideos_stddev_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_stddev_pop_fields'] = ResolversParentTypes['OnboardingVideos_stddev_pop_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_stddev_samp_fields'] = ResolversParentTypes['OnboardingVideos_stddev_samp_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_sum_fields'] = ResolversParentTypes['OnboardingVideos_sum_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_var_pop_fields'] = ResolversParentTypes['OnboardingVideos_var_pop_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_var_samp_fields'] = ResolversParentTypes['OnboardingVideos_var_samp_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OnboardingVideos_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingVideos_variance_fields'] = ResolversParentTypes['OnboardingVideos_variance_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = ResolversObject<{
  OrderLineItems?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType, Partial<OrderOrderLineItemsArgs>>;
  OrderLineItems_aggregate?: Resolver<ResolversTypes['OrderLineItem_aggregate'], ParentType, ContextType, Partial<OrderOrderLineItems_AggregateArgs>>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  customer_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order_details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_payload?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<OrderOrder_PayloadArgs>>;
  sf_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_order_item_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopify_webhook_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem'] = ResolversParentTypes['OrderLineItem']> = ResolversObject<{
  Order?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  PaymentHistories?: Resolver<Array<ResolversTypes['PaymentHistory']>, ParentType, ContextType, Partial<OrderLineItemPaymentHistoriesArgs>>;
  PaymentHistories_aggregate?: Resolver<ResolversTypes['PaymentHistory_aggregate'], ParentType, ContextType, Partial<OrderLineItemPaymentHistories_AggregateArgs>>;
  Subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  UserFinancePlan?: Resolver<Maybe<ResolversTypes['UserFinancePlan']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product_title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sf_order_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_finance_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_aggregate'] = ResolversParentTypes['OrderLineItem_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['OrderLineItem_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_aggregate_fields'] = ResolversParentTypes['OrderLineItem_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['OrderLineItem_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OrderLineItem_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['OrderLineItem_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['OrderLineItem_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['OrderLineItem_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['OrderLineItem_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['OrderLineItem_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['OrderLineItem_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['OrderLineItem_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['OrderLineItem_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['OrderLineItem_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_avg_fields'] = ResolversParentTypes['OrderLineItem_avg_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_max_fields'] = ResolversParentTypes['OrderLineItem_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sf_order_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_finance_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_min_fields'] = ResolversParentTypes['OrderLineItem_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sf_order_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_finance_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_mutation_response'] = ResolversParentTypes['OrderLineItem_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_stddev_fields'] = ResolversParentTypes['OrderLineItem_stddev_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_stddev_pop_fields'] = ResolversParentTypes['OrderLineItem_stddev_pop_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_stddev_samp_fields'] = ResolversParentTypes['OrderLineItem_stddev_samp_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_sum_fields'] = ResolversParentTypes['OrderLineItem_sum_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_var_pop_fields'] = ResolversParentTypes['OrderLineItem_var_pop_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_var_samp_fields'] = ResolversParentTypes['OrderLineItem_var_samp_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderLineItem_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderLineItem_variance_fields'] = ResolversParentTypes['OrderLineItem_variance_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_line_item_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_aggregate'] = ResolversParentTypes['Order_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Order_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_aggregate_fields'] = ResolversParentTypes['Order_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Order_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Order_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Order_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Order_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Order_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Order_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Order_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Order_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Order_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Order_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Order_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_avg_fields'] = ResolversParentTypes['Order_avg_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_max_fields'] = ResolversParentTypes['Order_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  customer_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_order_item_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopify_webhook_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_min_fields'] = ResolversParentTypes['Order_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  customer_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_order_item_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopify_webhook_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_mutation_response'] = ResolversParentTypes['Order_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_stddev_fields'] = ResolversParentTypes['Order_stddev_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_stddev_pop_fields'] = ResolversParentTypes['Order_stddev_pop_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_stddev_samp_fields'] = ResolversParentTypes['Order_stddev_samp_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_sum_fields'] = ResolversParentTypes['Order_sum_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_var_pop_fields'] = ResolversParentTypes['Order_var_pop_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_var_samp_fields'] = ResolversParentTypes['Order_var_samp_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order_variance_fields'] = ResolversParentTypes['Order_variance_fields']> = ResolversObject<{
  shopify_customer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopify_short_order_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PauseOrResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PauseOrResumeOutput'] = ResolversParentTypes['PauseOrResumeOutput']> = ResolversObject<{
  duration_seen?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory'] = ResolversParentTypes['PaymentHistory']> = ResolversObject<{
  OrderLineItem?: Resolver<ResolversTypes['OrderLineItem'], ParentType, ContextType>;
  Subscription?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order_line_item_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stripe_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscription_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_aggregate'] = ResolversParentTypes['PaymentHistory_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['PaymentHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PaymentHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_aggregate_fields'] = ResolversParentTypes['PaymentHistory_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['PaymentHistory_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PaymentHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PaymentHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PaymentHistory_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PaymentHistory_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PaymentHistory_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PaymentHistory_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PaymentHistory_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PaymentHistory_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PaymentHistory_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PaymentHistory_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_avg_fields'] = ResolversParentTypes['PaymentHistory_avg_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_max_fields'] = ResolversParentTypes['PaymentHistory_max_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_line_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_min_fields'] = ResolversParentTypes['PaymentHistory_min_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_line_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_mutation_response'] = ResolversParentTypes['PaymentHistory_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PaymentHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_stddev_fields'] = ResolversParentTypes['PaymentHistory_stddev_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_stddev_pop_fields'] = ResolversParentTypes['PaymentHistory_stddev_pop_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_stddev_samp_fields'] = ResolversParentTypes['PaymentHistory_stddev_samp_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_sum_fields'] = ResolversParentTypes['PaymentHistory_sum_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_var_pop_fields'] = ResolversParentTypes['PaymentHistory_var_pop_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_var_samp_fields'] = ResolversParentTypes['PaymentHistory_var_samp_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentHistory_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentHistory_variance_fields'] = ResolversParentTypes['PaymentHistory_variance_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PearTokensResolvers<ContextType = any, ParentType extends ResolversParentTypes['PearTokens'] = ResolversParentTypes['PearTokens']> = ResolversObject<{
  access_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refresh_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay'] = ResolversParentTypes['PlanDay']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  WeeklyPlan?: Resolver<Maybe<ResolversTypes['WeeklyPlan']>, ParentType, ContextType>;
  challenge_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordinal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pear_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pear_workout_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  week?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekly_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_type?: Resolver<ResolversTypes['WorkoutType'], ParentType, ContextType>;
  yield_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_aggregate'] = ResolversParentTypes['PlanDay_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['PlanDay_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PlanDay']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_aggregate_fields'] = ResolversParentTypes['PlanDay_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['PlanDay_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PlanDay_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PlanDay_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PlanDay_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PlanDay_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PlanDay_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PlanDay_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PlanDay_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PlanDay_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PlanDay_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PlanDay_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_avg_fields'] = ResolversParentTypes['PlanDay_avg_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_max_fields'] = ResolversParentTypes['PlanDay_max_fields']> = ResolversObject<{
  challenge_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pear_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pear_workout_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekly_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  yield_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_min_fields'] = ResolversParentTypes['PlanDay_min_fields']> = ResolversObject<{
  challenge_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pear_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pear_workout_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekly_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  yield_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_mutation_response'] = ResolversParentTypes['PlanDay_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PlanDay']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_stddev_fields'] = ResolversParentTypes['PlanDay_stddev_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_stddev_pop_fields'] = ResolversParentTypes['PlanDay_stddev_pop_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_stddev_samp_fields'] = ResolversParentTypes['PlanDay_stddev_samp_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_sum_fields'] = ResolversParentTypes['PlanDay_sum_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_var_pop_fields'] = ResolversParentTypes['PlanDay_var_pop_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_var_samp_fields'] = ResolversParentTypes['PlanDay_var_samp_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanDay_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanDay_variance_fields'] = ResolversParentTypes['PlanDay_variance_fields']> = ResolversObject<{
  ordinal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanProgressCountsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanProgressCounts'] = ResolversParentTypes['PlanProgressCounts']> = ResolversObject<{
  cardio?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strength?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  workin?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanProgressOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanProgressOutput'] = ResolversParentTypes['PlanProgressOutput']> = ResolversObject<{
  activePlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['PlanProgressCounts'], ParentType, ContextType>;
  goal?: Resolver<ResolversTypes['PlanProgressCounts'], ParentType, ContextType>;
  notInPlan?: Resolver<Maybe<ResolversTypes['NotInPlanType']>, ParentType, ContextType>;
  planType?: Resolver<Maybe<ResolversTypes['PlanTypeObject']>, ParentType, ContextType>;
  range?: Resolver<ResolversTypes['PlanProgressRange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanProgressRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanProgressRange'] = ResolversParentTypes['PlanProgressRange']> = ResolversObject<{
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stop?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface PlanTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PlanType'], any> {
  name: 'PlanType';
}

export type PlanTypeObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanTypeObject'] = ResolversParentTypes['PlanTypeObject']> = ResolversObject<{
  fitnessGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetDietResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetDiet'] = ResolversParentTypes['PresetDiet']> = ResolversObject<{
  CoachedNutritions?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, Partial<PresetDietCoachedNutritionsArgs>>;
  CoachedNutritions_aggregate?: Resolver<ResolversTypes['CoachedNutrition_aggregate'], ParentType, ContextType, Partial<PresetDietCoachedNutritions_AggregateArgs>>;
  carbs?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  diet_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fats?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  proteins?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetDiet_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetDiet_aggregate'] = ResolversParentTypes['PresetDiet_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['PresetDiet_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PresetDiet']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetDiet_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetDiet_aggregate_fields'] = ResolversParentTypes['PresetDiet_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PresetDiet_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PresetDiet_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PresetDiet_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetDiet_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetDiet_max_fields'] = ResolversParentTypes['PresetDiet_max_fields']> = ResolversObject<{
  carbs?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  diet_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fats?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proteins?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetDiet_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetDiet_min_fields'] = ResolversParentTypes['PresetDiet_min_fields']> = ResolversObject<{
  carbs?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  diet_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fats?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proteins?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetDiet_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetDiet_mutation_response'] = ResolversParentTypes['PresetDiet_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PresetDiet']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PresetPlansResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetPlans'] = ResolversParentTypes['PresetPlans']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomPlanDay']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_type?: Resolver<Maybe<ResolversTypes['PlanTypeEnum']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pro'] = ResolversParentTypes['Pro']> = ResolversObject<{
  ProMusics?: Resolver<Array<ResolversTypes['ProMusic']>, ParentType, ContextType, Partial<ProProMusicsArgs>>;
  ProMusics_aggregate?: Resolver<ResolversTypes['ProMusic_aggregate'], ParentType, ContextType, Partial<ProProMusics_AggregateArgs>>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  Users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<ProUsersArgs>>;
  Users_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<ProUsers_AggregateArgs>>;
  Workouts?: Resolver<Array<ResolversTypes['Workout']>, ParentType, ContextType, Partial<ProWorkoutsArgs>>;
  Workouts_aggregate?: Resolver<ResolversTypes['Workout_aggregate'], ParentType, ContextType, Partial<ProWorkouts_AggregateArgs>>;
  _FollowingPros?: Resolver<Array<ResolversTypes['_FollowingPro']>, ParentType, ContextType, Partial<Pro_FollowingProsArgs>>;
  _FollowingPros_aggregate?: Resolver<ResolversTypes['_FollowingPro_aggregate'], ParentType, ContextType, Partial<Pro_FollowingPros_AggregateArgs>>;
  aboutMe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bigImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  musicLinks?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialties?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  specialty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProMusicResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProMusic'] = ResolversParentTypes['ProMusic']> = ResolversObject<{
  Pro?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  musicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  proId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProMusic_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProMusic_aggregate'] = ResolversParentTypes['ProMusic_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['ProMusic_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ProMusic']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProMusic_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProMusic_aggregate_fields'] = ResolversParentTypes['ProMusic_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ProMusic_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ProMusic_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProMusic_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProMusic_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProMusic_max_fields'] = ResolversParentTypes['ProMusic_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  musicUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProMusic_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProMusic_min_fields'] = ResolversParentTypes['ProMusic_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  musicUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProMusic_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProMusic_mutation_response'] = ResolversParentTypes['ProMusic_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProMusic']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Pro_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pro_aggregate'] = ResolversParentTypes['Pro_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Pro_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Pro']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Pro_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pro_aggregate_fields'] = ResolversParentTypes['Pro_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Pro_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Pro_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Pro_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Pro_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pro_max_fields'] = ResolversParentTypes['Pro_max_fields']> = ResolversObject<{
  aboutMe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bigImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  musicLinks?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialties?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  specialty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Pro_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pro_min_fields'] = ResolversParentTypes['Pro_min_fields']> = ResolversObject<{
  aboutMe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bigImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  introVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  musicLinks?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialties?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  specialty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Pro_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pro_mutation_response'] = ResolversParentTypes['Pro_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Pro']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = ResolversObject<{
  FinancePlans?: Resolver<Array<ResolversTypes['FinancePlan']>, ParentType, ContextType, Partial<ProductFinancePlansArgs>>;
  FinancePlans_aggregate?: Resolver<ResolversTypes['FinancePlan_aggregate'], ParentType, ContextType, Partial<ProductFinancePlans_AggregateArgs>>;
  OrderLineItems?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType, Partial<ProductOrderLineItemsArgs>>;
  OrderLineItems_aggregate?: Resolver<ResolversTypes['OrderLineItem_aggregate'], ParentType, ContextType, Partial<ProductOrderLineItems_AggregateArgs>>;
  UserProductHistories?: Resolver<Array<ResolversTypes['UserProductHistory']>, ParentType, ContextType, Partial<ProductUserProductHistoriesArgs>>;
  UserProductHistories_aggregate?: Resolver<ResolversTypes['UserProductHistory_aggregate'], ParentType, ContextType, Partial<ProductUserProductHistories_AggregateArgs>>;
  UserProducts?: Resolver<Array<ResolversTypes['UserProduct']>, ParentType, ContextType, Partial<ProductUserProductsArgs>>;
  UserProducts_aggregate?: Resolver<ResolversTypes['UserProduct_aggregate'], ParentType, ContextType, Partial<ProductUserProducts_AggregateArgs>>;
  availableQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopify_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SubscriptionType'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductDelivery'] = ResolversParentTypes['ProductDelivery']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secondary_tracking_numbers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  secondary_tracking_urls?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  tracking_info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tracking_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLog'] = ResolversParentTypes['ProductLog']> = ResolversObject<{
  UserProduct?: Resolver<ResolversTypes['UserProduct'], ParentType, ContextType>;
  column?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  old_data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_product_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLog_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLog_aggregate'] = ResolversParentTypes['ProductLog_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['ProductLog_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ProductLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLog_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLog_aggregate_fields'] = ResolversParentTypes['ProductLog_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ProductLog_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ProductLog_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProductLog_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLog_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLog_max_fields'] = ResolversParentTypes['ProductLog_max_fields']> = ResolversObject<{
  column?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  old_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLog_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLog_min_fields'] = ResolversParentTypes['ProductLog_min_fields']> = ResolversObject<{
  column?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  old_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLog_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLog_mutation_response'] = ResolversParentTypes['ProductLog_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProductLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_aggregate'] = ResolversParentTypes['Product_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Product_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_aggregate_fields'] = ResolversParentTypes['Product_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Product_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Product_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Product_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Product_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Product_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Product_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Product_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Product_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Product_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Product_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Product_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_avg_fields'] = ResolversParentTypes['Product_avg_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_max_fields'] = ResolversParentTypes['Product_max_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopify_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['SubscriptionType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_min_fields'] = ResolversParentTypes['Product_min_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopify_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['SubscriptionType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_mutation_response'] = ResolversParentTypes['Product_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_stddev_fields'] = ResolversParentTypes['Product_stddev_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_stddev_pop_fields'] = ResolversParentTypes['Product_stddev_pop_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_stddev_samp_fields'] = ResolversParentTypes['Product_stddev_samp_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_sum_fields'] = ResolversParentTypes['Product_sum_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_var_pop_fields'] = ResolversParentTypes['Product_var_pop_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_var_samp_fields'] = ResolversParentTypes['Product_var_samp_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Product_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_variance_fields'] = ResolversParentTypes['Product_variance_fields']> = ResolversObject<{
  availableQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProfileStatsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileStatsOutput'] = ResolversParentTypes['ProfileStatsOutput']> = ResolversObject<{
  alter_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alter_type_hex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  current_num_wins?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  free_months?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  num_nights_slept?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  num_reps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  num_workins?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  num_workouts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strength_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user_joined_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weeks_won?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem'] = ResolversParentTypes['PromotionalItem']> = ResolversObject<{
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_aggregate'] = ResolversParentTypes['PromotionalItem_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['PromotionalItem_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PromotionalItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_aggregate_fields'] = ResolversParentTypes['PromotionalItem_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['PromotionalItem_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PromotionalItem_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PromotionalItem_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PromotionalItem_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PromotionalItem_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PromotionalItem_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PromotionalItem_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PromotionalItem_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PromotionalItem_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PromotionalItem_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PromotionalItem_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_avg_fields'] = ResolversParentTypes['PromotionalItem_avg_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_max_fields'] = ResolversParentTypes['PromotionalItem_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_min_fields'] = ResolversParentTypes['PromotionalItem_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_mutation_response'] = ResolversParentTypes['PromotionalItem_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PromotionalItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_stddev_fields'] = ResolversParentTypes['PromotionalItem_stddev_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_stddev_pop_fields'] = ResolversParentTypes['PromotionalItem_stddev_pop_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_stddev_samp_fields'] = ResolversParentTypes['PromotionalItem_stddev_samp_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_sum_fields'] = ResolversParentTypes['PromotionalItem_sum_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_var_pop_fields'] = ResolversParentTypes['PromotionalItem_var_pop_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_var_samp_fields'] = ResolversParentTypes['PromotionalItem_var_samp_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromotionalItem_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromotionalItem_variance_fields'] = ResolversParentTypes['PromotionalItem_variance_fields']> = ResolversObject<{
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Props'] = ResolversParentTypes['Props']> = ResolversObject<{
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurveyResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey'] = ResolversParentTypes['QuizSurvey']> = ResolversObject<{
  DNAKit?: Resolver<Maybe<ResolversTypes['DNAKit']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  activity?: Resolver<Maybe<ResolversTypes['SurveyActivity']>, ParentType, ContextType>;
  additional_notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['SurveyQuizAge']>, ParentType, ContextType>;
  age_num?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  areas_needing_help?: Resolver<Maybe<Array<ResolversTypes['SurveyAreasNeedingHelp']>>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  current_fitness_ability?: Resolver<Maybe<ResolversTypes['SurveyFitnessAbility']>, ParentType, ContextType>;
  current_weight_lbs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dNAKitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  equipments_owned?: Resolver<Maybe<ResolversTypes['SurveyEquipment']>, ParentType, ContextType>;
  equipments_owned_list?: Resolver<Maybe<Array<ResolversTypes['SurveyEquipment']>>, ParentType, ContextType>;
  favorite_type_of_exercise?: Resolver<Maybe<ResolversTypes['SurveyFavoriteExercise']>, ParentType, ContextType>;
  favorite_workouts?: Resolver<Maybe<ResolversTypes['SurveyWorkouts']>, ParentType, ContextType>;
  favorite_workouts_list?: Resolver<Maybe<Array<ResolversTypes['SurveyWorkouts']>>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['SurveyQuizGender']>, ParentType, ContextType>;
  glp_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hows_your_sleep?: Resolver<Maybe<ResolversTypes['SurveySleep']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  main_motivation?: Resolver<Maybe<ResolversTypes['SurveyMotivation']>, ParentType, ContextType>;
  motivation_struggle?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  services_tried?: Resolver<Maybe<Array<ResolversTypes['SurveyServicesTried']>>, ParentType, ContextType>;
  tried_at_home?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_loss_goal?: Resolver<Maybe<ResolversTypes['SurveyWeightLossGoal']>, ParentType, ContextType>;
  weight_loss_goal_custom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_loss_timeline?: Resolver<Maybe<ResolversTypes['SurveyWeightLossTimeline']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_aggregate'] = ResolversParentTypes['QuizSurvey_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['QuizSurvey_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['QuizSurvey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_aggregate_fields'] = ResolversParentTypes['QuizSurvey_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['QuizSurvey_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<QuizSurvey_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['QuizSurvey_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['QuizSurvey_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['QuizSurvey_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['QuizSurvey_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['QuizSurvey_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['QuizSurvey_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['QuizSurvey_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['QuizSurvey_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['QuizSurvey_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_avg_fields'] = ResolversParentTypes['QuizSurvey_avg_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_max_fields'] = ResolversParentTypes['QuizSurvey_max_fields']> = ResolversObject<{
  activity?: Resolver<Maybe<ResolversTypes['SurveyActivity']>, ParentType, ContextType>;
  additional_notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['SurveyQuizAge']>, ParentType, ContextType>;
  age_num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  areas_needing_help?: Resolver<Maybe<Array<ResolversTypes['SurveyAreasNeedingHelp']>>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  current_fitness_ability?: Resolver<Maybe<ResolversTypes['SurveyFitnessAbility']>, ParentType, ContextType>;
  current_weight_lbs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dNAKitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipments_owned?: Resolver<Maybe<ResolversTypes['SurveyEquipment']>, ParentType, ContextType>;
  equipments_owned_list?: Resolver<Maybe<Array<ResolversTypes['SurveyEquipment']>>, ParentType, ContextType>;
  favorite_type_of_exercise?: Resolver<Maybe<ResolversTypes['SurveyFavoriteExercise']>, ParentType, ContextType>;
  favorite_workouts?: Resolver<Maybe<ResolversTypes['SurveyWorkouts']>, ParentType, ContextType>;
  favorite_workouts_list?: Resolver<Maybe<Array<ResolversTypes['SurveyWorkouts']>>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['SurveyQuizGender']>, ParentType, ContextType>;
  glp_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hows_your_sleep?: Resolver<Maybe<ResolversTypes['SurveySleep']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  main_motivation?: Resolver<Maybe<ResolversTypes['SurveyMotivation']>, ParentType, ContextType>;
  services_tried?: Resolver<Maybe<Array<ResolversTypes['SurveyServicesTried']>>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_loss_goal?: Resolver<Maybe<ResolversTypes['SurveyWeightLossGoal']>, ParentType, ContextType>;
  weight_loss_goal_custom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_loss_timeline?: Resolver<Maybe<ResolversTypes['SurveyWeightLossTimeline']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_min_fields'] = ResolversParentTypes['QuizSurvey_min_fields']> = ResolversObject<{
  activity?: Resolver<Maybe<ResolversTypes['SurveyActivity']>, ParentType, ContextType>;
  additional_notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['SurveyQuizAge']>, ParentType, ContextType>;
  age_num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  areas_needing_help?: Resolver<Maybe<Array<ResolversTypes['SurveyAreasNeedingHelp']>>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  current_fitness_ability?: Resolver<Maybe<ResolversTypes['SurveyFitnessAbility']>, ParentType, ContextType>;
  current_weight_lbs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dNAKitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipments_owned?: Resolver<Maybe<ResolversTypes['SurveyEquipment']>, ParentType, ContextType>;
  equipments_owned_list?: Resolver<Maybe<Array<ResolversTypes['SurveyEquipment']>>, ParentType, ContextType>;
  favorite_type_of_exercise?: Resolver<Maybe<ResolversTypes['SurveyFavoriteExercise']>, ParentType, ContextType>;
  favorite_workouts?: Resolver<Maybe<ResolversTypes['SurveyWorkouts']>, ParentType, ContextType>;
  favorite_workouts_list?: Resolver<Maybe<Array<ResolversTypes['SurveyWorkouts']>>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['SurveyQuizGender']>, ParentType, ContextType>;
  glp_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hows_your_sleep?: Resolver<Maybe<ResolversTypes['SurveySleep']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  main_motivation?: Resolver<Maybe<ResolversTypes['SurveyMotivation']>, ParentType, ContextType>;
  services_tried?: Resolver<Maybe<Array<ResolversTypes['SurveyServicesTried']>>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_loss_goal?: Resolver<Maybe<ResolversTypes['SurveyWeightLossGoal']>, ParentType, ContextType>;
  weight_loss_goal_custom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_loss_timeline?: Resolver<Maybe<ResolversTypes['SurveyWeightLossTimeline']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_mutation_response'] = ResolversParentTypes['QuizSurvey_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['QuizSurvey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_stddev_fields'] = ResolversParentTypes['QuizSurvey_stddev_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_stddev_pop_fields'] = ResolversParentTypes['QuizSurvey_stddev_pop_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_stddev_samp_fields'] = ResolversParentTypes['QuizSurvey_stddev_samp_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_sum_fields'] = ResolversParentTypes['QuizSurvey_sum_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_var_pop_fields'] = ResolversParentTypes['QuizSurvey_var_pop_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_var_samp_fields'] = ResolversParentTypes['QuizSurvey_var_samp_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuizSurvey_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizSurvey_variance_fields'] = ResolversParentTypes['QuizSurvey_variance_fields']> = ResolversObject<{
  age_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quote'] = ResolversParentTypes['Quote']> = ResolversObject<{
  context?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Quote_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quote_aggregate'] = ResolversParentTypes['Quote_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Quote_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Quote']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Quote_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quote_aggregate_fields'] = ResolversParentTypes['Quote_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Quote_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Quote_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Quote_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Quote_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quote_max_fields'] = ResolversParentTypes['Quote_max_fields']> = ResolversObject<{
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Quote_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quote_min_fields'] = ResolversParentTypes['Quote_min_fields']> = ResolversObject<{
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Quote_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quote_mutation_response'] = ResolversParentTypes['Quote_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Quote']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderOutput'] = ResolversParentTypes['RecommenderOutput']> = ResolversObject<{
  biometricEligibility?: Resolver<Maybe<ResolversTypes['BiometricEligibility']>, ParentType, ContextType>;
  cardio_filters?: Resolver<Maybe<ResolversTypes['HistoryAndCounts']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['RecommenderGeneralRank']>, ParentType, ContextType>;
  cardio_group?: Resolver<Maybe<ResolversTypes['RecommenderGeneralRankGroup']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_history?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkIOWithEpoch']>>>, ParentType, ContextType>;
  off_plan_recommendation?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  pear_outage?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_history?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  sleep_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_stop?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  todays_recommendation?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  workin_filters?: Resolver<Maybe<ResolversTypes['HistoryAndCounts']>, ParentType, ContextType>;
  workin_recommendation?: Resolver<ResolversTypes['RecommenderWorkinObj'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderV2CardioAlternativesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderV2CardioAlternatives'] = ResolversParentTypes['RecommenderV2CardioAlternatives']> = ResolversObject<{
  bodyweight?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  combat?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  dance?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  mobility?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderV2CardioObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderV2CardioObj'] = ResolversParentTypes['RecommenderV2CardioObj']> = ResolversObject<{
  alternatives?: Resolver<Maybe<ResolversTypes['RecommenderV2CardioAlternatives']>, ParentType, ContextType>;
  bodyweight?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  combat?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  dance?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  mobility?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderV2Output'] = ResolversParentTypes['RecommenderV2Output']> = ResolversObject<{
  biometricEligibility?: Resolver<Maybe<ResolversTypes['BiometricEligibility']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['RecommenderGeneralRank']>, ParentType, ContextType>;
  cardio_group?: Resolver<Maybe<ResolversTypes['RecommenderGeneralRankGroup']>, ParentType, ContextType>;
  cardio_recommendation?: Resolver<Maybe<ResolversTypes['RecommenderV2CardioObj']>, ParentType, ContextType>;
  chronotype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest_history?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkIOWithEpoch']>>>, ParentType, ContextType>;
  pear_outage?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_history?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  sleep_is_override?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sleep_start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_stop?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_alternatives?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_workouts_done_at_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_workouts_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  todays_recommendation?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  workin_recommendation?: Resolver<Maybe<ResolversTypes['RecommenderV2WorkinObj']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderV2WorkinAlternativesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderV2WorkinAlternatives'] = ResolversParentTypes['RecommenderV2WorkinAlternatives']> = ResolversObject<{
  breathwork?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  meditation?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  yoga?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderV2WorkinObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderV2WorkinObj'] = ResolversParentTypes['RecommenderV2WorkinObj']> = ResolversObject<{
  alternatives?: Resolver<Maybe<ResolversTypes['RecommenderV2WorkinAlternatives']>, ParentType, ContextType>;
  breathwork?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  meditation?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  yoga?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderWorkinObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderWorkinObj'] = ResolversParentTypes['RecommenderWorkinObj']> = ResolversObject<{
  alternative?: Resolver<Maybe<ResolversTypes['RecommenderWorkinSubAlternativeObj']>, ParentType, ContextType>;
  breathwork?: Resolver<Maybe<ResolversTypes['RecommenderWorkinSubObj']>, ParentType, ContextType>;
  meditation?: Resolver<Maybe<ResolversTypes['RecommenderWorkinSubObj']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderWorkinSubAlternativeObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderWorkinSubAlternativeObj'] = ResolversParentTypes['RecommenderWorkinSubAlternativeObj']> = ResolversObject<{
  breathwork?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecommenderWorkinSubObj']>>>, ParentType, ContextType>;
  meditation?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecommenderWorkinSubObj']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecommenderWorkinSubObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommenderWorkinSubObj'] = ResolversParentTypes['RecommenderWorkinSubObj']> = ResolversObject<{
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extended_content?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<ResolversTypes['WorkoutSubTypeEnum'], ParentType, ContextType>;
  times_seen?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workout_type?: Resolver<ResolversTypes['WorkoutTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegisterUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisterUser'] = ResolversParentTypes['RegisterUser']> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferMetric?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strapColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirementResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement'] = ResolversParentTypes['ReimbursementRequirement']> = ResolversObject<{
  cadence?: Resolver<ResolversTypes['CadenceType'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  household_members?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  required_tokens_num?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['HouseholdType'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_aggregate'] = ResolversParentTypes['ReimbursementRequirement_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_aggregate_fields'] = ResolversParentTypes['ReimbursementRequirement_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ReimbursementRequirement_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_avg_fields'] = ResolversParentTypes['ReimbursementRequirement_avg_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_max_fields'] = ResolversParentTypes['ReimbursementRequirement_max_fields']> = ResolversObject<{
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  household_members?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['HouseholdType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_min_fields'] = ResolversParentTypes['ReimbursementRequirement_min_fields']> = ResolversObject<{
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  household_members?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['HouseholdType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_mutation_response'] = ResolversParentTypes['ReimbursementRequirement_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_stddev_fields'] = ResolversParentTypes['ReimbursementRequirement_stddev_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_stddev_pop_fields'] = ResolversParentTypes['ReimbursementRequirement_stddev_pop_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_stddev_samp_fields'] = ResolversParentTypes['ReimbursementRequirement_stddev_samp_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_sum_fields'] = ResolversParentTypes['ReimbursementRequirement_sum_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_var_pop_fields'] = ResolversParentTypes['ReimbursementRequirement_var_pop_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_var_samp_fields'] = ResolversParentTypes['ReimbursementRequirement_var_samp_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReimbursementRequirement_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReimbursementRequirement_variance_fields'] = ResolversParentTypes['ReimbursementRequirement_variance_fields']> = ResolversObject<{
  household_members?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  required_tokens_num?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RepMasteryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RepMasteryResponse'] = ResolversParentTypes['RepMasteryResponse']> = ResolversObject<{
  BraceProne?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  BraceSide?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  BraceSupine?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  FoamRolling?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Hinge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Lunge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Mobility?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PullHorizontal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PullVertical?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PushHorizontal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PushVertical?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Squat?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResultListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResultListOutput'] = ResolversParentTypes['ResultListOutput']> = ResolversObject<{
  result?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResultObjectOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResultObjectOutput'] = ResolversParentTypes['ResultObjectOutput']> = ResolversObject<{
  result?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RetrieveFirmwareV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RetrieveFirmwareV2Output'] = ResolversParentTypes['RetrieveFirmwareV2Output']> = ResolversObject<{
  device?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hardware_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_beta?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface RoleScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Role'], any> {
  name: 'Role';
}

export type SampleOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleOutput'] = ResolversParentTypes['SampleOutput']> = ResolversObject<{
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SampleQuizSurveyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleQuizSurveyOutput'] = ResolversParentTypes['SampleQuizSurveyOutput']> = ResolversObject<{
  activity?: Resolver<Maybe<ResolversTypes['SurveyQuizActivity']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipments_owned?: Resolver<Maybe<Array<Maybe<ResolversTypes['SurveyEquipmentEnum']>>>, ParentType, ContextType>;
  favorite_workouts?: Resolver<Maybe<Array<Maybe<ResolversTypes['SurveyWorkoutsEnum']>>>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['SurveyQuizGenderEnum']>, ParentType, ContextType>;
  hows_your_sleep?: Resolver<Maybe<ResolversTypes['SurveySleepEnum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  main_motivation?: Resolver<Maybe<ResolversTypes['SurveyMotivationEnum']>, ParentType, ContextType>;
  motivation_struggle?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tried_at_home?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SampleScreenOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleScreenOutput'] = ResolversParentTypes['SampleScreenOutput']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SampleWorkoutZoneOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleWorkoutZoneOutput'] = ResolversParentTypes['SampleWorkoutZoneOutput']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder'] = ResolversParentTypes['ScreenOrder']> = ResolversObject<{
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  estimated_delivery_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extensivRefNum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfilledBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderItemIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  order_payload?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<ScreenOrderOrder_PayloadArgs>>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTrackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_aggregate'] = ResolversParentTypes['ScreenOrder_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['ScreenOrder_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ScreenOrder']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_aggregate_fields'] = ResolversParentTypes['ScreenOrder_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['ScreenOrder_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ScreenOrder_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ScreenOrder_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ScreenOrder_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ScreenOrder_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['ScreenOrder_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['ScreenOrder_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ScreenOrder_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['ScreenOrder_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['ScreenOrder_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ScreenOrder_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_avg_fields'] = ResolversParentTypes['ScreenOrder_avg_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_max_fields'] = ResolversParentTypes['ScreenOrder_max_fields']> = ResolversObject<{
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimated_delivery_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extensivRefNum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfilledBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderItemIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTrackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_min_fields'] = ResolversParentTypes['ScreenOrder_min_fields']> = ResolversObject<{
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimated_delivery_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extensivRefNum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfilledBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderItemIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  owner_user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnTrackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  webhookId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_mutation_response'] = ResolversParentTypes['ScreenOrder_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ScreenOrder']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_stddev_fields'] = ResolversParentTypes['ScreenOrder_stddev_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_stddev_pop_fields'] = ResolversParentTypes['ScreenOrder_stddev_pop_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_stddev_samp_fields'] = ResolversParentTypes['ScreenOrder_stddev_samp_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_sum_fields'] = ResolversParentTypes['ScreenOrder_sum_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_var_pop_fields'] = ResolversParentTypes['ScreenOrder_var_pop_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_var_samp_fields'] = ResolversParentTypes['ScreenOrder_var_samp_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScreenOrder_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScreenOrder_variance_fields'] = ResolversParentTypes['ScreenOrder_variance_fields']> = ResolversObject<{
  extensivOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopifyOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_aggregate'] = ResolversParentTypes['SearchResult_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['SearchResult_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['SearchResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_aggregate_fields'] = ResolversParentTypes['SearchResult_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['SearchResult_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<SearchResult_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['SearchResult_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SearchResult_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['SearchResult_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['SearchResult_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['SearchResult_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['SearchResult_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['SearchResult_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['SearchResult_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['SearchResult_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_avg_fields'] = ResolversParentTypes['SearchResult_avg_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_max_fields'] = ResolversParentTypes['SearchResult_max_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_min_fields'] = ResolversParentTypes['SearchResult_min_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_mutation_response'] = ResolversParentTypes['SearchResult_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['SearchResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_stddev_fields'] = ResolversParentTypes['SearchResult_stddev_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_stddev_pop_fields'] = ResolversParentTypes['SearchResult_stddev_pop_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_stddev_samp_fields'] = ResolversParentTypes['SearchResult_stddev_samp_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_sum_fields'] = ResolversParentTypes['SearchResult_sum_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_var_pop_fields'] = ResolversParentTypes['SearchResult_var_pop_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_var_samp_fields'] = ResolversParentTypes['SearchResult_var_samp_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchResult_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult_variance_fields'] = ResolversParentTypes['SearchResult_variance_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface SetQualityScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SetQuality'], any> {
  name: 'SetQuality';
}

export type SetUserGoalsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetUserGoalsOutput'] = ResolversParentTypes['SetUserGoalsOutput']> = ResolversObject<{
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfCoachCallOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SfCoachCallOutput'] = ResolversParentTypes['SfCoachCallOutput']> = ResolversObject<{
  scheduled_call_status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfFitnessGoalOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SfFitnessGoalOutput'] = ResolversParentTypes['SfFitnessGoalOutput']> = ResolversObject<{
  goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShipStatusMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipStatusMessage'] = ResolversParentTypes['ShipStatusMessage']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShippingAddressOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingAddressOutput'] = ResolversParentTypes['ShippingAddressOutput']> = ResolversObject<{
  shipping_address?: Resolver<Maybe<ResolversTypes['Props']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepInsightsDetailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepInsightsDetailOutput'] = ResolversParentTypes['SleepInsightsDetailOutput']> = ResolversObject<{
  averages?: Resolver<Maybe<Array<Maybe<ResolversTypes['sleepAverage']>>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['sleepInsightsDetailData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepInsightsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepInsightsOutput'] = ResolversParentTypes['SleepInsightsOutput']> = ResolversObject<{
  bedtimeConsistency?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  morningConsistency?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['CurrentAverage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepSurveyResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepSurvey'] = ResolversParentTypes['SleepSurvey']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sleptWithStrap?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepSurvey_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepSurvey_aggregate'] = ResolversParentTypes['SleepSurvey_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['SleepSurvey_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['SleepSurvey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepSurvey_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepSurvey_aggregate_fields'] = ResolversParentTypes['SleepSurvey_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<SleepSurvey_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['SleepSurvey_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SleepSurvey_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepSurvey_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepSurvey_max_fields'] = ResolversParentTypes['SleepSurvey_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepSurvey_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepSurvey_min_fields'] = ResolversParentTypes['SleepSurvey_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepSurvey_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SleepSurvey_mutation_response'] = ResolversParentTypes['SleepSurvey_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['SleepSurvey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StartAssessmentOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['StartAssessmentOutput'] = ResolversParentTypes['StartAssessmentOutput']> = ResolversObject<{
  assessment_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StepsV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepsV2Output'] = ResolversParentTypes['StepsV2Output']> = ResolversObject<{
  _avg_distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _avg_steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface StrapLocationScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['StrapLocation'], any> {
  name: 'StrapLocation';
}

export interface SubWorkoutTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SubWorkoutType'], any> {
  name: 'SubWorkoutType';
}

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  Households?: Resolver<Array<ResolversTypes['Household']>, ParentType, ContextType, Partial<SubscriptionHouseholdsArgs>>;
  Households_aggregate?: Resolver<ResolversTypes['Household_aggregate'], ParentType, ContextType, Partial<SubscriptionHouseholds_AggregateArgs>>;
  OrderLineItems?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType, Partial<SubscriptionOrderLineItemsArgs>>;
  OrderLineItems_aggregate?: Resolver<ResolversTypes['OrderLineItem_aggregate'], ParentType, ContextType, Partial<SubscriptionOrderLineItems_AggregateArgs>>;
  PaymentHistories?: Resolver<Array<ResolversTypes['PaymentHistory']>, ParentType, ContextType, Partial<SubscriptionPaymentHistoriesArgs>>;
  PaymentHistories_aggregate?: Resolver<ResolversTypes['PaymentHistory_aggregate'], ParentType, ContextType, Partial<SubscriptionPaymentHistories_AggregateArgs>>;
  Users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<SubscriptionUsersArgs>>;
  Users_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<SubscriptionUsers_AggregateArgs>>;
  amount?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  cadence?: Resolver<ResolversTypes['CadenceType'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  expiration?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stripe_item_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['HouseholdType'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLog'] = ResolversParentTypes['SubscriptionLog']> = ResolversObject<{
  column?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  old_data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscription_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionLog_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLog_aggregate'] = ResolversParentTypes['SubscriptionLog_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['SubscriptionLog_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['SubscriptionLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionLog_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLog_aggregate_fields'] = ResolversParentTypes['SubscriptionLog_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<SubscriptionLog_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['SubscriptionLog_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SubscriptionLog_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionLog_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLog_max_fields'] = ResolversParentTypes['SubscriptionLog_max_fields']> = ResolversObject<{
  column?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  old_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionLog_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLog_min_fields'] = ResolversParentTypes['SubscriptionLog_min_fields']> = ResolversObject<{
  column?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  old_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionLog_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLog_mutation_response'] = ResolversParentTypes['SubscriptionLog_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['SubscriptionLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface SubscriptionTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SubscriptionType'], any> {
  name: 'SubscriptionType';
}

export type Subscription_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_aggregate'] = ResolversParentTypes['Subscription_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Subscription_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_aggregate_fields'] = ResolversParentTypes['Subscription_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Subscription_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Subscription_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Subscription_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Subscription_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Subscription_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Subscription_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Subscription_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Subscription_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Subscription_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Subscription_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Subscription_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_avg_fields'] = ResolversParentTypes['Subscription_avg_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_max_fields'] = ResolversParentTypes['Subscription_max_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['HouseholdType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_min_fields'] = ResolversParentTypes['Subscription_min_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  cadence?: Resolver<Maybe<ResolversTypes['CadenceType']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['HouseholdType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_mutation_response'] = ResolversParentTypes['Subscription_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_stddev_fields'] = ResolversParentTypes['Subscription_stddev_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_stddev_pop_fields'] = ResolversParentTypes['Subscription_stddev_pop_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_stddev_samp_fields'] = ResolversParentTypes['Subscription_stddev_samp_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_sum_fields'] = ResolversParentTypes['Subscription_sum_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_var_pop_fields'] = ResolversParentTypes['Subscription_var_pop_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_var_samp_fields'] = ResolversParentTypes['Subscription_var_samp_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription_variance_fields'] = ResolversParentTypes['Subscription_variance_fields']> = ResolversObject<{
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface SurveyActivityScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyActivity'], any> {
  name: 'SurveyActivity';
}

export interface SurveyAreasNeedingHelpScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyAreasNeedingHelp'], any> {
  name: 'SurveyAreasNeedingHelp';
}

export interface SurveyEquipmentScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyEquipment'], any> {
  name: 'SurveyEquipment';
}

export interface SurveyFavoriteExerciseScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyFavoriteExercise'], any> {
  name: 'SurveyFavoriteExercise';
}

export interface SurveyFitnessAbilityScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyFitnessAbility'], any> {
  name: 'SurveyFitnessAbility';
}

export interface SurveyMotivationScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyMotivation'], any> {
  name: 'SurveyMotivation';
}

export interface SurveyQuizAgeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyQuizAge'], any> {
  name: 'SurveyQuizAge';
}

export interface SurveyQuizGenderScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyQuizGender'], any> {
  name: 'SurveyQuizGender';
}

export interface SurveyServicesTriedScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyServicesTried'], any> {
  name: 'SurveyServicesTried';
}

export interface SurveySleepScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveySleep'], any> {
  name: 'SurveySleep';
}

export interface SurveyWeightLossGoalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyWeightLossGoal'], any> {
  name: 'SurveyWeightLossGoal';
}

export interface SurveyWeightLossTimelineScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyWeightLossTimeline'], any> {
  name: 'SurveyWeightLossTimeline';
}

export interface SurveyWorkoutsScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SurveyWorkouts'], any> {
  name: 'SurveyWorkouts';
}

export interface ThemeColorScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ThemeColor'], any> {
  name: 'ThemeColor';
}

export type TimeMovingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeMovingOutput'] = ResolversParentTypes['TimeMovingOutput']> = ResolversObject<{
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  totals?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TimeWorkingOutOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeWorkingOutOutput'] = ResolversParentTypes['TimeWorkingOutOutput']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['WorkinWorkoutEnum']>, ParentType, ContextType>;
  datePart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ovarll_avg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = ResolversObject<{
  expires_at?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface TokenChangeSourceScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TokenChangeSource'], any> {
  name: 'TokenChangeSource';
}

export interface TokenChangeTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TokenChangeType'], any> {
  name: 'TokenChangeType';
}

export type TokenHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory'] = ResolversParentTypes['TokenHistory']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserWorkouts?: Resolver<Array<ResolversTypes['UserWorkout']>, ParentType, ContextType, Partial<TokenHistoryUserWorkoutsArgs>>;
  UserWorkouts_aggregate?: Resolver<ResolversTypes['UserWorkout_aggregate'], ParentType, ContextType, Partial<TokenHistoryUserWorkouts_AggregateArgs>>;
  change_source?: Resolver<ResolversTypes['TokenChangeSource'], ParentType, ContextType>;
  change_type?: Resolver<ResolversTypes['TokenChangeType'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count_after?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count_before?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_aggregate'] = ResolversParentTypes['TokenHistory_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['TokenHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TokenHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_aggregate_fields'] = ResolversParentTypes['TokenHistory_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['TokenHistory_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TokenHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['TokenHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TokenHistory_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['TokenHistory_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['TokenHistory_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['TokenHistory_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['TokenHistory_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['TokenHistory_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['TokenHistory_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['TokenHistory_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_avg_fields'] = ResolversParentTypes['TokenHistory_avg_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_max_fields'] = ResolversParentTypes['TokenHistory_max_fields']> = ResolversObject<{
  change_source?: Resolver<Maybe<ResolversTypes['TokenChangeSource']>, ParentType, ContextType>;
  change_type?: Resolver<Maybe<ResolversTypes['TokenChangeType']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_min_fields'] = ResolversParentTypes['TokenHistory_min_fields']> = ResolversObject<{
  change_source?: Resolver<Maybe<ResolversTypes['TokenChangeSource']>, ParentType, ContextType>;
  change_type?: Resolver<Maybe<ResolversTypes['TokenChangeType']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_mutation_response'] = ResolversParentTypes['TokenHistory_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TokenHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_stddev_fields'] = ResolversParentTypes['TokenHistory_stddev_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_stddev_pop_fields'] = ResolversParentTypes['TokenHistory_stddev_pop_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_stddev_samp_fields'] = ResolversParentTypes['TokenHistory_stddev_samp_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_sum_fields'] = ResolversParentTypes['TokenHistory_sum_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_var_pop_fields'] = ResolversParentTypes['TokenHistory_var_pop_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_var_samp_fields'] = ResolversParentTypes['TokenHistory_var_samp_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHistory_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenHistory_variance_fields'] = ResolversParentTypes['TokenHistory_variance_fields']> = ResolversObject<{
  count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_after?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  count_before?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_aggregate'] = ResolversParentTypes['Token_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Token_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_aggregate_fields'] = ResolversParentTypes['Token_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Token_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Token_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Token_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Token_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Token_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Token_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Token_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Token_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Token_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Token_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Token_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_avg_fields'] = ResolversParentTypes['Token_avg_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_max_fields'] = ResolversParentTypes['Token_max_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_min_fields'] = ResolversParentTypes['Token_min_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_mutation_response'] = ResolversParentTypes['Token_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_stddev_fields'] = ResolversParentTypes['Token_stddev_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_stddev_pop_fields'] = ResolversParentTypes['Token_stddev_pop_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_stddev_samp_fields'] = ResolversParentTypes['Token_stddev_samp_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_sum_fields'] = ResolversParentTypes['Token_sum_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_var_pop_fields'] = ResolversParentTypes['Token_var_pop_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_var_samp_fields'] = ResolversParentTypes['Token_var_samp_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Token_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token_variance_fields'] = ResolversParentTypes['Token_variance_fields']> = ResolversObject<{
  expires_at?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface TutorialModeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TutorialMode'], any> {
  name: 'TutorialMode';
}

export interface UnitScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Unit'], any> {
  name: 'Unit';
}

export type UploadContentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadContents'] = ResolversParentTypes['UploadContents']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['DevicesType']>, ParentType, ContextType>;
  fileType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadSetOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadSetOutput'] = ResolversParentTypes['UploadSetOutput']> = ResolversObject<{
  did_progress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mastery?: Resolver<ResolversTypes['RepMasteryResponse'], ParentType, ContextType>;
  set_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  set_quality?: Resolver<Maybe<ResolversTypes['SetQualityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadSetV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadSetV2Output'] = ResolversParentTypes['UploadSetV2Output']> = ResolversObject<{
  set_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  set_quality?: Resolver<Maybe<ResolversTypes['SetQualityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  Coach?: Resolver<Maybe<ResolversTypes['Coach']>, ParentType, ContextType>;
  CoachedNutritions?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, Partial<UserCoachedNutritionsArgs>>;
  CoachedNutritions_aggregate?: Resolver<ResolversTypes['CoachedNutrition_aggregate'], ParentType, ContextType, Partial<UserCoachedNutritions_AggregateArgs>>;
  Coaches?: Resolver<Array<ResolversTypes['Coach']>, ParentType, ContextType, Partial<UserCoachesArgs>>;
  Coaches_aggregate?: Resolver<ResolversTypes['Coach_aggregate'], ParentType, ContextType, Partial<UserCoaches_AggregateArgs>>;
  DNAResults?: Resolver<Array<ResolversTypes['DNAResults']>, ParentType, ContextType, Partial<UserDnaResultsArgs>>;
  DNAResults_aggregate?: Resolver<ResolversTypes['DNAResults_aggregate'], ParentType, ContextType, Partial<UserDnaResults_AggregateArgs>>;
  Devices?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType, Partial<UserDevicesArgs>>;
  Devices_aggregate?: Resolver<ResolversTypes['Device_aggregate'], ParentType, ContextType, Partial<UserDevices_AggregateArgs>>;
  Diagnostics?: Resolver<Array<ResolversTypes['Diagnostics']>, ParentType, ContextType, Partial<UserDiagnosticsArgs>>;
  Diagnostics_aggregate?: Resolver<ResolversTypes['Diagnostics_aggregate'], ParentType, ContextType, Partial<UserDiagnostics_AggregateArgs>>;
  Goal?: Resolver<Maybe<ResolversTypes['Goal']>, ParentType, ContextType>;
  HeartRateDiagnostics?: Resolver<Array<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, Partial<UserHeartRateDiagnosticsArgs>>;
  HeartRateDiagnostics_aggregate?: Resolver<ResolversTypes['HeartRateDiagnostics_aggregate'], ParentType, ContextType, Partial<UserHeartRateDiagnostics_AggregateArgs>>;
  Household?: Resolver<Maybe<ResolversTypes['Household']>, ParentType, ContextType>;
  Households?: Resolver<Array<ResolversTypes['Household']>, ParentType, ContextType, Partial<UserHouseholdsArgs>>;
  Households_aggregate?: Resolver<ResolversTypes['Household_aggregate'], ParentType, ContextType, Partial<UserHouseholds_AggregateArgs>>;
  PlanDays?: Resolver<Array<ResolversTypes['PlanDay']>, ParentType, ContextType, Partial<UserPlanDaysArgs>>;
  PlanDays_aggregate?: Resolver<ResolversTypes['PlanDay_aggregate'], ParentType, ContextType, Partial<UserPlanDays_AggregateArgs>>;
  Pro?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType>;
  Pros?: Resolver<Array<ResolversTypes['Pro']>, ParentType, ContextType, Partial<UserProsArgs>>;
  Pros_aggregate?: Resolver<ResolversTypes['Pro_aggregate'], ParentType, ContextType, Partial<UserPros_AggregateArgs>>;
  QuizSurveys?: Resolver<Array<ResolversTypes['QuizSurvey']>, ParentType, ContextType, Partial<UserQuizSurveysArgs>>;
  QuizSurveys_aggregate?: Resolver<ResolversTypes['QuizSurvey_aggregate'], ParentType, ContextType, Partial<UserQuizSurveys_AggregateArgs>>;
  ScreenOrders?: Resolver<Array<ResolversTypes['ScreenOrder']>, ParentType, ContextType, Partial<UserScreenOrdersArgs>>;
  ScreenOrders_aggregate?: Resolver<ResolversTypes['ScreenOrder_aggregate'], ParentType, ContextType, Partial<UserScreenOrders_AggregateArgs>>;
  SleepSurveys?: Resolver<Array<ResolversTypes['SleepSurvey']>, ParentType, ContextType, Partial<UserSleepSurveysArgs>>;
  SleepSurveys_aggregate?: Resolver<ResolversTypes['SleepSurvey_aggregate'], ParentType, ContextType, Partial<UserSleepSurveys_AggregateArgs>>;
  Subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>;
  TokenHistories?: Resolver<Array<ResolversTypes['TokenHistory']>, ParentType, ContextType, Partial<UserTokenHistoriesArgs>>;
  TokenHistories_aggregate?: Resolver<ResolversTypes['TokenHistory_aggregate'], ParentType, ContextType, Partial<UserTokenHistories_AggregateArgs>>;
  UserAggregates?: Resolver<Array<ResolversTypes['UserAggregates']>, ParentType, ContextType, Partial<UserUserAggregatesArgs>>;
  UserAggregates_aggregate?: Resolver<ResolversTypes['UserAggregates_aggregate'], ParentType, ContextType, Partial<UserUserAggregates_AggregateArgs>>;
  UserAssessments?: Resolver<Array<ResolversTypes['UserAssessment']>, ParentType, ContextType, Partial<UserUserAssessmentsArgs>>;
  UserAssessments_aggregate?: Resolver<ResolversTypes['UserAssessment_aggregate'], ParentType, ContextType, Partial<UserUserAssessments_AggregateArgs>>;
  UserFeatureControls?: Resolver<Array<ResolversTypes['UserFeatureControl']>, ParentType, ContextType, Partial<UserUserFeatureControlsArgs>>;
  UserFeatureControls_aggregate?: Resolver<ResolversTypes['UserFeatureControl_aggregate'], ParentType, ContextType, Partial<UserUserFeatureControls_AggregateArgs>>;
  UserFeedbacks?: Resolver<Array<ResolversTypes['UserFeedback']>, ParentType, ContextType, Partial<UserUserFeedbacksArgs>>;
  UserFeedbacks_aggregate?: Resolver<ResolversTypes['UserFeedback_aggregate'], ParentType, ContextType, Partial<UserUserFeedbacks_AggregateArgs>>;
  UserFinancePlans?: Resolver<Array<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, Partial<UserUserFinancePlansArgs>>;
  UserFinancePlans_aggregate?: Resolver<ResolversTypes['UserFinancePlan_aggregate'], ParentType, ContextType, Partial<UserUserFinancePlans_AggregateArgs>>;
  UserMovementDailies?: Resolver<Array<ResolversTypes['UserMovementDaily']>, ParentType, ContextType, Partial<UserUserMovementDailiesArgs>>;
  UserMovementDailies_aggregate?: Resolver<ResolversTypes['UserMovementDaily_aggregate'], ParentType, ContextType, Partial<UserUserMovementDailies_AggregateArgs>>;
  UserPreferences?: Resolver<Array<ResolversTypes['UserPreferences']>, ParentType, ContextType, Partial<UserUserPreferencesArgs>>;
  UserPreferences_aggregate?: Resolver<ResolversTypes['UserPreferences_aggregate'], ParentType, ContextType, Partial<UserUserPreferences_AggregateArgs>>;
  UserProductHistories?: Resolver<Array<ResolversTypes['UserProductHistory']>, ParentType, ContextType, Partial<UserUserProductHistoriesArgs>>;
  UserProductHistories_aggregate?: Resolver<ResolversTypes['UserProductHistory_aggregate'], ParentType, ContextType, Partial<UserUserProductHistories_AggregateArgs>>;
  UserProducts?: Resolver<Array<ResolversTypes['UserProduct']>, ParentType, ContextType, Partial<UserUserProductsArgs>>;
  UserProducts_aggregate?: Resolver<ResolversTypes['UserProduct_aggregate'], ParentType, ContextType, Partial<UserUserProducts_AggregateArgs>>;
  UserReadinessLogs?: Resolver<Array<ResolversTypes['UserReadinessLog']>, ParentType, ContextType, Partial<UserUserReadinessLogsArgs>>;
  UserReadinessLogs_aggregate?: Resolver<ResolversTypes['UserReadinessLog_aggregate'], ParentType, ContextType, Partial<UserUserReadinessLogs_AggregateArgs>>;
  UserReadinesses?: Resolver<Array<ResolversTypes['UserReadiness']>, ParentType, ContextType, Partial<UserUserReadinessesArgs>>;
  UserReadinesses_aggregate?: Resolver<ResolversTypes['UserReadiness_aggregate'], ParentType, ContextType, Partial<UserUserReadinesses_AggregateArgs>>;
  UserReimbursmentHistories?: Resolver<Array<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType, Partial<UserUserReimbursmentHistoriesArgs>>;
  UserReimbursmentHistories_aggregate?: Resolver<ResolversTypes['UserReimbursmentHistory_aggregate'], ParentType, ContextType, Partial<UserUserReimbursmentHistories_AggregateArgs>>;
  UserReps?: Resolver<Array<ResolversTypes['UserRep']>, ParentType, ContextType, Partial<UserUserRepsArgs>>;
  UserReps_aggregate?: Resolver<ResolversTypes['UserRep_aggregate'], ParentType, ContextType, Partial<UserUserReps_AggregateArgs>>;
  UserSets?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType, Partial<UserUserSetsArgs>>;
  UserSets_aggregate?: Resolver<ResolversTypes['UserSet_aggregate'], ParentType, ContextType, Partial<UserUserSets_AggregateArgs>>;
  UserStates?: Resolver<Array<ResolversTypes['UserState']>, ParentType, ContextType, Partial<UserUserStatesArgs>>;
  UserStates_aggregate?: Resolver<ResolversTypes['UserState_aggregate'], ParentType, ContextType, Partial<UserUserStates_AggregateArgs>>;
  UserWorkouts?: Resolver<Array<ResolversTypes['UserWorkout']>, ParentType, ContextType, Partial<UserUserWorkoutsArgs>>;
  UserWorkouts_aggregate?: Resolver<ResolversTypes['UserWorkout_aggregate'], ParentType, ContextType, Partial<UserUserWorkouts_AggregateArgs>>;
  WeeklyPlans?: Resolver<Array<ResolversTypes['WeeklyPlan']>, ParentType, ContextType, Partial<UserWeeklyPlansArgs>>;
  WeeklyPlans_aggregate?: Resolver<ResolversTypes['WeeklyPlan_aggregate'], ParentType, ContextType, Partial<UserWeeklyPlans_AggregateArgs>>;
  _FollowingPros?: Resolver<Array<ResolversTypes['_FollowingPro']>, ParentType, ContextType, Partial<User_FollowingProsArgs>>;
  _FollowingPros_aggregate?: Resolver<ResolversTypes['_FollowingPro_aggregate'], ParentType, ContextType, Partial<User_FollowingPros_AggregateArgs>>;
  activityLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  app_download_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  auth0UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  brace_side_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  brace_supine_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  cardio_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  cuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enable_asensei?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enable_diagnostics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fitness_goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  has_warming_state?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hinge_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  household_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inRingMode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_onboarded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  lunge_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mastery_prestige_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mostImportantFitnessGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearSessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearTokenExpiration?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  pearUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferMetric?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preferredWorkouts?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pull_vertical_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  push_horizontal_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  push_vertical_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  radioPreferences?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  referredId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ringId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringMacAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  screen_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signal_color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squat_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strapColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strap_location?: Resolver<ResolversTypes['StrapLocation'], ParentType, ContextType>;
  strength_level?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swabId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  v02_max_raw?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weightUnit?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workin_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  workin_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  workin_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  workoutFrequency?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates'] = ResolversParentTypes['UserAggregates']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_aggregate'] = ResolversParentTypes['UserAggregates_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserAggregates_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserAggregates']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_aggregate_fields'] = ResolversParentTypes['UserAggregates_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserAggregates_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserAggregates_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserAggregates_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserAggregates_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserAggregates_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserAggregates_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserAggregates_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserAggregates_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserAggregates_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserAggregates_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserAggregates_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_avg_fields'] = ResolversParentTypes['UserAggregates_avg_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_max_fields'] = ResolversParentTypes['UserAggregates_max_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_min_fields'] = ResolversParentTypes['UserAggregates_min_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_mutation_response'] = ResolversParentTypes['UserAggregates_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserAggregates']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_stddev_fields'] = ResolversParentTypes['UserAggregates_stddev_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_stddev_pop_fields'] = ResolversParentTypes['UserAggregates_stddev_pop_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_stddev_samp_fields'] = ResolversParentTypes['UserAggregates_stddev_samp_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_sum_fields'] = ResolversParentTypes['UserAggregates_sum_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_var_pop_fields'] = ResolversParentTypes['UserAggregates_var_pop_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_var_samp_fields'] = ResolversParentTypes['UserAggregates_var_samp_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAggregates_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregates_variance_fields'] = ResolversParentTypes['UserAggregates_variance_fields']> = ResolversObject<{
  allowedAwakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeningPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakenings?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  highHRPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medianHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesAwake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minutesLightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthlyHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessMild?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessModerate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessNone?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  restlessnessSevere?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEfficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepGoalAchieved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepQuality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepPenalty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSleepTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeInBed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment'] = ResolversParentTypes['UserAssessment']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserSets?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType, Partial<UserAssessmentUserSetsArgs>>;
  UserSets_aggregate?: Resolver<ResolversTypes['UserSet_aggregate'], ParentType, ContextType, Partial<UserAssessmentUserSets_AggregateArgs>>;
  assessment_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  assessment_type?: Resolver<ResolversTypes['AssessmentType'], ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  screen_data?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserAssessmentScreen_DataArgs>>;
  start_epoch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  v02_max?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_aggregate'] = ResolversParentTypes['UserAssessment_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserAssessment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserAssessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_aggregate_fields'] = ResolversParentTypes['UserAssessment_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserAssessment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserAssessment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserAssessment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserAssessment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserAssessment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserAssessment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserAssessment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserAssessment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserAssessment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserAssessment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserAssessment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_avg_fields'] = ResolversParentTypes['UserAssessment_avg_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_max_fields'] = ResolversParentTypes['UserAssessment_max_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  assessment_type?: Resolver<Maybe<ResolversTypes['AssessmentType']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_min_fields'] = ResolversParentTypes['UserAssessment_min_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  assessment_type?: Resolver<Maybe<ResolversTypes['AssessmentType']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_mutation_response'] = ResolversParentTypes['UserAssessment_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserAssessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_stddev_fields'] = ResolversParentTypes['UserAssessment_stddev_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_stddev_pop_fields'] = ResolversParentTypes['UserAssessment_stddev_pop_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_stddev_samp_fields'] = ResolversParentTypes['UserAssessment_stddev_samp_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_sum_fields'] = ResolversParentTypes['UserAssessment_sum_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_var_pop_fields'] = ResolversParentTypes['UserAssessment_var_pop_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_var_samp_fields'] = ResolversParentTypes['UserAssessment_var_samp_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAssessment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAssessment_variance_fields'] = ResolversParentTypes['UserAssessment_variance_fields']> = ResolversObject<{
  assessment_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  end_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fitness_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_hr_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recovery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeatureControlResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeatureControl'] = ResolversParentTypes['UserFeatureControl']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  feature_title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeatureControl_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeatureControl_aggregate'] = ResolversParentTypes['UserFeatureControl_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserFeatureControl_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserFeatureControl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeatureControl_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeatureControl_aggregate_fields'] = ResolversParentTypes['UserFeatureControl_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserFeatureControl_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserFeatureControl_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserFeatureControl_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeatureControl_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeatureControl_max_fields'] = ResolversParentTypes['UserFeatureControl_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  feature_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeatureControl_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeatureControl_min_fields'] = ResolversParentTypes['UserFeatureControl_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  feature_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeatureControl_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeatureControl_mutation_response'] = ResolversParentTypes['UserFeatureControl_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserFeatureControl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback'] = ResolversParentTypes['UserFeedback']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  external_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedback_type?: Resolver<ResolversTypes['FeedbackType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  max_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  multi_choice_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  raw_user_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_aggregate'] = ResolversParentTypes['UserFeedback_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserFeedback_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserFeedback']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_aggregate_fields'] = ResolversParentTypes['UserFeedback_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserFeedback_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserFeedback_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserFeedback_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserFeedback_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserFeedback_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserFeedback_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserFeedback_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserFeedback_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserFeedback_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserFeedback_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserFeedback_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_avg_fields'] = ResolversParentTypes['UserFeedback_avg_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_max_fields'] = ResolversParentTypes['UserFeedback_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  external_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedback_type?: Resolver<Maybe<ResolversTypes['FeedbackType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  multi_choice_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  raw_user_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_min_fields'] = ResolversParentTypes['UserFeedback_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  external_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedback_type?: Resolver<Maybe<ResolversTypes['FeedbackType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  multi_choice_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  raw_user_text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_mutation_response'] = ResolversParentTypes['UserFeedback_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserFeedback']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_stddev_fields'] = ResolversParentTypes['UserFeedback_stddev_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_stddev_pop_fields'] = ResolversParentTypes['UserFeedback_stddev_pop_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_stddev_samp_fields'] = ResolversParentTypes['UserFeedback_stddev_samp_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_sum_fields'] = ResolversParentTypes['UserFeedback_sum_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_var_pop_fields'] = ResolversParentTypes['UserFeedback_var_pop_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_var_samp_fields'] = ResolversParentTypes['UserFeedback_var_samp_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFeedback_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFeedback_variance_fields'] = ResolversParentTypes['UserFeedback_variance_fields']> = ResolversObject<{
  max_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFinancePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancePlan'] = ResolversParentTypes['UserFinancePlan']> = ResolversObject<{
  FinancePlan?: Resolver<ResolversTypes['FinancePlan'], ParentType, ContextType>;
  OrderLineItems?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType, Partial<UserFinancePlanOrderLineItemsArgs>>;
  OrderLineItems_aggregate?: Resolver<ResolversTypes['OrderLineItem_aggregate'], ParentType, ContextType, Partial<UserFinancePlanOrderLineItems_AggregateArgs>>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  finance_plan_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FinanceStatus'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendor_item_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFinancePlan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancePlan_aggregate'] = ResolversParentTypes['UserFinancePlan_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserFinancePlan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserFinancePlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFinancePlan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancePlan_aggregate_fields'] = ResolversParentTypes['UserFinancePlan_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserFinancePlan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserFinancePlan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserFinancePlan_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFinancePlan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancePlan_max_fields'] = ResolversParentTypes['UserFinancePlan_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  finance_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['FinanceStatus']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFinancePlan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancePlan_min_fields'] = ResolversParentTypes['UserFinancePlan_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  finance_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['FinanceStatus']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFinancePlan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancePlan_mutation_response'] = ResolversParentTypes['UserFinancePlan_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserFinancePlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabitResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit'] = ResolversParentTypes['UserHabit']> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  coached_habit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<ResolversTypes['HabitType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<ResolversTypes['HabitSubType'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_aggregate'] = ResolversParentTypes['UserHabit_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserHabit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserHabit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_aggregate_fields'] = ResolversParentTypes['UserHabit_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserHabit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserHabit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserHabit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserHabit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserHabit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserHabit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserHabit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserHabit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserHabit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserHabit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserHabit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_avg_fields'] = ResolversParentTypes['UserHabit_avg_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_max_fields'] = ResolversParentTypes['UserHabit_max_fields']> = ResolversObject<{
  coached_habit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_min_fields'] = ResolversParentTypes['UserHabit_min_fields']> = ResolversObject<{
  coached_habit_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  custom_congrats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  custom_try_again?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  habit_type?: Resolver<Maybe<ResolversTypes['HabitType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  long_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  medium_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  short_goal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['HabitSubType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_mutation_response'] = ResolversParentTypes['UserHabit_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserHabit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_stddev_fields'] = ResolversParentTypes['UserHabit_stddev_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_stddev_pop_fields'] = ResolversParentTypes['UserHabit_stddev_pop_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_stddev_samp_fields'] = ResolversParentTypes['UserHabit_stddev_samp_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_sum_fields'] = ResolversParentTypes['UserHabit_sum_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_var_pop_fields'] = ResolversParentTypes['UserHabit_var_pop_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_var_samp_fields'] = ResolversParentTypes['UserHabit_var_samp_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHabit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHabit_variance_fields'] = ResolversParentTypes['UserHabit_variance_fields']> = ResolversObject<{
  days_required?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goal_value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_checkin_time?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMonthsActiveOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMonthsActiveOutput'] = ResolversParentTypes['UserMonthsActiveOutput']> = ResolversObject<{
  monthsActive?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDailyResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily'] = ResolversParentTypes['UserMovementDaily']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  activeCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  caloric_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['time']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_aggregate'] = ResolversParentTypes['UserMovementDaily_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserMovementDaily_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserMovementDaily']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_aggregate_fields'] = ResolversParentTypes['UserMovementDaily_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserMovementDaily_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserMovementDaily_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserMovementDaily_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserMovementDaily_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserMovementDaily_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserMovementDaily_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserMovementDaily_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserMovementDaily_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserMovementDaily_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserMovementDaily_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserMovementDaily_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_avg_fields'] = ResolversParentTypes['UserMovementDaily_avg_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_max_fields'] = ResolversParentTypes['UserMovementDaily_max_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  caloric_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_min_fields'] = ResolversParentTypes['UserMovementDaily_min_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  caloric_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_mutation_response'] = ResolversParentTypes['UserMovementDaily_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserMovementDaily']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_stddev_fields'] = ResolversParentTypes['UserMovementDaily_stddev_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_stddev_pop_fields'] = ResolversParentTypes['UserMovementDaily_stddev_pop_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_stddev_samp_fields'] = ResolversParentTypes['UserMovementDaily_stddev_samp_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_sum_fields'] = ResolversParentTypes['UserMovementDaily_sum_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_var_pop_fields'] = ResolversParentTypes['UserMovementDaily_var_pop_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_var_samp_fields'] = ResolversParentTypes['UserMovementDaily_var_samp_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMovementDaily_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMovementDaily_variance_fields'] = ResolversParentTypes['UserMovementDaily_variance_fields']> = ResolversObject<{
  activeCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activeMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_high_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_inactive_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_low_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_medium_activity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_workout?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netCalories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sedentaryHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workouts_accounted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Mins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferences'] = ResolversParentTypes['UserPreferences']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  WorkinPrefrences?: Resolver<Array<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType, Partial<UserPreferencesWorkinPrefrencesArgs>>;
  WorkinPrefrences_aggregate?: Resolver<ResolversTypes['WorkinPrefrence_aggregate'], ParentType, ContextType, Partial<UserPreferencesWorkinPrefrences_AggregateArgs>>;
  WorkoutPrefrences?: Resolver<Array<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType, Partial<UserPreferencesWorkoutPrefrencesArgs>>;
  WorkoutPrefrences_aggregate?: Resolver<ResolversTypes['WorkoutPrefrence_aggregate'], ParentType, ContextType, Partial<UserPreferencesWorkoutPrefrences_AggregateArgs>>;
  _GoalToUserPreferences?: Resolver<Array<ResolversTypes['_GoalToUserPreferences']>, ParentType, ContextType, Partial<UserPreferences_GoalToUserPreferencesArgs>>;
  _GoalToUserPreferences_aggregate?: Resolver<ResolversTypes['_GoalToUserPreferences_aggregate'], ParentType, ContextType, Partial<UserPreferences_GoalToUserPreferences_AggregateArgs>>;
  cardioPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coaching_cues?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  enabled_coach_chat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enabled_coach_chat_notification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enabled_low_signal_battery_notification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  goal_updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownsBand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsDumbells?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receive_personalized_content?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  receive_promotional_content?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  theme_color?: Resolver<Maybe<ResolversTypes['ThemeColor']>, ParentType, ContextType>;
  trainerPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workout_tutorial_mode?: Resolver<ResolversTypes['TutorialMode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferencesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferencesOutput'] = ResolversParentTypes['UserPreferencesOutput']> = ResolversObject<{
  cardioPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coaching_cues?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enabled_coach_chat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enabled_coach_chat_notification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enabled_low_signal_battery_notification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsBand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsDumbells?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receive_personalized_content?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  receive_promotional_content?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  theme_color?: Resolver<Maybe<ResolversTypes['ThemeColorEnum']>, ParentType, ContextType>;
  trainerPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workin_prefrence?: Resolver<Maybe<ResolversTypes['WorkinPrefrenceOutput']>, ParentType, ContextType>;
  workout_prefrence?: Resolver<Maybe<ResolversTypes['WorkoutPrefrenceOutput']>, ParentType, ContextType>;
  workout_tutorial_mode?: Resolver<Maybe<ResolversTypes['TutorialModeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferences_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferences_aggregate'] = ResolversParentTypes['UserPreferences_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserPreferences_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserPreferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferences_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferences_aggregate_fields'] = ResolversParentTypes['UserPreferences_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserPreferences_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserPreferences_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserPreferences_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferences_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferences_max_fields'] = ResolversParentTypes['UserPreferences_max_fields']> = ResolversObject<{
  cardioPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  goal_updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsBand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsDumbells?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  theme_color?: Resolver<Maybe<ResolversTypes['ThemeColor']>, ParentType, ContextType>;
  trainerPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_tutorial_mode?: Resolver<Maybe<ResolversTypes['TutorialMode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferences_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferences_min_fields'] = ResolversParentTypes['UserPreferences_min_fields']> = ResolversObject<{
  cardioPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  goal_updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsBand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownsDumbells?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  theme_color?: Resolver<Maybe<ResolversTypes['ThemeColor']>, ParentType, ContextType>;
  trainerPreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_tutorial_mode?: Resolver<Maybe<ResolversTypes['TutorialMode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPreferences_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferences_mutation_response'] = ResolversParentTypes['UserPreferences_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserPreferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct'] = ResolversParentTypes['UserProduct']> = ResolversObject<{
  Deliveries?: Resolver<Array<ResolversTypes['Delivery']>, ParentType, ContextType, Partial<UserProductDeliveriesArgs>>;
  Deliveries_aggregate?: Resolver<ResolversTypes['Delivery_aggregate'], ParentType, ContextType, Partial<UserProductDeliveries_AggregateArgs>>;
  ProductLogs?: Resolver<Array<ResolversTypes['ProductLog']>, ParentType, ContextType, Partial<UserProductProductLogsArgs>>;
  ProductLogs_aggregate?: Resolver<ResolversTypes['ProductLog_aggregate'], ParentType, ContextType, Partial<UserProductProductLogs_AggregateArgs>>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  delivery_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stripe_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductHistory'] = ResolversParentTypes['UserProductHistory']> = ResolversObject<{
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  column?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  dml_type?: Resolver<ResolversTypes['DMLType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  old_data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductHistory_aggregate'] = ResolversParentTypes['UserProductHistory_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserProductHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserProductHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductHistory_aggregate_fields'] = ResolversParentTypes['UserProductHistory_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserProductHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserProductHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserProductHistory_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductHistory_max_fields'] = ResolversParentTypes['UserProductHistory_max_fields']> = ResolversObject<{
  column?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dml_type?: Resolver<Maybe<ResolversTypes['DMLType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  old_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductHistory_min_fields'] = ResolversParentTypes['UserProductHistory_min_fields']> = ResolversObject<{
  column?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dml_type?: Resolver<Maybe<ResolversTypes['DMLType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  old_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductHistory_mutation_response'] = ResolversParentTypes['UserProductHistory_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserProductHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_aggregate'] = ResolversParentTypes['UserProduct_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserProduct_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserProduct']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_aggregate_fields'] = ResolversParentTypes['UserProduct_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserProduct_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserProduct_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserProduct_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserProduct_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserProduct_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserProduct_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserProduct_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserProduct_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserProduct_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserProduct_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserProduct_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_avg_fields'] = ResolversParentTypes['UserProduct_avg_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_max_fields'] = ResolversParentTypes['UserProduct_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  delivery_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stripe_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_min_fields'] = ResolversParentTypes['UserProduct_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  delivery_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stripe_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_mutation_response'] = ResolversParentTypes['UserProduct_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserProduct']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_stddev_fields'] = ResolversParentTypes['UserProduct_stddev_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_stddev_pop_fields'] = ResolversParentTypes['UserProduct_stddev_pop_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_stddev_samp_fields'] = ResolversParentTypes['UserProduct_stddev_samp_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_sum_fields'] = ResolversParentTypes['UserProduct_sum_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_var_pop_fields'] = ResolversParentTypes['UserProduct_var_pop_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_var_samp_fields'] = ResolversParentTypes['UserProduct_var_samp_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProduct_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProduct_variance_fields'] = ResolversParentTypes['UserProduct_variance_fields']> = ResolversObject<{
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserProductsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProductsOutput'] = ResolversParentTypes['UserProductsOutput']> = ResolversObject<{
  delivery?: Resolver<Maybe<ResolversTypes['ProductDelivery']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product_sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness'] = ResolversParentTypes['UserReadiness']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  dayString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deepSleep?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hrv?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  is_full_override?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_philips?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  override_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_stages?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  sleep_timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  zScoreHRV?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  zScoreRHR?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<ResolversTypes['float8'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadinessLog'] = ResolversParentTypes['UserReadinessLog']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['CRUDaction'], ParentType, ContextType>;
  cause_for_action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserReadinessLogContentArgs>>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  divided_sleeps?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserReadinessLogDivided_SleepsArgs>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  single_results?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserReadinessLogSingle_ResultsArgs>>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessLog_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadinessLog_aggregate'] = ResolversParentTypes['UserReadinessLog_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserReadinessLog_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserReadinessLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessLog_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadinessLog_aggregate_fields'] = ResolversParentTypes['UserReadinessLog_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserReadinessLog_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserReadinessLog_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserReadinessLog_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessLog_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadinessLog_max_fields'] = ResolversParentTypes['UserReadinessLog_max_fields']> = ResolversObject<{
  action?: Resolver<Maybe<ResolversTypes['CRUDaction']>, ParentType, ContextType>;
  cause_for_action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessLog_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadinessLog_min_fields'] = ResolversParentTypes['UserReadinessLog_min_fields']> = ResolversObject<{
  action?: Resolver<Maybe<ResolversTypes['CRUDaction']>, ParentType, ContextType>;
  cause_for_action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadinessLog_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadinessLog_mutation_response'] = ResolversParentTypes['UserReadinessLog_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserReadinessLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_aggregate'] = ResolversParentTypes['UserReadiness_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserReadiness_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserReadiness']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_aggregate_fields'] = ResolversParentTypes['UserReadiness_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserReadiness_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserReadiness_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserReadiness_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserReadiness_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserReadiness_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserReadiness_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserReadiness_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserReadiness_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserReadiness_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserReadiness_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserReadiness_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_avg_fields'] = ResolversParentTypes['UserReadiness_avg_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_max_fields'] = ResolversParentTypes['UserReadiness_max_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dayString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  override_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_stages?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  sleep_timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_min_fields'] = ResolversParentTypes['UserReadiness_min_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  dayString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  override_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleep_stages?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  sleep_timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_mutation_response'] = ResolversParentTypes['UserReadiness_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserReadiness']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_stddev_fields'] = ResolversParentTypes['UserReadiness_stddev_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_stddev_pop_fields'] = ResolversParentTypes['UserReadiness_stddev_pop_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_stddev_samp_fields'] = ResolversParentTypes['UserReadiness_stddev_samp_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_sum_fields'] = ResolversParentTypes['UserReadiness_sum_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_var_pop_fields'] = ResolversParentTypes['UserReadiness_var_pop_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_var_samp_fields'] = ResolversParentTypes['UserReadiness_var_samp_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReadiness_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReadiness_variance_fields'] = ResolversParentTypes['UserReadiness_variance_fields']> = ResolversObject<{
  alter_zone_max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  alter_zone_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_start_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avg_sleep_stop_30_day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  awakeTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  circadianConsistency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combinedSleeps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_onset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  consistency_wakeup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  efficiency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interruptionMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latencyMins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  netSleepScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overrideScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readinessScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rhr_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepEndEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sleepStartEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wakingHrv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreDeepSleep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreHRV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreRHR?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zScoreSleepDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReimbursmentHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReimbursmentHistory'] = ResolversParentTypes['UserReimbursmentHistory']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planEndDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  planStartDate?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  reimbursed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReimbursmentHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReimbursmentHistory_aggregate'] = ResolversParentTypes['UserReimbursmentHistory_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReimbursmentHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReimbursmentHistory_aggregate_fields'] = ResolversParentTypes['UserReimbursmentHistory_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserReimbursmentHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReimbursmentHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReimbursmentHistory_max_fields'] = ResolversParentTypes['UserReimbursmentHistory_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planEndDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  planStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReimbursmentHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReimbursmentHistory_min_fields'] = ResolversParentTypes['UserReimbursmentHistory_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planEndDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  planStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReimbursmentHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReimbursmentHistory_mutation_response'] = ResolversParentTypes['UserReimbursmentHistory_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRepResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep'] = ResolversParentTypes['UserRep']> = ResolversObject<{
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  asensei_raw?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserRepAsensei_RawArgs>>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  negative_nuances?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rep?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userSetByUserSetId?: Resolver<ResolversTypes['UserSet'], ParentType, ContextType>;
  userWorkoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_set_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_aggregate'] = ResolversParentTypes['UserRep_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserRep_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserRep']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_aggregate_fields'] = ResolversParentTypes['UserRep_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserRep_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserRep_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserRep_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserRep_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserRep_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserRep_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserRep_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserRep_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserRep_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserRep_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserRep_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_avg_fields'] = ResolversParentTypes['UserRep_avg_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_max_fields'] = ResolversParentTypes['UserRep_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  negative_nuances?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userWorkoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_set_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_min_fields'] = ResolversParentTypes['UserRep_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  negative_nuances?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userWorkoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_set_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_mutation_response'] = ResolversParentTypes['UserRep_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserRep']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_stddev_fields'] = ResolversParentTypes['UserRep_stddev_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_stddev_pop_fields'] = ResolversParentTypes['UserRep_stddev_pop_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_stddev_samp_fields'] = ResolversParentTypes['UserRep_stddev_samp_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_sum_fields'] = ResolversParentTypes['UserRep_sum_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_var_pop_fields'] = ResolversParentTypes['UserRep_var_pop_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_var_samp_fields'] = ResolversParentTypes['UserRep_var_samp_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRep_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRep_variance_fields'] = ResolversParentTypes['UserRep_variance_fields']> = ResolversObject<{
  negative_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSetResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet'] = ResolversParentTypes['UserSet']> = ResolversObject<{
  Mastery?: Resolver<Maybe<ResolversTypes['Mastery']>, ParentType, ContextType>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserAssessment?: Resolver<Maybe<ResolversTypes['UserAssessment']>, ParentType, ContextType>;
  UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  asenseiFeedback?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_exercise_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_exercise_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_raw?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserSetAsensei_RawArgs>>;
  category?: Resolver<Maybe<ResolversTypes['WorkoutCategory']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  exercise_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_reps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  json_asensei_summary?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserSetJson_Asensei_SummaryArgs>>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  num_good_reps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  num_perfect_reps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  positive_nuances_possible?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reps_completed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set_quality?: Resolver<Maybe<ResolversTypes['SetQuality']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userAssessmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userRepsByUserSetId?: Resolver<Array<ResolversTypes['UserRep']>, ParentType, ContextType, Partial<UserSetUserRepsByUserSetIdArgs>>;
  userRepsByUserSetId_aggregate?: Resolver<ResolversTypes['UserRep_aggregate'], ParentType, ContextType, Partial<UserSetUserRepsByUserSetId_AggregateArgs>>;
  userWorkoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_aggregate'] = ResolversParentTypes['UserSet_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserSet_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_aggregate_fields'] = ResolversParentTypes['UserSet_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserSet_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserSet_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserSet_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserSet_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserSet_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserSet_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserSet_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserSet_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserSet_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserSet_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserSet_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_avg_fields'] = ResolversParentTypes['UserSet_avg_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_max_fields'] = ResolversParentTypes['UserSet_max_fields']> = ResolversObject<{
  asenseiFeedback?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_exercise_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_exercise_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['WorkoutCategory']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  exercise_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set_quality?: Resolver<Maybe<ResolversTypes['SetQuality']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userAssessmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userWorkoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_min_fields'] = ResolversParentTypes['UserSet_min_fields']> = ResolversObject<{
  asenseiFeedback?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_exercise_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  asensei_exercise_version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['WorkoutCategory']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  exercise_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goal_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set_quality?: Resolver<Maybe<ResolversTypes['SetQuality']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userAssessmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userWorkoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_mutation_response'] = ResolversParentTypes['UserSet_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_stddev_fields'] = ResolversParentTypes['UserSet_stddev_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_stddev_pop_fields'] = ResolversParentTypes['UserSet_stddev_pop_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_stddev_samp_fields'] = ResolversParentTypes['UserSet_stddev_samp_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_sum_fields'] = ResolversParentTypes['UserSet_sum_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_var_pop_fields'] = ResolversParentTypes['UserSet_var_pop_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_var_samp_fields'] = ResolversParentTypes['UserSet_var_samp_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSet_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSet_variance_fields'] = ResolversParentTypes['UserSet_variance_fields']> = ResolversObject<{
  goal_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hold_goal_duration_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mastery_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  negative_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  positive_nuances_possible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reps_completed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  set?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time_in_hold_sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_reps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState'] = ResolversParentTypes['UserState']> = ResolversObject<{
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  chat_tab_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  data_tab_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  data_tip_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  home_tab_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  legal_coach_access_acknowledgment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  legal_fitness_and_nutrition_acknowledgment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  legal_screen_installation_acknowledgment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan_tab_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan_tab_locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan_tab_seen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  profile_tab_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  profile_tip_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ring_setup_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ring_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  ring_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  screen_setup_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  screen_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  screen_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  signal_setup_complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signal_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  signal_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserStateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStateOutput'] = ResolversParentTypes['UserStateOutput']> = ResolversObject<{
  chat_tab_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  data_tab_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  data_tip_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  home_tab_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  plan_tab_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  plan_tab_locked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  plan_tab_seen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profile_tab_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profile_tip_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ring_setup_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ring_setup_completed_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ring_setup_started_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_setup_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  screen_setup_completed_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_setup_started_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signal_setup_complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  signal_setup_completed_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signal_setup_started_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserState_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState_aggregate'] = ResolversParentTypes['UserState_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserState_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserState_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState_aggregate_fields'] = ResolversParentTypes['UserState_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserState_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserState_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserState_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserState_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState_max_fields'] = ResolversParentTypes['UserState_max_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ring_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  ring_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  screen_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  screen_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  signal_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  signal_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserState_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState_min_fields'] = ResolversParentTypes['UserState_min_fields']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ring_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  ring_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  screen_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  screen_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  signal_setup_completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  signal_setup_started_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserState_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState_mutation_response'] = ResolversParentTypes['UserState_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan'] = ResolversParentTypes['UserTrainingPlan']> = ResolversObject<{
  cardios_per_week?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  strengths_per_week?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PlanType'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  workins_per_week?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_aggregate'] = ResolversParentTypes['UserTrainingPlan_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_aggregate_fields'] = ResolversParentTypes['UserTrainingPlan_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserTrainingPlan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_avg_fields'] = ResolversParentTypes['UserTrainingPlan_avg_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_max_fields'] = ResolversParentTypes['UserTrainingPlan_max_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PlanType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_min_fields'] = ResolversParentTypes['UserTrainingPlan_min_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PlanType']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_mutation_response'] = ResolversParentTypes['UserTrainingPlan_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_stddev_fields'] = ResolversParentTypes['UserTrainingPlan_stddev_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_stddev_pop_fields'] = ResolversParentTypes['UserTrainingPlan_stddev_pop_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_stddev_samp_fields'] = ResolversParentTypes['UserTrainingPlan_stddev_samp_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_sum_fields'] = ResolversParentTypes['UserTrainingPlan_sum_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_var_pop_fields'] = ResolversParentTypes['UserTrainingPlan_var_pop_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_var_samp_fields'] = ResolversParentTypes['UserTrainingPlan_var_samp_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTrainingPlan_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTrainingPlan_variance_fields'] = ResolversParentTypes['UserTrainingPlan_variance_fields']> = ResolversObject<{
  cardios_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strengths_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workins_per_week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout'] = ResolversParentTypes['UserWorkout']> = ResolversObject<{
  Diagnostics?: Resolver<Array<ResolversTypes['Diagnostics']>, ParentType, ContextType, Partial<UserWorkoutDiagnosticsArgs>>;
  Diagnostics_aggregate?: Resolver<ResolversTypes['Diagnostics_aggregate'], ParentType, ContextType, Partial<UserWorkoutDiagnostics_AggregateArgs>>;
  HeartRateDiagnostics?: Resolver<Array<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, Partial<UserWorkoutHeartRateDiagnosticsArgs>>;
  HeartRateDiagnostics_aggregate?: Resolver<ResolversTypes['HeartRateDiagnostics_aggregate'], ParentType, ContextType, Partial<UserWorkoutHeartRateDiagnostics_AggregateArgs>>;
  PlanDays?: Resolver<Array<ResolversTypes['PlanDay']>, ParentType, ContextType, Partial<UserWorkoutPlanDaysArgs>>;
  PlanDays_aggregate?: Resolver<ResolversTypes['PlanDay_aggregate'], ParentType, ContextType, Partial<UserWorkoutPlanDays_AggregateArgs>>;
  TokenHistory?: Resolver<Maybe<ResolversTypes['TokenHistory']>, ParentType, ContextType>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  UserFeedbacks?: Resolver<Array<ResolversTypes['UserFeedback']>, ParentType, ContextType, Partial<UserWorkoutUserFeedbacksArgs>>;
  UserFeedbacks_aggregate?: Resolver<ResolversTypes['UserFeedback_aggregate'], ParentType, ContextType, Partial<UserWorkoutUserFeedbacks_AggregateArgs>>;
  UserReps?: Resolver<Array<ResolversTypes['UserRep']>, ParentType, ContextType, Partial<UserWorkoutUserRepsArgs>>;
  UserReps_aggregate?: Resolver<ResolversTypes['UserRep_aggregate'], ParentType, ContextType, Partial<UserWorkoutUserReps_AggregateArgs>>;
  UserSets?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType, Partial<UserWorkoutUserSetsArgs>>;
  UserSets_aggregate?: Resolver<ResolversTypes['UserSet_aggregate'], ParentType, ContextType, Partial<UserWorkoutUserSets_AggregateArgs>>;
  caloric_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inPlan?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isChallenge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCounted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_demo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_qualified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lookup_reference_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  off_screen_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pauses?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  pear_workout_structure?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserWorkoutPear_Workout_StructureArgs>>;
  proRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recommendation_log_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  screen_data?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UserWorkoutScreen_DataArgs>>;
  startEpoch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  thumbnail_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_history_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workoutByWorkoutid?: Resolver<Maybe<ResolversTypes['Workout']>, ParentType, ContextType>;
  workoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_pear_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_pear_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_aggregate'] = ResolversParentTypes['UserWorkout_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['UserWorkout_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_aggregate_fields'] = ResolversParentTypes['UserWorkout_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['UserWorkout_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserWorkout_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserWorkout_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserWorkout_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UserWorkout_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['UserWorkout_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['UserWorkout_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UserWorkout_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['UserWorkout_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['UserWorkout_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UserWorkout_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_avg_fields'] = ResolversParentTypes['UserWorkout_avg_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_max_fields'] = ResolversParentTypes['UserWorkout_max_fields']> = ResolversObject<{
  caloric_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lookup_reference_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  off_screen_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pauses?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recommendation_log_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  thumbnail_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_history_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_pear_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_pear_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_min_fields'] = ResolversParentTypes['UserWorkout_min_fields']> = ResolversObject<{
  caloric_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lookup_reference_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  off_screen_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pauses?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recommendation_log_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  thumbnail_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_history_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workoutId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_pear_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_pear_sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone_data?: Resolver<Maybe<Array<ResolversTypes['jsonb']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_mutation_response'] = ResolversParentTypes['UserWorkout_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserWorkout']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_stddev_fields'] = ResolversParentTypes['UserWorkout_stddev_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_stddev_pop_fields'] = ResolversParentTypes['UserWorkout_stddev_pop_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_stddev_samp_fields'] = ResolversParentTypes['UserWorkout_stddev_samp_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_sum_fields'] = ResolversParentTypes['UserWorkout_sum_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_var_pop_fields'] = ResolversParentTypes['UserWorkout_var_pop_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_var_samp_fields'] = ResolversParentTypes['UserWorkout_var_samp_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWorkout_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWorkout_variance_fields'] = ResolversParentTypes['UserWorkout_variance_fields']> = ResolversObject<{
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories_by_lookup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration_seen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_good_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  num_perfect_to_advance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rpe?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stressRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutRating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workout_streak?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_aggregate'] = ResolversParentTypes['User_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['User_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_aggregate_fields'] = ResolversParentTypes['User_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['User_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<User_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['User_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['User_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['User_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['User_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['User_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['User_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['User_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['User_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['User_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_avg_fields'] = ResolversParentTypes['User_avg_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_max_fields'] = ResolversParentTypes['User_max_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  app_download_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  auth0UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  cardio_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fitness_goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  household_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mastery_prestige_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mostImportantFitnessGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearSessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearTokenExpiration?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  pearUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preferredWorkouts?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  radioPreferences?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  referredId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ringId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringMacAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  screen_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signal_color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strapColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strap_location?: Resolver<Maybe<ResolversTypes['StrapLocation']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swabId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weightUnit?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workin_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  workin_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workoutFrequency?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_min_fields'] = ResolversParentTypes['User_min_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  app_download_date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  auth0UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  biostrapName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  cardio_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coach_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fitness_goal_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  household_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mastery_prestige_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mostImportantFitnessGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearRefreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearSessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pearTokenExpiration?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  pearUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preferredWorkouts?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  profileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  radioPreferences?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  referredId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ringId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringMacAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  screen_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screen_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signal_color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strapColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strap_location?: Resolver<Maybe<ResolversTypes['StrapLocation']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  subscription_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swabId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingPlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weightUnit?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workin_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  workin_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workoutFrequency?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_mutation_response'] = ResolversParentTypes['User_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_stddev_fields'] = ResolversParentTypes['User_stddev_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_stddev_pop_fields'] = ResolversParentTypes['User_stddev_pop_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_stddev_samp_fields'] = ResolversParentTypes['User_stddev_samp_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_sum_fields'] = ResolversParentTypes['User_sum_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_var_pop_fields'] = ResolversParentTypes['User_var_pop_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_var_samp_fields'] = ResolversParentTypes['User_var_samp_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type User_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_variance_fields'] = ResolversParentTypes['User_variance_fields']> = ResolversObject<{
  activityLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  days_signal_worn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reimbursementTokens?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strength_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workin_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValLabelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValLabels'] = ResolversParentTypes['ValLabels']> = ResolversObject<{
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  val?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValueAndTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValueAndTime'] = ResolversParentTypes['ValueAndTime']> = ResolversObject<{
  _time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValueTimeAndAverageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValueTimeAndAverage'] = ResolversParentTypes['ValueTimeAndAverage']> = ResolversObject<{
  _avg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValueTimeAndMinimumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValueTimeAndMinimum'] = ResolversParentTypes['ValueTimeAndMinimum']> = ResolversObject<{
  _min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VendorErrorLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorErrorLog'] = ResolversParentTypes['VendorErrorLog']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  errorDetails?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<VendorErrorLogErrorDetailsArgs>>;
  errorMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendorType?: Resolver<Maybe<ResolversTypes['VendorType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VendorErrorLog_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorErrorLog_aggregate'] = ResolversParentTypes['VendorErrorLog_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['VendorErrorLog_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['VendorErrorLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VendorErrorLog_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorErrorLog_aggregate_fields'] = ResolversParentTypes['VendorErrorLog_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<VendorErrorLog_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['VendorErrorLog_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['VendorErrorLog_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VendorErrorLog_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorErrorLog_max_fields'] = ResolversParentTypes['VendorErrorLog_max_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorType?: Resolver<Maybe<ResolversTypes['VendorType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VendorErrorLog_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorErrorLog_min_fields'] = ResolversParentTypes['VendorErrorLog_min_fields']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorType?: Resolver<Maybe<ResolversTypes['VendorType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VendorErrorLog_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorErrorLog_mutation_response'] = ResolversParentTypes['VendorErrorLog_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['VendorErrorLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface VendorTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['VendorType'], any> {
  name: 'VendorType';
}

export type VersionControlResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl'] = ResolversParentTypes['VersionControl']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobile_os?: Resolver<ResolversTypes['DeviceType'], ParentType, ContextType>;
  mobile_version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  screen_version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_aggregate'] = ResolversParentTypes['VersionControl_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['VersionControl_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['VersionControl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_aggregate_fields'] = ResolversParentTypes['VersionControl_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['VersionControl_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<VersionControl_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['VersionControl_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['VersionControl_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['VersionControl_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['VersionControl_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['VersionControl_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['VersionControl_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['VersionControl_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['VersionControl_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['VersionControl_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_avg_fields'] = ResolversParentTypes['VersionControl_avg_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_max_fields'] = ResolversParentTypes['VersionControl_max_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile_os?: Resolver<Maybe<ResolversTypes['DeviceType']>, ParentType, ContextType>;
  mobile_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_min_fields'] = ResolversParentTypes['VersionControl_min_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile_os?: Resolver<Maybe<ResolversTypes['DeviceType']>, ParentType, ContextType>;
  mobile_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_mutation_response'] = ResolversParentTypes['VersionControl_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['VersionControl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_stddev_fields'] = ResolversParentTypes['VersionControl_stddev_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_stddev_pop_fields'] = ResolversParentTypes['VersionControl_stddev_pop_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_stddev_samp_fields'] = ResolversParentTypes['VersionControl_stddev_samp_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_sum_fields'] = ResolversParentTypes['VersionControl_sum_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_var_pop_fields'] = ResolversParentTypes['VersionControl_var_pop_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_var_samp_fields'] = ResolversParentTypes['VersionControl_var_samp_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VersionControl_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionControl_variance_fields'] = ResolversParentTypes['VersionControl_variance_fields']> = ResolversObject<{
  mobile_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  screen_version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan'] = ResolversParentTypes['WeeklyPlan']> = ResolversObject<{
  PlanDays?: Resolver<Array<ResolversTypes['PlanDay']>, ParentType, ContextType, Partial<WeeklyPlanPlanDaysArgs>>;
  PlanDays_aggregate?: Resolver<ResolversTypes['PlanDay_aggregate'], ParentType, ContextType, Partial<WeeklyPlanPlanDays_AggregateArgs>>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  cardio_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coached_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  end_date?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pear_plan?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<WeeklyPlanPear_PlanArgs>>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_date?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  week?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_aggregate'] = ResolversParentTypes['WeeklyPlan_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['WeeklyPlan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['WeeklyPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_aggregate_fields'] = ResolversParentTypes['WeeklyPlan_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['WeeklyPlan_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<WeeklyPlan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['WeeklyPlan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['WeeklyPlan_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['WeeklyPlan_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['WeeklyPlan_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['WeeklyPlan_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['WeeklyPlan_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['WeeklyPlan_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['WeeklyPlan_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['WeeklyPlan_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_avg_fields'] = ResolversParentTypes['WeeklyPlan_avg_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_max_fields'] = ResolversParentTypes['WeeklyPlan_max_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  cardio_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coached_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_min_fields'] = ResolversParentTypes['WeeklyPlan_min_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_fitness?: Resolver<Maybe<ResolversTypes['GeneralRank']>, ParentType, ContextType>;
  cardio_fitness_group?: Resolver<Maybe<ResolversTypes['GeneralRankGroup']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coached_plan_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_mutation_response'] = ResolversParentTypes['WeeklyPlan_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['WeeklyPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_stddev_fields'] = ResolversParentTypes['WeeklyPlan_stddev_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_stddev_pop_fields'] = ResolversParentTypes['WeeklyPlan_stddev_pop_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_stddev_samp_fields'] = ResolversParentTypes['WeeklyPlan_stddev_samp_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_sum_fields'] = ResolversParentTypes['WeeklyPlan_sum_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_var_pop_fields'] = ResolversParentTypes['WeeklyPlan_var_pop_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_var_samp_fields'] = ResolversParentTypes['WeeklyPlan_var_samp_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyPlan_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklyPlan_variance_fields'] = ResolversParentTypes['WeeklyPlan_variance_fields']> = ResolversObject<{
  brace_prone_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_side_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brace_supine_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardio_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hinge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lunge_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pull_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_horizontal_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  push_vertical_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  squat_level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  v02_max_raw?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkIoWithEpochResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkIOWithEpoch'] = ResolversParentTypes['WorkIOWithEpoch']> = ResolversObject<{
  completedEpoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrence'] = ResolversParentTypes['WorkinPrefrence']> = ResolversObject<{
  UserPreference?: Resolver<ResolversTypes['UserPreferences'], ParentType, ContextType>;
  breath_work?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meditation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sound_bath?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  yoga?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrenceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrenceOutput'] = ResolversParentTypes['WorkinPrefrenceOutput']> = ResolversObject<{
  breath_work?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meditation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sound_bath?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yoga?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrence_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrence_aggregate'] = ResolversParentTypes['WorkinPrefrence_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['WorkinPrefrence_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrence_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrence_aggregate_fields'] = ResolversParentTypes['WorkinPrefrence_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<WorkinPrefrence_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['WorkinPrefrence_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['WorkinPrefrence_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrence_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrence_max_fields'] = ResolversParentTypes['WorkinPrefrence_max_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrence_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrence_min_fields'] = ResolversParentTypes['WorkinPrefrence_min_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkinPrefrence_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkinPrefrence_mutation_response'] = ResolversParentTypes['WorkinPrefrence_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout'] = ResolversParentTypes['Workout']> = ResolversObject<{
  Pro?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType>;
  ProId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicable_levels?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  extended_content?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<WorkoutExtended_ContentArgs>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdaptive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDemo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  previewUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<ResolversTypes['SubWorkoutType'], ParentType, ContextType>;
  tag?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  themes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userWorkoutsByWorkoutid?: Resolver<Array<ResolversTypes['UserWorkout']>, ParentType, ContextType, Partial<WorkoutUserWorkoutsByWorkoutidArgs>>;
  userWorkoutsByWorkoutid_aggregate?: Resolver<ResolversTypes['UserWorkout_aggregate'], ParentType, ContextType, Partial<WorkoutUserWorkoutsByWorkoutid_AggregateArgs>>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<ResolversTypes['WorkoutType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface WorkoutCategoryScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['WorkoutCategory'], any> {
  name: 'WorkoutCategory';
}

export type WorkoutPrefrenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrence'] = ResolversParentTypes['WorkoutPrefrence']> = ResolversObject<{
  UserPreference?: Resolver<ResolversTypes['UserPreferences'], ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  yoga?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutPrefrenceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrenceOutput'] = ResolversParentTypes['WorkoutPrefrenceOutput']> = ResolversObject<{
  combat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yoga?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutPrefrence_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrence_aggregate'] = ResolversParentTypes['WorkoutPrefrence_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutPrefrence_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrence_aggregate_fields'] = ResolversParentTypes['WorkoutPrefrence_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<WorkoutPrefrence_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutPrefrence_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrence_max_fields'] = ResolversParentTypes['WorkoutPrefrence_max_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutPrefrence_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrence_min_fields'] = ResolversParentTypes['WorkoutPrefrence_min_fields']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_prefrence_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkoutPrefrence_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkoutPrefrence_mutation_response'] = ResolversParentTypes['WorkoutPrefrence_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface WorkoutTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['WorkoutType'], any> {
  name: 'WorkoutType';
}

export type Workout_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_aggregate'] = ResolversParentTypes['Workout_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['Workout_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Workout']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_aggregate_fields'] = ResolversParentTypes['Workout_aggregate_fields']> = ResolversObject<{
  avg?: Resolver<Maybe<ResolversTypes['Workout_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Workout_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Workout_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Workout_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Workout_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Workout_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Workout_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Workout_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Workout_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Workout_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Workout_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_avg_fields'] = ResolversParentTypes['Workout_avg_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_max_fields'] = ResolversParentTypes['Workout_max_fields']> = ResolversObject<{
  ProId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicable_levels?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  previewUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  themes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_min_fields'] = ResolversParentTypes['Workout_min_fields']> = ResolversObject<{
  ProId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicable_levels?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  limitations?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  previewUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['SubWorkoutType']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  themes?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_mutation_response'] = ResolversParentTypes['Workout_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Workout']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_stddev_fields'] = ResolversParentTypes['Workout_stddev_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_stddev_pop_fields'] = ResolversParentTypes['Workout_stddev_pop_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_stddev_samp_fields'] = ResolversParentTypes['Workout_stddev_samp_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_sum_fields'] = ResolversParentTypes['Workout_sum_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_var_pop_fields'] = ResolversParentTypes['Workout_var_pop_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_var_samp_fields'] = ResolversParentTypes['Workout_var_samp_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Workout_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workout_variance_fields'] = ResolversParentTypes['Workout_variance_fields']> = ResolversObject<{
  duration_minutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preferredWorkoutLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  video_duration_seconds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _FollowingProResolvers<ContextType = any, ParentType extends ResolversParentTypes['_FollowingPro'] = ResolversParentTypes['_FollowingPro']> = ResolversObject<{
  A?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  B?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Pro?: Resolver<ResolversTypes['Pro'], ParentType, ContextType>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _FollowingPro_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['_FollowingPro_aggregate'] = ResolversParentTypes['_FollowingPro_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['_FollowingPro_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['_FollowingPro']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _FollowingPro_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['_FollowingPro_aggregate_fields'] = ResolversParentTypes['_FollowingPro_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<_FollowingPro_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['_FollowingPro_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['_FollowingPro_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _FollowingPro_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['_FollowingPro_max_fields'] = ResolversParentTypes['_FollowingPro_max_fields']> = ResolversObject<{
  A?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  B?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _FollowingPro_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['_FollowingPro_min_fields'] = ResolversParentTypes['_FollowingPro_min_fields']> = ResolversObject<{
  A?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  B?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _FollowingPro_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['_FollowingPro_mutation_response'] = ResolversParentTypes['_FollowingPro_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['_FollowingPro']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _GoalToUserPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['_GoalToUserPreferences'] = ResolversParentTypes['_GoalToUserPreferences']> = ResolversObject<{
  A?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  B?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Goal?: Resolver<ResolversTypes['Goal'], ParentType, ContextType>;
  UserPreference?: Resolver<ResolversTypes['UserPreferences'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _GoalToUserPreferences_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['_GoalToUserPreferences_aggregate'] = ResolversParentTypes['_GoalToUserPreferences_aggregate']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['_GoalToUserPreferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _GoalToUserPreferences_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['_GoalToUserPreferences_aggregate_fields'] = ResolversParentTypes['_GoalToUserPreferences_aggregate_fields']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<_GoalToUserPreferences_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _GoalToUserPreferences_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['_GoalToUserPreferences_max_fields'] = ResolversParentTypes['_GoalToUserPreferences_max_fields']> = ResolversObject<{
  A?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  B?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _GoalToUserPreferences_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['_GoalToUserPreferences_min_fields'] = ResolversParentTypes['_GoalToUserPreferences_min_fields']> = ResolversObject<{
  A?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  B?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _GoalToUserPreferences_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['_GoalToUserPreferences_mutation_response'] = ResolversParentTypes['_GoalToUserPreferences_mutation_response']> = ResolversObject<{
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['_GoalToUserPreferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export type BioStrapAssignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['bioStrapAssignment'] = ResolversParentTypes['bioStrapAssignment']> = ResolversObject<{
  biostrapId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateManyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['createManyOutput'] = ResolversParentTypes['createManyOutput']> = ResolversObject<{
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export type FetchActivityAveragesResolvers<ContextType = any, ParentType extends ResolversParentTypes['fetchActivityAverages'] = ResolversParentTypes['fetchActivityAverages']> = ResolversObject<{
  active_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  net_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchActivityResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['fetchActivityResult'] = ResolversParentTypes['fetchActivityResult']> = ResolversObject<{
  averages?: Resolver<Maybe<ResolversTypes['fetchActivityAverages']>, ParentType, ContextType>;
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  totals?: Resolver<Maybe<ResolversTypes['fetchActivityTotals']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchActivityTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['fetchActivityTotals'] = ResolversParentTypes['fetchActivityTotals']> = ResolversObject<{
  active_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bmr_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  net_cal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchFeatureFlagsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['fetchFeatureFlagsOutput'] = ResolversParentTypes['fetchFeatureFlagsOutput']> = ResolversObject<{
  flags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchHabitsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['fetchHabitsOutput'] = ResolversParentTypes['fetchHabitsOutput']> = ResolversObject<{
  days?: Resolver<Maybe<Array<Maybe<ResolversTypes['HabitDayOutput']>>>, ParentType, ContextType>;
  days_required?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  days_required_last_week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchPlansOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['fetchPlansOutput'] = ResolversParentTypes['fetchPlansOutput']> = ResolversObject<{
  completed_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_days?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Float8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['float8'], any> {
  name: 'float8';
}

export type GenerateSignedUrlResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['generateSignedUrlResponse'] = ResolversParentTypes['generateSignedUrlResponse']> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GenericListOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['genericListOutput'] = ResolversParentTypes['genericListOutput']> = ResolversObject<{
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetSfCoachIdOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['getSFCoachIdOutput'] = ResolversParentTypes['getSFCoachIdOutput']> = ResolversObject<{
  sendbird_access_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sf_coach_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetUserByAuth0IdOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['getUserByAuth0IdOutput'] = ResolversParentTypes['getUserByAuth0IdOutput']> = ResolversObject<{
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IdObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['idObj'] = ResolversParentTypes['idObj']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type MessageOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageOutput'] = ResolversParentTypes['messageOutput']> = ResolversObject<{
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MobileVersionsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['mobileVersionsOutput'] = ResolversParentTypes['mobileVersionsOutput']> = ResolversObject<{
  androidBuild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  androidVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iOSBuild?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  iOSVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Mutation_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = ResolversObject<{
  acknowledgeCoachAccess?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootAcknowledgeCoachAccessArgs, 'userId'>>;
  acknowledgeFitnessAndNutrition?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootAcknowledgeFitnessAndNutritionArgs, 'userId'>>;
  acknowledgeScreenInstallation?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootAcknowledgeScreenInstallationArgs, 'userId'>>;
  activateSubscription?: Resolver<ResolversTypes['json'], ParentType, ContextType, Partial<Mutation_RootActivateSubscriptionArgs>>;
  addFirmware?: Resolver<Maybe<ResolversTypes['UploadContents']>, ParentType, ContextType, RequireFields<Mutation_RootAddFirmwareArgs, 'activate' | 'device' | 'fileType' | 'location' | 'version'>>;
  addOrRemoveFavoriteWorkout?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootAddOrRemoveFavoriteWorkoutArgs, 'user_id' | 'workout_id'>>;
  addUserToHousehold?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootAddUserToHouseholdArgs, 'email'>>;
  assignBiostrap?: Resolver<Maybe<ResolversTypes['bioStrapAssignment']>, ParentType, ContextType, RequireFields<Mutation_RootAssignBiostrapArgs, 'biostrapId'>>;
  assignScreen?: Resolver<Maybe<ResolversTypes['SampleScreenOutput']>, ParentType, ContextType, Partial<Mutation_RootAssignScreenArgs>>;
  calculateActivityMetrics?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootCalculateActivityMetricsArgs, 'midnight' | 'userId'>>;
  calculateMacroGrams?: Resolver<Maybe<ResolversTypes['CalculateMacroGramsOutput']>, ParentType, ContextType, RequireFields<Mutation_RootCalculateMacroGramsArgs, 'goalWeight' | 'nutrientsCategory'>>;
  calculateWorkoutZones?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootCalculateWorkoutZonesArgs, 'workoutId'>>;
  cancelSubscription?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  changeCoachedPlan?: Resolver<Maybe<ResolversTypes['ChangeCoachedPlanOutput']>, ParentType, ContextType, RequireFields<Mutation_RootChangeCoachedPlanArgs, 'user_id'>>;
  changeGoal?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootChangeGoalArgs, 'fitness_goal_id' | 'user_id'>>;
  changeProductVariant?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootChangeProductVariantArgs, 'shopify_variant_id'>>;
  confirmAndCreatePresetPlan?: Resolver<Maybe<ResolversTypes['ResultObjectOutput']>, ParentType, ContextType, RequireFields<Mutation_RootConfirmAndCreatePresetPlanArgs, 'plan_structure_id' | 'plan_type' | 'title'>>;
  confirmAndUpdatePresetPlan?: Resolver<Maybe<ResolversTypes['ResultObjectOutput']>, ParentType, ContextType, RequireFields<Mutation_RootConfirmAndUpdatePresetPlanArgs, 'id' | 'plan_structure_id'>>;
  createCoachedHabit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<Mutation_RootCreateCoachedHabitArgs, 'coach_id' | 'habit_type' | 'sub_type' | 'user_id'>>;
  createCoachedNutrition?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootCreateCoachedNutritionArgs, 'coach_id' | 'user_id'>>;
  createFirmwareContentV2?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, Partial<Mutation_RootCreateFirmwareContentV2Args>>;
  createNutritionReport?: Resolver<Maybe<ResolversTypes['NutritionReportOutput']>, ParentType, ContextType, RequireFields<Mutation_RootCreateNutritionReportArgs, 'userId'>>;
  dailyCheckin?: Resolver<Maybe<ResolversTypes['DailyCheckinOutput']>, ParentType, ContextType, RequireFields<Mutation_RootDailyCheckinArgs, 'userId'>>;
  dailyCheckinV2?: Resolver<Maybe<ResolversTypes['DailyCheckinV2Output']>, ParentType, ContextType, RequireFields<Mutation_RootDailyCheckinV2Args, 'userId'>>;
  deleteAccount?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  deleteFirmwareContentV2?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootDeleteFirmwareContentV2Args, 'firmware_id'>>;
  deleteOneUser?: Resolver<Maybe<ResolversTypes['AllUsersOutput']>, ParentType, ContextType, RequireFields<Mutation_RootDeleteOneUserArgs, 'id'>>;
  delete_ActivationCode?: Resolver<Maybe<ResolversTypes['ActivationCode_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ActivationCodeArgs, 'where'>>;
  delete_ActivationCode_by_pk?: Resolver<Maybe<ResolversTypes['ActivationCode']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ActivationCode_By_PkArgs, 'id'>>;
  delete_AlterTypeColor?: Resolver<Maybe<ResolversTypes['AlterTypeColor_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_AlterTypeColorArgs, 'where'>>;
  delete_AlterTypeColor_by_pk?: Resolver<Maybe<ResolversTypes['AlterTypeColor']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_AlterTypeColor_By_PkArgs, 'letter'>>;
  delete_Coach?: Resolver<Maybe<ResolversTypes['Coach_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CoachArgs, 'where'>>;
  delete_Coach_by_pk?: Resolver<Maybe<ResolversTypes['Coach']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Coach_By_PkArgs, 'id'>>;
  delete_CoachedHabit?: Resolver<Maybe<ResolversTypes['CoachedHabit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CoachedHabitArgs, 'where'>>;
  delete_CoachedHabit_by_pk?: Resolver<Maybe<ResolversTypes['CoachedHabit']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CoachedHabit_By_PkArgs, 'id'>>;
  delete_CoachedNutrition?: Resolver<Maybe<ResolversTypes['CoachedNutrition_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CoachedNutritionArgs, 'where'>>;
  delete_CoachedNutrition_by_pk?: Resolver<Maybe<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CoachedNutrition_By_PkArgs, 'id'>>;
  delete_DNAKit?: Resolver<Maybe<ResolversTypes['DNAKit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DnaKitArgs, 'where'>>;
  delete_DNAKit_by_pk?: Resolver<Maybe<ResolversTypes['DNAKit']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DnaKit_By_PkArgs, 'id'>>;
  delete_DNAResults?: Resolver<Maybe<ResolversTypes['DNAResults_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DnaResultsArgs, 'where'>>;
  delete_DNAResults_by_pk?: Resolver<Maybe<ResolversTypes['DNAResults']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DnaResults_By_PkArgs, 'id'>>;
  delete_Delivery?: Resolver<Maybe<ResolversTypes['Delivery_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DeliveryArgs, 'where'>>;
  delete_DeliveryHistory?: Resolver<Maybe<ResolversTypes['DeliveryHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DeliveryHistoryArgs, 'where'>>;
  delete_DeliveryHistory_by_pk?: Resolver<Maybe<ResolversTypes['DeliveryHistory']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DeliveryHistory_By_PkArgs, 'id'>>;
  delete_Delivery_by_pk?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Delivery_By_PkArgs, 'id'>>;
  delete_Device?: Resolver<Maybe<ResolversTypes['Device_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DeviceArgs, 'where'>>;
  delete_Device_by_pk?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Device_By_PkArgs, 'id'>>;
  delete_Diagnostics?: Resolver<Maybe<ResolversTypes['Diagnostics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DiagnosticsArgs, 'where'>>;
  delete_Diagnostics_by_pk?: Resolver<Maybe<ResolversTypes['Diagnostics']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Diagnostics_By_PkArgs, 'id'>>;
  delete_FinancePlan?: Resolver<Maybe<ResolversTypes['FinancePlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FinancePlanArgs, 'where'>>;
  delete_FinancePlan_by_pk?: Resolver<Maybe<ResolversTypes['FinancePlan']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FinancePlan_By_PkArgs, 'id'>>;
  delete_FirmwareContent?: Resolver<Maybe<ResolversTypes['FirmwareContent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FirmwareContentArgs, 'where'>>;
  delete_FirmwareContent_by_pk?: Resolver<Maybe<ResolversTypes['FirmwareContent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FirmwareContent_By_PkArgs, 'id'>>;
  delete_GeneticDirectionWebhook?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_GeneticDirectionWebhookArgs, 'where'>>;
  delete_GeneticDirectionWebhook_by_pk?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_GeneticDirectionWebhook_By_PkArgs, 'id'>>;
  delete_GlobalFeatureControl?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_GlobalFeatureControlArgs, 'where'>>;
  delete_GlobalFeatureControl_by_pk?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_GlobalFeatureControl_By_PkArgs, 'id'>>;
  delete_Goal?: Resolver<Maybe<ResolversTypes['Goal_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_GoalArgs, 'where'>>;
  delete_Goal_by_pk?: Resolver<Maybe<ResolversTypes['Goal']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Goal_By_PkArgs, 'id'>>;
  delete_HabitDay?: Resolver<Maybe<ResolversTypes['HabitDay_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_HabitDayArgs, 'where'>>;
  delete_HabitDay_by_pk?: Resolver<Maybe<ResolversTypes['HabitDay']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_HabitDay_By_PkArgs, 'id'>>;
  delete_HeartRateDiagnostics?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_HeartRateDiagnosticsArgs, 'where'>>;
  delete_HeartRateDiagnostics_by_pk?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_HeartRateDiagnostics_By_PkArgs, 'id'>>;
  delete_Household?: Resolver<Maybe<ResolversTypes['Household_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_HouseholdArgs, 'where'>>;
  delete_Household_by_pk?: Resolver<Maybe<ResolversTypes['Household']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Household_By_PkArgs, 'id'>>;
  delete_MarketingEmail?: Resolver<Maybe<ResolversTypes['MarketingEmail_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_MarketingEmailArgs, 'where'>>;
  delete_MarketingEmail_by_pk?: Resolver<Maybe<ResolversTypes['MarketingEmail']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_MarketingEmail_By_PkArgs, 'id'>>;
  delete_Mastery?: Resolver<Maybe<ResolversTypes['Mastery_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_MasteryArgs, 'where'>>;
  delete_Mastery_by_pk?: Resolver<Maybe<ResolversTypes['Mastery']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Mastery_By_PkArgs, 'id'>>;
  delete_OnboardingInfo?: Resolver<Maybe<ResolversTypes['OnboardingInfo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OnboardingInfoArgs, 'where'>>;
  delete_OnboardingInfo_by_pk?: Resolver<Maybe<ResolversTypes['OnboardingInfo']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OnboardingInfo_By_PkArgs, 'id'>>;
  delete_OnboardingVideos?: Resolver<Maybe<ResolversTypes['OnboardingVideos_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OnboardingVideosArgs, 'where'>>;
  delete_OnboardingVideos_by_pk?: Resolver<Maybe<ResolversTypes['OnboardingVideos']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OnboardingVideos_By_PkArgs, 'id'>>;
  delete_Order?: Resolver<Maybe<ResolversTypes['Order_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OrderArgs, 'where'>>;
  delete_OrderLineItem?: Resolver<Maybe<ResolversTypes['OrderLineItem_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OrderLineItemArgs, 'where'>>;
  delete_OrderLineItem_by_pk?: Resolver<Maybe<ResolversTypes['OrderLineItem']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OrderLineItem_By_PkArgs, 'id'>>;
  delete_Order_by_pk?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Order_By_PkArgs, 'id'>>;
  delete_PaymentHistory?: Resolver<Maybe<ResolversTypes['PaymentHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PaymentHistoryArgs, 'where'>>;
  delete_PaymentHistory_by_pk?: Resolver<Maybe<ResolversTypes['PaymentHistory']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PaymentHistory_By_PkArgs, 'id'>>;
  delete_PlanDay?: Resolver<Maybe<ResolversTypes['PlanDay_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PlanDayArgs, 'where'>>;
  delete_PlanDay_by_pk?: Resolver<Maybe<ResolversTypes['PlanDay']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PlanDay_By_PkArgs, 'id'>>;
  delete_PresetDiet?: Resolver<Maybe<ResolversTypes['PresetDiet_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PresetDietArgs, 'where'>>;
  delete_PresetDiet_by_pk?: Resolver<Maybe<ResolversTypes['PresetDiet']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PresetDiet_By_PkArgs, 'id'>>;
  delete_Pro?: Resolver<Maybe<ResolversTypes['Pro_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProArgs, 'where'>>;
  delete_ProMusic?: Resolver<Maybe<ResolversTypes['ProMusic_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProMusicArgs, 'where'>>;
  delete_ProMusic_by_pk?: Resolver<Maybe<ResolversTypes['ProMusic']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProMusic_By_PkArgs, 'id'>>;
  delete_Pro_by_pk?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Pro_By_PkArgs, 'id'>>;
  delete_Product?: Resolver<Maybe<ResolversTypes['Product_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProductArgs, 'where'>>;
  delete_ProductLog?: Resolver<Maybe<ResolversTypes['ProductLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProductLogArgs, 'where'>>;
  delete_ProductLog_by_pk?: Resolver<Maybe<ResolversTypes['ProductLog']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ProductLog_By_PkArgs, 'id'>>;
  delete_Product_by_pk?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Product_By_PkArgs, 'id'>>;
  delete_PromotionalItem?: Resolver<Maybe<ResolversTypes['PromotionalItem_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PromotionalItemArgs, 'where'>>;
  delete_PromotionalItem_by_pk?: Resolver<Maybe<ResolversTypes['PromotionalItem']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_PromotionalItem_By_PkArgs, 'id'>>;
  delete_QuizSurvey?: Resolver<Maybe<ResolversTypes['QuizSurvey_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_QuizSurveyArgs, 'where'>>;
  delete_QuizSurvey_by_pk?: Resolver<Maybe<ResolversTypes['QuizSurvey']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_QuizSurvey_By_PkArgs, 'id'>>;
  delete_Quote?: Resolver<Maybe<ResolversTypes['Quote_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_QuoteArgs, 'where'>>;
  delete_Quote_by_pk?: Resolver<Maybe<ResolversTypes['Quote']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Quote_By_PkArgs, 'id'>>;
  delete_ReimbursementRequirement?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ReimbursementRequirementArgs, 'where'>>;
  delete_ReimbursementRequirement_by_pk?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ReimbursementRequirement_By_PkArgs, 'id'>>;
  delete_ScreenOrder?: Resolver<Maybe<ResolversTypes['ScreenOrder_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ScreenOrderArgs, 'where'>>;
  delete_ScreenOrder_by_pk?: Resolver<Maybe<ResolversTypes['ScreenOrder']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ScreenOrder_By_PkArgs, 'id'>>;
  delete_SearchResult?: Resolver<Maybe<ResolversTypes['SearchResult_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SearchResultArgs, 'where'>>;
  delete_SearchResult_by_pk?: Resolver<Maybe<ResolversTypes['SearchResult']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SearchResult_By_PkArgs, 'id'>>;
  delete_SleepSurvey?: Resolver<Maybe<ResolversTypes['SleepSurvey_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SleepSurveyArgs, 'where'>>;
  delete_SleepSurvey_by_pk?: Resolver<Maybe<ResolversTypes['SleepSurvey']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SleepSurvey_By_PkArgs, 'id'>>;
  delete_Subscription?: Resolver<Maybe<ResolversTypes['Subscription_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SubscriptionArgs, 'where'>>;
  delete_SubscriptionLog?: Resolver<Maybe<ResolversTypes['SubscriptionLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SubscriptionLogArgs, 'where'>>;
  delete_SubscriptionLog_by_pk?: Resolver<Maybe<ResolversTypes['SubscriptionLog']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_SubscriptionLog_By_PkArgs, 'id'>>;
  delete_Subscription_by_pk?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Subscription_By_PkArgs, 'id'>>;
  delete_Token?: Resolver<Maybe<ResolversTypes['Token_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TokenArgs, 'where'>>;
  delete_TokenHistory?: Resolver<Maybe<ResolversTypes['TokenHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TokenHistoryArgs, 'where'>>;
  delete_TokenHistory_by_pk?: Resolver<Maybe<ResolversTypes['TokenHistory']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TokenHistory_By_PkArgs, 'id'>>;
  delete_Token_by_pk?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Token_By_PkArgs, 'id'>>;
  delete_User?: Resolver<Maybe<ResolversTypes['User_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserArgs, 'where'>>;
  delete_UserAggregates?: Resolver<Maybe<ResolversTypes['UserAggregates_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserAggregatesArgs, 'where'>>;
  delete_UserAggregates_by_pk?: Resolver<Maybe<ResolversTypes['UserAggregates']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserAggregates_By_PkArgs, 'id'>>;
  delete_UserAssessment?: Resolver<Maybe<ResolversTypes['UserAssessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserAssessmentArgs, 'where'>>;
  delete_UserAssessment_by_pk?: Resolver<Maybe<ResolversTypes['UserAssessment']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserAssessment_By_PkArgs, 'id'>>;
  delete_UserFeatureControl?: Resolver<Maybe<ResolversTypes['UserFeatureControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserFeatureControlArgs, 'where'>>;
  delete_UserFeatureControl_by_pk?: Resolver<Maybe<ResolversTypes['UserFeatureControl']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserFeatureControl_By_PkArgs, 'id'>>;
  delete_UserFeedback?: Resolver<Maybe<ResolversTypes['UserFeedback_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserFeedbackArgs, 'where'>>;
  delete_UserFeedback_by_pk?: Resolver<Maybe<ResolversTypes['UserFeedback']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserFeedback_By_PkArgs, 'id'>>;
  delete_UserFinancePlan?: Resolver<Maybe<ResolversTypes['UserFinancePlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserFinancePlanArgs, 'where'>>;
  delete_UserFinancePlan_by_pk?: Resolver<Maybe<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserFinancePlan_By_PkArgs, 'id'>>;
  delete_UserHabit?: Resolver<Maybe<ResolversTypes['UserHabit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserHabitArgs, 'where'>>;
  delete_UserHabit_by_pk?: Resolver<Maybe<ResolversTypes['UserHabit']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserHabit_By_PkArgs, 'id'>>;
  delete_UserMovementDaily?: Resolver<Maybe<ResolversTypes['UserMovementDaily_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserMovementDailyArgs, 'where'>>;
  delete_UserMovementDaily_by_pk?: Resolver<Maybe<ResolversTypes['UserMovementDaily']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserMovementDaily_By_PkArgs, 'id'>>;
  delete_UserPreferences?: Resolver<Maybe<ResolversTypes['UserPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserPreferencesArgs, 'where'>>;
  delete_UserPreferences_by_pk?: Resolver<Maybe<ResolversTypes['UserPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserPreferences_By_PkArgs, 'id'>>;
  delete_UserProduct?: Resolver<Maybe<ResolversTypes['UserProduct_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserProductArgs, 'where'>>;
  delete_UserProductHistory?: Resolver<Maybe<ResolversTypes['UserProductHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserProductHistoryArgs, 'where'>>;
  delete_UserProductHistory_by_pk?: Resolver<Maybe<ResolversTypes['UserProductHistory']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserProductHistory_By_PkArgs, 'id'>>;
  delete_UserProduct_by_pk?: Resolver<Maybe<ResolversTypes['UserProduct']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserProduct_By_PkArgs, 'id'>>;
  delete_UserReadiness?: Resolver<Maybe<ResolversTypes['UserReadiness_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserReadinessArgs, 'where'>>;
  delete_UserReadinessLog?: Resolver<Maybe<ResolversTypes['UserReadinessLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserReadinessLogArgs, 'where'>>;
  delete_UserReadinessLog_by_pk?: Resolver<Maybe<ResolversTypes['UserReadinessLog']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserReadinessLog_By_PkArgs, 'id'>>;
  delete_UserReadiness_by_pk?: Resolver<Maybe<ResolversTypes['UserReadiness']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserReadiness_By_PkArgs, 'id'>>;
  delete_UserReimbursmentHistory?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserReimbursmentHistoryArgs, 'where'>>;
  delete_UserReimbursmentHistory_by_pk?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserReimbursmentHistory_By_PkArgs, 'id'>>;
  delete_UserRep?: Resolver<Maybe<ResolversTypes['UserRep_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserRepArgs, 'where'>>;
  delete_UserRep_by_pk?: Resolver<Maybe<ResolversTypes['UserRep']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserRep_By_PkArgs, 'id'>>;
  delete_UserSet?: Resolver<Maybe<ResolversTypes['UserSet_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserSetArgs, 'where'>>;
  delete_UserSet_by_pk?: Resolver<Maybe<ResolversTypes['UserSet']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserSet_By_PkArgs, 'id'>>;
  delete_UserState?: Resolver<Maybe<ResolversTypes['UserState_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserStateArgs, 'where'>>;
  delete_UserState_by_pk?: Resolver<Maybe<ResolversTypes['UserState']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserState_By_PkArgs, 'id'>>;
  delete_UserTrainingPlan?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserTrainingPlanArgs, 'where'>>;
  delete_UserTrainingPlan_by_pk?: Resolver<Maybe<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserTrainingPlan_By_PkArgs, 'id'>>;
  delete_UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserWorkoutArgs, 'where'>>;
  delete_UserWorkout_by_pk?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_UserWorkout_By_PkArgs, 'id'>>;
  delete_User_by_pk?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_User_By_PkArgs, 'id'>>;
  delete_VendorErrorLog?: Resolver<Maybe<ResolversTypes['VendorErrorLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_VendorErrorLogArgs, 'where'>>;
  delete_VendorErrorLog_by_pk?: Resolver<Maybe<ResolversTypes['VendorErrorLog']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_VendorErrorLog_By_PkArgs, 'id'>>;
  delete_VersionControl?: Resolver<Maybe<ResolversTypes['VersionControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_VersionControlArgs, 'where'>>;
  delete_VersionControl_by_pk?: Resolver<Maybe<ResolversTypes['VersionControl']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_VersionControl_By_PkArgs, 'id'>>;
  delete_WeeklyPlan?: Resolver<Maybe<ResolversTypes['WeeklyPlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WeeklyPlanArgs, 'where'>>;
  delete_WeeklyPlan_by_pk?: Resolver<Maybe<ResolversTypes['WeeklyPlan']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WeeklyPlan_By_PkArgs, 'id'>>;
  delete_WorkinPrefrence?: Resolver<Maybe<ResolversTypes['WorkinPrefrence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WorkinPrefrenceArgs, 'where'>>;
  delete_WorkinPrefrence_by_pk?: Resolver<Maybe<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WorkinPrefrence_By_PkArgs, 'id'>>;
  delete_Workout?: Resolver<Maybe<ResolversTypes['Workout_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WorkoutArgs, 'where'>>;
  delete_WorkoutPrefrence?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WorkoutPrefrenceArgs, 'where'>>;
  delete_WorkoutPrefrence_by_pk?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_WorkoutPrefrence_By_PkArgs, 'id'>>;
  delete_Workout_by_pk?: Resolver<Maybe<ResolversTypes['Workout']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Workout_By_PkArgs, 'id'>>;
  delete__FollowingPro?: Resolver<Maybe<ResolversTypes['_FollowingPro_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete__FollowingProArgs, 'where'>>;
  delete__GoalToUserPreferences?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete__GoalToUserPreferencesArgs, 'where'>>;
  endAssessment?: Resolver<Maybe<ResolversTypes['EndAssessmentOutput']>, ParentType, ContextType, RequireFields<Mutation_RootEndAssessmentArgs, 'assessment_id' | 'completed_epoch'>>;
  endWorkout?: Resolver<ResolversTypes['startWorkoutOutput'], ParentType, ContextType, RequireFields<Mutation_RootEndWorkoutArgs, 'completedAmount' | 'completedEpoch' | 'workout_id'>>;
  fetchHabits?: Resolver<Maybe<ResolversTypes['fetchHabitsOutput']>, ParentType, ContextType, RequireFields<Mutation_RootFetchHabitsArgs, 'user_id'>>;
  fetchMealPlanPreferences?: Resolver<Maybe<ResolversTypes['MealPlanPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootFetchMealPlanPreferencesArgs, 'user_id'>>;
  findOrCreateMealPlan?: Resolver<Maybe<ResolversTypes['MealPlanOutput']>, ParentType, ContextType, RequireFields<Mutation_RootFindOrCreateMealPlanArgs, 'user_id'>>;
  generateSignedUrl?: Resolver<Maybe<ResolversTypes['generateSignedUrlResponse']>, ParentType, ContextType, RequireFields<Mutation_RootGenerateSignedUrlArgs, 'key'>>;
  hasuraSync?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<Mutation_RootHasuraSyncArgs, 'email'>>;
  helloLocalHasura?: Resolver<Maybe<ResolversTypes['SampleOutput']>, ParentType, ContextType, RequireFields<Mutation_RootHelloLocalHasuraArgs, 'arg1'>>;
  insert_ActivationCode?: Resolver<Maybe<ResolversTypes['ActivationCode_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ActivationCodeArgs, 'objects'>>;
  insert_ActivationCode_one?: Resolver<Maybe<ResolversTypes['ActivationCode']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ActivationCode_OneArgs, 'object'>>;
  insert_AlterTypeColor?: Resolver<Maybe<ResolversTypes['AlterTypeColor_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_AlterTypeColorArgs, 'objects'>>;
  insert_AlterTypeColor_one?: Resolver<Maybe<ResolversTypes['AlterTypeColor']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_AlterTypeColor_OneArgs, 'object'>>;
  insert_Coach?: Resolver<Maybe<ResolversTypes['Coach_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CoachArgs, 'objects'>>;
  insert_Coach_one?: Resolver<Maybe<ResolversTypes['Coach']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Coach_OneArgs, 'object'>>;
  insert_CoachedHabit?: Resolver<Maybe<ResolversTypes['CoachedHabit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CoachedHabitArgs, 'objects'>>;
  insert_CoachedHabit_one?: Resolver<Maybe<ResolversTypes['CoachedHabit']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CoachedHabit_OneArgs, 'object'>>;
  insert_CoachedNutrition?: Resolver<Maybe<ResolversTypes['CoachedNutrition_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CoachedNutritionArgs, 'objects'>>;
  insert_CoachedNutrition_one?: Resolver<Maybe<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CoachedNutrition_OneArgs, 'object'>>;
  insert_DNAKit?: Resolver<Maybe<ResolversTypes['DNAKit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DnaKitArgs, 'objects'>>;
  insert_DNAKit_one?: Resolver<Maybe<ResolversTypes['DNAKit']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DnaKit_OneArgs, 'object'>>;
  insert_DNAResults?: Resolver<Maybe<ResolversTypes['DNAResults_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DnaResultsArgs, 'objects'>>;
  insert_DNAResults_one?: Resolver<Maybe<ResolversTypes['DNAResults']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DnaResults_OneArgs, 'object'>>;
  insert_Delivery?: Resolver<Maybe<ResolversTypes['Delivery_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DeliveryArgs, 'objects'>>;
  insert_DeliveryHistory?: Resolver<Maybe<ResolversTypes['DeliveryHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DeliveryHistoryArgs, 'objects'>>;
  insert_DeliveryHistory_one?: Resolver<Maybe<ResolversTypes['DeliveryHistory']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DeliveryHistory_OneArgs, 'object'>>;
  insert_Delivery_one?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Delivery_OneArgs, 'object'>>;
  insert_Device?: Resolver<Maybe<ResolversTypes['Device_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DeviceArgs, 'objects'>>;
  insert_Device_one?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Device_OneArgs, 'object'>>;
  insert_Diagnostics?: Resolver<Maybe<ResolversTypes['Diagnostics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DiagnosticsArgs, 'objects'>>;
  insert_Diagnostics_one?: Resolver<Maybe<ResolversTypes['Diagnostics']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Diagnostics_OneArgs, 'object'>>;
  insert_FinancePlan?: Resolver<Maybe<ResolversTypes['FinancePlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FinancePlanArgs, 'objects'>>;
  insert_FinancePlan_one?: Resolver<Maybe<ResolversTypes['FinancePlan']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FinancePlan_OneArgs, 'object'>>;
  insert_FirmwareContent?: Resolver<Maybe<ResolversTypes['FirmwareContent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FirmwareContentArgs, 'objects'>>;
  insert_FirmwareContent_one?: Resolver<Maybe<ResolversTypes['FirmwareContent']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FirmwareContent_OneArgs, 'object'>>;
  insert_GeneticDirectionWebhook?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_GeneticDirectionWebhookArgs, 'objects'>>;
  insert_GeneticDirectionWebhook_one?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_GeneticDirectionWebhook_OneArgs, 'object'>>;
  insert_GlobalFeatureControl?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_GlobalFeatureControlArgs, 'objects'>>;
  insert_GlobalFeatureControl_one?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_GlobalFeatureControl_OneArgs, 'object'>>;
  insert_Goal?: Resolver<Maybe<ResolversTypes['Goal_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_GoalArgs, 'objects'>>;
  insert_Goal_one?: Resolver<Maybe<ResolversTypes['Goal']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Goal_OneArgs, 'object'>>;
  insert_HabitDay?: Resolver<Maybe<ResolversTypes['HabitDay_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_HabitDayArgs, 'objects'>>;
  insert_HabitDay_one?: Resolver<Maybe<ResolversTypes['HabitDay']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_HabitDay_OneArgs, 'object'>>;
  insert_HeartRateDiagnostics?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_HeartRateDiagnosticsArgs, 'objects'>>;
  insert_HeartRateDiagnostics_one?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_HeartRateDiagnostics_OneArgs, 'object'>>;
  insert_Household?: Resolver<Maybe<ResolversTypes['Household_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_HouseholdArgs, 'objects'>>;
  insert_Household_one?: Resolver<Maybe<ResolversTypes['Household']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Household_OneArgs, 'object'>>;
  insert_MarketingEmail?: Resolver<Maybe<ResolversTypes['MarketingEmail_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_MarketingEmailArgs, 'objects'>>;
  insert_MarketingEmail_one?: Resolver<Maybe<ResolversTypes['MarketingEmail']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_MarketingEmail_OneArgs, 'object'>>;
  insert_Mastery?: Resolver<Maybe<ResolversTypes['Mastery_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_MasteryArgs, 'objects'>>;
  insert_Mastery_one?: Resolver<Maybe<ResolversTypes['Mastery']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Mastery_OneArgs, 'object'>>;
  insert_OnboardingInfo?: Resolver<Maybe<ResolversTypes['OnboardingInfo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OnboardingInfoArgs, 'objects'>>;
  insert_OnboardingInfo_one?: Resolver<Maybe<ResolversTypes['OnboardingInfo']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OnboardingInfo_OneArgs, 'object'>>;
  insert_OnboardingVideos?: Resolver<Maybe<ResolversTypes['OnboardingVideos_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OnboardingVideosArgs, 'objects'>>;
  insert_OnboardingVideos_one?: Resolver<Maybe<ResolversTypes['OnboardingVideos']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OnboardingVideos_OneArgs, 'object'>>;
  insert_Order?: Resolver<Maybe<ResolversTypes['Order_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OrderArgs, 'objects'>>;
  insert_OrderLineItem?: Resolver<Maybe<ResolversTypes['OrderLineItem_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OrderLineItemArgs, 'objects'>>;
  insert_OrderLineItem_one?: Resolver<Maybe<ResolversTypes['OrderLineItem']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OrderLineItem_OneArgs, 'object'>>;
  insert_Order_one?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Order_OneArgs, 'object'>>;
  insert_PaymentHistory?: Resolver<Maybe<ResolversTypes['PaymentHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PaymentHistoryArgs, 'objects'>>;
  insert_PaymentHistory_one?: Resolver<Maybe<ResolversTypes['PaymentHistory']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PaymentHistory_OneArgs, 'object'>>;
  insert_PlanDay?: Resolver<Maybe<ResolversTypes['PlanDay_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PlanDayArgs, 'objects'>>;
  insert_PlanDay_one?: Resolver<Maybe<ResolversTypes['PlanDay']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PlanDay_OneArgs, 'object'>>;
  insert_PresetDiet?: Resolver<Maybe<ResolversTypes['PresetDiet_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PresetDietArgs, 'objects'>>;
  insert_PresetDiet_one?: Resolver<Maybe<ResolversTypes['PresetDiet']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PresetDiet_OneArgs, 'object'>>;
  insert_Pro?: Resolver<Maybe<ResolversTypes['Pro_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProArgs, 'objects'>>;
  insert_ProMusic?: Resolver<Maybe<ResolversTypes['ProMusic_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProMusicArgs, 'objects'>>;
  insert_ProMusic_one?: Resolver<Maybe<ResolversTypes['ProMusic']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProMusic_OneArgs, 'object'>>;
  insert_Pro_one?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Pro_OneArgs, 'object'>>;
  insert_Product?: Resolver<Maybe<ResolversTypes['Product_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProductArgs, 'objects'>>;
  insert_ProductLog?: Resolver<Maybe<ResolversTypes['ProductLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProductLogArgs, 'objects'>>;
  insert_ProductLog_one?: Resolver<Maybe<ResolversTypes['ProductLog']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ProductLog_OneArgs, 'object'>>;
  insert_Product_one?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Product_OneArgs, 'object'>>;
  insert_PromotionalItem?: Resolver<Maybe<ResolversTypes['PromotionalItem_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PromotionalItemArgs, 'objects'>>;
  insert_PromotionalItem_one?: Resolver<Maybe<ResolversTypes['PromotionalItem']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_PromotionalItem_OneArgs, 'object'>>;
  insert_QuizSurvey?: Resolver<Maybe<ResolversTypes['QuizSurvey_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_QuizSurveyArgs, 'objects'>>;
  insert_QuizSurvey_one?: Resolver<Maybe<ResolversTypes['QuizSurvey']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_QuizSurvey_OneArgs, 'object'>>;
  insert_Quote?: Resolver<Maybe<ResolversTypes['Quote_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_QuoteArgs, 'objects'>>;
  insert_Quote_one?: Resolver<Maybe<ResolversTypes['Quote']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Quote_OneArgs, 'object'>>;
  insert_ReimbursementRequirement?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ReimbursementRequirementArgs, 'objects'>>;
  insert_ReimbursementRequirement_one?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ReimbursementRequirement_OneArgs, 'object'>>;
  insert_ScreenOrder?: Resolver<Maybe<ResolversTypes['ScreenOrder_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ScreenOrderArgs, 'objects'>>;
  insert_ScreenOrder_one?: Resolver<Maybe<ResolversTypes['ScreenOrder']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ScreenOrder_OneArgs, 'object'>>;
  insert_SearchResult?: Resolver<Maybe<ResolversTypes['SearchResult_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SearchResultArgs, 'objects'>>;
  insert_SearchResult_one?: Resolver<Maybe<ResolversTypes['SearchResult']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SearchResult_OneArgs, 'object'>>;
  insert_SleepSurvey?: Resolver<Maybe<ResolversTypes['SleepSurvey_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SleepSurveyArgs, 'objects'>>;
  insert_SleepSurvey_one?: Resolver<Maybe<ResolversTypes['SleepSurvey']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SleepSurvey_OneArgs, 'object'>>;
  insert_Subscription?: Resolver<Maybe<ResolversTypes['Subscription_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SubscriptionArgs, 'objects'>>;
  insert_SubscriptionLog?: Resolver<Maybe<ResolversTypes['SubscriptionLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SubscriptionLogArgs, 'objects'>>;
  insert_SubscriptionLog_one?: Resolver<Maybe<ResolversTypes['SubscriptionLog']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_SubscriptionLog_OneArgs, 'object'>>;
  insert_Subscription_one?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Subscription_OneArgs, 'object'>>;
  insert_Token?: Resolver<Maybe<ResolversTypes['Token_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TokenArgs, 'objects'>>;
  insert_TokenHistory?: Resolver<Maybe<ResolversTypes['TokenHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TokenHistoryArgs, 'objects'>>;
  insert_TokenHistory_one?: Resolver<Maybe<ResolversTypes['TokenHistory']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TokenHistory_OneArgs, 'object'>>;
  insert_Token_one?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Token_OneArgs, 'object'>>;
  insert_User?: Resolver<Maybe<ResolversTypes['User_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserArgs, 'objects'>>;
  insert_UserAggregates?: Resolver<Maybe<ResolversTypes['UserAggregates_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserAggregatesArgs, 'objects'>>;
  insert_UserAggregates_one?: Resolver<Maybe<ResolversTypes['UserAggregates']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserAggregates_OneArgs, 'object'>>;
  insert_UserAssessment?: Resolver<Maybe<ResolversTypes['UserAssessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserAssessmentArgs, 'objects'>>;
  insert_UserAssessment_one?: Resolver<Maybe<ResolversTypes['UserAssessment']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserAssessment_OneArgs, 'object'>>;
  insert_UserFeatureControl?: Resolver<Maybe<ResolversTypes['UserFeatureControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserFeatureControlArgs, 'objects'>>;
  insert_UserFeatureControl_one?: Resolver<Maybe<ResolversTypes['UserFeatureControl']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserFeatureControl_OneArgs, 'object'>>;
  insert_UserFeedback?: Resolver<Maybe<ResolversTypes['UserFeedback_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserFeedbackArgs, 'objects'>>;
  insert_UserFeedback_one?: Resolver<Maybe<ResolversTypes['UserFeedback']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserFeedback_OneArgs, 'object'>>;
  insert_UserFinancePlan?: Resolver<Maybe<ResolversTypes['UserFinancePlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserFinancePlanArgs, 'objects'>>;
  insert_UserFinancePlan_one?: Resolver<Maybe<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserFinancePlan_OneArgs, 'object'>>;
  insert_UserHabit?: Resolver<Maybe<ResolversTypes['UserHabit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserHabitArgs, 'objects'>>;
  insert_UserHabit_one?: Resolver<Maybe<ResolversTypes['UserHabit']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserHabit_OneArgs, 'object'>>;
  insert_UserMovementDaily?: Resolver<Maybe<ResolversTypes['UserMovementDaily_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserMovementDailyArgs, 'objects'>>;
  insert_UserMovementDaily_one?: Resolver<Maybe<ResolversTypes['UserMovementDaily']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserMovementDaily_OneArgs, 'object'>>;
  insert_UserPreferences?: Resolver<Maybe<ResolversTypes['UserPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserPreferencesArgs, 'objects'>>;
  insert_UserPreferences_one?: Resolver<Maybe<ResolversTypes['UserPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserPreferences_OneArgs, 'object'>>;
  insert_UserProduct?: Resolver<Maybe<ResolversTypes['UserProduct_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserProductArgs, 'objects'>>;
  insert_UserProductHistory?: Resolver<Maybe<ResolversTypes['UserProductHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserProductHistoryArgs, 'objects'>>;
  insert_UserProductHistory_one?: Resolver<Maybe<ResolversTypes['UserProductHistory']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserProductHistory_OneArgs, 'object'>>;
  insert_UserProduct_one?: Resolver<Maybe<ResolversTypes['UserProduct']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserProduct_OneArgs, 'object'>>;
  insert_UserReadiness?: Resolver<Maybe<ResolversTypes['UserReadiness_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserReadinessArgs, 'objects'>>;
  insert_UserReadinessLog?: Resolver<Maybe<ResolversTypes['UserReadinessLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserReadinessLogArgs, 'objects'>>;
  insert_UserReadinessLog_one?: Resolver<Maybe<ResolversTypes['UserReadinessLog']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserReadinessLog_OneArgs, 'object'>>;
  insert_UserReadiness_one?: Resolver<Maybe<ResolversTypes['UserReadiness']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserReadiness_OneArgs, 'object'>>;
  insert_UserReimbursmentHistory?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserReimbursmentHistoryArgs, 'objects'>>;
  insert_UserReimbursmentHistory_one?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserReimbursmentHistory_OneArgs, 'object'>>;
  insert_UserRep?: Resolver<Maybe<ResolversTypes['UserRep_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserRepArgs, 'objects'>>;
  insert_UserRep_one?: Resolver<Maybe<ResolversTypes['UserRep']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserRep_OneArgs, 'object'>>;
  insert_UserSet?: Resolver<Maybe<ResolversTypes['UserSet_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserSetArgs, 'objects'>>;
  insert_UserSet_one?: Resolver<Maybe<ResolversTypes['UserSet']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserSet_OneArgs, 'object'>>;
  insert_UserState?: Resolver<Maybe<ResolversTypes['UserState_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserStateArgs, 'objects'>>;
  insert_UserState_one?: Resolver<Maybe<ResolversTypes['UserState']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserState_OneArgs, 'object'>>;
  insert_UserTrainingPlan?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserTrainingPlanArgs, 'objects'>>;
  insert_UserTrainingPlan_one?: Resolver<Maybe<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserTrainingPlan_OneArgs, 'object'>>;
  insert_UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserWorkoutArgs, 'objects'>>;
  insert_UserWorkout_one?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_UserWorkout_OneArgs, 'object'>>;
  insert_User_one?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_OneArgs, 'object'>>;
  insert_VendorErrorLog?: Resolver<Maybe<ResolversTypes['VendorErrorLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_VendorErrorLogArgs, 'objects'>>;
  insert_VendorErrorLog_one?: Resolver<Maybe<ResolversTypes['VendorErrorLog']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_VendorErrorLog_OneArgs, 'object'>>;
  insert_VersionControl?: Resolver<Maybe<ResolversTypes['VersionControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_VersionControlArgs, 'objects'>>;
  insert_VersionControl_one?: Resolver<Maybe<ResolversTypes['VersionControl']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_VersionControl_OneArgs, 'object'>>;
  insert_WeeklyPlan?: Resolver<Maybe<ResolversTypes['WeeklyPlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WeeklyPlanArgs, 'objects'>>;
  insert_WeeklyPlan_one?: Resolver<Maybe<ResolversTypes['WeeklyPlan']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WeeklyPlan_OneArgs, 'object'>>;
  insert_WorkinPrefrence?: Resolver<Maybe<ResolversTypes['WorkinPrefrence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WorkinPrefrenceArgs, 'objects'>>;
  insert_WorkinPrefrence_one?: Resolver<Maybe<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WorkinPrefrence_OneArgs, 'object'>>;
  insert_Workout?: Resolver<Maybe<ResolversTypes['Workout_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WorkoutArgs, 'objects'>>;
  insert_WorkoutPrefrence?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WorkoutPrefrenceArgs, 'objects'>>;
  insert_WorkoutPrefrence_one?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_WorkoutPrefrence_OneArgs, 'object'>>;
  insert_Workout_one?: Resolver<Maybe<ResolversTypes['Workout']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Workout_OneArgs, 'object'>>;
  insert__FollowingPro?: Resolver<Maybe<ResolversTypes['_FollowingPro_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert__FollowingProArgs, 'objects'>>;
  insert__FollowingPro_one?: Resolver<Maybe<ResolversTypes['_FollowingPro']>, ParentType, ContextType, RequireFields<Mutation_RootInsert__FollowingPro_OneArgs, 'object'>>;
  insert__GoalToUserPreferences?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert__GoalToUserPreferencesArgs, 'objects'>>;
  insert__GoalToUserPreferences_one?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootInsert__GoalToUserPreferences_OneArgs, 'object'>>;
  logRecommendation?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootLogRecommendationArgs, 'full_readiness_override' | 'partial_readiness_override' | 'user_id'>>;
  login?: Resolver<ResolversTypes['AllUsersOutput'], ParentType, ContextType, RequireFields<Mutation_RootLoginArgs, 'email' | 'password'>>;
  lookupUspsStatus?: Resolver<Maybe<ResolversTypes['ShipStatusMessage']>, ParentType, ContextType>;
  pauseOrResumeWorkout?: Resolver<Maybe<ResolversTypes['PauseOrResumeOutput']>, ParentType, ContextType, RequireFields<Mutation_RootPauseOrResumeWorkoutArgs, 'epoch' | 'workout_id'>>;
  performReadinessCalculations?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootPerformReadinessCalculationsArgs, 'userId'>>;
  performWorkoutCalculations?: Resolver<Maybe<Array<Maybe<ResolversTypes['SampleWorkoutZoneOutput']>>>, ParentType, ContextType, RequireFields<Mutation_RootPerformWorkoutCalculationsArgs, 'userId'>>;
  prestigeUser?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootPrestigeUserArgs, 'target_user_id' | 'user_id'>>;
  promoteToCoach?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootPromoteToCoachArgs, 'contentful_id' | 'sf_coach_id' | 'user_id'>>;
  qualifyWorkout?: Resolver<ResolversTypes['qualifyWorkoutOutput'], ParentType, ContextType, RequireFields<Mutation_RootQualifyWorkoutArgs, 'completedAmount' | 'workout_id'>>;
  qualifyWorkoutV2?: Resolver<ResolversTypes['qualifyWorkoutOutput'], ParentType, ContextType, RequireFields<Mutation_RootQualifyWorkoutV2Args, 'completedAmount' | 'workout_id'>>;
  qualifyWorkoutV3?: Resolver<ResolversTypes['qualifyWorkoutOutput'], ParentType, ContextType, RequireFields<Mutation_RootQualifyWorkoutV3Args, 'completedAmount' | 'workout_id'>>;
  readinessOverride?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootReadinessOverrideArgs, 'date' | 'score' | 'userId'>>;
  recommender?: Resolver<Maybe<ResolversTypes['RecommenderOutput']>, ParentType, ContextType, RequireFields<Mutation_RootRecommenderArgs, 'userId'>>;
  recommenderV2?: Resolver<Maybe<ResolversTypes['RecommenderV2Output']>, ParentType, ContextType, RequireFields<Mutation_RootRecommenderV2Args, 'userId'>>;
  refreshPearToken?: Resolver<ResolversTypes['PearTokens'], ParentType, ContextType, RequireFields<Mutation_RootRefreshPearTokenArgs, 'pearRefreshToken'>>;
  refreshPlan?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, Partial<Mutation_RootRefreshPlanArgs>>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  register?: Resolver<ResolversTypes['RegisterUser'], ParentType, ContextType, RequireFields<Mutation_RootRegisterArgs, 'email' | 'firstName' | 'lastName'>>;
  registerDNAKit?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootRegisterDnaKitArgs, 'activationCode' | 'alterUserId' | 'dateOfBirth' | 'email' | 'firstName' | 'gender' | 'heightFeet' | 'heightInches' | 'lastName'>>;
  registerDNAKitStaging?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootRegisterDnaKitStagingArgs, 'activationCode' | 'alterUserId' | 'dateOfBirth' | 'email' | 'firstName' | 'gender' | 'heightFeet' | 'heightInches' | 'lastName'>>;
  removeUserFromHousehold?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootRemoveUserFromHouseholdArgs, 'user_id'>>;
  resetNutritionReport?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootResetNutritionReportArgs, 'userId'>>;
  resetOnboarding?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootResetOnboardingArgs, 'user_id'>>;
  resetPlan?: Resolver<ResolversTypes['messageOutput'], ParentType, ContextType, RequireFields<Mutation_RootResetPlanArgs, 'userId'>>;
  runInfluxCalculations?: Resolver<Maybe<ResolversTypes['AggregatesOutput']>, ParentType, ContextType, RequireFields<Mutation_RootRunInfluxCalculationsArgs, 'userId'>>;
  saveFileBase64?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootSaveFileBase64Args, 'file'>>;
  setUserGoals?: Resolver<ResolversTypes['SetUserGoalsOutput'], ParentType, ContextType, RequireFields<Mutation_RootSetUserGoalsArgs, 'goals_id'>>;
  softDeleteUser?: Resolver<ResolversTypes['messageOutput'], ParentType, ContextType, RequireFields<Mutation_RootSoftDeleteUserArgs, 'auth0UserId'>>;
  startAssessment?: Resolver<Maybe<ResolversTypes['StartAssessmentOutput']>, ParentType, ContextType, RequireFields<Mutation_RootStartAssessmentArgs, 'assessment_type' | 'start_epoch' | 'user_id'>>;
  startWorkout?: Resolver<ResolversTypes['startWorkoutOutput'], ParentType, ContextType, RequireFields<Mutation_RootStartWorkoutArgs, 'startEpoch' | 'userId' | 'workout_type'>>;
  submitDiagnostics?: Resolver<ResolversTypes['createManyOutput'], ParentType, ContextType, RequireFields<Mutation_RootSubmitDiagnosticsArgs, 'data'>>;
  submitHRDiagnostics?: Resolver<ResolversTypes['createManyOutput'], ParentType, ContextType, RequireFields<Mutation_RootSubmitHrDiagnosticsArgs, 'data'>>;
  submitHabitCompleted?: Resolver<Maybe<ResolversTypes['HabitCompletedResult']>, ParentType, ContextType, RequireFields<Mutation_RootSubmitHabitCompletedArgs, 'user_id'>>;
  submitHardwareDiagnostics?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootSubmitHardwareDiagnosticsArgs, 'device_type' | 'pass' | 'title' | 'user_id'>>;
  submitQuizSurvey?: Resolver<ResolversTypes['SampleQuizSurveyOutput'], ParentType, ContextType, RequireFields<Mutation_RootSubmitQuizSurveyArgs, 'email'>>;
  submitRegistrationSurvey?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootSubmitRegistrationSurveyArgs, 'email' | 'user_id'>>;
  submitReportViewed?: Resolver<ResolversTypes['messageOutput'], ParentType, ContextType, RequireFields<Mutation_RootSubmitReportViewedArgs, 'userId'>>;
  submitSurveyToSf?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootSubmitSurveyToSfArgs, 'userId'>>;
  submitSurveyToSfV2?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Mutation_RootSubmitSurveyToSfV2Args, 'userId'>>;
  submitUserFeedback?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, Partial<Mutation_RootSubmitUserFeedbackArgs>>;
  swapMeal?: Resolver<Maybe<ResolversTypes['MealPlanOutput']>, ParentType, ContextType, RequireFields<Mutation_RootSwapMealArgs, 'meal_plan_id' | 'target_meal_key' | 'target_plan_index'>>;
  unassignBiostrap?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUnassignBiostrapArgs, 'id'>>;
  updateConsentDetails?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<Mutation_RootUpdateConsentDetailsArgs, 'auth0UserId' | 'is_consented'>>;
  updateDNAResults?: Resolver<Maybe<ResolversTypes['DNAResultsOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateDnaResultsArgs, 'alterUserId'>>;
  updateEmail?: Resolver<ResolversTypes['messageOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateEmailArgs, 'auth0UserId' | 'newEmail'>>;
  updateFirmwareContentV2?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateFirmwareContentV2Args>>;
  updateFirmwareV1ActiveStatus?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateFirmwareV1ActiveStatusArgs>>;
  updateFirmwareV1BetaStatus?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateFirmwareV1BetaStatusArgs, 'device'>>;
  updateMealSelectionStatus?: Resolver<Maybe<ResolversTypes['MealPlanOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateMealSelectionStatusArgs, 'meal_plan_id' | 'targets'>>;
  updateMobileVersions?: Resolver<Maybe<ResolversTypes['mobileVersionsOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateMobileVersionsArgs, 'userId'>>;
  updatePassword?: Resolver<Maybe<ResolversTypes['messageOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdatePasswordArgs, 'auth0UserId' | 'password'>>;
  updateReimbursementTokens?: Resolver<Maybe<ResolversTypes['updateReimbursementTokensResponse']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateReimbursementTokensArgs, 'credits' | 'userId'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['updateUser']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateUserArgs, 'email' | 'id'>>;
  updateUserPreferences?: Resolver<Maybe<ResolversTypes['UserPreferencesOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateUserPreferencesArgs, 'userId'>>;
  update_ActivationCode?: Resolver<Maybe<ResolversTypes['ActivationCode_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivationCodeArgs, 'where'>>;
  update_ActivationCode_by_pk?: Resolver<Maybe<ResolversTypes['ActivationCode']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivationCode_By_PkArgs, 'pk_columns'>>;
  update_ActivationCode_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActivationCode_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ActivationCode_ManyArgs, 'updates'>>;
  update_AlterTypeColor?: Resolver<Maybe<ResolversTypes['AlterTypeColor_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_AlterTypeColorArgs, 'where'>>;
  update_AlterTypeColor_by_pk?: Resolver<Maybe<ResolversTypes['AlterTypeColor']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_AlterTypeColor_By_PkArgs, 'pk_columns'>>;
  update_AlterTypeColor_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlterTypeColor_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_AlterTypeColor_ManyArgs, 'updates'>>;
  update_Coach?: Resolver<Maybe<ResolversTypes['Coach_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachArgs, 'where'>>;
  update_Coach_by_pk?: Resolver<Maybe<ResolversTypes['Coach']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Coach_By_PkArgs, 'pk_columns'>>;
  update_Coach_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Coach_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Coach_ManyArgs, 'updates'>>;
  update_CoachedHabit?: Resolver<Maybe<ResolversTypes['CoachedHabit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachedHabitArgs, 'where'>>;
  update_CoachedHabit_by_pk?: Resolver<Maybe<ResolversTypes['CoachedHabit']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachedHabit_By_PkArgs, 'pk_columns'>>;
  update_CoachedHabit_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CoachedHabit_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachedHabit_ManyArgs, 'updates'>>;
  update_CoachedNutrition?: Resolver<Maybe<ResolversTypes['CoachedNutrition_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachedNutritionArgs, 'where'>>;
  update_CoachedNutrition_by_pk?: Resolver<Maybe<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachedNutrition_By_PkArgs, 'pk_columns'>>;
  update_CoachedNutrition_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CoachedNutrition_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CoachedNutrition_ManyArgs, 'updates'>>;
  update_DNAKit?: Resolver<Maybe<ResolversTypes['DNAKit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DnaKitArgs, 'where'>>;
  update_DNAKit_by_pk?: Resolver<Maybe<ResolversTypes['DNAKit']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DnaKit_By_PkArgs, 'pk_columns'>>;
  update_DNAKit_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['DNAKit_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DnaKit_ManyArgs, 'updates'>>;
  update_DNAResults?: Resolver<Maybe<ResolversTypes['DNAResults_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DnaResultsArgs, 'where'>>;
  update_DNAResults_by_pk?: Resolver<Maybe<ResolversTypes['DNAResults']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DnaResults_By_PkArgs, 'pk_columns'>>;
  update_DNAResults_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['DNAResults_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DnaResults_ManyArgs, 'updates'>>;
  update_Delivery?: Resolver<Maybe<ResolversTypes['Delivery_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DeliveryArgs, 'where'>>;
  update_DeliveryHistory?: Resolver<Maybe<ResolversTypes['DeliveryHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DeliveryHistoryArgs, 'where'>>;
  update_DeliveryHistory_by_pk?: Resolver<Maybe<ResolversTypes['DeliveryHistory']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DeliveryHistory_By_PkArgs, 'pk_columns'>>;
  update_DeliveryHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['DeliveryHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DeliveryHistory_ManyArgs, 'updates'>>;
  update_Delivery_by_pk?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Delivery_By_PkArgs, 'pk_columns'>>;
  update_Delivery_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Delivery_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Delivery_ManyArgs, 'updates'>>;
  update_Device?: Resolver<Maybe<ResolversTypes['Device_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DeviceArgs, 'where'>>;
  update_Device_by_pk?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_By_PkArgs, 'pk_columns'>>;
  update_Device_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Device_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Device_ManyArgs, 'updates'>>;
  update_Diagnostics?: Resolver<Maybe<ResolversTypes['Diagnostics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_DiagnosticsArgs, 'where'>>;
  update_Diagnostics_by_pk?: Resolver<Maybe<ResolversTypes['Diagnostics']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Diagnostics_By_PkArgs, 'pk_columns'>>;
  update_Diagnostics_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Diagnostics_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Diagnostics_ManyArgs, 'updates'>>;
  update_FinancePlan?: Resolver<Maybe<ResolversTypes['FinancePlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FinancePlanArgs, 'where'>>;
  update_FinancePlan_by_pk?: Resolver<Maybe<ResolversTypes['FinancePlan']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FinancePlan_By_PkArgs, 'pk_columns'>>;
  update_FinancePlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['FinancePlan_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FinancePlan_ManyArgs, 'updates'>>;
  update_FirmwareContent?: Resolver<Maybe<ResolversTypes['FirmwareContent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FirmwareContentArgs, 'where'>>;
  update_FirmwareContent_by_pk?: Resolver<Maybe<ResolversTypes['FirmwareContent']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FirmwareContent_By_PkArgs, 'pk_columns'>>;
  update_FirmwareContent_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['FirmwareContent_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_FirmwareContent_ManyArgs, 'updates'>>;
  update_GeneticDirectionWebhook?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GeneticDirectionWebhookArgs, 'where'>>;
  update_GeneticDirectionWebhook_by_pk?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GeneticDirectionWebhook_By_PkArgs, 'pk_columns'>>;
  update_GeneticDirectionWebhook_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['GeneticDirectionWebhook_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GeneticDirectionWebhook_ManyArgs, 'updates'>>;
  update_GlobalFeatureControl?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GlobalFeatureControlArgs, 'where'>>;
  update_GlobalFeatureControl_by_pk?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GlobalFeatureControl_By_PkArgs, 'pk_columns'>>;
  update_GlobalFeatureControl_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['GlobalFeatureControl_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GlobalFeatureControl_ManyArgs, 'updates'>>;
  update_Goal?: Resolver<Maybe<ResolversTypes['Goal_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_GoalArgs, 'where'>>;
  update_Goal_by_pk?: Resolver<Maybe<ResolversTypes['Goal']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Goal_By_PkArgs, 'pk_columns'>>;
  update_Goal_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Goal_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Goal_ManyArgs, 'updates'>>;
  update_HabitDay?: Resolver<Maybe<ResolversTypes['HabitDay_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HabitDayArgs, 'where'>>;
  update_HabitDay_by_pk?: Resolver<Maybe<ResolversTypes['HabitDay']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HabitDay_By_PkArgs, 'pk_columns'>>;
  update_HabitDay_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['HabitDay_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HabitDay_ManyArgs, 'updates'>>;
  update_HeartRateDiagnostics?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HeartRateDiagnosticsArgs, 'where'>>;
  update_HeartRateDiagnostics_by_pk?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HeartRateDiagnostics_By_PkArgs, 'pk_columns'>>;
  update_HeartRateDiagnostics_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['HeartRateDiagnostics_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HeartRateDiagnostics_ManyArgs, 'updates'>>;
  update_Household?: Resolver<Maybe<ResolversTypes['Household_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_HouseholdArgs, 'where'>>;
  update_Household_by_pk?: Resolver<Maybe<ResolversTypes['Household']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Household_By_PkArgs, 'pk_columns'>>;
  update_Household_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Household_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Household_ManyArgs, 'updates'>>;
  update_MarketingEmail?: Resolver<Maybe<ResolversTypes['MarketingEmail_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_MarketingEmailArgs, 'where'>>;
  update_MarketingEmail_by_pk?: Resolver<Maybe<ResolversTypes['MarketingEmail']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_MarketingEmail_By_PkArgs, 'pk_columns'>>;
  update_MarketingEmail_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['MarketingEmail_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_MarketingEmail_ManyArgs, 'updates'>>;
  update_Mastery?: Resolver<Maybe<ResolversTypes['Mastery_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_MasteryArgs, 'where'>>;
  update_Mastery_by_pk?: Resolver<Maybe<ResolversTypes['Mastery']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Mastery_By_PkArgs, 'pk_columns'>>;
  update_Mastery_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mastery_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Mastery_ManyArgs, 'updates'>>;
  update_OnboardingInfo?: Resolver<Maybe<ResolversTypes['OnboardingInfo_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OnboardingInfoArgs, 'where'>>;
  update_OnboardingInfo_by_pk?: Resolver<Maybe<ResolversTypes['OnboardingInfo']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OnboardingInfo_By_PkArgs, 'pk_columns'>>;
  update_OnboardingInfo_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['OnboardingInfo_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OnboardingInfo_ManyArgs, 'updates'>>;
  update_OnboardingVideos?: Resolver<Maybe<ResolversTypes['OnboardingVideos_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OnboardingVideosArgs, 'where'>>;
  update_OnboardingVideos_by_pk?: Resolver<Maybe<ResolversTypes['OnboardingVideos']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OnboardingVideos_By_PkArgs, 'pk_columns'>>;
  update_OnboardingVideos_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['OnboardingVideos_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OnboardingVideos_ManyArgs, 'updates'>>;
  update_Order?: Resolver<Maybe<ResolversTypes['Order_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OrderArgs, 'where'>>;
  update_OrderLineItem?: Resolver<Maybe<ResolversTypes['OrderLineItem_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OrderLineItemArgs, 'where'>>;
  update_OrderLineItem_by_pk?: Resolver<Maybe<ResolversTypes['OrderLineItem']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OrderLineItem_By_PkArgs, 'pk_columns'>>;
  update_OrderLineItem_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderLineItem_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_OrderLineItem_ManyArgs, 'updates'>>;
  update_Order_by_pk?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Order_By_PkArgs, 'pk_columns'>>;
  update_Order_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Order_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Order_ManyArgs, 'updates'>>;
  update_PaymentHistory?: Resolver<Maybe<ResolversTypes['PaymentHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PaymentHistoryArgs, 'where'>>;
  update_PaymentHistory_by_pk?: Resolver<Maybe<ResolversTypes['PaymentHistory']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PaymentHistory_By_PkArgs, 'pk_columns'>>;
  update_PaymentHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PaymentHistory_ManyArgs, 'updates'>>;
  update_PlanDay?: Resolver<Maybe<ResolversTypes['PlanDay_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PlanDayArgs, 'where'>>;
  update_PlanDay_by_pk?: Resolver<Maybe<ResolversTypes['PlanDay']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PlanDay_By_PkArgs, 'pk_columns'>>;
  update_PlanDay_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlanDay_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PlanDay_ManyArgs, 'updates'>>;
  update_PresetDiet?: Resolver<Maybe<ResolversTypes['PresetDiet_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PresetDietArgs, 'where'>>;
  update_PresetDiet_by_pk?: Resolver<Maybe<ResolversTypes['PresetDiet']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PresetDiet_By_PkArgs, 'pk_columns'>>;
  update_PresetDiet_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PresetDiet_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PresetDiet_ManyArgs, 'updates'>>;
  update_Pro?: Resolver<Maybe<ResolversTypes['Pro_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProArgs, 'where'>>;
  update_ProMusic?: Resolver<Maybe<ResolversTypes['ProMusic_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProMusicArgs, 'where'>>;
  update_ProMusic_by_pk?: Resolver<Maybe<ResolversTypes['ProMusic']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProMusic_By_PkArgs, 'pk_columns'>>;
  update_ProMusic_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProMusic_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProMusic_ManyArgs, 'updates'>>;
  update_Pro_by_pk?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Pro_By_PkArgs, 'pk_columns'>>;
  update_Pro_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Pro_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Pro_ManyArgs, 'updates'>>;
  update_Product?: Resolver<Maybe<ResolversTypes['Product_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProductArgs, 'where'>>;
  update_ProductLog?: Resolver<Maybe<ResolversTypes['ProductLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProductLogArgs, 'where'>>;
  update_ProductLog_by_pk?: Resolver<Maybe<ResolversTypes['ProductLog']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProductLog_By_PkArgs, 'pk_columns'>>;
  update_ProductLog_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductLog_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ProductLog_ManyArgs, 'updates'>>;
  update_Product_by_pk?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Product_By_PkArgs, 'pk_columns'>>;
  update_Product_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Product_ManyArgs, 'updates'>>;
  update_PromotionalItem?: Resolver<Maybe<ResolversTypes['PromotionalItem_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PromotionalItemArgs, 'where'>>;
  update_PromotionalItem_by_pk?: Resolver<Maybe<ResolversTypes['PromotionalItem']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PromotionalItem_By_PkArgs, 'pk_columns'>>;
  update_PromotionalItem_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PromotionalItem_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_PromotionalItem_ManyArgs, 'updates'>>;
  update_QuizSurvey?: Resolver<Maybe<ResolversTypes['QuizSurvey_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_QuizSurveyArgs, 'where'>>;
  update_QuizSurvey_by_pk?: Resolver<Maybe<ResolversTypes['QuizSurvey']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_QuizSurvey_By_PkArgs, 'pk_columns'>>;
  update_QuizSurvey_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuizSurvey_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_QuizSurvey_ManyArgs, 'updates'>>;
  update_Quote?: Resolver<Maybe<ResolversTypes['Quote_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_QuoteArgs, 'where'>>;
  update_Quote_by_pk?: Resolver<Maybe<ResolversTypes['Quote']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Quote_By_PkArgs, 'pk_columns'>>;
  update_Quote_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Quote_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Quote_ManyArgs, 'updates'>>;
  update_ReimbursementRequirement?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ReimbursementRequirementArgs, 'where'>>;
  update_ReimbursementRequirement_by_pk?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ReimbursementRequirement_By_PkArgs, 'pk_columns'>>;
  update_ReimbursementRequirement_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReimbursementRequirement_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ReimbursementRequirement_ManyArgs, 'updates'>>;
  update_ScreenOrder?: Resolver<Maybe<ResolversTypes['ScreenOrder_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ScreenOrderArgs, 'where'>>;
  update_ScreenOrder_by_pk?: Resolver<Maybe<ResolversTypes['ScreenOrder']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ScreenOrder_By_PkArgs, 'pk_columns'>>;
  update_ScreenOrder_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScreenOrder_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ScreenOrder_ManyArgs, 'updates'>>;
  update_SearchResult?: Resolver<Maybe<ResolversTypes['SearchResult_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SearchResultArgs, 'where'>>;
  update_SearchResult_by_pk?: Resolver<Maybe<ResolversTypes['SearchResult']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SearchResult_By_PkArgs, 'pk_columns'>>;
  update_SearchResult_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['SearchResult_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SearchResult_ManyArgs, 'updates'>>;
  update_SleepSurvey?: Resolver<Maybe<ResolversTypes['SleepSurvey_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SleepSurveyArgs, 'where'>>;
  update_SleepSurvey_by_pk?: Resolver<Maybe<ResolversTypes['SleepSurvey']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SleepSurvey_By_PkArgs, 'pk_columns'>>;
  update_SleepSurvey_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['SleepSurvey_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SleepSurvey_ManyArgs, 'updates'>>;
  update_Subscription?: Resolver<Maybe<ResolversTypes['Subscription_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SubscriptionArgs, 'where'>>;
  update_SubscriptionLog?: Resolver<Maybe<ResolversTypes['SubscriptionLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SubscriptionLogArgs, 'where'>>;
  update_SubscriptionLog_by_pk?: Resolver<Maybe<ResolversTypes['SubscriptionLog']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SubscriptionLog_By_PkArgs, 'pk_columns'>>;
  update_SubscriptionLog_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubscriptionLog_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_SubscriptionLog_ManyArgs, 'updates'>>;
  update_Subscription_by_pk?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Subscription_By_PkArgs, 'pk_columns'>>;
  update_Subscription_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Subscription_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Subscription_ManyArgs, 'updates'>>;
  update_Token?: Resolver<Maybe<ResolversTypes['Token_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TokenArgs, 'where'>>;
  update_TokenHistory?: Resolver<Maybe<ResolversTypes['TokenHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TokenHistoryArgs, 'where'>>;
  update_TokenHistory_by_pk?: Resolver<Maybe<ResolversTypes['TokenHistory']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TokenHistory_By_PkArgs, 'pk_columns'>>;
  update_TokenHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['TokenHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TokenHistory_ManyArgs, 'updates'>>;
  update_Token_by_pk?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Token_By_PkArgs, 'pk_columns'>>;
  update_Token_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Token_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Token_ManyArgs, 'updates'>>;
  update_User?: Resolver<Maybe<ResolversTypes['User_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserArgs, 'where'>>;
  update_UserAggregates?: Resolver<Maybe<ResolversTypes['UserAggregates_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserAggregatesArgs, 'where'>>;
  update_UserAggregates_by_pk?: Resolver<Maybe<ResolversTypes['UserAggregates']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserAggregates_By_PkArgs, 'pk_columns'>>;
  update_UserAggregates_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserAggregates_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserAggregates_ManyArgs, 'updates'>>;
  update_UserAssessment?: Resolver<Maybe<ResolversTypes['UserAssessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserAssessmentArgs, 'where'>>;
  update_UserAssessment_by_pk?: Resolver<Maybe<ResolversTypes['UserAssessment']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserAssessment_By_PkArgs, 'pk_columns'>>;
  update_UserAssessment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserAssessment_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserAssessment_ManyArgs, 'updates'>>;
  update_UserFeatureControl?: Resolver<Maybe<ResolversTypes['UserFeatureControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFeatureControlArgs, 'where'>>;
  update_UserFeatureControl_by_pk?: Resolver<Maybe<ResolversTypes['UserFeatureControl']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFeatureControl_By_PkArgs, 'pk_columns'>>;
  update_UserFeatureControl_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserFeatureControl_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFeatureControl_ManyArgs, 'updates'>>;
  update_UserFeedback?: Resolver<Maybe<ResolversTypes['UserFeedback_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFeedbackArgs, 'where'>>;
  update_UserFeedback_by_pk?: Resolver<Maybe<ResolversTypes['UserFeedback']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFeedback_By_PkArgs, 'pk_columns'>>;
  update_UserFeedback_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserFeedback_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFeedback_ManyArgs, 'updates'>>;
  update_UserFinancePlan?: Resolver<Maybe<ResolversTypes['UserFinancePlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFinancePlanArgs, 'where'>>;
  update_UserFinancePlan_by_pk?: Resolver<Maybe<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFinancePlan_By_PkArgs, 'pk_columns'>>;
  update_UserFinancePlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserFinancePlan_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserFinancePlan_ManyArgs, 'updates'>>;
  update_UserHabit?: Resolver<Maybe<ResolversTypes['UserHabit_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserHabitArgs, 'where'>>;
  update_UserHabit_by_pk?: Resolver<Maybe<ResolversTypes['UserHabit']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserHabit_By_PkArgs, 'pk_columns'>>;
  update_UserHabit_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserHabit_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserHabit_ManyArgs, 'updates'>>;
  update_UserMovementDaily?: Resolver<Maybe<ResolversTypes['UserMovementDaily_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserMovementDailyArgs, 'where'>>;
  update_UserMovementDaily_by_pk?: Resolver<Maybe<ResolversTypes['UserMovementDaily']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserMovementDaily_By_PkArgs, 'pk_columns'>>;
  update_UserMovementDaily_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserMovementDaily_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserMovementDaily_ManyArgs, 'updates'>>;
  update_UserPreferences?: Resolver<Maybe<ResolversTypes['UserPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserPreferencesArgs, 'where'>>;
  update_UserPreferences_by_pk?: Resolver<Maybe<ResolversTypes['UserPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserPreferences_By_PkArgs, 'pk_columns'>>;
  update_UserPreferences_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserPreferences_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserPreferences_ManyArgs, 'updates'>>;
  update_UserProduct?: Resolver<Maybe<ResolversTypes['UserProduct_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserProductArgs, 'where'>>;
  update_UserProductHistory?: Resolver<Maybe<ResolversTypes['UserProductHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserProductHistoryArgs, 'where'>>;
  update_UserProductHistory_by_pk?: Resolver<Maybe<ResolversTypes['UserProductHistory']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserProductHistory_By_PkArgs, 'pk_columns'>>;
  update_UserProductHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserProductHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserProductHistory_ManyArgs, 'updates'>>;
  update_UserProduct_by_pk?: Resolver<Maybe<ResolversTypes['UserProduct']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserProduct_By_PkArgs, 'pk_columns'>>;
  update_UserProduct_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserProduct_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserProduct_ManyArgs, 'updates'>>;
  update_UserReadiness?: Resolver<Maybe<ResolversTypes['UserReadiness_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReadinessArgs, 'where'>>;
  update_UserReadinessLog?: Resolver<Maybe<ResolversTypes['UserReadinessLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReadinessLogArgs, 'where'>>;
  update_UserReadinessLog_by_pk?: Resolver<Maybe<ResolversTypes['UserReadinessLog']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReadinessLog_By_PkArgs, 'pk_columns'>>;
  update_UserReadinessLog_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserReadinessLog_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReadinessLog_ManyArgs, 'updates'>>;
  update_UserReadiness_by_pk?: Resolver<Maybe<ResolversTypes['UserReadiness']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReadiness_By_PkArgs, 'pk_columns'>>;
  update_UserReadiness_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserReadiness_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReadiness_ManyArgs, 'updates'>>;
  update_UserReimbursmentHistory?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReimbursmentHistoryArgs, 'where'>>;
  update_UserReimbursmentHistory_by_pk?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReimbursmentHistory_By_PkArgs, 'pk_columns'>>;
  update_UserReimbursmentHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserReimbursmentHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserReimbursmentHistory_ManyArgs, 'updates'>>;
  update_UserRep?: Resolver<Maybe<ResolversTypes['UserRep_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserRepArgs, 'where'>>;
  update_UserRep_by_pk?: Resolver<Maybe<ResolversTypes['UserRep']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserRep_By_PkArgs, 'pk_columns'>>;
  update_UserRep_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserRep_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserRep_ManyArgs, 'updates'>>;
  update_UserSet?: Resolver<Maybe<ResolversTypes['UserSet_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserSetArgs, 'where'>>;
  update_UserSet_by_pk?: Resolver<Maybe<ResolversTypes['UserSet']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserSet_By_PkArgs, 'pk_columns'>>;
  update_UserSet_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserSet_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserSet_ManyArgs, 'updates'>>;
  update_UserState?: Resolver<Maybe<ResolversTypes['UserState_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserStateArgs, 'where'>>;
  update_UserState_by_pk?: Resolver<Maybe<ResolversTypes['UserState']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserState_By_PkArgs, 'pk_columns'>>;
  update_UserState_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserState_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserState_ManyArgs, 'updates'>>;
  update_UserTrainingPlan?: Resolver<Maybe<ResolversTypes['UserTrainingPlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserTrainingPlanArgs, 'where'>>;
  update_UserTrainingPlan_by_pk?: Resolver<Maybe<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserTrainingPlan_By_PkArgs, 'pk_columns'>>;
  update_UserTrainingPlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserTrainingPlan_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserTrainingPlan_ManyArgs, 'updates'>>;
  update_UserWorkout?: Resolver<Maybe<ResolversTypes['UserWorkout_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserWorkoutArgs, 'where'>>;
  update_UserWorkout_by_pk?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserWorkout_By_PkArgs, 'pk_columns'>>;
  update_UserWorkout_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserWorkout_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_UserWorkout_ManyArgs, 'updates'>>;
  update_User_by_pk?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_By_PkArgs, 'pk_columns'>>;
  update_User_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['User_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_ManyArgs, 'updates'>>;
  update_VendorErrorLog?: Resolver<Maybe<ResolversTypes['VendorErrorLog_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VendorErrorLogArgs, 'where'>>;
  update_VendorErrorLog_by_pk?: Resolver<Maybe<ResolversTypes['VendorErrorLog']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VendorErrorLog_By_PkArgs, 'pk_columns'>>;
  update_VendorErrorLog_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorErrorLog_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VendorErrorLog_ManyArgs, 'updates'>>;
  update_VersionControl?: Resolver<Maybe<ResolversTypes['VersionControl_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VersionControlArgs, 'where'>>;
  update_VersionControl_by_pk?: Resolver<Maybe<ResolversTypes['VersionControl']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VersionControl_By_PkArgs, 'pk_columns'>>;
  update_VersionControl_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['VersionControl_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_VersionControl_ManyArgs, 'updates'>>;
  update_WeeklyPlan?: Resolver<Maybe<ResolversTypes['WeeklyPlan_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WeeklyPlanArgs, 'where'>>;
  update_WeeklyPlan_by_pk?: Resolver<Maybe<ResolversTypes['WeeklyPlan']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WeeklyPlan_By_PkArgs, 'pk_columns'>>;
  update_WeeklyPlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['WeeklyPlan_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WeeklyPlan_ManyArgs, 'updates'>>;
  update_WorkinPrefrence?: Resolver<Maybe<ResolversTypes['WorkinPrefrence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkinPrefrenceArgs, 'where'>>;
  update_WorkinPrefrence_by_pk?: Resolver<Maybe<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkinPrefrence_By_PkArgs, 'pk_columns'>>;
  update_WorkinPrefrence_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkinPrefrence_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkinPrefrence_ManyArgs, 'updates'>>;
  update_Workout?: Resolver<Maybe<ResolversTypes['Workout_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkoutArgs, 'where'>>;
  update_WorkoutPrefrence?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkoutPrefrenceArgs, 'where'>>;
  update_WorkoutPrefrence_by_pk?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkoutPrefrence_By_PkArgs, 'pk_columns'>>;
  update_WorkoutPrefrence_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkoutPrefrence_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_WorkoutPrefrence_ManyArgs, 'updates'>>;
  update_Workout_by_pk?: Resolver<Maybe<ResolversTypes['Workout']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Workout_By_PkArgs, 'pk_columns'>>;
  update_Workout_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Workout_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Workout_ManyArgs, 'updates'>>;
  update__FollowingPro?: Resolver<Maybe<ResolversTypes['_FollowingPro_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate__FollowingProArgs, 'where'>>;
  update__FollowingPro_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['_FollowingPro_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate__FollowingPro_ManyArgs, 'updates'>>;
  update__GoalToUserPreferences?: Resolver<Maybe<ResolversTypes['_GoalToUserPreferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate__GoalToUserPreferencesArgs, 'where'>>;
  update__GoalToUserPreferences_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['_GoalToUserPreferences_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate__GoalToUserPreferences_ManyArgs, 'updates'>>;
  uploadHighKneeSet?: Resolver<Maybe<ResolversTypes['HighKneeSetOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUploadHighKneeSetArgs, 'assessment_id' | 'completedAt' | 'user_id'>>;
  uploadSet?: Resolver<ResolversTypes['UploadSetOutput'], ParentType, ContextType, RequireFields<Mutation_RootUploadSetArgs, 'category' | 'completedAt' | 'negative_nuances_possible' | 'positive_nuances_possible' | 'section' | 'set' | 'userId' | 'userWorkoutId'>>;
  uploadSetV2?: Resolver<ResolversTypes['UploadSetV2Output'], ParentType, ContextType, RequireFields<Mutation_RootUploadSetV2Args, 'category' | 'completedAt' | 'negative_nuances_possible' | 'positive_nuances_possible' | 'section' | 'set' | 'userId' | 'userWorkoutId'>>;
  upsertCookBookItem?: Resolver<Maybe<ResolversTypes['ActionResultOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpsertCookBookItemArgs, 'meal_key' | 'title' | 'user_id'>>;
  upsertMealPlanPreferences?: Resolver<Maybe<ResolversTypes['MealPlanPreferences']>, ParentType, ContextType, RequireFields<Mutation_RootUpsertMealPlanPreferencesArgs, 'user_id'>>;
  webRegister?: Resolver<Maybe<ResolversTypes['RegisterUser']>, ParentType, ContextType, RequireFields<Mutation_RootWebRegisterArgs, 'auth0UserId' | 'firstName' | 'lastName'>>;
}>;

export type PlanHistoryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['planHistoryOutput'] = ResolversParentTypes['planHistoryOutput']> = ResolversObject<{
  historyByWeek?: Resolver<Maybe<Array<Maybe<ResolversTypes['planHistoryWeek']>>>, ParentType, ContextType>;
  totals?: Resolver<Maybe<ResolversTypes['planHistoryTotals']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanHistoryTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planHistoryTotals'] = ResolversParentTypes['planHistoryTotals']> = ResolversObject<{
  free_months?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weeks_won?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanHistoryWeekResolvers<ContextType = any, ParentType extends ResolversParentTypes['planHistoryWeek'] = ResolversParentTypes['planHistoryWeek']> = ResolversObject<{
  completed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QualifyWorkoutOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['qualifyWorkoutOutput'] = ResolversParentTypes['qualifyWorkoutOutput']> = ResolversObject<{
  is_qualified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  plan_day_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zone1sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone2sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zone3sec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Query_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = ResolversObject<{
  ActivationCode?: Resolver<Array<ResolversTypes['ActivationCode']>, ParentType, ContextType, Partial<Query_RootActivationCodeArgs>>;
  ActivationCode_aggregate?: Resolver<ResolversTypes['ActivationCode_aggregate'], ParentType, ContextType, Partial<Query_RootActivationCode_AggregateArgs>>;
  ActivationCode_by_pk?: Resolver<Maybe<ResolversTypes['ActivationCode']>, ParentType, ContextType, RequireFields<Query_RootActivationCode_By_PkArgs, 'id'>>;
  AlterTypeColor?: Resolver<Array<ResolversTypes['AlterTypeColor']>, ParentType, ContextType, Partial<Query_RootAlterTypeColorArgs>>;
  AlterTypeColor_aggregate?: Resolver<ResolversTypes['AlterTypeColor_aggregate'], ParentType, ContextType, Partial<Query_RootAlterTypeColor_AggregateArgs>>;
  AlterTypeColor_by_pk?: Resolver<Maybe<ResolversTypes['AlterTypeColor']>, ParentType, ContextType, RequireFields<Query_RootAlterTypeColor_By_PkArgs, 'letter'>>;
  Coach?: Resolver<Array<ResolversTypes['Coach']>, ParentType, ContextType, Partial<Query_RootCoachArgs>>;
  Coach_aggregate?: Resolver<ResolversTypes['Coach_aggregate'], ParentType, ContextType, Partial<Query_RootCoach_AggregateArgs>>;
  Coach_by_pk?: Resolver<Maybe<ResolversTypes['Coach']>, ParentType, ContextType, RequireFields<Query_RootCoach_By_PkArgs, 'id'>>;
  CoachedHabit?: Resolver<Array<ResolversTypes['CoachedHabit']>, ParentType, ContextType, Partial<Query_RootCoachedHabitArgs>>;
  CoachedHabit_aggregate?: Resolver<ResolversTypes['CoachedHabit_aggregate'], ParentType, ContextType, Partial<Query_RootCoachedHabit_AggregateArgs>>;
  CoachedHabit_by_pk?: Resolver<Maybe<ResolversTypes['CoachedHabit']>, ParentType, ContextType, RequireFields<Query_RootCoachedHabit_By_PkArgs, 'id'>>;
  CoachedNutrition?: Resolver<Array<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, Partial<Query_RootCoachedNutritionArgs>>;
  CoachedNutrition_aggregate?: Resolver<ResolversTypes['CoachedNutrition_aggregate'], ParentType, ContextType, Partial<Query_RootCoachedNutrition_AggregateArgs>>;
  CoachedNutrition_by_pk?: Resolver<Maybe<ResolversTypes['CoachedNutrition']>, ParentType, ContextType, RequireFields<Query_RootCoachedNutrition_By_PkArgs, 'id'>>;
  DNAKit?: Resolver<Array<ResolversTypes['DNAKit']>, ParentType, ContextType, Partial<Query_RootDnaKitArgs>>;
  DNAKit_aggregate?: Resolver<ResolversTypes['DNAKit_aggregate'], ParentType, ContextType, Partial<Query_RootDnaKit_AggregateArgs>>;
  DNAKit_by_pk?: Resolver<Maybe<ResolversTypes['DNAKit']>, ParentType, ContextType, RequireFields<Query_RootDnaKit_By_PkArgs, 'id'>>;
  DNAResults?: Resolver<Array<ResolversTypes['DNAResults']>, ParentType, ContextType, Partial<Query_RootDnaResultsArgs>>;
  DNAResults_aggregate?: Resolver<ResolversTypes['DNAResults_aggregate'], ParentType, ContextType, Partial<Query_RootDnaResults_AggregateArgs>>;
  DNAResults_by_pk?: Resolver<Maybe<ResolversTypes['DNAResults']>, ParentType, ContextType, RequireFields<Query_RootDnaResults_By_PkArgs, 'id'>>;
  Delivery?: Resolver<Array<ResolversTypes['Delivery']>, ParentType, ContextType, Partial<Query_RootDeliveryArgs>>;
  DeliveryHistory?: Resolver<Array<ResolversTypes['DeliveryHistory']>, ParentType, ContextType, Partial<Query_RootDeliveryHistoryArgs>>;
  DeliveryHistory_aggregate?: Resolver<ResolversTypes['DeliveryHistory_aggregate'], ParentType, ContextType, Partial<Query_RootDeliveryHistory_AggregateArgs>>;
  DeliveryHistory_by_pk?: Resolver<Maybe<ResolversTypes['DeliveryHistory']>, ParentType, ContextType, RequireFields<Query_RootDeliveryHistory_By_PkArgs, 'id'>>;
  Delivery_aggregate?: Resolver<ResolversTypes['Delivery_aggregate'], ParentType, ContextType, Partial<Query_RootDelivery_AggregateArgs>>;
  Delivery_by_pk?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType, RequireFields<Query_RootDelivery_By_PkArgs, 'id'>>;
  Device?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType, Partial<Query_RootDeviceArgs>>;
  Device_aggregate?: Resolver<ResolversTypes['Device_aggregate'], ParentType, ContextType, Partial<Query_RootDevice_AggregateArgs>>;
  Device_by_pk?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<Query_RootDevice_By_PkArgs, 'id'>>;
  Diagnostics?: Resolver<Array<ResolversTypes['Diagnostics']>, ParentType, ContextType, Partial<Query_RootDiagnosticsArgs>>;
  Diagnostics_aggregate?: Resolver<ResolversTypes['Diagnostics_aggregate'], ParentType, ContextType, Partial<Query_RootDiagnostics_AggregateArgs>>;
  Diagnostics_by_pk?: Resolver<Maybe<ResolversTypes['Diagnostics']>, ParentType, ContextType, RequireFields<Query_RootDiagnostics_By_PkArgs, 'id'>>;
  FinancePlan?: Resolver<Array<ResolversTypes['FinancePlan']>, ParentType, ContextType, Partial<Query_RootFinancePlanArgs>>;
  FinancePlan_aggregate?: Resolver<ResolversTypes['FinancePlan_aggregate'], ParentType, ContextType, Partial<Query_RootFinancePlan_AggregateArgs>>;
  FinancePlan_by_pk?: Resolver<Maybe<ResolversTypes['FinancePlan']>, ParentType, ContextType, RequireFields<Query_RootFinancePlan_By_PkArgs, 'id'>>;
  FirmwareContent?: Resolver<Array<ResolversTypes['FirmwareContent']>, ParentType, ContextType, Partial<Query_RootFirmwareContentArgs>>;
  FirmwareContent_aggregate?: Resolver<ResolversTypes['FirmwareContent_aggregate'], ParentType, ContextType, Partial<Query_RootFirmwareContent_AggregateArgs>>;
  FirmwareContent_by_pk?: Resolver<Maybe<ResolversTypes['FirmwareContent']>, ParentType, ContextType, RequireFields<Query_RootFirmwareContent_By_PkArgs, 'id'>>;
  GeneticDirectionWebhook?: Resolver<Array<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType, Partial<Query_RootGeneticDirectionWebhookArgs>>;
  GeneticDirectionWebhook_aggregate?: Resolver<ResolversTypes['GeneticDirectionWebhook_aggregate'], ParentType, ContextType, Partial<Query_RootGeneticDirectionWebhook_AggregateArgs>>;
  GeneticDirectionWebhook_by_pk?: Resolver<Maybe<ResolversTypes['GeneticDirectionWebhook']>, ParentType, ContextType, RequireFields<Query_RootGeneticDirectionWebhook_By_PkArgs, 'id'>>;
  GlobalFeatureControl?: Resolver<Array<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType, Partial<Query_RootGlobalFeatureControlArgs>>;
  GlobalFeatureControl_aggregate?: Resolver<ResolversTypes['GlobalFeatureControl_aggregate'], ParentType, ContextType, Partial<Query_RootGlobalFeatureControl_AggregateArgs>>;
  GlobalFeatureControl_by_pk?: Resolver<Maybe<ResolversTypes['GlobalFeatureControl']>, ParentType, ContextType, RequireFields<Query_RootGlobalFeatureControl_By_PkArgs, 'id'>>;
  Goal?: Resolver<Array<ResolversTypes['Goal']>, ParentType, ContextType, Partial<Query_RootGoalArgs>>;
  Goal_aggregate?: Resolver<ResolversTypes['Goal_aggregate'], ParentType, ContextType, Partial<Query_RootGoal_AggregateArgs>>;
  Goal_by_pk?: Resolver<Maybe<ResolversTypes['Goal']>, ParentType, ContextType, RequireFields<Query_RootGoal_By_PkArgs, 'id'>>;
  HabitDay?: Resolver<Array<ResolversTypes['HabitDay']>, ParentType, ContextType, Partial<Query_RootHabitDayArgs>>;
  HabitDay_aggregate?: Resolver<ResolversTypes['HabitDay_aggregate'], ParentType, ContextType, Partial<Query_RootHabitDay_AggregateArgs>>;
  HabitDay_by_pk?: Resolver<Maybe<ResolversTypes['HabitDay']>, ParentType, ContextType, RequireFields<Query_RootHabitDay_By_PkArgs, 'id'>>;
  HeartRateDiagnostics?: Resolver<Array<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, Partial<Query_RootHeartRateDiagnosticsArgs>>;
  HeartRateDiagnostics_aggregate?: Resolver<ResolversTypes['HeartRateDiagnostics_aggregate'], ParentType, ContextType, Partial<Query_RootHeartRateDiagnostics_AggregateArgs>>;
  HeartRateDiagnostics_by_pk?: Resolver<Maybe<ResolversTypes['HeartRateDiagnostics']>, ParentType, ContextType, RequireFields<Query_RootHeartRateDiagnostics_By_PkArgs, 'id'>>;
  Household?: Resolver<Array<ResolversTypes['Household']>, ParentType, ContextType, Partial<Query_RootHouseholdArgs>>;
  Household_aggregate?: Resolver<ResolversTypes['Household_aggregate'], ParentType, ContextType, Partial<Query_RootHousehold_AggregateArgs>>;
  Household_by_pk?: Resolver<Maybe<ResolversTypes['Household']>, ParentType, ContextType, RequireFields<Query_RootHousehold_By_PkArgs, 'id'>>;
  MarketingEmail?: Resolver<Array<ResolversTypes['MarketingEmail']>, ParentType, ContextType, Partial<Query_RootMarketingEmailArgs>>;
  MarketingEmail_aggregate?: Resolver<ResolversTypes['MarketingEmail_aggregate'], ParentType, ContextType, Partial<Query_RootMarketingEmail_AggregateArgs>>;
  MarketingEmail_by_pk?: Resolver<Maybe<ResolversTypes['MarketingEmail']>, ParentType, ContextType, RequireFields<Query_RootMarketingEmail_By_PkArgs, 'id'>>;
  Mastery?: Resolver<Array<ResolversTypes['Mastery']>, ParentType, ContextType, Partial<Query_RootMasteryArgs>>;
  Mastery_aggregate?: Resolver<ResolversTypes['Mastery_aggregate'], ParentType, ContextType, Partial<Query_RootMastery_AggregateArgs>>;
  Mastery_by_pk?: Resolver<Maybe<ResolversTypes['Mastery']>, ParentType, ContextType, RequireFields<Query_RootMastery_By_PkArgs, 'id'>>;
  OnboardingInfo?: Resolver<Array<ResolversTypes['OnboardingInfo']>, ParentType, ContextType, Partial<Query_RootOnboardingInfoArgs>>;
  OnboardingInfo_aggregate?: Resolver<ResolversTypes['OnboardingInfo_aggregate'], ParentType, ContextType, Partial<Query_RootOnboardingInfo_AggregateArgs>>;
  OnboardingInfo_by_pk?: Resolver<Maybe<ResolversTypes['OnboardingInfo']>, ParentType, ContextType, RequireFields<Query_RootOnboardingInfo_By_PkArgs, 'id'>>;
  OnboardingVideos?: Resolver<Array<ResolversTypes['OnboardingVideos']>, ParentType, ContextType, Partial<Query_RootOnboardingVideosArgs>>;
  OnboardingVideos_aggregate?: Resolver<ResolversTypes['OnboardingVideos_aggregate'], ParentType, ContextType, Partial<Query_RootOnboardingVideos_AggregateArgs>>;
  OnboardingVideos_by_pk?: Resolver<Maybe<ResolversTypes['OnboardingVideos']>, ParentType, ContextType, RequireFields<Query_RootOnboardingVideos_By_PkArgs, 'id'>>;
  Order?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType, Partial<Query_RootOrderArgs>>;
  OrderLineItem?: Resolver<Array<ResolversTypes['OrderLineItem']>, ParentType, ContextType, Partial<Query_RootOrderLineItemArgs>>;
  OrderLineItem_aggregate?: Resolver<ResolversTypes['OrderLineItem_aggregate'], ParentType, ContextType, Partial<Query_RootOrderLineItem_AggregateArgs>>;
  OrderLineItem_by_pk?: Resolver<Maybe<ResolversTypes['OrderLineItem']>, ParentType, ContextType, RequireFields<Query_RootOrderLineItem_By_PkArgs, 'id'>>;
  Order_aggregate?: Resolver<ResolversTypes['Order_aggregate'], ParentType, ContextType, Partial<Query_RootOrder_AggregateArgs>>;
  Order_by_pk?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<Query_RootOrder_By_PkArgs, 'id'>>;
  PaymentHistory?: Resolver<Array<ResolversTypes['PaymentHistory']>, ParentType, ContextType, Partial<Query_RootPaymentHistoryArgs>>;
  PaymentHistory_aggregate?: Resolver<ResolversTypes['PaymentHistory_aggregate'], ParentType, ContextType, Partial<Query_RootPaymentHistory_AggregateArgs>>;
  PaymentHistory_by_pk?: Resolver<Maybe<ResolversTypes['PaymentHistory']>, ParentType, ContextType, RequireFields<Query_RootPaymentHistory_By_PkArgs, 'id'>>;
  PlanDay?: Resolver<Array<ResolversTypes['PlanDay']>, ParentType, ContextType, Partial<Query_RootPlanDayArgs>>;
  PlanDay_aggregate?: Resolver<ResolversTypes['PlanDay_aggregate'], ParentType, ContextType, Partial<Query_RootPlanDay_AggregateArgs>>;
  PlanDay_by_pk?: Resolver<Maybe<ResolversTypes['PlanDay']>, ParentType, ContextType, RequireFields<Query_RootPlanDay_By_PkArgs, 'id'>>;
  PresetDiet?: Resolver<Array<ResolversTypes['PresetDiet']>, ParentType, ContextType, Partial<Query_RootPresetDietArgs>>;
  PresetDiet_aggregate?: Resolver<ResolversTypes['PresetDiet_aggregate'], ParentType, ContextType, Partial<Query_RootPresetDiet_AggregateArgs>>;
  PresetDiet_by_pk?: Resolver<Maybe<ResolversTypes['PresetDiet']>, ParentType, ContextType, RequireFields<Query_RootPresetDiet_By_PkArgs, 'id'>>;
  Pro?: Resolver<Array<ResolversTypes['Pro']>, ParentType, ContextType, Partial<Query_RootProArgs>>;
  ProMusic?: Resolver<Array<ResolversTypes['ProMusic']>, ParentType, ContextType, Partial<Query_RootProMusicArgs>>;
  ProMusic_aggregate?: Resolver<ResolversTypes['ProMusic_aggregate'], ParentType, ContextType, Partial<Query_RootProMusic_AggregateArgs>>;
  ProMusic_by_pk?: Resolver<Maybe<ResolversTypes['ProMusic']>, ParentType, ContextType, RequireFields<Query_RootProMusic_By_PkArgs, 'id'>>;
  Pro_aggregate?: Resolver<ResolversTypes['Pro_aggregate'], ParentType, ContextType, Partial<Query_RootPro_AggregateArgs>>;
  Pro_by_pk?: Resolver<Maybe<ResolversTypes['Pro']>, ParentType, ContextType, RequireFields<Query_RootPro_By_PkArgs, 'id'>>;
  Product?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, Partial<Query_RootProductArgs>>;
  ProductLog?: Resolver<Array<ResolversTypes['ProductLog']>, ParentType, ContextType, Partial<Query_RootProductLogArgs>>;
  ProductLog_aggregate?: Resolver<ResolversTypes['ProductLog_aggregate'], ParentType, ContextType, Partial<Query_RootProductLog_AggregateArgs>>;
  ProductLog_by_pk?: Resolver<Maybe<ResolversTypes['ProductLog']>, ParentType, ContextType, RequireFields<Query_RootProductLog_By_PkArgs, 'id'>>;
  Product_aggregate?: Resolver<ResolversTypes['Product_aggregate'], ParentType, ContextType, Partial<Query_RootProduct_AggregateArgs>>;
  Product_by_pk?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<Query_RootProduct_By_PkArgs, 'id'>>;
  PromotionalItem?: Resolver<Array<ResolversTypes['PromotionalItem']>, ParentType, ContextType, Partial<Query_RootPromotionalItemArgs>>;
  PromotionalItem_aggregate?: Resolver<ResolversTypes['PromotionalItem_aggregate'], ParentType, ContextType, Partial<Query_RootPromotionalItem_AggregateArgs>>;
  PromotionalItem_by_pk?: Resolver<Maybe<ResolversTypes['PromotionalItem']>, ParentType, ContextType, RequireFields<Query_RootPromotionalItem_By_PkArgs, 'id'>>;
  QuizSurvey?: Resolver<Array<ResolversTypes['QuizSurvey']>, ParentType, ContextType, Partial<Query_RootQuizSurveyArgs>>;
  QuizSurvey_aggregate?: Resolver<ResolversTypes['QuizSurvey_aggregate'], ParentType, ContextType, Partial<Query_RootQuizSurvey_AggregateArgs>>;
  QuizSurvey_by_pk?: Resolver<Maybe<ResolversTypes['QuizSurvey']>, ParentType, ContextType, RequireFields<Query_RootQuizSurvey_By_PkArgs, 'id'>>;
  Quote?: Resolver<Array<ResolversTypes['Quote']>, ParentType, ContextType, Partial<Query_RootQuoteArgs>>;
  Quote_aggregate?: Resolver<ResolversTypes['Quote_aggregate'], ParentType, ContextType, Partial<Query_RootQuote_AggregateArgs>>;
  Quote_by_pk?: Resolver<Maybe<ResolversTypes['Quote']>, ParentType, ContextType, RequireFields<Query_RootQuote_By_PkArgs, 'id'>>;
  ReimbursementRequirement?: Resolver<Array<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType, Partial<Query_RootReimbursementRequirementArgs>>;
  ReimbursementRequirement_aggregate?: Resolver<ResolversTypes['ReimbursementRequirement_aggregate'], ParentType, ContextType, Partial<Query_RootReimbursementRequirement_AggregateArgs>>;
  ReimbursementRequirement_by_pk?: Resolver<Maybe<ResolversTypes['ReimbursementRequirement']>, ParentType, ContextType, RequireFields<Query_RootReimbursementRequirement_By_PkArgs, 'id'>>;
  ScreenOrder?: Resolver<Array<ResolversTypes['ScreenOrder']>, ParentType, ContextType, Partial<Query_RootScreenOrderArgs>>;
  ScreenOrder_aggregate?: Resolver<ResolversTypes['ScreenOrder_aggregate'], ParentType, ContextType, Partial<Query_RootScreenOrder_AggregateArgs>>;
  ScreenOrder_by_pk?: Resolver<Maybe<ResolversTypes['ScreenOrder']>, ParentType, ContextType, RequireFields<Query_RootScreenOrder_By_PkArgs, 'id'>>;
  SearchResult?: Resolver<Array<ResolversTypes['SearchResult']>, ParentType, ContextType, Partial<Query_RootSearchResultArgs>>;
  SearchResult_aggregate?: Resolver<ResolversTypes['SearchResult_aggregate'], ParentType, ContextType, Partial<Query_RootSearchResult_AggregateArgs>>;
  SearchResult_by_pk?: Resolver<Maybe<ResolversTypes['SearchResult']>, ParentType, ContextType, RequireFields<Query_RootSearchResult_By_PkArgs, 'id'>>;
  SleepSurvey?: Resolver<Array<ResolversTypes['SleepSurvey']>, ParentType, ContextType, Partial<Query_RootSleepSurveyArgs>>;
  SleepSurvey_aggregate?: Resolver<ResolversTypes['SleepSurvey_aggregate'], ParentType, ContextType, Partial<Query_RootSleepSurvey_AggregateArgs>>;
  SleepSurvey_by_pk?: Resolver<Maybe<ResolversTypes['SleepSurvey']>, ParentType, ContextType, RequireFields<Query_RootSleepSurvey_By_PkArgs, 'id'>>;
  Subscription?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType, Partial<Query_RootSubscriptionArgs>>;
  SubscriptionLog?: Resolver<Array<ResolversTypes['SubscriptionLog']>, ParentType, ContextType, Partial<Query_RootSubscriptionLogArgs>>;
  SubscriptionLog_aggregate?: Resolver<ResolversTypes['SubscriptionLog_aggregate'], ParentType, ContextType, Partial<Query_RootSubscriptionLog_AggregateArgs>>;
  SubscriptionLog_by_pk?: Resolver<Maybe<ResolversTypes['SubscriptionLog']>, ParentType, ContextType, RequireFields<Query_RootSubscriptionLog_By_PkArgs, 'id'>>;
  Subscription_aggregate?: Resolver<ResolversTypes['Subscription_aggregate'], ParentType, ContextType, Partial<Query_RootSubscription_AggregateArgs>>;
  Subscription_by_pk?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType, RequireFields<Query_RootSubscription_By_PkArgs, 'id'>>;
  Token?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, Partial<Query_RootTokenArgs>>;
  TokenHistory?: Resolver<Array<ResolversTypes['TokenHistory']>, ParentType, ContextType, Partial<Query_RootTokenHistoryArgs>>;
  TokenHistory_aggregate?: Resolver<ResolversTypes['TokenHistory_aggregate'], ParentType, ContextType, Partial<Query_RootTokenHistory_AggregateArgs>>;
  TokenHistory_by_pk?: Resolver<Maybe<ResolversTypes['TokenHistory']>, ParentType, ContextType, RequireFields<Query_RootTokenHistory_By_PkArgs, 'id'>>;
  Token_aggregate?: Resolver<ResolversTypes['Token_aggregate'], ParentType, ContextType, Partial<Query_RootToken_AggregateArgs>>;
  Token_by_pk?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<Query_RootToken_By_PkArgs, 'id'>>;
  User?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<Query_RootUserArgs>>;
  UserAggregates?: Resolver<Array<ResolversTypes['UserAggregates']>, ParentType, ContextType, Partial<Query_RootUserAggregatesArgs>>;
  UserAggregates_aggregate?: Resolver<ResolversTypes['UserAggregates_aggregate'], ParentType, ContextType, Partial<Query_RootUserAggregates_AggregateArgs>>;
  UserAggregates_by_pk?: Resolver<Maybe<ResolversTypes['UserAggregates']>, ParentType, ContextType, RequireFields<Query_RootUserAggregates_By_PkArgs, 'id'>>;
  UserAssessment?: Resolver<Array<ResolversTypes['UserAssessment']>, ParentType, ContextType, Partial<Query_RootUserAssessmentArgs>>;
  UserAssessment_aggregate?: Resolver<ResolversTypes['UserAssessment_aggregate'], ParentType, ContextType, Partial<Query_RootUserAssessment_AggregateArgs>>;
  UserAssessment_by_pk?: Resolver<Maybe<ResolversTypes['UserAssessment']>, ParentType, ContextType, RequireFields<Query_RootUserAssessment_By_PkArgs, 'id'>>;
  UserFeatureControl?: Resolver<Array<ResolversTypes['UserFeatureControl']>, ParentType, ContextType, Partial<Query_RootUserFeatureControlArgs>>;
  UserFeatureControl_aggregate?: Resolver<ResolversTypes['UserFeatureControl_aggregate'], ParentType, ContextType, Partial<Query_RootUserFeatureControl_AggregateArgs>>;
  UserFeatureControl_by_pk?: Resolver<Maybe<ResolversTypes['UserFeatureControl']>, ParentType, ContextType, RequireFields<Query_RootUserFeatureControl_By_PkArgs, 'id'>>;
  UserFeedback?: Resolver<Array<ResolversTypes['UserFeedback']>, ParentType, ContextType, Partial<Query_RootUserFeedbackArgs>>;
  UserFeedback_aggregate?: Resolver<ResolversTypes['UserFeedback_aggregate'], ParentType, ContextType, Partial<Query_RootUserFeedback_AggregateArgs>>;
  UserFeedback_by_pk?: Resolver<Maybe<ResolversTypes['UserFeedback']>, ParentType, ContextType, RequireFields<Query_RootUserFeedback_By_PkArgs, 'id'>>;
  UserFinancePlan?: Resolver<Array<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, Partial<Query_RootUserFinancePlanArgs>>;
  UserFinancePlan_aggregate?: Resolver<ResolversTypes['UserFinancePlan_aggregate'], ParentType, ContextType, Partial<Query_RootUserFinancePlan_AggregateArgs>>;
  UserFinancePlan_by_pk?: Resolver<Maybe<ResolversTypes['UserFinancePlan']>, ParentType, ContextType, RequireFields<Query_RootUserFinancePlan_By_PkArgs, 'id'>>;
  UserHabit?: Resolver<Array<ResolversTypes['UserHabit']>, ParentType, ContextType, Partial<Query_RootUserHabitArgs>>;
  UserHabit_aggregate?: Resolver<ResolversTypes['UserHabit_aggregate'], ParentType, ContextType, Partial<Query_RootUserHabit_AggregateArgs>>;
  UserHabit_by_pk?: Resolver<Maybe<ResolversTypes['UserHabit']>, ParentType, ContextType, RequireFields<Query_RootUserHabit_By_PkArgs, 'id'>>;
  UserMovementDaily?: Resolver<Array<ResolversTypes['UserMovementDaily']>, ParentType, ContextType, Partial<Query_RootUserMovementDailyArgs>>;
  UserMovementDaily_aggregate?: Resolver<ResolversTypes['UserMovementDaily_aggregate'], ParentType, ContextType, Partial<Query_RootUserMovementDaily_AggregateArgs>>;
  UserMovementDaily_by_pk?: Resolver<Maybe<ResolversTypes['UserMovementDaily']>, ParentType, ContextType, RequireFields<Query_RootUserMovementDaily_By_PkArgs, 'id'>>;
  UserPreferences?: Resolver<Array<ResolversTypes['UserPreferences']>, ParentType, ContextType, Partial<Query_RootUserPreferencesArgs>>;
  UserPreferences_aggregate?: Resolver<ResolversTypes['UserPreferences_aggregate'], ParentType, ContextType, Partial<Query_RootUserPreferences_AggregateArgs>>;
  UserPreferences_by_pk?: Resolver<Maybe<ResolversTypes['UserPreferences']>, ParentType, ContextType, RequireFields<Query_RootUserPreferences_By_PkArgs, 'id'>>;
  UserProduct?: Resolver<Array<ResolversTypes['UserProduct']>, ParentType, ContextType, Partial<Query_RootUserProductArgs>>;
  UserProductHistory?: Resolver<Array<ResolversTypes['UserProductHistory']>, ParentType, ContextType, Partial<Query_RootUserProductHistoryArgs>>;
  UserProductHistory_aggregate?: Resolver<ResolversTypes['UserProductHistory_aggregate'], ParentType, ContextType, Partial<Query_RootUserProductHistory_AggregateArgs>>;
  UserProductHistory_by_pk?: Resolver<Maybe<ResolversTypes['UserProductHistory']>, ParentType, ContextType, RequireFields<Query_RootUserProductHistory_By_PkArgs, 'id'>>;
  UserProduct_aggregate?: Resolver<ResolversTypes['UserProduct_aggregate'], ParentType, ContextType, Partial<Query_RootUserProduct_AggregateArgs>>;
  UserProduct_by_pk?: Resolver<Maybe<ResolversTypes['UserProduct']>, ParentType, ContextType, RequireFields<Query_RootUserProduct_By_PkArgs, 'id'>>;
  UserReadiness?: Resolver<Array<ResolversTypes['UserReadiness']>, ParentType, ContextType, Partial<Query_RootUserReadinessArgs>>;
  UserReadinessLog?: Resolver<Array<ResolversTypes['UserReadinessLog']>, ParentType, ContextType, Partial<Query_RootUserReadinessLogArgs>>;
  UserReadinessLog_aggregate?: Resolver<ResolversTypes['UserReadinessLog_aggregate'], ParentType, ContextType, Partial<Query_RootUserReadinessLog_AggregateArgs>>;
  UserReadinessLog_by_pk?: Resolver<Maybe<ResolversTypes['UserReadinessLog']>, ParentType, ContextType, RequireFields<Query_RootUserReadinessLog_By_PkArgs, 'id'>>;
  UserReadiness_aggregate?: Resolver<ResolversTypes['UserReadiness_aggregate'], ParentType, ContextType, Partial<Query_RootUserReadiness_AggregateArgs>>;
  UserReadiness_by_pk?: Resolver<Maybe<ResolversTypes['UserReadiness']>, ParentType, ContextType, RequireFields<Query_RootUserReadiness_By_PkArgs, 'id'>>;
  UserReimbursmentHistory?: Resolver<Array<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType, Partial<Query_RootUserReimbursmentHistoryArgs>>;
  UserReimbursmentHistory_aggregate?: Resolver<ResolversTypes['UserReimbursmentHistory_aggregate'], ParentType, ContextType, Partial<Query_RootUserReimbursmentHistory_AggregateArgs>>;
  UserReimbursmentHistory_by_pk?: Resolver<Maybe<ResolversTypes['UserReimbursmentHistory']>, ParentType, ContextType, RequireFields<Query_RootUserReimbursmentHistory_By_PkArgs, 'id'>>;
  UserRep?: Resolver<Array<ResolversTypes['UserRep']>, ParentType, ContextType, Partial<Query_RootUserRepArgs>>;
  UserRep_aggregate?: Resolver<ResolversTypes['UserRep_aggregate'], ParentType, ContextType, Partial<Query_RootUserRep_AggregateArgs>>;
  UserRep_by_pk?: Resolver<Maybe<ResolversTypes['UserRep']>, ParentType, ContextType, RequireFields<Query_RootUserRep_By_PkArgs, 'id'>>;
  UserSet?: Resolver<Array<ResolversTypes['UserSet']>, ParentType, ContextType, Partial<Query_RootUserSetArgs>>;
  UserSet_aggregate?: Resolver<ResolversTypes['UserSet_aggregate'], ParentType, ContextType, Partial<Query_RootUserSet_AggregateArgs>>;
  UserSet_by_pk?: Resolver<Maybe<ResolversTypes['UserSet']>, ParentType, ContextType, RequireFields<Query_RootUserSet_By_PkArgs, 'id'>>;
  UserState?: Resolver<Array<ResolversTypes['UserState']>, ParentType, ContextType, Partial<Query_RootUserStateArgs>>;
  UserState_aggregate?: Resolver<ResolversTypes['UserState_aggregate'], ParentType, ContextType, Partial<Query_RootUserState_AggregateArgs>>;
  UserState_by_pk?: Resolver<Maybe<ResolversTypes['UserState']>, ParentType, ContextType, RequireFields<Query_RootUserState_By_PkArgs, 'id'>>;
  UserTrainingPlan?: Resolver<Array<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType, Partial<Query_RootUserTrainingPlanArgs>>;
  UserTrainingPlan_aggregate?: Resolver<ResolversTypes['UserTrainingPlan_aggregate'], ParentType, ContextType, Partial<Query_RootUserTrainingPlan_AggregateArgs>>;
  UserTrainingPlan_by_pk?: Resolver<Maybe<ResolversTypes['UserTrainingPlan']>, ParentType, ContextType, RequireFields<Query_RootUserTrainingPlan_By_PkArgs, 'id'>>;
  UserWorkout?: Resolver<Array<ResolversTypes['UserWorkout']>, ParentType, ContextType, Partial<Query_RootUserWorkoutArgs>>;
  UserWorkout_aggregate?: Resolver<ResolversTypes['UserWorkout_aggregate'], ParentType, ContextType, Partial<Query_RootUserWorkout_AggregateArgs>>;
  UserWorkout_by_pk?: Resolver<Maybe<ResolversTypes['UserWorkout']>, ParentType, ContextType, RequireFields<Query_RootUserWorkout_By_PkArgs, 'id'>>;
  User_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<Query_RootUser_AggregateArgs>>;
  User_by_pk?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<Query_RootUser_By_PkArgs, 'id'>>;
  VendorErrorLog?: Resolver<Array<ResolversTypes['VendorErrorLog']>, ParentType, ContextType, Partial<Query_RootVendorErrorLogArgs>>;
  VendorErrorLog_aggregate?: Resolver<ResolversTypes['VendorErrorLog_aggregate'], ParentType, ContextType, Partial<Query_RootVendorErrorLog_AggregateArgs>>;
  VendorErrorLog_by_pk?: Resolver<Maybe<ResolversTypes['VendorErrorLog']>, ParentType, ContextType, RequireFields<Query_RootVendorErrorLog_By_PkArgs, 'id'>>;
  VersionControl?: Resolver<Array<ResolversTypes['VersionControl']>, ParentType, ContextType, Partial<Query_RootVersionControlArgs>>;
  VersionControl_aggregate?: Resolver<ResolversTypes['VersionControl_aggregate'], ParentType, ContextType, Partial<Query_RootVersionControl_AggregateArgs>>;
  VersionControl_by_pk?: Resolver<Maybe<ResolversTypes['VersionControl']>, ParentType, ContextType, RequireFields<Query_RootVersionControl_By_PkArgs, 'id'>>;
  WeeklyPlan?: Resolver<Array<ResolversTypes['WeeklyPlan']>, ParentType, ContextType, Partial<Query_RootWeeklyPlanArgs>>;
  WeeklyPlan_aggregate?: Resolver<ResolversTypes['WeeklyPlan_aggregate'], ParentType, ContextType, Partial<Query_RootWeeklyPlan_AggregateArgs>>;
  WeeklyPlan_by_pk?: Resolver<Maybe<ResolversTypes['WeeklyPlan']>, ParentType, ContextType, RequireFields<Query_RootWeeklyPlan_By_PkArgs, 'id'>>;
  WorkinPrefrence?: Resolver<Array<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType, Partial<Query_RootWorkinPrefrenceArgs>>;
  WorkinPrefrence_aggregate?: Resolver<ResolversTypes['WorkinPrefrence_aggregate'], ParentType, ContextType, Partial<Query_RootWorkinPrefrence_AggregateArgs>>;
  WorkinPrefrence_by_pk?: Resolver<Maybe<ResolversTypes['WorkinPrefrence']>, ParentType, ContextType, RequireFields<Query_RootWorkinPrefrence_By_PkArgs, 'id'>>;
  Workout?: Resolver<Array<ResolversTypes['Workout']>, ParentType, ContextType, Partial<Query_RootWorkoutArgs>>;
  WorkoutPrefrence?: Resolver<Array<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType, Partial<Query_RootWorkoutPrefrenceArgs>>;
  WorkoutPrefrence_aggregate?: Resolver<ResolversTypes['WorkoutPrefrence_aggregate'], ParentType, ContextType, Partial<Query_RootWorkoutPrefrence_AggregateArgs>>;
  WorkoutPrefrence_by_pk?: Resolver<Maybe<ResolversTypes['WorkoutPrefrence']>, ParentType, ContextType, RequireFields<Query_RootWorkoutPrefrence_By_PkArgs, 'id'>>;
  Workout_aggregate?: Resolver<ResolversTypes['Workout_aggregate'], ParentType, ContextType, Partial<Query_RootWorkout_AggregateArgs>>;
  Workout_by_pk?: Resolver<Maybe<ResolversTypes['Workout']>, ParentType, ContextType, RequireFields<Query_RootWorkout_By_PkArgs, 'id'>>;
  _FollowingPro?: Resolver<Array<ResolversTypes['_FollowingPro']>, ParentType, ContextType, Partial<Query_Root_FollowingProArgs>>;
  _FollowingPro_aggregate?: Resolver<ResolversTypes['_FollowingPro_aggregate'], ParentType, ContextType, Partial<Query_Root_FollowingPro_AggregateArgs>>;
  _GoalToUserPreferences?: Resolver<Array<ResolversTypes['_GoalToUserPreferences']>, ParentType, ContextType, Partial<Query_Root_GoalToUserPreferencesArgs>>;
  _GoalToUserPreferences_aggregate?: Resolver<ResolversTypes['_GoalToUserPreferences_aggregate'], ParentType, ContextType, Partial<Query_Root_GoalToUserPreferences_AggregateArgs>>;
  activityInsights?: Resolver<Maybe<ResolversTypes['ActivityInsightsOutput']>, ParentType, ContextType, RequireFields<Query_RootActivityInsightsArgs, 'startDate' | 'stopDate' | 'userId'>>;
  activityInsightsDetail?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActivityInsightsDetailOutput']>>>, ParentType, ContextType, RequireFields<Query_RootActivityInsightsDetailArgs, 'metric' | 'startDate' | 'stopDate' | 'userId'>>;
  assessmentHistory?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootAssessmentHistoryArgs, 'start_epoch' | 'user_id' | 'view'>>;
  assessmentHistoryDetail?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootAssessmentHistoryDetailArgs, 'assessment_type' | 'start_epoch' | 'user_id'>>;
  checkCompleteDNAResults?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  checkDnaKit?: Resolver<Maybe<ResolversTypes['CheckDNAKitOutput']>, ParentType, ContextType, Partial<Query_RootCheckDnaKitArgs>>;
  checkDnaKitEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<Query_RootCheckDnaKitEmailArgs, 'email'>>;
  checkNutritionReportReleased?: Resolver<Maybe<ResolversTypes['CheckNutritionReportReleasedOutput']>, ParentType, ContextType>;
  checkPresetPlanStructure?: Resolver<Maybe<ResolversTypes['CheckPresetPlanStructureOutput']>, ParentType, ContextType, Partial<Query_RootCheckPresetPlanStructureArgs>>;
  currentActivityProgress?: Resolver<Maybe<ResolversTypes['ActivityProgressOutput']>, ParentType, ContextType, RequireFields<Query_RootCurrentActivityProgressArgs, 'date' | 'userId'>>;
  discountCodeDetails?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootDiscountCodeDetailsArgs, 'discountCode' | 'productId'>>;
  emailHasDNAKit?: Resolver<Maybe<ResolversTypes['EmailHasKitOutput']>, ParentType, ContextType, RequireFields<Query_RootEmailHasDnaKitArgs, 'email'>>;
  fetchActivity?: Resolver<Maybe<ResolversTypes['fetchActivityResult']>, ParentType, ContextType, RequireFields<Query_RootFetchActivityArgs, 'startDate' | 'stopDate' | 'userId'>>;
  fetchBioStrapAssignment?: Resolver<Maybe<Array<Maybe<ResolversTypes['idObj']>>>, ParentType, ContextType, RequireFields<Query_RootFetchBioStrapAssignmentArgs, 'biostrapName'>>;
  fetchCalendlyLink?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  fetchCoachBySfId?: Resolver<Maybe<ResolversTypes['FetchCoachOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchCoachBySfIdArgs, 'sf_coach_id'>>;
  fetchCoachedPlan?: Resolver<Maybe<ResolversTypes['FetchCoachedPlanOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchCoachedPlanArgs, 'user_id'>>;
  fetchCoaches?: Resolver<Maybe<Array<Maybe<ResolversTypes['FetchCoachOutput']>>>, ParentType, ContextType>;
  fetchCookBook?: Resolver<Maybe<ResolversTypes['FetchCookBookOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchCookBookArgs, 'user_id'>>;
  fetchFeatureFlags?: Resolver<Maybe<ResolversTypes['fetchFeatureFlagsOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchFeatureFlagsArgs, 'user_id'>>;
  fetchFirmwareContentV2?: Resolver<Maybe<ResolversTypes['genericListOutput']>, ParentType, ContextType, Partial<Query_RootFetchFirmwareContentV2Args>>;
  fetchHousehold?: Resolver<Array<Maybe<ResolversTypes['HouseholdOutput']>>, ParentType, ContextType, RequireFields<Query_RootFetchHouseholdArgs, 'household_id'>>;
  fetchHouseholdCreditHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditHistoryOutput']>>>, ParentType, ContextType, RequireFields<Query_RootFetchHouseholdCreditHistoryArgs, 'householdId' | 'monthTimestamp'>>;
  fetchMealPlan?: Resolver<Maybe<ResolversTypes['MealPlanOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchMealPlanArgs, 'meal_plan_id'>>;
  fetchMealPlanPreferenceMap?: Resolver<Maybe<ResolversTypes['MealPlanPreferenceOutput']>, ParentType, ContextType, Partial<Query_RootFetchMealPlanPreferenceMapArgs>>;
  fetchMobileVersions?: Resolver<Maybe<ResolversTypes['mobileVersionsOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchMobileVersionsArgs, 'userId'>>;
  fetchNutritionReport?: Resolver<Maybe<ResolversTypes['NutritionReportOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchNutritionReportArgs, 'userId'>>;
  fetchPearWorkout?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootFetchPearWorkoutArgs, 'sku'>>;
  fetchPlanProgress?: Resolver<ResolversTypes['PlanProgressOutput'], ParentType, ContextType, RequireFields<Query_RootFetchPlanProgressArgs, 'user_id'>>;
  fetchPlans?: Resolver<Maybe<Array<Maybe<ResolversTypes['fetchPlansOutput']>>>, ParentType, ContextType, RequireFields<Query_RootFetchPlansArgs, 'start_date' | 'user_id'>>;
  fetchRingAssignment?: Resolver<Maybe<Array<Maybe<ResolversTypes['idObj']>>>, ParentType, ContextType, RequireFields<Query_RootFetchRingAssignmentArgs, 'ringName'>>;
  fetchUserMonthsActive?: Resolver<Maybe<ResolversTypes['UserMonthsActiveOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchUserMonthsActiveArgs, 'userId'>>;
  fetchUserShippingAddress?: Resolver<Maybe<ResolversTypes['ShippingAddressOutput']>, ParentType, ContextType, RequireFields<Query_RootFetchUserShippingAddressArgs, 'userId'>>;
  fetchWorkoutOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['FetchWorkoutOptionsOutput']>>>, ParentType, ContextType, RequireFields<Query_RootFetchWorkoutOptionsArgs, 'sub_type' | 'user_id' | 'workout_type'>>;
  fetchWorkoutOptionsV2?: Resolver<Maybe<Array<Maybe<ResolversTypes['FetchWorkoutOptionsOutputV2']>>>, ParentType, ContextType, RequireFields<Query_RootFetchWorkoutOptionsV2Args, 'sub_type' | 'user_id' | 'workout_type'>>;
  fetchWorkoutOptionsV3?: Resolver<Maybe<Array<Maybe<ResolversTypes['FetchWorkoutOptionsOutputV3']>>>, ParentType, ContextType, RequireFields<Query_RootFetchWorkoutOptionsV3Args, 'sub_type' | 'user_id' | 'workout_type'>>;
  findManyPresetPlans?: Resolver<Maybe<ResolversTypes['ResultListOutput']>, ParentType, ContextType, Partial<Query_RootFindManyPresetPlansArgs>>;
  fitnessRecommender?: Resolver<Maybe<Array<Maybe<ResolversTypes['FitnessRecommenderOutput']>>>, ParentType, ContextType, RequireFields<Query_RootFitnessRecommenderArgs, 'sub_type' | 'user_id' | 'workout_type'>>;
  getAllUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['AllUsersOutput']>>>, ParentType, ContextType>;
  getHRZoneBounds?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootGetHrZoneBoundsArgs, 'user_id'>>;
  getHouseholdMembers?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  getHrZone2?: Resolver<Maybe<ResolversTypes['HrZone2Output']>, ParentType, ContextType, RequireFields<Query_RootGetHrZone2Args, 'userId'>>;
  getSFCoachId?: Resolver<Maybe<ResolversTypes['getSFCoachIdOutput']>, ParentType, ContextType, RequireFields<Query_RootGetSfCoachIdArgs, 'userId'>>;
  getSfCoachCall?: Resolver<Maybe<ResolversTypes['SfCoachCallOutput']>, ParentType, ContextType, RequireFields<Query_RootGetSfCoachCallArgs, 'userId'>>;
  getSfFitnessGoal?: Resolver<Maybe<ResolversTypes['SfFitnessGoalOutput']>, ParentType, ContextType, RequireFields<Query_RootGetSfFitnessGoalArgs, 'userId'>>;
  getSubscription?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  getUserByAuth0Id?: Resolver<Maybe<ResolversTypes['getUserByAuth0IdOutput']>, ParentType, ContextType, RequireFields<Query_RootGetUserByAuth0IdArgs, 'auth0_id'>>;
  getUserMetadata?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootGetUserMetadataArgs, 'auth0UserId'>>;
  getUserProducts?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserProductsOutput']>>>, ParentType, ContextType, RequireFields<Query_RootGetUserProductsArgs, 'userId'>>;
  hi?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isValidKit?: Resolver<Maybe<ResolversTypes['IsValidKitOutput']>, ParentType, ContextType, RequireFields<Query_RootIsValidKitArgs, 'activationCode' | 'kitId'>>;
  myAlterProducts?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  planHistory?: Resolver<ResolversTypes['planHistoryOutput'], ParentType, ContextType, RequireFields<Query_RootPlanHistoryArgs, 'userId' | 'weekOffset'>>;
  profileStats?: Resolver<ResolversTypes['ProfileStatsOutput'], ParentType, ContextType, RequireFields<Query_RootProfileStatsArgs, 'user_id'>>;
  profileStatsDetail?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootProfileStatsDetailArgs, 'user_id'>>;
  quizSurveyLabels?: Resolver<Maybe<ResolversTypes['LabelsOutput']>, ParentType, ContextType>;
  recommendWorkoutByOption?: Resolver<Maybe<ResolversTypes['recommendWorkoutByOptionOutput']>, ParentType, ContextType, RequireFields<Query_RootRecommendWorkoutByOptionArgs, 'duration' | 'sub_type' | 'trainer' | 'user_id' | 'workout_type'>>;
  retrieveFirmwareV2?: Resolver<Maybe<ResolversTypes['RetrieveFirmwareV2Output']>, ParentType, ContextType, RequireFields<Query_RootRetrieveFirmwareV2Args, 'id'>>;
  sleepInsights?: Resolver<Maybe<ResolversTypes['SleepInsightsOutput']>, ParentType, ContextType, RequireFields<Query_RootSleepInsightsArgs, 'startDate' | 'stopDate' | 'userId'>>;
  sleepInsightsDetail?: Resolver<Maybe<ResolversTypes['SleepInsightsDetailOutput']>, ParentType, ContextType, RequireFields<Query_RootSleepInsightsDetailArgs, 'metric' | 'startDate' | 'stopDate' | 'userId'>>;
  testAdmin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  testCheckSleep?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootTestCheckSleepArgs, 'date' | 'start_modifier' | 'stop_modifier' | 'use_existing_start_stop' | 'user_id'>>;
  testFree?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  testPremium?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  testPro?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userNutritionReport?: Resolver<Maybe<ResolversTypes['userNutritionReportOutput']>, ParentType, ContextType, RequireFields<Query_RootUserNutritionReportArgs, 'userId'>>;
  workoutDetails?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootWorkoutDetailsArgs, 'workout_id' | 'workout_type'>>;
  workoutDetailsV2?: Resolver<ResolversTypes['json'], ParentType, ContextType, RequireFields<Query_RootWorkoutDetailsV2Args, 'workout_id'>>;
}>;

export type RecommendWorkoutByOptionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['recommendWorkoutByOptionOutput'] = ResolversParentTypes['recommendWorkoutByOptionOutput']> = ResolversObject<{
  audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration_minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extended_content?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAdaptive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sub_type?: Resolver<Maybe<ResolversTypes['WorkoutSubTypeEnum']>, ParentType, ContextType>;
  themes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workout_type?: Resolver<Maybe<ResolversTypes['WorkoutTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepAverageResolvers<ContextType = any, ParentType extends ResolversParentTypes['sleepAverage'] = ResolversParentTypes['sleepAverage']> = ResolversObject<{
  datePart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepInsightsDetailDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['sleepInsightsDetailData'] = ResolversParentTypes['sleepInsightsDetailData']> = ResolversObject<{
  awakeTime?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueAndTime']>>>, ParentType, ContextType>;
  hrVariability?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueTimeAndAverage']>>>, ParentType, ContextType>;
  restingHR?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueTimeAndMinimum']>>>, ParentType, ContextType>;
  score?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueAndTime']>>>, ParentType, ContextType>;
  sleep?: Resolver<Maybe<ResolversTypes['sleepV1']>, ParentType, ContextType>;
  sleepV2?: Resolver<Maybe<Array<Maybe<ResolversTypes['json']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SleepV1Resolvers<ContextType = any, ParentType extends ResolversParentTypes['sleepV1'] = ResolversParentTypes['sleepV1']> = ResolversObject<{
  awakeTime?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueAndTime']>>>, ParentType, ContextType>;
  deepSleep?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueAndTime']>>>, ParentType, ContextType>;
  lightSleep?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueAndTime']>>>, ParentType, ContextType>;
  sleepDuration?: Resolver<Maybe<Array<Maybe<ResolversTypes['ValueAndTime']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StartWorkoutOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['startWorkoutOutput'] = ResolversParentTypes['startWorkoutOutput']> = ResolversObject<{
  workout_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Subscription_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = ResolversObject<{
  ActivationCode?: SubscriptionResolver<Array<ResolversTypes['ActivationCode']>, "ActivationCode", ParentType, ContextType, Partial<Subscription_RootActivationCodeArgs>>;
  ActivationCode_aggregate?: SubscriptionResolver<ResolversTypes['ActivationCode_aggregate'], "ActivationCode_aggregate", ParentType, ContextType, Partial<Subscription_RootActivationCode_AggregateArgs>>;
  ActivationCode_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ActivationCode']>, "ActivationCode_by_pk", ParentType, ContextType, RequireFields<Subscription_RootActivationCode_By_PkArgs, 'id'>>;
  ActivationCode_stream?: SubscriptionResolver<Array<ResolversTypes['ActivationCode']>, "ActivationCode_stream", ParentType, ContextType, RequireFields<Subscription_RootActivationCode_StreamArgs, 'batch_size' | 'cursor'>>;
  AlterTypeColor?: SubscriptionResolver<Array<ResolversTypes['AlterTypeColor']>, "AlterTypeColor", ParentType, ContextType, Partial<Subscription_RootAlterTypeColorArgs>>;
  AlterTypeColor_aggregate?: SubscriptionResolver<ResolversTypes['AlterTypeColor_aggregate'], "AlterTypeColor_aggregate", ParentType, ContextType, Partial<Subscription_RootAlterTypeColor_AggregateArgs>>;
  AlterTypeColor_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['AlterTypeColor']>, "AlterTypeColor_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAlterTypeColor_By_PkArgs, 'letter'>>;
  AlterTypeColor_stream?: SubscriptionResolver<Array<ResolversTypes['AlterTypeColor']>, "AlterTypeColor_stream", ParentType, ContextType, RequireFields<Subscription_RootAlterTypeColor_StreamArgs, 'batch_size' | 'cursor'>>;
  Coach?: SubscriptionResolver<Array<ResolversTypes['Coach']>, "Coach", ParentType, ContextType, Partial<Subscription_RootCoachArgs>>;
  Coach_aggregate?: SubscriptionResolver<ResolversTypes['Coach_aggregate'], "Coach_aggregate", ParentType, ContextType, Partial<Subscription_RootCoach_AggregateArgs>>;
  Coach_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Coach']>, "Coach_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCoach_By_PkArgs, 'id'>>;
  Coach_stream?: SubscriptionResolver<Array<ResolversTypes['Coach']>, "Coach_stream", ParentType, ContextType, RequireFields<Subscription_RootCoach_StreamArgs, 'batch_size' | 'cursor'>>;
  CoachedHabit?: SubscriptionResolver<Array<ResolversTypes['CoachedHabit']>, "CoachedHabit", ParentType, ContextType, Partial<Subscription_RootCoachedHabitArgs>>;
  CoachedHabit_aggregate?: SubscriptionResolver<ResolversTypes['CoachedHabit_aggregate'], "CoachedHabit_aggregate", ParentType, ContextType, Partial<Subscription_RootCoachedHabit_AggregateArgs>>;
  CoachedHabit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CoachedHabit']>, "CoachedHabit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCoachedHabit_By_PkArgs, 'id'>>;
  CoachedHabit_stream?: SubscriptionResolver<Array<ResolversTypes['CoachedHabit']>, "CoachedHabit_stream", ParentType, ContextType, RequireFields<Subscription_RootCoachedHabit_StreamArgs, 'batch_size' | 'cursor'>>;
  CoachedNutrition?: SubscriptionResolver<Array<ResolversTypes['CoachedNutrition']>, "CoachedNutrition", ParentType, ContextType, Partial<Subscription_RootCoachedNutritionArgs>>;
  CoachedNutrition_aggregate?: SubscriptionResolver<ResolversTypes['CoachedNutrition_aggregate'], "CoachedNutrition_aggregate", ParentType, ContextType, Partial<Subscription_RootCoachedNutrition_AggregateArgs>>;
  CoachedNutrition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CoachedNutrition']>, "CoachedNutrition_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCoachedNutrition_By_PkArgs, 'id'>>;
  CoachedNutrition_stream?: SubscriptionResolver<Array<ResolversTypes['CoachedNutrition']>, "CoachedNutrition_stream", ParentType, ContextType, RequireFields<Subscription_RootCoachedNutrition_StreamArgs, 'batch_size' | 'cursor'>>;
  DNAKit?: SubscriptionResolver<Array<ResolversTypes['DNAKit']>, "DNAKit", ParentType, ContextType, Partial<Subscription_RootDnaKitArgs>>;
  DNAKit_aggregate?: SubscriptionResolver<ResolversTypes['DNAKit_aggregate'], "DNAKit_aggregate", ParentType, ContextType, Partial<Subscription_RootDnaKit_AggregateArgs>>;
  DNAKit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['DNAKit']>, "DNAKit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDnaKit_By_PkArgs, 'id'>>;
  DNAKit_stream?: SubscriptionResolver<Array<ResolversTypes['DNAKit']>, "DNAKit_stream", ParentType, ContextType, RequireFields<Subscription_RootDnaKit_StreamArgs, 'batch_size' | 'cursor'>>;
  DNAResults?: SubscriptionResolver<Array<ResolversTypes['DNAResults']>, "DNAResults", ParentType, ContextType, Partial<Subscription_RootDnaResultsArgs>>;
  DNAResults_aggregate?: SubscriptionResolver<ResolversTypes['DNAResults_aggregate'], "DNAResults_aggregate", ParentType, ContextType, Partial<Subscription_RootDnaResults_AggregateArgs>>;
  DNAResults_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['DNAResults']>, "DNAResults_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDnaResults_By_PkArgs, 'id'>>;
  DNAResults_stream?: SubscriptionResolver<Array<ResolversTypes['DNAResults']>, "DNAResults_stream", ParentType, ContextType, RequireFields<Subscription_RootDnaResults_StreamArgs, 'batch_size' | 'cursor'>>;
  Delivery?: SubscriptionResolver<Array<ResolversTypes['Delivery']>, "Delivery", ParentType, ContextType, Partial<Subscription_RootDeliveryArgs>>;
  DeliveryHistory?: SubscriptionResolver<Array<ResolversTypes['DeliveryHistory']>, "DeliveryHistory", ParentType, ContextType, Partial<Subscription_RootDeliveryHistoryArgs>>;
  DeliveryHistory_aggregate?: SubscriptionResolver<ResolversTypes['DeliveryHistory_aggregate'], "DeliveryHistory_aggregate", ParentType, ContextType, Partial<Subscription_RootDeliveryHistory_AggregateArgs>>;
  DeliveryHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['DeliveryHistory']>, "DeliveryHistory_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDeliveryHistory_By_PkArgs, 'id'>>;
  DeliveryHistory_stream?: SubscriptionResolver<Array<ResolversTypes['DeliveryHistory']>, "DeliveryHistory_stream", ParentType, ContextType, RequireFields<Subscription_RootDeliveryHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  Delivery_aggregate?: SubscriptionResolver<ResolversTypes['Delivery_aggregate'], "Delivery_aggregate", ParentType, ContextType, Partial<Subscription_RootDelivery_AggregateArgs>>;
  Delivery_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Delivery']>, "Delivery_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDelivery_By_PkArgs, 'id'>>;
  Delivery_stream?: SubscriptionResolver<Array<ResolversTypes['Delivery']>, "Delivery_stream", ParentType, ContextType, RequireFields<Subscription_RootDelivery_StreamArgs, 'batch_size' | 'cursor'>>;
  Device?: SubscriptionResolver<Array<ResolversTypes['Device']>, "Device", ParentType, ContextType, Partial<Subscription_RootDeviceArgs>>;
  Device_aggregate?: SubscriptionResolver<ResolversTypes['Device_aggregate'], "Device_aggregate", ParentType, ContextType, Partial<Subscription_RootDevice_AggregateArgs>>;
  Device_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Device']>, "Device_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDevice_By_PkArgs, 'id'>>;
  Device_stream?: SubscriptionResolver<Array<ResolversTypes['Device']>, "Device_stream", ParentType, ContextType, RequireFields<Subscription_RootDevice_StreamArgs, 'batch_size' | 'cursor'>>;
  Diagnostics?: SubscriptionResolver<Array<ResolversTypes['Diagnostics']>, "Diagnostics", ParentType, ContextType, Partial<Subscription_RootDiagnosticsArgs>>;
  Diagnostics_aggregate?: SubscriptionResolver<ResolversTypes['Diagnostics_aggregate'], "Diagnostics_aggregate", ParentType, ContextType, Partial<Subscription_RootDiagnostics_AggregateArgs>>;
  Diagnostics_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Diagnostics']>, "Diagnostics_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDiagnostics_By_PkArgs, 'id'>>;
  Diagnostics_stream?: SubscriptionResolver<Array<ResolversTypes['Diagnostics']>, "Diagnostics_stream", ParentType, ContextType, RequireFields<Subscription_RootDiagnostics_StreamArgs, 'batch_size' | 'cursor'>>;
  FinancePlan?: SubscriptionResolver<Array<ResolversTypes['FinancePlan']>, "FinancePlan", ParentType, ContextType, Partial<Subscription_RootFinancePlanArgs>>;
  FinancePlan_aggregate?: SubscriptionResolver<ResolversTypes['FinancePlan_aggregate'], "FinancePlan_aggregate", ParentType, ContextType, Partial<Subscription_RootFinancePlan_AggregateArgs>>;
  FinancePlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['FinancePlan']>, "FinancePlan_by_pk", ParentType, ContextType, RequireFields<Subscription_RootFinancePlan_By_PkArgs, 'id'>>;
  FinancePlan_stream?: SubscriptionResolver<Array<ResolversTypes['FinancePlan']>, "FinancePlan_stream", ParentType, ContextType, RequireFields<Subscription_RootFinancePlan_StreamArgs, 'batch_size' | 'cursor'>>;
  FirmwareContent?: SubscriptionResolver<Array<ResolversTypes['FirmwareContent']>, "FirmwareContent", ParentType, ContextType, Partial<Subscription_RootFirmwareContentArgs>>;
  FirmwareContent_aggregate?: SubscriptionResolver<ResolversTypes['FirmwareContent_aggregate'], "FirmwareContent_aggregate", ParentType, ContextType, Partial<Subscription_RootFirmwareContent_AggregateArgs>>;
  FirmwareContent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['FirmwareContent']>, "FirmwareContent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootFirmwareContent_By_PkArgs, 'id'>>;
  FirmwareContent_stream?: SubscriptionResolver<Array<ResolversTypes['FirmwareContent']>, "FirmwareContent_stream", ParentType, ContextType, RequireFields<Subscription_RootFirmwareContent_StreamArgs, 'batch_size' | 'cursor'>>;
  GeneticDirectionWebhook?: SubscriptionResolver<Array<ResolversTypes['GeneticDirectionWebhook']>, "GeneticDirectionWebhook", ParentType, ContextType, Partial<Subscription_RootGeneticDirectionWebhookArgs>>;
  GeneticDirectionWebhook_aggregate?: SubscriptionResolver<ResolversTypes['GeneticDirectionWebhook_aggregate'], "GeneticDirectionWebhook_aggregate", ParentType, ContextType, Partial<Subscription_RootGeneticDirectionWebhook_AggregateArgs>>;
  GeneticDirectionWebhook_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['GeneticDirectionWebhook']>, "GeneticDirectionWebhook_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGeneticDirectionWebhook_By_PkArgs, 'id'>>;
  GeneticDirectionWebhook_stream?: SubscriptionResolver<Array<ResolversTypes['GeneticDirectionWebhook']>, "GeneticDirectionWebhook_stream", ParentType, ContextType, RequireFields<Subscription_RootGeneticDirectionWebhook_StreamArgs, 'batch_size' | 'cursor'>>;
  GlobalFeatureControl?: SubscriptionResolver<Array<ResolversTypes['GlobalFeatureControl']>, "GlobalFeatureControl", ParentType, ContextType, Partial<Subscription_RootGlobalFeatureControlArgs>>;
  GlobalFeatureControl_aggregate?: SubscriptionResolver<ResolversTypes['GlobalFeatureControl_aggregate'], "GlobalFeatureControl_aggregate", ParentType, ContextType, Partial<Subscription_RootGlobalFeatureControl_AggregateArgs>>;
  GlobalFeatureControl_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['GlobalFeatureControl']>, "GlobalFeatureControl_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGlobalFeatureControl_By_PkArgs, 'id'>>;
  GlobalFeatureControl_stream?: SubscriptionResolver<Array<ResolversTypes['GlobalFeatureControl']>, "GlobalFeatureControl_stream", ParentType, ContextType, RequireFields<Subscription_RootGlobalFeatureControl_StreamArgs, 'batch_size' | 'cursor'>>;
  Goal?: SubscriptionResolver<Array<ResolversTypes['Goal']>, "Goal", ParentType, ContextType, Partial<Subscription_RootGoalArgs>>;
  Goal_aggregate?: SubscriptionResolver<ResolversTypes['Goal_aggregate'], "Goal_aggregate", ParentType, ContextType, Partial<Subscription_RootGoal_AggregateArgs>>;
  Goal_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Goal']>, "Goal_by_pk", ParentType, ContextType, RequireFields<Subscription_RootGoal_By_PkArgs, 'id'>>;
  Goal_stream?: SubscriptionResolver<Array<ResolversTypes['Goal']>, "Goal_stream", ParentType, ContextType, RequireFields<Subscription_RootGoal_StreamArgs, 'batch_size' | 'cursor'>>;
  HabitDay?: SubscriptionResolver<Array<ResolversTypes['HabitDay']>, "HabitDay", ParentType, ContextType, Partial<Subscription_RootHabitDayArgs>>;
  HabitDay_aggregate?: SubscriptionResolver<ResolversTypes['HabitDay_aggregate'], "HabitDay_aggregate", ParentType, ContextType, Partial<Subscription_RootHabitDay_AggregateArgs>>;
  HabitDay_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['HabitDay']>, "HabitDay_by_pk", ParentType, ContextType, RequireFields<Subscription_RootHabitDay_By_PkArgs, 'id'>>;
  HabitDay_stream?: SubscriptionResolver<Array<ResolversTypes['HabitDay']>, "HabitDay_stream", ParentType, ContextType, RequireFields<Subscription_RootHabitDay_StreamArgs, 'batch_size' | 'cursor'>>;
  HeartRateDiagnostics?: SubscriptionResolver<Array<ResolversTypes['HeartRateDiagnostics']>, "HeartRateDiagnostics", ParentType, ContextType, Partial<Subscription_RootHeartRateDiagnosticsArgs>>;
  HeartRateDiagnostics_aggregate?: SubscriptionResolver<ResolversTypes['HeartRateDiagnostics_aggregate'], "HeartRateDiagnostics_aggregate", ParentType, ContextType, Partial<Subscription_RootHeartRateDiagnostics_AggregateArgs>>;
  HeartRateDiagnostics_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['HeartRateDiagnostics']>, "HeartRateDiagnostics_by_pk", ParentType, ContextType, RequireFields<Subscription_RootHeartRateDiagnostics_By_PkArgs, 'id'>>;
  HeartRateDiagnostics_stream?: SubscriptionResolver<Array<ResolversTypes['HeartRateDiagnostics']>, "HeartRateDiagnostics_stream", ParentType, ContextType, RequireFields<Subscription_RootHeartRateDiagnostics_StreamArgs, 'batch_size' | 'cursor'>>;
  Household?: SubscriptionResolver<Array<ResolversTypes['Household']>, "Household", ParentType, ContextType, Partial<Subscription_RootHouseholdArgs>>;
  Household_aggregate?: SubscriptionResolver<ResolversTypes['Household_aggregate'], "Household_aggregate", ParentType, ContextType, Partial<Subscription_RootHousehold_AggregateArgs>>;
  Household_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Household']>, "Household_by_pk", ParentType, ContextType, RequireFields<Subscription_RootHousehold_By_PkArgs, 'id'>>;
  Household_stream?: SubscriptionResolver<Array<ResolversTypes['Household']>, "Household_stream", ParentType, ContextType, RequireFields<Subscription_RootHousehold_StreamArgs, 'batch_size' | 'cursor'>>;
  MarketingEmail?: SubscriptionResolver<Array<ResolversTypes['MarketingEmail']>, "MarketingEmail", ParentType, ContextType, Partial<Subscription_RootMarketingEmailArgs>>;
  MarketingEmail_aggregate?: SubscriptionResolver<ResolversTypes['MarketingEmail_aggregate'], "MarketingEmail_aggregate", ParentType, ContextType, Partial<Subscription_RootMarketingEmail_AggregateArgs>>;
  MarketingEmail_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['MarketingEmail']>, "MarketingEmail_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMarketingEmail_By_PkArgs, 'id'>>;
  MarketingEmail_stream?: SubscriptionResolver<Array<ResolversTypes['MarketingEmail']>, "MarketingEmail_stream", ParentType, ContextType, RequireFields<Subscription_RootMarketingEmail_StreamArgs, 'batch_size' | 'cursor'>>;
  Mastery?: SubscriptionResolver<Array<ResolversTypes['Mastery']>, "Mastery", ParentType, ContextType, Partial<Subscription_RootMasteryArgs>>;
  Mastery_aggregate?: SubscriptionResolver<ResolversTypes['Mastery_aggregate'], "Mastery_aggregate", ParentType, ContextType, Partial<Subscription_RootMastery_AggregateArgs>>;
  Mastery_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Mastery']>, "Mastery_by_pk", ParentType, ContextType, RequireFields<Subscription_RootMastery_By_PkArgs, 'id'>>;
  Mastery_stream?: SubscriptionResolver<Array<ResolversTypes['Mastery']>, "Mastery_stream", ParentType, ContextType, RequireFields<Subscription_RootMastery_StreamArgs, 'batch_size' | 'cursor'>>;
  OnboardingInfo?: SubscriptionResolver<Array<ResolversTypes['OnboardingInfo']>, "OnboardingInfo", ParentType, ContextType, Partial<Subscription_RootOnboardingInfoArgs>>;
  OnboardingInfo_aggregate?: SubscriptionResolver<ResolversTypes['OnboardingInfo_aggregate'], "OnboardingInfo_aggregate", ParentType, ContextType, Partial<Subscription_RootOnboardingInfo_AggregateArgs>>;
  OnboardingInfo_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['OnboardingInfo']>, "OnboardingInfo_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOnboardingInfo_By_PkArgs, 'id'>>;
  OnboardingInfo_stream?: SubscriptionResolver<Array<ResolversTypes['OnboardingInfo']>, "OnboardingInfo_stream", ParentType, ContextType, RequireFields<Subscription_RootOnboardingInfo_StreamArgs, 'batch_size' | 'cursor'>>;
  OnboardingVideos?: SubscriptionResolver<Array<ResolversTypes['OnboardingVideos']>, "OnboardingVideos", ParentType, ContextType, Partial<Subscription_RootOnboardingVideosArgs>>;
  OnboardingVideos_aggregate?: SubscriptionResolver<ResolversTypes['OnboardingVideos_aggregate'], "OnboardingVideos_aggregate", ParentType, ContextType, Partial<Subscription_RootOnboardingVideos_AggregateArgs>>;
  OnboardingVideos_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['OnboardingVideos']>, "OnboardingVideos_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOnboardingVideos_By_PkArgs, 'id'>>;
  OnboardingVideos_stream?: SubscriptionResolver<Array<ResolversTypes['OnboardingVideos']>, "OnboardingVideos_stream", ParentType, ContextType, RequireFields<Subscription_RootOnboardingVideos_StreamArgs, 'batch_size' | 'cursor'>>;
  Order?: SubscriptionResolver<Array<ResolversTypes['Order']>, "Order", ParentType, ContextType, Partial<Subscription_RootOrderArgs>>;
  OrderLineItem?: SubscriptionResolver<Array<ResolversTypes['OrderLineItem']>, "OrderLineItem", ParentType, ContextType, Partial<Subscription_RootOrderLineItemArgs>>;
  OrderLineItem_aggregate?: SubscriptionResolver<ResolversTypes['OrderLineItem_aggregate'], "OrderLineItem_aggregate", ParentType, ContextType, Partial<Subscription_RootOrderLineItem_AggregateArgs>>;
  OrderLineItem_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['OrderLineItem']>, "OrderLineItem_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOrderLineItem_By_PkArgs, 'id'>>;
  OrderLineItem_stream?: SubscriptionResolver<Array<ResolversTypes['OrderLineItem']>, "OrderLineItem_stream", ParentType, ContextType, RequireFields<Subscription_RootOrderLineItem_StreamArgs, 'batch_size' | 'cursor'>>;
  Order_aggregate?: SubscriptionResolver<ResolversTypes['Order_aggregate'], "Order_aggregate", ParentType, ContextType, Partial<Subscription_RootOrder_AggregateArgs>>;
  Order_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Order']>, "Order_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOrder_By_PkArgs, 'id'>>;
  Order_stream?: SubscriptionResolver<Array<ResolversTypes['Order']>, "Order_stream", ParentType, ContextType, RequireFields<Subscription_RootOrder_StreamArgs, 'batch_size' | 'cursor'>>;
  PaymentHistory?: SubscriptionResolver<Array<ResolversTypes['PaymentHistory']>, "PaymentHistory", ParentType, ContextType, Partial<Subscription_RootPaymentHistoryArgs>>;
  PaymentHistory_aggregate?: SubscriptionResolver<ResolversTypes['PaymentHistory_aggregate'], "PaymentHistory_aggregate", ParentType, ContextType, Partial<Subscription_RootPaymentHistory_AggregateArgs>>;
  PaymentHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PaymentHistory']>, "PaymentHistory_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPaymentHistory_By_PkArgs, 'id'>>;
  PaymentHistory_stream?: SubscriptionResolver<Array<ResolversTypes['PaymentHistory']>, "PaymentHistory_stream", ParentType, ContextType, RequireFields<Subscription_RootPaymentHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  PlanDay?: SubscriptionResolver<Array<ResolversTypes['PlanDay']>, "PlanDay", ParentType, ContextType, Partial<Subscription_RootPlanDayArgs>>;
  PlanDay_aggregate?: SubscriptionResolver<ResolversTypes['PlanDay_aggregate'], "PlanDay_aggregate", ParentType, ContextType, Partial<Subscription_RootPlanDay_AggregateArgs>>;
  PlanDay_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PlanDay']>, "PlanDay_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPlanDay_By_PkArgs, 'id'>>;
  PlanDay_stream?: SubscriptionResolver<Array<ResolversTypes['PlanDay']>, "PlanDay_stream", ParentType, ContextType, RequireFields<Subscription_RootPlanDay_StreamArgs, 'batch_size' | 'cursor'>>;
  PresetDiet?: SubscriptionResolver<Array<ResolversTypes['PresetDiet']>, "PresetDiet", ParentType, ContextType, Partial<Subscription_RootPresetDietArgs>>;
  PresetDiet_aggregate?: SubscriptionResolver<ResolversTypes['PresetDiet_aggregate'], "PresetDiet_aggregate", ParentType, ContextType, Partial<Subscription_RootPresetDiet_AggregateArgs>>;
  PresetDiet_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PresetDiet']>, "PresetDiet_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPresetDiet_By_PkArgs, 'id'>>;
  PresetDiet_stream?: SubscriptionResolver<Array<ResolversTypes['PresetDiet']>, "PresetDiet_stream", ParentType, ContextType, RequireFields<Subscription_RootPresetDiet_StreamArgs, 'batch_size' | 'cursor'>>;
  Pro?: SubscriptionResolver<Array<ResolversTypes['Pro']>, "Pro", ParentType, ContextType, Partial<Subscription_RootProArgs>>;
  ProMusic?: SubscriptionResolver<Array<ResolversTypes['ProMusic']>, "ProMusic", ParentType, ContextType, Partial<Subscription_RootProMusicArgs>>;
  ProMusic_aggregate?: SubscriptionResolver<ResolversTypes['ProMusic_aggregate'], "ProMusic_aggregate", ParentType, ContextType, Partial<Subscription_RootProMusic_AggregateArgs>>;
  ProMusic_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ProMusic']>, "ProMusic_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProMusic_By_PkArgs, 'id'>>;
  ProMusic_stream?: SubscriptionResolver<Array<ResolversTypes['ProMusic']>, "ProMusic_stream", ParentType, ContextType, RequireFields<Subscription_RootProMusic_StreamArgs, 'batch_size' | 'cursor'>>;
  Pro_aggregate?: SubscriptionResolver<ResolversTypes['Pro_aggregate'], "Pro_aggregate", ParentType, ContextType, Partial<Subscription_RootPro_AggregateArgs>>;
  Pro_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Pro']>, "Pro_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPro_By_PkArgs, 'id'>>;
  Pro_stream?: SubscriptionResolver<Array<ResolversTypes['Pro']>, "Pro_stream", ParentType, ContextType, RequireFields<Subscription_RootPro_StreamArgs, 'batch_size' | 'cursor'>>;
  Product?: SubscriptionResolver<Array<ResolversTypes['Product']>, "Product", ParentType, ContextType, Partial<Subscription_RootProductArgs>>;
  ProductLog?: SubscriptionResolver<Array<ResolversTypes['ProductLog']>, "ProductLog", ParentType, ContextType, Partial<Subscription_RootProductLogArgs>>;
  ProductLog_aggregate?: SubscriptionResolver<ResolversTypes['ProductLog_aggregate'], "ProductLog_aggregate", ParentType, ContextType, Partial<Subscription_RootProductLog_AggregateArgs>>;
  ProductLog_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ProductLog']>, "ProductLog_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProductLog_By_PkArgs, 'id'>>;
  ProductLog_stream?: SubscriptionResolver<Array<ResolversTypes['ProductLog']>, "ProductLog_stream", ParentType, ContextType, RequireFields<Subscription_RootProductLog_StreamArgs, 'batch_size' | 'cursor'>>;
  Product_aggregate?: SubscriptionResolver<ResolversTypes['Product_aggregate'], "Product_aggregate", ParentType, ContextType, Partial<Subscription_RootProduct_AggregateArgs>>;
  Product_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Product']>, "Product_by_pk", ParentType, ContextType, RequireFields<Subscription_RootProduct_By_PkArgs, 'id'>>;
  Product_stream?: SubscriptionResolver<Array<ResolversTypes['Product']>, "Product_stream", ParentType, ContextType, RequireFields<Subscription_RootProduct_StreamArgs, 'batch_size' | 'cursor'>>;
  PromotionalItem?: SubscriptionResolver<Array<ResolversTypes['PromotionalItem']>, "PromotionalItem", ParentType, ContextType, Partial<Subscription_RootPromotionalItemArgs>>;
  PromotionalItem_aggregate?: SubscriptionResolver<ResolversTypes['PromotionalItem_aggregate'], "PromotionalItem_aggregate", ParentType, ContextType, Partial<Subscription_RootPromotionalItem_AggregateArgs>>;
  PromotionalItem_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PromotionalItem']>, "PromotionalItem_by_pk", ParentType, ContextType, RequireFields<Subscription_RootPromotionalItem_By_PkArgs, 'id'>>;
  PromotionalItem_stream?: SubscriptionResolver<Array<ResolversTypes['PromotionalItem']>, "PromotionalItem_stream", ParentType, ContextType, RequireFields<Subscription_RootPromotionalItem_StreamArgs, 'batch_size' | 'cursor'>>;
  QuizSurvey?: SubscriptionResolver<Array<ResolversTypes['QuizSurvey']>, "QuizSurvey", ParentType, ContextType, Partial<Subscription_RootQuizSurveyArgs>>;
  QuizSurvey_aggregate?: SubscriptionResolver<ResolversTypes['QuizSurvey_aggregate'], "QuizSurvey_aggregate", ParentType, ContextType, Partial<Subscription_RootQuizSurvey_AggregateArgs>>;
  QuizSurvey_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['QuizSurvey']>, "QuizSurvey_by_pk", ParentType, ContextType, RequireFields<Subscription_RootQuizSurvey_By_PkArgs, 'id'>>;
  QuizSurvey_stream?: SubscriptionResolver<Array<ResolversTypes['QuizSurvey']>, "QuizSurvey_stream", ParentType, ContextType, RequireFields<Subscription_RootQuizSurvey_StreamArgs, 'batch_size' | 'cursor'>>;
  Quote?: SubscriptionResolver<Array<ResolversTypes['Quote']>, "Quote", ParentType, ContextType, Partial<Subscription_RootQuoteArgs>>;
  Quote_aggregate?: SubscriptionResolver<ResolversTypes['Quote_aggregate'], "Quote_aggregate", ParentType, ContextType, Partial<Subscription_RootQuote_AggregateArgs>>;
  Quote_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Quote']>, "Quote_by_pk", ParentType, ContextType, RequireFields<Subscription_RootQuote_By_PkArgs, 'id'>>;
  Quote_stream?: SubscriptionResolver<Array<ResolversTypes['Quote']>, "Quote_stream", ParentType, ContextType, RequireFields<Subscription_RootQuote_StreamArgs, 'batch_size' | 'cursor'>>;
  ReimbursementRequirement?: SubscriptionResolver<Array<ResolversTypes['ReimbursementRequirement']>, "ReimbursementRequirement", ParentType, ContextType, Partial<Subscription_RootReimbursementRequirementArgs>>;
  ReimbursementRequirement_aggregate?: SubscriptionResolver<ResolversTypes['ReimbursementRequirement_aggregate'], "ReimbursementRequirement_aggregate", ParentType, ContextType, Partial<Subscription_RootReimbursementRequirement_AggregateArgs>>;
  ReimbursementRequirement_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ReimbursementRequirement']>, "ReimbursementRequirement_by_pk", ParentType, ContextType, RequireFields<Subscription_RootReimbursementRequirement_By_PkArgs, 'id'>>;
  ReimbursementRequirement_stream?: SubscriptionResolver<Array<ResolversTypes['ReimbursementRequirement']>, "ReimbursementRequirement_stream", ParentType, ContextType, RequireFields<Subscription_RootReimbursementRequirement_StreamArgs, 'batch_size' | 'cursor'>>;
  ScreenOrder?: SubscriptionResolver<Array<ResolversTypes['ScreenOrder']>, "ScreenOrder", ParentType, ContextType, Partial<Subscription_RootScreenOrderArgs>>;
  ScreenOrder_aggregate?: SubscriptionResolver<ResolversTypes['ScreenOrder_aggregate'], "ScreenOrder_aggregate", ParentType, ContextType, Partial<Subscription_RootScreenOrder_AggregateArgs>>;
  ScreenOrder_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ScreenOrder']>, "ScreenOrder_by_pk", ParentType, ContextType, RequireFields<Subscription_RootScreenOrder_By_PkArgs, 'id'>>;
  ScreenOrder_stream?: SubscriptionResolver<Array<ResolversTypes['ScreenOrder']>, "ScreenOrder_stream", ParentType, ContextType, RequireFields<Subscription_RootScreenOrder_StreamArgs, 'batch_size' | 'cursor'>>;
  SearchResult?: SubscriptionResolver<Array<ResolversTypes['SearchResult']>, "SearchResult", ParentType, ContextType, Partial<Subscription_RootSearchResultArgs>>;
  SearchResult_aggregate?: SubscriptionResolver<ResolversTypes['SearchResult_aggregate'], "SearchResult_aggregate", ParentType, ContextType, Partial<Subscription_RootSearchResult_AggregateArgs>>;
  SearchResult_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['SearchResult']>, "SearchResult_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSearchResult_By_PkArgs, 'id'>>;
  SearchResult_stream?: SubscriptionResolver<Array<ResolversTypes['SearchResult']>, "SearchResult_stream", ParentType, ContextType, RequireFields<Subscription_RootSearchResult_StreamArgs, 'batch_size' | 'cursor'>>;
  SleepSurvey?: SubscriptionResolver<Array<ResolversTypes['SleepSurvey']>, "SleepSurvey", ParentType, ContextType, Partial<Subscription_RootSleepSurveyArgs>>;
  SleepSurvey_aggregate?: SubscriptionResolver<ResolversTypes['SleepSurvey_aggregate'], "SleepSurvey_aggregate", ParentType, ContextType, Partial<Subscription_RootSleepSurvey_AggregateArgs>>;
  SleepSurvey_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['SleepSurvey']>, "SleepSurvey_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSleepSurvey_By_PkArgs, 'id'>>;
  SleepSurvey_stream?: SubscriptionResolver<Array<ResolversTypes['SleepSurvey']>, "SleepSurvey_stream", ParentType, ContextType, RequireFields<Subscription_RootSleepSurvey_StreamArgs, 'batch_size' | 'cursor'>>;
  Subscription?: SubscriptionResolver<Array<ResolversTypes['Subscription']>, "Subscription", ParentType, ContextType, Partial<Subscription_RootSubscriptionArgs>>;
  SubscriptionLog?: SubscriptionResolver<Array<ResolversTypes['SubscriptionLog']>, "SubscriptionLog", ParentType, ContextType, Partial<Subscription_RootSubscriptionLogArgs>>;
  SubscriptionLog_aggregate?: SubscriptionResolver<ResolversTypes['SubscriptionLog_aggregate'], "SubscriptionLog_aggregate", ParentType, ContextType, Partial<Subscription_RootSubscriptionLog_AggregateArgs>>;
  SubscriptionLog_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionLog']>, "SubscriptionLog_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSubscriptionLog_By_PkArgs, 'id'>>;
  SubscriptionLog_stream?: SubscriptionResolver<Array<ResolversTypes['SubscriptionLog']>, "SubscriptionLog_stream", ParentType, ContextType, RequireFields<Subscription_RootSubscriptionLog_StreamArgs, 'batch_size' | 'cursor'>>;
  Subscription_aggregate?: SubscriptionResolver<ResolversTypes['Subscription_aggregate'], "Subscription_aggregate", ParentType, ContextType, Partial<Subscription_RootSubscription_AggregateArgs>>;
  Subscription_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Subscription']>, "Subscription_by_pk", ParentType, ContextType, RequireFields<Subscription_RootSubscription_By_PkArgs, 'id'>>;
  Subscription_stream?: SubscriptionResolver<Array<ResolversTypes['Subscription']>, "Subscription_stream", ParentType, ContextType, RequireFields<Subscription_RootSubscription_StreamArgs, 'batch_size' | 'cursor'>>;
  Token?: SubscriptionResolver<Array<ResolversTypes['Token']>, "Token", ParentType, ContextType, Partial<Subscription_RootTokenArgs>>;
  TokenHistory?: SubscriptionResolver<Array<ResolversTypes['TokenHistory']>, "TokenHistory", ParentType, ContextType, Partial<Subscription_RootTokenHistoryArgs>>;
  TokenHistory_aggregate?: SubscriptionResolver<ResolversTypes['TokenHistory_aggregate'], "TokenHistory_aggregate", ParentType, ContextType, Partial<Subscription_RootTokenHistory_AggregateArgs>>;
  TokenHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['TokenHistory']>, "TokenHistory_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTokenHistory_By_PkArgs, 'id'>>;
  TokenHistory_stream?: SubscriptionResolver<Array<ResolversTypes['TokenHistory']>, "TokenHistory_stream", ParentType, ContextType, RequireFields<Subscription_RootTokenHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  Token_aggregate?: SubscriptionResolver<ResolversTypes['Token_aggregate'], "Token_aggregate", ParentType, ContextType, Partial<Subscription_RootToken_AggregateArgs>>;
  Token_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Token']>, "Token_by_pk", ParentType, ContextType, RequireFields<Subscription_RootToken_By_PkArgs, 'id'>>;
  Token_stream?: SubscriptionResolver<Array<ResolversTypes['Token']>, "Token_stream", ParentType, ContextType, RequireFields<Subscription_RootToken_StreamArgs, 'batch_size' | 'cursor'>>;
  User?: SubscriptionResolver<Array<ResolversTypes['User']>, "User", ParentType, ContextType, Partial<Subscription_RootUserArgs>>;
  UserAggregates?: SubscriptionResolver<Array<ResolversTypes['UserAggregates']>, "UserAggregates", ParentType, ContextType, Partial<Subscription_RootUserAggregatesArgs>>;
  UserAggregates_aggregate?: SubscriptionResolver<ResolversTypes['UserAggregates_aggregate'], "UserAggregates_aggregate", ParentType, ContextType, Partial<Subscription_RootUserAggregates_AggregateArgs>>;
  UserAggregates_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserAggregates']>, "UserAggregates_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserAggregates_By_PkArgs, 'id'>>;
  UserAggregates_stream?: SubscriptionResolver<Array<ResolversTypes['UserAggregates']>, "UserAggregates_stream", ParentType, ContextType, RequireFields<Subscription_RootUserAggregates_StreamArgs, 'batch_size' | 'cursor'>>;
  UserAssessment?: SubscriptionResolver<Array<ResolversTypes['UserAssessment']>, "UserAssessment", ParentType, ContextType, Partial<Subscription_RootUserAssessmentArgs>>;
  UserAssessment_aggregate?: SubscriptionResolver<ResolversTypes['UserAssessment_aggregate'], "UserAssessment_aggregate", ParentType, ContextType, Partial<Subscription_RootUserAssessment_AggregateArgs>>;
  UserAssessment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserAssessment']>, "UserAssessment_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserAssessment_By_PkArgs, 'id'>>;
  UserAssessment_stream?: SubscriptionResolver<Array<ResolversTypes['UserAssessment']>, "UserAssessment_stream", ParentType, ContextType, RequireFields<Subscription_RootUserAssessment_StreamArgs, 'batch_size' | 'cursor'>>;
  UserFeatureControl?: SubscriptionResolver<Array<ResolversTypes['UserFeatureControl']>, "UserFeatureControl", ParentType, ContextType, Partial<Subscription_RootUserFeatureControlArgs>>;
  UserFeatureControl_aggregate?: SubscriptionResolver<ResolversTypes['UserFeatureControl_aggregate'], "UserFeatureControl_aggregate", ParentType, ContextType, Partial<Subscription_RootUserFeatureControl_AggregateArgs>>;
  UserFeatureControl_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserFeatureControl']>, "UserFeatureControl_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserFeatureControl_By_PkArgs, 'id'>>;
  UserFeatureControl_stream?: SubscriptionResolver<Array<ResolversTypes['UserFeatureControl']>, "UserFeatureControl_stream", ParentType, ContextType, RequireFields<Subscription_RootUserFeatureControl_StreamArgs, 'batch_size' | 'cursor'>>;
  UserFeedback?: SubscriptionResolver<Array<ResolversTypes['UserFeedback']>, "UserFeedback", ParentType, ContextType, Partial<Subscription_RootUserFeedbackArgs>>;
  UserFeedback_aggregate?: SubscriptionResolver<ResolversTypes['UserFeedback_aggregate'], "UserFeedback_aggregate", ParentType, ContextType, Partial<Subscription_RootUserFeedback_AggregateArgs>>;
  UserFeedback_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserFeedback']>, "UserFeedback_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserFeedback_By_PkArgs, 'id'>>;
  UserFeedback_stream?: SubscriptionResolver<Array<ResolversTypes['UserFeedback']>, "UserFeedback_stream", ParentType, ContextType, RequireFields<Subscription_RootUserFeedback_StreamArgs, 'batch_size' | 'cursor'>>;
  UserFinancePlan?: SubscriptionResolver<Array<ResolversTypes['UserFinancePlan']>, "UserFinancePlan", ParentType, ContextType, Partial<Subscription_RootUserFinancePlanArgs>>;
  UserFinancePlan_aggregate?: SubscriptionResolver<ResolversTypes['UserFinancePlan_aggregate'], "UserFinancePlan_aggregate", ParentType, ContextType, Partial<Subscription_RootUserFinancePlan_AggregateArgs>>;
  UserFinancePlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserFinancePlan']>, "UserFinancePlan_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserFinancePlan_By_PkArgs, 'id'>>;
  UserFinancePlan_stream?: SubscriptionResolver<Array<ResolversTypes['UserFinancePlan']>, "UserFinancePlan_stream", ParentType, ContextType, RequireFields<Subscription_RootUserFinancePlan_StreamArgs, 'batch_size' | 'cursor'>>;
  UserHabit?: SubscriptionResolver<Array<ResolversTypes['UserHabit']>, "UserHabit", ParentType, ContextType, Partial<Subscription_RootUserHabitArgs>>;
  UserHabit_aggregate?: SubscriptionResolver<ResolversTypes['UserHabit_aggregate'], "UserHabit_aggregate", ParentType, ContextType, Partial<Subscription_RootUserHabit_AggregateArgs>>;
  UserHabit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserHabit']>, "UserHabit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserHabit_By_PkArgs, 'id'>>;
  UserHabit_stream?: SubscriptionResolver<Array<ResolversTypes['UserHabit']>, "UserHabit_stream", ParentType, ContextType, RequireFields<Subscription_RootUserHabit_StreamArgs, 'batch_size' | 'cursor'>>;
  UserMovementDaily?: SubscriptionResolver<Array<ResolversTypes['UserMovementDaily']>, "UserMovementDaily", ParentType, ContextType, Partial<Subscription_RootUserMovementDailyArgs>>;
  UserMovementDaily_aggregate?: SubscriptionResolver<ResolversTypes['UserMovementDaily_aggregate'], "UserMovementDaily_aggregate", ParentType, ContextType, Partial<Subscription_RootUserMovementDaily_AggregateArgs>>;
  UserMovementDaily_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserMovementDaily']>, "UserMovementDaily_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserMovementDaily_By_PkArgs, 'id'>>;
  UserMovementDaily_stream?: SubscriptionResolver<Array<ResolversTypes['UserMovementDaily']>, "UserMovementDaily_stream", ParentType, ContextType, RequireFields<Subscription_RootUserMovementDaily_StreamArgs, 'batch_size' | 'cursor'>>;
  UserPreferences?: SubscriptionResolver<Array<ResolversTypes['UserPreferences']>, "UserPreferences", ParentType, ContextType, Partial<Subscription_RootUserPreferencesArgs>>;
  UserPreferences_aggregate?: SubscriptionResolver<ResolversTypes['UserPreferences_aggregate'], "UserPreferences_aggregate", ParentType, ContextType, Partial<Subscription_RootUserPreferences_AggregateArgs>>;
  UserPreferences_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserPreferences']>, "UserPreferences_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserPreferences_By_PkArgs, 'id'>>;
  UserPreferences_stream?: SubscriptionResolver<Array<ResolversTypes['UserPreferences']>, "UserPreferences_stream", ParentType, ContextType, RequireFields<Subscription_RootUserPreferences_StreamArgs, 'batch_size' | 'cursor'>>;
  UserProduct?: SubscriptionResolver<Array<ResolversTypes['UserProduct']>, "UserProduct", ParentType, ContextType, Partial<Subscription_RootUserProductArgs>>;
  UserProductHistory?: SubscriptionResolver<Array<ResolversTypes['UserProductHistory']>, "UserProductHistory", ParentType, ContextType, Partial<Subscription_RootUserProductHistoryArgs>>;
  UserProductHistory_aggregate?: SubscriptionResolver<ResolversTypes['UserProductHistory_aggregate'], "UserProductHistory_aggregate", ParentType, ContextType, Partial<Subscription_RootUserProductHistory_AggregateArgs>>;
  UserProductHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserProductHistory']>, "UserProductHistory_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserProductHistory_By_PkArgs, 'id'>>;
  UserProductHistory_stream?: SubscriptionResolver<Array<ResolversTypes['UserProductHistory']>, "UserProductHistory_stream", ParentType, ContextType, RequireFields<Subscription_RootUserProductHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  UserProduct_aggregate?: SubscriptionResolver<ResolversTypes['UserProduct_aggregate'], "UserProduct_aggregate", ParentType, ContextType, Partial<Subscription_RootUserProduct_AggregateArgs>>;
  UserProduct_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserProduct']>, "UserProduct_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserProduct_By_PkArgs, 'id'>>;
  UserProduct_stream?: SubscriptionResolver<Array<ResolversTypes['UserProduct']>, "UserProduct_stream", ParentType, ContextType, RequireFields<Subscription_RootUserProduct_StreamArgs, 'batch_size' | 'cursor'>>;
  UserReadiness?: SubscriptionResolver<Array<ResolversTypes['UserReadiness']>, "UserReadiness", ParentType, ContextType, Partial<Subscription_RootUserReadinessArgs>>;
  UserReadinessLog?: SubscriptionResolver<Array<ResolversTypes['UserReadinessLog']>, "UserReadinessLog", ParentType, ContextType, Partial<Subscription_RootUserReadinessLogArgs>>;
  UserReadinessLog_aggregate?: SubscriptionResolver<ResolversTypes['UserReadinessLog_aggregate'], "UserReadinessLog_aggregate", ParentType, ContextType, Partial<Subscription_RootUserReadinessLog_AggregateArgs>>;
  UserReadinessLog_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserReadinessLog']>, "UserReadinessLog_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserReadinessLog_By_PkArgs, 'id'>>;
  UserReadinessLog_stream?: SubscriptionResolver<Array<ResolversTypes['UserReadinessLog']>, "UserReadinessLog_stream", ParentType, ContextType, RequireFields<Subscription_RootUserReadinessLog_StreamArgs, 'batch_size' | 'cursor'>>;
  UserReadiness_aggregate?: SubscriptionResolver<ResolversTypes['UserReadiness_aggregate'], "UserReadiness_aggregate", ParentType, ContextType, Partial<Subscription_RootUserReadiness_AggregateArgs>>;
  UserReadiness_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserReadiness']>, "UserReadiness_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserReadiness_By_PkArgs, 'id'>>;
  UserReadiness_stream?: SubscriptionResolver<Array<ResolversTypes['UserReadiness']>, "UserReadiness_stream", ParentType, ContextType, RequireFields<Subscription_RootUserReadiness_StreamArgs, 'batch_size' | 'cursor'>>;
  UserReimbursmentHistory?: SubscriptionResolver<Array<ResolversTypes['UserReimbursmentHistory']>, "UserReimbursmentHistory", ParentType, ContextType, Partial<Subscription_RootUserReimbursmentHistoryArgs>>;
  UserReimbursmentHistory_aggregate?: SubscriptionResolver<ResolversTypes['UserReimbursmentHistory_aggregate'], "UserReimbursmentHistory_aggregate", ParentType, ContextType, Partial<Subscription_RootUserReimbursmentHistory_AggregateArgs>>;
  UserReimbursmentHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserReimbursmentHistory']>, "UserReimbursmentHistory_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserReimbursmentHistory_By_PkArgs, 'id'>>;
  UserReimbursmentHistory_stream?: SubscriptionResolver<Array<ResolversTypes['UserReimbursmentHistory']>, "UserReimbursmentHistory_stream", ParentType, ContextType, RequireFields<Subscription_RootUserReimbursmentHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  UserRep?: SubscriptionResolver<Array<ResolversTypes['UserRep']>, "UserRep", ParentType, ContextType, Partial<Subscription_RootUserRepArgs>>;
  UserRep_aggregate?: SubscriptionResolver<ResolversTypes['UserRep_aggregate'], "UserRep_aggregate", ParentType, ContextType, Partial<Subscription_RootUserRep_AggregateArgs>>;
  UserRep_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserRep']>, "UserRep_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserRep_By_PkArgs, 'id'>>;
  UserRep_stream?: SubscriptionResolver<Array<ResolversTypes['UserRep']>, "UserRep_stream", ParentType, ContextType, RequireFields<Subscription_RootUserRep_StreamArgs, 'batch_size' | 'cursor'>>;
  UserSet?: SubscriptionResolver<Array<ResolversTypes['UserSet']>, "UserSet", ParentType, ContextType, Partial<Subscription_RootUserSetArgs>>;
  UserSet_aggregate?: SubscriptionResolver<ResolversTypes['UserSet_aggregate'], "UserSet_aggregate", ParentType, ContextType, Partial<Subscription_RootUserSet_AggregateArgs>>;
  UserSet_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserSet']>, "UserSet_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserSet_By_PkArgs, 'id'>>;
  UserSet_stream?: SubscriptionResolver<Array<ResolversTypes['UserSet']>, "UserSet_stream", ParentType, ContextType, RequireFields<Subscription_RootUserSet_StreamArgs, 'batch_size' | 'cursor'>>;
  UserState?: SubscriptionResolver<Array<ResolversTypes['UserState']>, "UserState", ParentType, ContextType, Partial<Subscription_RootUserStateArgs>>;
  UserState_aggregate?: SubscriptionResolver<ResolversTypes['UserState_aggregate'], "UserState_aggregate", ParentType, ContextType, Partial<Subscription_RootUserState_AggregateArgs>>;
  UserState_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserState']>, "UserState_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserState_By_PkArgs, 'id'>>;
  UserState_stream?: SubscriptionResolver<Array<ResolversTypes['UserState']>, "UserState_stream", ParentType, ContextType, RequireFields<Subscription_RootUserState_StreamArgs, 'batch_size' | 'cursor'>>;
  UserTrainingPlan?: SubscriptionResolver<Array<ResolversTypes['UserTrainingPlan']>, "UserTrainingPlan", ParentType, ContextType, Partial<Subscription_RootUserTrainingPlanArgs>>;
  UserTrainingPlan_aggregate?: SubscriptionResolver<ResolversTypes['UserTrainingPlan_aggregate'], "UserTrainingPlan_aggregate", ParentType, ContextType, Partial<Subscription_RootUserTrainingPlan_AggregateArgs>>;
  UserTrainingPlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserTrainingPlan']>, "UserTrainingPlan_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserTrainingPlan_By_PkArgs, 'id'>>;
  UserTrainingPlan_stream?: SubscriptionResolver<Array<ResolversTypes['UserTrainingPlan']>, "UserTrainingPlan_stream", ParentType, ContextType, RequireFields<Subscription_RootUserTrainingPlan_StreamArgs, 'batch_size' | 'cursor'>>;
  UserWorkout?: SubscriptionResolver<Array<ResolversTypes['UserWorkout']>, "UserWorkout", ParentType, ContextType, Partial<Subscription_RootUserWorkoutArgs>>;
  UserWorkout_aggregate?: SubscriptionResolver<ResolversTypes['UserWorkout_aggregate'], "UserWorkout_aggregate", ParentType, ContextType, Partial<Subscription_RootUserWorkout_AggregateArgs>>;
  UserWorkout_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserWorkout']>, "UserWorkout_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUserWorkout_By_PkArgs, 'id'>>;
  UserWorkout_stream?: SubscriptionResolver<Array<ResolversTypes['UserWorkout']>, "UserWorkout_stream", ParentType, ContextType, RequireFields<Subscription_RootUserWorkout_StreamArgs, 'batch_size' | 'cursor'>>;
  User_aggregate?: SubscriptionResolver<ResolversTypes['User_aggregate'], "User_aggregate", ParentType, ContextType, Partial<Subscription_RootUser_AggregateArgs>>;
  User_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "User_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUser_By_PkArgs, 'id'>>;
  User_stream?: SubscriptionResolver<Array<ResolversTypes['User']>, "User_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_StreamArgs, 'batch_size' | 'cursor'>>;
  VendorErrorLog?: SubscriptionResolver<Array<ResolversTypes['VendorErrorLog']>, "VendorErrorLog", ParentType, ContextType, Partial<Subscription_RootVendorErrorLogArgs>>;
  VendorErrorLog_aggregate?: SubscriptionResolver<ResolversTypes['VendorErrorLog_aggregate'], "VendorErrorLog_aggregate", ParentType, ContextType, Partial<Subscription_RootVendorErrorLog_AggregateArgs>>;
  VendorErrorLog_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['VendorErrorLog']>, "VendorErrorLog_by_pk", ParentType, ContextType, RequireFields<Subscription_RootVendorErrorLog_By_PkArgs, 'id'>>;
  VendorErrorLog_stream?: SubscriptionResolver<Array<ResolversTypes['VendorErrorLog']>, "VendorErrorLog_stream", ParentType, ContextType, RequireFields<Subscription_RootVendorErrorLog_StreamArgs, 'batch_size' | 'cursor'>>;
  VersionControl?: SubscriptionResolver<Array<ResolversTypes['VersionControl']>, "VersionControl", ParentType, ContextType, Partial<Subscription_RootVersionControlArgs>>;
  VersionControl_aggregate?: SubscriptionResolver<ResolversTypes['VersionControl_aggregate'], "VersionControl_aggregate", ParentType, ContextType, Partial<Subscription_RootVersionControl_AggregateArgs>>;
  VersionControl_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['VersionControl']>, "VersionControl_by_pk", ParentType, ContextType, RequireFields<Subscription_RootVersionControl_By_PkArgs, 'id'>>;
  VersionControl_stream?: SubscriptionResolver<Array<ResolversTypes['VersionControl']>, "VersionControl_stream", ParentType, ContextType, RequireFields<Subscription_RootVersionControl_StreamArgs, 'batch_size' | 'cursor'>>;
  WeeklyPlan?: SubscriptionResolver<Array<ResolversTypes['WeeklyPlan']>, "WeeklyPlan", ParentType, ContextType, Partial<Subscription_RootWeeklyPlanArgs>>;
  WeeklyPlan_aggregate?: SubscriptionResolver<ResolversTypes['WeeklyPlan_aggregate'], "WeeklyPlan_aggregate", ParentType, ContextType, Partial<Subscription_RootWeeklyPlan_AggregateArgs>>;
  WeeklyPlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['WeeklyPlan']>, "WeeklyPlan_by_pk", ParentType, ContextType, RequireFields<Subscription_RootWeeklyPlan_By_PkArgs, 'id'>>;
  WeeklyPlan_stream?: SubscriptionResolver<Array<ResolversTypes['WeeklyPlan']>, "WeeklyPlan_stream", ParentType, ContextType, RequireFields<Subscription_RootWeeklyPlan_StreamArgs, 'batch_size' | 'cursor'>>;
  WorkinPrefrence?: SubscriptionResolver<Array<ResolversTypes['WorkinPrefrence']>, "WorkinPrefrence", ParentType, ContextType, Partial<Subscription_RootWorkinPrefrenceArgs>>;
  WorkinPrefrence_aggregate?: SubscriptionResolver<ResolversTypes['WorkinPrefrence_aggregate'], "WorkinPrefrence_aggregate", ParentType, ContextType, Partial<Subscription_RootWorkinPrefrence_AggregateArgs>>;
  WorkinPrefrence_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['WorkinPrefrence']>, "WorkinPrefrence_by_pk", ParentType, ContextType, RequireFields<Subscription_RootWorkinPrefrence_By_PkArgs, 'id'>>;
  WorkinPrefrence_stream?: SubscriptionResolver<Array<ResolversTypes['WorkinPrefrence']>, "WorkinPrefrence_stream", ParentType, ContextType, RequireFields<Subscription_RootWorkinPrefrence_StreamArgs, 'batch_size' | 'cursor'>>;
  Workout?: SubscriptionResolver<Array<ResolversTypes['Workout']>, "Workout", ParentType, ContextType, Partial<Subscription_RootWorkoutArgs>>;
  WorkoutPrefrence?: SubscriptionResolver<Array<ResolversTypes['WorkoutPrefrence']>, "WorkoutPrefrence", ParentType, ContextType, Partial<Subscription_RootWorkoutPrefrenceArgs>>;
  WorkoutPrefrence_aggregate?: SubscriptionResolver<ResolversTypes['WorkoutPrefrence_aggregate'], "WorkoutPrefrence_aggregate", ParentType, ContextType, Partial<Subscription_RootWorkoutPrefrence_AggregateArgs>>;
  WorkoutPrefrence_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['WorkoutPrefrence']>, "WorkoutPrefrence_by_pk", ParentType, ContextType, RequireFields<Subscription_RootWorkoutPrefrence_By_PkArgs, 'id'>>;
  WorkoutPrefrence_stream?: SubscriptionResolver<Array<ResolversTypes['WorkoutPrefrence']>, "WorkoutPrefrence_stream", ParentType, ContextType, RequireFields<Subscription_RootWorkoutPrefrence_StreamArgs, 'batch_size' | 'cursor'>>;
  Workout_aggregate?: SubscriptionResolver<ResolversTypes['Workout_aggregate'], "Workout_aggregate", ParentType, ContextType, Partial<Subscription_RootWorkout_AggregateArgs>>;
  Workout_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Workout']>, "Workout_by_pk", ParentType, ContextType, RequireFields<Subscription_RootWorkout_By_PkArgs, 'id'>>;
  Workout_stream?: SubscriptionResolver<Array<ResolversTypes['Workout']>, "Workout_stream", ParentType, ContextType, RequireFields<Subscription_RootWorkout_StreamArgs, 'batch_size' | 'cursor'>>;
  _FollowingPro?: SubscriptionResolver<Array<ResolversTypes['_FollowingPro']>, "_FollowingPro", ParentType, ContextType, Partial<Subscription_Root_FollowingProArgs>>;
  _FollowingPro_aggregate?: SubscriptionResolver<ResolversTypes['_FollowingPro_aggregate'], "_FollowingPro_aggregate", ParentType, ContextType, Partial<Subscription_Root_FollowingPro_AggregateArgs>>;
  _FollowingPro_stream?: SubscriptionResolver<Array<ResolversTypes['_FollowingPro']>, "_FollowingPro_stream", ParentType, ContextType, RequireFields<Subscription_Root_FollowingPro_StreamArgs, 'batch_size' | 'cursor'>>;
  _GoalToUserPreferences?: SubscriptionResolver<Array<ResolversTypes['_GoalToUserPreferences']>, "_GoalToUserPreferences", ParentType, ContextType, Partial<Subscription_Root_GoalToUserPreferencesArgs>>;
  _GoalToUserPreferences_aggregate?: SubscriptionResolver<ResolversTypes['_GoalToUserPreferences_aggregate'], "_GoalToUserPreferences_aggregate", ParentType, ContextType, Partial<Subscription_Root_GoalToUserPreferences_AggregateArgs>>;
  _GoalToUserPreferences_stream?: SubscriptionResolver<Array<ResolversTypes['_GoalToUserPreferences']>, "_GoalToUserPreferences_stream", ParentType, ContextType, RequireFields<Subscription_Root_GoalToUserPreferences_StreamArgs, 'batch_size' | 'cursor'>>;
}>;

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['time'], any> {
  name: 'time';
}

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamp'], any> {
  name: 'timestamp';
}

export type UpdateReimbursementTokensResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['updateReimbursementTokensResponse'] = ResolversParentTypes['updateReimbursementTokensResponse']> = ResolversObject<{
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['updateUser'] = ResolversParentTypes['updateUser']> = ResolversObject<{
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  has_warming_state?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  height_feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_inches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height_meters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inRingMode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferMetric?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  radioPreferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ringId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringMacAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ringName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strapColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strap_location?: Resolver<Maybe<ResolversTypes['StrapLocationEnum']>, ParentType, ContextType>;
  user_state?: Resolver<Maybe<ResolversTypes['UserStateOutput']>, ParentType, ContextType>;
  weight_kg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight_lbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserNutritionReportOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['userNutritionReportOutput'] = ResolversParentTypes['userNutritionReportOutput']> = ResolversObject<{
  age?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  alterType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bestExercise?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dailyCaloricLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dailyCaloricMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dailyCarbsMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dailyFatAllowance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dailyProteinMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  easeWeightLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  goalWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gramsRecommendedCarbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gramsRecommendedFats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gramsRecommendedProteins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  impactEatingCarbs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impactEatingFat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impactEatingProtein?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  keepingWeightOff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nutrients_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nutritionGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentRecommendedCarbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentRecommendedFats?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentRecommendedProteins?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sex?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systemicInflammation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  ActionResultOutput?: ActionResultOutputResolvers<ContextType>;
  ActivationCode?: ActivationCodeResolvers<ContextType>;
  ActivationCode_aggregate?: ActivationCode_AggregateResolvers<ContextType>;
  ActivationCode_aggregate_fields?: ActivationCode_Aggregate_FieldsResolvers<ContextType>;
  ActivationCode_max_fields?: ActivationCode_Max_FieldsResolvers<ContextType>;
  ActivationCode_min_fields?: ActivationCode_Min_FieldsResolvers<ContextType>;
  ActivationCode_mutation_response?: ActivationCode_Mutation_ResponseResolvers<ContextType>;
  ActivityInsightsDetailOutput?: ActivityInsightsDetailOutputResolvers<ContextType>;
  ActivityInsightsOutput?: ActivityInsightsOutputResolvers<ContextType>;
  ActivityProgressOutput?: ActivityProgressOutputResolvers<ContextType>;
  AggregatesOutput?: AggregatesOutputResolvers<ContextType>;
  AllUsersOutput?: AllUsersOutputResolvers<ContextType>;
  AlterTypeColor?: AlterTypeColorResolvers<ContextType>;
  AlterTypeColor_aggregate?: AlterTypeColor_AggregateResolvers<ContextType>;
  AlterTypeColor_aggregate_fields?: AlterTypeColor_Aggregate_FieldsResolvers<ContextType>;
  AlterTypeColor_max_fields?: AlterTypeColor_Max_FieldsResolvers<ContextType>;
  AlterTypeColor_min_fields?: AlterTypeColor_Min_FieldsResolvers<ContextType>;
  AlterTypeColor_mutation_response?: AlterTypeColor_Mutation_ResponseResolvers<ContextType>;
  AssessmentType?: GraphQLScalarType;
  CRUDaction?: GraphQLScalarType;
  CadenceType?: GraphQLScalarType;
  CalculateMacroGramsOutput?: CalculateMacroGramsOutputResolvers<ContextType>;
  CaloricOutput?: CaloricOutputResolvers<ContextType>;
  ChangeCoachedPlanOutput?: ChangeCoachedPlanOutputResolvers<ContextType>;
  CheckDNAKitOutput?: CheckDnaKitOutputResolvers<ContextType>;
  CheckNutritionReportReleasedOutput?: CheckNutritionReportReleasedOutputResolvers<ContextType>;
  CheckPresetPlanStructureOutput?: CheckPresetPlanStructureOutputResolvers<ContextType>;
  Coach?: CoachResolvers<ContextType>;
  Coach_aggregate?: Coach_AggregateResolvers<ContextType>;
  Coach_aggregate_fields?: Coach_Aggregate_FieldsResolvers<ContextType>;
  Coach_max_fields?: Coach_Max_FieldsResolvers<ContextType>;
  Coach_min_fields?: Coach_Min_FieldsResolvers<ContextType>;
  Coach_mutation_response?: Coach_Mutation_ResponseResolvers<ContextType>;
  CoachedHabit?: CoachedHabitResolvers<ContextType>;
  CoachedHabit_aggregate?: CoachedHabit_AggregateResolvers<ContextType>;
  CoachedHabit_aggregate_fields?: CoachedHabit_Aggregate_FieldsResolvers<ContextType>;
  CoachedHabit_avg_fields?: CoachedHabit_Avg_FieldsResolvers<ContextType>;
  CoachedHabit_max_fields?: CoachedHabit_Max_FieldsResolvers<ContextType>;
  CoachedHabit_min_fields?: CoachedHabit_Min_FieldsResolvers<ContextType>;
  CoachedHabit_mutation_response?: CoachedHabit_Mutation_ResponseResolvers<ContextType>;
  CoachedHabit_stddev_fields?: CoachedHabit_Stddev_FieldsResolvers<ContextType>;
  CoachedHabit_stddev_pop_fields?: CoachedHabit_Stddev_Pop_FieldsResolvers<ContextType>;
  CoachedHabit_stddev_samp_fields?: CoachedHabit_Stddev_Samp_FieldsResolvers<ContextType>;
  CoachedHabit_sum_fields?: CoachedHabit_Sum_FieldsResolvers<ContextType>;
  CoachedHabit_var_pop_fields?: CoachedHabit_Var_Pop_FieldsResolvers<ContextType>;
  CoachedHabit_var_samp_fields?: CoachedHabit_Var_Samp_FieldsResolvers<ContextType>;
  CoachedHabit_variance_fields?: CoachedHabit_Variance_FieldsResolvers<ContextType>;
  CoachedNutrition?: CoachedNutritionResolvers<ContextType>;
  CoachedNutrition_aggregate?: CoachedNutrition_AggregateResolvers<ContextType>;
  CoachedNutrition_aggregate_fields?: CoachedNutrition_Aggregate_FieldsResolvers<ContextType>;
  CoachedNutrition_avg_fields?: CoachedNutrition_Avg_FieldsResolvers<ContextType>;
  CoachedNutrition_max_fields?: CoachedNutrition_Max_FieldsResolvers<ContextType>;
  CoachedNutrition_min_fields?: CoachedNutrition_Min_FieldsResolvers<ContextType>;
  CoachedNutrition_mutation_response?: CoachedNutrition_Mutation_ResponseResolvers<ContextType>;
  CoachedNutrition_stddev_fields?: CoachedNutrition_Stddev_FieldsResolvers<ContextType>;
  CoachedNutrition_stddev_pop_fields?: CoachedNutrition_Stddev_Pop_FieldsResolvers<ContextType>;
  CoachedNutrition_stddev_samp_fields?: CoachedNutrition_Stddev_Samp_FieldsResolvers<ContextType>;
  CoachedNutrition_sum_fields?: CoachedNutrition_Sum_FieldsResolvers<ContextType>;
  CoachedNutrition_var_pop_fields?: CoachedNutrition_Var_Pop_FieldsResolvers<ContextType>;
  CoachedNutrition_var_samp_fields?: CoachedNutrition_Var_Samp_FieldsResolvers<ContextType>;
  CoachedNutrition_variance_fields?: CoachedNutrition_Variance_FieldsResolvers<ContextType>;
  CookBookItem?: CookBookItemResolvers<ContextType>;
  CreditHistoryOutput?: CreditHistoryOutputResolvers<ContextType>;
  CurrentAverage?: CurrentAverageResolvers<ContextType>;
  CustomPlanDay?: CustomPlanDayResolvers<ContextType>;
  DMLType?: GraphQLScalarType;
  DNAKit?: DnaKitResolvers<ContextType>;
  DNAKitRole?: GraphQLScalarType;
  DNAKit_aggregate?: DnaKit_AggregateResolvers<ContextType>;
  DNAKit_aggregate_fields?: DnaKit_Aggregate_FieldsResolvers<ContextType>;
  DNAKit_avg_fields?: DnaKit_Avg_FieldsResolvers<ContextType>;
  DNAKit_max_fields?: DnaKit_Max_FieldsResolvers<ContextType>;
  DNAKit_min_fields?: DnaKit_Min_FieldsResolvers<ContextType>;
  DNAKit_mutation_response?: DnaKit_Mutation_ResponseResolvers<ContextType>;
  DNAKit_stddev_fields?: DnaKit_Stddev_FieldsResolvers<ContextType>;
  DNAKit_stddev_pop_fields?: DnaKit_Stddev_Pop_FieldsResolvers<ContextType>;
  DNAKit_stddev_samp_fields?: DnaKit_Stddev_Samp_FieldsResolvers<ContextType>;
  DNAKit_sum_fields?: DnaKit_Sum_FieldsResolvers<ContextType>;
  DNAKit_var_pop_fields?: DnaKit_Var_Pop_FieldsResolvers<ContextType>;
  DNAKit_var_samp_fields?: DnaKit_Var_Samp_FieldsResolvers<ContextType>;
  DNAKit_variance_fields?: DnaKit_Variance_FieldsResolvers<ContextType>;
  DNAResults?: DnaResultsResolvers<ContextType>;
  DNAResultsOutput?: DnaResultsOutputResolvers<ContextType>;
  DNAResults_aggregate?: DnaResults_AggregateResolvers<ContextType>;
  DNAResults_aggregate_fields?: DnaResults_Aggregate_FieldsResolvers<ContextType>;
  DNAResults_avg_fields?: DnaResults_Avg_FieldsResolvers<ContextType>;
  DNAResults_max_fields?: DnaResults_Max_FieldsResolvers<ContextType>;
  DNAResults_min_fields?: DnaResults_Min_FieldsResolvers<ContextType>;
  DNAResults_mutation_response?: DnaResults_Mutation_ResponseResolvers<ContextType>;
  DNAResults_stddev_fields?: DnaResults_Stddev_FieldsResolvers<ContextType>;
  DNAResults_stddev_pop_fields?: DnaResults_Stddev_Pop_FieldsResolvers<ContextType>;
  DNAResults_stddev_samp_fields?: DnaResults_Stddev_Samp_FieldsResolvers<ContextType>;
  DNAResults_sum_fields?: DnaResults_Sum_FieldsResolvers<ContextType>;
  DNAResults_var_pop_fields?: DnaResults_Var_Pop_FieldsResolvers<ContextType>;
  DNAResults_var_samp_fields?: DnaResults_Var_Samp_FieldsResolvers<ContextType>;
  DNAResults_variance_fields?: DnaResults_Variance_FieldsResolvers<ContextType>;
  DailyCheckinOutput?: DailyCheckinOutputResolvers<ContextType>;
  DailyCheckinV2MasteryObject?: DailyCheckinV2MasteryObjectResolvers<ContextType>;
  DailyCheckinV2Output?: DailyCheckinV2OutputResolvers<ContextType>;
  DailyCheckinV2SleepObject?: DailyCheckinV2SleepObjectResolvers<ContextType>;
  Delivery?: DeliveryResolvers<ContextType>;
  DeliveryHistory?: DeliveryHistoryResolvers<ContextType>;
  DeliveryHistory_aggregate?: DeliveryHistory_AggregateResolvers<ContextType>;
  DeliveryHistory_aggregate_fields?: DeliveryHistory_Aggregate_FieldsResolvers<ContextType>;
  DeliveryHistory_max_fields?: DeliveryHistory_Max_FieldsResolvers<ContextType>;
  DeliveryHistory_min_fields?: DeliveryHistory_Min_FieldsResolvers<ContextType>;
  DeliveryHistory_mutation_response?: DeliveryHistory_Mutation_ResponseResolvers<ContextType>;
  DeliveryStatus?: GraphQLScalarType;
  Delivery_aggregate?: Delivery_AggregateResolvers<ContextType>;
  Delivery_aggregate_fields?: Delivery_Aggregate_FieldsResolvers<ContextType>;
  Delivery_max_fields?: Delivery_Max_FieldsResolvers<ContextType>;
  Delivery_min_fields?: Delivery_Min_FieldsResolvers<ContextType>;
  Delivery_mutation_response?: Delivery_Mutation_ResponseResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  DeviceType?: GraphQLScalarType;
  Device_aggregate?: Device_AggregateResolvers<ContextType>;
  Device_aggregate_fields?: Device_Aggregate_FieldsResolvers<ContextType>;
  Device_max_fields?: Device_Max_FieldsResolvers<ContextType>;
  Device_min_fields?: Device_Min_FieldsResolvers<ContextType>;
  Device_mutation_response?: Device_Mutation_ResponseResolvers<ContextType>;
  Diagnostics?: DiagnosticsResolvers<ContextType>;
  Diagnostics_aggregate?: Diagnostics_AggregateResolvers<ContextType>;
  Diagnostics_aggregate_fields?: Diagnostics_Aggregate_FieldsResolvers<ContextType>;
  Diagnostics_avg_fields?: Diagnostics_Avg_FieldsResolvers<ContextType>;
  Diagnostics_max_fields?: Diagnostics_Max_FieldsResolvers<ContextType>;
  Diagnostics_min_fields?: Diagnostics_Min_FieldsResolvers<ContextType>;
  Diagnostics_mutation_response?: Diagnostics_Mutation_ResponseResolvers<ContextType>;
  Diagnostics_stddev_fields?: Diagnostics_Stddev_FieldsResolvers<ContextType>;
  Diagnostics_stddev_pop_fields?: Diagnostics_Stddev_Pop_FieldsResolvers<ContextType>;
  Diagnostics_stddev_samp_fields?: Diagnostics_Stddev_Samp_FieldsResolvers<ContextType>;
  Diagnostics_sum_fields?: Diagnostics_Sum_FieldsResolvers<ContextType>;
  Diagnostics_var_pop_fields?: Diagnostics_Var_Pop_FieldsResolvers<ContextType>;
  Diagnostics_var_samp_fields?: Diagnostics_Var_Samp_FieldsResolvers<ContextType>;
  Diagnostics_variance_fields?: Diagnostics_Variance_FieldsResolvers<ContextType>;
  DietContents?: DietContentsResolvers<ContextType>;
  EmailHasKitOutput?: EmailHasKitOutputResolvers<ContextType>;
  EndAssessmentOutput?: EndAssessmentOutputResolvers<ContextType>;
  FeedbackType?: GraphQLScalarType;
  FetchCoachOutput?: FetchCoachOutputResolvers<ContextType>;
  FetchCoachedPlanOutput?: FetchCoachedPlanOutputResolvers<ContextType>;
  FetchCookBookOutput?: FetchCookBookOutputResolvers<ContextType>;
  FetchWorkoutOptionsOutput?: FetchWorkoutOptionsOutputResolvers<ContextType>;
  FetchWorkoutOptionsOutputV2?: FetchWorkoutOptionsOutputV2Resolvers<ContextType>;
  FetchWorkoutOptionsOutputV3?: FetchWorkoutOptionsOutputV3Resolvers<ContextType>;
  FileType?: GraphQLScalarType;
  FinancePlan?: FinancePlanResolvers<ContextType>;
  FinancePlan_aggregate?: FinancePlan_AggregateResolvers<ContextType>;
  FinancePlan_aggregate_fields?: FinancePlan_Aggregate_FieldsResolvers<ContextType>;
  FinancePlan_avg_fields?: FinancePlan_Avg_FieldsResolvers<ContextType>;
  FinancePlan_max_fields?: FinancePlan_Max_FieldsResolvers<ContextType>;
  FinancePlan_min_fields?: FinancePlan_Min_FieldsResolvers<ContextType>;
  FinancePlan_mutation_response?: FinancePlan_Mutation_ResponseResolvers<ContextType>;
  FinancePlan_stddev_fields?: FinancePlan_Stddev_FieldsResolvers<ContextType>;
  FinancePlan_stddev_pop_fields?: FinancePlan_Stddev_Pop_FieldsResolvers<ContextType>;
  FinancePlan_stddev_samp_fields?: FinancePlan_Stddev_Samp_FieldsResolvers<ContextType>;
  FinancePlan_sum_fields?: FinancePlan_Sum_FieldsResolvers<ContextType>;
  FinancePlan_var_pop_fields?: FinancePlan_Var_Pop_FieldsResolvers<ContextType>;
  FinancePlan_var_samp_fields?: FinancePlan_Var_Samp_FieldsResolvers<ContextType>;
  FinancePlan_variance_fields?: FinancePlan_Variance_FieldsResolvers<ContextType>;
  FinanceStatus?: GraphQLScalarType;
  FirmwareContent?: FirmwareContentResolvers<ContextType>;
  FirmwareContent_aggregate?: FirmwareContent_AggregateResolvers<ContextType>;
  FirmwareContent_aggregate_fields?: FirmwareContent_Aggregate_FieldsResolvers<ContextType>;
  FirmwareContent_avg_fields?: FirmwareContent_Avg_FieldsResolvers<ContextType>;
  FirmwareContent_max_fields?: FirmwareContent_Max_FieldsResolvers<ContextType>;
  FirmwareContent_min_fields?: FirmwareContent_Min_FieldsResolvers<ContextType>;
  FirmwareContent_mutation_response?: FirmwareContent_Mutation_ResponseResolvers<ContextType>;
  FirmwareContent_stddev_fields?: FirmwareContent_Stddev_FieldsResolvers<ContextType>;
  FirmwareContent_stddev_pop_fields?: FirmwareContent_Stddev_Pop_FieldsResolvers<ContextType>;
  FirmwareContent_stddev_samp_fields?: FirmwareContent_Stddev_Samp_FieldsResolvers<ContextType>;
  FirmwareContent_sum_fields?: FirmwareContent_Sum_FieldsResolvers<ContextType>;
  FirmwareContent_var_pop_fields?: FirmwareContent_Var_Pop_FieldsResolvers<ContextType>;
  FirmwareContent_var_samp_fields?: FirmwareContent_Var_Samp_FieldsResolvers<ContextType>;
  FirmwareContent_variance_fields?: FirmwareContent_Variance_FieldsResolvers<ContextType>;
  FirmwareDevice?: GraphQLScalarType;
  FitnessRecommenderOutput?: FitnessRecommenderOutputResolvers<ContextType>;
  GeneralRank?: GraphQLScalarType;
  GeneralRankGroup?: GraphQLScalarType;
  GeneticDirectionWebhook?: GeneticDirectionWebhookResolvers<ContextType>;
  GeneticDirectionWebhook_aggregate?: GeneticDirectionWebhook_AggregateResolvers<ContextType>;
  GeneticDirectionWebhook_aggregate_fields?: GeneticDirectionWebhook_Aggregate_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_avg_fields?: GeneticDirectionWebhook_Avg_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_max_fields?: GeneticDirectionWebhook_Max_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_min_fields?: GeneticDirectionWebhook_Min_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_mutation_response?: GeneticDirectionWebhook_Mutation_ResponseResolvers<ContextType>;
  GeneticDirectionWebhook_stddev_fields?: GeneticDirectionWebhook_Stddev_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_stddev_pop_fields?: GeneticDirectionWebhook_Stddev_Pop_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_stddev_samp_fields?: GeneticDirectionWebhook_Stddev_Samp_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_sum_fields?: GeneticDirectionWebhook_Sum_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_var_pop_fields?: GeneticDirectionWebhook_Var_Pop_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_var_samp_fields?: GeneticDirectionWebhook_Var_Samp_FieldsResolvers<ContextType>;
  GeneticDirectionWebhook_variance_fields?: GeneticDirectionWebhook_Variance_FieldsResolvers<ContextType>;
  GlobalFeatureControl?: GlobalFeatureControlResolvers<ContextType>;
  GlobalFeatureControl_aggregate?: GlobalFeatureControl_AggregateResolvers<ContextType>;
  GlobalFeatureControl_aggregate_fields?: GlobalFeatureControl_Aggregate_FieldsResolvers<ContextType>;
  GlobalFeatureControl_max_fields?: GlobalFeatureControl_Max_FieldsResolvers<ContextType>;
  GlobalFeatureControl_min_fields?: GlobalFeatureControl_Min_FieldsResolvers<ContextType>;
  GlobalFeatureControl_mutation_response?: GlobalFeatureControl_Mutation_ResponseResolvers<ContextType>;
  Goal?: GoalResolvers<ContextType>;
  Goal_aggregate?: Goal_AggregateResolvers<ContextType>;
  Goal_aggregate_fields?: Goal_Aggregate_FieldsResolvers<ContextType>;
  Goal_max_fields?: Goal_Max_FieldsResolvers<ContextType>;
  Goal_min_fields?: Goal_Min_FieldsResolvers<ContextType>;
  Goal_mutation_response?: Goal_Mutation_ResponseResolvers<ContextType>;
  HabitCompletedResult?: HabitCompletedResultResolvers<ContextType>;
  HabitDay?: HabitDayResolvers<ContextType>;
  HabitDayOutput?: HabitDayOutputResolvers<ContextType>;
  HabitDay_aggregate?: HabitDay_AggregateResolvers<ContextType>;
  HabitDay_aggregate_fields?: HabitDay_Aggregate_FieldsResolvers<ContextType>;
  HabitDay_avg_fields?: HabitDay_Avg_FieldsResolvers<ContextType>;
  HabitDay_max_fields?: HabitDay_Max_FieldsResolvers<ContextType>;
  HabitDay_min_fields?: HabitDay_Min_FieldsResolvers<ContextType>;
  HabitDay_mutation_response?: HabitDay_Mutation_ResponseResolvers<ContextType>;
  HabitDay_stddev_fields?: HabitDay_Stddev_FieldsResolvers<ContextType>;
  HabitDay_stddev_pop_fields?: HabitDay_Stddev_Pop_FieldsResolvers<ContextType>;
  HabitDay_stddev_samp_fields?: HabitDay_Stddev_Samp_FieldsResolvers<ContextType>;
  HabitDay_sum_fields?: HabitDay_Sum_FieldsResolvers<ContextType>;
  HabitDay_var_pop_fields?: HabitDay_Var_Pop_FieldsResolvers<ContextType>;
  HabitDay_var_samp_fields?: HabitDay_Var_Samp_FieldsResolvers<ContextType>;
  HabitDay_variance_fields?: HabitDay_Variance_FieldsResolvers<ContextType>;
  HabitSubType?: GraphQLScalarType;
  HabitType?: GraphQLScalarType;
  HeartRateDiagnostics?: HeartRateDiagnosticsResolvers<ContextType>;
  HeartRateDiagnostics_aggregate?: HeartRateDiagnostics_AggregateResolvers<ContextType>;
  HeartRateDiagnostics_aggregate_fields?: HeartRateDiagnostics_Aggregate_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_avg_fields?: HeartRateDiagnostics_Avg_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_max_fields?: HeartRateDiagnostics_Max_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_min_fields?: HeartRateDiagnostics_Min_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_mutation_response?: HeartRateDiagnostics_Mutation_ResponseResolvers<ContextType>;
  HeartRateDiagnostics_stddev_fields?: HeartRateDiagnostics_Stddev_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_stddev_pop_fields?: HeartRateDiagnostics_Stddev_Pop_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_stddev_samp_fields?: HeartRateDiagnostics_Stddev_Samp_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_sum_fields?: HeartRateDiagnostics_Sum_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_var_pop_fields?: HeartRateDiagnostics_Var_Pop_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_var_samp_fields?: HeartRateDiagnostics_Var_Samp_FieldsResolvers<ContextType>;
  HeartRateDiagnostics_variance_fields?: HeartRateDiagnostics_Variance_FieldsResolvers<ContextType>;
  HighKneeSetOutput?: HighKneeSetOutputResolvers<ContextType>;
  HistoryAndCounts?: HistoryAndCountsResolvers<ContextType>;
  Household?: HouseholdResolvers<ContextType>;
  HouseholdOutput?: HouseholdOutputResolvers<ContextType>;
  HouseholdType?: GraphQLScalarType;
  Household_aggregate?: Household_AggregateResolvers<ContextType>;
  Household_aggregate_fields?: Household_Aggregate_FieldsResolvers<ContextType>;
  Household_avg_fields?: Household_Avg_FieldsResolvers<ContextType>;
  Household_max_fields?: Household_Max_FieldsResolvers<ContextType>;
  Household_min_fields?: Household_Min_FieldsResolvers<ContextType>;
  Household_mutation_response?: Household_Mutation_ResponseResolvers<ContextType>;
  Household_stddev_fields?: Household_Stddev_FieldsResolvers<ContextType>;
  Household_stddev_pop_fields?: Household_Stddev_Pop_FieldsResolvers<ContextType>;
  Household_stddev_samp_fields?: Household_Stddev_Samp_FieldsResolvers<ContextType>;
  Household_sum_fields?: Household_Sum_FieldsResolvers<ContextType>;
  Household_var_pop_fields?: Household_Var_Pop_FieldsResolvers<ContextType>;
  Household_var_samp_fields?: Household_Var_Samp_FieldsResolvers<ContextType>;
  Household_variance_fields?: Household_Variance_FieldsResolvers<ContextType>;
  HrZone2Output?: HrZone2OutputResolvers<ContextType>;
  IsValidKitOutput?: IsValidKitOutputResolvers<ContextType>;
  LabelsOutput?: LabelsOutputResolvers<ContextType>;
  MarketingEmail?: MarketingEmailResolvers<ContextType>;
  MarketingEmail_aggregate?: MarketingEmail_AggregateResolvers<ContextType>;
  MarketingEmail_aggregate_fields?: MarketingEmail_Aggregate_FieldsResolvers<ContextType>;
  MarketingEmail_max_fields?: MarketingEmail_Max_FieldsResolvers<ContextType>;
  MarketingEmail_min_fields?: MarketingEmail_Min_FieldsResolvers<ContextType>;
  MarketingEmail_mutation_response?: MarketingEmail_Mutation_ResponseResolvers<ContextType>;
  Mastery?: MasteryResolvers<ContextType>;
  Mastery_aggregate?: Mastery_AggregateResolvers<ContextType>;
  Mastery_aggregate_fields?: Mastery_Aggregate_FieldsResolvers<ContextType>;
  Mastery_avg_fields?: Mastery_Avg_FieldsResolvers<ContextType>;
  Mastery_max_fields?: Mastery_Max_FieldsResolvers<ContextType>;
  Mastery_min_fields?: Mastery_Min_FieldsResolvers<ContextType>;
  Mastery_mutation_response?: Mastery_Mutation_ResponseResolvers<ContextType>;
  Mastery_stddev_fields?: Mastery_Stddev_FieldsResolvers<ContextType>;
  Mastery_stddev_pop_fields?: Mastery_Stddev_Pop_FieldsResolvers<ContextType>;
  Mastery_stddev_samp_fields?: Mastery_Stddev_Samp_FieldsResolvers<ContextType>;
  Mastery_sum_fields?: Mastery_Sum_FieldsResolvers<ContextType>;
  Mastery_var_pop_fields?: Mastery_Var_Pop_FieldsResolvers<ContextType>;
  Mastery_var_samp_fields?: Mastery_Var_Samp_FieldsResolvers<ContextType>;
  Mastery_variance_fields?: Mastery_Variance_FieldsResolvers<ContextType>;
  MealPlan?: MealPlanResolvers<ContextType>;
  MealPlanOutput?: MealPlanOutputResolvers<ContextType>;
  MealPlanPreferenceMapItem?: MealPlanPreferenceMapItemResolvers<ContextType>;
  MealPlanPreferenceOutput?: MealPlanPreferenceOutputResolvers<ContextType>;
  MealPlanPreferences?: MealPlanPreferencesResolvers<ContextType>;
  NotInPlanType?: NotInPlanTypeResolvers<ContextType>;
  NutritionReportOutput?: NutritionReportOutputResolvers<ContextType>;
  OnboardingEntityType?: GraphQLScalarType;
  OnboardingInfo?: OnboardingInfoResolvers<ContextType>;
  OnboardingInfo_aggregate?: OnboardingInfo_AggregateResolvers<ContextType>;
  OnboardingInfo_aggregate_fields?: OnboardingInfo_Aggregate_FieldsResolvers<ContextType>;
  OnboardingInfo_avg_fields?: OnboardingInfo_Avg_FieldsResolvers<ContextType>;
  OnboardingInfo_max_fields?: OnboardingInfo_Max_FieldsResolvers<ContextType>;
  OnboardingInfo_min_fields?: OnboardingInfo_Min_FieldsResolvers<ContextType>;
  OnboardingInfo_mutation_response?: OnboardingInfo_Mutation_ResponseResolvers<ContextType>;
  OnboardingInfo_stddev_fields?: OnboardingInfo_Stddev_FieldsResolvers<ContextType>;
  OnboardingInfo_stddev_pop_fields?: OnboardingInfo_Stddev_Pop_FieldsResolvers<ContextType>;
  OnboardingInfo_stddev_samp_fields?: OnboardingInfo_Stddev_Samp_FieldsResolvers<ContextType>;
  OnboardingInfo_sum_fields?: OnboardingInfo_Sum_FieldsResolvers<ContextType>;
  OnboardingInfo_var_pop_fields?: OnboardingInfo_Var_Pop_FieldsResolvers<ContextType>;
  OnboardingInfo_var_samp_fields?: OnboardingInfo_Var_Samp_FieldsResolvers<ContextType>;
  OnboardingInfo_variance_fields?: OnboardingInfo_Variance_FieldsResolvers<ContextType>;
  OnboardingVideos?: OnboardingVideosResolvers<ContextType>;
  OnboardingVideos_aggregate?: OnboardingVideos_AggregateResolvers<ContextType>;
  OnboardingVideos_aggregate_fields?: OnboardingVideos_Aggregate_FieldsResolvers<ContextType>;
  OnboardingVideos_avg_fields?: OnboardingVideos_Avg_FieldsResolvers<ContextType>;
  OnboardingVideos_max_fields?: OnboardingVideos_Max_FieldsResolvers<ContextType>;
  OnboardingVideos_min_fields?: OnboardingVideos_Min_FieldsResolvers<ContextType>;
  OnboardingVideos_mutation_response?: OnboardingVideos_Mutation_ResponseResolvers<ContextType>;
  OnboardingVideos_stddev_fields?: OnboardingVideos_Stddev_FieldsResolvers<ContextType>;
  OnboardingVideos_stddev_pop_fields?: OnboardingVideos_Stddev_Pop_FieldsResolvers<ContextType>;
  OnboardingVideos_stddev_samp_fields?: OnboardingVideos_Stddev_Samp_FieldsResolvers<ContextType>;
  OnboardingVideos_sum_fields?: OnboardingVideos_Sum_FieldsResolvers<ContextType>;
  OnboardingVideos_var_pop_fields?: OnboardingVideos_Var_Pop_FieldsResolvers<ContextType>;
  OnboardingVideos_var_samp_fields?: OnboardingVideos_Var_Samp_FieldsResolvers<ContextType>;
  OnboardingVideos_variance_fields?: OnboardingVideos_Variance_FieldsResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderLineItem?: OrderLineItemResolvers<ContextType>;
  OrderLineItem_aggregate?: OrderLineItem_AggregateResolvers<ContextType>;
  OrderLineItem_aggregate_fields?: OrderLineItem_Aggregate_FieldsResolvers<ContextType>;
  OrderLineItem_avg_fields?: OrderLineItem_Avg_FieldsResolvers<ContextType>;
  OrderLineItem_max_fields?: OrderLineItem_Max_FieldsResolvers<ContextType>;
  OrderLineItem_min_fields?: OrderLineItem_Min_FieldsResolvers<ContextType>;
  OrderLineItem_mutation_response?: OrderLineItem_Mutation_ResponseResolvers<ContextType>;
  OrderLineItem_stddev_fields?: OrderLineItem_Stddev_FieldsResolvers<ContextType>;
  OrderLineItem_stddev_pop_fields?: OrderLineItem_Stddev_Pop_FieldsResolvers<ContextType>;
  OrderLineItem_stddev_samp_fields?: OrderLineItem_Stddev_Samp_FieldsResolvers<ContextType>;
  OrderLineItem_sum_fields?: OrderLineItem_Sum_FieldsResolvers<ContextType>;
  OrderLineItem_var_pop_fields?: OrderLineItem_Var_Pop_FieldsResolvers<ContextType>;
  OrderLineItem_var_samp_fields?: OrderLineItem_Var_Samp_FieldsResolvers<ContextType>;
  OrderLineItem_variance_fields?: OrderLineItem_Variance_FieldsResolvers<ContextType>;
  Order_aggregate?: Order_AggregateResolvers<ContextType>;
  Order_aggregate_fields?: Order_Aggregate_FieldsResolvers<ContextType>;
  Order_avg_fields?: Order_Avg_FieldsResolvers<ContextType>;
  Order_max_fields?: Order_Max_FieldsResolvers<ContextType>;
  Order_min_fields?: Order_Min_FieldsResolvers<ContextType>;
  Order_mutation_response?: Order_Mutation_ResponseResolvers<ContextType>;
  Order_stddev_fields?: Order_Stddev_FieldsResolvers<ContextType>;
  Order_stddev_pop_fields?: Order_Stddev_Pop_FieldsResolvers<ContextType>;
  Order_stddev_samp_fields?: Order_Stddev_Samp_FieldsResolvers<ContextType>;
  Order_sum_fields?: Order_Sum_FieldsResolvers<ContextType>;
  Order_var_pop_fields?: Order_Var_Pop_FieldsResolvers<ContextType>;
  Order_var_samp_fields?: Order_Var_Samp_FieldsResolvers<ContextType>;
  Order_variance_fields?: Order_Variance_FieldsResolvers<ContextType>;
  PauseOrResumeOutput?: PauseOrResumeOutputResolvers<ContextType>;
  PaymentHistory?: PaymentHistoryResolvers<ContextType>;
  PaymentHistory_aggregate?: PaymentHistory_AggregateResolvers<ContextType>;
  PaymentHistory_aggregate_fields?: PaymentHistory_Aggregate_FieldsResolvers<ContextType>;
  PaymentHistory_avg_fields?: PaymentHistory_Avg_FieldsResolvers<ContextType>;
  PaymentHistory_max_fields?: PaymentHistory_Max_FieldsResolvers<ContextType>;
  PaymentHistory_min_fields?: PaymentHistory_Min_FieldsResolvers<ContextType>;
  PaymentHistory_mutation_response?: PaymentHistory_Mutation_ResponseResolvers<ContextType>;
  PaymentHistory_stddev_fields?: PaymentHistory_Stddev_FieldsResolvers<ContextType>;
  PaymentHistory_stddev_pop_fields?: PaymentHistory_Stddev_Pop_FieldsResolvers<ContextType>;
  PaymentHistory_stddev_samp_fields?: PaymentHistory_Stddev_Samp_FieldsResolvers<ContextType>;
  PaymentHistory_sum_fields?: PaymentHistory_Sum_FieldsResolvers<ContextType>;
  PaymentHistory_var_pop_fields?: PaymentHistory_Var_Pop_FieldsResolvers<ContextType>;
  PaymentHistory_var_samp_fields?: PaymentHistory_Var_Samp_FieldsResolvers<ContextType>;
  PaymentHistory_variance_fields?: PaymentHistory_Variance_FieldsResolvers<ContextType>;
  PearTokens?: PearTokensResolvers<ContextType>;
  PlanDay?: PlanDayResolvers<ContextType>;
  PlanDay_aggregate?: PlanDay_AggregateResolvers<ContextType>;
  PlanDay_aggregate_fields?: PlanDay_Aggregate_FieldsResolvers<ContextType>;
  PlanDay_avg_fields?: PlanDay_Avg_FieldsResolvers<ContextType>;
  PlanDay_max_fields?: PlanDay_Max_FieldsResolvers<ContextType>;
  PlanDay_min_fields?: PlanDay_Min_FieldsResolvers<ContextType>;
  PlanDay_mutation_response?: PlanDay_Mutation_ResponseResolvers<ContextType>;
  PlanDay_stddev_fields?: PlanDay_Stddev_FieldsResolvers<ContextType>;
  PlanDay_stddev_pop_fields?: PlanDay_Stddev_Pop_FieldsResolvers<ContextType>;
  PlanDay_stddev_samp_fields?: PlanDay_Stddev_Samp_FieldsResolvers<ContextType>;
  PlanDay_sum_fields?: PlanDay_Sum_FieldsResolvers<ContextType>;
  PlanDay_var_pop_fields?: PlanDay_Var_Pop_FieldsResolvers<ContextType>;
  PlanDay_var_samp_fields?: PlanDay_Var_Samp_FieldsResolvers<ContextType>;
  PlanDay_variance_fields?: PlanDay_Variance_FieldsResolvers<ContextType>;
  PlanProgressCounts?: PlanProgressCountsResolvers<ContextType>;
  PlanProgressOutput?: PlanProgressOutputResolvers<ContextType>;
  PlanProgressRange?: PlanProgressRangeResolvers<ContextType>;
  PlanType?: GraphQLScalarType;
  PlanTypeObject?: PlanTypeObjectResolvers<ContextType>;
  PresetDiet?: PresetDietResolvers<ContextType>;
  PresetDiet_aggregate?: PresetDiet_AggregateResolvers<ContextType>;
  PresetDiet_aggregate_fields?: PresetDiet_Aggregate_FieldsResolvers<ContextType>;
  PresetDiet_max_fields?: PresetDiet_Max_FieldsResolvers<ContextType>;
  PresetDiet_min_fields?: PresetDiet_Min_FieldsResolvers<ContextType>;
  PresetDiet_mutation_response?: PresetDiet_Mutation_ResponseResolvers<ContextType>;
  PresetPlans?: PresetPlansResolvers<ContextType>;
  Pro?: ProResolvers<ContextType>;
  ProMusic?: ProMusicResolvers<ContextType>;
  ProMusic_aggregate?: ProMusic_AggregateResolvers<ContextType>;
  ProMusic_aggregate_fields?: ProMusic_Aggregate_FieldsResolvers<ContextType>;
  ProMusic_max_fields?: ProMusic_Max_FieldsResolvers<ContextType>;
  ProMusic_min_fields?: ProMusic_Min_FieldsResolvers<ContextType>;
  ProMusic_mutation_response?: ProMusic_Mutation_ResponseResolvers<ContextType>;
  Pro_aggregate?: Pro_AggregateResolvers<ContextType>;
  Pro_aggregate_fields?: Pro_Aggregate_FieldsResolvers<ContextType>;
  Pro_max_fields?: Pro_Max_FieldsResolvers<ContextType>;
  Pro_min_fields?: Pro_Min_FieldsResolvers<ContextType>;
  Pro_mutation_response?: Pro_Mutation_ResponseResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductDelivery?: ProductDeliveryResolvers<ContextType>;
  ProductLog?: ProductLogResolvers<ContextType>;
  ProductLog_aggregate?: ProductLog_AggregateResolvers<ContextType>;
  ProductLog_aggregate_fields?: ProductLog_Aggregate_FieldsResolvers<ContextType>;
  ProductLog_max_fields?: ProductLog_Max_FieldsResolvers<ContextType>;
  ProductLog_min_fields?: ProductLog_Min_FieldsResolvers<ContextType>;
  ProductLog_mutation_response?: ProductLog_Mutation_ResponseResolvers<ContextType>;
  Product_aggregate?: Product_AggregateResolvers<ContextType>;
  Product_aggregate_fields?: Product_Aggregate_FieldsResolvers<ContextType>;
  Product_avg_fields?: Product_Avg_FieldsResolvers<ContextType>;
  Product_max_fields?: Product_Max_FieldsResolvers<ContextType>;
  Product_min_fields?: Product_Min_FieldsResolvers<ContextType>;
  Product_mutation_response?: Product_Mutation_ResponseResolvers<ContextType>;
  Product_stddev_fields?: Product_Stddev_FieldsResolvers<ContextType>;
  Product_stddev_pop_fields?: Product_Stddev_Pop_FieldsResolvers<ContextType>;
  Product_stddev_samp_fields?: Product_Stddev_Samp_FieldsResolvers<ContextType>;
  Product_sum_fields?: Product_Sum_FieldsResolvers<ContextType>;
  Product_var_pop_fields?: Product_Var_Pop_FieldsResolvers<ContextType>;
  Product_var_samp_fields?: Product_Var_Samp_FieldsResolvers<ContextType>;
  Product_variance_fields?: Product_Variance_FieldsResolvers<ContextType>;
  ProfileStatsOutput?: ProfileStatsOutputResolvers<ContextType>;
  PromotionalItem?: PromotionalItemResolvers<ContextType>;
  PromotionalItem_aggregate?: PromotionalItem_AggregateResolvers<ContextType>;
  PromotionalItem_aggregate_fields?: PromotionalItem_Aggregate_FieldsResolvers<ContextType>;
  PromotionalItem_avg_fields?: PromotionalItem_Avg_FieldsResolvers<ContextType>;
  PromotionalItem_max_fields?: PromotionalItem_Max_FieldsResolvers<ContextType>;
  PromotionalItem_min_fields?: PromotionalItem_Min_FieldsResolvers<ContextType>;
  PromotionalItem_mutation_response?: PromotionalItem_Mutation_ResponseResolvers<ContextType>;
  PromotionalItem_stddev_fields?: PromotionalItem_Stddev_FieldsResolvers<ContextType>;
  PromotionalItem_stddev_pop_fields?: PromotionalItem_Stddev_Pop_FieldsResolvers<ContextType>;
  PromotionalItem_stddev_samp_fields?: PromotionalItem_Stddev_Samp_FieldsResolvers<ContextType>;
  PromotionalItem_sum_fields?: PromotionalItem_Sum_FieldsResolvers<ContextType>;
  PromotionalItem_var_pop_fields?: PromotionalItem_Var_Pop_FieldsResolvers<ContextType>;
  PromotionalItem_var_samp_fields?: PromotionalItem_Var_Samp_FieldsResolvers<ContextType>;
  PromotionalItem_variance_fields?: PromotionalItem_Variance_FieldsResolvers<ContextType>;
  Props?: PropsResolvers<ContextType>;
  QuizSurvey?: QuizSurveyResolvers<ContextType>;
  QuizSurvey_aggregate?: QuizSurvey_AggregateResolvers<ContextType>;
  QuizSurvey_aggregate_fields?: QuizSurvey_Aggregate_FieldsResolvers<ContextType>;
  QuizSurvey_avg_fields?: QuizSurvey_Avg_FieldsResolvers<ContextType>;
  QuizSurvey_max_fields?: QuizSurvey_Max_FieldsResolvers<ContextType>;
  QuizSurvey_min_fields?: QuizSurvey_Min_FieldsResolvers<ContextType>;
  QuizSurvey_mutation_response?: QuizSurvey_Mutation_ResponseResolvers<ContextType>;
  QuizSurvey_stddev_fields?: QuizSurvey_Stddev_FieldsResolvers<ContextType>;
  QuizSurvey_stddev_pop_fields?: QuizSurvey_Stddev_Pop_FieldsResolvers<ContextType>;
  QuizSurvey_stddev_samp_fields?: QuizSurvey_Stddev_Samp_FieldsResolvers<ContextType>;
  QuizSurvey_sum_fields?: QuizSurvey_Sum_FieldsResolvers<ContextType>;
  QuizSurvey_var_pop_fields?: QuizSurvey_Var_Pop_FieldsResolvers<ContextType>;
  QuizSurvey_var_samp_fields?: QuizSurvey_Var_Samp_FieldsResolvers<ContextType>;
  QuizSurvey_variance_fields?: QuizSurvey_Variance_FieldsResolvers<ContextType>;
  Quote?: QuoteResolvers<ContextType>;
  Quote_aggregate?: Quote_AggregateResolvers<ContextType>;
  Quote_aggregate_fields?: Quote_Aggregate_FieldsResolvers<ContextType>;
  Quote_max_fields?: Quote_Max_FieldsResolvers<ContextType>;
  Quote_min_fields?: Quote_Min_FieldsResolvers<ContextType>;
  Quote_mutation_response?: Quote_Mutation_ResponseResolvers<ContextType>;
  RecommenderOutput?: RecommenderOutputResolvers<ContextType>;
  RecommenderV2CardioAlternatives?: RecommenderV2CardioAlternativesResolvers<ContextType>;
  RecommenderV2CardioObj?: RecommenderV2CardioObjResolvers<ContextType>;
  RecommenderV2Output?: RecommenderV2OutputResolvers<ContextType>;
  RecommenderV2WorkinAlternatives?: RecommenderV2WorkinAlternativesResolvers<ContextType>;
  RecommenderV2WorkinObj?: RecommenderV2WorkinObjResolvers<ContextType>;
  RecommenderWorkinObj?: RecommenderWorkinObjResolvers<ContextType>;
  RecommenderWorkinSubAlternativeObj?: RecommenderWorkinSubAlternativeObjResolvers<ContextType>;
  RecommenderWorkinSubObj?: RecommenderWorkinSubObjResolvers<ContextType>;
  RegisterUser?: RegisterUserResolvers<ContextType>;
  ReimbursementRequirement?: ReimbursementRequirementResolvers<ContextType>;
  ReimbursementRequirement_aggregate?: ReimbursementRequirement_AggregateResolvers<ContextType>;
  ReimbursementRequirement_aggregate_fields?: ReimbursementRequirement_Aggregate_FieldsResolvers<ContextType>;
  ReimbursementRequirement_avg_fields?: ReimbursementRequirement_Avg_FieldsResolvers<ContextType>;
  ReimbursementRequirement_max_fields?: ReimbursementRequirement_Max_FieldsResolvers<ContextType>;
  ReimbursementRequirement_min_fields?: ReimbursementRequirement_Min_FieldsResolvers<ContextType>;
  ReimbursementRequirement_mutation_response?: ReimbursementRequirement_Mutation_ResponseResolvers<ContextType>;
  ReimbursementRequirement_stddev_fields?: ReimbursementRequirement_Stddev_FieldsResolvers<ContextType>;
  ReimbursementRequirement_stddev_pop_fields?: ReimbursementRequirement_Stddev_Pop_FieldsResolvers<ContextType>;
  ReimbursementRequirement_stddev_samp_fields?: ReimbursementRequirement_Stddev_Samp_FieldsResolvers<ContextType>;
  ReimbursementRequirement_sum_fields?: ReimbursementRequirement_Sum_FieldsResolvers<ContextType>;
  ReimbursementRequirement_var_pop_fields?: ReimbursementRequirement_Var_Pop_FieldsResolvers<ContextType>;
  ReimbursementRequirement_var_samp_fields?: ReimbursementRequirement_Var_Samp_FieldsResolvers<ContextType>;
  ReimbursementRequirement_variance_fields?: ReimbursementRequirement_Variance_FieldsResolvers<ContextType>;
  RepMasteryResponse?: RepMasteryResponseResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  ResultListOutput?: ResultListOutputResolvers<ContextType>;
  ResultObjectOutput?: ResultObjectOutputResolvers<ContextType>;
  RetrieveFirmwareV2Output?: RetrieveFirmwareV2OutputResolvers<ContextType>;
  Role?: GraphQLScalarType;
  SampleOutput?: SampleOutputResolvers<ContextType>;
  SampleQuizSurveyOutput?: SampleQuizSurveyOutputResolvers<ContextType>;
  SampleScreenOutput?: SampleScreenOutputResolvers<ContextType>;
  SampleWorkoutZoneOutput?: SampleWorkoutZoneOutputResolvers<ContextType>;
  ScreenOrder?: ScreenOrderResolvers<ContextType>;
  ScreenOrder_aggregate?: ScreenOrder_AggregateResolvers<ContextType>;
  ScreenOrder_aggregate_fields?: ScreenOrder_Aggregate_FieldsResolvers<ContextType>;
  ScreenOrder_avg_fields?: ScreenOrder_Avg_FieldsResolvers<ContextType>;
  ScreenOrder_max_fields?: ScreenOrder_Max_FieldsResolvers<ContextType>;
  ScreenOrder_min_fields?: ScreenOrder_Min_FieldsResolvers<ContextType>;
  ScreenOrder_mutation_response?: ScreenOrder_Mutation_ResponseResolvers<ContextType>;
  ScreenOrder_stddev_fields?: ScreenOrder_Stddev_FieldsResolvers<ContextType>;
  ScreenOrder_stddev_pop_fields?: ScreenOrder_Stddev_Pop_FieldsResolvers<ContextType>;
  ScreenOrder_stddev_samp_fields?: ScreenOrder_Stddev_Samp_FieldsResolvers<ContextType>;
  ScreenOrder_sum_fields?: ScreenOrder_Sum_FieldsResolvers<ContextType>;
  ScreenOrder_var_pop_fields?: ScreenOrder_Var_Pop_FieldsResolvers<ContextType>;
  ScreenOrder_var_samp_fields?: ScreenOrder_Var_Samp_FieldsResolvers<ContextType>;
  ScreenOrder_variance_fields?: ScreenOrder_Variance_FieldsResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  SearchResult_aggregate?: SearchResult_AggregateResolvers<ContextType>;
  SearchResult_aggregate_fields?: SearchResult_Aggregate_FieldsResolvers<ContextType>;
  SearchResult_avg_fields?: SearchResult_Avg_FieldsResolvers<ContextType>;
  SearchResult_max_fields?: SearchResult_Max_FieldsResolvers<ContextType>;
  SearchResult_min_fields?: SearchResult_Min_FieldsResolvers<ContextType>;
  SearchResult_mutation_response?: SearchResult_Mutation_ResponseResolvers<ContextType>;
  SearchResult_stddev_fields?: SearchResult_Stddev_FieldsResolvers<ContextType>;
  SearchResult_stddev_pop_fields?: SearchResult_Stddev_Pop_FieldsResolvers<ContextType>;
  SearchResult_stddev_samp_fields?: SearchResult_Stddev_Samp_FieldsResolvers<ContextType>;
  SearchResult_sum_fields?: SearchResult_Sum_FieldsResolvers<ContextType>;
  SearchResult_var_pop_fields?: SearchResult_Var_Pop_FieldsResolvers<ContextType>;
  SearchResult_var_samp_fields?: SearchResult_Var_Samp_FieldsResolvers<ContextType>;
  SearchResult_variance_fields?: SearchResult_Variance_FieldsResolvers<ContextType>;
  SetQuality?: GraphQLScalarType;
  SetUserGoalsOutput?: SetUserGoalsOutputResolvers<ContextType>;
  SfCoachCallOutput?: SfCoachCallOutputResolvers<ContextType>;
  SfFitnessGoalOutput?: SfFitnessGoalOutputResolvers<ContextType>;
  ShipStatusMessage?: ShipStatusMessageResolvers<ContextType>;
  ShippingAddressOutput?: ShippingAddressOutputResolvers<ContextType>;
  SleepInsightsDetailOutput?: SleepInsightsDetailOutputResolvers<ContextType>;
  SleepInsightsOutput?: SleepInsightsOutputResolvers<ContextType>;
  SleepSurvey?: SleepSurveyResolvers<ContextType>;
  SleepSurvey_aggregate?: SleepSurvey_AggregateResolvers<ContextType>;
  SleepSurvey_aggregate_fields?: SleepSurvey_Aggregate_FieldsResolvers<ContextType>;
  SleepSurvey_max_fields?: SleepSurvey_Max_FieldsResolvers<ContextType>;
  SleepSurvey_min_fields?: SleepSurvey_Min_FieldsResolvers<ContextType>;
  SleepSurvey_mutation_response?: SleepSurvey_Mutation_ResponseResolvers<ContextType>;
  StartAssessmentOutput?: StartAssessmentOutputResolvers<ContextType>;
  StepsV2Output?: StepsV2OutputResolvers<ContextType>;
  StrapLocation?: GraphQLScalarType;
  SubWorkoutType?: GraphQLScalarType;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionLog?: SubscriptionLogResolvers<ContextType>;
  SubscriptionLog_aggregate?: SubscriptionLog_AggregateResolvers<ContextType>;
  SubscriptionLog_aggregate_fields?: SubscriptionLog_Aggregate_FieldsResolvers<ContextType>;
  SubscriptionLog_max_fields?: SubscriptionLog_Max_FieldsResolvers<ContextType>;
  SubscriptionLog_min_fields?: SubscriptionLog_Min_FieldsResolvers<ContextType>;
  SubscriptionLog_mutation_response?: SubscriptionLog_Mutation_ResponseResolvers<ContextType>;
  SubscriptionType?: GraphQLScalarType;
  Subscription_aggregate?: Subscription_AggregateResolvers<ContextType>;
  Subscription_aggregate_fields?: Subscription_Aggregate_FieldsResolvers<ContextType>;
  Subscription_avg_fields?: Subscription_Avg_FieldsResolvers<ContextType>;
  Subscription_max_fields?: Subscription_Max_FieldsResolvers<ContextType>;
  Subscription_min_fields?: Subscription_Min_FieldsResolvers<ContextType>;
  Subscription_mutation_response?: Subscription_Mutation_ResponseResolvers<ContextType>;
  Subscription_stddev_fields?: Subscription_Stddev_FieldsResolvers<ContextType>;
  Subscription_stddev_pop_fields?: Subscription_Stddev_Pop_FieldsResolvers<ContextType>;
  Subscription_stddev_samp_fields?: Subscription_Stddev_Samp_FieldsResolvers<ContextType>;
  Subscription_sum_fields?: Subscription_Sum_FieldsResolvers<ContextType>;
  Subscription_var_pop_fields?: Subscription_Var_Pop_FieldsResolvers<ContextType>;
  Subscription_var_samp_fields?: Subscription_Var_Samp_FieldsResolvers<ContextType>;
  Subscription_variance_fields?: Subscription_Variance_FieldsResolvers<ContextType>;
  SurveyActivity?: GraphQLScalarType;
  SurveyAreasNeedingHelp?: GraphQLScalarType;
  SurveyEquipment?: GraphQLScalarType;
  SurveyFavoriteExercise?: GraphQLScalarType;
  SurveyFitnessAbility?: GraphQLScalarType;
  SurveyMotivation?: GraphQLScalarType;
  SurveyQuizAge?: GraphQLScalarType;
  SurveyQuizGender?: GraphQLScalarType;
  SurveyServicesTried?: GraphQLScalarType;
  SurveySleep?: GraphQLScalarType;
  SurveyWeightLossGoal?: GraphQLScalarType;
  SurveyWeightLossTimeline?: GraphQLScalarType;
  SurveyWorkouts?: GraphQLScalarType;
  ThemeColor?: GraphQLScalarType;
  TimeMovingOutput?: TimeMovingOutputResolvers<ContextType>;
  TimeWorkingOutOutput?: TimeWorkingOutOutputResolvers<ContextType>;
  Token?: TokenResolvers<ContextType>;
  TokenChangeSource?: GraphQLScalarType;
  TokenChangeType?: GraphQLScalarType;
  TokenHistory?: TokenHistoryResolvers<ContextType>;
  TokenHistory_aggregate?: TokenHistory_AggregateResolvers<ContextType>;
  TokenHistory_aggregate_fields?: TokenHistory_Aggregate_FieldsResolvers<ContextType>;
  TokenHistory_avg_fields?: TokenHistory_Avg_FieldsResolvers<ContextType>;
  TokenHistory_max_fields?: TokenHistory_Max_FieldsResolvers<ContextType>;
  TokenHistory_min_fields?: TokenHistory_Min_FieldsResolvers<ContextType>;
  TokenHistory_mutation_response?: TokenHistory_Mutation_ResponseResolvers<ContextType>;
  TokenHistory_stddev_fields?: TokenHistory_Stddev_FieldsResolvers<ContextType>;
  TokenHistory_stddev_pop_fields?: TokenHistory_Stddev_Pop_FieldsResolvers<ContextType>;
  TokenHistory_stddev_samp_fields?: TokenHistory_Stddev_Samp_FieldsResolvers<ContextType>;
  TokenHistory_sum_fields?: TokenHistory_Sum_FieldsResolvers<ContextType>;
  TokenHistory_var_pop_fields?: TokenHistory_Var_Pop_FieldsResolvers<ContextType>;
  TokenHistory_var_samp_fields?: TokenHistory_Var_Samp_FieldsResolvers<ContextType>;
  TokenHistory_variance_fields?: TokenHistory_Variance_FieldsResolvers<ContextType>;
  Token_aggregate?: Token_AggregateResolvers<ContextType>;
  Token_aggregate_fields?: Token_Aggregate_FieldsResolvers<ContextType>;
  Token_avg_fields?: Token_Avg_FieldsResolvers<ContextType>;
  Token_max_fields?: Token_Max_FieldsResolvers<ContextType>;
  Token_min_fields?: Token_Min_FieldsResolvers<ContextType>;
  Token_mutation_response?: Token_Mutation_ResponseResolvers<ContextType>;
  Token_stddev_fields?: Token_Stddev_FieldsResolvers<ContextType>;
  Token_stddev_pop_fields?: Token_Stddev_Pop_FieldsResolvers<ContextType>;
  Token_stddev_samp_fields?: Token_Stddev_Samp_FieldsResolvers<ContextType>;
  Token_sum_fields?: Token_Sum_FieldsResolvers<ContextType>;
  Token_var_pop_fields?: Token_Var_Pop_FieldsResolvers<ContextType>;
  Token_var_samp_fields?: Token_Var_Samp_FieldsResolvers<ContextType>;
  Token_variance_fields?: Token_Variance_FieldsResolvers<ContextType>;
  TutorialMode?: GraphQLScalarType;
  Unit?: GraphQLScalarType;
  UploadContents?: UploadContentsResolvers<ContextType>;
  UploadSetOutput?: UploadSetOutputResolvers<ContextType>;
  UploadSetV2Output?: UploadSetV2OutputResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAggregates?: UserAggregatesResolvers<ContextType>;
  UserAggregates_aggregate?: UserAggregates_AggregateResolvers<ContextType>;
  UserAggregates_aggregate_fields?: UserAggregates_Aggregate_FieldsResolvers<ContextType>;
  UserAggregates_avg_fields?: UserAggregates_Avg_FieldsResolvers<ContextType>;
  UserAggregates_max_fields?: UserAggregates_Max_FieldsResolvers<ContextType>;
  UserAggregates_min_fields?: UserAggregates_Min_FieldsResolvers<ContextType>;
  UserAggregates_mutation_response?: UserAggregates_Mutation_ResponseResolvers<ContextType>;
  UserAggregates_stddev_fields?: UserAggregates_Stddev_FieldsResolvers<ContextType>;
  UserAggregates_stddev_pop_fields?: UserAggregates_Stddev_Pop_FieldsResolvers<ContextType>;
  UserAggregates_stddev_samp_fields?: UserAggregates_Stddev_Samp_FieldsResolvers<ContextType>;
  UserAggregates_sum_fields?: UserAggregates_Sum_FieldsResolvers<ContextType>;
  UserAggregates_var_pop_fields?: UserAggregates_Var_Pop_FieldsResolvers<ContextType>;
  UserAggregates_var_samp_fields?: UserAggregates_Var_Samp_FieldsResolvers<ContextType>;
  UserAggregates_variance_fields?: UserAggregates_Variance_FieldsResolvers<ContextType>;
  UserAssessment?: UserAssessmentResolvers<ContextType>;
  UserAssessment_aggregate?: UserAssessment_AggregateResolvers<ContextType>;
  UserAssessment_aggregate_fields?: UserAssessment_Aggregate_FieldsResolvers<ContextType>;
  UserAssessment_avg_fields?: UserAssessment_Avg_FieldsResolvers<ContextType>;
  UserAssessment_max_fields?: UserAssessment_Max_FieldsResolvers<ContextType>;
  UserAssessment_min_fields?: UserAssessment_Min_FieldsResolvers<ContextType>;
  UserAssessment_mutation_response?: UserAssessment_Mutation_ResponseResolvers<ContextType>;
  UserAssessment_stddev_fields?: UserAssessment_Stddev_FieldsResolvers<ContextType>;
  UserAssessment_stddev_pop_fields?: UserAssessment_Stddev_Pop_FieldsResolvers<ContextType>;
  UserAssessment_stddev_samp_fields?: UserAssessment_Stddev_Samp_FieldsResolvers<ContextType>;
  UserAssessment_sum_fields?: UserAssessment_Sum_FieldsResolvers<ContextType>;
  UserAssessment_var_pop_fields?: UserAssessment_Var_Pop_FieldsResolvers<ContextType>;
  UserAssessment_var_samp_fields?: UserAssessment_Var_Samp_FieldsResolvers<ContextType>;
  UserAssessment_variance_fields?: UserAssessment_Variance_FieldsResolvers<ContextType>;
  UserFeatureControl?: UserFeatureControlResolvers<ContextType>;
  UserFeatureControl_aggregate?: UserFeatureControl_AggregateResolvers<ContextType>;
  UserFeatureControl_aggregate_fields?: UserFeatureControl_Aggregate_FieldsResolvers<ContextType>;
  UserFeatureControl_max_fields?: UserFeatureControl_Max_FieldsResolvers<ContextType>;
  UserFeatureControl_min_fields?: UserFeatureControl_Min_FieldsResolvers<ContextType>;
  UserFeatureControl_mutation_response?: UserFeatureControl_Mutation_ResponseResolvers<ContextType>;
  UserFeedback?: UserFeedbackResolvers<ContextType>;
  UserFeedback_aggregate?: UserFeedback_AggregateResolvers<ContextType>;
  UserFeedback_aggregate_fields?: UserFeedback_Aggregate_FieldsResolvers<ContextType>;
  UserFeedback_avg_fields?: UserFeedback_Avg_FieldsResolvers<ContextType>;
  UserFeedback_max_fields?: UserFeedback_Max_FieldsResolvers<ContextType>;
  UserFeedback_min_fields?: UserFeedback_Min_FieldsResolvers<ContextType>;
  UserFeedback_mutation_response?: UserFeedback_Mutation_ResponseResolvers<ContextType>;
  UserFeedback_stddev_fields?: UserFeedback_Stddev_FieldsResolvers<ContextType>;
  UserFeedback_stddev_pop_fields?: UserFeedback_Stddev_Pop_FieldsResolvers<ContextType>;
  UserFeedback_stddev_samp_fields?: UserFeedback_Stddev_Samp_FieldsResolvers<ContextType>;
  UserFeedback_sum_fields?: UserFeedback_Sum_FieldsResolvers<ContextType>;
  UserFeedback_var_pop_fields?: UserFeedback_Var_Pop_FieldsResolvers<ContextType>;
  UserFeedback_var_samp_fields?: UserFeedback_Var_Samp_FieldsResolvers<ContextType>;
  UserFeedback_variance_fields?: UserFeedback_Variance_FieldsResolvers<ContextType>;
  UserFinancePlan?: UserFinancePlanResolvers<ContextType>;
  UserFinancePlan_aggregate?: UserFinancePlan_AggregateResolvers<ContextType>;
  UserFinancePlan_aggregate_fields?: UserFinancePlan_Aggregate_FieldsResolvers<ContextType>;
  UserFinancePlan_max_fields?: UserFinancePlan_Max_FieldsResolvers<ContextType>;
  UserFinancePlan_min_fields?: UserFinancePlan_Min_FieldsResolvers<ContextType>;
  UserFinancePlan_mutation_response?: UserFinancePlan_Mutation_ResponseResolvers<ContextType>;
  UserHabit?: UserHabitResolvers<ContextType>;
  UserHabit_aggregate?: UserHabit_AggregateResolvers<ContextType>;
  UserHabit_aggregate_fields?: UserHabit_Aggregate_FieldsResolvers<ContextType>;
  UserHabit_avg_fields?: UserHabit_Avg_FieldsResolvers<ContextType>;
  UserHabit_max_fields?: UserHabit_Max_FieldsResolvers<ContextType>;
  UserHabit_min_fields?: UserHabit_Min_FieldsResolvers<ContextType>;
  UserHabit_mutation_response?: UserHabit_Mutation_ResponseResolvers<ContextType>;
  UserHabit_stddev_fields?: UserHabit_Stddev_FieldsResolvers<ContextType>;
  UserHabit_stddev_pop_fields?: UserHabit_Stddev_Pop_FieldsResolvers<ContextType>;
  UserHabit_stddev_samp_fields?: UserHabit_Stddev_Samp_FieldsResolvers<ContextType>;
  UserHabit_sum_fields?: UserHabit_Sum_FieldsResolvers<ContextType>;
  UserHabit_var_pop_fields?: UserHabit_Var_Pop_FieldsResolvers<ContextType>;
  UserHabit_var_samp_fields?: UserHabit_Var_Samp_FieldsResolvers<ContextType>;
  UserHabit_variance_fields?: UserHabit_Variance_FieldsResolvers<ContextType>;
  UserMonthsActiveOutput?: UserMonthsActiveOutputResolvers<ContextType>;
  UserMovementDaily?: UserMovementDailyResolvers<ContextType>;
  UserMovementDaily_aggregate?: UserMovementDaily_AggregateResolvers<ContextType>;
  UserMovementDaily_aggregate_fields?: UserMovementDaily_Aggregate_FieldsResolvers<ContextType>;
  UserMovementDaily_avg_fields?: UserMovementDaily_Avg_FieldsResolvers<ContextType>;
  UserMovementDaily_max_fields?: UserMovementDaily_Max_FieldsResolvers<ContextType>;
  UserMovementDaily_min_fields?: UserMovementDaily_Min_FieldsResolvers<ContextType>;
  UserMovementDaily_mutation_response?: UserMovementDaily_Mutation_ResponseResolvers<ContextType>;
  UserMovementDaily_stddev_fields?: UserMovementDaily_Stddev_FieldsResolvers<ContextType>;
  UserMovementDaily_stddev_pop_fields?: UserMovementDaily_Stddev_Pop_FieldsResolvers<ContextType>;
  UserMovementDaily_stddev_samp_fields?: UserMovementDaily_Stddev_Samp_FieldsResolvers<ContextType>;
  UserMovementDaily_sum_fields?: UserMovementDaily_Sum_FieldsResolvers<ContextType>;
  UserMovementDaily_var_pop_fields?: UserMovementDaily_Var_Pop_FieldsResolvers<ContextType>;
  UserMovementDaily_var_samp_fields?: UserMovementDaily_Var_Samp_FieldsResolvers<ContextType>;
  UserMovementDaily_variance_fields?: UserMovementDaily_Variance_FieldsResolvers<ContextType>;
  UserPreferences?: UserPreferencesResolvers<ContextType>;
  UserPreferencesOutput?: UserPreferencesOutputResolvers<ContextType>;
  UserPreferences_aggregate?: UserPreferences_AggregateResolvers<ContextType>;
  UserPreferences_aggregate_fields?: UserPreferences_Aggregate_FieldsResolvers<ContextType>;
  UserPreferences_max_fields?: UserPreferences_Max_FieldsResolvers<ContextType>;
  UserPreferences_min_fields?: UserPreferences_Min_FieldsResolvers<ContextType>;
  UserPreferences_mutation_response?: UserPreferences_Mutation_ResponseResolvers<ContextType>;
  UserProduct?: UserProductResolvers<ContextType>;
  UserProductHistory?: UserProductHistoryResolvers<ContextType>;
  UserProductHistory_aggregate?: UserProductHistory_AggregateResolvers<ContextType>;
  UserProductHistory_aggregate_fields?: UserProductHistory_Aggregate_FieldsResolvers<ContextType>;
  UserProductHistory_max_fields?: UserProductHistory_Max_FieldsResolvers<ContextType>;
  UserProductHistory_min_fields?: UserProductHistory_Min_FieldsResolvers<ContextType>;
  UserProductHistory_mutation_response?: UserProductHistory_Mutation_ResponseResolvers<ContextType>;
  UserProduct_aggregate?: UserProduct_AggregateResolvers<ContextType>;
  UserProduct_aggregate_fields?: UserProduct_Aggregate_FieldsResolvers<ContextType>;
  UserProduct_avg_fields?: UserProduct_Avg_FieldsResolvers<ContextType>;
  UserProduct_max_fields?: UserProduct_Max_FieldsResolvers<ContextType>;
  UserProduct_min_fields?: UserProduct_Min_FieldsResolvers<ContextType>;
  UserProduct_mutation_response?: UserProduct_Mutation_ResponseResolvers<ContextType>;
  UserProduct_stddev_fields?: UserProduct_Stddev_FieldsResolvers<ContextType>;
  UserProduct_stddev_pop_fields?: UserProduct_Stddev_Pop_FieldsResolvers<ContextType>;
  UserProduct_stddev_samp_fields?: UserProduct_Stddev_Samp_FieldsResolvers<ContextType>;
  UserProduct_sum_fields?: UserProduct_Sum_FieldsResolvers<ContextType>;
  UserProduct_var_pop_fields?: UserProduct_Var_Pop_FieldsResolvers<ContextType>;
  UserProduct_var_samp_fields?: UserProduct_Var_Samp_FieldsResolvers<ContextType>;
  UserProduct_variance_fields?: UserProduct_Variance_FieldsResolvers<ContextType>;
  UserProductsOutput?: UserProductsOutputResolvers<ContextType>;
  UserReadiness?: UserReadinessResolvers<ContextType>;
  UserReadinessLog?: UserReadinessLogResolvers<ContextType>;
  UserReadinessLog_aggregate?: UserReadinessLog_AggregateResolvers<ContextType>;
  UserReadinessLog_aggregate_fields?: UserReadinessLog_Aggregate_FieldsResolvers<ContextType>;
  UserReadinessLog_max_fields?: UserReadinessLog_Max_FieldsResolvers<ContextType>;
  UserReadinessLog_min_fields?: UserReadinessLog_Min_FieldsResolvers<ContextType>;
  UserReadinessLog_mutation_response?: UserReadinessLog_Mutation_ResponseResolvers<ContextType>;
  UserReadiness_aggregate?: UserReadiness_AggregateResolvers<ContextType>;
  UserReadiness_aggregate_fields?: UserReadiness_Aggregate_FieldsResolvers<ContextType>;
  UserReadiness_avg_fields?: UserReadiness_Avg_FieldsResolvers<ContextType>;
  UserReadiness_max_fields?: UserReadiness_Max_FieldsResolvers<ContextType>;
  UserReadiness_min_fields?: UserReadiness_Min_FieldsResolvers<ContextType>;
  UserReadiness_mutation_response?: UserReadiness_Mutation_ResponseResolvers<ContextType>;
  UserReadiness_stddev_fields?: UserReadiness_Stddev_FieldsResolvers<ContextType>;
  UserReadiness_stddev_pop_fields?: UserReadiness_Stddev_Pop_FieldsResolvers<ContextType>;
  UserReadiness_stddev_samp_fields?: UserReadiness_Stddev_Samp_FieldsResolvers<ContextType>;
  UserReadiness_sum_fields?: UserReadiness_Sum_FieldsResolvers<ContextType>;
  UserReadiness_var_pop_fields?: UserReadiness_Var_Pop_FieldsResolvers<ContextType>;
  UserReadiness_var_samp_fields?: UserReadiness_Var_Samp_FieldsResolvers<ContextType>;
  UserReadiness_variance_fields?: UserReadiness_Variance_FieldsResolvers<ContextType>;
  UserReimbursmentHistory?: UserReimbursmentHistoryResolvers<ContextType>;
  UserReimbursmentHistory_aggregate?: UserReimbursmentHistory_AggregateResolvers<ContextType>;
  UserReimbursmentHistory_aggregate_fields?: UserReimbursmentHistory_Aggregate_FieldsResolvers<ContextType>;
  UserReimbursmentHistory_max_fields?: UserReimbursmentHistory_Max_FieldsResolvers<ContextType>;
  UserReimbursmentHistory_min_fields?: UserReimbursmentHistory_Min_FieldsResolvers<ContextType>;
  UserReimbursmentHistory_mutation_response?: UserReimbursmentHistory_Mutation_ResponseResolvers<ContextType>;
  UserRep?: UserRepResolvers<ContextType>;
  UserRep_aggregate?: UserRep_AggregateResolvers<ContextType>;
  UserRep_aggregate_fields?: UserRep_Aggregate_FieldsResolvers<ContextType>;
  UserRep_avg_fields?: UserRep_Avg_FieldsResolvers<ContextType>;
  UserRep_max_fields?: UserRep_Max_FieldsResolvers<ContextType>;
  UserRep_min_fields?: UserRep_Min_FieldsResolvers<ContextType>;
  UserRep_mutation_response?: UserRep_Mutation_ResponseResolvers<ContextType>;
  UserRep_stddev_fields?: UserRep_Stddev_FieldsResolvers<ContextType>;
  UserRep_stddev_pop_fields?: UserRep_Stddev_Pop_FieldsResolvers<ContextType>;
  UserRep_stddev_samp_fields?: UserRep_Stddev_Samp_FieldsResolvers<ContextType>;
  UserRep_sum_fields?: UserRep_Sum_FieldsResolvers<ContextType>;
  UserRep_var_pop_fields?: UserRep_Var_Pop_FieldsResolvers<ContextType>;
  UserRep_var_samp_fields?: UserRep_Var_Samp_FieldsResolvers<ContextType>;
  UserRep_variance_fields?: UserRep_Variance_FieldsResolvers<ContextType>;
  UserSet?: UserSetResolvers<ContextType>;
  UserSet_aggregate?: UserSet_AggregateResolvers<ContextType>;
  UserSet_aggregate_fields?: UserSet_Aggregate_FieldsResolvers<ContextType>;
  UserSet_avg_fields?: UserSet_Avg_FieldsResolvers<ContextType>;
  UserSet_max_fields?: UserSet_Max_FieldsResolvers<ContextType>;
  UserSet_min_fields?: UserSet_Min_FieldsResolvers<ContextType>;
  UserSet_mutation_response?: UserSet_Mutation_ResponseResolvers<ContextType>;
  UserSet_stddev_fields?: UserSet_Stddev_FieldsResolvers<ContextType>;
  UserSet_stddev_pop_fields?: UserSet_Stddev_Pop_FieldsResolvers<ContextType>;
  UserSet_stddev_samp_fields?: UserSet_Stddev_Samp_FieldsResolvers<ContextType>;
  UserSet_sum_fields?: UserSet_Sum_FieldsResolvers<ContextType>;
  UserSet_var_pop_fields?: UserSet_Var_Pop_FieldsResolvers<ContextType>;
  UserSet_var_samp_fields?: UserSet_Var_Samp_FieldsResolvers<ContextType>;
  UserSet_variance_fields?: UserSet_Variance_FieldsResolvers<ContextType>;
  UserState?: UserStateResolvers<ContextType>;
  UserStateOutput?: UserStateOutputResolvers<ContextType>;
  UserState_aggregate?: UserState_AggregateResolvers<ContextType>;
  UserState_aggregate_fields?: UserState_Aggregate_FieldsResolvers<ContextType>;
  UserState_max_fields?: UserState_Max_FieldsResolvers<ContextType>;
  UserState_min_fields?: UserState_Min_FieldsResolvers<ContextType>;
  UserState_mutation_response?: UserState_Mutation_ResponseResolvers<ContextType>;
  UserTrainingPlan?: UserTrainingPlanResolvers<ContextType>;
  UserTrainingPlan_aggregate?: UserTrainingPlan_AggregateResolvers<ContextType>;
  UserTrainingPlan_aggregate_fields?: UserTrainingPlan_Aggregate_FieldsResolvers<ContextType>;
  UserTrainingPlan_avg_fields?: UserTrainingPlan_Avg_FieldsResolvers<ContextType>;
  UserTrainingPlan_max_fields?: UserTrainingPlan_Max_FieldsResolvers<ContextType>;
  UserTrainingPlan_min_fields?: UserTrainingPlan_Min_FieldsResolvers<ContextType>;
  UserTrainingPlan_mutation_response?: UserTrainingPlan_Mutation_ResponseResolvers<ContextType>;
  UserTrainingPlan_stddev_fields?: UserTrainingPlan_Stddev_FieldsResolvers<ContextType>;
  UserTrainingPlan_stddev_pop_fields?: UserTrainingPlan_Stddev_Pop_FieldsResolvers<ContextType>;
  UserTrainingPlan_stddev_samp_fields?: UserTrainingPlan_Stddev_Samp_FieldsResolvers<ContextType>;
  UserTrainingPlan_sum_fields?: UserTrainingPlan_Sum_FieldsResolvers<ContextType>;
  UserTrainingPlan_var_pop_fields?: UserTrainingPlan_Var_Pop_FieldsResolvers<ContextType>;
  UserTrainingPlan_var_samp_fields?: UserTrainingPlan_Var_Samp_FieldsResolvers<ContextType>;
  UserTrainingPlan_variance_fields?: UserTrainingPlan_Variance_FieldsResolvers<ContextType>;
  UserWorkout?: UserWorkoutResolvers<ContextType>;
  UserWorkout_aggregate?: UserWorkout_AggregateResolvers<ContextType>;
  UserWorkout_aggregate_fields?: UserWorkout_Aggregate_FieldsResolvers<ContextType>;
  UserWorkout_avg_fields?: UserWorkout_Avg_FieldsResolvers<ContextType>;
  UserWorkout_max_fields?: UserWorkout_Max_FieldsResolvers<ContextType>;
  UserWorkout_min_fields?: UserWorkout_Min_FieldsResolvers<ContextType>;
  UserWorkout_mutation_response?: UserWorkout_Mutation_ResponseResolvers<ContextType>;
  UserWorkout_stddev_fields?: UserWorkout_Stddev_FieldsResolvers<ContextType>;
  UserWorkout_stddev_pop_fields?: UserWorkout_Stddev_Pop_FieldsResolvers<ContextType>;
  UserWorkout_stddev_samp_fields?: UserWorkout_Stddev_Samp_FieldsResolvers<ContextType>;
  UserWorkout_sum_fields?: UserWorkout_Sum_FieldsResolvers<ContextType>;
  UserWorkout_var_pop_fields?: UserWorkout_Var_Pop_FieldsResolvers<ContextType>;
  UserWorkout_var_samp_fields?: UserWorkout_Var_Samp_FieldsResolvers<ContextType>;
  UserWorkout_variance_fields?: UserWorkout_Variance_FieldsResolvers<ContextType>;
  User_aggregate?: User_AggregateResolvers<ContextType>;
  User_aggregate_fields?: User_Aggregate_FieldsResolvers<ContextType>;
  User_avg_fields?: User_Avg_FieldsResolvers<ContextType>;
  User_max_fields?: User_Max_FieldsResolvers<ContextType>;
  User_min_fields?: User_Min_FieldsResolvers<ContextType>;
  User_mutation_response?: User_Mutation_ResponseResolvers<ContextType>;
  User_stddev_fields?: User_Stddev_FieldsResolvers<ContextType>;
  User_stddev_pop_fields?: User_Stddev_Pop_FieldsResolvers<ContextType>;
  User_stddev_samp_fields?: User_Stddev_Samp_FieldsResolvers<ContextType>;
  User_sum_fields?: User_Sum_FieldsResolvers<ContextType>;
  User_var_pop_fields?: User_Var_Pop_FieldsResolvers<ContextType>;
  User_var_samp_fields?: User_Var_Samp_FieldsResolvers<ContextType>;
  User_variance_fields?: User_Variance_FieldsResolvers<ContextType>;
  ValLabels?: ValLabelsResolvers<ContextType>;
  ValueAndTime?: ValueAndTimeResolvers<ContextType>;
  ValueTimeAndAverage?: ValueTimeAndAverageResolvers<ContextType>;
  ValueTimeAndMinimum?: ValueTimeAndMinimumResolvers<ContextType>;
  VendorErrorLog?: VendorErrorLogResolvers<ContextType>;
  VendorErrorLog_aggregate?: VendorErrorLog_AggregateResolvers<ContextType>;
  VendorErrorLog_aggregate_fields?: VendorErrorLog_Aggregate_FieldsResolvers<ContextType>;
  VendorErrorLog_max_fields?: VendorErrorLog_Max_FieldsResolvers<ContextType>;
  VendorErrorLog_min_fields?: VendorErrorLog_Min_FieldsResolvers<ContextType>;
  VendorErrorLog_mutation_response?: VendorErrorLog_Mutation_ResponseResolvers<ContextType>;
  VendorType?: GraphQLScalarType;
  VersionControl?: VersionControlResolvers<ContextType>;
  VersionControl_aggregate?: VersionControl_AggregateResolvers<ContextType>;
  VersionControl_aggregate_fields?: VersionControl_Aggregate_FieldsResolvers<ContextType>;
  VersionControl_avg_fields?: VersionControl_Avg_FieldsResolvers<ContextType>;
  VersionControl_max_fields?: VersionControl_Max_FieldsResolvers<ContextType>;
  VersionControl_min_fields?: VersionControl_Min_FieldsResolvers<ContextType>;
  VersionControl_mutation_response?: VersionControl_Mutation_ResponseResolvers<ContextType>;
  VersionControl_stddev_fields?: VersionControl_Stddev_FieldsResolvers<ContextType>;
  VersionControl_stddev_pop_fields?: VersionControl_Stddev_Pop_FieldsResolvers<ContextType>;
  VersionControl_stddev_samp_fields?: VersionControl_Stddev_Samp_FieldsResolvers<ContextType>;
  VersionControl_sum_fields?: VersionControl_Sum_FieldsResolvers<ContextType>;
  VersionControl_var_pop_fields?: VersionControl_Var_Pop_FieldsResolvers<ContextType>;
  VersionControl_var_samp_fields?: VersionControl_Var_Samp_FieldsResolvers<ContextType>;
  VersionControl_variance_fields?: VersionControl_Variance_FieldsResolvers<ContextType>;
  WeeklyPlan?: WeeklyPlanResolvers<ContextType>;
  WeeklyPlan_aggregate?: WeeklyPlan_AggregateResolvers<ContextType>;
  WeeklyPlan_aggregate_fields?: WeeklyPlan_Aggregate_FieldsResolvers<ContextType>;
  WeeklyPlan_avg_fields?: WeeklyPlan_Avg_FieldsResolvers<ContextType>;
  WeeklyPlan_max_fields?: WeeklyPlan_Max_FieldsResolvers<ContextType>;
  WeeklyPlan_min_fields?: WeeklyPlan_Min_FieldsResolvers<ContextType>;
  WeeklyPlan_mutation_response?: WeeklyPlan_Mutation_ResponseResolvers<ContextType>;
  WeeklyPlan_stddev_fields?: WeeklyPlan_Stddev_FieldsResolvers<ContextType>;
  WeeklyPlan_stddev_pop_fields?: WeeklyPlan_Stddev_Pop_FieldsResolvers<ContextType>;
  WeeklyPlan_stddev_samp_fields?: WeeklyPlan_Stddev_Samp_FieldsResolvers<ContextType>;
  WeeklyPlan_sum_fields?: WeeklyPlan_Sum_FieldsResolvers<ContextType>;
  WeeklyPlan_var_pop_fields?: WeeklyPlan_Var_Pop_FieldsResolvers<ContextType>;
  WeeklyPlan_var_samp_fields?: WeeklyPlan_Var_Samp_FieldsResolvers<ContextType>;
  WeeklyPlan_variance_fields?: WeeklyPlan_Variance_FieldsResolvers<ContextType>;
  WorkIOWithEpoch?: WorkIoWithEpochResolvers<ContextType>;
  WorkinPrefrence?: WorkinPrefrenceResolvers<ContextType>;
  WorkinPrefrenceOutput?: WorkinPrefrenceOutputResolvers<ContextType>;
  WorkinPrefrence_aggregate?: WorkinPrefrence_AggregateResolvers<ContextType>;
  WorkinPrefrence_aggregate_fields?: WorkinPrefrence_Aggregate_FieldsResolvers<ContextType>;
  WorkinPrefrence_max_fields?: WorkinPrefrence_Max_FieldsResolvers<ContextType>;
  WorkinPrefrence_min_fields?: WorkinPrefrence_Min_FieldsResolvers<ContextType>;
  WorkinPrefrence_mutation_response?: WorkinPrefrence_Mutation_ResponseResolvers<ContextType>;
  Workout?: WorkoutResolvers<ContextType>;
  WorkoutCategory?: GraphQLScalarType;
  WorkoutPrefrence?: WorkoutPrefrenceResolvers<ContextType>;
  WorkoutPrefrenceOutput?: WorkoutPrefrenceOutputResolvers<ContextType>;
  WorkoutPrefrence_aggregate?: WorkoutPrefrence_AggregateResolvers<ContextType>;
  WorkoutPrefrence_aggregate_fields?: WorkoutPrefrence_Aggregate_FieldsResolvers<ContextType>;
  WorkoutPrefrence_max_fields?: WorkoutPrefrence_Max_FieldsResolvers<ContextType>;
  WorkoutPrefrence_min_fields?: WorkoutPrefrence_Min_FieldsResolvers<ContextType>;
  WorkoutPrefrence_mutation_response?: WorkoutPrefrence_Mutation_ResponseResolvers<ContextType>;
  WorkoutType?: GraphQLScalarType;
  Workout_aggregate?: Workout_AggregateResolvers<ContextType>;
  Workout_aggregate_fields?: Workout_Aggregate_FieldsResolvers<ContextType>;
  Workout_avg_fields?: Workout_Avg_FieldsResolvers<ContextType>;
  Workout_max_fields?: Workout_Max_FieldsResolvers<ContextType>;
  Workout_min_fields?: Workout_Min_FieldsResolvers<ContextType>;
  Workout_mutation_response?: Workout_Mutation_ResponseResolvers<ContextType>;
  Workout_stddev_fields?: Workout_Stddev_FieldsResolvers<ContextType>;
  Workout_stddev_pop_fields?: Workout_Stddev_Pop_FieldsResolvers<ContextType>;
  Workout_stddev_samp_fields?: Workout_Stddev_Samp_FieldsResolvers<ContextType>;
  Workout_sum_fields?: Workout_Sum_FieldsResolvers<ContextType>;
  Workout_var_pop_fields?: Workout_Var_Pop_FieldsResolvers<ContextType>;
  Workout_var_samp_fields?: Workout_Var_Samp_FieldsResolvers<ContextType>;
  Workout_variance_fields?: Workout_Variance_FieldsResolvers<ContextType>;
  _FollowingPro?: _FollowingProResolvers<ContextType>;
  _FollowingPro_aggregate?: _FollowingPro_AggregateResolvers<ContextType>;
  _FollowingPro_aggregate_fields?: _FollowingPro_Aggregate_FieldsResolvers<ContextType>;
  _FollowingPro_max_fields?: _FollowingPro_Max_FieldsResolvers<ContextType>;
  _FollowingPro_min_fields?: _FollowingPro_Min_FieldsResolvers<ContextType>;
  _FollowingPro_mutation_response?: _FollowingPro_Mutation_ResponseResolvers<ContextType>;
  _GoalToUserPreferences?: _GoalToUserPreferencesResolvers<ContextType>;
  _GoalToUserPreferences_aggregate?: _GoalToUserPreferences_AggregateResolvers<ContextType>;
  _GoalToUserPreferences_aggregate_fields?: _GoalToUserPreferences_Aggregate_FieldsResolvers<ContextType>;
  _GoalToUserPreferences_max_fields?: _GoalToUserPreferences_Max_FieldsResolvers<ContextType>;
  _GoalToUserPreferences_min_fields?: _GoalToUserPreferences_Min_FieldsResolvers<ContextType>;
  _GoalToUserPreferences_mutation_response?: _GoalToUserPreferences_Mutation_ResponseResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  bioStrapAssignment?: BioStrapAssignmentResolvers<ContextType>;
  createManyOutput?: CreateManyOutputResolvers<ContextType>;
  date?: GraphQLScalarType;
  fetchActivityAverages?: FetchActivityAveragesResolvers<ContextType>;
  fetchActivityResult?: FetchActivityResultResolvers<ContextType>;
  fetchActivityTotals?: FetchActivityTotalsResolvers<ContextType>;
  fetchFeatureFlagsOutput?: FetchFeatureFlagsOutputResolvers<ContextType>;
  fetchHabitsOutput?: FetchHabitsOutputResolvers<ContextType>;
  fetchPlansOutput?: FetchPlansOutputResolvers<ContextType>;
  float8?: GraphQLScalarType;
  generateSignedUrlResponse?: GenerateSignedUrlResponseResolvers<ContextType>;
  genericListOutput?: GenericListOutputResolvers<ContextType>;
  getSFCoachIdOutput?: GetSfCoachIdOutputResolvers<ContextType>;
  getUserByAuth0IdOutput?: GetUserByAuth0IdOutputResolvers<ContextType>;
  idObj?: IdObjResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  messageOutput?: MessageOutputResolvers<ContextType>;
  mobileVersionsOutput?: MobileVersionsOutputResolvers<ContextType>;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  planHistoryOutput?: PlanHistoryOutputResolvers<ContextType>;
  planHistoryTotals?: PlanHistoryTotalsResolvers<ContextType>;
  planHistoryWeek?: PlanHistoryWeekResolvers<ContextType>;
  qualifyWorkoutOutput?: QualifyWorkoutOutputResolvers<ContextType>;
  query_root?: Query_RootResolvers<ContextType>;
  recommendWorkoutByOptionOutput?: RecommendWorkoutByOptionOutputResolvers<ContextType>;
  sleepAverage?: SleepAverageResolvers<ContextType>;
  sleepInsightsDetailData?: SleepInsightsDetailDataResolvers<ContextType>;
  sleepV1?: SleepV1Resolvers<ContextType>;
  startWorkoutOutput?: StartWorkoutOutputResolvers<ContextType>;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  time?: GraphQLScalarType;
  timestamp?: GraphQLScalarType;
  updateReimbursementTokensResponse?: UpdateReimbursementTokensResponseResolvers<ContextType>;
  updateUser?: UpdateUserResolvers<ContextType>;
  userNutritionReportOutput?: UserNutritionReportOutputResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  cached?: CachedDirectiveResolver<any, any, ContextType>;
}>;
