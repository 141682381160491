import { v4 as uuidv4 } from 'uuid';

import { segmentUserUnauthenticatedAnalytics } from '@/lib/segment/segment';
import { globalContextProperties, globalEventProperties } from '@/utils/analytics/analytics-core';
import { sendFacebookPixelEvent, sendTikTokPixelEvent } from '@/utils/analytics/pixel';

export const leadQuizInitiatedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track('Lead Quiz Initiated', globalEventProperties(), {
    ...globalContextProperties(),
  });
};

export const weightLossQuizInitiatedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track(
    'Weight Loss Quiz Initiated',
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const weightLossQuizCheckoutStartedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track(
    'Weight Loss Quiz Checkout Started',
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const leadQuizStartedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track('Lead Quiz Age Selected', globalEventProperties(), {
    ...globalContextProperties(),
  });
};

export const leadQuizAnalytic = (name: string, answer: string) => {
  segmentUserUnauthenticatedAnalytics?.track(
    `Lead Quiz - ${name}`,
    {
      ...globalEventProperties(),
      answer,
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const weightLossQuizAnalytic = (name: string, answer: string) => {
  segmentUserUnauthenticatedAnalytics?.track(
    `Weight Loss Quiz - ${name}`,
    {
      ...globalEventProperties(),
      answer,
    },
    {
      ...globalContextProperties(),
    }
  );
};

export type DemoQuizAnswersT = {
  age_range: string;
  fitness_goal: string;
  weight_loss_goal: string;
  timeframe: string;
  current_weight: string;
  gender: string;
  hours_slept_each_night: string;
  anxiety_frequency: string;
  chronic_inflammation: boolean;
  burning_fat_or_maintaing_weight: string;
  burning_fat_or_maintaing_weight_specify?: string;
  burning_carbs: string;
  burning_carbs_specify?: string;
  preferred_exercise: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type WeightLossQuizAnswersT = {
  age: string;
  biggest_challenge: string;
  email: string;
  first_name: string;
  weight_loss_goal: string;
  last_name: string;
  relationship: string;
  what_support: string;
};

export const leadQuizCompletedAnalytics = (answers: DemoQuizAnswersT) => {
  const eventId = uuidv4();
  segmentUserUnauthenticatedAnalytics?.track(
    'Lead Quiz Completed',
    {
      ...globalEventProperties(),
      ...answers,
      ...attachGclid(),
      facebookEventId: eventId,
    },
    {
      ...globalContextProperties(),
    }
  );
  sendFacebookPixelEvent(
    'Lead Quiz Completed',
    {
      em: answers.email.trim().toLowerCase(),
      fn: answers.first_name.trim().toLowerCase(),
      ln: answers.last_name.trim().toLowerCase(),
      ...((answers.gender === 'Man' || answers.gender === 'Woman') && {
        ge: answers.gender[0].toLowerCase() === 'm' ? 'm' : 'f',
      }),
    },
    eventId
  );
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent('LeadQuizCompleted', {
    event_id: eventId,
    timestamp: new Date().toISOString(),
    email: answers.email.trim().toLowerCase(),
    user_agent: user_agent,
  });
};

export const weightLossQuizCompletedAnalytics = (answers: WeightLossQuizAnswersT) => {
  const eventId = uuidv4();
  segmentUserUnauthenticatedAnalytics?.track(
    'Weight Loss Quiz Completed',
    {
      ...globalEventProperties(),
      ...answers,
      ...attachGclid(),
      facebookEventId: eventId,
    },
    {
      ...globalContextProperties(),
    }
  );
  sendFacebookPixelEvent(
    'Weight Loss Quiz Completed',
    {
      em: answers.email.trim().toLowerCase(),
      fn: answers.first_name.trim().toLowerCase(),
      ln: answers.last_name.trim().toLowerCase(),
    },
    eventId
  );
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent('WeightLossQuizCompleted', {
    event_id: eventId,
    timestamp: new Date().toISOString(),
    email: answers.email.trim().toLowerCase(),
    user_agent: user_agent,
  });
};

export const attachGclid = () => {
  const gclidJSON = localStorage.getItem('gclid');
  if (!gclidJSON) {
    return undefined;
  }
  const gclid = JSON.parse(gclidJSON);
  const gclidIsValid = new Date().getTime() < gclid.expiryDate;
  if (!gclidIsValid) {
    return undefined;
  }
  return {
    gclid: gclid.value,
  };
};
