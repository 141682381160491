import { segmentUserUnauthenticatedAnalytics } from '@/lib/segment/segment';
import {
  getSegmentAnalytics,
  globalContextProperties,
  globalEventProperties,
} from '@/utils/analytics/analytics-core';

export const productModuleInteractedAnalytics = (
  interactionType: string,
  moduleTitle: string,
  image_url: string
) => {
  getSegmentAnalytics()?.track(
    'Product Module Interacted',
    {
      interaction_type: interactionType,
      interaction_timestamp: new Date().toISOString(),
      module_name: moduleTitle,
      image_url: image_url,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const productViewedAnalytics = (productName: string) => {
  getSegmentAnalytics()?.track(
    'Product Viewed',
    {
      product_name: productName,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const buyDNAKitClickedAnalytics = (buttonLocation: string) => {
  getSegmentAnalytics()?.track(
    'Buy DNA Kit Button Clicked',
    {
      location: buttonLocation,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const privacyAndSecurityDropdownInteractedAnalytics = (
  sectionTitle: string,
  opened: boolean
) => {
  getSegmentAnalytics()?.track(
    'Privacy & Security Dropdown Interacted',
    {
      dropdown_title: sectionTitle,
      action: opened ? 'Expanded' : 'Collapsed',
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const productDetailsExpandedAnalytics = (
  sectionTitle: string,
  productName: string,
  productId: string
) => {
  getSegmentAnalytics()?.track(
    'Product Details Expanded',
    {
      details_name: sectionTitle,
      product_name: productName,
      product_id: productId,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const imageCarouselInteractedAnalytics = (interactionType: string) => {
  getSegmentAnalytics()?.track(
    'Image Carousel Interacted',
    {
      interaction_type: interactionType,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const ageVerificationClickedAnalytics = () => {
  getSegmentAnalytics()?.track('Age Verification Clicked', globalEventProperties(), {
    ...globalContextProperties(),
  });
};

export const viewBagAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track('View Bag', globalEventProperties(), {
    ...globalContextProperties(),
  });
};

export const checkedIsGiftAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track('DNA Gift', globalEventProperties(), {
    ...globalContextProperties(),
  });
};
