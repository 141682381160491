import { logEvent } from 'firebase/analytics';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import { segmentUserUnauthenticatedAnalytics } from '@/lib/segment/segment';
import {
  getSegmentAnalytics,
  globalContextProperties,
  globalEventProperties,
} from '@/utils/analytics/analytics-core';

export const qrCodeAnalytics = (
  utm_source: string | string[],
  utm_medium: string | string[],
  utm_campaign: string | string[]
) => {
  logEvent(firebaseAnalytics, 's1_qr_code_scanned', {
    utm_source,
    utm_medium,
    utm_campaign,
  });
  getSegmentAnalytics()?.track('s1_qr_code_scanned', {
    utm_source,
    utm_medium,
    utm_campaign,
  });
};

export const systemDropDownInteractedAnalytics = (destination: string, title: string) => {
  getSegmentAnalytics()?.track(
    'System Dropdown Interacted',
    {
      ...globalEventProperties(),
      destination_url: destination,
      page_title: title,
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const virtualDemoInitiatedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics?.track('Virtual Demo Button Click', globalEventProperties(), {
    ...globalContextProperties(),
  });
};
