import littledata from '@littledata/headless-shopify-sdk';
import { getCookie } from 'cookies-next';
import clone from 'just-clone';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { ShopReducer, ShopReducerTypes } from '@/context/ShopContextReducer';
import type {
  AttributeInput,
  CartBuyerIdentityUpdateMutationOptions,
  CartCreateMutationOptions,
  CartDeliveryAddressesAddMutationOptions,
} from '@/generated/shopify';
import {
  useAddToCartMutation,
  useCartBuyerIdentityUpdateMutation,
  useCartCreateMutation,
  useCartDeliveryAddressesAddMutation,
  useCartDetailsLazyQuery,
  useFetchCartLinesLazyQuery,
  useRemoveFromCartMutation,
  useUpdateCartAttributesMutation,
  useUpdateCartDiscountCodesMutation,
} from '@/generated/shopify';
import type {
  ShopContextProviderProps,
  ShopContextType,
  ShopStateType,
  UpsertManyToCartParams,
  UpsertToCartParams,
} from '@/models/shop-context';
import type { ShopifyCart } from '@/models/shop-context-cart';
import { ShopifyService } from '@/services/shopify';
import { buyNowAnalytics, viewItem } from '@/utils/analytics';
import { exponentialBackoff } from '@/utils/misc';

const REGULAR_PRICE = 99;

const initialState: ShopStateType = {
  cart: {} as ShopifyCart,
  discountMap: {},
  cartAttributes: [],
  cartError: null,
};

export const ShopContext = createContext<ShopContextType>({
  state: initialState,
  dispatch: () => null,
  createCart: async () => {},
  upsertToCart: async () => {},
  upsertManyToCart: async () => {},
  applyDiscounts: async () => {},
  appendAttributes: async () => {},
  clearCart: async () => {},
  updateCartBuyerIdentity: async () => {},
  addCartDeliveryAddresses: async () => {},
  goToCheckout: async () => {},
  cartIsAvailable: false,
  cartIsReadyForCheckout: true,
  segmentAnonymousId: null,
  cartTotalPrice: () => '',
  cartTotalPriceNumber: () => 0,
  cartSaveAmount: () => '',
  regularPrice: REGULAR_PRICE,
});

export const useShopContext = () => {
  const context = React.useContext(ShopContext);
  if (context === undefined) {
    throw new Error('useShopContext must be used within a ShopContextProvider');
  }
  return context;
};

export const ShopContextProvider: React.FC<ShopContextProviderProps> = ({
  children,
  segmentAnonymousId,
  availableDiscountCodes,
}) => {
  const regularPrice = REGULAR_PRICE;
  const [state, dispatch] = useReducer(ShopReducer, initialState);
  const [cartIsAvailable, setCartIsAvailable] = useState(false);
  const [cartIsReadyForCheckout, setCartIsReadyForCheckout] = useState(false);
  // const [cartId, setCartId] = useState(false);

  const cartInitializeAttempted = useRef(false);
  const cartDetailsAttempted = useRef(false);

  const [createCartMutation] = useCartCreateMutation();
  const [cartDetailsLazyQuery] = useCartDetailsLazyQuery();
  const [upsertToCartMutation] = useAddToCartMutation();
  const [updateCartAttributesMutation] = useUpdateCartAttributesMutation();
  const [cartBuyerIdentityUpdateMutation] = useCartBuyerIdentityUpdateMutation();
  const [cartDeliveryAddressesAddMutation] = useCartDeliveryAddressesAddMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [fetchCartLinesLazyQuery] = useFetchCartLinesLazyQuery();
  const [updateCartDiscountCodesMutation] = useUpdateCartDiscountCodesMutation();

  const shopifyService = useMemo(
    () =>
      new ShopifyService(
        createCartMutation,
        cartDetailsLazyQuery,
        upsertToCartMutation,
        updateCartAttributesMutation,
        cartBuyerIdentityUpdateMutation,
        cartDeliveryAddressesAddMutation,
        removeFromCartMutation,
        fetchCartLinesLazyQuery,
        updateCartDiscountCodesMutation
      ),
    [
      createCartMutation,
      cartDetailsLazyQuery,
      upsertToCartMutation,
      updateCartAttributesMutation,
      cartBuyerIdentityUpdateMutation,
      cartDeliveryAddressesAddMutation,
      removeFromCartMutation,
      fetchCartLinesLazyQuery,
      updateCartDiscountCodesMutation,
    ]
  );

  const createCart = useCallback(
    async (data: CartCreateMutationOptions['variables']) => {
      try {
        const cart = await shopifyService.createCart(data);
        const cartToken = shopifyService.extractCartToken(cart.id);
        if (!cartToken) throw new Error('Invalid cart token');
        dispatch({ type: ShopReducerTypes.CreateCart, payload: cart });
        setCartIsAvailable(true);
        await littledata.sendCartToLittledata(cartToken);
        await shopifyService.sendCampaignData(cart.id);
      } catch (error) {
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to create cart' });
      }
    },
    [shopifyService]
  );

  const _cartHasMerchandiseId = useCallback(
    (merchandiseId: string): boolean => {
      const foundEdge = state.cart?.lines?.edges.find((edge) => {
        return edge.node.merchandise.id === merchandiseId;
      });
      return !!foundEdge;
    },
    [state.cart?.lines?.edges]
  );

  const _cartHasDiscount = useCallback(
    (promocode: string): boolean => {
      const foundCode = state.cart?.discountCodes?.find((discountCode) => {
        return discountCode.code === promocode;
      });
      return !!foundCode;
    },
    [state.cart?.discountCodes]
  );

  const _shouldUpdateDiscountCodes = useCallback(
    (discountCodes: string[]): [boolean, string[]] => {
      let shouldUpdate = false;
      const discountCodesForUpdate: string[] = clone(state.cart.discountCodes).map(
        (discountCode) => discountCode.code
      );

      discountCodes.forEach((discountCode) => {
        const foundDiscountCode = discountCodesForUpdate.find(
          (existingCode) => existingCode === discountCode
        );
        if (!foundDiscountCode) {
          discountCodesForUpdate.push(discountCode);
          shouldUpdate = true;
        }
      });
      return [shouldUpdate, discountCodesForUpdate];
    },
    [state.cart.discountCodes]
  );

  const applyDiscounts = useCallback(
    async (discountCodes: string[]) => {
      try {
        const [shouldUpdate, discountCodesForUpdate] = _shouldUpdateDiscountCodes(discountCodes);

        if (!shouldUpdate) {
          setCartIsReadyForCheckout(true);
          return;
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.addDiscountToCart({
          cartId: state.cart.id,
          discountCodes: discountCodesForUpdate,
        });
        dispatch({ type: ShopReducerTypes.UpdateCart, payload: cart! });
        setCartIsReadyForCheckout(true);
      } catch (error) {
        dispatch({
          type: ShopReducerTypes.SetCartError,
          payload: 'Failed to apply discounts to cart',
        });
      }
    },
    [shopifyService, state.cart.id, _shouldUpdateDiscountCodes]
  );

  const _shouldUpdateAttributes = useCallback(
    (attributes: AttributeInput[]): [boolean, AttributeInput[]] => {
      let shouldUpdate = false;
      const attributesForUpdate = clone(state.cart.attributes).map((attribute) => ({
        // INFO: Removing __typename
        key: attribute.key,
        value: attribute.value as string,
      }));

      attributes.forEach((attribute) => {
        const foundAttribute = attributesForUpdate.find(
          (existingAttribute) => existingAttribute.key === attribute.key
        );
        if (foundAttribute) {
          if (attribute.value !== foundAttribute.value) {
            foundAttribute.value = attribute.value;
            shouldUpdate = true;
          }
        } else {
          attributesForUpdate.push(attribute);
          shouldUpdate = true;
        }
      });
      return [shouldUpdate, attributesForUpdate];
    },
    [state.cart.attributes]
  );

  const appendAttributes = useCallback(
    async (attributes: AttributeInput[]) => {
      try {
        const [shouldUpdate, attributesForUpdate] = _shouldUpdateAttributes(attributes);
        if (!shouldUpdate) {
          setCartIsReadyForCheckout(true);
          return;
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.updateCartAttributes({
          cartId: state.cart.id,
          attributes: attributesForUpdate,
        });
        dispatch({ type: ShopReducerTypes.UpdateCart, payload: cart! });
        setCartIsReadyForCheckout(true);
      } catch (error) {
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to append attributes' });
      }
    },
    [state.cart.id, shopifyService, _shouldUpdateAttributes]
  );

  const upsertToCart = useCallback(
    async ({ merchandiseId, discountCodes, ...params }: UpsertToCartParams) => {
      const quantity = params.quantity || 1;
      try {
        if (_cartHasMerchandiseId(merchandiseId)) {
          // console.debug(`# DEBUG: ALREADY HAS MERCHANDISE_ID: ${merchandiseId}`);
          if (discountCodes) {
            await applyDiscounts(discountCodes);
          }
          setCartIsReadyForCheckout(true);
          return;
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.addToCart({
          lines: [
            {
              merchandiseId,
              quantity,
            },
          ],
          cartId: state.cart?.id,
        });
        dispatch({ type: ShopReducerTypes.AddToCart, payload: cart });
        if (discountCodes) {
          await applyDiscounts(discountCodes);
        }
        setCartIsReadyForCheckout(true);
        viewItem(cart);
      } catch (error) {
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to add to cart' });
      }
    },
    [shopifyService, state.cart?.id, _cartHasMerchandiseId, applyDiscounts]
  );

  const clearCart = useCallback(async () => {
    const cartId = state.cart?.id;
    if (!cartId) return;

    const lineIds = await shopifyService.fetchCartLines(cartId);
    if (!lineIds?.length) return;

    const cart = await shopifyService.removeCartLines(cartId, lineIds);
    dispatch({ type: ShopReducerTypes.DeleteCartItem, payload: cart });
  }, [shopifyService, state.cart?.id]);

  const upsertManyToCart = useCallback(
    async ({ lines, discountCodes, attributes }: UpsertManyToCartParams) => {
      try {
        if (state.cart?.lines?.edges?.length > 0) {
          // INFO: When upserting many, if there is already items, remove them first.
          await clearCart();
        }
        setCartIsReadyForCheckout(false);
        const cart = await shopifyService.addToCart({
          lines,
          cartId: state.cart?.id,
        });
        if (attributes) {
          await appendAttributes(attributes);
        }
        dispatch({ type: ShopReducerTypes.AddToCart, payload: cart });
        if (discountCodes && discountCodes.length > 0) {
          await applyDiscounts(discountCodes);
        }
        setCartIsReadyForCheckout(true);
        viewItem(cart);
      } catch (error) {
        dispatch({ type: ShopReducerTypes.SetCartError, payload: 'Failed to add many to cart' });
      }
    },
    [
      state.cart?.lines?.edges.length,
      state.cart?.id,
      shopifyService,
      clearCart,
      appendAttributes,
      applyDiscounts,
    ]
  );

  const addCartDeliveryAddresses = useCallback(
    async (
      addresses: NonNullable<CartDeliveryAddressesAddMutationOptions['variables']>['addresses']
    ) => {
      const cartId = state.cart?.id;
      if (!cartId) return;

      const result = await shopifyService.addCartDeliveryAddresses({ cartId, addresses });
      dispatch({ type: ShopReducerTypes.UpdateCart, payload: result });
    },
    [shopifyService, state.cart?.id]
  );

  const updateCartBuyerIdentity = useCallback(
    async (
      buyerIdentity: NonNullable<
        CartBuyerIdentityUpdateMutationOptions['variables']
      >['buyerIdentity'],
      addresses?: NonNullable<CartDeliveryAddressesAddMutationOptions['variables']>['addresses']
    ) => {
      const cartId = state.cart?.id;
      if (!cartId) return;

      const result = await shopifyService.updateCartBuyerIdentity({ cartId, buyerIdentity });

      if (addresses) {
        await addCartDeliveryAddresses(addresses);
      }

      dispatch({ type: ShopReducerTypes.UpdateCart, payload: result });
    },
    [shopifyService, state.cart?.id, addCartDeliveryAddresses]
  );

  const goToCheckout = useCallback(async () => {
    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventValue: {},
      eventName: `GO_TO_CHECKOUT`,
    });
    // INFO: Do this first before redirect
    await buyNowAnalytics(state.cart);
    dispatch({ type: ShopReducerTypes.Checkout });
  }, [state.cart]);

  const _initializeCart = useCallback(async () => {
    if (!segmentAnonymousId || cartInitializeAttempted.current || state.cart.id) return;
    console.debug('# DEBUG: Initializing Cart');
    cartInitializeAttempted.current = true;
    await exponentialBackoff(async () =>
      createCart({
        input: {
          attributes: _prepareCartAttributes(segmentAnonymousId),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentAnonymousId, state.cart.id]);

  const _loadCart = useCallback(
    async (cartId: string) => {
      if (cartDetailsAttempted.current) return;
      console.debug('# DEBUG: Initializing Cart');
      cartDetailsAttempted.current = true;
      try {
        const cartDetails: ShopifyCart = await shopifyService.cartDetails(cartId);
        dispatch({ type: ShopReducerTypes.CreateCart, payload: cartDetails });
        setCartIsAvailable(true);
      } catch (error) {
        // INFO: If we can not load the cart, initialize a new cart
        _initializeCart();
      }
      // console.debug('# DEBUG: ', cartDetails);
    },
    [shopifyService, _initializeCart]
  );

  const _prepareCartAttributes = (segmentAnonymousId: string): AttributeInput[] => {
    const attributes: AttributeInput[] = [
      {
        key: '_segment-clientID',
        value: segmentAnonymousId,
      },
    ];
    const vwoUuid = getCookie('_vwo_uuid');
    if (typeof vwoUuid === 'string' && vwoUuid) {
      attributes.push({ key: '_vwo_uuid', value: vwoUuid });
    }
    return [...attributes];
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const shopCartId = getCookie('shop_cart_id');
    if (typeof shopCartId === 'string') {
      _loadCart(shopCartId);
    } else {
      _initializeCart();
    }
  }, [_initializeCart, _loadCart]);

  const cartTotalPrice = useCallback(() => {
    if (!state.cart?.cost?.subtotalAmount?.amount) {
      return '';
    }
    return `$${parseFloat(state.cart.cost.subtotalAmount.amount)}`;
  }, [state.cart?.cost?.subtotalAmount?.amount]);

  const cartTotalPriceNumber = useCallback(() => {
    if (!state.cart?.cost?.subtotalAmount?.amount) {
      return 0;
    }
    return parseFloat(state.cart.cost.subtotalAmount.amount);
  }, [state.cart?.cost?.subtotalAmount?.amount]);

  const cartSaveAmount = useCallback(() => {
    if (!state.cart?.cost?.subtotalAmount?.amount) {
      return '';
    }
    return `$${REGULAR_PRICE - parseFloat(state.cart.cost.subtotalAmount.amount)}`;
  }, [state.cart?.cost?.subtotalAmount?.amount]);

  const value = useMemo(
    () => ({
      state,
      dispatch,
      createCart,
      upsertToCart,
      upsertManyToCart,
      applyDiscounts,
      appendAttributes,
      clearCart,
      updateCartBuyerIdentity,
      addCartDeliveryAddresses,
      goToCheckout,
      cartIsAvailable,
      cartIsReadyForCheckout,
      segmentAnonymousId,
      cartTotalPrice,
      cartTotalPriceNumber,
      cartSaveAmount,
      regularPrice,
    }),
    [
      state,
      dispatch,
      createCart,
      upsertToCart,
      upsertManyToCart,
      applyDiscounts,
      appendAttributes,
      clearCart,
      updateCartBuyerIdentity,
      addCartDeliveryAddresses,
      goToCheckout,
      cartIsAvailable,
      cartIsReadyForCheckout,
      segmentAnonymousId,
      cartTotalPrice,
      cartTotalPriceNumber,
      cartSaveAmount,
      regularPrice,
    ]
  );

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};
