import * as Sentry from '@sentry/nextjs';
import { getCookie } from 'cookies-next';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useEffect, useReducer, useRef } from 'react';

import type { FetchUserShippingAddressQuery } from '@/generated/hasura';
import {
  useFetchUserShippingAddressLazyQuery,
  useGetUserLazyQuery,
  useUpdateUserCuidMutation,
} from '@/generated/hasura';
import { logout, parseAuthJWT } from '@/lib/auth0/auth';
import type {
  UserReducerAction,
  UserShippingAddress,
  UserStateType,
  UserType,
} from '@/models/user.model';
import { toUserType, UserReducerTypes } from '@/models/user.model';
import { segmentIdentify } from '@/utils/analytics';
import { fullName } from '@/utils/helpers/full-name';
import { stringOrUndefined } from '@/utils/type-safe';

const UserReducer = (state: UserStateType, action: UserReducerAction): UserStateType => {
  const { type, user, shippingAddress } = action;
  switch (type) {
    case UserReducerTypes.LogIn:
      return { ...state, User: toUserType(user) };
    case UserReducerTypes.LogOut:
      return { ...state };
    case UserReducerTypes.FetchUser:
      return { ...state, User: toUserType(user) };
    case UserReducerTypes.SetShippingAddress:
      return {
        ...state,
        User: {
          ...(state.User as UserType), // INFO_sam: ...loose, but only called after user is successfull
          shippingAddress: toUserShippingAddress(shippingAddress),
        },
      };
    default:
      return state;
  }
};

const toUserShippingAddress = (
  shippingAddress: FetchUserShippingAddressQuery['fetchUserShippingAddress']
): UserShippingAddress => {
  if (!shippingAddress) {
    throw new Error('SHIPPING_ADDRESS_DEFINED');
  }
  return {
    zip: stringOrUndefined(shippingAddress?.shipping_address?.zip),
    city: stringOrUndefined(shippingAddress?.shipping_address?.city),
    country: stringOrUndefined(shippingAddress?.shipping_address?.country),
    address1: stringOrUndefined(shippingAddress?.shipping_address?.address1),
    address2: stringOrUndefined(shippingAddress?.shipping_address?.address2),
    province: stringOrUndefined(shippingAddress?.shipping_address?.province),
    country_code: stringOrUndefined(shippingAddress?.shipping_address?.country_code),
    province_code: stringOrUndefined(shippingAddress?.shipping_address?.province_code),
  };
};

const initialState: UserStateType = {
  User: null,
};

export const UserContext = createContext<{
  state: UserStateType;
  dispatch: React.Dispatch<UserReducerAction>;
}>({ state: initialState, dispatch: () => null });

export const UserContextProvider = ({ children }: any) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const auth0UserId = parseAuthJWT('id');

  const getUserAttempted = useRef(false);

  const [getUser] = useGetUserLazyQuery({
    variables: { auth0UserId: auth0UserId },
    context: { clientName: 'rapptr' },
    onCompleted: ({ User }) => {
      console.debug('# DEBUG: User:', User);
      dispatch({
        type: UserReducerTypes.FetchUser,
        user: User[0],
      });
      processUser(toUserType(User[0]));
    },
    onError: (error) => {
      console.error('# ERROR: USER_NOT_FOUND', error);
      logout();
      router.push({ pathname: '/login' });
    },
  });

  const [updateUserCuidMutation] = useUpdateUserCuidMutation({
    context: { clientName: 'rapptr' },
    onCompleted: () => {},
    onError: (error) => {
      console.error('ERROR_UPDATING_USER_CID', error);
    },
  });

  const [getShippingAddress] = useFetchUserShippingAddressLazyQuery({
    context: { clientName: 'rapptr' },
    onCompleted: ({ fetchUserShippingAddress }) => {
      if (fetchUserShippingAddress?.shipping_address) {
        dispatch({
          type: UserReducerTypes.SetShippingAddress,
          shippingAddress: fetchUserShippingAddress,
        });
      }
    },
    onError: (error) => {
      console.error('ERROR_FETCHING_SHIPPING_ADDRESS', error);
    },
  });

  const fetchShippingAddress = useCallback(() => {
    if (state.User) {
      getShippingAddress({ variables: { userId: state.User.id } });
    }
  }, [state.User, getShippingAddress]);

  const updateCuid = useCallback(() => {
    if (!state.User) return;

    const afUserId = getCookie('afUserId');
    if (state.User.cuid !== afUserId) {
      updateUserCuidMutation({
        variables: {
          userId: state.User.id,
          afWebUserId: afUserId as string,
        },
        context: { clientName: 'rapptr' },
      });
    }
  }, [state.User, updateUserCuidMutation]);

  const processUser = useCallback(
    (user: UserType) => {
      // console.trace(state.User?.id, fetchShippingAddress, updateCuid)
      if (user?.id) {
        console.debug('# DEBUG: PROCESSING_USER');
        // getShippingAddress({ variables: { userId: user.id } });
        fetchShippingAddress();

        segmentIdentify({
          id: user.id,
          email: user.email,
          firstName: user.firstName as string,
          lastName: user.lastName as string,
          birthday: user.birthday as string,
          gender: user.gender || '',
          'x-hasura-role': parseAuthJWT('role'),
        });

        LogRocket.identify(user.id, {
          email: user.email,
          name: fullName(user.lastName, user.lastName),
        });
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
        Sentry.setContext('profile', {
          ...user,
        });

        updateCuid();
      }
    },
    [fetchShippingAddress, updateCuid]
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && auth0UserId) {
      if (getUserAttempted.current) {
        return;
      }
      console.debug('# DEBUG: GET_USER');
      getUserAttempted.current = true;
      getUser();
    }
  }, [auth0UserId, getUser]);

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};
