import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { YotpoResponse } from '@/pages/api/yotpo';

interface YotpoContextType {
  yotpoResponse: YotpoResponse;
}

const YotpoContext = createContext<YotpoContextType | undefined>(undefined);

interface YotpoContextProps {
  children: ReactNode;
  yotpoResponse: YotpoResponse;
}

export const YotpoContextProvider: React.FC<YotpoContextProps> = ({ children, yotpoResponse }) => {
  return <YotpoContext.Provider value={{ yotpoResponse }}>{children}</YotpoContext.Provider>;
};

export function useYotpoReviewsContext(): YotpoContextType {
  const context = useContext(YotpoContext);
  if (!context) {
    throw new Error('useYotpoReviews must be used within a YotpoContextProvider');
  }
  return context;
}
