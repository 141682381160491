import { segmentUserUnauthenticatedAnalytics } from '@/lib/segment/segment';
import type { ShopifyCart } from '@/models/shop-context-cart';
import type { ProductCartEventProperties } from '@/utils/analytics/analytics-core';
import { getCampaignData, globalContextProperties } from '@/utils/analytics/analytics-core';
import { addToCartAnalytics } from '@/utils/analytics/analytics-events';

// INFO: All this was pulled out of old swab-pdp on 03/23/25. May need to revise.
export const buyNowAnalytics = async (cart: ShopifyCart) => {
  const campaignData = getCampaignData() ?? globalContextProperties();

  const merchandise = cart?.lines.edges[0].node.merchandise;
  const product = merchandise.product;
  const sku = merchandise.sku || null;
  const name = merchandise.title;
  const currencyCode = cart?.cost?.subtotalAmount?.currencyCode;
  const coupon = cart?.discountCodes[0].code;
  const minVariantPrice = product?.priceRange.minVariantPrice.amount as string;
  const cost = cart?.cost.subtotalAmount.amount as string;

  segmentUserUnauthenticatedAnalytics?.track(
    'Buy Now Clicked',
    {
      cartId: cart?.id,
      hasPromocode: !!coupon,
    },
    {
      ...campaignData,
    }
  );

  const properties: ProductCartEventProperties = {
    cart_id: cart.id,
    product_id: product.id,
    sku,
    name,
    price: minVariantPrice,
    discounted_price: coupon ? parseFloat(cost) : undefined,
    quantity: 1,
    url: window.location.href,
    coupon,
  };

  await addToCartAnalytics(
    parseFloat(minVariantPrice),
    currencyCode,
    [
      {
        item_id: `SKU_${sku}`,
        item_name: name,
      },
    ],
    properties
  );

  window.AF('pba', 'event', {
    eventType: 'EVENT',
    eventValue: {
      merchandiseId: merchandise.id,
      quantity: 1,
      cartId: cart.id,
    },
    eventName: `CART_ITEM_ADDED`,
  });
};
