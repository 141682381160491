import type { IncomingMessage } from 'http';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '@/../tailwind.config.js';

export const getTailwindBreakpoints = (size: 'sm' | 'md' | 'lg' | 'xl') => {
  /*
    in tailwind.config.js:
    sm: "480px",
    md: "768px",
    lg: "976px",
    xl: "1440px",
    */
  const cssConfig = resolveConfig(tailwindConfig) as any;
  return parseInt(cssConfig?.theme?.screens?.[size].slice(0, -2));
};

export const isClient = typeof window === 'object';

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getServerBaseUrl(req: IncomingMessage) {
  const protocol = req?.headers['x-forwarded-proto'] || 'http';
  const host = req.headers.host;
  return `${protocol}://${host}`;
}

export async function exponentialBackoff<T>(
  fn: () => Promise<T>,
  maxRetries: number = 3,
  baseDelay: number = 1000
): Promise<T> {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      return await fn();
    } catch (error) {
      if (attempt === maxRetries) {
        throw error;
      }
      // Calculate delay with exponential backoff
      const delay = baseDelay * Math.pow(2, attempt - 1);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
  throw new Error('Should not reach here');
}

export const splitAndReplaceSummaryTextTypeWithName = (text: string, firstName?: string) => {
  if (!text) return '';

  if (firstName) {
    const split = text.split('.');
    const firstSentence = split[0].replace(/Hey, \w+/, `Hey ${firstName}`);
    const remainingSentences = split.slice(1).join('.');
    return `${firstSentence}.${remainingSentences}`;
  } else {
    return text;
  }
};

/**
 *
 * Chronotype, exercise, and motivation in the DNA Report use a red-to-green slider, where red (good) transitions to green (bad). These sections should not indicate good or bad.
 * @param sectionId
 * @returns boolean
 */
export function isWhiteSliderSection(sectionId: string) {
  return ['chronotype', 'exercise', 'motivation'].some((section) => sectionId.includes(section));
}

export function formatDate(dateStr: string, options?: Intl.DateTimeFormatOptions) {
  const date = new Date(dateStr);

  return date.toLocaleDateString(
    'en-US',
    options || {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }
  );
}
