// https://github.com/vercel/next.js/tree/canary/examples/with-apollo

import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import type { GetStaticPropsResult } from 'next';
import { useMemo } from 'react';

import { getAuthAccessToken, parseAuthJWT } from '@/lib/auth0/auth';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationName = operation.operationName || 'Unnamed Operation';
  const clientName = operation.getContext().clientName || 'unknown';

  console.log(`[Apollo] Executing operation: ${operationName}, Client: ${clientName}`);

  if (graphQLErrors) {
    if (!Array.isArray(graphQLErrors)) {
      console.error(
        `[GraphQL Error] Non-iterable graphQLErrors for ${operationName} (Client: ${clientName}):`,
        JSON.stringify(graphQLErrors, null, 2)
      );
      return;
    }

    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL Error] Operation: ${operationName}, Client: ${clientName}\n` +
          `  Message: ${message}\n` +
          `  Location: ${locations ? JSON.stringify(locations) : 'N/A'}\n` +
          `  Path: ${path?.join(' > ') || 'N/A'}`
      );
    });
  }

  if (networkError) {
    console.error(
      `[Network Error] Operation: ${operationName}, Client: ${clientName}:`,
      networkError
    );
  }

  console.log(`[Apollo] Operation details:`, operation);
});

export const authTokenMiddleware = setContext((_, { headers }) => {
  const accessToken = getAuthAccessToken();
  const jwtRole = parseAuthJWT('role');
  return {
    headers:
      !!accessToken && !!jwtRole
        ? {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
            'x-hasura-role': `${jwtRole}`,
          }
        : {
            ...headers,
          },
  };
});

const rapptrLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_DATABASE_URL,
  credentials: 'same-origin',
});

const shopifyRetryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      return !!error && operation.operationName === 'cartDiscountCodesUpdate';
    },
  },
  delay: {
    initial: 300,
    max: 1000,
    jitter: false,
  },
});

const shopifyLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env
      .NEXT_PUBLIC_SHOPIFY_STORE_FRONT_ACCESS_TOKEN as string,
  },
});

const shopifyAdminLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_ADMIN_DOMAIN,
  headers: {
    'X-Shopify-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_ADMIN_ACCESS_TOKEN as string,
  },
});

const spaceId = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT;
const contentfulLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}${environment ? '/environments/' + environment : ''}`,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  },
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === 'contentful',
      from([errorLink, contentfulLink]), // <= apollo will send to this if clientName is "contentful"
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'shopify',
        from([errorLink, shopifyRetryLink, shopifyLink]), // <= apollo will send to this if clientName is "shopify"
        ApolloLink.split(
          (operation) => operation.getContext().clientName === 'shopify-admin',
          from([errorLink, shopifyRetryLink, shopifyAdminLink]), // <= apollo will send to this if clientName is "shopify-admin"
          from([authTokenMiddleware, errorLink, rapptrLink]) // <= otherwise will send to this
        )
      )
    ),
    cache: new InMemoryCache({
      typePolicies: {
        Cart: {
          fields: {
            attributes: {
              merge(existing = [], incoming = []) {
                // Merge the existing and incoming arrays by combining them
                const merged = [...existing, ...incoming];
                const unique = merged.filter(
                  (item, index, self) => self.findIndex((i) => i.key === item.key) === index
                );
                return unique;
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState<T extends Record<string, unknown>>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: GetStaticPropsResult<T>
): GetStaticPropsResult<T> {
  if ('props' in pageProps && pageProps.props) {
    return {
      ...pageProps,
      props: {
        ...pageProps.props,
        [APOLLO_STATE_PROP_NAME]: client.cache.extract(), // Add Apollo state
      },
    };
  }

  return pageProps;
}

export function useApollo(pageProps?: { [key: string]: any }) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}
