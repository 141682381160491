// you can import these packages anywhere
import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { shouldUseLogRocket } from '@/lib/vercel-env';

export const initializeLogRocket = () => {
  if (shouldUseLogRocket()) {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID || '');
    // plugins should also only be initialized when in the browser
    setupLogRocketReact(LogRocket);
    console.debug('# DEBUG: INITIALIZING_LOG_ROCKET');
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
    });
  }
};
