import type { FetchUserShippingAddressQuery, GetUserQuery } from '@/generated/hasura';
import { stringOrUndefined } from '@/utils/type-safe';

export enum UserReducerTypes {
  LogIn = 'LOG_IN',
  LogOut = 'LOG_OUT',
  FetchUser = 'FETCH_USER',
  SetShippingAddress = 'SET_SHIPPING_ADDRESS',
}

export interface UserShippingAddress {
  zip?: string;
  city?: string;
  country?: string;
  address1?: string;
  address2?: string;
  province?: string;
  country_code?: string;
  province_code?: string;
}

export type UserType = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  gender?: string;
  shippingAddress?: UserShippingAddress;
  cuid?: string;
};

export type UserStateType = {
  User: UserType | null;
};

export interface UserReducerAction {
  type: string;
  user?: GetUserQuery['User'][0];
  shippingAddress?: FetchUserShippingAddressQuery['fetchUserShippingAddress'];
}

export const toUserType = (user?: GetUserQuery['User'][0]): Omit<UserType, 'shippingAddress'> => {
  if (!user) {
    throw new Error('USER_NOT_DEFINED');
  }
  return {
    id: user.id,
    email: user.email,
    firstName: stringOrUndefined(user.firstName),
    lastName: stringOrUndefined(user.lastName),
    birthday: stringOrUndefined(user.birthday),
    gender: stringOrUndefined(user.gender),
  };
};
