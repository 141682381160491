/** @type {import('tailwindcss').Config} */
// eslint-disable-next-line @typescript-eslint/no-require-imports
const plugin = require('tailwindcss/plugin');

module.exports = {
  content: [
    './node_modules/@relume_io/relume-ui/dist/**/*.{js,ts,jsx,tsx}',
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
        sm: '2rem',
        lg: '2rem',
        xl: '2rem',
        '2xl': '2rem',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '976px',
      xl: '1440px',
    },
    extend: {
      boxShadow: {
        invert: '0 -1px 3px 0 rgb(0 0 0 / 0.1), 0 -1px 2px -1px rgb(0 0 0 / 0.1);',
      },
      data: {
        checked: 'ui~="checked"',
      },
      colors: {
        white: '#FFFFFF',
        black: '#000000',
        'alter-green': '#1E7E0E',
        'alter-green-60': '#39846C',
        'alter-red': '#B01601',
        'alter-red-60': '#B0160199',
        'alter-black': '#1A1818',
        'alter-black-90': 'rgba(26, 24, 24, 0.9)',
        'alter-black-80': 'rgba(26, 24, 24, 0.8)',
        'alter-black-65': 'rgba(26, 24, 24, 0.65)',
        'alter-black-70': 'rgba(26, 24, 24, 0.7)',
        'alter-black-60': 'rgba(26, 24, 24, 0.6)',
        'alter-black-50': 'rgba(26, 24, 24, 0.5)',
        'alter-black-40': 'rgba(26, 24, 24, 0.4)',
        'alter-black-20': 'rgba(26, 24, 24, 0.2)',
        'alter-black-10': 'rgba(26, 24, 24, 0.1)',
        'alter-black-8': 'rgba(26, 24, 24, 0.08)',
        'alter-black-4': 'rgba(26, 24, 24, 0.04)',
        'alter-bone': 'rgba(255, 252, 244, 1)',
        'alter-bone-10': 'rgba(255, 252, 244, 0.1)',
        'alter-bone-15': 'rgba(255, 252, 244, 0.15)',
        'alter-bone-30': 'rgba(255, 252, 244, 0.3)',
        'alter-bone-40': 'rgba(255, 252, 244, 0.4)',
        'alter-bone-50': 'rgba(255, 252, 244, 0.5)',
        'alter-bone-60': 'rgba(255, 252, 244, 0.6)',
        'alter-bone-70': 'rgba(255, 252, 244, 0.7)',
        'alter-bone-75': 'rgba(255, 252, 244, 0.75)',
        'alter-bone-80': 'rgba(255, 252, 244, 0.8)',
        'alter-bone-100': '#FFFCF4',
        'alter-gray': '#DADADA',
        'alter-gray-60': '#FFFCF4',
        'alter-error': '#EC4C4C',
        'alter-warning': '#FFFF00',
        'alter-success': '#00FF00',
        'alter-delete': 'rgba(176, 22, 1, 0.60)', // temp name,
        'graph-sage-10': '#DAE7DD',
        'graph-sage-50': '#506854',
        'graph-aqua-40': '#56A6C0',
        'alter-brown': '#8A7E76',
        'alter-orange': '#F7510C',
        'alter-beige': '#F6F6F1',
        'alter-warning-100': '#F7D10C',
        'alter-success-100': '#8BD710',
        'alter-blue': '#82DFDF',
        'alter-purple': '#BD7BDC',
        'alter-blue-100': '#60A5FA',
        'alter25-black-a': '#283042',
        'alter25-black-b': '#121A16',
        'alter25-black-c': '#2A373B',
        'alter25-button-dark': '#2A373B',
        'alter25-button-dark-outline': '#7f8685',
        'alter25-button-light': '#F5F4EF',
        'alter25-button-light-hover': '#EBEBE7',
        'alter25-button-light-outline': '#DDDCDC',
        'alter25-cream-a': '#EBEBE7',
        'alter25-cream-b': '#F5F4EF',
        'alter25-red': '#E0432E;',
        'checkmark-green': '#33912E',
        'highlight-green': '#DEECDD',
        'badge-gray': '#D5D7D8',
      },
      fontFamily: {
        ModernGothic: ['var(--modern-gothic)', 'sans-serif'],
        inter: ['var(--inter)', 'sans-serif'],
        ModernGothicTrial: ['var(--modern-gothic-trial)', 'sans-serif'],
        SabonLTStd: ['var(--sabon-lt-std)', 'serif'],
      },
      padding: {
        4.5: '1.125rem',
      },
      margin: {
        38: '9.5rem',
        74: '18.5rem',
      },
      fontSize: {
        '3.5xl': '2rem',
        xxl: '2.5rem',
        '3.5rem': '3.5rem',
      },
      lineHeight: {
        '4.25rem': '4.25rem',
      },
      borderRadius: {
        none: '0',
        '2.5xl': '0.625rem',
        DEFAULT: '16px',
        35: '35px',
        42: '42px',
      },
      borderColor: {
        'alter-bone-20': 'rgba(255, 252, 244, 0.20)',
      },
      animation: {
        fadeIn: 'fadeIn 1s ease-out forwards',
        fadeOut: 'fadeIn 1s ease-out reverse forwards',
        shrink: 'max-height ease-out',
        expand: 'max-height ease-in',
        spin: 'spin 1s linear infinite',
        'infinite-scroll': 'infinite-scroll 20s linear infinite',
      },
      keyframes: {
        'infinite-scroll': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%)' },
        },
      },
      backgroundImage: {
        gradient1:
          'linear-gradient(180deg, #0F141A 0%, rgba(8, 9, 8, 0.588542) 41.15%, rgba(164, 167, 255, 0.37) 100%)',
        gradient2: 'linear-gradient(0deg, #1A1818, #1A1818)',
      },
      screens: {
        'nutri-md': '1042px',
      },
    },
  },

  plugins: [
    plugin(function (helpers) {
      /* variants that help styling Radix-UI components
       https://blog.makerx.com.au/styling-radix-ui-components-using-tailwind-css/ */
      dataStateVariant('open', helpers);
      dataStateVariant('closed', helpers);
      dataStateVariant('on', helpers);
      dataStateVariant('checked', helpers);
      dataStateVariant('unchecked', helpers);
    }),

    plugin(function ({ addVariant }) {
      // Add a `third` variant, ie. `third:pb-0`
      addVariant('canhover', '@media (hover: hover)');
    }),

    //https://github.com/tailwindlabs/tailwindcss/discussions/3378
    plugin(function ({ matchUtilities, theme }) {
      matchUtilities(
        {
          'animation-delay': (value) => {
            return {
              'animation-delay': value,
            };
          },
        },
        {
          values: theme('transitionDelay'),
        }
      );
    }),

    // Add a class for anchor element offset to account for header
    plugin(function ({ addUtilities }) {
      const newUtilities = {
        '.anchor-offset::before': {
          content: "''",
          display: 'block',
          height: '140px',
          margin: '-140px 0 0',
        },
      };

      addUtilities(newUtilities, ['responsive']);
    }),
  ],
};

function dataStateVariant(
  state,
  {
    addVariant, // for registering custom variants
    e, // for manually escaping strings meant to be used in class names
  }
) {
  addVariant(`data-state-${state}`, ({ modifySelectors, separator }) => {
    modifySelectors(({ className }) => {
      return `.${e(`data-state-${state}${separator}${className}`)}[data-state='${state}']`;
    });
  });

  addVariant(`group-data-state-${state}`, ({ modifySelectors, separator }) => {
    modifySelectors(({ className }) => {
      return `.group[data-state='${state}'] .${e(`group-data-state-${state}${separator}${className}`)}`;
    });
  });

  addVariant(`peer-data-state-${state}`, ({ modifySelectors, separator }) => {
    modifySelectors(({ className }) => {
      return `.peer[data-state='${state}'] ~ .${e(`peer-data-state-${state}${separator}${className}`)}`;
    });
  });
}
