export enum VercelEnvironment {
  production = 'production',
  preview = 'preview',
  development = 'development',
  local = 'local',
}

export const vercelEnvironment: VercelEnvironment =
  VercelEnvironment[
    (process.env.NEXT_PUBLIC_VERCEL_ENV as 'production' | 'preview' | 'development') || 'local'
  ];

export const shouldUseSentry = (): boolean => {
  return [VercelEnvironment.production, VercelEnvironment.preview].includes(vercelEnvironment);
};

export const shouldUseLogRocket = (): boolean => {
  return [VercelEnvironment.production, VercelEnvironment.preview].includes(vercelEnvironment);
};
