import { getCookie } from 'cookies-next';
import UAParser from 'ua-parser-js';

import { parseAuthJWT } from '@/lib/auth0/auth';
import {
  segmentUserAuthenticatedAnalytics,
  segmentUserUnauthenticatedAnalytics,
} from '@/lib/segment/segment';

/*
    no integration with web firebase and segment
    so analytics are set up according to
    https://developers.google.com/tag-platform/gtagjs/reference/events
    in an attempt to mimic the events in both firebase and segment
*/

export type FirebaseItem = {
  item_id: string;
  item_name: string;
  [x: string]: any;
};

export const resetSegment = () => {
  segmentUserAuthenticatedAnalytics?.reset();
};

export const subscribeToNewsletterAnalytics = (email: string) => {
  segmentUserUnauthenticatedAnalytics?.track('newsletter_signup', {
    email: email,
  });
};

export type ProductCartEventProperties = {
  cart_id: string;
  product_id: string;
  sku: string | null;
  name: string;
  price: string;
  discounted_price?: number;
  quantity: number;
  coupon?: string;
  url: string;
  image_url?: string;
};

export const isAuthenticated = (): boolean => {
  return parseAuthJWT('id') !== undefined;
};

export const getSegmentAnalytics = () => {
  if (typeof window === 'undefined') return null;

  return isAuthenticated()
    ? segmentUserAuthenticatedAnalytics
    : segmentUserUnauthenticatedAnalytics;
};

export const segmentIdentify = ({
  id,
  ...properties
}: {
  id: string;
} & Record<string, string>) => {
  segmentUserAuthenticatedAnalytics?.identify(id, properties);
};

export const globalEventProperties = (): { [key: string]: any } => {
  const urlParams = new URLSearchParams(window.location.search);
  const userAgentParser = new UAParser();
  const properties: { [key: string]: any } = {
    source: 'Website',
    browser: userAgentParser.getBrowser().name,
    browser_version: userAgentParser.getBrowser().version,
    os: userAgentParser.getOS().name,
    ef_tid: getEverflowAdverstiserTransactionId(),
  };

  const cookieKeys = ['_fbc', '_fbp', '_ga'];
  cookieKeys.forEach((key) => {
    const value = getCookie(key);
    if (value) properties[key.replace('_', '')] = value;
  });

  [
    'campaign_name',
    'campaign_source',
    'campaign_medium',
    'campaign_term',
    'campaign_content',
    'tid',
  ].forEach((key) => {
    if (urlParams.get(key)) {
      properties[key] = urlParams.get(key);
    }
  });

  return properties;
};

export const storeCampaignData = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (Array.from(urlParams.keys()).length === 0) {
    return;
  }
  // Prevent overwriting campaignData with any other data
  if (!urlParams.has('utm_campaign')) {
    return;
  }
  const campaignData: { [key: string]: string } = {};
  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      const keyWithoutUtm = key.replace('utm_', '');
      campaignData[keyWithoutUtm] = value;
    } else {
      campaignData[key] = value;
    }
  });
  localStorage.setItem('campaignData', JSON.stringify(campaignData));
};

export const getCampaignData = () => {
  const campaignDataJSON = localStorage.getItem('campaignData');
  if (!campaignDataJSON) return null;
  return JSON.parse(campaignDataJSON);
};

export const globalContextProperties = (): { [key: string]: any } | null => {
  const urlParams = new URLSearchParams(window.location.search);
  if (Array.from(urlParams.keys()).length === 0) return null;
  const context: { [key: string]: any } = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      const keyWithoutUtm = key.replace('utm_', '');
      context[keyWithoutUtm] = value;
    } else {
      context[key] = value;
    }
  });

  return context;
};

export const getEverflowAdverstiserTransactionId = () => {
  if (
    typeof window !== 'undefined' &&
    window.EF &&
    typeof window.EF.getAdvertiserTransactionId === 'function'
  ) {
    return window.EF.getAdvertiserTransactionId(1);
  } else {
    return null;
  }
};
