import { logEvent } from 'firebase/analytics';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import { segmentUserAuthenticatedAnalytics } from '@/lib/segment/segment';

export const sendExceptionAnalytics = (description: string) => {
  logEvent(firebaseAnalytics, 'exception', {
    description: description,
  });
  segmentUserAuthenticatedAnalytics?.track('login', {
    description: description,
  });
};
