import type { ShopifyCart } from '@/models/shop-context-cart';
import { sendAxonEvent } from '@/utils/analytics/pixel';

export const viewItem = (cart: ShopifyCart) => {
  const merchandise = cart?.lines.edges[0].node.merchandise;
  const product = merchandise.product;
  const sku = merchandise.sku;
  const item_name = merchandise.title;
  const currency = cart?.cost?.subtotalAmount?.currencyCode;
  const discountCode = cart?.discountCodes[0].code;
  const minVariantPrice = product?.priceRange.minVariantPrice.amount as string;
  const cost = cart?.cost.subtotalAmount.amount as string;
  const discountAmount = (parseFloat(minVariantPrice) - parseFloat(cost)).toFixed(2);

  sendAxonEvent('view_item', {
    currency,
    value: cost,
    items: [
      {
        item_id: `SKU_${sku}`,
        item_name,
        price: cost,
        currency,
        quantity: 1,
        discount: discountCode ? discountAmount : undefined,
      },
    ],
  });
};
