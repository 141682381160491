import * as Sentry from '@sentry/nextjs';
import { deleteCookie, getCookie } from 'cookies-next';
import jwt_decode from 'jwt-decode';
import LogRocket from 'logrocket';

import { resetSegment } from '@/utils/analytics';

export const parseAuthJWT = (value?: 'role' | 'id') => {
  const cookie = getCookie('auth');
  // console.debug('Auth Cookie:', cookie);

  let parsedCookie;
  try {
    parsedCookie = cookie ? JSON.parse(cookie as string) : undefined;
  } catch (error) {
    console.error('Error parsing auth cookie:', error);
    return;
  }

  // console.debug('Parsed Cookie:', parsedCookie);
  let decodedToken: any;

  try {
    if (!parsedCookie?.access_token) {
      // throw new Error('Missing access_token in auth cookie');
      // console.debug('AUTH_COOKIE_NOT_DEFINED');
      return null;
    }
    // console.debug('Access Token:', parsedCookie.access_token);

    decodedToken = jwt_decode(parsedCookie.access_token);
    // console.log(decodedToken?.["https://hasura.io/jwt/claims"], "decodedToken");
    switch (value) {
      case 'role':
        return decodedToken?.['https://hasura.io/jwt/claims']?.['x-hasura-role'];
      case 'id':
        return decodedToken?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'];
      default:
        return decodedToken?.['https://hasura.io/jwt/claims'];
    }
  } catch (error) {
    console.error('JWT Decode Error:', error);
    return null;
  }
};

export const getAuthAccessToken = () => {
  const cookie = getCookie('auth');
  const parsedCookie = cookie ? JSON.parse(cookie as string) : undefined;
  return parsedCookie?.access_token;
};

export const logout = () => {
  localStorage.removeItem('kitId');
  localStorage.removeItem('activation_code');
  Sentry.setUser(null);
  LogRocket.identify('');
  resetSegment();
  deleteCookie('auth');
};
