// services/shopify.ts
import type { LazyQueryExecFunction } from '@apollo/client';
import axios from 'axios';

import type {
  AddToCartMutationFn,
  AddToCartMutationOptions,
  CartBuyerIdentityUpdateMutationFn,
  CartBuyerIdentityUpdateMutationOptions,
  CartCreateMutationFn,
  CartCreateMutationOptions,
  CartDeliveryAddressesAddMutationFn,
  CartDeliveryAddressesAddMutationOptions,
  CartDetailsQuery,
  Exact,
  FetchCartLinesQuery,
  RemoveFromCartMutationFn,
  UpdateCartAttributesMutationFn,
  UpdateCartAttributesMutationOptions,
  UpdateCartDiscountCodesMutationFn,
  UpdateCartDiscountCodesMutationOptions,
} from '@/generated/shopify';
import { sendSentryError } from '@/lib/sentry/sentry';
import type { ShopifyCart } from '@/models/shop-context-cart';
import { getCampaignData } from '@/utils/analytics';

interface IShopifyService {
  createCart(variables: CartCreateMutationOptions['variables']): Promise<ShopifyCart>;
  addToCart(variables: AddToCartMutationOptions['variables']): Promise<ShopifyCart>;
  updateCartBuyerIdentity(
    variables: CartBuyerIdentityUpdateMutationOptions['variables']
  ): Promise<ShopifyCart>;
  addCartDeliveryAddresses(
    variables: CartDeliveryAddressesAddMutationOptions['variables']
  ): Promise<ShopifyCart>;
  addDiscountToCart(variables: any): Promise<any>;
  updateCartAttributes(
    variables: UpdateCartAttributesMutationOptions['variables']
  ): Promise<ShopifyCart>;
  sendCampaignData(cartId: string): Promise<void>;
  removeCartLines(cartId: string, lineIds: string[]): Promise<any>;
  fetchCartLines(cartId: string): Promise<any>; // TODO: no any
  cartDetails(cartId: string): Promise<ShopifyCart>; // TODO: no any
  extractCartToken(cartId: string): string | undefined;
}
export class ShopifyService implements IShopifyService {
  private baseUrl = process.env.NEXT_PUBLIC_BACKEND_BASE_URL;

  constructor(
    private createCartMutation: CartCreateMutationFn,
    private cartDetailsLazyQuery: LazyQueryExecFunction<
      CartDetailsQuery,
      Exact<{ cartId: string }>
    >,
    private addToCartMutation: AddToCartMutationFn,
    private updateCartAttributesMutation: UpdateCartAttributesMutationFn,
    private updateCartBuyerIdentityMutation: CartBuyerIdentityUpdateMutationFn,
    private addCartDeliveryAddressesMutation: CartDeliveryAddressesAddMutationFn,
    private removeFromCartMutation: RemoveFromCartMutationFn,
    private fetchCartLinesLazyQuery: LazyQueryExecFunction<
      FetchCartLinesQuery,
      Exact<{ cartId: string }>
    >,
    private updateCartDiscountCodesMutation: UpdateCartDiscountCodesMutationFn
  ) {}

  async createCart(variables: CartCreateMutationOptions['variables']) {
    try {
      const response = await this.createCartMutation({
        variables,
        context: { clientName: 'shopify' },
      });
      const cart = response.data?.cartCreate?.cart;
      if (!cart) throw new Error('Failed to create cart');
      return cart;
    } catch (error) {
      sendSentryError('Cart creation failed', error);
      throw error;
    }
  }

  async updateCartBuyerIdentity(variables: CartBuyerIdentityUpdateMutationOptions['variables']) {
    try {
      const response = await this.updateCartBuyerIdentityMutation({
        variables,
        context: { clientName: 'shopify' },
      });
      const cartBuyerIdentityUpdate = response.data?.cartBuyerIdentityUpdate;
      if (!cartBuyerIdentityUpdate?.cart) throw new Error('Failed to update cart buyer identity');
      return cartBuyerIdentityUpdate.cart;
    } catch (error) {
      sendSentryError('Update cart buyer identity failed', error);
      throw error;
    }
  }

  async addCartDeliveryAddresses(variables: CartDeliveryAddressesAddMutationOptions['variables']) {
    try {
      const response = await this.addCartDeliveryAddressesMutation({
        variables,
        context: { clientName: 'shopify' },
      });
      const cartDeliveryAddressesAdd = response.data?.cartDeliveryAddressesAdd;
      if (!cartDeliveryAddressesAdd?.cart) throw new Error('Failed to add cart delivery addresses');
      return cartDeliveryAddressesAdd.cart;
    } catch (error) {
      sendSentryError('Add cart delivery addresses failed', error);
      throw error;
    }
  }

  async addToCart(variables: AddToCartMutationOptions['variables']) {
    try {
      const response = await this.addToCartMutation({
        variables,
        context: { clientName: 'shopify' },
      });
      const cartLinesAdd = response.data?.cartLinesAdd;
      if (!cartLinesAdd?.cart) throw new Error('Failed to add lines to cart');
      return cartLinesAdd.cart;
    } catch (error) {
      sendSentryError('Add to cart failed', error);
      throw error;
    }
  }

  async addDiscountToCart(variables: UpdateCartDiscountCodesMutationOptions['variables']) {
    try {
      const response = await this.updateCartDiscountCodesMutation({
        variables,
        context: { clientName: 'shopify' },
      });
      const cartDiscountCodesUpdate = response.data?.cartDiscountCodesUpdate;
      if (!cartDiscountCodesUpdate?.cart) throw new Error('Failed to update discount codes');
      return cartDiscountCodesUpdate.cart;
    } catch (error) {
      sendSentryError('Update discount codes failed', error);
      throw error;
    }
  }

  async updateCartAttributes(variables: UpdateCartAttributesMutationOptions['variables']) {
    try {
      const response = await this.updateCartAttributesMutation({
        variables,
        context: { clientName: 'shopify' },
      });
      const cartAttributesUpdate = response.data?.cartAttributesUpdate;
      if (!cartAttributesUpdate?.cart) throw new Error('Failed to update cart attributes');
      return cartAttributesUpdate.cart;
    } catch (error) {
      sendSentryError('Update cart attributes failed', error);
      throw error;
    }
  }

  async sendCampaignData(cartId: string) {
    try {
      const campaignData = getCampaignData();
      if (!campaignData) {
        return;
      }
      const cleanCartId = cartId.split('/').pop()?.split('?')[0];
      await axios.post(`${this.baseUrl}/campaignData`, {
        cart_id: cleanCartId,
        payload: campaignData,
      });
    } catch (error) {
      sendSentryError('Campaign data send failed', error);
      throw error;
    }
  }

  async removeCartLines(cartId: string, lineIds: string[]) {
    try {
      const response = await this.removeFromCartMutation({
        variables: { cartId, lineIds },
        context: { clientName: 'shopify' },
      });
      const cartLinesRemove = response.data?.cartLinesRemove;
      if (!cartLinesRemove?.cart) throw new Error('Failed to remove cart lines');
      return cartLinesRemove.cart;
    } catch (error) {
      sendSentryError('Cart lines removal failed', error);
      throw error;
    }
  }

  async fetchCartLines(cartId: string) {
    try {
      const response = await this.fetchCartLinesLazyQuery({
        variables: { cartId },
        fetchPolicy: 'no-cache',
        context: { clientName: 'shopify' },
      });
      return response.data?.cart?.lines.edges.map((edge: any) => edge.node.id);
    } catch (error) {
      sendSentryError('Cart lines fetch failed', error);
      throw error;
    }
  }

  async cartDetails(cartId: string) {
    try {
      const response = await this.cartDetailsLazyQuery({
        variables: { cartId },
        fetchPolicy: 'no-cache',
        context: { clientName: 'shopify' },
      });

      const cart = response.data?.cart;
      if (!cart) throw new Error('Failed to create cart');
      return cart;
    } catch (error) {
      sendSentryError('Cart Details fetch failed', error);
      throw error;
    }
  }

  extractCartToken(cartId: string): string | undefined {
    return cartId.match(/gid:\/\/shopify\/Cart\/(\w+)/)?.[1];
  }
}
