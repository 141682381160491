import { setCookie } from 'cookies-next';

import type { ShopStateType } from '@/models/shop-context';
import type { ShopifyCart } from '@/models/shop-context-cart';

export enum ShopReducerTypes {
  CreateCart = 'CREATE_CART',
  AddToCart = 'ADD_TO_CART',
  UpdateCart = 'UPDATE_CART',
  DeleteCartItem = 'REMOVE_CART_ITEM',
  Checkout = 'CHECKOUT',
  SetCartError = 'SET_CART_ERROR',
  ClearCartError = 'CLEAR_CART_ERROR',
}

type ShopAction =
  | { type: ShopReducerTypes.CreateCart; payload: ShopifyCart }
  | { type: ShopReducerTypes.AddToCart; payload: ShopifyCart }
  | { type: ShopReducerTypes.UpdateCart; payload: ShopifyCart } // Define specific type
  | { type: ShopReducerTypes.DeleteCartItem; payload: ShopifyCart } // Define specific type
  | { type: ShopReducerTypes.Checkout }
  | { type: ShopReducerTypes.SetCartError; payload: string } // Define specific error type
  | { type: ShopReducerTypes.ClearCartError };

export const ShopReducer = (state: ShopStateType, action: ShopAction) => {
  switch (action.type) {
    case ShopReducerTypes.CreateCart:
      _setCartIdCookie(action.payload);
      return { ...state, cart: action.payload, cartError: null };
    case ShopReducerTypes.AddToCart:
      _setCartIdCookie(action.payload);
      return { ...state, cart: action.payload, cartError: null };
    case ShopReducerTypes.UpdateCart:
      _setCartIdCookie(action.payload);
      return { ...state, cart: action.payload, cartError: null };
    case ShopReducerTypes.DeleteCartItem:
      _setCartIdCookie(action.payload);
      return { ...state, cart: action.payload, cartError: null };
    case ShopReducerTypes.Checkout:
      redirectToCheckout(state);
      return { ...state };
    case ShopReducerTypes.SetCartError:
      console.error(action.payload);
      return { ...state, cartError: action.payload };
    case ShopReducerTypes.ClearCartError:
      return { ...state, cartError: null };
    default:
      return state;
  }
};

/**
 * Opens the Shopify checkout URL in a new tab
 *
 * @param {ShopStateType} state - The current state of the shop,
 * containing the cart information.
 */
const redirectToCheckout = async (state: ShopStateType) => {
  console.log('Redirecting to checkout...');
  // console.debug('Current state:', state);
  console.log('Opening checkout URL:', state.cart.checkoutUrl);
  window.open(state.cart.checkoutUrl, '_self');
};

const _setCartIdCookie = (shopifyCart: ShopifyCart) => {
  setCookie('shop_cart_id', shopifyCart.id, {
    maxAge: 60 * 60 * 24 * 14, // 14 days in seconds
    path: '/',
  });
};
