import { logEvent } from 'firebase/analytics';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import { segmentUserAuthenticatedAnalytics } from '@/lib/segment/segment';

export const sendSignupAnalytics = () => {
  logEvent(firebaseAnalytics, 'sign_up', {
    method: 'auth0',
  });
  segmentUserAuthenticatedAnalytics?.track('sign_up', {
    method: 'auth0',
  });
};
