import { logEvent } from 'firebase/analytics';
import { v4 as uuidv4 } from 'uuid';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import {
  segmentUserAuthenticatedAnalytics,
  segmentUserUnauthenticatedAnalytics,
} from '@/lib/segment/segment';
import type { FirebaseItem, ProductCartEventProperties } from '@/utils/analytics/analytics-core';
import {
  getCampaignData,
  globalContextProperties,
  globalEventProperties,
  isAuthenticated,
} from '@/utils/analytics/analytics-core';
import { sendAxonEvent, sendTikTokPixelEvent } from '@/utils/analytics/pixel';

export const addToCartAnalytics = async (
  value: number,
  currency: string, //currency code
  items: FirebaseItem[],
  eventProperties?: ProductCartEventProperties
) => {
  console.log('addToCartAnalytics');
  const eventId = uuidv4();
  logEvent(firebaseAnalytics, 'add_to_cart', {
    value,
    currency,
    items,
  });
  const campaignData = getCampaignData() ?? globalContextProperties();
  console.log('addToCartAnalytics2');
  // Send the unauthenticated add to cart event regardless of authentication
  // This is needed on the snowflake side to join the user's events with the shopify purchase event
  await segmentUserUnauthenticatedAnalytics?.track(
    'Product Added',
    {
      ...eventProperties,
      ...globalEventProperties(),
    },
    {
      ...campaignData,
    }
  );
  if (isAuthenticated()) {
    console.log('addToCartAnalytics isAuthenticated');
    await segmentUserAuthenticatedAnalytics?.track(
      'add_to_cart',
      {
        value,
        currency,
        items,
      },
      {
        ...campaignData,
      }
    );
  }
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent('AddToCart', {
    event_id: eventId,
    value,
    currency,
    content_id: eventProperties?.product_id,
    content_type: 'product',
    content_name: eventProperties?.name,
    timestamp: new Date().toISOString(),
    user_agent,
  });

  sendAxonEvent('add_to_cart', {
    value,
    currency,
    items: items.map((item) => ({
      item_id: item.item_id,
      item_name: item.item_name,
      price: eventProperties?.discounted_price ?? eventProperties?.price,
      quantity: eventProperties?.quantity,
      discount:
        eventProperties?.discounted_price && eventProperties?.price
          ? Number(eventProperties.price) - eventProperties.discounted_price
          : undefined,
    })),
  });
};
