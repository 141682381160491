import { createContext, useEffect, useState } from 'react';

import tailwindConfig from '@/../tailwind.config';

export enum ScreenSize {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  none = 'none',
}

const extractPixels = (screenSize: string): number => {
  let pixels: number;
  try {
    pixels = parseInt(screenSize.replace('px', ''), 10);
  } catch (error) {
    throw new Error('BAD_SCREEN_SIZE_FROM_TAILWIND');
  }
  return pixels;
};

// Create the Context
export const ScreenSizeContext = createContext<ScreenSize>(ScreenSize.none);

// Context Provider
export const ScreenSizeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState<ScreenSize>(ScreenSize.none);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const getBreakpoint = (width: number) => {
        if (width >= extractPixels(tailwindConfig.theme.screens.xl)) return ScreenSize.xl;
        if (width >= extractPixels(tailwindConfig.theme.screens.lg)) return ScreenSize.lg;
        if (width >= extractPixels(tailwindConfig.theme.screens.md)) return ScreenSize.md;
        if (width >= extractPixels(tailwindConfig.theme.screens.sm)) return ScreenSize.sm;
        return ScreenSize.xs;
      };

      const handleResize = () => {
        setBreakpoint(getBreakpoint(window.innerWidth));
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Set initial value

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return <ScreenSizeContext.Provider value={breakpoint}>{children}</ScreenSizeContext.Provider>;
};
