export const orNULL = <T>(value?: T): T | null => {
  if (!value) {
    return null;
  }
  return value;
};

export const orUndefined = <T>(value?: T): T | undefined => {
  if (!value) {
    return undefined;
  }
  return value;
};

export const orEMPTY = (value?: string | null): string => {
  if (!value) {
    return '';
  }
  return value;
};

export const stringOrUndefined = (value?: string | number | null): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return value;
};

export const stringOrNull = (value?: string | number | null): string | null => {
  if (!value) {
    return null;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return value;
};

// export const numberOrUndefined = (
//   value?: number | null
// ): number | undefined => {
//   if (!value) {
//     return undefined;
//   }
//   return value;
// };
