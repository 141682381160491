import { logEvent } from 'firebase/analytics';

import { firebaseAnalytics } from '@/lib/firebase/firebase';
import { segmentUserAuthenticatedAnalytics } from '@/lib/segment/segment';

export const sendLoginAnalytics = (email: string) => {
  logEvent(firebaseAnalytics, 'login', {
    method: 'auth0',
    email: email,
  });
  segmentUserAuthenticatedAnalytics?.track('login', {
    method: 'auth0',
    email: email,
  });
};
